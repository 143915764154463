import React from 'react';
import { Col, Row } from 'antd';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ImageComponent from '@sharedComponent/image-component';
import './header-screen.scss';

function Headersection({ onClickable, upgradeDiv }) {
	return (
		<Row>
			<Col>
				<Row style={{ backgroundColor: '#F58178', justifyContent: 'center', marginTop: '-15px' }}>
					<Col xl={18} lg={20} md={20} sm={22} xs={22}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className="for-flex-alignment">
								<p className="get-discount-text">GET 15% OFF!</p>
								<p className="early-bird-text">Early bird discount valid for a limited time only!</p>
							</div>
							<div style={{ display: 'flex' }} onClick={() => onClickable('header')}>
								<p className="get-early-bird">Get Early Bird Discount</p>
								<ImageComponent src={not_focus_icons?.ArrowRight} className="arrow-image" />
							</div>
						</div>
					</Col>
				</Row>
				<Row style={{ justifyContent: 'center' }}>
					<Col xl={22} lg={22} md={24} sm={24} xs={24}>
						<Row>
							<Col xl={9} lg={8} md={24} sm={24} xs={24} style={{ alignSelf: 'center' }}>
								<p className="payroll-heading">Digitize Payroll and HR. Manage it all in one place.</p>
								<p className="payroll-paragraph">Process payroll, manage leaves, documents and send announcements with ease.</p>
								<button className="payroll-upgrade-button" onClick={upgradeDiv}>
									Upgrade
								</button>
							</Col>
							<Col xl={15} lg={16} md={24} sm={24} xs={24}>
								<ImageComponent src={not_focus_icons.HeaderImg} style={{ width: '100%', marginTop: '5%' }} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}

export default Headersection;
