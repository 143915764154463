import React from 'react';
import { Row, Col } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ETypo from '@pages/DesignSystem/Typo';
import ContentLoader from '@sharedComponent/content-loader';
import Timingcard from './timingcard';

const days = [{ leaveDays: 'Monday - Saturday' }];

const PayrollTimingsPresentational = ({ loading }) => {
	const staticPayrollTimingsWPS = [
		{ salaryfundrecieved: 'Salary Funds Received:', expectedPayout: 'Expected Payout:' },
		{ salaryfundrecieved: 'Before 9 AM', expectedPayout: '3 PM' },
		{ salaryfundrecieved: 'Before 11 AM', expectedPayout: '5 PM' },
		{ salaryfundrecieved: 'Before 1 PM', expectedPayout: '7 PM' },
		{ salaryfundrecieved: 'Before 4.30 PM', expectedPayout: '9 PM' },
	];
	const staticPayrollTimingsWPSTopFiftyClients = [
		{ salaryfundrecieved: 'Salary Funds Received:', expectedPayout: 'Expected Payout:' },
		{ salaryfundrecieved: 'Before 9 AM', expectedPayout: '3 PM' },
		{ salaryfundrecieved: 'Before 11 AM', expectedPayout: '5 PM' },
		{ salaryfundrecieved: 'Before 1 PM', expectedPayout: '7 PM' },
		{ salaryfundrecieved: 'Before 3 PM', expectedPayout: '9 PM' },
	];
	const staticPayrollTimingsNonWPS = [
		{ salaryfundrecieved: 'Salary Funds Received:', expectedPayout: 'Expected Payout:' },
		{ salaryfundrecieved: 'Before 4.30 PM', expectedPayout: '8 PM' },
	];
	console.log(staticPayrollTimingsWPS);
	return (
		<>
			<EdenredHeader />
			{loading ? (
				<ContentLoader />
			) : (
				<Row className="payroll_timings_container">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_timings_title">
						<ETypo h2 bold className="heading-color">
							Payroll Cut-off Timing
						</ETypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_timings_card_section">
						<Row xl={24} lg={24} md={24} sm={24} xs={24} className="wps-and-nonwps-section">
							<div className="payroll_timings_wps-nonwps_card">
								<ETypo h4 medium className="payroll_timings_card_title">
									WPS
								</ETypo>
								{staticPayrollTimingsWPS ? (
									<Timingcard content={staticPayrollTimingsWPS ? staticPayrollTimingsWPS : []}></Timingcard>
								) : (
									<Timingcard
										content={staticPayrollTimingsWPSTopFiftyClients ? staticPayrollTimingsWPSTopFiftyClients : []}></Timingcard>
								)}
							</div>

							{staticPayrollTimingsNonWPS ? (
								<div className="payroll_timings_wps-nonwps_card">
									<ETypo h4 medium className="payroll_timings_card_title">
										Non WPS
									</ETypo>

									<Timingcard content={staticPayrollTimingsNonWPS ? staticPayrollTimingsNonWPS : []} type="PayrollTimings" />
								</div>
							) : null}
						</Row>
						<Row className="payroll_timings_footer">
							<ETypo b2 light className="value-color">
								Salaries are processed 6 days a week ({days?.[0]?.leaveDays}), with the exception of public holidays.
							</ETypo>
							<ETypo b2 light className="value-color margin-top-16">
								The cut-off time for Non WPS Bank Accounts is 12 PM.
							</ETypo>
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
};

export default PayrollTimingsPresentational;
