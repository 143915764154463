import { reducer as oidcReducer } from 'redux-oidc';
import subscriptionsReducer from './redux-oidc/subscriptions';
// import counterReducer from '../screens/counter/counterReducer';
// import counterSage from '../screens/counter/sagas';
import { injectReducer, injectSaga } from './rootReducer';
import responsiveScreenReducer from 'src/hooks/useResponsiveScreen/responsiveScreenReducer';
import hrSetupReducer from '@pages/NewHrModuleSetup/store/reducer';
import hrSaga from '@pages/NewHrModuleSetup/store/rootSaga';
import counterReducer from '@pages/payroll-plus-new/reducer';
import counterSaga from '@pages/payroll-plus-new/sagas';
import mfaReducer from '@pages/auth/mfa/reducer';
import mfaSaga from '@pages/auth/mfa/saga/rootSaga';
import unemploymentSaga from '@pages/UnEmploymentInsurance/Employee/saga';
import unemploymentReducer from '@pages/UnEmploymentInsurance/Employee/reducer';
import { employeeVerificationReducer, employeeVerificationSaga } from '@pages/employees/EmployeeVerification/redux';

const injectReducers = (store) => {
	injectReducer(store, { key: 'oidc', reducer: oidcReducer });
	injectReducer(store, { key: 'subscriptions', reducer: subscriptionsReducer });
	injectReducer(store, { key: 'responsiveScreen', reducer: responsiveScreenReducer });
	injectReducer(store, { key: 'hrSetup', reducer: hrSetupReducer });
	injectSaga(store, { key: 'counter', saga: counterSaga });
	injectReducer(store, { key: 'counter', reducer: counterReducer });
	injectSaga(store, { key: 'hrSetup', saga: hrSaga });
	injectReducer(store, { key: 'auth', reducer: mfaReducer });
	injectSaga(store, { key: 'auth', saga: mfaSaga });
	injectReducer(store, { key: 'unemploymentReducer', reducer: unemploymentReducer });
	injectSaga(store, { key: 'unemploymentReducer', saga: unemploymentSaga });
	injectReducer(store, { key: 'employees', reducer: employeeVerificationReducer });
	injectSaga(store, { key: 'employees', saga: employeeVerificationSaga });
};

export default injectReducers;
