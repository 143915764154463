import Actions from './per-employee-action';
export const initState = {
	isPerEmployeeLoading: false,
	perEmployeeDetails: [],
	perEmployeeError: '',
};

const salaryPerEmployee = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_PER_EMPLOYEE_START: {
			return {
				...state,
				isPerEmployeeLoading: true,
			};
		}
		case Actions.types.GET_PER_EMPLOYEE_SUCCESS: {
			return {
				...state,
				isPerEmployeeLoading: false,
				perEmployeeDetails: action.response,
			};
		}
		case Actions.types.GET_PER_EMPLOYEE_FAIL: {
			return {
				...state,
				isPerEmployeeLoading: false,
				perEmployeeDetails: [],
				perEmployeeError: action.error,
			};
		}
		default:
			return state;
	}
};
export default salaryPerEmployee;
