import { put, takeEvery, call } from 'redux-saga/effects';
import Axios from 'axios';
import Actions from '../action';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';

export function* usersSetup(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetup/${corporateId}/company-setting-status`;
		const res = yield call(Axios.get, uri);
		const uri1 = `${DOMAIN_URL}/CorporateSetup/${corporateId}/employee-payroll-status`;
		const res1 = yield call(Axios.get, uri1);
		const uri2 = `${DOMAIN_URL}/CorporateSetup/${corporateId}/document-status`;
		const res2 = yield call(Axios.get, uri2);
		const uri3 = `${DOMAIN_URL}/CorporateSetup/${corporateId}/leave-workflow-status`;
		const res3 = yield call(Axios.get, uri3);
		const response = {
			comp_set: res.data,
			emp_pay: res1.data,
			doc: res2.data,
			leave: res3.data,
		};
		yield put(Actions.creators.getSetupSuccess(response));
	} catch (err) {
		yield put(Actions.creators.getSetupFailed(err));
	}
}
export function* setupStatusUpdateServices() {
	const baseUrl = `${DOMAIN_URL}/SetupStatus`;
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const request = {
		state: true,
	};
	try {
		const uri = `${baseUrl}/${corporateId}`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.setupStatusUpdateSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.setupStatusUpdateFail(err));
	}
}
export default function* appWatcher() {
	yield takeEvery(Actions.types.GET_SETUP, usersSetup);
	yield takeEvery(Actions.types.SETUP_STATUS_UPDATE_START, setupStatusUpdateServices);
}
