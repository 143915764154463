import React, { useEffect } from 'react';
import './toast.scss';

const Toast = ({
	color = '#D6EDFC',
	children,
	size = '38rem',
	textcolor = '#484B52',
	place = 'top',
	icon,
	visible = false,
	alignMent = 'center',
	clearPopUp = () => {},
}) => {
	useEffect(() => {
		if (visible) {
			const timer = setTimeout(() => {
				clearPopUp();
				clearTimeout(timer);
			}, 2500);
		}
	}, [visible, clearPopUp]);

	return (
		<div
			className={`status-toast-wrapper ${visible ? 'show' : 'hide'} success`}
			// className={`toat-outer ${visible ? ' fadeinOut ' : ' display-none'}`}
			// style={{
			// 	justifyContent: alignMent,
			// }}
		>
			{/* <div className={`toast ${place}`} style={{ background: color, width: size, color: textcolor }}> */}
			{icon}
			{children}
			{/* </div> */}
		</div>
	);
};

export default Toast;
