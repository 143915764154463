const initialState = {
	screen: null,
	width: null,
	height: null,
};

export default function responsiveScreenReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_RESPONSIVE_SCREEN':
			return {
				...state,
				screen: action.payload.screen,
				width: action.payload.width,
				height: action.payload.height,
			};
		default:
			return state;
	}
}
