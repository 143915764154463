import React from 'react';
import ETypo from '../../Typo';
import EdenredButtons from '../EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import deleteIcon from '../../Dragger/DraggerIcons/Deleteicon.png';
const LinkButtons = () => {
	return (
		<div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Link Button With No Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'link',
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					Save
				</EdenredButtons>
			</div>

			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Link Button With Left Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'link',
						leftIcon: <ImageComponent className="leftIcon1" src={deleteIcon} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					Save
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Link Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'link',
						rightIcon: <ImageComponent className="rightIcon1" src={deleteIcon} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					Save
				</EdenredButtons>
			</div>
		</div>
	);
};

export default LinkButtons;
