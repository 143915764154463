import React, { useEffect, useState } from 'react';
import GaugeChart from './gauge-chart';
import { Card, Col, Row } from 'antd';
import { DotLoader } from 'react-spinners';

const GaugeCounts = (props) => {
	let [employeeCount, setEmployeeCount] = useState({});

	useEffect(() => {
		if (props.employeeCount) {
			setEmployeeCount(props.employeeCount);
		}
	}, [props.employeeCount]);

	const { c3AppUsersCount, c3CardHoldersCount, totalEmployeesCount, bankAccountsCount } = employeeCount;
	return (
		<Card className="gauge_chart_card">
			{props.isEmployeeCountLoading === true ? (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			) : (
				<Row style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					{/* <Col xl={6} lg={6} md={6}>
							<GaugeChart
								filledArcColor={'#dc4639'}
								value={totalEmployeesCount ? (totalEmployeesCount / totalEmployeesCount) * 100 : 0}
								label={totalEmployeesCount ? totalEmployeesCount : 0}
								title={'Total Employees'}
							/>
						</Col> */}
					<Col xl={7} lg={8} md={8} sm={8}>
						<GaugeChart
							filledArcColor={'#FF9691'}
							value={c3CardHoldersCount ? (c3CardHoldersCount / totalEmployeesCount) * 100 : 0}
							label={c3CardHoldersCount ? c3CardHoldersCount : 0}
							title={'C3Pay Cardholders'}
						/>
					</Col>
					<Col xl={7} lg={8} md={8} sm={8}>
						<GaugeChart
							filledArcColor={'#0E8AFF'}
							value={totalEmployeesCount ? (c3AppUsersCount / totalEmployeesCount) * 100 : 0}
							label={c3AppUsersCount ? c3AppUsersCount : 0}
							title={'C3Pay App Users'}
						/>
					</Col>
					<Col xl={7} lg={8} md={8} sm={8}>
						<GaugeChart
							filledArcColor={'#FFDC91'}
							value={bankAccountsCount ? (bankAccountsCount / totalEmployeesCount) * 100 : 0}
							label={bankAccountsCount ? bankAccountsCount : 0}
							title={'Bank Accounts'}
						/>
					</Col>

					<Col xl={24} lg={24} md={24} sm={24} style={{ textAlign: 'center' }}>
						<p className="total_employees_text">
							Total Employees: <span> {totalEmployeesCount ? totalEmployeesCount : 0} </span>
						</p>
					</Col>
				</Row>
			)}
		</Card>
	);
};

export default React.memo(GaugeCounts);
