import { createSelector } from 'reselect';

import { initState } from './reducer';

const employeePayrollReducer = (state) => state.employeePayrollReducer || initState;

const isEmployeeTemplateLoading = createSelector(employeePayrollReducer, (cnter) => cnter.isEmployeeTemplateLoading);
const employeeTemplateDetail = createSelector(employeePayrollReducer, (cnter) => cnter.employeeTemplateDetail);
const isEmployeeUploadLoading = createSelector(employeePayrollReducer, (cnter) => cnter.isEmployeeUploadLoading);
const EmployeeUploadDetails = createSelector(employeePayrollReducer, (cnter) => cnter.EmployeeUploadDetails);
const isPayrollUploaded = createSelector(employeePayrollReducer, (cnter) => cnter.isPayrollUploaded);
const isEmployeePayrollLoading = createSelector(employeePayrollReducer, (cnter) => cnter.isEmployeePayrollLoading);
const employeePayrollDetails = createSelector(employeePayrollReducer, (cnter) => cnter.employeePayrollDetails);

export default {
	employeePayrollReducer,
	isEmployeeTemplateLoading,
	employeeTemplateDetail,
	isEmployeeUploadLoading,
	EmployeeUploadDetails,
	isPayrollUploaded,
	isEmployeePayrollLoading,
	employeePayrollDetails,
};
