import React from 'react';
import UploadPayrollDocumentation from './UploadPayrollDocumentation';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import './upload-payroll-details.scss';
const UploadPayrollDetails = () => {
	return (
		<div className="upload-payroll-details-container">
			<EdenRedHeader />
			<UploadPayrollDocumentation />
		</div>
	);
};

export default UploadPayrollDetails;
