// export const appConstant = {
// 	domain: 'https://eae-c3portal-web-d.azurewebsites.net/api/v1',
// };
const VERSION = process.env.REACT_APP_API_VERSION ? process.env.REACT_APP_API_VERSION : 1;
export const appConstant = {
	domain: `${process.env.REACT_APP_API_URL}/v${VERSION}`,
};

export const accountTypes = ['C3', 'Bank'];
export const accountTypeConst = {
	C3pay: 'C3',
	Bank: 'Bank',
};
export const salaryChannels = [
	{
		channel: 'WPS',
		value: 'Active',
	},
	{
		channel: 'Creating',
		value: 'Creating',
	},
	{
		channel: 'Pending',
		value: 'Pending',
	},
	{
		channel: 'Deleted',
		value: 'Deleted',
	},
];
export const salaryChannelConst = {
	Active: 'Active',
	Creating: 'Creating',
	Pending: 'Pending',
	Deleted: 'Deleted',
};

export const apiUrl = {
	//ROLES
	GET_USER_ROLE: '/roles',

	//USER
	GET_ALL_USER: '/Users',
	CREATE_USER: '/Users/',
	CORPORATE_ID: '/Corporates',
	GET_PROFILE: '/Users/me',

	//CLAIMS
	GET_CLAIMS: '/Roles/',

	//SUBSCRIPTION
	SUBSCRIPTION_URL: '/Subscription',
};

export const roles = {
	USER: 'User',
	VERIFIER: 'Verifier',
};

export const userClaims = {
	MANAGE_PAYROLL: 'Manage.Payroll',
	MANAGE_EMPLOYEE: 'Manage.Employees',
	MANAGE_ROLE: 'Manage.Roles.And.Permissions',
};

export const hrSubscription = {
	MANAGE_HR: 'HR',
};

export const TeamsClaim = {
	MANAGE_ROLE: userClaims?.MANAGE_ROLE,
};
export const PayrollClaims = {
	MANAGE_PAYROLL: userClaims?.MANAGE_PAYROLL,
	APPROVE_PAYROLL_REQUEST: 'Approve.Payroll.Requests',
	MANAGE_EMPLOYEE: userClaims?.MANAGE_EMPLOYEE,
	APPROVE_CARD_ORDERS: 'Approve.Card.Orders',
	APPROVE_CARD_CANCEL: 'Approve.Card.Cancellation',
	APPROVE_CARD_REPLACE: 'Approve.Card.Replacement',
};

export const HrClaims = {
	MANAGE_LEAVES: 'Manage.Leaves',
	APPROVAL_WORKFLOW: 'Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances',
	MANAGE_DOCUMENTS: 'Manage.Documents',
	MANAGE_ANNOUNCEMENTS: 'Manage.Announcements',
};

export const APPROVE_EMPLOYEE_REQUEST = [PayrollClaims?.APPROVE_CARD_ORDERS, PayrollClaims?.APPROVE_CARD_CANCEL, PayrollClaims?.APPROVE_CARD_REPLACE];

export const HrclaimsArray = ['Manage.Leaves', 'Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances', 'Manage.Documents', 'Manage.Announcements'];

export const verifierClaims = {
	APPROVE_PAYROLL_REQUESTS: 'Approve.Payroll.Requests',
	APPROVE_CARD_CANCELLATION: 'Approve.Card.Cancellation',
	APPROVE_CARD_ORDERS: 'Approve.Card.Orders',
	APPROVE_CARD_REPLACEMENT: 'Approve.Card.Replacement',
};

export const verifierClaimsArr = [
	verifierClaims.APPROVE_PAYROLL_REQUESTS,
	verifierClaims.APPROVE_CARD_CANCELLATION,
	verifierClaims.APPROVE_CARD_ORDERS,
	verifierClaims.APPROVE_CARD_REPLACEMENT,
];

export const appMenusRegEx = [
	{
		regEx: '/payroll/status/',
		url: '/payroll/history',
	},
	{
		regEx: '/payroll/rechecking_failed_employee/',
		url: '/payroll/history',
	},
	{
		regEx: '/payroll/failedDetail/',
		url: '/payroll/history',
	},
	{
		regEx: '/action_approve/',
		url: '/',
	},
	// {
	// 	regEx: [''],
	// 	url: '',
	// },
];

export const subMenus = [
	{
		menus: [
			'/run-payroll',
			'/payroll-plus',
			'/payroll/history',
			'/payroll/proof_of_transfer',
			'/payroll/timings',
			'/payroll/bank_details',
			'/salary',
			'/payroll/useful_files',
			'/payroll/approve_requests',
		],
		subMenu: 'sub1',
	},
	{
		menus: ['/leaves/leaves_to_approve', '/leaves/leaves_statuses', '/leaves/request_leave', '/approval-workflow-leave'],
		subMenu: 'sub6',
	},
	{
		menus: ['/company/company-setting', '/manage_team', '/company/information'],
		subMenu: 'sub7',
	},
	{
		menus: ['/employees/manage_employee', '/employees/card/order/status', '/employees/approve_employee_request', '/employees/useful/files'],
		subMenu: 'sub2',
	},
	{
		menus: ['/hrdocuments', '/hrdocuments/uploaddocument', '/hrdocuments/uploaddocument/approved'],
		subMenu: 'sub4',
	},
	{
		menus: ['/hrdocuments', '/hrdocuments/uploaddocument', '/hrdocuments/uploaddocument/approved'],
		subMenu: 'sub4',
	},
	{
		menus: ['/hrdocuments', '/hrdocuments/uploaddocument', '/hrdocuments/uploaddocument/approved'],
		subMenu: 'sub4',
	},
];

export const appMenus = [
	{
		menus: ['/', '/dashboard/profile', '/dashboard/pending-alerts', '/dashboard/transaction-history'],
		url: '/',
	},
	{
		menus: [
			'/run-payroll',
			'/payroll/history',
			'/upload/salary/spreadsheet_or_sif',
			'/payroll/run/channel',
			'/payroll/run/channel/nonwps',
			'/payroll/run/channel/wps',
			'/payroll/run/channel/now_or_later',
			'/payroll/run/channel/now_or_later',
			'/payroll/run/channel/submit_payroll',
			'/payroll/run/channel/next_steps',
		],
		url: '/run-payroll',
	},
	{
		menus: ['/payroll-plus'],
		url: '/payroll-plus',
	},
	{
		menus: ['/payroll/history'],
		url: '/payroll/history',
	},
	{
		menus: ['/payroll/proof_of_transfer'],
		url: '/payroll/proof_of_transfer',
	},
	{
		menus: ['/payroll/approve_requests', '/payroll/wps_table'],
		url: '/payroll/approve_requests',
	},
	{
		menus: ['/payroll/timings'],
		url: '/payroll/timings',
	},
	{
		menus: ['/payroll/bank_details'],
		url: '/payroll/bank_details',
	},
	{
		menus: ['/salary/salary_per_employee', '/salary-history', '/salary-monthwise_reports', '/salary'],
		url: '/salary',
	},
	{
		menus: ['/payroll/useful_files'],
		url: '/payroll/useful_files',
	},
	{
		menus: [
			'/employees/manage_employee',
			'/employees/update_emirate',
			'/employees/edit_profile',
			'employees/edit_profile',
			'/employees/viewemployee',
			'/manange/employees/bulk/delete',
			'/employees/add/multiple/employees/upload',
			'/employees/nextEmployee_step',
			'/employees/replacement_card',
		],
		url: '/employees/manage_employee',
	},
	{
		menus: [
			'/employees/card/order/status',
			'/employees/card/order/track/status',
			'/employees/card/order/view/details',
			'/employees/transaction-detail',
			'/employees/changeBankAccount',
			'/employees/requestStatement',
		],
		url: '/employees/card/order/status',
	},
	{
		menus: ['/employees/approve_employee_request', '/employees/card-request-detail'],
		url: '/employees/approve_employee_request',
	},
	{
		menus: ['/employees/useful/files'],
		url: '/employees/useful/files',
	},
	{
		menus: ['/hrdocuments', '/hrdocuments/uploaddocument', '/hrdocuments/uploaddocument/approved'],
		url: '/hrdocuments',
	},
	{
		menus: ['/announcement', '/announcement/successfull'],
		url: '/announcement',
	},
	{
		menus: [
			'/company/company-setting',
			'/manage_team',
			'/invite_team_member',
			'/invite_team_member_type',
			'/choose_verifier_permission',
			'/choose_user_permission',
			'/company/information',
			'/company/upload-spreadsheet',
		],
		url: '/company/company-setting',
	},
	{
		menus: ['/company/company-setting'],
		url: '/company/company-setting',
	},
	{
		menus: ['/manage_team', '/invite_team_member', '/invite_team_member_type', '/choose_verifier_permission', '/choose_user_permission'],
		url: '/manage_team',
	},
	{
		menus: ['/company/information', '/company/upload-spreadsheet'],
		url: '/company/information',
	},
	{
		menus: ['/healthinsurance'],
		url: '/healthinsurance',
	},
	{
		menus: ['/company/invoices'],
		url: '/company/invoices',
	},
	{
		menus: [
			'/help',
			'/help/labour-law',
			'/help/wps-penalty',
			'/help/wps-rules',
			'/help/employee',
			'/help/invoice',
			'/help/payroll',
			'/help/gratuity-calculate',
			'/help/terminate-employee',
			'/help/leave-calculation',
		],
		url: '/help',
	},
	{
		menus: ['/leaves/leaves_to_approve'],
		url: '/leaves/leaves_to_approve',
	},
	{
		menus: ['/leaves/leaves_statuses'],
		url: '/leaves/leaves_statuses',
	},
	{
		menus: ['/leaves/request_leave'],
		url: '/leaves/request_leave',
	},
	{
		menus: ['/approval-workflow-leave'],
		url: '/approval-workflow-leave',
	},
];

export const FILE_CONSTANTS = {
	FILE_MUST_SMALLER_THAN_4MB: 'File must be smaller than 4MB!',
	PLEASE_UPLOAD_SUPPORTED_FORMATS: 'Please upload supported formats',
};

export const setUpPath = [
	'/setup-landingpage',
	'/setup',
	'/company-setup',
	'/setupDocument',
	'/teamspermission',
	'/setupHrEmployeeList',
	'/setupHrEmployeepayrollList',
	'/leave-approval-table-setup',
	'/setupHrDocument',
	'/leave-approval-setup',
	'/admissionpermission',
	'/teamspermission',
	'/landingpage',
	'/permissionassign/:username',
];
export const monthsNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const ACCOUNT_TYPES = {
	C3: 'C3Pay Cardholders',
	BANK: 'Bank Accounts',
};

export const FAQ_LINK = '/iloe-renewal-faq.html';