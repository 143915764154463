import React from 'react';
import ETypo from '../../Typo';
import EdenredButtons from '../EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';

const PrimaryButtons = () => {
	return (
		<div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '5px', marginBottom: '5px' }}>
					Primary Button With No Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Primary Button With Left Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						leftIcon: <ImageComponent className="leftIcon" src={images.imgupload} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Primary Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						rightIcon: <ImageComponent className="rightIcon" src={images.imgupload} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Primary Button With Double Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						leftIcon: <ImageComponent className="leftIcon" src={images.imgupload} />,
						rightIcon: <ImageComponent className="rightIcon" src={images.imgupload} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Primary Button With No Text
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						rightIcon: <ImageComponent src={images.imgupload} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}></EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Primary Button with No Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						disabled: true,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Primary Button With Left Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						disabled: true,
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Primary Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						disabled: true,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Primary Button With Double Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						disabled: true,
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Primary Button With No Text
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'primary',
						disabled: true,
						rightIcon: <ImageComponent src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}></EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Primary Button With No Icon
				</ETypo>
				<EdenredButtons
					{...{
						inactive: true,
						btnType: 'primary',
						disabled: true,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Primary Button With Left Icon
				</ETypo>
				<EdenredButtons
					{...{
						inactive: true,
						btnType: 'primary',
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Primary Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						inactive: true,
						btnType: 'primary',
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Primary Button With Double Icon
				</ETypo>
				<EdenredButtons
					{...{
						inactive: true,
						btnType: 'primary',
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Primary Button With No Text
				</ETypo>
				<EdenredButtons
					{...{
						inactive: true,
						btnType: 'primary',
						rightIcon: <ImageComponent src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}></EdenredButtons>
			</div>
		</div>
	);
};

export default PrimaryButtons;
