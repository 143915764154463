import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';
import Axios from 'axios';

class EmployeeSalariesService {
	baseUrl = `${DOMAIN_URL}/EmployeeSalaries`;

	async getPayrollHistory(pageNumber = 1, pageSize = 20) {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
	}

	async getEmployeePaidMonthWise() {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/dashboard/salarypaid`);
	}

	async getEmployeePaidByPayrollChannel() {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/dashboard/payrollchannel`);
	}
}

export default EmployeeSalariesService;
