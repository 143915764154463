import './emp-documents.scss';

import { Col, Row, Upload, message } from 'antd';
import React, { useState } from 'react';

import ClevertapReact from 'src/utils/clever-tap';
import EdenredTypo from '@sharedComponent/typography';
import EmpDocumentModal from './emp-document-modal';
import ImageComponent from '@sharedComponent/image-component';
import { dummyRequest } from '@helpers';
import { icons } from 'src/assets/icons';
import ETypo from '@pages/DesignSystem/Typo';

const EmpDragger = ({ emessage, deleteImagesLoading, draggerChange, employeeId, img, getDeleteImages }) => {
	const { Dragger } = Upload;
	const [thumb, setThumb] = useState('');
	const [fileList, setFileList] = useState([]);
	const [drag, setDrag] = useState(false);
	const [beforeUpload, setBeforeUpload] = useState(false);
	const [viewPreviewModal, setViewPreviewModal] = useState(false);
	const [deletePreview, setDeletePreview] = useState(false);
	const val = img?.documentName;
	const openPreviewModal = () => {
		if (emessage === 'Labour Card Page') {
			ClevertapReact.event('Profile_Labour_ViewDoc', null);
		} else if (emessage === 'Emirates ID Front') {
			ClevertapReact.event('Profile_EID_ViewDocFront', null);
		} else if (emessage === 'Emirates ID Back') {
			ClevertapReact.event('Profile_EID_ViewDocBack', null);
		} else if (emessage === 'Visa Page') {
			ClevertapReact.event('Profile_Visa_ViewDoc', null);
		} else if (emessage === 'Passport Page') {
			ClevertapReact.event('Profile_Passport_ViewDoc', null);
		}
		setViewPreviewModal(true);
	};
	const closePreviewModal = () => {
		setViewPreviewModal(false);
	};
	const handleOpenDeleteModal = () => {
		setDeletePreview(true);
	};
	const deleteCleverTapEvents = () => {
		if (emessage === 'Labour Card Page') {
			ClevertapReact.event('Profile_Labour_DeleteDoc', null);
		} else if (emessage === 'Emirates ID Front') {
			ClevertapReact.event('Profile_EID_DeleteDocFront', null);
		} else if (emessage === 'Emirates ID Back') {
			ClevertapReact.event('Profile_EID_DocBack', null);
		} else if (emessage === 'Visa Page') {
			ClevertapReact.event('Profile_Visa_DeleteDoc', null);
		} else if (emessage === 'Passport Page') {
			ClevertapReact.event('Profile_Passport_DeleteDoc', null);
		}
	};
	const handleCloseDelete = () => {
		setDeletePreview(false);
	};
	const handleCloseDeleteModal = (value = '', type) => {
		setBeforeUpload(false);
		setDrag(false);
		if (fileList) {
			setFileList([]);
			draggerChange(value, type);
			setDeletePreview(false);
			setViewPreviewModal(false);
		}
		if (fileList.length === 0) {
			img && img.documentId && getDeleteImages(img.documentId, employeeId);
			img.documentId = null;
			img.documentUrl = null;
			setDeletePreview(true);
		}

		setThumb('');
	};
	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
	const onChange = async (info, type) => {
		if (info.file.status === 'uploading' && !beforeUpload) {
			info.file.status = 'done';
			setFileList([info.file]);
			setDrag(true);
			if (!info.file.url && !info.file.preview) {
				info.file.preview = await getBase64(info.file.originFileObj);
			}
			draggerChange(info.file.originFileObj, type);
		}
		setThumb(info.file.preview);
	};

	const setCleverTapEvents = (val) => {
		if (val === 'Labour Card Page') {
			ClevertapReact.event('Profile_Labour_EditDoc', null);
		} else if (val === 'Emirates ID Front') {
			ClevertapReact.event('Profile_EID_EditDocFront', null);
		} else if (val === 'Emirates ID Back') {
			ClevertapReact.event('Profile_EID_EditDocBack', null);
		} else if (val === 'Visa Page') {
			ClevertapReact.event('Profile_Visa_EditDoc', null);
		} else if (val === 'Passport Page') {
			ClevertapReact.event('Profile_Passport_EditDoc', null);
		}
	};
	const allowedFileTypes = ['image/jpg', 'image/JPG', 'image/jpeg', 'image/JPEG', 'image/png', 'image/PNG', 'application/PDF', 'application/pdf'];
	const props = {
		accept: '.jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF',
		onClick: () => setCleverTapEvents(emessage),
		beforeUpload(file) {
			if (allowedFileTypes.indexOf(file.type) >= 0) {
				const isLt4M = file.size / 1024 / 1024 < 4;
				if (!isLt4M) {
					message.error('File must be smaller than 4MB!');
					setBeforeUpload(true);
					setDrag(false);
				}
				setBeforeUpload(false);
				return isLt4M;
			} else {
				message.error('Please upload supported formats');
				setFileList([]);
				setBeforeUpload(true);
				setDrag(false);

				return false;
			}
		},
	};

	const EmpDocumentModalPreview = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'relative',
			top: '0px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'auto',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			width: '415px',
			minWidth: '415px',
			boxShadow: 'none',
		},
	};

	return (
		<>
			{drag ? (
				<>
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="previewContainer">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							{fileList[0].type === 'application/pdf' ? (
								<div className="thumbnailPdf" style={{ display: 'flex', justifyContent: 'center' }}>
									<ImageComponent src={icons.focus_state.PDF} style={{ height: '140px' }} />
								</div>
							) : (
								<img src={thumb} className="thumbnail" />
							)}
						</Col>
						<Row className="buttonRow">
							<Col className="buttonContainer">
								<div className="buttonImage">
									<ImageComponent src={icons.focus_state.documentView} onClick={openPreviewModal} />
								</div>
								<Upload
									{...props}
									onChange={(e) => onChange(e, emessage)}
									customRequest={dummyRequest}
									fileList={fileList}
									className="empDraggerButton">
									<div className="buttonImage">
										<ImageComponent src={icons.focus_state.editDocument} />
									</div>
								</Upload>
								<div className="buttonImage">
									<ImageComponent src={icons.focus_state.NewDelete} onClick={handleOpenDeleteModal} />
								</div>
							</Col>
						</Row>
					</Col>
				</>
			) : img?.documentUrl ? (
				<>
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="previewContainer">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							{val.substr(val.lastIndexOf('.') + 1) === 'pdf' ? (
								<div className="thumbnailPdf" style={{ display: 'flex', justifyContent: 'center' }}>
									<ImageComponent src={icons.focus_state.PDF} style={{ height: '140px' }} />
								</div>
							) : (
								<img src={img?.documentUrl} className="thumbnail" />
							)}
						</Col>
						<Row className="buttonRow">
							<Col className="buttonContainer">
								<div className="buttonImage">
									<ImageComponent src={icons.focus_state.documentView} onClick={openPreviewModal} />
								</div>
								<Upload
									{...props}
									onChange={(e) => onChange(e, emessage)}
									customRequest={dummyRequest}
									fileList={fileList}
									className="empDraggerButton">
									<div className="buttonImage">
										<ImageComponent src={icons.focus_state.editDocument} />
									</div>
								</Upload>
								<div className="buttonImage">
									<ImageComponent src={icons.focus_state.NewDelete} onClick={handleOpenDeleteModal} />
								</div>
							</Col>
						</Row>
					</Col>
				</>
			) : (
				<Dragger
					{...props}
					onChange={(e) => onChange(e, emessage)}
					customRequest={dummyRequest}
					fileList={fileList}
					className="draggerdoccontainer">
					<Col xl={24} md={24} lg={24} sm={24} xs={24}>
						<ImageComponent src={icons.focus_state.hrUpload} />
					</Col>
					<Col xl={24} md={24} className="whole">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<ETypo medium b2>
								Drag and drop
							</ETypo>
						</Col>
						<Col xl={24} md={24} lg={24} sm={24} xs={24} style={{ marginTop: '-5px' }}>
							<ETypo medium b2>
								Or click here
							</ETypo>
						</Col>
					</Col>
				</Dragger>
			)}
			<EmpDocumentModal
				viewPreviewModal={viewPreviewModal}
				openPreviewModal={openPreviewModal}
				closePreviewModal={closePreviewModal}
				customStyles={EmpDocumentModalPreview}
				url={thumb ? null : img?.documentUrl}
				blob={thumb ? thumb : null}
				img={img}
				deleteCleverTapEvents={deleteCleverTapEvents}
				value={val?.substr(val?.lastIndexOf('.') + 1)}
				deleteImagesLoading={deleteImagesLoading}
				handleOpenDeleteModal={handleOpenDeleteModal}
				handleCloseDeleteModal={handleCloseDeleteModal}
				handleCloseDelete={handleCloseDelete}
				deletePreview={deletePreview}
				emessage={emessage}
				buttonModalStyle={{
					display: 'none',
				}}
			/>
		</>
	);
};
export default EmpDragger;
