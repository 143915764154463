import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { icons } from 'src/assets/icons';
import UserService from 'src/services/user-service';
import appActions from '@sharedComponent/app-action-reducer/action';
import ClevertapReact from 'src/utils/clever-tap';

const EwaPopup = () => {
	const [postUSerNavLoading, setPostUSerNavLoading] = useState(false);
	const MenuAPI = new UserService();
	const dispatch = useDispatch();

	let { showEwaPopup } = useSelector((state) => state?.sharedStates);
	let userNavPayload = useSelector((state) => state?.sharedStates?.userNavigation);

	/**
	 * Click handler for okay button in popup
	 */
	const ewaOkClickHandler = () => {
		let payload = {
			...userNavPayload,
			ewaPopupDone: true,
		};
		setPostUSerNavLoading(true);
		MenuAPI.postUserNavigationMenu(payload)
			.then((res) => {
				ClevertapReact.event('Ok_EWA_T&C');
				setPostUSerNavLoading(false);
				dispatch(appActions.creators.setShowEwaPopup(false));
				dispatch(appActions.creators.getUserNavigation());
			})
			.catch((error) => {
				setPostUSerNavLoading(false);
				dispatch(appActions.creators.setShowEwaPopup(false));
			});
	};

	return (
		<Edenredmodal
			{...{
				className: 'new-announcement-save-popup',
				isOpen: showEwaPopup,
				onOkayButton: 'Ok',
				loading: postUSerNavLoading,
				onOkay: () => ewaOkClickHandler(),
				linkname: <span style={{ fontWeight: 700 }}>Terms & Conditions</span>,
				href: '/ewa-terms-and-conditions',
				hrefOnClick: () => {
					ClevertapReact.event('View_EWA_T&C');
				},
				target: '_blank',
				topImage: <ImageComponent src={icons.focus_state.landingPopup}></ImageComponent>,
				title: 'Your Employees Can Now Access Their Salaries Early on C3Pay!',
				desc: (
					<>
						<div className="title-row1" style={{ fontWeight: 700 }}>
							C3Pay cardholders can withdraw salaries at any time of the month for the days worked
						</div>
						<small>
							Reach out to <span style={{ fontWeight: 700 }}>support.ae@edenred.com</span> for queries
						</small>
					</>
				),
			}}
		/>
	);
};

export default EwaPopup;
