import Action from './action';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import React, { useState } from 'react';
import SetupTitle from '@pages/NewHrModuleSetup/Component/SetupTitle/SetupTitle';
import Step from '@pages/NewHrModuleSetup/Component/Step/Step';
import Stepper from '@pages/NewHrModuleSetup/Component/Stepper/Stepper';
import setupAction from 'src/components/shared/app-action-reducer/action';
import ToastAction from '@pages/DesignSystem/StatusToast/actions';
import UploadDocument from '@pages/NewHrModuleSetup/UploadPayrollDetails/UploadDocument';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import './uploadLeave.scss';

const UploadLeave = ({ navigate = '/leave/setup-approval-workflow', CleverTap = true }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const uploadData = useSelector((state) => state?.hrSetup?.leaves?.uploadDocument || '');
	const downloadTemplate = useSelector((state) => state?.hrSetup?.leaves?.downloadTemplate || '');
	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const [draggerStatus, setDraggerStatus] = useState(true);
	const [showModal, setShowaModal] = useState(false);

	const handleUploadChange = (data) => {
		dispatch(Action.creators.postUploadLeave(data));
		setDraggerStatus(false);
	};
	const handleRemove = () => {
		setDraggerStatus(true);
		dispatch(Action.creators.putUploadLeaveReset());
	};
	const handleSubmit = () => {
		ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStartedUploadData_Next' : 'V1_1_Leave_DepartUploadData_Next');
		dispatch(Action.creators.setFileUploadStatus(true));
		dispatch(Action.creators.submitUploadLeave(userName));
		dispatch(setupAction.creators.postHrSetupStatus({ hrLeaveCompleted: true }));
		dispatch(ToastAction.creators.putSuccessStatusToast({ message: 'Congrats! Your employees’ leave details have been added!' }));
		history.push(navigate);
	};
	const stepperItems = [
		{
			name: '1 - Departments',
			isActive: true,
			isCompleted: true,
			navigate: !CleverTap ? '/new-hr-features/departments' : '/leave/departments',
		},
		{
			name: '2 - Upload Data',
			isActive: true,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/upload-leave' : '/leave/upload-leave',
		},
		{
			name: '3 - How To Set Up Approval Workflow',
			isActive: false,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/setup-approval-workflow' : '/leave/setup-approval-workflow',
		},
		{
			name: '4 - How To Invite Employees',
			isActive: false,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/invite-employees' : '/leave/invite-employees',
		},
	];
	const steps = [
		{
			stepNo: 1,
			iconSrc: focus_state_icons?.ClipboardText,
			descriptionText: `Prepare your employees’ leave details.`,
		},
		{
			stepNo: 2,
			iconSrc: focus_state_icons?.documentDownload,
			descriptionText: 'Download your new documents Excel template.',
			btnType: 'secondary',
			btnText: 'Download template',
		},
	];
	const buttonStatus = () => {
		// handling submit button state
		if (draggerStatus === true) {
			return 'secondary';
		} else if (uploadData?.data?.validationMessage?.length > 0) {
			return 'secondary';
		} else if (uploadData?.loading) {
			return 'secondary';
		} else if (uploadData?.error) {
			return 'secondary';
		} else {
			return 'primary';
		}
	};
	return (
		<>
			<div className="containers">
				<div className="title-box">
					<SetupTitle
						title={`Add leave details`}
						rightBtnType={buttonStatus()}
						rightBtnDisabled={buttonStatus() == 'secondary'}
						rightBtnText={'Save & Finish'}
						rightBtnClick={handleSubmit}
						leftBtnType="secondary"
						leftBtnText={'Do It Later'}
						leftBtnClick={() => {
							ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStartedupload_later' : 'V1_1_Leave_Departupload_later');
							setShowaModal(true);
						}}
						leftBtnRightIcon={focus_state_icons.RightClrArrow}
					/>
				</div>
				<div className="content-wrapper">
					<Stepper listOfTabs={stepperItems} />
					<div className="main-content">
						<div className="steps-wrapper">
							{steps.map((item) => (
								<Step
									stepNo={item?.stepNo}
									iconSrc={item?.iconSrc}
									descriptionText={item?.descriptionText}
									btnType={item?.btnType}
									btnText={item?.btnText}
									isBtnLoading={downloadTemplate?.loading}
									className="step-description-style"
									btnOnClick={() => dispatch(Action.creators.getDownloadLeaveTemplate())}
								/>
							))}
							<div className="step-3-container">
								<span>
									<ETypo H5>Step 3:</ETypo>
								</span>
								<UploadDocument
									uploadData={uploadData?.data?.validationMessage || uploadData?.error || ''}
									uploadingStatus={uploadData?.loading || ''}
									onChange={(data) => handleUploadChange(data)}
									draggerStatus={draggerStatus}
									onRemove={handleRemove}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Edenredmodal
				title="You didn’t add employees’ details"
				desc="You're missing out on alerts before documents expire."
				onCancelButton="Skip For Now"
				onOkay={() => {
					ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStartedupload_PopAdd' : 'V1_1_Leave_Departupload_PopAdd');
					setShowaModal(false);
				}}
				onOkayButton="Add Now"
				onCancel={() => {
					ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStartedupload_PopSkip' : 'V1_1_Leave_Departupload_PopSkip');
					dispatch(Action.creators.setFileUploadStatus(false));
					history.push(navigate);
				}}
				isOpen={showModal}
				heart={true}
				imageSrc={focus_state_icons.warningNew}
			/>
		</>
	);
};

export default UploadLeave;
