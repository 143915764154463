import React from 'react';
import { Menu } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButton from '@sharedComponent/button';
import { icons } from 'src/assets/icons';

function FilterContents({ options = [], handleChange, handleSelectAll, name = '' }) {
	return (
		<Menu className="run-payroll-dropdownMenus">
			<EdenredButton type="outline-g" className="run-payroll-new-dropdown" onClick={() => handleSelectAll(name)}>
				Select all
			</EdenredButton>
			{(function () {
				return options?.map(
					(option, index) =>
						(option?.payrollChannelName || option?.accountTypeName || option?.establishmentId || option?.bankName) && (
							<Menu.Item key={index}>
								<EdenredButton
									type="outline-g"
									className="run-payroll-new-dropdown"
									onClick={() =>
										handleChange(
											name,
											option?.payrollChannelName || option?.accountTypeName || option?.establishmentId || option?.bankName
										)
									}>
									<ImageComponent
										className="run-payroll-new-dropdownIcon"
										src={option?.isSelected ? icons.focus_state.Check : icons.not_focus.EmptyBox}
									/>
									{option?.payrollChannelName || option?.accountTypeName || option?.establishmentId || option?.bankName || ''}
								</EdenredButton>
							</Menu.Item>
						)
				);
			})()}
		</Menu>
	);
}

export default FilterContents;
