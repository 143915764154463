import React from 'react';
import { UN_EMPLOYMENT_INSURANCE_CONSTANTS } from '@constants/app-constants';

export const INSURANCE_PLAN_CONSTANTS = {
	headerText: 'Insurance Plan Details',
	invoicePlan: UN_EMPLOYMENT_INSURANCE_CONSTANTS.insurancePlan,
	policyCoverage: <span>Policy Coverage:&nbsp;</span>,
	planType: <span>2 Years(paid in full)</span>,
	planContents: [
		{
			id: 1,
			label: 'Premium:',
			value: 'AED 120',
		},
		{
			id: 2,
			label: 'VAT:',
			value: 'AED 6',
		},
		{
			id: 3,
			label: 'Transaction Fee:',
			value: 'AED 14',
		},
		{
			id: 4,
			label: 'Total:',
			value: 'AED 140',
		},
	],
	helperBannerText: (
		<span>
			As per Dubai Insurance mandates, the new subscriptions are only allowed for two years, paid in full.
			<br />
			For subscribed employees, the Policy will be auto-renewed for one year, paid in full.
		</span>
	),
};
