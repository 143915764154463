import React from 'react';
import { useSelector } from 'react-redux';
import ManageEmployeeFunctional from './components/manage-employee-functional';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import './manage-employee.scss';
import './manage-employee-mobile.scss';

const AuthorizedAccess = UnauthorizedAccess(ManageEmployeeFunctional);

const ManageEmployee = () => {
	let currentClaims = useSelector((state) => state?.sharedStates?.currentUser?.claims);
	return <AuthorizedAccess {...{ currentClaims, UserClaims: 'Manage.Employees' }} />;
};

export default ManageEmployee;
