import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { images } from '@assets/img/index';
import SharedServices, { GET_PROFILE, SET_LOGIN_STEP } from '@sharedComponent/app-action-reducer/services';
import Actions from '@sharedComponent/app-action-reducer/action';
import SliderPresentational from './slider-presentational';
import { SITEMAP } from 'src/routes/sitemap';

const contents = [
	{
		image: images.slidePad,
		title: 'Payroll Simplified',
		desc: 'Easily process salaries - we’ll ensure your employees will be paid quickly!',
	},
	{
		image: images.slideCard,
		title: 'Order Cards Easily',
		desc: 'Employee records, account numbers and live card tracking all at your fingertips.',
	},
	{
		image: images.slideTime,
		title: 'Real-Time Reporting',
		desc: 'Peace-of-mind payroll tracking with detailed reports.',
	},
	{
		image: images.slideEmail,
		title: 'Alerts To Your Inbox',
		desc: 'Receive email notifications at each stage of your payroll run.',
	},
];

const SliderFunctional = ({ currentUser: { roles }, setUser }) => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const { state } = useLocation() || {};

	const api = new SharedServices();
	const role = roles && roles[0].name;
	const nextClicked = async () => {
		setLoading(true);
		await api
			.services(SET_LOGIN_STEP, {
				step: 2,
			})
			.then(() => {
				api.services(GET_PROFILE)
					.then((res) => {
						setUser(res?.data);
						setLoading(false);
						history.push({
							pathname: SITEMAP.user.approvePage,
							state: {
								user: state?.user,
							},
						});
					})
					.catch((err) => {});
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {}, []);
	return <SliderPresentational loading={loading} nextClicked={nextClicked} contents={contents} />;
};
function mapStateToProps({ oidc: { user }, sharedStates: { currentUser } }) {
	return { user, currentUser };
}
const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (roles) => dispatch(Actions.creators.setUser(roles)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderFunctional);
