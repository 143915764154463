import Axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';

export function* leaveToApproveService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/review`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getLeaveToApproveSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getLeaveToApproveFail(err));
	}
}

export function* overLappingLeavesService(post) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const date = post?.post;
	const request = {
		departmentId: [date?.id] || [],
		status: date?.status || '',
		startDate: date?.startDate || '',
		endDate: date?.enddate || '',
	};
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/overlapping`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.getOverLappingLeavesSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getOverLappingLeavesFail(err));
	}
}

export function* approveRejectService(Data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const data = Data?.Data;
	const employeeId = data?.empolyeeId;
	const request = {
		leaveId: data?.leaveId || '',
		status: data?.status || '',
		managerId: data?.managerId || '',
		umrahLeaveFlag: data?.umrahLeaveFlag || '',
	};
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/${employeeId}/approve`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.getApproveSuccess(res?.data));
		yield put(Actions.creators.getLeaveToApproveStart());
	} catch (err) {
		yield put(Actions.creators.getApproveFail(err));
	}
}

export default function* leavetoApproveWatcher() {
	yield takeEvery(Actions.types.GET_LEAVETOAPPROVE_START, leaveToApproveService);
	yield takeEvery(Actions.types.GET_OVERLAPPING_LEAVES_START, overLappingLeavesService);
	yield takeEvery(Actions.types.GET_APPROVEREJECT_START, approveRejectService);
}
