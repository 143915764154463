import React from 'react';
import { Row, Col } from 'antd';
import { Data, Constants } from './mock';
import EdenredButton from '@sharedComponent/button';
import SetupHeader from '../../setup-Screen/components/setup-header';
import './landing.scss';

const LandingPresentational = ({ setupscreen }) => {
	return (
		<>
			<SetupHeader />
			<div className="landing_parent">
				<section className="landing">
					{/* <img className='landing_logo' src={images?.logo} /> */}
					{Constants?.map((constant) => (
						<Col className="landing_header">
							<h1 className="landing_header_text">
								{constant?.mainText}
								<span>{constant?.mainTextSub}</span>
							</h1>
							<span className="landing_header_subtext">{constant?.subText}</span>
						</Col>
					))}
					<Row className="landing_cards">
						{Data?.map((item, i) => (
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<Row className="landing_cards_container">
									<Col xs={24} sm={14} md={14} lg={14} xl={14}>
										<img className={`landing_cards_img ${item?.className}`} src={item?.imgUrl} />
									</Col>
									<Col xs={24} sm={10} md={10} lg={10} xl={10}>
										<h2 className="landing_cards_desc">{item?.desc}</h2>
									</Col>
								</Row>
							</Col>
						))}
					</Row>
					<Row>
						<Col xl={4} lg={4} className="setupBtn_container">
							<EdenredButton className="setupBtn" onClick={setupscreen}>
								Start Setup
							</EdenredButton>
						</Col>
					</Row>
				</section>
			</div>
		</>
	);
};

export default LandingPresentational;
