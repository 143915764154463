import { message } from "antd";
import Axios from "axios";
import { APP_VARIABLES, DOMAIN_URL } from "@constants/app-constants";
import { call, put, takeEvery } from 'redux-saga/effects';
import { getLocalvariable } from "@helpers";
import employeeVerifyActions from "../employeeVerifyActions";

export function* employeeVerification() {
    const baseUrl = `${DOMAIN_URL}/employee-verification/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
    try {
        const url = `${baseUrl}/status`;
        const response = yield call(Axios.get, url)
        yield put(employeeVerifyActions.creators.addemployeeVerify(response.data))
    } catch (error) {
        console.error({ error })
    }
}

export function* downloadEmployeeList() {
    try {
        const baseUrl = `${DOMAIN_URL}/employee-verification/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
        const uri = `${baseUrl}/report/download`;
        const res = yield call(Axios.get, uri, {
            responseType: 'blob',
            headers: {
                Accept: 'application/xlsx',
            },
        });

        const isTextType = 'text/plain';
        if (res.data.type !== isTextType) {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {}));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `InvalidEmployeesList.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    } catch (error) {
        console.error({ error }, 'File download error')
        message.error('Something went wrong while downloading file')
    }
}

export function* uploadEmployeeList(body) {
    try {
        yield put(employeeVerifyActions.creators.uploadEmployeesLoading(true))
        const baseUrl = `${DOMAIN_URL}/employee-verification/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
        const formData = new FormData()
        formData.append('file', body?.payload)
        const uri = `${baseUrl}/report/upload`;
        const res = yield call(Axios.post, uri, formData);
        yield put(employeeVerifyActions.creators.uploadEmployeesLoading(false))
        const { isValid, records, validationErrors } = res.data || {};
        if (isValid) {
            if (records?.length) {
                yield put(employeeVerifyActions.creators.addEmployeeList(records));
            } else if (!records?.length) {
                message.warn('There was no data found to update or those employees are already under review');
            }
        } else if (!isValid) {
            if (validationErrors?.length) {
                yield put(employeeVerifyActions.creators.addEmployeeErrorList(validationErrors))
            }
        }
    } catch (error) {
        console.log({ error })
        yield put(employeeVerifyActions.creators.uploadEmployeesLoading(false))
    }
}


export function* watcher() {
    yield takeEvery(employeeVerifyActions.types.EMPLOYEE_VERIFICATION, employeeVerification);
    yield takeEvery(employeeVerifyActions.types.DOWNLOAD_INVALID_EMPLOYEES, downloadEmployeeList);
    yield takeEvery(employeeVerifyActions.types.UPLOAD_INVALID_EMPLOYEES, uploadEmployeeList);
}
