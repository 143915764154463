import { createSelector } from 'reselect';
import { initState } from './reducer';

const paySlipReducer = (state) => state.paySlipReducer || initState;
const paySlipData = createSelector(paySlipReducer, (cnter) => cnter.paySlipData);
const paySlipDownload = createSelector(paySlipReducer, (cnter) => cnter.paySlipDownload);

export default {
	paySlipReducer,
	paySlipData,
	paySlipDownload,
};
