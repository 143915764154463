import React, { useState } from 'react';
import { Table, Radio, Divider } from 'antd';
import './table.scss';

const EdenredTable = ({ dataSource, columns, onRow,scroll={ y: 440 }, className, pagination, ...restProps }) => {
	return (
		<Table
			className={className}
			columns={columns}
			dataSource={dataSource}
			pagination={pagination}
			scroll={scroll}
			onRow={onRow}
			{...restProps}
		/>
	);
};
export default EdenredTable;
