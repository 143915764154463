import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';
import '../replacement-card.scss';
import EdenredButton from '@sharedComponent/button';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

const EmployeeDocument = ({ history }) => {
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	const { state } = useLocation();

	useEffect(() => {
		forceUpdate({});
		console.log(state);
	}, [forceUpdate]);

	const onFinish = (values) => {
		history.push({ pathname: '/employees/viewemployee', state: { ...state } });
	};
	return (
		<>
			<EdenredHeader />
			<Row className="employee-document-container">
				<Col xs={24} sm={24} className="employee-document-header">
					<EdenredTypo>Employee Documents</EdenredTypo>
				</Col>
				<Col xs={24} sm={24} className="employee-document-form">
					<Form form={form} onFinish={onFinish}>
						<Col xs={24} sm={24}>
							<Form.Item
								name="Passport Number"
								initialValue={state.data.passportNumber}
								rules={[
									// { required: true, message: 'Please provide the passport number' },
									{ max: 12, message: 'The passport number should not exceed 12 characters.' },
									{
										pattern: new RegExp(/^([a-zA-Z0-9 _-]+)$/),
										message: 'Special Character not allowed only alphanumberic',
									},
								]}>
								<Input labels="Passport Number" value={state.data.passportNumber} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24}>
							<Form.Item name="Emirates ID Number" initialValue={state.data.emiratesId}>
								<Input labels="Emirates ID Number" type="number" value={state.data.emiratesId} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24}>
							<Form.Item name="Emirates ID Expiry" initialValue={moment(state.data.emiratesIdExpiryDate).format('YYYY-MM-DD')}>
								<Input labels="Emirates ID Expiry" type="date" value={moment(state.data.emiratesIdExpiryDate).format('YYYY-MM-DD')} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24}>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Save Changes
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
};
export default EmployeeDocument;
