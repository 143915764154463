import React from 'react';
import { withReducer } from '@store/reducerLoader';
import EmployeeDocumentFunctional from './emp-document-functional';
import reducer from './reducer';
import sagas from './saga/index';

const EmpDocumentMain = ({
	getEmployeeById,
	employeeDetails,
	setNavigateTabCheck,
	navigateTabCheck,
	buttonFlag,
	setButtonFlag,
	hasErrors,
	setHasErrors,
	empDocState,
	setEmpDocState,
	backupData,
	setBackupData,
	expiryDates,
	setExpiryDates,
	imageData,
	setImageData,
	empDocumentForm,
}) => {
	return (
		<EmployeeDocumentFunctional
			{...{
				getEmployeeById,
				employeeDetails,
				setNavigateTabCheck,
				navigateTabCheck,
				buttonFlag,
				setButtonFlag,
				hasErrors,
				setHasErrors,
				empDocState,
				setEmpDocState,
				backupData,
				setBackupData,
				expiryDates,
				setExpiryDates,
				imageData,
				setImageData,
				empDocumentForm,
			}}
		/>
	);
};
const ReducedScreen = withReducer('individualData', reducer, sagas)(EmpDocumentMain);
export default ReducedScreen;
