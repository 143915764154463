import { API_DOMAIN, APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { apiUrl, appConstant } from '@constants';
import { call, put, takeEvery } from 'redux-saga/effects';

import Actions from '../action';
import Axios from 'axios';
import Base from 'antd/lib/typography/Base';
import { message } from 'antd';
import ToastAction from '@pages/DesignSystem/StatusToast/actions';

export function* documentcountService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	let employeesCount = {
		emiratesDocumentEmployeeCount: {
			totalEmployeesCount: 50,
			expiredDocumentCount: 47,
			expirySoonCount: 10,
			missingDateCount: 3,
			upToDateCount: 0,
		},
	};
	try {
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/employeecount`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeeCountSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeeCountFail(err));
	}
}

export function* getApprovedValidEmployeeService(action) {
	const { pageNumber, pageSize, searchNameId } = action.payload;
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		yield put(Actions.creators.getApprovedEmployeeSuccess([]));
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/data/getemirates/?${
			searchNameId ? 'filter=' + searchNameId + '&' : ''
		}pageNumber=${pageNumber}&pageSize=${pageSize}`; // added condition for filter if "searchNameId" is there.
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getApprovedEmployeeSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getApprovedEmployeeError(err));
	}
}

export function* downloadService(downloadDocument) {
	const downloadDatas = downloadDocument.downloadDocument;
	let request = {
		documentTypeFilter: downloadDatas.documentTypeFilter,
		expiryDateFilter: downloadDatas.expiryDateFilter,
		employeeIdNameFilter: downloadDatas.employeeIdNameFilter,
		pageNumber: downloadDatas.pageNumber,
		pageSize: downloadDatas.pageSize,
		reportType: downloadDatas.reportType,
	};
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/Data/Download`;
		const res = yield call(Axios.post, uri, request, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.saveDownloadSuccess(res.data, downloadDatas.reportType));
		const downloadUrl = window.URL.createObjectURL(res.data);
		const link = document.createElement('a');

		link.href = downloadUrl;

		link.setAttribute('download', `${request.reportType == 'excel' ? 'EmployeesExpiryDocument.xls' : 'EmployeesExpiryDocument.pdf'}`); //any other extension
		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
	} catch (err) {
		yield put(Actions.creators.saveDownloadFail(err, downloadDatas.reportType));
	}
}

export function* filterService(filterDocument) {
	const filterData = filterDocument.filterDocument;
	let request = {
		documentTypeFilter: filterData.documentTypeFilter,
		expiryDateFilter: filterData.expiryDateFilter,
		employeeIdNameFilter: filterData.employeeIdNameFilter,
		pageNumber: filterData.pageNumber,
		pageSize: filterData.pageSize,
		reportType: filterData.reportType,
	};
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/data`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.saveFilterDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.saveFilterDataFail(err));
	}
}

export function* submitValidEmployeeService(action) {
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/data/Update`;
		const res = yield call(Axios.put, uri, action.req);
		yield put(Actions.creators.submitValidEmployeeSuccess());
		yield put(Actions.creators.handelSubmitSuccessPopup(true));
		yield put(ToastAction.creators.putSuccessStatusToast({ message: 'Your changes has been saved' }));
	} catch (err) {
		yield put(Actions.creators.submitValidEmployeeError(err));
	}
}
export function* saveService(action) {
	const saveData = action.modifiedData;
	console.log('modifiedData3', action);
	let request = [
		{
			citizenId: saveData.citizenId,
			dateOfBirth: saveData.dateOfBirth,
			emiratesIdNumber: saveData.emiratesIdNumber,
			emiratesExpiryDate: saveData.emiratesExpiryDate,
			employeeId: saveData.employeeId,
			labourCardExpiryDate: saveData.labourCardExpiryDate,
			labourCardNumber: saveData.labourCardNumber,
			mobileNumber: saveData.mobileNumber,
			name: saveData.name,
			passportExpiryDate: saveData.passportExpiryDate,
			passportNumber: saveData.passportNumber,
			visaExpiryDate: saveData.visaExpiryDate,
			visaNumber: saveData.visaNumber,
		},
	];
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/Data/Save`;
		const res = yield call(Axios.post, uri, action.modifiedData, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		yield put(Actions.creators.saveButtonSuccess(res.data));
		yield put(Actions.creators.setPopupVisible(true));
		yield put(ToastAction.creators.putSuccessStatusToast({ message: 'Your changes has been saved' }));
		yield put(Actions.creators.saveFilterDataStart(action.filterDocument));
	} catch (err) {
		yield put(Actions.creators.saveButtonFail(err));
	}
}

export default function* expiryWatcher() {
	yield takeEvery(Actions.types.GET_EMPLOYEECOUNT_START, documentcountService);
	yield takeEvery(Actions.types.SAVE_DOWNLOAD_START, downloadService);
	yield takeEvery(Actions.types.SAVE_FILTERDATA_START, filterService);
	yield takeEvery(Actions.types.SAVE_BUTTON_START, saveService);
	yield takeEvery(Actions.types.GET_APPROVED_EMPLOYEE, getApprovedValidEmployeeService);
	yield takeEvery(Actions.types.SUBMIT_VALID_EMPLOYEE, submitValidEmployeeService);
}
