import React from 'react';
import './header.scss';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import propTypes from 'prop-types';
const Header = ({ icons }) => {
	return (
		<>
			<div className="header-outer">
				<div className="content">
					<div className="company">
						<p className="companyname">Gilbane Building Company</p>
						<p className="coporateid">(10029931)</p>
					</div>
					<div className="arrow">
						<ImageComponent src={images.down} className="arrowimage" />
					</div>
				</div>
				<div className="image">
					<ImageComponent src={icons} className="headerimage" />
				</div>
			</div>
		</>
	);
};

export default Header;
Header.propTypes = {
	title: propTypes.string,
	coporateid: propTypes.string,
	icons: propTypes.object,
};
