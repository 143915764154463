export const options = [
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
];
export const options2 = [
	{
		name: 'Sujai',
		sub: '10259',
		subMenu: [
			{ name: 'React js', sub: 'Dev' },
			{ name: 'Angular js', sub: 'Dev' },
		],
	},
	{
		name: 'Subash',
		sub: '99466',
		subMenu: [
			{ name: 'React js', sub: 'Dev' },
			{ name: 'HTML', sub: 'Dev' },
			{ name: 'Css', sub: 'Dev' },
		],
	},
];
export const options4 = [
	{
		name: 'Sujai',
	},
	{
		name: 'Subash',
		sub: '99466',
		subMenu: [{ name: 'React js' }],
	},
	{
		name: 'Subash',
		subMenu: [{ name: 'React js' }],
	},
];
export const options3 = [
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
	{
		name: 'Sujai',
		sub: '10259',
	},
	{
		name: 'Subash',
		sub: '99466',
	},
];

export const feedbox = [
	{
		propName: 'Type',
		propType: 'String',
		default: 'Success',
	},
	{
		propName: 'Icon',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'Desc',
		propType: 'String',
		default: '',
	},
];
export const modal = [
	{
		propName: 'Title',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Desc',
		propType: 'String',
		default: '',
	},
	{
		propName: 'OnOkay',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'OnCancel',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'OnCancelbutton',
		propType: 'string',
		default: '',
	},

	{
		propName: 'Href',
		propType: 'Link',
		default: '',
	},
	{
		propName: 'ClassName',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Isopen',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'Aftermodal',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'heart',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'Close',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'celebration',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'imagedown ',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'image',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'field',
		propType: 'string',
		default: '',
	},
];

export const button = [
	{
		propName: 'Children',
		propType: 'String',
		default: 'Required',
	},
	{
		propName: 'LeftIcon',
		propType: 'Element',
		default: 'Null',
	},
	{
		propName: 'RightIcon',
		propType: 'Element',
		default: 'Null',
	},
	{
		propName: 'OnPress',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'Disabled',
		propType: 'String',
		default: 'Null',
	},
	{
		propName: 'Loading',
		propType: 'Boolean',
		default: 'False',
	},

	{
		propName: 'Inactive',
		propType: 'boolean',
		default: 'False',
	},
	{
		propName: 'Type',
		propType: 'String',
		default: 'Primary',
	},
	{
		propName: 'Style',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'OnClick',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'Secondaryactive',
		propType: 'Boolean',
		default: '',
	},
];
export const typo = [
	{
		propName: 'Children',
		propType: 'String',
		default: 'Required',
	},
	{
		propName: 'ClassName',
		propType: 'string',
		default: '',
	},

	{
		propName: 'FontWeightStyle',
		propType: 'String',
		default: 'h1',
	},
	{
		propName: 'HeaderStyle',
		propType: 'String',
		default: 'h1',
	},
];
export const tooltip = [
	{
		propName: 'Children',
		propType: 'String',
		default: 'Required',
	},
	{
		propName: 'Directon',
		propType: 'String',
		default: '',
	},

	{
		propName: 'Content',
		propType: 'Object',
		default: '',
	},
];
export const carousel = [
	{
		propName: 'Children',
		propType: 'String',
		default: 'Required',
	},
	{
		propName: 'ClassName	',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Autoplay	',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'Duration	',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'PrevButton	',
		propType: 'String',
		default: '',
	},
	{
		propName: 'NextButton',
		propType: 'String',
		default: '',
	},
	{
		propName: 'ShowPagination',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'OnlySlider',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'Pagination',
		propType: 'number',
		default: '0',
	},
];
export const chip = [
	{
		propName: 'Children',
		propType: 'String',
		default: 'Required',
	},
	{
		propName: 'Color	',
		propType: 'String',
		default: '',
	},
];
export const combobox = [
	{
		propName: 'SelectedValue',
		propType: 'String',
		default: '',
	},
	{
		propName: 'OnChange	',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Option	',
		propType: 'Array',
		default: 'Required',
	},
	{
		propName: 'Placeholder',
		propType: 'String',
		default: '',
	},
	{
		propName: 'SearchVisibility',
		propType: 'String',
		default: '',
	},
];
export const input = [
	{
		propName: 'Type',
		propType: 'String',
		default: 'Required',
	},
	{
		propName: 'Placeholder',
		propType: 'String',
		default: '',
	},

	{
		propName: 'Disabled',
		propType: 'Boolean',
		default: 'False',
	},
	{
		propName: 'Prefix',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Label',
		propType: 'String',
		default: '',
	},
	{
		propName: 'MaxLength',
		propType: 'String',
		default: '',
	},
	{
		propName: 'OnChange',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'Pattertn',
		propType: 'String',
		default: '',
	},
	{
		propName: 'RightIcon',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'LeftImage',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'OnFocus',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'OnBlur',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'OnKeyDown',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'OnKeyUp',
		propType: 'Function',
		default: '',
	},
];
export const radiobox = [
	{
		propName: 'Bordercolor',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Borderstyle',
		propType: 'String',
		default: '',
	},

	{
		propName: 'Borderwidth',
		propType: 'Number',
		default: '2',
	},
	{
		propName: 'Checked',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Disabled',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Right',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Name',
		propType: 'String',
		default: '',
	},
	{
		propName: 'BorderRadius',
		propType: 'Number',
		default: '7',
	},
	{
		propName: 'Size',
		propType: 'Number',
		default: '22',
	},
	{
		propName: 'Styles',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Classname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Labelstyle',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Labelclassname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Containerstyle',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Containerclassname',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Value',
		propType: 'String',
		default: '',
	},
	{
		propName: 'OnChange',
		propType: 'Function',
		default: '',
	},
];
export const dragger = [
	{
		propName: 'ErrorMessage',
		propType: 'String',
		default: '',
	},
	{
		propName: 'AcceptedTypes',
		propType: 'String',
		default: '',
	},

	{
		propName: 'SuccessMessage',
		propType: 'String',
		default: '',
	},
	{
		propName: 'LoadingText1 ',
		propType: 'String',
		default: '',
	},
	{
		propName: 'LoadingText2',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Idealtext1',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Idealtext2',
		propType: 'String',
		default: '',
	},
	{
		propName: 'SuccessIcon',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'ErrorIcon',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'IdleIcon',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'UploadIcon',
		propType: 'Element',
		default: '',
	},
	{
		propName: 'OnChange',
		propType: 'Function',
		default: '',
	},
];
export const checkbox = [
	{
		propName: 'Bordercolor',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Borderstyle',
		propType: 'String',
		default: '',
	},

	{
		propName: 'Borderwidth',
		propType: 'Number',
		default: '2',
	},
	{
		propName: 'Checked',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Disabled',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Right',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Name',
		propType: 'String',
		default: '',
	},
	{
		propName: 'BorderRadius',
		propType: 'Number',
		default: '7',
	},
	{
		propName: 'Size',
		propType: 'Number',
		default: '22',
	},
	{
		propName: 'Styles',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Classname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Labelstyle',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Labelclassname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Containerstyle',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Containerclassname',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'value',
		propType: 'String',
		default: '',
	},
	{
		propName: 'OnChange',
		propType: 'Function',
		default: '',
	},
];
export const tableselector = [
	{
		propName: 'Bordercolor',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Borderstyle',
		propType: 'String',
		default: '',
	},

	{
		propName: 'Borderwidth',
		propType: 'Number',
		default: '2',
	},
	{
		propName: 'Checked',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Disabled',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Right',
		propType: 'Boolean',
		default: '',
	},
	{
		propName: 'Name',
		propType: 'String',
		default: '',
	},
	{
		propName: 'BorderRadius',
		propType: 'Number',
		default: '7',
	},
	{
		propName: 'Size',
		propType: 'Number',
		default: '22',
	},
	{
		propName: 'Styles',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Classname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Labelstyle',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Labelclassname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Containerstyle',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'Containerclassname',
		propType: 'Object',
		default: '',
	},
	{
		propName: 'value',
		propType: 'String',
		default: '',
	},
	{
		propName: 'OnChange',
		propType: 'Function',
		default: '',
	},
	{
		propName: 'Type',
		propType: 'String',
		default: '',
	},
];
export const tableprops = [
	{
		propName: 'Classname',
		propType: 'String',
		default: '',
	},
	{
		propName: 'Columns',
		propType: 'Objects',
		default: '',
	},

	{
		propName: 'data',
		propType: 'Objects',
		default: '',
	},
	{
		propName: 'ClassName',
		propType: 'String',
		default: '',
	},
	{
		propName: 'CurrentPage',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'TotalCount',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'PageSize',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'onChange',
		propType: 'Funtion',
		default: '',
	},
];

export const tablepagination = [
	{
		propName: 'ClassName',
		propType: 'String',
		default: '',
	},
	{
		propName: 'CurrentPage',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'TotalCount',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'PageSize',
		propType: 'Number',
		default: '',
	},
	{
		propName: 'onChange',
		propType: 'Funtion',
		default: '',
	},
];
export const sidenavbar = [
	{
		propName: 'menus',
		propType: 'array of object',
	},
];
export const counter = [
	{
		propName: 'grey',
		propType: 'boolean',
	},
	{
		propName: 'width',
		propType: 'string',
	},
	{
		propName: 'vertical',
		propType: 'boolean',
	},
	{
		propName: 'twobutton',
		propType: 'boolean',
	},
	{
		propName: 'content',
		propType: 'boolean',
	},

	{
		propName: 'handleincrement',
		propType: 'function',
	},
	{
		propName: 'handledecrement',
		propType: 'function',
	},
	{
		propName: 'content',
		propType: 'array of object',
	},
];
export const progressbar = [
	{
		propName: 'values',
		propType: 'array of object',
	},
];
export const header = [
	{
		propName: 'title',
		propType: 'string',
	},
	{
		propName: 'coporate id',
		propType: 'string',
	},
	{
		propName: 'icons',
		propType: ' object',
	},
];
