import { useEffect, useRef } from 'react';

function useSideEffects({ setMainmenu, menus, setSize, location, mainmenu }) {
	const _mainmenu = useRef(mainmenu);
	useEffect(() => {
		setMainmenu(menus);
	}, [menus]);

	useEffect(() => {
		_mainmenu.current = mainmenu;
	}, [mainmenu]);

	const updateMenusBasedOnRoute = () => {
		const updateMenuAndFirstSubMenu = (menuKey) => {
			const head = menus?.map((item, index) => {
				if (item.key === menuKey) {
					return {
						...item,
						isSelect: !item.isSelect,
						// isSelect: typeof mainmenu?.[index]?.isSelect === 'boolean' ? mainmenu?.[index]?.isSelect : !item.isSelect,
						isVisible: typeof mainmenu?.[index]?.isVisible === 'boolean' ? mainmenu?.[index]?.isVisible : !item.isVisible,
						subMenus: item.subMenus
							? item.subMenus.map((subMenu, index) => {
								if (index === 0) {
									return {
										...subMenu,
										isSelect: true,
									};
								} else {
									return { ...subMenu };
								}
							})
							: [],
					};
				}
				return { ...item, isSelect: false };
			});
			setMainmenu(head);
		};
		const updateSubMenuAndParentMenu = (menuKey) => {
			const submenus = menus?.map((item, index) => {
				let menusIsSelected = false;
				let isVisible = false;
				return {
					...item,
					subMenus: item?.subMenus?.map((items) => {
						if (items.key === menuKey) {
							// menusIsSelected = typeof mainmenu?.[index]?.isSelect === 'boolean' ? mainmenu?.[index]?.isSelect : !item.isSelect;
							menusIsSelected = true;
							isVisible =
								typeof _mainmenu.current?.[index]?.isVisible === 'boolean' ? _mainmenu.current?.[index]?.isVisible : !item.isVisible;
							return { ...items, isSelect: true };
						}
						return { ...items, isSelect: false };
					}),
					isSelect: menusIsSelected,
					isVisible: isVisible,
				};
			});
			setMainmenu(submenus);
		};

		let locationsplit = location?.pathname;
		let locationsplit1 = locationsplit?.split('/');

		switch (location.pathname) {
			case '/': {
				updateMenuAndFirstSubMenu('dashboard');
				break;
			}
			case '/home': {
				updateMenuAndFirstSubMenu('home');
				break;
			}
			case '/employee/request_leave': {
				updateMenuAndFirstSubMenu('request-leave');
				break;
			}
			case '/run-payroll': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll-plus': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll/run/channel': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll/run/channel/wps': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll/run/channel/now_or_later': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll/run/channel/submit_payroll': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/upload/salary/spreadsheet_or_sif': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll/run/channel/next_steps': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('run-payroll');
				break;
			}
			case '/payroll/history': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('payroll-status');
				break;
			}
			case `/payroll/status/${locationsplit1?.[3]}`: {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('payroll-status');
				break;
			}
			case `/payroll/failedDetail/${locationsplit1?.[3]}`: {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('payroll-status');
				break;
			}
			case '/payroll/proof_of_transfer': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('proof-of-funds-transfer');
				break;
			}
			case '/payroll/timings': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('payroll-timings');
				break;
			}
			case '/payroll/approve_requests': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('payroll-approve-requests');
				break;
			}
			case '/payroll/wps_table': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('payroll-approve-requests');
				break;
			}
			case '/payroll/bank_details': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('our-bank-details');
				break;
			}
			case '/salary': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('salary-reports');
				break;
			}
			case '/salary/salary_per_employee': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('salary-reports');
				break;
			}
			case '/salary-history': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('salary-reports');
				break;
			}
			case '/salary-monthwise_reports': {
				// handleMenuBar('payroll');
				updateSubMenuAndParentMenu('salary-reports');
				break;
			}
			case '/payroll/useful_files': {
				// handleMenuBar('payroll');
				//updateSubMenuAndParentMenu('payroll-useful-files');
				break;
			}
			case '/employees/manage_employee': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/replacement_card': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/add/multiple/employees/upload': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employee/update': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/add/multiple/employees/submit': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/add/multiple/employees/submit/nextsteps': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/transaction-detail': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/requestStatement': {
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/viewemployee': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/manange/employees/bulk/delete': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/cardholder': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/cardholder/c3paynumber': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/cardholder/banknumber': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('manage-employees');
				break;
			}
			case '/employees/card/order/track/status': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('card-order-status');
				break;
			}
			case '/employees/card/order/view/details': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('card-order-status');
				break;
			}
			case '/employees/card/order/status': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('card-order-status');
				break;
			}
			case '/employees/card-request-detail': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('employees-approve-requests');
				break;
			}
			case '/employees/approve_employee_request': {
				// handleMenuBar('employees');
				updateSubMenuAndParentMenu('employees-approve-requests');
				break;
			}
			// case '/employees/useful/files': {
			// 	// handleMenuBar('employees');
			// 	//updateSubMenuAndParentMenu('employees-useful-files');
			// 	break;
			// }
			case '/company/company-setting': {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('company-setting');
				break;
			}
			case '/manage_team': {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('team-permissions');
				break;
			}
			case `/user_role/${locationsplit1?.[2]}`: {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('team-permissions');
				break;
			}
			case '/invite_team_member': {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('team-permissions');
				break;
			}
			case '/permission/user': {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('team-permissions');
				break;
			}
			case '/company/information': {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('company-information');
				break;
			}
			case '/company/upload-spreadsheet': {
				// handleMenuBar('company');
				updateSubMenuAndParentMenu('company-information');
				break;
			}
			case '/leaves/leaves_to_approve': {
				// handleMenuBar('leaves');
				updateSubMenuAndParentMenu('leavestoapprove');
				break;
			}
			case '/leaves/leaves_statuses': {
				// handleMenuBar('leaves');
				updateSubMenuAndParentMenu('leavestatues');
				break;
			}
			case '/leaves/request_leave': {
				// handleMenuBar('leaves');
				updateSubMenuAndParentMenu('requestleave');
				break;
			}
			case '/approval-workflow-leave': {
				// handleMenuBar('leaves');
				updateSubMenuAndParentMenu('approvalworkflow');
				break;
			}
			case '/hrdocuments': {
				updateMenuAndFirstSubMenu('documents');
				break;
			}
			case '/documents/document-setup': {
				updateMenuAndFirstSubMenu('documents');
				break;
			}
			case '/documents/documents-upload': {
				updateMenuAndFirstSubMenu('documents');
				break;
			}
			case '/documents/preview-upload-document': {
				updateMenuAndFirstSubMenu('documents');
				break;
			}
			case '/announcement': {
				updateMenuAndFirstSubMenu('announcements');
				break;
			}
			case '/new-hr-features/announcement': {
				updateMenuAndFirstSubMenu('announcements');
				break;
			}
			case '/payslip': {
				updateMenuAndFirstSubMenu('payslip');
				break;
			}
			case '/design-system': {
				updateMenuAndFirstSubMenu('design-system');
				break;
			}
			case '/healthinsurance': {
				updateMenuAndFirstSubMenu('health-insurance');
				break;
			}
			case '/employees/useful/files': {
				updateMenuAndFirstSubMenu('usefulFiles');
				break;
			}
			case '/proof-of-invoice': {
				updateMenuAndFirstSubMenu('invoice');
				break;
			}
			case '/proof-of-invoice/history': {
				updateMenuAndFirstSubMenu('invoice');
				break;
			}
			case '/videoTutorial': {
				updateMenuAndFirstSubMenu('video-tutorials');
				break;
			}
			case '/help': {
				updateMenuAndFirstSubMenu('help');
				break;
			}
			case '/leads': {
				updateMenuAndFirstSubMenu('logout');
				break;
			}

			case '/un-employment-insurance/intro':
			case '/un-employment-insurance/employee':
			case '/un-employment-insurance/plan':
			case '/un-employment-insurance/terms-and-conditions':
			case '/un-employment-insurance/bulk-update':
			case '/un-employment-insurance/single-update':
			case '/un-employment-insurance/success': {
				updateMenuAndFirstSubMenu('un-employment-insurance');
				break;
			}
			default:
				// updateMenuAndFirstSubMenu('dashboard');
				break;
		}
	};

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;

			if (width <= 1300) {
				setSize(true);
			} else {
				setSize(false);
			}
		};

		window.addEventListener('resize', handleResize);

		window.onresize = handleResize();
	}, []);

	useEffect(() => {
		updateMenusBasedOnRoute();
	}, [menus]);

	useEffect(() => {
		updateMenusBasedOnRoute();
	}, [location]);
}

export default useSideEffects;
