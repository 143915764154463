import React from 'react';

import LoadingFunctional from './components/loading-functional';
import { withReducer } from '@store/reducerLoader';
import reducer from './reducer';
import sagas from './sagas';

const LoadingScreen = () => {
	return <LoadingFunctional />;
};

const ReducedScreen = withReducer('sharedStates', reducer, sagas)(LoadingScreen);

export default ReducedScreen;
