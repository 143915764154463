import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './index.scss';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { useSelector } from 'react-redux';
import { mergeAllClaims } from 'src/utils/claims';
import { ALL_EMPLOYEE_CLAIMS, ALL_HR_CLAIMS, ALL_PAYROLL_CLAIMS } from '@constants/app-constants';
import ETypo from '@pages/DesignSystem/Typo';
import { icons } from 'src/assets/icons';

function RenewalSuccessOld() {
	const history = useHistory();

	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	const unEmployeeCardDetails = useSelector((state) => state?.unemploymentReducer?.unEmploymentCardHolderData);
	const isUnEmploymentLoading = useSelector((state) => state?.unemploymentReducer?.isUnEmploymentLoading);
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);

	function OnClickCancel() {
		const SHOULD_CONTAIN = allClaims.some((_claims) => [...ALL_EMPLOYEE_CLAIMS, ...ALL_HR_CLAIMS].indexOf(_claims) >= 0);
		const SHOULD_NOT_CONTAIN = !allClaims.some((_claims) => [...ALL_PAYROLL_CLAIMS].indexOf(_claims) >= 0);

		if (SHOULD_CONTAIN && SHOULD_NOT_CONTAIN) {
			history.push('/home');
		} else {
			history.push('/');
		}
	}

	return (
		<div className="success-wrapper">
			<div>
				<section className="header">
					<h1>Mandatory Unemployment Insurance</h1>
					<EdenredButtons onClick={OnClickCancel}>Go To Dashboard</EdenredButtons>
				</section>

				<section className="main main-card">
					<section>
						<img src={not_focus_icons.tickHexagon} alt="" />
						<h1>Request successfully submitted</h1>
						<h2>Your employees will soon receive their Unemployment Insurance policies!</h2>
						<ul>
							<li>Policies will be issued for employees whenever they have sufficient balance.</li>
							<br />
							<li>They will receive an SMS once the policy is issued.</li>
						</ul>
						<p>
							Contact us if you have any questions at <span>support.ae@edenred.com</span>
						</p>
					</section>
					<section className="unemployment-insurance-success">
						<div className="success-header">
							<ETypo b1 medium className="success-row">
								Total C3Pay Cardholders
							</ETypo>
							<ETypo b1 medium className="success-row">
								{isUnEmploymentLoading ? (
									<div className={`loader secondary_loader`} />
								) : unEmployeeCardDetails ? (
									unEmployeeCardDetails?.total
								) : (
									0
								)}
							</ETypo>
						</div>
						<div className="success-header" style={{ backgroundColor: '#FBFCFD' }}>
							<div className="success-row">Cardholders with insurance policies requested</div>
							<div className="success-row">
								{isUnEmploymentLoading ? (
									<div className={`loader secondary_loader`} />
								) : unEmployeeCardDetails ? (
									unEmployeeCardDetails?.chInsurancePolicyRequestedCount
								) : (
									0
								)}
							</div>
						</div>
						<div className="success-header">
							<div className="success-row">Cardholders with missing details</div>
							<div className="success-row">
								{isUnEmploymentLoading ? (
									<div className={`loader secondary_loader`} />
								) : unEmployeeCardDetails ? (
									unEmployeeCardDetails?.chMissingDetailsCount
								) : (
									0
								)}
							</div>
						</div>
					</section>
				</section>
				<section className="main-card">
					<h1>
						Request policies for the remaining{' '}
						{isUnEmploymentLoading ? (
							<div style={{ height: '15px', width: '15px' }} className={`loader secondary_loader`} />
						) : unEmployeeCardDetails ? (
							unEmployeeCardDetails?.chMissingDetailsCount
						) : (
							0
						)}{' '}
						employees by providing the missing details
					</h1>
					<Link
						className="view-detail-style"
						to={{
							pathname: `/un-employment-insurance/single-update`,
							state: {},
						}}>
						<div className="main-card-outer">
							<div className="icon-outer">
								<div className="icon">
									<img src={icons?.focus_state?.UnEmploymentUser} alt="" />
								</div>
							</div>
							<div className="icon-content">
								<ETypo b1 medium className="success-row">
									Update details in the table directly
								</ETypo>
								<div style={{ color: '#484B52' }}>
									You can fill in the missing Emirates ID and Mobile number directly in the table
								</div>
							</div>
							<div className="right-icon-content">
								<img src={icons?.focus_state?.InvoiceArrow} alt="icon" />
							</div>
						</div>
					</Link>
					<div className="separator"></div>
					<Link
						className="view-detail-style"
						to={{
							pathname: `/un-employment-insurance/bulk-update`,
							state: {},
						}}>
						<div className="main-card-outer">
							<div className="icon-outer">
								<div className="icon">
									<img src={icons?.focus_state?.UnEmploymentGroupUser} alt="" />
								</div>
							</div>
							<div className="icon-content">
								<ETypo b1 medium className="success-row">
									Bulk update details using the spreadsheet
								</ETypo>
								<div style={{ color: '#484B52' }}>
									You can download the employee list with missing details and bulk update the details using a spreadsheet
								</div>
							</div>
							<div className="right-icon-content">
								<img src={icons?.focus_state?.InvoiceArrow} alt="icon" />
							</div>
						</div>
					</Link>
					<div className="separator"></div>
				</section>
			</div>
		</div>
	);
}

export default RenewalSuccessOld;
