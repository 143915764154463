import '../../../../DesktopFix.css';

import React, { useState } from 'react';
import PayrollService from 'src/services/payroll-service';
import { useHistory } from 'react-router-dom';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { images } from 'src/assets/img';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import Toast from '@pages/DesignSystem/Toast';

const ChangePasswordPresentational = ({ form, password, password1, principles, onPasswordChange, onPasswordChange1 }) => {
	let history = useHistory();
	const [loading, setloading] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showPassword, setShowPassword] = useState({
		current: false,
		new: false,
	});
	const _payrollService = new PayrollService();

	const onFinish = () => {
		setloading(true);
		let data = {
			password: password1,
			// Temporarily Hiding the Old password
			// oldPassword: password,
		};

		_payrollService
			.changePassword(data)
			.then((res) => {
				setloading(false);
				setShowToast(true);
			})
			.catch((err) => {
				setloading(false);
			});
	};

	return (
		<>
			{/* <EdenredHeader /> */}
			<Toast color="#8EF9B3" visible={showToast} clearPopUp={() => setShowToast(false)}>
				Your Password Was Updated
			</Toast>
			<main className="change_password_main" style={{ width: '100%' }}>
				<section className="header">
					<h1>Change Password</h1>
					<div>
						<EdenredButtons
							onClick={() => history.goBack()}
							btnType="secondary"
							leftIcon={<img alt="left-arrow" src={images.leftsarrow} />}
							className="go-back-button">
							Back
						</EdenredButtons>
						<EdenredButtons
							loading={loading}
							disabled={
								// Temporarily Hiding the Old password
								// !(principles.filter((principle) => principle.rule(password)).length === 2) ||
								// password === password1 ||
								!(principles.filter((principle) => principle.rule(password1)).length === 2)
							}
							onClick={() => onFinish()}
							btnType="primary"
							className="change-password-button">
							Save
						</EdenredButtons>
					</div>
				</section>
				<section className="body">
					<div className="inputs_area">
						{/* Temporarily Hiding the Old password */}
						{/* <div className="input-filed">
							<p>
								Old Password<span style={{ color: '#FF9691' }}> *</span>
							</p>
							<Inputbox
								type={showPassword?.current?"text":"password"}
								value={password}
								onChange={(event) => {
									onPasswordChange(event.target.value);
								}}
								placeholder="Enter Your Current Password"
								rightIcon={<div style={{cursor:'pointer'}} onClick={()=>setShowPassword({...showPassword,current:!showPassword?.current})}><img src={focus_state_icons.Eye} alt="" /></div>}
							/>
						</div> */}
						<div className="input-filed">
							<p>
								New Password<span style={{ color: '#FF9691' }}> *</span>
							</p>
							<Inputbox
								type={showPassword?.new ? 'text' : 'password'}
								value={password1}
								onChange={(event) => {
									onPasswordChange1(event.target.value);
								}}
								placeholder="Enter New Password"
								rightIcon={
									<div style={{ cursor: 'pointer' }} onClick={() => setShowPassword({ ...showPassword, new: !showPassword?.new })}>
										<img src={focus_state_icons.Eye} alt="" />
									</div>
								}
							/>
						</div>
					</div>
					<ul>
						<li>Minimum 6 characters</li>
						<li>Must contain at least 1 number</li>
					</ul>
				</section>
			</main>
		</>
	);
};

export default ChangePasswordPresentational;
