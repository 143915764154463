import React from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import { images } from 'src/assets/img';
import './add-individual-header.scss';

const AddIndividualHeader = ({
	handleBackButton = () => {},
	showNext = false,
	showConform = false,
	disableBack = false,
	disableNext = false,
	disableConform = false,
	handleNextButton = () => {},
	handleConformButton = () => {},
	loadingNext = false,
	loadingConform = false,
	secondaryButtonText = 'Back',
}) => {
	return (
		<div className="add_individual_header">
			<div className="add_individual_text">
				<ETypo bold h2>
					Add Employee
				</ETypo>
			</div>
			<div className="add_individual_button">
				<EdenredButtons disabled={disableBack} onClick={handleBackButton} btnType="secondary" leftIcon={<img src={images.leftsarrow} />}>
					{secondaryButtonText}
				</EdenredButtons>
				{showNext && (
					<EdenredButtons disabled={disableNext} onClick={handleNextButton} loading={loadingNext}>
						Next
					</EdenredButtons>
				)}
				{showConform && (
					<EdenredButtons disabled={disableConform} onClick={handleConformButton} loading={loadingConform}>
						Confirm
					</EdenredButtons>
				)}
			</div>
		</div>
	);
};

export default AddIndividualHeader;
