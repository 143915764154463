import { Col, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import ETypo from '@pages/DesignSystem/Typo';
import Actions from '../invoiceDetailAction';
import { numberWithCommas, onlyNumberForNumberType } from '@helpers';
import Table from '@pages/DesignSystem/Table/Table';
import './group-table.scss';
import Inputbox from '@pages/DesignSystem/Input-Field';
import Toast from '@pages/DesignSystem/Toast';

export const getFormatedNumber = (number) => {
	return numberWithCommas(parseFloat(number)?.toFixed(2));
};

const GroupTable = (props) => {
	const dispatch = useDispatch();
	const { invoiceGroup, getGroupInvoiceLoading, saveGroupInvoiceLoading, saveGroupInvoiceMsg } = useSelector((state) => state?.invoiceState) || {};

	const previousScreenState = props?.location?.state; //invoiceDetails
	const { receiptId, receiptAmt } = previousScreenState;

	const [selectedCompany, setSelectedCompany] = useState([]);
	const [companyDetails, setCompanyDetails] = useState([]);

	useEffect(() => {
		dispatch(Actions.creators.getGroupInvoiceStart());
	}, []);

	useEffect(() => {
		if (invoiceGroup?.length > 0) {
			invoiceGroup.map((invoice) => {
				invoice.receiptAmount = 0;
			});
			setCompanyDetails(invoiceGroup);
		}
	}, [invoiceGroup]);

	const handleRowSelect = (isChecked, row) => {
		if (!isChecked) {
			const arr = selectedCompany?.filter((company) => {
				return company.corporateId !== row.corporateId;
			});
			setSelectedCompany(arr);
		} else {
			row.isChecked = isChecked;
			setSelectedCompany((preState) => [...preState, row]);
		}
	};

	const handleSelectedAll = (isChecked) => {
		if (isChecked) {
			const validCompany = companyDetails.filter((com) => Number(com?.receiptAmount) > 0);
			setSelectedCompany(validCompany);
		} else {
			setSelectedCompany([]);
		}
	};

	const onChangeGroupCompany = (val, rowData) => {
		companyDetails.map((company) => {
			if (selectedCompany?.find((selected) => selected?.corporateId === rowData?.corporateId)) {
				rowData.receiptAmount = val;
				if (val > 0) {
					setSelectedCompany((prevState) => {
						const newState = prevState?.map((obj) => {
							if (obj?.corporateId === rowData?.corporateId) {
								return { ...obj, receiptAmount: val };
							}
							return obj;
						});
						return newState;
					});
				}
				else {
					const selectedArr = selectedCompany.filter((record) => {
						return record.corporateId !== rowData.corporateId;
					});
					setSelectedCompany(selectedArr);
				}
			} else if (company.corporateId === rowData.corporateId) {
				rowData.receiptAmount = val;
				setSelectedCompany((preState) => [...preState, rowData]);
			}
		});
	};

	const remainingAmtCal = () => {
		/**
		 * Sum of all receipt amount in selected array
		 */
		const sumOfReceiptAmt = selectedCompany.reduce(
			(n, { receiptAmount }) => parseFloat(n ? n : 0) + parseFloat(Number(receiptAmount) > 0 ? receiptAmount : 0),
			0
		);
		/**
		 * User uploaded receipt amount subtract with sum receipt amount
		 * (userUploadedAmountFromPreviousScreen - sumOfReceiptAmount)
		 */
		return getFormatedNumber(receiptAmt - Number(sumOfReceiptAmt.toFixed(2)));
	};

	/**
	 * @returns true for disable state and false for enable state
	 */
	const getButtonActiveState = () => {
		if (selectedCompany.length === 0 || remainingAmtCal() !== getFormatedNumber(0)) {
			return true;
		} else {
			return false;
		}
	};

	const saveGroupInvoiceDetails = () => {
		const companiesWithValidAmount = selectedCompany.filter((companies) => {
			const validAmount = !isNaN(Number(companies?.receiptAmount))
			if (validAmount) {
				return Number(companies?.receiptAmount) > 0
			}
		})
		dispatch(Actions.creators.saveGroupInvoiceStart(companiesWithValidAmount, receiptId));
	};

	const companyChecked = (row) => {
		const isValidReceiptAmount = Number(row?.receiptAmount) > 0
		if (isValidReceiptAmount) {
			return selectedCompany?.findIndex((data) => data.corporateId === row.corporateId) !== -1
		}
		return false
	}


	const columns = [
		{
			sortDirections: ['ascend'],
			fixed: 'left',
			align: 'center',
			scroll: 'hidden',
			title: (
				<Checkbox
					{...{
						checked: selectedCompany.length > 0,
						size: 16,
						borderWidth: 1,
						borderRadius: 4,
						onChange: (isChecked) => handleSelectedAll(isChecked),
					}}
				/>
			),
			dataIndex: 'isSelected',
			width: '5%',
			render: (isSelected, row) => {
				return (
					<Tooltip
						placement="top"
						defaultVisible={false}
						title={Number(row?.receiptAmount) <= 0 && 'The receipt amount should not be empty or less than or equal to 0'}
					>
						<Col>
							<Checkbox
								{...{
									size: 16,
									borderWidth: 1,
									borderRadius: 4,
									checked: companyChecked(row),
									onChange: (isChecked) => {
										const isValidReceiptAmount = Number(row?.receiptAmount) > 0
										if (isValidReceiptAmount) {
											handleRowSelect(isChecked, row)
										}
									},
									disabled: Number(row?.receiptAmount) <= 0
								}}
							/>
						</Col>
					</Tooltip>
				);
			},
		},
		{
			sorter: (a, b) => a.corporateName.localeCompare(b.corporateName),
			key: 'corporateName',
			title: 'Company',
			dataIndex: 'corporateName',
			width: '25%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val}</p>;
			},
		},
		{
			sorter: (a, b) => a.corporateId.localeCompare(b.corporateId),
			key: 'corporateId',
			title: 'Branch ID',
			dataIndex: 'corporateId',
			width: '15%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val}</p>;
			},
		},
		{
			sorter: (a, b) => a.pendingInvoiceAmount.localeCompare(b.pendingInvoiceAmount),
			key: 'pendingInvoiceAmount',
			title: 'Due Amount',
			dataIndex: 'pendingInvoiceAmount',
			width: '15%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val || 0}</p>;
			},
		},
		{
			key: 'receiptAmount',
			title: 'Receipt Amount',
			dataIndex: 'receiptAmount',
			width: '20%',
			hidden: false,
			activeTab: true,
			render: (val, row) => {
				return (
					<Inputbox
						prefix={'AED'}
						onChange={(e) => {
							let amount = e.target.value;
							onChangeGroupCompany(amount, row);
						}}
						disabled={row?.groupInvoiceHide}
						type="number"
						onKeyDown={(event) => onlyNumberForNumberType(event)}
						placeholder="Enter amount on slip"
					/>
				);
			},
		},
	];

	const history = useHistory();
	return (
		<div className="group-invoice-wrapper">
			{saveGroupInvoiceMsg && (
				<Toast
					color="#8EF9B3"
					visible={true}
					clearPopUp={() => {
						dispatch(Actions.creators.saveGroupInvoiceSuccess(null));
						history.push('/proof-of-invoice');
					}}>
					{saveGroupInvoiceMsg}
				</Toast>
			)}
			<Row className="payment-group-header">
				<ETypo bold h3>
					Allocate The Payment Among Your Companies
				</ETypo>
				<div className="action-layer">
					<div>
						<span className="label">Total Receipt Amount:</span>
						<span className="value">
							AED {getFormatedNumber(receiptAmt)} for {companyDetails?.length || 0} Companies
						</span>
					</div>
					<EdenredButtons
						style={{ width: '84px' }}
						loading={saveGroupInvoiceLoading}
						{...{
							btnType: 'primary',
						}}
						onClick={saveGroupInvoiceDetails}
						disabled={getButtonActiveState()}>
						Submit
					</EdenredButtons>
				</div>
			</Row>
			<Row className="group-invoice-content">
				<div style={{ width: '100%', fontSize: '18px', fontWeight: 700, fontFamily: 'Montserrat-Regular' }}>AED {remainingAmtCal()}</div>
				<div style={{ width: '100%', paddingBottom: '17px' }} className="label">
					Remaining Amount to be Allocated
				</div>
				<Table columns={columns} data={companyDetails} tableLoading={getGroupInvoiceLoading} />
			</Row>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(GroupTable);
