import Actions from './action';

export const initState = {
	isDepartmentLoading: false,
	Departmentdata: [],
	isContractLoading: false,
	contractdata: [],
	isContractDetailSaveLoading: false,
	contractDetailResponse: [],
	isContractDetailSaved: false,
};

const contractReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_DEPARTMENT_DATA_START:
			return {
				...state,
				isDepartmentLoading: true,
			};
		case Actions.types.GET_DEPARTMENT_DATA_SUCCESS:
			return {
				...state,
				isDepartmentLoading: false,
				Departmentdata: action?.res?.departmentList,
			};
		case Actions.types.GET_DEPARTMENT_DATA_FAIL:
			return {
				...state,
				isDepartmentLoading: false,
				Departmentdata: [],
			};

		case Actions.types.GET_CONTRACT_DATA_START:
			return {
				...state,
				isContractLoading: true,
			};
		case Actions.types.GET_CONTRACT_DATA_SUCCESS:
			return {
				...state,
				isContractLoading: false,
				contractdata: action?.res?.contractTypeList,
			};
		case Actions.types.GET_CONTRACT_DATA_FAIL:
			return {
				...state,
				isContractLoading: false,
				contractdata: [],
			};

		case Actions.types.SAVE_CONTRACT_DETAIL_START:
			return {
				...state,
				isContractDetailSaveLoading: true,
			};
		case Actions.types.SAVE_CONTRACT_DETAIL_SUCCESS:
			return {
				...state,
				isContractDetailSaveLoading: false,
				contractDetailResponse: action?.res,
			};
		case Actions.types.SAVE_CONTRACT_DETAIL_FAIL:
			return {
				...state,
				isContractDetailSaveLoading: false,
				contractDetailResponse: [],
			};
		case Actions.types.EMP_CONTRACT_DETAIL_SAVED:
			return {
				...state,
				isContractDetailSaved: action?.flag,
			};
		default:
			return state;
	}
};
export default contractReducer;
