import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { STEPPER_STATUS_KEY, PAYROLL_EMPLOYEE_STATUS_KEY } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Toast from '@pages/DesignSystem/Toast';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { APP_VARIABLES } from '@constants/app-constants';
import EmployeeService from 'src/services/employee-service';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import Stepper from './../../../DesignSystem/stepper/index';

const contents = [
	{
		date: 'July 11',
		content: 'Ordered',
		processingContent: 'Ordered by user',
	},
	{
		date: 'July 11',
		content: 'Approved',
		processingContent: 'Approved by verifier',
	},
	{
		date: 'July 13',
		content: 'Cards created!',
		processingContent: 'Creating cards',
	},
	{
		date: 'July 14',
		content: 'Picked up by courier',
		processingContent: 'Picked up by courier',
	},
	{
		date: 'July 19',
		content: 'Delivered',
		processingContent: 'Arriving 20 July',
	},
];

const abc = {
	payrollStatus: {
		nextPayrollStatus: 'Processing salaries',
		currentPayrollStatus: 'Waiting for funds',
		headerType: 'Non WPS Payroll for January 2023 ',
		headerAmount: 'AED 050.00',
		headerEmployeeCount: '1 employees',
		employeesCount: 1,
		payrollSubmittedByUser: true,
		approvedByVerifier: true,
		salaryFundsAreReady: false,
		salaryProcessed: false,
		yourEmployeesPaid: false,
		lastUpdatedDate: '2022-12-14T15:26:36.12',
		expectedPayoutTime: 'Expected Salary Payout:15 December, 07:00 PM',
		colorCode: '#FFDC91',
		rejectedCount: 0,
		isRejected: false,
		submittedDate: '2022-12-14T15:26:13.227',
		status: [
			{
				payrollStatusType: 'submitted',
				date: '2022-12-14T15:26:13.227',
				status: 'Completed',
				messageType: 'Sucess',
				message: '',
				data: [],
			},
			{
				payrollStatusType: 'approved',
				date: '2022-12-14T15:26:36.12',
				status: 'Completed',
				messageType: 'Sucess',
				message: '',
				data: [],
			},
		],
	},
};
const TrackCardOrderStatus = (props) => {
	const { corporateIds } = props;
	const [cancelOrderModal, setCancelOrderModal] = useState(false);
	const [approveModal, setapproveModal] = useState(false);
	const [contents, setcontents] = useState([]);
	const [spinLoading, setSpinloading] = useState(false);
	const [address, setAddress] = useState(null);
	const [statusState, setstate] = useState({
		loading: true,
		statusResponse: null,
		stepCount: 0,
		packageNumber: null,
		isDeliveredFailed: false,
	});
	let history = props.history.location;
	const historyConst = useHistory();
	let noOfCards = 0;
	let employeeApi = new EmployeeService();
	if (history.state && history.state.orderStatus) {
		noOfCards = history.state.orderStatus.noOfCards;
	}

	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [isAction, setIsAction] = useState(null);

	const getAddress = async () => {
		let address = Object.assign(
			{},
			...(corporateIds || []).filter((corporate) => corporate.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID).toString())
		);

		if (address) {
			const { address2, address3 } = address;
			setAddress(`${address2}, ${address3}`);
		}
	};

	useEffect(() => {
		getAddress();
	}, []);

	const { state } = useLocation();
	const closeModal = () => setCancelOrderModal(false);
	const closeApproveModal = () => setapproveModal(false);
	const { uploadId, employeeId } = state?.orderStatus || { uploadId: null, employeeId: null };
	const [payloadState, setpayloadState] = useState({ uploadId, employeeId });
	const { loading, statusResponse, stepCount, packageNumber, isCardCreating } = statusState;

	const getEmployeStatus = () => {
		let payload = {
			details: {
				...payloadState,
			},
		};
		employeeApi
			.getEmployeStatusAPI(payload)
			.then((result) => {
				setstate({
					...state,
					loading: false,
					statusResponse: result.data,
				});
			})
			.catch((err) => {
				setstate({
					...state,
					loading: false,
				});
			});
	};

	useEffect(() => {
		if (!!Object.keys(statusResponse || {})?.length) {
			const {
				isPending = false,
				pendingDate = '',
				isApproved = false,
				approvedDate = null,
				isCardCreating = false,
				isCardCreated = false,
				cardCreatedDate = null,
				isCardTransit = false,
				cardTransitDate = null,
				packageNumber = null,
				packageClientReference = null,
				isDelivered = false,
				deliveredDate = null,
				isDeliveredFailed = false,
				isProcessing = false,
				estimatedArrival = '',
				arrival = null,
				status = [],
			} = statusResponse;

			let employeeOrdered = status.find((element) => element?.employeeStatusType == 'Ordered' && element);
			let employeeApproved = status.find((element) => element?.employeeStatusType == 'Approved' && element);
			let employeeCardCreated = status.find((element) => element?.employeeStatusType == 'Card Created' && element);
			let employeeCourierPicked = status.find((element) => element?.employeeStatusType == 'Picked up by Courier' && element);
			let employeeDelivered = status.find((element) => element?.employeeStatusType == 'Delivered' && element);

			let payrollSteps = [
				{
					id: 1,
					title: employeeOrdered?.date ? moment(employeeOrdered?.date).format('MMM DD') : '',
					description: employeeOrdered?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? 'Pending Order' : 'Ordered',
					errorTitle: 'Ordered Failed',
					status:
						employeeOrdered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: employeeOrdered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: employeeOrdered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: employeeOrdered?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && employeeOrdered?.message,
					errorLinkText: '',
					onerrorLinkClick: () => {},
					message: employeeOrdered?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && employeeOrdered?.message,
					messageLinkText: '',
					messageLink: () => {},
				},
				{
					id: 2,
					title: employeeApproved?.date ? moment(employeeApproved?.date).format('MMM DD') : '',
					errorTitle: 'Approval Failed',
					description: employeeApproved?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? ' Processing approval' : 'Approved',
					status:
						employeeApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: employeeApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: employeeApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: employeeApproved?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && employeeApproved?.message,
					errorLinkText: '',
					onerrorLinkClick: () => {},
					message: employeeApproved?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && employeeApproved?.message,
					messageLinkText: '',
					onMessageLinkClick: () => {},
				},
				{
					id: 3,
					title: employeeCardCreated?.date ? moment(employeeCardCreated?.date).format('MMM DD') : '',
					errorTitle: 'Card creation Failed',
					description: employeeCardCreated?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? 'Creating cards' : 'Cards created!',
					status:
						employeeCardCreated?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: employeeCardCreated?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: employeeCardCreated?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: employeeCardCreated?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && employeeCardCreated?.message,
					errorLinkText: '',
					onerrorLinkClick: () => {},
					message: employeeCardCreated?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && employeeCardCreated?.message,
					messageLinkText: '',
					onMessageLinkClick: () => {},
				},
				{
					id: 4,
					title: employeeCourierPicked?.date ? moment(employeeCourierPicked?.date).format('MMM DD') : '',
					errorTitle: 'Courier Pickedup Failed',
					description:
						employeeCourierPicked?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK || employeeCourierPicked?.status == 'Completed'
							? `Picked up by courier Order ID: ${packageNumber}`
							: `Picked up by courier`,
					status:
						employeeCourierPicked?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: employeeCourierPicked?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: employeeCourierPicked?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: employeeCourierPicked?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && employeeCourierPicked?.message,
					errorLinkText:
						employeeCourierPicked?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && employeeCourierPicked?.messageType,
					onerrorLinkClick: () => {},
					message: employeeCourierPicked?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && employeeCourierPicked?.message,
					messageLinkText:
						employeeCourierPicked?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ||
						employeeCourierPicked?.status == PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? 'Track Order'
							: '',
					onMessageLinkClick: () => {
						window.open('https://track.fetchr.us', '_blank');
					},
				},
				{
					id: 5,
					title: employeeDelivered?.date ? moment(employeeDelivered?.date).format('MMM DD') : '',
					errorTitle: 'Delivery Failed',
					description:
						employeeDelivered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? 'Delivered'
							: employeeDelivered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? arrival
							: 'Arriving',
					status:
						employeeDelivered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: employeeDelivered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: employeeDelivered?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: employeeDelivered?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && employeeDelivered?.message,
					errorLinkText: '',
					onerrorLinkClick: () => {},
					message: employeeDelivered?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && employeeDelivered?.message,
					messageLinkText: '',
					onMessageLinkClick: () => {},
				},
			];

			const temp = [
				{
					label: 'isPending',
					value: isPending,
					date: pendingDate,
				},
				{
					label: 'isApproved',
					value: isApproved,
					date: approvedDate,
				},
				{
					label: 'isCardCreated',
					value: isCardCreated,
					date: cardCreatedDate,
				},
				{
					label: 'isCardTransit',
					value: isCardTransit,
					date: cardTransitDate,
				},
				{
					label: 'isDelivered',
					value: isDelivered,
					date: deliveredDate,
				},
			];

			const index = payrollSteps?.filter((data) => data?.status === 'COMPLTED')?.length;
			const tempContent = temp.map(({ date }, i) => {
				return {
					...contents[i],
					date,
				};
			});
			setstate({
				...state,
				loading,
				statusResponse,
				stepCount: index,
				packageNumber: statusResponse?.employeeRequestModel?.packageNumber,
				isCardCreating: statusResponse?.employeeRequestModel?.isCardCreating,
				isDeliveredFailed: statusResponse?.employeeRequestModel?.isDeliveredFailed,
				estimatedArrival: statusResponse && statusResponse?.employeeRequestModel && statusResponse?.employeeRequestModel?.estimatedArrival,
			});

			setcontents(payrollSteps);
		}
	}, [statusResponse]);

	useEffect(() => {
		getEmployeStatus();
	}, []);

	const count = stepCount;
	// const stepCountTemp = `${count}.2`;
	const numberArray = [2];
	// const stepCountTemp = count === 3 ? (packageNumber ? '4.0' : `3.3`) : numberArray.includes(count) ? `${count + 1}.0` : `${count}.3`;
	const stepCountTemp =
		count === 3 ? (packageNumber ? '4.0' : `3.3`) : numberArray.indexOf(count) >= 0 && isCardCreating ? `${count + 1}.0` : `${count}.3`;
	let currentUser = useSelector((state) => state.sharedStates.currentUser);
	const role = (currentUser && currentUser?.roles && currentUser?.roles?.[0]?.name) || null;

	const updateRunPayrollReview = async (action) => {
		setIsAction(action);
		setSpinloading(true);
		const { employeeId, uploadId } = state.orderStatus
			? state.orderStatus
			: state.content.orderStatus
			? state.content.orderStatus
			: state.content.content.orderStatus
			? state.content.content.orderStatus
			: state.content.content.content.orderStatus
			? state.content.content.content.orderStatus
			: state.content.content.content.content.orderStatus;

		let payload = {
			cardRequestAction: {
				uploadIds: uploadId ? [uploadId] : [],
				employeeIds: employeeId ? [employeeId] : [],
				action: action,
				massCorpDelUploadId: [],
			},
		};

		employeeApi
			.reviewPendingRequest(payload)
			.then((res) => {
				if (res) {
					setSpinloading(false);
					setCancelOrderModal(false);
					setpopupVisible(true);
					setapproveModal(false);
					setPopupMessage({
						titleMessage: `${action} successfully..`, // TODO - DONT GIVE MSG CONTENT DIRECTLY DIRECTLY ITS SHOULD BE COME FROM CONST IN SOMEWHERE
						subMessage: null,
					});
				} else {
					setSpinloading(false);
					setCancelOrderModal(false);
					setapproveModal(false);
				}
			})
			.catch((err) => {
				setSpinloading(false);
				setCancelOrderModal(false);
				setapproveModal(false);
			});
	};

	const closePopup = () => {
		setpopupVisible(false);
		if (isAction !== 'reject') {
			getEmployeStatus();
		} else {
			historyConst.goBack();
		}
		setCancelOrderModal(false);
		setapproveModal(false);
	};

	const steps = [
		{
			id: 1,
			heading: 'July 11',
			para: 'Ordered',
			status: statusState?.statusResponse?.employeeRequestModel?.isApproved === true ? 'COMPLETED' : '',
		},
		{
			id: 2,
			heading: 'July 11',
			para: 'Approved',
			status: statusState?.statusResponse?.employeeRequestModel?.isApproved === true ? 'COMPLETED' : '',
		},
		{
			id: 3,
			heading: 'July 12',
			para:
				statusState?.statusResponse?.employeeRequestModel?.isCardCreated === true
					? 'Cards created!'
					: statusState?.statusResponse?.employeeRequestModel?.isCardCreating === true
					? 'Cards creating'
					: '',
			status:
				statusState?.statusResponse?.employeeRequestModel?.isCardCreated === true
					? 'COMPLETED'
					: statusState?.statusResponse?.employeeRequestModel?.isCardCreating === true
					? 'PROCESSING'
					: '',
		},
		{
			id: 4,
			heading: 'July 11',
			para: '',
			status: statusState?.statusResponse?.employeeRequestModel?.isDelivered === true ? 'COMPLETED' : '',
		},
		{
			id: 5,
			heading: 'July 11',
			para: 'Delivered',
			status:
				statusState?.statusResponse?.employeeRequestModel?.isDelivered === true
					? 'COMPLETED'
					: statusResponse?.employeeRequestModel?.isDeliveredFailed === true
					? 'FAILED'
					: '',
		},
	];
	let userLocation = useSelector((state) => state?.sharedStates?.corporateIds);

	let employeeCardAddress = Object?.assign(
		{},
		...(userLocation || [])?.filter((corporate) => corporate?.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
	);
	return (
		// <EdenredEmployeeStep orderId={packageNumber} activeStep={stepCountTemp} contents={contents} failed={isDeliveredFailed} />
		// <>
		// 	<Toast visible={popupVisible} color="#8EF9B3" textcolor="#484B52" clearPopUp={closePopup}>
		// 		{popupMessage.titleMessage}
		// 	</Toast>
		// 	<EdenredHeader />
		// 	<Row className="track_card_order_status_container" style={statusLoading ? { width: '100%' } : {}}>
		// 		{statusLoading ? (
		// 			<Col xl={24}>
		// 				<Row justify="center">
		// 					<Col>
		// 						<ContentLoader />
		// 					</Col>
		// 				</Row>
		// 			</Col>
		// 		) : (
		// 			<Fragment>
		// 				<EmployeeStepContent status={isDeliveredFailed ? 6 : count} noOfCards={noOfCards} estimatedArrival={estimatedArrival} />
		// 				<Divider />
		// 				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="track_card_order_status_steps_progress_bar">
		// 					<EdenredEmployeeStep orderId={packageNumber} activeStep={stepCountTemp} contents={contents} failed={isDeliveredFailed} />
		// 				</Col>
		// 				<Col xl={22} lg={22} md={22} sm={24} xs={24} className="track_card_order_status_location">
		// 					<ImageComponent src={icons.focus_state.Location} />
		// 					<EdenredTypo>{address}</EdenredTypo>
		// 				</Col>
		// 				{role === USER_ROLE.USER ? (
		// 					<Row className="track_card_order_status_button_section">
		// 						<Col xl={5} lg={8} md={12} sm={12} xs={12} className="track_card_order_status_view_button">
		// 							<Link to={{ pathname: '/employees/card/order/view/details', state: { content: history.state || {} } }}></Link>
		// 						</Col>
		// 						{count === 1 && (
		// 							<Col xl={5} lg={8} md={12} sm={15} xs={15} className="track_card_order_status_cancel_button">
		// 								<EdenredButton
		// 									disabled={count != 1}
		// 									noShadow={true}
		// 									type={'outline-g'}
		// 									onClick={() => setCancelOrderModal(true)}>
		// 									<ImageComponent src={icons.not_focus.Close} />
		// 									Cancel Order
		// 								</EdenredButton>
		// 							</Col>
		// 						)}
		// 					</Row>
		// 				) : (
		// 					<>
		// 						<Row className="track_card_order_status_button_section">
		// 							<Link
		// 								style={{ height: '100%', marginRight: '10px' }}
		// 								to={{ pathname: '/employees/card/order/view/details', state: { content: history.state || {} } }}>
		// 								<EdenredButton
		// 									style={{ height: '100%' }}
		// 									type={'outline-g'}
		// 									noShadow={true}
		// 									className="cancel_order_link_button">
		// 									View Order Details
		// 								</EdenredButton>
		// 							</Link>
		// 							{count === 1 && (
		// 								<EdenredButton
		// 									type="outline-g"
		// 									onClick={() => setCancelOrderModal(true)}
		// 									className="cancel_order_link_button">
		// 									Cancel Order
		// 								</EdenredButton>
		// 							)}
		// 						</Row>
		// 					</>
		// 				)}
		// 			</Fragment>
		// 		)}
		// 	</Row>
		// 	<Edenredmodal
		// 		{...{
		// 			isOpen: cancelOrderModal,
		// 			title: 'Do you wish to cancel this order?',
		// 			onOkay: () => updateRunPayrollReview('reject'),
		// 			onCancel: closeModal,
		// 			loading: loading,
		// 		}}></Edenredmodal>

		// 	<Edenredmodal
		// 		{...{
		// 			isOpen: approveModal,
		// 			title: 'Do you wish to approve this order?',
		// 			onOkay: () => updateRunPayrollReview('approve'),
		// 			onCancel: closeApproveModal,
		// 		}}></Edenredmodal>
		// </>

		<>
			<Toast visible={popupVisible} color="#8EF9B3" textcolor="#484B52" clearPopUp={closePopup}>
				{popupMessage.titleMessage}
			</Toast>
			<div className="track_card_order_status_container">
				<div className="employee-status-card-header-contianer">
					<ETypo h2 bold>
						{' '}
						{statusState?.orderStatus?.name} | {statusState?.orderStatus?.cardStatus}
					</ETypo>
					<div className="employee-button-container">
						{count === 1 && (
							<EdenredButtons disabled={count !== 1} onClick={() => setCancelOrderModal(true)} btnType="secondary">
								Cancel Order
							</EdenredButtons>
						)}

						<Link
							style={{ height: '100%', marginRight: '10px' }}
							to={{ pathname: '/employees/card/order/view/details', state: { content: history.state || {} } }}>
							<EdenredButtons btnType="primary">View Order Details</EdenredButtons>
						</Link>
					</div>
				</div>

				<div className="employee-stepper-container">
					<ETypo b1 light>
						Your verifier needs to approve the card order before we can create the cards
					</ETypo>
					<div>
						<Stepper state={contents}></Stepper>
					</div>
				</div>
				<div className="address-box-container">
					<ETypo h5 bold color="#00344E">
						Delivery Address:
					</ETypo>
					<div className="address-section">
						<ImageComponent src={focus_state_icons.addressIcon} />{' '}
						<ETypo b1 light color="#484B52">
							{/* {employeeCardAddress} */}
							{employeeCardAddress.address2}, {employeeCardAddress.address3}, {employeeCardAddress.countryCode}
						</ETypo>
					</div>
				</div>
			</div>
			<Edenredmodal
				{...{
					isOpen: cancelOrderModal,
					title: 'Do you wish to cancel this order?',
					onOkay: () => updateRunPayrollReview('reject'),
					onCancel: closeModal,
					loading: spinLoading,
				}}></Edenredmodal>

			<Edenredmodal
				{...{
					isOpen: approveModal,
					title: 'Do you wish to approve this order?',
					onOkay: () => updateRunPayrollReview('approve'),
					onCancel: closeApproveModal,
				}}></Edenredmodal>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(TrackCardOrderStatus);
