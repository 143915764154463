import React from 'react';
import { useHistory } from 'react-router-dom';
import AddIndividualHeader from '@pages/employees/add-individual-header/add-individual-header';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './add-individual-contract.scss';

const AddIndividualContractPresentational = ({
	empContractDetails,
	handleNextButton,
	disableNextButton,
	empContractSelected,
	handleContractSelect,
	isContractDetailSaveLoading,
}) => {
	const history = useHistory();
	return (
		<div className="emp_individual_contract_detail_main">
			<AddIndividualHeader
				{...{
					showNext: true,
					loadingNext: isContractDetailSaveLoading,
					handleNextButton,
					disableNext: disableNextButton,
					handleBackButton: () => history.push('/employee/add-individual-employee'),
				}}
			/>
			<div className="emp_individual_contract_detail_card">
				<div className="emp_individual_contract_title">
					<ETypo medium h4>
						Job Details
					</ETypo>
				</div>
				<div className="emp_individual_contract_select">
					<ETypo medium b3 color="#181919">
						Contract Type
						<ETypo medium b3 color="#ff9691" style={{ marginLeft: '4px' }}>
							*
						</ETypo>
					</ETypo>
					<div className="emp_individual_contract_radio">
						{empContractSelected?.map((data) => (
							<div className="emp_individual_contract_radio_select" onClick={() => handleContractSelect(data)}>
								<ImageComponent
									src={data?.isSelected ? focus_state_icons.radioButtonActive : focus_state_icons.radioButtonInactive}
								/>
								<ETypo font={data?.isSelected ? 'medium' : 'light'} b2 color={data?.isSelected ? '#484B52' : '#181919'}>
									{data?.name}
								</ETypo>
							</div>
						))}
					</div>
				</div>
				<div className="emp_individual_contract_date">
					{empContractDetails?.map(
						({
							label,
							placeholder,
							Component,
							value,
							param,
							required,
							onChange,
							Option,
							dropDownValue,
							compareDropDownValue,
							hidden,
							id,
							isErrorMessage,
							errorMessage,
						}) => (
							<div key={id} className={`emp_contract_field ${param}`}>
								{hidden && (
									<Component
										{...{
											label,
											placeholder,
											value,
											onChange,
											required,
											Option,
											dropDownValue,
											compareDropDownValue,
											isErrorMessage,
											errorMessage,
										}}
									/>
								)}
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default AddIndividualContractPresentational;
