import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { amountWithNoCommas, arrayToObject, buttonDisable, numberWithCommas, twoDigitAfterDec } from '@helpers';
import { managePayrollClaim } from '@constants/app-constants';
import ClevertapReact from 'src/utils/clever-tap';
import Selectors from '../payroll-setting-selector';
import action from '../payroll-setting-action';
import PayrollSettingPresentational from './payroll-settings-presentational';

const PayrollSettingFunctional = ({
	employeeDetails,
	getEmployeeById,
	payrollLists,
	payrollSetting,
	departmentDetails,
	departmentSaga,
	allowanceDetails,
	allowanceSaga,
	ContractTypeDetails,
	contractTypeSaga,
	payrollSubmitSaga,
	isPayrollLoading,
	isDepartmentLoading,
	isAllowanceLoading,
	isContractTypeLoading,
	handleSavePopUpSaga,
	isPayrollSubmitLoading,
	savedSucceessfulPopUp,
	isSavePopUp,
	savedSuccessfully,
	setNavigateTabCheck,
	navigateTabCheck,
	clearPayrollDetails,
	payrollDetails,
	setPayrollDetails,
	addAllowance,
	setAddAllowance,
	isEnabled,
	setIsEnabled,
	notification,
	setNotification,
}) => {
	const history = useHistory();

	const [addAllowanceFinal, setAddAllowanceFinal] = useState([]);
	const employeeId = employeeDetails?.employeeId;
	const { employeeContracts = {} } = payrollLists;
	const { departmentList = [] } = departmentDetails;
	const { allowanceList = [] } = allowanceDetails;
	const { contractTypeList = [] } = ContractTypeDetails;
	// checking claim
	const payrollClaims = useSelector((state) => state?.sharedStates?.currentUser?.payrollClaims) || [];
	let isPayrollSettingClaim = [];
	const data = payrollClaims?.map((item) => {
		if (managePayrollClaim.includes(item.value)) {
			isPayrollSettingClaim.push(item);
		}
	});
	// to reset the payroll detail
	useEffect(() => {
		return () => {
			clearPayrollDetails();
		};
	}, []);
	useEffect(() => {
		if (employeeContracts && Object?.keys(employeeContracts)?.length !== 0) {
			payrollSettings(employeeContracts, departmentList, allowanceList, contractTypeList);
		} else {
			setIsEnabled({ ...isEnabled, Limited: true, UnLimited: false });
			defaultvalue();
		}
	}, [payrollLists, departmentDetails, allowanceDetails, ContractTypeDetails]);

	const defaultvalue = () => {
		if (isEnabled?.Limited === true) {
			const filtered = contractTypeList?.filter((data) => data?.name.toLowerCase() === 'limited');
			setPayrollDetails({ ...payrollDetails, contractTypeId: filtered?.[0]?.id, contractType: filtered?.[0]?.name });
		}
	};
	useEffect(() => {
		payrollSetting(employeeId);
		departmentSaga();
		allowanceSaga();
		contractTypeSaga();
	}, []);

	useEffect(() => {
		let modifiedData = addAllowance?.map((item, i) => {
			return {
				...item,
				index: i,
			};
		});
		setAddAllowance([...modifiedData]);
	}, [addAllowanceFinal]);
	const allowancevalidation = addAllowance?.every((item) => item.allowance);
	const aedvalidation = addAllowance?.every((item) => item.aed && item.aed != 0);
	const payrollSettings = (employeeContracts, departmentList, allowanceList, contractTypeList) => {
		const startDate = employeeContracts?.startDate != null ? moment(employeeContracts?.startDate).format('DD/MM/YYYY') : '';
		const endDate = employeeContracts?.endDate != null ? moment(employeeContracts?.endDate).format('DD/MM/YYYY') : '';
		const salary = employeeContracts?.employeeBasicSalary != null ? employeeContracts?.employeeBasicSalary?.value : '';
		setPayrollDetails({
			...payrollDetails,
			joiningDate: startDate,
			endDate: endDate,
			designation: employeeContracts?.designation,
			salary: numberWithCommas(salary) || 0,
			id: employeeContracts?.id,
		});
		const concateDepartment = departmentList?.filter((datas, i) => datas?.id === employeeContracts?.departmentId);
		const ndepart = concateDepartment?.map((data) => {
			setPayrollDetails((prev) => ({ ...prev, departmentId: data?.id, department: data?.name }));
		});
		const concateContract = contractTypeList?.filter((datas, i) => datas?.name === employeeContracts?.contractType);
		const nContract = concateContract?.map((data) => {
			setIsEnabled({ [data?.name]: true });
			setPayrollDetails((prev) => ({ ...prev, contractTypeId: data?.id, contractType: data?.name }));
		});
		let concateArray = employeeContracts?.employeeAllowances?.filter((o1) =>
			allowanceList.some((o2) => o1.allowanceTypeId === o2.id && (o1.allowanceTypeName = o2.name))
		);
		let modifiedData = concateArray?.map((concateArray, i) => {
			return {
				allowance: concateArray?.allowanceTypeName,
				aed: numberWithCommas(concateArray?.value) || 0,
				allowanceId: concateArray?.allowanceTypeId,
				index: i,
				id: concateArray?.id,
			};
		});
		setAddAllowance(modifiedData);
	};
	const handlePayrollDate = (dateObj, formatStr, value) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '3',
		});
		setNotification(true);
		setPayrollDetails((prev) => ({ ...prev, [value]: formatStr }));
	};
	const handleRadioButton = (values, id) => {
		setPayrollDetails((prev) => ({ ...prev, contractTypeId: id, contractType: values }));
		if (values === 'Limited') {
			setIsEnabled({ ...isEnabled, Limited: true, UnLimited: false });
		} else if (values === 'UnLimited') {
			setIsEnabled({ ...isEnabled, Limited: false, UnLimited: true });
		}
	};
	const handleDropDown = (data, value, i) => {
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '3',
		});
		if (value != 'allowance') {
			setPayrollDetails((prev) => ({ ...prev, [value]: data?.name, departmentId: data?.id }));
		} else if (value === 'allowance') {
			setIsEnabled({ ...isEnabled, disAedAble: false });
			let modifiedData = addAllowance?.map((d) => {
				if (d?.index === i) {
					return { ...d, [value]: data?.name, allowanceId: data?.id };
				}
				return d;
			});
			setAddAllowance([...modifiedData]);
		}
	};
	const handleInput = (e, value, i) => {
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '3',
		});
		if (value !== 'aed') {
			let input = e?.target?.value;
			setPayrollDetails((prev) => ({ ...prev, [value]: twoDigitAfterDec(input) }));
		} else if (value === 'aed') {
			setIsEnabled({ ...isEnabled, disAedAble: false });
			let input = e?.target?.value;
			let modifiedData = addAllowance?.map((d) => {
				if (d?.index === i) {
					return { ...d, [value]: twoDigitAfterDec(input) };
				}
				return d;
			});
			setAddAllowance([...modifiedData]);
		}
	};
	const handleAddAllowance = () => {
		setIsEnabled({ ...isEnabled, disAedAble: false });
		if (allowancevalidation && aedvalidation) {
			setAddAllowance((prev) => [...prev, { ...addAllowance, index: addAllowance?.length }]);
		}
	};
	const handleDeleteAllowance = (i) => {
		const values = [...addAllowance];
		values.splice(i, 1);
		setAddAllowance(values);
		setAddAllowanceFinal(values);
	};
	const handleButton = (value) => {
		const cleverTapProperties = {
			EmployeeID: employeeDetails?.employeeId,
			EmployeeName: employeeDetails?.name,
			AccountType: employeeDetails?.accountTypeDescription,
			JoiningDate: payrollDetails?.joiningDate,
			ContractType: payrollDetails?.contractType,
			ContractEndDate: payrollDetails?.endDate,
			Department: payrollDetails?.department,
			Designation: payrollDetails?.designation,
			BasicSalary: payrollDetails?.salary,
			...arrayToObject(addAllowance, 'aed', 'Allowance', true),
		};
		if (value === 'save') {
			ClevertapReact.event('Save_PayrollSettings', { cleverTapProperties });
			setIsEnabled({ ...isEnabled, disAedAble: true });
			if (allowancevalidation && aedvalidation) {
				handleSavePopUpSaga(true);
			}
		} else if (value === 'cancel') {
			setIsEnabled({ ...isEnabled, cancelPopUp: true });
		}
	};
	const employeeID = employeeDetails?.id;
	const handlePayrollPopup = (value) => {
		switch (value) {
			case 'closeSavePopUp': {
				handleSavePopUpSaga(false);
				break;
			}
			case 'handleSavePopUp': {
				setNotification(false);
				setNavigateTabCheck({
					...navigateTabCheck,
					isTabClicked: false,
					tabKey: '3',
				});
				const startDate = payrollDetails?.joiningDate.split('/').reverse().join('-');
				const endDate = payrollDetails?.endDate.split('/').reverse().join('-') || '';
				const response = addAllowance?.map((data) => {
					return {
						id: '',
						allowanceTypeId: data?.allowanceId,
						allowanceTypeName: data?.allowance,
						value: parseFloat(amountWithNoCommas(data?.aed)),
					};
				});
				const payload = {
					setting: {
						id: payrollDetails?.id || '',
						employeeId: employeeId,
						startDate: startDate,
						endDate: payrollDetails?.contractType === 'Limited' ? endDate : '',
						contractTypeId: payrollDetails?.contractTypeId,
						contractType: payrollDetails?.contractType,
						departmentId: payrollDetails?.departmentId,
						designation: payrollDetails?.designation,
						basicSalary: parseFloat(amountWithNoCommas(payrollDetails?.salary)),
						allowanceList: response || [],
					},
				};
				payrollSubmitSaga(payload);
				break;
			}
			case 'closeCancelPopUp': {
				setIsEnabled({ ...isEnabled, cancelPopUp: false });
				break;
			}
			case 'handleCancelPopUp': {
				setNotification(false);
				setNavigateTabCheck({
					...navigateTabCheck,
					isTabClicked: false,
					tabKey: '3',
				});
				if (employeeContracts && Object?.keys(employeeContracts)?.length !== 0) {
					payrollSettings(employeeContracts, departmentList, allowanceList, contractTypeList);
				} else {
					setPayrollDetails({
						...payrollDetails,
						employeeId: '',
						joiningDate: '',
						endDate: '',
						contractTypeId: '',
						contractType: '',
						departmentId: '',
						department: '',
						designation: '',
						salary: '',
					});
					setIsEnabled({ ...isEnabled, Limited: true, UnLimited: false });
					setAddAllowance([
						{
							allowanceId: '',
							allowance: '',
							aed: 0,
							index: 0,
							id: '',
						},
					]);
				}
				setIsEnabled({ ...isEnabled, cancelPopUp: false });
				break;
			}
			case 'closeSuccessPopUp': {
				savedSucceessfulPopUp(false);
				getEmployeeById(employeeID);
				history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '3' } });
				break;
			}
		}
	};
	const totalValue = useMemo(() => {
		let grossSalary = 0;
		let grossValue = 0;
		let basicSalary = parseFloat(payrollDetails?.salary?.toString().replace(/,/g, '')) || 0;
		for (let i = 0; i < addAllowance?.length; i++) {
			grossSalary += parseFloat(addAllowance?.[i]?.aed?.toString().replace(/,/g, '')) || 0;
		}
		return (grossValue = basicSalary + grossSalary);
	}, [payrollDetails, addAllowance]);
	const allowanceListed = useMemo(() => {
		return allowanceList?.filter(({ id: id1 }) => addAllowance?.some(({ allowanceId: id2 }) => id2 !== id1));
	}, [allowanceList, addAllowance]);
	const disable = useMemo(() => buttonDisable(payrollDetails, addAllowance, employeeContracts, isEnabled), [payrollDetails, addAllowance]);
	return (
		<PayrollSettingPresentational
			{...{
				departmentList,
				handleInput,
				allowanceListed,
				handlePayrollDate,
				handleRadioButton,
				isEnabled,
				handleDropDown,
				payrollDetails,
				handleAddAllowance,
				handleDeleteAllowance,
				handleButton,
				handlePayrollPopup,
				addAllowance,
				totalValue,
				disable,
				contractTypeList,
				isPayrollLoading,
				isDepartmentLoading,
				isAllowanceLoading,
				isContractTypeLoading,
				savedSuccessfully,
				isPayrollSubmitLoading,
				isSavePopUp,
				notification,
				allowancevalidation,
				aedvalidation,
				employeeDetails,
				isPayrollSettingClaim,
				allowanceList,
			}}
		/>
	);
};
const mapStateToProps = (state) => {
	return {
		isPayrollLoading: Selectors.isPayrollLoading(state),
		payrollLists: Selectors.payrollLists(state),
		isDepartmentLoading: Selectors.isDepartmentLoading(state),
		departmentDetails: Selectors.departmentDetails(state),
		isAllowanceLoading: Selectors.isAllowanceLoading(state),
		allowanceDetails: Selectors.allowanceDetails(state),
		isContractTypeLoading: Selectors.isContractTypeLoading(state),
		ContractTypeDetails: Selectors.ContractTypeDetails(state),
		isPayrollSubmitLoading: Selectors.isPayrollSubmitLoading(state),
		isSavePopUp: Selectors.settingSavePopUp(state),
		savedSuccessfully: Selectors.savedSettingSuccessfully(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		clearPayrollDetails: () => dispatch(action.creators.clearPayroll()),
		payrollSetting: (employeeId) => dispatch(action.creators.getPayrollStart(employeeId)),
		departmentSaga: () => dispatch(action.creators.getDepartmentStart()),
		allowanceSaga: () => dispatch(action.creators.getAllowanceStart()),
		contractTypeSaga: () => dispatch(action.creators.getContractTypeStart()),
		payrollSubmitSaga: (payload) => dispatch(action.creators.getPayrollSubmitStart(payload)),
		handleSavePopUpSaga: (flag) => dispatch(action.creators.handleSettingSavePopUp(flag)),
		savedSucceessfulPopUp: (flag) => dispatch(action.creators.savedSettingSucceessfulPopUp(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(PayrollSettingFunctional);
export default Connected;
