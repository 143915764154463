import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import VideoTutorialService from 'src/services/videoTutorial-service';
import VideoPlayerPopUpModal from '../../video-player-modal/videoPlayerPopUpModal';
import '../video-tutorial.scss';
import VideoSegmentContainer from './videoSegmentContainer';
import { videoList } from './video-list';

const VideoTutorialPresentational = () => {
	// const _videoTutorialService = new VideoTutorialService();
	const [isvideoModalVisible, setIsvideoModalVisible] = useState(false);
	const [isVideoPaused, setIsVideoPaused] = useState(true);
	const [video, setVideo] = useState();
	// const [allFolderDetails, setAllFolderDetails] = useState([]);

	// const { state } = useLocation();
	let history = useHistory();

	// useEffect(() => {
	// 	fetchAllFolders();
	// }, []);
	// const fetchAllFolders = async () => {
	// 	try {
	// 		let allfolders = await _videoTutorialService.getAlluserFolders({});
	// 		let folders = allfolders;
	// 		let allFolderDetail = [];
	// 		folders.forEach((element) => {
	// 			allFolderDetail.push({ name: element?.name, folderId: element?.url.split('/')[4] });
	// 		});
	// 		setAllFolderDetails(allFolderDetail);
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	const loadVideo = (link) => {
		setVideo(link);
		setIsvideoModalVisible(true);
	};

	const closeVideo = () => {
		setIsVideoPaused(true);
		setTimeout(() => {
			setIsvideoModalVisible(false);
		}, 100);
	};

	const startVideo = () => setIsVideoPaused(false);

	return (
		<>
			<VideoPlayerPopUpModal
				visible={isvideoModalVisible}
				video={video}
				setIsVideoPaused={setIsVideoPaused}
				closeVideo={closeVideo}
				isVideoPaused={isVideoPaused}
				startVideo={startVideo}
			/>

			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="video_tutorial_container">
				<Row className="header_container">
					<EdenredTypo className="header_text">Edenred Payroll Video Tutorials</EdenredTypo>
				</Row>
				<Row className="content_container">
					{videoList ? (
						videoList.map((res) => {
							return (
								<VideoSegmentContainer
									title={res.name}
									folderId={res.videoUrl}
									loadVideo={loadVideo}
									length={res.videoUrl?.length}></VideoSegmentContainer>
							);
						})
					) : (
						<div className="no_folder_message_container">
							<EdenredTypo className="header_text">No Folders found</EdenredTypo>
						</div>
					)}
				</Row>
			</Col>
		</>
	);
};

export default VideoTutorialPresentational;
