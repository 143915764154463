import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';

const ViewSalaryHistory = () => {
	const salaryhistory = [
		{
			payroll: 'WPS Payroll July 2020',
			amount: 'AED 2,500',
		},
		{
			payroll: 'WPS Payroll July 2020',
			amount: 'AED 2,500',
		},
		{
			payroll: 'WPS Payroll July 2020',
			amount: 'AED 2,500',
		},
	];
	const columnKeys = [
		// { labelKey: 'Channel / Month', valueKey: 'displayMonth' },
		// { labelKey: 'Days On Leave', valueKey: 'daysOnLeave' },
		// { labelKey: 'Amount', valueKey: 'displayAmount' },
		// { labelKey: 'Variable Pay', valueKey: 'variablePay' },
		{ labelKey: 'Sl. No.', valueKey: 'serialNo' },
		{ labelKey: 'Emp Id', valueKey: 'employeCode' },
		{ labelKey: 'First Name', valueKey: 'firstName' },
		{ labelKey: 'Last Name', valueKey: 'lastName' },
		{ labelKey: 'Salary', valueKey: 'salary' },
		{ labelKey: 'Processed Date', valueKey: 'processedDateModified' },
		{ labelKey: 'Type', valueKey: 'channel' },
		{ labelKey: 'Month/Year', valueKey: 'monthYear' },
		{ labelKey: 'WPS Person Id', valueKey: 'wpsPersonId' },
		{ labelKey: 'WPS Establishment Id', valueKey: 'wpsEstablishmentId' },
	];
	const { state } = useLocation();

	useEffect(() => {
		console.log({ state });
	}, []);
	return (
		<>
			<EdenredHeader />
			<Row className="view-salary-history-container">
				<Col xl={24} lg={24} md={24} xs={24} sm={24} className="view-salary-history-header">
					<EdenredTypo>{state.employeeHistory[0].employeeName}</EdenredTypo>
				</Col>
				{state.employeeHistory && state.employeeHistory.length > 0 ? (
					state.employeeHistory.map((history, index) => {
						return (
							<Col xl={24} lg={24} md={24} xs={24} sm={24} className="view-salary-history-card">
								<EdenredTypo className="main-payroll">{history.displayMonth}</EdenredTypo>
								<EdenredTypo className="amount">{history.displayAmount}</EdenredTypo>
							</Col>
						);
					})
				) : (
						<div style={{ textAlign: 'center', color: '#ddd', width: '100%' }}>No data found</div>
					)}
				{/* <Col xl={24} lg={24} md={24} xs={24} sm={24} className="view-salary-history">
					<EdenredButton>Download Salary History</EdenredButton>
				</Col> */}
				{state.employeeHistory && state.employeeHistory.length ? (
					<Col xl={24} lg={24} md={24} xs={24} sm={24} className="view-salary-history">
						<ExcelDownload
							dataSet={state.employeeHistory}
							columnKeys={columnKeys}
							className="view-salary-history"
							filename={`Salary_History`}
							element={<EdenredButton>Download Salary History</EdenredButton>}
						/>
					</Col>
				) : null}
			</Row>
		</>
	);
};
export default ViewSalaryHistory;
