import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './payroll-setting-reducer';
import saga from './saga/index';
import PayrollSettingFunctional from './component/payroll-setting-functional';

function PayrollSetting({
	employeeDetails,
	getEmployeeById,
	setNavigateTabCheck,
	navigateTabCheck,
	payrollDetails,
	setPayrollDetails,
	addAllowance,
	setAddAllowance,
	isEnabled,
	setIsEnabled,
	notification,
	setNotification,
}) {
	return (
		<PayrollSettingFunctional
			{...{
				employeeDetails,
				getEmployeeById,
				setNavigateTabCheck,
				navigateTabCheck,
				payrollDetails,
				setPayrollDetails,
				addAllowance,
				setAddAllowance,
				isEnabled,
				setIsEnabled,
				notification,
				setNotification,
			}}
		/>
	);
}
const ReducedScreen = withReducer('payrollReducer', reducer, saga)(PayrollSetting);
export default ReducedScreen;
