import React from 'react';
import ETypo from '../Typo';
import './hintcard.scss';

const HintCard = ({ icon = '', bgColor = '', hint = '', hintColor = '' }) => {
	return (
		<div className="hint_card" style={{ background: bgColor }}>
			<img src={icon} />
			<ETypo light b1 color={hintColor}>
				{hint}
			</ETypo>
		</div>
	);
};

export default HintCard;
