import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EdenredTypo from '@sharedComponent/typography';
import UploadAttachment from '@pages/Leave-Management/request-leave/component/upload-attachment';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Counter from '@pages/DesignSystem/counter';
import ETypo from '@pages/DesignSystem/Typo';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import action from '../OverTimeAction';
import Toast from '@pages/DesignSystem/Toast';

const CompanyTabPane1 = ({
	allowancesArray,
	handleNewArray,
	departmentArray,
	monthState,
	handleMonthIncrement,
	additionArray,
	deductionsArray,
	setFileUpload,
	handleSuccessPopups,
	setIsDisabledComp,
	isCompanySaveSuccess,
	handleNewArray1,
	setupload,
	upload,
	dragandDropEdit,
	saveGetUploadLogoLoading,
	saveGetUploadLogoStatus,
	getUploadFile,
	isSetUp,
	saveCompanySettingLoading,
	popupvisible,
	setPopupvisible,
	vm,
}) => {
	const dispatch = useDispatch();
	// const isPopupVisible = useSelector((state) => state?.companySettingReducer?.companyAddEditPopUp);
	const isPopupVisible = popupvisible;
	const [message, setMessage] = useState('');
	const [label, setLabel] = useState('');
	const [editValue, setEditValue] = useState('');
	const [value, setValue] = useState('');
	const [dublicateArray, setDublicateArray] = useState();
	const [duplicate, setDuplicate] = useState(false);

	console.log('sdsad', isPopupVisible);
	const handleAddClick = (message, label, data, index, arrayData) => {
		// dispatch(action.creators.companyAddEditOrg(true));

		setMessage(message);
		setLabel(label);
		setEditValue(data?.name);
		setValue(index);
		setDublicateArray(arrayData);
		setPopupvisible(true);
	};
	const closePopup = () => {
		setDuplicate(false);
		setPopupvisible(false);
		// dispatch(action.creators.companyAddEditOrg(false));
	};
	const handleDepartmentPopup = (e) => {
		const value = e?.target?.value;
		const array = [];
		const isExists = dublicateArray?.filter((data) => {
			array.push(data?.name?.toLowerCase());
		});
		const duplicate = array?.includes(value?.toLowerCase());
		setDuplicate(duplicate);
		setEditValue(value);
	};
	const handleDepartmentSave = () => {
		if (message?.includes('Add New')) {
			console.log('sd1');
			handleNewArray(editValue, label);
			cleverTapEvent(label);
		} else {
			handleNewArray1(editValue, label, value);
		}
	};
	const cleverTapEvent = (value) => {
		switch (value) {
			case 'Department':
				ClevertapReact.event(isSetUp ? 'setup_add_department' : 'add_department', { editValue });
				break;
			case 'Allowance':
				ClevertapReact.event(isSetUp ? 'setup_add_allowances' : 'add_allowances', { editValue });
				break;
			case 'Addition':
				ClevertapReact.event(isSetUp ? 'setup_add_additions' : 'add_additions', { editValue });
				break;
			case 'Deduction':
				ClevertapReact.event(isSetUp ? 'setup_add_deductions' : 'add_deductions', { editValue });
				break;
			default:
				break;
		}
	};

	return (
		<>
			<Toast place={'bottom'} visible={vm} color="#8EF9B3" textcolor="#484B52" clearPopUp={handleSuccessPopups}>
				Company Settings Successfully Updated
			</Toast>
			<Row className="company-setting-tabpane-row1">
				<Col xl={24} className="company-setting-column1">
					<Col xl={24} className="company-setting-column1-sub1-main">
						<EdenredTypo className="title-color h5 bold">Departments / Sites</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub2">
						<EdenredTypo className="subtext-company h5 regular">
							You can use this to set approval workflows for leave and salary reports.
						</EdenredTypo>
					</Col>
					{!!departmentArray?.length && (
						<Row className="company-setting-column1-sub1">
							{departmentArray?.map((data, index) => (
								<Col
									className="render-addition"
									key={index}
									onClick={() => handleAddClick('Edit Department', 'Department', data, index, departmentArray)}>
									<div>
										<EdenredTypo className="medium render-ad-text">{data?.name}</EdenredTypo>
									</div>
									<div>
										<img
											src={not_focus_icons.NewEdit}
											alt=""
											style={{ width: '15px', height: '15px', marginLeft: '7px', cursor: 'pointer' }}
										/>{' '}
									</div>
								</Col>
							))}
						</Row>
					)}
					<Col xl={24} className="company-setting-column1-sub3 button-with-252">
						<EdenredButtons
							className="company-setting-addBtn"
							btnType="secondary"
							onClick={() => handleAddClick('Add New Department', 'Department', '', '', departmentArray)}>
							<img style={{ marginRight: '8px' }} src={not_focus_icons.plusSvg} alt="" className="plus-img-svg" />
							Add New Department / Site
						</EdenredButtons>
					</Col>
				</Col>
				<Col xl={24} className="company-setting-column1 company-setting-column2">
					<Col xl={24} className="company-setting-column1-sub1-main">
						<EdenredTypo className="title-color h5 bold">Allowances</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub2">
						<EdenredTypo className="subtext-company h5 regular">
							You can use this to set approval workflows for leave and salary reports.
						</EdenredTypo>
					</Col>
					{!!allowancesArray?.length && (
						<Row className="company-setting-column1-sub1">
							{allowancesArray?.map((data, index) => (
								<Col
									className="render-addition"
									key={index}
									onClick={() => handleAddClick('Edit Department', 'Allowance', data, index, allowancesArray)}>
									<div>
										<EdenredTypo className="medium render-ad-text">{data.name}</EdenredTypo>
									</div>
									<div>
										{data.name === 'HRA' || data.name === 'Transport' ? null : (
											<img
												src={not_focus_icons.NewEdit}
												alt=""
												style={{ width: '15px', height: '25px', marginLeft: '8px', cursor: 'pointer' }}
											/>
										)}
									</div>
								</Col>
							))}
						</Row>
					)}
					<Col xl={24} className="company-setting-column1-sub3 button-with-200">
						<EdenredButtons
							className="company-setting-addBtn"
							btnType="secondary"
							onClick={() => handleAddClick('Add New Allowance', 'Allowance', '', '', allowancesArray)}>
							<img style={{ marginRight: '8px' }} src={not_focus_icons.plusSvg} alt="" className="plus-img-svg" />
							Add New Allowance
						</EdenredButtons>
					</Col>
				</Col>
				<Col xl={24} className="company-setting-column1 company-setting-column2">
					<Col xl={24} className="company-setting-column1-sub1-main">
						<EdenredTypo className="title-color h5 bold">Additions</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub2">
						<EdenredTypo className="subtext-company h5 regular">
							Set monthly additions to your employees' payroll, Eg: Commission, Bonus.
						</EdenredTypo>
					</Col>
					{!!additionArray?.length && (
						<Row className="company-setting-column1-sub1">
							{additionArray?.map((data, index) => (
								<Col
									className="render-addition"
									key={index}
									onClick={() => handleAddClick('Edit Department', 'Addition', data, index, additionArray)}>
									<div>
										<EdenredTypo className="medium render-ad-text">{data.name}</EdenredTypo>
									</div>
									<div>
										<img
											src={not_focus_icons.NewEdit}
											alt=""
											style={{ width: '15px', height: '25px', marginLeft: '8px', cursor: 'pointer' }}
										/>{' '}
									</div>
								</Col>
							))}
						</Row>
					)}
					<Col xl={24} className="company-setting-column1-sub3 button-with-200">
						<EdenredButtons
							className="company-setting-addBtn"
							btnType="secondary"
							onClick={() => handleAddClick('Add New Addition', 'Addition', '', '', additionArray)}>
							<img style={{ marginRight: '8px' }} src={not_focus_icons.plusSvg} alt="" className="plus-img-svg" />
							Add New Addition
						</EdenredButtons>
					</Col>
				</Col>
				<Col xl={24} className="company-setting-column1 company-setting-column2">
					<Col xl={24} className="company-setting-column1-sub1-main">
						<EdenredTypo className="title-color h5 bold">Deductions</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub2">
						<EdenredTypo className="subtext-company h5 regular">
							Set monthly deductions to your employees' payroll, Eg: Traffic Fines, Loans.
						</EdenredTypo>
					</Col>
					{!!deductionsArray?.length && (
						<Row className="company-setting-column1-sub1">
							{deductionsArray?.map((data, index) => (
								<Col
									className="render-addition"
									key={index}
									onClick={() => handleAddClick('Edit Department', 'Deduction', data, index, deductionsArray)}>
									<div>
										<EdenredTypo className="medium render-ad-text">{data.name}</EdenredTypo>
									</div>
									<div>
										<img
											src={not_focus_icons.NewEdit}
											alt=""
											style={{ width: '15px', height: '25px', marginLeft: '8px', cursor: 'pointer' }}
										/>{' '}
									</div>
								</Col>
							))}
						</Row>
					)}
					<Col xl={24} className="company-setting-column1-sub3 button-with-200">
						<EdenredButtons
							className="company-setting-addBtn"
							btnType="secondary"
							onClick={() => handleAddClick('Add New Deduction', 'Deduction', '', '', deductionsArray)}>
							<img style={{ marginRight: '8px' }} src={not_focus_icons.plusSvg} alt="" className="plus-img-svg" />
							Add New Deduction
						</EdenredButtons>
					</Col>
				</Col>
				<Col xl={24} className="company-setting-column1 company-setting-column3">
					<Col xl={24} className="company-setting-column1-sub1-main">
						<EdenredTypo className="title-color h5 bold">Probation Period</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub2">
						<EdenredTypo className="subtext-company h5 regular">
							During this period, employees will not be eligible to apply for annual leave and sick leave
						</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub3 company-setting-column3-sub3">
						<div className="inc-dec-box">
							<Counter
								grey={true}
								width="146px"
								twoButton={true}
								handleIncrement={() => handleMonthIncrement('plus')}
								handleDecrement={() => handleMonthIncrement('decrement')}
								content={monthState?.months}
							/>
							<ETypo light b1 className="month-text">
								Months
							</ETypo>
						</div>
					</Col>
				</Col>
				<Col xl={24} className="company-setting-column1 company-setting-column4">
					<Col xl={24} className="company-setting-column1-sub1-main">
						<EdenredTypo className="title-color h5 bold">Upload Your Company Logo</EdenredTypo>
					</Col>
					<Col xl={24} className="company-setting-column1-sub2">
						<EdenredTypo className="subtext-company h5 regular">This will be used in your company’s payslips.</EdenredTypo>
					</Col>
					<Col xl={24}>
						<UploadAttachment
							{...{
								setFileUpload,
								uploadLoad: saveGetUploadLogoLoading,
								getUploadFile,
								saveGetUploadLogoStatus,
								setupload,
								dragandDropEdit,
								upload,
								setIsDisabledComp,
								filesize: 'Max file size:4mb',
								fileFormat: '(PNG, JPEG, JPG)',
							}}
							companySettingUpload="true"
							className="company-setting-dragger"
						/>
					</Col>
				</Col>
				<Edenredmodal
					{...{
						loading: saveCompanySettingLoading,
						className: 'payroll-run-company-modal',
						isOpen: isPopupVisible,
						fieldlabel: message,
						close: closePopup,
						onCancel: closePopup,
						onOkay: handleDepartmentSave,
						onOkayDisable: !editValue || duplicate,
						field: `Enter ${label}`,
						fieldValue: editValue,
						onOkayButton: 'Save',
						onCancelButton: 'Cancel',
						fieldonchange: handleDepartmentPopup,
						errorMessage: duplicate ? `${label} already exists. Add a different ${label}.` : '',
					}}
				/>
				{/* <Edenredmodal
				{...{ onOkayButton: 'OK', onOkay: handleSuccessPopups, isOpen: isCompanySaveSuccess.flag, title: isCompanySaveSuccess.message }}
			/> */}
			</Row>
		</>
	);
};

export default CompanyTabPane1;
