import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import { useLocation } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import EmployeeService from 'src/services/employee-service';
import { getDateFormat, setDateFormat } from '@helpers';
import { successToast } from 'src/utils/toast';
import { MESSAGES } from 'src/utils/toast-messages';
import moment from 'moment';
import Popup from 'src/utils/popup/popups';
const EditProfile = ({ history }) => {
	const [form] = Form.useForm();
	const [employeeDetails, setEmployeeDetails] = useState({});
	const [isShowLoader, setIsShowLoader] = useState(false);
	let [maxDobDate, setMaxDobDate] = useState(null);
	const [isDobActive, setDobActive] = useState(true);
	const { state } = useLocation();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	console.log(state);
	useEffect(() => {
		if (history.location.state.empDetails) {
			let finalResp = history.location.state.empDetails;
			// finalResp.dateOfBirth = setDateFormat(finalResp.dateOfBirth);

			setEmployeeDetails(finalResp);
		}
		setMaxDobDate(moment(new Date()).format('YYYY-MM-DD'));
	}, []);

	const onFinish = async (values) => {
		setIsShowLoader(true);
		let employeeApi = new EmployeeService();
		try {
			let payload = { ...values };
			payload.firstName = employeeDetails.firstName;
			payload.lastName = employeeDetails.lastName;
			payload.nationality = employeeDetails.nationality;
			payload.dateOfBirth = getDateFormat(payload.dateOfBirth);
			let response = await employeeApi.updateEmployee(payload, employeeDetails.id);

			if (response && response.status === 200) {
				// successToast({
				// 	msg: response.data.message || MESSAGES.UPDATE_PROFILE_SUC,
				// 	icon: 'Mail',
				// });
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: response.data.message || MESSAGES.UPDATE_PROFILE_SUC,
					subMessage: null,
				});
			}
		} catch (err) {
			setIsShowLoader(false);
		}
	};
	const handleChange = () => {
		setDobActive(false);
	};
	let user = moment(employeeDetails.dateOfBirth).format();

	const closePopup = () => {
		setpopupVisible(false);
		history.push({ pathname: '/employees/viewemployee', state: { ...state } });
	};
	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
			<Row className="edit-profile-container">
				<Col xl={24} md={24} lg={24} className="edit-profile-header">
					<EdenredTypo>Edit Profile</EdenredTypo>
				</Col>
				{employeeDetails.id && (
					<Form form={form} onFinish={onFinish}>
						<Col xl={24} md={24} lg={24}>
							<Row>
								<Col xl={12} md={12} lg={12} className="edit-profile-label">
									<Form.Item>
										<Input labels="First Name" disabled value={employeeDetails.firstName} />
									</Form.Item>
								</Col>
								<Col xl={12} md={12} lg={12} className="edit-profile-label">
									<Form.Item>
										<Input labels="Last Name" disabled value={employeeDetails.lastName} />
									</Form.Item>
								</Col>
								<Col xl={12} md={12} lg={12} className="edit-profile-label">
									<Form.Item>
										<Input labels="Nationality" disabled value={employeeDetails.nationality} />
									</Form.Item>
								</Col>
								<Col xl={12} md={12} lg={12} className="edit-profile-label">
									<Form.Item>
										<Input labels="Employee ID" disabled value={employeeDetails.employeeId} />
									</Form.Item>
								</Col>
								<Col xl={12} md={12} lg={12} className="edit-profile-label">
									<Form.Item>
										<Input labels="Payroll Channel" disabled value={employeeDetails.salaryChannel} />
									</Form.Item>
								</Col>
								<Col xl={12} md={12} lg={12} className="edit-profile-input-date">
									<Form.Item
										name="dateOfBirth"
										initialValue={moment(user).format('YYYY-MM-DD')}
										// rules={[{ required: true, message: 'Please provide the Date Of Birth' }]}
									>
										<Input
											type="date"
											onChange={handleChange}
											labels="Date Of Birth"
											maxDate={maxDobDate}
											value={moment(user).format('YYYY-MM-DD')}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Row className="edit-profile-number">
							<Col className="edit-profile-frame">
								<ImageComponent style={{ paddingRight: '10px' }} src={images.Frame} /> +971
							</Col>

							<Col xl={10} md={12} lg={12} className="edit-profile-input">
								<Form.Item
									disabled
									name="mobileNumber"
									initialValue={employeeDetails.mobileNo}
									rules={[
										{
											max: 10,
											message: 'Max length should be 10',
										},
									]}>
									<Input labels="Mobile Number" disabled />
								</Form.Item>
							</Col>
						</Row>

						<Col xl={12} md={12} lg={12} className="edit-profile-save">
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										loading={isShowLoader}
										disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
										disabled={isDobActive}>
										Update
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				)}
			</Row>
		</>
	);
};
export default EditProfile;
