import React from 'react';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import './payroll-select-dropdown.scss';

export const PayrollSelectDropdown = ({ placeholder, Option, onChange, disable, dropDownLabel = '', dropDownValue = '', value = '' }) => {
	return (
		<>
			<MultiSelect
				{...{
					placeholder,
					onChange,
					disabled: disable,
					dropDownValue,
					value,
					Option,
					label: dropDownLabel,
					searchVisibility: false,
				}}
			/>
		</>
	);
};
