import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PayrollService from 'src/services/payroll-service';
import PayrollTimingsPresentational from './payroll-timings-presentational';

const PayrollTimingsFunctional = () => {
	const [state, setstate] = useState({
		loading: true,
		payrollTimings: null,
		payrollTimingsNonWPS: null,
		leaveDays: null,
	});

	const api = new PayrollService();

	const getPayrollTimings = () => {
		api.getPayrollTiming()
			.then((result) => {
				const payrollTimings = (result.data.payoutTimings || []).map((timing) => {
					return {
						salaryfundrecieved: `Before ${moment(timing.endTime, "'HH:mm:ss'").add(1, 'hour').format('h A')}`,
						expectedPayout: timing.c3WPS,
					};
				});
				const payrollTimingsNonWPS = (result.data.payoutTimings || []).map((timing) => {
					return {
						salaryfundrecieved: `Before ${moment(timing.endTime, "'HH:mm:ss'").add(1, 'hour').format('h A')}`,
						expectedPayout: timing.c3NonWPS,
					};
				});
				payrollTimings.unshift({ salaryfundrecieved: 'Salary Fund Received', expectedPayout: 'Expected Payout' });
				payrollTimingsNonWPS.unshift({ salaryfundrecieved: 'Salary Fund Received', expectedPayout: 'Expected Payout' });
				setstate({
					...state,
					loading: false,
					payrollTimings,
					payrollTimingsNonWPS,
					leaveDays: result.data.leaveDays.replace(',', ' - '),
				});
			})
			.catch((err) => {
				setstate({
					...state,
					loading: false,
				});
			});
	};
	useEffect(() => {
		getPayrollTimings();
	}, []);

	const { loading, payrollTimings, payrollTimingsNonWPS, leaveDays } = state;

	return (
		<PayrollTimingsPresentational
			leaveDays={leaveDays}
			loading={loading}
			payrollTimings={payrollTimings}
			payrollTimingsNonWPS={payrollTimingsNonWPS}
		/>
	);
};

export default PayrollTimingsFunctional;
