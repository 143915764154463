import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './payslip-main.scss';

const PayslipPresentational = ({ paySlipData, handleDownload }) => {
	const yearsData = useMemo(() => {
		let years = [];
		paySlipData.map((data) => years.push(data.salaryYear));
		let finalYears = [...new Set(years)];
		return finalYears;
	}, [paySlipData]);
	let height = window.innerHeight - 300;
	return (
		<>
			<Row className="payslip-new">
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Row className="payslip-content">
						<Col xl={24} lg={24} md={24} sm={22} xs={22} className="payslip-content-container">
							<ETypo bold h2>
								Payslips
							</ETypo>
							<ETypo light b2 color="#484B52" style={{ marginTop: '8px' }}>
								Get your payslips for a month where you received salaries.
							</ETypo>
						</Col>
					</Row>
					{paySlipData?.length === 0 ? (
						<Row className="payslip-container">
							<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payslip_no_record">
								<ImageComponent src={focus_state_icons?.payslipNoRecord} />
								<ETypo medium h5 color="#484B52" style={{ marginTop: '16px' }} className="payslip-without-content-head">
									No Payslips Found
								</ETypo>
								<ETypo light b1 color="#484B52" style={{ marginTop: '8px' }} className="payslip-without-content-text">
									Payslips will be available starting next salary credit.
								</ETypo>
							</Col>
						</Row>
					) : (
						<Row className="payslip-content-mock">
							<Col xl={24} lg={24} md={24} sm={22} xs={22} className="payslip-content-container">
								{yearsData?.map((data) => (
									<div className="payslip_record_container">
										<div className="payslip-content-year">
											<ETypo medium b2 className="payslip-year-text">
												{data}
											</ETypo>
										</div>
										{paySlipData?.map((items, index) => (
											<>
												{data === items?.salaryYear ? (
													<div className="payslip-content-download">
														<div
															className="payslip-content-container-div"
															onClick={() => handleDownload(data, items?.salaryMonth)}>
															<div className="payslip_download">
																<img src={focus_state_icons?.payslipDownload} className="cloud" />
															</div>
															<ETypo medium b1>
																{moment(items?.salaryMonth?.toString()).format('MMMM')}
															</ETypo>
														</div>
													</div>
												) : (
													<></>
												)}
											</>
										))}
									</div>
								))}
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</>
	);
};

export default PayslipPresentational;
