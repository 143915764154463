import React from 'react';
import { Row, Col, Card } from 'antd';
import { icons } from 'src/assets/icons';
import EdenredTypo from '@sharedComponent/typography';
import EdenRedCard from '@sharedComponent/card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import Switch from '@sharedComponent/switch';
import ImageComponent from '@sharedComponent/image-component';
import EdenredNewLable from '@sharedComponent/Lable/newLable';
import './teampayroll.scss'


const EdenredTeamPayroll = ({ content, title, symbolicon, description, onClick, type, className,value }) => {
	const renderSwitch = (type) => {
		switch (type) {
			case 'teampayroll_files':
				return (
					<Row className="edencard_web_view_useful_files">
						{content.image && (
							<>
							{value?null:
							<Col xl={1} lg={1} md={1} style={{marginLeft:'10px'}}>
								<ImageComponent src={content.image} className='image'/>
							</Col>}
							</>
						)}
						<Col xl={20} lg={20} md={20} className={!value && content.title !=='Payroll Only'?"content_useful_files1":'content_useful_files'}>
							<Col  xl={24} lg={24} md={24}style={{display:'flex'}}>
							<Col className={!value && content.title !=='Payroll Only'?"title_edencard1":"title_edencard"}>
								<EdenredTypo bold>{content.title}</EdenredTypo>
							</Col>
							{content.title ==='Payroll Only'?null:<div style={{textAlign:"center"}}><EdenredNewLable/></div>}
							</Col>
							
							<Col xl={24} lg={24} md={24} className={!value && content.title !=='Payroll Only'?"description_edenredcard1":"description_edenredcard"}>
								<EdenredTypo>{content.description}</EdenredTypo>
							</Col>
						</Col>
						<Col xl={1} lg={1} md={1} className="download_icon">
							{symbolicon === 'salary_reports' ? (
							<>{!value && content.title !=='Payroll Only'?null	:<ImageComponent className="salaryIcons" src={icons.not_focus.Right} />}</>
							) : (
								<ImageComponent src={icons.not_focus.Download} />
							)}
						</Col>
					</Row>
				);
			default:
				return (
					<Row>
						<Col xl={24} lg={24} md={24}>
							<EdenredTypo medium h4>
								{title}
							</EdenredTypo>
						</Col>
						<Col xl={24} lg={24} md={24}>
							<EdenredTypo b3>{description}</EdenredTypo>
						</Col>
					</Row>
				);
		}
	};
	return (
		<Card className={`edenredCard_main1 ${className}`} onClick={onClick}>
			{/* web */}
			{renderSwitch(type)}
			{/* mobile */}
			{/* {renderSwitchMobile(type)} */}
		</Card>
	);
};

export default EdenredTeamPayroll;
