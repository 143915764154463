import { FlagFilled } from '@ant-design/icons';

const GET_PAYROLL_FILTER_DROPDOWN_DATA = 'GET_PAYROLL_FILTER_DROPDOWN_DATA';
const GET_PAYROLL_FILTER_DROPDOWN_DATA_SUCCESS = 'GET_PAYROLL_FILTER_DROPDOWN_DATA_SUCCESS';
const GET_PAYROLL_FILTER_DROPDOWN_DATA_ERROR = 'GET_PAYROLL_FILTER_DROPDOWN_DATA_ERROR';
const GET_PAYROLL_EMPLOYEE_TABLE_DATA = 'GET_PAYROLL_EMPLOYEE_TABLE_DATA';
const GET_PAYROLL_EMPLOYEE_TABLE_DATA_SUCCESS = 'GET_PAYROLL_EMPLOYEE_TABLE_DATA_SUCCESS';
const GET_PAYROLL_EMPLOYEE_TABLE_DATA_ERROR = 'GET_PAYROLL_EMPLOYEE_TABLE_DATA_ERROR';
const GET_SALARY_STATUS = 'GET_SALARY_STATUS';
const GET_SALARY_STATUS_SUCCESS = 'GET_SALARY_STATUS_SUCCESS';
const GET_SALARY_STATUS_ERROR = 'GET_SALARY_STATUS_ERROR';
const GET_PAYROLL_EMPLOYEE_TABLE_COUNT = 'GET_PAYROLL_EMPLOYEE_TABLE_COUNT';
const GET_PAYROLL_EMPLOYEE_TABLE_COUNT_SUCCESS = 'GET_PAYROLL_EMPLOYEE_TABLE_COUNT_SUCCESS';
const GET_PAYROLL_EMPLOYEE_TABLE_COUNT_ERROR = 'GET_PAYROLL_EMPLOYEE_TABLE_COUNT_ERROR';
const GET_PAYROLL_DYNAMIC_HEADER = 'GET_PAYROLL_DYNAMIC_HEADER';
const GET_PAYROLL_DYNAMIC_HEADER_SUCCESS = 'GET_PAYROLL_DYNAMIC_HEADER_SUCCESS';
const GET_PAYROLL_DYNAMIC_HEADER_ERROR = 'GET_PAYROLL_DYNAMIC_HEADER_ERROR';
const BULK_UPDATE_PAYROLL = 'BULK_UPDATE_PAYROLL';
const BULK_UPDATE_PAYROLL_SUCCESS = 'BULK_UPDATE_PAYROLL_SUCCESS';
const BULK_UPDATE_PAYROLL_ERROR = 'BULK_UPDATE_PAYROLL_ERROR';
const UPDATE_STAGING = 'UPDATE_STAGING ';
const UPDATE_STAGING_SUCCESS = 'UPDATE_STAGING _SUCCESS';
const UPDATE_STAGING_ERROR = 'UPDATE_STAGING _ERROR';
const POPUP_TRIGGER = 'POPUP_TRIGGER';
const EMPLOYEE_SALARY_STAGINGS = 'EMPLOYEE_SALARY_STAGINGS';
const EMPLOYEE_SALARY_STAGINGS_SUCCESS = 'EMPLOYEE_SALARY_STAGINGS_SUCCESS';
const EMPLOYEE_SALARY_STAGINGS_ERROR = 'EMPLOYEE_SALARY_STAGINGS_ERROR';
const POST_RAKBANK_DETAILS = 'POST_RAKBANK_DETAILS';
const POST_RAKBANK_DETAILS_SUCCESS = 'POST_RAKBANK_DETAILS_SUCCESS';
const POST_RAKBANK_DETAILS_FAIL = 'POST_RAKBANK_DETAILS_FAIL';
const POST_RAKBANK_TOAST = 'POST_RAKBANK_TOAST';
const TRANSFER_FUND_POPUP_OPEN_CLOSE = 'RANSFER_FUND_POPUP_OPEN_CLOSE';
const RAK_BANK_ACCOUNT_HOLDER = 'RAK_BANK_ACCOUNT_HOLDER';
const CHANGE_WPS_NON_WPS_POPUP = 'CHANGE_WPS_NON_WPS_POPUP';
const PAYROLL_EMPLOYEE_TABLE_DATA_LOADER = 'PAYROLL_EMPLOYEE_TABLE_DATA_LOADER';
const SALARY_LIMIT_EXCEEDS = 'SALARY_LIMIT_EXCEEDS';


const getPayrollFilterDropdownData = (response) => {
	return {
		type: GET_PAYROLL_FILTER_DROPDOWN_DATA,
		payload: response,
	};
};

const getPayrollFilterDropdownDataSuccess = (res) => {
	return {
		type: GET_PAYROLL_FILTER_DROPDOWN_DATA_SUCCESS,
		res,
	};
};

const getPayrollFilterDropdownDataError = (res) => {
	return {
		type: GET_PAYROLL_FILTER_DROPDOWN_DATA_ERROR,
		payload: {},
	};
};

const getPayrollEmployeeTabledata = (res, isAllSelected, additionTypeRes, deductionTypeRes, allowanceData) => {
	return {
		type: GET_PAYROLL_EMPLOYEE_TABLE_DATA,
		res,
		additionTypeRes,
		deductionTypeRes,
		allowanceData,
		isAllSelected,
	};
};

const getPayrollEmployeeTabledataSuccess = (res) => {
	return {
		type: GET_PAYROLL_EMPLOYEE_TABLE_DATA_SUCCESS,
		res,
	};
};

const getPayrollEmployeeTabledataError = (res) => {
	return {
		type: GET_PAYROLL_EMPLOYEE_TABLE_DATA_ERROR,
		payload: {},
	};
};

const getPayrollEmployeeTableCount = (res) => {
	return {
		type: GET_PAYROLL_EMPLOYEE_TABLE_COUNT,
		res,
	};
};
const getPayrollEmployeeTableCountSuccess = (res) => {
	return {
		type: GET_PAYROLL_EMPLOYEE_TABLE_COUNT_SUCCESS,
		res,
	};
};
const getPayrollEmployeeTableCountError = (res) => {
	return {
		type: GET_PAYROLL_EMPLOYEE_TABLE_COUNT_ERROR,
		res,
	};
};

const getSalaryStatus = () => {
	return {
		type: GET_SALARY_STATUS,
	};
};
const getSalaryStatusSuccess = (res) => {
	return {
		type: GET_SALARY_STATUS_SUCCESS,
		res,
	};
};
const getSalaryStatusError = () => {
	return {
		type: GET_SALARY_STATUS_ERROR,
	};
};
const getPayrollDynamicHeader = (req) => {
	return {
		type: GET_PAYROLL_DYNAMIC_HEADER,
		req,
	};
};
const getPayrollDynamicHeaderSuccess = (addition, deduction, allowance) => {
	return {
		type: GET_PAYROLL_DYNAMIC_HEADER_SUCCESS,
		addition,
		deduction,
		allowance,
	};
};

const getPayrollDynamicHeaderError = () => {
	return {
		type: GET_PAYROLL_DYNAMIC_HEADER_ERROR,
	};
};

const bulkUpdatePayroll = (req, params) => {
	return {
		type: BULK_UPDATE_PAYROLL,
		req,
		params,
	};
};

const bulkUpdatePayrollSuccess = (res) => {
	return {
		type: BULK_UPDATE_PAYROLL_SUCCESS,
		res,
	};
};

const bulkUpdatePayrollError = (res) => {
	return {
		type: BULK_UPDATE_PAYROLL_ERROR,
		res,
	};
};

const updateStaging = (req, payload, month, year) => {
	return {
		type: UPDATE_STAGING,
		req,
		payload,
	};
};

const updateStagingSuccess = (payload) => {
	return {
		type: UPDATE_STAGING_SUCCESS,
		payload
	};
};

const updateStagingError = ({ resData, mode }) => {
	return {
		type: UPDATE_STAGING_ERROR,
		resData,
		mode,
	};
};
const popupTrigger = (res) => {
	return {
		type: POPUP_TRIGGER,
		res,
	};
};

const employeeSalaryStagings = (req) => {
	return {
		type: EMPLOYEE_SALARY_STAGINGS,
		req,
	};
};

const employeeSalaryStagingsSuccess = (res) => {
	return {
		type: EMPLOYEE_SALARY_STAGINGS_SUCCESS,
		res,
	};
};

const changeWpsNonWpsPopup = (flag) => {
	return {
		type: CHANGE_WPS_NON_WPS_POPUP,
		flag,
	};
};

const employeeSalaryStagingsError = (res) => {
	return {
		type: EMPLOYEE_SALARY_STAGINGS_ERROR,
		res,
	};
};

const postRakBankDetails = (payload) => {
	return {
		type: POST_RAKBANK_DETAILS,
		payload,
	};
};

const postRakBankDetailsSuccess = (res) => {
	return {
		type: POST_RAKBANK_DETAILS_SUCCESS,
		res,
	};
};

const postRakBankDetailsFail = (res) => {
	return {
		type: POST_RAKBANK_DETAILS_FAIL,
		res,
	};
};
const postRakBankToast = (flag) => {
	return {
		type: POST_RAKBANK_TOAST,
		flag,
	};
};

const showTransferFundPopup = (flag) => {
	return {
		type: TRANSFER_FUND_POPUP_OPEN_CLOSE,
		flag,
	};
};
const isRakBankAccountHolder = (flag) => {
	return {
		type: RAK_BANK_ACCOUNT_HOLDER,
		flag,
	};
};
const updatePayrollTableLoader = (payload) => {
	return {
		type: PAYROLL_EMPLOYEE_TABLE_DATA_LOADER,
		payload,
	};
};
const salaryLimitExceeds = (payload) => {
	return {
		type: SALARY_LIMIT_EXCEEDS,
		payload,
	};
};

export default {
	types: {
		GET_PAYROLL_FILTER_DROPDOWN_DATA,
		GET_PAYROLL_FILTER_DROPDOWN_DATA_SUCCESS,
		GET_PAYROLL_FILTER_DROPDOWN_DATA_ERROR,
		GET_PAYROLL_EMPLOYEE_TABLE_DATA,
		GET_PAYROLL_EMPLOYEE_TABLE_DATA_SUCCESS,
		GET_PAYROLL_EMPLOYEE_TABLE_DATA_ERROR,
		GET_SALARY_STATUS,
		GET_SALARY_STATUS_SUCCESS,
		GET_SALARY_STATUS_ERROR,
		GET_PAYROLL_EMPLOYEE_TABLE_COUNT,
		GET_PAYROLL_EMPLOYEE_TABLE_COUNT_SUCCESS,
		GET_PAYROLL_EMPLOYEE_TABLE_COUNT_ERROR,
		GET_PAYROLL_DYNAMIC_HEADER,
		GET_PAYROLL_DYNAMIC_HEADER_SUCCESS,
		GET_PAYROLL_DYNAMIC_HEADER_ERROR,
		BULK_UPDATE_PAYROLL,
		BULK_UPDATE_PAYROLL_SUCCESS,
		BULK_UPDATE_PAYROLL_ERROR,
		UPDATE_STAGING,
		UPDATE_STAGING_SUCCESS,
		UPDATE_STAGING_ERROR,
		POPUP_TRIGGER,
		EMPLOYEE_SALARY_STAGINGS,
		EMPLOYEE_SALARY_STAGINGS_SUCCESS,
		EMPLOYEE_SALARY_STAGINGS_ERROR,
		POST_RAKBANK_DETAILS,
		POST_RAKBANK_DETAILS_SUCCESS,
		POST_RAKBANK_DETAILS_FAIL,
		POST_RAKBANK_TOAST,
		TRANSFER_FUND_POPUP_OPEN_CLOSE,
		RAK_BANK_ACCOUNT_HOLDER,
		CHANGE_WPS_NON_WPS_POPUP,
		PAYROLL_EMPLOYEE_TABLE_DATA_LOADER,
		SALARY_LIMIT_EXCEEDS
	},
	creators: {
		getPayrollFilterDropdownData,
		getPayrollFilterDropdownDataSuccess,
		getPayrollFilterDropdownDataError,
		getPayrollEmployeeTabledata,
		getPayrollEmployeeTabledataSuccess,
		getPayrollEmployeeTabledataError,
		getSalaryStatus,
		getSalaryStatusSuccess,
		getSalaryStatusError,
		getPayrollEmployeeTableCount,
		getPayrollEmployeeTableCountSuccess,
		getPayrollEmployeeTableCountError,
		getPayrollDynamicHeader,
		getPayrollDynamicHeaderSuccess,
		getPayrollDynamicHeaderError,
		bulkUpdatePayroll,
		bulkUpdatePayrollSuccess,
		bulkUpdatePayrollError,
		updateStaging,
		updateStagingSuccess,
		updateStagingError,
		popupTrigger,
		employeeSalaryStagings,
		employeeSalaryStagingsSuccess,
		employeeSalaryStagingsError,
		postRakBankDetails,
		postRakBankDetailsSuccess,
		postRakBankDetailsFail,
		postRakBankToast,
		showTransferFundPopup,
		isRakBankAccountHolder,
		changeWpsNonWpsPopup,
		updatePayrollTableLoader,
		salaryLimitExceeds
	},
};
