const GET_PAYSLIP_DATA_START = 'GET_PAYSLIP_DATA_START';
const GET_PAYSLIP_DATA_SUCCESS = 'GET_PAYSLIP_DATA_SUCCESS';
const GET_PAYSLIP_DATA_ERROR = 'GET_PAYSLIP_DATA_ERROR';
const GET_PAYSLIP_DOWNLOAD_START = 'GET_PAYSLIP_DOWNLOAD_START';
const GET_PAYSLIP_DOWNLOAD_SUCCESS = 'GET_PAYSLIP_DOWNLOAD_SUCCESS';
const GET_PAYSLIP_DOWNLOAD_ERROR = 'GET_PAYSLIP_DOWNLOAD_ERROR';

const getPayslipDataStart = (employeeID, payslipDocument) => {
	return {
		type: GET_PAYSLIP_DATA_START,
		employeeID,
		payslipDocument,
	};
};

const getPayslipDataSuccess = (res) => {
	return {
		type: GET_PAYSLIP_DATA_SUCCESS,
		res,
	};
};

const getPayslipDataError = (err) => {
	return {
		type: GET_PAYSLIP_DATA_ERROR,
		err,
	};
};

const getPayslipDownloadStart = (employeeID, salMonth, salYear) => {
	return {
		type: GET_PAYSLIP_DOWNLOAD_START,
		employeeID,
		salMonth,
		salYear,
	};
};

const getPayslipDownloadSuccess = (res) => {
	return {
		type: GET_PAYSLIP_DOWNLOAD_SUCCESS,
		res,
	};
};

const getPayslipDownloadError = (err) => {
	return {
		type: GET_PAYSLIP_DOWNLOAD_ERROR,
		err,
	};
};

export default {
	types: {
		GET_PAYSLIP_DATA_START,
		GET_PAYSLIP_DATA_SUCCESS,
		GET_PAYSLIP_DATA_ERROR,
		GET_PAYSLIP_DOWNLOAD_START,
		GET_PAYSLIP_DOWNLOAD_SUCCESS,
		GET_PAYSLIP_DOWNLOAD_ERROR,
	},
	creators: {
		getPayslipDataStart,
		getPayslipDataSuccess,
		getPayslipDataError,
		getPayslipDownloadStart,
		getPayslipDownloadSuccess,
		getPayslipDownloadError,
	},
};
