import React from 'react';
import { Switch } from 'antd';
import './switch.scss';

const EdenredSwitch = ({ onChange, disabled, defaultChecked, style = {}, ...rest }) => {
	const props = rest.checked
		? {
				checked: rest.checked,
		  }
		: {};
	return (
		<Switch
			size="large"
			className="edenRedSwitch"
			defaultChecked={defaultChecked}
			onChange={onChange}
			disabled={disabled}
			style={style}
			{...props}
		/>
	);
};

export default EdenredSwitch;
