import React, { useEffect, useState } from 'react';
import { Row, Col, Switch } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import { withReducer } from '@store/reducerLoader';
import reducer from '../reducer';
import sagas from '../sagas';
import Actions from '../action';
import Selectors from '../selectors';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { appConstant } from '@constants/';
import { apiUrl } from '@constants/';
import TeamPermission, { CREATE_USER } from '@pages/team-permission/services';
import EdenredHeader from '@pages/main-layout/components/main-header';

const ChooseUserPermissions = ({ getClaims, claimsLoading, claims: { User } }) => {
	const { state } = useLocation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const history = useHistory();
	function onChange({ value }, checked) {
		setData(
			data.map((role) => {
				if (role.value === value) {
					return { ...role, checked };
				} else return role;
			})
		);
	}

	const generateValues = (getClaims = []) => {
		setData(
			getClaims.map((claim, i) => {
				return {
					...claim,
					checked: true,
				};
			})
		);
	};

	useEffect(() => {
		if (User.length) generateValues(User);
	}, [User]);

	const api = new TeamPermission();

	const sendInvite = () => {
		setLoading(true);
		const { username, email, roleId } = state;
		let filteredValue = data.filter((role) => role.checked);
		let claims = filteredValue.map((role) => role.value);
		api.services(CREATE_USER, { username }, { claims, email, roleId })
			.then((res) => {
				setLoading(false);
				history.push({
					pathname: '/manage_team',
					state: {
						response: `${state.username} has been invited as a User.`,
						text: `We’ve sent an email to ${state.username}.`,
						type: 'Invite',
					},
				});
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const Permission = ({ role }) => (
		<Row className="choose_content_list">
			<Col xl={2} lg={2} md={3} xs={5} sm={5}>
				<Switch onChange={(checked) => onChange(role, checked)} checked={role.checked} className="choose_switch" />
			</Col>
			<Col xl={11} lg={11} md={11} xs={18} sm={19}>
				<EdenredTypo className="choose_company" style={{ color: role.checked === false ? '#9A9EA6' : '#484B52' }}>
					{role.description}
				</EdenredTypo>
			</Col>
		</Row>
	);

	return (
		<>
			<EdenredHeader />
			<Row className="choose_user_permission">
				<Col xl={24} md={24} className="choose_header_content">
					<EdenredTypo bold>Choose {state.username}'s Role & Permissions</EdenredTypo>
				</Col>
				<Col xl={24} md={24} className="choose_user_content">
					<EdenredTypo className="choose_user">User</EdenredTypo>
					<EdenredTypo className="choose_payroll">Users can manage payroll and employee records with an verifier’s approval</EdenredTypo>
				</Col>
				<Col xl={24}>
					<Row className="choose_user_card_content">
						{!claimsLoading &&
							data.map((role, index) => (
								<Col xl={24} lg={24} md={24} sm={24} xs={24} key={index} className="choose_toggle_content">
									{index === 0 ? (
										<Col xl={13} lg={13} md={13} sm={23} xs={23} className="choose_align_horizontal_card"></Col>
									) : null}
									<Permission role={role} />
									<Col xl={13} lg={13} md={13} sm={23} xs={23} className="choose_align_horizontal"></Col>
								</Col>
							))}
					</Row>
				</Col>

				<Col xl={10} md={10} xs={24} sm={10} className="choose_send_invite">
					<EdenredButton loading={loading} onClick={sendInvite}>
						Send Invite
					</EdenredButton>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		sendingInvitation: Selectors.sendingInvitation(state),
		claimsLoading: Selectors.claimsLoading(state),
		claims: SelectorsApp.claims(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendInvite: () => dispatch(Actions.creators.sendInvite()),
		getClaims: (roleId) => dispatch(Actions.creators.getClaims(roleId)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(ChooseUserPermissions);

const ReducedScreen = withReducer('sendInvite', reducer, sagas)(Connected);

export default ReducedScreen;
