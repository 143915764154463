import React, { useState, useEffect } from 'react';
import { Row, Col, Switch, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { icons } from '@assets/icons/index';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import EdenredModal from '@sharedComponent/modal';
import { useLocation, BrowserRouter as Router } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ContentLoader from '@sharedComponent/content-loader';
import './permission.scss';
import TeamPermission, { CREATE_USER } from '@pages/team-permission/services';
import UserService from 'src/services/user-service';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const HrPermissionPresentational = ({
	onChange,
	showModal,
	afterOpenModal,
	closeModal,
	visible,
	toggleSwitch,
	roles,
	getDeleterole,
	deleteLoading,
	username,
}) => {
	const { state, button, Claim, user, verifier } = useLocation();
	const [hrClaimList, setHrClaimList] = useState([]);
	const [apiLoading, setapiLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const userAPI = new UserService();
	const [addclaim, setaddclaim] = useState([]);
	const [button1, setbuttonEnable] = useState(false);
	const [button2, setbuttonEnable1] = useState(false);
	const [button3, setbuttonEnable2] = useState(false);
	const [button4, setbuttonEnable3] = useState(false);
	const api = new TeamPermission();
	const changeClaims = (isSelected, value) => {
		if (isSelected) {
			addclaim.push(value);
			if (value.title === 'Manage Documents') {
				setbuttonEnable1(true);
			} else {
				setbuttonEnable2(true);
			}
		} else {
			if (value.title === 'Manage Documents') {
				setbuttonEnable1(false);
			} else {
				setbuttonEnable2(false);
			}
			for (var i = 0; i < addclaim.length; i++) {
				if (value.title === addclaim[i].title) {
					const index = addclaim.indexOf(value);
					if (index > -1) {
						addclaim.splice(index, 1);
					}
				}
			}
		}
	};
	const changeClaims1 = (isSelected, value) => {
		const value1 = {
			type: 'http://edenred.ae/claims/hr/permission',
			value: 'Approval.Workflow',
			title: 'Approval Workflow',
			description: 'User can approve and view leaves for their department. Assign in leave approval workflow page.',
		};
		if (isSelected) {
			addclaim.push(value);
			if (value.title === 'Manage Leaves' || value.title === 'Approval Workflow') {
				setbuttonEnable(isSelected);
				setbuttonEnable3(isSelected);
			}
		} else {
			if (value.title !== 'Manage Leaves') {
				setbuttonEnable3(isSelected);
			} else {
				setbuttonEnable(isSelected);
				setbuttonEnable3(isSelected);
			}

			for (var i = 0; i < addclaim.length; i++) {
				if (value.title === addclaim[i].title) {
					const index = addclaim.indexOf(value);
					if (value.title === 'Manage Leaves') {
						if (index > -1) {
							addclaim.splice(index, 2);
						}
					} else if (value.title === 'Approval Workflow') {
						if (index === -1) {
							addclaim.splice(index, 1);
						}
					}
				}
			}
		}
		if (value.title === 'Manage Leaves' && isSelected) {
			addclaim.push(value1);
		}
	};
	useEffect(() => {
		getallClaims();
	}, []);
	const getallClaims = () => {
		userAPI
			.getAllClaimsApi()
			.then((response) => {
				setHrClaimList(response.data.hrClaims);
				setapiLoading(false);
			})
			.catch((err) => {
				setapiLoading(false);
			});
	};

	const Leaves = ({ leave, changeClaims }) => {
		return (
			<Row className="content_list1">
				<Col xl={2} md={3} xs={5} sm={5}>
					{leave.title === 'Manage Leaves' ? (
						<Switch className="switch" onChange={(checked) => changeClaims1(checked, leave)} checked={button1} />
					) : (
						''
					)}
					{leave.title === 'Approval Workflow' ? (
						<Switch className="switch" onChange={(checked) => changeClaims1(checked, leave)} checked={button4} />
					) : (
						''
					)}
					{leave.title === 'Manage Documents' ? (
						<Switch className="switch" onChange={(checked) => changeClaims(checked, leave)} checked={button2} />
					) : (
						''
					)}
					{leave.title === 'Manage Announcements' ? (
						<Switch className="switch" onChange={(checked) => changeClaims(checked, leave)} checked={button3} />
					) : (
						''
					)}
				</Col>
				<Col style={{ display: 'flex', flexDirection: 'column' }}>
					<Col className="value">{leave.title}</Col>
					<Col className="value1">{leave.description}</Col>
				</Col>
			</Row>
		);
	};
	let query = useQuery();

	let history = useHistory();

	const saveClicked = () => {
		setLoading(true);
		const { username, email } = state;
		let claims = Claim?.map((role) => role?.value);
		let hrClaims = addclaim?.map((role) => role?.value);
		var roleId;
		if (user || verifier) {
			if (user) {
				roleId = '673ed75f-f406-4b5c-bfc2-54d0f68f5398';
			} else {
				roleId = 'cf59c601-f2e2-47be-a94e-36a56e6a8c8d';
			}
			api.services(CREATE_USER, { username }, { claims, hrClaims, email, roleId })
				.then((res) => {
					setLoading(false);
					history.push({
						pathname: '/manage_team',
						state: {
							response: `${state.username} has been invited as a User.`,
							text: `We’ve sent an email to ${state.username}.`,
							type: 'Invite',
						},
					});
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			roleId = '673ed75f-f406-4b5c-bfc2-54d0f68f5398';
			api.services(CREATE_USER, { username }, { claims, hrClaims, email, roleId })
				.then((res) => {
					setLoading(false);
					history.push({
						pathname: '/manage_team',
						state: {
							response: `${state.username} has been invited as a User.`,
							text: `We’ve sent an email to ${state.username}.`,
							type: 'Invite',
						},
					});
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};
	return (
		<>
			<EdenredHeader cancel />
			<Row className="mainContainer">
				<Col xl={24} md={24} className="header_content">
					<EdenredTypo>
						HR Role & Permissions for <span>{state?.username}</span>
					</EdenredTypo>
				</Col>
				{apiLoading ? (
					<div className="loader-role">
						<ContentLoader />
					</div>
				) : (
					<Col xl={24} md={24} className="userbackground">
						<Col xl={24} md={24} xs={24} sm={24} className="toggle_para">
							{hrClaimList.map((leave, index) => (
								<Col xl={24} md={24} xs={24} sm={24} key={leave.index} className="toggle_content1" style={{ marginLeft: '20px' }}>
									<Leaves leave={leave} changeClaims={changeClaims} />

									{leave.length === index + 1 ? null : (
										<Col xl={22} md={22} lg={22} xs={24} sm={24} className="align_horizontal">
											<Divider style={{ margin: '0px 0px' }} />
										</Col>
									)}
								</Col>
							))}
						</Col>
					</Col>
				)}
				<Col xl={10} md={10} xs={24} sm={24} className="save_change">
					<EdenredButton loading={loading} onClick={saveClicked}>
						Submit
					</EdenredButton>
				</Col>
				<EdenredModal
					isOpen={visible}
					afterOpenModal={afterOpenModal}
					getDeleterole={getDeleterole}
					closeModal={closeModal}
					loading={loading}
					className="modal_card_role"
					user={`Remove ${username}?`}
					content_web={`${username} will not be able to login anymore.
					          Permissions can be reassigned among existing users.`}
					content_mobile={`${username} will not be able to login anymore.`}
				/>
			</Row>
		</>
	);
};
export default HrPermissionPresentational;
