import React from 'react';
import { images } from 'src/assets/img';
import { Layout, Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import './setup-header.scss';

const { Header } = Layout;

const SetupHeader = () => {
	return (
		<Header className="setup_main_route">
			<Row className="header">
				<Col xl={6} lg={6} md={6} sm={24} xs={24} style={{ display: 'flex', marginLeft: '30px' }}>
					<ImageComponent style={{ height: '2.1rem' }} src={images.edenredSVG} />
				</Col>
			</Row>
		</Header>
	);
};

export default SetupHeader;
