import Axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { templateDownload } from '@helpers';
import Actions from '../action';

export function* updateEmailData(initial) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const isPortalInviteNull = initial?.payload?.portalInviteStatus;
	const request = {
		phoneNumber: initial?.payload?.mobileNumber || '',
		emailId: initial?.payload?.emailId || '',
		employeeId: initial?.payload?.employeeCode,
		userFirstLastName: initial?.payload?.employeeName,
	};
	const request1 = {
		emailId: initial?.payload?.emailId || '',
		employeeId: initial?.payload?.employeeCode,
	};
	const forPayLoad = isPortalInviteNull === null ? request : request1;
	try {
		const url =
			isPortalInviteNull === null
				? `${DOMAIN_URL}/Users/users/${corporateId}/create-employee-user`
				: `${DOMAIN_URL}/Users/${corporateId}/re-invite-employee`;
		const res = yield call(Axios.post, url, forPayLoad);
		yield put(Actions.creators.postEmailDataSuccess(res.data));
		yield put(Actions.creators.invitePortalPopup(false));
		yield put(Actions.creators.inviteCardStatus(res.status === 200 ? 'Success' : 'NotSuccess'));
	} catch (err) {
		yield put(Actions.creators.postEmailDataError(err));
		yield put(Actions.creators.invitePortalPopup(false));
		yield put(Actions.creators.inviteCardStatus('NotSuccess'));
	}
}
export function* updateEmailServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const emp_Id = action?.payload?.employeeId;
	const email = action?.payload?.emailId;
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/${emp_Id}/update-email?emailId=${email}`;
		const res = yield call(Axios.patch, uri);
		yield put(Actions.creators.updateEmailNewSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.updateEmailNewFail(err));
	}
}

export function* getEmployeeNewDataSaga() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/Employees/${corporateId}/data/download`;
		const res = yield call(Axios.get, url, {
			responseType: 'blob',
		});
		yield put(Actions.creators.getEmployeeNewDataSuccess(res?.data));
		templateDownload(res?.data, 'employee.xlsx');
	} catch (err) {
		yield put(Actions.creators.getEmployeeNewDataError(err));
	}
}
export default function* emailWatcher() {
	yield takeEvery(Actions.types.POST_EMAIL_DATA, updateEmailData);
	yield takeEvery(Actions.types.UPDATE_EMAIL_NEW_START, updateEmailServices);
	yield takeEvery(Actions.types.GET_NEW_DATA, getEmployeeNewDataSaga);
}
