const SAVE_OVERTIME_DATA_START = 'SAVE_OVERTIME_DATA_START';
const SAVE_OVERTIME_DATA_SUCCESS = 'SAVE_OVERTIME_DATA_SUCCESS';
const SAVE_OVERTIME_DATA_FAIL = 'SAVE_OVERTIME_DATA_FAIL';
const GET_OVERTIME_POLICY_PERCENT_START = 'GET_OVERTIME_POLICY_PERCENT_START';
const GET_OVERTIME_POLICY_PERCENT_SUCCESS = 'GET_OVERTIME_POLICY_PERCENT_SUCCESS';
const GET_OVERTIME_POLICY_PERCENT_FAIL = 'GET_OVERTIME_POLICY_PERCENT_FAIL';
const GET_OVERTIME_DATA_START = 'GET_OVERTIME_DATA_START';
const GET_OVERTIME_DATA_SUCCESS = 'GET_OVERTIME_DATA_SUCCESS';
const GET_OVERTIME_DATA_FAIL = 'GET_OVERTIME_DATA_FAIL';
const GET_OVERTIME_SALARY_START = 'GET_OVERTIME_SALARY_START';
const GET_OVERTIME_SALARY_SUCCESS = 'GET_OVERTIME_SALARY_SUCCESS';
const GET_OVERTIME_SALARY_FAIL = 'GET_OVERTIME_SALARY_FAIL';
const GET_COMPANYSETTING_START = 'GET_COMPANYSETTING_START';
const GET_COMPANYSETTING_SUCCESS = 'GET_COMPANYSETTING_SUCCESS';
const GET_COMPANYSETTING_FAIL = 'GET_COMPANYSETTING_FAIL';
const SAVE_COMPANYSETTING_START = 'SAVE_COMPANYSETTING_START';
const SAVE_COMPANYSETTING_SUCCESS = 'SAVE_COMPANYSETTING_SUCCESS';
const SAVE_COMPANYSETTING_FAIL = 'SAVE_COMPANYSETTING_FAIL';
const SAVE_UPLOADLOGO_START = 'SAVE_UPLOADLOGO_START';
const SAVE_UPLOADLOGO_SUCCESS = 'SAVE_UPLOADLOGO_SUCCESS';
const SAVE_UPLOADLOGO_FAIL = 'SAVE_UPLOADLOGO_FAIL';
const SAVE_SUCCESSFULL_DONE = 'SAVE_SUCCESSFULL_DONE';
const GET_OVERTIME_LABEL_START = 'GET_OVERTIME_LABEL_START';
const GET_OVERTIME_LABEL_SUCCESS = 'GET_OVERTIME_LABEL_SUCCESS';
const GET_OVERTIME_LABEL_FAIL = 'GET_OVERTIME_LABEL_FAIL';
const GET_UPLOADLOGO_START = 'GET_UPLOADLOGO_START';
const GET_UPLOADLOGO_SUCCESS = 'GET_UPLOADLOGO_SUCCESS';
const GET_UPLOADLOGO_FAIL = 'GET_UPLOADLOGO_FAIL';
const GET_DEFAULT_APPROVER_START = 'GET_DEFAULT_APPROVER_START';
const GET_DEFAULT_APPROVER_SUCCESS = 'GET_DEFAULT_APPROVER_SUCCESS';
const GET_DEFAULT_APPROVER_FAIL = 'GET_DEFAULT_APPROVER_FAIL';
const COMPANY_ADD_EDIT_ORG = 'COMPANY_ADD_EDIT_ORG';

const saveOverTimeStart = (overtimePolicies, overtimeData) => {
	return {
		type: SAVE_OVERTIME_DATA_START,
		overtimePolicies,
		overtimeData,
	};
};

const saveOverTimeSuccess = (response) => {
	return {
		type: SAVE_OVERTIME_DATA_SUCCESS,
		response,
	};
};
const saveOverTimeFail = (error) => {
	return {
		type: SAVE_OVERTIME_DATA_FAIL,
		error,
	};
};

const getOvertimePolicyPercentage = () => {
	return {
		type: GET_OVERTIME_POLICY_PERCENT_START,
	};
};

const getOvertimePolicyPercentageSuccess = (response) => {
	return {
		type: GET_OVERTIME_POLICY_PERCENT_SUCCESS,
		response,
	};
};
const getOvertimePolicyPercentageFail = (error) => {
	return {
		type: GET_OVERTIME_POLICY_PERCENT_FAIL,
		error,
	};
};

const getOverTimeData = () => {
	return {
		type: GET_OVERTIME_DATA_START,
	};
};

const getOverTimeDataSuccess = (response) => {
	return {
		type: GET_OVERTIME_DATA_SUCCESS,
		response,
	};
};
const getOverTimeDataFail = (error) => {
	return {
		type: GET_OVERTIME_DATA_FAIL,
		error,
	};
};
const getOverTimeSalaryPerDay = () => {
	return {
		type: GET_OVERTIME_SALARY_START,
	};
};

const getOverTimeSalaryPerDaySuccess = (response) => {
	return {
		type: GET_OVERTIME_SALARY_SUCCESS,
		response,
	};
};
const getOverTimeSalaryPerDayFail = (error) => {
	return {
		type: GET_OVERTIME_SALARY_FAIL,
		error,
	};
};

const getCompanySettingStart = () => {
	return {
		type: GET_COMPANYSETTING_START,
	};
};
const getCompanySettingSuccess = (response) => {
	return {
		type: GET_COMPANYSETTING_SUCCESS,
		response,
	};
};
const getCompanySettingFail = (error) => {
	return {
		type: GET_COMPANYSETTING_FAIL,
		error,
	};
};
const saveCompanySettingStart = (data) => {
	return {
		type: SAVE_COMPANYSETTING_START,
		data,
	};
};
const saveCompanySettingSuccess = (response) => {
	return {
		type: SAVE_COMPANYSETTING_SUCCESS,
		response,
	};
};
const saveCompanySettingFail = (error) => {
	return {
		type: SAVE_COMPANYSETTING_FAIL,
		error,
	};
};
const saveUploadLogoStart = (data) => {
	return {
		type: SAVE_UPLOADLOGO_START,
		data,
	};
};
const saveUploadLogoSuccess = (response) => {
	return {
		type: SAVE_UPLOADLOGO_SUCCESS,
		response,
	};
};
const saveUploadLogoFail = (error) => {
	return {
		type: SAVE_UPLOADLOGO_FAIL,
		error,
	};
};
const isSaveSuccessfullDone = () => {
	return {
		type: SAVE_SUCCESSFULL_DONE,
	};
};
const overtimeLabelStart = () => {
	return {
		type: GET_OVERTIME_LABEL_START,
	};
};
const overtimeLabelSuccess = (response) => {
	return {
		type: GET_OVERTIME_LABEL_SUCCESS,
		response,
	};
};
const overtimeLabelFail = (error) => {
	return {
		type: GET_OVERTIME_LABEL_FAIL,
		error,
	};
};
const getUploadLogoStart = () => {
	return {
		type: GET_UPLOADLOGO_START,
	};
};
const getUploadLogoSuccess = (response) => {
	return {
		type: GET_UPLOADLOGO_SUCCESS,
		response,
	};
};
const getUploadLogoFail = (error) => {
	return {
		type: GET_UPLOADLOGO_FAIL,
		error,
	};
};
const getDefaultApproverStart = () => {
	return {
		type: GET_DEFAULT_APPROVER_START,
	};
};
const getDefaultApproverSuccess = (res) => {
	return {
		type: GET_DEFAULT_APPROVER_SUCCESS,
		res,
	};
};
const getDefaultApproverFail = (err) => {
	return {
		type: GET_DEFAULT_APPROVER_FAIL,
		err,
	};
};
const companyAddEditOrg = (flag) => ({
	type: COMPANY_ADD_EDIT_ORG,
	flag,
});

export default {
	types: {
		SAVE_OVERTIME_DATA_START,
		SAVE_OVERTIME_DATA_SUCCESS,
		SAVE_OVERTIME_DATA_FAIL,
		GET_OVERTIME_POLICY_PERCENT_START,
		GET_OVERTIME_POLICY_PERCENT_SUCCESS,
		GET_OVERTIME_POLICY_PERCENT_FAIL,
		GET_OVERTIME_DATA_START,
		GET_OVERTIME_DATA_SUCCESS,
		GET_OVERTIME_DATA_FAIL,
		GET_OVERTIME_SALARY_START,
		GET_OVERTIME_SALARY_SUCCESS,
		GET_OVERTIME_SALARY_FAIL,
		GET_COMPANYSETTING_START,
		GET_COMPANYSETTING_SUCCESS,
		GET_COMPANYSETTING_FAIL,
		SAVE_COMPANYSETTING_START,
		SAVE_COMPANYSETTING_SUCCESS,
		SAVE_COMPANYSETTING_FAIL,
		SAVE_UPLOADLOGO_START,
		SAVE_UPLOADLOGO_SUCCESS,
		SAVE_UPLOADLOGO_FAIL,
		SAVE_SUCCESSFULL_DONE,
		GET_OVERTIME_LABEL_START,
		GET_OVERTIME_LABEL_SUCCESS,
		GET_OVERTIME_LABEL_FAIL,
		GET_UPLOADLOGO_START,
		GET_UPLOADLOGO_SUCCESS,
		GET_UPLOADLOGO_FAIL,
		GET_DEFAULT_APPROVER_START,
		GET_DEFAULT_APPROVER_SUCCESS,
		GET_DEFAULT_APPROVER_FAIL,
		COMPANY_ADD_EDIT_ORG,
	},
	creators: {
		saveOverTimeStart,
		saveOverTimeSuccess,
		saveOverTimeFail,
		getOvertimePolicyPercentage,
		getOvertimePolicyPercentageSuccess,
		getOvertimePolicyPercentageFail,
		getOverTimeData,
		getOverTimeDataSuccess,
		getOverTimeDataFail,
		getOverTimeSalaryPerDay,
		getOverTimeSalaryPerDaySuccess,
		getOverTimeSalaryPerDayFail,
		getCompanySettingStart,
		getCompanySettingSuccess,
		getCompanySettingFail,
		saveCompanySettingStart,
		saveCompanySettingSuccess,
		saveCompanySettingFail,
		saveUploadLogoStart,
		saveUploadLogoSuccess,
		saveUploadLogoFail,
		isSaveSuccessfullDone,
		overtimeLabelStart,
		overtimeLabelSuccess,
		overtimeLabelFail,
		getUploadLogoStart,
		getUploadLogoSuccess,
		getUploadLogoFail,
		getDefaultApproverStart,
		getDefaultApproverSuccess,
		getDefaultApproverFail,
		companyAddEditOrg,
	},
};
