import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EmployeeService from 'src/services/employee-service';
import { DotLoader } from 'react-spinners';
import { APP_VARIABLES } from '@constants/app-constants';

const C3PayCardstatuses = [
	{
		name: 'Verifier Approve',
		icon: icons.focus_state.Check,
		status: 'Your verifier needs to approve this addition first. We’ll notify you once it happens.',
	},
	{
		name: 'Employee Registration',
		icon: icons.focus_state.Clock,
		status: 'Employee registration will be completed by the end of each working day.',
	},
	{
		name: 'Notify Email',
		icon: icons.focus_state.Email,
		status:
			'You’ll receive an e-mail and a portal notification when your employees are registered, so that you can start running payroll for them.',
	},
	{
		name: 'Track Card Status',
		icon: icons.focus_state.Tracking,

		status: (
			<span>
				Track your card order status{' '}
				<Link
					to={{ pathname: '/employees/card/order/status' }}
					target="_blank"
					onClick={() =>
						localStorage.setItem(
							APP_VARIABLES.REDIRECT_TO,
							JSON.stringify({
								url: '/employees/card/order/status',
							})
						)
					}>
					here
				</Link>{' '}
				You can run payroll for employees befores they receive their cards.
			</span>
		),
	},
];
const BankAccountstatuses = [
	{
		name: 'Verifier Approve',
		icon: icons.focus_state.Check,
		status: 'Your verifier needs to approve this addition first. We’ll notify you once it happens.',
	},
	{
		name: 'Employee Registration',
		icon: icons.focus_state.Clock,
		status: 'Employee registration will be completed by the end of each working day.',
	},
	{
		name: 'Notify Email',
		icon: icons.focus_state.Email,
		status:
			'You’ll receive an e-mail and a portal notification when your employees are registered, so that you can start running payroll for them.',
	},
];

const IndividualEmployeeNextSteps = () => {
	const { state } = useLocation();
	const history = useHistory();
	let [loading, setLoading] = useState(false);
	let [name, setName] = useState('');
	let employeeApi = new EmployeeService();
	useEffect(init, []);
	function init() {
		handleEmployeeCreation();
	}

	async function handleEmployeeCreation() {
		let payload = history.location.state;
		let dob = new Date(payload.dateOfBirth);
		if (payload && payload.firstName) {
			setName(payload.firstName);
		}
		payload.dateOfBirth = dob.toISOString();
		setLoading(true);
		await createEmployee(payload);
		setLoading(false);
	}

	async function createEmployee(payload) {
		let employee = {
			employee: payload,
		};
		try {
			let response = await employeeApi.createEmployee(employee);
			return response;
		} catch (err) {}
	}

	const handleRedirect = () => {
		history.push('/employees/manage_employee');
	};
	return (
		<>
			<EdenredHeader />
			{loading && (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			)}
			{!loading && (
				<Row className="individual_employee_next_steps_container">
					<Col xl={16} lg={16} md={20} sm={24} xs={24} className="individual_employee_next_steps_header">
						<EdenredTypo>Next Steps:</EdenredTypo>
					</Col>
					<Col xl={16} lg={16} md={20} sm={24} xs={24} className="individual_employee_next_steps_message">
						<EdenredTypo>You’ve added {name} as an employee</EdenredTypo>
					</Col>
					<Col xl={22} lg={22} md={22} sm={24} xs={24} className="individual_employee_next_steps_status">
						{state.orderType === 'C3PayCard'
							? C3PayCardstatuses.map((status, index) => (
									<Row key={index} className="individual_employee_next_steps_status_container">
										<Col xl={1} lg={1} md={1} sm={2} xs={2} className="individual_employee_next_steps_status_icon">
											<ImageComponent src={status.icon} />
										</Col>
										<Col xl={20} lg={20} md={20} sm={20} xs={20} className="individual_employee_next_steps_status_content">
											<EdenredTypo>{status.status}</EdenredTypo>
										</Col>
									</Row>
							  ))
							: BankAccountstatuses.map((status, index) => (
									<Row key={index} className="individual_employee_next_steps_status_container">
										<Col xl={1} lg={1} md={1} sm={2} xs={2} className="individual_employee_next_steps_status_icon">
											<ImageComponent src={status.icon} />
										</Col>
										<Col xl={20} lg={20} md={20} sm={20} xs={20} className="individual_employee_next_steps_status_content">
											<EdenredTypo>{status.status}</EdenredTypo>
										</Col>
									</Row>
							  ))}
					</Col>
					<Col xl={8} lg={12} md={18} sm={24} xs={24} className="individual_employee_next_steps_button">
						<EdenredButton type="outline-g" onClick={handleRedirect}>
							Return To Manage Employees
						</EdenredButton>
					</Col>
				</Row>
			)}
		</>
	);
};

export default IndividualEmployeeNextSteps;
