import React from 'react';
import './etypo.scss';

const ETypo = ({ children, color = '', style, className = '', textDecoration = '', ...rest }) => {
	const [headerStyle = 'h2', fontWeightStyle = 'medium'] = Object.keys(rest);
	return (
		<span
			style={{ color: color, ...style, textDecoration: textDecoration }}
			className={`shedenredTypo ${headerStyle} ${fontWeightStyle} ${className}`}
			{...rest}>
			{children}
		</span>
	);
};

export default ETypo;
