import React from 'react';
import ImageSlider from './Examples/imageSlider';
import SlidersPrimary from './Examples/slidersPrimary';
import './index.scss';

const Index = () => {
	return (
		<div className="main_slider">
			<div className="sliderPrimary">
				<h1>Sliders</h1>
				<SlidersPrimary />
			</div>
			<div className="imageSlider">
				<h1>Previous/Next Button</h1>
				<ImageSlider />
			</div>
		</div>
	);
};

export default Index;
