import Actions from './actions';
export const initState = {
	toastType: null,
	message: null,
};

const statusToastReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.PUT_STATUS_TOAST: {
			return {
				...state,
				toastType: action.payload.toastType,
				message: action.payload.message,
			};
		}
		case Actions.types.CLEAR_STATUS_TOAST: {
			return {
				...state,
				toastType: null,
				message: null,
			};
		}
		default:
			return state;
	}
};
export default statusToastReducer;
