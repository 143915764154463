const SAVE_DOCUMENTUPLOAD_LOADING = 'SAVE_DOCUMENTUPLOAD_LOADING';
const SAVE_DOCUMENTUPLOAD_SUCCESS = 'SAVE_DOCUMENTUPLOAD_SUCCESSS';
const SAVE_DOCUMENTUPLOAD_FAIL = 'SAVE_DOCUMENTUPLOAD_FAIL';
const SAVE_DOCUMENTUPLOAD_RESET = 'SAVE_DOCUMENTUPLOAD_RESET';
const SAVE_DOCUMENTSUBMIT_LOADING = 'SAVE_DOCUMENTSUBMIT_LOADING';
const SAVE_DOCUMENTSUBMIT_SUCCESS = 'SAVE_DOCUMENTSUBMIT_SUCCESS';
const SAVE_DOCUMENTSUBMIT_FAIL = 'SAVE_DOCUMENTSUBMIT_FAIL';
const SAVE_TEMPLATE_LOADING = 'SAVE_TEMPLATE_LOADING';
const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
const SAVE_TEMPLATE_FAIL = 'SAVE_TEMPLATE_FAIL';
const SAVE_FILTERDATA_START = 'SAVE_FILTERDATA_START';
const SAVE_FILTERDATA_SUCCESS = 'SAVE_FILTERDATA_SUCCESS';
const SAVE_FILTERDATA_FAIL = 'SAVE_FILTERDATA_FAIL';
const SETUP_TEMPLATE_START = 'SETUP_TEMPLATE_START';
const SETUP_TEMPLATE_SUCCESS = 'SETUP_TEMPLATE_SUCCESS';
const SETUP_TEMPLATE_FAIL = 'SETUP_TEMPLATE_FAIL';

const saveDocumentUploadStart = (file) => {
	return {
		type: SAVE_DOCUMENTUPLOAD_LOADING,
		file,
	};
};
const saveDocumentUploadSuccess = (response) => {
	return {
		type: SAVE_DOCUMENTUPLOAD_SUCCESS,
		response,
	};
};
const saveDocumentUploadFail = (error) => {
	return {
		type: SAVE_DOCUMENTUPLOAD_FAIL,
		error,
	};
};
const saveDocumentUploadReset = () => {
	return {
		type: SAVE_DOCUMENTUPLOAD_RESET,
	};
};
const saveDocumentSubmitStart = (file) => {
	return {
		type: SAVE_DOCUMENTSUBMIT_LOADING,
		file,
	};
};
const saveDocumentSubmitSuccess = (response) => {
	return {
		type: SAVE_DOCUMENTSUBMIT_SUCCESS,
		response,
	};
};
const saveDocumentSubmitFail = (error) => {
	return {
		type: SAVE_DOCUMENTSUBMIT_FAIL,
		error,
	};
};
const saveTemplateStart = () => {
	return {
		type: SAVE_TEMPLATE_LOADING,
	};
};
const saveTemplateSuccess = (response) => {
	return {
		type: SAVE_TEMPLATE_SUCCESS,
		response,
	};
};
const saveTemplateFail = (error) => {
	return {
		type: SAVE_TEMPLATE_FAIL,
		error,
	};
};
const saveFilterDataStart = (filterDocument) => {
	return {
		type: SAVE_FILTERDATA_START,
		filterDocument,
	};
};
const saveFilterDataSuccess = (response) => {
	return {
		type: SAVE_FILTERDATA_SUCCESS,
		response,
	};
};
const saveFilterDataFail = (error) => {
	return {
		type: SAVE_FILTERDATA_FAIL,
		error,
	};
};
const setupTemplateStart = () => {
	return {
		type: SETUP_TEMPLATE_START,
	};
};
const setupTemplateSuccess = (response) => {
	return {
		type: SETUP_TEMPLATE_SUCCESS,
		response,
	};
};
const setupTemplateFail = (error) => {
	return {
		type: SETUP_TEMPLATE_FAIL,
		error,
	};
};

export default {
	types: {
		SAVE_DOCUMENTUPLOAD_LOADING,
		SAVE_DOCUMENTUPLOAD_SUCCESS,
		SAVE_DOCUMENTUPLOAD_FAIL,
		SAVE_DOCUMENTUPLOAD_RESET,
		SAVE_DOCUMENTSUBMIT_LOADING,
		SAVE_DOCUMENTSUBMIT_SUCCESS,
		SAVE_DOCUMENTSUBMIT_FAIL,
		SAVE_TEMPLATE_LOADING,
		SAVE_TEMPLATE_SUCCESS,
		SAVE_TEMPLATE_FAIL,
		SAVE_FILTERDATA_START,
		SAVE_FILTERDATA_SUCCESS,
		SAVE_FILTERDATA_FAIL,
		SETUP_TEMPLATE_START,
		SETUP_TEMPLATE_SUCCESS,
		SETUP_TEMPLATE_FAIL,
	},
	creators: {
		saveDocumentUploadStart,
		saveDocumentUploadSuccess,
		saveDocumentUploadFail,
		saveDocumentUploadReset,
		saveDocumentSubmitStart,
		saveDocumentSubmitSuccess,
		saveDocumentSubmitFail,
		saveTemplateStart,
		saveTemplateSuccess,
		saveTemplateFail,
		saveFilterDataStart,
		saveFilterDataSuccess,
		saveFilterDataFail,
		setupTemplateStart,
		setupTemplateSuccess,
		setupTemplateFail,
	},
};
