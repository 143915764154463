import { useHistory } from "react-router-dom"
import { message } from "antd"
import { API_STATUS_CODES } from "@constants/app-constants"
import { COOKIE_CONSTANTS, deletingCookie, gettingCookie, settingCookie } from "src/helpers/cookies"
import { CorporateService } from "src/services"
import useCallEmployeeVerify from "./useCallEmployeeVerify"
import { SITEMAP } from "src/routes/sitemap"

const useCallCbdClient = (userData) => {
    const history = useHistory()
    const { callEmployeeVerifyCheck } = useCallEmployeeVerify(userData)

    const redirectToTermsAndConditions = () => {
        if (gettingCookie(COOKIE_CONSTANTS.mfa.inProgress)) {
            deletingCookie(COOKIE_CONSTANTS.mfa.inProgress)
        }
        history.replace({ pathname: SITEMAP.termsAndConditions.index, state: { user: userData } });
    }

    // Checking Cbd client check
    async function checkCbdClient() {
        try {
            const corporateService = new CorporateService()
            const res = await corporateService.checkCbdClient()
            if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
                throw new Error('Something went wrong')
            }
            const { data } = res.data || {}
            // If we get data from the response and if its a cbdclient and client not accepted TC we redirect to TC page
            if (data && data?.isCbdClient && !data?.isCbdClientAcceptedTc) {
                settingCookie(COOKIE_CONSTANTS.cbdClient.tc, 'true')
                redirectToTermsAndConditions()
            }
            else {
                callEmployeeVerifyCheck()
            }

        } catch (error) {
            if (error?.message) {
                message.error(error?.message);
            }
            console.error(error, 'cbd client api')
            callEmployeeVerifyCheck()
        }
    }

    return {
        checkCbdClient
    }
}

export default useCallCbdClient
