import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import MfaImageCarousel from './components/MfaImageCarousel';
import MfaContainer from './components/MfaContainer';
import mfaActions from './mfaActions';
import { COOKIE_CONSTANTS, gettingCookie } from 'src/helpers/cookies';
import { SITEMAP } from 'src/routes/sitemap';
import './mfaOtpValidation.scss';


const MfaMain = () => {
    const auth = useSelector((state) => state.auth);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    // checking mfa is in progress or not
    const isMFA = gettingCookie(COOKIE_CONSTANTS.mfa.inProgress);
    useEffect(() => {
        // If MFA is not in progress
        if (!isMFA) {
            // If the user data was not present in location
            if (!location?.state) {
                history.push(SITEMAP.dashboard.index);
            }
            // If the user data present in location
            else if (location?.state?.user) {
                history.push({ pathname: SITEMAP.dashboard.index, state: { user: location?.state?.user } });
            }
        }
        if (isMFA && !auth.mfa.inProgress) {
            dispatch(mfaActions.creators.checkingMfa());
        }
    }, []);
    return (
        <div className="mfa-wrapper">
            <div className="mfa-main-container">
                <div className="edenred-logo">
                    <ImageComponent src={images.logo} />
                </div>
                <div className='mfa-container'>
                    <MfaContainer />
                </div>
            </div>
            <div className="mfa-image-container">
                <MfaImageCarousel />
            </div>
        </div>

    )
};


export default MfaMain;
