import React, { useEffect } from 'react';
import Chart from 'chart.js';

const BarChart2 = (props) => {
	useEffect(() => {
		const ctx1 = document.getElementById('Chart');
		Chart.defaults.global.legend.labels.usePointStyle = true;

		// generate HTML legend

		new Chart(ctx1, {
			type: 'bar',
			axisX: {
				gridThickness: 0,
				tickLength: 0,
				lineThickness: 0,
			},
			axisY: {
				gridThickness: 0,
				tickLength: 0,
				lineThickness: 0,
			},
			data: {
				labels: props.payroll.map((data) => data.month),
				datasets: [
					{
						label: 'WPS',
						data: props.payroll.map((data) => data.totalWPS),
						backgroundColor: '#4991B6',
						hoverBackgroundColor: '#4991B6',
						hoverBorderWidth: 0,
						barPercentage: 0.5,
						barThickness: 20,
					},
					{
						label: 'Non WPS',
						data: props.payroll.map((data) => data.totalNonWPS),
						backgroundColor: '#EA6257',
						hoverBackgroundColor: '#EA6257',
						hoverBorderWidth: 0,
						barThickness: 20,
						barPercentage: 0.5,
					},
				],
			},
			options: {
				scales: {
					gridLines: {
						showBorder: false,
					},

					xAxes: [
						{
							// gridLines: {
							// 	drawBorder: false,
							// },
							zeroLineColor: '#ffff',
							gridThickness: 0,
							tickLength: 0,
							lineThickness: 0,
							stacked: true,
							ticks: {
								autoSkip: false,
							},

							gridLines: { display: false },
						},
					],
					yAxes: [
						{
							ticks: {
								autoSkip: false,
								beginAtZero: false,
							},
							gridThickness: 0,
							tickLength: 0,
							lineThickness: 0,
							gridLines: {
								drawBorder: false,
							},
							stacked: true,
						},
					],
				},

				legend: {
					boxHeight: '12px',
					display: true,
					position: 'bottom',
					align: 'start',
					boxWidth: 10,
					labels: {
						fontColor: '#484B52',
					},
				},
			},
		});
	});

	return (
		<>
			<div className="App">
				<canvas id="Chart" width="700px" height="500px" />
				<div id="legend"></div>
			</div>
		</>
	);
};

export default BarChart2;
