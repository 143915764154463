import React from 'react';
import ApproveEmployeeRequestFunctional from './components/approve-employee-request-functional';
import './approve-employee-request.scss';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess=UnauthorizedAccess(ApproveEmployeeRequestFunctional)
const ApproveEmployeeRequest = () => {
	let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
	return 	<AuthorizedAccess {...{currentUser}}/>;
};
export default ApproveEmployeeRequest;
