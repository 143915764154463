import React from 'react';
import { withReducer } from '@store/reducerLoader';
import AccouncementFunctional from './announcement-functional';
import reducer from './reducer';
import sagas from './saga/index';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess=UnauthorizedAccess(AccouncementFunctional)
const AnnouncementMain = () => {
	let currentClaims = useSelector((state)=>state?.sharedStates?.currentUser?.hrClaims);
	let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
	return <AuthorizedAccess {...{currentUser,currentClaims,UserClaims:"Manage.Announcements"}}/>;
};
const ReducedScreen = withReducer('counterReducer', reducer, sagas)(AnnouncementMain);
export default ReducedScreen;
