import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { employeeVerifyActions } from '@pages/employees/EmployeeVerification/redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SITEMAP } from 'src/routes/sitemap';

const useCallEmployeeVerify = (userData) => {
    const employeeVerification = useSelector((state) => state.employees?.verification)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (employeeVerification) {
            // previously we have employee verify check here before dashboard but removed the flow now
            redirectToDashboard()
        }
    }, [employeeVerification]);

    const callEmployeeVerifyCheck = async () => {
        dispatch(employeeVerifyActions.creators.employeeVerify());
    };

    function redirectToDashboard() {
        history.replace({ pathname: SITEMAP.dashboard.index, state: { user: userData || location?.state?.user } });
    }

    return { callEmployeeVerifyCheck }
}

export default useCallEmployeeVerify
