import action from './action';
import ClevertapReact from 'src/utils/clever-tap';
import Edenredmodal from '../../DesignSystem/Edenredmodal/index';
import HeroContent from '../Component/HeroContent/HeroContent';
import React, { useEffect, useState } from 'react';
import SetupTitle from '../Component/SetupTitle/SetupTitle';
import Stepper from '../Component/Stepper/Stepper';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import hrFeatureAction from '../HrFeatureAccess/action';
import './allowance.scss';

const Allowance = ({ cleverTap = false }) => {
	// const storeData = useSelector((state) => state?.hrSetup?.allowance?.data);
	const storeData = useSelector((state) => state?.hrSetup?.corporateSettings?.allowanceTypes) || [];
	const initialAllowanceList = [
		{
			name: 'Housing',
			isActive: storeData?.length ? false : true,
			id: '',
		},
		{
			name: 'Transport',
			isActive: storeData?.length ? false : true,
			id: '',
		},
		{
			name: 'Meal/Food',
			isActive: false,
			id: '',
		},
		{
			name: 'Telephone',
			isActive: false,
			id: '',
		},
		{
			name: 'Fuel',
			isActive: false,
			id: '',
		},
		{
			name: 'Other Allowances',
			isActive: false,
			id: '',
		},
	];
	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const location = useLocation();
	const stepperItems = location?.state?.editUploadSpreadsheet
		? [
				{
					name: '1 - Allowance',
					isActive: true,
					isCompleted: false,
					navigate: '/hr-features/allowance',
					editUploadSpreadsheet: true,
				},
				{
					name: '2 - Additions',
					isActive: false,
					isCompleted: false,
					navigate: '/hr-features/addition',
					editUploadSpreadsheet: true,
				},
				{
					name: '3 - Deductions',
					isActive: false,
					isCompleted: false,
					navigate: '/hr-features/deduction',
					editUploadSpreadsheet: true,
				},
		  ]
		: [
				{
					name: '1 - Allowance',
					isActive: true,
					isCompleted: false,
					navigate: '/new-hr-features/allowance',
				},
				{
					name: '2 - Additions',
					isActive: false,
					isCompleted: false,
					navigate: '/new-hr-features/addition',
				},
				{
					name: '3 - Deductions',
					isActive: false,
					isCompleted: false,
					navigate: '/new-hr-features/deduction',
				},
				{
					name: '4 - Upload Data (Optional)',
					isActive: false,
					isCompleted: false,
					navigate: '/new-hr-features/uploadPayrollDetails',
				},
		  ];

	// const convertedList = storeData?.map((item) => ({ id: item?.id, name: item.name, isActive: true })); // adding checked property to the list(checkbox status)
	let mergeList = [];
	if (storeData) {
		const uniqueList = initialAllowanceList.filter((item) => !storeData.some((element) => element.name === item.name)); // removing same categories
		mergeList = [...uniqueList, ...storeData]; // removing same categories
	} else {
		mergeList = [...initialAllowanceList];
	}
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState({ state: false, value: '' });
	const [allowanceList, setAllowanceList] = useState([...mergeList]);
	const [postList, setPostList] = useState([]);
	const [newAllowanceItem, setNewAllowanceItem] = useState('');
	const [showModal, setShowModal] = useState('');
	const history = useHistory();
	const [isAlreadyExists, setIsAlreadyExists] = useState(false);
	const { allowance } = useSelector((state) => state?.hrSetup) || [];
	const handleCheckboxState = (isSelected, value, isActive, isEmployeeExists) => {
		// use this dispatch call for get how many employee have this selected allowance
		ClevertapReact.event(cleverTap ? 'V1_1_Allowances_AddNew' : 'V1_1_UploadSpreadEdit_Allowances_AddNew');
		if (isActive === true && isEmployeeExists === true) {
			setModalOpen({ state: true, value: modalOpen?.value });
		} else {
			// changing checkbox state
			const allowanceListCopy = [...allowanceList]; // storing allowance list
			const indexOfValue = allowanceListCopy.findIndex((item) => item.name === value); // finding index of the element
			if (indexOfValue > -1) {
				allowanceListCopy[indexOfValue].isActive = isSelected;
				setAllowanceList(allowanceListCopy);
			}
		}
	};
	const addNewAllowance = (value = '') => {
		ClevertapReact.event('V1_1_Allowances_AddNew');
		// add new category to the allowances list
		setShowModal('AddNewAllowance');
		const isValueNewItem = !allowanceList.some((item) => item.name == value); // checking value is unique or not
		if (value !== '' && isValueNewItem) {
			setAllowanceList((prev) => [...prev, { id: '', name: value.trim(), isActive: true }]);
			setNewAllowanceItem('');
			setShowModal('');
		}
	};
	const closeModal = () => {
		setShowModal('');
		setNewAllowanceItem('');
		setIsAlreadyExists(false);
	};
	const saveChanges = () => {
		const filteredAllowanceList = [...allowanceList].filter((item) => item?.isActive === true); // storing checked item to the new temp variable
		const isEdited = JSON.stringify(storeData.filter((item) => item?.isActive === true)) !== JSON.stringify(filteredAllowanceList); // checking user checked list with data from store
		const finalList = filteredAllowanceList.map((item) => ({ name: item?.name, id: item?.id || '' })); // converting list format to match database
		setPostList([...finalList]);
		if (finalList.length > 0) {
			var cleverTapData = finalList.map((data) => data.name);
		}
		const cleverTapProperties = {
			AllowanceTypes: cleverTapData.toString(),
		};
		ClevertapReact.event(
			cleverTap ? 'V1_1_Allowances_Next' : 'V1_1_UploadSpreadEdit_Allowances_Next',
			cleverTapData.length > 0 ? cleverTapProperties : null
		);
		if (isEdited && finalList && finalList.length > 0) {
			dispatch(action.creators.postAllowance(finalList));
		}
		if (finalList && finalList.length > 0) {
			history.push(
				location?.state?.editUploadSpreadsheet
					? { pathname: '/hr-features/addition', state: { editUploadSpreadsheet: true } }
					: '/new-hr-features/addition'
			);
		} else {
			setShowModal('SkipOrSaveChanges');
		}
	};

	// to save & push (popup)
	const onSkipForNow = () => {
		ClevertapReact.event(cleverTap ? 'V1_1_Allowances_PopSkip' : 'V1_1_UploadSpreadEdit_Allowances_PopSkip');
		dispatch(action.creators.postAllowance(postList));
		history.push(
			location?.state?.editUploadSpreadsheet
				? { pathname: '/hr-features/addition', state: { editUploadSpreadsheet: true } }
				: '/new-hr-features/addition'
		);
	};

	const handleChange = (event) => {
		const value = event?.target?.value;
		setNewAllowanceItem(value);
		let isUnique = allowanceList.some((item) => item?.name.toLowerCase() === value.trim().toLowerCase());
		if (isUnique === false) {
			setIsAlreadyExists(false);
		} else {
			setIsAlreadyExists(true);
		}
	};

	useEffect(() => {
		dispatch(hrFeatureAction.creators.getCorporateSettings());
		dispatch(action?.creators.getAllowance());
	}, []);

	return (
		<>
			<main className="main-wrapper">
				<div className="container_title_box">
					<SetupTitle
						title="What type of ALLOWANCES do you have?"
						rightBtnClick={saveChanges}
						rightBtnRightIcon={focus_state_icons.RightArrow}
						rightBtnText="Next"
						rightBtnType="primary"
					/>
				</div>
				<div className="hero-content">
					<Stepper
						listOfTabs={stepperItems}
						// handleTabClick={} (under discussion)
					/>
					<HeroContent
						title="Allowances are a recurring part of the employees' contract. Please select or add the types of allowances that are included in their contract and add them accordingly."
						list={allowanceList}
						handleCheckboxState={handleCheckboxState}
						onAddNewItem={() => addNewAllowance(newAllowanceItem)}
						addNewItemButtonTitle={'Add New Allowance'}
						loader={allowance?.loading}
					/>
				</div>
				<Edenredmodal
					title="Warning"
					desc={`You cannot unselect ${modalOpen?.value} because payroll has been processed with this allowance`}
					onOkay={() => setModalOpen({ state: false, value: '' })}
					onOkayButton="Okay"
					isOpen={allowance?.loading === false && modalOpen?.state}
					imageSrc={icons.not_focus?.info2}
					heart={true}
				/>
			</main>
			{showModal === 'SkipOrSaveChanges' && (
				<Edenredmodal
					title="You didn’t add new Allowance"
					desc="Creating allowances will you speed up salary processing next payroll run.."
					onOkay={() => {
						ClevertapReact.event(cleverTap ? 'V1_1_Allowances_PopAdd' : 'V1_1_UploadSpreadEdit_Allowances_PopAdd');
						setShowModal('');
					}}
					onCancelButton="Skip For Now"
					onOkayButton="Add Allowance"
					onCancel={onSkipForNow}
					isOpen={showModal !== ''}
					imageSrc={focus_state_icons?.warningNew}
					heart={true}
				/>
			)}
			<Edenredmodal
				title="Allowance Cannot be Unselected"
				desc={`Allowance is a part of your employees' contract and cannot be edited `}
				onOkay={() => setModalOpen({ state: false, value: '' })}
				onOkayButton="Ok, Got it"
				isOpen={allowance?.loading === false && modalOpen?.state}
				imageSrc={focus_state_icons?.warningNew}
				heart={true}
			/>
			{showModal === 'AddNewAllowance' && (
				<Edenredmodal
					field="Enter Allowance"
					fieldlabel="Add New Allowance"
					onOkay={() => addNewAllowance(newAllowanceItem)}
					onCancelButton="Cancel"
					onOkayButton="Add"
					onOkayDisable={newAllowanceItem.trim() === '' || isAlreadyExists}
					onCancel={closeModal}
					isOpen={showModal !== ''}
					close={closeModal}
					fieldonchange={handleChange}
					fieldValue={newAllowanceItem}
					errorMessage={isAlreadyExists && 'Allowance already exists'}
				/>
			)}
		</>
	);
};

export default Allowance;
