import React from 'react';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import UploadDocumentPreview from './UploadDocumentPreview';
import './setupUploadDocumentPreview.scss';

const SetupUploadDocumentPreview = () => {
	return (
		<main className="page-wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<UploadDocumentPreview clevertap={false} />
			</div>
		</main>
	);
};

export default SetupUploadDocumentPreview;
