import React from 'react';
import { SITEMAP } from 'src/routes/sitemap';
import HelpFaqs from './HelpFaqs';
import { Route } from 'react-router-dom';
import LabourLaw from '@pages/help/labour-law';
import Payroll from '@pages/help/PayrollFaqs';
import Employee from '@pages/help/employee';
import Invoice from '@pages/help/InvoiceFaqs';

const HelpPage = () => {
	return (
		<>
			<Route path={SITEMAP.help.labourLaws.index} component={LabourLaw} />
			<Route path={SITEMAP.help.employee.index} component={Employee} />
			<Route path={SITEMAP.help.invoice} component={Invoice} exact />
			<Route path={SITEMAP.help.payroll} component={Payroll} exact />
			<Route path={SITEMAP.help.index} exact component={HelpFaqs} />
		</>
	);
};

export default HelpPage;
