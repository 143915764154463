import { APP_VARIABLES, DOMAIN_URL } from "@constants/app-constants";
import Axios from "axios";
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import Actions from "../mfaActions";
import { COOKIE_CONSTANTS, deletingCookie, gettingCookie, settingCookie } from "src/helpers/cookies";
import { MFA_CONSTANTS } from "../constants";
import { v4 as uuidv4, validate } from 'uuid';
import { getLocalvariable, removeLocalvariable } from "@helpers";

const baseUrl = `${DOMAIN_URL}/Users`;

export function* checkingMfa() {
    yield put(Actions.creators.mfaLoading(true))
    yield put(Actions.creators.mfaProcessing(true))
    settingCookie(COOKIE_CONSTANTS.mfa.inProgress, true)
    const cookieName = COOKIE_CONSTANTS.device.name
    const validCookieId = gettingCookie(cookieName) && validate(gettingCookie(cookieName))
    let deviceId = validCookieId ? gettingCookie(cookieName) : null

    if (!deviceId) {
        const newDeviceId = uuidv4();
        const cookieExpiry = COOKIE_CONSTANTS.device.expiryInDays
        settingCookie(cookieName, newDeviceId, cookieExpiry)
        deviceId = newDeviceId
    }
    try {
        const url = `${baseUrl}/second-factor-mode`;
        const localtoken = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
        const token = localtoken && JSON.parse(localtoken).access_token;

        const headers = {
            'X-Device-Id': deviceId || '',
        }
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        const response = yield call(Axios.get, url, { headers })
        const { mode } = response.data || {}
        yield put(Actions.creators.settingMfa(response.data))
        if (mode === MFA_CONSTANTS.mfa.OFF || mode === MFA_CONSTANTS.mfa.VERIFIED) {
            if (gettingCookie(COOKIE_CONSTANTS.mfa.inProgress)) {
                deletingCookie(COOKIE_CONSTANTS.mfa.inProgress)
            }
            yield put(Actions.creators.clearMfaData())
        }

        yield put(Actions.creators.mfaLoading(false))
    } catch (error) {
        yield put(Actions.creators.mfaError(error))
    }
}

export function* sendOtp(action) {
    yield put(Actions.creators.clearOtpData())
    yield put(Actions.creators.otpLoading(true))
    const cookieName = COOKIE_CONSTANTS.device.name
    const validCookieId = gettingCookie(cookieName) && validate(gettingCookie(cookieName))
    let deviceId = validCookieId ? gettingCookie(cookieName) : null

    if (!deviceId) {
        const newDeviceId = uuidv4();
        const cookieExpiry = COOKIE_CONSTANTS.device.expiryInDays
        settingCookie(cookieName, newDeviceId, cookieExpiry)
        deviceId = newDeviceId
    }
    try {
        const url = `${baseUrl}/send-otp`;
        const localtoken = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
        const token = localtoken && JSON.parse(localtoken).access_token;
        const headers = {
            'X-Device-Id': deviceId || '',
        }
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        const response = yield call(Axios.post, url, action.payload, { headers })
        if (response.data === true) {
            yield put(Actions.creators.otpLoading(false))
            yield put(Actions.creators.setOtpData({ sent: response.data }))
        } else {
            yield put(Actions.creators.otpLoading(false))
            yield put(Actions.creators.setOtpData({ sent: false }))
        }

    } catch (error) {
        yield put(Actions.creators.otpLoading(false))
        yield put(Actions.creators.mfaError(error))
    }
}
export function* verifyMfa(action) {
    yield put(Actions.creators.clearOtpData())
    yield put(Actions.creators.verifyOtp(true))
    const cookieName = COOKIE_CONSTANTS.device.name
    const validCookieId = gettingCookie(cookieName) && validate(gettingCookie(cookieName))
    let deviceId = validCookieId ? gettingCookie(cookieName) : null
    if (!deviceId) {
        const newDeviceId = uuidv4();
        const cookieExpiry = COOKIE_CONSTANTS.device.expiryInDays
        settingCookie(cookieName, newDeviceId, cookieExpiry)
        deviceId = newDeviceId
    }
    try {
        const url = `${baseUrl}/verify-second-factor-mode`;
        const localtoken = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
        const token = localtoken && JSON.parse(localtoken).access_token;;
        const headers = {
            'X-Device-Id': deviceId || '',
        }
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        const response = yield call(Axios.post, url, action.payload, { headers })
        yield put(Actions.creators.setOtpData(response.data))
        yield put(Actions.creators.verifyOtp(false))
        if (response.data?.status === MFA_CONSTANTS.otpStatusVerify.VERIFIED) {
            // If user not gave remember device removing the cookie so that new cookie created upon new request
            if (!action.payload.rememberMe) {
                deletingCookie(cookieName)
            }
            if (gettingCookie(COOKIE_CONSTANTS.mfa.inProgress)) {
                deletingCookie(COOKIE_CONSTANTS.mfa.inProgress)
            }
            // While setup mfa and update mobile number page storing countries api data in local storage upon verify removing this , as we dont use this anywhere
            if (getLocalvariable(MFA_CONSTANTS.localStorage.countriesKey)) {
                removeLocalvariable(MFA_CONSTANTS.localStorage.countriesKey)
            }
            yield put(Actions.creators.clearOtpData())
            yield put(Actions.creators.clearMfaData())
            yield put(Actions.creators.clearError())
        }
    } catch (error) {
        yield put(Actions.creators.verifyOtp(false))
        yield put(Actions.creators.mfaError(error))
    }
}

export function* updatePhoneNumber(action) {
    yield put(Actions.creators.clearOtpData())
    yield put(Actions.creators.verifyOtp(true))
    const cookieName = COOKIE_CONSTANTS.device.name
    const validCookieId = gettingCookie(cookieName) && validate(gettingCookie(cookieName))
    let deviceId = validCookieId ? gettingCookie(cookieName) : null
    if (!deviceId) {
        const newDeviceId = uuidv4();
        const cookieExpiry = COOKIE_CONSTANTS.device.expiryInDays
        settingCookie(cookieName, newDeviceId, cookieExpiry)
        deviceId = newDeviceId
    }
    try {
        const url = `${baseUrl}/update-phone-number`;
        const localtoken = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
        const token = localtoken && JSON.parse(localtoken).access_token;
        const headers = {
            'X-Device-Id': deviceId || '',
        }
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        const response = yield call(Axios.post, url, action.payload, { headers })
        yield put(Actions.creators.verifyOtp(false))
        yield put(Actions.creators.verifyUpdatePhoneNumber(response.data))
        if (response.data.status === MFA_CONSTANTS.otpStatusVerify.UPDATED) {
            yield put(Actions.creators.clearOtpData())
            yield put(Actions.creators.clearError())
        }
    } catch (error) {
        yield put(Actions.creators.updatePhoneNumberError(error))

    }
}


export function* mfaWatcher() {
    yield takeEvery(Actions.types.CHECK_MFA, checkingMfa);
    yield takeLatest(Actions.types.VERIFY_MFA, verifyMfa);
    yield takeLatest(Actions.types.SEND_OTP, sendOtp);
    yield takeLatest(Actions.types.UPDATE_PHONE_NUMBER, updatePhoneNumber);
}
