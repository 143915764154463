import React from 'react';
import ApprovalWorkflow from '@pages/approval-workflow-leave/approval-workflow';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import './approve-workflow-setup.scss';

const ApproveWorkflowSetup = () => {
	return (
		<>
			<SetupHeader />
			<div className="setup-workflow" style={{ padding: '0px 140px 0px 140px', background: '#F7F8FA' }}>
				<ApprovalWorkflow {...{ isSetup: true }} />
			</div>
		</>
	);
};

export default ApproveWorkflowSetup;
