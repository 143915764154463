import React from 'react';
import { Row, Col } from 'antd';
import Uploaddocument from '@pages/payroll/EmployeesExpirymodule/component/Upload-document';
import SetupHeader from '@pages/setup-Screen/components/setup-header';

function SetupDocumentPresentational({
	setUpUpload,
	isPayrollUploaded,
	isEmployeeUploadLoading,
	EmployeeUploadDetails,
	employeePayrollUploadSaga,
	empDocumentUploadReset,
}) {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
			<SetupHeader />
			<Row style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="setupEmpDocs">
					<Uploaddocument
						{...{
							setUpUpload,
							isSetup: true,
							setUpName: 'Employee & Payroll Details:',
							hintText:
								'To complete this setup. fill in Basic Salary, Allowance, Joining Date, Contract Type, Department and Designation.',
							description: 'Automatically calculate your payroll and leave balances each month, following the steps below.',
							nextPath: '/setupHrEmployeepayrollList',
							docLink: '/setupDocument',
							uploadType: 'employeePayroll',
							tableData: EmployeeUploadDetails,
							isLeaveUploadLoading: isEmployeeUploadLoading,
							leaveUploadDetails: EmployeeUploadDetails,
							leaveApproveUploadSaga: employeePayrollUploadSaga,
							isLeaveDocUploaded: isPayrollUploaded,
							resetUploadDocuments: empDocumentUploadReset,
						}}
					/>
				</Col>
			</Row>
		</div>
	);
}

export default SetupDocumentPresentational;
