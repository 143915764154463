import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './monthwise-reducer';
import saga from './saga/index';
import SalaryMontthWiseReportFunctional from '../salary-monthwise-reports/components/salary-monthWise-functional';

const SalaryMonthWiseReport = () => {
	return <SalaryMontthWiseReportFunctional />;
};
const ReducedScreen = withReducer('salaryMonthWise', reducer, saga)(SalaryMonthWiseReport);
export default ReducedScreen;
