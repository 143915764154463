import React, { useRef } from 'react';
import { Col, Row, Divider } from 'antd';
import moment from 'moment';
import EdenredTypo from '@sharedComponent/typography';
import { DATE_FORMAT_LEAVE } from '@constants/app-constants';
import ETypo from '@pages/DesignSystem/Typo';
import Table from '@pages/DesignSystem/Table/Table';
import Chips from '@pages/DesignSystem/Chips';
import ContentLoader from '@sharedComponent/content-loader';
import useOnClickOutside from '@pages/payroll-plus-new/components/useOnClickOutside';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './overlapCard.scss';

function OverlapCard({ isModalVisible, setIsModalVisible, title, subtitle, dataOverlap, loader }) {
	const column = [
		{
			title: 'Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			width: '30%',
			render: (text, row) => (
				<ETypo b2 medium className="overlapping-highlighted-text">
					{row.employeeName}
				</ETypo>
			),
		},
		{
			title: 'Employee ID',
			dataIndex: 'employeeId',
			key: 'employeeId',
			width: '20%',
			render: (text, row) => (
				<ETypo b2 light className="overlapping-text">
					{row.employeeId}
				</ETypo>
			),
		},
		{
			title: 'Leave Type',
			dataIndex: 'leaveType',
			key: 'leaveType',
			width: '20%',
			render: (text) => (
				<Chips color={setLeaveColor(text)}>
					<ETypo b2 light className="overlapping-text">
						{text}
					</ETypo>
				</Chips>
			),
		},
		{
			title: 'Date',
			dataIndex: 'fromTo',
			key: 'fromTo',
			width: '30%',
			render: (text, row) => (
				<ETypo b2 light className="overlapping-text">
					{moment(row.startDate).format(DATE_FORMAT_LEAVE.DD_MM_YYYY)} - {moment(row.endDate).format(DATE_FORMAT_LEAVE.DD_MM_YYYY)}
				</ETypo>
			),
		},
		{
			title: 'Days',
			dataIndex: 'totalDays',
			key: 'totalDays',
			width: '5%',
			render: (text) => (
				<ETypo b2 light className="overlapping-text">
					{text}
				</ETypo>
			),
		},
	];
	const ref = useRef(null);
	useOnClickOutside(ref, () => {
		if (isModalVisible) setIsModalVisible(false);
	});
	const handleCancel = () => setIsModalVisible(false);
	const setLeaveColor = (type) => {
		switch (type) {
			case 'Annual':
				return '#FEF5E8';
			case 'Sick':
				return '#FFE5E5';
			case 'Unpaid':
				return '#CCECE6';
			case 'Bereavement':
				return '#FEF5E8';
		}
	};

	return (
		<>
			{isModalVisible ? (
				<div ref={ref} className="leave-modal">
					<Col xl={12} lg={12} xs={12} className="modal-content">
						<Col xl={24} lg={24} xs={24} className="overlapping-modal-web">
							<Row xs={24}>
								<Col xs={24}>
									<div className="overlapping-text-container">
										<Col className="header-and-department-container">
											<ETypo h3 bold className="overlapping-text-header">
												{title}
											</ETypo>
											<ETypo b2 light className="overlapping-sub-text">
												{subtitle} Department
											</ETypo>
										</Col>
										<Col className="close-icon-container">
											<ImageComponent
												onClick={handleCancel}
												src={focus_state_icons.OverLappingClose}
												className="close-icon-style"></ImageComponent>
										</Col>
									</div>
									<div className="table-section">
										{!!!dataOverlap?.length && loader ? (
											<div className="loader-style">
												<ContentLoader />
											</div>
										) : (
											<div className="table-modificaton">
												<Table columns={column} tableLoading={!!!dataOverlap?.length && loader} data={dataOverlap} />
											</div>
										)}
										<Col xs={24} className="overlaping-card-mobile-main">
											{loader ? (
												<ContentLoader />
											) : (
												<>
													{dataOverlap?.map((data) => (
														<>
															<Col>
																<EdenredTypo className="name-leave-mob bold">{data.employeeName}</EdenredTypo>
															</Col>
															<Col>
																<EdenredTypo className="name-leave-mob-sub">{data.employeeId}</EdenredTypo>
															</Col>
															<Col>
																<EdenredTypo className="type-overlaping-text medium">
																	{data?.leaveType}:
																	<span className="overlap-text-inner-date">
																		{moment(data?.startDate).format(DATE_FORMAT_LEAVE.MM_DD)}-
																		{moment(data?.endDate).format(DATE_FORMAT_LEAVE.MM_DD)}
																	</span>
																</EdenredTypo>
															</Col>
															<Divider style={{ margin: '10px 0px' }} />
														</>
													))}
												</>
											)}
										</Col>
									</div>
								</Col>
							</Row>
						</Col>
					</Col>
				</div>
			) : null}
		</>
	);
}

export default OverlapCard;
