import React from 'react';
import SetupApprovalWorkflow from './SetupApprovalWorkflow';
import EdenRedHeader from '@pages/NewHrModuleSetup/Component/EdenRedHeader/EdenRedHeader';
import '../Departments/index.scss';
const SetupApprovalWorkflowPage = () => {
	return (
		<main className="wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<SetupApprovalWorkflow navigate="/new-hr-features/invite-employees" CleverTap={false} />
			</div>
		</main>
	);
};

export default SetupApprovalWorkflowPage;
