import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Upload, Card } from 'antd';
import * as XLSX from 'xlsx';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import ErrorTable from '@sharedComponent/error-table';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import EDragger from '@pages/DesignSystem/Dragger';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import { icons } from 'src/assets/icons';
import EmployeeService from 'src/services/employee-service';
import ClevertapReact from 'src/utils/clever-tap';

const { Dragger } = Upload;
const DeleteEmployeesInBulk = () => {
	const history = useHistory();
	const [errorData, seterrorData] = useState([]);
	const [deleteOption, setDeleteOption] = useState('Immediately');
	const [employeeTableData, SetemployeeTableData] = useState([]);
	const [validInvalidData, setValidInvalidData] = useState({
		inValidEmployees: null,
		validEmployees: null,
	});
	const [deleteEmployeeData, setDeleteEmployeeData] = useState([]);
	const [deleteEmployeesDragger, setDeleteEmployeesDragger] = useState(false);
	const [isDeleteEmployeesLoading, setIsDeleteEmployeesLoading] = useState(false);
	const [checked, setChecked] = useState(true);
	const [checkedAfter, setCheckedAfter] = useState(false);
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	const [files, setFiles] = useState({});
	const [clicked, setClicked] = useState(true);
	const [click, setClick] = useState(false);
	const employeeApi = new EmployeeService();
	const deleteOptions = [
		{
			name: 'Immediately',
			icon: clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox,
		},
		{
			name: 'After 30 days',
			icon: !clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox,
		},
	];

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const { validEmployees, inValidEmployees } = validInvalidData;

	const onFinish = (values) => {
		ClevertapReact.event('Submit_DeleteBulkEmployees');

		history.push({
			pathname: '/manange/employees/bulk/delete/sumbit',
			state: { validEmployees, inValidEmployees, deleteOption, DeleteEmployeeType: clicked ? 'Immediately' : 'After30Days' },
		});
	};

	const deleteEmployeesConfig = {
		accept: '.xls, .xlsx',
		onChange(info) {
			if (info.file.status === 'done') {
				setFiles(info);
				getEmployees(info.file.originFileObj);
			}
		},
	};

	async function fetchEmployees() {
		let queryParams = {
			status: 'ACTIVE',
			includeEmployeeCounts: true,
		};
		try {
			let response = await employeeApi.getEmployees(queryParams);
			if (response && response.data) {
				let ids = response.data.employees.map((emp) => emp.employeeCode);
				SetemployeeTableData(ids);
			}
		} catch (err) {
			SetemployeeTableData([]);
		}
	}

	const generateEmployeeData = (data) => {
		let validEmployees = [];
		let inValidEmployees = [];
		data.map((emp) => {
			if (emp.firstName) {
				validEmployees.push({
					employeeName: emp.name,
					employeeCode: emp.employeeId,
				});
			} else {
				inValidEmployees.push(emp.employeeId);
			}
		});
		setValidInvalidData({
			...validInvalidData,
			inValidEmployees,
			validEmployees,
		});
	};

	useEffect(() => {
		// fetchEmployees();
	}, []);

	const getEmployees = (selectedFile) => {
		setIsDeleteEmployeesLoading(true);
		let formData = new FormData();
		formData.append('file', selectedFile);
		employeeApi
			.getEmployeesForDelete(formData)
			.then((res) => {
				setDeleteEmployeeData(res.data);
				setDeleteEmployeesDragger(true);
				setIsDeleteEmployeesLoading(false);
				if (res.data.validationMessage) {
					seterrorData([...res.data.validationMessage]);
				} else {
					generateEmployeeData(res.data);
				}
			})
			.catch((err) => {
				setDeleteEmployeeData([]);
				setDeleteEmployeesDragger(false);
				setIsDeleteEmployeesLoading(false);
			});
	};

	// console.log('employeeTableData', employeeTableData, deleteEmployeeData);

	// const handleSelectedCard = (index) => {
	// 	console.log('kdjnk');
	// 	setClicked(!clicked);
	// 	if (clicked === true) {
	// 		setDeleteOption(deleteOptions[index].name);
	// 	} else {
	// 		setDeleteOption(deleteOptions[index].name);
	// 	}
	// };
	const handleSelectedCard1 = () => {
		if (clicked == false) {
			setClicked(!clicked);
			setClick(false);
		}
	};
	const handleSelectedCard2 = () => {
		if (click == false) {
			setClick(!click);
			setClicked(false);
		}
	};
	const handleLocalDelete = () => {
		setDeleteEmployeeData({});
		setDeleteEmployeesDragger(false);
		generateEmployeeData([]);
		seterrorData([]);
	};

	let columnKeys = [{ labelKey: 'Employee ID', valueKey: 'employeeCode' }];

	const hasError = errorData.length;

	const draggerIcon = () => {
		if (isDeleteEmployeesLoading) {
			return focus_state_icons.hrUploading;
		} else if (hasError > 0) {
			return focus_state_icons.NewDesignErro;
		} else if (deleteEmployeesDragger) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = (value) => {
		if (isDeleteEmployeesLoading) {
			return 'uploading';
		} else if (hasError > 0) {
			return 'error';
		} else if (deleteEmployeesDragger) {
			return 'done';
		} else {
			return 'new';
		}
	};
	const handleDragger = (info) => {
		setFiles(info);
		getEmployees(info);
	};
	const onRemoveUpload = () => {
		setFiles({});
		seterrorData([]);
		setDeleteEmployeesDragger(false);
	};

	const handleChecked = () => {
		setChecked(!checked);
		setCheckedAfter(false);
		setClicked(!clicked);
		setClick(false);
	};
	const handleCheckedAfter = () => {
		setCheckedAfter(!checkedAfter);
		setChecked(false);
		setClick(!click);
		setClicked(false);
	};
	const handleDownload = () => {};
	return (
		<>
			{/* <EdenredHeader /> */}
			<Row className="delete_employees_in_bulk_container">
				<Col className="delete_employees_in_bulk_title">
					<ETypo h2 bold>
						Delete Employees In Bulk
					</ETypo>
					<div style={{ display: 'flex', gap: '8px' }}>
						<EdenredButtons
							{...{
								btnType: 'secondary',
								leftIcon: <ImageComponent src={images.leftsarrow} />,
								className: 'button_back_new ',
								onClick: () =>
									history.push({
										pathname: '/employees/manage_employee',
									}),
							}}>
							<ETypo b1 color="#484B52">
								Back
							</ETypo>
						</EdenredButtons>
						<Form.Item shouldUpdate={true}>
							<EdenredButtons
								{...{
									btnType: 'primary',
									className: 'button_back_new',
									onClick: onFinish,
									disabled: !deleteEmployeesDragger || hasError,
								}}>
								Next
							</EdenredButtons>
						</Form.Item>
					</div>
				</Col>
				<div className="delete-container">
					<Form form={form}>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="delete_employees_in_bulk_dragger_container">
							<p className="delete_container_heading">Import Employee Spreadsheet</p>
							<Form.Item name="delete_employees_in_bulk_spreadsheet">
								<EDragger
									{...{
										idleIcon: draggerIcon(),
										draggerStatus: draggerStatus(),
										uploadDeleteIcon: focus_state_icons.NewDelete,
										onChange: (e) => handleDragger(e),
										onRemoveUpload,
									}}
								/>
							</Form.Item>
						</Col>

						{/* <Col xl={24} lg={24} md={24} sm={24} xs={24} className="delete_employees_card_title">
						<EdenredTypo>When do you want to delete these employees?</EdenredTypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<Row className="delete_employees_time_card_row">
							<Col xl={9} lg={12} md={20} xs={12} sm={12} className="delete_employees_time_card_column">
								<Card
									className={clicked === true ? 'delete_employees_time_select_card' : 'delete_employees_time_unselect_card'}
									onClick={handleSelectedCard1}>
									<Row className="card_body_content_row">
										<Col xl={4} md={4} lg={4} sm={24} xs={24} className="delete_employees_select_image">
											<ImageComponent src={clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox} />
										</Col>
										<Col xl={20} md={20} lg={20} sm={24} xs={24} className="delete_employees_time_name">
											<EdenredTypo>Immediately</EdenredTypo>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xl={9} lg={12} md={20} xs={12} sm={12} className="delete_employees_time_card_column">
								<Card
									className={click === true ? 'delete_employees_time_select_card' : 'delete_employees_time_unselect_card'}
									onClick={handleSelectedCard2}>
									<Row className="card_body_content_row">
										<Col xl={4} md={4} lg={4} sm={24} xs={24} className="delete_employees_select_image">
											<ImageComponent src={click ? icons.focus_state.Check : icons.not_focus.EmptyBox} />
										</Col>
										<Col xl={20} md={20} lg={20} sm={24} xs={24} className="delete_employees_time_name">
											<EdenredTypo>After 30 days</EdenredTypo>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Col> */}
						{hasError ? (
							<Fragment>
								<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error">
									<Card className="card_action">
										<Row>
											<Col xl={1} sm={3} xs={3} md={2}>
												<ImageComponent src={icons.focus_state.Error} />
											</Col>
											<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
												Please correct the following errors and upload the file again.
											</Col>
											<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
												Please correct the following errors and upload the file again.
											</Col>
										</Row>
									</Card>
								</Col>
								<Col xl={24} md={24} sm={24} lg={24} xs={24} style={{ maxHeight: '30vh', overflow: 'auto' }} className="card_error">
									<ErrorTable data={errorData} fromDeletePage={true} />
								</Col>
							</Fragment>
						) : null}
						{/* <Col xl={8} lg={11} md={12} sm={24} xs={24} className="delete_employees_in_bulk_submit_button"> */}
						{/* <Form.Item shouldUpdate={true}>
							{() => (
								<EdenredButton onClick={onFinish} disabled={!deleteEmployeesDragger || hasError}>
									Submit
								</EdenredButton>
							)}
						</Form.Item> */}
						{/* </Col> */}
						<Col xl={24} lg={24} md={24} sm={20} xs={20} className="download_delete_employees_spreadsheet_template_hint">
							<EdenredTypo>
								If you don’t have the spreadsheet template you can download it
								{
									<ExcelDownload
										dataSet={[]}
										columnKeys={columnKeys}
										filename={`employeelist`}
										element={
											// <EdenredButton
											// 	className="fs-button-outline"
											// 	icon={<ImageComponent src={icons.not_focus.Download} className="download_image" />}
											// 	type="outline-g">
											// 	Download Spreadsheet
											// </EdenredButton>
											<ETypo className="Here-text" light b1>
												{' '}
												HERE.
											</ETypo>
										}
									/>
								}
							</EdenredTypo>
						</Col>
						{/* <Col xl={18} lg={18} md={18} sm={24} xs={24} className="download_delete_employees_spreadsheet_template_button"> */}

						{/* <ExcelDownload columnKeys={columnKeys} dataSet={validInvalidData.validEmployees}>
							Download Spreadsheet
						</ExcelDownload> */}
						{/* <ExcelDownload columnKeys={columnKeys}>Download Spreadsheet</ExcelDownload> */}
						{/* </Col> */}

						<div>
							<p className="delete_container_heading">When do you want to delete these employees?</p>
							<div style={{ display: 'flex', gap: '30px' }}>
								<div className={checked ? 'delete-employee-button' : 'delete-employee-button-uncheck'}>
									<Checkbox
										checked={checked}
										borderRadius={4}
										checkHeight={12}
										labelPadding={4}
										size={14}
										onChange={handleChecked}
										label={
											<EdenredTypo headerStyle={'H4'} fontWeightStyle={'bold'} className="delete-conatiner-text">
												Immediately
											</EdenredTypo>
										}
									/>
								</div>
								{/* Due to development time constraints. For now hiding this option as discussed with client */}
								{/* <div className={checkedAfter ? 'delete-employee-button' : 'delete-employee-button-uncheck'}>
									<Checkbox
										checked={checkedAfter}
										borderRadius={4}
										checkHeight={12}
										labelPadding={4}
										size={14}
										onChange={handleCheckedAfter}
										label={
											<EdenredTypo headerStyle={'H4'} fontWeightStyle={'bold'} className="delete-conatiner-text">
												After 30 Days
											</EdenredTypo>
										}
									/>
								</div> */}
							</div>
						</div>
					</Form>
				</div>
			</Row>
		</>
	);
};
export default DeleteEmployeesInBulk;
