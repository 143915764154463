import React from 'react';
import { withReducer } from '@store/reducerLoader';
import reducer from '../reducer';
import saga from '../saga';
import Approved from './Approved';

function ApprovedPage({ isSetup, employeesList, onSubmissionSuccess, onClickCancel }) {
	return <Approved {...{ isSetup, employeesList, onSubmissionSuccess, onClickCancel }} />;
}
const ReducedScreen = withReducer('expiryCountReducer', reducer, saga)(ApprovedPage);
export default ReducedScreen;
