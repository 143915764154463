import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import moment from 'moment';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { onlyNumberForNumberType } from '@helpers';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Inputbox from '@pages/DesignSystem/Input-Field/index';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import Toast from '@pages/DesignSystem/Toast';

const CompanyTabPane2 = ({
	handleOnChange,
	overtimePolicies,
	overtimeData,
	getOvertimePolicyPercentage,
	overTimePercentData,
	overTimePercentLoading,
	getOverTimeData,
	overTimeDataLoading,
	overTimeSalaryData,
	overTimeSalaryDataLoading,
	getOverTimeSalaryPerDay,
	setOvertimeData,
	overTimeDataInfo,
	setOvertimePolicies,
	setDefaultOvertimeData,
	setDefaultOvertimePolicies,
	handleSuccessPopups,
	isOverTimeSaved,
	iscompanySettingSaveSuccess,
	overTimeLabelData,
}) => {
	const [defaultSalaryData, setDefaultSalaryData] = useState();
	const [defaultCalendarData, setDefaultCalendarData] = useState();
	useEffect(() => {
		getOvertimePolicyPercentage();
		getOverTimeData();
		getOverTimeSalaryPerDay();
	}, []);
	useEffect(() => {
		if (
			overTimeDataInfo?.overtimeSalaryPerDayType?.salaryPerDay != null ||
			overTimeDataInfo?.overtimeSalaryPerDayType?.salaryPerDay != undefined
		) {
			let unSplitedData = overTimeDataInfo?.overtimeSalaryPerDayType?.salaryPerDay.split('/');
			setOvertimeData({
				salaryType: unSplitedData[0] || '',
				days: unSplitedData[1] || '',
				hoursADay: String(overTimeDataInfo?.overtimeSalaryPerDayType?.hoursADay) || '',
			});
			setDefaultOvertimeData({
				salaryType: unSplitedData[0],
				days: unSplitedData[1],
				hoursADay: String(overTimeDataInfo?.overtimeSalaryPerDayType?.hoursADay) || '',
			});
		}
		if (overTimeDataInfo?.overtimePolicies?.length > 0) {
			setOvertimePolicies(overTimeDataInfo?.overtimePolicies);
			setDefaultOvertimePolicies(overTimeDataInfo?.overtimePolicies);
		}
	}, [overTimeDataInfo]);
	useEffect(() => {
		if (overTimeSalaryData != null || overTimeSalaryData != undefined) {
			let newSplit = overTimeSalaryData.map((data) => data.name.split('/'));
			const uniqueArr1 = [...new Set(newSplit.map((data) => data[0]))];
			const uniqueArr2 = [...new Set(newSplit.map((data) => data[1]))];
			!Object?.keys(overTimeDataInfo?.overtimeSalaryPerDayType || {})?.length &&
				setOvertimeData({
					salaryType: uniqueArr1?.[0] || '',
					days: uniqueArr2?.[0] || '',
					hoursADay: 8,
				});
			const salaryValue = uniqueArr1?.map((data) => {
				return {
					salary: data,
				};
			});
			const calendarValue = uniqueArr2?.map((data) => {
				return {
					calendarDays: data,
				};
			});
			setDefaultSalaryData(salaryValue);
			setDefaultCalendarData(calendarValue);
		}
	}, [overTimeSalaryData]);
	const tooltip = useMemo(() => {
		const day = overtimeData.days === '30 Days' ? 30 : moment().daysInMonth();
		const hour = overtimeData.hoursADay ? overtimeData.hoursADay : 8;
		return 1000 / (day * hour);
	}, [overtimeData]);
	return (
		<>
			<Toast visible={iscompanySettingSaveSuccess.flag} color="#8EF9B3" textcolor="#484B52" clearPopUp={handleSuccessPopups}>
				{!isOverTimeSaved == 'success' ? 'Saved Unsuccessfully' : 'Saved Successfully'}
			</Toast>
			<Row className="company-setting-tabpane-row1 new_design_overtime">
				{/* onOkayButton: 'OK',
					onOkay: ,
					isOpen: iscompanySettingSaveSuccess.flag,
					title: !isOverTimeSaved == 'success' ? 'Saved Unsuccessfully' : 'Saved Successfully', */}

				<Col xl={24} lg={24} md={24} className="company-setting-column1 company-setting-column2">
					<Row xl={24} lg={24} md={24} className="payroll-policy-sub1">
						<Col xl={24} lg={24} md={24} className="company-setting-column1-sub1-head">
							<Col xl={24} lg={24} md={22}>
								<p className="overtime-amount">Overtime Calculation per Hours</p>
								<p className="directly-enter">Enter overtime hours worked to automatically calculate pay.</p>
							</Col>
						</Col>
					</Row>
					{overTimeDataLoading && overTimeSalaryDataLoading ? (
						<Skeleton width={'100%'} height={'30%'} />
					) : (
						<>
							<Row xl={24} lg={24} md={24} className="company-setting-column1-sub1-time payroll-policy-sub1">
								<Col className="basic-sal-dropdown1">
									<ETypo b1 light className="sal-text-policy1" color="#484B52">
										Salary per hour =
									</ETypo>
								</Col>
								<Col xl={5} lg={6} md={6} sm={6} className="basic-sal-dropdown2">
									<MultiSelect
										onChange={(data) => handleOnChange(data?.salary, 'salaryType')}
										placeholder="Basic Salary"
										Option={defaultSalaryData}
										value={overtimeData?.salaryType}
										dropDownValue="salary"
										searchVisibility={false}
										showIcon={false}
									/>
								</Col>
								<Col className="divide-padding">
									<img src={not_focus_icons.divideSvg} alt="" />
								</Col>
								<Col className="basic-sal-dropdown2 basic-sal-dropdown3" xl={4} lg={5} md={6} sm={5}>
									<MultiSelect
										onChange={(data) => handleOnChange(data?.calendarDays, 'days')}
										placeholder="Calendar Days"
										Option={defaultCalendarData}
										dropDownValue="calendarDays"
										value={overtimeData?.days}
										searchVisibility={false}
										showIcon={false}
									/>
								</Col>
								<Col className="divide-padding">
									<img src={not_focus_icons.closeSvg} alt="" />
								</Col>
								<Col className="hour-input">
									<Inputbox
										placeholder="Hours"
										type="number"
										onKeyDown={(evt) => onlyNumberForNumberType(evt) || (evt.key === '.' && evt.preventDefault())}
										labels="Hours"
										value={overtimeData?.hoursADay}
										onChange={(e) => handleOnChange(e.target.value, 'hoursADay')}
									/>
								</Col>
								<Col xl={1} lg={1} md={1} sm={1} className="info-box-payroll-run">
									<Tooltip
										direction="up"
										content={
											<div className="tooltip">
												<p className="tooltip_content">
													Use this to set how you want hourly salary to be calculated. This will be multiplied by the number
													of overtime hours you provide for each employee.
												</p>
												<p className="tooltip_eg">Eg:</p>
												<p className="tooltip_eg1">Employee's {overtimeData.salaryType} is AED 1,000</p>
												<p className="tooltip_eg1">
													Their hourly salary will be calculated as (1,000/(
													{overtimeData.days === '30 Days' ? 30 : moment().daysInMonth()}x
													{overtimeData.hoursADay ? overtimeData.hoursADay : 8})) = AED {tooltip?.toFixed(2)}
												</p>
												{/* <ETypo b1 light color="#FFFFFF">
												Use this to set how you want hourly salary to be calculated. This will be multiplied by the number of
												overtime hours you provide for each employee.
											</ETypo>
											<ETypo b1 light color="#FFFFFF">
												Eg:
											</ETypo>
											<ETypo b1 light color="#FFFFFF">
												Employee's {overtimeData.salaryType} is AED 1,000
											</ETypo>
											<ETypo b1 light color="#FFFFFF">
												Their hourly salary will be calculated as (1,000/(
												{overtimeData.days === '30 Days' ? 30 : moment().daysInMonth()}x
												{overtimeData.hoursADay ? overtimeData.hoursADay : 8})) = AED {tooltip?.toFixed(2)}
											</ETypo> */}
											</div>
										}>
										<img src={not_focus_icons.infoSvg} alt="" />
									</Tooltip>
								</Col>
							</Row>
						</>
					)}
					<Row xl={24} lg={24} md={24} className="payroll-policy-sub1-bottom">
						<Col xl={24} lg={24} md={24} className="overtime_policy">
							<EdenredTypo h5 bold className="title-color">
								Overtime Policy
							</EdenredTypo>
						</Col>
						{overTimePercentLoading || overTimeDataLoading ? (
							<Skeleton width={'100%'} height={'30%'} />
						) : (
							<>
								<Col xl={23} lg={23} md={23} sm={23} className="company-setting-column2-sub2">
									<Col xl={5} lg={8} md={8} sm={9} className="overtime-container1">
										<ETypo b1 light className="overtime-text1">
											{overTimeLabelData?.[0]?.name}
										</ETypo>
									</Col>
									<Col xl={2} lg={3} md={3} sm={5} className="basic-sal-dropdown2 overtime-container2">
										<MultiSelect
											value={overtimePolicies?.[0]?.standardPercentage}
											Option={overTimePercentData}
											onChange={(data) =>
												handleOnChange(data?.value, 'Overtime On Weekdays', overTimeDataInfo?.overtimePolicies[0]?.id)
											}
											dropDownValue="value"
											searchVisibility={false}
											showIcon={false}
										/>
									</Col>
								</Col>
								<Col xl={23} lg={23} md={23} sm={23} className="company-setting-column2-sub2">
									<Col xl={5} lg={8} md={8} sm={9} className="overtime-container1">
										<ETypo b1 light className="overtime-text1">
											{overTimeLabelData?.[1]?.name}
										</ETypo>
									</Col>
									<Col xl={2} lg={3} md={3} sm={5} className="basic-sal-dropdown2 overtime-container2">
										<MultiSelect
											// placeholder="1.5"
											value={overtimePolicies?.[1]?.standardPercentage}
											Option={overTimePercentData}
											onChange={(data) =>
												handleOnChange(data?.value, 'Overtime On Weekends', overTimeDataInfo?.overtimePolicies[1]?.id)
											}
											dropDownValue="value"
											searchVisibility={false}
											showIcon={false}
										/>
									</Col>
									<div className="for-brackets">
										<ImageComponent src={not_focus_icons.curvedLine} alt="" />
										<ImageComponent src={not_focus_icons.curvedBracket} alt="" className="for-curved-bracket" />
									</div>
									<p className="salary-per-hour">
										<img src={not_focus_icons.closeSvg} alt="" className="hint-padding" /> Salary Per Hour{' '}
										<img src={not_focus_icons.closeSvg} alt="" className="hint-padding" /> Number of Overtime Hours
									</p>
								</Col>
								<Col xl={23} lg={23} md={23} sm={23} className="company-setting-column2-sub2">
									<Col xl={5} lg={8} md={8} sm={9} className="overtime-container1">
										<ETypo b1 light className="overtime-text1">
											{overTimeLabelData?.[2]?.name}
										</ETypo>
									</Col>
									<Col xl={2} lg={3} md={3} sm={5} className="basic-sal-dropdown2 overtime-container2">
										<MultiSelect
											Option={overTimePercentData}
											value={overtimePolicies?.[2]?.standardPercentage}
											onChange={(data) =>
												handleOnChange(data?.value, 'Overtime On Public Holidays', overTimeDataInfo?.overtimePolicies[2]?.id)
											}
											dropDownValue="value"
											searchVisibility={false}
											showIcon={false}
										/>
									</Col>
								</Col>
							</>
						)}
					</Row>
				</Col>
				{/* <Edenredmodal
				{...{
					onOkayButton: 'OK',
					onOkay: handleSuccessPopups,
					isOpen: iscompanySettingSaveSuccess.flag,
					title: !isOverTimeSaved == 'success' ? 'Saved Unsuccessfully' : 'Saved Successfully',
				}}
			/> */}
			</Row>
		</>
	);
};

export default CompanyTabPane2;
