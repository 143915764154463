import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredEmployeeCard from '@sharedComponent/card/employees/employees-card';
import { icons } from 'src/assets/icons';
import ETypo from '../../DesignSystem/Typo/index';
import { mergeAllClaims } from 'src/utils/claims';
import { PERMISSIONS } from '@constants/app-constants';
import { SITEMAP } from 'src/routes/sitemap';
import { UN_EMPLOYMENT_TAB_KEYS } from '@pages/UnEmploymentInsurance/constants';
import selectors from '@sharedComponent/app-action-reducer/selectors';
import sharedActions from '@sharedComponent/app-action-reducer/action';
import './PendingAlerts.scss';
import { employeeVerifyActions } from '@pages/employees/EmployeeVerification/redux';

const PendingAlertsPage = () => {
	const currentUser = useSelector((state) => selectors.currentUser(state));
	const reqCounts = useSelector((state) => state?.sharedStates?.PendingStatusDetail?.pendingRequest);
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);
	const { unEmploymentBannerData } = useSelector((state) => state?.sharedStates) || {};
	const invalidEmployees = useSelector((state) => state?.employees?.verification?.invalidEmployees);
	const employeeVerification = useSelector((state) => state?.employees?.verification);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(sharedActions.creators.getPendingStatusStart());
	}, []);

	useEffect(() => {
		if (!employeeVerification) {
			dispatch(employeeVerifyActions.creators.employeeVerify());
		}
	}, [employeeVerification]);

	const checkClaims = (claim) => {
		const result = allClaims?.find((userClaim) => userClaim === claim);
		return typeof result === 'string';
	};

	// To check whethere corporate bulk subscribed or not using unemploymen banner flag
	function checkCorporateBulkSubscribed() {
		const { displayBanner } = unEmploymentBannerData ? unEmploymentBannerData?.data : {};
		if (displayBanner === false) {
			return true;
		}
		return false;
	}
	// During employee verification we get blocked cards user count
	const invalidEmployeesCount = invalidEmployees || reqCounts?.cardsToBeBlockedDueToEmployeeVerificationFailure || 0;

	const showCardBlockCard = () => {
		if (employeeVerification?.featureEnabled && invalidEmployeesCount) {
			return true;
		}
		return false;
	};

	const pendingAlerts = [
		{
			icons: icons.focus_state.cardBlockIcon,
			text: `UPDATE NOW! ${invalidEmployeesCount} Cards are going to be blocked due to incorrect details`,
			path: SITEMAP.employees.verification.dashboard.index,
			visible: showCardBlockCard(),
		},
		{
			icons: icons.focus_state.pendingPayrollApproval,
			text: `${reqCounts ? reqCounts.payrollRequest : 0} Payroll Approvals`,
			path: '/payroll/approve_requests',
			visible: checkClaims(PERMISSIONS.APPROVE_PAYROLL_REQUEST),
		},
		{
			icons: icons.focus_state.pendeingPayrolluser,
			text: `${reqCounts ? reqCounts.employeeRequest : 0} Employee Approvals`,
			path: '/employees/approve_employee_request',
			visible: checkClaims(PERMISSIONS.APPROVE_CARD_ORDERS),
		},
		{
			icons: icons.focus_state.hexaganVoiletTick,
			text: `${reqCounts ? reqCounts?.insurancePolicies || 0 : 0} Unemployment Insurance policies pending`,
			path: `${SITEMAP.unemploymentInsurance.singleUpdate}?tab=${UN_EMPLOYMENT_TAB_KEYS.PENDING}`,
			visible: checkCorporateBulkSubscribed(),
		},
	];

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', height: '100%' }}>
			<EdenredHeader />
			<div className="pending-alerts-conatiner">
				<div className="pending-alerts-col">
					{/* header */}
					<div className="pending-alerts-header">
						<ETypo h2 bold color="#00344E">
							Pending Alerts
						</ETypo>
					</div>
					{/* card */}
					<div className="pending-alerts-content-text">
						{pendingAlerts &&
							pendingAlerts?.map((content, index) => {
								if (content.visible === true) {
									return (
										<Link to={content?.path} key={index}>
											<EdenredEmployeeCard type="PendingAlerts" style={{ cursor: 'pointer' }} content={content} index={index} />
										</Link>
									);
								} else {
									return null;
								}
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PendingAlertsPage;
