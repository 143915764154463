import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Row, Select } from 'antd';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import ImageComponent from '@sharedComponent/image-component';
import { DATE_FORMAT } from '@constants/app-constants';
import { checkForPassportDocuments, checkForVisaDocuments } from '@helpers';
import './index.scss';
import ETypo from '@pages/DesignSystem/Typo';

const { Option } = Select;

const EdenredDropDown = ({ index, placeholder, name, employeeData, param, value, onChange, options, disabled = false, leaveValid }) => {
	const details = value[0];
	const [detail, setDetail] = useState();
	const [focused, setFocused] = useState(false);
	useEffect(() => {
		setDetail(options);
	}, [options]);

	const setSearchVals = (searchVal, data, filtered) => {
		return options?.filter((obj) => {
			let searchNewStr = searchVal?.toLowerCase().replace(/ /g, '');
			let searchStr = obj?.employeeName?.toLowerCase().replace(/ /g, '');
			let searchId = obj?.employeeCode?.toString();
			let searchID = obj?.employeeId?.toString();
			if (
				searchStr?.search(searchNewStr) !== -1 ||
				searchId?.search(searchNewStr) !== -1 ||
				(filtered && searchID?.search(searchNewStr) !== -1)
			) {
				return obj;
			}
		});
	};
	const setsearchleave = (searchVal, repeat = 1) => {
		return options?.filter((obj) => {
			let searchNewStr = searchVal?.toLowerCase();
			let searchStr = obj?.leaveTypeName?.toLowerCase();
			if (searchStr?.search(searchNewStr) !== -1) {
				return obj;
			}
		});
	};

	const onSearch = (searchText) => {
		let filtered = options?.filter(({ employeeId }) => {
			return employeeId?.toString().includes(searchText?.toString());
		});
		filtered = filtered.length > 0 ? false : true;
		if (searchText && param === 'leaveType') {
			// let arrayOfMatchedObjects = detail.filter((object) => {
			// 	return (object?>).toLowerCase().replace(/ /g, '').includes(searchText.toLowerCase().replace(/ /g, ''));
			// });
			setDetail(setSearchVals(searchText));
		} else if (searchText && param === 'leaveType') {
			setDetail(setsearchleave(searchText, detail));
		} else {
			setDetail(options);
		}
	};
	const filtered = (input, option) => {
		return (
			option.children?.[0].toLowerCase().replace(/ /g, '').indexOf(input.toLowerCase().replace(/ /g, '')) >= 0 ||
			option.children?.[4].toLowerCase().replace(/ /g, '').indexOf(input.toLowerCase().replace(/ /g, '')) >= 0
		);
	};
	return (
		<Fragment>
			<Select
				placeholder={focused && placeholder === 'Select Employee' ? 'Search by Employee ID, Name...' : placeholder}
				onSearch={onSearch}
				onChange={(data) => onChange(param, data, index, leaveValid)}
				// onSelect={onSelect}
				value={employeeData[param]}
				showSearch
				filterOption={(input, option) => filtered(input, option)}
				autoFocus={false}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				{...{ disabled }}
				dropdownClassName="request-leave-dropdown-list">
				{detail?.map((option, indx) => (
					<Option
						key={indx}
						value={
							param === 'nameId'
								? `${option?.employeeName}code:${option?.employeeCode}id:${option?.employeeId} ${option?.gender}`
								: `${option?.leaveTypeName}`
						}>
						{/* <div className="option-name">{name}</div> */}
						{option?.employeeName} {param === 'nameId' ? '' : null} {option?.employeeCode} {option?.leaveTypeName}
					</Option>
				))}
			</Select>
		</Fragment>
	);
};

export default EdenredDropDown;

export const Validate = ({ documentDetails, employeeData, documentValidLoading = false }) => {
	const passportDate = moment(documentDetails?.passportExpiryDate).format('DD/MM/YYYY');
	const visaDate = moment(documentDetails?.visaExpiryDate).format('DD/MM/YYYY');

	const documentPassportValid = useMemo(() => {
		return checkForPassportDocuments(
			documentDetails.passportExpiryDate,
			employeeData?.endDate ? moment(employeeData?.endDate).format(DATE_FORMAT.YYYY_MM_DD) : moment().format(DATE_FORMAT.YYYY_MM_DD),
			employeeData?.startDate ? moment(employeeData?.startDate).format(DATE_FORMAT.YYYY_MM_DD) : moment().format(DATE_FORMAT.YYYY_MM_DD)
		)?.filter((data) => data.rulePass);
	}, [documentDetails, employeeData, employeeData?.endDate]);

	const documentVisaValid = useMemo(() => {
		return checkForVisaDocuments(
			documentDetails.visaExpiryDate,
			employeeData?.endDate ? moment(employeeData?.endDate).format(DATE_FORMAT.YYYY_MM_DD) : moment().format(DATE_FORMAT.YYYY_MM_DD),
			employeeData?.startDate ? moment(employeeData?.startDate).format(DATE_FORMAT.YYYY_MM_DD) : moment().format(DATE_FORMAT.YYYY_MM_DD)
		)?.filter((data) => data.rulePass);
	}, [documentDetails, employeeData, employeeData?.endDate]);

	const hasPassportExpired = useMemo(
		() => !!(documentPassportValid || [])?.filter((d, v) => d.name === 'Expired')?.length,
		[documentPassportValid]
	);
	const hasVisaExpired = useMemo(() => !!(documentVisaValid || [])?.filter((d, v) => d.name === 'Expired')?.length, [documentVisaValid]);

	if (documentValidLoading) {
		return Array(2)
			.fill('')
			.map((data, i) => (
				<Col key={i} xl={6} lg={4} md={3} sm={24} xs={24} className={`loading`}>
					<Skeleton width={'100%'} height={'30%'} />
				</Col>
			));
	}

	return (
		<>
			{documentPassportValid?.map((data) => (
				<Row className="Passport-status-box-style">
					<ImageComponent className="image1 icons-mob-leave" src={data?.img} />
					<ETypo b2 medium className="visa-passport-title-color">
						Passport Status {data?.text === 'Valid' ? data?.text : ''}{' '}
						<ETypo b3 light className="visa-passport-date-color">
							{data?.name} {passportDate === 'Invalid date' ? 'Missing' : `on ${passportDate}`}
						</ETypo>
					</ETypo>
				</Row>
			))}
			{documentVisaValid?.map((data) => (
				<Row className="visa-status-box-style">
					<ImageComponent className="image1 icons-mob-leave" src={data?.img} />
					<ETypo b2 medium className="visa-passport-title-color">
						Visa Status {data?.text == 'Valid' ? data?.text : ''}
						<ETypo b3 light className="visa-passport-date-color">
							{' '}
							{data?.name} {visaDate == 'Invalid date' ? 'Missing' : `on ${visaDate}`}
						</ETypo>
					</ETypo>
				</Row>
			))}
		</>
	);
};
