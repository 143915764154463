import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row, Spin, Upload } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import ETypo from '@pages/DesignSystem/Typo';
import EDragger from '@pages/DesignSystem/Dragger';
import FreshdeskWidgetHelper from '@sharedComponent/freshdesk/FreshdeskWidgetHelper';
import ImageComponent from '@sharedComponent/image-component';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Popup from 'src/utils/replacement-popup';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import Selectors from '../selector';
import action from '../action';
import '../Component/updateNew.scss';
import { LoadingOutlined } from '@ant-design/icons';
import FadeIn from '@sharedComponent/AnimatedComponent';
import ErrorTable from '@sharedComponent/error-table';

const { Dragger } = Upload;

const Updatedocument = ({
	uploadDocuments,
	uploadLoading,
	uploadError,
	documentUpload,
	savedata,
	downloadTemplate,
	isDocUploaded,
	resetDocuments,
	templateLoading,
}) => {
	const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#ffffff' }} spin />;
	const dispatch = useDispatch();
	const [modalDrag, setModalDrag] = useState(false);
	const [hasErrorFile, setHasErrorFile] = useState(false);
	const [fileLists, setfileLists] = useState([]);
	const history = useHistory();
	const [counter, setcounter] = useState(0);
	const [errordataList, setErrorDataList] = useState([]);
	const [emiratesBlobFileName, setEmiratesBlobFileName] = useState(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [hasError, setHasError] = useState();
	const location = useLocation();
	const nFilterDocument = location?.state?.filterDocument;
	const [files, setFiles] = useState({});
	const [templateDocument, settemplateDocument] = useState([]);
	const [uploads, setUploads] = useState(false);
	useEffect(() => {
		if (documentUpload?.validationMessage) {
			settemplateDocument(documentUpload?.validationMessage);
			setUploads(true);
		} else {
			setUploads(false);
			settemplateDocument([]);
		}
	}, [documentUpload]);
	const disableButton = useMemo(() => {
		return isDocUploaded === 'yes' || isDocUploaded === 'loading' ? true : false || isDocUploaded === false || fileLists?.length === 0;
	}, [isDocUploaded, fileLists]);
	const upload = [
		{
			description: '1',
			list: (
				<span className="spanA">
					Download an excel template with a list of all employees and their information{' '}
					{/* <Link onClick={() => AppEmitter.emit(EMITTER_CONSTANT.SAVE_FILE, FILE_TEMPLATES.EMPLOYEE_FILE)} style={{ textTransform: 'none' }}> */}
					<Link
						to="/employee/update"
						onClick={() => {
							downloadTemplate();
							ClevertapReact.event('Bulk_Download_Template');
						}}
						className="forHere">
						Here.
					</Link>
				</span>
			),
		},
		{
			description: '2',
			list: <span className="spanA">Update any of the columns coloured grey on the excel sheet.</span>,
		},
		{
			description: '3',
			list: <span className="spanA">Upload the updated spreadsheet and click “Next” for your employees to be updated.</span>,
		},
	];
	// useEffect(() => {

	const uploadFile = (file) => {
		let fileResData = [];
		uploadDocuments(file);
		setModalDrag(true);
	};

	useEffect(() => {
		const FreshdeskWidgetElement = document.getElementById('freshworks-container');
		if (FreshdeskWidgetElement && counter >= 2) {
			var element = document.getElementById('freshworks-container');
			element.classList.add('freshdeskShow');
			element.classList.remove('freshdeskHide');
		}
		return () => {
			dispatch(action.creators.saveDocumentUploadEmployeeReset());
			const FreshdeskWidgetElement = document.getElementById('freshworks-container');
			if (FreshdeskWidgetElement) {
				var element = document.getElementById('freshworks-container');
				element.classList.add('freshdeskHide');
				element.classList.remove('freshdeskShow');
			}
		};
	}, [counter]);

	if (counter >= 2) {
		let widgetDetails = { wigetid: '67000002930' };
		FreshdeskWidgetHelper(widgetDetails);
	}
	// const allowedFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
	const allowedFileTypes = ['sif', 'xls', 'xlsx', 'paf', 'xlx'];

	const props = {
		accept: '.xlsx,.xls',
		onChange(info) {
			if (info.file.status === 'uploading') {
				setErrorDataList([]);
				setFiles(info);
				setEmiratesBlobFileName(null);
				let fileList = [...info.fileList];
				fileList = fileList.slice(-1);
				const file = fileList[0].originFileObj;
				if (allowedFileTypes.indexOf(file.name && file.name.split('.').splice(-1)[0]) >= 0) {
					uploadFile(file);
					fileList = fileList.map((file) => {
						if (file.response) {
							file.url = file.response.url;
						}
						return file;
					});
					setHasErrorFile(false);
					setfileLists(fileList);
				} else {
					setHasErrorFile(true);
				}
			}
			ClevertapReact.event('Bulk_Upload_Spreadsheet_DragDrop');
		},
		beforeUpload(file) {
			// checkFile(file, allowedFileTypes, removeFile);
		},
		onRemove() {
			setfileLists([]);
			setModalDrag(false);
			setFiles({});

			setErrorDataList([]);
			setEmiratesBlobFileName(null);
			setUploads(false);
			settemplateDocument([]);
		},
	};

	// const hasErrorVal = errorData.length;
	const loadErrorData = (errorDataArray) => {
		if (!errordataList.length) {
			setErrorDataList(errorDataArray);
		} else {
			let newArrayData = [...errordataList, ...errorDataArray];
			setErrorDataList(newArrayData);
		}
	};

	const nextOnClick = () => {
		const detail = { detail: 'documentUpload' };
		// ClevertapReact.event(CLEVER_TAP_EVENTS.Uploadexcel_PayrollTable)
		history.push({ pathname: '/employees/add/multiple/employees/submit', state: { data: documentUpload, files: files, status: 'update' } });
		let errors = [];
		let emplists = [];
		let errorDataPayLoad = {
			emiratesBlobFileName: emiratesBlobFileName,
			FileUploadAutoFixList: errordataList,
		};
	};

	const enableNextButton = (value) => {
		if (value) {
			setIsButtonDisabled(value);
		}
	};

	const closePopup = () => {
		setpopupVisible(false);
	};

	const draggerIcon = (value) => {
		if (uploadLoading) {
			return focus_state_icons.hrUploading;
		} else if (value === 'yes') {
			return focus_state_icons.NewDesignErro;
		} else if (value === 'no') {
			return focus_state_icons.NewDesignCheck;
		} else if (!value || hasErrorFile) {
			return focus_state_icons.NewDesignErro;
		} else {
			return focus_state_icons.hrUpload;
		}
	};

	const draggerStatus = (value) => {
		if (uploadLoading) {
			return 'uploading';
		} else if (value === 'yes') {
			return 'error';
		} else if (value === 'no') {
			return 'done';
		} else if (!value || hasErrorFile) {
			return 'error';
		} else {
			return 'new';
		}
	};

	const handleDragger = (file) => {
		if (allowedFileTypes.indexOf(file.name && file.name.split('.').splice(-1)[0]) >= 0) {
			uploadFile(file);
			setfileLists(file);
			setHasErrorFile(false);
		} else {
			setHasErrorFile(true);
		}
		ClevertapReact.event('Bulk_Upload_Spreadsheet_DragDrop');
	};
	const onRemoveUpload = () => {
		setfileLists([]);
		setModalDrag(false);
		setFiles({});
		setHasErrorFile(false);
		setErrorDataList([]);
		setEmiratesBlobFileName(null);
		setUploads(false);
		settemplateDocument([]);
		resetDocuments();
	};
	return (
		<>
			<Col className="upload-spreadsheet-col-container header-container hr-upload new-upload">
				<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
				<EdenredHeader />
				{/* <FreshdeskWidgetHelper true={true} /> */}
				<Row className="upload-container-body">
					<Col className="upload_employees_in_bulk_title">
						<ETypo h2 bold>
							Update Employees
						</ETypo>
						<div style={{ display: 'flex', gap: '8px' }}>
							<EdenredButtons
								{...{
									btnType: 'secondary',
									leftIcon: <ImageComponent src={images.leftsarrow} />,
									className: 'button_back_new ',
									onClick: () =>
										history.push({
											pathname: '/employees/manage_employee',
										}),
								}}>
								<ETypo b1 color="#484B52">
									Back
								</ETypo>
							</EdenredButtons>
							<EdenredButtons
								{...{
									btnType: 'primary',
									className: 'button_back_new',
									disabled: disableButton,
									onClick: () => {
										nextOnClick();
										ClevertapReact.event('Bulk_Next');
									},
								}}>
								Next
							</EdenredButtons>
						</div>
					</Col>
					{/* <Col xl={24} md={24} lg={24} sm={24} xs={24} className="upload_spreadsheet_steps_container">
						{upload.map((content, index) => (
							<Row key={index} className="upload_spreadsheet_steps_row">
								<Col xl={2} md={2} lg={2} sm={2} xs={2} className="upload_spreadsheet_steps_icon">
									<EdenredTypo>{content.description}</EdenredTypo>
								</Col>
								<Col
									xl={22}
									lg={22}
									md={22}
									sm={22}
									xs={22}
									className={`upload_spreadsheet_steps_content${content.description === '1' ? ' bold' : ''}`}>
									<EdenredTypo>{content.list}</EdenredTypo>
								</Col>
							</Row>
						))}
					</Col> */}
					{/* {hasErrorFile && (
						<Col xl={21} md={21} sm={24} lg={21} xs={24} className="card_error" style={{ marginTop: 15 }}>
							<Card className="card_action">
								<Row>
									<Col xl={1} sm={3} xs={3} md={2}>
										<ImageComponent src={icons.focus_state.Error} />
									</Col>
									<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
										Invalid format. Please upload one of these formats {allowedFileTypes.join()}
									</Col>
									<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
										Please correct the following errors and upload the file again.
									</Col>
								</Row>
							</Card>
						</Col>
					)} */}
					<Col xl={24} md={24} lg={24} className="dragger_card_container topCont">
						{/* <FadeIn direction="bottom"> */}
						<div className="drag-drop-container">
							<p className="update_container_heading">Import Employee Spreadsheet</p>
							<EDragger
								{...{
									idleIcon: draggerIcon(isDocUploaded),
									draggerStatus: draggerStatus(isDocUploaded),
									uploadDeleteIcon: focus_state_icons.NewDelete,
									onChange: handleDragger,
									onRemoveUpload,
								}}
							/>
							<Col xl={24} lg={24} md={24} sm={20} xs={20} className="upload_delete_employees_spreadsheet_template_hint">
								<EdenredTypo b3>
									If you don’t have the spreadsheet template you can download it
									{
										templateLoading ? (
											<Spin indicator={antIcon} color="red" />
										) : (
											<Link
												to="/employee/update"
												onClick={() => {
													downloadTemplate();
													ClevertapReact.event('Bulk_Download_Template');
												}}
												className="upload_delete_employees_spreadsheet_template_hint Here-text">
												HERE.
											</Link>
										)

										// <ExcelDownload
										// 	dataSet={[]}
										// 	columnKeys={columnKeys}
										// 	filename={`employeelist11`}
										// 	element={
										// 		// <EdenredButton
										// 		// 	className="fs-button-outline"
										// 		// 	icon={<ImageComponent src={icons.not_focus.Download} className="download_image" />}
										// 		// 	type="outline-g">
										// 		// 	Download Spreadsheet
										// 		// </EdenredButton>
										// 		<ETypo className="Here-text" light b1>
										// 			HERE.
										// 		</ETypo>
										// 	}
										// />
									}
								</EdenredTypo>
								{/* </FadeIn> */}
							</Col>
						</div>
					</Col>

					{hasError ? (
						<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error">
							<Card className="card_action">
								<Row>
									<Col xl={1} sm={3} xs={3} md={2}>
										<ImageComponent src={not_focus_icons.Expired} />
									</Col>
									<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
										Please correct the following errors and upload the file again.
									</Col>
									<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
										Please correct the following errors and upload the file again.
									</Col>
								</Row>
							</Card>
						</Col>
					) : (
						''
					)}
					{templateDocument.length > 0 ? (
						<FadeIn delay={0.5} style={{ height: 'unset' }}>
							<Fragment>
								<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error">
									<ErrorTable data={templateDocument} loadErrorData={loadErrorData} enableNextButton={enableNextButton} />
								</Col>
							</Fragment>
						</FadeIn>
					) : (
						''
					)}
					{/* <Col xl={8} md={8} lg={8} xs={24} sm={24} className="next_button">
						<Col className="left-content-box">
							<div
							// onClick={() => { ClevertapReact.event(CLEVER_TAP_EVENTS.Uploadexcel_PayrollTable) }}
							>
								<EdenredButton
									onClick={() => {
										nextOnClick();
										ClevertapReact.event('Bulk_Next');
									}}
									disabled={disableButton}
									type="primary">
									Next
								</EdenredButton>
							</div>
						</Col>
					</Col> */}
				</Row>
			</Col>
			{/* <Approve detail={documentUpload} /> */}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		uploadLoading: Selectors.uploadLoad(state),
		documentUpload: Selectors.documentUpload(state),
		templateLoading: Selectors.templateLoading(state),
		templateDowload: Selectors.templateDowload(state),
		isDocUploaded: Selectors.isDocUploaded(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		uploadDocuments: (file) => dispatch(action.creators.saveDocumentUploadStart(file)),
		downloadTemplate: () => dispatch(action.creators.saveEmpTemplateStart()),
		resetDocuments: () => dispatch(action.creators.saveDocumentUploadEmployeeReset()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Updatedocument);
export default Connected;
