import Actions from '../action';
import Axios from 'axios';
import counterAction from '@pages/payroll-plus-new/action';
import ToastActions from '@pages/DesignSystem/StatusToast/actions';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import setupAction from '../../../../components/shared/app-action-reducer/action';
import hrFeatureActions from '../../HrFeatureAccess/action';

export function* getDeductionServices() {
	yield put(Actions.creators.putDeductionLoading(true));

	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/deductiontype`;
	try {
		const response = yield call(Axios.get, url);
		yield put(Actions.creators.getDeductionData(response.data));
		yield put(Actions.creators.putDeductionLoading(false));
	} catch (error) {
		yield put(Actions.creators.putDeductionError(error));
		yield put(Actions.creators.putDeductionLoading(false));
	}
}

export function* sentDeductionData(action) {
	yield put(Actions.creators.putDeductionLoading(true));
	yield put(setupAction.creators.postHrSetupStatus({ hrSetupDone: true }));

	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/deductiontype?companySetupFlag=true`;
		yield call(Axios.post, url, action?.payload);
		yield put(Actions.creators.putDeductionLoading(false));
		yield put(ToastActions.creators.putSuccessStatusToast({ message: 'Your deductions have been added!' }));
		yield put(Actions.creators.getDeduction());
		yield put(hrFeatureActions.creators.getCorporateSettings());
		yield put(counterAction.creators.getPayrollDynamicHeader());
	} catch (error) {
		yield put(Actions.creators.putDeductionError(error));
		yield put(Actions.creators.putDeductionLoading(false));
	}
}

export default function* deductionWatcher() {
	yield takeEvery(Actions.types.GET_DEDUCTION, getDeductionServices);
	yield takeLatest(Actions.types.POST_DEDUCTION, sentDeductionData);
}
