import { createSelector } from 'reselect';

import { initState } from './payroll-setting-reducer';

const payrollReducer = (state) => state.payrollReducer || initState;
const isPayrollLoading = createSelector(payrollReducer, (cnter) => cnter.isPayrollLoading);
const payrollLists = createSelector(payrollReducer, (cnter) => cnter.payrollDetails);
const payrollError = createSelector(payrollReducer, (cnter) => cnter.payrollError);
const isDepartmentLoading = createSelector(payrollReducer, (cnter) => cnter.isDepartmentLoading);
const departmentDetails = createSelector(payrollReducer, (cnter) => cnter.departmentDetails);
const departmentError = createSelector(payrollReducer, (cnter) => cnter.departmentError);
const isAllowanceLoading = createSelector(payrollReducer, (cnter) => cnter.isAllowanceLoading);
const allowanceDetails = createSelector(payrollReducer, (cnter) => cnter.allowanceDetails);
const allowanceError = createSelector(payrollReducer, (cnter) => cnter.allowanceError);
const isContractTypeLoading = createSelector(payrollReducer, (cnter) => cnter.isContractTypeLoading);
const ContractTypeDetails = createSelector(payrollReducer, (cnter) => cnter.ContractTypeDetails);
const ContractTypeError = createSelector(payrollReducer, (cnter) => cnter.ContractTypeError);
const isPayrollSubmitLoading = createSelector(payrollReducer, (cnter) => cnter.isPayrollSubmitLoading);
const payrollSubmitted = createSelector(payrollReducer, (cnter) => cnter.payrollSubmitted);
const payrollSubmittedFail = createSelector(payrollReducer, (cnter) => cnter.payrollSubmittedFail);
const settingSavePopUp = createSelector(payrollReducer, (cnter) => cnter.settingSavePopUp);
const savedSettingSuccessfully = createSelector(payrollReducer, (cnter) => cnter.savedSettingSuccessfully);

export default {
	payrollReducer,
	isPayrollLoading,
	payrollLists,
	payrollError,
	isDepartmentLoading,
	departmentDetails,
	departmentError,
	isAllowanceLoading,
	allowanceDetails,
	allowanceError,
	isContractTypeLoading,
	ContractTypeDetails,
	ContractTypeError,
	isPayrollSubmitLoading,
	payrollSubmitted,
	payrollSubmittedFail,
	settingSavePopUp,
	savedSettingSuccessfully,
};
