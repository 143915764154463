import React from 'react';
import { Link } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import { Card, Col, Row } from 'antd';
import Table from '@pages/DesignSystem/Table/Table';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import ETypo from '@pages/DesignSystem/Typo';
import { icons } from '@assets/icons';
import moment from 'moment';
import Chips from '@pages/DesignSystem/Chips';
import { getCardStatusColor } from '@helpers';

const PayrollHistoryCard = ({ isPayrollHistoryLoading, payrollHistory }) => {
	/**
	 * @param {Array} data
	 * It returns only card type or Bank type.
	 * It skip which have both card type and Bank type.
	 */
	const getOnlySingleTypeTransaction = (data) => {
		const finalData = data.filter((hisData) => {
			if (hisData.accountTypeDescription) {
				return hisData;
			}
		});
		return finalData;
	};
	const dataSource = [
		...payrollHistory?.map((history, index) => {
			const records = getOnlySingleTypeTransaction(history.payrollRunDetail);
			return {
				key: index,
				...history,
				Amount: history?.payrollRunHeader?.headerAmount,
				Submitted: history?.payrollRunHeader?.submittedAt,
				Status: records[0]?.historyStatus || 'Pending',
			};
		}),
	];

	const columns = [
		{
			title: 'Amount',
			dataIndex: 'Amount',
			key: 'Amount',
			render: (Amount, data) => {
				const uploadId = data?.payrollRunHeader?.uploadId;
				const cardDetail = getOnlySingleTypeTransaction(data.payrollRunDetail)[0];
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Link
							to={{
								pathname: `/payroll/status/${uploadId}`,
								state: {
									payrollRunHeader: data?.payrollRunHeader && data?.payrollRunHeader?.payrollAmountTypeMonthYear,
									cardDetail: cardDetail,
								},
							}}>
							<ETypo b1 style={{ color: '#0E5274', textDecoration: 'underline', marginBottom: '0px' }}>
								{Amount}
							</ETypo>
						</Link>
					</div>
				);
			},
		},
		{
			title: 'Submitted on',
			dataIndex: 'Submitted',
			key: 'Submitted',
			render: (Submitted) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<p style={{ color: '#181919', marginBottom: '0px' }}>{moment(Submitted.split('on ')[1]).format('DD/MMM/YYYY')}</p>
					</div>
				);
			},
		},
		{
			title: 'Status',
			dataIndex: 'Status',
			key: 'Status',
			render: (Status) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Chips color={getCardStatusColor(Status)?.color}>{Status}</Chips>
					</div>
				);
			},
		},
	];

	return (
		<Col xl={12} lg={24} md={24} sm={24} xs={24} className="payroll_history_card_column">
			<Card className="payroll_history_card card-resp">
				<Row className="payroll_history_card_title_row">
					<div className="payroll_history_title">
						<div className="payroll_history_icon">
							<ImageComponent src={icons.focus_state.ReceiptNew} />
						</div>
						<EdenredTypo style={{ color: '#181919' }}>Payroll History</EdenredTypo>
					</div>
					<Link to="/payroll/history">
						<ETypo b1="b1" style={{ textDecoration: 'underline' }} color="#0E5274">
							View All
						</ETypo>
						<ImageComponent src={icons.focus_state.RightClrArrow} style={{ paddingLeft: '10px', paddingBottom: '4px' }} />
					</Link>
				</Row>
				{isPayrollHistoryLoading ? (
					<Col
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							minHeight: '120px',
						}}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				) : (
					<Col xl={24} lg={24} md={24} className="payroll_history_details_section">
						{dataSource.length === 0 ? (
							<>
								<Row
									className="payroll_history_card_detail_row"
									style={{
										height: '120px',
										minHeight: '120px',
										maxHeight: '120px',
										display: 'flex',
										alignItems: 'center',
									}}>
									<Col xs={1} sm={1}>
										<Row></Row>
									</Col>
									<Col xs={23} sm={23} style={{ display: 'grid', placeItems: 'center' }}>
										<ImageComponent src={icons.focus_state.Nohistory} />
										<p className="payroll_status_head">No payroll history</p>
										<p className="payroll_status_text" style={{ display: 'flex', marginBottom: '0px' }}>
											Run payroll now for your employees
											<Link to="/run-payroll">
												<span
													style={{
														color: '#0E5274',
														marginLeft: '4px',
														textDecoration: 'underline',
														display: 'block',
													}}>
													HERE.
												</span>
											</Link>
										</p>
									</Col>
								</Row>
							</>
						) : (
							<>
								<Table columns={columns} data={dataSource} />
							</>
						)}
					</Col>
				)}
			</Card>
		</Col>
	);
};

export default PayrollHistoryCard;
