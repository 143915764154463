import React, { useState } from 'react';
import { Carousel, Col, Divider, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import './cardholder.scss';

const Cardholder = () => {
	const history = useHistory();
	const historyNew = useLocation();
	const successCallback = () => {
		history.push({
			pathname: '/cardholder/c3paynumber',
			state: { empId: historyNew?.state.employeeId, empName: historyNew?.state?.employeeName },
		});
	};
	const previousScreen = () => {
		history.push({
			pathname: '/employees/viewemployee',
			state: {
				tableData: {
					employeeId: historyNew?.state.employeeId,
				},
			},
		});
	};
	console.log('historyNew', historyNew);

	return (
		<Row className="cardholder">
			<Col xl={22} lg={22} md={22} sm={22} xs={22} className="main-box">
				<Col xl={20} lg={20} md={20} sm={20} xs={20} className="cardholder-card">
					<span className="card-name">{historyNew?.state?.employeeName}</span>
					<p className="card-title">
						{historyNew?.state?.employeeName}’s card will be deleted and they will be registered with a bank account. Do you want to
						continue?
					</p>
					<p className="card-description">You may be charged a small fee for card deletion, depending upon your agreement.</p>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="card-two-button">
						<Col xl={8} lg={8} md={8} sm={8} xs={8}>
							<button className="card-button" onClick={previousScreen}>
								NO
							</button>
						</Col>
						<Col xl={8} lg={8} md={8} sm={8} xs={8}>
							<EdenredButton type="primary" className="card-button-primary" onClick={successCallback}>
								yes
							</EdenredButton>
						</Col>
					</Col>
				</Col>
			</Col>
		</Row>
	);
};

export default Cardholder;
