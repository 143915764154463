import React from 'react';
import SelectDropdownWithSearch from './Example/SelectDropdownWithSearch';
import DefaultDropdown from './Example/DefaultDropdown';
import WithPlaceholder from './Example/WithPlaceholder';
import WithSubmenu from './Example/WithSubmenu';
import SubmenuCustomisation from './Example/SubmenuCustomisation';
import DisableSelect from './Example/DisableSelect';
import './SelectDropdown.scss';

const index = () => {
	return (
		<>
			<SelectDropdownWithSearch />
			<DefaultDropdown />
			<WithPlaceholder />
			<WithSubmenu />
			<SubmenuCustomisation />
			<DisableSelect />
		</>
	);
};

export default index;
