import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../history-action';
import Axios from 'axios';

export function* historyDepartmentService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/department`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getHistoryDepartmentSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getHistoryDepartmentFail(err));
	}
}

export default function* historyWatcher() {
	yield takeEvery(Actions.types.GET_HISTORY_DEPARTMENT_START, historyDepartmentService);
}
