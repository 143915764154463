import React from 'react';
import { icons } from 'src/assets/icons';
import './stepper.scss';
import ETypo from './../Typo/index';
import { STEPPER_STATUS_KEY } from '@constants/app-constants';
const getStatus = (value) => {
	switch (value) {
		case STEPPER_STATUS_KEY.COMPLETED:
			return {
				image: icons?.not_focus.tickBox,
				name: 'stepper_content_completed',
				style: 'stepper_dotted_completed',
			};
		case STEPPER_STATUS_KEY.PROCESSING:
			return {
				image: icons?.not_focus.updating,
				name: 'stepper_content_processing',
				style: 'stepper_dotted_processing',
			};
		case STEPPER_STATUS_KEY.FAILED:
			return {
				image: icons?.not_focus.failed,
				name: 'stepper_content_failed',
				style: 'stepper_dotted_failed',
			};

		default:
			return {
				name: 'stepper_content_default',
				style: 'stepper_dotted_default',
			};
	}
};
const Stepper = ({ state }) => {
	let stepperItems = state;

	return (
		<>
			{stepperItems?.map((items) => (
				<div className="stepper_component" key={items.id}>
					<div className="stepper_component_progress">
						<div className={getStatus(items.status).name}>
							<img src={getStatus(items.status).image} />
						</div>
						{stepperItems.length === items.id ? (
							''
						) : (
							<>
								<div className={getStatus(items?.status)?.style} />
								<div className={getStatus(items?.status)?.style} />
								<div className={getStatus(items?.status)?.style} />
								<div className={getStatus(items?.status)?.style} />
							</>
						)}
					</div>
					<p
						className={
							items?.status == STEPPER_STATUS_KEY.COMPLETED
								? 'stepper_heading'
								: items?.status == STEPPER_STATUS_KEY.FAILED
								? 'stepper_error_heading'
								: 'stepper_heading_default'
						}>
						{items?.status == STEPPER_STATUS_KEY.FAILED ? items?.errorTitle : items?.title}
					</p>

					<div className="messageBox">
						<p className={items?.status !== '' ? 'stepper_para' : 'stepper_para_default'}>
							{items?.status == STEPPER_STATUS_KEY.FAILED ? '' : items?.description}
						</p>
					</div>
					{items?.message && (
						<div className="stepper-message-box">
							<ETypo b3 light color="#0E5274">
								{items?.message}
								<div className="stepper_message_para" onClick={items?.onMessageLinkClick}>
									{items?.messageLinkText ? items?.messageLinkText : ''}
								</div>
							</ETypo>{' '}
						</div>
					)}
					{items?.errorMessage && (
						<div className="stepper-error-box">
							<ETypo b3 light color="#F72717">
								{items?.errorMessage}
								<div className="stepper_error_para" onClick={items?.onerrorLinkClick}>
									{items?.errorLinkText ? items?.errorLinkText : ''}
								</div>
							</ETypo>{' '}
						</div>
					)}
				</div>
			))}
		</>
	);
};

export default Stepper;
