const SALARY_REPORTS_CONSTANTS = {
    // cards page
    cards: {
        reportType: {
            issue: 'issue',
            delete: 'delete'
        },
        reportPageTitle: {
            issue: 'Cards Issued Report',
            delete: 'Cards Deleted Report'
        },
        reportFileName: {
            issue: 'SalaryCardIssueReport.xlsx',
            delete: 'SalaryCardDeleteReport.xlsx',
            other: 'SalaryReport.xlsx',
        },
    },
    salaryForm: {
        fileDownloadedText: {
            success: 'Report downloaded successfully',
            noDataFound: 'There is no data for this Report'
        },
        type: {
            monthWiseReports: 'salary_monthwise-reports',
            perEmployee: 'salary_per-employee',
            perType: 'salary_per_type',
            historyEmployee: 'salary_history-employee',
            cardsReport: 'salary_report_cards'
        },
        fileType: {
            excel: 'excel',
            pdf: 'pdf',
        },
        loaderhelperText: 'This may take up to a minute. Please wait.',
        excelLabelText: 'Create Excel Report',
        pdfLabelText: 'Create PDF Report',
    }
}

export default SALARY_REPORTS_CONSTANTS;