import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import './setup-title.scss';

const SetupTitle = ({
	title,
	rightBtnText,
	rightBtnClick,
	rightBtnType,
	rightBtnRightIcon,
	rightBtnDisabled,
	leftBtnText,
	leftBtnClick,
	leftBtnType,
	leftBtnRightIcon,
	leftBtnDisable,
}) => {
	return (
		<div className="title">
			{title && <span>{title}</span>}

			<div className="button-container">
				{leftBtnText && (
					<span>
						<EdenredButtons
							children={leftBtnText}
							onClick={typeof leftBtnClick === 'function' && leftBtnClick}
							btnType={leftBtnType}
							rightIcon={leftBtnRightIcon && <ImageComponent className="rightIcon" src={leftBtnRightIcon} />}
							disabled={leftBtnDisable || false}
						/>
					</span>
				)}
				{rightBtnText && (
					<span>
						<EdenredButtons
							children={rightBtnText}
							onClick={typeof rightBtnClick === 'function' && rightBtnClick}
							btnType={rightBtnType}
							rightIcon={rightBtnRightIcon && <ImageComponent className="rightIcon" src={rightBtnRightIcon} />}
							disabled={rightBtnDisabled || false}
						/>
					</span>
				)}
			</div>
		</div>
	);
};

export default SetupTitle;
