import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import EdenredDropDown, { DropDownGratuity } from '@pages/payroll-run/index';
import { amountWithCommas, amountWithNoCommas, numberWithCommas, twoDigitAfterDec } from '@helpers';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import ClevertapReact from 'src/utils/clever-tap';
import { icons } from 'src/assets/icons';
import { DynamicAdditionsInput, DynamicDeductionsInput } from './addition-textinput';
import PayrollDatePicker from './../date-picker';
import { PopUp } from './../date-picker';
import './payroll-run.scss';

var grossSalaryCalculation;
var additionCalculation = 0;
var deductionsCalculation = 0;
var overtimeCalculation = 0;
var totalSalaryCalculation = 0;
const PayrollRunPresentational = ({
	index,
	handlePayrollRun,
	handleChangeDropdown,
	handleChange,
	handleRemove,
	additions,
	deductions,
	salaryPaidStatusVal,
	onChange,
	payrolldata,
	additionValues,
	deductionValues,
	currentmonth,
	overTimePayCalculation,
	terminationTypes,
	dataPicker,
	employeeDetail,
	gratutitycalValue1,
	onChangeTermination,
	savePopup,
	savePayrollValue,
	handleSavePayroll,
	savePayrollRunLoading,
	savedSuccessfully,
	isCancle,
	payRollRunLoading,
	setGratuity,
	gratuity,
	setSaveCancleBuuton,
	setNotification,
	notification,
	buttondisabel,
	setGratuityCal,
	setTerminationType,
	defaultSalary,
	salaryPaidStatusLoading,
	month,
	payRollRun,
	isPayrollRunClaims,
	setNavigateTabCheck,
	navigateTabCheck,
	employeeAllowance,
	setEmpolyeeAllowance,
	overTimes,
	setOverTimes,
	empolyeeSalary,
	setEmpolyeeSalary,
	setSaveValue,
	leavingDate,
	setLeavingDate,
	terminationId,
	setTerminationId,
}) => {
	const history = useHistory();
	const [overTimePaySalary, setOverTimePaySalary] = useState([]);
	const [overTimePayPolicies, setOverTimePayPolicies] = useState();
	const [contractEndDate, setContractEndDate] = useState();
	var grossSalary = 0;
	const amount = [];
	const additionList = [];
	const deductionList = [];
	const overTimeList = [];
	const overTimeCal = [];
	const leaveDetail = [
		{
			name: 'Name,Employee ID',
			value: salaryPaidStatusVal,
			param: 'nameId',
		},
	];
	const gratutityType = [
		{
			name: 'Name,Employee ID',
			value: terminationTypes,
			param: 'nameId',
		},
	];
	amount.push(empolyeeSalary?.value);
	let runEmployeeAllowanceList = employeeAllowance?.map((item, i) => {
		amount.push(parseFloat(amountWithNoCommas(item?.value)));
		return {
			...item,
			value: parseFloat(amountWithNoCommas(item?.value)),
		};
	});
	let temp3 = overTimes?.map((item, i) => {
		overTimeList.push({ id: item?.id, overtimePolicyId: item?.overtimePolicyId, hours: item?.hours === '' ? 0 : parseFloat(item?.hours) });
	});
	additions &&
		additions.map((item, i) => {
			additionList.push({
				id: item?.id,
				additionTypeId: additions[i]?.additionTypeId,
				value: additions[i]?.value === '' ? 0 : parseFloat(amountWithNoCommas(additions[i]?.value)),
			});
		});
	deductions &&
		deductions.map((item, i) => {
			deductionList.push({
				id: item?.id,
				deductionTypeId: deductions[i]?.deductionTypeId,
				value: deductions[i]?.value === '' ? 0 : parseFloat(amountWithNoCommas(deductions[i]?.value)),
			});
		});
	var cal = parseFloat(grossSalary);
	for (var i = 0; i < amount?.length; i++) {
		grossSalary += parseFloat(amount[i]?.toString().replace(/,/g, '')) || 0;
	}
	var grosscomma = grossSalary + cal;
	grossSalaryCalculation = grosscomma.toFixed(2);
	var overtimepay = 0;
	overTimeCal.push(overTimePaySalary);
	var salary1 = overTimeCal[0]?.salaryPerDay;
	var text = salary1 === undefined ? '' : salary1.split('/');
	var calenderdaycal = ((text[1] == 'Calendar Days' ? moment(month).daysInMonth() : 30) * overTimeCal[0]?.hoursADay).toFixed(2);
	var salaryperhour = text[0] === 'Basic Salary+Allowances' ? grosscomma / calenderdaycal : amount[0] / calenderdaycal;
	var salary = salaryperhour;
	var salary1 = salary;
	for (var i = 0; i < overTimes?.length; i++) {
		overtimepay = overtimepay + salary1 * overTimes?.[i]?.hours * overTimePayPolicies?.[i]?.standardPercentage;
	}
	var over = overtimepay;
	var overTimemoney = over;
	overtimeCalculation = overTimemoney.toLocaleString(undefined, { maximumFractionDigits: 2 });
	var additionValue = 0;
	var cal1 = parseFloat(additionValue);
	for (var i = 0; i < additions?.length; i++) {
		additionValue += parseFloat(additions?.[i]?.value.toString().replace(/,/g, '')) || 0;
	}
	var addcomma = additionValue + cal1;
	additionCalculation = addcomma.toLocaleString(undefined, { maximumFractionDigits: 2 });
	var decutionValue = 0;
	var cal2 = parseFloat(decutionValue);
	for (var i = 0; i < deductions?.length; i++) {
		decutionValue += parseFloat(deductions?.[i]?.value.toString().replace(/,/g, '')) || 0;
	}
	var deductioncomma = decutionValue + cal2;
	deductionsCalculation = deductioncomma.toLocaleString(undefined, { maximumFractionDigits: 2 });
	var adddec = addcomma - deductioncomma;
	var gratuitycalltot = gratuity ? parseFloat(amountWithNoCommas(gratutitycalValue1?.[0].value)) : 0;
	var i = grosscomma + overTimemoney + adddec + gratuitycalltot;
	totalSalaryCalculation = i.toFixed(2);
	useEffect(() => {
		setEmpolyeeSalary(payrolldata?.employeeBasicSalary);
		setEmpolyeeAllowance(payrolldata?.employeeAllowances);
		setOverTimes(payrolldata?.overtimes);
		setOverTimePaySalary(overTimePayCalculation?.overtimeSalaryPerDayType);
		setOverTimePayPolicies(overTimePayCalculation?.overtimePolicies);
		setContractEndDate(payrolldata?.employeeContract);
	}, [payrolldata]);

	const basicSalary = (e, i) => {
		const values = [...empolyeeSalary];
		values[i].value = e.target.value;
		setEmpolyeeSalary(values);
	};

	const salaryAllowance = (e, i) => {
		const values = [...employeeAllowance];
		values[i].value = twoDigitAfterDec(e?.target?.value);
		setEmpolyeeAllowance(values);
		setSaveCancleBuuton(true);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
	};
	const overTimePayMethod = (e, i) => {
		const values = [...overTimes];
		values[i].hours = parseFloat(e.target.value) || 0;
		setOverTimes(values);
		setSaveCancleBuuton(true);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
	};

	const handleGratuity = (e, i) => {
		const values = [...gratutitycalValue1];
		values[i].value = twoDigitAfterDec(e?.target?.value);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
		setGratuityCal(e.target.value);
	};
	const gratuitycaldata = () => {
		setGratuity(!gratuity);
		if (gratuity) {
			var val = payrolldata.gratuity;
			if (val === null) {
				setGratuityCal(0);
			}
			setTerminationType(null);
			// setDate(null);
			setLeavingDate(null);
		}
	};
	var saveValue;
	if (gratuity) {
		saveValue = {
			allowanceList: runEmployeeAllowanceList,
			overtimePayList: overTimeList,
			additionList: additionList,
			deductionList: deductionList,
			leavingDate: leaveDetail || contractEndDate?.leavingDate,
			terminationTypeId: terminationId || '',
			gratuity: { value: parseFloat(amountWithNoCommas(gratutitycalValue1[0]?.value)) },
		};
	} else {
		saveValue = {
			allowanceList: runEmployeeAllowanceList,
			overtimePayList: overTimeList,
			additionList: additionList,
			deductionList: deductionList,
			leavingDate: contractEndDate?.leavingDate,
		};
	}
	useEffect(() => {
		Object?.keys(saveValue)?.length > 0 && setSaveValue(saveValue);
	}, []);

	let nAddition = [];
	let nDeduction = [];
	// future ref
	// const additionListed = useMemo(() => {
	// 	return additionValues.filter(({ id: id1 }) => !additions?.some(({ additionTypeId: id2 }) => id2 === id1));
	// }, [additionValues, additions]);
	// const deductionListed = useMemo(() => {
	// 	return deductionValues.filter(({ id: id1 }) => !deductions?.some(({ deductionTypeId: id2 }) => id2 === id1));
	// }, [deductionValues, deductions]);
	const nAdditions = !additions?.every((item) => item.name && parseFloat(item.value) >= 0);
	const nDeductions = !deductions?.every((item) => item.name && parseFloat(item.value) >= 0);
	const isDisable = additions?.map((data) => {
		if (data?.name === '') {
			return true;
		} else return false;
	});

	return (
		<>
			{payRollRunLoading && !!salaryPaidStatusVal ? (
				<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</div>
			) : (
				<Row className="payroll_run_main_container">
					<Col className="dropdown-container">
						{!salaryPaidStatusLoading &&
							leaveDetail?.map((detail, i) => (
								<Col className="dropdown-inner-container">
									<EdenredDropDown
										{...{
											index: i,
											options: detail?.value,
											value: [detail?.value],
											placeholder: 'Select Month',
											onChange: onChange,
											currentmonth: currentmonth,
											salary: salaryPaidStatusVal,
											newSalary: defaultSalary?.salaryDate,
											disabled: isPayrollRunClaims?.length === 0,
										}}
									/>
								</Col>
							))}
					</Col>

					<Col className="contant_container">
						<Col>
							<Col className="col_left">
								<ETypo bold h2 color="#0E5274" className="width_60">
									Total Salary
								</ETypo>
								<ETypo bold h2 color="#0E5274">
									AED {totalSalaryCalculation === 'NaN' ? 0 : totalSalaryCalculation}
								</ETypo>
							</Col>
							<Col className="cross_salary_left">
								<ETypo medium h4 className="width_60">
									Gross Salary
								</ETypo>
								<ETypo medium h4>
									AED {grossSalaryCalculation}
								</ETypo>
							</Col>
							<Col className="cross_salary_left1">
								<Col className="salary_list3">
									<ETypo light b2 color="#181919" className="width_60">
										Basic Salary
									</ETypo>
									<div className="payroll-run-input">
										<Inputbox
											prefix="AED"
											type="text"
											value={numberWithCommas(empolyeeSalary?.value)}
											onChange={(e) => basicSalary(e, i)}
											disabled
										/>
									</div>
								</Col>
							</Col>
							<Col className="payroll_run_cross_salary">
								{employeeAllowance?.map((item, i) => (
									<>
										<Col className={buttondisabel ? 'salary_list3' : 'salary_list'}>
											<ETypo light b2 color="#181919" className="width_60">
												{item?.allowanceTypeName}
											</ETypo>
											<div className="payroll-run-input">
												<Inputbox
													prefix="AED"
													type="text"
													value={amountWithCommas(item?.value)}
													onChange={(e) => salaryAllowance(e, i)}
													disabled={buttondisabel || isPayrollRunClaims?.length === 0}
												/>
											</div>
										</Col>
									</>
								))}
							</Col>
							<Col className="cross_salary_left">
								<ETypo medium h4 className="width_60">
									Overtime Pay
								</ETypo>
								<ETypo medium h4 className="cross_text">
									AED {overtimeCalculation === 'NaN' ? 0 : overtimeCalculation}
								</ETypo>
							</Col>
							<Col className="cross_salary_left1">
								<ETypo light b2 color="#181919" className="company_setting">
									Set your overtime policies in {''}
									<ETypo
										light
										b2
										onClick={() => {
											ClevertapReact.event('CompanySettings_Overtime');
											history.push('/company/company-setting');
										}}
										style={{ textDecoration: 'underline', cursor: 'pointer' }}>
										Company Settings.
									</ETypo>
								</ETypo>
							</Col>
							<Col className="cross_salary_left1">
								{overTimes?.map((items, i) => (
									<>
										<Col className={buttondisabel ? 'salary_list3' : 'salary_list'}>
											<ETypo light b2 color="#181919" className="width_60">
												{items?.overtimeType}
											</ETypo>
											<div className="payroll-run-input">
												<Inputbox
													prefix={'Hours'}
													value={items?.hours}
													type="number"
													onChange={(e) => overTimePayMethod(e, i)}
													disabled={buttondisabel || isPayrollRunClaims?.length === 0}
												/>
											</div>
										</Col>
									</>
								))}
							</Col>
							<Col className="cross_salary_left">
								<ETypo medium h4 className="width_60">
									Additions
								</ETypo>
								<ETypo medium h4>
									AED {additionCalculation}
								</ETypo>
							</Col>
							<Col style={{ marginTop: '24px' }}>
								{additions?.map(({ type, value, index, additionTypeId, name }, i) => {
									nAddition.push(additions?.[index - 1]?.name);
									return (
										<DynamicAdditionsInput
											{...{
												type,
												additionTypeId,
												value,
												index,
												name,
												key: i,
												handleAdditionRemove: handleRemove,
												handleAdditionChange: handleChange,
												handleAdditionChangeDropdown: handleChangeDropdown,
												additionListed: additionValues?.filter((data) => !nAddition?.includes(data?.name)),
												placeholder: 'Select Addition',
												buttondisabel,
												isDisable,
												payRollRun,
												isPayrollRunClaims,
											}}
										/>
									);
								})}
							</Col>
							<Col className="addition_col">
								<EdenredButtons
									disabled={buttondisabel && !isDisable?.[index] ? true : false}
									btnType="secondary"
									className="add_addition"
									leftIcon={<ImageComponent src={icons.not_focus.plusSvg} />}
									onClick={() => !nAdditions && handlePayrollRun('additions')}>
									Add Addition
								</EdenredButtons>
							</Col>
							<Col className="cross_salary_left">
								<ETypo medium h4 className="width_60">
									Deductions
								</ETypo>
								<ETypo medium h4>
									AED {deductionsCalculation}
								</ETypo>
							</Col>
							<Col style={{ marginTop: '24px' }}>
								{deductions?.map(({ type, value, index, deductionTypeId, name }, i) => {
									nDeduction.push(deductions?.[index - 1]?.name);
									return (
										<DynamicDeductionsInput
											{...{
												type,
												value,
												deductionTypeId,
												index,
												key: i,
												handleDeductionRemove: handleRemove,
												handleDedutionChange: handleChange,
												handleDeductionChangeDropdown: handleChangeDropdown,
												deductionListed: deductionValues?.filter((data) => !nDeduction?.includes(data?.name)),
												name,
												placeholder: 'Select Deduction',
												buttondisabel,
												isPayrollRunClaims,
											}}
										/>
									);
								})}
							</Col>
							<Col className="addition_col">
								<EdenredButtons
									btnType="secondary"
									className="add_addition"
									leftIcon={
										<ImageComponent
											src={icons.not_focus.plusSvg}
											className={buttondisabel || isPayrollRunClaims?.length === 0 ? 'plus-img-svg1' : 'plus-img-svg'}
										/>
									}
									onClick={() => !nDeductions && handlePayrollRun('deductions')}
									disabled={buttondisabel || isPayrollRunClaims?.length === 0}>
									Add Deduction
								</EdenredButtons>
							</Col>
							<Col
								className="addition_col1"
								style={{
									pointerEvents: buttondisabel ? 'none' : '',
									opacity: buttondisabel || isPayrollRunClaims?.length === 0 ? '0.4' : '',
								}}>
								<Col>
									<Checkbox
										{...{
											checked: gratuity,
											disabled: buttondisabel || isPayrollRunClaims?.length === 0,
											onChange: gratuitycaldata,
											labelPadding: 0,
											checkHeight: 14,
											icons: (
												<div className="payroll_run_profile_check_box">
													<img src={icons.not_focus.tick} alt="check" />
												</div>
											),
										}}
									/>
								</Col>
								<Col style={{ marginLeft: '8px' }}>
									<ETypo medium b1>
										Check if you want to include gratuity for the employee in this payroll cycle.
									</ETypo>
								</Col>
							</Col>
							{gratuity ? (
								<Col className="addition_col2">
									<Col className="addition_col3">
										<Col className="payroll-setting-main-header1">
											<PayrollDatePicker
												{...{
													dataPicker,
													placeholder: 'Leaving Date',
													date: leavingDate,
													employeeDetail,
													payRollRun1: payrolldata,
													defaultDate: employeeDetail?.leavingDate,
													disabled: isPayrollRunClaims?.length === 0,
													label: 'Leaving Date',
												}}
											/>
										</Col>
										<Col className="dropdown-container1">
											{gratutityType?.map((detail, i) => (
												<Col className="dropdown-inner-container">
													<DropDownGratuity
														{...{
															index: i,
															options: detail?.value,
															value: [detail?.value],
															placeholder: 'Select Type Of Termination',
															onChangeTermination: onChangeTermination,
															empolyee: employeeDetail?.terminationType,
															disabledValue: employeeDetail?.contractType,
															payRollRun1: payrolldata,
															date1: leavingDate,
															disabled: isPayrollRunClaims?.length === 0,
															label: 'Type of Termination',
														}}
													/>
												</Col>
											))}
										</Col>
									</Col>
									<Col className="cross_salary_left2">
										<ETypo medium h4 className="width_60">
											Accrued Gratuity
										</ETypo>
										<ETypo medium h4>
											AED {amountWithCommas(gratutitycalValue1?.[0]?.value)}
										</ETypo>
									</Col>
									<Col className="salary_list2">
										{gratutitycalValue1?.map((item, i) => (
											<>
												<ETypo light b2 color="#181919" className="width_60">
													Accrued Gratuity
												</ETypo>
												<Inputbox
													prefix={'AED'}
													type="text"
													value={amountWithCommas(item?.value)}
													onChange={(e) => handleGratuity(e, i)}
													disabled={isPayrollRunClaims?.length === 0}
												/>
											</>
										))}
									</Col>
								</Col>
							) : null}
							<Col className="col_right"></Col>
						</Col>
					</Col>
					<PopUp
						{...{ savePopup, handleSavePayroll, savePayrollValue, savePayrollRunLoading, savedSuccessfully, isCancle, notification }}
					/>
				</Row>
			)}
		</>
	);
};

export default PayrollRunPresentational;
