import React from 'react';
import { Row, Col } from 'antd';
import Uploaddocument from '@pages/payroll/EmployeesExpirymodule/component/Upload-document';
import SetupHeader from '@pages/setup-Screen/components/setup-header';

function SetupHrDocumentPresentation({ setUpUpload }) {
	return (
		<>
			<SetupHeader />
			<Row>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="setupEmpDocs">
					<Uploaddocument
						{...{
							isSetup: true,
							setUpName: 'Documents:',
							setUpUpload,
							draggerHeader: 'Upload Your Spreadsheet Here:',
							hintText: "to complete setup, fill in all your employees' document numbers and expiry dates.",
							description: "Receive email notifications to remind you of the expiry of your employees' documents",
							nextPath: '/setupHrEmployeeList',
							docLink: '/setupHrDocument',
							uploadType: 'hrDocument',
						}}
					/>
				</Col>
			</Row>
		</>
	);
}

export default SetupHrDocumentPresentation;
