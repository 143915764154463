import React from 'react';
import DotLoader from 'react-spinners/DotLoader';
import { Row, Col, Form } from 'antd';
import EdenredButton from '@sharedComponent/button';
import Input from '@sharedComponent/text-field';
import { images } from '@assets/img/index';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { Link } from 'react-router-dom';
import AppLoader from '@sharedComponent/app-loader';

const LoginPresentational = ({ onLoginButtonClick }) => {
	return <AppLoader />;
	// return (
	// 	<Row className="login_container">
	// 		<Col xs={24} sm={24} md={16} xl={10} className="left_area">
	// 			{/* row-1 */}
	// 			<Row className="logo">
	// 				<Col xl={24} xs={24} sm={20} md={24} lg={32}>
	// 					<ImageComponent src={images.logo} />
	// 				</Col>
	// 			</Row>
	// 			{/* row-2 */}
	// 			<Row>
	// 				<Col xl={18} className="login_header">
	// 					Log In
	// 				</Col>
	// 			</Row>
	// 			{/* row-3 */}
	// 			<Row className="form_label">
	// 				<Col xs={24} sm={20} md={16} xl={18}>
	// 					<Form>
	// 						{/* <Form.Item name="name" rules={[{ required: true, message: 'Error Message!' }]}>
	// 							<Input type="name" labels="username" />
	// 						</Form.Item>
	// 						<Form.Item name="password" rules={[{ required: true, message: 'Error Message!' }]}>
	// 							<Input type="password" labels="password" />
	// 						</Form.Item> */}
	// 						<Form.Item>
	// 							<EdenredButton onClick={onLoginButtonClick}>Login</EdenredButton>
	// 						</Form.Item>
	// 					</Form>
	// 				</Col>
	// 			</Row>
	// 			{/* row- 4 */}
	// 			<Row>
	// 				<Col xs={16} sm={19} md={9} xl={14}>
	// 					<a style={{ float: 'left', textDecoration: 'underline', color: '#0E5274' }} href="">
	// 						Forgot password{' '}
	// 					</a>
	// 				</Col>
	// 				<Col xs={7} sm={5} md={7} xl={5}>
	// 					<a style={{ float: 'left', textDecoration: 'underline', color: '#0E5274' }} href="">
	// 						Need Help ?
	// 					</a>
	// 				</Col>
	// 			</Row>
	// 		</Col>
	// 		<Col xs={16} sm={24} md={16} xl={14} className="right_area_container">
	// 			<Row className="right_area" xs={16} sm={24} md={16} xl={14}>
	// 				<Col xs={16} sm={24} md={16} xl={12} className="we_brand">
	// 					<Col className="we_brand_text">We</Col>
	// 					<Col className="we_brand_text">Brand</Col>
	// 					<Col>
	// 						<ImageComponent src={images.MultiDevices} className="laptop_img" />
	// 					</Col>
	// 				</Col>
	// 				<Col xs={16} sm={24} md={16} xl={12} className="are_new">
	// 					<Col className="are_new_text">Are</Col>
	// 					<Col className="are_new_text">New</Col>
	// 				</Col>
	// 			</Row>
	// 		</Col>
	// 	</Row>
	// );
};

export default LoginPresentational;
