import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import AppActions from '@sharedComponent/app-action-reducer/action';
import Actions from '../action';
import Axios from 'axios';

export function* saveProfileImageService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const getUserName = (state) => state?.sharedStates?.currentUser?.user?.userName;
	const userId = yield select(getUserName);
	const types = action?.file;
	let formData = new FormData();
	formData.append('employeeProfileImage', types);
	try {
		const uri = `${DOMAIN_URL}/Users/${corporateId}/user-employee-profile-image?userId=${userId}`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.saveProfileImageSuccess(res?.data));
		yield put(Actions.creators.showSuccessSaveMsg(true));
		const profileUri = `${DOMAIN_URL}/Users/me`;
		const userDetails = yield call(Axios.get, profileUri);
		yield put(AppActions.creators.getProfileImageStart(userDetails?.data?.user?.profileImagePath));
	} catch (err) {
		yield put(Actions.creators.saveProfileImageFail(err));
		yield put(Actions.creators.showSuccessSaveMsg(false));
	}
}

export function* deleteProfileImageService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const getUserName = (state) => state?.sharedStates?.currentUser?.user?.userName;
	const userId = yield select(getUserName);
	try {
		const uri = `${DOMAIN_URL}/Users/${corporateId}/delete-user-profile-image?userId=${userId}`;
		const res = yield call(Axios.post, uri);
		console.log('DELETE MSG ::', res?.data);
		yield put(Actions.creators.deleteProfileImageSuccess(res?.data));
		// const profileUri = `${DOMAIN_URL}/Users/me`;
		// yield call(Axios.get, profileUri);
		yield put(AppActions.creators.getProfileImageSuccess(null));
	} catch (err) {
		yield put(Actions.creators.deleteProfileImageFail(err));
	}
}

export default function* getProfileWatcher() {
	yield takeEvery(Actions.types.SAVE_PROFILE_IMAGE_START, saveProfileImageService);
	yield takeEvery(Actions.types.DELETE_PROFILE_IMAGE_START, deleteProfileImageService);
}
