import React from 'react';
import { Col, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import EdenredButton from '@sharedComponent/button';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import CompanyTabPane1 from './company-tab-pane1';
import CompanyTabPane2 from './company-tab-pane2';
import ContentLoader from '@sharedComponent/content-loader';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import Toast from '@pages/DesignSystem/Toast';

const CompanySettingPresentational = ({
	allowancesArray,
	handleNewArray,
	handleDelete,
	iscompanySettingSaveSuccess,
	departmentArray,
	handleDeleteDep,
	monthState,
	handleMonthIncrement,
	handleTick,
	tick,
	handleOverTime,
	correct,
	handleOnChange,
	overtimePolicies,
	overtimeData,
	overTimeDataInfo,
	overTimeSaveData,
	handleSuccessPopups,
	isCreateSaved,
	isOverTimeSaved,
	getOvertimePolicyPercentage,
	overTimePercentData,
	overTimePercentLoading,
	getOverTimeData,
	overTimeData,
	overTimeDataLoading,
	overTimeSalaryData,
	overTimeSalaryDataLoading,
	getOverTimeSalaryPerDay,
	setOvertimeData,
	setOvertimePolicies,
	checkDataChange,
	defaultOvertimeData,
	setDefaultOvertimeData,
	defaultOvertimePolicies,
	setDefaultOvertimePolicies,
	isDisabled,
	setIsDisabled,
	cancel,
	additionArray,
	deductionsArray,
	handleSaveButton,
	handleCancleButton,
	setFileUpload,
	handleDeleteDed,
	SaveCompanySettingValues,
	isDisabledComp,
	setIsDisabledComp,
	handleDeleteAdd,
	isCompanySaveSuccess,
	isSaveSuccessfullDone,
	handleNewArray1,
	setupload,
	upload,
	overTimeLabelData,
	dragandDropEdit,
	saveGetUploadLogoLoading,
	saveGetUploadLogoStatus,
	getUploadFile,
	saveCompanySettingLoading,
	forEnable,
	loading,
	isSetUp,
	handleCompanyTab,
	handleCompanySetting,
	companyTab,
	popupvisible,
	setPopupvisible,
	handleOrganizationSetting,
	disableorganizationSave,
	vm,
}) => {
	const { TabPane } = Tabs;
	const defaultApproverLoading = useSelector((state) => state?.companySettingReducer?.defaultApproverLoading);

	return (
		<>
			{defaultApproverLoading ? (
				<div style={{ display: 'flex', width: '100%', height: '100%', marginTop: '45%' }}>
					<ContentLoader />
				</div>
			) : (
				<Row className="row-company-setting">
					<Row className="company-setting-headerRow">
						<Col className="company-setting-header" xl={24}>
							<Col xl={18} lg={10} md={10} sm={10} xs={1}>
								<ETypo h1 bold className="company-setting-title">
									Company Setting
								</ETypo>
							</Col>
							<Col xl={4}>
								{companyTab == 1 ? (
									<Col xl={24} className="company-setting-column5">
										<EdenredButtons
											leftIcon={<ImageComponent src={images.leftsarrow} />}
											// className="company-setting-cancelBtn"
											onClick={() => handleOrganizationSetting('cancel')}
											btnType="secondary">
											Back
										</EdenredButtons>
										<EdenredButtons
											loading={saveCompanySettingLoading}
											onClick={() => handleOrganizationSetting('save', 1)}
											disabled={disableorganizationSave}>
											{isSetUp ? 'Save changes' : 'Save'}
										</EdenredButtons>
									</Col>
								) : (
									<Col xl={24} className="company-setting-column5">
										<EdenredButtons
											leftIcon={<ImageComponent src={images.leftsarrow} />}
											className="company-setting-cancelBtn"
											onClick={() => handleCompanySetting('cancel')}
											btnType="secondary">
											Back
										</EdenredButtons>
										<EdenredButtons
											loading={saveCompanySettingLoading}
											onClick={() => handleCompanySetting('save', 2)}
											disabled={isDisabled}>
											{isSetUp ? 'Save changes' : 'Save'}
										</EdenredButtons>
									</Col>
								)}
							</Col>
						</Col>
					</Row>
					<Col xl={24} sm={24} md={24} lg={24} className="company-setting-main-container">
						<Tabs defaultActiveKey="1" onChange={handleCompanyTab}>
							<TabPane tab="Organisation" key="1">
								<CompanyTabPane1
									{...{
										allowancesArray,
										handleNewArray,
										handleDelete,
										departmentArray,
										handleDeleteDep,
										monthState,
										handleMonthIncrement,
										additionArray,
										deductionsArray,
										handleSaveButton,
										handleCancleButton,
										setFileUpload,
										handleDeleteDed,
										handleSuccessPopups,
										isCreateSaved,
										SaveCompanySettingValues,
										isDisabledComp,
										setIsDisabledComp,
										handleDeleteAdd,
										isCompanySaveSuccess,
										isSaveSuccessfullDone,
										handleNewArray1,
										setupload,
										upload,
										dragandDropEdit,
										saveGetUploadLogoLoading,
										saveGetUploadLogoStatus,
										getUploadFile,
										saveCompanySettingLoading,
										forEnable,
										isSetUp,
										popupvisible,
										setPopupvisible,
										vm,
									}}
								/>
							</TabPane>
							<TabPane tab="Overtime" key="2">
								<CompanyTabPane2
									{...{
										handleTick,
										tick,
										handleOverTime,
										correct,
										handleOnChange,
										overtimePolicies,
										overtimeData,
										overTimeSaveData,
										isOverTimeSaved,
										getOvertimePolicyPercentage,
										overTimePercentData,
										overTimePercentLoading,
										getOverTimeData,
										overTimeData,
										overTimeDataLoading,
										overTimeSalaryData,
										overTimeSalaryDataLoading,
										getOverTimeSalaryPerDay,
										setOvertimeData,
										overTimeDataInfo,
										setOvertimePolicies,
										checkDataChange,
										defaultOvertimeData,
										setDefaultOvertimeData,
										defaultOvertimePolicies,
										setDefaultOvertimePolicies,
										isDisabled,
										setIsDisabled,
										cancel,
										handleSuccessPopups,
										isCreateSaved,
										iscompanySettingSaveSuccess,
										overTimeLabelData,
										loading,
										isSetUp,
									}}
								/>
							</TabPane>
						</Tabs>
					</Col>
				</Row>
			)}
		</>
	);
};

export default CompanySettingPresentational;
