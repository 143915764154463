import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { icons } from '@assets/icons/index';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import Inputbox from '@pages/DesignSystem/Input-Field';
import Switch from '@pages/DesignSystem/togglebutton';
import ETypo from '@pages/DesignSystem/Typo';
import ClevertapReact from 'src/utils/clever-tap';
import PayrollService from 'src/services/payroll-service';
import Action from 'src/components/shared/app-action-reducer/action';
import PayrollPlusAction from 'src/pages/payroll-plus-new/action';
import RunPayrollPresentational from './run-payroll-presentational';
import { UnemploymentInsuranceShortBanner } from './unemployment-insurance-short-banner';
import { API_STATUS_CODES } from '@constants/app-constants';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import { amountWithCommas, amountWithNoCommas, findObjFromArray, twoDigitAfterDec } from '@helpers';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';

const RunPayrollFuntional = () => {
	const _payrollService = new PayrollService();
	const history = useHistory();
	const [totalEmpAmt, settotalEmpAmt] = useState(0);
	const [totalVariableAmt, settotalVariableAmt] = useState(0);
	const [totalEmpSelect, settotalEmpSelect] = useState(0);
	const [checkAll, setCheckAll] = useState(false);
	const [selectedRecord, setselectedRecord] = useState([]);
	const [stagingLoader, setstagingLoader] = useState(false);
	const [nextLoader, setNextLoader] = useState(false);
	const [uploadId, setUploadId] = useState(null);
	const [isRepeatMode, setIsRepeatMode] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [selectedFilterCount, setSelectedFilterCount] = useState({
		payrollChannels: 0,
		accountTypes: 0,
		establishments: 0,
		bankNames: 0,
	});
	const [paytableData, SetPayTableData] = useState([]);
	const [checkedpaytableData, SetCheckedPayTableData] = useState([]);
	const [loading, setloading] = useState(true);
	const [paytableDataDummy, SetPayTableDataDummy] = useState([]);
	const [tableFilter, settableFilter] = useState({
		bankName: null,
		establishmentId: null,
		accountType: null,
		payrollChannel: null,
		pageSize: null,
		pageNumber: null,
		search: null,
	});
	const [payrollPagination, setpayrollPagination] = useState({
		pagination: {
			current: 1,
			pageSize: 30,
		},
	});
	const [employeeCounts, setemployeeCounts] = useState(null);
	const [fromUploadSheetPage, setfromUploadSheetPage] = useState(history?.location?.state?.fromUploadSheetPage || false);
	const [blockedData, setblockedData] = useState(null);
	let [totalFilterCount, setTotalFilterCount] = useState(0);
	const [dropdwon, setDropdown] = useState({
		accountTypeDD: [],
		establishmentIdsDD: [],
		bankNameDD: [],
		payrollChannelDD: [],
	});
	const [isPopupVisible, setIsPopupVisible] = useState({
		isWpsNonWps: false,
		data: [],
		endofService: [],
		payrollPlus: {},
		runPayroll: {},
		message: '',
		wpsNonWps: '',
		monthYearSelect: {},
		selectDate: '',
		status: 'wpsNonWps',
		type: '',
	});

	const [salaryExceedsModal, setSalaryExceedsModal] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(0);
	const [isSalaryExceeds, setIsSalaryExceeds] = useState(false);

	const payrollPlusState = useSelector((state) => state?.payrollPlusState);
	const featuresFlag = useSelector((state) => state?.sharedStates?.features);
	const payrollErrorInitailState = {
		userLimitExceeds: false,
		cardLoad: false,
		salaryProcess: false,
		cardLoadError: [],
		salaryProcessErrorMsg: [],
	};
	const [payrollError, setPayrollError] = useState(payrollErrorInitailState);

	useEffect(() => {
		if (isSalaryExceeds) {
			setSalaryExceedsModal(true);
			setNextLoader(false);
		}
	}, [isSalaryExceeds]);

	const handletoggleswitch = (emp_id, data) => {
		let paytableDataResult = [...paytableData];
		SetPayTableData((prevState) => {
			const prevStateCopy = [...prevState];
			const result = prevStateCopy.map((val) => {
				const _result = { ...val };
				if (val.emp_id === emp_id) {
					_result.endofservice = !val?.endofservice;
				}
				return _result;
			});
			paytableDataResult = result;
			return result;
		});
		/**
		 * To set the End Of Service to the selecteds array.
		 */
		// let selectedRecordResult = [...selectedRecord];
		setselectedRecord((PreSelected) => {
			const PreSelectedCopy = [...PreSelected];
			const preResult = PreSelectedCopy.map((val) => {
				const _result = { ...val };
				if (val.emp_id === emp_id) {
					_result.endofservice = !val?.endofservice;
				}
				return _result;
			});
			// selectedRecordResult = preResult;
			return preResult;
		});
		SetPayTableDataDummy([...paytableDataResult]);
	};
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(Action.creators.getUserNavigation());
	}, []);
	useEffect(() => {
		let totalCount =
			selectedFilterCount.payrollChannels +
			selectedFilterCount.accountTypes +
			selectedFilterCount.establishments +
			selectedFilterCount.bankNames;
		setTotalCount(totalCount);
	}, [selectedFilterCount]);

	const setDropdown1 = (data) => indupdateTotalFilterCount(data);
	const updateTotalFilterCount = (selectedFilter) => {
		let updatedFilterCount = Object?.values(selectedFilter)?.reduce((value1, value2) => value1 + value2);
		setTotalFilterCount(updatedFilterCount);
	};
	const indupdateTotalFilterCount = async (data) => {
		let updatedFilterData = await data;
		let updatedSelectedFilterCount = await { ...selectedFilterCount };
		await Object?.keys(updatedFilterData || {})?.forEach(async (key) => {
			let count = 0;
			await updatedFilterData?.[key]?.forEach(async (value) => {
				if (value?.isSelected) {
					count = (await count) + 1;
				}
			});
			switch (key) {
				case 'accountTypeDD':
					updatedSelectedFilterCount.accountTypes = (await count) || 0;
					break;
				case 'establishmentIdsDD':
					updatedSelectedFilterCount.establishments = (await count) || 0;
					break;
				case 'bankNameDD':
					updatedSelectedFilterCount.bankNames = (await count) || 0;
					break;
				case 'payrollChannelDD':
					updatedSelectedFilterCount.payrollChannels = (await count) || 0;
					break;
				default:
					break;
			}
		});
		await updateTotalFilterCount(updatedSelectedFilterCount);
	};

	const handleClearAllFilter = async (isSelected) => {
		let updatedFilterData = await { ...dropdwon };
		let updatedSelectedFilterCount = await { ...selectedFilterCount };
		await Object?.keys(updatedFilterData)?.forEach(async (key) => {
			await updatedFilterData[key]?.forEach((value) => {
				value.isSelected = isSelected;
			});
			switch (key) {
				case 'accountTypeDD':
					updatedSelectedFilterCount.accountTypes = isSelected ? updatedFilterData?.[key]?.length : 0;
					break;
				case 'establishmentIdsDD':
					updatedSelectedFilterCount.establishments = isSelected ? updatedFilterData?.[key]?.length : 0;
					break;
				case 'bankNameDD':
					updatedSelectedFilterCount.bankNames = isSelected ? updatedFilterData?.[key]?.length : 0;
					break;
				case 'payrollChannelDD':
					updatedSelectedFilterCount.payrollChannels = isSelected ? updatedFilterData?.[key]?.length : 0;
					break;
				default:
					break;
			}
			!isSelected &&
				setSelectedFilterCount({
					payrollChannels: 0,
					accountTypes: 0,
					establishments: 0,
					bankNames: 0,
				});
		});
		await setSelectedFilterCount({ ...updatedSelectedFilterCount });
		await setDropdown({ ...updatedFilterData });
		await updateTotalFilterCount(updatedSelectedFilterCount);
		await getrunpayrollTable(dropdwon);
	};

	const handleFilterHeader = async () => {
		let data = paytableData ? [...paytableData] : [];
		if (!checkAll) {
			await data.map((val) => {
				val.isSelected = true;
				val['salaryExceeds'] = false;
				val['hasError'] = checkHasError(val);
				return val;
			});
			await SetCheckedPayTableData([...data]);
			setselectedRecord([...data]);
		} else {
			await data.map((val) => {
				val.isSelected = false;
				val['salaryExceeds'] = false;
				val['hasError'] = checkHasError(val);
				return val;
			});
			await SetCheckedPayTableData([]);
			setselectedRecord([]);
		}
		SetPayTableData([...data]);
		SetPayTableDataDummy([...data]);
		setCheckAll(!checkAll);
		setTotalEmp(paytableData);
	};

	const checkHasError = (val) =>
		parseFloat(val?.totol_sal) === 0 ||
		val.totol_sal === '0' ||
		val.totol_sal === '' ||
		parseInt(val?.dayonLeave) > 30 ||
		parseFloat(val?.totol_sal) < parseFloat(val?.variable_pay) ||
		parseFloat(val?.variable_pay) > parseFloat(val?.totol_sal);

	const checkedIndex = async (emp_id, makeTrue = false, data) => {
		let dataChecked = selectedRecord || [];
		data = paytableData ? [...paytableData] : data;
		data.map((val) => {
			if (emp_id === val?.emp_id) {
				let isChangable = dataChecked.filter((dcval) => emp_id !== dcval?.emp_id) || [];
				isChangable && isChangable?.[0] && SetCheckedPayTableData([...isChangable, ...selectedRecord]);
				if (makeTrue) {
					val.isSelected = true;
					val['hasError'] = checkHasError(val);
					return;
				}
				if (val['salaryExceeds']) {
					val['salaryExceeds'] = false;
					val.isSelected = false;
					setIsSalaryExceeds(false);
					val['hasError'] = checkHasError(val);
					return;
				}
				val.isSelected = !val?.isSelected;
				val['hasError'] = checkHasError(val);
			}
			return val;
		});
		SetPayTableData([...data]);
		SetPayTableDataDummy([...data]);
		let data1 = data || [];
		let isUnCheckedData = data1?.filter((val) => !val?.isSelected) || [];
		let isCheckedData = data1?.filter((val) => val?.isSelected) || [];
		SetCheckedPayTableData([...isCheckedData]);
		setselectedRecord(uniqueValue([...isCheckedData, ...selectedRecord]));
		setCheckAll(isUnCheckedData && isUnCheckedData?.[0] ? false : true);
		setTotalEmp(uniqueValue([...isCheckedData, ...selectedRecord]));
	};

	const uniqueValue = (tableData) => {
		let array = Array?.from(new Set(tableData?.map((a) => a?.emp_id)))?.map((emp_id) => {
			return tableData?.find((a) => a?.emp_id === emp_id);
		});
		return array?.filter((array) => array?.isSelected);
	};

	const setTotalEmp = async (data1) => {
		let totalEmpSelect = (await data1?.filter((val) => val?.isSelected)) || [];
		let total = 0;
		let totalVariableAmt = 0;
		await totalEmpSelect?.forEach((val) => {
			total = parseFloat(total) + parseFloat(val?.totol_sal);
		});
		await totalEmpSelect?.forEach((val) => {
			totalVariableAmt = parseFloat(totalVariableAmt) + parseFloat(val?.variable_pay);
		});
		settotalEmpSelect(totalEmpSelect.length || 0);
		settotalEmpAmt(total || 0);
		settotalVariableAmt(totalVariableAmt || 0);
	};

	const hanldePayTableData = async (label, e, emp_id, data) => {
		const reg = /^-?\d*(\.\d*)?$/;
		let newValue = e?.split('');
		let value;
		if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
			newValue.splice(0, 1);
			value = amountWithNoCommas(twoDigitAfterDec(newValue.join('')));
		} else {
			value = amountWithNoCommas(twoDigitAfterDec(e));
		}
		// for days on leave not allowing decimal value
		if (label === 'dayonLeave') {
			value = value.replace(/\./g, '');
		}
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			data = paytableData ? [...paytableData] : data;
			await data.map((val) => {
				if (val.emp_id === emp_id) {
					val[label] = isNaN(value) ? 0 : value || 0;
					if (label === 'totol_sal') {
						// If salary exceeds true it will become false
						val['salaryExceeds'] = false;
						setIsSalaryExceeds(false);
						val['hasError'] =
							parseFloat(value) === 0 || value === '0' || value === '' || parseFloat(value) < parseFloat(val?.variable_pay);
					}
					if (label === 'variable_pay') {
						val['hasError'] = parseFloat(value) > parseFloat(val?.totol_sal) || Number(val?.totol_sal) <= 0 || Number(value) < 0;
					}
					if (label === 'dayonLeave') {
						val['hasError'] = parseInt(value) > 30 || parseInt(val?.totol_sal) <= 0;
					}
				}
				return val;
			});
			let isCheckedData = data?.filter((val) => val?.isSelected) || [];
			SetCheckedPayTableData([...isCheckedData]);
			SetPayTableData([...data]);
			SetPayTableDataDummy([...data]);
			await setTotalEmp(paytableData);
		}
	};

	const getrunpayrollTable = (data, pageNumber, pageSize) => {
		setloading(true);
		let params = {}; // TODO - NEED TO SET FILTER FUNC HERE
		params.bankName = data?.bankName || '';
		params.establishmentId = data?.establishmentId || '';
		params.accountType = data?.accountType || '';
		params.payrollChannel = data?.payrollChannel || '';
		params.pageSize = data?.previousPayrollUploadId ? '' : pageSize || payrollPagination?.pagination?.pageSize;
		params.previousPayrollUploadId = data?.previousPayrollUploadId || null;
		params.previoustotalPaidEmps = data?.previoustotalPaidEmps || null;
		params.pageNumber = pageNumber || payrollPagination?.pagination?.current;
		params.search = data?.search || null;
		params.includeEmployeeCounts = false;
		params.payrollBlockCheck = true;
		params.status = 'ACTIVE';
		let globalSelect = [];
		_payrollService
			.getPayrollTableData(params)
			.then((response) => {
				setloading(false);
				if (
					response?.data &&
					response?.data?.length > 0 &&
					response?.data?.[0]?.financialBlockResponseModel &&
					response?.data?.[0]?.financialBlockResponseModel?.corporateMessage
				) {
					setblockedData(response?.data?.[0]?.financialBlockResponseModel);
				}
				let data = [];
				if (!history?.location?.state) {
					response?.data &&
						response?.data?.employees &&
						response.data.employees.forEach((val, ind) => {
							data.push({
								key: ind,
								index: ind,
								isSelected: params?.previousPayrollUploadId ? val?.previousSalary > 0 : false,
								firstName: val?.employeeName,
								emp_id: val?.employeeId,
								employeeCode: val?.employeeCode,
								totol_sal: params?.previousPayrollUploadId ? val?.previousSalary : val?.salary,
								variable_pay: params?.previousPayrollUploadId ? val?.previousVariablePay : val?.variablePay,
								endofservice: params?.previousPayrollUploadId ? val?.previousEndofService : val?.endOfService,
								dayonLeave: params?.previousPayrollUploadId ? val?.previousDaysOnLeave : val?.daysOnLeave,
								accountType: val?.accountType,
								labourCardNo: val?.labourCardNo,
								wpsPersonid: val?.wpsPersonId ? val.wpsPersonId : val.wpsPersonid,
								payrollChannel: val?.payrollChannel,
								bankName: val?.bankName,
								establishmentId: val?.establishmentId,
								hasError: false,
								salaryExceeds: val.salaryExceeds || false,
							});
						});
					if (params.previousPayrollUploadId) {
						let selectedValue = data?.filter((value) => value?.isSelected);
						if (uploadId !== params?.previousPayrollUploadId) {
							setselectedRecord([...selectedValue]);
							globalSelect = [...selectedValue];
						} else {
							setselectedRecord(uniqueValue([...selectedValue, ...selectedRecord]));
							globalSelect = uniqueValue([...selectedValue, ...selectedRecord]);
						}
					}
				} else {
					history.location.state.empList.forEach((val, ind) => {
						data.push({
							key: ind,
							index: ind,
							isSelected: true,
							firstName: val?.employeeName,
							emp_id: val?.employeeId,
							employeeCode: val?.employeeCode || val?.employeCode,
							totol_sal: val?.salary,
							variable_pay: val?.variablePay,
							endofservice: val?.endOfService || val?.isEndOfService,
							dayonLeave: val?.daysOnLeave,
							accountType: val?.accountType,
							labourCardNo: val?.labourCardNo,
							wpsPersonid: val?.wpsPersonId ? val.wpsPersonId : val?.wpsPersonid,
							payrollChannel: val?.payrollChannel,
							bankName: val?.bankName,
							establishmentId: val?.establishmentId,
							hasError: checkHasError({
								...val,
								totol_sal: val?.salary,
								variable_pay: val?.variablePay,
								dayonLeave: val?.daysOnLeave,
							}),
							salaryExceeds: val?.salaryExceeds || false,
						});
					});
					setselectedRecord(data);
				}
				if (uploadId !== params?.previousPayrollUploadId) {
					setUploadId(params?.previousPayrollUploadId);
					selectedRecord.forEach((val, ind) => {
						data.forEach((val1, ind1) => {
							if (val?.emp_id === val1?.emp_id) {
								data[ind1].key = val.index != 0 ? val.index || null : 0;
								data[ind1].index = val.index != 0 ? val.index || null : 0;
								data[ind1].isSelected = params.previousPayrollUploadId ? val1.isSelected : true || null;
								data[ind1].firstName = val.firstName || null;
								data[ind1].emp_id = val.emp_id || null;
								data[ind1].employeeCode = val.employeeCode || null;
								data[ind1].totol_sal = val.totol_sal || 0;
								data[ind1].variable_pay = val.variable_pay != 0 ? val.variable_pay || null : 0;
								data[ind1].dayonLeave = val.dayonLeave != 0 ? val.dayonLeave || null : 0;
								data[ind1].endofservice = val.endofservice || null;
								data[ind1].accountType = val.accountType || null;
								data[ind1].payrollChannel = val.payrollChannel || null;
								data[ind1].bankName = val.bankName || null;
								data[ind1].establishmentId = val.establishmentId || null;
								data[ind1].hasError = val1.hasError || null;
								data[ind1].salaryExceeds = val1.salaryExceeds || false;
							}
						});
					});
				} else {
					selectedRecord.forEach((val, ind) => {
						data.forEach((val1, ind1) => {
							if (val.emp_id === val1.emp_id) {
								data[ind1].key = val.index != 0 ? val.index || null : 0;
								data[ind1].index = val.index != 0 ? val.index || null : 0;
								data[ind1].isSelected = true || null;
								data[ind1].firstName = val.firstName || null;
								data[ind1].emp_id = val.emp_id || null;
								data[ind1].employeeCode = val.employeeCode || null;
								data[ind1].totol_sal = val.totol_sal || 0;
								data[ind1].variable_pay = val.variable_pay != 0 ? val.variable_pay || null : 0;
								data[ind1].dayonLeave = val.dayonLeave != 0 ? val.dayonLeave || null : 0;
								data[ind1].endofservice = val.endofservice || null;
								data[ind1].accountType = val.accountType || null;
								data[ind1].payrollChannel = val.payrollChannel || null;
								data[ind1].bankName = val.bankName || null;
								data[ind1].establishmentId = val.establishmentId || null;
								data[ind1].hasError = val1.hasError || null;
								data[ind1].salaryExceeds = val1.salaryExceeds || false;
							}
						});
					});
				}
				data.sort((x, y) => (x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1));
				SetPayTableData([...data]);
				history?.location?.state && setValueFromSpreadSheet(data, history?.location?.state?.empList);
				setemployeeCounts(response?.data?.totalCount);
				SetPayTableDataDummy([...data]);
				let isCheckdDAta = data?.filter((val) => !val?.isSelected) || [];
				setCheckAll(isCheckdDAta && isCheckdDAta?.[0] ? false : true);
				if (params?.previousPayrollUploadId !== null) {
					setIsRepeatMode(true);
					setTotalEmp([...globalSelect]);
				} else {
					setIsRepeatMode(false);
				}
			})
			.catch((responseerror) => {
				setloading(false);
			});
	};
	const activeEmployeeCount = employeeCounts;
	const setValueFromSpreadSheet = (dataFromAPI, response = []) => {
		setloading(false);
		let data = [];
		response.forEach((val, ind) => {
			data.push({
				key: ind,
				index: ind,
				isSelected: false,
				emp_id: val.employeeId,
				employeeCode: val.employeCode,
				totol_sal: val.salary,
				variable_pay: val.variablePay,
				endofservice: val.isEndOfService,
				dayonLeave: val.daysOnLeave,
				hasError: val.salary === 0 || val.salary === '0' || val.salary === '' || parseFloat(val.salary) < parseFloat(val.variablePay),
				salaryExceeds: val?.salaryExceeds || false,
			});
		});
		const ids = data?.map((data) => data?.emp_id);
		let result = dataFromAPI?.map((o1, index) => {
			if (ids?.indexOf(o1?.emp_id) >= 0) {
				const o2 = Object.assign({}, ...data?.filter((data) => data?.emp_id === o1?.emp_id));
				return {
					...o1,
					...o2,
					index,
					isSelected: true,
				};
			}
			return o1;
		});
		result = result.sort((x, y) => (x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1));
		result = result.map((x, xind) => {
			x['key'] = xind;
			x['index'] = xind;
			return { ...x };
		});
		SetPayTableData([...result]);
		SetPayTableDataDummy([...result]);
		setselectedRecord([...result]);
		let isCheckdDAta = result?.filter((val) => !val?.isSelected) || [];
		setCheckAll(isCheckdDAta && isCheckdDAta?.[0] ? false : true);
		setTotalEmp(result);
	};

	useEffect(() => {
		setIsPopupVisible({
			...isPopupVisible,
			isWpsNonWps: payrollPlusState.wpsNonWpsPopupFlag,
		});
	}, [payrollPlusState.wpsNonWpsPopupFlag]);

	useEffect(() => {
		getDropdownList();
		getrunpayrollTable(tableFilter);
	}, [history?.location?.state && history?.location?.state?.empList]);

	// has error variable
	const hasError = selectedRecord?.filter((data) => data?.hasError && data?.isSelected).length > 0;
	// running payroll upon employee selction
	const handleDataNext = async (param) => {
		let tabledata = selectedRecord?.filter((data) => data?.isSelected); //TODO-passing only selected value
		let empArray = [];
		tabledata.length > 0 &&
			tabledata.map((data) => {
				let empObj = {};
				empObj = {
					employeeId: data?.emp_id,
					employeeCode: data?.employeeCode,
					employeeName: data?.firstName,
					salary: isNaN(data?.totol_sal) ? 0 : data?.totol_sal || 0,
					variablePay: data?.variable_pay || 0,
					daysOnLeave: data?.dayonLeave || 0,
					isEndOfService: data?.endofservice || false,
				};
				empArray.push(empObj);
			});
		if (param === 'save') {
			setstagingLoader(true);
		} else if (param === 'next') {
			setNextLoader(true);
		}
		const salaryExcessData = {
			userInput: {
				salaryExceedsCount: 0,
				exceedsSalaryEmployees: [],
				exceedsSalaryEmployeesIds: [],
			},
			userActualLimit: {
				salaryExceedsCount: 0,
				exceedsSalaryEmployees: [],
				exceedsSalaryEmployeesIds: [],
			},
		};

		tabledata.forEach((emp) => {
			const total = Number(emp?.totol_sal);
			if (
				featuresFlag &&
				featuresFlag?.isCardLoadLimitEnabled &&
				emp.accountType === PAYROLL_CONSTANTS.employeeAccountType.card &&
				total > PAYROLL_CONSTANTS.salaryLimit
			) {
				salaryExcessData.userInput.salaryExceedsCount += 1;
				salaryExcessData.userInput.exceedsSalaryEmployees.push({ ...emp, salaryExceeds: true });
				salaryExcessData.userInput.exceedsSalaryEmployeesIds.push(Number(emp.emp_id));
			}
		});
		const proceedPayroll = async (param) => {
			try {
				let body = {
					employeeDetails: empArray,
				};
				const payrollResponse = await _payrollService?.EmployeeSalaryStaging(body);
				if (payrollResponse?.status === API_STATUS_CODES.SUCCESS) {
					ClevertapReact.event('Next_PayrollTable');
					setNextLoader(false);
					const { data } = payrollResponse || {};
					// if the data has items then its payroll error
					if (data?.length) {
						setSelectedEmployee(data.length);
						if (featuresFlag && featuresFlag?.isCardLoadLimitEnabled) {
							data.forEach((emp) => {
								if (emp.field === 'Salary' && emp.error === 'Exceeds Monthly Limit.') {
									const selectedEmployees = findObjFromArray(paytableData, String(emp.row), 'employeeCode');
									salaryExcessData.userActualLimit.salaryExceedsCount += 1;
									if (selectedEmployees) {
										salaryExcessData.userActualLimit.exceedsSalaryEmployees.push({ ...selectedEmployees, salaryExceeds: true });
									}
									salaryExcessData.userActualLimit.exceedsSalaryEmployeesIds.push(String(emp.row));
								}
							});
							if (salaryExcessData.userActualLimit.salaryExceedsCount > 0) {
								// payroll error employees
								setselectedRecord(() => [...salaryExcessData.userActualLimit.exceedsSalaryEmployees]);
								SetPayTableData((prev) => {
									const filterData = prev.filter(
										(prevTable) =>
											!salaryExcessData.userActualLimit.exceedsSalaryEmployeesIds.includes(String(prevTable.employeeCode))
									);
									return [...salaryExcessData.userActualLimit.exceedsSalaryEmployees, ...filterData];
								});
							}
						}
						// Using field to detect type of error msg
						data.map((d) => {
							// If its card load limit error
							if (d.field === 'Salary' && d.error === 'Exceeds Monthly Limit.') {
								setPayrollError((prevData) => ({
									...prevData,
									cardLoad: true,
									cardLoadError: [...prevData.cardLoadError, d?.row],
								}));
							} else {
								setPayrollError((prevData) => ({
									...prevData,
									salaryProcess: true,
									salaryProcessErrorMsg: [...prevData.salaryProcessErrorMsg, d.error],
								}));
							}
						});
						// payroll error popup
						setIsSalaryExceeds(true);
					} else {
						dispatch(PayrollPlusAction.creators.changeWpsNonWpsPopup(true));
						if (param === 'next') {
							setIsPopupVisible({
								...isPopupVisible,
								// isWpsNonWps: true,
								endofService: param === 'next' ? [...tabledata] : [],
								message: param === 'save' ? 'Saved Successfully' : 'next',
								type: 'runpayroll',
								status: 'wpsNonWps',
							});
						}
						if (param === 'save') {
							setstagingLoader(false);
							// message.success('Saved Successfully!');
						} else if (param === 'next') {
							setNextLoader(false);
						}
					}
				}
			} catch (error) {
				console.log(error, 'unsubscribed payroll error');
				if (param === 'save') {
					setstagingLoader(false);
				} else if (param === 'next') {
					setNextLoader(false);
				}
			}
		};
		if (featuresFlag && featuresFlag?.isCardLoadLimitEnabled && salaryExcessData.userInput.salaryExceedsCount > 0) {
			if (param === 'save') {
				setstagingLoader(false);
			} else if (param === 'next') {
				setNextLoader(false);
			}
			setPayrollError((prevData) => ({
				...prevData,
				userLimitExceeds: true,
			}));
			setSelectedEmployee(salaryExcessData.userInput.exceedsSalaryEmployees.length);
			setIsSalaryExceeds(true);
			setselectedRecord(() => [...salaryExcessData.userInput.exceedsSalaryEmployees]);
			SetPayTableData((prev) => {
				const filterData = prev.filter(
					(prevTable) => !salaryExcessData.userInput.exceedsSalaryEmployeesIds.includes(Number(prevTable.emp_id))
				);
				return [...salaryExcessData.userInput.exceedsSalaryEmployees, ...filterData];
			});
		} else {
			await proceedPayroll();
		}
	};
	const onSearch = async (searchValue) => {
		let data = await { ...tableFilter, search: String(searchValue).trim() };
		await settableFilter(data);
		await getrunpayrollTable(data);
	};

	const checkClicked = async (data) => {
		let bankName = '';
		let establishmentId = '';
		let accountType = '';
		let payrollChannel = '';
		setSelectedFilterCount({
			...selectedFilterCount,
			payrollChannels: data.payrollChannelDDlist.length,
			accountTypes: data.accountTypeList.length,
			establishments: data.establishmentIdsDDList.length,
			bankNames: data.bankNameDDList.length,
		});

		data &&
			data.bankNameDDList.forEach((val, i) => {
				bankName = bankName + val.bankName + ',';
				if (data.bankNameDDList || [].length !== i + 1) {
					payrollChannel = payrollChannel + ',';
				}
			});
		data &&
			data.establishmentIdsDDList.forEach((val, i) => {
				establishmentId = establishmentId + val.establishmentId + ',';
				if (data.establishmentIdsDDList || [].length !== i + 1) {
					payrollChannel = payrollChannel + ',';
				}
			});
		data &&
			data.accountTypeList.forEach((val, i) => {
				accountType = accountType + val.accountTypeName + ',';
				if (data.accountTypeList || [].length !== i + 1) {
					payrollChannel = payrollChannel + ',';
				}
			});
		data &&
			data.payrollChannelDDlist.forEach((val, i) => {
				payrollChannel = payrollChannel + val.payrollChannelName;
				if (data.payrollChannelDDlist.length !== i + 1) {
					payrollChannel = payrollChannel + ',';
				}
			});

		let filterData = {
			...tableFilter,
			bankName,
			establishmentId,
			accountType,
			payrollChannel,
		};
		await settableFilter(filterData);
		await getrunpayrollTable(filterData, payrollPagination.pagination.current);
	};

	const getDropdownList = () => {
		let request = {};
		request.includeEmployeeCounts = true;
		request.status = 'ACTIVE';
		_payrollService
			.getPayrollTableFilterData(request)
			.then(async (getTableDataesponse) => {
				if (getTableDataesponse && getTableDataesponse?.data) {
					await setDropdown({
						payrollChannelDD:
							(getTableDataesponse && getTableDataesponse.data && getTableDataesponse.data.payrollChannels
								? getTableDataesponse.data.payrollChannels
										.filter((pay) => pay.payrollChannelName !== '' && pay.payrollChannelName !== null)
										.map((val) => {
											return { ...val, isSelected: false };
										})
								: []) || [],
						accountTypeDD:
							(getTableDataesponse && getTableDataesponse.data && getTableDataesponse.data.accountTypes
								? getTableDataesponse.data.accountTypes
										.filter((acc) => acc.accountTypeName !== '' && acc.accountTypeName !== null)
										.map((val) => {
											return { ...val, isSelected: false };
										})
								: []) || [],
						establishmentIdsDD:
							(getTableDataesponse && getTableDataesponse.data && getTableDataesponse.data.establishments
								? getTableDataesponse.data.establishments
										.filter((estab) => estab.establishmentId !== '' && estab.establishmentId !== null)
										.map((val) => {
											return { ...val, isSelected: false };
										})
								: []) || [],
						bankNameDD:
							(getTableDataesponse && getTableDataesponse.data && getTableDataesponse.data.bankNames
								? getTableDataesponse.data.bankNames
										.filter((estab) => estab.bankName !== '' && estab.bankName !== null)
										.map((val) => {
											return { ...val, isSelected: false };
										})
								: []) || [],
					});
				}
			})
			.catch((err) => {});
	};

	const handleTableChange = (page, param, PrevPayRollData) => {
		setpayrollPagination({
			...payrollPagination,
			pagination: {
				...payrollPagination.pagination,
				[param]: page,
			},
		});
		if (param === 'current') {
			if (PrevPayRollData.previousPayrollUploadId) {
				let newTableFilter = tableFilter;
				newTableFilter.previousPayrollUploadId = PrevPayRollData.previousPayrollUploadId;
				newTableFilter.previoustotalPaidEmps = PrevPayRollData.previoustotalPaidEmps;
				getrunpayrollTable(newTableFilter, page);
			} else {
				getrunpayrollTable(tableFilter, page);
			}
		} else {
			getrunpayrollTable(tableFilter, '', page);
		}
	};

	function displayErrorMsg() {
		if (payrollError.userLimitExceeds) {
			return (
				<>
					Amount exceeds monthly limit of AED 50,000.
					<br />
					<p style={{ marginBottom: 0, marginTop: '16px' }}>
						You can either reduce the amount or
						<br />
						unselect these employees
					</p>
				</>
			);
		} else if (payrollError.cardLoad && payrollError.salaryProcess) {
			return (
				<>
					Amount exceeds monthly limit of AED 50,000.
					<br />
					<>
						Employee Ids:{' '}
						<ETypo b2>
							{payrollError.cardLoadError.length > 1
								? payrollError?.cardLoadError.map((err) => `${err}, `)
								: payrollError?.cardLoadError.map((err) => err)}
						</ETypo>
					</>
					<p style={{ marginBottom: 0, marginTop: '16px' }}>
						You can either reduce the amount or
						<br />
						unselect these employees
					</p>
					<br />
					Duplicate Ids found: <br />
					<>
						<ETypo b2>
							{payrollError.salaryProcessErrorMsg.length > 1
								? payrollError?.salaryProcessErrorMsg.map((err) => {
										return (
											<>
												{err}.<br />
											</>
										);
								  })
								: payrollError?.salaryProcessErrorMsg.map((err) => err)}
						</ETypo>
					</>
				</>
			);
		} else if (payrollError.cardLoad) {
			return (
				<>
					Amount exceeds monthly limit of AED 50,000.
					<br />
					<>
						Employee Ids:{' '}
						<ETypo b2>
							{payrollError.cardLoadError.length > 1
								? payrollError?.cardLoadError.map((err) => `${err}, `)
								: payrollError?.cardLoadError.map((err) => err)}
						</ETypo>
					</>
					<p style={{ marginBottom: 0, marginTop: '16px' }}>
						You can either reduce the amount or
						<br />
						unselect these employees
					</p>
				</>
			);
		} else if (payrollError.salaryProcess) {
			return (
				<>
					Reason: <br />
					<>
						<ETypo b2>
							{payrollError.salaryProcessErrorMsg.length > 1
								? payrollError?.salaryProcessErrorMsg.map((err) => {
										return (
											<>
												{err}.<br />
											</>
										);
								  })
								: payrollError?.salaryProcessErrorMsg.map((err) => err)}
						</ETypo>
					</>
				</>
			);
		}
	}

	const payrollErrorDialog = {
		title: `Payroll cannot be processed for ${selectedEmployee} employees`,
		desc: displayErrorMsg(),
		modalCloseText: 'Go Back',
	};

	function isValidAmountExists() {
		return paytableData.some((emp) => Number(emp.totol_sal) === 0);
	}

	const columns = [
		{
			key: 'isSelected',
			title: (
				<Col style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
					<Checkbox
						{...{
							borderWidth: 1,
							borderRadius: 4,
							checked: checkAll,
							size: 14,
							disabled: isValidAmountExists(),
							onChange: handleFilterHeader,
							icon: (
								<div className="payroll_table_check_box">
									<img src={icons.not_focus.tick} alt="check" />
								</div>
							),
						}}
					/>
				</Col>
			),
			dataIndex: 'isSelected',
			width: '5%',
			render: (val, val1, val2, val3) => {
				return (
					<Col style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
						<Checkbox
							{...{
								borderWidth: 1,
								borderRadius: 4,
								checked: val,
								size: 14,
								onChange: () => {
									checkedIndex(val1?.emp_id, '', val3);
								},
								icon: (
									<div className={`payroll_table_check_box ${val1.salaryExceeds ? 'salary-exceeds-error' : ''}`}>
										<img src={val1.salaryExceeds ? icons.not_focus.xmarkBold : icons.not_focus.tick} alt="check" />
									</div>
								),
							}}
						/>
					</Col>
				);
			},
		},
		{
			sort: (a, b) => a.firstName.localeCompare(b.firstName),
			key: 'firstName',
			title: 'Name',
			dataIndex: 'firstName',
			width: '15%',
			render: (val, val1) => {
				return <Col className={`emp_id ${val1?.isSelected ? 'tabName backactive' : 'tabName'}`}>{val}</Col>;
			},
		},
		{
			sort: (a, b) => a.employeeCode - b.employeeCode,
			key: 'employeeCode',
			title: 'Employee ID',
			dataIndex: 'employeeCode',
			width: '15%',
			render: (val, val1) => {
				return <Col className={`emp_id ${val1?.isSelected ? 'tabName backactive' : 'tabName'}`}>{val}</Col>;
			},
		},
		{
			sort: {
				compare: (a, b) => a.totol_sal - b.totol_sal,
				multiple: 5,
			},
			key: 'totol_sal',
			title: 'Total Salary',
			dataIndex: 'totol_sal',
			width: '15%',
			render: (totol_sal, val, val2, val3) => {
				return (
					<div className="payroll_amount_input">
						<Inputbox
							prefix={
								<ETypo light b1 color="#9A9EA6">
									AED
								</ETypo>
							}
							value={amountWithCommas(isNaN(totol_sal) ? 0 : totol_sal) ?? 0}
							onBlur={(e) =>
								e?.target?.value !== '0' && e?.target?.value !== ''
									? checkedIndex(val?.emp_id, true, val3)
									: checkedIndex(val?.emp_id, '', val3)
							}
							onChange={(e, e1) => hanldePayTableData('totol_sal', e.target.value ?? 0, val.emp_id, val3)}
							className={val?.isSelected && val.salaryExceeds ? 'table-salary-input-error' : ''}
							isErrorMessage={(val?.isSelected && parseFloat(totol_sal) === 0) || (val?.isSelected && val.salaryExceeds)}
							errorMessage={val.salaryExceeds ? 'Exceeds Monthly Limit ' : 'Enter Amount'}
						/>
					</div>
				);
			},
		},
		{
			sort: {
				compare: (a, b) => a.variable_pay - b.variable_pay,
				multiple: 5,
			},
			key: 'variable_pay',
			title: 'Variable Pay (Optional)',
			dataIndex: 'variable_pay',
			width: '15%',
			render: (variable_pay, val, val2, val3) => {
				const hasError = parseFloat(variable_pay) > parseFloat(val?.totol_sal) || Number(variable_pay) < 0;
				return (
					<div className="payroll_amount_input">
						<Inputbox
							disabled={!val?.totol_sal && val?.isSelected}
							prefix={
								<ETypo light b1 color="#9A9EA6">
									AED
								</ETypo>
							}
							value={amountWithCommas(isNaN(variable_pay) ? 0 : variable_pay) ?? 0}
							onChange={(e) => {
								hanldePayTableData('variable_pay', e?.target?.value, val?.emp_id, val3);
							}}
							isErrorMessage={hasError}
							errorMessage="Must be < Total Salary or value should not negative"
						/>
					</div>
				);
			},
		},
		{
			sort: {
				compare: (a, b) => a.dayonLeave - b.dayonLeave,
				multiple: 5,
			},
			key: 'dayonLeave',
			title: 'Days on Leave (Optional)',
			dataIndex: 'dayonLeave',
			width: '15%',
			render: (dayonLeave, val, val2, val3) => {
				return (
					<div className="payroll_amount_input">
						<Inputbox
							value={dayonLeave}
							type="number"
							onChange={(e) => hanldePayTableData('dayonLeave', e?.target?.value, val?.emp_id, val3)}
							isErrorMessage={dayonLeave > 30}
							errorMessage="days on leave must be < 30"
						/>
					</div>
				);
			},
		},
		{
			sort: {
				compare: (a, b) => a.Name - b.Name,
				multiple: 5,
			},
			key: 'endofservice',
			title: 'End of Service',
			dataIndex: 'endofservice',
			width: '10%',
			render: (endofservice, val, val1, val2) => {
				return (
					<div>
						<Switch
							{...{
								disabled: !val?.isSelected,
								isOn: endofservice,
								handleToggle: () => handletoggleswitch(val?.emp_id, val2),
								leftText: (
									<ETypo medium b2 color={val?.isSelected ? '#181919' : '#DFE1E6'}>
										No
									</ETypo>
								),
								rightText: (
									<ETypo medium b2 color={val?.isSelected ? '#181919' : '#DFE1E6'}>
										Yes
									</ETypo>
								),
							}}
						/>
					</div>
				);
			},
		},
	];

	return (
		<>
			{/* To display banner for non-subscribed unemployment insurance corprates */}
			<UnemploymentInsuranceShortBanner />
			<Edenredmodal
				isOpen={salaryExceedsModal}
				onOkay={() => {
					setSalaryExceedsModal(false);
					setPayrollError(payrollErrorInitailState);
				}}
				title={payrollErrorDialog.title}
				desc={payrollErrorDialog.desc}
				onCancel={null}
				close={null}
				onOkayButton={payrollErrorDialog.modalCloseText}
				topImage={<ImageComponent src={icons.not_focus.limitError} />}
			/>
			{/* End */}
			<RunPayrollPresentational
				{...{
					handletoggleswitch,
					data: paytableData,
					paytableDataDummy,
					columns,
					totalEmpAmt,
					totalVariableAmt,
					totalEmpSelect,
					checkAll,
					checkedIndex,
					handleFilterHeader,
					hanldePayTableData,
					checkClicked,
					SetPayTableData,
					SetPayTableDataDummy,
					handleDataNext,
					stagingLoader,
					onSearch,
					loading,
					selectedFilterCount,
					hasError,
					handleClearAllFilter,
					getrunpayrollTable,
					tableFilter,
					settableFilter,
					totalFilterCount,
					dropdwon,
					isRepeatMode,
					setDropdown,
					setDropdown1,
					setTotalFilterCount,
					indupdateTotalFilterCount,
					activeEmployeeCount,
					payrollPagination,
					handleTableChange,
					fromUploadSheetPage,
					blockedData,
					totalCount,
					isPopupVisible,
					setIsPopupVisible,
					nextLoader,
					isSalaryExceeds,
				}}
			/>
		</>
	);
};
export default RunPayrollFuntional;
