import React from 'react';
import AddEmployeesFunctional from './components/add-employees-functional';

import './add-employees.scss';
import './add-employees-mobile.scss';

const AddEmployees = () => {
	return <AddEmployeesFunctional />;
};

export default AddEmployees;
