import React from 'react';
import LandingPresentational from './LandingPresentational';
import { useHistory } from 'react-router-dom';

function LandingFunctional() {
	const history = useHistory();
	const setupscreen = () => {
		history.push({ pathname: '/setup' });
	};
	return <LandingPresentational {...{ setupscreen }} />;
}

export default LandingFunctional;
