export const emp_Request = [
    {
        question: `How do my employees block or unblock their cards?`,
        answer: [
            {
                label: 'Visit the "Manage Employees" section.',
                link: '',
            },
            {
                label: 'Your employees can immediately block their cards by visiting the C3Pay Mobile App and selecting “Block/Unblock my Card” from the profile section.',
                link: '',
            },
        ],
    },
    {
        question: 'My employee had an issue with an ATM withdrawal. He received the wrong amount of money. What do I do?',
        answer: [
            {
                label: 'Visit the “Manage Employees” section.',
                link: '',
            },
            {
                label: 'Select the employee you wish to file an ATM dispute for.',
                link: '',
            },
            {
                label: 'Select “ATM Dispute”.',
                link: '',
            },
            {
                label: 'Fill in the details of the transaction. This is important to retrieve the transaction’s details and initiate the refund process after verification.',
                link: '',
            },
        ],
    },
    {
        question: 'How do I request for a statement for my employee?',
        answer: [
            {
                label: 'Visit the “Manage Employees” section.',
                link: '',
            },
            {
                label: 'Select the employee you wish to request a statement for.',
                link: '',
            },
            {
                label: 'Select “Request Card Statement”.',
                link: '',
            },
            {
                label: 'Enter the email address on which you wish to receive the statement.',
                link: '',
            },
            {
                label: 'Click submit, you’ll soon receive the statement in your e-mail.',
                link: '',
            },
        ],
    },
    {
        question: 'My employee wants to close his C3 account and wishes to have the remaining balance transferred to him. How do I proceed?',
        answer: [
            {
                label: 'Please visit the useful files section. If the employee wants the remaining balance to be transferred to his local or international bank account, you may download the Bank Transfer form. If the employee wants a cheque with the remaining balance on it, you may download the Cheque Issuance form. Filled copies of forms can be sent to support.ae@edenred.com',
                link: '',
            },
        ],
    },
    {
        question: 'My employee wants help with the C3Pay App. What should he do?',
        answer: [
            {
                label: 'Your employees can get assistance with the C3Pay App at any time by calling 600567772 and speaking to an agent.',
                link: '',
            },
        ],
    }
]