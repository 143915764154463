import EdenRedHeader from '@pages/NewHrModuleSetup/Component/EdenRedHeader/EdenRedHeader';
import React from 'react';
import UploadLeave from './UploadLeave';
import '../Departments/index.scss';

const UploadLeavePage = () => {
	return (
		<>
			<main className="wrapper">
				<EdenRedHeader />
				<div className="main-container">
					<UploadLeave navigate="/new-hr-features/setup-approval-workflow" CleverTap={false} />
				</div>
			</main>
		</>
	);
};

export default UploadLeavePage;
