import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Input from '@sharedComponent/text-field';
import { payrollAllClaimCheck, payrollClaimCheck } from '@constants/app-constants';
import { accountTypeConst } from '@constants/';
import { forEnablingButton, isEmailValid } from '@helpers';
import Action from '@sharedComponent/app-action-reducer/action';
import { MESSAGES } from 'src/utils/toast-messages';
import EmployeeService from 'src/services/employee-service';
import UploadPayrollPopup from 'src/utils/uploadPayrollPopup';
import { withReducer } from 'src/store/reducerLoader';
import PayrollProfilePresentational from './payroll-profile-presentational';
import Selectors from '../profile-selector';
import Actions from '../profile-action';
import reducer from '../profile-reducer';
import saga from '../profile-saga';

function PayrollProfileFunctional({
	handleClick,
	openDeleteModal,
	employeeDetails,
	getEmployeeById,
	accountType,
	bankOptions,
	nationalityData,
	getNationality,
	claims,
	mainProfile,
	setShowLeave = () => {},
	showLeave,
	isInviteLoading = false,
	inviteEmpToPortalSaga,
	updateEmailSaga,
	emailConformation,
	conformEmailSaga,
	userNavigationMenu,
	setNavigateTabCheck,
	navigateTabCheck,
	employeeDetailState,
	setEmployeeDetailState,
}) {
	const history = useHistory();
	const [wpsForm] = Form.useForm();
	const { state } = useLocation();
	const [popupVisible, setpopupVisible] = useState(false);
	const [isShowLoader, setIsShowLoader] = useState(false);
	const [empToPortalEmail, setEmpToPortalEmail] = useState('');
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	let payrollclaim = [];
	let showEdit = [];
	let newclaim = claims?.map((item) => {
		if (payrollAllClaimCheck.includes(item?.value)) {
			payrollclaim.push(item);
		}
		if (payrollClaimCheck.includes(item?.value)) {
			showEdit.push(item);
		}
	});

	const onFinish = async () => {
		setIsShowLoader(true);
		let employeeApi = new EmployeeService();
		try {
			let payload = {
				firstName: employeeDetails.firstName,
				lastName: employeeDetails.lastName,
				nationality: employeeDetailState?.nationality,
				dateOfBirth: employeeDetailState?.dob?.split('/').reverse().join('-'),
				mobileNumber: employeeDetails?.mobileNo,
				emailId: employeeDetailState?.emailId,
			};

			let response = await employeeApi.updateEmployee(payload, employeeDetails.id);

			if (response && response.status === 200) {
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: response.data.message || MESSAGES.UPDATE_PROFILE_SUC,
					subMessage: null,
				});
				setIsShowLoader(false);
			}
		} catch (err) {
			setIsShowLoader(false);
		}
	};
	let employeeApi = new EmployeeService();
	async function updateWPSDetails(value) {
		setIsShowLoader(true);
		try {
			let payload = {
				wpsPersonId: employeeDetailState?.wpsPersonid,
				wpsEstablishmentId: employeeDetailState?.wpsEstablishmentId,
				labourCardNo: employeeDetailState?.labourCardNo,
			};
			let response = await employeeApi.updateWPS(payload, employeeDetails.id);
			if (response && response.status === 200) {
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: response.data.message || MESSAGES.UPDATE_PROFILE_SUC,
					subMessage: null,
				});
				setIsShowLoader(false);
			}
		} catch (err) {
			setIsShowLoader(false);
		}
	}
	async function onFinishBank() {
		setIsShowLoader(true);
		const empId = employeeDetails?.id;
		try {
			let payload = {
				iBanNumber: employeeDetailState?.iban,
				bankCode: employeeDetailState?.bankName,
				wpsPersonId: employeeDetailState?.wpsPersonid,
				wpsEstablishmentId: employeeDetailState?.wpsEstablishmentId,
				labourCardNumber: employeeDetailState?.labourCardNo,
			};

			let response = await employeeApi.updateBank(payload, empId);
			if (response && response.status === 200) {
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: response.data.message || MESSAGES.UPDATE_PROFILE_SUC,
					subMessage: null,
				});
				setIsShowLoader(false);
				history.push({ pathname: '/employees/viewemployee', state: { ...state } });
			}
		} catch (err) {
			setIsShowLoader(false);
		}
	}
	const [forInfo, setForInfo] = useState({ personalInfo: false, accountInfo: false });
	const [forDisableButton, setForDisableButton] = useState({
		wpsPersonid: false,
		wpsEstablishmentId: false,
		nationality: false,
		dateOfBirth: false,
		emailId: false,
		visible: false,
	});
	const handleInfo = (value) => {
		if (value === 'personalInfo') {
			setShowLeave({ ...showLeave, personal: false });
			setForInfo({ ...forInfo, personalInfo: true });
		} else {
			setShowLeave({ ...showLeave, account: false });
			setForInfo({ ...forInfo, accountInfo: true });
		}
	};
	const handleSave = (value) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '3',
		});
		if (value === 'personalInfoSave') {
			onFinish();
			setForDisableButton({
				nationality: false,
				dob: false,
				emailId: false,
			});
		} else if (value === 'accountInfoSave') {
			if (!wpsForm.getFieldsError().some(({ errors }) => errors.length)) {
				if (accountType === accountTypeConst.C3pay) {
					updateWPSDetails();
					setForDisableButton({
						...forDisableButton,
						wpsPersonid: true,
						wpsEstablishmentId: true,
					});
				} else {
					onFinishBank();
					setForDisableButton({
						...forDisableButton,
						wpsPersonid: true,
						wpsEstablishmentId: true,
					});
				}
			}
		}
	};
	const handleCancel = (value) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '3',
		});
		setForDisableButton({
			...forDisableButton,
			visible: true,
		});
	};
	useEffect(() => {
		setEmployeeDetailState({
			...employeeDetailState,
			nationality: employeeDetails?.nationality,
			dob: employeeDetails?.dateOfBirth,
			mobileNo: employeeDetails?.mobileNo,
			emailId: employeeDetails?.emailId,
			cardSerialNumber: employeeDetails?.cardSerialNumber,
			cardIssued: employeeDetails?.rhfreceivedDate,
			accountNo: employeeDetails?.accountNo,
			wpsPersonid: employeeDetails?.wpsPersonid,
			wpsEstablishmentId: employeeDetails?.wpsEstablishmentId,
			iban: employeeDetails?.bankAccountNo,
			bankName: employeeDetails?.bankRoutingCode,
			labourCardNo: employeeDetails?.labourCardNo,
		});
		setEmpToPortalEmail(employeeDetails?.emailId || '');
	}, [employeeDetails]);
	useEffect(() => {
		getNationality();
	}, []);
	const handleValueChanged = (id, event) => {
		const eventValue = event?.target?.value;

		setNavigateTabCheck((prevState) => {
			return { ...prevState, isTabClicked: true, tabKey: '1' };
		});
		if (id === 'dob') {
			setEmployeeDetailState((prevState) => {
				return { ...prevState, [id]: event };
			});
		} else {
			setEmployeeDetailState((prevState) => {
				const result = { ...prevState, [id]: eventValue || null };
				return result;
			});
		}
		if (id === 'bankName') {
			setEmployeeDetailState((prevState) => {
				return { ...prevState, [id]: event['routingCode'] };
			});
		}
		if (id === 'nationality') {
			setEmployeeDetailState((prevState) => {
				return { ...prevState, [id]: event };
			});
		}
	};
	const closePopUp = () => {
		setForDisableButton({
			...forDisableButton,
			wpsPersonid: true,
			wpsEstablishmentId: true,
			visible: false,
		});
	};
	const handleCancelPopUp = (value) => {
		setForDisableButton({
			visible: false,
		});
		if (value === 'personalInfoCancel') {
			setEmployeeDetailState({
				...employeeDetailState,

				nationality: employeeDetails?.nationality,
				dob: employeeDetails?.dateOfBirth,
				emailId: employeeDetails?.emailId,
			});
			setForDisableButton({
				nationality: false,
				dob: false,
				emailId: false,
			});
			setShowLeave({ ...showLeave, personal: true });
			setForInfo({ ...forInfo, personalInfo: false });
		} else {
			setEmployeeDetailState({
				...employeeDetailState,
				wpsPersonid: employeeDetails?.wpsPersonid,
				wpsEstablishmentId: employeeDetails?.wpsEstablishmentId,
				labourCardNo: employeeDetails?.labourCardNo,
				iban: employeeDetails?.bankAccountNo,
				bankName: employeeDetails.bankRoutingCode,
			});
			setForDisableButton({
				wpsPersonid: false,
				wpsEstablishmentId: false,
			});
			setShowLeave({ ...showLeave, account: true });
			setForInfo({ ...forInfo, accountInfo: false });
		}
	};
	const employeeId = employeeDetails?.id;
	const handleSuccessPopUp = (value) => {
		if (value === 'personalSuccess') {
			getEmployeeById(employeeId);
			setpopupVisible(false);
			setShowLeave({ ...showLeave, personal: true });
			setForInfo({ ...forInfo, personalInfo: false });
			if (mainProfile) {
				history.push({
					pathname: '/dashboard/profile',
					state: {
						tableData: { employeeId: employeeId },
						mainProfile: true,
					},
				});
			} else {
				history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '1', mainProfile: false } });
			}
		} else {
			getEmployeeById(employeeId);
			setpopupVisible(false);
			setShowLeave({ ...showLeave, account: true });
			setForInfo({ ...forInfo, accountInfo: false });
			if (mainProfile) {
				history.push({
					pathname: '/dashboard/profile',
					state: {
						tableData: { employeeId: employeeId },
						mainProfile: true,
					},
				});
			} else {
				history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '1', mainProfile: false } });
			}
		}
	};
	const forEnable = useMemo(() => forEnablingButton(employeeDetails, employeeDetailState), [employeeDetailState]);
	const bankName = bankOptions?.filter((data, i) => data?.routingCode === employeeDetails?.bankRoutingCode && data.bankName);
	const nationalityName = nationalityData?.filter((data, i) => data?.countryCode === employeeDetails?.nationality);
	const changeBankAccount = () => {
		history.push({ pathname: '/cardholder', state: { employeeId: employeeDetails?.id, employeeName: employeeDetails?.name } });
	};
	const handleEmpToPortalEmail = (value) => {
		setEmpToPortalEmail(value);
	};
	const handleEmpToPortal = () => {
		const payload = {
			phoneNumber: employeeDetails?.mobileNo || '',
			emailId: empToPortalEmail,
			employeeId: employeeDetails?.employeeId,
			userFirstLastName: employeeDetails?.name,
		};
		const data = {
			email: empToPortalEmail,
			emp_Id: employeeDetails?.id,
		};
		if (empToPortalEmail !== '') {
			updateEmailSaga(data);
			inviteEmpToPortalSaga(payload);
		}
	};
	return (
		<>
			{/* <UploadPayrollPopup
				className={`invite_EmpToPortal_PopUp ${empToPortalEmail === '' || !isEmailValid(empToPortalEmail) ? 'invite_emp_disable' : ''}`}
				loading={isInviteLoading}
				visible={emailConformation}
				closePopup={() => {
					conformEmailSaga(false);
				}}
				autoFixEmployeeFileError={handleEmpToPortal}
				cancelText="Cancel"
				okText="Invite"
				message={'Confirm Employee Email'}
				subMessage={
					<ul>
						<li>They will be able to request leaves, download their payslips and read announcements.</li>
						<li>They will not have admin access.</li>
					</ul>
				}
				subMessage1={
					<Col>
						<Input
							type="text"
							labels="Email Address"
							value={empToPortalEmail}
							onChange={(event) => handleEmpToPortalEmail(event?.target?.value)}
							className="forBottomMargin"
						/>
					</Col>
				}
			/> */}
			<PayrollProfilePresentational
				{...{
					handleClick,
					openDeleteModal,
					employeeDetails,
					handleInfo,
					forInfo,
					handleCancel,
					employeeDetailState,
					handleValueChanged,
					forDisableButton,
					updateWPSDetails,
					handleSave,
					onFinish,
					popupVisible,
					popupMessage,
					closePopUp,
					handleCancelPopUp,
					isShowLoader,
					handleSuccessPopUp,
					forEnable,
					accountType,
					bankOptions,
					bankName,
					nationalityData,
					nationalityName,
					payrollclaim,
					wpsForm,
					changeBankAccount,
					showEdit,
					mainProfile,
					conformEmailSaga,
					userNavigationMenu,
				}}
			/>
		</>
	);
}
const mapStateToProps = (state) => {
	return {
		nationalityData: Selectors.nationalityData(state),
		isInviteLoading: Selectors.isInviteLoading(state),
		inviteSuccess: Selectors.inviteSuccess(state),
		emailUpdated: Selectors.emailUpdated(state),
		emailConformation: Selectors.emailConformation(state),
		isEmailUpdateLoading: Selectors.isEmailUpdateLoad(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getNationality: () => dispatch(Actions.creators.getNationalityData()),
		inviteEmpToPortalSaga: (payload) => dispatch(Actions.creators.inviteEmpToPortalStart(payload)),
		updateEmailSaga: (payload) => dispatch(Actions.creators.updateEmailStart(payload)),
		conformEmailSaga: (flag) => dispatch(Actions.creators.conformEmail(flag)),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(PayrollProfileFunctional);
const ReducedScreen = withReducer('counterReducer', reducer, saga)(Connected);

export default ReducedScreen;
