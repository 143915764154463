import React from 'react';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { employeeFaqs } from './constants/mockData';
import ItemHeader from '@pages/help/components/ItemHeader';
import CardItemLink from '@pages/help/components/CardItemLink';
import { SITEMAP } from 'src/routes/sitemap';
import './employee-faqs.scss';
import './employee.scss';

const EmployeeFaqs = () => {
	const history = useHistory();
	return (
		<Col xs={24} sm={24} xl={24} md={24} lg={24} className="employees_container">
			<ItemHeader title="Employees" goback={() => history.push(SITEMAP.help.index)} />
			<CardItemLink items={employeeFaqs} />
		</Col>
	);
};

export default EmployeeFaqs;
