import React from 'react';
import { Row, Col } from 'antd';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';

const NotPaid = () => {
	return (
		<Row className="not_paid_error_container">
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Row className="not_paid_warning_card">
					<Col xl={2} lg={2} md={2} sm={2} xs={2} className="not_paid_error_message_warning">
						<ImageComponent src={icons.focus_state.Warning} />
					</Col>
					<Col xl={22} lg={22} md={22} sm={24} xs={24} className="not_paid_error_message_title">
						<EdenredTypo bold>
							Your Payroll Services Have Been Temporarily Suspended As You Have Not Paid Your Pending Invoices For June 2020.
						</EdenredTypo>
					</Col>
				</Row>
			</Col>
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="not_paid_error_message_advise">
				<EdenredTypo>
					To reactivate your payroll services, please send the proof of invoice payment (Deposit proof or slip/SWIFT Copy etc.) to
					finance@edenred.ae
				</EdenredTypo>
			</Col>
			<Col xl={8} lg={8} md={8} sm={24} xs={24} className="not_paid_error_message_button">
				<EdenredButton>View My Invoices Here</EdenredButton>
			</Col>
		</Row>
	);
};

export default NotPaid;
