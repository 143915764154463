import { getDaysCountByTwoDates } from '@helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';

export const UnemploymentInsuranceShortBanner = () => {
	const { unEmploymentBannerLoading, unEmploymentBannerData } = useSelector((state) => state?.sharedStates);

	return unEmploymentBannerData?.data?.displayBanner ? (
		<div style={{ padding: '40px 40px 0 40px', width: '100%', color: '#00344E', fontWeight: '700' }}>
			{getDaysCountByTwoDates() > 0 ? (
				<div className={`sort-alert-error-box unemployment-insurance-banner error`}>
					<div className="info-icon">
						<img src={icons.focus_state.Warning} alt="warning" />
					</div>
					<div className="info-content">
						<h4 className="">Unemployment Insurance Deadline Approaching Soon!</h4>
						<p>Bulk subscribe before Oct 1st & avoid unnecessary fines for employees</p>
					</div>
					<div className="info-action" style={{ width: '200px' }}>
						<Link
							style={{ color: '#181919' }}
							className="info-action-link"
							to={{
								pathname: `/un-employment-insurance/employee`,
							}}>
							Subscribe Now
							<img src={images?.unempArrow} style={{ paddingLeft: '8px' }} alt="icon" />
						</Link>
					</div>
				</div>
			) : (
				<div className={`sort-alert-info-box unemployment-insurance-banner error`}>
					<div className="info-icon">
						<img src={images.infoCircle} alt="warning" />
					</div>
					<div className="info-content">
						<h4 className="">Bulk Subscribe to Unemployment Insurance</h4>
						<p>Subscribe for all your employees in one place & avoid fines</p>
					</div>
					<div className="info-action" style={{ width: '200px' }}>
						<Link
							style={{ color: '#181919' }}
							className="info-action-link"
							to={{
								pathname: `/un-employment-insurance/employee`,
							}}>
							Subscribe Now
							<img src={images?.unempArrow} style={{ paddingLeft: '8px' }} alt="icon" />
						</Link>
					</div>
				</div>
			)}
		</div>
	) : (
		''
	);
};
