import React from 'react';
import moment from 'moment';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { isOnlySpecialCharacter } from '@helpers';
import { PROFILE_ERROR_MESSEAGE } from '@constants/app-constants';
import './account-details-presentational.scss';

function Accountdetails({ employeeDetails, employeeDetailState, handleValueChanged, bankOptions, type, dropDownValue, compareDropDownValue }) {
	const personId = isOnlySpecialCharacter(employeeDetailState?.wpsPersonid);
	const establishmentId = isOnlySpecialCharacter(employeeDetailState?.wpsEstablishmentId);
	const labourCard = isOnlySpecialCharacter(employeeDetails?.labourCardNo);

	const profileSwitchRender = (type) => {
		switch (type) {
			case 'iban':
				return (
					<Inputbox
						value={employeeDetailState.iban}
						onChange={(event) => handleValueChanged('iban', event)}
						isErrorMessage={employeeDetailState?.iban?.length > 34}
						errorMessage={PROFILE_ERROR_MESSEAGE?.IBANNO}
					/>
				);
			case 'bankName':
				return (
					<MultiSelect
						onChange={(event) => handleValueChanged('bankName', event)}
						value={employeeDetails?.bankRoutingCode}
						placeholder="Bank Name"
						Option={bankOptions}
						disabled={employeeDetails?.isInactivatedforDeletion}
						{...(dropDownValue && compareDropDownValue
							? {
									dropDownValue: dropDownValue,
									compareDropDownValue: compareDropDownValue,
							  }
							: {})}
					/>
				);
			case 'cardSerialNumber':
				return <Inputbox value={employeeDetailState?.cardSerialNumber} disabled={true} />;
			case 'cardIssues':
				return <Inputbox value={moment(employeeDetailState?.cardIssued).format('DD/MM/YYYY')} disabled={true} className="forBottomMargin" />;
			case 'accountNumber':
				return <Inputbox value={employeeDetailState?.accountNo} disabled={true} />;
			case 'wpsPersonId':
				return (
					<Inputbox
						onChange={(event) => handleValueChanged('wpsPersonid', event)}
						value={employeeDetailState?.wpsPersonid}
						disabled={employeeDetails?.isInactivatedforDeletion}
						className="forBottomMargin"
						isErrorMessage={employeeDetailState?.wpsPersonid && (employeeDetailState?.wpsPersonid?.length !== 14 || !personId)}
						errorMessage={!personId ? PROFILE_ERROR_MESSEAGE?.ONLY_ALPHANUMERIC : PROFILE_ERROR_MESSEAGE?.PERSONID}
					/>
				);
			case 'wpsEstablishmentId':
				return (
					<Inputbox
						value={employeeDetailState?.wpsEstablishmentId}
						disabled={employeeDetails?.isInactivatedforDeletion}
						onChange={(event) => handleValueChanged('wpsEstablishmentId', event)}
						isErrorMessage={
							employeeDetailState?.wpsEstablishmentId && (employeeDetailState?.wpsEstablishmentId?.length !== 13 || !establishmentId)
						}
						errorMessage={!establishmentId ? PROFILE_ERROR_MESSEAGE?.ONLY_ALPHANUMERIC : PROFILE_ERROR_MESSEAGE?.ESTABLISHID}
					/>
				);
			case 'labourCardNumber':
				return (
					<Inputbox
						type="number"
						maxLength="9"
						value={employeeDetailState?.labourCardNo}
						disabled={employeeDetails?.isInactivatedforDeletion}
						onChange={(event) => handleValueChanged('labourCardNo', event)}
						isErrorMessage={
							(employeeDetailState?.labourCardNo && employeeDetailState?.labourCardNo?.length < 5) ||
							employeeDetailState?.labourCardNo?.length > 9
						}
						errorMessage={PROFILE_ERROR_MESSEAGE?.LABOURCARD}
					/>
				);

			default:
				return <></>;
		}
	};
	return <div>{profileSwitchRender(type)}</div>;
}

export default Accountdetails;
