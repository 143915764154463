import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { APP_VARIABLES, INVITE_TEAMS_CONSTANTS } from '@constants/app-constants';
import { emailCheck } from '@helpers';
import UserService from 'src/services/user-service';
import Popup from 'src/utils/popup/popups';
import EmployeeService from 'src/services/employee-service';
import action from '../action';
import selectors from '../selectors';
import InviteTeamMemberPresentational from './invite-team-member-presentional';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

const InviteTeamMemberFunctional = ({ isEmployeesLoading, getEmployeeUserStart, isemployeeUserExists, issetup = false }) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	let UserDuplicateApi = new UserService();
	const [loading, setloading] = useState(false);
	const [userLoading, setUserloading] = useState(false);
	const [userClaims, setUserClaims] = useState([]);
	const [employeeDetail, setEmployeeDetail] = useState({});
	const [isSelect, setIsSelect] = useState({
		existing: false,
		new: true,
	});
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});

	const [newAdmin, setNewAdmin] = useState({
		name: '',
		username: '',
		email: '',
	});
	const [existing, setExisting] = useState({
		name: '',
		username: '',
		checkEmail: '',
		email: '',
		emp_Id: '',
		emp_Code: '',
	});
	const userAPI = new UserService();
	let employeeApi = new EmployeeService();

	// To disable submit button at the beginning.
	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const handleClick = (type, values) => {
		if (type === INVITE_TEAMS_CONSTANTS.NEXT_BUTTON) {
			if (values?.new) {
				setloading(true);
				UserDuplicateApi.getDuplicatestatusApi(newAdmin?.username)
					.then((res) => {
						setloading(false);
						if (res.data !== true) {
							history.push({
								pathname: issetup ? '/setupnewpermission' : '/permission/user',
								state: { value: newAdmin, param: 'new' },
							});
						} else {
							setpopupVisible(true);
							setPopupMessage({
								titleMessage: 'Username Already exits',
								subMessage: null,
							});
						}
					})
					.catch((error) => {
						console.error(error);
					});
			} else if (values?.existing) {
				const { hrClaims, payrollClaims, teamClaims } = userClaims;
				const isExiting = hrClaims !== null || payrollClaims !== null || teamClaims !== null;
				if (isemployeeUserExists || isExiting) {
					history.push({
						pathname: issetup ? '/permissionassign/' + employeeDetail?.userId : '/user_role/' + employeeDetail?.userId,
						state: existing,
						onlyEmployee: isemployeeUserExists,
					});
				} else {
					history.push({
						pathname: issetup ? '/setupnewpermission' : '/permission/user',
						state: { value: existing, param: 'exisiting' },
					});
				}
			}
		} else {
			history.push(issetup ? '/teamspermission' : '/manage_team');
		}
	};

	const closePopup = () => setpopupVisible(false);

	const handleSelect = (value) => {
		if (value === 'existing') {
			setIsSelect({ ...isSelect, existing: true, new: false });
		} else {
			setIsSelect({ ...isSelect, existing: false, new: true });
		}
	};

	const hangleDropChange = (event, value) => {
		if (value === 'name') {
			const emp_name = event?.employeeName;
			const emp_Id = event?.employeeId;
			const emp_code = event?.employeeCode;
			let emp_email = event?.emailId;
			emp_email = emp_email !== 'null' ? emp_email : '';
			setExisting({
				...existing,
				name: emp_name,
				username: corporateId + '-' + emp_code,
				checkEmail: emp_email,
				email: emp_email,
				emp_Id: emp_Id,
				emp_Code: emp_code,
			});
			getEmployeeUserStart(emp_code);
			getEmployeeById(emp_Id);
		} else {
			setExisting({ ...existing, email: event });
		}
	};
	const getEmployeeById = async (emp_Id) => {
		setUserloading(true);
		try {
			let response = await employeeApi.getEmployeeById(emp_Id);
			if (response && response?.data) {
				setUserloading(false);
				setEmployeeDetail(response?.data?.employee);
				getUserClaims(response?.data?.employee?.userId);
			} else {
				setUserloading(false);
			}
		} catch (err) {
			setUserloading(false);
		}
	};

	const getUserClaims = (username) => {
		userAPI
			.getUserClaims(username)
			.then((response) => {
				setloading(false);
				setUserClaims(response.data);
			})
			.catch((error) => {
				setloading(false);
			});
	};
	const handleNewAdmin = (event, value) => {
		setNewAdmin({ ...newAdmin, [value]: event });
	};

	const newDisable = useMemo(() => !newAdmin?.name || !newAdmin?.username || !newAdmin?.email || !emailCheck?.test(newAdmin?.email), [newAdmin]);
	const exDisable = useMemo(
		() => !existing?.name || (isemployeeUserExists && !existing?.email) || !emailCheck?.test(existing?.email),
		[existing, userClaims]
	);
	return (
		<>
			<Edenredmodal
				{...{ isOpen: popupVisible, textcenter: true, title: popupMessage?.titleMessage, onOkay: closePopup, onOkayButton: 'OK' }}
			/>
			<InviteTeamMemberPresentational
				{...{
					handleClick,
					form,
					loading,
					handleSelect,
					isSelect,
					existing,
					hangleDropChange,
					handleNewAdmin,
					isemployeeUserExists,
					isEmployeesLoading,
					newDisable,
					exDisable,
					newAdmin,
					issetup,
					userLoading,
				}}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		isEmployeesLoading: selectors.isEmployeesLoading(state),
		isEmployeeUserLoading: selectors.isEmployeeUserLoading(state),
		isemployeeUserExists: selectors.employeeUserDetail(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		employeesSaga: () => dispatch(action.creators.getEmployeesStart()),
		getEmployeeUserStart: (payload) => dispatch(action.creators.getEmployeeUserStart(payload)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(InviteTeamMemberFunctional);

export default Connected;
