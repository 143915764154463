const GET_PER_EMPLOYEE_START = 'GET_PER_EMPLOYEE_START';
const GET_PER_EMPLOYEE_SUCCESS = 'GET_PER_EMPLOYEE_SUCCESS';
const GET_PER_EMPLOYEE_FAIL = 'GET_PER_EMPLOYEE_FAIL';

const getPerEmployeeStart = () => {
	return {
		type: GET_PER_EMPLOYEE_START,
	};
};
const getPerEmployeeSuccess = (response) => {
	return {
		type: GET_PER_EMPLOYEE_SUCCESS,
		response,
	};
};
const getPerEmployeeFail = (error) => {
	return {
		type: GET_PER_EMPLOYEE_FAIL,
		error,
	};
};

export default {
	types: {
		GET_PER_EMPLOYEE_START,
		GET_PER_EMPLOYEE_SUCCESS,
		GET_PER_EMPLOYEE_FAIL,
	},
	creators: {
		getPerEmployeeStart,
		getPerEmployeeSuccess,
		getPerEmployeeFail,
	},
};
