import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeClaims, payrollClaimCheck } from '@constants/app-constants';
import action from '@pages/Leave-Management/leaves-status/Component/employeeHistorySaga/action';
import EmployeeService from 'src/services/employee-service';
import HomePresentational from './home-presentational';
import EwaPopup from '@pages/dashboard/main-dashboard/components/ewaPopup';

const HomeFunctional = () => {
	const dispatch = useDispatch();
	const [employeeDetails, setEmployeeDetails] = useState({});
	const [leaveTypelist, setLeaveTypelist] = useState([]);
	const [sampleData, setSampleData] = useState([]);
	const PayrollClaims = useSelector((state) => state?.sharedStates?.currentUser?.payrollClaims) || [];
	const hrClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims) || [];
	const employeeClaims = useSelector((state) => state?.sharedStates?.currentUser?.employeeClaims) || [];
	const emp_Id = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeCode) || '';
	const roles = useSelector((state) => state?.sharedStates?.currentUser?.roles) || [];
	let employeeApi = new EmployeeService();
	let payrollClaims = useSelector((state) => state?.sharedStates?.currentUser?.payrollClaims);
	const nPayrollClaims = [];
	let newClaims = payrollClaims?.map((item) => {
		if (payrollClaimCheck?.includes(item?.value)) {
			nPayrollClaims.push(item);
		}
	});
	useEffect(() => {
		emp_Id && getEmployeeById(emp_Id);
	}, []);
	async function getEmployeeById(emp_Id) {
		try {
			let response = await employeeApi.getEmployeeById(emp_Id);
			if (response && response?.data) {
				let finalResp = { ...response?.data?.employee };
				setEmployeeDetails(finalResp);
			} else {
				setEmployeeDetails({});
			}
		} catch (err) {
			setEmployeeDetails({});
		}
	}
	const nEmpClaims = [];
	let newclaim = employeeClaims?.map((item) => {
		if (EmployeeClaims.includes(item?.value)) {
			nEmpClaims.push(item);
		}
	});

	const nHrClaims = [];
	let newHrclaim = hrClaims?.map((item) => {
		if (item?.value === 'Manage.Leaves') {
			nHrClaims.push(item);
		}
	});
	const handelSaveChanges = () => {
		const payload = sampleData?.map((dta) => {
			return {
				leaveBalanceId: dta?.LeaveBalanceId || '',
				leaveTypeId: dta?.LeaveTypeId,
				leaveTypeName: dta?.leaveTypeName,
				balance: dta?.Days?.replace(/[^0-9-]/g, ''),
			};
		});
		dispatch(action.creators.saveLeaveTypeStart({ employeeId: employeeDetails?.employeeId, employeeLeaveBalance: payload }));
		getEmployeeById(employeeDetails?.id);
	};
	const isLeaveEdited = useMemo(() => JSON.stringify(sampleData) === JSON.stringify(leaveTypelist), [sampleData]);
	return (
		<>
			<EwaPopup />
			<HomePresentational
				{...{
					PayrollClaims,
					hrClaims,
					employeeClaims,
					nEmpClaims,
					nHrClaims,
					employeeDetails,
					leaveTypelist,
					setLeaveTypelist,
					sampleData,
					setSampleData,
					isLeaveEdited,
					handelSaveChanges,
					roles,
				}}
			/>
		</>
	);
};

export default HomeFunctional;
