import { createSelector } from 'reselect';

import { initState } from './reducer';

const requestLeavesReducer = (state) => state.requestLeavesReducer || initState;
const employeeListLoading = createSelector(requestLeavesReducer, (cnter) => cnter.employeesListLoading);
const employeeList = createSelector(requestLeavesReducer, (cnter) => cnter.employeeLists);
const employeeListError = createSelector(requestLeavesReducer, (cnter) => cnter.employeesListError);
const leaveTypeLoading = createSelector(requestLeavesReducer, (cnter) => cnter.leaveTypeLoading);
const leaveType = createSelector(requestLeavesReducer, (cnter) => cnter.leaveType);
const leaveTypeError = createSelector(requestLeavesReducer, (cnter) => cnter.leaveTypeError);
const overLappingLoading = createSelector(requestLeavesReducer, (cnter) => cnter.overLappingLoading);
const overLappingLists = createSelector(requestLeavesReducer, (cnter) => cnter.overLappingLists);
const overLappingError = createSelector(requestLeavesReducer, (cnter) => cnter.overLappingError);
const leaveBalanceLoading = createSelector(requestLeavesReducer, (cnter) => cnter.leaveBalanceLoading);
const leaveBalanceLists = createSelector(requestLeavesReducer, (cnter) => cnter.leaveBalanceLists);
const leaveBalanceError = createSelector(requestLeavesReducer, (cnter) => cnter.leaveBalanceError);
const documentValidLoading = createSelector(requestLeavesReducer, (cnter) => cnter.documentValidLoading);
const documentValidLists = createSelector(requestLeavesReducer, (cnter) => cnter.documentValidLists);
const documentValidError = createSelector(requestLeavesReducer, (cnter) => cnter.documentValidError);
const createLoading = createSelector(requestLeavesReducer, (cnter) => cnter.createLoading);
const createLists = createSelector(requestLeavesReducer, (cnter) => cnter.createLists);
const isCreateSaved = createSelector(requestLeavesReducer, (cnter) => cnter.isCreateSaved);
const createError = createSelector(requestLeavesReducer, (cnter) => cnter.createError);
const showleaveConfirmFlag = createSelector(requestLeavesReducer, (cnter) => cnter.showleaveConfirmFlag);
const leaveComfirmPopupFlag = createSelector(requestLeavesReducer, (cnter) => cnter.leaveComfirmPopupFlag);
const leaveValidLoading = createSelector(requestLeavesReducer, (cnter) => cnter.leaveValidLoading);
const leaveValid = createSelector(requestLeavesReducer, (cnter) => cnter.leaveValid);
const leaveValidError = createSelector(requestLeavesReducer, (cnter) => cnter.leaveValidError);
const leavePopupMsg = createSelector(requestLeavesReducer, (cnter) => cnter.leavePopupMsg);
const departmentLeaveLoading = createSelector(requestLeavesReducer, (cnter) => cnter.departmentLeaveLoading);
const departmentLeave = createSelector(requestLeavesReducer, (cnter) => cnter.departmentLeave);
const departmentLeaveError = createSelector(requestLeavesReducer, (cnter) => cnter.departmentLeaveError);

export default {
	requestLeavesReducer,
	employeeListLoading,
	employeeList,
	employeeListError,
	leaveTypeLoading,
	leaveType,
	leaveTypeError,
	overLappingLoading,
	overLappingLists,
	overLappingError,
	leaveBalanceLoading,
	leaveBalanceLists,
	leaveBalanceError,
	documentValidLoading,
	documentValidLists,
	documentValidError,
	createLoading,
	createLists,
	isCreateSaved,
	createError,
	showleaveConfirmFlag,
	leaveComfirmPopupFlag,
	leaveValidLoading,
	leaveValid,
	leaveValidError,
	departmentLeaveLoading,
	departmentLeave,
	departmentLeaveError,
	leavePopupMsg,
};
