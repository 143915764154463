import { images } from 'src/assets/img';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { MESSAGES } from './../utils/toast-messages';

export const APP_VARIABLES = {
	ACCESS_TOKEN: 'ACCESS_TOKEN',
	CORPORATE_ID: 'cid',
	PROD_SUBSCRIPTIONS: 'subscriptionProds',
	CORPORATE_NAME: 'cName',
	ID_TOKEN: 'id_token',
	REDIRECT_TO: 'REDIRECT_TO',
	USER_DETAILS: 'USER_DETAILS',
};
export const SETUP_REDIRECT = {
	SETUP_REDIRECT_TO: 'setupRedirectTo',
};

export const EMP_MOCK = {
	data: [
		{
			id: '4a51bb73-f084-4a1c-bcc1-01f0df7bb7fe',
			corporateId: 'bc9b9890-be4d-412f-8869-21eb9abea4a8',
			corporate: null,
			employeeId: '625115ea-92cd-4910-a328-3af9980f34b1',
			employee: {
				id: '625115ea-92cd-4910-a328-3af9980f34b1',
				employeeId: 'SL164',
				corporateId: 'bc9b9890-be4d-412f-8869-21eb9abea4a8',
				corporate: null,
				firstName: 'AJAYKUMAR LAXMINARAYAN',
				middleName: '',
				lastName: 'AGRAWAL',
				dateOfBirth: '2020-11-20T14:13:17.1033333',
				mobileNo: null,
				cardNo: '533605*****',
				cardStatus: 'L',
				salaryChannel: 'NonWPSC3',
				isDeleted: false,
				recordStatus: null,
				wpsPersonid: '',
				wpsEstablishmentId: '0000000191891',
				labourCardNo: null,
				emiratedId: null,
				emiratedIdExpiryDate: null,
				passportNumber: null,
				nationality: null,
				frozen: '',
				blockStatus: '',
				rhfreceivedDate: '2020-11-28T06:26:34.76',
				isInactivatedforDeletion: null,
				bankAccountNo: null,
				bankRoutingCode: null,
				routing: null,
				isDeactivated: null,
				uploadId: null,
				createdBy: 'c1a08d99-269f-402e-86c3-15c84ab7b20f',
				createdDate: '2020-11-04T09:14:36.6533333',
				modifiedBy: 'c1a08d99-269f-402e-86c3-15c84ab7b20f',
				modifiedDate: '2020-11-04T09:14:36.6533333',
				employeeSalaryStagings: [],
				employeeSalaries: null,
				isWps: true,
				payrollChannelDescription: null,
				accountTypeDescription: null,
				emiratesIdStatus: 'Expired',
				name: 'AJAYKUMAR LAXMINARAYAN  AGRAWAL',
			},
			month: 10,
			year: 2020,
			salary: 300,
			variablePay: 0,
			daysOnLeave: 0,
			isEndOfService: true,
			createdBy: '1485401b-10d5-4417-812c-6947af45ffb2',
			createdDate: '2020-11-30T13:23:06.4481554+05:30',
			yearMonth: 202010,
		},
	],
	isSuccessful: true,
	errorMessage: null,
};
export const domain = {
	// url: 'http://cors-anywhere.herokuapp.com/http://a3812be774a6.ngrok.io',
	url: 'http://0bc10fdf1706.ngrok.io',
	// url: 'http://b64559137036.ngrok.io',
};

// export const VERSION = 1;

// // export const CORPORATE_ID = '39ea8285-d067-4243-82ea-1888288bafe9';
export const CORPORATE_ID = '8b78d278-7474-42cd-8e8b-73c63ab9fc0f';

// // export const DOMAIN_URL = `https://cors-anywhere.herokuapp.com/https://eae-c3portal-web-d.azurewebsites.net`;
// export const DOMAIN_URL = `https://eae-c3portal-web-d.azurewebsites.net`;

// export const ACCESS_TOKEN = `FFIU-VG8lrUIkhMXMNSAsBhaiJ1fOAfzWrTGsApJOTg`;
// export const VERSION = 1;
export const VERSION = process.env.REACT_APP_API_VERSION ? process.env.REACT_APP_API_VERSION : 1;

// export const DOMAIN_URL = `https://eae-c3portal-web-d.azurewebsites.net/api/v${VERSION}`;
export const DOMAIN_URL = `${process.env.REACT_APP_API_URL}/v${VERSION}`;

export const user = {
	name: 'sathish',
	role: 'Verifier',
	// name: 'naveen',
	// role: 'Verifier',
};

export const USER_ROLE = {
	USER: 'User',
	VERIFIER: 'Verifier',
};

export const EMITTER_CONSTANT = {
	SAVE_FILE: 'SAVE_FILE',
};

export const FILE_TEMPLATES = {
	EMPLOYEE_FILE: 'EMPLOYEE_FILE',
};

export const MONTH_OBJ = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

export const CLEVER_TAP_EVENTS = {
	NextSteps_TransferFunds: 'NextSteps_TransferFunds',
	NextSteps_UploadProof: 'NextSteps_UploadProof',
	NextSteps_TrackPayout: 'NextSteps_TrackPayout',
	Uploadexcel_PayrollTable: 'Uploadexcel_PayrollTable',
	Next_Uploadexcel: 'Next_Uploadexcel',
	SendInvite_InviteTeamMember: 'SendInvite_InviteTeamMember',
	Next_Permissions: 'Next_Permissions',
	ApproveSelected_Payroll: 'ApproveSelected_Payroll',
	HealthInsurance: 'HealthInsurance',
	StayUpdated_HealthInsurance: 'StayUpdated_HealthInsurance',
	repeatpreviouspayroll: 'repeatpreviouspayroll',
	repeat: 'repeat',
};

export const API_DOMAIN = 'https://eae-c3portalhr-web-d.azurewebsites.net';

export const expiryDateOptions = [
	{
		name: 'Expired',
		value: 'expired',
	},
	{
		name: 'in 1 Month',
		value: 'in1month',
	},
	{
		name: 'in 3 Months',
		value: 'in3months',
	},
	{
		name: 'in 6 Months',
		value: 'in6months',
	},
	{
		name: 'Missing Dates',
		value: 'missingdates',
	},
];

export const documentTypeOptions = [
	{
		name: 'Labour Card',
		value: 'labourcard',
	},
	{
		name: 'Emirates ID',
		value: 'emiratesid',
	},
	{
		name: 'Visa',
		value: 'visa',
	},
	{
		name: 'Passport',
		value: 'passport',
	},
];

export const hrFilterData = [
	{
		filterLabel: 'Expiry Date',
		count: 0,
		filterValue: [
			{
				filterValue: 'Expired',
				filterParam: 'expired',
			},
			{
				filterValue: 'in 1 Month',
				filterParam: 'in1month',
			},
			{
				filterValue: 'in 3 Months',
				filterParam: 'in3months',
			},
			{
				filterValue: 'in 6 Months',
				filterParam: 'in6months',
			},
			{
				filterValue: 'Missing Dates',
				filterParam: 'missingdates',
			},
		],
	},
	{
		filterLabel: 'Document Type',
		count: 0,
		filterValue: [
			{
				filterValue: 'Labour Card',
				filterParam: 'labourcard',
			},
			{
				filterValue: 'Emirates ID',
				filterParam: 'emiratesid',
			},
			{
				filterValue: 'Visa',
				filterParam: 'visa',
			},
			{
				filterValue: 'Passport',
				filterParam: 'passport',
			},
		],
	},
	{
		filterLabel: 'Document Status',
		count: 0,
		filterValue: [
			{
				filterValue: 'Expired Documents',
				filterParam: 'expired',
			},
			{
				filterValue: 'Expiring Soon',
				filterParam: 'in6months',
			},
			{
				filterValue: 'Missing Dates',
				filterParam: 'missingdates',
			},
			{
				filterValue: 'Up To Date',
				filterParam: 'uptodate',
			},
		],
	},
];

export const monthWise = ['in1month', 'in3months', 'in6months'];

export const DOC_TYPES = [
	{
		name: 'PDF File',
		value: 'pdf',
		image: focus_state_icons.hrExport,
		loading: false,
	},
	{
		name: 'Excel File',
		value: 'excel',
		image: focus_state_icons.hrExport,
		loading: false,
	},
];
export const teamsFilter = [
	{
		filterLabel: 'Roles',
		count: 0,
		filterValue: [
			{
				filterValue: 'Super Admin',
				value: 'superadmin',
			},
			{
				filterValue: 'HR',
				value: 'hr',
			},
			{
				filterValue: 'Payroll',
				value: 'payroll',
			},
			{
				filterValue: 'Roles',
				value: 'manage roles',
			},
		],
	},
];
export const DATE_FORMAT = {
	YYYY_MM_DD: 'YYYY-MM-DD',
	DD_MM_YYYY: 'DD/MM/YYYY',
	MMM_YYYY: 'MMM YYYY',
};

// run payroll dropdown constant
export const RUN_PAYROLL_DROPDOWN = {
	PAYROLL_CHANNEL: 'Payroll Channel',
	ACCOUNT_TYPE: 'Account Type',
	ESTABLISHMENT_ID: 'Establishment ID',
	BANK_NAME: 'Bank Name',
};
//leave to approve constants
export const DATE_FORMAT_LEAVE = {
	MM_DD: 'MMM DD',
	YYYY_MM_DD: 'YYYY-MM-DD',
	DD_MM_YYYY: DATE_FORMAT?.DD_MM_YYYY,
	DD_MM_YYYY_H_M_S: 'YYYY-MM-DDThh:mm:ss',
};

export const checkLeaveTypeArray = ['Sick', 'Parental Leave', 'Compassionate', 'Compensatory'];

export const checkBalanceLeaveTypeArray = ['Unpaid', 'Umrah', 'Compassionate'];

export const checkCompensatory = ['Compensatory'];

export const checkOverlappingleaves = ['Sick', 'Compensatory'];

export const checkApprovePopup = ['Umrah'];

//leave ro approve constants ends
export const popUpMessage = {
	saveMessage: 'Do you want to submit this leave request?',
	cancelMessage: 'Do you want to leave this page? Your changes will not be saved',
	successMessage: 'Leave Request Submitted Successfully',
};
export const popUpNotification = {
	saveMessage: 'Do you want to submit?',
	subSaveMessage: 'Changes will be saved.',
	cancelMessage: 'Do you want to cancel?',
	subCancelMessage: 'Change will not be saved.',
	successMessage: 'Saved Successfully',
};
export const saveButton = [
	{
		name: 'Cancel',
		value: 'cancel',
	},
	{
		name: 'Save',
		value: 'save',
	},
];
export const saveLeaveButton = [
	{
		name: 'Cancel',
		value: 'cancel',
	},
	{
		name: 'Yes, Submit',
		value: 'save',
	},
];
export const dates = [
	{
		placeholder: 'From',
		dates: 'startDate',
	},
	{
		placeholder: 'To',
		dates: 'endDate',
	},
];

// Notification constant
export const NOTIFICATION_TYPE = {
	WARNING: 'warning',
};
export const NOTIFICATION_POSITION = {
	TOP_RIGHT: 'topRight',
	BOTTOM_RIGHT: 'bottomRight',
};

// Download doc constants
export const DOCUMENT_DOWNLOAD_CONSTANT = {
	Warning: 'Warning',
	PleaseSelect: 'Please select a Expiry date or Document type.',
};
export const radioButton = [
	{
		name: 'Limited',
		id: 1,
		value: 'limited',
		img: not_focus_icons.EmptyCircle,
		clicked: not_focus_icons.UpToDate,
	},
	{
		name: 'UnLimited',
		id: 2,
		value: 'unimited',
		img: not_focus_icons.EmptyCircle,
		clicked: not_focus_icons.UpToDate,
	},
];

export const leaveNotAppilable = ['annual', 'compensatory', 'umrah', 'paternal', 'maternity', 'hajj', 'compassionate'];

export const leaveClaimCheck = ['Manage.Leaves', 'Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances'];

export const leaveApproveClaim = ['Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances'];

export const documentClaimCheck = ['Manage.Documents'];

export const announcementClaimCheck = ['Manage.Announcements'];

export const EmployeeClaims = ['Request.Leave', 'View.Payslip', 'View.Announcement'];

export const payrollClaimCheck = ['Manage.Payroll', 'Manage.Employees'];

export const payrollAllClaimCheck = [
	'Manage.Payroll',
	'Manage.Employees',
	'Approve.Card.Replacement',
	'Approve.Card.Cancellation',
	'Approve.Card.Orders',
	'Approve.Payroll.Requests',
];
export const payrollRunClaimCheck = ['Manage.Payroll', 'Approve.Payroll.Requests'];
export const managePayrollClaim = ['Manage.Payroll'];
/**
 * Added to test commit for build issue.
 * TODO:: We Should remove this line after issue fix.
 */

export const BUILD_TEST = 'Build test';

//Ends
export const setupClaims = {
	MANAGE_PAYROLL: 'Manage.Payroll',
	MANAGE_EMPLOYEES: 'Manage.Employees',
	MANAGE_DOCUMENTS: 'Manage.Documents',
	MANAGE_LEAVES: 'Manage.Leaves',
	MANAGE_ROLES: 'Manage.Roles.And.Permissions',
};
export const roleNames = {
	EMPLOYEE: 'Employee',
	HR: 'HR',
	PAYROLL: 'Payroll',
	MANAGE_ROLES: 'Manage Roles',
	SUPER_ADMIN: 'SuperAdmin',
};
export const INVITE_TEAMS_CONSTANTS = {
	TITLE: 'Add New Admin',
	DESCRIPTION: 'If the employee is not in the list of employees, you can add them by selecting',
	CANCEL_BUTTON: 'Cancel',
	NEXT_BUTTON: 'Next',
	ADD_ADMIN_BUTTON: 'Add New Admin',
	REMOVE_ADMIN: 'Remove Admin',
	SAVE_CHANGE: 'Save Changes',
	SUBMIT: 'Submit',
	EDIT_ROLE_SAVED: 'Changes saved successfully',
	NEW_ROLE_SAVED: 'Roles & Permissions Successfully Added',
};
export const LEAVE_SUBMIT = {
	LEAVES_SUBMITTED: 'Leaves Submitted Successfully',
};

export const DEFAULT_TEXT = {
	YES: 'YES',
};

export const LEAVES_CONSTANTS = {
	LEAVE_VALID: 'Department Not Configured for the Employee',
};
export const APPROVE_WORKFLOW_CONSTANT = {
	APPROVE_WORKFLOW_HINT:
		'Setup the Leave approval workflow by assigning managers to each department. To be able to assign managers on the approval workflow, you can give the "Manage Leaves" permission',
	MANAGE_ROLES_POPUP_BUTTON: 'ok, got it',
	MANAGE_ROLE_POPUP_MESSAGE:
		'You currently do not have permission to do this.To get access, please ask the superAdmin to give you the "Roles and Permission" Permission.',
};

export const EMPLOYEE_POPUP_CONSTANT = {
	CARD_REMOVE_TITLE: 'Do you want to remove this employee?',
	CARD_REMOVE_MSG_WEB: 'Their card will be deleted and you can no longer run payroll for them',
	CARD_REMOVE_MSG_MOB: 'Their card will be deleted and you can no longer run payroll for them',
	EMAIL_CONFORM_TEXT: 'Confirm Employee Email',
	EMAIL_ENTER_TEXT: 'Enter Employee Email',
	EMAILE_DESC_1: 'They will be able to request leaves, download their payslips and read announcements.',
	EMAIL_DESC_2: 'They will not have admin access.',
	REPLACE_CARD: 'Do you want to request a replacement card for',
	REPLACE_CARD_ADD: 'The replacement card will be delivered to your office address:',
	INVOICE: 'Charges will be added to your invoice.',
};

export const DASH_BOARD_CONSTANT = {
	APPROVER_CONTENT: 'As a super admin, you can choose what actions of the user account you wish to verify.',
	WPS_PERCENTAGE: 'For WPS compliance pay 90% of your employees before 15th of next month.',
};

export const SAVE_CANCEL_MSG = {
	CANCEL_MSG: 'Are you sure you want to cancel?',
	CANCEL_DESC: 'Changes will not be saved',
};

export const HR_ALLOWED_FILETYPES = ['sif', 'xls', 'xlsx', 'paf', 'xlx'];

export const HR_SETUP_UPLOAD = ['Download the documents spreadsheet', "Fill in your employees' document details and upload the spreadsheet below."];

export const SETUP_FINAL = {
	COMPLETED: 'Your data is ready to be used in our platform. You’ll be able to update your data from dashboard.',
	NOT_COMPLETED:
		'We encourage you to complete your Setup to enjoy the new products. In case you want to skip for now you’ll be able to update your data from dashboard.',
	NEW_CORPORATE: 'You’re joining over 6,000 clients who trust Edenred to manage their Payroll and HR.',
	FROM_NAVBAR: 'You’ve got “X” days remaining in your free trial to experience new features on out portal!',
	FROM_LOGIN: 'You’ve got one month free to experience the below brand new features on our portal	',
	SETUP_FINAL_DESC:
		"You can now save time managing your employees' leaves, documents, payslips and send communication messages to them through Edenred.",
};

export const VERIFIER_INFO = {
	VERIFIER: "Disabling any of these actions would mean that your user can directly perform them WITHOUT a verifier's approval.",
};

export const LEAVE_APPROVE = {
	APPROVE: 'Do you want to approve this leave?',
	REJECT: 'Do you want to reject this leave?',
};

export const DATE_VALID_MSG = {
	GREATER_THAN_FROM: '"To" date should be greater than "From" Date',
	GREATER_THAN_JOIN: 'The leave date has to be after the joining date',
};

export const PROFILE_ERROR_MESSEAGE = {
	ONLY_ALPHANUMERIC: 'Special Characters not allowed only alphanumeric',
	PERSONID: 'Person ID should be 14 digit long',
	ESTABLISHID: 'Establishment ID must be minimum 13 digit',
	IBANNO: 'IBAN number must not exceed 34 digits',
	LABOURCARD: 'The length of Labour Card Number should be  between 5 to 9 digit.',
	LABOUR_CARD_ALPHANUMERIC: 'Re-enter the Labour Card  Number using only alphanumeric characters.',
};

export const ALERT_POPUP = {
	LEAVE_MSG: 'Do you want to leave this page? Your changes will not be saved.',
	LEAVE_OK: 'Yes, I want to leave',
};
export const PAYROLL_PLUS = {
	TOOLTIPMESSAGE: 'To edit this field, upload individual contract details in the employees profile or in bulk from Setup on the left menu',
};
export const PAYROLL_PROCESSED_DATA = {
	TOOLTIPMESSAGE: 'you already processed payroll, you cannot do it again for this month',
};
export const SHOW_ONLY_IN_DEV = 'SHOW_ONLY_IN_DEV';
export const DEFAULT_SHOW = 'DEFAULT_SHOW';
export const ADD_INDIVIDUAL_EMP = {
	PERSONALDETAILS: 'Personal Details',
	ORDERC3PAY: 'Do you want to order a C3Pay Card for this employee?',
	SELECTNO: 'Please select “No” only if the employee has a bank account that you will transfer salaries to.',
	C3PAY: 'c3pay',
	BANK: 'bank',
	EMPLOYEE_INFO: 'employeeInfo',
	LIMITED: 'Limited',
	UNLIMITED: 'UnLimited',
};

/**
 * To display invoice menu for particular corporates only
 * Handled in backend.
 * TODO - It should remove in feature
 */
export const invoiceMenuToShow = [
	'12121',
	'12316',
	'9999',
	'99998',
	'99999',
	'32180',
	'13602',
	'13608',
	'13612',
	'13288',
	'33054',
	'12148',
	'33157',
	'32145',
	'51270',
	'33637',
]; //UAT
// export const invoiceMenuToShow = ['99998']; //Prod

export const EMPLOYEE_ERROR_KEY = {
	COLUMN_NAME: 'columnName',
	DOB: 'DOB',
	MOBILE_NUMBER: 'MobileNumber',
	JOINING_DATE: 'JoiningDate',
	DEPARTMENT: 'Department',
	DESIGNATION: 'Designation',
	EMP_ID: 'EmployeeId',
	WPS_PERSON_ID: 'WPSPersonId',
	WPS_ESTABLISH_ID: 'EstablishmentId',
	LABOURCARD_NO: 'LabourCardNo',
	EMIRATES_ID: 'EmiratesId',
	PASSPORT_NO: 'PassportNumber',
};
export const EMPLOYEE_DETAILS_KEY = {
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	DOB: 'dob',
	NATIONALITY: 'nationality',
	MOBILE: 'mobile',
	EMAIL: 'email',
	JOINING_DATE: 'joiningDate',
	END_DATE: 'endDate',
	DEPARTMENT: 'department',
	DESIGNATION: 'designation',
	EMP_ID: 'empId',
	WPS_PERSON_ID: 'wpsPersonId',
	WPS_ESTABLISH_ID: 'wpsEstablishmentId',
	LABOURCARD_NO: 'labourCardNumber',
	EMIRATES_ID: 'emiratesId',
	PASSPORT_NO: 'passportNumber',
	C3: 'C3',
};

export const STEPPER_STATUS_KEY = {
	COMPLETED: 'COMPLTED',
	PROCESSING: 'PROCESSING',
	FAILED: 'FAILED',
};

export const PAYROLL_EMPLOYEE_STATUS_KEY = {
	COMPLETED_CHECK: 'Completed',
	PROCESSING_CHECK: 'Processing',
	ERROR_CHECK: 'Error',
	MESSAGE_SUCCESS: 'success',
	MESSAGE_ERROR: 'error',
};

export const PERMISSIONS = {
	// ------ Hr - claims ------

	MANAGE_LEAVES: 'Manage.Leaves',

	SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES: 'Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances',

	MANAGE_DOCUMENTS: 'Manage.Documents',

	MANAGE_ANNOUNCEMENTS: 'Manage.Announcements',

	// ------ Payroll claims ------

	MANAGE_PAYROLL: 'Manage.Payroll',

	APPROVE_PAYROLL_REQUEST: 'Approve.Payroll.Requests',

	MANAGE_EMPLOYEES: 'Manage.Employees',

	APPROVE_CARD_ORDERS: 'Approve.Card.Orders',

	APPROVE_CARD_REPLACEMENT: 'Approve.Card.Replacement',

	APPROVE_CARD_CANCELLATION: 'Approve.Card.Cancellation',

	// ------ Employee Claims ------

	REQUEST_LEAVE: 'Request.Leave',

	VIEW_PAYSLIP: 'View.Payslip',

	VIEW_ANNOUNCEMENT: 'View.Announcement',

	// ----- Manage Rolls ------

	MANAGE_ROLES_AND_PERMISSIONS: 'Manage.Roles.And.Permissions',

	SOME_TEST_ROLE: 'SOME_TEST_ROLE',
};

export const ALL_HR_CLAIMS = [
	PERMISSIONS.MANAGE_LEAVES,

	PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,

	PERMISSIONS.MANAGE_DOCUMENTS,

	PERMISSIONS.MANAGE_ANNOUNCEMENTS,
];

export const ALL_PAYROLL_CLAIMS = [
	PERMISSIONS.MANAGE_PAYROLL,

	PERMISSIONS.APPROVE_PAYROLL_REQUEST,

	PERMISSIONS.MANAGE_EMPLOYEES,

	PERMISSIONS.APPROVE_CARD_ORDERS,

	PERMISSIONS.APPROVE_CARD_REPLACEMENT,

	PERMISSIONS.APPROVE_CARD_CANCELLATION,
];

export const ALL_EMPLOYEE_CLAIMS = [PERMISSIONS.REQUEST_LEAVE, PERMISSIONS.VIEW_PAYSLIP, PERMISSIONS.VIEW_ANNOUNCEMENT];
export const PENDING_ALERTS_CLAIMS = [PERMISSIONS.APPROVE_PAYROLL_REQUEST, PERMISSIONS.APPROVE_CARD_ORDERS];

export const OTP_SOURCE_TYPE = Object.freeze({
	sms: 'SMS',
	email: 'EMAIL',
	authenticatorApp: 'AUTHENTICATOR APP'
})

export const API_STATUS_CODES = Object.freeze({
	SUCCESS: 200,
	BAD_REQUEST: 400,
	INTERNAL_ERROR: 500
})

// employees page constants
export const EMPLOYEES_PAGE_CONSTANTS = {
	cards: {
		actions: {
			approve: 'Approve',
			reject: 'Reject'
		},
		type: {
			all: 'all'
		}
	},

}

export const UN_EMPLOYMENT_INSURANCE_CONSTANTS = {
	insurancePlan: 'Annual'
}
