import React from 'react';
import reducer from './reducer';
import { withReducer } from 'src/store/reducerLoader';
import LeaveStatusFunctional from './Component/leaveStatusFunctional';
import saga from './saga/index.js';
import LeaveStatusMobile from './mob-leave-status/LeaveStatusMobile';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess=UnauthorizedAccess(LeaveStatusFunctional)

function LeaveStatus() {
	let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
	let currentClaims = useSelector((state)=>state?.sharedStates?.currentUser?.hrClaims);
	return (
		<>
			<AuthorizedAccess {...{currentUser,currentClaims,UserClaims:"Manage.Leaves"}}/>
			{/* <div className="leave-status-mobile">
				<LeaveStatusMobile />
			</div> */}
		</>
	);
}
const ReducedScreen = withReducer('leaveStatusReducer', reducer, saga)(LeaveStatus);
export default ReducedScreen;
