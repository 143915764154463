import React from 'react';
import { LANGUAGES, TERMS_OF_SERVICE } from './constants';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './tableView.scss';

const TableView = ({ pageNo = 0 }) => {
	function showContentsByPageNo() {
		// showing the pages as per the document received, each page contains 4 pages data from docs
		if (pageNo === 1) {
			return TERMS_OF_SERVICE.data.slice(39, 68);
		} else if (pageNo === 2) {
			return TERMS_OF_SERVICE.data.slice(68);
		} else {
			return TERMS_OF_SERVICE.data.slice(0, 39);
		}
	}
	return (
		<div className="tos_page_outer">
			<div className="edenred-logo">
				<ImageComponent src={focus_state_icons.edenredLogo} width={78.99} height={48} alt="edenred-logo" />
			</div>
			<table className="tos_table">
				{pageNo === 0 && (
					<thead className="table-header">
						<tr>
							<th>{TERMS_OF_SERVICE.header[LANGUAGES.english].text}</th>
							<th className="empty-header"></th>
							<th dir="rtl">{TERMS_OF_SERVICE.header[LANGUAGES.arabic].text}</th>
						</tr>
					</thead>
				)}
				<tbody className="table-body">
					{showContentsByPageNo().map((data, index) => {
						return (
							<tr id={index}>
								<td>{data[LANGUAGES.english].text}</td>
								<td></td>
								<td dir="rtl">{data[LANGUAGES.arabic].text}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default TableView;
