export const INVOICE_STATUSES = Object.freeze({
    new: 'new',
    autoSuccess: 'autosuccess',
    autofailed: 'autofailed',
    manualsuccess: 'manualsuccess',
    pending: 'pending',
    rejected: 'rejected',
    generalerror: 'generalerror',
    manualfailedDisputed: 'manualfailed-disputed'
})
export const INVOICE_STATUS_TEXT = Object.freeze({
    success: 'Payment received',
    pending: 'Pending reconciliation from Edenred',
    reject: 'Rejected'
})