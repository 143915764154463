import React from 'react';
import moment from 'moment';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons';
import { MANAGE_EMPLOYEES_CONSTANTS } from '../../constants';

const { employeeAddingDate } = MANAGE_EMPLOYEES_CONSTANTS.addEmployees.modalContents.insuranceExternal;

const date = moment(employeeAddingDate);
// Dates in number and word format
const numberFormat = date.format('DD/MM/YYYY'); // 01/04/2024
const wordFormat = date.format('D MMMM YYYY'); // 1 April 2024

const stopInsuranceContents = [
	{
		icon: icons.focus_state.smallFailCross,
		customText: (
			<ETypo b2 light color="#737780">
				Newly added Employees from{' '}
				<ETypo b2 medium color="#737780">
					{numberFormat}
				</ETypo>
				&nbsp;won't be issued policies.
			</ETypo>
		),
		iconAlt: 'fail-xmark',
	},
	{
		icon: icons.focus_state.smallSuccessTick,
		text: `Renewal for existing employees whom policies issued prior to ${wordFormat} - will continue`,
		iconAlt: 'success-tick',
	},
	{
		icon: icons.focus_state.smallSuccessTick,
		text: 'Installment premium for existing cardholders already registered through us will continue.',
		iconAlt: 'success-tick',
	},
	{
		icon: icons.focus_state.smallSuccessTick,
		text: 'Pending Requests for Existing C3pay cardholders.',
		iconAlt: 'success-tick',
	},
];

const DisableInsuranceInstructions = () => {
	return (
		<>
			<ETypo color="#737780" medium b2>
				By Stopping Adding New Employees:
			</ETypo>
			<div className="stop_insurance_contents_container">
				{stopInsuranceContents.map((content, i) => (
					<div id={i} className="stop_insurance_contents">
						<ImageComponent src={content.icon} alt={content.iconAlt} />
						{content.customText ? (
							content.customText
						) : (
							<ETypo b2 light color="#737780">
								{content.text}
							</ETypo>
						)}
					</div>
				))}
			</div>
		</>
	);
};

export default DisableInsuranceInstructions;
