import { put, takeEvery, call } from 'redux-saga/effects';
import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL, RUN_PAYROLL_DROPDOWN } from '@constants/app-constants';
import Actions from '../action';

const setIsSelectedValue = (options = []) => {
	return options?.map((option, index) => {
		return {
			...option,
			isSelected: false,
		};
	});
};

const formatDropdownValues = (data) => {
	return [
		{
			name: RUN_PAYROLL_DROPDOWN.PAYROLL_CHANNEL,
			options: setIsSelectedValue(data?.payrollChannels) || [],
			key: 'payrollChannel',
		},
		{
			name: RUN_PAYROLL_DROPDOWN.ACCOUNT_TYPE,
			options: setIsSelectedValue(data?.accountTypes) || [],
			key: 'accountType',
		},
		{
			name: RUN_PAYROLL_DROPDOWN.ESTABLISHMENT_ID,
			options: setIsSelectedValue(data?.establishments) || [],
			key: 'establishment',
		},
		{
			name: RUN_PAYROLL_DROPDOWN.BANK_NAME,
			options: setIsSelectedValue(data?.bankNames) || [],
			key: 'bankName',
		},
	];
};
const formatEmployeeData = (data) => {
	console.log('emp', data);
	return data?.employees.map((employee, index) => {
		return {
			name: employee?.employeeName,
			employeeId: employee?.employeeCode,
			totalSalary: employee?.salary,
			variablePay: employee?.variablePay,
			daysOnLeave: employee?.daysOnLeave,
			endOfService: employee?.endOfService,
			previousSalary: employee?.previousSalary,
			isSelected: false,
		};
	});
};

export function* getFilterValues() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/filter-lookup-data?status=ACTIVE`;
		const res = yield call(Axios.get, uri);
		const formattedData = yield formatDropdownValues(res.data);
		yield put(Actions.creators.getFilterDropdownDataSuccess(formattedData));
	} catch (err) {
		yield put(Actions.creators.getFilterDropdownDataError(err));
	}
}
export function* getEmployeeData(params) {
	console.log('params', params);
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	let parameters = params.response;

	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}?status=${parameters.status}&pageNumber=${parameters.pageNumber}&pageSize=${parameters.pageSize}&accountType=${parameters.accountType}&bankName=${parameters.bankName}&establishment=${parameters.establishment}&payrollChannel=${parameters.payrollChannel}&previousPayrollUploadId=${parameters.previousPayrollUploadId}`;
		const res = yield call(Axios.get, uri);

		const data = res.data;
		console.log('res', data);
		const formattedEmployeeData = yield formatEmployeeData(res.data);
		yield put(Actions.creators.getEmployeeTabledataSuccess(formattedEmployeeData));
		yield put(Actions.creators.getTotalCountSuccess(data.totalCount));
	} catch (err) {
		yield put(Actions.creators.getEmployeeTabledataError(err));
	}
}
export function* getPreviousPayroll(params) {
	console.log('pagweeeee', params);
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/EmployeeSalaries/${corporateId}/previous-payroll?status=ACTIVE&pageNumber=${params.response}&pageSize=${3}`;
		const res = yield call(Axios.get, uri);

		yield put(Actions.creators.getPreviousPayrollDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getPreviousPayrollDataError(err));
	}
}

export default function* expiryWatcher() {
	yield takeEvery(Actions.types.GET_FILTER_DROPDOWN_DATA, getFilterValues);
	yield takeEvery(Actions.types.GET_EMPLOYEE_TABLE_DATA, getEmployeeData);
	yield takeEvery(Actions.types.GET_PREVIOUS_PAYROLL_DATA, getPreviousPayroll);
}
