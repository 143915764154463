import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { EmployeesExcelDownload } from '@sharedComponent/excel/employees-excel';
import ErrorTable from '@sharedComponent/error-table';
import FreshdeskWidgetHelper from '@sharedComponent/freshdesk/FreshdeskWidgetHelper';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EDragger from '@pages/DesignSystem/Dragger';
import HintCard from '@pages/DesignSystem/hindcard';
import { HR_ALLOWED_FILETYPES } from '@constants/app-constants';
import PayrollService from 'src/services/payroll-service';
import EmployeeService from 'src/services/employee-service';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { templateDownload } from 'src/helpers/index';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ClevertapReact from 'src/utils/clever-tap';
import WpsNonWpsPopupFunctional from './wps-nonwps-popup-functional';
import moment from 'moment';
import './upload-spreadsheet.scss';
const UploadSpreadsheet = () => {
	const [StagingLoader, setStagingLoader] = useState(false);
	const [nextFileUploadLoader, setNextFileUploadLoader] = useState(false);
	const [hasErrorFile, setHasErrorFile] = useState(false);
	const [uploadLoader, setUploadLoader] = useState(false);
	const [empList, setEmpList] = useState([]);
	const [errorData, seterrorData] = useState([]);
	const [employeeTableData, SetemployeeTableData] = useState([]);
	const history = useHistory();
	const location = useLocation();
	const [counter, setcounter] = useState(0);
	const [errordataList, setErrorDataList] = useState([]);
	const [salaryBolbFileName, setSalaryBolbFileName] = useState(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({ titleMessage: '', subMessage: '' });
	const [isPopupVisible, setIsPopupVisible] = useState({
		isWpsNonWps: false,
		data: [],
		endofService: [],
		payrollPlus: {},
		runPayroll: {},
		message: '',
		wpsNonWps: '',
		monthYearSelect: {},
		selectDate: '',
		status: 'wpsNonWps',
		type: '',
		employeesCount: '',
		calledFrom: 'SPREAD_SHEET_UPLOAD_SCREEN',
	});
	const _payrollService = new PayrollService();
	let employeeApi = new EmployeeService();
	const download = () => {
		ClevertapReact.event('salaryfile_payrolltable ');
		_payrollService.usefullfileDownload('Salary', 'GET', 'blob').then(({ data }) => {
			templateDownload(new Blob([data], { type: data.type }), 'payroll.xls');
		});
	};
	const upload = [
		{
			description: '1',
			list: (
				<span>
					Download our salary file template
					<Link to={'#'} onClick={() => download()} style={{ textTransform: 'none' }}>
						Here
					</Link>
				</span>
			),
		},
		{ description: '2', list: 'Fill in your employee salary amounts.' },
		{
			description: '3',
			list: (
				<span>
					Please ensure that all the employees are active and registered with us - you can download a list of your active employees
					{window.screen.width > 425 ? (
						<EmployeesExcelDownload
							dataSet={employeeTableData}
							filename={`employeelist`}
							element={
								<Link to={'#'} style={{ textTransform: 'none' }}>
									Here
								</Link>
							}
						/>
					) : null}
				</span>
			),
		},
	];
	const uploadFile = (file) => {
		let formData = new FormData();
		formData.append('file', file);
		let apform = formData.append('file', file);
		setUploadLoader(true);
		_payrollService
			.uploadRunPayrollExcel(formData)
			.then((res) => {
				setUploadLoader(false);
				if (res?.data?.validationMessage) {
					setcounter(counter + 1);
					setSalaryBolbFileName(res?.data?.salaryBlobFileName);
					seterrorData([...res?.data?.validationMessage]);
				} else {
					setcounter(0);
					seterrorData([]);
					setIsButtonDisabled(true);
					if (res?.data?.employeeSalaries?.length < 100) {
						setEmpList(
							res?.data &&
								res?.data?.employeeSalaries &&
								res?.data?.employeeSalaries?.map((data) => {
									return { ...data, hasError: false };
								})
						);
					} else {
						setStagingLoader(true);
						_payrollService
							.EmployeeSalaryStaging({ employeeDetails: res?.data?.employeeSalaries })
							.then((response) => {
								let tempEmployeeSalaries = [];
								res.data &&
									res.data.employeeSalaries.map((emp) => {
										tempEmployeeSalaries.push({ ...emp, totol_sal: emp.salary, variable_pay: emp.variablePay });
									});
								const date = new Date();
								const month = moment(date).format('MM');
								const year = moment(date).format('YYYY');
								if (location.state.payrollService === 'PAYROLL-PLUS') {
									setIsPopupVisible({
										...isPopupVisible,
										type: 'payrollPlus',
										isWpsNonWps: true,
										message: 'next',
										status: 'wpsNonWps',
										monthYearSelect: { month: month, year: year },
										payrollPlus: { month: month, year: year },
										endofService: [],
										employeesCount: res?.data?.employeeSalaries?.length,
									});
								} else if (location.state.payrollService === 'RUN-PAYROLL') {
									setIsPopupVisible({
										...isPopupVisible,
										isWpsNonWps: true,
										message: 'next',
										status: 'wpsNonWps',
										monthYearSelect: { month: month, year: year },
										payrollPlus: { month: month, year: year },
										endofService: tempEmployeeSalaries || [],
										type: 'runpayroll',
										employeesCount: res?.data?.employeeSalaries?.length,
									});
								}
								setStagingLoader(false);
							})
							.catch((error) => {
								setStagingLoader(false);
							});
					}
				}
			})
			.catch((error) => {
				setEmpList([]);
				seterrorData([]);
				setUploadLoader(false);
				FreshdeskWidgetHelper(false);
			});
	};
	useEffect(() => {
		const FreshdeskWidgetElement = document.getElementById('freshworks-container');
		if (FreshdeskWidgetElement && counter >= 2) {
			var element = document.getElementById('freshworks-container');
			element.classList.add('freshdeskShow');
			element.classList.remove('freshdeskHide');
		}
		return () => {
			const FreshdeskWidgetElement = document.getElementById('freshworks-container');
			if (FreshdeskWidgetElement) {
				var element = document.getElementById('freshworks-container');
				element.classList.add('freshdeskHide');
				element.classList.remove('freshdeskShow');
			}
		};
	}, [counter]);
	if (counter >= 2) {
		let widgetDetails = { wigetid: '67000002930' };
		FreshdeskWidgetHelper(widgetDetails);
	}
	async function fetchEmployees(queryParams = { status: 'ACTIVE', includeEmployeeCounts: true }) {
		try {
			let response = await employeeApi.getEmployees(queryParams);
			if (response && response.data) {
				SetemployeeTableData([...response.data.employees]);
			}
		} catch (err) {
			SetemployeeTableData([]);
		}
	}
	useEffect(() => {
		fetchEmployees();
	}, []);
	const handleDragger = (file) => {
		if (HR_ALLOWED_FILETYPES?.indexOf(file?.name && file?.name?.split('.')?.splice(-1)[0]) >= 0) {
			uploadFile(file);
			setHasErrorFile(false);
		} else {
			setHasErrorFile(true);
		}
	};
	const onRemoveUpload = () => {
		setHasErrorFile(false);
		seterrorData([]);
		setIsButtonDisabled(false);
		setErrorDataList([]);
		setSalaryBolbFileName(null);
	};
	const hasError = errorData.length;
	const loadErrorData = (errorDataArray) => {
		if (!errordataList.length) {
			setErrorDataList(errorDataArray);
		} else {
			let newArrayData = [...errordataList, ...errorDataArray];
			setErrorDataList(newArrayData);
		}
	};
	const nextOnClick = async () => {
		ClevertapReact.event('Next_Uploadexcel', null);
		let errors = [];
		let emplists = [];
		let errorDataPayLoad = { salaryBlobFileName: salaryBolbFileName, salaryFileUploadAutoFixList: errordataList };
		setNextFileUploadLoader(true);
		if (errordataList.length !== 0) {
			try {
				let res = await _payrollService.validateUploadSpreadSheet(errorDataPayLoad);
				if (res.data.validationMessage) {
					setcounter(counter + 1);
					setSalaryBolbFileName(res.data.salaryBlobFileName);
					seterrorData([...res.data.validationMessage]);
					errors = [...res.data.validationMessage];
				} else {
					setcounter(0);
					seterrorData([]);
					if (res.data.employeeSalaries.length < 100) {
						setNextFileUploadLoader(false);
						emplists =
							res.data &&
							res.data.employeeSalaries &&
							res.data.employeeSalaries.map((data) => {
								return { ...data, hasError: false };
							});
						if (errors.length == 0 && emplists.length > 0) {
							history.push({ pathname: '/run-payroll', state: { empList: emplists, fromUploadSheetPage: true } });
						} else if (emplists.length === 0) {
							setNextFileUploadLoader(false);
							setIsButtonDisabled(false);
							setpopupVisible(true);
							setPopupMessage({ titleMessage: 'Excel is blank', subMessage: null });
						}
					} else {
						setStagingLoader(true);
						setNextFileUploadLoader(false);
						_payrollService
							.EmployeeSalaryStaging({ employeeDetails: res.data.employeeSalaries })
							.then((response) => {
								let tempEmployeeSalaries = [];
								res.data &&
									res.data.employeeSalaries.map((emp) => {
										tempEmployeeSalaries.push({ ...emp, totol_sal: emp.salary, variable_pay: emp.variablePay });
									});
								setStagingLoader(false);
							})
							.catch((error) => {
								setStagingLoader(false);
							});
					}
				}
			} catch (error) {
				setNextFileUploadLoader(false);
				console.log(error);
			}
		} else {
			setNextFileUploadLoader(false);
			if (empList.length !== 0) {
				history.push({ pathname: '/run-payroll', state: { empList, fromUploadSheetPage: true } });
			}
		}
	};
	const enableNextButton = (value) => value && setIsButtonDisabled(value);
	const closePopup = () => setpopupVisible(false);
	const draggerIcon = () => {
		if (uploadLoader) {
			return focus_state_icons.hrUploading;
		} else if (errorData?.length > 0) {
			return focus_state_icons.NewDesignErro;
		} else if (empList?.length > 0) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (uploadLoader) {
			return 'uploading';
		} else if (errorData?.length > 0) {
			return 'error';
		} else if (empList?.length > 0) {
			return 'done';
		} else {
			return 'new';
		}
	};
	return (
		<>
			<Col className="upload-spreadsheet-col-container">
				<Edenredmodal isOpen={popupVisible} title={popupMessage?.titleMessage} onOkay={closePopup} onOkayButton="OK" />
				<EdenredHeader />
				<Row className="UploadSpreadsheet">
					<div className="UploadSpreadsheet_header">
						<p className="title">Upload A Spreadsheet Or A Direct SIF</p>
						<EdenredButtons
							className="width-fit-content"
							disabled={!isButtonDisabled}
							onClick={nextOnClick}
							loading={nextFileUploadLoader || StagingLoader}
							type="primary">
							Next
						</EdenredButtons>
					</div>
					<Col className="upload_spreadsheet_card">
						<Col xl={24} md={24} lg={24} sm={24} xs={24} className="upload_spreadsheet_steps_container">
							{upload?.map((content, index) => (
								<Row key={index} className="upload_spreadsheet_steps_row">
									<Col className="upload_spreadsheet_steps_icon">
										<ETypo medium b2 color="#313338">
											{content?.description}
										</ETypo>
									</Col>
									<Col xl={22} lg={22} md={22} sm={22} xs={22} className="upload_spreadsheet_steps_content">
										<ETypo light b1 color="#484B52">
											{content?.list}
										</ETypo>
									</Col>
								</Row>
							))}
						</Col>
						{hasErrorFile && (
							<Col xl={24} md={24} sm={24} lg={21} xs={24} className="card_error" style={{ padding: '17px 0px 0px 0px' }}>
								<Card className="card_action">
									<Row>
										<Col xl={1} sm={3} xs={3} md={2}>
											<ImageComponent src={focus_state_icons.hrDocumentExpired} />
										</Col>
										<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
											<ETypo light b1>
												Invalid format. Please upload one of these formats {HR_ALLOWED_FILETYPES.join()}
											</ETypo>
										</Col>
										<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
											<ETypo light b1>
												Please correct the following errors and upload the file again.
											</ETypo>
										</Col>
									</Row>
								</Card>
							</Col>
						)}
						<Col xl={24} md={24} lg={24} className="dragger_card_container">
							<EDragger
								{...{
									idleIcon: draggerIcon(),
									draggerStatus: draggerStatus(),
									uploadDeleteIcon: focus_state_icons.NewDelete,
									onChange: handleDragger,
									onRemoveUpload,
									acceptedTypes: '.xlsx,.xls,.sif',
								}}
							/>
						</Col>
						{!hasError ? (
							<div style={{ marginTop: '17px' }}>
								<HintCard
									{...{
										hint: 'You can also upload a direct SIF here in the WPS format, if you have a SIF file ready.',
										icon: not_focus_icons?.uploadhint,
										bgColor: '#FEFAF5',
										hintColor: '#00344E',
									}}
								/>
							</div>
						) : null}
						{hasError ? (
							<Col xl={24} md={24} sm={24} lg={21} xs={24} className="card_error">
								<Card className="card_action">
									<Row>
										<Col xl={1} sm={3} xs={3} md={2}>
											<ImageComponent src={focus_state_icons.hrDocumentExpired} />
										</Col>
										<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
											<ETypo light b1>
												Please correct the following errors and upload the file again.
											</ETypo>
										</Col>
										<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
											<ETypo light b1>
												Please correct the following errors and upload the file again.
											</ETypo>
										</Col>
									</Row>
								</Card>
							</Col>
						) : null}
						{hasError ? (
							<Fragment>
								<Col xl={24} md={24} sm={24} lg={21} xs={24}>
									<ErrorTable data={errorData} loadErrorData={loadErrorData} enableNextButton={enableNextButton} />
								</Col>
							</Fragment>
						) : null}
					</Col>
				</Row>
			</Col>
			<WpsNonWpsPopupFunctional {...{ isPopupVisible, setIsPopupVisible }} />
		</>
	);
};
export default UploadSpreadsheet;
