const GET_NATIONALITY_DATA_START = 'GET_NATIONALITY_DATA_START';
const GET_NATIONALITY_DATA_SUCCESS = 'GET_NATIONALITY_DATA_SUCCESS';
const GET_NATIONALITY_DATA_FAIL = 'GET_NATIONALITY_DATA_FAIL';
const SAVE_INDIVIDUAL_EMPLOYEE_START = 'SAVE_INDIVIDUAL_EMPLOYEE_START';
const SAVE_INDIVIDUAL_EMPLOYEE_SUCCESS = 'SAVE_INDIVIDUAL_EMPLOYEE_SUCCESS';
const SAVE_INDIVIDUAL_EMPLOYEE_FAIL = 'SAVE_INDIVIDUAL_EMPLOYEE_SUCCESS';
const EMP_PERSONAL_DETAIL_SAVED = 'EMP_PERSONAL_DETAIL_SAVED';

const getNationalityDataStart = () => ({
	type: GET_NATIONALITY_DATA_START,
});
const getNationalityDataSuccess = (res) => ({
	type: GET_NATIONALITY_DATA_SUCCESS,
	res,
});
const getNationalityDataFail = (err) => ({
	type: GET_NATIONALITY_DATA_FAIL,
	err,
});
const saveIndividualEmployeeStart = (payload) => ({
	type: SAVE_INDIVIDUAL_EMPLOYEE_START,
	payload,
});
const saveIndividualEmployeeSuccess = (res) => ({
	type: SAVE_INDIVIDUAL_EMPLOYEE_SUCCESS,
	res,
});
const saveIndividualEmployeeFail = (err) => ({
	type: SAVE_INDIVIDUAL_EMPLOYEE_FAIL,
	err,
});
const isPersonalDetailSaved = (flag) => ({
	type: EMP_PERSONAL_DETAIL_SAVED,
	flag,
});

export default {
	types: {
		GET_NATIONALITY_DATA_START,
		GET_NATIONALITY_DATA_SUCCESS,
		GET_NATIONALITY_DATA_FAIL,
		SAVE_INDIVIDUAL_EMPLOYEE_START,
		SAVE_INDIVIDUAL_EMPLOYEE_SUCCESS,
		SAVE_INDIVIDUAL_EMPLOYEE_FAIL,
		EMP_PERSONAL_DETAIL_SAVED,
	},
	creators: {
		getNationalityDataStart,
		getNationalityDataSuccess,
		getNationalityDataFail,
		saveIndividualEmployeeStart,
		saveIndividualEmployeeSuccess,
		saveIndividualEmployeeFail,
		isPersonalDetailSaved,
	},
};
