import React, { useEffect, useState } from 'react';
import { Col, Row, Divider } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButton from '@sharedComponent/button';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import Inputbox from '@pages/DesignSystem/Input-Field';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Toast from '@pages/DesignSystem/Toast';
import UserService from 'src/services/user-service';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import Modalcarousel from '../../../../components/shared/modal-carousel';
import './announcement-presentational.scss';

function AnnouncementPresentational({
	announcement,
	popup,
	handleSavePopup,
	leaveRequestSave,
	size,
	mobPopUp,
	popUpMobile,
	handleHeadingChange,
	handleTextChange,
	initial,
	isAnnouncementLoading,
	announcementPopUp,
	nHrClaims,
	userNavigationMenu,
	isToastVisible,
	clearPopUp,
}) {
	const [popupNew, setPopupNew] = useState(false);
	const [nextSlide, setNextSlide] = useState(0);
	const MenuAPI = new UserService();
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	useEffect(() => {
		if (
			!isOnlyEmployeeExits &&
			navbarClaims &&
			(!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('announcements')) &&
			isSubcribed
		) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);

	const handleClose = () => setPopupNew(false);

	const data = [
		{
			image: not_focus_icons.announcementMode1,
			text: 'Communicate with all your employees in seconds',
			buttonText: 'Next',
		},
		{
			image: not_focus_icons.announcementModel,
			text: 'Send notifications directly to their mobile phones',
			buttonText: 'Next',
		},
		{
			image: not_focus_icons.announcementModel2,
			text: 'Your employees are going to receive notifications instantly',
			buttonText: 'Get Started',
		},
	];
	const handleSlideChange = (flag, status) => {
		let payload = {
			...navbarClaims,
			tourGuide: navbarClaims?.tourGuide ? `${navbarClaims?.tourGuide},${status}` : status,
		};
		if (nextSlide < data?.length - 1) {
			setNextSlide((prev) => prev + 1);
		}
		if (flag == false) {
			MenuAPI.postUserNavigationMenu(payload)
				.then((res) => {
					userNavigationMenu();
					setPopupNew(false);
				})
				.catch((error) => {
					setPopupNew(false);
				});
		}
	};

	function saveButton() {
		return !(
			typeof initial.title === 'string' &&
			typeof initial.body === 'string' &&
			initial.title.trim().length > 0 &&
			initial.body.trim().length > 0
		);
	}

	return (
		<>
			<Toast visible={isToastVisible} clearPopUp={clearPopUp} color="#8EF9B3" textcolor="#484B52" size="38rem">
				Your announcement was sent successfully
			</Toast>
			<Row className="announcementContainer">
				<Col sm={24} xs={24} className="for-edenlogo">
					<ImageComponent src={not_focus_icons.Logo} />
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="page-padding">
					<Row className="new_design_ann_header">
						<Col xl={18} lg={18} md={18} sm={24} xs={24}>
							<p className="heading">Announcements</p>
						</Col>
					</Row>
					{nHrClaims?.length > 0 ? (
						<div className="new_design_announcement">
							<div className="new_ann_message">
								<Row>
									<Col xl={12} lg={12} md={12} sm={24} xs={24}>
										<p className="notification-title">Notification Title</p>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} sm={24} xs={24} className="new_design_input">
										<Inputbox
											className="input-title-note"
											maxLength="40"
											placeholder="Write your message here "
											onChange={handleHeadingChange}
											value={initial?.title}
										/>
										<p className={initial?.title?.length == 40 ? 'for-characters' : 'for-characters-1'}>
											{initial?.title?.length}/40 Characters
										</p>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} sm={24} xs={24}>
										<p className="notification-title">Notification Text</p>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} sm={24} xs={24} className="message-area">
										<Inputbox
											className="input-note"
											maxLength="120"
											placeholder="Write your message here "
											onChange={initial.body.length <= 120 ? handleTextChange : ''}
											value={initial?.body}
										/>
										<p className={initial?.body?.length == 120 ? 'for-characters' : 'for-characters-1'}>
											{initial?.body?.length}/120 Characters
										</p>
									</Col>
								</Row>
								<Row>
									<Col xl={12} lg={12} md={12} sm={24} xs={24} className="for-next-button">
										<EdenredButtons onClick={size ? popUpMobile : popup} className="Send" disabled={saveButton()}>
											Next
										</EdenredButtons>
									</Col>
								</Row>
							</div>
						</div>
					) : null}
					<div className="new_design_history">
						<div className="new_design_ann_his">
							<Row className="new_his_header">
								<Col xl={18} lg={18} md={18}>
									<p className="heading-history">History</p>
								</Col>
							</Row>
							{announcement?.announcementList?.length !== 0 ? (
								<div className="new_ann_his">
									{announcement?.announcementList?.map((data) => (
										<Row>
											<Col xl={24} lg={24} md={24} className="announcement-history">
												<p className="for-date">{data.notificationDate && moment(data.notificationDate).format('LL')}</p>
												<p className="announcement-heading">{data.title}</p>
												<p className="announcement-text">"{data.text}"</p>
											</Col>
											<Divider />
										</Row>
									))}
								</div>
							) : (
								<div className="new_design_no_history">
									<div className="no_announcement_record">
										<Row className="history-frame">
											<ImageComponent src={focus_state_icons.NewDesignFile} />
										</Row>
										<Row className="history-frame">
											<p className="no-announcement">No announcements yet</p>
										</Row>
										<Row className="history-frame">
											<p className="no-announcement-text">
												After your first announcement you will be <br />
												able to view it here
											</p>
										</Row>
									</div>
								</div>
							)}
						</div>
					</div>
				</Col>
				<Edenredmodal
					{...{
						className: 'new-announcement-save-popup',
						isOpen: announcementPopUp,
						close: handleSavePopup,
						onOkayButton: 'Send Announcements',
						onCancelButton: 'Edit Message',
						onOkay: leaveRequestSave,
						onCancel: handleSavePopup,
						loading: isAnnouncementLoading,
						title: 'You will be sending this message for FREE as an app notification to 40/50 of your employees:',
						desc: (
							<>
								<div className="title-row1">
									{' '}
									<p className="title-body">
										<span className="title-name">Title:</span> "{initial.title}"
									</p>{' '}
								</div>
								<div className="title-row">
									{' '}
									<p className="title-body">
										<span className="title-name">Description:</span> "{initial.body}"
									</p>{' '}
								</div>
							</>
						),
					}}
				/>

				{mobPopUp ? (
					<div className="next-Container">
						<Row>
							<p className="doYou">You will be sending this message for FREE as an app notification to 40/50 of your employees:</p>
						</Row>

						<Row className="title-row">
							{' '}
							<div className="title-name">Title:</div>
							<div className="title-body">{initial.title}</div>{' '}
						</Row>
						<Row className="title-row">
							{' '}
							<p className="title-body">
								<span className="title-name">Description:</span>"{initial.body}"
							</p>{' '}
						</Row>

						<Row style={{ paddingTop: 30 }}>
							<Col xs={11}>
								<EdenredButton className="Edit " type="outline-g" onClick={handleSavePopup}>
									Edit Message
								</EdenredButton>
							</Col>
							<Col xs={1} />
							<Col xs={12}>
								<EdenredButton className="Send" onClick={leaveRequestSave}>
									Send
								</EdenredButton>
							</Col>
						</Row>
					</div>
				) : null}
				{/* <Modalcarousel
					{...{
						visible: popupNew,
						handleClose,
						data,
						handleSlideChange,
						initialSlide: nextSlide,
						status: 'announcements',
					}}></Modalcarousel> */}
			</Row>
		</>
	);
}

export default AnnouncementPresentational;
