import Actions from './action';
export const initState = {
	paySlipData: [],
	paySlipDownload: [],
};

const paySlipReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_PAYSLIP_DATA_START: {
			return {
				...state,
			};
		}
		case Actions.types.GET_PAYSLIP_DATA_SUCCESS: {
			return {
				...state,
				paySlipData: action.res,
			};
		}
		case Actions.types.GET_PAYSLIP_DATA_ERROR: {
			return {
				...state,
				paySlipData: [],
			};
		}
		case Actions.types.GET_PAYSLIP_DOWNLOAD_START: {
			return {
				...state,
			};
		}
		case Actions.types.GET_PAYSLIP_DOWNLOAD_SUCCESS: {
			return {
				...state,
				paySlipDownload: action.res,
			};
		}
		case Actions.types.GET_PAYSLIP_DOWNLOAD_ERROR: {
			return {
				...state,
				paySlipDownload: [],
			};
		}

		default:
			return state;
	}
};
export default paySlipReducer;
