import { createSelector } from 'reselect';

import { initState } from './reducer';

const leaveStatusReducer = (state) => state.leaveStatusReducer || initState;
const employeeListLoadings = createSelector(leaveStatusReducer, (cnter) => cnter.employeeListLoading);
const employeeLists = createSelector(leaveStatusReducer, (cnter) => cnter.employeeList);
const employeeListErrors = createSelector(leaveStatusReducer, (cnter) => cnter.employeeListError);
console.log('employeeLists', employeeLists);
export default {
	leaveStatusReducer,
	employeeListLoadings,
	employeeLists,
	employeeListErrors,
};
