import React, { useEffect, useState } from 'react';
import '../replacement-card.scss';
import { Row, Col, Form } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';
import EdenredButton from '@sharedComponent/button';
import { Link, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
// import EdenredDropdown from '@sharedComponent/dropdown/dropdown';
import RoutingService from 'src/services/routing-service';
import EmployeeService from 'src/services/employee-service';
import EdenredBankDropdown from '@sharedComponent/dropdown/ibm-bank-dropdown';

const IbmBankName = ({ history }) => {
	let [bankOptions, setBankOptions] = useState([]);
	let [bankId, setBankId] = useState();
	let [empId, setEmpId] = useState('');
	let routingApi = new RoutingService();
	let employeeApi = new EmployeeService();
	const { state } = useLocation();
	const [form] = Form.useForm();

	useEffect(() => {
		setEmpId(history.location.state.tableData.employeeId);

		fetchRoutingDetails();
	}, []);
	async function fetchRoutingDetails() {
		try {
			let response = await routingApi.getRouting();
			if (response && response.data && response.data.routings) {
				let bankOptions = response.data.routings.map((bank) => {
					bank.label = bank.bankName;
					bank.value = bank.routingCode;
					return bank;
				});
				setBankOptions(bankOptions);
			}
		} catch (err) { }
	}
	async function onFinish() {
		try {
			let payload = { bankId };
			payload.iBanNumber = history.location.state.iBanNumber;
			let response = await employeeApi.updateBank(payload, empId);
			if (response && response.status === 200) {
				history.push({ pathname: '/employees/viewemployee', state: { ...state } });
			}
		} catch (err) { }
	}
	return (
		<>
			<EdenredHeader />
			<Row className="change-bank-account-number">
				<Col xl={24} md={24} lg={24} sm={24} xs={24} className="change-bank-account-header">
					<EdenredTypo>What Is {state.tableData.employeeName} ’s Bank Name?</EdenredTypo>
				</Col>
				<Col xl={24} md={24} lg={24} sm={24} xs={24} className="change-bank-account-content">
					<EdenredTypo>Pick the bank used by the employee from the options given. </EdenredTypo>
				</Col>
				<Form form={form} onFinish={onFinish}>
					<Col xl={13} md={18} lg={15} sm={24} xs={24} className="change-bank-account-input">
						<Form.Item name="bankCode" rules={[{ required: true, message: 'Please provide the employmentID of the employee' }]}>
							<EdenredBankDropdown placeholder="Bank Name" type="dropdownfield" onChange={(val) => {
								setBankId(val)
							}} options={bankOptions} labels="Bank Name" />
						</Form.Item>
					</Col>
					<Row style={{ width: '100%' }}>
						<Col xl={9} md={12} lg={12} sm={24} xs={24} className="change-bank-account-content">
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Submit
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Row>
		</>
	);
};
export default IbmBankName;
