import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Atm_Pin_Qus } from '../constants/mockData';
import { renderATMQuestionAndAnswer } from './cardOrderFaqs';
import ItemHeader from '@pages/help/components/ItemHeader';
import '../employee.scss';

const ATMPinFaqs = () => {
	const [indexValue, setIndexValue] = useState('');
	return (
		<Col className="employee-presentational">
			<Row className="employee-col">
				<Col xl={24} md={24} lg={24}>
					<ItemHeader title='ATM Pin' />
					<Col className="employee-questions">{renderATMQuestionAndAnswer(Atm_Pin_Qus, indexValue, setIndexValue)}</Col>
				</Col>
			</Row>
		</Col>
	);
};
export default ATMPinFaqs;
