import { createSelector } from 'reselect';
import { initState } from './reducer';

const Runpayroll = (state) => state.filterDropdownValues || initState;
// const counterVal = createSelector(counterState, (cnter) => cnter.counterVal);
const dropdownValues = createSelector(Runpayroll, (cnter) => cnter.filterData);
const employeesData = createSelector(Runpayroll, (cnter) => cnter.employeeDatas);
const previousPayrollData = createSelector(Runpayroll, (cnter) => cnter.datas);
const payrollTableLoader = createSelector(Runpayroll, (cnter) => cnter.payrollDataLoader);
const prevPayrollLoader = createSelector(Runpayroll, (cnter) => cnter.repeatprevpayrollLoader);
const totalCount = createSelector(Runpayroll, (cnter) => cnter.totalCount);

export default {
	dropdownValues,
	employeesData,
	previousPayrollData,
	payrollTableLoader,
	prevPayrollLoader,
	totalCount,
};
