import React from 'react';
import { images } from '@assets/img/index';
import { Row, Col, Spin } from 'antd';
import { LoadingOutlined, UnderlineOutlined } from '@ant-design/icons';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import SetupHeader from './setup-header';
import './setup.scss';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButton from '@sharedComponent/button';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Chips from '@pages/DesignSystem/Chips';
import { Link } from 'react-router-dom';
import ApproveRequestsFunctional from './../../payroll/approve-requests/components/approve-requests-functional';

function SetUpPresentational({ setupcard, handleSetUpCard, isUserExists = false, handleSkipPop, setupLoading = false, setupDetails, isContinue }) {
	const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#ffffff' }} spin />;
	const handleStatus = (status) => {
		switch (status) {
			case 'No Data':
				return 'setup-chip';
			case 'Missing info':
				return 'setup-chip-missing';
			case 'Completed':
				return 'setup-chip-completed';
			default:
				break;
		}
	};

	const getChipsColor = (status) => {
		switch (status) {
			case 'No Data':
				return '#FF9691';
			case 'Missing info':
				return '#FFDC91';
			case 'Completed':
				return '#8EF9B3';
			default:
				break;
		}
	};
	const callPage = (path, cleverTap, status, isVisible) => {
		if (isVisible == false) {
			handleSetUpCard(path, cleverTap, status);
		}
	};

	return (
		<>
			<SetupHeader />

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					alignSelf: 'flex-start',
					alignItems: 'center',
					padding: ' 0px 240px 0px 240px',
				}}>
				<div className="setup-info-page">
					<div className="setup-information-screen">
						<ETypo h2 bold color="#00344E">
							What employee information do you want to include now?
						</ETypo>
						<div className="setup-button">
							{isContinue ? (
								<EdenredButtons onClick={handleSkipPop} btnType="primary">
									Continue
								</EdenredButtons>
							) : (
								<EdenredButtons onClick={handleSkipPop} btnType="secondary">
									Continue later
								</EdenredButtons>
							)}
						</div>
					</div>
					{!isUserExists && (
						<div className="setup-warning-box">
							<div className="warning-icon-holder">
								<ImageComponent src={focus_state_icons.setupWarning}></ImageComponent>
							</div>
							<div className="warning-icon-content">
								<ETypo b1 light color="#00344E">
									You haven’t assigned a separate admin for payroll. You will be fully responsible for submitting and approving
									payroll with this account. To create a separate admin to submit and verify payroll, click on{' '}
									<ETypo b1 bold color="#00344E">
										“Roles & Permissions”.
									</ETypo>
								</ETypo>
							</div>
						</div>
					)}
					<div className="setup-information-section">
						{setupcard?.map((data, index) => (
							<div
								className="setup-card-container"
								onClick={() => {
									callPage(data?.path, data?.cleverTap, data?.status, data?.isVisible);
								}}>
								<div className={data?.isVisible ? 'setup-active disabled' : 'setup-active'}>
									<div className="setup-card-top-section">
										<div className="setup-icon-box">
											{/* <ImageComponent src={3 > 2 ? data?.defaultImage : data?.image} /> */}
											<ImageComponent src={data?.image} />
										</div>
										<div className="chips-container">
											{data?.status && (
												<Chips color={data?.isVisible ? '#18191945' : getChipsColor(data?.status)}>
													{setupLoading ? <Spin indicator={antIcon} /> : data?.status}
												</Chips>
											)}
										</div>
									</div>
									<div className="setup-card-down-section">
										<ETypo h5 bold color="#00344E">
											{data.heading}
										</ETypo>
										<div className="setup-content-section">
											<ETypo b2 light color="#484B52">
												{data.content}
											</ETypo>
										</div>
										<div className="update-container">
											<ETypo b1 medium color="#0E5274" textDecoration="underline">
												{data?.status == 'No Data' || data?.heading === 'Leave Approval workflow' ? 'Add Data' : 'Update'}
											</ETypo>
											<ImageComponent
												src={data?.isVisible ? not_focus_icons?.rightArrowDisabled : not_focus_icons.arrowRight}
											/>
										</div>
									</div>
								</div>
							</div>
						))}
						{/* {!isUserExists && (
							<Col xl={22} lg={22} md={22} sm={21} xs={21} className="setup-information-alert">
								<ImageComponent src={focus_state_icons?.Warning} />
								<p className="setup-alert-text">
									You haven’t assigned a separate admin for payroll. You will be fully responsible for submitting payroll with this
									account, which will be immediately processed without approval. To create a separate admin to submit and verify
									payroll, click on “Roles & Permissions”.
								</p>
							</Col>
						)} */}
					</div>
					{/* <Col xl={22} lg={22} md={22} sm={21} xs={21} className="setup-information-screen">
					<button className="continue-button" onClick={handleSkipPop}>
						Continue Later
					</button>
				</Col> */}
				</div>
			</div>
		</>
	);
}

export default SetUpPresentational;

// const { quickMenus } = useQuickMenus();
// 	const history = useHistory();

// 	return (
// 		<section className="approve_page_wrapper">
// 			<main className="main">
// 				<ImageComponent src={images.logo} className="approve_page_header_image" />
// 				<div className="title_section">
// 					<h1>What would you like to do next?</h1>
// 					<EdenredButtons btnType="secondary" onClick={() => history.push('/')}>
// 						{' '}
// 						Skip & Go To Dashboard{' '}
// 					</EdenredButtons>
// 				</div>

// 				<section className="card_section">
// 					{quickMenus &&
// 						quickMenus.map((menu, index) => {
// 							return (
// 								<div className="card" key={`card-${index}`}>
// 									<img src={menu.icon} alt="" />
// 									<h1>{menu.title}</h1>
// 									<p>{menu.subTitle}</p>

// 									<EdenredButtons
// 										btnType="secondary"
// 										onClick={async () => {
// 											// ClevertapReact.event(menu.cleverTapValue);
// 											console.log('onClick : ', menu);
// 											if (menu.actionType === 'NAVIGATE' && typeof menu?.action?.navigateTo === 'string') {
// 												history.push(menu?.action?.navigateTo);
// 											} else if (
// 												menu.actionType === 'DOWNLOAD' &&
// 												typeof menu?.action?.downloadEmployeeDetails === 'function'
// 											) {
// 												const result = await menu.action.downloadEmployeeDetails((status) => {
// 													console.log('Loading status : ', status);
// 												});
// 												console.log('Result : ', result);
// 											}
// 										}}>
// 										{' '}
// 										Select{' '}
// 									</EdenredButtons>
// 								</div>
// 							);
// 						})}
// 				</section>
// 			</main>
// 		</section>
// 	);
