import './company-setting.scss';
import CompanySettingFunctional from './components/company-setting-functional';
import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './overTimeReducer';
import saga from './overtime-saga/index';
import './company-setting.scss';

const CompanySetting = ({ isSetUp }) => {
	return <CompanySettingFunctional {...{ isSetUp }} />;
};

const ReducedScreen = withReducer('companySettingReducer', reducer, saga)(CompanySetting);
export default ReducedScreen;
