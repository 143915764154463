import React from 'react';
import { Row } from 'antd';
import ETypo from '.././index';

const TypoExample = () => {
	return (
		<div style={{ width: '70%' }}>
			<Row>
				<ETypo headerStyle={'h1'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is H1 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h2'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is H2 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is H3 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h4'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is H4 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h5'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is H5 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h1'} fontWeightStyle={'light'}>
					This is H1 Light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h2'} fontWeightStyle={'light'}>
					This is H2 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h3'} fontWeightStyle={'light'}>
					This is H3 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h4'} fontWeightStyle={'light'}>
					This is H4 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h5'} fontWeightStyle={'light'}>
					This is H5 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'h1'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is H1 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'h2'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is H2 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'h3'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is H3 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'h4'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is H4 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'h5'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is H5 medium text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b1'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is b1 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'b2'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is b2 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'b3'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is b3 medium text
				</ETypo>
			</Row>

			<Row>
				<ETypo headerStyle={'b4'} fontWeightStyle={'medium'} style={{ marginTop: '10px' }}>
					This is b4 medium text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b1'} fontWeightStyle={'light'} style={{ marginTop: '10px' }}>
					This is b1 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b2'} fontWeightStyle={'light'} style={{ marginTop: '10px' }}>
					This is b2 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b3'} fontWeightStyle={'light'} style={{ marginTop: '10px' }}>
					This is b3 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b4'} fontWeightStyle={'light'} style={{ marginTop: '10px' }}>
					This is b4 light text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b1'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is b1 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b2'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is b2 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b3'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is b3 bold text
				</ETypo>
			</Row>
			<Row>
				<ETypo headerStyle={'b4'} fontWeightStyle={'bold'} style={{ marginTop: '10px' }}>
					This is b4 bold text
				</ETypo>
			</Row>
		</div>
	);
};

export default TypoExample;
