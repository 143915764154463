import React from 'react';
import { Col } from 'antd';
import GaugeCounts from '../../gaugeCounts';

const GaugeChartsCard = ({ employeeCount, isEmployeeCountLoading }) => {
	return (
		<Col xl={10} lg={12} md={12} sm-={24} className="gauge_chart_card_column">
			<GaugeCounts employeeCount={employeeCount} isEmployeeCountLoading={isEmployeeCountLoading} />
		</Col>
	);
};

export default GaugeChartsCard;
