import React from 'react';
import { Layout, Menu, Row, Col } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { images } from '@assets/img/index';
import ImageComponent from '@sharedComponent/image-component';
import AppLoader from '@sharedComponent/app-loader';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import Actions from '@sharedComponent/app-action-reducer/action';
import { checkWithClaims } from '@helpers';
import { userClaims } from '@constants/';
import IdleComponent from '@sharedComponent/idlecomponent';
import { MenuRoutes } from 'src/routes';
import CompanySearchDropDown from './company-search-dropdown';
import SideNavBar from '@pages/DesignSystem/sidenavbar/index';

const { Header, Content } = Layout;

const MainLayoutPresentational = ({ username, currentUser, loading, approveReqCount, loadingRefresh, removeUser }) => {
	let { pathname } = useLocation();
	const fixedTableUrl = ['/hrdocuments', '/run-payroll', '/employees/manage_employee'];
	const isHrDocPath = fixedTableUrl?.includes(pathname);
	const role = (currentUser && currentUser?.roles && currentUser?.roles) || null;
	const { hrClaims, payrollClaims, teamClaims } = currentUser;
	// checking claim for mobile
	const claims = (Object.keys(currentUser)?.length > 1 && [...hrClaims, ...payrollClaims, ...teamClaims]) || [];

	return !loading ? (
		<Layout className="edenred_layout">
			<IdleComponent />
			{/* <SideNavBar {...{ menus, username, role, removeUser, approveReqCount, loadingRefresh }} /> */}
			{/* <SidenavbarFunctional removeUser={removeUser} currentUser={currentUser} /> */}
			<SideNavBar removeUser={removeUser} currentUser={currentUser} />
			<Row className="menu_mobile">
				<Menu selectedKeys={[pathname]} className="custom_menu">
					<Menu.Item key="/" icon={<ImageComponent className="dashboard" />} className="dashboard_menu_item">
						<Link to="/">Dashboard</Link>
					</Menu.Item>
					{checkWithClaims(claims, userClaims.MANAGE_PAYROLL) && (
						<Menu.Item key="/SubMenuPage" icon={<ImageComponent className="payroll" />} className="payroll_menu_item">
							<Link to="/SubMenuPage">Payroll</Link>
						</Menu.Item>
					)}
					{checkWithClaims(claims, userClaims.MANAGE_EMPLOYEE) && (
						<Menu.Item key="/employees/sub-menu" icon={<ImageComponent className="employees" />} className="employees_menu_item">
							<Link to="/employees/sub-menu">Employees</Link>
						</Menu.Item>
					)}
					<Menu.Item key="/more-sub-menu" icon={<ImageComponent className="more" />} className="more_menu_item">
						<Link to="/more-sub-menu">More</Link>
					</Menu.Item>
				</Menu>
			</Row>
			<Layout
				className=""
				style={{
					...(isHrDocPath ? { backgroundColor: 'white' } : { backgroundColor: '#f0f2f5' }),
					width: 'calc(100% - 245px)',
				}}>
				<Header className="site-layout-sub-header-background" style={isHrDocPath ? { marginBottom: '0px' } : { marginBottom: 'unset' }}>
					<Row>
						<Col xl={20} lg={20} md={20} sm={20} className="company_build">
							<Col sm={20} md={20} lg={16} xl={16}>
								<CompanySearchDropDown />
							</Col>
						</Col>
						<Col xl={2} md={2} sm={2} xs={20} className="logo_header">
							<Link to="/">
								<ImageComponent style={{ height: '2.1rem' }} src={images.edenredSVG} />
							</Link>
						</Col>
					</Row>
				</Header>
				<Content>
					<div className="main_container_width">
						<MenuRoutes />
					</div>
				</Content>
			</Layout>
			<ToastContainer />
		</Layout>
	) : (
		<AppLoader />
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: SelectorsApp.currentUser(state),
		user: state.oidc.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		removeUser: () => dispatch(Actions.creators.removeUser()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutPresentational);
