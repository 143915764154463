import React, { useEffect, useState } from 'react';
import PayslipPresentational from './payslip-presentational';
import { connect, useSelector } from 'react-redux';
import Selectors from './selector';
import Actions from './action';

const PayslipFunctional = ({ paySlipSaga, paySlipData, paySlipDownloadSaga }) => {
	const [payslipDocument, setPayslipDocument] = useState({
		pageNumber: '',
		pageSize: '',
	});
	const emplID = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeId);
	useEffect(() => {
		paySlipSaga(emplID, payslipDocument);
	}, [payslipDocument]);
	const handleDownload = (year, month) => {
		paySlipDownloadSaga(emplID, month, year);
	};
	return <PayslipPresentational {...{ paySlipData, handleDownload, setPayslipDocument, payslipDocument }} />;
};
const mapStateToProps = (state) => {
	return {
		paySlipData: Selectors.paySlipData(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		paySlipSaga: (emplID, payslipDocument) => dispatch(Actions.creators.getPayslipDataStart(emplID, payslipDocument)),
		paySlipDownloadSaga: (emplID, salMonth, salYear) => dispatch(Actions.creators.getPayslipDownloadStart(emplID, salMonth, salYear)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(PayslipFunctional);
export default Connected;
