import React from 'react';
import EdenredTypo from '@sharedComponent/typography';
import SelectDropdown from '@pages/DesignSystem/ComboBox/SelectDropdown';
import { options } from '@pages/DesignSystem/Json/DesignSystem';
import '../SelectDropdown.scss';

const WithPlaceholder = () => {
	return (
		<div style={{ width: '50%', paddingTop: '10px' }}>
			<EdenredTypo h2 bold style={{ fontSize: '18px' }}>
				Without Placeholder
			</EdenredTypo>
			<SelectDropdown
				className="select-dropdown-without-placeholder"
				onChange={(data) => console.log('datass', data)}
				Option={options}
				placeholder=""
				searchVisibility={false}
			/>
		</div>
	);
};

export default WithPlaceholder;
