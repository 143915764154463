import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import action from '../Employee/action';
import { Row } from 'antd';
import ETypo from '@pages/DesignSystem/Typo';
import CustomTabUnemployment from './custom-tab-unemployment';
import { useQuery } from 'src/helpers/hooks';
import { InsurancePolicyRenewalStartedBanner, InsurancePolicyUpdateBanner } from '@sharedComponent/banner';
import { syncLocalStorage } from '@helpers';
import './index.scss';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import UserService from 'src/services/user-service';
import { API_STATUS_CODES } from '@constants/app-constants';
import { SITEMAP } from 'src/routes/sitemap';
import { LOCAL_STORAGE_KEYS } from '@constants/localStorage';
import { UN_EMPLOYMENT_TAB_KEYS, UN_EMPLOYMENT_TAB_LABELS } from '../constants';

export const initFormState = {
	selectedTab: UN_EMPLOYMENT_TAB_LABELS.SUBSCRIBED,
};

function Index(props) {
	const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
	const [policyUpdateBanner, setPolicyUpdateBanner] = useState(false);
	const [policyRenewalStartBanner, setPolicyRenewalStartBanner] = useState(false);
	const search = useQuery();
	const history = useHistory();
	const { updateMissingEmpLoading } = useSelector((state) => state?.unemploymentReducer) || {};
	const isBeforeRenewalBanner = syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal);
	const isAfterRenewalBanner = syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal);
	const userMenuNavigations = useSelector((state) => state?.sharedStates?.userNavigation);
	const unEmploymentBannerData = useSelector((state) => state?.sharedStates?.unEmploymentBannerData);

	function isCorporateBulkSubscribed() {
		const { displayBanner } = unEmploymentBannerData ? unEmploymentBannerData?.data : {};
		if (displayBanner === false) {
			return true;
		}
		return false;
	}
	const isBulkSubscibed = isCorporateBulkSubscribed();

	function isShowRenewalBanner() {
		const parsedBeforeRenewalKey = isBeforeRenewalBanner ? JSON.parse(isBeforeRenewalBanner) : '';
		const parsedAfterRenewalKey = isAfterRenewalBanner ? JSON.parse(isAfterRenewalBanner) : '';
		if (isBulkSubscibed) {
			// if the user not seen a banner yet it will come as false and we need to show the banner
			if (parsedBeforeRenewalKey === false) {
				setPolicyUpdateBanner(true);
			}
			if (!policyRenewalStartBanner && parsedAfterRenewalKey === false) {
				setPolicyRenewalStartBanner(true);
			}
		}
	}
	useEffect(() => {
		// if the client was not bulk subscribed he wont allow to view this page
		if (!isBulkSubscibed) {
			history.push(SITEMAP.unemploymentInsurance.employee);
		}
	}, [unEmploymentBannerData]);

	useEffect(() => {
		if (unEmploymentBannerData) {
			isShowRenewalBanner();
		}
	}, [isBeforeRenewalBanner, isAfterRenewalBanner, unEmploymentBannerData]);

	function validSearchTab() {
		const searchedTab = search.get('tab');
		// if the searched tab was not the pre defined then we return null
		if (searchedTab) {
			if (
				searchedTab === UN_EMPLOYMENT_TAB_KEYS.PENDING ||
				searchedTab === UN_EMPLOYMENT_TAB_KEYS.MISSING_DETAILS ||
				searchedTab === UN_EMPLOYMENT_TAB_KEYS.CANCELLED
			) {
				return searchedTab;
			}
			return null;
		}
		return null;
	}
	const requestedTab = validSearchTab();

	const dispatch = useDispatch();

	const unemploymentTabArr = [
		{
			tabHeading: UN_EMPLOYMENT_TAB_LABELS.SUBSCRIBED,
			key: UN_EMPLOYMENT_TAB_KEYS.SUBSCRIBED,
		},
		{
			tabHeading: UN_EMPLOYMENT_TAB_LABELS.MISSING_DETAILS,
			key: UN_EMPLOYMENT_TAB_KEYS.MISSING_DETAILS,
		},
		{
			tabHeading: UN_EMPLOYMENT_TAB_LABELS.PENDING,
			key: UN_EMPLOYMENT_TAB_KEYS.PENDING,
		},
		{
			tabHeading: UN_EMPLOYMENT_TAB_LABELS.CANCELLED,
			key: UN_EMPLOYMENT_TAB_KEYS.CANCELLED,
		},
	];

	useEffect(() => {
		dispatch(action.creators.getUnemploymentAllCount());
	}, []);

	const saveEmployeeMissingDetails = () => {
		const payload = selectedEmployeeList.map((emp) => {
			return {
				employeeId: emp.employeeId,
				emiratesId: emp.emiratesId,
				mobileNumber: emp.mobileNumber,
			};
		});
		dispatch(action.creators.updateMissingEmployeeList(payload));
	};

	/**
	 * @param {String} val
	 * @returns true | false
	 * If valid return false
	 * If invalid return true
	 */
	const checkMobileNumber = (val) => {
		if ((val?.length > 0 && val?.substring(0, 6) !== '009715') || (val?.length > 0 && val?.length < 14)) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * @param {String} val
	 * @returns true | false
	 * If valid return false
	 * If invalid return true
	 */
	const checkEmiratesNumber = (val) => {
		if ((val?.length > 0 && val?.substring(0, 3) !== '784') || (val?.length > 0 && val?.length < 15)) {
			return true;
		} else {
			return false;
		}
	};

	const getIsValidData = () => {
		if (selectedEmployeeList.length > 0) {
			let empList = selectedEmployeeList.map((emp) => {
				const checkEmirates = checkEmiratesNumber(emp.emiratesId);
				const checkMobile = checkMobileNumber(emp.mobileNumber);
				if (checkEmirates || !emp.emiratesId || emp.emiratesId === '') {
					return true;
				} else if (checkMobile || !emp.mobileNumber || emp.mobileNumber === '') {
					return true;
				} else {
					return false;
				}
			});
			if (empList.filter((emp) => emp).length) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	const userMenuApi = new UserService();

	async function iloeBeforeRenewalAction() {
		const payload = {
			iloeKnowMore: true,
		};
		setPolicyUpdateBanner(false);
		const res = await userMenuApi.postUserNavigationMenu(payload);
		// make sure its status 200 and not 500 error
		if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
			return;
		} else if (res.status === API_STATUS_CODES.SUCCESS) {
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal);
			if (!syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal)) {
				syncLocalStorage.setItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal, JSON.stringify(userMenuNavigations?.iloeUnderstood || false));
			}
			setPolicyRenewalStartBanner(true);
		}
	}
	async function iloeAfterRenewalAction() {
		const payload = {
			iloeUnderstood: true,
		};
		setPolicyRenewalStartBanner(false);
		const res = await userMenuApi.postUserNavigationMenu(payload);
		// make sure its status 200 and not 500 error
		if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
			return;
		} else if (res.status === API_STATUS_CODES.SUCCESS) {
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal);
		}
	}

	function isMissingDetailsTab() {
		const searchedTab = search.get('tab');
		if (searchedTab === UN_EMPLOYMENT_TAB_KEYS.MISSING_DETAILS) {
			return true;
		}
		return false;
	}

	return (
		<div style={{ padding: '40px', display: 'grid', rowGap: '24px' }} className="unemployment-tabs">
			<Row className="single-update-header" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
				<ETypo bold h3>
					Mandatory Unemployment Insurance Subscription
				</ETypo>
				{isMissingDetailsTab() && (
					<div className="action-layer">
						<EdenredButtons
							loading={updateMissingEmpLoading}
							{...{
								btnType: 'primary',
							}}
							onClick={() => saveEmployeeMissingDetails()}
							disabled={getIsValidData()}>
							Submit & Issue Policies
						</EdenredButtons>
					</div>
				)}
			</Row>
			{policyUpdateBanner && <InsurancePolicyUpdateBanner action={iloeBeforeRenewalAction} />}
			{policyRenewalStartBanner && <InsurancePolicyRenewalStartedBanner action={iloeAfterRenewalAction} />}
			<CustomTabUnemployment
				tabArr={unemploymentTabArr}
				{...{ checkEmiratesNumber, checkMobileNumber, selectedEmployeeList, setSelectedEmployeeList, requestedTab }}
			/>
		</div>
	);
}

export default Index;
