import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { debounceHandler } from '@helpers';
import Actions from 'src/pages/payroll-plus-new/action';
import counterReducer from '@pages/payroll-plus-new/reducer';
import sagas from '@pages/payroll-plus-new/sagas/index';
import { successToast } from 'src/utils/toast';
import { withReducer } from 'src/store/reducerLoader';
import Autoreconcillationcard from './auto-reconciliation-card';

const AutoReconciliationFunctional = ({ page }) => {
	const [accNumber, setAccNumber] = useState('');
	const [transferCode, setTransferCode] = useState('');
	const [showTransferCode, setShowTransferCode] = useState(true);
	const [errorMessage, setErrorMessage] = useState(false);
	const [rakbankInfo, setRakbankInfo] = useState({ transferCode: {}, accountNumber: {} });
	const dispatch = useDispatch();
	let rakbankDetails = useSelector((state) => state?.sharedStates?.rakbankdetails);
	let loader = useSelector((state) => state?.sharedStates?.isRakbankLoading);
	let postLoader = useSelector((state) => state?.payrollPlusState?.postRakBankDetailsLoading);
	let postBankRes = useSelector((state) => {
		return state?.payrollPlusState?.postRakBankDetailsResponse;
	});
	let [initialAccNumber, setInitialAccNumber] = useState('');
	let isToastMessageAvailable = useSelector((state) => state?.payrollPlusState?.postRakBankToastRes);

	useEffect(() => {
		if (rakbankDetails.length > 0 && typeof rakbankDetails !== 'string') {
			rakbankDetails.map((res) => {
				if (res.nonRakBank === true) {
					rakbankInfo.transferCode = res;
					setTransferCode(res.accountNumber);
					setInitialAccNumber('');
					setAccNumber('');
				} else {
					rakbankInfo.accountNumber = res;
					setShowTransferCode(false);
					setInitialAccNumber(res.accNumber);
					setAccNumber(res.accountNumber);
				}
			});
			setRakbankInfo(rakbankInfo);
		}
	}, [rakbankDetails]);

	const createRakankAccDetails = (res) => {
		setAccNumber(res);
		if (res.toString().length == 13) {
			setErrorMessage(false);
			debounceHandler(res, postBankDetails);
		} else {
			setErrorMessage(true);
		}
	};
	const toastExecuter = () => {
		successToast({
			msg: postBankRes,
			icon: 'Mail',
		});
	};

	useEffect(() => {
		isToastMessageAvailable && toastExecuter();
		setTimeout(() => {
			dispatch(Actions?.creators?.postRakBankToast(false));
		}, 5000);
	}, [isToastMessageAvailable]);

	const postBankDetails = (res) => {
		let bankDetailPayload = {};
		// { accountNumber: '12345ABC', branchId: '33304', id: 1436, nonRakBank: true };
		// [{ accountNumber: '1234567890123', branchId: '33304', id: 1436, nonRakBank: false }, { accountNumber: '12345ABC', branchId: '33304', id: 1436, nonRakBank: true }];
		if (rakbankInfo?.accountNumber?.accountNumber) {
			bankDetailPayload = rakbankInfo.accountNumber;
			bankDetailPayload.accountNumber = res;
		} else {
			bankDetailPayload = rakbankInfo.transferCode;
			bankDetailPayload.accountNumber = res;
			delete delete bankDetailPayload?.id;
		}
		bankDetailPayload.nonRakBank = false;
		dispatch(Actions.creators.postRakBankDetails(bankDetailPayload));
		// dispatch(Actioner.creators.getRakBankDetails());
	};

	const postRakBankThroughClickHere = (isVisible) => {
		setShowTransferCode(isVisible);
		let bankDetailPayload = {};
		if (isVisible) {
			if (rakbankInfo?.transferCode?.accountNumber) {
				bankDetailPayload = rakbankInfo.transferCode;
			}
			bankDetailPayload.nonRakBank = true;
			dispatch(Actions.creators.postRakBankDetails(bankDetailPayload));
		}
	};

	return (
		<Autoreconcillationcard
			{...{
				rakbankInfo,
				accNumber,
				transferCode,
				setAccNumber,
				showTransferCode,
				setShowTransferCode,
				page,
				createRakankAccDetails,
				errorMessage,
				loader,
				postLoader,
				postRakBankThroughClickHere,
			}}
		/>
	);
};

const ReducedScreen = withReducer('payrollPlusState', counterReducer, sagas)(AutoReconciliationFunctional);
export default ReducedScreen;
