import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import TeamPermission, { GET_USERS } from '@pages/team-permission/services';
import Chips from '@pages/DesignSystem/Chips';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import Selectors from '../selectors';
import Actions from '../action';
import ManageTeamPresentational from './manage-team-presentational';

const ManageTeamFunctional = ({ issetup = false }) => {
	const [userList, setUserList] = useState([]);
	const [loading, setloading] = useState(false);
	const [totalPermissionCounts, setTotalPermissionCounts] = useState(1);
	const history = useHistory();
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
		search: '',
		filter: '',
	});

	console.log('sadasd', issetup);
	const teamPermission = new TeamPermission();
	useEffect(() => {
		setloading(true);
		teamPermission
			.services(GET_USERS, pagination)
			.then((res) => {
				setloading(false);
				const nData = res?.data?.userRolesAndClaims.filter((data) =>
					data?.roles?.find((item) => ['Payroll', 'HR', 'Manage Roles', 'SuperAdmin'].includes(item?.name))
				);
				setUserList(nData);
				setTotalPermissionCounts(res?.data?.totalCount);
			})
			.catch((err) => {
				setloading(false);
			});
	}, [pagination]);

	// New Desgin Manage Role table
	const columns = [
		{
			title: 'Username',
			dataIndex: 'userName',
			key: 'userName',
			sort: true,
			width: '15%',
			render: (val, detail) => {
				return (
					<div className="table-input-1" onClick={() => handleNewTable(val, detail)}>
						<p>{detail?.user?.userName}</p>
					</div>
				);
			},
		},
		{
			title: 'Admin Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			sort: true,
			width: '15%',
			render: (val, detail) => {
				return (
					<div className="table-input-1" onClick={() => handleNewTable(val, detail)}>
						<p>{detail?.user?.employeeName}</p>
					</div>
				);
			},
		},
		{
			title: 'Email Address',
			dataIndex: 'userEmail',
			key: 'userEmail',
			sort: true,
			width: '15%',
			render: (val, detail) => {
				return (
					<div className="table-input-1" onClick={() => handleNewTable(val, detail)}>
						<p>{detail?.user?.userEmail}</p>
					</div>
				);
			},
		},
		{
			title: 'Role',
			dataIndex: 'roles',
			key: 'roles',
			sort: true,
			width: '15%',
			render: (val, detail) => {
				const nRoles = val?.map((data) => data?.name?.toLowerCase());
				const newRoles = nRoles?.includes('superadmin')
					? [{ name: 'Super Admin' }]
					: val.filter((data) => data?.name?.toLowerCase() !== 'employee');
				return (
					<div className="table-input" onClick={() => handleNewTable(val, detail)}>
						{newRoles?.map((data) => (
							<Chips color={rolesChips(data?.name)}>{data?.name}</Chips>
						))}
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			sort: false,
			width: '5%',
			render: (val, detail) => {
				return (
					<div className="table-input" onClick={() => handleNewTable(val, detail)}>
						<ImageComponent src={focus_state_icons.NewEdit} />
						<p>Edit</p>
					</div>
				);
			},
		},
	];
	const rolesChips = (val) => {
		switch (val) {
			case 'Super Admin':
				return '#CEE8FF';
			case 'HR':
				return '#E2D2FE';
			case 'Payroll':
				return '#FEF5E8';
			case 'Manage Roles':
				return '#CCECE6';

			default:
				break;
		}
	};
	const handleNewTable = (value, detail) =>
		history.push({
			pathname: issetup ? '/permissionassign/' + detail?.user?.userName : '/user_role/' + detail?.user?.userName,
			state: { claims: detail?.claims, userList },
		});
	const handlePageChange = (page, value) => setPagination({ ...pagination, [value]: page });

	const handleSetupButton = () => (issetup ? history.push({ pathname: '/setup' }) : history.push({ pathname: '/' }));

	const filterClearAll = () => setPagination({ ...pagination, filter: '' });

	return (
		<ManageTeamPresentational
			{...{
				columns,
				list: userList,
				loading,
				issetup,
				handleSetupButton,
				handlePageChange,
				pagination,
				totalPermissionCounts,
				filterClearAll,
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		users: Selectors.users(state),
		usersLoading: Selectors.usersLoading(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: () => dispatch(Actions.creators.getUsers()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(ManageTeamFunctional);

export default Connected;
