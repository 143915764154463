import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import './dropdown.scss';

const { Option } = Select;

const EdenredBankDropdown = ({ labelClassName, defaultValue, disabled = false, onChange, options, type, className = '', labels }) => {
	const [marginTop, setMarginTop] = useState('0px');
	const [fontSize, setFontSize] = useState('14px');

	const handleChangeSelect = () => {
		setMarginTop('-20px');
		setFontSize('10px');
	};

	useEffect(() => {
		if (defaultValue) {
			handleChangeSelect();
		}
	}, [defaultValue]);

	return (
		<div className="dropdown-label">
			<Select
				defaultValue={defaultValue}
				className={`${className} edenRedDropdown ibmEdenRedDropdown ${type}`}
				onDropdownVisibleChange={handleChangeSelect}
				disabled={disabled}
				onChange={onChange}>
				{options.map((item, index) => (
					<Option key={index} value={item.value}>
						{item.label}
					</Option>
				))}
			</Select>
			{labels && (
				<label
					style={{ marginTop: marginTop, fontSize: fontSize }}
					className={`form-labels-dropdown${labelClassName ? ' ' + labelClassName : ''}`}>
					{labels}
				</label>
			)}
		</div>
	);
};

export default EdenredBankDropdown;
