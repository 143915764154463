import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import SetupTitle from '@pages/NewHrModuleSetup/Component/SetupTitle/SetupTitle';
import Stepper from '@pages/NewHrModuleSetup/Component/Stepper/Stepper';
import React from 'react';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClevertapReact from 'src/utils/clever-tap';
import './setupApprovalWorkflow.scss';

const SetupApprovalWorkflow = ({ navigate = '/leave/invite-employees', CleverTap = true }) => {
	const history = useHistory();
	const isFileUploaded = useSelector((state) => state?.hrSetup?.leaves?.isFileUploaded);
	const stepperItems = [
		{
			name: '1 - Departments',
			isActive: false,
			isCompleted: true,
			navigate: !CleverTap ? '/new-hr-features/departments' : '/leave/departments',
		},
		{
			name: '2 - Upload Data',
			isActive: false,
			isCompleted: true,
			navigate: !CleverTap ? '/new-hr-features/upload-leave' : '/leave/upload-leave',
			isMissing: isFileUploaded === false,
		},
		{
			name: '3 - How To Set Up Approval Workflow',
			isActive: true,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/setup-approval-workflow' : '/leave/setup-approval-workflow',
		},
		{
			name: '4 - How To Invite Employees',
			isActive: false,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/invite-employees' : '/leave/invite-employees',
		},
	];
	const steps = [
		{
			step: 'Step 1:',
			title: 'Assign Manager Permissions',
			description: 'Go to Company > Team Permissions and add new admins giving them “Manage Leaves” permissions.',
			imgSrc: images?.assignManagerPermissions,
		},
		{
			step: 'Step 2:',
			title: 'Assign Departments',
			description: 'Go to Leaves > Approval Workflow and assign to the departments the managers are going to approve the leave requests.',
			imgSrc: images?.assignDepartments,
		},
	];
	return (
		<div className="page-wrappers">
			<SetupTitle
				title="One last step: Upload your data"
				rightBtnText="Next"
				rightBtnType="primary"
				rightBtnClick={() => {
					ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStarted_Next3' : 'V1_1_Leave_Depart_Next3');
					history.push(navigate);
				}}
			/>
			<div className="content-wrapper">
				<Stepper listOfTabs={stepperItems} />
				<main className="main_wrapper">
					<div className="info_card">
						<ImageComponent src={focus_state_icons?.information} />
						<ETypo h6>Setup your approval workflow in case you have multiple manager to approve leave requests.</ETypo>
					</div>
					<div className="hero-wrapper">
						{steps?.map((item) => (
							<div className="step-box">
								<div className="step-content">
									<span className="step-number">{item?.step}</span>
									<ETypo h5>{item?.title}</ETypo>
									<span className="step-description">{item?.description}</span>
								</div>
								<div>
									<ImageComponent src={item?.imgSrc} />
								</div>
							</div>
						))}
					</div>
				</main>
			</div>
		</div>
	);
};

export default SetupApprovalWorkflow;
