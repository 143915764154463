import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MissingDetails from './missing-details';
import SubscribeDetails from './subscribe-details';
import PendingDetails from './pending-details';
import { useSelector } from 'react-redux';
import CancelledDetails from './cancelled-details';
import { icons } from 'src/assets/icons';
import { FAQ_LINK } from '@constants/index';
import ImageComponent from '@sharedComponent/image-component';
import { useHistory } from 'react-router-dom';
import { SITEMAP } from 'src/routes/sitemap';
import { UN_EMPLOYMENT_TAB_KEYS } from '../constants';
import './custom-tab.scss'

const CustomTabUnemployment = ({ tabArr, selectedEmployeeList, setSelectedEmployeeList, checkEmiratesNumber, checkMobileNumber, requestedTab }) => {
	const [activeTab, setActiveTab] = useState(requestedTab || tabArr[0].key);
	const { unemploymentAllCountLoading, unemploymentAllCount } = useSelector((state) => state?.unemploymentReducer) || {}
	const history = useHistory()
	const getActiveComponent = () => {
		let activeTabContent;
		switch (activeTab) {
			case UN_EMPLOYMENT_TAB_KEYS.SUBSCRIBED:
				activeTabContent = <SubscribeDetails />;
				break;
			case UN_EMPLOYMENT_TAB_KEYS.CANCELLED:
				activeTabContent = <CancelledDetails />;
				break;
			case UN_EMPLOYMENT_TAB_KEYS.MISSING_DETAILS:
				activeTabContent = (
					<MissingDetails
						{...{
							checkEmiratesNumber,
							checkMobileNumber,
							selectedEmployeeList,
							setSelectedEmployeeList,
						}}
					/>
				);
				break;
			case UN_EMPLOYMENT_TAB_KEYS.PENDING:
				activeTabContent = <PendingDetails />;
				break;
			default:
				break;
		}
		return activeTabContent;
	};

	const getUnemplymentCount = (tab) => {
		if (tab.key === UN_EMPLOYMENT_TAB_KEYS.SUBSCRIBED) {
			return unemploymentAllCount?.subscribedStatusCount || 0;
		} else if (tab.key === UN_EMPLOYMENT_TAB_KEYS.MISSING_DETAILS) {
			return unemploymentAllCount?.missingDetailCount || 0;
		} else if (tab.key === UN_EMPLOYMENT_TAB_KEYS.PENDING) {
			return unemploymentAllCount?.pendingStatusCount || 0;
		} else if (tab.key === UN_EMPLOYMENT_TAB_KEYS.CANCELLED) {
			return unemploymentAllCount?.cancelledStatusCount || 0;
		} else {
			return 0;
		}
	};

	const changeTab = (tab = '') => () => {
		setActiveTab(tab);
		history.push(`${SITEMAP.unemploymentInsurance.singleUpdate}?tab=${tab}`)
	}

	return (
		<div className="ed-tab-container">
			<div className="ed-tab-header-container">
				<div className='ed-tab-header'>
					{tabArr.map((tab) => {
						return (
							<div
								key={tab.key}
								onClick={changeTab(tab?.key)}
								className={`ed-tab-items ${activeTab === tab.key ? 'ed-tab-items__active' : ''}`}
							>
								{tab.tabHeading}{' '}
								<span className="number">
									{unemploymentAllCountLoading ? (
										<div className={`loader secondary_loader`} style={{ height: '10px', width: '10px' }} />
									) : (
										getUnemplymentCount(tab)
									)}
								</span>
							</div>
						);
					})}
				</div>
				<div>
					<a href={FAQ_LINK} target='_blank' rel="noopener noreferrer">
						<ImageComponent src={icons.not_focus.questionMarkCircle} alt="question-mark" srcset="" />
					</a>
				</div>
			</div>
			<div className="ed-tab-content invoice-details">{getActiveComponent()}</div>
		</div>
	);
};

CustomTabUnemployment.defaultProps = {
	tabArr: [
		{
			tabHeading: 'Tab1',
			key: 'tab1',
		},
		{
			tabHeading: 'Tab2',
			key: 'tab2',
		},
		{
			tabHeading: 'Tab3',
			key: 'tab3',
		},
		{
			tabHeading: 'Tab4',
			key: 'tab4',
		},
	],
};

export default CustomTabUnemployment;
CustomTabUnemployment.propTypes = {
	tabArr: PropTypes.array,
};
