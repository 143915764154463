import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './EdenredCarousel.scss';

const EdenredCarousel = ({
	children,
	className = '',
	autoplay = false,
	duration = 1500,
	prevButton = null,
	nextButton = null,
	pagination = 0,
	showPagination = true,
	onlySlider = false,
	defaultIndicator = false
}) => {
	const [current, setCurrent] = useState(0);
	const length = children?.length;

	useEffect(() => {
		if (autoplay) {
			const interval = setInterval(() => {
				setCurrent((prev) => (prev === length - 1 ? 0 : prev + 1));
			}, duration);
			return () => clearInterval(interval);
		}
		if (pagination !== 0) {
			setCurrent(pagination - 1);
		}
	}, []);

	useEffect(() => {
		if (defaultIndicator) {
			const ol = document.querySelector('.carousel-indicators')
			const liElements = ol.querySelectorAll('li');

			if (current === 0) {
				liElements[0].classList.add('active');
				liElements[1].classList.remove('active');
			}
			if (current === 1) {
				liElements[0].classList.remove('active');
				liElements[1].classList.add('active');
			}
		}
	}, [current])


	const nextSlide = () => {
		setCurrent(current === length - 1 ? 0 : current + 1);
	};
	const prevSlide = () => {
		setCurrent(current === 0 ? length - 1 : current - 1);
	};
	const handleSlider = (inx) => {
		setCurrent(inx);
	};
	return (
		<div className="slider-carousel">
			{!onlySlider && (
				<div className={`slider ${className}`}>
					<div className="prev-button" onClick={prevSlide}>
						{prevButton ? (
							prevButton
						) : (
							<div className="left-arrow">
								<span />
							</div>
						)}
					</div>
					{defaultIndicator &&
						<ol className="carousel-indicators">
							<li data-target="#carouselIndicators" data-slide-to="p_big1.png" className="active" onClick={prevSlide}></li>
							<li data-target="#carouselIndicators" data-slide-to="p_big2.png" className="" onClick={prevSlide}></li>
						</ol>
					}
					<div className="carousel">
						{children?.map((slide, idx) => {
							return (
								<div key={idx} className={idx === current ? 'active' : 'slide'}>
									{idx === current && slide}
								</div>
							);
						})}
					</div>
					<div className="next-button" onClick={nextSlide}>
						{nextButton ? (
							nextButton
						) : (
							<div className="right-arrow">
								<span />
							</div>
						)}
					</div>
				</div>
			)}
			{showPagination && (
				<div className="bottom-slide">
					{children?.map((slide, idx) => {
						return <span key={idx} className={idx === current ? 'active' : 'pagination'} onClick={() => handleSlider(idx)} />;
					})}
				</div>
			)}
		</div>
	);
};
export default EdenredCarousel;
EdenredCarousel.propTypes = {
	children: PropTypes.any.isRequired,
	className: PropTypes.string,
	autoplay: PropTypes.bool,
	duration: PropTypes.number,
	prevButton: PropTypes.string,
	nextButton: PropTypes.string,
	pagination: PropTypes.number,
	numberOfSlide: PropTypes.number,
	showPagination: PropTypes.bool,
	onlySlider: PropTypes.bool,
};
