import React from 'react';
import { icons } from '@assets/icons/index';
import ImageComponent from '@sharedComponent/image-component';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';

const DatePicker = ({ className, name = null, onChange, edit, value, label, option = {} }) => {
	const options = {
		allowInput: true,
		dateFormat: 'd/m/Y',
		disableMobile: true,
		...option,
	};
	return (
		<div className="date_picker_doc_profile">
			{label && <div className="form_date_field_label">{label}</div>}
			<Flatpickr
				options={options}
				name={name}
				className={className}
				placeholder="DD/MM/YYYY"
				value={value}
				onClose={onChange}
				disabled={!edit}
			/>
		</div>
	);
};
export default DatePicker;
