import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../profile-action';
import Axios from 'axios';
export function* nationalityService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/countries`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getNationalityDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getNationalityDataError(err));
	}
}
export function* inviteEmpToPortalServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const response = action?.payload;
	try {
		const uri = `${DOMAIN_URL}/Users/users/${corporateId}/create-employee-user`;
		const res = yield call(Axios.post, uri, response);
		yield put(Actions.creators.inviteEmpToPortalSuccess(res.data));
		yield put(Actions.creators.conformEmail(false));
	} catch (err) {
		yield put(Actions.creators.inviteEmpToPortalFail(err));
	}
}
export function* updateEmailServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const emp_Id = action?.payload?.emp_Id;
	const email = action?.payload?.email;
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/${emp_Id}/update-email?emailId=${email}`;
		const res = yield call(Axios.patch, uri);
		yield put(Actions.creators.updateEmailSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.updateEmailFail(err));
	}
}

export default function* payrollProfileWatcher() {
	yield takeEvery(Actions.types.GET_NATIONALITY_START, nationalityService);
	yield takeEvery(Actions.types.INVITE_EMPTOPORTAL_START, inviteEmpToPortalServices);
	yield takeEvery(Actions.types.UPDATE_EMAIL_START, updateEmailServices);
}
