import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import Selectors from '@sharedComponent/app-action-reducer/selectors';
import ETypo from '@pages/DesignSystem/Typo';
import Inputbox from '@pages/DesignSystem/Input-Field';

const CompanyDetails = ({ corporateIds }) => {
	const [companyDetails, setCompanyDetails] = useState({
		name: '',
		address2: '',
		address3: '',
		buildingName: '',
		landmark: '',
		emailId: '',
		city: '',
	});
	const setComponyDetailss = (label, value) => setCompanyDetails({ ...companyDetails, [label]: value });
	const cid = localStorage.getItem('cid');

	useEffect(() => {
		let companyDetail = corporateIds?.filter((value) => value?.corporateId == cid);
		setCompanyDetails({
			...companyDetails,
			name: companyDetail?.[0]?.corporateName || null,
			emailId: companyDetail?.[0]?.emailId || null,
			address2: companyDetail?.[0]?.address2 || null,
			address3: companyDetail?.[0]?.address3 || null,
			buildingName: companyDetail?.[0]?.buildingName || null,
			landmark: companyDetail?.[0]?.landmark || null,
			emailId: companyDetail?.[0]?.emailId || null,
			city: companyDetail?.[0]?.city || '',
		});
	}, []);
	return (
		<>
			<Row className="company_information_company_details_row">
				<Row className="company_details_row">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company_details_title">
						<ETypo h2 bold>
							Enter Company Details
						</ETypo>
					</Col>

					<Col xl={12} lg={12} md={12} sm={24} xs={24} className="company_details_name">
						<Inputbox placeholder="Enter Name" name="company_name" value={companyDetails.name} label="Name" disabled />
					</Col>
					<Col xl={12} lg={12} md={12} sm={24} xs={24} className="company_details_address">
						<Inputbox
							name="company_address"
							placeholder="Default"
							value={companyDetails?.address2}
							onChange={(e) => setComponyDetailss('address2', e?.target?.value)}
							label="Area"
							edit={false}
							disabled
						/>
					</Col>
					<Col xl={12} lg={12} md={12} sm={24} xs={24} className="company_details_area">
						<Inputbox
							name="company_city"
							onChange={(e) => setComponyDetailss('address3', e?.target?.value)}
							value={companyDetails?.address3}
							label="Address"
							placeholder="Enter Address"
							edit={false}
							disabled
						/>
					</Col>
					<Col xl={12} lg={12} md={12} sm={24} xs={24} className="company_details_address">
						<Inputbox
							name="company_city"
							onChange={(e) => setComponyDetailss('address3', e?.target?.value)}
							value={companyDetails?.address3}
							label="Emirates"
							placeholder="Dubai"
							edit={false}
							disabled
						/>
					</Col>
				</Row>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		corporateIds: Selectors.corporateIds(state),
		user: state.oidc.user,
	};
};

const Connected = connect(mapStateToProps, null)(CompanyDetails);

export default Connected;
