import Action from '@pages/payroll-plus-new/action';
import ClevertapReact from 'src/utils/clever-tap';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import moment from 'moment';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './salary-month-modal.scss';

const SalaryMonthModal = ({ showModal, handleCloseModal, handleProceed, SalaryMonthData, loading }) => {
	const dispatch = useDispatch();
	const [date, setDate] = useState({});
	const [requestParams, setRequestParams] = useState({
		accountType: '',
		bankName: '',
		establishment: '',
		includeEmployeeCounts: false,
		pageNumber: 1,
		pageSize: 10,
		payrollBlockCheck: true,
		payrollChannel: '',
		previousPayrollUploadId: '',
		previoustotalPaidEmps: null,
		search: '',
		status: 'ACTIVE',
		month: moment().format('MM'),
		year: moment().format('yyyy'),
	});
	useEffect(() => {
		dispatch(Action.creators.getSalaryStatus());
	}, []);
	const PayrollSalaryStatus = useSelector((state) => state?.counter?.payrollSalaryStatus);
	const leaveDetail = [
		{
			name: 'Name,Employee ID',
			value: PayrollSalaryStatus?.map((salariesStatusData) => {
				if (salariesStatusData?.salaryProcessStatus === 'Salaries Paid') {
					return { ...salariesStatusData, salaryProcessStatus: 'Paid', isActive: false };
				} else if (salariesStatusData?.salaryProcessStatus === 'Salaries Due') {
					return { ...salariesStatusData, salaryProcessStatus: 'Salaries Due', isActive: true };
				} else {
					return salariesStatusData;
				}
			}),
			param: 'nameId',
		},
	];
	useEffect(() => {
		setRequestParams({
			...requestParams,
			month: moment(date?.salaryDate).format('MM'),
			year: moment(date?.salaryDate).format('YYYY'),
		});
	}, [date]);
	const onChange = (data) => {
		ClevertapReact.event('SelectMonth');
		setDate({
			...date,
			salaryDate: data?.salaryDate,
		});
		setRequestParams({
			...requestParams,
			month: moment(data?.salaryDate).format('MM'),
			year: moment(data?.salaryDate).format('YYYY'),
		});
		SalaryMonthData(data);
	};
	return (
		<Edenredmodal className="payroll_wps_nonwps" isOpen={showModal} close={handleCloseModal}>
			<div className="payroll_select_date">
				<ETypo bold h4>
					What month are your Additions & Deductions for?
				</ETypo>
				<ETypo light b1 color="#737780" style={{ marginTop: '8px' }}>
					Please select a date to proceed
				</ETypo>
				<div className="paroll_select_date_dropdown">
					<MultiSelect
						Option={leaveDetail?.[0]?.value}
						value={date?.salaryDate}
						placeholder="Select Month"
						onChange={onChange}
						dropDownValue="salaryDate"
						dropDownSubValue="salaryProcessStatus"
						dateFormat="MMMM YYYY"
						showIcon={false}
						prefix={<img src={not_focus_icons?.payrollCalender} />}
					/>
				</div>
				<div className="payroll_select_date_button">
					<EdenredButtons onClick={() => handleCloseModal()} btnType="secondary">
						Cancel
					</EdenredButtons>
					<EdenredButtons
						btnType={date?.salaryDate ? 'primary' : 'secondary'}
						disabled={date?.salaryDate ? false : true}
						onClick={() => handleProceed(date)}
						loading={loading}>
						Proceed
					</EdenredButtons>
				</div>
			</div>
		</Edenredmodal>
	);
};

export default SalaryMonthModal;
