import React from 'react';
import propTypes from 'prop-types';
import Tooltip from '../Tool-Tip';
import './EdenredButton.scss';
import { ContentLoader } from '@sharedComponent/content-loader';

const EdenredButtons = ({
	children,
	onPress,
	leftIcon,
	rightIcon,
	disabled = false,
	className = '',
	inactive = false,
	btnType = 'primary',
	style,
	type = '',
	onClick,
	toolTipClassName,
	secondaryactive,
	loading = false,
	noShadow = false,
	primaryColor = '',
}) => {
	const _props = {
		children,
		onPress,
		leftIcon,
		rightIcon,
		disabled,
		className,
		inactive,
		btnType,
		style,
		type,
		onClick,
		secondaryactive,
		loading,
		noShadow,
	};

	if (toolTipClassName && typeof toolTipClassName === 'string') {
		return (
			<Tooltip content={children} className={toolTipClassName}>
				<Button {..._props} />
			</Tooltip>
		);
	} else {
		return <Button {..._props} />;
	}
};

const Button = ({
	children,
	onPress,
	leftIcon,
	rightIcon,
	disabled,
	className,
	inactive,
	btnType,
	style,
	type,
	onClick,
	secondaryactive,
	loading,
	noShadow,
}) => {
	return (
		<button
			// title="this is the tool tip attribute to show tooltip"
			className={` buttonnew ${className} ${btnType}-btn
			${inactive ? 'inactive' : ''}	${secondaryactive ? 'secondary-active' : ''} ${loading ? 'block_click' : ''} ${noShadow ? 'no_shadow' : ''}`}
			type={type}
			disabled={disabled}
			style={style}
			onClick={onClick}
			// onPress={onPress}
		>
			{loading ? (
				<div className={`loader ${btnType === 'primary' ? 'primary_loader' : 'secondary_loader'}`} />
			) : (
				<>
					{leftIcon && leftIcon}
					<span>{children}</span>
					{rightIcon && rightIcon}
				</>
			)}
		</button>
	);
};

export default EdenredButtons;
EdenredButtons.propTypes = {
	children: propTypes.any.isRequired,
	leftIcon: propTypes.any,
	rightIcon: propTypes.bool,
	onPress: propTypes.string,
	disabled: propTypes.bool,
	loading: propTypes.bool,
	className: propTypes.string,
	inactive: propTypes.bool,
	type: propTypes.string,
	style: propTypes.object,
	onClick: propTypes.func,
};
