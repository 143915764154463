import { createSelector } from 'reselect';
import { initState } from './reducer';

const EmpDocData = (state) => state.individualData || initState;

const employeeValues = createSelector(EmpDocData, (cnter) => cnter.employeeData);
const employeeDocumentImages = createSelector(EmpDocData, (cnter) => cnter.employeeImages);
const empDataLoading = createSelector(EmpDocData, (cnter) => cnter.empDataLoading);
const updatedData = createSelector(EmpDocData, (cnter) => cnter.updatedData);
const uploadDataLoading = createSelector(EmpDocData, (cnter) => cnter.uploadDataLoading);
const empImageLoading = createSelector(EmpDocData, (cnter) => cnter.empImageLoading);
const deleteImages = createSelector(EmpDocData, (cnter) => cnter.deleteImages);
const deleteImagesLoading = createSelector(EmpDocData, (cnter) => cnter.deleteImagesLoading);

export default {
	employeeValues,
	employeeDocumentImages,
	empDataLoading,
	updatedData,
	uploadDataLoading,
	empImageLoading,
	deleteImages,
	deleteImagesLoading,
};
