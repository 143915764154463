import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';

const AddEmployeeIBANNumber = () => {
	const { state } = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		const { iBanNumber } = values;
		history.push({ pathname: '/employees/add/individual/employee/bankname', state: { ...state, iBanNumber } });
	};
	return (
		<>
			<EdenredHeader />
			<Row className="add_employee_iban_number_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_employee_iban_number_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName + '’s' : ''} IBAN Number?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_employee_iban_number_description">
					<EdenredTypo>23-digit IBAN for employees with bank accounts.</EdenredTypo>
				</Col>
				<Col xl={10} lg={20} md={24} sm={24} xs={24} className="add_employee_iban_number_form_section">
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="iBanNumber"
							rules={[
								{ required: true, message: 'Please provide the IBAN Number of the employee' },
								{
									max: 23,
									message: 'Max length should be 23',
								},
							]}>
							<Input placeholder="XX00 0000 0000 0000 0000 00" labels="IBAN Number" labelClassName="inputlabelrequired" />
						</Form.Item>
						<Col xl={18} lg={18} md={24} sm={24} xs={24}>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default AddEmployeeIBANNumber;
