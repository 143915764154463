import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ETypo from '@pages/DesignSystem/Typo';
import { DATE_FORMAT_LEAVE } from '@constants/app-constants';
import LeaveStatusPresentational from './leaveStatusPresentational';
import Selector from '../selector';
import action from '../action';
import LeaveStatusMobile from '../mob-leave-status/LeaveStatusMobile';
import './leavestatusPresentational.scss';

function LeaveStatusFunctional({ activeLeaves, counts, countLoader }) {
	const [details, setDetails] = useState({ onGoingLeave: [], currentLeave: [] });
	const [payload, setPayload] = useState({ leaveStatus: 0, pageNumber: 1, pageSize: 10 });
	const detail = counts?.leaveStatus;
	let pageCount = counts?.totalCount;

	const showdate = moment().format('YYYY-MM-DD');
	useEffect(() => {
		let currentLeave = detail?.filter((data) => {
			if (moment(data.startDate).format('YYYY-MM-DD') <= showdate && moment(data.endDate).format('YYYY-MM-DD') >= showdate) {
				return data;
			}
		});
		let onGoingLeave = detail?.filter((data) => {
			if (moment(data.startDate).format('YYYY-MM-DD') > showdate && moment(data.endDate).format('YYYY-MM-DD') > showdate) {
				return data;
			}
		});
		setDetails({
			...details,
			currentLeave,
			onGoingLeave,
		});
	}, [counts]);
	const { currentLeave, onGoingLeave } = details;
	const columns = [
		{
			title: 'Employee',
			dataIndex: ['employeeName'],
			key: 'employeeName',
			width: '21%',
			render: (employeeName, row) => {
				return (
					<ETypo b2 bold className="emp-name-style">
						{row.employeeName}
					</ETypo>
				);
			},
		},
		{
			title: 'Emp id',
			dataIndex: ['employeeId'],
			key: 'employeeId',
			width: '12%',
			render: (employeeId, row) => {
				return (
					<ETypo b2 light className="status-other-field-style">
						{row.employeeId}
					</ETypo>
				);
			},
		},
		{
			title: 'Department',
			dataIndex: 'departmentName',
			key: 'departmentName',
			width: '15%',
			render: (departmentName) => {
				return (
					<ETypo b2 light className="status-other-field-style">
						{departmentName}
					</ETypo>
				);
			},
		},
		{
			title: 'Leave Type',
			dataIndex: 'leaveTypeName',
			key: 'leaveTypeName',
			width: '15%',
			render: (leaveTypeName) => {
				return (
					<ETypo b2 light className="status-other-field-style">
						{leaveTypeName}
					</ETypo>
				);
			},
		},
		{
			title: 'Date',
			dataIndex: 'startDate',
			key: 'startDate',
			width: '22%',
			render: (startDate, row) => {
				return (
					<ETypo b2 light className="status-other-field-style">
						{moment(row.startDate).format(DATE_FORMAT_LEAVE.DD_MM_YYYY)} - {moment(row.endDate).format(DATE_FORMAT_LEAVE.DD_MM_YYYY)}
					</ETypo>
				);
			},
		},
		{
			title: 'Days',
			dataIndex: 'totalDays',
			key: 'totalDays',
			width: '5%',
			render: (totalDays) => {
				return (
					<ETypo b2 light className="status-other-field-style">
						{totalDays}
					</ETypo>
				);
			},
		},
	];

	useEffect(() => {
		activeLeaves(payload);
	}, [payload]);

	const onPageNumberandSizeSelect = (value, type) => {
		setPayload({ ...payload, [type]: value });
	};

	return (
		<>
			<div className="leave-status-web">
				<LeaveStatusPresentational {...{ columns, onGoingLeave, currentLeave, countLoader, onPageNumberandSizeSelect, payload, pageCount }} />
			</div>
			<div className="leave-status-mobile">
				<LeaveStatusMobile {...{ onGoingLeave, currentLeave, countLoader }} />
			</div>
		</>
	);
}
const mapStateToProps = (state) => {
	return {
		countLoader: Selector.employeeListLoadings(state),
		counts: Selector.employeeLists(state),
		counterror: Selector.employeeListErrors(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		activeLeaves: (payload) => dispatch(action.creators.getCurrenlyLeaveStart(payload)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(LeaveStatusFunctional);

export default Connected;
