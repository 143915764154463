import Actions from './action';
export const initState = {
	employeeData: {},
	employeeImages: [],
	empDataLoading: false,
	updatedData: {},
	uploadDataLoading: false,
	empImageLoading: false,
	deleteImages: [],
	deleteImagesLoading: false,
	duplicateLoading: false,
	duplicateCheckData: '',
};

const counterReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.UPDATE_PROFILE_DATA: {
			return {
				...state,
				updatedData: action.res,
				uploadDataLoading: true,
			};
		}
		case Actions.types.UPDATE_PROFILE_DATA_SUCCESS: {
			return {
				...state,
				updatedData: action.res,
				uploadDataLoading: false,
			};
		}

		case Actions.types.UPDATE_PROFILE_DATA_ERROR: {
			return {
				...state,
				updatedData: action.res,
				uploadDataLoading: false,
			};
		}
		case Actions.types.GET_EMPLOYEE_INDIVIDUAL_DATA: {
			return {
				...state,
				employeeData: {},
				empDataLoading: true,
			};
		}
		case Actions.types.GET_EMPLOYEE_INDIVIDUAL_DATA_RESET: {
			return {
				...state,
				employeeData: {},
			};
		}
		case Actions.types.GET_EMPLOYEE_INDIVIDUAL_DATA_SUCCESS: {
			return {
				...state,
				employeeData: action.res,
				empDataLoading: false,
			};
		}
		case Actions.types.GET_EMPLOYEE_INDIVIDUAL_DATA_ERROR: {
			return {
				...state,
				employeeData: {},
				empDataLoading: false,
			};
		}
		case Actions.types.GET_EMPLOYEE_DOCUMENT_IMAGES: {
			return {
				...state,
				employeeImages: action.res,
				empImageLoading: true,
			};
		}
		case Actions.types.GET_EMPLOYEE_DOCUMENT_IMAGES_SUCCESS: {
			return {
				...state,
				employeeImages: action.res,
				empImageLoading: false,
			};
		}
		case Actions.types.GET_EMPLOYEE_DOCUMENT_IMAGES_ERROR: {
			return {
				...state,
				employeeImages: action.res,
				empImageLoading: false,
			};
		}
		case Actions.types.GET_DELETE_IMAGES: {
			return {
				...state,
				deleteImages: action.res,
				deleteImagesLoading: true,
			};
		}
		case Actions.types.GET_DELETE_IMAGES_SUCCESS: {
			return {
				...state,
				deleteImages: action.res,
				deleteImagesLoading: false,
			};
		}
		case Actions.types.GET_DELETE_IMAGES_ERROR: {
			return {
				...state,
				deleteImages: action.res,
				deleteImagesLoading: false,
			};
		}
		case Actions.types.CHECK_DUPLICATE_DOCUMENT_START: {
			return {
				...state,
				duplicateLoading: true,
			};
		}
		case Actions.types.CHECK_DUPLICATE_DOCUMENT_SUCCESS: {
			return {
				...state,
				duplicateLoading: false,
				duplicateCheckData: action?.res ? 'Duplicate Emirates ID Found' : '',
			};
		}
		case Actions.types.CHECK_DUPLICATE_DOCUMENT_FAIL: {
			return {
				...state,
				duplicateLoading: false,
				duplicateCheckData: '',
			};
		}
		default:
			return state;
	}
};
export default counterReducer;
