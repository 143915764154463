import { useRef, useEffect } from 'react';

/**
 * A custom hook that triggers a callback function when a target element is in view,
 * typically used for implementing infinite scroll functionality.
 *
 * @param {Function} callback - The function to be called when the target element comes into view.
 * @param {boolean} isLoading - Indicates whether data is currently being loaded,
 * preventing the observer from triggering when true.
 * @param {boolean} hasMore - Indicates if there are more items to load. The callback is triggered
 * only if this is true.
 * @param {boolean} updateOnMount - If true, ensures that the observer is updated when the component mounts.
 * @param {string} [rootMargin='20px'] - Margin around the root. Can be used to trigger the callback
 * slightly before or after the target comes into view. Default is '20px'.
 * @param {number} [threshold=0.5] - A number between 0 and 1, representing how much of the target element
 * should be visible before the callback is triggered. Default is 0.5 (50% visibility).
 *
 * @returns {React.MutableRefObject} observerRef - A ref that should be assigned to the sentinel element
 * (the element that will trigger the callback when in view).
 *
 * @example
 * // Usage:
 * const sentinelRef = useInfiniteScroll(() => {
 *    // Your load more function
 * }, isLoading, hasMore, updateOnMount);
 *
 * <div ref={sentinelRef}></div>
 */
const useInfiniteScroll = (callback, isLoading, hasMore, updateOnMount, rootMargin = '20px', threshold = 0.5) => {
	const observerRef = useRef(null);

	useEffect(() => {
		if (isLoading) return; // Don't observe while loading

		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasMore) {
					callback(); // Trigger callback when the sentinel is in view and more data is available
				}
			},
			{
				root: null, // Scroll relative to the viewport
				rootMargin, // Trigger before reaching the exact end
				threshold, // Trigger when 100% of the sentinel is visible
			}
		);

		if (observerRef.current) observer.observe(observerRef.current);

		return () => observer.disconnect(); // Cleanup on component unmount
	}, [isLoading, hasMore, updateOnMount]);

	return observerRef; // Return the ref to be assigned to the sentinel element
};

export default useInfiniteScroll;
