import React from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from '@assets/icons/not_focus';

const DashboardFooter = ({ corporateDetails = {} }) => {
	return (
		<section className="main-dashboard-container-footer fade-right">
			<div>
				<ImageComponent src={not_focus_icons.supportEmailIcon} />
				<ETypo light={true} b2 color="#181919">
					For any queries, please mail :
				</ETypo>
				<ETypo bold b2 color="#181919">
					{` support.ae@edenred.com`}
				</ETypo>
			</div>

			<div>
				<ImageComponent src={not_focus_icons.supportHelpIcon} />
				<ETypo light b2 color="#181919">
					For escalations, please contact :
				</ETypo>
				<ETypo bold b2 color="#181919">
					{corporateDetails?.name} {corporateDetails?.email}
					<ETypo light b2 color="#181919">
						|
					</ETypo>
					{corporateDetails?.phNumber}
				</ETypo>
			</div>
		</section>
	);
};

export default DashboardFooter;
