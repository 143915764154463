import React from 'react';
import Teamspermissionfunctional from './components/teams-permission-functional';
import ManageTeamFunctional from '../../pages/team-permission/manage-team-members/components/manage-team-functional';
import './setupPermission.scss';
const SetupteamsandPermissions = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F7F8FA', width: '100%', height: '100%' }}>
			<Teamspermissionfunctional />
		</div>
	);
};

export default SetupteamsandPermissions;
