import Actions from './action';
export const initState = {
	isDownloadPdfLoading: false,
	downloadPdfData: [],
};

const payrollStatusPdf = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_DOWNLOAD_PDF_START: {
			return {
				...state,
                isDownloadPdfLoading:true,
			};
		}
		case Actions.types.GET_DOWNLOAD_PDF_SUCCESS: {
			return {
				...state,
				isDownloadPdfLoading:false,
                downloadPdfData:action?.res
			};
		}
		case Actions.types.GET_DOWNLOAD_PDF_FAIL: {
			return {
				...state,
                isDownloadPdfLoading:false,
                downloadPdfData:[]
			};
        }
		default:
			return state;
	}
};
export default payrollStatusPdf;
