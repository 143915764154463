import React, { useEffect, useState } from 'react';
import { DotLoader } from 'react-spinners';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import Action from '@sharedComponent/app-action-reducer/action';
import { LEAVE_APPROVE } from '@constants/app-constants';
import { images } from 'src/assets/img';
import ClevertapReact from 'src/utils/clever-tap';
import action from '../action';
import Selectors from '../selector';
import LeavesToApproveMob from './leaves-to-approve-mob';
import LeavesToApprove from './leaves-to-approve-presentational';
import LeaveSetup from '@pages/NewHrModuleSetup/Leaves/LeaveSetup/LeaveSetup';

function LeavesToApproveFunctional({
	leaveToApprove = [],
	leaveToApproveCounts = '',
	leaveToApproveLoader = false,
	overLappingLeaves = [],
	overLappingLeaveCounts = '',
	overLappingLeaveLoader = false,
	approveRejectLeave = [],
	approveRejectLoading = false,
	userNavigationMenu,
}) {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [popupState, setPopupState] = useState(false);
	const [popupMessageState, setPopupMessageState] = useState(false);
	const [popupStateMob, setPopupStateMob] = useState(false);
	const [popupMessageStateMob, setPopupMessageStateMob] = useState(false);
	const [value, setvalue] = useState({});
	const [leaveType, setLeaveType] = useState('');
	const [Department, setDepartment] = useState();
	const [showToast, setShowToast] = useState(false);
	const passportDetails = [
		{
			image: images.Check,
			name: 'Passport',
		},
		{
			image: images.Leave_Warning,
			name: 'Visa',
		},
	];
	const { hrLeaveCompleted } = useSelector((state) => state?.sharedStates?.userNavigation);
	const userSubscriptionDetails = useSelector((state) => state?.sharedStates?.subscriptionProds?.[0]);
	useEffect(() => {
		leaveToApprove();
	}, []);

	const handlePopupClick = (message, item, mode) => {
		const cleverTapProperties = {
			leaveType: item?.leaveType,
			StartDate: item?.startDate,
			EndDate: item?.endDate,
			Days: item?.leaveDays,
			BalanceRemaining: item?.remainingBalance,
			Note: item?.note ? 'yes' : 'no',
			Attachment: item?.attachment ? 'yes' : 'no',
		};
		ClevertapReact.event(mode === 'Approve' ? 'Leave_ApproveLeave' : 'Leave_RejectLeave', {
			cleverTapProperties,
		});
		setPopupState(true);
		setPopupMessageState(message);
		setLeaveType(item?.leaveType);
		const Data = {
			empolyeeId: item?.employeeId,
			leaveId: item?.leaveId,
			status: message === LEAVE_APPROVE?.APPROVE ? 'Approved' : 'Rejected',
			managerId: '',
			UmrahLeaveFlag: '',
			employeeName: item?.employeeName,
			leaveType: item?.leaveType,
		};
		setvalue(Data);
	};
	const handlePopupClickMob = (message, item) => {
		setPopupStateMob(true);
		setPopupMessageStateMob(message);
		setLeaveType(item.leaveType);
		const Data = {
			empolyeeId: item?.employeeId,
			leaveId: item?.leaveId,
			status: message === LEAVE_APPROVE?.APPROVE ? 'Approved' : 'Rejected',
			managerId: '',
			umrahLeaveFlag: '',
		};
		setvalue(Data);
	};
	const handlePopupClick1 = (id) => {
		if (id === 'Umrah' || id === 'Annual') {
			value.umrahLeaveFlag = id;
		} else {
			value.umrahLeaveFlag = '';
		}
		approveRejectLeave(value);
		setTimeout(() => {
			setPopupState(false);
			setPopupStateMob(false);
		}, 1000);
		setTimeout(() => {
			setShowToast(true);
		}, 2500);
	};
	const handlePopupClose = () => setPopupState(false);
	const handlePopupCloseMob = () => setPopupStateMob(false);

	const showModal = (item) => {
		ClevertapReact.event('Leave_OverlappingLeaves', { leaveType: item?.leaveType });
		setIsModalVisible(true);
		setDepartment(item?.departmentName);
		const post = {
			status: '',
			startDate: moment(item?.startDate).format('YYYY-MM-DD') || '',
			enddate: moment(item?.endDate).format('YYYY-MM-DD') || '',
			id: item?.departmentId || '',
		};
		overLappingLeaves(post);
	};

	if (leaveToApproveLoader) {
		return (
			<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
				<DotLoader size={100} color={'#e91d24'} loading />
			</div>
		);
	}
	if (hrLeaveCompleted !== true && (userSubscriptionDetails?.isFreeTrailOn || userSubscriptionDetails?.isSubscriptionOn === true)) {
		return (
			<>
				<LeaveSetup />
			</>
		);
	}
	return (
		<>
			<div className="leave-to-approve-web">
				<LeavesToApprove
					{...{
						isModalVisible,
						setIsModalVisible,
						showModal,
						dataOverlap: overLappingLeaveCounts?.leaves,
						data: leaveToApproveCounts.activeLeaves,
						popupState,
						handlePopupClose,
						popupMessageState,
						setPopupMessageState,
						handlePopupClick,
						handlePopupClick1,
						approveMessage: LEAVE_APPROVE?.APPROVE,
						rejectMessage: LEAVE_APPROVE?.REJECT,
						overLappingLeaveLoader,
						passportDetails,
						approveRejectLoading,
						leaveType,
						Department,
						userNavigationMenu,
						showToast,
						setShowToast,
						value,
					}}
				/>
			</div>
			<div className="leave-to-approve-mobile">
				<LeavesToApproveMob
					{...{
						isModalVisible,
						setIsModalVisible,
						showModal,
						dataOverlap: overLappingLeaveCounts?.leaves,
						data: leaveToApproveCounts.activeLeaves,
						popupStateMob,
						handlePopupCloseMob,
						popupMessageStateMob,
						setPopupMessageState,
						handlePopupClickMob,
						handlePopupClick1,
						approveMessage: LEAVE_APPROVE?.APPROVE,
						rejectMessage: LEAVE_APPROVE?.REJECT,
						overLappingLeaveLoader,
						approveRejectLoading,
						leaveType,
						Department,
					}}
				/>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		leaveToApproveLoader: Selectors.leaveToapproveLoading(state),
		leaveToApproveCounts: Selectors.leaveToApprove(state),
		leaveToApproveError: Selectors.leaveToApproveError(state),
		overLappingLeaveLoader: Selectors.overLappingLeaveLoading(state),
		overLappingLeaveCounts: Selectors.overLappingLeave(state),
		overLappingleaveError: Selectors.overLappingLeaveError(state),
		approveRejectLoading: Selectors.approveRejectLoading(state),
		approveReject: Selectors.approveReject(state),
		approveRejectError: Selectors.approveRejectError(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		leaveToApprove: () => dispatch(action.creators.getLeaveToApproveStart()),
		overLappingLeaves: (post) => dispatch(action.creators.getOverLappingLeavesStart(post)),
		approveRejectLeave: (Data) => dispatch(action.creators.getApproveStart(Data)),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(LeavesToApproveFunctional);

export default Connected;
