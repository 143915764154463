import { createSelector } from 'reselect';

import { initState } from './approval-reducer';

const WorkflowReducer = (state) => state.WorkflowReducer || initState;
const isApprovalWorkflowLoading = createSelector(WorkflowReducer, (cnter) => cnter.isApprovalWorkflowLoading);
const approvalWorkflowDetails = createSelector(WorkflowReducer, (cnter) => cnter.approvalWorkflowDetails);
const approvalWorkflowError = createSelector(WorkflowReducer, (cnter) => cnter.approvalWorkflowError);
const isApprovalWorkflowUsertLoading = createSelector(WorkflowReducer, (cnter) => cnter.isApprovalWorkflowUsertLoading);
const approvalWorkflowUserDetails = createSelector(WorkflowReducer, (cnter) => cnter.approvalWorkflowUserDetails);
const approvalWorkflowUserError = createSelector(WorkflowReducer, (cnter) => cnter.approvalWorkflowUserError);
const isApprovalWorkflowSubmitLoading = createSelector(WorkflowReducer, (cnter) => cnter.isApprovalWorkflowSubmitLoading);
const approvalWorkflowSubmitted = createSelector(WorkflowReducer, (cnter) => cnter.approvalWorkflowSubmittedFail);
const approvalWorkflowSubmittedFail = createSelector(WorkflowReducer, (cnter) => cnter.allowanceError);
const isSavePopUp = createSelector(WorkflowReducer, (cnter) => cnter.isSavePopUp);
const isSavedSuccessfully = createSelector(WorkflowReducer, (cnter) => cnter.isSavedSuccessfully);

export default {
	isApprovalWorkflowLoading,
	approvalWorkflowDetails,
	approvalWorkflowError,
	isApprovalWorkflowUsertLoading,
	approvalWorkflowUserDetails,
	approvalWorkflowUserError,
	isApprovalWorkflowSubmitLoading,
	approvalWorkflowSubmitted,
	approvalWorkflowSubmittedFail,
	isSavePopUp,
	isSavedSuccessfully,
};
