import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import ForgotPasswordPresentational from './forgot-password-presentational';
import { useHistory } from 'react-router-dom';
import UserService from 'src/services/user-service';
import { successToast } from 'src/utils/toast';
import Popup from 'src/utils/popup/popups';

const ForgotPasswordFunctional = () => {
	const history = useHistory();
	const [loading, setloading] = useState(false);
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const api = new UserService();

	// To disable submit button at the beginning.
	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = ({ username }) => {
		setloading(true);
		api.requestNewPassword(username)
			.then((result) => {
				setloading(false);

				setpopupVisible(true);
				setPopupMessage({
					titleMessage: 'We’ve sent your password to your email.',
					subMessage: null,
				});
				// successToast({
				// 	msg: 'We’ve sent your password to your email.',
				// 	icon: 'Mail',
				// });
			})
			.catch((err) => {
				setloading(false);
			});
	};

	const closePopup = () => {
		setpopupVisible(false);
		setTimeout(() => {
			history.push('/');
		}, 2000);
	};

	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
			<ForgotPasswordPresentational loading={loading} onFinish={onFinish} form={form} />
		</>
	);
};

export default ForgotPasswordFunctional;
