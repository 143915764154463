import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Col, Row, Dropdown, Pagination, Spin, Input } from 'antd';
import { icons } from 'src/assets/icons';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import EdenredSearch from '@sharedComponent/search-input';
import { DownCircleTwoTone, DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import EdenredTable from '@sharedComponent/table';
import FilterContents from './filterContents';
import '../run-payroll-new.scss';
import { useHistory } from 'react-router-dom';
import ContentLoader from '@sharedComponent/content-loader';
import Expand from 'react-expand-animated';
import useOutsideClickpayroll from './useOutsideClickpayroll';
import { debounceHandler } from '@helpers';
import PayrollNoData from '@pages/payroll/payrollNodata/PayrollNoData';

const RunPayrollNewPresentational = ({
	dropdownValues,
	employeesData,
	empData,
	columns,
	employeeSalary,
	setRequestParams,
	requestParams,
	employeeTotalCount,
	dropDownValueState,
	setDropDownValueState,
	previousPayrollData,
	pageNumber,
	setPageNumber,
	payrollTableLoader,
	prevPayrollLoader,
	isRepeatMode,
	setIsRepeatMode,
	totalCount,
	onSearch,
}) => {
	const [open, setOpen] = useState(false);
	const [previousPayroll, setPreviousPayroll] = useState(false);
	const [size, setSize] = useState([0, 0]);

	let history = useHistory();
	const refrence = useRef();

	useEffect(() => {
		setDropDownValueState(dropdownValues);
	}, [dropdownValues]);

	function useWindowSize() {
		useLayoutEffect(() => {
			function updateSize() {
				setSize([window.innerHeight - 342, window.innerHeight - (28 + 40 + 569.5 - 200)]);
			}
			window.addEventListener('resize', updateSize);
			updateSize();
			return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	}
	const [final2, final] = useWindowSize();

	const handleSelectAll = (filterType = null, isAll = false, deselect = false) => {
		let lDropDownValueState = (dropDownValueState || [])?.map((dropdown) => {
			if (isAll || dropdown.name === filterType) {
				return {
					...dropdown,
					options: (dropdown?.options || [])?.map((option) => {
						console.log('option', option);
						return {
							...option,
							isSelected: deselect ? false : true,
						};
					}),
				};
			}
			return dropdown;
		});
		setDropDownValueState([...lDropDownValueState]);
	};

	const tableLoading = {
		spinning: payrollTableLoader,
		indicator: <ContentLoader />,
	};
	let locale = {
		emptyText: (
			<div>
				<PayrollNoData tableLoading={tableLoading.spinning} />
			</div>
		),
	};

	useOutsideClickpayroll(refrence, () => {
		setOpen(false);
	});

	const pageChange = (isAble, mode) => {
		if (isAble === false && mode == 'nextPage') {
			nextPage();
		}

		if (isAble === false && mode == 'prevPage') {
			prevPage();
		}
	};
	const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;
	const nextPage = () => {
		setPageNumber(pageNumber + 1);
	};

	const prevPage = () => {
		setPageNumber(pageNumber - 1);
	};

	const defaultSettings = {
		pageSize: 30,
		showSizeChanger: false,
	};

	const handleChange = (filterType, dropdownType) => {
		let lDropDownValueState = (dropDownValueState || [])?.map((dropdown) => {
			if (dropdown.name === filterType) {
				return {
					...dropdown,
					options: (dropdown?.options || [])?.map((option) => {
						console.log('option', option);
						const lDropdownType = option?.payrollChannelName || option?.accountTypeName || option?.establishmentId || option?.bankName;

						if (lDropdownType === dropdownType) {
							return {
								...option,
								isSelected: !option?.isSelected,
							};
						} else return option;
					}),
				};
			}
			return dropdown;
		});
		setDropDownValueState([...lDropDownValueState]);
	};

	const handlePrevPayroll = (prevPayrollId) => {
		setPreviousPayroll(false);
		setRequestParams({
			...requestParams,
			previousPayrollUploadId: prevPayrollId,
		});
	};

	const handlePagination = (pagination) => {
		setRequestParams({
			...requestParams,
			pageNumber: pagination.current,
		});
	};

	console.log('dataaaaa', previousPayrollData);

	return (
		<Row className="run-payroll-new-top">
			<Col xs={24} className="run-payroll-new">
				<EdenredTypo h2 className="run-payroll-typo">
					Payroll Table
				</EdenredTypo>
				<Col>
					<div className="run-payroll-filters">
						<EdenredButton type="outline-g" className="run-payroll-filterButton" onClick={() => setOpen(!open)}>
							<ImageComponent className="fil-image" src={icons.not_focus.Filter} />
							Filters
						</EdenredButton>
						<Input
							className="runPayroll-New-search"
							prefix={<ImageComponent className="search-icon" src={icons.not_focus.Search} />}
							placeholder="Search by Name or Employee ID."
							onChange={(e) => debounceHandler(e.target.value, onSearch)}
						/>
						<EdenredButton type="outline-g" className="run-payroll-previous" onClick={() => setPreviousPayroll(!previousPayroll)}>
							Repeat Previous Payroll
							{!previousPayroll ? <DownOutlined style={{ paddingLeft: 8 }} /> : <UpOutlined style={{ paddingLeft: 8 }} />}
						</EdenredButton>
					</div>
				</Col>

				<Expand open={previousPayroll} duration={700}>
					<Col className="runpayroll-previous">
						<div className="prev-payroll-text-container">
							<EdenredTypo h6="true" className="prev-payroll-text">
								Previous payroll runs:
							</EdenredTypo>
						</div>
						<div className="paper-box-container">
							{!prevPayrollLoader ? (
								previousPayrollData?.lastPayrollDetails?.map((item) => (
									<div className="paper-box">
										<div>
											<EdenredTypo className="payroll-card-text1" h6="true" style={{ display: 'flex' }}>
												<span
													style={{
														background: '#F7F8FA',
														borderRadius: '50%',
														width: '32px',
														height: '32px',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														marginRight: '10px',
													}}>
													<img style={{ width: '80%' }} src={icons.focus_state.Calendar}></img>
												</span>
												{item.submittedDate}
											</EdenredTypo>
										</div>
										<div className="line" />
										<div>
											<div style={{ paddingBottom: 3 }}>
												<EdenredTypo h6="true" className="payroll-card-text2">
													{item.bank + item.c3Pay} Total Employees
												</EdenredTypo>
											</div>
											<div className="cards-accounts">
												<EdenredTypo h6="true" className="payroll-card-text3">
													{item.c3Pay} C3Pay Cards
												</EdenredTypo>
												<div className="vertical-line" />
												<EdenredTypo className="payroll-card-text3" h6="true">
													{item.bank} Bank Accounts
												</EdenredTypo>
											</div>
										</div>
										<div className="aed-container">
											<EdenredTypo className="payroll-card-text4" h6="true">
												AED {item && item.totalSalary ? item.totalSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
											</EdenredTypo>
											<button
												onClick={(() => handlePrevPayroll(item.uploadId), setIsRepeatMode(true))}
												style={{ cursor: 'pointer' }}
												className={`payroll-card-button ${payrollTableLoader || prevPayrollLoader ? 'disabled' : ''}`}>
												{' '}
												{payrollTableLoader || prevPayrollLoader ? (
													<Spin indicator={antIcon} />
												) : (
													<img style={{ paddingLeft: '10px', paddingRight: '15px' }} src={icons.focus_state.Repeat}></img>
												)}
												Repeat
											</button>
										</div>
									</div>
								))
							) : (
								<ContentLoader />
							)}
						</div>
						<div className="icon-box-container">
							<ImageComponent
								style={{ marginRight: '8px', cursor: 'pointer' }}
								onClick={() => {
									pageChange(pageNumber == 1 && true, 'prevPage');
								}}
								src={pageNumber == 1 ? icons.not_focus.UnSelectedLeft : icons.focus_state.SelectedLeft}
							/>
							<ImageComponent
								style={{ cursor: 'pointer' }}
								onClick={() => {
									pageChange(pageNumber >= previousPayrollData.totalCount && true, 'nextPage');
								}}
								src={pageNumber >= previousPayrollData.totalCount ? icons.not_focus.UnSelectedRight : icons.focus_state.SelectedRight}
							/>
						</div>
						<div onClick={() => setPreviousPayroll(false)} style={{ cursor: 'pointer' }}>
							<div className="orange-box">
								<img className="upicon" src={icons.focus_state.UpIcon}></img>
							</div>
							<div className="white-box">
								<EdenredTypo h6="true" className="show-payroll-text">
									Show Payroll Table Again
								</EdenredTypo>
							</div>
						</div>
					</Col>
				</Expand>

				{open && (
					<Row className="run-payroll-filters-popup">
						{dropDownValueState.map((data, index) => (
							<Col xl={6} lg={8} md={12} sm={24} className="run-payroll-new-filters-container">
								<Col xl={24} lg={24} md={24} sm={24} key={index} className="run-payroll-new-filters-inside">
									<Dropdown
										overlay={
											<FilterContents
												{...{
													name: data?.name || '',
													options: data?.options || [],
													handleChange,
													handleSelectAll,
												}}
											/>
										}
										trigger={['click']}
										className="run-payroll-new-filtersPopup">
										<EdenredButton type="outline-g" className="run-payroll-filter-tiles">
											{data.name}
											<DownOutlined className="run-payroll-down-filters" />
										</EdenredButton>
									</Dropdown>
								</Col>
							</Col>
						))}
						<Col xl={24} lg={24} md={24}>
							<Col className="run-payroll-clearAll">
								<EdenredTypo className="run-payroll-clearAll-bottomOne b3" onClick={() => handleSelectAll(null, true, true)}>
									Clear All Filters
								</EdenredTypo>
								<EdenredTypo className="run-payroll-clearAll-bottomTwo b3" onClick={() => handleSelectAll(null, true)}>
									Select All Filters
								</EdenredTypo>
							</Col>
						</Col>
					</Row>
				)}

				<Col>
					{!previousPayroll && (
						<EdenredTable
							className="run-payroll-new-table"
							columns={columns}
							dataSource={empData.tableData}
							loading={tableLoading}
							pagination={{ total: totalCount, ...defaultSettings }}
							onChange={(e) => handlePagination(e)}
							locale={locale}
						/>
					)}
				</Col>
				<Col xs={24} className="run-payroll-new-footer">
					<Row xl={24} className="bottom-bar">
						<Col xl={2} md={1}>
							{' '}
						</Col>
						<Col className="run-payroll-new-amount" xl={9} md={8} sm={23}>
							<Col>Payroll Amount:</Col>
							<Col className="run-payroll-new-value">{`AED ${employeeSalary || 0} for ${employeeTotalCount.size || 0} employees.`}</Col>
						</Col>
						<Col className="run-payroll-new-buttons" xl={13} md={15} sm={24}>
							<Col className="button-payrollNew">
								<EdenredButton
									type="outline-g"
									className="run-payroll-newUpload"
									onClick={() => history.push('/upload/salary/spreadsheet_or_sif')}>
									Upload Excel or Direct SIF
								</EdenredButton>
							</Col>
							<Col>
								<EdenredButton disabled={true} className="run-payroll-newNext">
									Next
								</EdenredButton>
							</Col>
						</Col>
					</Row>
				</Col>
			</Col>
		</Row>
	);
};

export default RunPayrollNewPresentational;
