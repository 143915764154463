import ImageComponent from '@sharedComponent/image-component';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './accordian.scss';

const AccordianContent = ({ questions = [] }) => {
	const [active, setActive] = useState(questions);
	const answerDiv = (id) => {
		const accordianContent = active?.map((data) => {
			if (id === data?.id) {
				return {
					...data,
					isActive: !data.isActive,
				};
			} else {
				return data;
			}
		});
		setActive(accordianContent);
	};

	return (
		<div className="accordian-main-contents">
			{active?.map((data, index) => {
				return (
					<Row style={{ justifyContent: 'center', marginTop: '1%', cursor: 'pointer' }} key={index}>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="frequently-asked-div" onClick={() => answerDiv(data.id)}>
							<p className="accordian-questions">{data.question}</p>
							<ImageComponent
								src={data?.isActive ? not_focus_icons.MinusImage : not_focus_icons.AddImage}
								style={{ cursor: 'pointer' }}
							/>
						</Col>
						{data?.isActive && (
							<Col xl={24} lg={24} md={24} sm={24} xs={24} className="frequently-asked-answer">
								<p className="accordian-answer">
									{data.answer}
									{data?.link && (
										<a href={data?.link} className="accordian_link">
											HERE.
										</a>
									)}
								</p>
							</Col>
						)}
					</Row>
				);
			})}
		</div>
	);
};

export default AccordianContent;
