import { APP_VARIABLES } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

function useRedirectBasedOnClaim() {
	const location = useLocation();
	const history = useHistory();
	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));

	useEffect(() => {
		const pathname = location.pathname;
		if (checkSubscription === true) {
			redirectSubscribedUserToAppropriatePath(pathname);
		} else {
			redirectUnSubscribedUserToAppropriatePath(pathname);
		}
	}, [currentUser]);

	const redirectSubscribedUserToAppropriatePath = (currentPath) => {
		switch (currentPath) {
			case '/run-payroll':
				history.push('/payroll-plus');
				break;

			default:
				break;
		}
	};

	const redirectUnSubscribedUserToAppropriatePath = (currentPath) => {
		switch (currentPath) {
			case '/payroll-plus':
				history.push('/run-payroll');
				break;

			/** 
				 NOt FIXED
			 expected : 
				while clicking on "company" menu , "Company Settings" in sub-menu should be selected & navigated to "/company/company-setting"
			result : 
				"company" menu & submenu not selected but redirected to "/company/company-setting"
			problem : 
				user does not have claim for that sub-menu "company settings" so it is not selected but the default path for menu is "/company/company-setting" so its navigating
			reproduce : 
			 1 . switch account to un-subscribed user
			 2. click on company settings
			 3. it wont heighlight or select but it will navigate
			 **/
			case '/company/company-setting':
				history.push('/manage_team');
				break;

			case '/leaves/leaves_to_approve':
			case '/leaves/leaves_statuses':
			case '/leaves/request_leave':
			case '/approval-workflow-leave':
				history.push('/');
				break;

			default:
				break;
		}
	};
}

export default useRedirectBasedOnClaim;
