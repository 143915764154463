import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './saga/index';
import AddIndividualFunctional from './component/add-individual-functional';

const AddIndividualMain = () => {
	return <AddIndividualFunctional />;
};
const ReducedScreen = withReducer('addIndividualReducer', reducer, saga)(AddIndividualMain);
export default ReducedScreen;
