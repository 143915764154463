import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';

const BankDetailsPresentational = ({
	transferSalary,
	payInvoice,
	handleTab,
	createRakankAccDetails,
	transferCode,
	showTransferCode,
	accNumber,
	proceedPopUp,
	setProceedPopUp,
	handleProceeedPopUp,
	setShowCreatePopUp,
	handleCreatePop,
	errorMessage,
	tabInfo,
	postLoader,
	initialAccNumber,
	setPopUpCard,
	showParollBnkDetailPopup,
	closePopUpCard,
}) => {
	return (
		<>
			<EdenredHeader />
			<Row className="bankdetails_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="bankdetails_title">
					<ETypo h2 bold className="header-title-color">
						{PAYROLL_CONSTANTS.bankDetails.title}
					</ETypo>
					<ETypo b1 light className="bankdetail-info-container">
						{PAYROLL_CONSTANTS.bankDetails.description}
					</ETypo>
				</Col>
				<Col className="funds_invoice_tabs_container">
					<div className="invoice-tab-switch-container">
						<EdenredButtons
							{...{
								className: 'button-bank-detail-left',
								onClick: () => handleTab(PAYROLL_CONSTANTS.bankDetails.trasnferFundsTab.key),
								btnType: transferSalary ? 'primary' : 'secondary',
							}}>
							<ETypo b1 medium className={transferSalary ? 'secondary-color' : 'edenred-heading-color'}>
								{PAYROLL_CONSTANTS.bankDetails.trasnferFundsTab.title}
							</ETypo>
						</EdenredButtons>
						<EdenredButtons
							{...{
								className: 'button-bank-detail-right',
								onClick: () => handleTab(PAYROLL_CONSTANTS.bankDetails.payInvoicesTab.key),
								btnType: payInvoice ? 'primary' : 'secondary',
							}}>
							<ETypo b1 medium className={payInvoice ? 'secondary-color' : 'edenred-heading-color'}>
								{PAYROLL_CONSTANTS.bankDetails.payInvoicesTab.title}
							</ETypo>
						</EdenredButtons>
					</div>
				</Col>
				<div className="fund-info-and-transfer-fund-container">
					<div className="info-cell">
						{tabInfo?.map((res, index) => {
							return (
								<div className={(index + 1) % 2 == 0 ? 'each-info-cell cell-even-color' : 'each-info-cell'}>
									<div className="name">
										<ETypo b2 medium color="#00344E">
											{res?.title}
										</ETypo>
									</div>
									<div className="value">
										<ETypo b2 light color="#181919">
											{res?.value}
										</ETypo>
									</div>
								</div>
							);
						})}
					</div>
					{transferSalary && (
						<div className="bank-info">
							<div className="bank-title">
								<ETypo b2 medium className="bank-acc-title">
									{showTransferCode ? 'Transfer Code' : 'RAKBANK Account Number'}
								</ETypo>
							</div>

							{showTransferCode ? (
								<div className="accnumber-box">
									<ETypo b1 medium className="acc-number-style">
										{transferCode}
									</ETypo>
								</div>
							) : (
								<Inputbox
									value={accNumber}
									className="inplace-acc-editor"
									onChange={(e) => (e?.target?.value?.length < 14 ? createRakankAccDetails(e.target.value, 'inlinEditor') : null)}
									placeholder={'Enter Account Number'}
									type="number"
									loading={postLoader}
									isErrorMessage={errorMessage}
									errorMessage="The account Number must be 13 digits"
									rightIcon={<img src={focus_state_icons?.bankDetailEdit} alt="eyeIcon" />}
								/>
							)}

							<div className="info-box">
								{showTransferCode ? (
									<ETypo b2 light className="info-style">
										If you are not using RAKBANK Corporate Account to send your salary funds to us, please use this transfer code
										as a reference. This will enable us to update your balance automaticall
									</ETypo>
								) : (
									<ETypo b2 light className="info-style">
										Your RAKBANK Account Number will be used as reference when you transfer your salary funds to us
									</ETypo>
								)}
							</div>
							<div className="click-here-box">
								<ImageComponent src={focus_state_icons.bankDetailInfo} />
								<div className="click-here-style">
									{showTransferCode ? (
										<ETypo b2 light className="click-here-para-style">
											Already using a RAKBANK Corporate Account to send us salary funds? You don’t need a transfer code, you can
											use your RAKBANK Account Number{' '}
											<Link to={'#'} onClick={() => setPopUpCard(true)} className="click-here-font-style">
												Here
											</Link>{' '}
											as a reference
										</ETypo>
									) : (
										<ETypo b2 light className="click-here-para-style">
											If you are not using RAKBANK Corporate Account to send your salary funds to us, please use a transfer code
											as a reference. This will enable us to update your balance automatically. Please{' '}
											<Link to={'#'} onClick={() => setProceedPopUp(true)} className="click-here-font-style">
												Click Here
											</Link>{' '}
											to get a transfer code
										</ETypo>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				<Edenredmodal
					{...{
						onOkay: () => handleCreatePop(accNumber),
						onOkayButton: 'ok',
						close: () => closePopUpCard(false),
						isOpen: showParollBnkDetailPopup,
						onOkayDisable: initialAccNumber == accNumber || errorMessage,
					}}>
					<div className="enter-accnt-number-pop-up-container">
						<div className="bank-acct-title">
							<ETypo h4 bold>
								Enter RAKBANK Account Number
							</ETypo>
						</div>
						<div b1 light className="bank-acct-info">
							If you are using RAKBANK Corporate Account to transfer your funds to us, you don’t need a transfer code, we will take your
							account number as a reference!
						</div>
						<div className="bank-acct-input">
							<Inputbox
								value={accNumber}
								onChange={(e) => (e?.target?.value?.length < 14 ? createRakankAccDetails(e.target.value, 'popUpEditor') : null)}
								placeholder={'Enter Account Number'}
								type="number"
								loading={postLoader}
								isErrorMessage={errorMessage}
								errorMessage="The account Number must be 13 digits"
								rightIcon={<img src={focus_state_icons.bankDetailEdit} alt="eyeIcon" />}
							/>
						</div>
					</div>
				</Edenredmodal>
				<Edenredmodal
					{...{
						onOkay: () => handleProceeedPopUp(),
						onCancelButton: 'Cancel',
						onOkayButton: 'Proceed',
						onCancel: () => setProceedPopUp(false),
						isOpen: proceedPopUp,
						close: () => setProceedPopUp(false),
					}}>
					<div className="proceed-container">
						<div className="proceed-header">
							<ETypo h4 bold className="proceed-acct-header-style">
								Don’t have a RAKBANK Account Number?
							</ETypo>
						</div>
						<div className="proceed-info">
							<ETypo b1 light className="proceed-acct-info">
								If you are using RAKBANK Corporate Account, you can use your account number as a reference. Otherwise, please use a
								Transfer Code and click proceed.
							</ETypo>
						</div>
					</div>
				</Edenredmodal>
			</Row>
		</>
	);
};
export default BankDetailsPresentational;
