import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import LoginsetupPresentational from './loginstep-presentational';
import { SITEMAP } from 'src/routes/sitemap';

const LoginsetupFunctional = ({ currentUser: { user } }) => {
	const isSubscribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);

	useEffect(() => {
		getLoginStep();
	}, []);
	const history = useHistory();
	const [page, setPage] = useState(<></>);
	const { state } = useLocation();

	const getLoginStep = () => {
		if (!user?.isPasswordReset) {
			history.push({
				pathname: SITEMAP.user.createPassword,
				state: {
					user: state?.user,
				},
			});
		} else {
			if (state?.loginStep === 0) {
				history.push({
					pathname: SITEMAP.dashboard.index,
					state: {
						user: state?.user,
					},
				});
			}
			// else if (!isOnlyEmployeeExits && state?.loginStep === 1 && isSubscribed) {
			// 	history.push('/slidersetup');
			// 	return;
			// }
			else if (!isOnlyEmployeeExits && state?.loginStep === 1 && !isSubscribed) {
				history.push({
					pathname: SITEMAP.user.slider,
					state: {
						user: state?.user,
					},
				});
			} else {
				history.push({
					pathname: SITEMAP.dashboard.index,
					state: {
						user: state?.user,
					},
				});
			}
		}
	};

	return <LoginsetupPresentational page={page} />;
};
function mapStateToProps({ oidc: { user }, sharedStates: { currentUser } }) {
	return { user, currentUser };
}

export default connect(mapStateToProps, null)(LoginsetupFunctional);
