import React from 'react';
import { Card, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { accountTypeConst } from '@constants/index';
import Chips from '@pages/DesignSystem/Chips';
import ETypo from '@pages/DesignSystem/Typo';
import profileAction from '@pages/payroll-plus/profile-action';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import { icons } from 'src/assets/icons';
import '../view-employee.scss';
import ClevertapReact from 'src/utils/clever-tap';

const EmployeeProfileInfoCard = ({ employeeDetails, history, employeeRoles, accountType, employeeProfileCard, handleProfileCard }) => {
	const dispatch = useDispatch();
	let userId = employeeDetails?.userId ? employeeDetails?.userId : '';
	const getChipsColor = (data) => {
		switch (data) {
			case 'Payroll':
				return '#FEF5E8';
			case 'HR':
				return '#E2D2FE';
			case 'Manage Roles':
				return '#CCECE6';
		}
	};
	return (
		<Row style={{ alignItems: 'center' }} className="employee_profile_view_employee">
			<Col className="profile_picture">
				{/* <ImageComponent src={images?.userProfileVector} /> */}
				<ETypo medium h2 color="#181919">
					{employeeDetails?.firstName && employeeDetails?.lastName
						? employeeDetails?.firstName?.[0] + '' + employeeDetails?.lastName?.[0]
						: null}
				</ETypo>
			</Col>
			<Col xl={24} md={24} lg={24} sm={24} xs={24}>
				<Row className="view-employees-india">
					<Col xl={24} md={24} lg={24} sm={24} xs={24}>
						<Col xl={24} md={24} lg={24} sm={24} xs={24} className="view-employee-username">
							{employeeDetails?.firstName && employeeDetails?.lastName ? (
								<ETypo medium h4 color="#181919" className="word-break">
									{employeeDetails?.firstName}&nbsp;
									{employeeDetails?.lastName}
								</ETypo>
							) : null}
						</Col>
					</Col>
				</Row>
				<Row className="view-emp-row">
					<ETypo light b2 color="#484B52">
						{employeeDetails?.employeeId}{' '}
					</ETypo>
					{employeeDetails?.department && (
						<ETypo light b2 color="#484B52">
							{employeeDetails?.department}
						</ETypo>
					)}
					{employeeDetails?.salaryChannelDescription && (
						<div className="emp_account_type">
							<ETypo light b2 color="#181919">
								{employeeDetails?.salaryChannelDescription}
							</ETypo>
						</div>
					)}
				</Row>
			</Col>
			{history?.location?.state?.mainProfile ? (
				<Col className="profile-change-password" onClick={() => history.push('/dashboard/change-password')}>
					<div className="profile_password_lock">
						<ImageComponent src={icons?.focus_state?.PasswordLock} />
					</div>
					<ETypo medium b2 color="#484B52">
						Change Password
					</ETypo>
					<ImageComponent src={icons?.focus_state?.RightArrow} />
				</Col>
			) : null}
			{!history?.location?.state?.mainProfile ? (
				<>
					{employeeRoles?.length > 0 && (
						<div className="emp_profile_roles">
							{employeeRoles?.map((data) => (
								<Chips color={getChipsColor(data)}>{data}</Chips>
							))}
						</div>
					)}
					{accountType !== accountTypeConst?.C3pay ? (
						<>
							{userId === '' ? (
								<Row className="invite_emp_to_portal">
									<Col className="card-row" onClick={() => dispatch(profileAction.creators.conformEmail(true))}>
										<Col xl={20} className="card-detail-space">
											<div className="invite_portal_profile_icon">
												<ImageComponent src={icons.not_focus.invite} />
											</div>
											<p className="card-names">Invite Employee To The Portal</p>
										</Col>
										<ImageComponent src={icons?.not_focus?.RightNav} />
									</Col>
								</Row>
							) : null}
						</>
					) : (
						<Row>
							<Row className="For-card-row">
								{employeeProfileCard?.map((data) => (
									<Col
										key={data.name}
										className={data?.enable ? 'card-row' : 'carddisable'}
										style={{ paddingRight: '12px' }}
										onClick={() => {
											handleProfileCard(data?.name, data?.param);
											ClevertapReact.event(data.cleverTapEventName, null);
										}}>
										<Col xl={20} className="card-detail-space">
											<div className="emp_card_icon">
												<ImageComponent src={data?.icon} />
											</div>
											<ETypo medium b2 color="#484B52" className="card-names" style={{ width: '100%' }}>
												{data?.name}
											</ETypo>
										</Col>
										<ImageComponent src={icons?.not_focus?.RightNav} />
									</Col>
								))}
							</Row>
							{employeeDetails && employeeDetails?.requestReplacementCardMessage && (
								<Col xl={6} sm={6} xs={6} md={6} className="warningrow" style={{ minWidth: '100%', marginTop: '8px' }}>
									<Card className="warning_style">
										<Row>
											<Col xl={4} sm={4} xs={4} md={4}>
												<ImageComponent src={icons?.focus_state?.Warning} className="c" />
											</Col>
											<Col xl={20} md={20} sm={20} xs={20} className="action_apporve">
												<ETypo medium b2>
													{employeeDetails?.requestReplacementCardMessage}
												</ETypo>
											</Col>
										</Row>
									</Card>
								</Col>
							)}
						</Row>
					)}
				</>
			) : null}
		</Row>
	);
};

export default EmployeeProfileInfoCard;
