const POST_UPLOAD_LEAVE = 'POST_UPLOAD_LEAVE';
const PUT_UPLOAD_LEAVE_LOADING = 'PUT_UPLOAD_LEAVE_LOADING';
const PUT_UPLOAD_LEAVE_ERROR = 'PUT_UPLOAD_LEAVE_ERROR';
const PUT_UPLOAD_LEAVE_VALIDATION_MESSAGE = 'PUT_UPLOAD_LEAVE_VALIDATION_MESSAGE';
const PUT_UPLOAD_LEAVE_RESET = 'PUT_UPLOAD_LEAVE_RESET';

const PUT_DOWNLOAD_LEAVE_TEMPLATE_LOADING = 'PUT_DOWNLOAD_LEAVE_TEMPLATE_LOADING';
const GET_DOWNLOAD_LEAVE_TEMPLATE = 'GET_DOWNLOAD_LEAVE_TEMPLATE';
const GET_DOWNLOAD_LEAVE_TEMPLATE_DATA = 'GET_DOWNLOAD_LEAVE_TEMPLATE_DATA';
const GET_DOWNLOAD_LEAVE_TEMPLATE_ERROR = 'GET_DOWNLOAD_LEAVE_TEMPLATE_ERROR';

const SUBMIT_UPLOAD_LEAVE = 'SUBMIT_UPLOAD_LEAVE';
const SET_FILE_UPLOAD_STATUS = 'SET_FILE_UPLOAD_STATUS';
const setFileUploadStatus = (isFileUploaded) => ({
	type: SET_FILE_UPLOAD_STATUS,
	payload: isFileUploaded,
});
const submitUploadLeave = (payload) => ({
	type: SUBMIT_UPLOAD_LEAVE,
	payload: payload,
});

const postUploadLeave = (data) => ({
	type: POST_UPLOAD_LEAVE,
	payload: data,
});

const putUploadLeaveLoading = (loadingStatus) => ({
	type: PUT_UPLOAD_LEAVE_LOADING,
	payload: loadingStatus,
});

const putUploadLeaveValidationMessage = (validationMessage) => ({
	type: PUT_UPLOAD_LEAVE_VALIDATION_MESSAGE,
	payload: validationMessage,
});

const putUploadLeaveError = (error) => ({
	type: PUT_UPLOAD_LEAVE_ERROR,
	payload: error,
});
const putUploadLeaveReset = () => ({
	type: PUT_UPLOAD_LEAVE_RESET,
});

const putDownloadLeaveTemplateLoading = (loadingStatus) => ({
	type: PUT_DOWNLOAD_LEAVE_TEMPLATE_LOADING,
	payload: loadingStatus,
});

const getDownloadLeaveTemplate = () => ({
	type: GET_DOWNLOAD_LEAVE_TEMPLATE,
});

const getDownloadLeaveTemplateData = (data) => ({
	type: GET_DOWNLOAD_LEAVE_TEMPLATE_DATA,
	payload: data,
});

const getDownloadLeaveTemplateError = (error) => ({
	type: GET_DOWNLOAD_LEAVE_TEMPLATE_ERROR,
	payload: error,
});

export default {
	types: {
		GET_DOWNLOAD_LEAVE_TEMPLATE,
		GET_DOWNLOAD_LEAVE_TEMPLATE_DATA,
		GET_DOWNLOAD_LEAVE_TEMPLATE_ERROR,
		PUT_DOWNLOAD_LEAVE_TEMPLATE_LOADING,
		POST_UPLOAD_LEAVE,
		PUT_UPLOAD_LEAVE_LOADING,
		PUT_UPLOAD_LEAVE_VALIDATION_MESSAGE,
		PUT_UPLOAD_LEAVE_ERROR,
		PUT_UPLOAD_LEAVE_RESET,
		SUBMIT_UPLOAD_LEAVE,
		SET_FILE_UPLOAD_STATUS,
	},
	creators: {
		getDownloadLeaveTemplate,
		getDownloadLeaveTemplateData,
		getDownloadLeaveTemplateError,
		putDownloadLeaveTemplateLoading,
		putUploadLeaveReset,
		postUploadLeave,
		putUploadLeaveLoading,
		putUploadLeaveValidationMessage,
		putUploadLeaveError,
		submitUploadLeave,
		setFileUploadStatus,
	},
};
