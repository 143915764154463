import React, { useMemo } from 'react';
import { icons } from 'src/assets/icons';
import './_pagination.scss';
export const DOTS = '...';

const range = (start, end) => {
	let length = Math.abs(end - start + 1);
	return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }) => {
	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);

		const totalPageNumbers = siblingCount + 5;

		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = 3 + 2 * siblingCount;
			let leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 3 + 2 * siblingCount;
			let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [totalCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};

const Pagination = (props) => {
	const { onPageChange, totalCount = 0, siblingCount = 1, currentPage, pageSize, className } = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});
	if (currentPage === 0 || paginationRange?.length < 1) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	let lastPage = paginationRange?.[paginationRange?.length - 1];
	return (
		<ul className={`pagination-container ${className}`}>
			<li className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious}>
				<div className="arrow left">
					<img src={icons.not_focus.up} alt="" style={{ width: '15px', height: '15px', opacity: `${currentPage === 1 ? 0.3 : ''}` }} />
				</div>
			</li>
			{paginationRange?.map((pageNumber, i) => {
				if (pageNumber === DOTS) {
					return (
						<li key={i} className="pagination-item dots">
							&#8230;
						</li>
					);
				}

				return (
					<li
						key={i}
						className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
						onClick={() => onPageChange(pageNumber)}>
						{pageNumber}
					</li>
				);
			})}
			<li className={`pagination-item ${currentPage === lastPage ? 'disabled' : ''}`} onClick={onNext}>
				<div className="arrow right">
					<img
						src={icons.not_focus.up}
						alt=""
						style={{ width: '15px', height: '15px', opacity: `${currentPage === lastPage ? 0.3 : ''}` }}
					/>
				</div>
			</li>
		</ul>
	);
};

export default Pagination;
