import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { getMonthLabelFromNumber } from '@helpers';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ETypo from '../Typo';

const DateRangePicker = ({
	className,
	label,
	disabled,
	value,
	onFocus = () => {},
	onBlur = () => {},
	onChange,
	onKeyDown,
	isDateError,
	dateErrorMessage,
	options,
	rangeDateActivePlaceHolder,
	rangeDateFromPlaceHolder,
	rangeDateToPlaceHolder,
	defaultValue,
	dateMode,
	dateCalender,
	required,
	dateFormatFlatPicker,
}) => {
	const [showRangeDateDefault, setShowRangeDateDefault] = useState(false);
	const [rangeDatePickerValue, setRangeDatePickerValue] = useState({
		fromDate: '',
		todate: '',
	});
	const handleRangeDate = (stringFormat, dateFormat) => {
		if (dateMode === 'range') {
			const startDate = dateFormat?.split('to')?.[0];
			const endDate = dateFormat?.split('to')?.[1];
			const nStartMonthYear = moment(startDate).format(dateFormatFlatPicker ? dateFormatFlatPicker : 'MMM YYYY');
			const nEndMonthYear = moment(endDate).format(dateFormatFlatPicker ? dateFormatFlatPicker : 'MMM YYYY');
			setRangeDatePickerValue({
				...rangeDatePickerValue,
				fromDate: dateCalender === 'monthOnly' ? nStartMonthYear : startDate,
				todate: dateCalender === 'monthOnly' ? nEndMonthYear : endDate,
			});
		} else {
			const month = dateFormat && getMonthLabelFromNumber(parseInt(dateFormat?.split('/')?.[1]) - 1, 'MMMM');
			const year = dateFormat?.split('/')?.[2];
			setRangeDatePickerValue({ ...rangeDatePickerValue, fromDate: month, todate: year });
		}
		onChange && onChange(dateFormat);
	};
	useEffect(() => {
		if (dateMode === 'range') {
			const startDate = value && value?.split('to')?.[0];
			const endDate = value && value?.split('to')?.[1];
			const nStartMonthYear = moment(startDate, 'DD/MM/YYYY').format(dateFormatFlatPicker ? dateFormatFlatPicker : 'MMM YYYY');
			const nEndMonthYear = moment(endDate, 'DD/MM/YYYY').format(dateFormatFlatPicker ? dateFormatFlatPicker : 'MMM YYYY');
			setRangeDatePickerValue({
				...rangeDatePickerValue,
				fromDate: value ? (dateCalender === 'monthOnly' ? nStartMonthYear : startDate) : '',
				todate: value ? (dateCalender === 'monthOnly' ? nEndMonthYear : endDate) : '',
			});
		} else {
			const month = value && value?.split(' ')?.[0];
			const year = value && value?.split(' ')?.[1];
			setRangeDatePickerValue({ ...rangeDatePickerValue, fromDate: value ? month : '', todate: value ? year : '' });
		}
	}, [value]);
	return (
		<div className="range_date_picker_container">
			{label && (
				<p className="date_label">
					{label}
					{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
				</p>
			)}
			<div className="date_range_picker">
				<Flatpickr
					{...{
						mode: 'range',
						disabled,
						options,
						onFocus: () => {
							setShowRangeDateDefault(true);
							onFocus();
						},
						onBlur: () => {
							setShowRangeDateDefault(false);
							onBlur();
						},
						onChange: handleRangeDate,
						onKeyDown,
						className: 'range_date_input',
						defaultValue,
					}}
				/>
				<div
					className={`date_picker_main ${className} ${disabled ? 'disabled_date' : 'active_date_field'} ${
						value ? 'non_empty_date_field' : 'empty_date_field'
					} ${isDateError ? 'date_error_field' : ''}`}>
					<img src={not_focus_icons?.payrollCalender} />
					<div className="date_range_picker_content">
						<div className="range_from_container">
							<ETypo b2 medium color={rangeDatePickerValue?.fromDate ? '#181919' : '#9a9ea6'}>
								{rangeDatePickerValue?.fromDate && rangeDatePickerValue?.fromDate !== 'Invalid date'
									? rangeDatePickerValue?.fromDate
									: showRangeDateDefault && rangeDateActivePlaceHolder
									? rangeDateActivePlaceHolder
									: rangeDateFromPlaceHolder}
							</ETypo>
						</div>
						<span className="ranger_picker_divider" />
						<div className="range_to_container">
							<ETypo b2 medium color={rangeDatePickerValue?.todate ? '#181919' : '#9a9ea6'}>
								{rangeDatePickerValue?.todate && rangeDatePickerValue?.todate !== 'Invalid date'
									? rangeDatePickerValue?.todate
									: showRangeDateDefault & rangeDateActivePlaceHolder
									? rangeDateActivePlaceHolder
									: rangeDateToPlaceHolder}
							</ETypo>
						</div>
					</div>
					<img className="arrow-inner" src={showRangeDateDefault ? focus_state_icons.uparrow : not_focus_icons.down} />
				</div>
				{isDateError && <p className="date_field_error_message">{dateErrorMessage}</p>}
			</div>
		</div>
	);
};
export default DateRangePicker;
