export const new_emp_Qus = [
    {
        question: `How do I add employees with bank accounts?`,
        answer: [
            {
                label: 'Visit the "Manage Employees" section.',
                link: '',
            },
            {
                label: 'Select "Add New Employee".',
                link: '',
            },
            {
                label: 'Download the spreadsheet template for employee addition (Employee file)',
                link: '',
            },
            {
                label: 'Select the salary processing channel as “WPS Bank Account” or “Non WPS Bank Account” as applicable.',
                link: '',
            },
        ],
    },
    {
        question: 'How do I get the account numbers of my newly added employees?',
        answer: [
            {
                label: 'Visit the “Manage Employees” section.',
                link: '',
            },
            {
                label: 'Click on “Download Employee List” at the bottom left of the screen.',
                link: '',
            },
            {
                label: 'The list of active employees will be downloaded along with their account numbers.',
                link: '',
            },
        ],
    }
]