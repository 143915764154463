import { images } from 'src/assets/img';

export const carouselObj1 = [
	{
		id: 0,
		content1: 'Edenred Carousel Demo1',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 1,
		content1: 'Edenred Carousel Demo2',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 2,
		content1: 'Edenred Carousel Demo3',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 3,
		content1: 'Edenred Carousel Demo4',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 4,
		content1: 'Edenred Carousel Demo5',
		content2: 'Edenred Carousel is a Slider',
	},
];

export const carouselObj2 = [
	{
		id: 0,
		content1: 'Edenred Carousel Demo1',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 1,
		content1: 'Edenred Carousel Demo2',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 2,
		content1: 'Edenred Carousel Demo3',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 3,
		content1: 'Edenred Carousel Demo4',
		content2: 'Edenred Carousel is a Slider',
	},
];

export const carouselObj3 = [
	{
		id: 0,
		content1: 'Edenred Carousel Demo1',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 1,
		content1: 'Edenred Carousel Demo2',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 2,
		content1: 'Edenred Carousel Demo3',
		content2: 'Edenred Carousel is a Slider',
	},
];

export const carouselObj4 = [
	{
		id: 0,
		content1: 'Edenred Carousel Demo1',
		content2: 'Edenred Carousel is a Slider',
	},
	{
		id: 1,
		content1: 'Edenred Carousel Demo2',
		content2: 'Edenred Carousel is a Slider',
	},
];

export const carouselImage = [
	{
		id: 0,
		image: images?.slider1,
	},
	{
		id: 1,
		image: images?.slider2,
	},
	{
		id: 2,
		image: images?.slider3,
	},
];
