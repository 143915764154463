import React, { useState } from 'react';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ClevertapReact from 'src/utils/clever-tap';
import { UnemploymentService } from 'src/services';
import { useHistory } from 'react-router-dom';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { icons } from '@assets/icons';
import DisableInsuranceInstructions from './DisableInsuranceInstructions';
import { MANAGE_EMPLOYEES_CONSTANTS } from '../../constants';
import { SITEMAP } from 'src/routes/sitemap';
import './addEmployeeCheckModal.scss';

const ManageEmployeeHeader = ({ renderBasedOnClaims, handleBulkDelete, handleUpdateBulk }) => {
	const history = useHistory();
	const [workPermitCheckModal, setWorkPermitCheckModal] = useState(false);
	const [insuranceExternalModal, setInsuranceExternalModal] = useState(false);
	const [insuranceInternalModal, setInsuranceInternalModal] = useState(false);
	const [addEmployeeLoading, setAddEmployeeLoading] = useState(false);
	const [continueSubscribeloading, setContinueSubscribeLoading] = useState(false);
	const [stopSubscribeloading, setStopSubscribeLoading] = useState(false);

	const IloeService = new UnemploymentService();

	const { workPermitCheck, insuranceExternal, insuranceInternal } = MANAGE_EMPLOYEES_CONSTANTS.addEmployees.modalContents;

	const addEmployee = async () => {
		setAddEmployeeLoading(true);
		ClevertapReact.event('Add_Employees');
		try {
			const res = await IloeService.checkEmployeeSubscribed();
			setAddEmployeeLoading(false);
			const { subscribed, verified } = res.data || {};
			// If the corporate was not mass subscribed or
			// if the corporate is mass subscribed and verified
			if (!subscribed || verified) {
				history.push(SITEMAP.employees.add);
			}
			// if the corporate is mass subscribed and not verified
			else if (subscribed && !verified) {
				setWorkPermitCheckModal(true);
			}
		} catch (error) {
			setAddEmployeeLoading(false);
			console.error(error);
		}
	};

	const isInsuranceExternal = () => {
		setWorkPermitCheckModal(false);
		setInsuranceExternalModal(true);
	};
	const isInsuranceInternal = () => {
		setWorkPermitCheckModal(false);
		setInsuranceInternalModal(true);
	};

	const stopSubscription = async () => {
		setStopSubscribeLoading(true);
		try {
			const body = {
				verified: true,
				stopSubscription: true,
			};
			const res = await IloeService.verifyEmployeeInsurance(body);
			setStopSubscribeLoading(false);
			if (res?.data) {
				history.push(SITEMAP.employees.add);
			}
		} catch (error) {
			setStopSubscribeLoading(false);
			console.error(error);
		}
	};

	const continueSubscription = async () => {
		setContinueSubscribeLoading(true);
		try {
			const body = {
				verified: true,
				stopSubscription: false,
			};
			const res = await IloeService.verifyEmployeeInsurance(body);
			setContinueSubscribeLoading(false);
			if (res?.data) {
				history.push(SITEMAP.employees.add);
			}
		} catch (error) {
			setContinueSubscribeLoading(false);
			console.error(error);
		}
	};
	// function currying
	const onCloseModal =
		(modal = () => {}) =>
		() => {
			setAddEmployeeLoading(false);
			setContinueSubscribeLoading(false);
			setStopSubscribeLoading(false);
			modal(false);
		};

	return (
		<>
			{/* Initial modal after checking corporate is mass subscribed */}
			<Edenredmodal
				isOpen={workPermitCheckModal}
				title={workPermitCheck.title}
				desc={workPermitCheck.desc}
				onOkay={isInsuranceExternal}
				onsecondary={isInsuranceInternal}
				onOkayButton={workPermitCheck.yesText}
				secondarybtn={workPermitCheck.noText}
				close={onCloseModal(setWorkPermitCheckModal)}
				topImage={<ImageComponent src={icons.focus_state.tawjeehCheckIcon} />}
				mainModalClassName="iloe_subscribe_check_modal"
			/>
			{/* If the employee opted for any external channel, then this modal show about instructions for disabling insurance for new employees */}
			<Edenredmodal
				isOpen={insuranceExternalModal}
				title={insuranceExternal.title}
				desc={insuranceExternal.desc}
				onOkay={stopSubscription}
				onsecondary={continueSubscription}
				loading={stopSubscribeloading}
				secondaryBtnLoading={continueSubscribeloading}
				onOkayButton={insuranceExternal.yesText}
				secondarybtn={insuranceExternal.noText}
				children={<DisableInsuranceInstructions />}
				close={onCloseModal(setInsuranceExternalModal)}
				topImage={<ImageComponent src={icons.focus_state.stopInsuranceIcon} />}
				mainModalClassName="iloe_subscribe_check_modal"
			/>
			{/* If the employee not used any external channels then this modal shows */}
			<Edenredmodal
				isOpen={insuranceInternalModal}
				title={insuranceInternal.title}
				desc={insuranceInternal.desc}
				onOkay={continueSubscription}
				onsecondary={stopSubscription}
				loading={continueSubscribeloading}
				secondaryBtnLoading={stopSubscribeloading}
				onOkayButton={insuranceInternal.yesText}
				secondarybtn={insuranceInternal.noText}
				close={onCloseModal(setInsuranceInternalModal)}
				mainModalClassName="iloe_subscribe_check_modal"
			/>
			<section className="manage_employees_action_header_wrapper">
				<p className="title">Manage Employees</p>
				<div className="action_buttons_wrapper">
					{renderBasedOnClaims?.deleteInBulkButton && (
						<div className="delete_btn">
							<EdenredButtons
								onClick={handleBulkDelete}
								leftIcon={<ImageComponent src={not_focus_icons.Delete} />}
								toolTipClassName="width_100_percentage"
								btnType="secondary">
								Delete in Bulk
							</EdenredButtons>
						</div>
					)}
					{renderBasedOnClaims?.updateInBulkButton && (
						<div className="upload_btn">
							<EdenredButtons
								onClick={handleUpdateBulk}
								toolTipClassName="width_100_percentage"
								leftIcon={<ImageComponent src={not_focus_icons.uploadv2} />}
								btnType="secondary">
								Update in Bulk
							</EdenredButtons>
						</div>
					)}
					{renderBasedOnClaims?.addEmployeesButton && (
						<div className="add_btn">
							<EdenredButtons loading={addEmployeeLoading} toolTipClassName="width_100_percentage" onClick={addEmployee}>
								Add Employees
							</EdenredButtons>
						</div>
					)}
				</div>
			</section>
		</>
	);
};

export default ManageEmployeeHeader;
