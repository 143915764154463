import React, { useState } from 'react';
import { Form } from 'antd';
import ChangePasswordPresentational from './change-passoword-presentational';

const DIGIT_REGEX = /[0-9]/;
const DIGIT_REGEX1 = /[$&+,:;=?@#]/;
const principles = [
	{
		label: 'Minimum 6 characters',
		rule: (password) => password.length >= 6,
	},
	{
		label: 'Must contain at least 1 number',
		rule: (password) => password.match(DIGIT_REGEX) !== null,
	},
	// {
	// 	label: 'Must contain at least [$&+,:;=?@#]',
	// 	rule: (password) => password.match(DIGIT_REGEX1) !== null,
	// },
];
const ChangePasswordFunctional = ({ history }) => {
	const [form] = Form.useForm();
	const [password, setPassword] = useState('');
	const [password1, setPassword1] = useState('');

	const onPasswordChange = (password) => {
		setPassword(password);
	};
	const onPasswordChange1 = (password1) => {
		setPassword1(password1);
	};

	return (
		<ChangePasswordPresentational
			form={form}
			password1={password1}
			password={password}
			principles={principles}
			onPasswordChange={onPasswordChange}
			onPasswordChange1={onPasswordChange1}
		/>
	);
};

export default ChangePasswordFunctional;
