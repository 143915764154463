import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import CreatePasswordPresentational from './create-password-presentational';
import SharedServices, { CREATE_PASSWORD, GET_PROFILE, SET_LOGIN_STEP } from '@sharedComponent/app-action-reducer/services';
import Actions from '@sharedComponent/app-action-reducer/action';
import { useCallMfa, useUserLoginData } from 'src/hooks';

const DIGIT_REGEX = /[0-9]/;
const principles = [
	{
		label: 'Minimum 6 characters',
		rule: (password) => password.length >= 6,
	},
	{
		label: 'Must contain at least 1 number',
		rule: (password) => password.match(DIGIT_REGEX) !== null,
	},
];
const CreatePasswordFunctional = ({ setUser, currentUser }) => {
	const api = new SharedServices();
	const [form] = Form.useForm();
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const { state } = useLocation() || {};
	const { user } = currentUser || {};
	const { checkMfa } = useCallMfa(state?.user);
	const { getUserDetails } = useUserLoginData(state?.user);

	useEffect(() => {
		if (user?.isPasswordReset) {
			setLoading(false);
			history.goBack();
		}
	}, []);

	useEffect(() => {
		if (!user) {
			getUserDetails();
		}
	}, [user]);

	const onFinish = (values) => {
		setLoading(true);
		api.services(CREATE_PASSWORD, null, {
			...values,
		}).then(() => {
			api.services(SET_LOGIN_STEP, {
				step: 0,
			})
				.then(() => {
					api.services(GET_PROFILE)
						.then((res) => {
							setUser(res.data);
							checkMfa();
						})
						.catch((err) => {
							setLoading(false);
						});
				})
				.catch(() => {
					setLoading(false);
				});
		});
	};

	const onPasswordChange = (password) => {
		setPassword(password);
	};

	return (
		<CreatePasswordPresentational
			form={form}
			password={password}
			principles={principles}
			onFinish={onFinish}
			onPasswordChange={onPasswordChange}
			loading={loading}
		/>
	);
};

function mapStateToProps({ oidc: { user }, sharedStates: { currentUser } }) {
	return { user, currentUser };
}
const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (details) => dispatch(Actions.creators.setUser(details)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordFunctional);
