import Actions from '../action';
import Axios from 'axios';
import counterAction from '@pages/payroll-plus-new/action';
import ToastActions from '@pages/DesignSystem/StatusToast/actions';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import hrFeatureActions from '../../HrFeatureAccess/action';

export function* getAllowanceServices() {
	yield put(Actions.creators.putAllowanceLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/allowance`;
	try {
		const response = yield call(Axios.get, url);
		yield put(Actions.creators.getAllowanceData(response.data));
		yield put(Actions.creators.putAllowanceLoading(false));
	} catch (error) {
		yield put(Actions.creators.putAllowanceError(error));
		yield put(Actions.creators.putAllowanceLoading(false));
	}
}

export function* sentAllowanceData(action) {
	yield put(Actions.creators.putAllowanceLoading(true));

	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/allowancetype?companySetupFlag=true`;
		yield call(Axios.post, url, action?.payload);
		yield put(Actions.creators.putAllowanceLoading(false));
		yield put(ToastActions.creators.putSuccessStatusToast({ message: 'Your allowances have been added!' }));
		yield put(Actions.creators.getAllowance());
		yield put(hrFeatureActions.creators.getCorporateSettings());
		yield put(counterAction.creators.getPayrollDynamicHeader());
	} catch (error) {
		yield put(Actions.creators.putAllowanceError(error));
		yield put(Actions.creators.putAllowanceLoading(false));
	}
}
export function* getCorporateSettingsServices() {
	yield put(hrFeatureActions.creators.putCorporateSettingsLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/corporatesetting/all`;
	try {
		const response = yield call(Axios.get, url);
		yield put(hrFeatureActions.creators.getCorporateSettingsData(response.data));
		yield put(hrFeatureActions.creators.putCorporateSettingsLoading(false));
	} catch (error) {
		yield put(hrFeatureActions.creators.putCorporateSettingsError(error));
		yield put(hrFeatureActions.creators.putCorporateSettingsLoading(false));
	}
}

export default function* allowanceWatcher() {
	yield takeLatest(Actions.types.POST_ALLOWANCE, sentAllowanceData);
	yield takeEvery(Actions.types.GET_ALLOWANCE, getAllowanceServices);
	yield takeEvery(hrFeatureActions.types.GET_CORPORATE_SETTINGS, getCorporateSettingsServices);
}
