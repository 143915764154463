import { useEffect } from 'react';

const useOnClickOutside = (refrence, callback) => {
	const handleClick = (e) => {
		if (refrence.current && !refrence.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);
		document.addEventListener('scroll', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
			document.removeEventListener('scroll', handleClick);
		};
	});
};

export default useOnClickOutside;
