import React, { useState } from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '../index';

const ModelEightteen = () => {
	const [isOpen, setisOpen] = useState(false);

	return (
		<>
			<EdenredButtons
				{...{
					btnType: 'primary',
					onClick: () => setisOpen(true),
					style: { marginTop: '20px' },
				}}>
				Modal With field
			</EdenredButtons>

			<Edenredmodal
				{...{
					field: 'Sales Department',
					fieldlabel: 'Edit Depatment',
					onOkay: 'abc',
					onCancelButton: 'Save',
					onOkayButton: 'Cancel',
					onCancel: () => setisOpen(false),
					isOpen: isOpen,
					close: () => setisOpen(false),
				}}
			/>
		</>
	);
};

export default ModelEightteen;
