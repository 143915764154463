import React from 'react';
import './stepper.scss';
import { useHistory } from 'react-router-dom';

const Stepper = ({ listOfTabs }) => {
	const history = useHistory();
	return (
		<div className="stepper-container">
			{listOfTabs &&
				listOfTabs.map((tab) => (
					<div
						key={tab?.name}
						onClick={() => {
							tab?.editUploadSpreadsheet
								? history.push({ pathname: tab?.navigate, state: { editUploadSpreadsheet: tab?.editUploadSpreadsheet } })
								: history.push(tab?.navigate);
						}}
						className={`progress-container ${tab?.isActive && 'active-container'} ${tab?.isCompleted && 'complected-container'} ${
							tab?.isMissing && 'missing-container'
						}`}>
						<div
							className={`progress-bar ${tab?.isActive && 'active-bar'} ${tab?.isCompleted && 'completed-bar'} ${
								tab?.isMissing && 'missing-bar'
							}`}></div>
						<span className={`disabled-text ${(tab?.isCompleted || tab?.isActive || tab?.isMissing) && 'active-text'}`}>
							{tab?.name} {tab?.isMissing && '(Missing)'}
						</span>
					</div>
				))}
		</div>
	);
};

export default Stepper;
