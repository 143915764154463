const GET_ALLOWANCE = 'GET_ALLOWANCE';
const GET_ALLOWANCE_DATA = 'GET_ALLOWANCE_DATA';
const POST_ALLOWANCE = 'POST_ALLOWANCE';
const PUT_ALLOWANCE_ERROR = 'PUT_ALLOWANCE_ERROR';
const PUT_ALLOWANCE_LOADING = 'PUT_ALLOWANCE_LOADING';

const getAllowance = () => ({
	type: GET_ALLOWANCE,
});
const putAllowanceLoading = (loadingStatus) => ({
	type: PUT_ALLOWANCE_LOADING,
	payload: loadingStatus,
});
const getAllowanceData = (response) => ({
	type: GET_ALLOWANCE_DATA,
	response,
});

const postAllowance = (data, userName) => ({
	type: POST_ALLOWANCE,
	payload: data,
	userName: userName,
});

const putAllowanceError = (error) => ({
	type: PUT_ALLOWANCE_ERROR,
	payload: error,
});

export default {
	types: {
		GET_ALLOWANCE: GET_ALLOWANCE,
		GET_ALLOWANCE_DATA: GET_ALLOWANCE_DATA,
		POST_ALLOWANCE: POST_ALLOWANCE,
		PUT_ALLOWANCE_ERROR: PUT_ALLOWANCE_ERROR,
		PUT_ALLOWANCE_LOADING: PUT_ALLOWANCE_LOADING,
	},
	creators: {
		getAllowance,
		getAllowanceData,
		postAllowance,
		putAllowanceError,
		putAllowanceLoading,
	},
};
