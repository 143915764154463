import React, { useState } from 'react';
import EdenredTypo from '@sharedComponent/typography';
import SelectDropdown from '@pages/DesignSystem/ComboBox/SelectDropdown';
import { options4 } from '@pages/DesignSystem/Json/DesignSystem';
import '../SelectDropdown.scss';

const DisableSelect = () => {
	const [data, setData] = useState('');
	console.log('data', data);
	return (
		<div style={{ width: '50%', paddingTop: '10px' }}>
			<EdenredTypo h3 bold style={{ fontSize: '18px' }}>
				Disabled Select
			</EdenredTypo>
			<SelectDropdown
				className="select-dropdown-without-placeholder"
				// onChange={(data) => console.log('datass', data)}
				onChange={(data) => setData(data)}
				Option={options4}
				placeholder="Sub Menu Options"
				searchVisibility={false}
				disabled={true}
			/>
		</div>
	);
};

export default DisableSelect;
