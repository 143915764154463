import React from 'react';
import { Col } from 'antd';
import ItemHeader from '../../components/ItemHeader';
import './gratuity-calculate.scss';

const GratuityCalculate = () => {
	return (
		<Col className="gratuity-calculate-presentational">
			<ItemHeader title="End of Service / Gratuity Calculation" />
			<Col className="questions" xl={24} lg={24} md={24} sm={24} xs={24}>
				<p className="gratuity">How is gratuity / EOSB calculated under UAE Labour Law?</p>
				<p className="terminated">When the employee is terminated by the employer in a limited or unlimited contract:</p>
				<Col className="table" xl={24} lg={24} md={24} sm={24} xs={24}>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="long">How long has the employee worked at your company?</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}></Col>
					</Col>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="year">Less than 1 year</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}>
							<p className="year">The employee is not entitled to any gratuity pay.</p>
						</Col>
					</Col>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="year">1-5 Years</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}>
							<p className="year">
								The employee is entitled for 21 days pay for each year worked. This pay is calculated based on the final basic salary
								of the employee.
							</p>
						</Col>
					</Col>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24} style={{ borderBottom: '0px' }}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="year">More than 5 years</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}>
							<p className="year">
								First five years worked = 21 days pay for each year. For each year worked in addition to the first 5 years = 30 days
								for each year.
							</p>
						</Col>
					</Col>
				</Col>
				<p className="contract">When the employee resigns from an unlimited contract:</p>
				<Col className="table" xl={24} lg={24} md={24} sm={24} xs={24}>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="long">How long has the employee worked at your company?</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}></Col>
					</Col>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="year">Less than 1 year</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}>
							<p className="year">The employee is not entitled to any gratuity pay.</p>
						</Col>
					</Col>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="year">1-3 Years</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}>
							<p className="year">
								The employee is entitled for 7 days pay for each year worked. This pay is calculated based on the final basic salary
								of the employee.
							</p>
						</Col>
					</Col>
					<Col className="outer-row" xl={24} lg={24} md={24} sm={24} xs={24} style={{ borderBottom: '0px' }}>
						<Col className="outer-one" xl={6} lg={6} md={6} sm={6} xs={6}>
							<p className="year"> 3-5 years</p>
						</Col>
						<Col className="outer-two" xl={18} lg={18} md={18} sm={18} xs={18}>
							<p className="year">
								The employee is entitled for 14 days pay for each year worked. This pay is calculated based on the final basic salary
								of the employee.
							</p>
						</Col>
					</Col>
				</Col>
			</Col>
		</Col>
	);
};
export default GratuityCalculate;
