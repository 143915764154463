const GET_HISTORY_DEPARTMENT_START = 'GET_HISTORY_DEPARTMENT_START';
const GET_HISTORY_DEPARTMENT_SUCCESS = 'GET_HISTORY_DEPARTMENT_SUCCESS';
const GET_HISTORY_DEPARTMENT_FAIL = 'GET_HISTORY_DEPARTMENT_FAIL';

const getHistoryDepartmentStart = () => {
	return {
		type: GET_HISTORY_DEPARTMENT_START,
	};
};
const getHistoryDepartmentSuccess = (response) => {
	return {
		type: GET_HISTORY_DEPARTMENT_SUCCESS,
		response,
	};
};
const getHistoryDepartmentFail = (error) => {
	return {
		type: GET_HISTORY_DEPARTMENT_FAIL,
		error,
	};
};

export default {
	types: {
		GET_HISTORY_DEPARTMENT_START,
		GET_HISTORY_DEPARTMENT_SUCCESS,
		GET_HISTORY_DEPARTMENT_FAIL,
	},
	creators: {
		getHistoryDepartmentStart,
		getHistoryDepartmentSuccess,
		getHistoryDepartmentFail,
	},
};
