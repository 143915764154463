import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ImageComponent from '@sharedComponent/image-component';
import { getCurrentDate, getObjectLength } from '@helpers';
import ETypo from '@pages/DesignSystem/Typo';
import { DATE_FORMAT } from '@constants/app-constants';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import MonthPicker from '@pages/DesignSystem/MonthPicker';
import { images } from '@assets/img';
import EmployeeService from 'src/services/employee-service';
import { icons } from '@assets/icons';
import DepartmentMultiSelect from './departmentMultiSelect';
import SALARY_REPORTS_CONSTANTS from '@pages/salary-reports/constants';
import './date.scss';
import InfiniteScrollDropdown from '@pages/DesignSystem/InfiniteScrollDropdown';

const EdenredDates = ({
	title,
	type,
	onfinish,
	isSubcribed,
	onsubmit,
	loading,
	reporttype,
	downloadReportType,
	isFromSalaryTransferPage,
	options: optionsFromParent = [],
	handleAllDepartment,
	toggleAllDepartment,
	departmentDetails,
	isModalVisible,
	setIsModalVisible,
	handleCancel,
	selectedDepartment,
	handleModalCancel,
	cardReportType,
}) => {
	const history = useHistory();
	let employeeApi = new EmployeeService();
	const monthDDList = [
		{ index: 0, label: 'January', isSelected: false },
		{ index: 1, label: 'February', isSelected: false },
		{ index: 2, label: 'March', isSelected: false },
		{ index: 3, label: 'April', isSelected: false },
		{ index: 4, label: 'May', isSelected: false },
		{ index: 5, label: 'June', isSelected: false },
		{ index: 6, label: 'July', isSelected: false },
		{ index: 7, label: 'August', isSelected: false },
		{ index: 8, label: 'September', isSelected: false },
		{ index: 9, label: 'October', isSelected: false },
		{ index: 10, label: 'November', isSelected: false },
		{ index: 11, label: 'December', isSelected: false },
	];
	const [message, setmessage] = useState(false);
	const [dateRangErrMessage, setDateRangErrMessage] = useState(false);
	//future ref
	const [monthDateRangErrMessage, setMonthDateRangErrMessage] = useState(false);
	const [value, setValue] = useState();
	const [selectedYear, setSelectedYear] = useState();
	const [selectedMonth, setSelectedMonth] = useState();
	// let Dates = getMonths(selectedMonth, selectedYear); future ref
	const selectedDate = new Date().getDate();
	// To date states
	var nDate = new Date(),
		y = nDate.getFullYear(),
		m = nDate.getMonth();
	const selectedToDate =
		type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports ? new Date(y, m + 1, 0).getDate() : new Date().getDate();
	const [selectedToYear, setSelectedToYear] = useState();
	const [selectedToMonth, setSelectedToMonth] = useState();
	//future referrence
	// let ToDates = getMonths(selectedToMonth, selectedToYear);
	const [clicked, setClicked] = useState({
		processed: true,
		notProcessed: false,
	});
	const [salaryProcess, setsalaryProcess] = useState('salaryprocessed');
	const [options, setOptions] = useState([]);
	const [salaryFromToSelectedDate, setSalaryFromToSelectedDate] = useState({
		fromDate: getCurrentDate(DATE_FORMAT?.DD_MM_YYYY),
		toDate: getCurrentDate(DATE_FORMAT?.DD_MM_YYYY),
		rangeDate: getCurrentDate(DATE_FORMAT?.DD_MM_YYYY) + 'to' + getCurrentDate(DATE_FORMAT?.DD_MM_YYYY),
		month: nDate.getMonth(),
		year: nDate.getFullYear(),
		monthYear: getCurrentDate(DATE_FORMAT?.MMM_YYYY),
		department: [],
	});

	const [employeePageNumber, setEmployeePageNumber] = useState(1);
	const [employeeDropdownLoading, setEmployeeDropdownLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		handleChangeYear(new Date().getFullYear(), 1);
		handleChangeToYear(new Date().getFullYear(), 1);
		// await initializeMonth(new Date().getMonth()); // TODO MONTHS ARE SET BY HANDLE CHANGE YEAR
	}, []);

	async function fetchEmployees(searchKey = '', pageNumber = 1, reset = false) {
		setEmployeeDropdownLoading(true);
		let queryParams = {
			status: 'ACTIVE',
			pageSize: 10,
			pageNumber,
		};
		if (searchKey) {
			queryParams.search = searchKey;
		}
		// here reset props will reset all employees data with initial page number
		if (reset && searchKey) {
			setEmployeePageNumber(1);
		}
		try {
			const employeeApi = new EmployeeService();
			const res = await employeeApi.getEmployees(queryParams);
			const { employees } = res.data || {};
			if (employees) {
				if (reset) {
					setOptions(employees);
				} else if (!reset && searchKey) {
					setOptions((prevData) => {
						// Create a Map with the current options using the employee `id` as the key
						const prevDataMap = new Map(prevData.map((employee) => [employee.employeeId, employee]));

						// Loop through new employees and add them to the map (overwriting duplicates by `id`)
						employees.forEach((employee) => {
							prevDataMap.set(employee.employeeId, employee);
						});

						// Convert the Map back into an array
						return Array.from(prevDataMap.values());
					});
				} else {
					// This process just to remove the duplicates in array
					setOptions((prevData) => {
						// Create a Map with the current options using the employee `id` as the key
						const prevDataMap = new Map(prevData.map((employee) => [employee.employeeId, employee]));

						// Loop through new employees and add them to the map (overwriting duplicates by `id`)
						employees.forEach((employee) => {
							prevDataMap.set(employee.employeeId, employee);
						});

						// Convert the Map back into an array
						return Array.from(prevDataMap.values());
					});
				}
				if (!employees?.length || employees?.length < 10) {
					setHasMore(false);
				} else {
					setHasMore(true);
				}
				setEmployeeDropdownLoading(false);
			}
		} catch (error) {
			console.error(error);
			setEmployeeDropdownLoading(false);
		}
	}

	const onSelect = (data) => setValue(data?.employeeCode);
	const currentMonths = async (year, defalut, type) => {
		let currentYear = new Date().getFullYear();
		let date = new Date().setFullYear(year);
		let currentIndex = new Date(date).getMonth();
		let newMonth = [];
		if (currentYear == year) {
			newMonth = monthDDList?.filter((value) => {
				if (value?.index <= currentIndex) {
					return value;
				}
			});
		} else {
			newMonth = monthDDList;
		}
		//await setMonths([...newMonth]);
		if (defalut == 1) {
			let month = monthDDList?.filter((value) => value?.index == new Date().getMonth());
			if (month && month?.[0]?.label) {
				if (type === 'SelectedToMonth') {
					setSelectedToMonth(month?.[0]?.label);
				} else {
					setSelectedMonth(month?.[0]?.label);
				}
			}
		}
	};
	const handleChangeYear = async (year, defalut = 0) => {
		setSelectedYear(year);
		currentMonths(year, defalut, (type = 'selectedYear'));
		let FromDate = year + '-' + selectedMonth + '-' + selectedDate;
		let ToDate = selectedToYear + '-' + selectedToMonth + '-' + selectedToDate;
		validationTofileds(FromDate, ToDate);
	};
	// user Select To year
	const handleChangeToYear = async (year, defalut = 0) => {
		setSelectedToYear(year);
		currentMonths(year, defalut, (type = 'SelectedToMonth'));
		let FromDate = selectedYear + '-' + selectedMonth + '-' + selectedDate;
		let ToDate = year + '-' + selectedToMonth + '-' + selectedToDate;
		validationTofileds(FromDate, ToDate);
	};
	const validationTofileds = (FromDate, ToDate) => {
		if (Date.parse(FromDate) > Date.parse(ToDate)) {
			setmessage(true);
		} else if (Date.parse(FromDate) < Date.parse(ToDate)) {
			setmessage(false);
		} else {
			setmessage(false);
		}
	};
	const channelOptions = [
		{
			title: 'Salary Processed',
			icon: clicked?.processed ? icons.focus_state.radioButtonActive : icons.focus_state.radioButtonInactive,
			isSelected: clicked?.processed,
			type: 'salaryprocessed',
		},
		{
			title: 'Salary Not Processed',
			icon: clicked?.notProcessed ? icons.focus_state.radioButtonActive : icons.focus_state.radioButtonInactive,
			isSelected: clicked?.notProcessed,
			type: 'salarynotprocessed',
		},
	];

	const handlePerEmpdate = (data) => {
		const startDate = data?.split('to')?.[0]?.replace(/ /g, '');
		const endDate = data?.split('to')?.[1]?.replace(/ /g, '');
		setSalaryFromToSelectedDate({ ...salaryFromToSelectedDate, fromDate: startDate, toDate: endDate, rangeDate: data });
	};

	const onMonthSelect = (selectedMonth) => {
		if (selectedMonth && selectedMonth.from && selectedMonth.to) {
			if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports) {
				setMonthDateRangErrMessage(false);
			}
			const formattedFromMonth = new Date(selectedMonth.from).toLocaleDateString('en-US');
			const formattedToMonth = new Date(selectedMonth.to).toLocaleDateString('en-US');

			if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports) {
				const dateFromeTime = moment(formattedFromMonth, 'MM/DD/YYYY').format('DD/MM/YYYY');

				const dateToTime = moment(formattedToMonth, 'MM/DD/YYYY').format('DD/MM/YYYY');

				setSalaryFromToSelectedDate({ ...salaryFromToSelectedDate, fromDate: dateFromeTime, toDate: dateToTime });
			} else {
				setSalaryFromToSelectedDate({ ...salaryFromToSelectedDate, fromDate: formattedFromMonth, toDate: formattedToMonth });
			}
		}
	};
	const handleOptionChange = (data, param) => {
		setSalaryFromToSelectedDate({ ...salaryFromToSelectedDate, department: data });
		// salaryFromToSelectedDate.department.push(data.id);
	};
	// const selectedOptions = (optionsFromParent || []).filter((option) => option.isSelected && option.id); future ref
	const departments = salaryFromToSelectedDate?.department.map((el) => el.id);

	const onSubmitProof = async (reportformat, salaryFromToSelectedDate) => {
		let dateFrom = salaryFromToSelectedDate?.fromDate;
		let dateTo = salaryFromToSelectedDate?.toDate;
		const format1 = 'YYYY-MM-DD';
		const FromTime = moment(dateFrom, 'DD/MM/YYYY').format(format1);
		const dateToTime = moment(dateTo, 'DD/MM/YYYY').format(format1);

		let reqObj = {};
		const monthDifference = moment(new Date(dateToTime)).diff(new Date(FromTime), 'months', true);

		if (monthDifference <= 1 && type === SALARY_REPORTS_CONSTANTS.salaryForm.type.historyEmployee) {
			// if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.historyEmployee) {
			setDateRangErrMessage(false);
			reqObj.fromDate = FromTime;
			reqObj.toDate = dateToTime;
			reqObj.salarymethod = salaryProcess;
			// reqObj.salarymethod = value ? 'salaryperemployee' : salaryProcess;
			if (value !== '') {
				reqObj.employeeId = value;
				// reqObj.employeeId = value ? value.split('__')[1] : null;
			}
			reqObj.salarytype = type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports ? 'MONTHWISE' : 'ALL';
			reqObj.reportType = reportformat;
			reqObj.departmentList = departments || '';
			onsubmit(reqObj);
		} else if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.historyEmployee) {
			setDateRangErrMessage(true);
		}

		if (monthDifference <= 2 && type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports) {
			//future ref
			//setMonthDateRangErrMessage(false);
			reqObj.fromDate = FromTime;
			reqObj.toDate = dateToTime;
			reqObj.salarymethod = salaryProcess;
			// reqObj.salarymethod = value ? 'salaryperemployee' : salaryProcess;
			if (value !== '') {
				reqObj.employeeId = value;
				// reqObj.employeeId = value ? value.split('__')[1] : null;
			}
			reqObj.salarytype = type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports ? 'MONTHWISE' : 'ALL';
			reqObj.reportType = reportformat;
			reqObj.departmentList = departments || '';
			onsubmit(reqObj);
		} else if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports) {
			//future ref
			setMonthDateRangErrMessage(true);
		}

		if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.perEmployee) {
			//future ref
			//setMonthDateRangErrMessage(false);
			reqObj.fromDate = FromTime;
			reqObj.toDate = dateToTime;
			reqObj.salarymethod = salaryProcess;
			// reqObj.salarymethod = value ? 'salaryperemployee' : salaryProcess;
			if (value !== '') {
				reqObj.employeeId = value;
				// reqObj.employeeId = value ? value.split('__')[1] : null;
			}
			reqObj.salarytype = type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports ? 'MONTHWISE' : 'ALL';
			reqObj.reportType = reportformat;
			onsubmit(reqObj);
		}
		if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.perType) {
			//future ref
			//setMonthDateRangErrMessage(false);
			if (value !== '') {
				reqObj.employeeId = value;
			}
			reqObj.month = salaryFromToSelectedDate?.month;
			reqObj.year = salaryFromToSelectedDate?.year;
			reqObj.reportType = reportformat;

			onfinish(reqObj);
		}

		if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.cardsReport) {
			onsubmit(FromTime, dateToTime);
		}
	};

	const Channelreports = (e) => {
		setsalaryProcess(e?.type);
		if (e?.type === 'salaryprocessed') {
			setClicked({ processed: true, notProcessed: false });
		} else if (e?.type === 'salarynotprocessed') {
			setClicked({ notProcessed: true, processed: false });
		}
	};

	function monthDefaultValue(date, type) {
		if (date && typeof date === 'string' && date.length > 17) {
			const [fromMonth, toMonth] = date.split('to');

			if (type === 'FROM' && fromMonth) {
				const [day, month, year] = fromMonth.split('/');
				if (day && month && year) {
					return `${year}-${month}-1`;
				} else {
					return null;
				}
			} else if (type === 'TO' && toMonth) {
				const [day, month, year] = toMonth.split('/');
				if (day && month && year) {
					return `${year}-${month}-1`;
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	const buttonDisabled =
		type === SALARY_REPORTS_CONSTANTS.salaryForm.type.perEmployee || type === SALARY_REPORTS_CONSTANTS.salaryForm.type.perType ? value : '';
	const Salaryhistorybuttondisable = type === SALARY_REPORTS_CONSTANTS.salaryForm.type.historyEmployee;
	const salarymonthwisebuttondisable = type === SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports;

	const showReportsOrEmployee = () => {
		if (type === SALARY_REPORTS_CONSTANTS.salaryForm.type.cardsReport) {
			return null;
		} else if (type !== SALARY_REPORTS_CONSTANTS.salaryForm.type.perEmployee && type !== SALARY_REPORTS_CONSTANTS.salaryForm.type.perType) {
			return (
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Col xl={12} md={24} lg={24} sm={24} xs={24}>
						<ETypo medium b1>
							Report Status
						</ETypo>
					</Col>
					<Row className="salary_select_card_section">
						{channelOptions?.map((option, index) => (
							<div key={index} className="salary_select_cards">
								<ImageComponent src={option?.icon} onClick={() => Channelreports(option)} />
								<ETypo light b1 color={option?.isSelected ? '#00344E' : '#484B52'}>
									{option?.title}
								</ETypo>
							</div>
						))}
					</Row>
					{isSubcribed ? (
						<DepartmentMultiSelect
							{...{
								optionsFromParent,
								departmentDetails,
								handleOptionChange,
								title: 'Select Departments',
								handleAllDepartment,
								toggleAllDepartment,
								isModalVisible,
								setIsModalVisible,
								handleCancel,
								selectedDepartment,
								handleModalCancel,
							}}
						/>
					) : null}
				</Col>
			);
		} else {
			return (
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="salary_payment data_slip">
					<Row className="salary_slip">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<ETypo medium b1>
								Select Employee
							</ETypo>
						</Col>
						<Col xl={10} md={13} lg={13} className="salary_slip_data_date">
							<InfiniteScrollDropdown
								{...{
									searchHolder: 'Employee Name',
									placeholder: 'Employee Name',
									Option: options,
									showIcon: false,
									onChange: (e) => onSelect(e),
									dropDownValue: 'employeeName',
									dropDownSubValue: 'employeeCode',
									loading: employeeDropdownLoading,
									page: employeePageNumber,
									setPage: setEmployeePageNumber,
									fetchData: fetchEmployees,
									hasMoreData: hasMore,
								}}
							/>
						</Col>
					</Row>
				</Col>
			);
		}
	};

	const showDownloadReport = () => {
		const typeOfReport = () => {
			if (downloadReportType === SALARY_REPORTS_CONSTANTS.salaryForm.fileType.excel) {
				return (
					<Col className="upload-btn excel-btn-mob salary_button" style={{ marginLeft: '8px' }}>
						<EdenredButtons
							loading={loading}
							btnType="secondary"
							disabled={!salaryFromToSelectedDate.fromDate || !salaryFromToSelectedDate.toDate}
							onClick={() => {
								onSubmitProof(SALARY_REPORTS_CONSTANTS.salaryForm.fileType.excel, salaryFromToSelectedDate);
							}}
							leftIcon={<ImageComponent src={icons?.focus_state.hrExport} />}>
							{SALARY_REPORTS_CONSTANTS.salaryForm.excelLabelText}
						</EdenredButtons>
						{loading && <div style={{ paddingTop: '10px' }}>{SALARY_REPORTS_CONSTANTS.salaryForm.loaderhelperText}</div>}
					</Col>
				);
			} else if (downloadReportType === SALARY_REPORTS_CONSTANTS.salaryForm.fileType.pdf) {
				return (
					<Col className="upload-btn upload-mob salary_button">
						<EdenredButtons
							loading={loading}
							btnType="secondary"
							disabled={!salaryFromToSelectedDate.fromDate || !salaryFromToSelectedDate.toDate}
							onClick={() => {
								onSubmitProof(SALARY_REPORTS_CONSTANTS.salaryForm.fileType.pdf, salaryFromToSelectedDate);
							}}
							leftIcon={<ImageComponent src={icons?.focus_state.hrExport} />}>
							{SALARY_REPORTS_CONSTANTS.salaryForm.pdfLabelText}
						</EdenredButtons>
						{loading && <div style={{ paddingTop: '10px' }}>{SALARY_REPORTS_CONSTANTS.salaryForm.loaderhelperText}</div>}
					</Col>
				);
			} else {
				return (
					<>
						<Col className="upload-btn upload-mob salary_button">
							<EdenredButtons
								loading={reporttype === SALARY_REPORTS_CONSTANTS.salaryForm.fileType.pdf ? loading : null}
								btnType="secondary"
								disabled={
									(!buttonDisabled && !Salaryhistorybuttondisable && !salarymonthwisebuttondisable && !monthDateRangErrMessage) ||
									(!getObjectLength(salaryFromToSelectedDate?.department) > 0 && isSubcribed && optionsFromParent?.length !== 0) ||
									message ||
									(!clicked?.processed && !clicked?.notProcessed)
								}
								onClick={() => {
									onSubmitProof(SALARY_REPORTS_CONSTANTS.salaryForm.fileType.pdf, salaryFromToSelectedDate);
								}}
								leftIcon={
									<ImageComponent
										src={
											(!buttonDisabled &&
												!Salaryhistorybuttondisable &&
												!salarymonthwisebuttondisable &&
												!monthDateRangErrMessage) ||
											(!getObjectLength(salaryFromToSelectedDate?.department) > 0 &&
												isSubcribed &&
												optionsFromParent?.length !== 0) ||
											message ||
											(!clicked?.processed && !clicked?.notProcessed)
												? icons?.not_focus?.inactiveSalaryDownload
												: icons?.focus_state.hrExport
										}
									/>
								}>
								{SALARY_REPORTS_CONSTANTS.salaryForm.pdfLabelText}
							</EdenredButtons>
							{reporttype === SALARY_REPORTS_CONSTANTS.salaryForm.fileType.pdf && loading && (
								<div style={{ paddingTop: '10px' }}>{SALARY_REPORTS_CONSTANTS.salaryForm.loaderhelperText}</div>
							)}
						</Col>
						{!isFromSalaryTransferPage && (
							<Col className="upload-btn excel-btn-mob salary_button" style={{ marginLeft: '8px' }}>
								<EdenredButtons
									loading={reporttype === 'Excel' ? loading : null}
									btnType="secondary"
									disabled={
										(!buttonDisabled && !Salaryhistorybuttondisable && !salarymonthwisebuttondisable) ||
										(!getObjectLength(salaryFromToSelectedDate?.department) > 0 &&
											isSubcribed &&
											optionsFromParent?.length !== 0) ||
										message ||
										(!clicked?.processed && !clicked?.notProcessed)
									}
									onClick={() => {
										onSubmitProof('Excel', salaryFromToSelectedDate);
									}}
									leftIcon={
										<ImageComponent
											src={
												(!buttonDisabled && !Salaryhistorybuttondisable && !salarymonthwisebuttondisable) ||
												(!getObjectLength(salaryFromToSelectedDate?.department) > 0 &&
													isSubcribed &&
													optionsFromParent?.length !== 0) ||
												message ||
												(!clicked?.processed && !clicked?.notProcessed)
													? icons?.not_focus?.inactiveSalaryDownload
													: icons?.focus_state.hrExport
											}
										/>
									}>
									{SALARY_REPORTS_CONSTANTS.salaryForm.excelLabelText}
								</EdenredButtons>
								{reporttype === 'Excel' && loading && (
									<div style={{ paddingTop: '10px' }}>{SALARY_REPORTS_CONSTANTS.salaryForm.loaderhelperText}</div>
								)}
							</Col>
						)}
					</>
				);
			}
		};
		return (
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="salary_reports_buttons">
				<Col xl={12} md={24} lg={24} sm={24} xs={24}>
					<ETypo medium b1>
						Download Report
					</ETypo>
				</Col>
				<Row className="salary_report_button_container">{typeOfReport()}</Row>
			</Col>
		);
	};
	const showContactEmail = () => {
		return (
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="salary_report_cheques">
				<ETypo light b1 color="#484B52">
					If you require an official letter from us, please write to us at{' '}
					<a href="mailto:support.ae@edenred.com">support.ae@edenred.com</a>
				</ETypo>
			</Col>
		);
	};

	const showDateSelection = () => {
		const dateSelectionTitle = () => {
			if (cardReportType) {
				if (cardReportType === SALARY_REPORTS_CONSTANTS.cards.reportType.issue) {
					return 'Date Issued';
				} else if (cardReportType === SALARY_REPORTS_CONSTANTS.cards.reportType.delete) {
					return 'Date Deleted';
				}
			} else {
				return type?.includes('salary_per') ? 'Select Date' : 'Date Processed';
			}
		};
		if (type !== SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports && type !== SALARY_REPORTS_CONSTANTS.salaryForm.type.perType) {
			return (
				<Col className="salaray-report-parent" xl={24} lg={24} md={24} sm={24} xs={24}>
					<Col xl={12} md={24} lg={24} sm={24} xs={24}>
						<ETypo medium b1>
							{dateSelectionTitle()}
						</ETypo>
					</Col>
					<Row className="salary_report_date_select">
						<Col xl={8} md={13} lg={13}>
							<DatePickers
								{...{
									dateMode: 'range',
									rangeDateActivePlaceHolder: 'DD/MM/YYYY',
									maxDate: '31/12/' + new Date().getFullYear(),
									onChange: handlePerEmpdate,
									value: salaryFromToSelectedDate?.rangeDate,
									isErrorMessage: dateRangErrMessage,
									errorMessage: 'Please select a 1-month range.',
								}}
							/>
						</Col>
					</Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="cheques">
						<ETypo light b2 color="#737780">
							The month and year reflect the month and year chosen while processing the employees salaries.
						</ETypo>
					</Col>
				</Col>
			);
		} else {
			return (
				<Col className="salaray-report-parent" xl={24} lg={24} md={24} sm={24} xs={24}>
					<Col xl={12} md={24} lg={24} sm={24} xs={24}>
						<ETypo medium b1>
							Select Date
						</ETypo>
					</Col>
					<Col xl={8} md={13} lg={13}>
						{type !== SALARY_REPORTS_CONSTANTS.salaryForm.type.perType ? (
							<>
								<MonthPicker
									fromMonthDefaultValue={monthDefaultValue(salaryFromToSelectedDate?.rangeDate, 'FROM').replace(/-/gi, '/')}
									toMonthDefaultValue={monthDefaultValue(salaryFromToSelectedDate?.rangeDate, 'TO').replace(/-/gi, '/')}
									isDateError={monthDateRangErrMessage}
									dateErrorMessage={'Please select a 1-month range within same year.'}
									onChange={onMonthSelect}
									isRange={false}
								/>
							</>
						) : (
							<>
								<MonthPicker
									fromMonthDefaultValue={monthDefaultValue(salaryFromToSelectedDate?.rangeDate, 'FROM').replace(/-/gi, '/')}
									toMonthDefaultValue={monthDefaultValue(salaryFromToSelectedDate?.rangeDate, 'TO').replace(/-/gi, '/')}
									isDateError={dateRangErrMessage}
									errorMessage={'Please select a 1-month range.'}
									onChange={onMonthSelect}
									isRange={false}
								/>
							</>
						)}
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="cheques">
						<ETypo light b2>
							The month and year reflect the month and year chosen while processing the employees salaries.
						</ETypo>
					</Col>
				</Col>
			);
		}
	};

	return (
		<Col className="salary_reports_presentational_container">
			<Row className="salary_reports_presentational">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="upload">
					<ETypo bold h2>
						{title}
					</ETypo>
					<EdenredButtons
						onClick={() => history.goBack()}
						btnType="secondary"
						leftIcon={<ImageComponent src={images.leftsarrow} />}
						style={{ marginTop: '0px', alignItems: 'center' }}>
						Back
					</EdenredButtons>
				</Col>
				<Row className="salary_report_main_container" data-testid="test_edenred_salary_report_form">
					{showReportsOrEmployee()}
					{showDateSelection()}
					{showDownloadReport()}
					{showContactEmail()}
				</Row>
			</Row>
		</Col>
	);
};

export default EdenredDates;
