export const emoji = {
	Alert: require('./Alert.png'),
	ATM: require('./ATM.png'),
	Bank: require('./Bank.png'),
	Buildings: require('./Buildings.png'),
	Calendar: require('./Calendar.png'),
	Card: require('./Card.png'),
	Chat: require('./Chat.png'),
	Computer: require('./Computer.png'),
	DocumentBoard: require('./Document-Board.png'),
	DocumentPencil: require('./Document-Pencil.png'),
	DocumentVarios: require('./Document-Varios.png'),
	Document: require('./Document.png'),
	EnvelopArrow: require('./Envelop-Arrow.png'),
	EnvelopSending: require('./Envelop-Sending.png'),
	Envelop: require('./Envelop.png'),
	Family: require('./Family.png'),
	Flight: require('./Flight.png'),
	Gift: require('./Gift.png'),
	Graphics: require('./Graphics.png'),
	GuyRunning: require('./GuyRunning.png'),
	Home: require('./Home.png'),
	International: require('./International.png'),
	LockUnlock: require('./Lock-Unlock.png'),
	Lock: require('./Lock.png'),
	LogIn: require('./LogIn.png'),
	Mailbox: require('./Mailbox.png'),
	Man: require('./Man.png'),
	MobileNotification: require('./Mobile-Notification.png'),
	Mobile: require('./Mobile.png'),
	MoneyFlying: require('./Money-Flying.png'),
	MoneyNotes: require('./Money-Notes.png'),
	Money: require('./Money.png'),
	Notification: require('./Notification.png'),
	Password: require('./Password.png'),
	Petrol: require('./Petrol.png'),
	Police: require('./Police.png'),
	Rates: require('./Rates.png'),
	Search: require('./Search.png'),
	TelephoneOld: require('./Telephone-Old.png'),
	Telephone: require('./Telephone.png'),
	Thinking: require('./Thinking.png'),
	Trolley: require('./Trolley.png'),
	Warning: require('./Warning.png'),
};
