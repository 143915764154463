import React from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { amountWithCommas, numberWithCommas } from '@helpers';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { PayrollSelectDropdown } from './payroll-select-dropdown';
import { PayrollDatePicker, PopUp } from './payroll-date-picker';
import { AddAllowance, PayrollInput } from './payroll-input';
import './payroll-setting.scss';

function PayrollSettingPresentational({
	payrollDetails,
	departmentList,
	allowanceListed,
	handlePayrollDate,
	handleRadioButton,
	isEnabled = false,
	handleDropDown,
	handleInput,
	handleAddAllowance,
	handleDeleteAllowance,
	handlePayrollPopup,
	addAllowance,
	totalValue,
	contractTypeList,
	isPayrollLoading = false,
	isAllowanceLoading = false,
	savePopUp = false,
	savedSuccessfully = false,
	isPayrollSubmitLoading = false,
	isSavePopUp = false,
	notification = false,
	allowancevalidation,
	aedvalidation,
	employeeDetails,
	isPayrollSettingClaim,
	allowanceList,
}) {
	const history = useHistory();
	let nAllowance = [];
	return (
		<Row className="payroll-setting-main-header">
			{isPayrollLoading ? (
				<Col xl={19} lg={19} className="content-Loader-payroll">
					<DotLoader size={100} color={'#e91d24'} loading />
				</Col>
			) : (
				<>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll-setting-container">
						<ETypo medium h4>
							Contract
						</ETypo>
						<Col xl={20} lg={20} md={20} sm={22} xs={22} className="payroll-setting-limit">
							<ETypo light b2 color="#181919">
								Select Limited if your employee's contract has a specified end date.
							</ETypo>
						</Col>
						<Col xl={10} lg={10} md={10} sm={22} xs={22} className="payroll-setting-select">
							{contractTypeList?.map((data, i) => (
								<div className="payroll-radio-img">
									<ImageComponent
										className="payroll-img-cmp"
										onClick={() => !employeeDetails?.isInactivatedforDeletion && handleRadioButton(data?.name, data?.id)}
										src={isEnabled?.[data?.name] ? focus_state_icons.radioButtonActive : focus_state_icons.radioButtonInactive}
									/>
									<ETypo b2 className={`payroll-setting-radio-${isEnabled?.[data?.name]}`}>
										{data?.name.charAt(0).toUpperCase() + data?.name.slice(1).toLowerCase()}
									</ETypo>
								</div>
							))}
						</Col>
						<Row className="payroll_setting_date_select">
							<Col xl={12} lg={12} md={12} sm={22} xs={22} className="payroll-setting-date padding-right-32">
								<PayrollDatePicker
									{...{
										defaultDate: payrollDetails?.joiningDate,
										placeholder: 'Joining Date',
										onChange: (dateObj, formatStr) => handlePayrollDate(dateObj, formatStr, 'joiningDate'),
										dateLoader: isPayrollLoading,
										disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
									}}
								/>
							</Col>
							{isEnabled?.Limited && !isEnabled.UnLimited ? (
								<Col xl={12} lg={12} md={12} sm={22} xs={22} className="payroll-setting-date">
									<PayrollDatePicker
										{...{
											payrollDetails,
											defaultDate: payrollDetails?.endDate,
											placeholder: 'Contract End Date',
											onChange: (dateObj, formatStr) => handlePayrollDate(dateObj, formatStr, 'endDate'),
											dateLoader: isPayrollLoading,
											disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
										}}
									/>
								</Col>
							) : null}
						</Row>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll-setting-headers">
						<div className="payroll_setting_department">
							<div className="payroll_setting_department_content">
								<ETypo medium h4>
									Department / Site
								</ETypo>
								<ETypo light b2 color="#181919">
									You can use departments to divide your employees into sites or projects.
								</ETypo>
							</div>
							<div className="payroll_setting_department_button">
								<EdenredButtons
									btnType="secondary"
									onClick={() => history.push('/company/company-setting')}
									leftIcon={<img src={not_focus_icons.Plus} />}>
									Departments / Sites
								</EdenredButtons>
							</div>
						</div>
						<Col className="payroll-setting-inner">
							<Col xl={12} lg={12} md={12} sm={22} xs={22} className="payroll-setting-dropdown padding-right-32">
								<PayrollSelectDropdown
									{...{
										dropDownLabel: 'Department / Site',
										placeholder: 'Select Department / sites',
										value: payrollDetails?.department,
										Option: departmentList,
										dropDownValue: 'name',
										onChange: (data) => handleDropDown(data, 'department'),
										disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
									}}
								/>
							</Col>
							<Col xl={12} lg={12} md={12} sm={22} xs={22} className="payroll-setting-dropdown-input">
								<PayrollInput
									{...{
										onChange: (e) => handleInput(e, 'designation'),
										payrollDetails,
										value: payrollDetails?.designation,
										placeholder: 'Designation',
										disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
									}}
								/>
							</Col>
						</Col>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll-setting-sub-header">
						<div className="payroll_setting_basic_salary">
							<ETypo medium h4>
								Salary
							</ETypo>
							<ETypo bold className="payroll-setting-gross">
								Gross Salary: <p> AED {numberWithCommas(totalValue?.toFixed(2))}</p>
							</ETypo>
						</div>
						<Col xl={11} lg={11} md={11} sm={22} xs={22} className="payroll-setting-input">
							<PayrollInput
								{...{
									onChange: (e) => handleInput(e, 'salary'),
									payrollDetails,
									prefix: 'AED',
									placeholder: 'Basic Salary',
									value: amountWithCommas(payrollDetails?.salary),
									type: 'text',
									min: 0,
									disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
								}}
							/>
						</Col>
						{addAllowance?.length > 0 && (
							<>
								<div className="payroll_setting_salary_allowance">Allowances</div>
								<Row className="payroll-setting-sub-inner-main">
									{addAllowance?.map((addAllowance, i) => {
										return (
											<AddAllowance
												{...{
													handleInput,
													nAllowanceList: addAllowance,
													i,
													addAllowance,
													handleDeleteAllowance,
													payrollDetails,
													value: '0',
													allowanceListed,
													handleDropDown,
													isAllowanceLoading,
													isEnabled,
													allowancevalidation,
													aedvalidation,
													employeeDetails,
													isPayrollSettingClaim,
													allowanceList,
													nAllowance,
												}}
											/>
										);
									})}
								</Row>
							</>
						)}
						{isEnabled?.disAedAble && (
							<p className="disableButton">
								{isEnabled?.disAedAble && !allowancevalidation
									? 'Please select allowance type'
									: isEnabled?.disAedAble && !aedvalidation
									? 'Please enter an amount'
									: ''}
							</p>
						)}
						<Col className="payroll_setting_add">
							<EdenredButtons
								btnType="secondary"
								className="payroll-setting-add-alowance"
								leftIcon={<ImageComponent className="payroll-img-plus" src={not_focus_icons.Plus} />}
								onClick={handleAddAllowance}>
								Add Allowance
							</EdenredButtons>
						</Col>
					</Col>
					<PopUp {...{ isSavePopUp, notification, handlePayrollPopup, isPayrollSubmitLoading, isEnabled, savePopUp, savedSuccessfully }} />
				</>
			)}
		</Row>
	);
}
export default PayrollSettingPresentational;
