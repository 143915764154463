import React from 'react';

function EwaTermsAndConfitions(props) {
	return (
		<div style={{ width: '80%', margin: '60px auto' }}>
			<h2 style={{ fontFamily: 'Montserrat-Medium' }}>Terms and Conditions</h2>
			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>Introduction: </h3>

			<p>
				These terms and conditions (the "Agreement") outline the terms under which your company ("Company") and its employees ("Employees")
				who hold C3Pay cards can access and utilize the Earned Wage Access ("EWA") service offered through the C3Pay App, facilitated by ABHI
				Fintech Limited ("ABHI").
			</p>

			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>1. EWA Service Description:</h3>
			<p>1.1 The EWA service enables eligible Employees of your Company to access a portion of their earned salary before their next payday.</p>
			<p>
				1.2 The EWA service is provided through the C3Pay App, which serves as the platform for Employees to access their payroll and avail
				value-added services.
			</p>
			<p>
				1.3 The salary of an employee is calculated on the basis of the average of the last 3 salaries received by the employee on their C3Pay
				card.
			</p>
			<p>1.4 The employee will receive the remaining amount of his salary during the next salary credit,.</p>

			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>2. No Risk to Company:</h3>
			<p>
				2.1 In case a cardholder fails to receive their next salary, the{' '}
				<span style={{ fontFamily: 'Montserrat-Medium' }}>company shall not be liable for any losses incurred by ABHI or Edenred.</span>
			</p>
			<p>
				2.2 Company understands and agrees that it will not incur any financial risk or liability as a result of its Employees using the EWA
				service.
			</p>
			<p>
				2.3 In the event of any dispute or issue arising from EWA usage, Company acknowledges that ABHI and Edenred assume full responsibility
				for resolution.
			</p>

			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>3. Data Procurement:</h3>
			<p>
				3.1 By accepting these Terms and Conditions, you will be providing Edenred consent to collect and store your C3Pay Cardholders’
				personal data in furtherance of offering the EWA Service to the respective C3Pay Cardholders
			</p>

			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>4. Changes and Termination:</h3>
			<p>
				4.1 The EWA service is already available to your employees on the C3Pay App. You can contact us for any further questions or concerns
				regarding the service, via our e-mail support.ae@edenred.com.
			</p>
			<p>4.2 ABHI and Edenred reserve the right to modify, suspend, or terminate the EWA service at any time without prior notice.</p>

			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>5. Governing Law and Jurisdiction:</h3>
			<p>5.1 This Agreement shall be governed by and construed in accordance with the laws of the United Arab Emirates.</p>
			<p>
				5.2 Any disputes arising from or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of the
				United Arab Emirates.
			</p>

			<h3 style={{ fontFamily: 'Montserrat-Medium' }}>6. Disclaimer</h3>
			<p>
				6.1 Edenred Prepaid Cards Management LLC and ABHI Fintech Limited (we or us) provides a technology solution that enables the
				cardholder to access an earned but unpaid wage. By agreeing to these terms and condition, you are approving us to offer the service to
				your employees. Neither Edenred Prepaid Cards Management nor Abhi Fintech Limited are offering a financial product require a special
				financial permission license and we are not required to, and do not, hold a UAE Financial Services License.
			</p>
			<p style={{ paddingBottom: '30px' }}>
				6.2 Eligible employees may request their earned but unpaid wages any time by applying to EWA on C3 Pay App. Any EWA request approved
				and paid to the cardholder via Abhi is not an advance, loan or any other form of credit made or given by us or by the employer “you”,
				as it is just a payment of the wages in advance of the normal payday.
			</p>
		</div>
	);
}

export default EwaTermsAndConfitions;
