import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { APP_VARIABLES, DEFAULT_SHOW, SHOW_ONLY_IN_DEV } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';
import { mergeAllClaims } from 'src/utils/claims';
import { QUICK_MENUS } from './helpers';

function useQuickMenus() {
	const [result, setResult] = useState({});
	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	// const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);

	function getQuickMenusToShow() {
		let quickMenusToShow = [];

		for (const _menu of QUICK_MENUS) {
			// If user is not subscriber & menu is only for subscriber users then skip (don't add menu)
			// if (checkSubscription === false && _menu.forSubscribedUsersOnly) continue;
			// If claims contains values in NotToShow of menu then skip (don't add menu)
			// if (_menu.notToShow && _menu.notToShow.some((values) => allClaims.indexOf(values) >= 0)) continue;
			const MENU_HAS_CLAIM = _menu.toShow.some((values) => {
				if (values === SHOW_ONLY_IN_DEV) return true;
				if (values === DEFAULT_SHOW) return true;
				return allClaims.indexOf(values) >= 0;
			});

			if (quickMenusToShow.length >= 6) break;

			if (MENU_HAS_CLAIM) {
				let result = {
					..._menu,
				};
				quickMenusToShow.push(result);
			}
		}

		return quickMenusToShow;
	}

	useEffect(() => {
		const menuToShow = getQuickMenusToShow();
		setResult((prevState) => {
			return {
				...prevState,
				quickMenus: menuToShow,
			};
		});
	}, [currentUser]);

	return {
		...result,
	};
}

export default useQuickMenus;
