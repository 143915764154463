import React from 'react';
import { Col, Row } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenRedCard from '@sharedComponent/card';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import { icons } from 'src/assets/icons';
import ETypo from '@pages/DesignSystem/Typo';
import DividerComponent from '@pages/DesignSystem/Divider';
import { SITEMAP } from 'src/routes/sitemap';
import SALARY_REPORTS_CONSTANTS from './constants';
import './salaryReport.scss';

const SalaryReports = () => {
	const isSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const salaryReportsData = [
		{
			image: icons.focus_state.salaryUsers,
			title: 'Salary Check per Employee',
			description: 'Check the salary amount paid to an employee over a chosen period of time.',
			url: SITEMAP.payroll.salaryReports.perEmployee,
			isVisible: true,
		},
		{
			image: icons.focus_state.salaryGroupUsers,
			title: 'Salary History of All Employees',
			description: 'Check a detailed report of your salary payments to your employees.',
			url: SITEMAP.payroll.salaryReports.history,
			isVisible: true,
		},
		{
			image: icons.focus_state.salaryGroupUsers,
			title: 'Card Issued Report',
			description: 'Reports of cards issued between 2 dates',
			url: `${SITEMAP.payroll.salaryReports.cards}?type=${SALARY_REPORTS_CONSTANTS.cards.reportType.issue}`,
			isVisible: true,
		},
		{
			image: icons.focus_state.salaryGroupUsers,
			title: 'Card Deleted Report',
			description: 'Reports of cards deleted between 2 dates',
			url: `${SITEMAP.payroll.salaryReports.cards}?type=${SALARY_REPORTS_CONSTANTS.cards.reportType.delete}`,
			isVisible: true,
		},
		{
			image: icons.focus_state.salaryMoneyWallet,
			title: 'Salary Report Month-wise (WPS/Non WPS)',
			description: 'Check a detailed report of your salary payments through WPS or Non WPS',
			url: SITEMAP.payroll.salaryReports.monthWiseReports,
			isVisible: true,
		},
		{
			image: icons.focus_state.transferSalaryReport,
			title: 'Salary Transfer Certificate',
			description: 'Download proof of salary payment to your employee for a range of months.',
			url: `${SITEMAP.payroll.salaryReports.perEmployee}?salary=true`,
			isVisible: isSubscription,
		},
	];

	return (
		<>
			<EdenredHeader />
			<Row className="Salary_report_UsefulFiles_container" data-testid="test_salary_reports_container">
				<Col xl={24} md={24} lg={24} className="UsefulFiles_header" data-testid="test_salary_reports_header">
					<ETypo bold h2>
						Salary Reports
					</ETypo>
				</Col>
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="card_content_list">
					{salaryReportsData?.map((reports, index) => {
						return <SalaryReporsCard key={index} {...{ reports, index, salaryReportsData }} />;
					})}
				</Col>
			</Row>
		</>
	);
};

export const SalaryReporsCard = ({ reports, index, salaryReportsData }) => {
	if (reports?.isVisible) {
		return (
			<Link to={reports.url}>
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="UsefulFiles_Card" key={index}>
					<EdenRedCard type="Useful_files" content={reports} symbolicon="salary_reports" className="salaryreports" />
					{index < salaryReportsData?.length - 1 && <DividerComponent />}
				</Col>
			</Link>
		);
	}
	return null;
};

export default withRouter(SalaryReports);
