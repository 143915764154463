import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { icons } from '@assets/icons/index';
import ETypo from '@pages/DesignSystem/Typo';
import UploadPayrollPopup from 'src/utils/uploadPayrollPopup';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './error-table.scss';

export default function ErrorTable({
	data = [
		{
			columnName: 'EmiratesId',
			rowId: [1, 2],
			errorMessage: 'Emirates ID number should begin with 784.',
		},
		{
			columnName: 'EmiratesId',
			rowId: [1, 2],
			errorMessage: "Employee's Date of Birth doesn't match with the Emirates ID number.",
		},
		{
			columnName: 'Iban',
			rowId: [2],
			errorMessage: 'The bank details for the employee will not be considered as you selected C3 as a channel.',
		},
	],
	loadErrorData = () => { },
	isShowAction = true,
	enableNextButton = () => { },
	fromDeletePage = false,
}) {
	const [dataSource, setDataSource] = useState({ tableData: data, expanded: false });
	const [checkList, setCheckList] = useState([]);
	const [visible, setVisible] = useState(false);
	const [temp, setTemp] = useState(null);
	const [message, setMessage] = useState('');
	const [subMessage, setSubMessage] = useState('');
	const [indexNo, setIndexNo] = useState(null);

	useEffect(() => {
		setDataSource({
			...dataSource,
			tableData: data,
		});
	}, [data]);

	const toggleContent = () => {
		setDataSource({
			...dataSource,
			expanded: !expanded,
		});
	};
	const { tableData, expanded } = dataSource;

	let viewContent = '';
	let slicedData = [];
	if (expanded) {
		viewContent = 'less';
		slicedData = tableData?.slice(0, tableData?.length);
	} else {
		viewContent = 'all';
		slicedData = tableData?.slice(0, 2);
	}

	const autoFixEmployeeFileError = (errorEmployeeObject, index) => {
		if (errorEmployeeObject) {
			let errorData = [];
			let rowId = errorEmployeeObject?.rowId;
			if (rowId?.length > 1) {
				rowId.forEach((id, index) => {
					errorData.push({
						columnName: errorEmployeeObject?.columnName,
						empId: id.toString(),
						rowId: id.toString(),
						error: errorEmployeeObject?.errorMessage,
						errorAction: errorEmployeeObject?.errorType,
					});
				});

				loadErrorData(errorData);
			} else {
				let singleArrayObject = [];
				singleArrayObject.push({
					columnName: errorEmployeeObject?.columnName,
					empId: errorEmployeeObject?.rowId?.[0],
					rowId: errorEmployeeObject?.rowId?.[0],
					error: errorEmployeeObject?.errorMessage,
					errorAction: errorEmployeeObject?.errorType,
				});
				loadErrorData(singleArrayObject);
			}
			let checkedList = [];
			checkedList = checkList;
			checkedList.push(index);
			setCheckList(checkedList);
			enableButton();
			setVisible(false);
		}
	};

	const enableButton = () => enableNextButton([...checkList]?.length == tableData?.length);
	const closePopup = () => setVisible(false);

	const setPopupMessage = (errorType) => {
		switch (errorType) {
			case 'Remove':
				setMessage('Remove Employee(s) from File');
				setSubMessage('Do you want to remove employee(s) from the file?');
				break;
			case 'Round-off Decimals':
				setMessage('Round off decimals?');
				setSubMessage('Your file will be submitted with a maximum of 2 decimals for each salary.');
				break;
			case 'Ignore':
				setMessage('Ignore error?');
				setSubMessage('The error will be ignored and the employee will be included in the file.');
				break;
			case 'Bank Error':
				setMessage('Ignore Bank Error?');
				setSubMessage('A C3Pay card will be delivered for this employee.');
				break;
			case 'Remove Bank':
				setMessage('Ignore Bank Error?');
				setSubMessage('A C3Pay card will be delivered for this employee.');
				break;
			default:
				setMessage('Remove Employee(s) from File');
				setSubMessage('Do you want to remove employee(s) from the file?');
				break;
		}
	};
	const errorMessages = {
		monthlyLimit: 'Employee exceeds monthly limit.'
	}
	const errorType = {
		remove: 'Remove',
		required: 'Required'
	}
	return (
		<Col xl={24} md={24} lg={24} sm={24} xs={24}>
			{slicedData[0]?.corporateId ? (
				<Row className="errorTable">
					<Row className="new_error_table">
						<Col {...{ xl: 6, lg: 6, md: 6, xs: 6, ...(!fromDeletePage && { xl: 4, lg: 4, md: 4, xs: 4 }) }} className="new_error_header">
							Column
						</Col>
						<Col {...{ xl: 6, lg: 6, md: 6, xs: 6, ...(!fromDeletePage && { xl: 3, lg: 3, md: 3, xs: 3 }) }} className="new_error_header">
							Corporate ID
						</Col>
						<Col {...{ xl: 6, lg: 6, md: 6, xs: 6, ...(!fromDeletePage && { xl: 3, lg: 3, md: 3, xs: 3 }) }} className="new_error_header">
							Employee ID
						</Col>
						<Col {...{ xl: 7, lg: 7, md: 7, xs: 7, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }} className="new_error_header">
							Error
						</Col>
						{!fromDeletePage && isShowAction && (
							<Col
								{...{ xl: 7, lg: 7, md: 7, xs: 7, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
								className="new_error_header">
								Action
							</Col>
						)}
					</Row>
					<Row style={{ width: '100%' }}>
						{slicedData?.map((data, index) => (
							<Row className="new_error_table_data">
								<Col
									{...{ xl: 6, lg: 6, md: 6, xs: 6, ...(!fromDeletePage && { xl: 4, lg: 4, md: 4, xs: 4 }) }}
									className="new_error_column">
									{
										data?.errorType && (data?.errorType === errorType.remove && data?.errorMessage === errorMessages.monthlyLimit) ? (
											<button
												style={{
													background: 'transparent',
													border: 'none',
													cursor: data?.errorType == 'Required' ? null : 'pointer',

													opacity: checkList?.includes(index) === true ? '0.5' : null,
												}}
												className={data?.errorType == 'Remove' ? 'btnForSalaryExceeds' : 'btnforpayroll'}
												disabled={checkList?.includes(index) === true || data?.errorType === 'Required' ? true : false}
												onClick={() => {
													setVisible(true);
													setTemp({
														columnName: data?.columnName,
														rowId: data?.rowId,
														errorMessage: data?.errorMessage,
														errorType: data?.errorType,
														data: data,
													});
													setPopupMessage(data?.errorType);
													setIndexNo(index);
												}}>
												{/*   */}

												{data?.errorType == errorType.remove && checkList?.includes(index) == false ? (
													<ImageComponent
														style={{
															marginRight: '10px',
															width: '15px',
															display: 'inline',
															cursor: 'pointer',
															opacity: 'none',
														}}
														src={icons.focus_state.trashIconDelete}
													/>
												) : null}
												{data?.errorType == 'Ignore' ? <ImageComponent src={focus_state_icons.ignoreaddemployee} /> : null}
												{data?.errorType == errorType.remove ? (
													`${data.errorType} Employee`
												) : (
													<ETypo b1 className="ignore-style">
														{data.errorType}
													</ETypo>
												)}
											</button>
										) :
											data?.errorType !== 'Required' && data?.errorType !== null ? (
												<button
													style={{
														background: 'transparent',
														border: 'none',
														cursor: data?.errorType == 'Required' ? null : 'pointer',

														opacity: checkList?.includes(index) === true ? '0.5' : null,
													}}
													className={data?.errorType == 'Remove' ? 'btnforpayrollRemove' : 'btnforpayroll'}
													disabled={checkList?.includes(index) === true || data?.errorType === 'Required' ? true : false}
													onClick={() => {
														setVisible(true);
														setTemp({
															columnName: data?.columnName,
															rowId: data?.rowId,
															errorMessage: data?.errorMessage,
															errorType: data?.errorType,
															data: data,
														});
														setPopupMessage(data?.errorType);
														setIndexNo(index);
													}}>
													{/*   */}

													{data?.errorType == 'Remove' && checkList?.includes(index) == false ? (
														<ImageComponent
															style={{
																marginRight: '10px',
																width: '15px',
																display: 'inline',
																cursor: 'pointer',
																opacity: 'none',
															}}
															src={icons.focus_state.deleteIcon}
														/>
													) : null}
													{data?.errorType == 'Ignore' ? <ImageComponent src={focus_state_icons.ignoreaddemployee} /> : null}
													{data?.errorType == 'Remove' ? (
														`${data.errorType} Employee`
													) : (
														<ETypo b1 className="ignore-style">
															{data.errorType}
														</ETypo>
													)}
												</button>
											) : (
												'Correct the excel and upload the file again.'
											)}
								</Col>
								<Col
									{...{ xl: 6, lg: 6, md: 6, xs: 6, ...(!fromDeletePage && { xl: 3, lg: 3, md: 3, xs: 3 }) }}
									className="new_error_column">
									{data?.corporateId}
								</Col>
								<Col
									{...{ xl: 6, lg: 6, md: 6, xs: 6, ...(!fromDeletePage && { xl: 3, lg: 3, md: 3, xs: 3 }) }}
									className="new_error_column">
									<p>{data?.rowId?.join()}</p>
								</Col>
								<Col
									{...{ xl: 7, lg: 7, md: 7, xs: 7, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
									className="new_error_column">
									{data?.errorMessage}
								</Col>
								{!fromDeletePage && isShowAction && (
									<Col
										{...{ xl: 7, lg: 7, md: 7, xs: 7, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
										className="new_error_column">
										{data?.errorType !== 'Required' && data?.errorType !== null ? (
											<button
												style={{
													background: 'transparent',
													border: 'none',
													cursor: data?.errorType == 'Required' ? null : 'pointer',

													opacity: checkList?.includes(index) === true ? '0.5' : null,
												}}
												className={data?.errorType == 'Remove' ? 'btnforpayrollRemove' : 'btnforpayroll'}
												disabled={checkList?.includes(index) === true || data?.errorType === 'Required' ? true : false}
												onClick={() => {
													setVisible(true);
													setTemp({
														columnName: data?.columnName,
														rowId: data?.rowId,
														errorMessage: data?.errorMessage,
														errorType: data?.errorType,
														data: data,
													});
													setPopupMessage(data?.errorType);
													setIndexNo(index);
												}}>
												{/*   */}

												{data?.errorType == 'Remove' && checkList?.includes(index) == false ? (
													<ImageComponent
														style={{
															marginRight: '10px',
															width: '15px',
															display: 'inline',
															cursor: 'pointer',
															opacity: 'none',
														}}
														src={icons.focus_state.deleteIcon}
													/>
												) : null}
												{data?.errorType == 'Ignore' ? <ImageComponent src={focus_state_icons.ignoreaddemployee} /> : null}
												{data?.errorType == 'Remove' ? (
													`${data.errorType} employee`
												) : (
													<ETypo b1 className="ignore-style">
														{data.errorType}
													</ETypo>
												)}
											</button>
										) : (
											'Correct the excel and upload the file again.'
										)}
									</Col>
								)}
								{/* <UploadPayrollPopup
								visible={visible}
								closePopup={closePopup}
								autoFixEmployeeFileError={autoFixEmployeeFileError}
								temp={temp}
								index={indexNo}
								message={message}
								subMessage={subMessage}
							/> */}
								<Edenredmodal
									// className="add-employee-popup"
									title={message}
									desc={subMessage}
									onOkay={() => autoFixEmployeeFileError(temp, indexNo)}
									onCancelButton="Cancel"
									onOkayButton="Save"
									onCancel={() => closePopup()}
									isOpen={visible}
									// topImage={true}
									topImage={<ImageComponent src={icons.focus_state.binIconDelete}></ImageComponent>}
									textcenter={true}
								/>
							</Row>
						))}
					</Row>
				</Row>
			) : (
				<Row className="errorTable">
					<Row className="new_error_table">
						<Col {...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }} className="new_error_header">
							Column
						</Col>
						<Col {...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 5, lg: 5, md: 5, xs: 5 }) }} className="new_error_header">
							Employee ID
						</Col>
						<Col {...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }} className="new_error_header">
							Error
						</Col>
						{!fromDeletePage && isShowAction && (
							<Col
								{...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
								className="new_error_header">
								Action
							</Col>
						)}
					</Row>
					<Row style={{ width: '100%' }}>
						{slicedData?.map((data, index) => (
							<Row className="new_error_table_data">
								<Col
									{...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
									className="new_error_column">
									{data?.columnName}
								</Col>
								<Col
									{...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 5, lg: 5, md: 5, xs: 5 }) }}
									className="new_error_column">
									<p>{data?.rowId?.join()}</p>
								</Col>
								<Col
									{...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
									className="new_error_column">
									{data?.errorMessage}
								</Col>
								{!fromDeletePage && isShowAction && (
									<Col
										{...{ xl: 8, lg: 8, md: 8, xs: 8, ...(!fromDeletePage && { xl: 6, lg: 6, md: 6, xs: 6 }) }}
										className="new_error_column">
										{data?.errorType !== 'Required' && data?.errorType !== null ? (
											<button
												style={{
													background: 'transparent',
													border: 'none',
													cursor: data?.errorType == 'Required' ? null : 'pointer',

													opacity: checkList?.includes(index) === true ? '0.5' : null,
												}}
												className={data?.errorType == 'Remove' ? 'btnforpayrollRemove' : 'btnforpayroll'}
												disabled={checkList?.includes(index) === true || data?.errorType === 'Required' ? true : false}
												onClick={() => {
													setVisible(true);
													setTemp({
														columnName: data?.columnName,
														rowId: data?.rowId,
														errorMessage: data?.errorMessage,
														errorType: data?.errorType,
														data: data,
													});
													setPopupMessage(data?.errorType);
													setIndexNo(index);
												}}>
												{/*   */}

												{data?.errorType == 'Remove' && checkList?.includes(index) == false ? (
													<ImageComponent
														style={{
															marginRight: '10px',
															width: '15px',
															display: 'inline',
															cursor: 'pointer',
															opacity: 'none',
														}}
														src={icons.focus_state.deleteIcon}
													/>
												) : null}
												{data?.errorType == 'Ignore' ? <ImageComponent src={focus_state_icons.ignoreaddemployee} /> : null}
												{data?.errorType == 'Remove' ? (
													`${data.errorType} employee`
												) : (
													<ETypo b1 className="ignore-style">
														{data.errorType}
													</ETypo>
												)}
											</button>
										) : (
											'Correct the excel and upload the file again.'
										)}
									</Col>
								)}
								{/* <UploadPayrollPopup
								visible={visible}
								closePopup={closePopup}
								autoFixEmployeeFileError={autoFixEmployeeFileError}
								temp={temp}
								index={indexNo}
								message={message}
								subMessage={subMessage}
							/> */}
								<Edenredmodal
									// className="add-employee-popup"
									title={message}
									desc={subMessage}
									onOkay={() => autoFixEmployeeFileError(temp, indexNo)}
									onCancelButton="Cancel"
									onOkayButton="Save"
									onCancel={() => closePopup()}
									isOpen={visible}
									// topImage={true}
									topImage={<ImageComponent src={icons.focus_state.binIconDelete}></ImageComponent>}
									textcenter={true}
								/>
							</Row>
						))}
					</Row>
				</Row>
			)}
			{tableData?.length > 2 && (
				<span className="view-text" onClick={toggleContent}>
					View {viewContent}
				</span>
			)}
		</Col>
	);
}
