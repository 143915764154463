import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { checkHrSubscription, getObjectFromArray, isEmailValid, onlyNumberForTextType } from '@helpers';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { ADD_INDIVIDUAL_EMP, APP_VARIABLES, EMPLOYEE_DETAILS_KEY, EMPLOYEE_ERROR_KEY } from '@constants/app-constants';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import action from '../action';
import AddIndividualPresentational from './add-individual-presentational';

const AddIndividualFunctional = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const nationalitydata = useSelector((state) => state?.addIndividualReducer?.nationalitydata);
	const isSaveIndividualLoading = useSelector((state) => state?.addIndividualReducer?.isSaveIndividualLoading);
	const individualEmployeeResponse = useSelector((state) => state?.addIndividualReducer?.individualEmployeeResponse);
	const isEmpPersonalDetailSaved = useSelector((state) => state?.addIndividualReducer?.isEmpPersonalDetailSaved);
	const isSubscribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const [empAccountSelected, setEmpAccountSelected] = useState({
		c3pay: false,
		bank: false,
	});

	const [isValidMobileNumber, setIsValidMobileNumber] = useState({
		value: true,
		message: undefined,
	});

	const [empIndividualDetails, setEmpIndividualDetails] = useState({
		empId: '1002',
		firstName: '',
		lastName: '',
		dob: '',
		nationality: '',
		mobile: '',
		email: '',
		account: '',
		gender: 'M',
	});
	const [validateMessage, setValidationMessage] = useState([]);
	useEffect(() => {
		setValidationMessage(individualEmployeeResponse?.validationMessage || []);
	}, [individualEmployeeResponse]);

	const isMobileNumberError = () => {
		if (
			empIndividualDetails?.mobile &&
			!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.MOBILE_NUMBER)?.errorMessage
		) {
			return true;
		} else if (isValidMobileNumber.value === false) {
			return true;
		} else {
			return false;
		}
	};

	const mobileNumberErrorMessage = () => {
		const message = getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.MOBILE_NUMBER)?.errorMessage;
		if (message) {
			return message;
		} else if (isValidMobileNumber.message) {
			return isValidMobileNumber.message;
		} else {
			return undefined;
		}
	};

	const getContactInfoKey = (str, concatFirst = '', concatLst = '') => {
		return str && concatFirst + str?.charAt(0)?.toUpperCase() + str?.slice(1) + concatLst;
	};

	const validateMobileNumber = (event) => {
		const value = event.target.value;
		if (!value) return;
		if (value?.charAt(0) !== '5') {
			setIsValidMobileNumber({
				value: false,
				message: 'Mobile Number should start with 5',
			});
		} else if (value?.length > 9) {
			setIsValidMobileNumber({
				value: false,
				message: 'Mobile Number must be within 9 digits',
			});
		} else if (value?.length < 9) {
			setIsValidMobileNumber({
				value: false,
				message: 'Mobile Number must be not less than 9 digits',
			});
		} else {
			setIsValidMobileNumber({
				value: true,
				message: undefined,
			});
		}
	};

	// const handleFirstname = (name) => {
	// 	setEmpIndividualDetails({ ...empIndividualDetails, firstName: name });
	// };

	// const handleLastName = (e) => {
	// 	setEmpIndividualDetails({ ...empIndividualDetails, firstName: e?.target?.value });
	// };

	const addIndividualEmpDetails = [
		{
			id: 1,
			label: 'First Name',
			placeholder: 'Enter First Name',
			Component: Inputbox,
			value: empIndividualDetails?.firstName,
			param: 'firstName',
			required: true,
			onChange: (e) => handleEmpDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.FIRST_NAME),
		},
		{
			id: 2,
			label: 'Last Name',
			placeholder: 'Enter Last Name',
			Component: Inputbox,
			value: empIndividualDetails?.lastName,
			param: 'lastName',
			required: true,
			onChange: (e) => handleEmpDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.LAST_NAME),
		},
		{
			id: 3,
			label: 'Date of Birth',
			placeholder: 'DD/MM/YYYY',
			Component: DatePickers,
			value: empIndividualDetails?.dob,
			param: 'dob',
			required: true,
			onChange: (e, date) => handleEmpDetails(date, EMPLOYEE_DETAILS_KEY?.DOB),
			isErrorMessage:
				empIndividualDetails?.dob &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.DOB)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.DOB)?.errorMessage,
		},
		{
			id: 4,
			label: 'Nationality',
			placeholder: 'Enter Nationality',
			Component: MultiSelect,
			value: empIndividualDetails?.nationality,
			param: 'nationality',
			required: true,
			onChange: (e) => handleEmpDetails(e?.countryName, EMPLOYEE_DETAILS_KEY?.NATIONALITY),
			Option: nationalitydata,
			dropDownValue: 'countryName',
		},
		{
			id: 5,
			label: 'Mobile Number',
			placeholder: '5XXXXXXXX',
			Component: Inputbox,
			maxLength: 14,
			value: empIndividualDetails?.mobile,
			param: 'mobile',
			required: false,
			onChange: (e) => handleEmpDetails(onlyNumberForTextType(e?.target?.value), EMPLOYEE_DETAILS_KEY?.MOBILE),
			prefix: '+971',
			leftImage: <img src={not_focus_icons.countryImage} style={{ marginRight: '8px' }} alt="country" />,
			onBlur: (e) => validateMobileNumber(e),
			isErrorMessage: isMobileNumberError(),
			errorMessage: mobileNumberErrorMessage(),
		},
		{
			id: 6,
			label: 'Email Address',
			placeholder: 'Enter Company E-mail',
			Component: Inputbox,
			value: empIndividualDetails?.email,
			param: 'email',
			required: false,
			onChange: (e) => handleEmpDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.EMAIL),
			isErrorMessage: empIndividualDetails?.email && !isEmailValid(empIndividualDetails?.email),
			errorMessage: 'Enter Valid Email ID',
		},
	];
	const cardAccountDetails = [
		{
			id: 1,
			isSelected: empAccountSelected?.c3pay,
			param: 'c3pay',
			text: 'Yes, order a C3Pay Card',
		},
		{
			id: 1,
			isSelected: empAccountSelected?.bank,
			param: 'bank',
			text: 'No, employee has a bank account',
		},
	];
	useEffect(() => {
		dispatch(action.creators.getNationalityDataStart());
	}, []);

	useEffect(() => {
		if (isEmpPersonalDetailSaved && validateMessage?.length === 0) {
			localStorage.setItem(ADD_INDIVIDUAL_EMP?.EMPLOYEE_INFO, JSON.stringify(empIndividualDetails));
			history.push({
				pathname: isSubscribed ? '/employee/add-individual-contract/' : '/employee/add-individual-document/',
			});
			dispatch(action.creators.isPersonalDetailSaved(false));
		}
	}, [isEmpPersonalDetailSaved]);

	const handleEmpDetails = (value, param) => {
		setEmpIndividualDetails((prevState) => {
			return { ...prevState, [param]: value };
		});
		if (param === EMPLOYEE_DETAILS_KEY?.DOB) {
			clearValidationField(EMPLOYEE_ERROR_KEY?.DOB);
		}
		if (param === EMPLOYEE_DETAILS_KEY?.MOBILE) {
			clearValidationField(EMPLOYEE_ERROR_KEY?.MOBILE_NUMBER);
		}
	};
	const clearValidationField = (columnName) => {
		const error = validateMessage?.map((data) => {
			return {
				...data,
				errorMessage: data?.columnName === columnName ? '' : data?.errorMessage,
			};
		});
		setValidationMessage(error);
	};
	const handleAccountSelect = (param) => {
		setEmpIndividualDetails({ ...empIndividualDetails, account: param });
		if (param === ADD_INDIVIDUAL_EMP?.C3PAY) {
			setEmpAccountSelected({ ...empAccountSelected, c3pay: true, bank: false });
		} else {
			setEmpAccountSelected({ ...empAccountSelected, c3pay: false, bank: true });
		}
	};

	const handleGenderSelect = (gender) => {
		setEmpIndividualDetails({ ...empIndividualDetails, gender: gender[0] });
	};

	const disableNextButton = useMemo(
		() =>
			!!addIndividualEmpDetails?.filter((data) => data?.required && !data?.value)?.length ||
			!cardAccountDetails?.filter((data) => data?.isSelected)?.length,
		[empIndividualDetails, empAccountSelected]
	);
	const errorCheck = useMemo(() => !!addIndividualEmpDetails?.filter((data) => data?.isErrorMessage)?.length, [empIndividualDetails]);
	const handleNextButton = () => {
		const result = {
			...empIndividualDetails,
			mobile:
				typeof empIndividualDetails.mobile === 'string' && empIndividualDetails.mobile.length > 2
					? `00971${empIndividualDetails.mobile}`
					: '',
		};
		dispatch(action.creators.saveIndividualEmployeeStart(result));
	};

	return (
		<AddIndividualPresentational
			{...{
				addIndividualEmpDetails,
				nationalitydata,
				disableNextButton,
				handleNextButton,
				cardAccountDetails,
				handleAccountSelect,
				errorCheck,
				isSaveIndividualLoading,
				handleGenderSelect,
			}}
		/>
	);
};

export default AddIndividualFunctional;
