const GET_ADDITION = 'GET_ADDITION';
const GET_ADDITION_DATA = 'GET_ADDITION_DATA';
const PUT_ADDITION_LOADING = 'PUT_ADDITION_LOADING';
const POST_ADDITION = 'POST_ADDITION';
const PUT_ADDITION_ERROR = 'PUT_ADDITION_ERROR';
const putAdditionLoading = (loadingStatus) => ({
	type: PUT_ADDITION_LOADING,
	payload: loadingStatus,
});

const getAddition = () => ({
	type: GET_ADDITION,
});

const getAdditionData = (response) => ({
	type: GET_ADDITION_DATA,
	response,
});
const postAddition = (data, userName) => ({
	type: POST_ADDITION,
	payload: data,
	userName: userName,
});

const putAdditionError = (error) => ({
	type: PUT_ADDITION_ERROR,
	payload: error,
});

export default {
	types: {
		PUT_ADDITION_LOADING: PUT_ADDITION_LOADING,
		GET_ADDITION: GET_ADDITION,
		GET_ADDITION_DATA: GET_ADDITION_DATA,
		POST_ADDITION: POST_ADDITION,
		PUT_ADDITION_ERROR: PUT_ADDITION_ERROR,
	},
	creators: {
		putAdditionLoading,
		getAddition,
		getAdditionData,
		postAddition,
		putAdditionError,
	},
};
