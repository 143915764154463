import React from 'react';
import EdenredTypo from '@sharedComponent/typography';
import MultiSelect from './MultiSelect';

const index = () => {
	const options = [
		{
			name: 'Thamo',
			sub: '2343',
		},
		{
			name: 'Satish',
			sub: '423434',
		},
		{
			name: 'vishnu',
		},
		{
			name: 'subash',
		},
		{
			name: 'Yuva',
		},
		{
			name: 'sujai',
		},
		{
			name: 'Aravind',
		},
	];
	return (
		<>
			<div style={{ width: '50%', paddingTop: '10px' }}>
				<div style={{ marginBottom: '10px' }}>
					<EdenredTypo h1 bold>
						Select DropDown With Waterfall Search
					</EdenredTypo>
				</div>
				<MultiSelect onChange={(data) => console.log('datass', data)} Option={options} style={{ marginTop: '10px' }} />
			</div>
			<div style={{ width: '50%', paddingTop: '10px' }}>
				<div style={{ marginBottom: '10px' }}>
					<EdenredTypo h1 bold>
						Select DropDown
					</EdenredTypo>
				</div>
				<MultiSelect onChange={(data) => console.log('datass', data)} Option={options} searchVisibility={false} />
			</div>
		</>
	);
};

export default index;
