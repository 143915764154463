import Actions from './action';

export const initState = {
	saveProfileImgLoading: false,
	deleteProfileImgLoading: false,
	isProfileImgSaved: false,
	deleteProfileImgMsg: null,
};

const profileReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SAVE_PROFILE_IMAGE_START: {
			return {
				...state,
				saveProfileImgLoading: true,
			};
		}
		case Actions.types.SAVE_PROFILE_IMAGE_SUCCESS: {
			return {
				...state,
				saveProfileImgLoading: false,
			};
		}
		case Actions.types.SAVE_PROFILE_IMAGE_FAIL: {
			return {
				...state,
				saveProfileImgLoading: false,
			};
		}
		case Actions.types.DELETE_PROFILE_IMAGE_START: {
			return {
				...state,
				deleteProfileImgLoading: true,
				deleteProfileImgMsg: null,
			};
		}
		case Actions.types.DELETE_PROFILE_IMAGE_SUCCESS: {
			return {
				...state,
				deleteProfileImgMsg: action.response,
				deleteProfileImgLoading: false,
			};
		}
		case Actions.types.DELETE_PROFILE_IMAGE_FAIL: {
			return {
				...state,
				deleteProfileImgMsg: 'Issue while deleting',
				deleteProfileImgLoading: false,
			};
		}
		case Actions.types.SHOW_SUCCESS_SAVE_MSG: {
			return {
				...state,
				isProfileImgSaved: action.flag,
			};
		}
		default:
			return state;
	}
};

export default profileReducer;
