import React from 'react';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { icons } from 'src/assets/icons';
import './search-input.scss';

const EdenredSearch = ({ style = {}, value = '', placeholder = 'search here', defaultValue = '', disabled, onInput, onChange }) => {
	return (
		<Inputbox
			value={value}
			className="edenRedSearch"
			onChange={onChange}
			disabled={disabled}
			size="large"
			onInput={onInput}
			placeholder={placeholder}
			prefix={<img src={icons.focus_state.searchEmployeeCard} alt={'search'} />}
			style={style}
			defaultValue={defaultValue}
		/>
	);
};

export default EdenredSearch;
