const INVOICE_RECEIPT_DOWNLOAD_START = 'INVOICE_RECEIPT_DOWNLOAD_START';
const INVOICE_RECEIPT_DOWNLOAD_SUCCESS = 'INVOICE_RECEIPT_DOWNLOAD_SUCCESS';
const INVOICE_RECEIPT_DOWNLOAD_FAIL = 'INVOICE_RECEIPT_DOWNLOAD_FAIL';

const DOWNLOAD_COMPANY_STATEMENT_START = 'DOWNLOAD_COMPANY_STATEMENT_START';
const DOWNLOAD_COMPANY_STATEMENT_SUCCESS = 'DOWNLOAD_COMPANY_STATEMENT_SUCCESS';
const DOWNLOAD_COMPANY_STATEMENT_FAIL = 'DOWNLOAD_COMPANY_STATEMENT_FAIL';

const INVOICE_HISTORY_START = 'INVOICE_HISTORY_START';
const INVOICE_HISTORY_SUCCESS = 'INVOICE_HISTORY_SUCCESS';
const INVOICE_HISTORY_FAIL = 'INVOICE_HISTORY_FAIL';

const GET_GROUP_INVOICE_START = 'GET_GROUP_INVOICE_START';
const GET_GROUP_INVOICE_SUCCESS = 'GET_GROUP_INVOICE_SUCCESS';
const GET_GROUP_INVOICE_FAIL = 'GET_GROUP_INVOICE_FAIL';

const SAVE_GROUP_INVOICE_START = 'SAVE_GROUP_INVOICE_START';
const SAVE_GROUP_INVOICE_SUCCESS = 'SAVE_GROUP_INVOICE_SUCCESS';
const SAVE_GROUP_INVOICE_FAIL = 'SAVE_GROUP_INVOICE_FAIL';

const INVOICE_FORM_VALUE_HANDLER = 'INVOICE_FORM_VALUE_HANDLER';

const invoiceReceiptDownloadStart = (receiptId) => {
	return {
		type: INVOICE_RECEIPT_DOWNLOAD_START,
		receiptId,
	};
};

const invoiceReceiptDownloadSuccess = (data) => {
	return {
		type: INVOICE_RECEIPT_DOWNLOAD_SUCCESS,
		data,
	};
};
const invoiceReceiptDownloadFail = (error) => {
	return {
		type: INVOICE_RECEIPT_DOWNLOAD_FAIL,
		error,
	};
};

const getGroupInvoiceStart = () => {
	return {
		type: GET_GROUP_INVOICE_START,
	};
};

const getGroupInvoiceSuccess = (data) => {
	return {
		type: GET_GROUP_INVOICE_SUCCESS,
		data,
	};
};

const getGroupInvoiceFail = (error) => {
	return {
		type: GET_GROUP_INVOICE_FAIL,
		error,
	};
};

const saveGroupInvoiceStart = (selectedCompany, receiptId) => {
	return {
		type: SAVE_GROUP_INVOICE_START,
		selectedCompany,
		receiptId,
	};
};

const saveGroupInvoiceSuccess = (message) => {
	return {
		type: SAVE_GROUP_INVOICE_SUCCESS,
		message,
	};
};
const saveGroupInvoiceFail = (error) => {
	return {
		type: SAVE_GROUP_INVOICE_FAIL,
		error,
	};
};

const invoiceHistoryStart = () => {
	return {
		type: INVOICE_HISTORY_START,
	};
};

const invoiceHistorySuccess = (data) => {
	return {
		type: INVOICE_HISTORY_SUCCESS,
		data,
	};
};
const invoiceHistoryFail = (error) => {
	return {
		type: INVOICE_HISTORY_FAIL,
		error,
	};
};

const downloadCompanyStatementStart = (overtimePolicies, overtimeData) => {
	return {
		type: DOWNLOAD_COMPANY_STATEMENT_START,
		overtimePolicies,
		overtimeData,
	};
};

const downloadCompanyStatementSuccess = (response) => {
	return {
		type: DOWNLOAD_COMPANY_STATEMENT_SUCCESS,
		response,
	};
};
const downloadCompanyStatementFail = (error) => {
	return {
		type: DOWNLOAD_COMPANY_STATEMENT_FAIL,
		error,
	};
};

const invoiceFormValueHandler = (payloadState) => {
	return {
		type: INVOICE_FORM_VALUE_HANDLER,
		payloadState,
	};
};

export default {
	types: {
		DOWNLOAD_COMPANY_STATEMENT_START,
		DOWNLOAD_COMPANY_STATEMENT_SUCCESS,
		DOWNLOAD_COMPANY_STATEMENT_FAIL,

		INVOICE_HISTORY_START,
		INVOICE_HISTORY_SUCCESS,
		INVOICE_HISTORY_FAIL,

		SAVE_GROUP_INVOICE_START,
		SAVE_GROUP_INVOICE_SUCCESS,
		SAVE_GROUP_INVOICE_FAIL,

		GET_GROUP_INVOICE_START,
		GET_GROUP_INVOICE_SUCCESS,
		GET_GROUP_INVOICE_FAIL,

		INVOICE_RECEIPT_DOWNLOAD_START,
		INVOICE_RECEIPT_DOWNLOAD_SUCCESS,
		INVOICE_RECEIPT_DOWNLOAD_FAIL,

		INVOICE_FORM_VALUE_HANDLER,
	},
	creators: {
		downloadCompanyStatementStart,
		downloadCompanyStatementSuccess,
		downloadCompanyStatementFail,

		invoiceHistoryStart,
		invoiceHistorySuccess,
		invoiceHistoryFail,

		getGroupInvoiceStart,
		getGroupInvoiceSuccess,
		getGroupInvoiceFail,

		saveGroupInvoiceStart,
		saveGroupInvoiceSuccess,
		saveGroupInvoiceFail,

		invoiceReceiptDownloadStart,
		invoiceReceiptDownloadSuccess,
		invoiceReceiptDownloadFail,

		invoiceFormValueHandler,
	},
};
