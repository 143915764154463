import Action from '@pages/NewHrModuleSetup/UploadPayrollDetails/action';
import Actions from 'src/pages/payroll-plus-new/action';
import additionAction from '@pages/NewHrModuleSetup/addition/action';
import deductionAction from '@pages/NewHrModuleSetup/deduction/action';
import allowanceAction from '@pages/NewHrModuleSetup/allowance/action';
import ClevertapReact from 'src/utils/clever-tap';
import { Card, Col, Row } from 'antd';
import { DotLoader } from 'react-spinners';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EDragger from '@pages/DesignSystem/Dragger';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ErrorTable from '@sharedComponent/error-table';
import EmployeeService from 'src/services/employee-service';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import FreshdeskWidgetHelper from '@sharedComponent/freshdesk/FreshdeskWidgetHelper';
import { HR_ALLOWED_FILETYPES } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import moment from 'moment';
import PayrollService from 'src/services/payroll-service';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Step from '@pages/NewHrModuleSetup/Component/Step/Step';
import SalaryMonthModal from '@pages/NewHrModuleSetup/UploadPayrollDetails/SalaryMonthModal/SalaryMonthModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WpsNonWpsPopupFunctional from './wps-nonwps-popup-functional';
import './upload-spreadsheet-plus.scss';
const UploadSpreadsheetPlus = () => {
	const { allowanceType, additionType, deductionType } = useSelector((state) => state?.counter);
	const { allowance, addition, deduction } = useSelector((state) => state?.hrSetup);
	const ref = useRef(null);
	const { downloadTemplate } = useSelector((state) => state?.hrSetup?.employeePayrollUpload);
	const [showSalaryMonthModal, setShowSalaryMonthModal] = useState(false);
	const [uploadPayrollLoader, setUploadPayrollLoader] = useState(false);
	const [totalCount, setTotalCount] = useState();
	const [StagingLoader, setStagingLoader] = useState(false);
	const [uploadData, setUploadData] = useState();
	const [nextFileUploadLoader, setNextFileUploadLoader] = useState(false);
	const [hasErrorFile, setHasErrorFile] = useState(false);
	const [uploadLoader, setUploadLoader] = useState(false);
	const [empList, setEmpList] = useState([]);
	const [errorData, seterrorData] = useState([]);
	const [employeeTableData, SetemployeeTableData] = useState([]);
	const history = useHistory();
	const [counter, setcounter] = useState(0);
	const [errordataList, setErrorDataList] = useState([]);
	const [salaryBolbFileName, setSalaryBolbFileName] = useState(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [isPopupVisible, setIsPopupVisible] = useState({
		isWpsNonWps: false,
		data: [],
		endofService: [],
		payrollPlus: {},
		runPayroll: {},
		message: '',
		wpsNonWps: '',
		monthYearSelect: {},
		selectDate: '',
		status: 'wpsNonWps',
		type: '',
	});
	const dispatch = useDispatch();
	const _payrollService = new PayrollService();
	let employeeApi = new EmployeeService();
	const uploadFile = (file) => {
		let formData = new FormData();
		formData.append('file', file);
		let apform = formData.append('file', file);
		setUploadLoader(true);
		_payrollService
			.employeePayrollUpload(formData)
			.then((res) => {
				setUploadLoader(false);
				if (res?.data?.validationMessage) {
					ClevertapReact.event('V1_1_Payroll_Spreadsheet_Upload', { ...res?.data?.validationMessage });
					setcounter(counter + 1);
					setSalaryBolbFileName(res?.data?.salaryBlobFileName);
					seterrorData([...res?.data?.validationMessage]);
				} else {
					setcounter(0);
					seterrorData([]);
					setIsButtonDisabled(true);
					setTotalCount(res?.data?.totalCount);
					setUploadData(res?.data);
					ClevertapReact.event('V1_1_Payroll_Spreadsheet_Upload', { ...res?.data });
					if (res?.data?.month === true) {
						setShowSalaryMonthModal(true);
					} else {
						if (res?.data?.totalCount <= 100) {
							setEmpList(res?.data?.employeeHRSalaryStaging);
							const date = new Date();
							const month = moment(date).format('MM');
							const year = moment(date).format('YYYY');
							setIsPopupVisible({
								...isPopupVisible,
								type: 'payrollPlus',
								isWpsNonWps: false,
								message: 'next',
								status: 'wpsNonWps',
								monthYearSelect: { month: month, year: year },
								payrollPlus: { month: month, year: year },
								endofService: [],
							});
						} else if (res?.data?.totalCount > 100) {
							setStagingLoader(true);
							// dispatch(Actions.creators.updateStaging(res?.data?.employeeHRSalaryStaging, 'next'));
							const date = new Date();
							const month = moment(date).format('MM');
							const year = moment(date).format('YYYY');
							setIsPopupVisible({
								...isPopupVisible,
								type: 'payrollPlus',
								isWpsNonWps: true,
								message: 'next',
								status: 'wpsNonWps',
								monthYearSelect: { month: month, year: year },
								payrollPlus: { month: month, year: year },
								endofService: [],
							});
							setStagingLoader(false);
							ref.current.value = null;
						}
					}
				}
			})
			.catch((error) => {
				setEmpList([]);
				seterrorData([]);
				setUploadLoader(false);
				FreshdeskWidgetHelper(false);
			});
	};

	useEffect(() => {
		dispatch(allowanceAction.creators.getAllowance());
		dispatch(additionAction.creators.getAddition());
		dispatch(deductionAction.creators.getDeduction());
	}, []);
	useEffect(() => {
		const FreshdeskWidgetElement = document.getElementById('freshworks-container');
		if (FreshdeskWidgetElement && counter >= 2) {
			var element = document.getElementById('freshworks-container');
			element.classList.add('freshdeskShow');
			element.classList.remove('freshdeskHide');
		}
		return () => {
			const FreshdeskWidgetElement = document.getElementById('freshworks-container');
			if (FreshdeskWidgetElement) {
				var element = document.getElementById('freshworks-container');
				element.classList.add('freshdeskHide');
				element.classList.remove('freshdeskShow');
			}
		};
	}, [counter]);

	if (counter >= 2) {
		let widgetDetails = { wigetid: '67000002930' };
		FreshdeskWidgetHelper(widgetDetails);
	}
	async function fetchEmployees(
		queryParams = {
			status: 'ACTIVE',
			includeEmployeeCounts: true,
		}
	) {
		try {
			let response = await employeeApi.getEmployees(queryParams);
			if (response && response.data) {
				SetemployeeTableData([...response.data.employees]);
			}
		} catch (err) {
			SetemployeeTableData([]);
		}
	}
	useEffect(() => {
		fetchEmployees();
	}, []);
	const handleDragger = (file) => {
		if (HR_ALLOWED_FILETYPES?.indexOf(file?.name && file?.name?.split('.')?.splice(-1)[0]) >= 0) {
			uploadFile(file);
			setHasErrorFile(false);
		} else {
			setHasErrorFile(true);
		}
	};
	const onRemoveUpload = () => {
		setHasErrorFile(false);
		seterrorData([]);
		setIsButtonDisabled(false);
		setErrorDataList([]);
		setSalaryBolbFileName(null);
		setEmpList([]);
	};
	const hasError = errorData.length;
	const loadErrorData = (errorDataArray) => {
		if (!errordataList.length) {
			setErrorDataList(errorDataArray);
		} else {
			let newArrayData = [...errordataList, ...errorDataArray];
			setErrorDataList(newArrayData);
		}
	};
	const nextOnClick = async () => {
		ClevertapReact.event('V1_1_Payroll_Spreadsheet_Next');
		// ClevertapReact.event('Next_Uploadexcel', null);
		let errors = [];
		let emplists = [];
		let errorDataPayLoad = {
			salaryBlobFileName: salaryBolbFileName,
			salaryFileUploadAutoFixList: errordataList,
		};
		setNextFileUploadLoader(true);
		if (errordataList?.length !== 0) {
			try {
				let res = await _payrollService.validateUploadSpreadSheet(errorDataPayLoad);
				if (res.data.validationMessage) {
					setcounter(counter + 1);
					setSalaryBolbFileName(res.data.salaryBlobFileName);
					seterrorData([...res.data.validationMessage]);
					errors = [...res.data.validationMessage];
				} else {
					setcounter(0);
					seterrorData([]);
					if (res.data.employeeSalaries.length <= 100) {
						setNextFileUploadLoader(false);
						emplists =
							res.data &&
							res.data.employeeSalaries &&
							res.data.employeeSalaries.map((data) => {
								return {
									...data,
									hasError: false,
								};
							});

						if (errors.length == 0 && emplists.length > 0) {
							history.push({ pathname: '/run-payroll', state: { empList: emplists, fromUploadSheetPage: true } });
						} else if (emplists.length === 0) {
							setNextFileUploadLoader(false);
							setIsButtonDisabled(false);
							setpopupVisible(true);
							setPopupMessage({
								titleMessage: 'Excel is blank',
								subMessage: null,
							});
						}
					} else {
						setStagingLoader(true);
						setNextFileUploadLoader(false);
						_payrollService
							.EmployeeSalaryStaging({ employeeDetails: res.data.employeeSalaries })
							.then((response) => {
								let tempEmployeeSalaries = [];
								res.data &&
									res.data.employeeSalaries.map((emp) => {
										tempEmployeeSalaries.push({
											...emp,
											totol_sal: emp.salary,
											variable_pay: emp.variablePay,
										});
									});
								setStagingLoader(false);
							})
							.catch((error) => {
								setStagingLoader(false);
							});
					}
				}
			} catch (error) {
				setNextFileUploadLoader(false);
			}
		} else {
			setNextFileUploadLoader(false);
			if (empList.length !== 0) {
				history.push({
					pathname: '/payroll-plus/preview-uploaded-document-data',
					state: {
						empList: empList,
					},
				});
			}
		}
	};

	const enableNextButton = (value) => value && setIsButtonDisabled(value);

	const closePopup = () => setpopupVisible(false);
	const draggerIcon = () => {
		if (uploadLoader) {
			return focus_state_icons?.loaderIcon;
		} else if (errorData?.length > 0) {
			return focus_state_icons.NewDesignErro;
		} else if (empList?.length > 0) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (uploadLoader) {
			return 'uploading';
		} else if (errorData?.length > 0) {
			return 'error';
		} else if (empList?.length > 0) {
			return 'done';
		} else {
			return 'new';
		}
	};
	const handleEdit = () => {
		ClevertapReact.event(
			'V1_1_Payroll_Spreadsheet_edit',
			allowanceType == '' && deductionType == '' && additionType == '' ? null : { ...allowanceType, ...deductionType, ...additionType }
		);
		history.push({ pathname: '/hr-features/allowance', state: { editUploadSpreadsheet: true } });
	};

	const handleCloseModal = () => {
		ClevertapReact.event('V1_1_Payroll_Spreadsheet_SavePopCancel');
		setShowSalaryMonthModal(false);
	};
	const salaryStage = (res, salaryMonthData) => {
		if (totalCount <= 100) {
			setUploadPayrollLoader(false);
			history.push({
				pathname: '/payroll-plus/preview-uploaded-document-data',
				state: {
					salaryMonthData: salaryMonthData,
					empList: res?.data,
				},
			});
		} else if (totalCount > 100) {
			setUploadPayrollLoader(false);
			setStagingLoader(true);
			// dispatch(Actions.creators.updateStaging(res?.data?.employeeHRSalaryStaging, 'next'));
			const date = new Date(salaryMonthData?.salaryDate);
			const month = moment(date).format('MM');
			const year = moment(date).format('YYYY');
			setIsPopupVisible({
				...isPopupVisible,
				type: 'payrollPlus',
				isWpsNonWps: true,
				message: 'next',
				status: 'wpsNonWps',
				monthYearSelect: { month: month, year: year },
				payrollPlus: { month: month, year: year },
				endofService: [],
			});
			setStagingLoader(false);
			ref.current.value = null;
		}
	};
	const handleProceed = async (salaryMonthData) => {
		ClevertapReact.event('V1_1_Payroll_Spreadsheet_SavePopProceed');
		setUploadPayrollLoader(true);
		try {
			const date = new Date(salaryMonthData?.salaryDate);
			const month = moment(date).format('MM');
			const year = moment(date).format('YYYY');
			const blobFileName = encodeURIComponent(uploadData?.blobFileName);
			const res = await _payrollService.initialStaging(blobFileName, month, year);
			salaryStage(res, salaryMonthData);
			setShowSalaryMonthModal(false);
		} catch (error) {
			setUploadPayrollLoader(false);
			console.log(error);
		}
	};
	return (
		<>
			<SalaryMonthModal
				showModal={showSalaryMonthModal}
				handleCloseModal={() => handleCloseModal()}
				handleProceed={(date) => handleProceed(date)}
				loading={uploadPayrollLoader}
			/>
			<Col className="upload-spreadsheet-col-container">
				<Edenredmodal isOpen={popupVisible} title={popupMessage?.titleMessage} onOkay={closePopup} onOkayButton="OK" />
				<EdenredHeader />
				<Row className="UploadSpreadsheet">
					<div className="UploadSpreadsheet_header">
						<p className="title">Upload Spreadsheet</p>
						<EdenredButtons
							className="width-fit-content"
							disabled={empList?.length > 0 ? false : true}
							onClick={nextOnClick}
							loading={nextFileUploadLoader || StagingLoader}
							type="primary">
							Next
						</EdenredButtons>
					</div>
					{addition.loading || deduction.loading || allowance.loading ? (
						<Col style={{ minHeight: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
							<DotLoader size={50} color={'#e91d24'} loading />
						</Col>
					) : (
						<Col className="upload_spreadsheet_card">
							<div className={allowanceType == '' && deductionType == '' && additionType == '' ? 'container-wrapper' : ''}>
								<div className="upload-wrapper">
									<div className="upload-step-wrapper">
										<Step
											stepNo={1}
											iconSrc={
												allowanceType == '' && deductionType == '' && additionType == ''
													? focus_state_icons?.editNew
													: focus_state_icons?.ClipboardText
											}
											descriptionText={
												allowanceType == '' && deductionType == '' && additionType == '' ? (
													"You haven't added any allowance, addition or deduction yet! Click on Edit to start configuring now."
												) : (
													<div className="edit-data">
														<p className="edit-data-title">Edit or add Allowances, Additions & Deductions.</p>
														<ul>
															<li className="allowanceData">
																Allowances:
																{allowanceType?.map((allowanceData) => (
																	<> {allowanceData?.name},</>
																))}
															</li>
															<li className="allowanceData">
																Additions:
																{additionType?.map((additionData) => (
																	<> {additionData?.name},</>
																))}
															</li>

															<li className="allowanceData">
																Deductions:
																{deductionType?.map((deductionData) => (
																	<> {deductionData?.name},</>
																))}
															</li>
														</ul>
													</div>
												)
											}
											btnType="secondary"
											btnText="Edit"
											btnClassName="download-btn"
											className={
												allowanceType == '' && deductionType == '' && additionType == ''
													? 'upload-description-background-color'
													: 'description-background-color'
											}
											btnLeftIcon={<ImageComponent src={focus_state_icons?.edit} />}
											btnOnClick={() => handleEdit()}
										/>
										<Step
											stepNo={2}
											iconSrc={focus_state_icons?.documentDownload}
											descriptionText="Download the excel template and fill in your employees’ payroll details."
											btnType="secondary"
											btnText="Download template"
											btnClassName="download-btn"
											className="description-background-color"
											btnOnClick={() => {
												ClevertapReact.event('V1_1_Payroll_Spreadsheet_Download');
												dispatch(Action.creators.getPayrollDownloadTemplate());
											}}
											isBtnLoading={downloadTemplate?.loading}
										/>
									</div>
									{hasErrorFile && (
										<Col xl={24} md={24} sm={24} lg={21} xs={24} className="card_error" style={{ padding: '17px 0px 0px 0px' }}>
											<Card className="card_action">
												<Row>
													<Col xl={1} sm={3} xs={3} md={2}>
														<ImageComponent src={focus_state_icons.hrDocumentExpired} />
													</Col>
													<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
														<ETypo light b1>
															Invalid format. Please upload one of these formats {HR_ALLOWED_FILETYPES.join()}
														</ETypo>
													</Col>
													<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
														<ETypo light b1>
															Please correct the following errors and upload the file again.
														</ETypo>
													</Col>
												</Row>
											</Card>
										</Col>
									)}

									<Col xl={24} md={24} lg={24} className="dragger_card_container">
										<ETypo medium b2>
											Step 3:
										</ETypo>
										<EDragger
											{...{
												idleIcon: draggerIcon(),
												draggerStatus: draggerStatus(),
												uploadDeleteIcon: focus_state_icons.NewDelete,
												onChange: handleDragger,
												onRemoveUpload: onRemoveUpload,
												acceptedTypes: '.xlsx,.xls,.sif',
												refValue: ref,
											}}
										/>
									</Col>
								</div>
								<div className="upload-intro-video-wrapper">
									{allowanceType == '' && deductionType == '' && additionType == '' && (
										<video className="payroll-video-container" autoPlay="true" controls>
											<source
												src="https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo1(aad).mp4"
												type="video/mp4"
											/>
										</video>
									)}
								</div>
							</div>
							{hasError ? (
								<Col xl={24} md={24} sm={24} lg={21} xs={24} className="card_error">
									<Card className="card_action">
										<Row>
											<Col xl={1} sm={3} xs={3} md={2}>
												<ImageComponent src={focus_state_icons.hrDocumentExpired} />
											</Col>
											<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
												<ETypo light b1>
													Please correct the following errors and upload the file again.
												</ETypo>
											</Col>
											<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
												<ETypo light b1>
													Please correct the following errors and upload the file again.
												</ETypo>
											</Col>
										</Row>
									</Card>
								</Col>
							) : null}
							{hasError ? (
								<Fragment>
									<Col xl={24} md={24} sm={24} lg={21} xs={24}>
										<ErrorTable data={errorData} loadErrorData={loadErrorData} enableNextButton={enableNextButton} />
									</Col>
								</Fragment>
							) : null}
						</Col>
					)}
				</Row>
			</Col>
			<WpsNonWpsPopupFunctional {...{ isPopupVisible, setIsPopupVisible }} />
		</>
	);
};
export default UploadSpreadsheetPlus;
