import { Col, Input, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import Actions from '../action';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import RunPayrollNewPresentational from './run-payroll-new-presentational';
import Selectors from '../selectors';
import Switch from '@sharedComponent/switch';
import { connect } from 'react-redux';
import { icons } from 'src/assets/icons';

const RunPayrollNewFunctional = ({
	getFilterDropdownData,
	dropdownValues,
	getEmployeeTabledata,
	employeesData,
	getPreviousPayrollData,
	previousPayrollData,
	payrollTableLoader,
	prevPayrollLoader,
	totalCount,
}) => {
	const [empData, setEmpData] = useState({ tableData: [], selectedData: [] });
	const [selectAll, setSelectAll] = useState(false);
	const [dropDownValueState, setDropDownValueState] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [isRepeatMode, setIsRepeatMode] = useState(false);
	const [requestParams, setRequestParams] = useState({
		accountType: '',
		bankName: '',
		establishment: '',
		includeEmployeeCounts: false,
		pageNumber: 1,
		pageSize: 30,
		payrollBlockCheck: true,
		payrollChannel: '',
		previousPayrollUploadId: '',
		previoustotalPaidEmps: null,
		search: '',
		status: 'ACTIVE',
	});

	useEffect(() => {
		getFilterDropdownData();
		getPreviousPayrollData();
	}, []);

	useEffect(() => {
		getPreviousPayrollData(pageNumber);
	}, [pageNumber]);

	useEffect(() => {
		getEmployeeTabledata(requestParams);
	}, [requestParams]);

	useEffect(() => {
		setEmpData({ ...empData, tableData: employeesData });
	}, [employeesData]);

	useEffect(() => {
		dropdownValidator(dropDownValueState);
	}, [dropDownValueState]);

	const onSearch = (employeeIdNameFilter) => {
		setRequestParams({ ...requestParams, search: employeeIdNameFilter });
	};

	const dropdownValidator = (value) => {
		let temp = (value || [])?.map((response) => {
			let temp1 = response.options
				.filter((res) => res.isSelected)
				.map((res) => {
					return {
						key: response.key,
						selectedValues: res.payrollChannelName || res.accountTypeName || res.establishmentId || res.bankName,
					};
				});
			return {
				[response.key]: temp1.map((res) => res.selectedValues).join(),
			};
		});
		const finalValue = Object.assign({}, ...temp);
		setRequestParams({ ...requestParams, ...finalValue });
	};
	const handletoggleswitch = (emp_id) => {
		let data = empData.tableData.map((val, ind) => {
			if (val.employeeId === emp_id) {
				val.endOfService = val.endOfService === true ? false : true;
			}
			return val;
		});
		setEmpData({ ...empData, tableData: data });
	};

	const empSalary = (empData) => {
		return empData?.selectedData.reduce(function (accumulator, totalSalary) {
			return parseFloat(accumulator) + parseFloat(totalSalary?.totalSalary || 0);
		}, 0);
	};

	const employeeSalary = useMemo(() => empSalary(empData), [empData]);
	const employeeTotalCount = useMemo(() => new Set(empData.selectedData), [empData.selectedData]);

	// const handleValue
	const handleChange = (e, emp_id) => {
		let inputFormatter = empData.tableData?.map((data) => {
			if (emp_id === data.employeeId) {
				data.totalSalary = e.target.value;
			}
			return data;
		});
		setEmpData({ ...empData, tableData: [...inputFormatter] });
	};

	const handleSelect = (emp_id, selectAll) => {
		let employeesSelected = empData.selectedData;
		let toggledData = empData.tableData.map((employee) => {
			if (emp_id === employee.employeeId) {
				employee.isSelected = !employee.isSelected;
				if (employee.isSelected) {
					employeesSelected.push(employee);
				} else {
					employeesSelected = employeesSelected.filter((data) => emp_id !== data.employeeId);
				}
			}
			if (!emp_id) {
				employee.isSelected = selectAll;
				if (employee.isSelected) {
					employeesSelected.push(employee);
				}
				if (!employee.isSelected) {
					employeesSelected = employeesSelected.filter((data) => !data.employeeId);
				}
			}
			return employee;
		});
		setEmpData({ ...empData, tableData: [...toggledData], selectedData: [...employeesSelected] });
	};

	useEffect(() => {
		handleSelect(null, selectAll);
	}, [selectAll]);

	const handleInputs = (type, event, emp_obj) => {
		const otherInputs = empData.tableData.map((data) => {
			if (emp_obj.employeeId === data.employeeId) {
				{
					type === 'variablePay' ? (data.variablePay = event.target.value) : (data.daysOnLeave = event.target.value);
				}
			}
			return data;
		});
		setEmpData({ ...empData, tableData: [...otherInputs] });
	};

	const columns = [
		{
			// sorter: (x, y) => (x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1),
			key: 'isSelected',
			showSorterTooltip: false,
			sortDirections: ['ascend'],
			align: 'center',
			// sortOrder: 'ascend',
			title: (
				<ImageComponent
					className="run-payroll-header-check"
					src={selectAll ? icons.focus_state.Error : icons.not_focus.EmptyWhite}
					style={
						selectAll
							? { width: '33px', height: '33px', cursor: 'pointer' }
							: { width: '23px', height: '23px', cursor: 'pointer', marginLeft: '5px' }
					}
					onClick={() => setSelectAll(!selectAll)}
				/>
			),
			dataIndex: 'isSelected',
			width: '13%',
			render: (val, row) => {
				return (
					<Col style={{ display: 'flex', justifyContent: 'center' }}>
						<ImageComponent
							style={{ width: '33px', height: '33px', cursor: 'pointer' }}
							src={
								row.previousSalary > 0 || empData.selectedData.findIndex((data) => data.employeeId === row.employeeId) > -1
									? icons.focus_state.Check
									: icons.not_focus.EmptyBox
							}
							onClick={() => handleSelect(row.employeeId)}
						/>
					</Col>
				);
			},
		},

		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Employee ID',
			dataIndex: 'employeeId',
			key: 'Employee ID',
		},
		{
			title: 'Total Salary',
			dataIndex: 'totalSalary',
			key: 'totalSalary',
			render: (totalSalary, val) => {
				return (
					<>
						<Input
							className="runpayroll prehighlight-bold"
							style={{
								borderColor:
									val.isSelected === true
										? totalSalary === 0 || totalSalary === '0' || totalSalary === ''
											? '#E60A14'
											: null
										: null,
							}}
							prefix={'AED'}
							type="number"
							value={val.previousSalary > 0 ? val.previousSalary : totalSalary}
							onBlur={(e) =>
								e.target.value !== '0' && e.target.value !== '' ? handleSelect(val.employeeId, true) : handleSelect(val.employeeId)
							}
							onChange={(e) => handleChange(e, val.employeeId)}
						/>
						<br />

						{val.isSelected ? (
							totalSalary === 0 || totalSalary === '0' || totalSalary === '' ? (
								<EdenredTypo
									style={{
										color: '#E60A14',
										fontFamily: 'Montserrat-Regular',
										fontStyle: 'normal',
										fontWeight: '300',
										fontSize: '10px',
										lineHeight: '12px',
										position: 'absolute',
										bottom: '0',
									}}>
									Enter Amount
								</EdenredTypo>
							) : null
						) : (
							false
						)}
					</>
				);
			},
		},
		{
			title: 'Variable Pay (Optional)',
			dataIndex: 'variablePay',
			key: 'variablePay',
			render: (variablePay, val) => {
				return (
					<>
						<Input
							className="runpayroll prehighlight"
							disabled={!val.totalSalary && val.isSelected}
							style={{ borderColor: parseFloat(variablePay) > parseFloat(val.totalSalary) ? '#E60A14' : null }}
							prefix={'AED'}
							value={variablePay}
							type="number"
							onChange={(e) => handleInputs('variablePay', e, val)}
						/>
						<br />
						{parseFloat(variablePay) > parseFloat(val.totalSalary) ? (
							<EdenredTypo
								style={{
									color: '#E60A14',
									fontFamily: 'Montserrat-Regular',
									fontStyle: 'normal',
									fontWeight: '300',
									fontSize: '10px',
									lineHeight: '12px',
									position: 'absolute',
									bottom: 0,
								}}>
								{'Must be > Total Salary'}
							</EdenredTypo>
						) : (
							''
						)}
					</>
				);
			},
		},
		{
			title: 'Days On Leave (Optional)',
			dataIndex: 'daysOnLeave',
			key: 'daysOnLeave',
			render: (dayonLeave, val) => {
				return (
					<>
						<Input
							className="runpayroll"
							value={dayonLeave}
							type="number"
							onChange={(e) => {
								handleInputs('dayonLeave', e, val);
							}}
						/>
						<br />
						{dayonLeave <= 30 ? (
							<EdenredTypo>{''}</EdenredTypo>
						) : (
							<EdenredTypo className="leave-error-text">{`days on leave must be < 30`}</EdenredTypo>
						)}
					</>
				);
			},
		},
		{
			title: 'End of Service',
			dataIndex: 'endOfService',
			key: 'endOfService',
			render: (endOfService, val) => {
				return (
					<Row align="middle">
						<Col xl={7} md={7} lg={7} style={{ paddingRight: 13 }} className={endOfService === true ? 'checkNo' : 'checkNo-false'}>
							No
						</Col>
						<Col xl={10} md={10} lg={10}>
							<Switch
								className="switch"
								defaultChecked={endOfService}
								checked={endOfService}
								disabled={val.isSelected === false}
								value={endOfService}
								onChange={() => handletoggleswitch(val.emp_id)}
							/>
						</Col>
						<Col
							xl={7}
							md={7}
							lg={7}
							style={{
								fontWeight: 'bold',
								color: endOfService === true ? 'red' : ' #DFE1E6',
							}}>
							Yes
						</Col>
					</Row>
				);
			},
		},
	];

	return (
		<RunPayrollNewPresentational
			{...{
				dropdownValues,
				employeesData,
				empData,
				columns,
				employeeSalary,
				setRequestParams,
				requestParams,
				employeeTotalCount,
				dropDownValueState,
				setDropDownValueState,
				previousPayrollData,
				pageNumber,
				setPageNumber,
				payrollTableLoader,
				prevPayrollLoader,
				isRepeatMode,
				setIsRepeatMode,
				totalCount,
				onSearch,
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		dropdownValues: Selectors.dropdownValues(state),
		employeesData: Selectors.employeesData(state),
		previousPayrollData: Selectors.previousPayrollData(state),
		payrollTableLoader: Selectors.payrollTableLoader(state),
		prevPayrollLoader: Selectors.prevPayrollLoader(state),
		totalCount: Selectors.totalCount(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getFilterDropdownData: () => dispatch(Actions.creators.getFilterDropdownData()),
		getEmployeeTabledata: (requestParams) => dispatch(Actions.creators.getEmployeeTabledata(requestParams)),
		getPreviousPayrollData: (pageNumber) => dispatch(Actions.creators.getPreviousPayrollData(pageNumber)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(RunPayrollNewFunctional);
export default Connected;
