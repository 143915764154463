const PUT_STATUS_TOAST = 'PUT_STATUS_TOAST';
const CLEAR_STATUS_TOAST = 'CLEAR_STATUS_TOAST';

const putSuccessStatusToast = ({ message }) => ({
	type: PUT_STATUS_TOAST,
	payload: {
		toastType: 'SUCCESS',
		message: message,
	},
});

const putWarningStatusToast = ({ message }) => ({
	type: PUT_STATUS_TOAST,
	payload: {
		toastType: 'WARNING',
		message: message,
	},
});

const putErrorStatusToast = ({ message }) => ({
	type: PUT_STATUS_TOAST,
	payload: {
		toastType: 'ERROR',
		message: message,
	},
});

const clearStatusToast = () => ({
	type: CLEAR_STATUS_TOAST,
});

export default {
	types: {
		PUT_STATUS_TOAST: PUT_STATUS_TOAST,
		CLEAR_STATUS_TOAST: CLEAR_STATUS_TOAST,
	},
	creators: {
		putSuccessStatusToast,
		putWarningStatusToast,
		putErrorStatusToast,
		clearStatusToast,
	},
};
