import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Tooltip } from 'antd';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { gsap, TweenMax } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { amountWithCommas, amountWithNoCommas, findObjFromArray, numberWithCommas, twoDigitAfterDec } from '@helpers';
import Action from '@sharedComponent/app-action-reducer/action';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ClevertapReact from 'src/utils/clever-tap';
import Actions from '../action';
import PayrollPlusNewPresentational from './payroll-plus-new-presentational';
import Selectors from '../selectors';
import { getEmpTotalSalary } from '../sagas/payroll-plus-new-saga';
import { default as CustomToolTip } from '@pages/DesignSystem/Tool-Tip';
import { isArray } from 'lodash';
import ImageComponent from '@sharedComponent/image-component';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { icons } from 'src/assets/icons';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';
import ETypo from '@pages/DesignSystem/Typo';
import './filterContents.scss';

const PayrollPlusNewFunctional = ({
	getFilterDropdownData,
	dropdownValues,
	getPayrollEmpData,
	getSalaryData,
	employeeTableData,
	empCount,
	payrollTableLoader,
	payrollSalaryStatus,
	getPayrollDynamicHeader,
	additionData,
	deductionData,
	allowanceData,
	dynamicHeaderSuccess,
	bulkUpdatePayroll,
	popupFlag,
	flagPopup,
	employeeSalaryStagings,
	popupTurnOff,
	salaryStaging,
	userNavigationMenu,
	wpsNonWpsPopupFlag,
	updatePayrollTableLoader
}) => {
	const dispatch = useDispatch();
	const [dropDownValueState, setDropDownValueState] = useState([]);
	const [payrollFilter, setPayrollFilter] = useState(false);
	const [empData, setEmpData] = useState({ tableData: [], selectedData: [], selectedByPage: [], editedData: [] });
	const [dropdownIcon, setDropdownIcon] = useState(false);
	const [toogleScroll, setToogleScroll] = useState(true);
	const [totalSalary, setTotalSalary] = useState([]);
	const [selectionListener, setSelectionListener] = useState(false);
	const [popup, setPopup] = useState(false);
	const [nextHide, setNextHide] = useState(false);
	const [date, setDate] = useState({});
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [popupValue, setPopupValue] = useState();
	const [onChangePopup, setOnChangePopup] = useState(false);
	const [prevSearch, setPrevSearch] = useState({});
	const [editedEmpDataByPage, setEditedEmpDataByPage] = useState([]);
	const [payrollRunData, setPayrollRunData] = useState({ tempEditedData: [], tempData: [] });
	const [requestParams, setRequestParams] = useState({
		accountType: '',
		bankName: '',
		establishment: '',
		includeEmployeeCounts: false,
		pageNumber: 1,
		pageSize: 10,
		payrollBlockCheck: true,
		payrollChannel: '',
		previousPayrollUploadId: '',
		previoustotalPaidEmps: null,
		search: '',
		status: 'ACTIVE',
		month: date?.salaryDate !== undefined && date?.salaryDate !== '' ? moment().format('MM') : '',
		year: date?.salaryDate !== undefined && date?.salaryDate !== '' ? moment().format('YYYY') : '',
	});
	const [isPopupVisible, setIsPopupVisible] = useState({
		isWpsNonWps: false,
		data: [],
		endofService: [],
		payrollPlus: {},
		runPayroll: {},
		message: '',
		wpsNonWps: '',
		monthYearSelect: {},
		selectDate: '',
		status: 'wpsNonWps',
		type: '',
	});
	const [cleverTapPropertiesData, setCleverTapPropertiesData] = useState([]);
	const [saveContinueCleverTapPropertiesData, setSaveContinueCleverTapPropertiesData] = useState([]);
	const [salaryExceedsModal, setSalaryExceedsModal] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(0);
	const [isSalaryExceeds, setIsSalaryExceeds] = useState(false);
	const updateStagedEmployees = useSelector((state) => state?.counter?.updateStagingEmployees)
	const salaryLimitExceeds = useSelector((state) => state?.counter?.salaryLimitExceeds)
	const featuresFlag = useSelector((state) => state?.sharedStates?.features)
	const payrollErrorInitailState = {
		userLimitExceeds: false,
		cardLoad: false,
		salaryProcess: false,
		cardLoadError: [],
		salaryProcessErrorMsg: [],
	};
	const [payrollError, setPayrollError] = useState(payrollErrorInitailState);

	const history = useHistory();
	let init = useRef(null);
	let tableRef = useRef(null);
	const messagesEndRef = useRef(null);
	gsap.registerPlugin(ScrollToPlugin);
	const totalSal = 0;
	const { state } = useLocation();
	useEffect(() => {
		userNavigationMenu();
	}, []);

	useEffect(() => {
		getPayrollDynamicHeader();
	}, []);

	useEffect(() => {
		getFilterDropdownData();
	}, []);

	useEffect(() => {
		getSalaryData();
		setEmpData({ ...empData, tableData: [] });
		// getPayrollEmpData(requestParams, isAllSelected);
	}, []);

	useEffect(() => {
		if (flagPopup) {
			setPopup(true);
		}
	}, [flagPopup]);

	useEffect(() => {
		setPopup(false);
	}, [salaryStaging]);

	useEffect(() => {
		setDropDownValueState(dropdownValues);
	}, [dropdownValues]);

	useEffect(() => {
		dropdownValidator(dropDownValueState);
	}, [dropDownValueState]);

	useEffect(() => {
		if (date?.salaryDate !== undefined && date?.salaryDate !== '') {
			setRequestParams({
				...requestParams,
				month: moment(date?.salaryDate).format('MM'),
				year: moment(date?.salaryDate).format('YYYY'),
			});
		}
	}, [date]);

	useEffect(() => {
		handleTotalSalary(empData.tableData, totalSal);
	}, [totalSal]);
	useEffect(() => {
		if (dynamicHeaderSuccess && requestParams?.month !== '' && requestParams?.year !== '') {
			if (JSON.stringify(requestParams) !== JSON.stringify(prevSearch)) {
				setEmpData({ ...empData, tableData: [] });
				// In case cardload limit salary exceeds enabled will disable
				if (salaryLimitExceeds) {
					dispatch(Actions.creators.salaryLimitExceeds(false))
				}
				getPayrollEmpData(requestParams, isAllSelected);
				setPrevSearch(requestParams);
			}
		}
	}, [requestParams, dynamicHeaderSuccess, popupFlag]);

	const updateStagingLoader = useSelector((state) => state?.counter?.updateStagingLoader);

	useEffect(() => {
		if (popupFlag || (updateStagingLoader === false && requestParams?.month !== '' && requestParams?.year !== '')) {
			setEmpData({ ...empData, tableData: [], selectedData: [] });
			getPayrollEmpData(requestParams, isAllSelected);
		}
	}, [popupFlag, updateStagingLoader]);

	const empTableDataRef = useRef(null);
	useEffect(() => {
		const apiData = employeeTableData?.map((data) => {
			const hasRecord = empData?.selectedData?.findIndex((d) => d?.empId === data?.empId) > -1;
			if (hasRecord) {
				return {
					...data,
					isSelected: true,
					isEdited: false,
				};
			}
			return { ...data, isEdited: false };
		});
		empTableDataRef.current = JSON.parse(JSON.stringify(apiData)); // creating new reference for employee table data
		let allData = JSON.parse(JSON.stringify(payrollRunData?.tempData));

		if (allData?.length > 0) {
			const editApiData = apiData.map((element) => {
				// loop through the getPayrollData
				let index = allData.findIndex(({ employeeCode }) => element.employeeCode === employeeCode); // if a data in editedEmpDataByPage matches the getPayrollData replace it with the editedEmpDataByPage
				if (index !== -1) {
					return allData[index]; // returning the matched data
				}
				return element;
			});
			setEmpData({ ...empData, tableData: editApiData });
		} else if (empData.editedData.length > 0) {
			// checking editedEmpDataByPage has data
			const editApiData = apiData.map((element) => {
				// loop through the getPayrollData
				let index = empData.editedData.findIndex(({ employeeCode }) => element.employeeCode === employeeCode); // if a data in editedEmpDataByPage matches the getPayrollData replace it with the editedEmpDataByPage
				if (index !== -1) {
					return empData.editedData[index]; // returning the matched data
				}
				return element;
			});
			setEmpData({ ...empData, tableData: editApiData });
		} else if (editedEmpDataByPage.length > 0) {
			// checking editedEmpDataByPage has data
			const editApiData = apiData.map((element) => {
				// loop through the getPayrollData
				let index = editedEmpDataByPage.findIndex(({ employeeCode }) => element.employeeCode === employeeCode); // if a data in editedEmpDataByPage matches the getPayrollData replace it with the editedEmpDataByPage
				if (index !== -1) {
					return editedEmpDataByPage[index]; // returning the matched data
				}
				return element;
			});
			setEmpData({ ...empData, tableData: editApiData }); // updating the empData with the editedEmpDataByPage
		} else {
			setEmpData({ ...empData, tableData: apiData });
		}
	}, [employeeTableData]);

	// useEffect(() => {
	// 	handleSalary(empData?.selectedData);
	// }, [empData?.selectedData, empData?.tableData]);

	useEffect(() => {
		popupTurnOff(false);
	}, [popup]);

	useEffect(() => {
		if (isSalaryExceeds) {
			setSalaryExceedsModal(true);
		}
		return () => {
			setSalaryExceedsModal(false);

		}
	}, [isSalaryExceeds]);


	useEffect(() => {
		setNextHide(empData?.length === 0);
		const finalVal = empData?.selectedData?.map((data) => {
			setNextHide(data?.isSelected);
		});
	}, [empData?.selectedData, empData?.tableData]);
	// Save and continue button disable logic
	const buttonDisable = !empData?.selectedData?.every((item) => item?.isSelected === true);

	const handleSalary = (selectedData) => {
		const data = selectedData.map((item) => {
			const val = item?.gratuity;
			const datas = item?.employeeBasicSalary;
			return (
				item.additions?.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0) +
				item.employeeAllowances.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0) +
				parseFloat(datas?.value ?? 0) +
				parseFloat(val?.value ?? 0) +
				parseFloat(item.overtimePayAmount ?? 0) -
				item.deductions.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0)
			);
		});
		const finalData = data.reduce((a, b) => a + b, 0);
		setTotalSalary(finalData);
	};

	const handleEditData = () => {
		let tempEditedData = [];
		if (editedEmpDataByPage?.length > 0) {
			const tempDifferentEmployeeEditData = empData?.editedData.filter(
				(data) => !editedEmpDataByPage?.some((emp) => emp.employeeCode === data?.employeeCode)
			);

			const tempRemoveDuplicateEditedData = JSON.parse(JSON.stringify(editedEmpDataByPage)).map((element) => {
				let index = empData?.editedData.findIndex(({ employeeCode }) => element.employeeCode === employeeCode);
				if (index !== -1) {
					return empData?.editedData[index]; // returning the matched data
				}
				return element;
			});
			tempEditedData = [...tempRemoveDuplicateEditedData, ...tempDifferentEmployeeEditData];
		} else {
			tempEditedData = [...empData?.editedData];
		}
		let tempOnlyEditSelectedData = JSON.parse(JSON.stringify(tempEditedData)).filter((data) =>
			empData?.selectedData?.some((emp) => emp.employeeCode === data?.employeeCode)
		);
		let tempOnlyEditData = JSON.parse(JSON.stringify(tempEditedData)).filter(
			(data) => !tempOnlyEditSelectedData?.some((emp) => emp.employeeCode === data?.employeeCode)
		);
		let tempOnlyEditDataStatus = tempOnlyEditData.map((employee) => {
			if (employee?.isEdited === true) {
				return {
					...employee,
					isSelected: false,
				};
			}
			return employee;
		});
		let tempOnlySelectedData = JSON.parse(JSON.stringify(empData?.selectedData)).filter(
			(data) => !tempOnlyEditSelectedData?.some((emp) => emp.employeeCode === data?.employeeCode)
		);
		let tempSelectedData = [...tempOnlySelectedData, ...tempOnlyEditSelectedData];
		let tempData = [...tempOnlyEditDataStatus, ...tempOnlySelectedData, ...tempOnlyEditSelectedData];
		// while debugging payroll tabel data we can use below consoles for each stage of payroll process
		// console.log('t1 tempEditedData : ', tempEditedData);
		// console.log('t1 empData?.editedData : ', empData?.editedData);
		// console.log('t1 editedEmpDataByPage : ', editedEmpDataByPage);
		// console.log('t1 tempOnlyEditData:', tempOnlyEditData);
		// console.log('t1 tempOnlySelectedData:', tempOnlySelectedData);
		// console.log('t1 tempData:', tempData);
		// console.log('t1 tempSelectedData', tempSelectedData);
		// console.log('t1 empData?.selectedData:', empData?.selectedData);
		// console.log('t1 tempOnlyEditSelectedData:', tempOnlyEditSelectedData);
		// console.log('t1 button disable:', nextHide, buttonDisable, isSalaryExceeds);

		if (tempSelectedData?.length > 0) {
			handleSalary(tempSelectedData);
		} else if (tempSelectedData?.length == 0) {
			handleSalary([]);
		}
		// if (empData.editedData.length > 0) {
		// 	setPayrollRunData((prev) => ({
		// 		...prev,
		// 		tempData: [...tempData],
		// 		tempEditedData: [...tempEditedData],
		// 	}));
		// } else {
		setPayrollRunData((prev) => ({
			...prev,
			tempData: [...tempData],
			tempEditedData: [...tempEditedData],
		}));
	};
	const cleverTapData = (tempData) => {
		tempData.forEach((employee) => {
			if (employee?.totalSalary > 0) {
				if (!saveContinueCleverTapPropertiesData?.includes('Total Salary')) {
					saveContinueCleverTapPropertiesData.push('Total Salary');
				}
			}
		});
		tempData.forEach((employee) => {
			if (employee?.employeeBasicSalary?.value > 0) {
				if (!saveContinueCleverTapPropertiesData?.includes('Basic Salary')) {
					saveContinueCleverTapPropertiesData.push('Basic Salary');
				}
			}
		});
		allowanceData.forEach((allowance) => {
			tempData.forEach((employee) => {
				employee.employeeAllowances.forEach((emp) => {
					if (emp?.allowanceTypeName === allowance?.name && emp?.value > 0) {
						if (!saveContinueCleverTapPropertiesData?.includes(emp?.allowanceTypeName)) {
							saveContinueCleverTapPropertiesData.push(emp?.allowanceTypeName);
						}
					}
				});
			});
		});
		additionData.forEach((addition) => {
			tempData.forEach((employee) => {
				employee.additions.forEach((emp) => {
					if (emp?.additionTypeName === addition?.name && emp?.value > 0) {
						if (!saveContinueCleverTapPropertiesData?.includes(emp?.additionTypeName)) {
							saveContinueCleverTapPropertiesData.push(emp?.additionTypeName);
						}
					}
				});
			});
		});
		deductionData.forEach((deduction) => {
			tempData.forEach((employee) => {
				employee.deductions.forEach((emp) => {
					if (emp?.deductionTypeName === deduction?.name && emp?.value > 0) {
						if (!saveContinueCleverTapPropertiesData?.includes(emp?.deductionTypeName)) {
							saveContinueCleverTapPropertiesData.push(emp?.deductionTypeName);
						}
					}
				});
			});
		});
	};
	useEffect(() => {
		handleEditData();
	}, [empData?.editedData, editedEmpDataByPage, empData?.selectedByPage, empData?.selectedData]);

	const onChangeHandler = (employeeIdNameFilter) => setRequestParams({ ...requestParams, search: String(employeeIdNameFilter).trim() });

	const handlePagination = (pagination, param) => {
		setRequestParams({
			...requestParams,
			[param]: pagination,
		});
		let temp = empData.editedData.filter((emp) => empData.editedByPage.some((el) => el.employeeCode !== emp.employeeCode));
		setEmpData((prev) => ({ ...prev, editedByPage: [...prev.editedData] }));
	};

	const handleSelectAll = (filterType = null, isAll = false, deselect = false, selectDrop) => {
		setIsAllSelected(deselect ? false : isAll);
		let lDropDownValueState = (dropDownValueState || [])?.map((dropdown) => {
			if (isAll || dropdown.name === filterType) {
				let tempOption = (dropdown?.options || [])?.map((option) => {
					return {
						...option,
						isSelected: deselect || selectDrop ? false : true,
					};
				});
				const isAllSelected = tempOption?.filter((value) => value?.isSelected)?.length === tempOption?.length;
				return {
					...dropdown,
					isAllSelected,
					options: tempOption,
				};
			}
			return dropdown;
		});
		setDropDownValueState([...lDropDownValueState]);
	};
	const onChange = (data) => {
		ClevertapReact.event('SelectMonth');
		setDate({
			...date,
			salaryDate: data?.salaryDate,
		});
		setRequestParams({
			...requestParams,
			month: moment(data?.salaryDate).format('MM'),
			year: moment(data?.salaryDate).format('YYYY'),
		});
		setCleverTapPropertiesData([]);
	};

	const employeeTotalCount = useMemo(() => new Set(empData?.selectedData), [empData.selectedData]);
	useEffect(() => {
		setSelectionListener(employeeTotalCount?.size > 0);
	}, [employeeTotalCount]);
	const handleChange = (filterType, dropdownType, data) => {
		let lDropDownValueState = (dropDownValueState || [])?.map((dropdown) => {
			if (dropdown.name === filterType) {
				let tempOption = (dropdown?.options || [])?.map((option) => {
					const lDropdownType = option?.payrollChannelName || option?.accountTypeName || option?.establishmentId || option?.bankName;
					if (lDropdownType === dropdownType) {
						return {
							...option,
							isSelected: data || !option?.isSelected,
						};
					} else return option;
				});
				const isAllSelected = tempOption?.filter((value) => value?.isSelected)?.length === tempOption?.length;
				return {
					...dropdown,
					isAllSelected,
					options: tempOption,
				};
			}
			return dropdown;
		});
		setDropDownValueState([...lDropDownValueState]);
	};

	const handleTotalSalary = (data) => {
		const totalValue = data?.map((item) => {
			return (item.totalSalary = data);
		});
		return totalValue;
	};
	const dropdownValidator = (value) => {
		let temp = (value || [])?.map((response) => {
			let temp1 = response?.options
				?.filter((res) => res?.isSelected)
				?.map((res) => {
					return {
						key: response?.key,
						selectedValues: res?.payrollChannelName || res?.accountTypeName || res?.establishmentId || res?.bankName,
					};
				});

			return {
				[response.key]: temp1?.map((res) => res?.selectedValues).join(),
			};
		});

		const finalValue = Object.assign({}, ...temp);
		setRequestParams({ ...requestParams, ...finalValue });
	};
	const handleSelect = (emp_id, selectAll) => {
		setOnChangePopup(true);
		let employeesSelected = empData?.selectedData;
		let selectedByPage = empData?.selectedByPage;
		let toggledData = empData?.tableData?.map((employee) => {
			if (emp_id === employee?.employeeCode) {
				employee.isSelected = !employee?.isSelected;
				if (employee['salaryExceeds']) {
					employee['salaryExceeds'] = false
				}
				if (isSalaryExceeds) {
					setIsSalaryExceeds(false);
				}
				if (employee?.isSelected) {
					employeesSelected.push(employee);
					selectedByPage.push({ employeeCode: employee.employeeCode, pageNumber: requestParams.pageNumber });
				} else {
					employeesSelected = employeesSelected?.filter((data) => emp_id !== data?.employeeCode);
					selectedByPage = selectedByPage?.filter((data) => emp_id !== data?.employeeCode);
				}
			}
			if (!emp_id) {
				employee.isSelected = selectAll;
				if (employee?.isSelected) {
					employeesSelected.push(employee);
					selectedByPage.push({ employeeCode: employee.employeeCode, pageNumber: requestParams.pageNumber });
				}
				if (!employee.isSelected) {
					employeesSelected = employeesSelected?.filter((data) => !data?.employeeCode);
					selectedByPage = selectedByPage?.filter((data) => !data?.employeeCode);
				}
			}
			return employee;
		});
		setEmpData({ ...empData, tableData: [...toggledData], selectedData: [...employeesSelected], selectedByPage: [...selectedByPage] });
	};
	const typeNameCheck = (typename, item, data) => {
		if (item?.id === data?.id && item?.[typename] === data?.[typename] && item?.value != data?.value) {
			return true;
		}
	};

	const isEmpEdited = (emp, index) => {
		// returns boolean based on if emp is modified (true) or not (false)
		let edited = false;
		const fieldNameToCheck = ['gratuity', 'overtimePayAmount', 'employeeBasicSalary', 'employeeAllowances', 'additions', 'deductions']; // added field names that needs to be checked if the values changed
		fieldNameToCheck.forEach((fieldName) => {
			if (empTableDataRef.current[index]?.employeeCode === emp?.employeeCode) {
				if (fieldName === 'employeeAllowances' || fieldName === 'additions' || fieldName === 'deductions') {
					emp[fieldName].forEach((item) => {
						empTableDataRef.current[index][fieldName].forEach((data) => {
							if (fieldName === 'employeeAllowances') {
								if (typeNameCheck('allowanceTypeName', item, data) === true) {
									edited = true;
								}
							} else if (fieldName === 'additions') {
								if (typeNameCheck('additionTypeName', item, data) === true) {
									edited = true;
								}
							} else if (fieldName === 'deductions') {
								if (typeNameCheck('deductionTypeName', item, data) === true) {
									edited = true;
								}
							}
						});
					});
				} else {
					if (
						emp[fieldName]?.id === empTableDataRef.current[index][fieldName]?.id &&
						emp[fieldName]?.value != empTableDataRef.current[index][fieldName]?.value
					) {
						edited = true;
					}
				}
			}
		});
		return edited;
	};

	const handleInputs = (employeeCode, fieldName, keyName, e) => {
		let val = e.split('');
		let value;
		if (val.length > 1 && val[0] === '0' && val[1] !== '.') {
			let values = val.splice(0, 1);
			value = amountWithNoCommas(twoDigitAfterDec(val.join('')));
		} else {
			value = amountWithNoCommas(twoDigitAfterDec(e));
		}

		setOnChangePopup(true);
		let temp = empData?.tableData?.map((employee, i) => {
			if (employeeCode === employee?.employeeCode) {
				if (isSalaryExceeds) {
					setIsSalaryExceeds(false);
				}
				if (employee['salaryExceeds']) {
					employee['salaryExceeds'] = false
				}
				if (employee[fieldName]) {
					if (employee[fieldName]?.id === keyName) {
						employee[fieldName].value = isNaN(value) ? 0 : value || 0;
					} else {
						employee[fieldName].map((data) => {
							if (data.allowanceTypeName === keyName || data.additionTypeName === keyName || data.deductionTypeName === keyName) {
								data.value = isNaN(value) ? 0 : value || 0;
							}
							return data;
						});
					}
				}
				employee.salary = isNaN(getEmpTotalSalary(employee)) ? 0 : getEmpTotalSalary(employee);
				employee.totalSalary = isNaN(getEmpTotalSalary(employee)) ? 0 : getEmpTotalSalary(employee);

				employee.isEdited = isEmpEdited(employee, i);
			}
			return employee;
		});

		const editedEmpList = temp.filter((emp) => emp.isEdited === true);
		setEmpData({ ...empData, tableData: [...temp], editedData: editedEmpList });
		// setPayrollRunData((prev) => ({
		// 	...prev,
		// 	tempData: [...temp],
		// }));
	};
	useEffect(() => {
		if (empData.tableData?.length > 0) {
			if (isArray(editedEmpDataByPage)) {
				if (editedEmpDataByPage.length === 0) {
					setEditedEmpDataByPage(JSON.parse(JSON.stringify(empData?.editedData)));
				} else {
					if (empData?.editedData?.length > 0) {
						let checkEmployeeCode = editedEmpDataByPage?.filter(
							(data) => !empData?.editedData?.some((el) => el?.employeeCode === data?.employeeCode)
						);
						let temp = JSON.parse(JSON.stringify(empData?.editedData));
						setEditedEmpDataByPage([...checkEmployeeCode, ...temp]);
					}
				}
				setEmpData((prev) => ({ ...prev, editedData: [] }));
			}
		}
	}, [requestParams?.pageNumber, requestParams?.pageSize, requestParams?.search]);


	// ------------- Run payroll logics and salary exceeds checks -----
	const salaryExcessData = {
		userInput: {
			salaryExceedsCount: 0,
			exceedsSalaryEmployees: [],
			exceedsSalaryEmployeesIds: [],
		},
		userActualLimit: {
			salaryExceedsCount: 0,
			exceedsSalaryEmployees: [],
			exceedsSalaryEmployeesIds: [],
		},
	};

	useEffect(() => {
		// If after running payroll selelcted employee exceeds the monthly limit then will call this function
		if (updateStagedEmployees?.length && salaryLimitExceeds && employeeTableData.length) {
			handleSalaryExceeds()
		}
	}, [updateStagedEmployees, employeeTableData]);

	useEffect(() => {
		setIsPopupVisible({
			...isPopupVisible,
			isWpsNonWps: wpsNonWpsPopupFlag,
		});
	}, [wpsNonWpsPopupFlag]);


	const handleSave = (month, year, mode = 'save') => {
		if (mode == 'save') {
			cleverTapData(payrollRunData?.tempEditedData);
			const cleverTapProperties = {
				TotalSalary: totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 }),
				NumberofEmployees: employeeTotalCount?.size,
				Month: moment(date?.salaryDate).format('MM'),
				year: moment(date?.salaryDate).format('YYYY'),
				ColumnNames: saveContinueCleverTapPropertiesData.toString(),
			};
			ClevertapReact.event('V1_1_PayrollTable_Save', { ...cleverTapProperties });
		}
		// if (mode == 'save') {
		// 	ClevertapReact.event('Save_PayrollPlusTable', { ...cleverTapProperties });
		// }		
		setOnChangePopup(false);
		// const payload = {
		// 	month: requestParams.month,
		// 	year: requestParams.year,
		// 	payrollDetails: data?.map((employee) => ({
		// 		employeeId: employee?.employeeCode,
		// 		allowances: employee?.employeeAllowances?.map((allowance) => {
		// 			if (allowance?.value === '' || allowance?.value === null) {
		// 				allowance.value = 0;
		// 			}
		// 			return { ...allowance, value: parseFloat(allowance.value) };
		// 		}),
		// 		additions: employee?.additions?.map((addition) => {
		// 			if (addition?.value === '' || addition?.value === null) {
		// 				addition.value = 0;
		// 			}
		// 			return { ...addition, value: parseFloat(addition.value) };
		// 		}),
		// 		deductions: employee?.deductions?.map((deduction) => {
		// 			if (deduction.value === '' || deduction.value === null) {
		// 				deduction.value = 0;
		// 			}
		// 			return { ...deduction, value: parseFloat(deduction.value) };
		// 		}),
		// 		gratuity:
		// 			employee?.gratuity?.value === '' || null
		// 				? { id: employee?.gratuity?.id, value: 0 }
		// 				: { id: employee?.gratuity?.id, value: parseFloat(employee?.gratuity?.value) },

		// 		isEndOfService: employee?.gratuity?.value > 0 ? true : false,
		// 	})),
		// };
		// bulkUpdatePayroll(payload, requestParams);
		const data = mode === 'save' ? payrollRunData?.tempEditedData : payrollRunData?.tempData;
		const salMonth = typeof +month === 'number' ? +month : month;
		const salYear = typeof +year === 'number' ? +year : year;
		const selectedPayrollData = data?.map((selectedEmployeeDetails) => {
			const { basicSalaryPaid, value } = selectedEmployeeDetails?.employeeBasicSalary || {}
			const basicSalary = basicSalaryPaid ? 0 : Number(value)
			if (featuresFlag && featuresFlag?.isCardLoadLimitEnabled && selectedEmployeeDetails.accountType === PAYROLL_CONSTANTS.employeeAccountType.card) {
				if (Number(selectedEmployeeDetails?.totalSalary.toFixed(2)) > PAYROLL_CONSTANTS.salaryLimit && selectedEmployeeDetails?.isSelected) {
					salaryExcessData.userInput.salaryExceedsCount += 1;
					salaryExcessData.userInput.exceedsSalaryEmployees.push({ ...selectedEmployeeDetails, salaryExceeds: true });
					salaryExcessData.userInput.exceedsSalaryEmployeesIds.push(Number(selectedEmployeeDetails.employeeId));
				}
			}
			return {
				id: 0,
				employeeId: selectedEmployeeDetails?.employeeCode,
				corporateId: selectedEmployeeDetails?.corporateId,
				salMonth: salMonth,
				salYear: salYear,
				endOfService: Number(selectedEmployeeDetails?.gratuity?.value),
				basicSalary,
				employeeName: selectedEmployeeDetails?.employeeName,
				c3RegId: selectedEmployeeDetails?.employeeId,
				totalSalary: Number(selectedEmployeeDetails?.totalSalary.toFixed(2)),
				employeeHRAllowanceStaging: selectedEmployeeDetails?.employeeAllowances.map((allowance) => {
					if (allowance.allowancePaid) {
						return {
							...allowance,
							value: 0,
						};

					}
					if (typeof allowance?.value === 'string') {
						return {
							...allowance,
							value: Number(allowance?.value),
						};
					}
					return allowance;
				}),
				employeeHRAdditionStaging: selectedEmployeeDetails?.additions.map((addition) => {
					if (typeof addition?.value === 'string') {
						return {
							...addition,
							value: Number(addition?.value),
						};
					}
					return addition;
				}),
				employeeHRDeductionStaging: selectedEmployeeDetails?.deductions.map((deduction) => {
					if (typeof deduction?.value === 'string') {
						return {
							...deduction,
							value: Number(deduction?.value),
						};
					}
					return deduction;
				}),
				isSelected: mode !== 'save' ? selectedEmployeeDetails?.isSelected : false,
				// overtimePay:selectedEmployeeDetails?.overtimePayAmount
			};
		});
		if (featuresFlag && featuresFlag?.isCardLoadLimitEnabled && salaryExcessData.userInput.salaryExceedsCount > 0) {
			setSelectedEmployee(salaryExcessData.userInput.exceedsSalaryEmployees.length);
			setPayrollError((prevData) => ({
				...prevData,
				userLimitExceeds: true,
			}));
			setIsSalaryExceeds(true);
			setEmpData((prev) => {
				// employees whose not exceeds >50k in the total salary				
				const filterEmployees = prev.tableData?.filter(
					(prevTable) => !salaryExcessData.userInput.exceedsSalaryEmployeesIds.includes(Number(prevTable.employeeId))
				);
				// In the selected employees non salary exceeds employees also may present so filtering them and adding as selected upon salary exceeds check
				const nonExceedsSalaryEmployees = prev.selectedData.filter((selectedData) => !salaryExcessData.userInput.exceedsSalaryEmployeesIds.includes(Number(selectedData.employeeId)))
				const selectedData = [...salaryExcessData.userInput.exceedsSalaryEmployees, ...nonExceedsSalaryEmployees]
				const tableData = [...salaryExcessData.userInput.exceedsSalaryEmployees, ...filterEmployees]
				return {
					...prev,
					selectedData,
					tableData
				}
			})
		} else {
			if (mode === 'save') {
				dispatch(Actions.creators.updateStaging(selectedPayrollData, { status: 'save', month: salMonth, year: salYear }));
				setEmpData((prev) => ({ ...prev, editedData: [] }));
				setEditedEmpDataByPage([]);
				setPayrollRunData((prev) => ({ ...prev, tempData: [], tempEditedData: [] }));
			} else if (mode === 'next') {
				dispatch(Actions.creators.updateStaging(selectedPayrollData, { status: 'next', month: salMonth, year: salYear }));
				setEmpData((prev) => ({ ...prev, selectedData: [], selectedByPage: [] }));
				setEmpData({ ...empData, editedData: [] });
				setEditedEmpDataByPage([]);
				setPayrollRunData((prev) => ({ ...prev, tempData: [], tempEditedData: [] }));
			}
			setSaveContinueCleverTapPropertiesData([]);
		}
	};

	const handleNext = (month, year) => {
		cleverTapData(payrollRunData?.tempData);
		const cleverTapProperties = {
			TotalSalary: totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 }),
			NumberofEmployees: employeeTotalCount?.size,
			Month: moment(date?.salaryDate).format('MM'),
			year: moment(date?.salaryDate).format('YYYY'),
			ColumnNames: saveContinueCleverTapPropertiesData.toString(),
		};
		ClevertapReact.event('V1_1_Payroll_Table_Next', { ...cleverTapProperties });
		if (salaryLimitExceeds) {
			dispatch(Actions.creators.salaryLimitExceeds(false))
		}
		handleSave(month, year, 'next');
		setIsPopupVisible({
			...isPopupVisible,
			// isWpsNonWps: true,
			message: 'next',
			type: 'payrollPlus',
			status: 'wpsNonWps',
			monthYearSelect: { month: month, year: year },
			payrollPlus: { month: month, year: year },
		});
	};

	const handleSalaryExceeds = () => {
		const validations = []
		updateStagedEmployees.forEach((employee) => validations.push(...employee?.validations))
		if (validations?.length) {
			setSelectedEmployee(validations.length);
			if (featuresFlag && featuresFlag?.isCardLoadLimitEnabled) {
				validations.forEach((emp) => {
					if (emp.field === 'TotalSalary' && emp.error === 'Exceeds Monthly Limit.') {
						const selectedEmployees = findObjFromArray(employeeTableData, String(emp.row), 'employeeCode');
						salaryExcessData.userActualLimit.salaryExceedsCount += 1;
						if (selectedEmployees) {
							salaryExcessData.userActualLimit.exceedsSalaryEmployees.push({ ...selectedEmployees, isSelected: true, salaryExceeds: true });
						}
						salaryExcessData.userActualLimit.exceedsSalaryEmployeesIds.push(String(emp.row));
					}
				});
				setEmpData((prev) => {
					const filterEmployees = prev.tableData?.filter(
						(prevTable) => !salaryExcessData.userActualLimit.exceedsSalaryEmployeesIds.includes(String(prevTable.employeeCode))
					);
					const nonExceedsSalaryEmployees = prev.selectedData.filter((selectedData) => !salaryExcessData.userActualLimit.exceedsSalaryEmployeesIds.includes(String(selectedData.employeeCode)))
					const selectedData = [
						...salaryExcessData.userActualLimit.exceedsSalaryEmployees,
						...nonExceedsSalaryEmployees
					]
					const tableData = [
						...salaryExcessData.userActualLimit.exceedsSalaryEmployees,
						...filterEmployees
					]
					return {
						...prev,
						selectedData,
						tableData
					}
				})
			}
			// Using field to detect type of error msg
			validations.map((val) => {
				// If its card load limit error
				if (val.field === 'TotalSalary' && val.error === 'Exceeds Monthly Limit.') {
					setPayrollError((prevData) => ({
						...prevData,
						cardLoad: true,
						cardLoadError: [...prevData.cardLoadError, val?.row],
					}));
				} else {
					setPayrollError((prevData) => ({
						...prevData,
						salaryProcess: true,
						salaryProcessErrorMsg: [...prevData.salaryProcessErrorMsg, val?.error],
					}));
				}
			});
			setIsSalaryExceeds(true);
		}
		else {
			setIsSalaryExceeds(false)
		}
	}
	// --------------- Update staging payroll step ends ----------------

	const handleEmployee = (data) => {
		setPopupValue(() => data);
		let val = data;
		history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '2', tableData: val } });
	};

	const handleGratuity = (employeeCode, e) => {
		let val = e.split('');
		let value;
		if (val.length > 1 && val[0] === '0' && val[1] !== '.') {
			let values = val.splice(0, 1);
			value = amountWithNoCommas(twoDigitAfterDec(val.join('')));
		} else {
			value = amountWithNoCommas(twoDigitAfterDec(e));
		}
		setOnChangePopup(true);
		if (value === '' || value === null || value === NaN) {
			value = 0;
		} else {
			value = value;
		}
		let temp = empData?.tableData?.map((employee, index) => {
			if (employeeCode === employee?.employeeCode) {
				employee.gratuity.value = !isNaN(value) ? value : 0;
				employee.salary = getEmpTotalSalary(employee);
				employee.totalSalary = getEmpTotalSalary(employee);
			}
			employee.isEdited = isEmpEdited(employee, index);
			return employee;
		});
		const editedEmpList = empData.tableData.filter((emp) => emp.isEdited === true);
		setEmpData({ ...empData, tableData: [...temp], editedData: editedEmpList });
	};

	const handleOverTimePay = (employeeCode, e) => {
		let value = amountWithNoCommas(twoDigitAfterDec(e));
		setOnChangePopup(true);
		if (value === '' || value === null || value === NaN) {
			value = 0;
		} else {
			value = value;
		}
		let temp = empData?.tableData?.map((employee, index) => {
			if (employeeCode === employee?.employeeCode) {
				employee.overtimePayAmount = value;
				employee.salary = getEmpTotalSalary(employee);
				employee.totalSalary = getEmpTotalSalary(employee);
			}
			employee.isEdited = isEmpEdited(employee, index);
			return employee;
		});
		const editedEmpList = empData.tableData.filter((emp) => emp.isEdited === true);
		setEmpData({ ...empData, tableData: [...temp], editedData: editedEmpList });
	};
	const handleSelectedDataByPage = (isAllDataSelected) => {
		// setOnChangePopup(true);
		let employeesSelected = JSON.parse(JSON.stringify(empData?.selectedData));
		let selectedByPage = JSON.parse(JSON.stringify(empData?.selectedByPage));
		const thisPageValues = empData?.selectedByPage
			?.filter((data) => data?.pageNumber === requestParams?.pageNumber)
			?.map((data) => data?.employeeCode);
		let toggledData = empData?.tableData?.map((employee) => {
			if (isAllDataSelected) {
				if (thisPageValues.includes(employee?.employeeCode)) {
					employee.isSelected = false;
					employeesSelected = employeesSelected?.filter((data) => data?.employeeCode !== employee?.employeeCode);
					selectedByPage = selectedByPage?.filter((data) => data?.employeeCode !== employee?.employeeCode);
				}
			} else {
				if (employee?.paidForSelectedMonthYear) {
					employee.isSelected = false;
				} else {
					employee.isSelected = true;
					if (!selectedByPage?.find((data) => data?.employeeCode === employee?.employeeCode)) {
						employeesSelected.push(employee);
						selectedByPage.push({ employeeCode: employee?.employeeCode, pageNumber: requestParams?.pageNumber });
					}
				}
			}
			return employee;
		});
		setEmpData((prev) => ({
			...prev,
			tableData: [...toggledData],
			selectedData: [...employeesSelected],
			selectedByPage: [...selectedByPage],
		}));
	};
	const tooltipContent = (state) => {
		if (state === 'Waiting for approver') {
			return 'Payroll is pending for approval';
		} else if (state === 'Approved') {
			return 'Payroll already processed for this month, no further changes can be made';
		} else if (state === 'Salary Processed') {
			return 'Payroll already processed for this month, no further changes can be made';
		} else if (state === 'totalSalaryZero') {
			return 'The total salary cannot be 0. Check all the values';
		} else if (state === 'totalSalaryNegative') {
			return 'The total salary cannot be negative. Check all the values';
		}
	};
	const isAllDataSelected = useMemo(
		() =>
			empData?.selectedByPage?.filter((data) => data?.pageNumber === requestParams?.pageNumber)?.length ===
			empData?.tableData?.filter((data) => data?.paidForSelectedMonthYear !== true)?.length,
		[requestParams?.pageNumber, empData?.selectedByPage, empData?.tableData, empData?.selectedData]
	);

	useEffect(() => {
		handleSelectedDataByPage(true);
		setEmpData((prev) => ({ ...prev, editedData: [] }));
	}, [date]);

	function isInValidAmountExists() {
		return empData.tableData.some((emp) => emp.totalSalary <= 0)
	}

	const columns = [
		{
			sortDirections: ['ascend'],
			fixed: 'left',
			align: 'center',
			scroll: 'hidden',
			title: (
				<Checkbox
					{...{
						checked: isAllDataSelected,
						size: 16,
						borderWidth: 1,
						borderRadius: 4,
						disabled: isInValidAmountExists(),
						onChange: () => handleSelectedDataByPage(isAllDataSelected),
					}}
				/>
			),
			dataIndex: 'isSelected',
			width: '20%',
			render: (isSelected, row) => {
				return (
					<Tooltip
						placement="top"
						title={tooltipContent(row.totalSalary === 0 ? 'totalSalaryZero' : row.totalSalary < 0 && 'totalSalaryNegative')}
						overlayClassName="toolTipCheckBox">
						<Col ref={(el) => (init = el)}>
							<Checkbox
								{...{
									size: 16,
									borderWidth: 1,
									borderRadius: 4,
									checked: empData.selectedData.findIndex((data) => data.employeeId === row.employeeId) > -1,
									onChange: () => handleSelect(row.employeeCode),
									disabled: row.paidForSelectedMonthYear || row.totalSalary <= 0,
									icon: (
										<div className={`payroll_plus_table_check_box ${row.salaryExceeds ? 'salary-exceeds-error' : ''}`}>
											<ImageComponent src={row.salaryExceeds ? icons.not_focus.xmarkBold : icons.not_focus.tick} alt="check" />
										</div>
									),
								}}
							/>
						</Col>
					</Tooltip>
				);
			},
		},
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			sort: true,
			title: 'Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			width: '20%',
			fixed: 'left',
			render: (val, rowData) => {
				return (
					<CustomToolTip direction="up" content={val} className="max-width-100-percentage">
						<Col
							disabled={rowData.basicSalary === 0}
							className="name"
							style={{
								display: 'grid',
								gap: '2px'
							}}
							onClick={() => handleEmployee(rowData)}>
							<span style={{
								textDecoration: 'underline',
								color: '#2B769C',
								textUnderlinePosition: 'under',
								cursor: 'pointer',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
								{val}
							</span>
							<span style={{
								color: '#181919',
								fontSize: ' 10px',
								fontStyle: 'normal',
								fontWeight: 300,
								lineHeight: '12px', /* 120% */
							}}>
								{rowData?.employeeCode || ''}
							</span>
						</Col>
					</CustomToolTip>
				);
			},
		},
		{
			title: 'Paid',
			sort: true,
			dataIndex: 'paid',
			key: 'paid',
			width: '20%',
			sorter: (a, b) => a?.employeeBasicSalary?.value - b?.employeeBasicSalary?.value,
			render: (paid, rowData) => {
				return (
					// <CustomToolTip
					// 	className="tooltip-style"
					// 	content={tooltipContent(rowData?.salaryStatus)}
					// 	active={rowData?.paidForSelectedMonthYear}>
					// 	<Col
					// 		// disabled={rowData.salary === 0}
					// 		className="name"
					// 		style={{ fontFamily: 'Montserrat-Medium', color: '#2B769C', fontWeight: '500', fontSize: '14px', lineHeight: '16px' }}>
					// 		{`${paid} Paid`}
					// 	</Col>
					// </CustomToolTip>
					<Tooltip
						placement="top"
						title={''}
					// title={tooltipContent(rowData?.salaryStatus)}
					>
						<Col
							// disabled={rowData.salary === 0}
							className="name"
							style={{ fontFamily: 'Montserrat-Medium', color: '#2B769C', fontWeight: '500', fontSize: '12px', lineHeight: '14px' }}>
							{`${paid} Paid`}
						</Col>
					</Tooltip>
				);
			},
		},
		{
			title: 'Total',
			sort: true,
			width: '20%',
			dataIndex: 'totalSalary',
			key: 'totalSalary',
			fixed: 'left',
			sorter: (a, b) => a?.totalSalary - b?.totalSalary,
			render: (totalSalary, rowData) => (
				<Col
					disabled={rowData.salary === 0}
					style={{ fontFamily: 'Montserrat-Bold', color: rowData.salaryExceeds ? '#F75448' : '', fontWeight: '700', fontSize: '14px', lineHeight: '16px' }}>
					{`${totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? 0}`}<br />
					{/* {`AED ${totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? 0}`} */}
					<div style={{ fontSize: '8px' }}>
						{rowData?.salaryExceeds && <span>Exceeds Monthly Limit</span>}
					</div>
				</Col>
			),
		},
		{
			title: 'Basic Salary',
			sort: true,
			dataIndex: 'employeeBasicSalary',
			key: 'employeeBasicSalary',
			width: '20%',
			sorter: (a, b) => a?.employeeBasicSalary?.value - b?.employeeBasicSalary?.value,
			render: (basicSalary, rowData) => {
				const disableBasicSalary = rowData.paidForSelectedMonthYear || basicSalary?.basicSalaryPaid
				// const Component = parseInt(rowData?.salary) === 0 ? Tooltip : Fragment;
				return (
					// <Component placement="bottom" title={PAYROLL_PLUS?.TOOLTIPMESSAGE} overlayClassName="Payroll_toolTip_Overlay">
					// <div>
					<CustomToolTip
						className="tooltip-style"
						direction="down"
						content={tooltipContent(rowData?.salaryStatus)}
						active={rowData?.paidForSelectedMonthYear}>
						<Inputbox
							className="payrollPlus prehighlight-bolds"
							disabled={disableBasicSalary}
							style={{
								borderColor: false ? '#E60A14' : null,
							}}
							// prefix={'AED'}
							type="text"
							value={amountWithCommas(isNaN(basicSalary?.value) ? 0 : basicSalary?.value) ?? 0}
							onChange={(e) => handleInputs(rowData.employeeCode, 'employeeBasicSalary', basicSalary?.id, e.target.value ?? 0)}
						/>
					</CustomToolTip>
					// </div>
					// </Component>
				);
			},
		},
		...allowanceData?.map((data) => {
			return {
				title: `${data?.name}`,
				dataIndex: `${data?.name}`,
				key: `${data?.name}`,
				width: '20%',
				sort: true,
				sorter: {
					compare: (a, b) => {
						let c = findObjFromArray(a.employeeAllowances, data.name, 'allowanceTypeName');
						let d = findObjFromArray(b.employeeAllowances, data.name, 'allowanceTypeName');
						return c?.value - d?.value;
					},
				},
				render: (overtimePay, rowData) => {
					const res = findObjFromArray(rowData.employeeAllowances, data.name, 'allowanceTypeName');
					const disableAllowance = rowData.paidForSelectedMonthYear || res?.allowancePaid
					// const Component = parseInt(rowData?.salary) === 0 ? Tooltip : Fragment;
					return (
						// <Component placement="bottom" title={PAYROLL_PLUS?.TOOLTIPMESSAGE} overlayClassName="Payroll_toolTip_Overlay">
						// <div>
						<CustomToolTip
							className="tooltip-style"
							direction="down"
							content={tooltipContent(rowData?.salaryStatus)}
							active={rowData?.paidForSelectedMonthYear}>
							<Inputbox
								className="payrollPlus prehighlight-bolds"
								style={{ borderColor: false ? '#E60A14' : null }}
								// prefix={'AED'}
								disabled={disableAllowance}
								value={amountWithCommas(isNaN(res?.value) ? 0 : res?.value) ?? 0}
								type="text"
								onChange={(e) => handleInputs(rowData.employeeCode, 'employeeAllowances', data.name, e.target.value ?? 0)}
							/>
						</CustomToolTip>
						//</div>
						// </Component>
					);
				},
			};
		}),
		...additionData?.map((data, rowData) => {
			return {
				title: `${data?.name}`,
				dataIndex: `${data?.name}`,
				key: `${data?.name}`,
				width: '20%',
				sort: true,
				sorter: {
					compare: (a, b) => {
						let c = findObjFromArray(a.additions, data.name, 'additionTypeName');
						let d = findObjFromArray(b.additions, data.name, 'additionTypeName');
						return c?.value - d?.value;
					},
				},
				render: (text, rowData, index) => {
					const res = findObjFromArray(rowData?.additions, data?.name, 'additionTypeName');
					// const Component = parseInt(rowData?.salary) === 0 ? Tooltip : Fragment;
					return (
						// <Component placement="bottom" title={PAYROLL_PLUS?.TOOLTIPMESSAGE} overlayClassName="Payroll_toolTip_Overlay">
						// <div>
						<CustomToolTip
							className="tooltip-style"
							direction="down"
							content={tooltipContent(rowData?.salaryStatus)}
							active={rowData?.paidForSelectedMonthYear}>
							<Inputbox
								className="payrollPlus prehighlight-bolds"
								style={{ borderColor: false ? '#E60A14' : null }}
								// prefix={'AED'}
								disabled={rowData?.paidForSelectedMonthYear}
								value={amountWithCommas(isNaN(res?.value) ? 0 : res?.value) ?? 0}
								type="text"
								onChange={(e) => handleInputs(rowData?.employeeCode, 'additions', data?.name, e?.target?.value ?? 0)}
							/>
						</CustomToolTip>
						// </div>
						// </Component>
					);
				},
			};
		}),

		// {
		// 	title: 'Overtime Pay',
		// 	dataIndex: 'overtimePayAmount',
		// 	key: 'overtimePayAmount',
		// 	width: '20%',
		// 	sort: true,
		// 	sorter: (a, b) => a?.overtimePayAmount - b?.overtimePayAmount,
		// 	render: (overtimePay, rowData) => (
		// 		<Inputbox
		// 			className="payrollPlus prehighlight-bolds"
		// 			disabled={true}
		// 			style={{ borderColor: false ? '#E60A14' : null }}
		// 			prefix={'AED'}
		// 			type="text"
		// 			value={amountWithCommas(Number(overtimePay)) ?? 0}
		// 			onChange={(e) => handleOverTimePay(rowData.employeeCode, e.target.value ?? 0)}
		// 		/>
		// 	),
		// },
		...deductionData?.map((data, index, index2) => {
			return {
				title: `${data?.name}`,
				dataIndex: `${data?.name}`,
				key: `${data?.name}`,
				width: '20%',
				sort: true,
				sorter: {
					compare: (a, b) => {
						let c = findObjFromArray(a.deductions, data.name, 'deductionTypeName');
						let d = findObjFromArray(b.deductions, data.name, 'deductionTypeName');
						return c?.value - d?.value;
					},
				},
				render: (overtimePay, rowData) => {
					const res = findObjFromArray(rowData.deductions, data.name, 'deductionTypeName');
					// const Component = parseInt(rowData?.salary) === 0 ? Tooltip : Fragment;
					return (
						// <Component placement="bottom" title={PAYROLL_PLUS?.TOOLTIPMESSAGE} overlayClassName="Payroll_toolTip_Overlay">
						// <div>
						<CustomToolTip
							className="tooltip-style"
							direction="down"
							content={tooltipContent(rowData?.salaryStatus)}
							active={rowData?.paidForSelectedMonthYear}>
							<Inputbox
								className="payrollPlus prehighlight-bolds"
								disabled={rowData.paidForSelectedMonthYear}
								style={{ borderColor: false ? '#E60A14' : null }}
								// prefix={'AED'}
								value={amountWithCommas(isNaN(res?.value) ? 0 : res?.value) ?? 0}
								type="text"
								onChange={(e) => handleInputs(rowData?.employeeCode, 'deductions', data?.name, e.target.value ?? 0)}
							/>
						</CustomToolTip>
						// </div>
						// </Component>
					);
				},
			};
		}),
		{
			title: 'Gratuity',
			dataIndex: 'gratuity',
			key: 'gratuity',
			width: '20%',
			sort: true,
			sorter: (a, b) => a?.gratuity?.value - b?.gratuity?.value,
			render: (gratuity, rowData) => (
				<Inputbox
					className="payrollPlus prehighlight-bolds"
					disabled={rowData.paidForSelectedMonthYear}
					style={{ borderColor: false ? '#E60A14' : null }}
					// prefix={'AED'}
					type="text"
					value={amountWithCommas(isNaN(gratuity?.value) ? 0 : gratuity?.value) ?? 0}
					onChange={(e) => handleGratuity(rowData.employeeCode, e.target.value ?? 0)}
				/>
			),
		},
	];
	const scrollEvent = () => {
		let temp = tableRef?.children[0]?.children[0]?.children[2]?.children[1]?.children[0]?.children[0].children[0]?.children[0]?.children[1];
		TweenMax.to(temp, 2, { scrollTo: { x: toogleScroll ? 1900 : -1900 } });
		setToogleScroll(!toogleScroll);
	};

	useEffect(() => {
		if (employeeTableData?.length == 0 && payrollTableLoader === false) {
			setRequestParams({
				...requestParams,
				pageNumber: 1,
			});
		}
	}, [employeeTableData]);

	function displayErrorMsg() {
		if (payrollError.userLimitExceeds) {
			return (
				<>
					Amount exceeds monthly limit of AED 50,000.
					<br />
					<p style={{ marginBottom: 0, marginTop: '16px' }}>
						You can either reduce the amount or
						<br />
						unselect these employees
					</p>
				</>
			);
		} else if (payrollError.cardLoad && payrollError.salaryProcess) {
			return (
				<>
					Amount exceeds monthly limit of AED 50,000.
					<br />
					<>
						Employee Ids:{' '}
						<ETypo b2>
							{payrollError.cardLoadError.length > 1
								? payrollError?.cardLoadError.map((err) => `${err}, `)
								: payrollError?.cardLoadError.map((err) => err)}
						</ETypo>
					</>
					<p style={{ marginBottom: 0, marginTop: '16px' }}>
						You can either reduce the amount or
						<br />
						unselect these employees
					</p>
					<br />
					Duplicate Ids found: <br />
					<>
						<ETypo b2>
							{payrollError.salaryProcessErrorMsg.length > 1
								? payrollError?.salaryProcessErrorMsg.map((err) => {
									return (
										<>
											{err}.<br />
										</>
									)
								})
								: payrollError?.salaryProcessErrorMsg.map((err) => err)}
						</ETypo>
					</>
				</>
			);
		} else if (payrollError.cardLoad) {
			return (
				<>
					Amount exceeds monthly limit of AED 50,000.
					<br />
					<>
						Employee Ids:{' '}
						<ETypo b2>
							{payrollError.cardLoadError.length > 1
								? payrollError?.cardLoadError.map((err) => `${err}, `)
								: payrollError?.cardLoadError.map((err) => err)}
						</ETypo>
					</>
					<p style={{ marginBottom: 0, marginTop: '16px' }}>
						You can either reduce the amount or
						<br />
						unselect these employees
					</p>
				</>
			);
		} else if (payrollError.salaryProcess) {
			return (
				<>
					Reason: <br />
					<>
						<ETypo b2>
							{payrollError.salaryProcessErrorMsg.length > 1
								? payrollError?.salaryProcessErrorMsg.map((err) => {
									return (
										<>
											{err}.<br />
										</>
									)
								})
								: payrollError?.salaryProcessErrorMsg.map((err) => err)}
						</ETypo>
					</>
				</>
			);
		}
	}
	const payrollErrorDialog = {
		title: `Payroll cannot be processed for ${selectedEmployee} employees`,
		desc: displayErrorMsg(),
		modalCloseText: 'Go Back',
	};

	function resetCardLoadStatus() {
		if (salaryLimitExceeds) {
			dispatch(Actions.creators.salaryLimitExceeds(false))
		}
		if (selectedEmployee) {
			setSelectedEmployee(0)
		}
		if (isSalaryExceeds) {
			setIsSalaryExceeds(false);
		}
	}

	function payrollErrorGoBack() {
		setSalaryExceedsModal(false);
		if (salaryLimitExceeds && payrollError.salaryProcess && !payrollError.cardLoad) {
			resetCardLoadStatus()
		}
		setPayrollError(payrollErrorInitailState);
	}
	return (
		<>
			<Edenredmodal
				isOpen={salaryExceedsModal}
				onOkay={payrollErrorGoBack}
				title={payrollErrorDialog.title}
				desc={payrollErrorDialog.desc}
				onCancel={null}
				close={null}
				onOkayButton={payrollErrorDialog.modalCloseText}
				topImage={<ImageComponent src={icons.not_focus.limitError} />}
			/>
			<div ref={(el) => (tableRef = el)} style={{ width: '100%', alignSelf: 'flex-start' }}>
				<PayrollPlusNewPresentational
					{...{
						columns,
						dropDownValueState,
						handleChange,
						handleSelectAll,
						payrollFilter,
						setPayrollFilter,
						empData,
						onChangeHandler,
						empCount,
						payrollTableLoader,
						handlePagination,
						payrollSalaryStatus,
						dropdownIcon,
						setDropdownIcon,
						messagesEndRef,
						init,
						tableRef,
						scrollEvent,
						toogleScroll,
						handleSave,
						popup,
						setPopup,
						popupFlag,
						totalSalary,
						employeeTotalCount,
						selectionListener,
						handleNext,
						onChange,
						date,
						setDate,
						popupValue,
						flagPopup,
						requestParams,
						onChangePopup,
						nextHide,
						buttonDisable,
						userNavigationMenu,
						isPopupVisible,
						setIsPopupVisible,
						setIsAllSelected,
						setDropDownValueState,
						editedEmpDataByPage,
						setEditedEmpDataByPage,
						setEmpData,
						isSalaryExceeds,
						resetCardLoadStatus
					}}
				/>
			</div>
		</>

	);
};

const mapStateToProps = (state) => {
	return {
		dropdownValues: Selectors.dropdownValues(state),
		payrollSalaryStatus: Selectors.payrollSalaryStatus(state),
		employeeTableData: Selectors.employeeTableData(state),
		empCount: Selectors.empCount(state),
		payrollTableLoader: Selectors.payrollTableLoader(state),
		additionData: Selectors.additionData(state),
		deductionData: Selectors.deductionData(state),
		allowanceData: Selectors.allowanceData(state),
		dynamicHeaderSuccess: Selectors.dynamicHeaderSuccess(state),
		popupFlag: Selectors.popupFlag(state),
		flagPopup: Selectors.flagPopup(state),
		salaryStaging: Selectors.salaryStaging(state),
		wpsNonWpsPopupFlag: Selectors.wpsNonWpsPopupFlag(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getFilterDropdownData: () => dispatch(Actions.creators.getPayrollFilterDropdownData()),
		getPayrollEmpData: (requestParams, isAllSelected) => dispatch(Actions.creators.getPayrollEmployeeTabledata(requestParams, isAllSelected)),
		getSalaryData: () => dispatch(Actions.creators.getSalaryStatus()),
		getPayrollDynamicHeader: () => dispatch(Actions.creators.getPayrollDynamicHeader()),
		bulkUpdatePayroll: (data, requestParams) => dispatch(Actions.creators.bulkUpdatePayroll(data, requestParams)),
		employeeSalaryStagings: (data) => dispatch(Actions.creators.employeeSalaryStagings(data)),
		popupTurnOff: () => dispatch(Actions.creators.popupTrigger(false)),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
		postRakDetails: (payload) => dispatch(Actions.creators.postRakBankDetails(payload)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(PayrollPlusNewFunctional);
export default Connected;
