import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import SetupTitle from '@pages/NewHrModuleSetup/Component/SetupTitle/SetupTitle';
import Stepper from '@pages/NewHrModuleSetup/Component/Stepper/Stepper';
import React from 'react';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClevertapReact from 'src/utils/clever-tap';
import '../SetupApprovalWorkflow/setupApprovalWorkflow.scss';

const InviteEmployees = ({ navigate = '/leaves/leaves_to_approve', CleverTap = true }) => {
	const history = useHistory();
	const isFileUploaded = useSelector((state) => state?.hrSetup?.leaves?.isFileUploaded);
	const stepperItems = [
		{
			name: '1 - Departments',
			isActive: false,
			isCompleted: true,
			navigate: !CleverTap ? '/new-hr-features/departments' : '/leave/departments',
		},
		{
			name: '2 - Upload Data',
			isActive: false,
			isCompleted: true,
			navigate: !CleverTap ? '/new-hr-features/upload-leave' : '/leave/upload-leave',
			isMissing: isFileUploaded === false,
		},
		{
			name: '3 - How To Set Up Approval Workflow',
			isActive: false,
			isCompleted: true,
			navigate: !CleverTap ? '/new-hr-features/setup-approval-workflow' : '/leave/setup-approval-workflow',
		},
		{
			name: '4 - How To Invite Employees',
			isActive: true,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/invite-employees' : '/leave/invite-employees',
		},
	];
	const steps = [
		{
			step: 'Step 1:',
			title: 'Employees Table',
			description: 'Go to Employees Table and find the bank account holders you want to give them access to request leaves.',
			imgSrc: images?.employeesTable,
		},
		{
			step: 'Step 2:',
			title: 'Invite Your Employees',
			description:
				'Click in “Invite To Portal” for those bank account holders, they will be able to request leaves from the portal, your C3Pay cardholders are going to be able from C3Pay App.',
			imgSrc: images?.inviteEmployees,
		},
	];
	return (
		<div className="page-wrappers">
			<SetupTitle
				title="One last step: Upload your data"
				rightBtnText="Understood"
				rightBtnType="primary"
				rightBtnClick={() => {
					ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStarted_Understood' : 'V1_1_Leave_Depart_Understood');
					history.push(navigate);
				}}
			/>
			<div className="content-wrapper">
				<Stepper listOfTabs={stepperItems} />
				<main className="main_wrapper">
					<div className="info_card">
						<ImageComponent src={focus_state_icons?.information} />
						<ETypo h6>
							Inviting your bank account holders they will be able to get access to request leaves, download payslips and view your
							announcements.
						</ETypo>
					</div>
					<div className="hero-wrapper">
						{steps?.map((item) => (
							<div className="step-box">
								<div className="step-content">
									<span className="step-number">{item?.step}</span>
									<ETypo h5>{item?.title}</ETypo>
									<span className="step-description">{item?.description}</span>
								</div>
								<div>
									<ImageComponent src={item?.imgSrc} />
								</div>
							</div>
						))}
					</div>
				</main>
			</div>
		</div>
	);
};

export default InviteEmployees;
