const GET_SALARYPAIDSTATUS_START = 'GET_SALARYPAIDSTATUS_START';
const GET_SALARYPAIDSTATUS_SUCCESS = 'GET_SALARYPAIDSTATUS_SUCCESS';
const GET_SALARYPAIDSTATUS_FAIL = 'GET_SALARYPAIDSTATUS_FAIL';
const GET_PAYROLLRUN_START = 'GET_PAYROLLRUN_START';
const GET_PAYROLLRUN_SUCCESS = 'GET_PAYROLLRUN_SUCCESS';
const GET_PAYROLLRUN_FAIL = 'GET_PAYROLLRUN_FAIL';
const GET_ADDITION_START = 'GET_ADDITION_START';
const GET_ADDITION_SUCCESS = 'GET_ADDITION_SUCCESS';
const GET_ADDITION_FAIL = 'GET_ADDITION_FAIL';
const GET_DEDUCTION_START = 'GET_DEDUCTION_START';
const GET_DEDUCTION_SUCCESS = 'GET_DEDUCTION_SUCCESS';
const GET_DEDUCTION_FAIL = 'GET_DEDUCTION_FAIL';
const GET_OVERTIMEPAYCAL_START = 'GET_OVERTIMEPAYCAL_START';
const GET_OVERTIMEPAYCAL_SUCCESS = 'GET_OVERTIMEPAYCAL_SUCCESS';
const GET_OVERTIMEPAYCAL_FAIL = 'GET_OVERTIMEPAYCAL_FAIL';
const GET_TERMINATIONTYPE_START = 'GET_TERMINATIONTYPE_START';
const GET_TERMINATIONTYPE_SUCCESS = 'GET_TERMINATIONTYPE_SUCCESS';
const GET_TERMINATIONTYPE_FAIL = 'GET_TERMINATIONTYPE_FAIL';
const HANDLE_SAVE_POPUP = 'HANDLE_SAVE_POPUP';
const SAVED_SUCCEESSFUL_POPUP = 'SAVED_SUCCEESSFUL_POPUP';
const GET_SAVEPAYROLLRUN_START = 'GET_SAVEPAYROLLRUN_START';
const GET_SAVEPAYROLLRUN_SUCCESS = 'GET_SAVEPAYROLLRUN_SUCCESS';
const GET_SAVEPAYROLLRUN_FAIL = 'GET_SAVEPAYROLLRUN_FAIL';
const MODIFIED_MONTH_YEAR = 'MODIFIED_MONTH_YEAR';

const getSalaryProcessStatusStart = (employeeDetails) => {
	return {
		type: GET_SALARYPAIDSTATUS_START,
		employeeDetails,
	};
};

const getSalaryProcessStatusSuccess = (response) => {
	return {
		type: GET_SALARYPAIDSTATUS_SUCCESS,
		response,
	};
};

const getSalaryProcessStatusFail = (error) => {
	return {
		type: GET_SALARYPAIDSTATUS_FAIL,
		error,
	};
};
const getPayrollRunStart = (employeeDetails, month, year) => {
	console.log('GET_PAYROLLRUN_START');
	return {
		type: GET_PAYROLLRUN_START,
		employeeDetails,
		month,
		year,
	};
};

const getPayrollRunSuccess = (response) => {
	console.log('GET_PAYROLLRUN_SUCCESS', response);
	return {
		type: GET_PAYROLLRUN_SUCCESS,
		response,
	};
};

const getPayrollRunFail = (error) => {
	return {
		type: GET_PAYROLLRUN_FAIL,
		error,
	};
};

const getAdditionValueStart = () => {
	console.log('GET_ADDITION_START');
	return {
		type: GET_ADDITION_START,
	};
};

const getAdditionValueSuccess = (response) => {
	console.log('GET_ADDITION_SUCCESS', response);
	return {
		type: GET_ADDITION_SUCCESS,
		response,
	};
};

const getAdditionValueFail = (error) => {
	return {
		type: GET_ADDITION_FAIL,
		error,
	};
};

const getDeductionValueStart = () => {
	console.log('GET_DEDUCTION_START');
	return {
		type: GET_DEDUCTION_START,
	};
};

const getDeductionValueSuccess = (response) => {
	console.log('GET_DEDUCTION_SUCCESS', response);
	return {
		type: GET_DEDUCTION_SUCCESS,
		response,
	};
};

const getDeductionValueFail = (error) => {
	return {
		type: GET_DEDUCTION_FAIL,
		error,
	};
};
const getOverTimePayCalStart = () => {
	console.log('GET_OVERTIMEPAYCAL_START');
	return {
		type: GET_OVERTIMEPAYCAL_START,
	};
};

const getOverTimePayCalSuccess = (response) => {
	console.log('GET_OVERTIMEPAYCAL_SUCCESS', response);
	return {
		type: GET_OVERTIMEPAYCAL_SUCCESS,
		response,
	};
};

const getOverTimePayCalFail = (error) => {
	return {
		type: GET_OVERTIMEPAYCAL_FAIL,
		error,
	};
};
const getTerminationTypeStart = () => {
	console.log('GET_TERMINATIONTYPE_START');
	return {
		type: GET_TERMINATIONTYPE_START,
	};
};

const getTerminationTypeSuccess = (response) => {
	console.log(' GET_TERMINATIONTYPE_SUCCESS', response);
	return {
		type: GET_TERMINATIONTYPE_SUCCESS,
		response,
	};
};

const getTerminationTypeFail = (error) => {
	return {
		type: GET_TERMINATIONTYPE_FAIL,
		error,
	};
};

const getSavePopup = (flag) => {
	return {
		type: HANDLE_SAVE_POPUP,
		flag,
	};
};

const savedSucceessfulPopUp = (flag) => {
	return {
		type: SAVED_SUCCEESSFUL_POPUP,
		flag,
	};
};
const getSavePayrollRunStart = (employeeDetails, saveValue, month, year, isGratuity) => {
	return {
		type: GET_SAVEPAYROLLRUN_START,
		employeeDetails,
		saveValue,
		month,
		year,
		isGratuity,
	};
};

const getSavePayrollRunSuccess = (response) => {
	console.log('GET_PAYROLLRUN_SUCCESS', response);
	return {
		type: GET_SAVEPAYROLLRUN_SUCCESS,
		response,
	};
};

const getSavePayrollRunFail = (error) => {
	return {
		type: GET_SAVEPAYROLLRUN_FAIL,
		error,
	};
};

const getModifiedMonthYearReducer = (value) => {
	return {
		type: MODIFIED_MONTH_YEAR,
		value,
	};
};

export default {
	types: {
		GET_SALARYPAIDSTATUS_START,
		GET_SALARYPAIDSTATUS_SUCCESS,
		GET_SALARYPAIDSTATUS_FAIL,
		GET_PAYROLLRUN_START,
		GET_PAYROLLRUN_SUCCESS,
		GET_PAYROLLRUN_FAIL,
		GET_ADDITION_START,
		GET_ADDITION_SUCCESS,
		GET_ADDITION_FAIL,
		GET_DEDUCTION_START,
		GET_DEDUCTION_SUCCESS,
		GET_DEDUCTION_FAIL,
		GET_OVERTIMEPAYCAL_START,
		GET_OVERTIMEPAYCAL_SUCCESS,
		GET_OVERTIMEPAYCAL_FAIL,
		GET_TERMINATIONTYPE_START,
		GET_TERMINATIONTYPE_SUCCESS,
		GET_TERMINATIONTYPE_FAIL,
		HANDLE_SAVE_POPUP,
		SAVED_SUCCEESSFUL_POPUP,
		GET_SAVEPAYROLLRUN_START,
		GET_SAVEPAYROLLRUN_SUCCESS,
		GET_SAVEPAYROLLRUN_FAIL,
		MODIFIED_MONTH_YEAR,
	},
	creators: {
		getSalaryProcessStatusStart,
		getSalaryProcessStatusSuccess,
		getSalaryProcessStatusFail,
		getPayrollRunStart,
		getPayrollRunSuccess,
		getPayrollRunFail,
		getAdditionValueStart,
		getAdditionValueSuccess,
		getAdditionValueFail,
		getDeductionValueStart,
		getDeductionValueSuccess,
		getDeductionValueFail,
		getOverTimePayCalStart,
		getOverTimePayCalSuccess,
		getOverTimePayCalFail,
		getTerminationTypeStart,
		getTerminationTypeSuccess,
		getTerminationTypeFail,
		getSavePopup,
		savedSucceessfulPopUp,
		getSavePayrollRunStart,
		getSavePayrollRunSuccess,
		getSavePayrollRunFail,
		getModifiedMonthYearReducer,
	},
};
