import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './index.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { mergeAllClaims } from 'src/utils/claims';
import ClevertapReact from 'src/utils/clever-tap';
import { ALL_EMPLOYEE_CLAIMS, ALL_HR_CLAIMS, ALL_PAYROLL_CLAIMS } from '@constants/app-constants';
import action from '../Employee/action';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

function Index(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const invoicePlan = props.location ? props.location.state : '';

	const [openPopUp, setOpenPopUp] = useState(false);
	const { state } = useLocation();
	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	const corporateId = useSelector((state) => state?.sharedStates?.corporateId);
	const corporateIds = useSelector((state) => state?.sharedStates?.corporateIds);
	const isUnEmploymentLoading = useSelector((state) => state?.unemploymentReducer?.isUnEmploymentLoading);

	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);
	const { unEmploymentBannerData } = useSelector((state) => state?.sharedStates);
	useEffect(() => {
		if (corporateId && typeof corporateId === 'string' && corporateIds && corporateIds.length > 0) {
			const [corporate] = corporateIds.filter((_corporate) => _corporate.corporateId === corporateId);
			if (corporate && unEmploymentBannerData?.data?.displayBanner === false) {
				history.push('/un-employment-insurance/success');
			}
		}
	}, [corporateId, corporateIds]);

	const corporateName = () => {
		if (corporateId && typeof corporateId === 'string' && corporateIds && corporateIds.length > 0) {
			const [corporate] = corporateIds.filter((_corporate) => _corporate.corporateId === corporateId);
			if (corporate) {
				return corporate.corporateName;
			} else {
				return '';
			}
		} else {
			return '';
		}
	};

	function onSubmit() {
		ClevertapReact.event('Confirm_T&C');
		dispatch(action.creators.putUnEmploymentEmployeeData(history, invoicePlan));
	}

	function OnClickCancel() {
		ClevertapReact.event('Cancel_T&C');
		const SHOULD_CONTAIN = allClaims.some((_claims) => [...ALL_EMPLOYEE_CLAIMS, ...ALL_HR_CLAIMS].indexOf(_claims) >= 0);
		const SHOULD_NOT_CONTAIN = !allClaims.some((_claims) => [...ALL_PAYROLL_CLAIMS].indexOf(_claims) >= 0);

		if (SHOULD_CONTAIN && SHOULD_NOT_CONTAIN) {
			history.push('/home');
		} else {
			history.push('/');
		}
	}

	return (
		<div className="terms-and-condition-wrapper">
			<div>
				<section className="header">
					<h1>Terms & Conditions</h1>
					<div>
						<EdenredButtons onClick={OnClickCancel} btnType={'secondary'}>
							Cancel
						</EdenredButtons>
						<EdenredButtons onClick={() => setOpenPopUp(true)}>Accept & Confirm</EdenredButtons>
					</div>
				</section>

				<section className="main">
					<p>
						Unemployment Insurance policies are issued pursuant to Decree-Law No. 13 of 2022 regarding Unemployment Insurance and Cabinet
						Resolution No. 97 of 2022 regarding the mechanisms and controls for implementing Unemployment Insurance
					</p>

					<p>
						Policies are issued by Dubai Insurance Co. (P.S.C), While Edenred is acting as the payment facilitator to enabling your
						policies issued easily.
					</p>

					<h5>
						Important: By agreeing to these terms and conditions, Company {corporateName()} confirms and guarantees that it has collected
						consent from its employees and informed them about the following:
					</h5>

					<ol type="1">
						<li>The unemployment insurance is a mandatory government regulation.</li>
						<li>How the unemployment insurance works and what are its benefits.</li>
						<li>The cost of the subscription via Edenred is monthly AED 120 + VAT + AED 14 transaction fee.</li>
						<li>
							The amount for next renewal cycle will be debited automatically from their C3Pay accounts to maintain the subscription.
						</li>
						<li>
							The insurance policy, insurance coverage and claims are all entirely provided by Dubai Insurance Co. P.S.C. Under this
							provision, Edenred is exempted from any liability that may arise with the sale of the policy and all associated claims The
							Company agrees to indemnify, defend, and hold harmless Edenred, its holding company, affiliates, subsidiaries and their
							representatives, officers, employees, contractors, assigns and agents indemnified from and against all losses arising in
							connection with this Agreement (including without limitation lawyers’ fees, penalties, interest and loss of profits) in
							connection with any breach of this Agreement by the Customer, any fraud, act or omission of the Customer, the Employees or
							any other third party.
						</li>
					</ol>
					<p>
						In addition, you can view the full terms and conditions for your employees{' '}
						<a
							style={{ color: '#0E5274', textDecoration: 'underline', fontWeight: '700' }}
							href="https://iridescent-dieffenbachia-69fb26.netlify.app/"
							target="__blank">
							here.
						</a>
					</p>
				</section>
			</div>
			<Edenredmodal
				{...{
					className: 'unemployment-save-popup',
					isOpen: openPopUp,
					close: () => setOpenPopUp(false),
					onOkayButton: 'Issue Policies',
					onCancelButton: 'Cancel',
					onOkay: onSubmit,
					onCancel: () => setOpenPopUp(false),
					loading: isUnEmploymentLoading,
					title: 'By clicking "Issue Policies", policies will be issued for your employees with C3Pay cards.',
				}}
			/>
		</div>
	);
}

export default Index;
