import Axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';

export function* employeesListService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}?status=ACTIVE`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeesListSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeesListFail(err));
	}
}

export function* leaveTypeService() {
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leavetype`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getLeaveTypeSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getLeaveTypeFail(err));
	}
}

export function* overLappingService(nEmployeeDetail) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const dates = nEmployeeDetail?.employeeData;
	const request = {
		status: dates?.status || '',
		startDate: dates?.startDate,
		endDate: dates?.endDate,
	};
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/overlapping`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.getOverLappingSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getOverLappingFail(err));
	}
}

export function* leaveBalanceService(employeeData) {
	const request = employeeData?.employeeData;
	const employeeId = request?.nameId?.split('code:')?.[1]?.split('id:')?.[0] || '';
	const leavetype = request?.leaveType || '';
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leavebalance?employeeId=${employeeId}&leaveType=${leavetype}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getLeaveBalanceSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getLeaveBalanceFail(err));
	}
}

export function* documentValidService(employeeData) {
	const employeeId = employeeData?.employeeData || '';
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/${employeeId}/employeedocumentdata`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getDocumentValidSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getDocumentValidFail(err));
	}
}
export function* LeaveValidService(action) {
	const id = action?.payload?.val || '';
	const id1 = action?.payload?.employeeData || '';
	const sDate = action?.payload?.startDate || '';
	const eDate = action?.payload?.endDate || '';
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri1 = `${DOMAIN_URL}/Leave/${corporateId}/detail?employeeId=${id}`;
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/detail?employeeId=${id1}&startDate=${sDate}&endDate=${eDate}`;
		const URL = (sDate == undefined && eDate == undefined) || (sDate == '' && eDate == '') ? uri1 : uri;
		const res = yield call(Axios.get, URL);
		yield put(Actions.creators.getLeaveValidSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getLeaveValidFail(err));
	}
}

export function* departmentLeaves(action) {
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const URL = `${DOMAIN_URL}/Employees/${corporateId}/${action?.action}`;
		const res = yield call(Axios.get, URL);
		yield put(Actions.creators.getDepartmentLeaveSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getDepartmentLeaveFail(err));
	}
}

export function* createService(employeeData) {
	const data = employeeData?.employeeData;
	const files = employeeData?.fileUpload || '';
	const request = {
		EmployeeId: data?.nameId?.split('code:')?.[1]?.split('id:')?.[0] || '',
		LeaveType: data?.leaveType || '',
		StartDate: data?.startDate || '',
		EndDate: data?.endDate || '',
		Notes: data?.notes || '',
	};
	let formData = new FormData();
	formData.append('Attachment', files);
	formData.append('EmployeeId', request?.EmployeeId);
	formData.append('LeaveType', request?.LeaveType);
	formData.append('StartDate', request?.StartDate);
	formData.append('EndDate', request?.EndDate);
	formData.append('Notes', request?.Notes);
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/create`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.getCreateSuccess(res.data));
		yield put(Actions.creators.handleLeaveConfirmPopup(false));
		yield put(Actions.creators.getCreateSaved(true));
	} catch (err) {
		yield put(Actions.creators.getCreateFail(err));
	}
}

export default function* requestLeaveWatcher() {
	yield takeEvery(Actions.types.GET_EMPLOYEESLIST_START, employeesListService);
	yield takeEvery(Actions.types.GET_LEAVETYPE_START, leaveTypeService);
	yield takeEvery(Actions.types.GET_OVERLAPPING_START, overLappingService);
	yield takeEvery(Actions.types.GET_LEAVEBALANCE_START, leaveBalanceService);
	yield takeEvery(Actions.types.GET_DOCUMENTVALID_START, documentValidService);
	yield takeEvery(Actions.types.GET_CREATE_START, createService);
	yield takeEvery(Actions.types.GET_LEAVEVALID_START, LeaveValidService);
	yield takeEvery(Actions.types.GET_DEPARTMENTLEAVE_START, departmentLeaves);
}
