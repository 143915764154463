import Axios from 'axios';
import { message } from 'antd';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';

export function* getProfileData(value) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/${value.payload}/employeedocumentdata`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeeIndividualDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeeIndividualDataError(err));
	}
}

export function* getDocumentImages(value) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/${value.payload}/expirydocumentimages`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeeDocumentImagesSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeeDocumentImagesError(err));
	}
}

export function* getDeleteImages(action) {
	const docId = action?.payload;
	const empId = action?.empId;
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/document/${docId}`;
		const res = yield call(Axios.delete, uri);

		yield put(Actions.creators.getDeleteImagesSuccess(res.data));
		message.success('Deleted Successfully');
		yield put(Actions.creators.getEmployeeIndividualData(empId));
		yield put(Actions.creators.getEmployeeDocumentImages(empId));
	} catch (err) {
		yield put(Actions.creators.getDeleteImagesError(err));
	}
}
export function* updateProfileData(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const upData = action?.upData;
	const id = action?.payload;
	const imageData = action?.imageData;
	let formData = new FormData();
	formData.append('LabourCardScan', imageData.labourCardScan || null);
	formData.append('EmiratesFrontScan', imageData.emiratesFrontScan || null);
	formData.append('EmiratesBackScan', imageData.emiratesBackScan || null);
	formData.append('VisaScan', imageData.visaScan || null);
	formData.append('passportScan', imageData.passportScan || null);
	formData.append('WorkPermitNumber', upData?.workPermitNumber || '');
	formData.append('LabourCardNumber', upData?.labourCardNumber || '');
	formData.append('LabourCardExpiryDate', upData?.labourCardExpiryDate || '');
	formData.append('EmiratesId', upData?.emiratesIdNumber || '');
	formData.append('EmiratesIssueDate', upData?.emiratesIssueDate || '');
	formData.append('EmiratesExpiryDate', upData?.emiratesExpiryDate || '');
	formData.append('VisaNumber', upData?.visaNumber || '');
	formData.append('VisaIssueDate', upData?.visaIssueDate || '');
	formData.append('VisaExpiryDate', upData?.visaExpiryDate || '');
	formData.append('PassportId', upData?.passportNumber || '');
	formData.append('PassportIssueDate', upData?.passportIssueDate || '');
	formData.append('PassportExpiryDate', upData?.passportExpiryDate || '');
	formData.append('EmiratesFrontScanId', '19');
	formData.append('EmiratesBackScanId', '20');
	formData.append('LabourCardScanId', '8');
	formData.append('VisaScanId', '2');
	formData.append('PassportScanId', '1');

	try {
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/${id}/update`;
		const res = yield call(Axios.put, uri, formData);
		yield put(Actions.creators.updateProfileDataSuccess(res.data));
		message.success('Saved Successfully');
		yield put(Actions.creators.getEmployeeIndividualData(id));
		yield put(Actions.creators.getEmployeeDocumentImages(id));
	} catch (err) {
		yield put(Actions.creators.updateProfileDataError(err));
	}
}
export function* checkDuplicateServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const resquest = action?.payload;
	try {
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/id-duplication-check?documentId=${resquest?.docId}&documentType=${resquest?.docType}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.checkDuplicateDocumentSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.checkDuplicateDocumentFail(err));
	}
}
export default function* empWatcher() {
	yield takeEvery(Actions.types.GET_EMPLOYEE_INDIVIDUAL_DATA, getProfileData);
	yield takeEvery(Actions.types.GET_EMPLOYEE_DOCUMENT_IMAGES, getDocumentImages);
	yield takeEvery(Actions.types.GET_DELETE_IMAGES, getDeleteImages);
	yield takeEvery(Actions.types.UPDATE_PROFILE_DATA, updateProfileData);
	yield takeEvery(Actions.types.CHECK_DUPLICATE_DOCUMENT_START, checkDuplicateServices);
}
