import React, { useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import { LEAVE_SUBMIT } from '@constants/app-constants';
import { withReducer } from 'src/store/reducerLoader';
import Popup from 'src/utils/popup/popups';
import action from './action';
import saga from './saga/index';
import selector from './selector';
import { entriesDropDown } from '@helpers';
import reducer from './reducer';
import './leave-upload-table.scss';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from './../DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Table from '@pages/DesignSystem/Table/Table';
import { DotLoader } from 'react-spinners';
import { icons } from 'src/assets/icons';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import EdenredSearch from '@sharedComponent/search-input';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';
import Toast from '@pages/DesignSystem/Toast';

const LeaveUploadTable = ({
	leaveBalanceSaga,
	isBalanceLoading,
	getLeaveBalance,
	getLeaveBalanceSaga,
	isleaveApproveUpload = false,
	leavePath = '',
	shouldHideHeader,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const isSubmitted = useSelector((state) => state?.leaveApprovalReducer?.isLeaveApproverSubmitted);
	const { leaveBalanceModel, totalEmployeeCount } = getLeaveBalance;
	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const [leaveDetails, setLeaveDetails] = useState([]);
	const [submitModal, setSubmitModal] = useState(false);
	let [pageSize, setPageSize] = useState(10);
	let [pageNumber, setPageNumber] = useState(1);
	const [searchvalue, setSearchValue] = useState('');
	const handleSetupLists = () => {
		leaveBalanceSaga(userName);
		setSubmitModal(false);
	};

	useEffect(() => {
		getLeaveBalanceSaga({ userName: userName, pageNumber: pageNumber, pageSize: pageSize });
	}, [pageNumber, pageSize, searchvalue]);

	const handelSuccessPopup = () => {
		history.push({
			pathname: isleaveApproveUpload ? leavePath : '/setup',
		});
		dispatch(action.creators.submitLeaveApprove(false));
	};
	useEffect(() => {
		const nUserData = leaveBalanceModel?.map((data) => {
			return {
				...data,
				leaveTypeStagingModel: data?.leaveTypeStagingModel?.sort((a, b) => a.leaveTypeId - b.leaveTypeId),
			};
		});
		setLeaveDetails(nUserData);
	}, [getLeaveBalance]);
	let height = window.innerHeight - 300;

	const extraColumns = () => {
		const uniqueColumns = new Set();
		const _leaveBalanceModel = leaveBalanceModel || [];
		_leaveBalanceModel.forEach((value) => {
			value.leaveTypeStagingModel.forEach((_value) => {
				uniqueColumns.add(_value.leaveTypeName);
			});
		});
		return [...uniqueColumns];
	};

	const column = [
		{
			key: 'employeeName',
			title: 'Name',
			width: '38%',
			dataIndex: 'employeeName',
			render: (employeeName) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ETypo b2 light color="#181919">
							{employeeName}
						</ETypo>
					</div>
				);
			},
		},

		{
			key: 'employeeId',
			title: 'Employee ID',
			dataIndex: 'employeeId',
			width: '38%',
			// align: 'left',
			render: (employeeId) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ETypo b2 light color="#181919">
							{employeeId}
						</ETypo>
					</div>
				);
			},
		},

		...extraColumns().map((data, index) => {
			return {
				key: data,
				title: data,
				dataIndex: `${data}-${index}`,
				width: '38%',
				// align: 'left',
				render: (_, rowData) => {
					const [value] = rowData?.leaveTypeStagingModel?.filter((_value) => _value.leaveTypeName === data);
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ETypo b2 light color="#181919">
								{value?.balance || '-'}
							</ETypo>
						</div>
					);
				},
			};
		}),
	];

	/////

	// useEffect(() => {
	// 	if (searchvalue?.length > 0) {
	// 		let a = leaveDetails?.filter((item) => item?.employeeName?.includes(searchvalue) || item?.employeeId?.includes(searchvalue));
	// 		setLeaveDetails(a);
	// 		if (pageNumber && pageSize) {
	// 			var start = (pageNumber - 1) * pageSize;
	// 			var end = start + pageSize;
	// 			console.log(start, end, leaveDetails?.length, 'sadsa');
	// 			let f = a?.slice(start, end);

	// 			// pageSize > setUpList.length ? setAddEmployeeList([]) : setAddEmployeeList(f);
	// 			setLeaveDetails(f);
	// 		}
	// 	} else {
	// 		let a = leaveDetails;

	// 		var start = (pageNumber - 1) * pageSize;
	// 		var end = start + pageSize;
	// 		let f = a?.slice(start, end);

	// 		// pageSize > setUpList.length ? setAddEmployeeList([]) : setAddEmployeeList(f);
	// 		setLeaveDetails(f);
	// 	}
	// }, [searchvalue, pageNumber, pageSize, userData]);

	const showModal = () => {
		setSubmitModal(true);
	};
	const closeModal = () => setSubmitModal(false);

	return (
		<>
			<Toast
				visible={isSubmitted}
				color="#8EF9B3"
				left="35%"
				// message={{ titleMessage: LEAVE_SUBMIT?.LEAVES_SUBMITTED, subMessage: '' }}
				clearPopUp={handelSuccessPopup}>
				{LEAVE_SUBMIT?.LEAVES_SUBMITTED}
			</Toast>
			{!shouldHideHeader && <SetupHeader />}
			{/* style={{ padding: '32px 240px 0px 240px', backgroundColor: '#F7F8FA' }} */}
			<Row className="bulk-employee-detail-container">
				<Col xl={24} md={24} lg={24}>
					<div className="bulk-employee-header-container">
						<ETypo h2 bold className="bulk-employee-page-title">
							You’re Updating The Following Leave Balances
						</ETypo>
						<div className="bulk-employee-button-container">
							<EdenredButtons
								leftIcon={<ImageComponent src={focus_state_icons.InviteCancelButton} />}
								btnType="secondary"
								onClick={() => history.goBack()}>
								Cancel
							</EdenredButtons>
							<EdenredButtons disabled={leaveDetails === 0} onClick={showModal} btnType="primary">
								Submit
							</EdenredButtons>
						</div>
					</div>
					<div className="bulk-employee-sub-header">
						{/* <ETypo b2 light color="#484B52">
							Click "submit" to proceed
						</ETypo> */}
					</div>
					<div className="bulk-employee-info-board">
						<div className="bulk-employee-search-and-show-count-container">
							<EdenredSearch
								value={searchvalue}
								placeholder="Search by Name, Employee ID..."
								onChange={(event) => setSearchValue(event.target.value)}
							/>
							<div className="bulk-employee-entries-select-container">
								<ETypo b2 light color="#484B52">
									Show
								</ETypo>
								<MultiSelect
									{...{
										Option: entriesDropDown,
										value: pageSize,
										dropDownValue: 'value',
										searchVisibility: false,
										onChange: (page) => setPageSize(page.value),
										checkboxIcon: (
											<span className="entries_check_box">
												<img src={icons.not_focus.tick} alt="check" />
											</span>
										),
									}}
								/>
								<ETypo b2 light color="#484B52">
									Entries
								</ETypo>
							</div>
						</div>

						<div className="bulk-employee-table-card-container">
							{1 < 0 ? (
								<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
									<DotLoader size={50} color={'#e91d24'} loading />
								</Col>
							) : (
								<Table data={leaveDetails} columns={column} />
							)}
						</div>

						<div className="bulk-employee-card-pagination-container">
							<ETypo b3 light color="#484B52">
								Showing {leaveDetails?.length} out of {leaveDetails?.length} Employees
							</ETypo>
							<Pagination
								{...{
									currentPage: pageNumber,
									totalCount: totalEmployeeCount,
									pageSize: pageSize,
									onPageChange: (page) => setPageNumber(page),
								}}
							/>
						</div>
						<Edenredmodal
							title={`'Click on “Submit” to confirm',`}
							onOkay={handleSetupLists}
							onCancelButton="Cancel"
							onOkayButton="Submit"
							onCancel={() => closeModal()}
							isOpen={submitModal}
							loading={isBalanceLoading}
							close={() => closeModal()}
						/>
					</div>
				</Col>
			</Row>

			{/* //////////////////////////////////////////////////////////////////// */}
			{/* {!isleaveApproveUpload && <SetupHeader />}
			<Popup
				visible={isSubmitted}
				message={{ titleMessage: LEAVE_SUBMIT?.LEAVES_SUBMITTED, subMessage: '' }}
				closePopup={handelSuccessPopup}></Popup>
			<Row className="multiple_employees_submit_container">
				<Row className="multiple_employees_submit_button_row">
					<Col xl={21} lg={23} md={23} sm={16} xs={16} className="multiple_employees_submit_title">
						<EdenredTypo>You’re updating the following leave balances.</EdenredTypo>
					</Col>
					<Col xl={3} lg={1} md={1} sm={4} xs={4} className="multiple_employees_submit_button" onClick={handleSetupLists}>
						<EdenredButton loading={isBalanceLoading}>Submit</EdenredButton>
					</Col>
				</Row>
				<Col xl={12} lg={12} md={12} sm={24} xs={24} className="multiple_employees_submit_hint">
					<EdenredTypo>Click “Submit” to proceed.</EdenredTypo>
				</Col>
				<Col
					xl={24}
					lg={24}
					md={24}
					sm={24}
					xs={24}
					style={{ maxHeight: '70vh', overFlow: 'auto' }}
					className="submit_multiple_employees_list_section">
					<Row className="multiple_employees_list_row">
						<Col xl={6} lg={6} md={6} sm={6} xs={6} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Name</EdenredTypo>
						</Col>
						<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Annual</EdenredTypo>
						</Col>
						<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Sick</EdenredTypo>
						</Col>
						<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Unpaid</EdenredTypo>
						</Col>
						<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Maternity</EdenredTypo>
						</Col>
						<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Parental</EdenredTypo>
						</Col>
						<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_title'}>
							<EdenredTypo>Bereavement</EdenredTypo>
						</Col>
					</Row>
					<Divider />
					<InfiniteScroll
						dataLength={leaveDetails?.length}
						next={handleInfiniteScroll}
						hasMore={true}
						height={`${height}px`}
						style={{ height: '450px', scrollBehavior: 'smooth', overflowY: 'auto', whiteSpace: 'nowrap' }}
						endMessage={
							<p style={{ textAlign: 'center' }}>
								<b>Yay! You have seen it all</b>
							</p>
						}>
						{leaveDetails?.map((list) => (
							<>
								<Row className="multiple_employees_list_row">
									<Col xl={6} lg={6} md={6} sm={6} xs={6} className={'multiple_employees_name_employee_id_column'}>
										<EdenredTypo>{list?.employeeName}</EdenredTypo>
									</Col>
									{list?.leaveTypeStagingModel?.map((data) => (
										<Col xl={3} lg={3} md={3} sm={3} xs={3} className={'multiple_employees_name_employee_id_column'}>
											<EdenredTypo>{data?.balance || '-'}</EdenredTypo>
										</Col>
									))}
								</Row>
								<Divider />
							</>
						))}
					</InfiniteScroll>
				</Col>
			</Row> */}
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		isBalanceLoading: selector.isBalanceLoading(state),
		isBalanceSubmitted: selector.isBalanceSubmitted(state),
		isGetBalanceLoading: selector.isGetBalanceLoading(state),
		getLeaveBalance: selector.getLeaveBalance(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		leaveBalanceSaga: (userName) => dispatch(action.creators.leaveBalanceStart(userName)),
		getLeaveBalanceSaga: (payload) => dispatch(action.creators.getLeaveBalanceStart(payload)),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(LeaveUploadTable);
const ReducedScreen = withReducer('leaveApprovalReducer', reducer, saga)(Connected);
export default ReducedScreen;
