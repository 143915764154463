import React from 'react'
import { useSelector } from 'react-redux';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import HrPermissionPresentational from './hrPermissionPersentaional'

const AuthorizedAccess=UnauthorizedAccess(HrPermissionPresentational)

const HrPermission = () => {
    let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
  return (
    <AuthorizedAccess {...{currentUser}}/>
  )
}

export default HrPermission