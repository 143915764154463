import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { isOnlySpecialCharacter } from '@helpers';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { images } from 'src/assets/img';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './saga/index';
import Actions from './action';
import './c3paynumber.scss';
import Toast from '@pages/DesignSystem/Toast';

const C3paynumber = () => {
	const dispatch = useDispatch();
	const [number, setnumber] = useState();
	const [isBankAccMatch, setIsBankAccMatch] = useState(false);
	const [banKToastMessage, setBanKToastMessage] = useState(false);
	const history = useHistory();
	const historyNew = useLocation();
	const bankAccountNumber = useSelector((state) => state?.appReducer?.bankName);
	const bankAccPopup = useSelector((state) => state?.appReducer?.isbankAccountPopUP);
	const bankAccSubmitLoading = useSelector((state) => state?.appReducer?.bankAccountLoader);
	const bankAccSubmitResponse = useSelector((state) => state?.appReducer?.bankAccount);
	useEffect(() => {
		dispatch(Actions.creators.getBankNameStart());
	}, []);

	const [routingState, setRoutingState] = useState({
		bankName: '',
		bankRoutingCode: '',
	});
	function handleChange(event) {
		let bankNumber = event;
		setnumber(bankNumber);
	}
	const handleDropDown = (data) => {
		setRoutingState({
			bankName: data?.bankName,
			bankRoutingCode: data?.bankRoutingCode,
		});
	};
	useEffect(() => {
		if (bankAccSubmitResponse) {
			dispatch(Actions.creators.BankAccountPopUp(false));
			setIsBankAccMatch(true);
		} else if (bankAccSubmitResponse !== '') {
			dispatch(Actions.creators.BankAccountPopUp(false));
			setBanKToastMessage(true);
			setTimeout(() => {
				setBanKToastMessage(false);
				history.push('/employees/manage_employee');
			}, 5000);
		}
	}, [bankAccSubmitResponse]);
	const handleBankAccPopup = (param) => {
		const payload = {
			employeeId: historyNew?.state?.employeeId,
			ibanNumber: number,
			bankRoutingCode: routingState?.bankRoutingCode,
			bankName: routingState?.bankName,
		};
		if (param === 'save') {
			dispatch(Actions.creators.postBankAccountStart(payload, historyNew));
		} else {
			dispatch(Actions.creators.BankAccountPopUp(false));
		}
	};

	return (
		<Row className="card-holder-number">
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="main-box">
				<Col className="c3pay_to_bank_header">
					<ETypo bold h2>
						Change C3Pay to Bank Account
					</ETypo>
					<div style={{ display: 'flex' }}>
						<EdenredButtons
							onClick={() =>
								history.push({
									pathname: '/employees/viewemployee',
									state: { tableData: { employeeId: historyNew?.state?.employeeId } },
								})
							}
							btnType="secondary"
							leftIcon={<img alt="left-arrow" src={images.leftsarrow} />}>
							Back
						</EdenredButtons>
						<EdenredButtons
							style={{ marginLeft: '10px' }}
							disabled={!number || !routingState?.bankName || !isOnlySpecialCharacter(number) || number?.length < 23}
							onClick={() => dispatch(Actions.creators.BankAccountPopUp(true))}>
							Submit
						</EdenredButtons>
					</div>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="cardholder-card">
					<ETypo medium h4>
						Bank Account Details
					</ETypo>
					<Col className="bank_details">
						<Col style={{ width: '100%' }}>
							<Inputbox
								{...{
									required: true,
									label: 'IBAN Number',
									placeholder: '23-Digit IBAN for employees with bank accounts',
									value: number,
									maxLength: 23,
									onChange: (event) => handleChange(event.target.value),
									isErrorMessage: number && (!isOnlySpecialCharacter(number) || number?.length < 23),
									errorMessage:
										number?.length < 23 ? 'IBAN number must be 23 digits' : 'Special Characters not allowed only alphanumeric',
								}}
							/>
						</Col>
						<Col style={{ width: '100%' }}>
							<MultiSelect
								{...{
									required: true,
									label: 'Bank Name',
									placeholder: 'Bank Name',
									value: routingState.bankName,
									Option: bankAccountNumber,
									onChange: handleDropDown,
									dropDownValue: 'bankName',
								}}
							/>
						</Col>
					</Col>
				</Col>
			</Col>
			<Edenredmodal
				{...{
					isOpen: bankAccPopup,
					onOkay: () => handleBankAccPopup('save'),
					onCancel: () => handleBankAccPopup('canel'),
					close: () => handleBankAccPopup('canel'),
					title: 'Confirm the change',
					textcenter: true,
					loading: bankAccSubmitLoading,
					desc: `${historyNew?.state?.employeeName || ''} Card will be deleted and they will be registered with a bank account`,
				}}
			/>
			{banKToastMessage && (
				<div className="bankAccountToast">
					<Toast size="100%" color="#8EF9B3">
						Your employee was changed to Bank Account
					</Toast>
				</div>
			)}
			<Edenredmodal
				{...{
					isOpen: isBankAccMatch,
					onOkay: () => setIsBankAccMatch(false),
					title: bankAccSubmitResponse,
					textcenter: true,
					onOkayButton: 'Ok',
				}}
			/>
		</Row>
	);
};
const ReducedScreen = withReducer('appReducer', reducer, saga)(C3paynumber);
export default ReducedScreen;
