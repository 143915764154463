import React, { useEffect } from 'react';
import { Card, Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import './add-new-signatory.scss';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';

const signatory = [
	{
		username: 'Adil Uzair',
	},
	{
		username: 'Adil Uzair',
	},
	{
		username: 'Adil Uzair',
	},
];
const AddNewSignatory = ({ history }) => {
	const { state } = useLocation();

	const handlesignatory = () => {
		let states = { ...state };
		console.log({ states });
		history.push({ pathname: '/company/upload-spreadsheet', state: states });
	};
	useEffect(() => {
		console.log({ state });
	}, []);
	return (
		<>
			<EdenredHeader />
			<Col className="add-new-signatory-container">
				<Row className="add-new-row">
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="signatory">
						<EdenredTypo>{(state && state.title) || 'Signatory & Owner'}</EdenredTypo>
					</Col>
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="card-owner">
						{state && state.signatory && state.signatory.length > 0 ? (
							state.signatory.map((user, index) => (
								<Card className="card-add-new-signatory">
									<Row className="add">
										<Col xl={18} lg={18} md={18} className="username">
											<EdenredTypo>{user.username}</EdenredTypo>
										</Col>
										{/* <Col xl={3} md={3} lg={3} className="button-delete">
										<EdenredButton type="outline-g">Delete</EdenredButton>
									</Col>
									<Col xl={3} md={3} lg={3} className="button-delete">
										<EdenredButton>Upload</EdenredButton>
									</Col> */}
									</Row>
								</Card>
							))
						) : (
							<Col className="no-data">
								<EdenredTypo>No Data Found</EdenredTypo>
							</Col>
						)}
					</Col>
					<Col className="owner-button" style={{ marginTop: '32px' }}>
						<EdenredButton onClick={handlesignatory}>Add New Signatory Or Owner</EdenredButton>
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default AddNewSignatory;
