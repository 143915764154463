import { createSelector } from 'reselect';
import { initState } from './reducer';

const counterReducer = (state) => state.appReducer || initState;

const bankAccount = createSelector(counterReducer, (cnter) => cnter.bankAccount);
const bankName = createSelector(counterReducer, (cnter) => cnter.bankName);
const isbankAccountPopUP = createSelector(counterReducer, (cnter) => cnter.isbankAccountPopUP);
const bankAccountLoader = createSelector(counterReducer, (cnter) => cnter.bankAccountLoader);
const bankRoute = createSelector(counterReducer, (cnter) => cnter.bankRoute);

export default {
	counterReducer,
	bankAccount,
	bankName,
	isbankAccountPopUP,
	bankAccountLoader,
	bankRoute,
};
