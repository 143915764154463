import React from 'react';
import { Col, Row } from 'antd';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ImageComponent from '@sharedComponent/image-component';
import Input from '@sharedComponent/text-field';
import './price-card.scss';

const PriceCard = ({ plan, month, cardPaid, cardtext, chips, imageText, onClickable }) => {
	return (
		<Col className="price-card">
			<div>
				<h1 className="price-Card-heading">{plan}</h1>
				<h1 className="price-Card-number">{month}</h1>
				<p className="price-Card-permonth">{cardPaid}</p>
				{chips && (
					<div className="price-chip-div">
						<p className="price-card-chip">{chips}</p>
					</div>
				)}
				<p className="price-Card-permonth">{cardtext}</p>
			</div>
			<p className={`${plan === 'Plus' ? 'price-what-included-new' : ''}  price-what-included`}>WHAT’S INCLUDED</p>
			{imageText.map((data) => (
				<div className="list-text">
					<ImageComponent className={data.flag === 'wrong' ? 'image-wrong' : ''} src={data.image} />
					<p className={data.flag}>{data.text}</p>
				</div>
			))}
			<div className="button-container" onClick={() => plan === 'Plus' && onClickable('plus')}>
				<button className={plan === 'Plus' ? 'upgrade-plan-active' : 'upgrade-plan'}>{plan === 'Plus' ? 'Upgrade' : 'Current Plan'}</button>
			</div>
		</Col>
	);
};

export default PriceCard;
