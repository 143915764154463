import Actions from './action';

export const initState = {
	uploadLoading: false,
	documentUpload: [],
	uploadError: '',
	submitLoading: false,
	submitUpload: [],
	submitError: '',
	templateLoading: false,
	templateDowload: [],
	templateError: '',
	filterLoading: false,
	isDocUploaded: 'loading',
	filterDetail: [],
	filterError: '',
	setupTemplateLoading: false,
	setupTemplateDowload: [],
	setupTemplateError: '',
};

const uploadReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SAVE_DOCUMENTUPLOAD_LOADING: {
			return {
				...state,
				uploadLoading: true,
				isDocUploaded: 'loading',
			};
		}
		case Actions.types.SAVE_DOCUMENTUPLOAD_SUCCESS: {
			let isErrorData = 'yes';
			if (action.response && action.response.validationMessage) {
				isErrorData = 'yes';
			} else {
				isErrorData = 'no';
			}
			return {
				...state,
				uploadLoading: false,
				documentUpload: action.response,
				isDocUploaded: isErrorData,
			};
		}
		case Actions.types.SAVE_DOCUMENTUPLOAD_RESET: {
			return {
				...state,
				uploadLoading: false,
				documentUpload: [],
				isDocUploaded: 'loading',
			};
		}
		case Actions.types.SAVE_DOCUMENTUPLOAD_FAIL: {
			return {
				...state,
				uploadLoading: false,
				uploadError: action.error,
				isDocUploaded: false,
			};
		}
		case Actions.types.SAVE_DOCUMENTSUBMIT_LOADING: {
			return {
				...state,
				submitLoading: true,
			};
		}
		case Actions.types.SAVE_DOCUMENTSUBMIT_SUCCESS: {
			return {
				...state,
				submitLoading: false,
				submitUpload: action.response,
			};
		}
		case Actions.types.SAVE_DOCUMENTSUBMIT_FAIL: {
			return {
				...state,
				submitLoading: false,
				submitError: action.error,
			};
		}
		case Actions.types.SAVE_TEMPLAT_LOADING: {
			return {
				...state,
				templateLoading: true,
			};
		}
		case Actions.types.SAVE_TEMPLATE_SUCCESS: {
			return {
				...state,
				templateLoading: false,
				templateDowload: action.response,
			};
		}
		case Actions.types.SAVE_TEMPLATE_FAIL: {
			return {
				...state,
				uploadLoading: false,
				templateError: action.error,
			};
		}
		case Actions.types.SAVE_FILTERDATA_START: {
			return {
				...state,
				filterLoading: true,
			};
		}
		case Actions.types.SAVE_FILTERDATA_SUCCESS: {
			return {
				...state,
				filterLoading: false,
				filterDetail: action.response,
			};
		}
		case Actions.types.SAVE_FILTERDATA_FAIL: {
			return {
				...state,
				filterLoading: false,
				filterError: action.error,
			};
		}
		case Actions.types.SETUP_TEMPLAT_LOADING: {
			return {
				...state,
				setupTemplateLoading: true,
			};
		}
		case Actions.types.SETUP_TEMPLATE_SUCCESS: {
			return {
				...state,
				setupTemplateLoading: false,
				setupTemplateDowload: action.response,
			};
		}
		case Actions.types.SETUP_TEMPLATE_FAIL: {
			return {
				...state,
				setupTemplateLoading: false,
				setupTemplateError: action.error,
			};
		}
		default:
			return state;
	}
};

export default uploadReducer;
