import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Modalcarousel from '@sharedComponent/modal-carousel';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES, popUpNotification } from '@constants/app-constants';
import { accountTypeConst } from '@constants/index';
import ETypo from '@pages/DesignSystem/Typo';
import SubscriptionPopup from '@sharedComponent/alert-notification/subscriptionPopup';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import UserService from 'src/services/user-service';
import PersonalInfo from './personal-info-presentational';
import Accountdetails from './account-details-presentational';
import './payroll-profile-presentational.scss';

function PayrollProfilePresentational({
	employeeDetails,
	forInfo,
	handleCancel,
	employeeDetailState,
	handleValueChanged,
	forDisableButton,
	updateWPSDetails,
	handleSave,
	onFinish,
	popupVisible,
	popupMessage,
	closePopUp,
	handleCancelPopUp,
	isShowLoader,
	handleSuccessPopUp,
	forEnable,
	accountType,
	bankOptions,
	bankName,
	nationalityData,
	nationalityName,
	payrollclaim,
	wpsForm,
	showEdit,
	mainProfile,
	conformEmailSaga,
	userNavigationMenu,
}) {
	const [popupNew, setPopupNew] = useState(false);
	const [subscription, setSubscription] = useState(false);
	const [nextSlide, setNextSlide] = useState(0);
	const MenuAPI = new UserService();
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	useEffect(() => {
		if (
			!isOnlyEmployeeExits &&
			!mainProfile &&
			navbarClaims &&
			(!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('profile')) &&
			isSubcribed
		) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);

	const handleClose = () => setPopupNew(false);

	const dataObj = [
		{
			image: not_focus_icons.Profile1,
			text: 'Upload your employees’ contracts and payroll details',
		},
		{
			image: not_focus_icons.Profile2,
			text: 'Run payroll details for your next month',
		},
		{
			image: not_focus_icons.Profile3,
			text: 'Edit your employees’ leave balances',
		},
		{
			image: not_focus_icons.Profile4,
			text: 'Update documents’ details and upload images',
		},
	];
	const handleSlideChange = (flag, status) => {
		let payload = {
			...navbarClaims,
			tourGuide: navbarClaims?.tourGuide ? `${navbarClaims?.tourGuide},${status}` : status,
		};
		if (nextSlide < dataObj?.length - 1) {
			setNextSlide((prev) => prev + 1);
		}
		if (flag == false) {
			MenuAPI.postUserNavigationMenu(payload)
				.then((res) => {
					userNavigationMenu();
					setPopupNew(false);
				})
				.catch((error) => {
					setPopupNew(false);
				});
		}
	};
	return (
		<>
			<Row className="personalInfoContainer" style={{ display: forInfo?.personalInfo || forInfo?.accountInfo ? 'none' : 'block' }}>
				<Col xl={24} lg={24} md={24}>
					<Row className="personalRow">
						<Col xl={12} lg={12} md={12}>
							<ETypo medium h4>
								Personal Info
							</ETypo>
						</Col>
					</Row>
					<Row>
						<Col xl={12} lg={12} md={24}>
							<Row>
								<Col xl={24} lg={24} md={24}>
									<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
										Nationality:
									</ETypo>
								</Col>
								<Col xl={24} lg={24} md={24} className="padding_right_16px">
									{showEdit?.length > 0 ? (
										<PersonalInfo
											{...{
												type: 'nationality',
												employeeDetails,
												nationalityData,
												employeeDetailState,
												handleValueChanged,
											}}
										/>
									) : (
										<ETypo medium b2 color="#181919">
											{nationalityName?.[0]?.countryName}
										</ETypo>
									)}
								</Col>
							</Row>
						</Col>
						<Col xl={12} lg={12} md={24}>
							<Row>
								<Col xl={24} lg={24} md={24}>
									<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
										Date of Birth:
									</ETypo>
								</Col>
								<Col xl={24} lg={24} md={24}>
									{showEdit?.length > 0 ? (
										<PersonalInfo
											{...{
												type: 'dob',
												employeeDetails,
												employeeDetailState,
												handleValueChanged,
											}}
										/>
									) : (
										<ETypo medium b2 color="#181919">
											{employeeDetails?.dateOfBirth}{' '}
										</ETypo>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{ marginTop: '16px' }}>
						<Col xl={12} lg={12} md={24}>
							<Row>
								<Col xl={24} lg={24} md={24}>
									<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
										Email Address:
									</ETypo>
								</Col>
								<Col xl={24} lg={24} md={24} className="padding_right_16px">
									{showEdit?.length > 0 ? (
										<PersonalInfo
											{...{
												type: 'email',
												employeeDetails,
												employeeDetailState,
												handleValueChanged,
												placeholder: 'Email',
											}}
										/>
									) : (
										<ETypo medium b2 color="#181919">
											{employeeDetails?.emailId}
										</ETypo>
									)}
								</Col>
							</Row>
						</Col>
						<Col xl={12} lg={12} md={24}>
							<Row>
								<Col xl={24} lg={24} md={24}>
									<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
										Mobile Number:
									</ETypo>
								</Col>
								<Col xl={24} lg={24} md={24}>
									{showEdit?.length > 0 ? (
										<PersonalInfo
											{...{
												type: 'mobile',
												employeeDetails,
												employeeDetailState,
												handleValueChanged,
												placeholder: 'Mobile Number',
											}}
										/>
									) : employeeDetails?.mobileNo !== null ? (
										<ETypo medium b2 color="#181919">
											`+9715${employeeDetails?.mobileNo}`{' '}
										</ETypo>
									) : (
										'-'
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			{forInfo?.personalInfo ? (
				<PersonalInfo
					{...{
						employeeDetails,
						handleCancel,
						employeeDetailState,
						handleValueChanged,
						forDisableButton,
						handleSave,
						onFinish,
						popupVisible,
						popupMessage,
						closePopUp,
						handleCancelPopUp,
						isShowLoader,
						handleSuccessPopUp,
						forEnable,
						nationalityData,
					}}
				/>
			) : null}
			{!mainProfile ? (
				payrollclaim?.length > 0 ? (
					<Row className="personalInfoContainer" style={{ display: forInfo?.accountInfo || forInfo?.personalInfo ? 'none' : 'block' }}>
						<Col xl={24} lg={24} md={24}>
							<Row className="personalRow">
								<Col xl={12} lg={12} md={12}>
									<ETypo medium h4>
										Account Details
									</ETypo>
								</Col>
							</Row>
							<Row>
								<Col xl={12} lg={12} md={24}>
									<Row>
										{accountType !== accountTypeConst?.C3pay ? (
											<>
												<Col xl={22} lg={22} md={22}>
													<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
														Bank Name:
													</ETypo>
												</Col>
												<Col xl={22} lg={22} md={22}>
													{showEdit?.length > 0 ? (
														<Accountdetails
															{...{
																type: 'bankName',
																employeeDetailState,
																employeeDetails,
																handleValueChanged,
																bankOptions,
																dropDownValue: 'bankName',
																compareDropDownValue: 'routingCode',
															}}
														/>
													) : (
														<ETypo medium b2 color="#181919">
															{' '}
															{bankName?.[0]?.bankName}
														</ETypo>
													)}
												</Col>
											</>
										) : (
											<>
												<Col xl={10} lg={12} md={11}>
													<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
														Card Serial Number:
													</ETypo>
												</Col>
												<Col xl={22} lg={22} md={22}>
													{showEdit?.length > 0 ? (
														<Accountdetails
															{...{
																type: 'cardSerialNumber',
																employeeDetailState,
																employeeDetails,
																handleValueChanged,
															}}
														/>
													) : (
														<ETypo medium b2 color="#181919">
															{employeeDetails?.cardSerialNumber}{' '}
														</ETypo>
													)}
												</Col>
											</>
										)}
									</Row>
								</Col>
								<Col xl={12} lg={12} md={24}>
									<Row>
										<Col xl={24} lg={24} md={24}>
											<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
												WPS Person ID:{' '}
											</ETypo>
										</Col>
										<Col xl={24} lg={24} md={24}>
											{showEdit?.length > 0 ? (
												<Accountdetails
													{...{ type: 'wpsPersonId', employeeDetailState, employeeDetails, handleValueChanged }}
												/>
											) : (
												<ETypo medium b2 color="#181919">
													{employeeDetails?.wpsPersonid}
												</ETypo>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row style={{ marginTop: '16px' }}>
								<Col xl={12} lg={12} md={24}>
									<Row>
										{accountType !== accountTypeConst?.C3pay ? (
											<>
												<Col xl={22} lg={22} md={22}>
													<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
														IBAN:
													</ETypo>
												</Col>
												<Col xl={22} lg={22} md={22}>
													{showEdit?.length > 0 ? (
														<Accountdetails
															{...{
																type: 'iban',
																employeeDetailState,
																employeeDetails,
																bankOptions,
																handleValueChanged,
															}}
														/>
													) : (
														<ETypo medium b2 color="#181919">
															{employeeDetails?.bankAccountNo}
														</ETypo>
													)}
												</Col>
											</>
										) : (
											<>
												<Col xl={24} lg={24} md={24}>
													<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
														First Card Issued:
													</ETypo>
												</Col>
												<Col xl={22} lg={22} md={22}>
													{showEdit?.length > 0 ? (
														<Accountdetails
															{...{ type: 'cardIssues', employeeDetailState, employeeDetails, handleValueChanged }}
														/>
													) : (
														<ETypo medium b2 color="#181919">
															{moment(employeeDetails?.rhfreceivedDate).format('DD/MM/YYYY')}
														</ETypo>
													)}
												</Col>
											</>
										)}
									</Row>
								</Col>
								<Col xl={12} lg={12} md={24}>
									<Row>
										<Col xl={24} lg={24} md={24}>
											<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
												WPS Establishment ID:
											</ETypo>
										</Col>
										<Col xl={24} lg={24} md={24}>
											{showEdit?.length > 0 ? (
												<Accountdetails
													{...{ type: 'wpsEstablishmentId', employeeDetailState, employeeDetails, handleValueChanged }}
												/>
											) : (
												<ETypo medium b2 color="#181919">
													{employeeDetails?.wpsEstablishmentId}
												</ETypo>
											)}
										</Col>
									</Row>
								</Col>
								{accountType !== accountTypeConst?.C3pay ? (
									<></>
								) : (
									<Col xl={12} lg={12} md={24}>
										<Row style={{ marginTop: '16px' }}>
											<Col xl={24} lg={24} md={24}>
												<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
													Account Number:
												</ETypo>
											</Col>
											<Col xl={22} lg={22} md={22}>
												{showEdit?.length > 0 ? (
													<Accountdetails
														{...{ type: 'accountNumber', employeeDetailState, employeeDetails, handleValueChanged }}
													/>
												) : (
													<ETypo medium b2 color="#181919">
														{employeeDetails?.accountNo}
													</ETypo>
												)}
											</Col>
										</Row>
									</Col>
								)}
								<Col xl={12} lg={12} md={24}>
									<Row style={{ marginTop: '16px', paddingRight: accountType !== accountTypeConst?.C3pay ? '32px' : '0px' }}>
										<Col xl={24} lg={24} md={24}>
											<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
												Labour Card Number:
											</ETypo>
										</Col>
										<Col xl={24} lg={24} md={24}>
											{showEdit?.length > 0 ? (
												<Accountdetails
													{...{ type: 'labourCardNumber', employeeDetailState, employeeDetails, handleValueChanged }}
												/>
											) : (
												<ETypo medium b2 color="#181919">
													{employeeDetails?.labourCardNo}
												</ETypo>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				) : (
					''
				)
			) : (
				<Row className="personalInfoContainer" style={{ display: forInfo?.accountInfo || forInfo?.personalInfo ? 'none' : 'block' }}>
					<Col xl={24} lg={24} md={24}>
						<Row className="personalRow">
							<Col xl={12} lg={12} md={12}>
								<p className="personalInfo">Account Details</p>
							</Col>
						</Row>
						<Row>
							<Col xl={12} lg={12} md={24}>
								<Row>
									{accountType !== accountTypeConst?.C3pay ? (
										<>
											<Col xl={24} lg={24} md={24}>
												<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
													Bank Name:
												</ETypo>
											</Col>
											<Col xl={22} lg={22} md={22}>
												{showEdit?.length > 0 ? (
													<Accountdetails
														{...{ type: 'bankName', employeeDetailState, employeeDetails, handleValueChanged }}
													/>
												) : (
													<ETypo medium b2 color="#181919">
														{' '}
														{bankName?.[0]?.bankName}
													</ETypo>
												)}
											</Col>
										</>
									) : (
										<>
											<Col xl={22} lg={22} md={22}>
												<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
													Card Serial Number:
												</ETypo>
											</Col>
											<Col xl={22} lg={22} md={22}>
												{showEdit?.length > 0 ? (
													<Accountdetails
														{...{ type: 'cardSerialNumber', employeeDetailState, employeeDetails, handleValueChanged }}
													/>
												) : (
													<ETypo medium b2 color="#181919">
														{employeeDetails?.cardSerialNumber}{' '}
													</ETypo>
												)}
											</Col>
										</>
									)}
								</Row>
							</Col>
							<Col xl={12} lg={12} md={24}>
								<Row>
									<Col xl={24} lg={24} md={24}>
										<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
											WPS Person ID:{' '}
										</ETypo>
									</Col>
									<Col xl={24} lg={24} md={24}>
										{showEdit?.length > 0 ? (
											<Accountdetails {...{ type: 'wpsPersonId', employeeDetailState, employeeDetails, handleValueChanged }} />
										) : (
											<ETypo medium b2 color="#181919">
												{employeeDetails?.wpsPersonid}
											</ETypo>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xl={12} lg={12} md={24}>
								<Row style={{ marginTop: '16px' }}>
									{accountType !== accountTypeConst?.C3pay ? (
										<>
											<Col xl={24} lg={24} md={24}>
												<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
													IBAN:
												</ETypo>
											</Col>
											<Col xl={24} lg={24} md={24}>
												{showEdit?.length > 0 ? (
													<Accountdetails {...{ type: 'iban', employeeDetailState, employeeDetails, handleValueChanged }} />
												) : (
													<ETypo medium b2 color="#181919">
														{employeeDetails?.bankAccountNo}{' '}
													</ETypo>
												)}
											</Col>
										</>
									) : (
										<>
											<Col xl={24} lg={24} md={24}>
												<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
													First Card Issued:
												</ETypo>
											</Col>
											<Col xl={22} lg={22} md={22}>
												{showEdit?.length > 0 ? (
													<Accountdetails
														{...{ type: 'cardIssues', employeeDetailState, employeeDetails, handleValueChanged }}
													/>
												) : (
													<ETypo medium b2 color="#181919">
														{' '}
														{!employeeDetails?.rhfreceivedDate
															? ''
															: moment(employeeDetails?.rhfreceivedDate).format('DD/MM/YYYY')}
													</ETypo>
												)}
											</Col>
										</>
									)}
								</Row>
							</Col>
							<Col xl={12} lg={12} md={24}>
								<Row style={{ marginTop: '16px' }}>
									<Col xl={24} lg={24} md={24}>
										<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
											{' '}
											WPS Establishment ID:
										</ETypo>
									</Col>
									<Col xl={24} lg={24} md={24}>
										{showEdit?.length > 0 ? (
											<Accountdetails
												{...{ type: 'wpsEstablishmentId', employeeDetailState, employeeDetails, handleValueChanged }}
											/>
										) : (
											<ETypo medium b2 color="#181919">
												{employeeDetails?.wpsEstablishmentId}
											</ETypo>
										)}
									</Col>
								</Row>
							</Col>
							{accountType !== accountTypeConst?.C3pay ? (
								<></>
							) : (
								<Col xl={12} lg={12} md={24}>
									<Row style={{ marginTop: '16px' }}>
										<Col xl={22} lg={22} md={22}>
											<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
												{' '}
												Account Number:
											</ETypo>
										</Col>
										<Col xl={22} lg={22} md={22}>
											{showEdit?.length > 0 ? (
												<Accountdetails
													{...{ type: 'accountNumber', employeeDetailState, employeeDetails, handleValueChanged }}
												/>
											) : (
												<ETypo medium b2 color="#181919">
													{employeeDetails?.accountNo}
												</ETypo>
											)}
										</Col>
									</Row>
								</Col>
							)}
							<Col xl={12} lg={12} md={24}>
								<Row style={{ marginTop: '16px' }}>
									<Col xl={24} lg={24} md={24}>
										<ETypo medium b4 color={showEdit?.length > 0 ? '#181919' : '#737780'}>
											{' '}
											Labour Card Number:
										</ETypo>
									</Col>
									<Col xl={24} lg={24} md={24}>
										{showEdit?.length > 0 ? (
											<Accountdetails
												{...{ type: 'labourCardNumber', employeeDetailState, employeeDetails, handleValueChanged }}
											/>
										) : (
											<ETypo medium b2 color="#181919">
												{employeeDetails?.labourCardNo}
											</ETypo>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
			{forInfo?.accountInfo ? (
				<Accountdetails
					{...{
						employeeDetails,
						handleCancel,
						employeeDetailState,
						handleValueChanged,
						forDisableButton,
						updateWPSDetails,
						handleSave,
						closePopUp,
						handleCancelPopUp,
						popupVisible,
						popupMessage,
						isShowLoader,
						handleSuccessPopUp,
						forEnable,
						accountType,
						bankOptions,
						bankName,
						wpsForm,
					}}
				/>
			) : null}
			{/* <Modalcarousel
				{...{
					visible: popupNew,
					handleClose,
					data: dataObj,
					handleSlideChange,
					initialSlide: nextSlide,
					status: 'profile',
				}}
			/> */}
			<SubscriptionPopup
				{...{
					visible: subscription,
					message: popUpNotification.saveMessage,
					subMessage: popUpNotification?.subSaveMessage,
					className: 'payroll-save-popup',
					okText: 'Know More',
					cancelText: 'Cancel',
					setSubscription,
				}}
			/>
		</>
	);
}

export default PayrollProfilePresentational;
