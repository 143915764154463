import React from 'react';
import PayrollProfileFunctional from './component/payroll-profile-functional';

function PayrollProfile({
	mainProfile,
	handleClick,
	openDeleteModal,
	employeeDetails,
	updateWPSDetails,
	getEmployeeById,
	accountType,
	bankOptions,
	claims,
	setShowLeave,
	showLeave,
	setNavigateTabCheck = () => {},
	navigateTabCheck,
	employeeDetailState,
	setEmployeeDetailState,
}) {
	return (
		<div>
			<PayrollProfileFunctional
				{...{
					handleClick,
					openDeleteModal,
					employeeDetails,
					updateWPSDetails,
					getEmployeeById,
					accountType,
					bankOptions,
					claims,
					mainProfile,
					setShowLeave,
					showLeave,
					setNavigateTabCheck,
					navigateTabCheck,
					employeeDetailState,
					setEmployeeDetailState,
				}}
			/>
		</div>
	);
}

export default PayrollProfile;
