import React, { useState } from 'react';
import EdenredDateform from '@sharedComponent/date-fileds';
import { checkHrSubscription, templateDownload } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import SalaryService from 'src/services/payroll-service';
import SALARY_REPORTS_CONSTANTS from '@pages/salary-reports/constants';

const SalaryPerEmployeePresentational = ({
	handleOptionChange,
	options = [],
	handleAllDepartment,
	toggleAllDepartment,
	isModalVisible,
	setIsModalVisible,
	handleCancel,
	selectedDepartment,
	handleModalCancel,
}) => {
	const title = 'Salary Report Month-wise (WPS/Non WPS)';

	const _SalaryService = new SalaryService();
	const [loading, setloading] = useState(false);
	const [reporttype, setreporttype] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const onsubmit = (body) => {
		setreporttype(body.reportType);
		setloading(true);
		if (isSubcribed && body?.salarymethod === 'salaryprocessed') {
			_SalaryService
				.salaryReportsSubscribe(body, 'GET', 'blob')
				.then(({ data }) => {
					if (data.type !== 'text/plain') {
						setloading(false);
						templateDownload(data, `${body.reportType === 'Excel' ? `SalaryReportMonthWise.xlsx` : `SalaryReportMonthWise.pdf`}`);
						setpopupVisible(true);
						setPopupMessage({
							titleMessage: `Report downloaded successfully`,
							subMessage: null,
						});
					} else {
						setloading(false);
						setpopupVisible(true);
						setPopupMessage({
							titleMessage: `There is no data for this Report..`,
							subMessage: null,
						});
					}
				})
				.catch((err) => {
					setloading(false);
					setloading(false);
				});
		} else
			_SalaryService
				.salaryReportsProcessed(body, 'GET', 'blob')
				.then(({ data }) => {
					if (data.type !== 'text/plain') {
						setloading(false);
						templateDownload(data, `${body.reportType === 'Excel' ? `SalaryReportMonthWise.xlsx` : `SalaryReportMonthWise.pdf`}`);
						setpopupVisible(true);
						setPopupMessage({
							titleMessage: `Report downloaded successfully`,
							subMessage: null,
						});
					} else {
						setloading(false);
						setpopupVisible(true);
						setPopupMessage({
							titleMessage: `There is no data for this Report..`,
							subMessage: null,
						});
					}
				})
				.catch((err) => {
					setloading(false);
					setloading(false);
				});
	};

	const closePopup = () => setpopupVisible(false);

	return (
		<>
			<Edenredmodal isOpen={popupVisible} title={popupMessage?.titleMessage} onOkayButton="Ok" onOkay={closePopup} />
			<EdenredDateform
				title={title}
				type={SALARY_REPORTS_CONSTANTS.salaryForm.type.monthWiseReports}
				{...{
					options,
					isSubcribed,
					handleOptionChange,
					reporttype,
					loading,
					onsubmit,
					handleAllDepartment,
					toggleAllDepartment,
					isModalVisible,
					setIsModalVisible,
					handleCancel,
					selectedDepartment,
					handleModalCancel,
				}}
			/>
		</>
	);
};
export default SalaryPerEmployeePresentational;
