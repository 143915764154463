import { put, takeEvery, call, select } from 'redux-saga/effects';
import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL, RUN_PAYROLL_DROPDOWN } from '@constants/app-constants';
import Actions from '../action';
import selector from '../selectors';
import PayrollService from 'src/services/payroll-service';
import AppAction from 'src/components/shared/app-action-reducer/action';

const _payrollService = new PayrollService();
const setIsSelectedValue = (options = []) => {
	return options?.map((option, index) => {
		return {
			...option,
			isSelected: false,
		};
	});
};

const formatDropdownValues = (data) => {
	return [
		{
			name: RUN_PAYROLL_DROPDOWN.PAYROLL_CHANNEL,
			isAllSelected: false,
			options: setIsSelectedValue(data?.payrollChannels) || [],
			key: 'payrollChannel',
		},
		{
			name: RUN_PAYROLL_DROPDOWN.ACCOUNT_TYPE,
			isAllSelected: false,
			options: setIsSelectedValue(data?.accountTypes) || [],
			key: 'accountType',
		},
		{
			name: RUN_PAYROLL_DROPDOWN.ESTABLISHMENT_ID,
			isAllSelected: false,
			options: setIsSelectedValue(data?.establishments) || [],
			key: 'establishment',
		},
		{
			name: RUN_PAYROLL_DROPDOWN.BANK_NAME,
			isAllSelected: false,
			options: setIsSelectedValue(data?.bankNames) || [],
			key: 'bankName',
		},
	];
};

const handleData = (id, names, res = [], key) => {
	const data = key.map((data) => {
		let nObj = {
			[id]: data?.id,
			[names]: data?.name,
			id: null,
			value: 0,
		};
		return nObj;
	});
	var result = data?.filter(function (o1) {
		return !res.some(function (o2) {
			return o1[id] === o2[id];
		});
	});

	return result;
};

export const getEmpTotalSalary = (rowData) => {

	function basicSalary() {
		const data = rowData?.employeeBasicSalary;
		if (data?.basicSalaryPaid) {
			return 0
		}
		return parseFloat(data?.value ?? 0)
	}
	function employeeAllowance() {
		return rowData?.employeeAllowances.reduce(function (accumulator, currentValue) {
			// If allowance paid returning 0
			if (currentValue?.allowancePaid) {
				return 0;
			}
			if (currentValue?.value === '') {
				return 0;
			}
			return parseFloat(accumulator) + parseFloat(currentValue?.value);
		}, 0)
	}
	function additions() {
		return rowData?.additions.reduce(function (accumulator, currentValue) {
			if (currentValue.value === '') {
				return 0;
			}
			return parseFloat(accumulator) + parseFloat(currentValue?.value);
		}, 0)
	}
	function deductions() {
		return parseFloat(rowData.overtimePayAmount ?? 0) -
			rowData?.deductions.reduce(function (accumulator, currentValue) {
				if (currentValue.value === '') {
					return 0;
				}
				return parseFloat(accumulator) + parseFloat(currentValue?.value);
			}, 0)
	}
	function gratuity() {
		return parseFloat(rowData?.gratuity?.value ?? 0)
	}

	const totalSalaryTemp = additions() + employeeAllowance() + basicSalary() + deductions() + gratuity();
	return parseFloat(totalSalaryTemp);
};

export function* getPayrollFilterValues() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/filter-lookup-data?status=ACTIVE`;
		const res = yield call(Axios.get, uri);
		const formattedData = yield formatDropdownValues(res.data);
		yield put(Actions.creators.getPayrollFilterDropdownDataSuccess(formattedData));
	} catch (err) {
		yield put(Actions.creators.getPayrollFilterDropdownDataError(err));
	}
}
export function* getDyanamicHeaders(params) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const allowance = `${DOMAIN_URL}/CorporateSetting/${corporateId}/allowance`;
		const additionType = `${DOMAIN_URL}/CorporateSetting/${corporateId}/additiontype`;
		const deductionType = `${DOMAIN_URL}/CorporateSetting/${corporateId}/deductiontype`;
		const allowanceRes = yield call(Axios.get, allowance);
		const Allowance = allowanceRes?.data;
		const additionTypeRes = yield call(Axios.get, additionType);
		const deductionTypeRes = yield call(Axios.get, deductionType);

		yield put(
			Actions.creators.getPayrollDynamicHeaderSuccess(additionTypeRes?.data || [], deductionTypeRes?.data || [], Allowance?.allowanceList || [])
		);
	} catch (err) {
		yield put(Actions.creators.getPayrollDynamicHeaderError(err));
	}
}
export function* getEmployeeData(params) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	const parameters = params.isAllSelected
		? {
			accountType: '',
			bankName: '',
			establishment: '',
			includeEmployeeCounts: false,
			month: params.res.month,
			pageNumber: params.res.pageNumber,
			pageSize: params.res.pageSize,
			payrollBlockCheck: true,
			payrollChannel: '',
			previousPayrollUploadId: '',
			previoustotalPaidEmps: null,
			search: params.res.search,
			status: 'ACTIVE',
			year: params.res.year,
		}
		: params.res;

	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/payrolldetail?status=${parameters.status}&pageNumber=${parameters.pageNumber}&pageSize=${parameters.pageSize}&accountType=${parameters.accountType}&bankName=${parameters.bankName}&establishment=${parameters.establishment}&payrollChannel=${parameters.payrollChannel}&previousPayrollUploadId=${parameters.previousPayrollUploadId}&search=${parameters.search}&month=${parameters.month}&year=${parameters.year}`;
		const res = yield call(Axios.get, uri);
		const data = res.data;

		let additionData = yield select(selector.additionData || []);
		let allowanceData = yield select(selector.allowanceData || []);
		let deductionData = yield select(selector.deductionData || []);

		const updatedData = data?.employees?.map((employee, index) => ({
			...employee,
			isSelected: false,
			gratuity: employee?.gratuity || { id: null, value: 0 },
			salary: getEmpTotalSalary(employee),
			totalSalary: getEmpTotalSalary(employee),
			employeeAllowances: employee?.employeeAllowances?.concat(
				handleData('allowanceTypeId', 'allowanceTypeName', employee?.employeeAllowances, allowanceData)
			),
			additions: employee?.additions?.concat(handleData('additionTypeId', 'additionTypeName', employee?.additions, additionData)),
			deductions: employee?.deductions?.concat(handleData('deductionTypeId', 'deductionTypeName', employee?.deductions, deductionData)),
		}));

		yield put(Actions.creators.getPayrollEmployeeTabledataSuccess(updatedData));
		yield put(Actions.creators.getPayrollEmployeeTableCountSuccess(data?.totalCount));
	} catch (err) {
		yield put(Actions.creators.getPayrollEmployeeTabledataError(err));
		yield put(Actions.creators.getPayrollEmployeeTableCountError(err));
	}
}
export function* getPayrollSalaryStatus(params) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/PayrollRun/${corporateId}/salarystatus`;
		const res = yield call(Axios.get, uri);
		const data = res.data;

		yield put(Actions.creators.getSalaryStatusSuccess(data.salaryTypeList));
	} catch (err) {
		yield put(Actions.creators.getSalaryStatusError(err));
	}
}

export function* bulkUpdateData(data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		const uri = `${DOMAIN_URL}/Payroll/${corporateId}/bulkupdate`;
		const res = yield call(Axios.post, uri, data.req);
		yield put(Actions.creators.bulkUpdatePayrollSuccess(res.data));
		// getEmployeeData(data.params);
		yield put(Actions.creators.popupTrigger(true));
	} catch (err) {
		yield put(Actions.creators.bulkUpdatePayrollError(err));
	}
}
export function* UpdateStaging(data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		// destructuring nested object
		const { sharedStates: { features } } = yield select(); // gives global state like store.getState()
		const uri = `${DOMAIN_URL}/Payroll/${corporateId}/update-staging?month=${data?.payload.month}&year=${data?.payload.year}`;
		const res = yield call(Axios.post, uri, data.req);
		// checking validations from backend if we have any payroll processing error for an employee
		const validations = [];
		if (res?.data?.length) {
			res.data.forEach((employee) => validations.push(...employee?.validations))
		}
		if (validations?.length) {
			yield put(Actions.creators.updateStagingSuccess(res.data));
			yield put(Actions.creators.salaryLimitExceeds(true));
		}
		else {
			yield put(Actions.creators.updateStagingSuccess());
			const salaryLimitExceeds = yield select(selector.salaryLimitExceeds);
			if (salaryLimitExceeds) {
				yield put(Actions.creators.salaryLimitExceeds(false));
			}
			yield put(Actions.creators.popupTrigger(true));
			yield put(Actions.creators.changeWpsNonWpsPopup(data?.payload.status === 'next' ? true : false));
		}
	} catch (err) {
		yield put(Actions.creators.updateStagingError(err));
	}
}
export function* stagingData(data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		// _payrollService.EmployeeSalaryStaging(data.req);
		const uri = `${DOMAIN_URL}/EmployeeSalaryStagings/${corporateId}`;
		const res = yield call(Axios.post, uri, data.req);
		yield put(Actions.creators.employeeSalaryStagingsSuccess(res.data));
		yield put(Actions.creators.changeWpsNonWpsPopup(true));
	} catch (err) {
		yield put(Actions.creators.employeeSalaryStagingsError(err));
	}
}
export function* postRakBankDetailsService(data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Corporates/${corporateId}/transfer-code/save`;
		const res = yield call(Axios.post, uri, data.payload);
		yield put(Actions.creators.postRakBankDetailsSuccess(res.data));
		yield put(AppAction.creators.getRakBankDetails());
		yield put(Actions.creators.showTransferFundPopup(false));
		yield put(Actions.creators.isRakBankAccountHolder(false));
		yield put(Actions.creators.postRakBankToast(true));
	} catch (err) {
		yield put(Actions.creators.postRakBankDetailsFail(err));
	}
}

export default function* expiryWatcher() {
	yield takeEvery(Actions.types.GET_PAYROLL_FILTER_DROPDOWN_DATA, getPayrollFilterValues);
	yield takeEvery(Actions.types.GET_PAYROLL_EMPLOYEE_TABLE_DATA, getEmployeeData);
	yield takeEvery(Actions.types.GET_SALARY_STATUS, getPayrollSalaryStatus);
	yield takeEvery(Actions.types.GET_PAYROLL_DYNAMIC_HEADER, getDyanamicHeaders);
	yield takeEvery(Actions.types.BULK_UPDATE_PAYROLL, bulkUpdateData);
	yield takeEvery(Actions.types.UPDATE_STAGING, UpdateStaging);
	yield takeEvery(Actions.types.EMPLOYEE_SALARY_STAGINGS, stagingData);
	yield takeEvery(Actions.types.POST_RAKBANK_DETAILS, postRakBankDetailsService);
}
