import React, { useState } from 'react';
import { Row, Col, Carousel } from 'antd';
import { images } from '@assets/img/index';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import ClevertapReact from 'src/utils/clever-tap';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import EdenredButtons from './../../../DesignSystem/button/EdenredButton';

const SliderImageText = ({ content }) => (
	<Row className="slider_container">
		<Col className="slider_view">
			<Row className="slider">
				<ImageComponent src={content.image} className="laptop_img" />
			</Row>
			<Row className="slider_head">
				<EdenredTypo bold>{content.title}</EdenredTypo>
			</Row>
			<Row className="slider">
				<EdenredTypo b3>{content.desc}</EdenredTypo>
			</Row>
		</Col>
	</Row>
);

const SliderPresentational = ({ contents, nextClicked, loading, setup }) => {
	const history = useHistory();
	const [activeIndex, SetActiveIndex] = useState(0);
	let carousel = useRef(null);
	const slider = useRef();

	function onChange(activeIndex) {
		SetActiveIndex(activeIndex);
	}

	const handleCarousel = () => {
		if (activeIndex === 0) {
			ClevertapReact.event('Onboard1', null);
		} else if (activeIndex === 1) {
			ClevertapReact.event('Onboard2', null);
		} else if (activeIndex === 2) {
			ClevertapReact.event('Onboard3', null);
		}
		if (activeIndex !== contents.length - 1) {
			ClevertapReact.event('Onboard4', null);
			carousel.next();
		} else {
			nextClicked();
		}
	};

	const handleCarouselOnPrevious = () => {
		carousel.prev();
	};

	console.log('ssddd', loading);
	return (
		<>
			<header className="slider-screen-header">
				<img src={not_focus_icons.Logo} alt="" />
			</header>
			<Row className="slider_screen_main_container">
				<img className="slider-background-image" src={not_focus_icons.sliderBackground} alt="" />
				<Col xs={24} sm={24} xl={8} md={10} className="carousel_view">
					<Col>
						<Carousel ref={(node) => (carousel = node)} afterChange={onChange}>
							{contents.map((content, i) => (
								<SliderImageText content={content} />
							))}
						</Carousel>
					</Col>
					<Col xl={24} md={24} lg={24} sm={12} xs={12} className="button_view">
						<EdenredButtons btnType="secondary" disabled={activeIndex === 0} onClick={() => handleCarouselOnPrevious()}>
							Previous
						</EdenredButtons>
						<EdenredButtons loading={loading} btnType="primary" onClick={() => handleCarousel()}>
							{activeIndex === contents.length - 1 ? 'Understood' : 'Next'}
						</EdenredButtons>
					</Col>
				</Col>
			</Row>
		</>
	);
};
export default SliderPresentational;
