import './gauge-chart.scss';

import { Col, Row } from 'antd';

import EdenredTypo from '@sharedComponent/typography';
import React from 'react';
import { arc } from 'd3-shape';
import { format } from 'd3-format';
import { scaleLinear } from 'd3-scale';
import { useHistory } from 'react-router-dom';

const GaugeChart = ({ value = 50, min = 0, max = 100, label, units, filledArcColor, title }) => {
	const backgroundArc = arc()
		.innerRadius(0.8)
		.outerRadius(1)
		.startAngle(-Math.PI / 2)
		.endAngle(Math.PI / 2)
		.cornerRadius(1)();

	const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
	const percent = percentScale(value);
	const history = useHistory();

	const angleScale = scaleLinear()
		.domain([0, 1])
		.range([-Math.PI / 2, Math.PI / 2])
		.clamp(true);
	const angle = angleScale(percent);

	// const colorScale = scaleLinear().domain([0, 1]).range(filledArcColor);
	// const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));

	const filledArc = arc()
		.innerRadius(0.8)
		.outerRadius(1)
		.startAngle(-Math.PI / 2)
		.endAngle(angle)
		.cornerRadius(1)();
	return (
		<Row className="gauge_chart_container">
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<svg width={'100%'} viewBox={[-1, -1, 2, 1].join(' ')} className="gauge_chart">
					{/* <defs>
						<linearGradient id="Gauge__gradient" gradientUnits="userSpaceOnUse" x1="-1" x2="1" y2="0">
							{gradientSteps.map((color, index) => (
								<stop key={color} stopColor={color} offset={`${index / (gradientSteps.length - 1)}`} />
							))}
						</linearGradient>
					</defs> */}
					<path d={backgroundArc} fill="#dfe1e6" />
					<path d={filledArc} fill={filledArcColor} />
				</svg>
			</Col>
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="percentage_text">
				<EdenredTypo>{Math.round(value)}%</EdenredTypo>
			</Col>
			<Col
				className="label_text"
				onClick={() => {
					history.push({
						pathname: '/employees/manage_employee',
						state: {
							type: title,
						},
					});
				}}>
				<EdenredTypo xl={24} lg={24} md={24} sm={24} xs={24} className="total-text">
					{label}
				</EdenredTypo>
			</Col>
			{!!title && (
				<Col
					xl={24}
					lg={24}
					md={24}
					sm={24}
					xs={24}
					className="gauge_chart_title"
					onClick={() => {
						history.push({
							pathname: '/employees/manage_employee',
							state: {
								type: title,
							},
						});
					}}>
					<EdenredTypo className="title-text">{title}</EdenredTypo>
				</Col>
			)}
		</Row>
	);
};
export default GaugeChart;
