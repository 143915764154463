import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import action from '../monthwise-action';
import Selectors from '../monthwise-selecter';
import SalaryMonthWisePresentational from './salary-monthWise-presentational';

const formatData = (data) => {
	return (data || []).filter((data) => data.name);
};

const SalarymonthwiseFunctional = ({ departmentDetails, monthwiseDepartmentSaga }) => {
	const [toggleAllDepartment, setToggleAllDepartment] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedDepartment, setSelectedDepartment] = useState([]);
	const [options, setOptions] = useState([
		{
			name: '',
			id: '',
			isSelected: false,
		},
	]);

	const handleAllDepartment = (value = false) => {
		setToggleAllDepartment(value);
		handleOptionChange(null, 'ALL', value);
	};
	const { departmentList } = departmentDetails;
	useEffect(() => {
		const deprt = departmentList?.map((element) => {
			return {
				name: element?.name,
				id: element?.id,
				isSelected: false,
			};
		});
		setOptions(deprt);
	}, [departmentDetails]);
	useEffect(() => {
		monthwiseDepartmentSaga();
	}, []);
	useEffect(() => {
		if (toggleAllDepartment) {
			const hasSelectedValues = options.filter((option) => option.isSelected)?.length === options.length;
			!hasSelectedValues && setToggleAllDepartment(false);
		}
	}, [options]);

	const handleOptionChange = (name, isAll = null, isSelected, edit) => {
		let lOptions = [];
		if (isAll) {
			lOptions = (options || [])?.map((option) => {
				return {
					...option,
					isSelected,
				};
			});
		} else {
			lOptions = (options || [])?.map((option) => {
				if (option?.name === name) {
					return {
						...option,
						isSelected: !option?.isSelected,
					};
				} else return option;
			});
		}
		setOptions([...lOptions]);
		if (edit === 'delete') {
			setSelectedDepartment([...lOptions]);
		}
	};
	const handleModalCancel = () => {
		if (selectedDepartment?.length !== 0) {
			setOptions([...selectedDepartment]);
		} else if (selectedDepartment?.length === 0) {
			const nArray = options?.map((data) => {
				return {
					...data,
					isSelected: false,
				};
			});
			setOptions([...nArray]);
		}
		setSelectedDepartment([...selectedDepartment]);
		setIsModalVisible(false);
	};
	const handleCancel = () => {
		setSelectedDepartment([...options]);
		setIsModalVisible(false);
	};
	return (
		<SalaryMonthWisePresentational
			{...{
				options,
				handleOptionChange,
				handleAllDepartment,
				toggleAllDepartment,
				isModalVisible,
				setIsModalVisible,
				handleCancel,
				selectedDepartment,
				handleModalCancel,
			}}
		/>
	);
};
const mapStateToProps = (state) => {
	return {
		isDepartmentLoading: Selectors.isDepartmentLoading(state),
		departmentDetails: Selectors.departmentDetails(state),
		departmentError: Selectors.departmentError(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		monthwiseDepartmentSaga: () => dispatch(action.creators.getMonthWiseDepartmentStart()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(SalarymonthwiseFunctional);
export default Connected;
