import { Col } from 'antd';
import EDragger from '@pages/DesignSystem/Dragger';
import ErrorTable from '@sharedComponent/error-table';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import React, { Fragment } from 'react';
import './upload-document.scss';
const UploadDocument = ({ uploadData, uploadingStatus, onChange, draggerStatus, onRemove }) => {
	const iconSrc = () => {
		if (draggerStatus === true) {
			//initial dragger has upload icon
			return focus_state_icons.hrUpload;
		} else if (uploadingStatus === true) {
			//when user upload the  file  loader status will show at the uploading.
			return focus_state_icons.loaderIcon;
		} else if (uploadData != '') {
			// if the file is incorrect or it have some error message it show error icon
			return focus_state_icons.NewDesignErro;
		} else {
			// if the file has no error it will upload success it show success icon
			return focus_state_icons?.checkBox;
		}
	};
	const border = () => {
		if (draggerStatus === true) {
			//initial dragger has upload border
			return 'new';
		} else if (uploadingStatus === true) {
			//when user upload the  file  loader status will show at the uploading.
			return 'uploading';
		} else if (uploadData != '') {
			// if the file is incorrect or it have some error message it show error border
			return 'error';
		} else {
			// if the file has no error it will upload success it show success border
			return 'done';
		}
	};
	return (
		<div>
			<EDragger
				idleText1={draggerStatus === true ? `Drag and drop or click here` : ''}
				idleText2={draggerStatus === true ? `to upload your employees’s payroll details` : ''}
				idleIcon={iconSrc()}
				onChange={(data) => draggerStatus === true && onChange(data)}
				onRemoveUpload={draggerStatus === false && onRemove}
				draggerStatus={border()}
			/>
			{uploadData && uploadData.length > 0 && (
				<section className="error-data-container">
					<div className="error-message-container">
						<img className="error-icon" src={focus_state_icons?.empUploadErrorMessage}></img>
						<p className="error-message">Please correct the following errors and upload the file again.</p>
					</div>
					<div className="error-list">
						<Fragment>
							<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card-error">
								<ErrorTable data={uploadData && uploadData} />
							</Col>
						</Fragment>
					</div>
				</section>
			)}
		</div>
	);
};

export default UploadDocument;
