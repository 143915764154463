import Actions from './action';
export const initState = {
	bankAccount: '',
	isbankAccountPopUP: false,
	bankAccountLoader: false,
	bankName: [],
	bankRoute: [],
};

const appReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.POST_BANK_ACCOUNT_START: {
			return {
				...state,
				bankAccountLoader: true,
			};
		}
		case Actions.types.POST_BANK_SUCCESS: {
			return {
				...state,
				bankAccount: action.response,
				bankAccountLoader: false,
			};
		}
		case Actions.types.POST_BANK_FAILED: {
			return {
				...state,
				bankAccount: action.response,
				bankAccountLoader: false,
			};
		}
		case Actions.types.GET_BANK_NAME_START: {
			return {
				...state,
			};
		}
		case Actions.types.GET_BANK_NAME_SUCCESS: {
			console.log('nnnnn', action.response);
			return {
				...state,
				bankName: action.response,
			};
		}
		case Actions.types.GET_BANK_NAME_FAILED: {
			return {
				...state,
			};
		}
		case Actions.types.BANK_ACCOUNT_POPUP: {
			return {
				...state,
				isbankAccountPopUP: action.flag,
			};
		}
		case Actions.types.BANK_ACCOUNT_ROUTE: {
			return {
				...state,
				bankRoute: action.flag,
			};
		}

		default:
			return state;
	}
};

export default appReducer;
