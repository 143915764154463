import { put, takeEvery, call } from 'redux-saga/effects';
import Axios from 'axios';
import Actions from '../action';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';

export function* clentStatusServices() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const request = {
		state: true,
	};
	try {
		const uri = `${DOMAIN_URL}/Corporates/${corporateId}/check-corporate-new-old-status`;
		const res = yield call(Axios.get, uri, request);
		yield put(Actions.creators.getClientStatusSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getClientStatusFail(err));
	}
}
export default function* SetupFinalsaga() {
	yield takeEvery(Actions.types.GET_CLIENT_STATUS_START, clentStatusServices);
}
