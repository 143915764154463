import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import action from './action';
import ClevertapReact from 'src/utils/clever-tap';
import ContentLoader from '@sharedComponent/content-loader';
import { SITEMAP } from 'src/routes/sitemap';
import { useQuery } from 'src/helpers/hooks';
import './index.scss';

function Employee() {
	const history = useHistory();
	const search = useQuery();

	const dispatch = useDispatch();

	const corporateId = useSelector((state) => state?.sharedStates?.corporateId);
	const corporateIds = useSelector((state) => state?.sharedStates?.corporateIds);
	const { unEmploymentBannerData } = useSelector((state) => state?.sharedStates);

	const unEmployeeCardDetails = useSelector((state) => state?.unemploymentReducer?.unEmploymentCardHolderData);
	const unEmployeeEidDetails = useSelector((state) => state?.unemploymentReducer?.unEmploymentEidData);
	const isUnEmploymentLoading = useSelector((state) => state?.unemploymentReducer?.isUnEmploymentLoading);

	function redirectUnEmploymentTabs() {
		const getTab = search.get('tab');
		if (getTab) {
			history.push(`${SITEMAP.unemploymentInsurance.singleUpdate}?tab=${getTab}`);
		} else {
			history.push(SITEMAP.unemploymentInsurance.singleUpdate);
		}
	}

	useEffect(() => {
		if (corporateId && typeof corporateId === 'string' && corporateIds && corporateIds.length > 0) {
			const [corporate] = corporateIds.filter((_corporate) => _corporate.corporateId === corporateId);
			if (corporate && unEmploymentBannerData?.data?.displayBanner === false) {
				redirectUnEmploymentTabs();
			}
		}
	}, [corporateId, corporateIds, unEmploymentBannerData]);

	useEffect(() => {
		dispatch(action.creators.getUnEmploymentDataStart());
	}, []);

	return (
		<div className="employee-wrapper">
			{isUnEmploymentLoading === true ? (
				<div style={{ width: '100%', height: '80vh', display: 'flex', alignItems: 'center' }}>
					<ContentLoader />
				</div>
			) : (
				<div>
					<section className="header">
						<h1>Mandatory Unemployment Insurance Subscription</h1>
						<EdenredButtons
							onClick={() => {
								ClevertapReact.event('Continue_MandatorySub', {
									Count: `${unEmployeeEidDetails?.total}`,
								});
								history.push(SITEMAP.unemploymentInsurance.plan);
							}}>
							Continue
						</EdenredButtons>
					</section>
					<section className="main">
						<div className="indication-card">
							<img src={not_focus_icons.warning_icn} alt="" />
							<p>Total C3Pay Cardholders: {unEmployeeCardDetails ? unEmployeeCardDetails?.chInsurancePolicyRequestedCount : 0}</p>
						</div>

						<ul>
							<li>
								All eligible employees will be subscribed to unemployment insurance whenever balance is available in their account.
							</li>
							<br />
							<li>Any new C3Pay Cardholder will automatically be subscribed for Unemployment Insurance.</li>
							<br />
							<li>We will notify your employees about their subscription.</li>
						</ul>
					</section>
				</div>
			)}
		</div>
	);
}

export default Employee;
