import Actions from './OverTimeAction';

export const initState = {
	overTimeSaveLoading: false,
	isOverTimeSaved: null,
	overTimePercentData: {},
	overTimePercentLoading: true,
	overTimeDataInfo: {},
	overTimeDataLoading: true,
	overTimeSalaryData: [],
	overTimeSalaryDataLoading: true,
	companySettingLoading: false,
	companySettingStatus: [],
	companySettingError: '',
	saveCompanySettingLoading: false,
	saveCompanySettingStatus: [],
	saveCompanySettingError: '',
	saveUploadLogoLoading: false,
	saveUploadLogoStatus: [],
	iscompanySaveSuccess: { flag: false, message: 'Saved Successfully' },
	iscompanySettingSaveSuccess: { flag: false, message: 'Saved Successfully' },
	overTimeLabelLoading: false,
	overTimeLabelData: [],
	overTimeLabelError: '',
	saveGetUploadLogoLoading: false,
	saveGetUploadLogoError: [],
	saveGetUploadLogoStatus: [],
	companySettingStatusList: [],
	defaultApproverLoading: false,
	defaultApproverDetail: [],
	defaultApproverFail: '',
	companyAddEditPopUp: false,
};

const companySettingReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SAVE_OVERTIME_DATA_START: {
			return {
				...state,
				overTimeSaveLoading: true,
			};
		}

		case Actions.types.SAVE_OVERTIME_DATA_SUCCESS: {
			return {
				...state,
				isOverTimeSaved: 'success',
				overTimeSaveLoading: false,
				iscompanySettingSaveSuccess: { flag: true, message: 'Saved Successfully' },
			};
		}
		case Actions.types.SAVE_OVERTIME_DATA_FAIL: {
			return {
				...state,
				isOverTimeSaved: 'error',
				overTimeSaveLoading: false,
			};
		}
		case Actions.types.GET_OVERTIME_POLICY_PERCENT_START: {
			return {
				...state,
				overTimePercentLoading: true,
			};
		}

		case Actions.types.GET_OVERTIME_POLICY_PERCENT_SUCCESS: {
			return {
				...state,
				overTimePercentData: action.response,
				overTimePercentLoading: false,
			};
		}
		case Actions.types.GET_OVERTIME_POLICY_PERCENT_FAIL: {
			return {
				...state,
				overTimePercentData: 'error',
				overTimePercentLoading: false,
			};
		}
		case Actions.types.GET_OVERTIME_DATA_START: {
			return {
				...state,
				overTimeDataLoading: true,
			};
		}

		case Actions.types.GET_OVERTIME_DATA_SUCCESS: {
			return {
				...state,
				overTimeDataInfo: action.response,
				overTimeDataLoading: false,
			};
		}
		case Actions.types.GET_OVERTIME_DATA_FAIL: {
			return {
				...state,
				overTimeDataInfo: 'error',
				overTimeDataLoading: false,
			};
		}
		case Actions.types.GET_OVERTIME_SALARY_START: {
			return {
				...state,
				overTimeSalaryDataLoading: true,
			};
		}

		case Actions.types.GET_OVERTIME_SALARY_SUCCESS: {
			return {
				...state,
				overTimeSalaryData: action.response,
				overTimeSalaryDataLoading: false,
			};
		}
		case Actions.types.GET_OVERTIME_SALARY_FAIL: {
			return {
				...state,
				overTimeSalaryData: 'error',
				overTimeSalaryDataLoading: false,
			};
		}
		case Actions.types.GET_COMPANYSETTING_START: {
			return {
				...state,
				companySettingLoading: true,
			};
		}
		case Actions.types.GET_COMPANYSETTING_SUCCESS: {
			return {
				...state,
				companySettingLoading: false,
				companySettingStatus: action?.response,
				companySettingStatusList: JSON.parse(JSON.stringify(action?.response)),
			};
		}
		case Actions.types.GET_COMPANYSETTING_FAIL: {
			return {
				...state,
				companySettingLoading: false,
				companySettingError: action?.error,
				companySettingStatus: [],
			};
		}
		case Actions.types.SAVE_COMPANYSETTING_START: {
			return {
				...state,
				saveCompanySettingLoading: true,
			};
		}
		case Actions.types.SAVE_COMPANYSETTING_SUCCESS: {
			return {
				...state,
				saveCompanySettingLoading: false,
				saveCompanySettingStatus: 'success',
				iscompanySaveSuccess: { flag: true, message: 'Saved Successfully' },
			};
		}
		case Actions.types.SAVE_SUCCESSFULL_DONE: {
			return {
				...state,
				iscompanySaveSuccess: { flag: false, message: 'Saved Unsuccessful' },
				iscompanySettingSaveSuccess: { flag: false, message: 'Saved Unsuccessful' },
			};
		}

		case Actions.types.SAVE_COMPANYSETTING_FAIL: {
			return {
				...state,
				saveCompanySettingLoading: false,
				saveCompanySettingError: action?.error,
				saveCompanySettingStatus: [],
			};
		}
		case Actions.types.SAVE_UPLOADLOGO_START: {
			return {
				...state,
				saveUploadLogoLoading: true,
			};
		}
		case Actions.types.SAVE_UPLOADLOGO_SUCCESS: {
			return {
				...state,
				saveUploadLogoLoading: false,
				saveUploadLogoStatus: action?.response,
			};
		}
		case Actions.types.SAVE_UPLOADLOGO_FAIL: {
			return {
				...state,
				saveUploadLogoLoading: false,
				saveUploadLogoError: action?.error,
				saveUploadLogoStatus: [],
			};
		}

		case Actions.types.GET_OVERTIME_LABEL_START: {
			return {
				...state,
				overTimeLabelLoading: true,
			};
		}
		case Actions.types.GET_OVERTIME_LABEL_SUCCESS: {
			return {
				...state,
				overTimeLabelLoading: false,
				overTimeLabelData: action?.response,
			};
		}
		case Actions.types.GET_OVERTIME_LABEL_FAIL: {
			return {
				...state,
				overTimeLabelLoading: false,
				overTimeLabelError: action?.error,
				overTimeLabelData: [],
			};
		}
		case Actions.types.GET_UPLOADLOGO_START: {
			return {
				...state,
				saveGetUploadLogoLoading: true,
			};
		}
		case Actions.types.GET_UPLOADLOGO_SUCCESS: {
			return {
				...state,
				saveGetUploadLogoLoading: false,
				saveGetUploadLogoStatus: action?.response,
			};
		}
		case Actions.types.GET_UPLOADLOGO_FAIL: {
			return {
				...state,
				saveGetUploadLogoLoading: false,
				saveGetUploadLogoError: action?.error,
				saveGetUploadLogoStatus: [],
			};
		}
		case Actions.types.GET_DEFAULT_APPROVER_START: {
			return {
				...state,
				defaultApproverLoading: true,
			};
		}
		case Actions.types.GET_DEFAULT_APPROVER_SUCCESS: {
			return {
				...state,
				defaultApproverLoading: false,
				defaultApproverDetail: action?.res,
			};
		}
		case Actions.types.GET_DEFAULT_APPROVER_FAIL: {
			return {
				...state,
				defaultApproverLoading: false,
				defaultApproverDetail: [],
			};
		}
		case Actions.types.COMPANY_ADD_EDIT_ORG: {
			return {
				...state,
				companyAddEditPopUp: action?.flag,
			};
		}
		default:
			return state;
	}
};

export default companySettingReducer;
