import React from 'react';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const EmployeesExcelDownload = ({ filename, dataSet, element, activeTabs }) => {
	// element props - with_custom_download_element eg: custom download button
	let nDataSet = [...dataSet];
	nDataSet &&
		nDataSet.map((dataSet) => {
			let data = dataSet;
			data.bankName = data.bankName ? data.bankName : '-';
			data.bankRoutingCode = data.bankRoutingCode ? data.bankRoutingCode : '-';
			data.rhfreceivedDate = data.rhfreceivedDate ? moment(new Date(data.rhfreceivedDate)).format('DD/MM/YYYY') : '';
			return data;
		});
	return (
		<ExcelFile filename={filename} element={element}>
			{/* <ExcelSheet data={dataSet} name={moment(new Date()).format('MM/DD/YYYY')}> */}
			<ExcelSheet data={dataSet} name={'employees'}>
				<ExcelColumn label="Name" value="employeeName" />
				<ExcelColumn label="Employee ID" value="employeeCode" />
				<ExcelColumn label="Account Number" value="accountNumber" />
				{/* <ExcelColumn label="Card Status" value="cardStatus" />
				<ExcelColumn label="EMIRATES ID Number" value="emiratesIdNumber" />
				<ExcelColumn label="EMIRATES ID Status" value="emiratesIdStatus" /> */}

				<ExcelColumn label="WPS Establishment ID" value="establishmentId" />
				<ExcelColumn label="Emirates ID Number" value="emiratesId" />
				<ExcelColumn label="Bank Routing Code" value="bankRoutingCode" />
				<ExcelColumn label="Card Created Date" value="rhfreceivedDate" />
				<ExcelColumn label="IBAN" value="bankAccountNo" />
				<ExcelColumn label="Bank Name" value="bankName" />
				<ExcelColumn label="WPS Person ID" value="wpsPersonid" />
				<ExcelColumn label="Passport Number" value="passportNumber" />
			</ExcelSheet>
		</ExcelFile>
	);
};
