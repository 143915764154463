import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { onlyNumberForNumberType } from '@helpers';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import EmployeeService from 'src/services/employee-service';
import RoutingService from 'src/services/routing-service';
import ClevertapReact from 'src/utils/clever-tap';
import { images } from 'src/assets/img';
import '../replacement-card.scss';
import { DATE_FORMAT } from '@constants/app-constants';

const TransactionDetail = ({ history }) => {
	const [transferModal, setTransferModal] = useState(false);
	let [bankOptions, setBankOptions] = useState([]);
	const [transactions, setTransactions] = useState({
		transactionDate: '',
		withDrawnAmount: '',
		amountReceived: '',
		bankName: '',
	});
	const [empId, setEmpId] = useState(null);
	let [maxDobDate, setMaxDobDate] = useState(null);

	let routingApi = new RoutingService();
	let employeeApi = new EmployeeService();

	useEffect(() => {
		setEmpId(history?.location?.state?.tableData?.employeeId);
		fetchRoutingDetails();
		setMaxDobDate(moment(new Date()).format('DD/MM/YYYY'));
	}, []);
	async function fetchRoutingDetails() {
		try {
			let response = await routingApi.getRouting();
			if (response && response?.data && response?.data?.routings) {
				let bankOptions = response?.data?.routings?.map((bank) => {
					bank.label = bank?.bankName;
					bank.value = bank?.bankName;
					return bank;
				});
				setBankOptions(bankOptions);
			}
		} catch (err) {}
	}
	const [, forceUpdate] = useState();
	const handleTransitionDetails = (data, param) => setTransactions({ ...transactions, [param]: data });

	const afterOpenModal = () => setTransferModal(false);

	const closeModal = async () => {
		setTransferModal(false);
		ClevertapReact.event('EnterDetails_ATMDispute', {
			Employee_id: history?.location?.state?.tableData?.employeeId || null,
			Employee_name: history?.location?.state?.tableData?.employeeName || null,
			Bank_name: history?.location?.state?.tableData?.accountType || null,
		});
		let payload = { ...transactions };
		payload.withDrawnAmount = parseFloat(payload?.withDrawnAmount);
		payload.amountReceived = parseFloat(payload?.amountReceived);
		payload.bankName = payload?.bankName;
		payload.transactionDate = moment(payload?.transactionDate, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD);
		try {
			let response = await employeeApi.updateDisputeDetails({ atmDispute: payload }, empId);
			if (response && response?.status === 200) {
				history.push('/employees/nextTransaction_step', { state: transactions?.bankName });
			}
		} catch (err) {}
	};

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = () => setTransferModal(true);

	return (
		<>
			<EdenredHeader />
			<Row className="transaction-detail-container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="transaction-detail-header">
					<ETypo bold h2>
						Enter The Transaction Details:
					</ETypo>
					<div>
						<EdenredButtons
							onClick={() =>
								history.push({
									pathname: '/employees/viewemployee',
									state: { ...history?.location?.state },
								})
							}
							btnType="secondary"
							leftIcon={<img src={images.leftsarrow} />}>
							Back
						</EdenredButtons>
					</div>
					<div>
						<EdenredButtons
							disabled={
								!transactions?.amountReceived ||
								transactions?.amountReceived === 0 ||
								!transactions?.bankName ||
								!transactions?.transactionDate ||
								!transactions?.withDrawnAmount ||
								transactions?.withDrawnAmount === 0
							}
							onClick={onFinish}>
							Next
						</EdenredButtons>
					</div>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="form-record">
					<ETypo medium h4>
						Enter the transaction details
					</ETypo>
					<Row className="transaction-details-input">
						<Col xl={12} md={24} lg={12} sm={24} xs={24} className="transaction-record1">
							<DatePickers
								label="Transaction Date"
								value={transactions?.transactionDate}
								placeholder="DD/MM/YYYY"
								maxDate={maxDobDate}
								required={true}
								onChange={(e, date) => handleTransitionDetails(date, 'transactionDate')}
							/>
						</Col>
						<Col xl={12} md={24} lg={12} sm={24} xs={24} className="transaction-record">
							<Inputbox
								type="number"
								value={transactions?.withDrawnAmount}
								label="Withdrawn Amount"
								placeholder="Withdrawn Amount"
								labelClassName="inputlabelrequired"
								onChange={(e) => handleTransitionDetails(parseFloat(e?.target?.value), 'withDrawnAmount')}
								onKeyDown={(e) => onlyNumberForNumberType(e)}
								required={true}
								isErrorMessage={transactions?.withDrawnAmount === 0}
								errorMessage="Must be < Disputed Amount"
								prefix="AED"
							/>
						</Col>
						<Col xl={12} md={24} lg={12} sm={24} xs={24} className="transaction-record1 margin-top-16">
							<Inputbox
								type="number"
								value={transactions?.amountReceived}
								label="Disputed Amount"
								placeholder="Disputed Amount"
								labelClassName="inputlabelrequired"
								onChange={(e) => handleTransitionDetails(parseFloat(e?.target?.value), 'amountReceived')}
								onKeyDown={(e) => onlyNumberForNumberType(e)}
								required={true}
								isErrorMessage={transactions?.amountReceived === 0}
								errorMessage="Please provide the Disputed Amount"
								prefix="AED"
							/>
						</Col>
						<Col xl={12} md={24} lg={12} sm={24} xs={24} className="transaction-record margin-top-16">
							{/* <Form.Item name="bankName" rules={[{ required: true, message: 'Please provide the Bank name' }]}> */}
							<MultiSelect
								placeholder="Bank Name"
								Option={bankOptions}
								value={transactions?.bankName}
								onChange={(e) => handleTransitionDetails(e?.bankName, 'bankName')}
								label="Bank Name"
								dropDownValue="label"
								searchVisibility={false}
								required={true}
							/>
						</Col>
					</Row>
					<Edenredmodal
						isOpen={transferModal}
						onCancel={afterOpenModal}
						onOkay={closeModal}
						title="Confirm the transaction"
						desc="I confirm that all the filled details are accurate
					and give my consent to initiate due legal process."
						textcenter={true}
					/>
				</Col>
			</Row>
		</>
	);
};

export default TransactionDetail;
