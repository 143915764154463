import React from 'react';
import reducer from './reducer';
import saga from './saga/index';
import { withReducer } from 'src/store/reducerLoader';
import AddIndividualContractFunctional from './component/add-individual-contract-functional';

const AddIndividualContractMain = () => {
	return <AddIndividualContractFunctional />;
};

const ReducedScreen = withReducer('contractReducer', reducer, saga)(AddIndividualContractMain);
export default ReducedScreen;
