import React, { useRef, Fragment } from 'react';
import IdleTimer from 'react-idle-timer';
import { APP_VARIABLES } from '@constants/app-constants';
import userManager from 'src/config/user-manager';

const IdleComponent = () => {
	const MILLI_SECONDS = 60000;
	const MINUTE = 10;
	const idleTimerRef = useRef(null);
	const onIdle = () => {
		console.log('user is idle');
		localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
		localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
		localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
		localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
		localStorage.removeItem('amount');
		localStorage.removeItem('transferFundsPopupVisible');
		localStorage.removeItem('showUnemploymentBanner');
		userManager.signoutRedirect();
		userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
	};
	return (
		<Fragment>
			<IdleTimer ref={idleTimerRef} timeout={MINUTE * MILLI_SECONDS} onIdle={onIdle} />
		</Fragment>
	);
};

export default IdleComponent;
