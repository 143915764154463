import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './approval-reducer';
import saga from './saga/index';
import ApprovalWorkflowFunctional from './component/approval-workflow-functional';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess = UnauthorizedAccess(ApprovalWorkflowFunctional);

function ApprovalWorkflow({ isSetup }) {
	let currentClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims);
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.roles?.[0]?.name);
	return <AuthorizedAccess {...{ currentUser, currentClaims, UserClaims: 'Approval.Workflow', isSetup }} />;
}
const ReducedScreen = withReducer('WorkflowReducer', reducer, saga)(ApprovalWorkflow);
export default ReducedScreen;
