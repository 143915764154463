const EMPLOYEE_VERIFICATION = 'EMPLOYEE_VERIFICATION'
const ADD_EMPLOYEE_VERIFICATION = 'ADD_EMPLOYEE_VERIFICATION'
const DOWNLOAD_INVALID_EMPLOYEES = 'DOWNLOAD_INVALID_EMPLOYEES'
const UPLOAD_INVALID_EMPLOYEES = 'UPLOAD_INVALID_EMPLOYEES'
const ADD_EMPLOYEES_LIST = 'ADD_EMPLOYEES_LIST'
const ADD_EMPLOYEES_ERROR_LIST = 'ADD_EMPLOYEES_ERROR_LIST'
const UPLOAD_EMPLOYEES_LOADING = 'UPLOAD_EMPLOYEES_LOADING'
const CLEAR_EMPLOYEES_DATA = 'CLEAR_EMPLOYEES_DATA'

const employeeVerify = (payload) => ({
    type: EMPLOYEE_VERIFICATION,
    payload,
});

const addemployeeVerify = (payload) => ({
    type: ADD_EMPLOYEE_VERIFICATION,
    payload,
});
const downloadInvalidEmployees = (payload) => ({
    type: DOWNLOAD_INVALID_EMPLOYEES,
    payload,
});
const uploadInvalidEmployees = (payload) => ({
    type: UPLOAD_INVALID_EMPLOYEES,
    payload,
});
const addEmployeeList = (payload) => ({
    type: ADD_EMPLOYEES_LIST,
    payload,
});
const addEmployeeErrorList = (payload) => ({
    type: ADD_EMPLOYEES_ERROR_LIST,
    payload,
});
const uploadEmployeesLoading = (payload) => ({
    type: UPLOAD_EMPLOYEES_LOADING,
    payload,
});
const clearEmployeeData = () => ({
    type: CLEAR_EMPLOYEES_DATA,
});

export default {
    types: {
        EMPLOYEE_VERIFICATION,
        ADD_EMPLOYEE_VERIFICATION,
        DOWNLOAD_INVALID_EMPLOYEES,
        UPLOAD_INVALID_EMPLOYEES,
        ADD_EMPLOYEES_LIST,
        ADD_EMPLOYEES_ERROR_LIST,
        UPLOAD_EMPLOYEES_LOADING,
        CLEAR_EMPLOYEES_DATA,
    },
    creators: {
        employeeVerify,
        addemployeeVerify,
        downloadInvalidEmployees,
        uploadInvalidEmployees,
        addEmployeeList,
        addEmployeeErrorList,
        uploadEmployeesLoading,
        clearEmployeeData

    }
}