import actions from "./employeeVerifyActions";

const initialState = {
    verification: null,
    data: {
        loading: false,
        employeeList: null,
        errorData: null
    }
}

const employeeVerificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.types.ADD_EMPLOYEE_VERIFICATION:
            return {
                ...state,
                verification: {
                    ...action.payload
                }
            }
        case actions.types.ADD_EMPLOYEES_LIST:
            return {
                ...state,
                data: {
                    ...state.data,
                    employeeList: action.payload
                }
            }
        case actions.types.ADD_EMPLOYEES_ERROR_LIST:
            return {
                ...state,
                data: {
                    ...state.data,
                    errorData: action.payload
                }
            }
        case actions.types.UPLOAD_EMPLOYEES_LOADING:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: action.payload
                }
            }
        case actions.types.CLEAR_EMPLOYEES_DATA:
            return {
                ...state,
                data: initialState.data
            }
        default:
            return state;
    }

}

export default employeeVerificationReducer