const GET_UNEMPLOYMENT_START = 'GET_UNEMPLOYMENT_START';
const GET_UNEMPLOYMENT_CARD_SUCCESS = 'GET_UNEMPLOYMENT_CARD_SUCCESS';
const GET_UNEMPLOYMENT_EID_SUCCESS = 'GET_UNEMPLOYMENT_EID_SUCCESS';
const GET_UNEMPLOYMENT_FAIL = 'GET_UNEMPLOYMENT_FAIL';
const PUT_UNEMPLOYMENT_EMPLOYEE_LOADING = 'PUT_UNEMPLOYMENT_EMPLOYEE_LOADING';
const PUT_UNEMPLOYMENT_EMPLOYEE_DATA = 'PUT_UNEMPLOYMENT_EMPLOYEE_DATA';
const PUT_UNEMPLOYMENT_EMPLOYEE_DATA_SUCCESS = 'PUT_UNEMPLOYMENT_EMPLOYEE_DATA_SUCCESS';
const PUT_UNEMPLOYMENT_EMPLOYEE_DATA_FAIL = 'PUT_UNEMPLOYMENT_EMPLOYEE_DATA_FAIL';

const GET_MISSING_EMPLOYEE_LIST = 'GET_MISSING_EMPLOYEE_LIST';
const GET_MISSING_EMPLOYEE_LIST_SUCCESS = 'GET_MISSING_EMPLOYEE_LIST_SUCCESS';
const GET_MISSING_EMPLOYEE_LIST_FAIL = 'GET_MISSING_EMPLOYEE_LIST_FAIL';

const UPDATE_MISSING_EMPLOYEE_LIST = 'UPDATE_MISSING_EMPLOYEE_LIST';
const UPDATE_MISSING_EMPLOYEE_LIST_SUCCESS = 'UPDATE_MISSING_EMPLOYEE_LIST_SUCCESS';
const UPDATE_MISSING_EMPLOYEE_LIST_FAIL = 'UPDATE_MISSING_EMPLOYEE_LIST_FAIL';

const MISSING_EMP_TEMPLATE_DOWNLOAD = 'MISSING_EMP_TEMPLATE_DOWNLOAD';
const MISSING_EMP_TEMPLATE_DOWNLOAD_SUCCESS = 'MISSING_EMP_TEMPLATE_DOWNLOAD_SUCCESS';
const MISSING_EMP_TEMPLATE_DOWNLOAD_FAIL = 'MISSING_EMP_TEMPLATE_DOWNLOAD_FAIL';

const DOWNLOAD_DETAILED_REPORT_START = 'DOWNLOAD_DETAILED_REPORT_START';
const DOWNLOAD_DETAILED_REPORT_SUCCESS = 'DOWNLOAD_DETAILED_REPORT_SUCCESS';
const DOWNLOAD_DETAILED_REPORT_FAIL = 'DOWNLOAD_DETAILED_REPORT_FAIL';

const GET_UNEMPLOYMENT_ALL_COUNT = 'GET_UNEMPLOYMENT_ALL_COUNT';
const GET_UNEMPLOYMENT_ALL_COUNT_SUCCESS = 'GET_UNEMPLOYMENT_ALL_COUNT_SUCCESS';
const GET_UNEMPLOYMENT_ALL_COUNT_FAIL = 'GET_UNEMPLOYMENT_ALL_COUNT_FAIL';

const GET_UN_EMPLOYMENT_LIST_WITH_STATUS = 'GET_UN_EMPLOYMENT_LIST_WITH_STATUS';
const GET_UN_EMPLOYMENT_LIST_WITH_STATUS_SUCCESS = 'GET_UN_EMPLOYMENT_LIST_WITH_STATUS_SUCCESS';
const GET_UN_EMPLOYMENT_LIST_WITH_STATUS_FAIL = 'GET_UN_EMPLOYMENT_LIST_WITH_STATUS_FAIL';

const SET_SUCCESS_TOAST_FLAG = 'SET_SUCCESS_TOAST_FLAG';

const getUnEmploymentDataStart = () => ({
	type: GET_UNEMPLOYMENT_START,
});
const getUnEmploymentCardHolderSuccess = (res) => ({
	type: GET_UNEMPLOYMENT_CARD_SUCCESS,
	res,
});
const getUnEmploymentEidDataSuccess = (res) => ({
	type: GET_UNEMPLOYMENT_EID_SUCCESS,
	res,
});

const getMissingEmployeeList = (pageSize, pageNumber, searchedText) => ({
	type: GET_MISSING_EMPLOYEE_LIST,
	pageSize,
	pageNumber,
	searchedText,
});
const getMissingEmployeeListSuccess = (res) => ({
	type: GET_MISSING_EMPLOYEE_LIST_SUCCESS,
	res,
});
const getMissingEmployeeListFail = (res) => ({
	type: GET_MISSING_EMPLOYEE_LIST_FAIL,
	res,
});

const getUnEmploymentListWithStatus = (pageSize, pageNumber, searchedText, status) => ({
	type: GET_UN_EMPLOYMENT_LIST_WITH_STATUS,
	pageSize,
	pageNumber,
	searchedText,
	status,
});
const getUnEmploymentListWithStatusSuccess = (res, status) => ({
	type: GET_UN_EMPLOYMENT_LIST_WITH_STATUS_SUCCESS,
	res,
	status,
});
const getUnEmploymentListWithStatusFail = (res) => ({
	type: GET_UN_EMPLOYMENT_LIST_WITH_STATUS_FAIL,
	res,
});

const missingEmpTemplateDownload = () => ({
	type: MISSING_EMP_TEMPLATE_DOWNLOAD,
});
const missingEmpTemplateDownloadSuccess = (res) => ({
	type: MISSING_EMP_TEMPLATE_DOWNLOAD_SUCCESS,
	res,
});
const missingEmpTemplateDownloadFail = (res) => ({
	type: MISSING_EMP_TEMPLATE_DOWNLOAD_FAIL,
	res,
});

const downloadDetailedReportStart = (status) => ({
	type: DOWNLOAD_DETAILED_REPORT_START,
	status,
});
const downloadDetailedReportSuccess = (res) => ({
	type: DOWNLOAD_DETAILED_REPORT_SUCCESS,
	res,
});
const downloadDetailedReportFail = (res) => ({
	type: DOWNLOAD_DETAILED_REPORT_FAIL,
	res,
});

const getUnemploymentAllCount = () => ({
	type: GET_UNEMPLOYMENT_ALL_COUNT,
});
const getUnemploymentAllCountSuccess = (res) => ({
	type: GET_UNEMPLOYMENT_ALL_COUNT_SUCCESS,
	res,
});
const getUnemploymentAllCountFail = (res) => ({
	type: GET_UNEMPLOYMENT_ALL_COUNT_FAIL,
	res,
});

const updateMissingEmployeeList = (payload) => ({
	type: UPDATE_MISSING_EMPLOYEE_LIST,
	payload,
});
const updateMissingEmployeeListSuccess = (res) => ({
	type: UPDATE_MISSING_EMPLOYEE_LIST_SUCCESS,
	res,
});
const updateMissingEmployeeListFail = (res) => ({
	type: UPDATE_MISSING_EMPLOYEE_LIST_FAIL,
	res,
});

const getUnEmploymentDataFail = (err) => ({
	type: GET_UNEMPLOYMENT_FAIL,
	err,
});
const putUnEmploymentEmployeeData = (history, plan) => ({
	type: PUT_UNEMPLOYMENT_EMPLOYEE_DATA,
	extras: {
		history: history,
		plan,
	},
});

const putUnEmploymentEmployeeDataSuccess = (data) => ({
	type: PUT_UNEMPLOYMENT_EMPLOYEE_DATA_SUCCESS,
	payload: { ...data },
});

const putUnEmploymentEmployeeDataFail = (error) => ({
	type: PUT_UNEMPLOYMENT_EMPLOYEE_DATA_FAIL,
	payload: error,
});

const setSuccessToastFlag = (flag) => ({
	type: SET_SUCCESS_TOAST_FLAG,
	flag,
});

export default {
	types: {
		GET_UNEMPLOYMENT_START: GET_UNEMPLOYMENT_START,
		GET_UNEMPLOYMENT_CARD_SUCCESS: GET_UNEMPLOYMENT_CARD_SUCCESS,
		GET_UNEMPLOYMENT_EID_SUCCESS: GET_UNEMPLOYMENT_EID_SUCCESS,
		GET_UNEMPLOYMENT_FAIL: GET_UNEMPLOYMENT_FAIL,
		PUT_UNEMPLOYMENT_EMPLOYEE_DATA,
		PUT_UNEMPLOYMENT_EMPLOYEE_DATA_SUCCESS,
		PUT_UNEMPLOYMENT_EMPLOYEE_DATA_FAIL,

		GET_MISSING_EMPLOYEE_LIST,
		GET_MISSING_EMPLOYEE_LIST_SUCCESS,
		GET_MISSING_EMPLOYEE_LIST_FAIL,

		UPDATE_MISSING_EMPLOYEE_LIST,
		UPDATE_MISSING_EMPLOYEE_LIST_SUCCESS,
		UPDATE_MISSING_EMPLOYEE_LIST_FAIL,

		MISSING_EMP_TEMPLATE_DOWNLOAD,
		MISSING_EMP_TEMPLATE_DOWNLOAD_SUCCESS,
		MISSING_EMP_TEMPLATE_DOWNLOAD_FAIL,

		GET_UNEMPLOYMENT_ALL_COUNT,
		GET_UNEMPLOYMENT_ALL_COUNT_SUCCESS,
		GET_UNEMPLOYMENT_ALL_COUNT_FAIL,

		GET_UN_EMPLOYMENT_LIST_WITH_STATUS,
		GET_UN_EMPLOYMENT_LIST_WITH_STATUS_SUCCESS,
		GET_UN_EMPLOYMENT_LIST_WITH_STATUS_FAIL,

		DOWNLOAD_DETAILED_REPORT_START,
		DOWNLOAD_DETAILED_REPORT_SUCCESS,
		DOWNLOAD_DETAILED_REPORT_FAIL,

		SET_SUCCESS_TOAST_FLAG,
		PUT_UNEMPLOYMENT_EMPLOYEE_LOADING
	},
	creators: {
		getUnEmploymentDataStart,
		getUnEmploymentCardHolderSuccess,
		getUnEmploymentEidDataSuccess,
		getUnEmploymentDataFail,
		putUnEmploymentEmployeeData,
		putUnEmploymentEmployeeDataSuccess,
		putUnEmploymentEmployeeDataFail,

		getMissingEmployeeList,
		getMissingEmployeeListSuccess,
		getMissingEmployeeListFail,

		updateMissingEmployeeList,
		updateMissingEmployeeListSuccess,
		updateMissingEmployeeListFail,

		missingEmpTemplateDownload,
		missingEmpTemplateDownloadSuccess,
		missingEmpTemplateDownloadFail,

		downloadDetailedReportStart,
		downloadDetailedReportSuccess,
		downloadDetailedReportFail,

		getUnemploymentAllCount,
		getUnemploymentAllCountSuccess,
		getUnemploymentAllCountFail,

		getUnEmploymentListWithStatus,
		getUnEmploymentListWithStatusSuccess,
		getUnEmploymentListWithStatusFail,

		setSuccessToastFlag,
	},
};
