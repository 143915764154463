import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import EdenredDropdown from '@sharedComponent/dropdown/dropdown';
import RoutingService from 'src/services/routing-service';
import EmployeeService from 'src/services/employee-service';
import EdenredBankDropdown from '@sharedComponent/dropdown/ibm-bank-dropdown';

const AddEmployeeBankName = () => {
	const { state } = useLocation();
	const history = useHistory();
	let [bankId, setBankId] = useState();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	let [loading, setLoading] = useState(false);
	let [bankOptions, setBankOptions] = useState([]);
	let routingApi = new RoutingService();
	let employeeApi = new EmployeeService();

	useEffect(() => {
		init();
		forceUpdate({});
	}, [forceUpdate]);

	function init() {
		fetchRoutingDetails();
	}

	async function fetchRoutingDetails() {
		try {
			let response = await routingApi.getRouting();
			if (response && response.data && response.data.routings) {
				let bankOptions = response.data.routings.map((bank) => {
					bank.label = bank.bankName;
					bank.value = bank.routingCode;
					return bank;
				});
				setBankOptions(bankOptions);
			}
		} catch (err) {}
	}

	const onFinish = async (values) => {
		const { bankCode } = values;
		let selectedBank = bankOptions.find((bank) => bank.bankName === bankCode);

		if (bankId) {
			let payload = { ...history.location.state };
			payload.bankCode = bankId;
			history.push({ pathname: '/employees/add/individual/employee/details/submit/nextsteps', state: { ...payload } });
		}
	};

	return (
		<>
			<EdenredHeader />
			<Row className="add_employee_bank_name_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_employee_bank_name_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName + '’s' : ''} Bank Name?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_employee_bank_name_description">
					<EdenredTypo>Pick the bank used by the employee from the options given.</EdenredTypo>
				</Col>
				<Col xl={10} lg={20} md={24} sm={24} xs={24} className="add_employee_bank_name_form_section">
					<Form form={form} onFinish={onFinish}>
						<Form.Item name="bankCode" rules={[{ required: true, message: 'Please provide the Bank Name of the employee' }]}>
							<EdenredBankDropdown
								placeholder="Bank Name"
								onChange={(val) => {
									setBankId(val);
								}}
								type="dropdownfield"
								options={bankOptions}
							/>
						</Form.Item>
						<Col xl={18} lg={18} md={24} sm={24} xs={24}>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}
										loading={loading}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default AddEmployeeBankName;
