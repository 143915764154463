import React from 'react';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { Row, Col } from 'antd';
import MainDashboardMobilePresentational from './main-dashboard-mobile';
import MainDashboardWebPresentational from './main-dashboard-web';
import { DotLoader } from 'react-spinners';
//import access from '../../../../helpers/accessProvider'

const MainDashboardPresentational = (props) => {
	return (
		<>
			<EdenredHeader profile={{ link: { pathname: '/dashboard/profile', state: {} } }} />
			{props.pageLoading ? (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			) : (
				<>
					<div className="main-dashboard-web-presentational">
						<MainDashboardWebPresentational {...props} />
					</div>
					<Row className="main-dashboard-mobile-presentational">
						<MainDashboardMobilePresentational {...props} />
					</Row>
				</>
			)}
		</>
	);
};

export default MainDashboardPresentational;
