import React from 'react';
import Iframe from 'react-iframe';
import ContentLoader from '@sharedComponent/content-loader';
import './iframe.scss';

const IframeComponent = ({ src, height, width, loading }) => {
	return loading ? (
		<ContentLoader />
	) : src ? (
		// <div>
		// 	<iframe src={src} height={height} width={width} />
		// </div>
		<Iframe className="iframeStyle" src={src} height={height} width={width} />
	) : null;
};

export default IframeComponent;
