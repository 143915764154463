import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import Input from '@sharedComponent/text-field';
import { LoadingOutlined } from '@ant-design/icons';
import EdenredButton from '@sharedComponent/button';
import Popup from 'src/utils/popup/popups';
import './employee-leaves-edit.scss';
import ClevertapReact from 'src/utils/clever-tap';

const EmployeeLeavesEdit = ({
	employeeId,
	sampleData,
	handleEditLeave,
	SaveLeave,
	saveLeaveLoading,
	saveLeaveSucceessfulPopUp,
	getHandleValue,
	setNavigateTabCheck,
	navigateTabCheck,
}) => {
	const [itemEdit, setItemEdit] = useState([]);
	useEffect(() => {
		setItemEdit([...sampleData]);
	}, [sampleData]);
	const handleValueChanges = (event) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '4',
		});
		const Editvalues = itemEdit?.map((values) => {
			if (values.leaveTypeName === event.target.name) {
				return {
					...values,
					Days: `${event.target.value} Days`,
				};
			}
			return values;
		});
		setItemEdit([...Editvalues]);
	};
	const valueChange = () => JSON.stringify(sampleData) === JSON.stringify(itemEdit);

	const handleSave = () => {
		ClevertapReact.event('Leave _profile_editbalances');
		const payload = itemEdit?.map((dta) => {
			return {
				leaveBalanceId: dta?.LeaveBalanceId || '',
				leaveTypeId: dta?.LeaveTypeId,
				leaveTypeName: dta?.leaveTypeName,
				balance: dta?.Days?.replace(/[^0-9-]/g, ''),
			};
		});
		SaveLeave({
			employeeId: employeeId,
			employeeLeaveBalance: payload,
		});
	};
	const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#ffffff' }} spin />;

	const onlyNumberForNumberType = (evt) => {
		return (evt.key === '+' && evt.preventDefault()) || (evt.key === 'E' && evt.preventDefault()) || (evt.key === 'e' && evt.preventDefault());
	};

	return (
		<>
			<Popup visible={saveLeaveSucceessfulPopUp} message={{ titleMessage: 'Saved Successfully' }} closePopup={() => getHandleValue()}></Popup>
			<Col className="leaveEdit">
				<p className="leave-balance-title">Leave Balances:</p>
				<Row className="card-row">
					{itemEdit?.map((items) =>
						items?.isVisible === false ? (
							<Col Xl={4} lg={6} md={6} sm={12} xs={12} className="card-item">
								<Input
									type="number"
									labels={items.leaveTypeName}
									value={items.Days.replace(/[^0-9-]/g, '')}
									name={items.leaveTypeName}
									onChange={(event) => handleValueChanges(event)}
									onKeyDown={(event) => onlyNumberForNumberType(event)}
								/>
							</Col>
						) : null
					)}
				</Row>
			</Col>
			<Col className="leave-butons">
				<EdenredButton className="for-cancel" onClick={handleEditLeave}>
					Cancel
				</EdenredButton>
				<EdenredButton disabled={valueChange()} onClick={handleSave} shouldUpdate={true}>
					{saveLeaveLoading ? <Spin indicator={antIcon} /> : 'Save'}
				</EdenredButton>
			</Col>
		</>
	);
};

export default EmployeeLeavesEdit;
