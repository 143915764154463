import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import Input from '@sharedComponent/text-field';
import EdenredButton from '@sharedComponent/button';

const CompanyAuthority = ({ history }) => {
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		history.push({ pathname: '/company/establishment-id' });
	};
	return (
		<>
			<EdenredHeader />
			<Row className="company-authority-container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="authority-header">
					<EdenredTypo>Select The Regulating Authority:</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="authority-sub-header">
					<EdenredTypo>For companies located in the mainland (not Freezones), the authority in the Ministry of Labour (MoL)</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Form form={form} onFinish={onFinish}>
						<Row className="company-authority-form">
							<Col xl={11} lg={11} md={12} sm={24} xs={24} className="authority-text-field">
								<Form.Item name="Regulating Authority">
									<Input labels="Regulating Authority" labelClassName="inputlabelrequired" />
								</Form.Item>
							</Col>
						</Row>
						<Col xl={8} lg={8} md={8} sm={24} xs={24} className="authority-button">
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default CompanyAuthority;
