import Actions from './action';

export const initState = {
	leaveToapproveLoading: false,
	leavetoapprove: [],
	leavetoapproveError: '',
	overLappingLeavesLoading: false,
	overLappingLeaves: [],
	overLappingLeavesError: '',
	approveRejectLoading: false,
	approveReject: [],
	approveRejectError: '',
};

const LeavetoApproveReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_LEAVETOAPPROVE_START: {
			return {
				...state,
				leaveToapproveLoading: true,
			};
		}
		case Actions.types.GET_LEAVETOAPPROVE_SUCCESS: {
			return {
				...state,
				leaveToapproveLoading: false,
				leavetoapprove: action?.response,
			};
		}
		case Actions.types.GET_LEAVETOAPPROVE_FAIL: {
			return {
				...state,
				leaveToapproveLoading: false,
				leavetoapproveError: action.error,
				leavetoapprove: [],
			};
		}
		case Actions.types.GET_OVERLAPPING_LEAVES_START: {
			return {
				...state,
				overLappingLeavesLoading: true,
			};
		}
		case Actions.types.GET_OVERLAPPING_LEAVES_SUCCESS: {
			return {
				...state,
				overLappingLeavesLoading: false,
				overLappingLeaves: action?.response,
			};
		}
		case Actions.types.GET_OVERLAPPING_LEAVES_FAIL: {
			return {
				...state,
				overLappingLeavesLoading: false,
				overLappingLeaves: action.error,
				overLappingLeaves: [],
			};
		}
		case Actions.types.GET_APPROVEREJECT_START: {
			return {
				...state,
				approveRejectLoading: true,
			};
		}
		case Actions.types.GET_APPROVEREJECT_SUCCESS: {
			return {
				...state,
				approveRejectLoading: false,
				approveReject: action?.response,
			};
		}
		case Actions.types.GET_APPROVEREJECT_FAIL: {
			return {
				...state,
				approveRejectLoading: false,
				approveRejectError: action.error,
				approveReject: [],
			};
		}

		default:
			return state;
	}
};

export default LeavetoApproveReducer;
