import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import Axios from 'axios';

export function* employeesListService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leavestatus?leaveStatus=${action.payload.leaveStatus}&pageNumber=${action.payload.pageNumber}&pageSize=${action.payload.pageSize}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getCurrenlyLeaveSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getCurrenlyLeaveFail(err));
	}
}
export default function* leaveStatusSagaWatcher() {
	yield takeEvery(Actions.types.GET_CURRENTLYLEAVE_START, employeesListService);
}
