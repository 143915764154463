const POST_EMAIL_DATA = 'POST_EMAIL_DATA';
const POST_EMAIL_DATA_SUCCESS = 'POST_EMAIL_DATA_SUCCESS';
const POST_EMAIL_DATA_ERROR = 'POST_EMAIL_DATA_ERROR';
const UPDATE_EMAIL_NEW_START = 'UPDATE_EMAIL_NEW_START';
const UPDATE_EMAIL_NEW_SUCCESS = 'UPDATE_EMAIL_NEW_SUCCESS';
const UPDATE_EMAIL_NEW_FAIL = 'UPDATE_EMAIL_NEW_FAIL';
const INVITE_PORTAL_POPUP = 'INVITE_PORTAL_POPUP';
const GET_NEW_DATA = 'GET_NEW_DATA';
const GET_NEW_DATA_SUCCESS = 'GET_NEW_DATA_SUCCESS';
const GET_NEW_DATA_ERROR = 'GET_NEW_DATA_ERROR';
const INVITE_CARD_STATUS = 'INVITE_CARD_STATUS';

const postEmailData = (initial) => {
	return {
		type: POST_EMAIL_DATA,
		payload: initial,
	};
};
const postEmailDataSuccess = (res) => {
	return {
		type: POST_EMAIL_DATA_SUCCESS,
		res,
	};
};
const postEmailDataError = (err) => {
	return {
		type: POST_EMAIL_DATA_ERROR,
		err,
	};
};
const updateEmailNewStart = (payload) => {
	return {
		type: UPDATE_EMAIL_NEW_START,
		payload,
	};
};
const updateEmailNewSuccess = (res) => {
	return {
		type: UPDATE_EMAIL_NEW_SUCCESS,
		res,
	};
};
const updateEmailNewFail = (error) => {
	return {
		type: UPDATE_EMAIL_NEW_FAIL,
		error,
	};
};
const invitePortalPopup = (flag) => {
	return {
		type: INVITE_PORTAL_POPUP,
		flag,
	};
};
const getEmployeeNewData = () => {
	return {
		type: GET_NEW_DATA,
	};
};

const getEmployeeNewDataSuccess = (res) => {
	return {
		type: GET_NEW_DATA_SUCCESS,
		res,
	};
};

const getEmployeeNewDataError = (res) => {
	return {
		type: GET_NEW_DATA_ERROR,
		res,
	};
};
const inviteCardStatus = (status) => {
	return {
		type: INVITE_CARD_STATUS,
		status,
	};
};
export default {
	types: {
		POST_EMAIL_DATA,
		POST_EMAIL_DATA_SUCCESS,
		POST_EMAIL_DATA_ERROR,
		UPDATE_EMAIL_NEW_START,
		UPDATE_EMAIL_NEW_SUCCESS,
		UPDATE_EMAIL_NEW_FAIL,
		INVITE_PORTAL_POPUP,
		GET_NEW_DATA,
		GET_NEW_DATA_SUCCESS,
		GET_NEW_DATA_ERROR,
		INVITE_CARD_STATUS,
	},

	creators: {
		postEmailData,
		postEmailDataSuccess,
		postEmailDataError,
		updateEmailNewStart,
		updateEmailNewSuccess,
		updateEmailNewFail,
		invitePortalPopup,
		getEmployeeNewData,
		getEmployeeNewDataSuccess,
		getEmployeeNewDataError,
		inviteCardStatus,
	},
};
