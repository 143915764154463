import Actions from '../action';
import Axios from 'axios';
import ToastAction from '@pages/DesignSystem/StatusToast/actions';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeLatest } from 'redux-saga/effects';

export function* getDepartmentServices() {
	yield put(Actions.creators.putDepartmentLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/department`;
	try {
		const response = yield call(Axios.get, url);
		yield put(Actions.creators.getDepartmentData(response.data));
		yield put(Actions.creators.putDepartmentLoading(false));
	} catch (error) {
		yield put(Actions.creators.putDepartmentError(error));
		yield put(Actions.creators.putDepartmentLoading(false));
	}
}

export function* sentDepartmentData(action) {
	const postData = {
		userName: action?.userName,
		companySetupFlag: true,
		organizationSetting: {
			departments: action?.payload,
			allowanceTypes: [],
			additionTypes: [],
			deductionTypes: [],
			probationPeriod: {},
		},
	};
	yield put(Actions.creators.putDepartmentLoading(true));
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/corporatesetting`;
		yield call(Axios.post, url, postData);
		yield put(Actions.creators.putDepartmentLoading(false));
		yield put(ToastAction.creators.putSuccessStatusToast({ message: 'Your departments have been added!' }));
		yield put(Actions.creators.getDepartment());
	} catch (error) {
		yield put(Actions.creators.putDepartmentError(error));
		yield put(Actions.creators.putDepartmentLoading(false));
	}
}
export default function* departmentWatcher() {
	yield takeLatest(Actions.types.GET_DEPARTMENT, getDepartmentServices);
	yield takeLatest(Actions.types.POST_DEPARTMENT, sentDepartmentData);
}
