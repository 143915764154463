import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { forDisableButton } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import ClevertapReact from 'src/utils/clever-tap';
import Selectors from '../overTimeSelector';
import action from '../OverTimeAction';
import CompanySettingPresentational from './company-setting-presentational';

const CompanySettingFunctional = ({
	overTimeSaveData,
	isOverTimeSaved,
	loading,
	getOvertimePolicyPercentage,
	overTimePercentLoading,
	overTimePercentData,
	getOverTimeData,
	overTimeDataLoading,
	getOverTimeSalaryPerDay,
	overTimeSalaryData,
	overTimeSalaryDataLoading,
	overTimeDataInfo,
	getCompanySettingStart = [],
	CompanySettingValues,
	saveCompanySettingStart = [],
	setFileUpload,
	SaveCompanySettingValues,
	saveCompanySettingLoading,
	isCompanySaveSuccess,
	iscompanySettingSaveSuccess,
	isSaveSuccessfullDone,
	overTimeLabelData,
	getOverTimeLabelSaga,
	saveGetUploadLogoLoading,
	saveGetUploadLogoStatus,
	getUploadFile,
	companySettingStatusList,
	isSetUp = false,
}) => {
	const history = useHistory();
	const [message, setMessage] = useState('');
	const [allowancesArray, setAllowanceArray] = useState([]);
	const [isCreateSaved, setIsCreateSaved] = useState(false);
	const [departmentArray, setDepartmentArray] = useState([]);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isDisabledComp, setIsDisabledComp] = useState(true);
	const [defaultOvertimePolicies, setDefaultOvertimePolicies] = useState([]);
	const [additionArray, setAdditionArray] = useState([]);
	const [dragandDropEdit, setDragandDropEdit] = useState(false);
	const [deductionsArray, setDeductionsArray] = useState([]);
	const [disableorganizationSave, setDisableOrganizationSave] = useState(true);
	const [monthState, setMonthState] = useState({
		id: '',
		months: 6,
	});
	const [popupvisible, setPopupvisible] = useState(false);

	const [overtimeData, setOvertimeData] = useState({
		salaryType: '',
		days: '',
		hoursADay: 0,
	});
	const [overtimePolicies, setOvertimePolicies] = useState([]);
	const [defaultOvertimeData, setDefaultOvertimeData] = useState({
		salaryType: '',
		days: '',
		hoursADay: 0,
	});
	const [tick, setTick] = useState(false);
	const [correct, setCorrect] = useState(false);
	const [upload, setupload] = useState(false);
	const dispatch = useDispatch();
	const [companyTab, setCompanyTab] = useState('1');
	const defaultApprover = useSelector((state) => state?.companySettingReducer?.defaultApproverDetail) || [];
	let newDefaultUser = [];
	const userarray = defaultApprover?.userRolesAndClaims?.map((user) => {
		user?.user && newDefaultUser.push(user?.user?.userName);
	});
	const cleverTapProperties = {
		TypeOfSalary: overtimeData?.salaryType,
		Days: overtimeData?.days,
		Hours: overtimeData?.hoursADay,
		OvertimeOnWeekdays: overtimePolicies?.[0]?.standardPercentage,
		OvertimeOnWeekends: overtimePolicies?.[1]?.standardPercentage,
		OvertimeOnPublicHolidays: overtimePolicies?.[2]?.standardPercentage,
	};
	const handleNewArray = (name, type, tab) => {
		switch (type) {
			case 'Department':
				companyTypes.organizationSetting.departments.push({ name: name, id: '' });
				setDepartmentArray([...departmentArray, { name: name, id: '' }]);
				setDisableOrganizationSave(false);
				break;
			case 'Allowance':
				companyTypes.organizationSetting.allowanceTypes.push({ name: name, id: '' });
				setAllowanceArray([...allowancesArray, { name: name, id: '' }]);
				setDisableOrganizationSave(false);
				break;
			case 'Addition':
				companyTypes.organizationSetting.additionTypes.push({ name: name, id: '' });
				setAdditionArray([...additionArray, { name: name, id: '' }]);
				setDisableOrganizationSave(false);
				break;
			case 'Deduction':
				companyTypes.organizationSetting.deductionTypes.push({ name: name, id: '' });
				setDeductionsArray([...deductionsArray, { name: name, id: '' }]);
				setDisableOrganizationSave(false);
				break;
			default:
				break;
		}

		handleSaveButton();
	};

	useEffect(() => {
		const DEFAULT_DATA = [
			{
				id: '',
				overtimeType: 'Overtime On Weekdays',
				standardPercentage: '1.25',
			},
			{
				id: '',
				overtimeType: 'Overtime On Weekends',
				standardPercentage: '1.5',
			},
			{
				id: '',
				overtimeType: 'Overtime On Public Holidays',
				standardPercentage: '2.5',
			},
		];
		!overTimeDataInfo?.overtimePolicies?.length && setOvertimePolicies([...DEFAULT_DATA]);
	}, [overTimeDataInfo]);
	const handleNewArray1 = (name, type, value) => {
		switch (type) {
			case 'Department':
				companyTypes.organizationSetting.departments[value].name = name;
				setDepartmentArray(departmentArray, (departmentArray[value].name = name));
				setDisableOrganizationSave(false);
				break;
			case 'Allowance':
				companyTypes.organizationSetting.allowanceTypes[value].name = name;
				setAllowanceArray(allowancesArray, (allowancesArray[value].name = name));
				setDisableOrganizationSave(false);
				break;
			case 'Addition':
				companyTypes.organizationSetting.additionTypes[value].name = name;
				setAdditionArray(additionArray, (additionArray[value].name = name));
				setDisableOrganizationSave(false);
				break;
			case 'Deduction':
				companyTypes.organizationSetting.deductionTypes[value].name = name;
				setDeductionsArray(deductionsArray, (deductionsArray[value].name = name));
				setDisableOrganizationSave(false);
				break;
			default:
				break;
		}
		handleSaveButton();
	};
	const handleMonthIncrement = (type) => {
		if (monthState?.months >= 0) {
			if (type === 'plus') {
				setMonthState((prev) => ({ ...prev, months: monthState?.months <= 11 ? monthState?.months + 1 : 12, id: monthState?.id }));
				companyTypes.organizationSetting.probationPeriod.month = monthState?.months <= 11 ? monthState?.months + 1 : 12;
				companyTypes.organizationSetting.probationPeriod.id = monthState?.id;
				setDisableOrganizationSave(false);
			} else {
				setMonthState((prev) => ({ ...prev, months: monthState?.months >= 1 ? monthState?.months - 1 : 0, id: monthState?.id }));
				companyTypes.organizationSetting.probationPeriod.month = monthState?.months >= 1 ? monthState?.months - 1 : 0;
				companyTypes.organizationSetting.probationPeriod.id = monthState?.id;
				setDisableOrganizationSave(false);
			}
			handleSaveButton();
		}
		setIsDisabledComp(false);
	};
	const handleTick = () => setTick(!tick);
	const handleOverTime = () => setCorrect(!correct);
	const handleOnChange = (data, param, index) => {
		if (param === 'Overtime On Weekdays' || param == 'Overtime On Weekends' || param == 'Overtime On Public Holidays') {
			if (overtimePolicies?.[0]?.id !== '' && overtimePolicies?.length !== 0) {
				setOvertimePolicies(
					overtimePolicies.map((item) => (item.id === index ? { ...item, overtimeType: param, standardPercentage: data, id: index } : item))
				);
			} else {
				if (overtimePolicies?.length !== 3) {
					setOvertimePolicies((prev) => [...prev, { overtimeType: param, standardPercentage: data, id: '' }]);
				} else {
					const nPolicies = overtimePolicies?.map((dta) => {
						if (dta?.overtimeType === param) {
							return {
								...dta,
								overtimeType: param,
								standardPercentage: data,
								id: '',
							};
						}
						return dta;
					});
					setOvertimePolicies([...nPolicies]);
				}
			}
		} else {
			if (param === 'hoursADay') {
				if (data <= 24) {
					setOvertimeData({ ...overtimeData, [param]: data });
				}
			} else if (param !== 'hoursADay') {
				setOvertimeData({ ...overtimeData, [param]: data });
			}
		}
	};

	const handleSuccessPopups = () => {
		isSaveSuccessfullDone();
		setIsCreateSaved(false);
		setIsDisabledComp(true);
		svm(false);
		isSetUp && history.push({ pathname: '/setup' });
	};
	useEffect(() => {
		if (isOverTimeSaved === 'success' && isOverTimeSaved != null) {
			setIsCreateSaved(true);
		} else if (isCompanySaveSuccess.flag && SaveCompanySettingValues != null) {
			setIsCreateSaved(isCompanySaveSuccess.flag);
		}
	}, [isCompanySaveSuccess.flag, saveCompanySettingLoading]);

	const checkDataChange = useMemo(() => {
		let toogle =
			(JSON.stringify(overtimePolicies) === JSON.stringify(defaultOvertimePolicies) &&
				JSON.stringify(overtimeData) === JSON.stringify(defaultOvertimeData)) ||
			!overtimeData?.days ||
			!overtimeData?.salaryType ||
			!overtimePolicies?.length ||
			!overtimeData.hoursADay ||
			parseFloat(overtimeData.hoursADay) === 0;
		setIsDisabled(toogle);
	}, [overtimePolicies, overtimeData]);

	const cancel = () => {
		setOvertimePolicies(defaultOvertimePolicies);
		setOvertimeData(defaultOvertimeData);
		isSetUp && history.push({ pathname: '/setup' });
	};
	const handleDeleteAdd = (index) => {
		let temp = additionArray.filter((data, i) => i != index);
		setAdditionArray(temp);
		setIsDisabledComp(false);
	};
	const handleDeleteDed = (index) => {
		let temp = deductionsArray.filter((data, i) => i != index);
		setDeductionsArray(temp);
		setIsDisabledComp(false);
	};
	useEffect(() => {
		getUploadFile();
		getCompanySettingStart();
		getOverTimeLabelSaga();
		dispatch(action.creators.getDefaultApproverStart());
	}, []);

	useEffect(() => {
		CompanySettingValues.allowanceTypes && setAllowanceArray(CompanySettingValues.allowanceTypes);
		CompanySettingValues.additionTypes && setAdditionArray(CompanySettingValues.additionTypes);
		CompanySettingValues.departments && setDepartmentArray(CompanySettingValues.departments);
		CompanySettingValues.deductionTypes && setDeductionsArray(CompanySettingValues.deductionTypes);
		CompanySettingValues?.probationPeriod &&
			setMonthState((prev) => ({
				...prev,
				months: CompanySettingValues?.probationPeriod?.month,
				id: CompanySettingValues?.probationPeriod?.id,
			}));
	}, [CompanySettingValues]);

	const companyTypes = {
		userName: newDefaultUser?.[0],
		organizationSetting: {
			departments: departmentArray?.map((data) => {
				return {
					id: data.id,
					name: data.name,
				};
			}),
			allowanceTypes: allowancesArray?.map((data) => {
				return {
					id: data.id,
					name: data.name,
				};
			}),
			additionTypes: additionArray?.map((data) => {
				return {
					id: data.id,
					name: data.name,
				};
			}),
			deductionTypes: deductionsArray?.map((data) => {
				return {
					id: data.id,
					name: data.name,
				};
			}),
			probationPeriod: {
				id: monthState?.id || '',
				month: monthState.months,
			},
			corporateId: localStorage.getItem(APP_VARIABLES.CORPORATE_ID),
		},
	};
	const handleSaveButton = (obj, type) => {
		// saveCompanySettingStart(companyTypes);
		// if (iscompanySettingSaveSuccess.message === 'Saved Successfully') {
		// 	setDragandDropEdit(true);
		// }
		// setupload(false);

		setPopupvisible(false);
	};

	const handleCancleButton = () => {
		getCompanySettingStart();
		isSetUp && history.push({ pathname: '/setup' });
		setupload(false);
		setIsDisabledComp(true);
	};
	const forEnable = useMemo(() => forDisableButton(companySettingStatusList, companyTypes.organizationSetting), [companyTypes]);

	const handleCompanyTab = (key) => setCompanyTab(key);

	const handleCompanySetting = (value) => {
		if (value === 'save') {
			ClevertapReact.event(isSetUp ? 'setup_save_overtime' : 'save_overtime', { cleverTapProperties });
			overTimeSaveData(overtimePolicies, overtimeData);
		} else {
			cancel();
		}
	};
	const [vm, svm] = useState(false);
	const handleOrganizationSetting = (mode) => {
		if (mode == 'save') {
			saveCompanySettingStart(companyTypes);
			console.log('jp');
			if (iscompanySettingSaveSuccess.message === 'Saved Successfully') {
				svm(true);
				setDragandDropEdit(true);
			}
			setDisableOrganizationSave(true);
			setupload(false);
		} else {
			history.goBack();
		}
	};

	return (
		<CompanySettingPresentational
			{...{
				allowancesArray,
				overtimePolicies,
				overtimeData,
				setAllowanceArray,
				handleNewArray,
				departmentArray,
				setDepartmentArray,
				handleMonthIncrement,
				monthState,
				handleTick,
				tick,
				handleOverTime,
				correct,
				handleOnChange,
				handleSuccessPopups,
				iscompanySettingSaveSuccess,
				isCreateSaved,
				isOverTimeSaved,
				setIsCreateSaved,
				getOvertimePolicyPercentage,
				overTimePercentData,
				overTimePercentLoading,
				getOverTimeData,
				overTimeDataLoading,
				getOverTimeSalaryPerDay,
				overTimeSalaryData,
				overTimeSalaryDataLoading,
				setOvertimeData,
				overTimeDataInfo,
				setOvertimePolicies,
				defaultOvertimeData,
				setDefaultOvertimeData,
				defaultOvertimePolicies,
				setDefaultOvertimePolicies,
				checkDataChange,
				isDisabled,
				setIsDisabled,
				saveCompanySettingStart,
				additionArray,
				deductionsArray,
				handleDeleteAdd,
				handleDeleteDed,
				setFileUpload,
				SaveCompanySettingValues,
				isDisabledComp,
				setIsDisabledComp,
				isCompanySaveSuccess,
				isSaveSuccessfullDone,
				setAllowanceArray,
				handleNewArray1,
				setupload,
				upload,
				overTimeLabelData,
				dragandDropEdit,
				saveGetUploadLogoLoading,
				saveGetUploadLogoStatus,
				getUploadFile,
				saveCompanySettingLoading,
				forEnable,
				loading,
				isSetUp,
				handleCompanyTab,
				handleCompanySetting,
				companyTab,
				popupvisible,
				setPopupvisible,
				handleOrganizationSetting,
				disableorganizationSave,
				vm,
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: Selectors.loading(state),
		isOverTimeSaved: Selectors.isOverTimeSaved(state),
		overTimePercentData: Selectors.overTimePercentData(state),
		overTimePercentLoading: Selectors.overTimePercentLoading(state),
		overTimeDataInfo: Selectors.overTimeDataInfo(state),
		overTimeDataLoading: Selectors.overTimeDataLoading(state),
		overTimeSalaryData: Selectors.overTimeSalaryData(state),
		overTimeSalaryDataLoading: Selectors.overTimeSalaryDataLoading(state),
		CompanySettingValues: Selectors.CompanySettingValues(state),
		SaveCompanySettingValues: Selectors.SaveCompanySettingValues(state),
		saveCompanySettingLoading: Selectors.saveCompanySettingLoading(state),
		isCompanySaveSuccess: Selectors.isCompanySaveSuccess(state),
		iscompanySettingSaveSuccess: Selectors.iscompanySettingSaveSuccess(state),
		overTimeLabelLoading: Selectors.overTimeLabelLoading(state),
		overTimeLabelData: Selectors.overTimeLabelData(state),
		saveGetUploadLogoLoading: Selectors.saveGetUploadLogoLoading(state),
		saveGetUploadLogoStatus: Selectors.saveGetUploadLogoStatus(state),
		saveGetUploadLogoError: Selectors.saveGetUploadLogoError(state),
		companySettingStatusList: Selectors.companySettingStatusList(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		overTimeSaveData: (overtimePolicies, overtimeData) => dispatch(action.creators.saveOverTimeStart(overtimePolicies, overtimeData)),
		getOvertimePolicyPercentage: () => dispatch(action.creators.getOvertimePolicyPercentage()),
		getOverTimeData: () => dispatch(action.creators.getOverTimeData()),
		getOverTimeSalaryPerDay: () => dispatch(action.creators.getOverTimeSalaryPerDay()),
		getCompanySettingStart: () => dispatch(action.creators.getCompanySettingStart()),
		saveCompanySettingStart: (data) => dispatch(action.creators.saveCompanySettingStart(data)),
		setFileUpload: (fileUpload) => dispatch(action.creators.saveUploadLogoStart(fileUpload)),
		isSaveSuccessfullDone: () => dispatch(action.creators.isSaveSuccessfullDone()),
		getOverTimeLabelSaga: () => dispatch(action.creators.overtimeLabelStart()),
		getUploadFile: () => dispatch(action.creators.getUploadLogoStart()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(CompanySettingFunctional);
export default Connected;
