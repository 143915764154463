const EMPLOYEE_PAYROLL_TEMPLATE_START = 'EMPLOYEE_PAYROLL_TEMPLATE_START';
const EMPLOYEE_PAYROLL_TEMPLATE_SUCCESS = 'EMPLOYEE_PAYROLL_TEMPLATE_SUCCESS';
const EMPLOYEE_PAYROLL_TEMPLATE_FAIL = 'EMPLOYEE_PAYROLL_TEMPLATE_FAIL';
const EMPLOYEE_PAYROLL_UPLOAD_START = 'EMPLOYEE_PAYROLL_UPLOAD_START';
const EMPLOYEE_PAYROLL_UPLOAD_SUCCESS = 'EMPLOYEE_PAYROLL_UPLOAD_SUCCESS';
const EMPLOYEE_PAYROLL_UPLOAD_FAIL = 'EMPLOYEE_PAYROLL_UPLOAD_FAIL';
const GET_EMPLOYEE_PAYROLL_START = 'GET_EMPLOYEE_PAYROLL_START';
const GET_EMPLOYEE_PAYROLL_SUCCESS = 'GET_EMPLOYEE_PAYROLL_SUCCESS';
const GET_EMPLOYEE_PAYROLL_FAIL = 'GET_EMPLOYEE_PAYROLL_FAIL';
const EMP_RESET_DOCUMENT = 'EMP_RESET_DOCUMENT';

const employeePayrollTemplateStart = () => {
	return {
		type: EMPLOYEE_PAYROLL_TEMPLATE_START,
	};
};
const employeePayrollTemplateSuccess = () => {
	return {
		type: EMPLOYEE_PAYROLL_TEMPLATE_SUCCESS,
	};
};
const employeePayrollTemplateFail = () => {
	return {
		type: EMPLOYEE_PAYROLL_TEMPLATE_FAIL,
	};
};

const employeePayrollUploadStart = (payload) => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD_START,
		payload,
	};
};
const employeePayrollUploaduccess = (res) => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD_SUCCESS,
		res,
	};
};
const employeePayrollUploadFail = () => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD_FAIL,
	};
};
const getEmployeePayrollStart = (payload) => {
	return {
		type: GET_EMPLOYEE_PAYROLL_START,
		payload,
	};
};
const getEmployeePayrollSuccess = (res) => {
	return {
		type: GET_EMPLOYEE_PAYROLL_SUCCESS,
		res,
	};
};
const getEmployeePayrollFail = () => {
	return {
		type: GET_EMPLOYEE_PAYROLL_FAIL,
	};
};
const empDocumentUploadReset = () => ({
	type: EMP_RESET_DOCUMENT,
});
export default {
	types: {
		EMPLOYEE_PAYROLL_TEMPLATE_START,
		EMPLOYEE_PAYROLL_TEMPLATE_SUCCESS,
		EMPLOYEE_PAYROLL_TEMPLATE_FAIL,
		EMPLOYEE_PAYROLL_UPLOAD_START,
		EMPLOYEE_PAYROLL_UPLOAD_SUCCESS,
		EMPLOYEE_PAYROLL_UPLOAD_FAIL,
		GET_EMPLOYEE_PAYROLL_START,
		GET_EMPLOYEE_PAYROLL_SUCCESS,
		GET_EMPLOYEE_PAYROLL_FAIL,
		EMP_RESET_DOCUMENT,
	},
	creators: {
		employeePayrollTemplateStart,
		employeePayrollTemplateSuccess,
		employeePayrollTemplateFail,
		employeePayrollUploadStart,
		employeePayrollUploaduccess,
		employeePayrollUploadFail,
		getEmployeePayrollStart,
		getEmployeePayrollSuccess,
		getEmployeePayrollFail,
		empDocumentUploadReset,
	},
};
