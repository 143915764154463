import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButton from '@sharedComponent/button';
import Table from '@pages/DesignSystem/Table/Table';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { HrclaimsArray } from '@constants/';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { INVITE_TEAMS_CONSTANTS, teamsFilter } from '@constants/app-constants';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import ClevertapReact from 'src/utils/clever-tap';
import Toast from '@pages/DesignSystem/Toast';
import { debounceHandler, entriesDropDown } from '@helpers';
import FilterButton from '@pages/DesignSystem/filter';
import ETypo from '@pages/DesignSystem/Typo';
import { images } from 'src/assets/img';

const ManageTeamPresentational = ({
	list,
	issetup,
	handleSetupButton,
	columns,
	handlePageChange,
	pagination,
	loading,
	totalPermissionCounts,
	filterClearAll,
}) => {
	let history = useHistory();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [Hrclaims, setHrClaims] = useState([]);
	(list || []).map((item) => {
		let Hrclaim1 = item?.hrClaims;
		let claim = item?.claims;
		let Hrclaim = [];
		if (Hrclaim < 0) {
			claim.map((item) => {
				if (HrclaimsArray?.includes(item.value)) {
					Hrclaim.push(item);
				}
			});
		} else {
			Hrclaim1.map((item) => {
				if (HrclaimsArray?.includes(item.value)) {
					Hrclaim.push(item);
				}
			});
		}
		let call;
		for (var i = 0; i < Hrclaim?.length; i++) {
			var data = Hrclaim[i]?.value;
			var data1 = data?.substr(data?.lastIndexOf('.') + 1);
			if (call === undefined) {
				call = data1;
			} else {
				call = call + ', ' + data1;
			}
		}
		Hrclaims.push(call);
	});
	useEffect(() => {
		if (history?.location?.state && history?.location?.state?.response) {
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: history?.location?.state?.response,
				subMessage: history?.location?.state?.type === 'role' ? null : history?.location?.state?.text,
			});
		} else {
			setpopupVisible(false);
		}
	}, [history?.location?.state?.response]);
	const clearPopUp = () => setpopupVisible(false);
	const handleteammember = () => {
		history.push(issetup ? '/admissionpermission' : '/invite_team_member');
		ClevertapReact.event('InviteTeamMember');
	};

	const closePopup = () => {
		setpopupVisible(false);
		setTimeout(() => {
			if (history?.location && history?.location?.state && history?.location?.state?.response) {
				const state = { ...history?.location?.state };
				delete state?.response;
				history.replace({ ...history?.location, state });
			}
		}, 0);
	};
	return (
		<>
			<EdenredHeader />
			<Toast visible={popupVisible} clearPopUp={clearPopUp} color="#8EF9B3" textcolor="#484B52" size="35rem">
				{popupMessage?.titleMessage}
			</Toast>

			<Row className="manage_team">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="manage_team_header">
					<div className="team_header">
						<ETypo h2 bold>
							Roles & Permissions
						</ETypo>
						<ETypo b2 light>
							You can edit permissions of existing members or assign new permissions to help you manage payroll, employees and HR.
						</ETypo>
					</div>
					<div className="manage_team_header_description">
						<EdenredButtons btnType="secondary" leftIcon={<ImageComponent src={images.leftsarrow} />} onClick={handleSetupButton}>
							Back
						</EdenredButtons>
						<EdenredButtons
							className="new_desgin_add_admin"
							leftIcon={<ImageComponent src={focus_state_icons.NewAddAdmin} />}
							onClick={handleteammember}>
							{INVITE_TEAMS_CONSTANTS.ADD_ADMIN_BUTTON}
						</EdenredButtons>
					</div>
				</Col>
				<Row className="new_design_table_role">
					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="new_design_table_header">
						<Col xs={24} sm={24} md={11} lg={11} xl={11} className="new_design_search_filter">
							<Col className="new_design_search">
								<Inputbox
									{...{
										leftImage: <ImageComponent src={focus_state_icons.NewSearch} />,
										placeholder: 'Search by Name, Employee ID...',
										className: 'new_design_input',
										onChange: (e) => debounceHandler(e.target.value, handlePageChange, 'search'),
									}}
								/>
							</Col>
							<Col className="new_design_filter">
								<FilterButton
									{...{
										filterData: teamsFilter,
										selectedFilter: (page) => handlePageChange(page?.name, 'filter'),
										value: pagination?.filter,
										compareValue: 'value',
										filterClearAll,
									}}
								/>
							</Col>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11} xl={11} className="new_design_entries">
							<p>Show</p>
							<MultiSelect
								{...{
									Option: entriesDropDown,
									value: pagination?.pageSize,
									dropDownValue: 'value',
									searchVisibility: false,
									onChange: (page) => handlePageChange(page?.value, 'pageSize'),
								}}
							/>
							<p>Entries</p>
						</Col>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="manage_team_table_header">
						<Table
							{...{
								data: list,
								columns: columns,
								tableLoading: loading,
							}}
						/>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="new_design_pagination_bar">
						<div className="permission-pagination-count">
							<ETypo light b3 color="#484B52">
								Showing {list?.length} out of {totalPermissionCounts} Employees
							</ETypo>
						</div>
						<Pagination
							currentPage={pagination?.pageNumber || 1}
							totalCount={totalPermissionCounts}
							pageSize={pagination?.pageSize}
							onPageChange={(page) => handlePageChange(page, 'pageNumber')}
						/>
					</Col>
				</Row>
				{/* {issetup && (
					<Row className="invite_team_member_btn_section">
						{history?.location?.state !== undefined ? (
							<Col
								xl={18}
								md={18}
								lg={18}
								sm={24}
								xs={24}
								className="invite_team_member"
								style={{ display: 'flex', gap: '16px', marginLeft: '-147px' }}>
								<Link to={'/admissionpermission'}>
									<EdenredButton
										style={{
											background: 'white',
											border: ' 2px solid #DFE1E6',
											color: '#484B52',
											width: '216px',
											padding: '18px, 32px, 18px, 32px',
										}}
										noShadow={true}
										onClick={() => handleteammember()}>
										Add New Admin
									</EdenredButton>
								</Link>
								<EdenredButton noShadow={true} onClick={() => handleSetupButton()} style={{ width: '107px' }}>
									Next
								</EdenredButton>
							</Col>
						) : (
							<>
							
							</>
						)}
					</Row>
				)} */}
			</Row>
		</>
	);
};

export default ManageTeamPresentational;
