import React, { useEffect, useState } from 'react';
import './custom-tab.scss';
import { TimePicker } from 'antd';

const TimeInput = ({ getTimeVal, label, required, value }) => {
	const [timeVal, setTimeVal] = useState(null);

	const onChangeHandler = (val) => {
		setTimeVal(val);
		getTimeVal(val);
	};

	useEffect(() => {
		setTimeVal(value);
	}, [value]);

	return (
		<div>
			{label && (
				<p className="date_label">
					{label}
					{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
				</p>
			)}
			<TimePicker
				placeholder="HH:MM"
				format="HH:mm"
				value={timeVal}
				id="invoice-time"
				onChange={(val) => {
					onChangeHandler(val);
				}}
			/>
		</div>
	);
};

export default TimeInput;
