import React from 'react';
import { useQuery } from 'src/helpers/hooks';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './per-employee-reducer';
import saga from './saga/index';
import SalaryperEmployeeFunctional from './components/salary-per-employe-functional';

const SalaryperEmployee = () => {
	let query = useQuery().get('salary');

	const isFromSalaryTransferPage = !!query;
	console.log(isFromSalaryTransferPage);

	return <SalaryperEmployeeFunctional {...{ isFromSalaryTransferPage }} />;
};

const ReducedScreen = withReducer('salaryPerEmployee', reducer, saga)(SalaryperEmployee);
export default ReducedScreen;
