import React, { useEffect, useState } from 'react';
import moment from 'moment';
import EdenredButton from '@sharedComponent/button';
import { Col, Row, Select } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { getYears } from '@helpers';
import EdenredHeader from '@pages/main-layout/components/main-header';
import PayrollService from 'src/services/payroll-service';
import ClevertapReact from 'src/utils/clever-tap';

const { Option } = Select;

const NonWpsPayroll = ({ history }) => {
	const customStyles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(255, 255, 255, 0.75)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'absolute',
			top: '16rem',
			left: '1rem',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'white',
			overflow: 'auto',
			borderRadius: '16px',
			outline: 'none',
			padding: '15px',
			width: '90%',
			boxShadow: '0px 10px 20px rgba(174, 182, 183, 0.5)',
		},
	};
console.log('history ', history);
	const yearLength = 6;
	const [years, setyears] = useState(
		getYears(1, 1).map((year) => {
			return {
				value: year,
			};
		})
	);
	const [currentIndex, setcurrentIndex] = useState(0);
	const [indexObject, setindex] = useState({
		start: currentIndex,
		end: currentIndex + yearLength,
	});
	const { start, end } = indexObject;
	const hasPreviousData = start >= yearLength;
	const hasNextData = years.slice(end, end + yearLength).length !== 0;
	const yearData = years.slice(indexObject.start, indexObject.end);
	const [selectedYear, setSelectedYear] = useState();

	const [Months, setMonths] = useState([
		{ month: '01', index: 0, label: 'January', isSelected: false },
		{ month: '02', index: 1, label: 'February', isSelected: false },
		{ month: '03', index: 2, label: 'March', isSelected: false },
		{ month: '04', index: 3, label: 'April', isSelected: false },
		{ month: '05', index: 4, label: 'May', isSelected: false },
		{ month: '06', index: 5, label: 'June', isSelected: false },
		{ month: '07', index: 6, label: 'July', isSelected: false },
		{ month: '08', index: 7, label: 'August', isSelected: false },
		{ month: '09', index: 8, label: 'September', isSelected: false },
		{ month: '10', index: 9, label: 'October', isSelected: false },
		{ month: '11', index: 10, label: 'November', isSelected: false },
		{ month: '12', index: 11, label: 'December', isSelected: false },
	]);
	const [selectedMonth, setSelectedMonth] = useState();
	const [viewMonthModal, setViewMonthModal] = useState(false);
	const [viewYearModal, setViewYearModal] = useState(false);

	const handleYears = (type) => {
		switch (type) {
			case 'PRE':
				if (hasPreviousData) {
					setindex({
						...indexObject,
						start: start - yearLength,
						end: end - yearLength,
					});
				}
				break;
			case 'AFT':
				if (hasNextData) {
					setindex({
						...indexObject,
						start: start + yearLength,
						end: end + yearLength,
					});
				}
				break;

			default:
				break;
		}
	};

	const openMonthModal = () => {
		setViewMonthModal(true);
	};

	const closeMonthModal = () => {
		setViewMonthModal(false);
	};

	const openYearModal = () => {
		setViewYearModal(true);
	};

	const closeYearModal = () => {
		setViewYearModal(false);
	};

	const isMonthSelected = (month) => {
		initializeMonth(month && month.index);
	};

	const payrollService = new PayrollService();

	const getLastProccedMonth = () => {
		payrollService.getLastProccedMonth().then((res) => {
			console.log(res);
			console.log(res.data.toString().charAt(4));
			console.log(res.data.toString().charAt(5));
			if (res && res.data) {
				setSelectedYear(
					`${res.data.toString().charAt(0)}${res.data.toString().charAt(1)}${res.data.toString().charAt(2)}${res.data.toString().charAt(3)}`
				);
				// initializeMonth1(new Date().getMonth(`${res.data.toString().charAt(4)}${res.data.toString().charAt(5)}`));
				initializeMonth1(`${res.data.toString().charAt(4)}${res.data.toString().charAt(5)}`);
			} else {
				setSelectedYear(new Date().getFullYear());
				initializeMonth(new Date().getMonth());
			}
		});
	};

	useEffect(() => {
		//getLastProccedMonth();
	}, []);

	useEffect(() => {
		Modal.setAppElement('body');
	}, [Modal]);

	const initializeMonth = (data) => {
		console.log(data);
		let months = Months;
		months.map((month, i) => {
			if (month.index === data) {
				month.isSelected = true;
				setSelectedMonth(moment().month(month.label).format('MM'));
			} else {
				month.isSelected = false;
			}
		});
		setMonths([...months]);
		closeMonthModal();
	};

	const initializeMonth1 = (data) => {
		// console.clear();
		console.log(data);

		console.log(data);
		let months = Months;
		months.map((month, i) => {
			if (month.month == data) {
				month.isSelected = true;
				// setSelectedMonth(month.label); // TODO: FOR MONTH STRING
				setSelectedMonth(moment().month(month.label).format('MM')); // TODO: FOR MONTH NUMBER
			} else {
				month.isSelected = false;
			}
		});
		setMonths([...months]);
		closeMonthModal();
	};

	const handleChangeMonth = (monthItem, mont) => {
		console.log(monthItem);
		console.log(mont);
		if (mont.children) {
			setSelectedMonth(moment().month(mont.children).format('MM'));
		} else {
			setSelectedMonth(moment().month(monthItem).format('MM'));
		}
	};

	const handleChangeYear = (year) => {
		setSelectedYear(year);
	};

	const { state } = useLocation();

	const handlenect = () => {
		ClevertapReact.event('Next_MthYear', { Type: 'Non Wps' });
		history.push({
			pathname: '/payroll/run/channel/now_or_later',
			state: { ...state, payrollForMonth: selectedMonth, payrollForYear: selectedYear },
		});
	};

	return (
		<>
			<EdenredHeader />
			<Row className="non_wps_payroll_container">
				<Col xl={20} lg={20} md={20} sm={24} xs={24} className="non_wps_payroll_header">
					<EdenredTypo bold>What Month And Year Is This Non WPS Payroll For?</EdenredTypo>
				</Col>
				<Col xl={22} lg={16} md={16} sm={24} xs={24} className="non_wps_payroll_description">
					<EdenredTypo>Select the month and date that will reflect in your accounting records.</EdenredTypo>
				</Col>
				<Row className="non_wps_payroll_select_desktop_section">
					<Col xl={5} lg={5} md={5} className="non_wps_payroll_month">
						<Select value={selectedMonth} placeholder="Select Month" onChange={handleChangeMonth}>
							{Months.map((monthItem, index) => {
								return (
									<Option key={index} value={monthItem.month}>
										{monthItem.label}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col xl={5} lg={5} md={5} className="non_wps_payroll_month">
						<Select value={selectedYear} placeholder="Select Year" onChange={handleChangeYear}>
							{years.map((year, index) => (
								<Option key={index} value={year.value}>
									{year.value}
								</Option>
							))}
						</Select>
					</Col>
				</Row>
				<Row className="non_wps_payroll_select_mobile_section">
					<Col sm={10} xs={10} className="non_wps_payroll_select_month" onClick={openMonthModal}>
						<EdenredTypo medium>
							{Months.filter((month) => month.isSelected === true)[0] && Months.filter((month) => month.isSelected === true)[0].label}
						</EdenredTypo>
						<ImageComponent src={icons.not_focus.Down} />
					</Col>
					<Modal isOpen={viewMonthModal} onRequestClose={closeMonthModal} style={customStyles}>
						<Row>
							{Months.map((monthItem, index) => {
								return (
									<Col sm={3} style={{ padding: '1% 1% 1% 1%' }}>
										<EdenredButton
											onClick={() => isMonthSelected(monthItem)}
											className={monthItem.isSelected === true ? 'isSelected month_modal_button' : 'month_modal_button'}>
											{monthItem.label}
										</EdenredButton>
									</Col>
								);
							})}
						</Row>
					</Modal>
					<Col sm={10} xs={10} className="non_wps_payroll_select_year" onClick={openYearModal}>
						<EdenredTypo medium>{selectedYear || new Date().getFullYear()}</EdenredTypo>
						<ImageComponent src={icons.not_focus.Down} />
					</Col>
					<Modal isOpen={viewYearModal} onRequestClose={closeYearModal} style={customStyles}>
						<Row className="date_modal">
							<Col sm={2} xs={2} className="icon_area" onClick={() => hasPreviousData && handleYears('PRE')}>
								{hasPreviousData ? <ImageComponent src={icons.not_focus.Down} className="left_arrow" /> : ''}
							</Col>
							<Col sm={20} xs={20}>
								<Row>
									{yearData.map((year, i) => (
										<Col key={i} sm={8} xs={8} style={{ padding: '1% 1% 1% 1%' }}>
											<EdenredButton
												onClick={() => {
													setSelectedYear(year.value);
													closeYearModal();
												}}
												className={`${selectedYear === year.value ? 'isSelected' : ''} month_modal_button`}>
												{year.value}
											</EdenredButton>
										</Col>
									))}
								</Row>
							</Col>
							<Col sm={2} xs={2} className="icon_area" onClick={() => hasNextData && handleYears('AFT')}>
								{hasNextData ? <ImageComponent src={icons.not_focus.Down} className="right_arrow" /> : ''}
							</Col>
						</Row>
					</Modal>
				</Row>
				<Col xl={8} lg={8} md={8} sm={24} xs={24} className="non_wps_payroll_button">
					{/* <Link to="/payroll/run/channel/now_or_later"> */}
					<EdenredButton disabled={selectedMonth && selectedYear ? false : true} onClick={() => handlenect()}>
						Next
					</EdenredButton>
					{/* </Link> */}
				</Col>
			</Row>
		</>
	);
};

export default NonWpsPayroll;
