const LEAVE_APPROVAL_TEMPLATE_START = 'LEAVE_APPROVAL_TEMPLATE_START';
const LEAVE_APPROVAL_TEMPLATE_SUCCESS = 'LEAVE_APPROVAL_TEMPLATE_SUCCESS';
const LEAVE_APPROVAL_TEMPLATE_FAIL = 'LEAVE_APPROVAL_TEMPLATE_FAIL';
const LEAVE_APPROVAL_UPLOAD_START = 'LEAVE_APPROVAL_UPLOAD_START';
const LEAVE_APPROVAL_UPLOAD_SUCCESS = 'LEAVE_APPROVAL_UPLOAD_SUCCESS';
const LEAVE_APPROVAL_UPLOAD_FAIL = 'LEAVE_APPROVAL_UPLOAD_FAIL';
const GET_LEAVE_BALANCE_SUBMIT_START = 'GET_LEAVE_BALANCE_SUBMIT_START';
const GET_LEAVE_BALANCE_SUBMIT_SUCCESS = 'GET_LEAVE_BALANCE_SUBMIT_SUCCESS';
const GET_LEAVE_BALANCE_SUBMIT_FAIL = 'GET_LEAVE_BALANCE_SUBMIT_FAIL';
const LEAVE_BALANCE_SUBMIT_START = 'LEAVE_BALANCE_SUBMIT_START';
const LEAVE_BALANCE_SUBMIT_SUCCESS = 'LEAVE_BALANCE_SUBMIT_SUCCESS';
const LEAVE_BALANCE_SUBMIT_FAIL = 'LEAVE_BALANCE_SUBMIT_FAIL';
const SUBMIT_LEAVE_APPROVER = 'SUBMIT_LEAVE_APPROVER';
const SAVE_DOCUMENT_RESET = 'SAVE_DOCUMENT_RESET';

const leaveApproveTemplateStart = () => {
	return {
		type: LEAVE_APPROVAL_TEMPLATE_START,
	};
};
const leaveApproveTemplateSuccess = () => {
	return {
		type: LEAVE_APPROVAL_TEMPLATE_SUCCESS,
	};
};
const leaveApproveTemplateFail = () => {
	return {
		type: LEAVE_APPROVAL_TEMPLATE_FAIL,
	};
};

const leaveApproveUploadStart = (payload) => {
	return {
		type: LEAVE_APPROVAL_UPLOAD_START,
		payload,
	};
};
const leaveApproveUploaduccess = (res) => {
	return {
		type: LEAVE_APPROVAL_UPLOAD_SUCCESS,
		res,
	};
};
const leaveApproveUploadFail = () => {
	return {
		type: LEAVE_APPROVAL_UPLOAD_FAIL,
	};
};

const getLeaveBalanceStart = (payload) => {
	return {
		type: GET_LEAVE_BALANCE_SUBMIT_START,
		payload,
	};
};
const getLeaveBalanceSuccess = (res) => {
	return {
		type: GET_LEAVE_BALANCE_SUBMIT_SUCCESS,
		res,
	};
};
const getLeaveBalanceFail = () => {
	return {
		type: GET_LEAVE_BALANCE_SUBMIT_FAIL,
	};
};

const leaveBalanceStart = (payload) => {
	return {
		type: LEAVE_BALANCE_SUBMIT_START,
		payload,
	};
};
const leaveBalanceSuccess = (res) => {
	return {
		type: LEAVE_BALANCE_SUBMIT_SUCCESS,
		res,
	};
};
const leaveBalanceFail = () => {
	return {
		type: LEAVE_BALANCE_SUBMIT_FAIL,
	};
};
const submitLeaveApprove = (res) => ({
	type: SUBMIT_LEAVE_APPROVER,
	res,
});
const saveDocumentUploadReset = () => ({
	type: SAVE_DOCUMENT_RESET,
});
export default {
	types: {
		LEAVE_APPROVAL_TEMPLATE_START,
		LEAVE_APPROVAL_TEMPLATE_SUCCESS,
		LEAVE_APPROVAL_TEMPLATE_FAIL,
		LEAVE_APPROVAL_UPLOAD_START,
		LEAVE_APPROVAL_UPLOAD_SUCCESS,
		LEAVE_APPROVAL_UPLOAD_FAIL,
		GET_LEAVE_BALANCE_SUBMIT_START,
		GET_LEAVE_BALANCE_SUBMIT_SUCCESS,
		GET_LEAVE_BALANCE_SUBMIT_FAIL,
		LEAVE_BALANCE_SUBMIT_START,
		LEAVE_BALANCE_SUBMIT_SUCCESS,
		LEAVE_BALANCE_SUBMIT_FAIL,
		SUBMIT_LEAVE_APPROVER,
		SAVE_DOCUMENT_RESET,
	},
	creators: {
		leaveApproveTemplateStart,
		leaveApproveTemplateSuccess,
		leaveApproveTemplateFail,
		leaveApproveUploadStart,
		leaveApproveUploaduccess,
		leaveApproveUploadFail,
		leaveBalanceStart,
		leaveBalanceSuccess,
		leaveBalanceFail,
		getLeaveBalanceStart,
		getLeaveBalanceSuccess,
		getLeaveBalanceFail,
		submitLeaveApprove,
		saveDocumentUploadReset,
	},
};
