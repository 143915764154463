import React from 'react';
import { Row } from 'antd';
import Chips from '../index';

const ChipsExample = () => {
	return (
		<div style={{ width: '70%' }}>
			<Row style={{ justifyContent: 'center', gap: '10px' }}>
				<Chips color="#CEE8FF">HR</Chips>
				<Chips color="#FEF5E8">Teams</Chips>
				<Chips color="#CCECE6">Manage Employee</Chips>
				<Chips color="#E2D2FE">Payroll</Chips>
				<Chips color="#D52B1E">
					<p style={{ marginBottom: '0px', color: 'white' }}>Announcement</p>
				</Chips>
				<Chips color="#FFBECB">Leaves</Chips>
			</Row>
		</div>
	);
};

export default ChipsExample;
