const GET_PAYROLL_START = 'GET_PAYROLL_START';
const GET_PAYROLL_SUCCESS = 'GET_PAYROLL_SUCCESS';
const CLEAR_PAYROLL = 'CLEAR_PAYROLL';
const GET_PAYROLL_FAIL = 'GET_PAYROLL_FAIL';
const GET_CONTRACTTYPE_START = 'GET_CONTRACTTYPE_START';
const GET_CONTRACTTYPE_SUCCESS = 'GET_CONTRACTTYPE_SUCCESS';
const GET_CONTRACTTYPE_FAIL = 'GET_CONTRACTTYPE_FAIL';
const GET_DEPARTMENT_START = 'GET_DEPARTMENT_START';
const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
const GET_DEPARTMENT_FAIL = 'GET_DEPARTMENT_FAIL';
const GET_ALLOWANCE_START = 'GET_ALLOWANCE_START';
const GET_ALLOWANCE_SUCCESS = 'GET_ALLOWANCE_SUCCESS';
const GET_ALLOWANCE_FAIL = 'GET_ALLOWANCE_FAIL';
const GET_PAYROLLSUBMIT_START = 'GET_PAYROLLSUBMIT_START';
const GET_PAYROLLSUBMIT_SUCCESS = 'GET_PAYROLLSUBMIT_SUCCESS';
const GET_PAYROLLSUBMIT_FAIL = 'GET_PAYROLLSUBMIT_FAIL';
const HANDLE_SETTING_SAVE_POPUP = 'HANDLE_SETTING_SAVE_POPUP';
const SAVED_SETTING_SUCCEESSFUL_POPUP = 'SAVED_SETTING_SUCCEESSFUL_POPUP';

const getPayrollStart = (employeeId) => {
	return {
		type: GET_PAYROLL_START,
		employeeId,
	};
};
const getPayrollSuccess = (response) => {
	return {
		type: GET_PAYROLL_SUCCESS,
		response,
	};
};
const clearPayroll = () => ({
	type: CLEAR_PAYROLL,
});
const getPayrollFail = (error) => {
	return {
		type: GET_PAYROLL_FAIL,
		error,
	};
};
const getContractTypeStart = () => {
	return {
		type: GET_CONTRACTTYPE_START,
	};
};
const getContractTypeSuccess = (response) => {
	return {
		type: GET_CONTRACTTYPE_SUCCESS,
		response,
	};
};
const getContractTypeFail = (error) => {
	return {
		type: GET_CONTRACTTYPE_FAIL,
		error,
	};
};
const getDepartmentStart = () => {
	return {
		type: GET_DEPARTMENT_START,
	};
};
const getDepartmentSuccess = (response) => {
	return {
		type: GET_DEPARTMENT_SUCCESS,
		response,
	};
};
const getDepartmentFail = (error) => {
	return {
		type: GET_DEPARTMENT_FAIL,
		error,
	};
};
const getAllowanceStart = () => {
	return {
		type: GET_ALLOWANCE_START,
	};
};
const getAllowanceSuccess = (response) => {
	return {
		type: GET_ALLOWANCE_SUCCESS,
		response,
	};
};
const getAllowanceFail = (error) => {
	return {
		type: GET_ALLOWANCE_FAIL,
		error,
	};
};
const getPayrollSubmitStart = (payload) => {
	return {
		type: GET_PAYROLLSUBMIT_START,
		payload,
	};
};
const getPayrollSubmitSuccess = (response) => {
	return {
		type: GET_PAYROLLSUBMIT_SUCCESS,
		response,
	};
};
const getPayrollSubmitFail = (error) => {
	return {
		type: GET_PAYROLLSUBMIT_FAIL,
		error,
	};
};
const handleSettingSavePopUp = (flag) => {
	return {
		type: HANDLE_SETTING_SAVE_POPUP,
		flag,
	};
};
const savedSettingSucceessfulPopUp = (flag) => {
	return {
		type: SAVED_SETTING_SUCCEESSFUL_POPUP,
		flag,
	};
};

export default {
	types: {
		GET_PAYROLL_START,
		GET_PAYROLL_SUCCESS,
		CLEAR_PAYROLL,
		GET_PAYROLL_FAIL,
		GET_CONTRACTTYPE_START,
		GET_CONTRACTTYPE_SUCCESS,
		GET_CONTRACTTYPE_FAIL,
		GET_DEPARTMENT_START,
		GET_DEPARTMENT_SUCCESS,
		GET_DEPARTMENT_FAIL,
		GET_ALLOWANCE_START,
		GET_ALLOWANCE_SUCCESS,
		GET_ALLOWANCE_FAIL,
		GET_PAYROLLSUBMIT_START,
		GET_PAYROLLSUBMIT_SUCCESS,
		GET_PAYROLLSUBMIT_FAIL,
		HANDLE_SETTING_SAVE_POPUP,
		SAVED_SETTING_SUCCEESSFUL_POPUP,
	},
	creators: {
		getPayrollStart,
		getPayrollSuccess,
		clearPayroll,
		getPayrollFail,
		getContractTypeStart,
		getContractTypeSuccess,
		getContractTypeFail,
		getDepartmentStart,
		getDepartmentSuccess,
		getDepartmentFail,
		getAllowanceStart,
		getAllowanceSuccess,
		getAllowanceFail,
		getPayrollSubmitStart,
		getPayrollSubmitSuccess,
		getPayrollSubmitFail,
		handleSettingSavePopUp,
		savedSettingSucceessfulPopUp,
	},
};
