import React, { useState, useEffect, useRef } from 'react';
import { APP_VARIABLES } from '@constants/app-constants';
import { CustomAlertNotification } from '@pages/Leave-Management/request-leave/alert-notification/alert-notification';
import { checkHrSubscription } from '@helpers';
import ClevertapReact from 'src/utils/clever-tap';
import { Col, Row } from 'antd';
import { DotLoader } from 'react-spinners';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { gsap } from 'gsap';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import moment from 'moment';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import PayrollPlusNewTableHeader from './payroll-plus-new-table-header';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Table from '@pages/DesignSystem/Table/Table';
import useOnClickOutside from './useOnClickOutside';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WpsNonWpsPopupFunctional from '@pages/payroll/run-payroll/components/wps-nonwps-popup-functional';
import '../payroll-plus-new.scss';
import ContentLoader from '@sharedComponent/content-loader';

const PayrollPlusNewHrFeaturePresentational = ({
	columns,
	dropDownValueState,
	payrollFilter,
	setPayrollFilter,
	empData,
	onChangeHandler,
	handlePagination,
	payrollSalaryStatus,
	scrollEvent,
	toogleScroll,
	popupFlag,
	totalSalary,
	employeeTotalCount,
	selectionListener,
	handleNext,
	onChange,
	date,
	setDate,
	popupValue,
	requestParams,
	onChangePopup,
	nextHide,
	buttonDisable,
	handleChange,
	isPopupVisible,
	setIsPopupVisible,
	setDropDownValueState,
}) => {
	const [arrow, setArrow] = useState({ PayrollChannel: false, AccountType: false, EstablishmentID: false, BankName: false });
	const { updateStagingLoader } = useSelector((state) => state?.counter);
	const ref = useRef(null);
	let tableRef = useRef(null);
	gsap.registerPlugin(ScrollToPlugin);
	useOnClickOutside(ref, () => {
		if (payrollFilter) setPayrollFilter(false);
	});
	const location = useLocation();
	const salaryMonthData = location?.state?.salaryMonthData;
	const { dynamicHeaderSuccess } = useSelector((state) => state?.counter);
	var totalSalaryFormated = totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 });
	const handlePayrollPlusFilterSelect = (data) => {
		handleButton(data?.label);
		handleChange(data?.label, data?.value, data?.selected);
	};
	const handleButton = (data) => {
		switch (data) {
			case 'Payroll Channel':
				ClevertapReact.event('Filter_PayrollChannel');
				setArrow({ ...arrow, PayrollChannel: !arrow.PayrollChannel });
				break;
			case 'Account Type':
				ClevertapReact.event('Filter_AccountType');
				setArrow({ ...arrow, AccountType: !arrow.AccountType });
				break;
			case 'Establishment ID':
				ClevertapReact.event('Filter_EstID');
				setArrow({ ...arrow, EstablishmentID: !arrow.EstablishmentID });
				break;
			case 'Bank Name':
				ClevertapReact.event('Filter_BankName');
				setArrow({ ...arrow, BankName: !arrow.BankName });
				break;
		}
	};
	const handleDate = (data) => {
		return data.map((item) => {
			if (moment().format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY') && item?.salaryProcessStatus === 'Salaries paid') {
				setDate({
					salaryDate: item?.salaryDate,
					salaryProcessStatus: item?.salaryProcessStatus,
				});
			} else {
				const final = data?.filter((final) => final?.salaryProcessStatus === 'Salaries Paid');
				const dataa = final?.length - 1;
				if (
					salaryMonthData
						? moment(salaryMonthData?.salaryDate).format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY')
						: moment(final[dataa]?.salaryDate).add(1, 'M').format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY')
				) {
					setDate({
						salaryDate: item?.salaryDate,
						salaryProcessStatus: item?.salaryProcessStatus,
					});
				}
			}

			return item;
		});
	};
	const leaveDetail = [
		{
			name: 'Name,Employee ID',
			value: payrollSalaryStatus?.map((salariesStatusData) => {
				if (salariesStatusData?.salaryProcessStatus === 'Salaries Paid') {
					return { ...salariesStatusData, salaryProcessStatus: 'Paid', isActive: false };
				} else if (salariesStatusData?.salaryProcessStatus === 'Salaries Due') {
					return { ...salariesStatusData, salaryProcessStatus: 'Salaries Due', isActive: true };
				} else {
					return salariesStatusData;
				}
			}),
			param: 'nameId',
		},
	];
	var payroll = requestParams.payrollChannel;
	var payroll1 = payroll === '' ? 0 : payroll.split(',');
	var payrollcount = payroll1.length;
	var account = requestParams.accountType;
	var account1 = account === '' ? 0 : account.split(',');
	var accountcount = account1.length;
	var bank = requestParams.bankName;
	var bank1 = bank === '' ? 0 : bank.split(',');
	var bankcount = bank1.length;
	var establishment = requestParams.establishment;
	var establishment1 = establishment === '' ? 0 : establishment.split(',');
	var establishmentcount = establishment1.length;
	var selectcount =
		(payrollcount === undefined ? 0 : payrollcount) +
		(accountcount === undefined ? 0 : accountcount) +
		(establishmentcount === undefined ? 0 : establishmentcount) +
		(bankcount === undefined ? 0 : bankcount);
	useEffect(() => {
		handleDate(payrollSalaryStatus);
	}, [payrollSalaryStatus]);
	const [popupNew, setPopupNew] = useState(false);
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	useEffect(() => {
		if (!isOnlyEmployeeExits && navbarClaims && (!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('payroll')) && isSubcribed) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);
	const handleClose = () => {
		setPopupNew(false);
	};
	const dataObj = [
		{
			image: not_focus_icons.Payro1,
			text: 'Store and manage employee contract information',
		},
		{
			image: not_focus_icons.Payroll2,
			text: 'Automate your payroll and overtime calculations',
		},
		{
			image: not_focus_icons.Payroll3,
			text: 'Your employees get payslips with breakdowns of salaries',
		},
		{
			image: not_focus_icons.Payroll4,
			text: 'Download department-wise salary reports',
		},
	];
	return (
		<Row className="payroll-plus-new-top">
			<CustomAlertNotification
				{...{
					when: onChangePopup,
					message: 'Do you want to leave this page? Your changes will not be saved',
					okText: 'Yes, I want to leave',
					cancelText: 'Stay on Page',
					onOK: () => true,
					onCancel: () => true,
					popupValue,
					selectedTab: '2',
				}}
			/>
			<Col xs={24} className="payroll-plus-new">
				<div className="payroll_plus_new_header">
					<ETypo bold h2 className="payroll-plus-typo">
						Run Payroll
					</ETypo>
					<div className="run_payroll_plus">
						<div className="payroll_plus_amount">
							<ETypo light b1 color="#737780">
								Payroll Amount:
							</ETypo>
							<ETypo medium b1 color="#00344E">{`AED ${totalSalaryFormated} for ${employeeTotalCount.size} employees`}</ETypo>
						</div>
						<div className="payroll_salary_dropdown">
							<MultiSelect
								{...{
									Option: leaveDetail?.[0]?.value,
									value: date?.salaryDate,
									onChange: onChange,
									dropDownValue: 'salaryDate',
									dropDownSubValue: 'salaryProcessStatus',
									dateFormat: 'MMMM YYYY',
									showIcon: false,
									prefix: <img src={not_focus_icons?.payrollCalender} />,
								}}
							/>
						</div>
						<EdenredButtons
							btnType="primary"
							disabled={!nextHide || buttonDisable}
							className="payroll-plus-newNext"
							toolTipClassName="width_100_percentage"
							loading={updateStagingLoader}
							onClick={() => {
								handleNext(empData.selectedData, moment(date?.salaryDate).format('MM'), moment(date?.salaryDate).format('YYYY'));
							}}>
							Next
						</EdenredButtons>
					</div>
				</div>
				<div className="payroll_plus_new_table">
					<PayrollPlusNewTableHeader
						{...{
							onChangeHandler,
							handlePagination,
							requestParams,
							handlePayrollPlusFilterSelect,
							dropDownValueState,
							setDropDownValueState,
							uploadSpreadsheetShow: false,
						}}
					/>
					<div className="table-outer" ref={(el) => (tableRef = el)}>
						{empData?.tableData?.length === 0 && dynamicHeaderSuccess ? (
							<Col style={{ minHeight: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
								<DotLoader size={50} color={'#e91d24'} loading />
							</Col>
						) : (
							<Table {...{ columns, data: empData?.tableData, sticky: true, onRowSelectedColor: '#F7F8FA' }} />
						)}
					</div>
					<div className="payroll_plus_pagination">
						<div className="payroll-plus-pagination-count">
							<ETypo light small color="#484B52">
								{empData?.tableData?.length} out of {location?.state?.empList?.length} Employees
							</ETypo>
						</div>
					</div>
				</div>
			</Col>

			<WpsNonWpsPopupFunctional {...{ isPopupVisible, setIsPopupVisible }} />
		</Row>
	);
};

export default PayrollPlusNewHrFeaturePresentational;
