import React, { useState } from 'react';
import EdenredTypo from '@sharedComponent/typography';
import SelectDropdown from '@pages/DesignSystem/ComboBox/SelectDropdown';
import { options } from '@pages/DesignSystem/Json/DesignSystem';
import '../SelectDropdown.scss';

const DefaultDropdown = () => {
	const [data, setData] = useState('');
	console.log('data', data);
	return (
		<div style={{ width: '50%', paddingTop: '10px' }}>
			<EdenredTypo h2 bold style={{ fontSize: '18px' }}>
				Default Select Dropdown
			</EdenredTypo>
			<SelectDropdown
				className="select-dropdown-without-placeholder"
				// onChange={(data) => console.log('datass', data)}
				onChange={(data) => setData(data)}
				Option={options}
				placeholder="Waterfall Search"
				searchVisibility={false}
			/>
		</div>
	);
};

export default DefaultDropdown;
