import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { SITEMAP } from 'src/routes/sitemap';
import UpdateEmployees from '../components/UpdateEmployees';
import EmployeePreview from '../components/EmployeePreview';
import EmployeeUnderReview from '../components/EmployeeUnderReview';
import { employeeVerifyActions } from '../redux';

const VerificationOnDashboard = () => {
	const employeeVerification = useSelector((state) => state?.employees?.verification);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!employeeVerification) {
			dispatch(employeeVerifyActions.creators.employeeVerify());
		}
	}, [employeeVerification]);
	return (
		<>
			<Route path={SITEMAP.employees.verification.dashboard.employeeUnderReview} exact component={EmployeeUnderReview} />
			<Route path={SITEMAP.employees.verification.dashboard.employeePreview} exact component={EmployeePreview} />
			<Route path={SITEMAP.employees.verification.dashboard.index} exact component={UpdateEmployees} />
		</>
	);
};

export default VerificationOnDashboard;
