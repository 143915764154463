import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect } from 'react-redux';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import HintCard from '@pages/DesignSystem/hindcard';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { onlyNumberForNumberType } from '@helpers';
import InvoiceDragger from './invoice-dragger';
import TimeInput from './time-input';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import moment from 'moment';
import ETypo from '@pages/DesignSystem/Typo';

const ChequeDeposit = ({ isFileSize, modalDrag, allowedFileTypes, chequeDepositPayload, onChangeInvoiceForm, clearInvoiceFileState }) => {
	const invoiceOnChange = (info) => {
		onChangeInvoiceForm((preState) => ({ ...preState, InvoiceReceiptFile: info }));
	};

	const getTimeVal = (val) => {
		onChangeInvoiceForm((preState) => ({ ...preState, PaymentTime: val }));
	};

	const handlePaymentDate = (dateObj, formatStr) => {
		onChangeInvoiceForm((preState) => ({ ...preState, PaymentDate: formatStr }));
	};
	const { Amount, PaymentTime, PaymentDate, ATMDigit, ChequeNumber } = chequeDepositPayload;
	return (
		<Col>
			<Col
				xl={24}
				lg={24}
				md={24}
				sm={24}
				xs={24}
				className=""
				style={{
					minHeight: '150px',
					boxShadow: '0px 16px 32px -24px #BDBDBD',
					backgroundColor: '#FFFFFF',
					marginBottom: '15px',
					borderRadius: '8px',
					padding: '32px',
				}}>
				<ETypo bold h4 style={{ paddingBottom: '24px', display: 'inline-block' }}>
					Step #1 - Deposit Cheque at any RAKBANK ATM or Branch
				</ETypo>
				<div style={{ padding: '16px', background: '#F9FAFB' }}>
					<div className="bank-title">
						<ETypo b1 medium className="bank-acc-title">
							Beneficiary Details
						</ETypo>
					</div>
					<div className="cash-deposit-bank-details">
						<div className="cash-deposit-row">
							<div className="cash-deposit-label">Beneficiary Name:</div>
							<div>Edenred Prepaid Cards Management Services LLC</div>
						</div>
					</div>
				</div>
			</Col>
			<div
				style={{
					minHeight: '150px',
					boxShadow: '0px 16px 32px -24px #BDBDBD',
					backgroundColor: '#FFFFFF',
					padding: '32px',
					borderRadius: '8px',
				}}>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="dragger_submit">
					<ETypo bold h4 style={{ paddingBottom: '24px', display: 'inline-block' }}>
						Step #2 - Upload Proof of Transfer Funds
					</ETypo>
					<InvoiceDragger
						loadingState={chequeDepositPayload.isSavedSuccess}
						onRemoveUpload={clearInvoiceFileState}
						onChangeHandler={(info) => invoiceOnChange(info)}
					/>
				</Col>
				{(isFileSize || modalDrag) && (
					<Col style={{ marginTop: '10px' }}>
						<HintCard
							{...{
								icon: focus_state_icons.hrDocumentExpired,
								bgColor: '#fff6f5',
								hint: isFileSize
									? 'File must be smaller than 4MB!'
									: modalDrag
									? `Invalid format. Please upload one of these formats ${allowedFileTypes.join()}`
									: '',
							}}
						/>
					</Col>
				)}
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="ProofOfInvoice_payment">
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<Inputbox
							label="Payment Amount On Slip"
							prefix={'AED'}
							required={true}
							value={Amount}
							onChange={(e) => {
								let val = e.target.value;
								onChangeInvoiceForm((preState) => ({ ...preState, Amount: val }));
							}}
							type="number"
							onKeyDown={(event) => onlyNumberForNumberType(event)}
							placeholder="Enter amount on slip"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<DatePickers
							{...{
								value: PaymentDate,
								onChange: (dateObj, formatStr) => handlePaymentDate(dateObj, formatStr),
								label: 'Date of Slip',
								required: true,
							}}
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<TimeInput {...{ value: PaymentTime, getTimeVal, label: 'Time of Payment', required: true }} />
					</Col>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="ProofOfInvoice_payment">
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<Inputbox
							label="First 4 Digit of ATM"
							required={true}
							value={ATMDigit}
							onChange={(e) => {
								if (e.target.value?.length <= 4) {
									let val = e.target.value;
									onChangeInvoiceForm((preState) => ({ ...preState, ATMDigit: val }));
								}
							}}
							type="number"
							onKeyDown={(event) => onlyNumberForNumberType(event)}
							placeholder="XXXX"
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<Inputbox
							label="Cheque Number"
							value={ChequeNumber}
							required={true}
							onChange={(e) => {
								let val = e.target.value;
								onChangeInvoiceForm((preState) => ({ ...preState, ChequeNumber: val }));
							}}
							type="number"
							onKeyDown={(event) => onlyNumberForNumberType(event)}
							placeholder="Enter Number"
						/>
					</Col>
				</Col>
			</div>
		</Col>
	);
};
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(ChequeDeposit);
