import React from 'react';
import ModalOne from './model-one';
import ModalTwo from './model-two';
import ModalThree from './model-three';
import ModalFour from './model-four';
import ModalFive from './model-five';
import ModalSix from './model-six';
import ModalSeven from './model-seven';
import ModalEight from './model-eight';
import ModalNine from './model-nine';
import ModalTen from './model-ten';
import ModalEleven from './model-eleven';
import ModalTwelve from './model-twelve';
import ModalThirteen from './model-thirteen';
import ModalFourteen from './model-fourteen';
import ModalFifteen from './model-fifteen';
import ModalSixteen from './model-sixteen';
import ModalSeveteen from './model-seveteen';
import ModalEightten from './model-eighteen';
const Modelexamples = () => {
	return (
		<>
			<ModalOne />
			<ModalTwo />
			<ModalThree />
			<ModalFour />
			<ModalFive />
			<ModalSix />
			<ModalSeven />
			<ModalEight />
			<ModalNine />
			<ModalTen />
			<ModalEleven />
			<ModalTwelve />
			<ModalThirteen />
			<ModalFourteen />
			<ModalFifteen />
			<ModalSixteen />
			<ModalSeveteen />
			<ModalEightten />
		</>
	);
};

export default Modelexamples;
