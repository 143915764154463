import { country_flags } from './country_flags';
import { emoji } from './emoji';
import { focus_state_icons } from './focus_state';
import { not_focus_icons } from './not_focus';

export const icons = {
	emoji: emoji,
	focus_state: focus_state_icons,
	not_focus: not_focus_icons,
	countries: country_flags,
};
