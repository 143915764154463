import React, { useState } from 'react';
import propTypes from 'prop-types';
import './input-field.scss';
const Inputbox = ({
	type = 'text',
	placeholder = 'Input',
	disabled = false,
	label,
	value,
	prefix,
	maxLength = '',
	onChange,
	pattern,
	rightIcon = '',
	leftImage,
	onFocus,
	onBlur,
	onKeyDown,
	onKeyUp,
	className,
	isErrorMessage = false,
	errorMessage = 'Error Message',
	errorIcon,
	onClick,
	onInput,
	loading = false,
	name,
	required = false,
	inputType = 'input',
}) => {
	const Component = inputType === 'input' ? 'input' : 'textarea';
	return (
		<div className="input_component_main">
			{label && (
				<div className="input-label-top">
					{label}
					{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
				</div>
			)}
			<div
				className={`${className} input-box ${isErrorMessage ? 'error-message' : ''} ${value ? 'input-value' : 'input-empty'} ${
					disabled ? 'input-disabled' : ''
				}`}>
				{leftImage && leftImage}
				{prefix ? <span className={leftImage ? 'input-number-icon ' : 'prefix'}>{prefix}</span> : null}
				<Component
					{...{
						name,
						type,
						value,
						placeholder,
						disabled,
						label,
						onChange,
						onFocus,
						onBlur,
						onKeyDown,
						onKeyUp,
						maxLength,
						pattern,
						className: 'input-text-newbox',
						onClick,
						onInput,
						name,
					}}
				/>
				{loading ? <div className="input_loader" /> : isErrorMessage ? errorIcon : rightIcon && rightIcon}
			</div>
			{isErrorMessage && <p className="error-message-text">{errorMessage}</p>}
		</div>
	);
};

export default Inputbox;

Inputbox.propTypes = {
	type: propTypes.string,
	placeholder: propTypes.string,
	disabled: propTypes.bool,
	label: propTypes.string,
	prefix: propTypes.string,
	maxLength: propTypes.number,
	onChange: propTypes.func,
	pattern: propTypes.string,
	rightIcon: propTypes.element,
	leftImage: propTypes.element,
	onFocus: propTypes.func,
	onBlur: propTypes.func,
	onKeyDown: propTypes.func,
	onKeyUp: propTypes.func,
};
