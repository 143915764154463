import React, { useEffect } from 'react';

const CustomToast = ({ children, icon, visible = false, clearPopUp = () => {}, success = false, warning = false, error = false }) => {
	useEffect(() => {
		if (visible) {
			const timer = setTimeout(() => {
				clearPopUp();
				clearTimeout(timer);
			}, 2500);
		}
	}, [visible, clearPopUp]);

	function statusType() {
		if (success && !warning && !error) {
			return 'success';
		} else if (warning && !success && !error) {
			return 'warning';
		} else if (error && !warning && !success) {
			return 'error';
		} else return '';
	}

	return (
		<div className={`status-toast-wrapper ${visible ? 'show' : 'hide'} ${statusType()}`}>
			{icon}
			{children}
		</div>
	);
};

export default CustomToast;
