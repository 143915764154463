import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import saga from './saga/index';
import reducer from './reducer';
import PayrollRunFunctional from './component/payroll-run-functional';

function PayRollRun({
	employeeDetails,
	setDefaultTab,
	getEmployeeById,
	setNavigateTabCheck,
	navigateTabCheck,
	additions,
	setAdditions,
	deductions,
	setDeductions,
	gratuity,
	setGratuity,
	runEmployeeAllowanceList,
	setRunEmployeeAllowanceList,
	notification,
	setNotification,
	employeeAllowance,
	setEmpolyeeAllowance,
	empolyeeSalary,
	setEmpolyeeSalary,
	termiation,
	setTerminationType,
	overTimes,
	setOverTimes,
	month,
	setmonth,
	year,
	setyear,
	savePayrollValue,
	setSaveValue,
	gratutiycal,
	setGratuityCal,
	leavingDate,
	setLeavingDate,
	terminationId,
	setTerminationId,
}) {
	return (
		<>
			<PayrollRunFunctional
				{...{
					employeeDetails,
					setDefaultTab,
					getEmployeeById,
					setNavigateTabCheck,
					navigateTabCheck,
					additions,
					setAdditions,
					deductions,
					setDeductions,
					gratuity,
					setGratuity,
					runEmployeeAllowanceList,
					setRunEmployeeAllowanceList,
					notification,
					setNotification,
					employeeAllowance,
					setEmpolyeeAllowance,
					empolyeeSalary,
					setEmpolyeeSalary,
					termiation,
					setTerminationType,
					overTimes,
					setOverTimes,
					month,
					setmonth,
					year,
					setyear,
					savePayrollValue,
					setSaveValue,
					gratutiycal,
					setGratuityCal,
					leavingDate,
					setLeavingDate,
					terminationId,
					setTerminationId,
				}}
			/>
		</>
	);
}
const ReducedScreen = withReducer('PayRollRunReducer', reducer, saga)(PayRollRun);
export default ReducedScreen;
