export const focus_state_icons = {
	RightArrowBanner: require('./right-arrow.svg'),
	AvoidFine: require('./avoid-fine.svg'),
	ErrorIcon: require('./error-icon.svg'),
	InvoiceArrow: require('./invoice-arrow.svg'),
	InfoWarnig: require('./info-warnig.svg'),
	UnEmploymentUser: require('./un-employment-user.svg'),
	UnEmploymentGroupUser: require('./un-employment-group-user.svg'),
	Copy: require('./copy.svg'),
	InvoiceWeather: require('./invoice-weather.svg'),
	InvoiceRightArrow: require('./invoice-right-arrow.svg'),
	InvoiceDownloadArrow: require('./invoice-download-arrow.svg'),
	InvoicePaymentHistory: require('./invoice-payment-history.svg'),
	InvoiceMoney: require('./invoice-money.svg'),
	InvoiceFocus: require('./invoice-focus.svg'),
	AddCard: require('./AddCard.svg'),
	AddUser: require('./AddUser.svg'),
	AddUsers: require('./AddUsers.svg'),
	Approvals: require('./Approvals.svg'),
	ApproveCards: require('./ApproveCards.svg'),
	Balance: require('./Balance.svg'),
	BankTransfer: require('./BankTransfer.svg'),
	Cards: require('./Cards.svg'),
	CardStatement: require('./CardStatement.svg'),
	CashPickUp: require('./CashPickUp.svg'),
	Check: require('./Check.svg'),
	Cheque: require('./Cheque.svg'),
	Clock: require('./Clock.svg'),
	CustomerService: require('./CustomerService.svg'),
	Company_info: require('./Info.svg'),
	Dashboard: require('./Dashboard.svg'),
	Deals: require('./Deals.svg'),
	Document: require('./Document.svg'),
	Download: require('./Download.svg'),
	Download_Salary: require('./Download_Salary.svg'),
	Email: require('./Email.svg'),
	EmployeeManage: require('./EmployeeManage.svg'),
	Focus: require('./focus.svg'),
	ATM: require('./ATM.svg'),
	CardsUsage: require('./cards-usage.svg'),
	Error: require('./Error.svg'),
	ExchangeRates: require('./ExchangeRates.svg'),
	Eye: require('./eye.svg'),
	newUpload: require('./newUpload.svg'),

	Filter: require('./Filter.svg'),
	Gift: require('./Gift.svg'),
	Help: require('./Help.svg'),
	Home: require('./Home.svg'),
	Health: require('./health.svg'),
	ID: require('./ID.svg'),
	Info: require('./Info.svg'),
	Laptop: require('./Laptop.svg'),
	Location: require('./Location.svg'),
	locationBlue: require('./locationBlue.svg'),
	Loyalty: require('./Loyalty.svg'),
	More: require('./More.svg'),
	Notification: require('./Notification.svg'),
	Password: require('./Password.svg'),
	Payroll: require('./Payroll.svg'),
	ReceiptNew: require('./ReceiptNew.svg'),
	Certificate: require('./Certificate.svg'),
	Documents: require('./Documents.svg'),
	PDF: require('./PDF.svg'),
	Search: require('./Search.svg'),
	SendMoney: require('./SendMoney.svg'),
	Repeat: require('./Repeat.svg'),
	SMSServices: require('./SMSServices.svg'),
	Spreadsheet: require('./Spreadsheet.svg'),
	Statements: require('./Statements.svg'),
	Telephone: require('./Telephone.svg'),
	Tip: require('./Tip.svg'),
	TopUp: require('./TopUp.svg'),
	Tracking: require('./Tracking.svg'),
	Trash: require('./Trash.svg'),
	Upload: require('./Upload.svg'),
	User: require('./User.svg'),
	Warning: require('./Warning.svg'),
	Calendar: require('./Calendar.svg'),
	World: require('./World.svg'),
	AppIconNew: require('./AppIconNew.svg'),
	LawNew: require('./LawNew.svg'),
	Calculator: require('./Calculator.svg'),
	OverTime: require('./Overtime.svg'),
	Leave: require('./Leave.svg'),
	Reload: require('./reload.svg'),
	Loading: require('./Loading.gif'),
	Penalty: require('./Penalty.svg'),
	LeftNavigateTutorial: require('./LeftNavigateTutorial.png'),
	RightNavigateTutorial: require('./RightNavigateTutorial.png'),
	Excell: require('./Excell.svg'),
	PDF: require('./PDF.svg'),
	Excell: require('./Excell.svg'),
	MedicalInsurance: require('./Group 378.svg'),
	AllElements: require('./All_Elements.png'),
	Quotes: require('./1_Quotes.png'),
	Price: require('./2_Price.png'),
	Plans: require('./3_Plans.png'),
	AllInOne: require('./4_AllInOne.png'),
	PayRoll1: require('./tutorial-video/PayRoll1.png'),
	PayRoll2: require('./tutorial-video/PayRoll2.png'),
	PayRoll3: require('./tutorial-video/PayRoll3.png'),
	PayRoll4: require('./tutorial-video/PayRoll4.png'),
	SelectedLeft: require('./selectedleftnew.svg'),
	SelectedRight: require('./selectedRightnew.svg'),
	UpIcon: require('./UpIcon.svg'),
	VideoTutorialFocused: require('./VideoTutorialFocused.svg'),
	deleteIcon: require('./deleteIcon.svg'),
	SalaryUnpaid: require('./SalaryPaid.svg'),
	SalaryPaid: require('./SalaryUnpaid.svg'),
	RightIcon: require('./Right.svg'),
	payrollnew: require('./payrollnew.svg'),
	Usersnew: require('./Usersnew.svg'),
	Buildingnew: require('./Buildingnew.svg'),
	leavenew: require('./leavenew.svg'),
	docnew: require('./docnew.svg'),
	announcenew: require('./announcenew.svg'),
	heartnew: require('./heartnew.svg'),
	Gridnew: require('./Gridnew.svg'),
	folderheart: require('./folderheart.svg'),
	helpnew: require('./helpnew.svg'),
	InviteCancelButton: require('./InviteCancel.svg'),
	RightArrow: require('./RightArrow.svg'),
	RightClrArrow: require('./rightarrowClr.svg'),
	NewDelete: require('./Delete.svg'),
	NewTick: require('./tick.svg'),
	NewEdit: require('./NewEdit.svg'),
	NewSearch: require('./NewSearch.svg'),
	NewFilter: require('./NewFilter.svg'),
	NewAddAdmin: require('./NewAddAdmin.svg'),
	NewLastRole: require('./NewLastRole.svg'),
	NewRole: require('./NewRole.svg'),
	Money: require('./Money.svg'),
	moneySlip: require('./money-slip.svg'),
	emptyWalletAdd: require('./empty-wallet-add.svg'),
	MoneyNew: require('./moneyNew.svg'),
	Nocard: require('./noCard.svg'),
	Nohistory: require('./noHistory.svg'),
	hammer: require('./essetional.svg'),
	hrDocumentExpired: require('./NewDesignHrExp.svg'),
	hrMissing: require('./NewDesignHrMissing.svg'),
	hrDocumentUptoDate: require('./NewDesignHrUp2.svg'),
	hrDocumentWarning: require('./NewDesignHrwarning.svg'),
	hrExport: require('./NewDesignHrExport.svg'),
	hrUpload: require('./NewDesignHrUpload.svg'),
	hrUploading: require('./NewDesignHrUploading.svg'),
	NewDesignCheck: require('./NewDesignCheck.svg'),
	NewDesignErro: require('./NewDesignErro.svg'),
	NewDesignFile: require('./NewDesignFile.svg'),
	Setting: require('./Setting.svg'),
	notSetUp: require('./notSetupsvg.svg'),
	setupWarning: require('./setupWarning.svg'),
	setupWarningNew: require('./setupWarningNew.svg'),
	SetupCheck: require('./SetupCheck.svg'),

	Warning_two: require('./Warning_two.svg'),
	SetupCheckNew: require('./SetupChecknew.svg'),
	Sick: require('./sick-leave.svg'),
	SuccessIndicator: require('./successIndicator.svg'),
	pending: require('./pending.svg'),
	paperclip: require('./paperclip.svg'),
	leaveArrow: require('./arrow-right.svg'),
	Annual: require('./Annual-leave-icon.svg'),
	Unpaid: require('./unpaid.svg'),
	Bereavement: require('./bereavement.svg'),
	Paternal: require('./paternal.svg'),
	maternity: require('./maternity.svg'),
	Maternity: require('./maternity.svg'),
	OverLappingClose: require('./leave-close.svg'),
	time: require('./Time.svg'),
	DownArrow: require('./arrowdown.svg'),
	uparrow: require('./arrowup.svg'),
	leaveNote: require('./leave-note.svg'),
	NewUser: require('./NewUsers.svg'),
	NewDocEdit: require('./NewDocEdit.svg'),
	ApproveWorkflowHint: require('./ApproveWorkflowHint.svg'),
	payrollTransferHistory: require('./payrollTransferHistory.svg'),
	salaryGroupUsers: require('./salaryGroupUsers.svg'),
	salaryMoneyWallet: require('./salaryMoneyWallet.svg'),
	salaryUsers: require('./salaryUsers.svg'),
	payslipNoRecord: require('./payslipNoRecord.svg'),
	payslipDownload: require('./payslipDownload.svg'),
	AnnualLeave: require('./AnnualLeave.svg'),
	BereavementLeave: require('./BereavementLeave.svg'),
	MaternityLeave: require('./MaternityLeave.svg'),
	PaternalLeave: require('./PaternityLeave.svg'),
	SickLeave: require('./SickLeave.svg'),
	UnpaidLeave: require('./UnpaidLeave.svg'),
	PasswordLock: require('./PasswordLock.svg'),
	compareqoutes: require('./compareqoutes.svg'),
	customizedplan: require('./customizedplan.svg'),
	bestprice: require('./bestprice.svg'),
	allinone: require('./allinone.svg'),
	healthIncBanner: require('./healthIncBanner.svg'),
	payslipMenuActive: require('./payslipMenuActive.svg'),
	homeMenuActive: require('./homeMenuActive.svg'),
	menuHamberger: require('./menuHamberger.svg'),
	navbarAlert: require('./navbarAlert.svg'),
	menuActiveIndicator: require('./menuActiveIndicator.svg'),
	downloadAttachement: require('./downloadAttachement.svg'),
	bankDetailInfo: require('./BankDetailInformation.svg'),
	bankDetailEdit: require('./BankDetailEdit.svg'),
	searchEmployeeCard: require('./SearchEmployeeCard.svg'),
	cardViewArrow: require('./CardViewArrow.svg'),
	payrollDownloadArrow: require('./PayrollDownloadArrow.svg'),
	radioButtonInactive: require('./radioButtonInactive.svg'),
	radioButtonActive: require('./radioButtonActive.svg'),
	documentView: require('./documentView.svg'),
	editDocument: require('./editDocument.svg'),
	DownloadFiles: require('./DownloadFiles.svg'),
	TimeTransper: require('./TimeTransper.svg'),
	EmailTransper: require('./EmailTransper.svg'),
	RouteSquare: require('./routeSquare.svg'),
	disabledRightArrow: require('./disabledRightArrow.svg'),
	transferSalaryReport: require('./transferSalaryReport.svg'),
	empEssential: require('./EmpEssential.svg'),
	empUploadErrorMessage: require('./empUploadErrorMessage.svg'),
	ignoreaddemployee: require('./ignoreaddemployee.svg'),
	addIndividual: require('./addIndividual.svg'),
	addBulk: require('./addBulk.svg'),
	editDetails: require('./editDetails.svg'),
	filterCheckIn: require('./filterCheckIn.svg'),
	filterCheckOut: require('./filterCheckOut.svg'),
	landingPopup: require('./landing-popup.svg'),
	binIconDelete: require('./BinIconDelete.svg'),
	nextStepClock: require('./NextStepClock.svg'),
	nextStepEmail: require('./NextStepEmail.svg'),
	nextStepEmailOpen: require('./NextStepEmailOpen.svg'),
	nextStepLocation: require('./NextStepLocation.svg'),
	contantedit: require('./contantedit.svg'),
	userNewLogo: require('./UsersNewLogo.svg'),
	bank: require('./bank.svg'),
	receipt: require('./receipt.svg'),
	moneycardorder: require('./Money1.svg'),
	moneyatm: require('./Money2.svg'),
	moneycardUsage: require('./Money3.svg'),
	useremployee: require('./UsersEmployee.svg'),
	mailemployee: require('./EmailEmployee.svg'),
	/// setup screen
	setupApproval: require('./setup-approval.svg'),
	setupBuilding: require('./setup-buildings.svg'),
	setupedit: require('./setup-edit.svg'),
	setupPrograming: require('./setup-programing.svg'),
	setupUser: require('./setup-user.svg'),
	setupWeather: require('./setup-weather.svg'),
	setupWarning: require('./setup-warning.svg'),
	addressIcon: require('./addressIcon.svg'),
	hintIcon: require('./hint-icon.svg'),
	pendeingPayrolluser: require('./payroll-users.svg'),
	pendingPayrollApproval: require('./payrollApproval.svg'),
	messageboxsuccess: require('./messageBox-check.svg'),
	messageboxwarning: require('./messageBox-warning.svg'),
	//// landing page
	setupmoneyicon: require('./setupmoneyicon.svg'),
	setupcalculatoricon: require('./setupcalculatoricon.svg'),
	setupcardicon: require('./setupcardicon.svg'),
	setupfoldericon: require('./setupfoldericon.svg'),
	setupannoucementicon: require('./setupannoucementicon.svg'),
	setupusericon: require('./setupusersicons.svg'),
	setupweathericon: require('./setupweathericon.svg'),
	documentPayslip: require('./documentpayslip.svg'),
	remainder: require('./remainder.svg'),
	emails: require('./Emails.svg'),
	autoLeaves: require('./autoleaves.svg'),
	selectLeftArrow: require('./selectLeftArrow.svg'),
	selectRightArrow: require('./selectRightArrow.svg'),
	warningNew: require('./warningNew.jpg'),
	edit: require('./edit.svg'),
	tableFrame: require('./tableFrame.svg'),
	excelFrame: require('./excelFrame.svg'),
	documentDownload: require('./documentDownload.svg'),
	checkBox: require('./checkbox.svg'),
	information: require('./information.svg'),
	deliveredSuccess: require('./deliveredSuccess.svg'),
	NotificationAnnouncements: require('./NotificationAnnouncements.svg'),
	Date: require('./Date.svg'),
	ClipboardText: require('./ClipboardText.svg'),
	StarIcon: require('./StarIcon.svg'),
	discount: require('./discount.svg'),
	editNew: require('./editNew.svg'),
	newFeature: require('./newFeature.svg'),
	loaderIcon: require('./loaderIcon.svg'),

	// Delete icons
	trashIconDelete: require('./trash-icon.svg'),

	// Unemployment insurance
	hexaganVoiletTick: require('./ticks/hexagan-voilet-tick.svg'),
	hexaganSuccessTick: require('./ticks/hexagan-success-tick.svg'),
	hexaganMissing: require('./missing/hexagan-missing.svg'),
	hexaganPending: require('./pending/hexagan-pending.svg'),
	infoTraingleWarning: require('./info-traingle-warning.svg'),

	edenredLogo: require('./edenred-logo.svg'),

	tawjeehCheckIcon: require('./tawjeeh-check-icon.svg'),
	stopInsuranceIcon: require('./stop-insurance-icon.svg'),
	smallSuccessTick: require('./small-success-tick-square.svg'),
	smallFailCross: require('./small-fail-cross-square.svg'),
	cardBlockIcon: require('./card-block.svg'),
	squareRoundSuccessTick: require('./square-success-round-tick.svg'),
	warningLightHexagon: require('./warning-light-hexagon.svg'), // warning light means the warning yellow color was in light mode
	warningGrayHexagon: require('./warning-gray-hexagan.svg'), // warning light means the warning yellow color was in light mode
	sandLoadingWithBubbleBg: require('./sand-loading-bubble-bg.png')
};
