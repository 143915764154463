import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import UpdateEmployeesHeader from './UpdateEmployeesHeader';
import DownloadSheet from './DownloadSheet';
import UploadSheet from './UploadSheet';
import { SITEMAP } from 'src/routes/sitemap';
import ClevertapReact from 'src/utils/clever-tap';
import './updateEmployees.scss';

const UpdateEmployees = () => {
	const [disableContinueButton, setDisableContinueButton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [proceedToUpload, setproceedToUpload] = useState(false);
	const isEmployeeVerified = useSelector((state) => state.employees.verification);
	const history = useHistory();
	const location = useLocation();
	const { dashboard } = SITEMAP.employees.verification;
	const locationStateUser = location?.state?.user || null;


	useEffect(() => {
		// calling this event whenever user enter this page
		ClevertapReact.event('ICAVerification ');
	}, []);

	useEffect(() => {
		const { invalidEmployees, underReviewEmployees } = isEmployeeVerified || {};
		// if the invalid employees not there and under review employees there means we can route to the employee review page
		if (!invalidEmployees && underReviewEmployees) {
			const routeLink = dashboard.employeeUnderReview;
			if (locationStateUser) {
				history.push({ pathname: routeLink, state: { user: locationStateUser } });
			} else {
				history.push(routeLink);
			}
		}
	}, []);

	return (
		<Row data-testid="update-employees-container-test" className="update_employees_container">
			<Col className="update_employees_container_col_1" data-testid="update-employees-header-test">
				<UpdateEmployeesHeader {...{ disableContinueButton, setproceedToUpload, proceedToUpload, setLoading, loading }} />
			</Col>
			<Col xl={24} md={24} lg={24} className="update_employees_container_col_2 white_outer" data-testid="download-sheet-container-test">
				<DownloadSheet />
			</Col>
			<Col xl={24} md={24} lg={24} className="update_employees_container_col_3 white_outer" data-testid="upload-sheet-container-test">
				<UploadSheet {...{ disableContinueButton, proceedToUpload, setDisableContinueButton, setproceedToUpload, setLoading }} />
			</Col>
		</Row>
	);
};

export default UpdateEmployees;
