import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import VideoTutorialService from 'src/services/videoTutorial-service';
import { images } from 'src/assets/img';
import VideoPlayerPopUpModal from '../../video-player-modal/videoPlayerPopUpModal';
import '../view-all.scss';

const ViewAllPresentational = () => {
	// const _videoTutorialService = new VideoTutorialService();
	const [isvideoModalVisible, setIsvideoModalVisible] = useState(false);
	const [isVideoPaused, setIsVideoPaused] = useState(false);
	// const [videos, setVideos] = useState([]);
	// const [videoLoading, setVideoLoading] = useState(false);
	const [videoLink, setVideoLink] = useState(null);

	// useEffect(() => {
	// 	loadVideoData();
	// }, []);

	let history = useHistory();
	const location = useLocation();

	const {
		state: { titleName, folder_Id },
	} = location;

	// const loadVideoData = () => {
	// 	setVideoLoading(true);
	// 	try {
	// 		let videosOfFolder = [];
	// 		_videoTutorialService.getAllVideos({}, folder_Id).then((response) => {
	// 			let videoData = response?.data?.data;

	// 			videoData.forEach((res, index) => {
	// 				videosOfFolder.push({
	// 					name: res?.name,
	// 					description: res?.description,
	// 					link: res?.link,
	// 					thumbnail: res?.pictures?.sizes?.[1]?.link_with_play_button,
	// 				});
	// 			});
	// 			setVideos([...videosOfFolder]);
	// 			setVideoLoading(false);
	// 		});
	// 	} catch (error) {
	// 		console.log(error);
	// 		setVideoLoading(false);
	// 	}
	// };

	const loadVideo = (link) => {
		setIsvideoModalVisible(true);
		setVideoLink(link);
	};

	const closeVideo = () => {
		setIsvideoModalVisible(false);
		setIsVideoPaused(true);
	};

	const startVideo = () => setIsVideoPaused(false);

	return (
		<>
			<VideoPlayerPopUpModal
				visible={isvideoModalVisible}
				video={videoLink}
				closeVideo={closeVideo}
				isVideoPaused={isVideoPaused}
				startVideo={startVideo}></VideoPlayerPopUpModal>
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="view_all_wrapper">
				<Row className="view_all_header">
					<Col xl={23} lg={23} md={23} sm={23} xs={23} className="header_container_alignments">
						<div className="header_text">
							<p className="header_font">{titleName} Video Tutorials</p>
							<EdenredButtons
								inactive={folder_Id.length == 0 ? true : false}
								onClick={() => history.push({ pathname: '/videoTutorial' })}
								leftIcon={<ImageComponent src={images.leftsarrow} />}
								btnType="secondary"
								style={{ width: 'fit-content' }}>
								Back
							</EdenredButtons>
						</div>
					</Col>
				</Row>
				<Row className="view_all_video_container">
					<Col className="video_wrapper" lg={24} xl={24} md={24} sm={24} xs={24}>
						{folder_Id ? (
							<Row lg={24} xl={24} md={24} sm={24} xs={24} className="main_video_container">
								{folder_Id?.map((item, index) => {
									return (
										<Col
											key={index}
											style={{ marginTop: '20px' }}
											className="video_and_title_holder"
											onClick={() => loadVideo(item?.link)}
											lg={6}
											md={8}
											sm={10}
											xs={24}>
											<Row className="video_holder">
												<img className="viewall_img_container" style={{ objectFit: 'cover' }} src={item?.thumbnail} />
											</Row>
											<Row className="title_holder">
												<EdenredTypo>{item?.name}</EdenredTypo>
											</Row>
										</Col>
									);
								})}
							</Row>
						) : (
							<Row className="video_container">
								<ContentLoader />
							</Row>
						)}
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default ViewAllPresentational;
