import React from 'react';
import { Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './card-image.scss';

const CardImage = ({ topText, headingtext, paraText, Image, left, list, subHead }) => {
	return (
		<Row className="card-image">
			<Col xl={22} lg={24} md={22} sm={22} xs={22}>
				<Row className="card-image-container">
					{left ? (
						<>
							<Col xl={14} lg={14} md={24} sm={24} xs={24}>
								<ImageComponent src={Image} style={{ width: '90%' }} />
							</Col>
							<Col xl={10} lg={6} md={24} sm={24} xs={24}>
								<p className="top-text">{topText}</p>
								<h1 className="heading-text">{headingtext}</h1>
								{list &&
									list?.map((data) => (
										<div className="card-list">
											<ImageComponent src={data?.image} />
											<p>{data?.text}</p>
										</div>
									))}
								<p className="sub-heading-text">{subHead}</p>
								<p className="para-text">{paraText}</p>
							</Col>
						</>
					) : (
						<>
							<Col xl={10} lg={10} md={24} sm={24} xs={24}>
								<p className="top-text">{topText}</p>
								<h1 className="heading-text">{headingtext}</h1>
								{list &&
									list?.map((data) => (
										<div className="card-list">
											<ImageComponent src={data?.image} />
											<p>{data?.text}</p>
										</div>
									))}
								<p className="sub-heading-text">{subHead}</p>
								<p className="para-text">{paraText}</p>
							</Col>
							<Col xl={12} lg={12} md={24} sm={24} xs={24}>
								<ImageComponent src={Image} style={{ width: '90%' }} />
							</Col>
						</>
					)}
				</Row>
			</Col>
		</Row>
	);
};

export default CardImage;
