import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';
import Axios from 'axios';

class CorporateService {
    baseUrl = `${DOMAIN_URL}/Corporates`;
    checkCbdClient() {
        return Axios.get(`${this.baseUrl}/cbdclient-terms-accepted`);
    }
    acceptTermsAndConditions(isAccepted) {
        return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/cbdclient-terms-accepted/save?isCbdClientAcceptedTc=${isAccepted}`);
    }
    getAllCorporates() {
        return Axios.get(this.baseUrl);
    }
}

export default CorporateService;
