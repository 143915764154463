import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './saga/index';
import SetupDoucumentFunctional from './components/setup-document-functional';

function SetupDocumentMain() {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
			<SetupDoucumentFunctional />
		</div>
	);
}
const ReducedScreen = withReducer('employeePayrollReducer', reducer, saga)(SetupDocumentMain);

export default ReducedScreen;
