import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { debounceHandler } from '@helpers';
import counterReducer from '@pages/payroll-plus-new/reducer';
import sagas from '@pages/payroll-plus-new/sagas/index';
import BankDetailsPresentational from './bank-details-presentational';
import Actions from 'src/pages/payroll-plus-new/action';
import { withReducer } from 'src/store/reducerLoader';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';

const BankDetailsFunctional = ({}) => {
	const [transferSalary, setTransferSalary] = useState(true);
	const [payInvoice, setPayInvoice] = useState(false);
	const [accNumber, setAccNumber] = useState('');
	const [transferCode, setTransferCode] = useState('');
	const [showTransferCode, setShowTransferCode] = useState(true);
	const [errorMessage, setErrorMessage] = useState(false);
	const [rakbankInfo, setRakbankInfo] = useState({ transferCode: {}, accountNumber: {} });
	const [showCreatePopUp, setShowCreatePopUp] = useState(false);
	const [proceedPopUp, setProceedPopUp] = useState(false);
	const [initialAccNumber, setInitialAccountNumber] = useState('');

	const dispatch = useDispatch();
	let rakbankDetails = useSelector((state) => state?.sharedStates?.rakbankdetails);
	let loader = useSelector((state) => state?.sharedStates?.isRakbankLoading);
	let postLoader = useSelector((state) => state?.payrollPlusState?.postRakBankDetailsLoading);
	let postBankRes = useSelector((state) => {
		return state?.payrollPlusState?.postRakBankDetailsResponse;
	});

	let isToastMessageAvailable = useSelector((state) => state?.payrollPlusState?.postRakBankToastRes);
	let showParollBnkDetailPopup = useSelector((state) => state?.payrollPlusState?.isRakBankAccountHolder);

	const [tabInfo, setTabInfo] = useState(PAYROLL_CONSTANTS.bankDetails.trasnferFundsTab.data);
	useEffect(() => {
		if (rakbankDetails.length > 0 && typeof rakbankDetails !== 'string') {
			rakbankDetails.map((res) => {
				if (res.nonRakBank === true) {
					rakbankInfo.transferCode = res;
					setTransferCode(res?.accountNumber);
					setAccNumber('');
					setInitialAccountNumber('');
				} else {
					rakbankInfo.accountNumber = res;
					setShowTransferCode(false);
					setAccNumber(res?.accountNumber);
					setInitialAccountNumber(res?.accountNumber);
				}
			});
			setRakbankInfo(rakbankInfo);
		}
	}, [rakbankDetails]);

	const handleTab = (type) => {
		switch (type) {
			case PAYROLL_CONSTANTS.bankDetails.trasnferFundsTab.key:
				setTransferSalary(true);
				setPayInvoice(false);
				setTabInfo(PAYROLL_CONSTANTS.bankDetails.trasnferFundsTab.data);
				break;
			case PAYROLL_CONSTANTS.bankDetails.payInvoicesTab.key:
				setPayInvoice(true);
				setTransferSalary(false);
				setTabInfo(PAYROLL_CONSTANTS.bankDetails.payInvoicesTab.data);
				break;
			default:
				break;
		}
	};

	const createRakankAccDetails = (res, mode) => {
		setAccNumber(res);
		if (res?.toString()?.length == 13) {
			setErrorMessage(false);

			mode == 'inlinEditor' && debounceHandler(res, postBankDetails);
		} else {
			setErrorMessage(true);
		}
	};

	const postBankDetails = (res) => {
		payLaodStructuringAndPosting(res);
	};

	const handleProceeedPopUp = () => {
		setShowTransferCode(true);
		let bankDetailPayload = {};
		if (rakbankInfo?.transferCode?.accountNumber) {
			bankDetailPayload = rakbankInfo.transferCode;
		}
		bankDetailPayload.nonRakBank = true;
		dispatch(Actions.creators.postRakBankDetails(bankDetailPayload));
		setProceedPopUp(false);
	};

	const handleCreatePop = (res) => {
		payLaodStructuringAndPosting(res);
		// setShowCreatePopUp(false);
		setShowTransferCode(false);
	};

	const setPopUpCard = () => {
		dispatch(Actions.creators.isRakBankAccountHolder(true));
	};

	const payLaodStructuringAndPosting = (res) => {
		let bankDetailPayload = {};
		if (rakbankInfo?.accountNumber?.accountNumber) {
			bankDetailPayload = rakbankInfo?.accountNumber;
			bankDetailPayload.accountNumber = res;
		} else {
			bankDetailPayload = rakbankInfo?.transferCode;
			bankDetailPayload.accountNumber = res;
			delete bankDetailPayload?.id;
		}
		bankDetailPayload.nonRakBank = false;

		dispatch(Actions?.creators?.postRakBankDetails(bankDetailPayload));
	};

	const closePopUpCard = (res) => {
		dispatch(Actions.creators.isRakBankAccountHolder(false));
	};
	return (
		<BankDetailsPresentational
			{...{
				transferSalary,
				payInvoice,
				handleTab,
				rakbankInfo,
				accNumber,
				transferCode,
				setAccNumber,
				showTransferCode,
				setShowTransferCode,
				createRakankAccDetails,
				errorMessage,
				loader,
				proceedPopUp,
				setProceedPopUp,
				handleProceeedPopUp,
				setShowCreatePopUp,
				handleCreatePop,
				errorMessage,
				tabInfo,
				postLoader,
				initialAccNumber,
				setPopUpCard,
				showParollBnkDetailPopup,
				closePopUpCard,
			}}
		/>
	);
};
const ReducedScreen = withReducer('payrollPlusState', counterReducer, sagas)(BankDetailsFunctional);
export default ReducedScreen;
