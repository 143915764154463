import Actions from './approval-action';

export const initState = {
	isApprovalWorkflowLoading: false,
	approvalWorkflowDetails: [],
	approvalWorkflowError: '',
	isApprovalWorkflowUsertLoading: false,
	approvalWorkflowUserDetails: [],
	approvalWorkflowUserError: '',
	isApprovalWorkflowSubmitLoading: false,
	approvalWorkflowSubmitted: [],
	approvalWorkflowSubmittedFail: '',
	isSavePopUp: false,
	isSavedSuccessfully: false,
};
const WorkflowReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_APPROVALWORKFLOW_START: {
			return {
				...state,
				isApprovalWorkflowLoading: true,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_SUCCESS: {
			return {
				...state,
				isApprovalWorkflowLoading: false,
				approvalWorkflowDetails: action.response,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_FAIL: {
			return {
				...state,
				isApprovalWorkflowLoading: false,
				approvalWorkflowDetails: [],
				approvalWorkflowError: action.error,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_USER_START: {
			return {
				...state,
				isApprovalWorkflowUsertLoading: true,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_USER__SUCCESS: {
			return {
				...state,
				isApprovalWorkflowUsertLoading: false,
				approvalWorkflowUserDetails: action.response,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_USER__FAIL: {
			return {
				...state,
				isApprovalWorkflowUsertLoading: false,
				approvalWorkflowUserDetails: [],
				approvalWorkflowUserError: action.error,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_SUBMIT_START: {
			return {
				...state,
				isApprovalWorkflowSubmitLoading: true,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_SUBMIT_SUCCESS: {
			return {
				...state,
				isApprovalWorkflowSubmitLoading: false,
				approvalWorkflowSubmitted: action.response,
			};
		}
		case Actions.types.GET_APPROVALWORKFLOW_SUBMIT_FAIL: {
			return {
				...state,
				isApprovalWorkflowSubmitLoading: false,
				approvalWorkflowSubmitted: [],
				approvalWorkflowSubmittedFail: action.error,
			};
		}
		case Actions.types.HANDLE_SAVE_POPUP: {
			return {
				...state,
				isSavePopUp: action.flag,
			};
		}
		case Actions.types.SAVED_SUCCEESSFUL_POPUP: {
			return {
				...state,
				isSavedSuccessfully: action.flag,
			};
		}
		default:
			return state;
	}
};
export default WorkflowReducer;
