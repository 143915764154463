import React from 'react';
import propTypes from 'prop-types';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import './counter.scss';

const Counter = ({
	grey = false,
	vertical = false,
	width = '',
	twoButton = false,
	contentgrey = false,
	handleIncrement,
	handleDecrement,
	content,
}) => {
	return (
		<>
			{!twoButton ? (
				<div className={vertical ? 'vertical' : 'counter'}>
					<div className={`counter-outline ${grey ? 'small' : 'big'}`} style={{ width: width }}>
						<div className={`minus ${grey ? 'grey' : 'white'}`} onClick={vertical ? handleIncrement : handleDecrement}>
							<ImageComponent src={vertical ? images.add : images.minus} />
						</div>

						<div className={`content ${grey ? 'smallheight' : 'bigheight'} ${contentgrey ? 'grey' : 'white'}`}>{content}</div>

						<div className={`plus ${grey ? 'grey' : 'white'}`} onClick={vertical ? handleDecrement : handleIncrement}>
							<ImageComponent src={vertical ? images.minus : images.add} />
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="twobutton-counter">
						<div className="twobutton-content">{content}</div>
						<div>
							<div className="small-plus" onClick={handleIncrement}>
								<ImageComponent src={images.add} className="small-image" />
							</div>
							<div className="small-minus" onClick={handleDecrement}>
								<ImageComponent src={images.minus} className="small-image" />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Counter;
Counter.propTypes = {
	grey: propTypes.bool,
	vertical: propTypes.bool,
	width: propTypes.string,
	twoButton: propTypes.bool,
	contentgrey: propTypes.bool,
	handleIncrement: propTypes.func,
	handledecrement: propTypes.func,
	content: propTypes.object,
};
