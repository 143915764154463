import React from 'react'
import reducer from './component/reducer'
import { withReducer } from '@store/reducerLoader';
import sagas from './component/saga/index';
import PayslipFunctional from '../payslip/component/payslip-functional'
const PayslipMain = () => {
    return (
        <PayslipFunctional />
    )
}
const ReducedScreen = withReducer('paySlipReducer', reducer, sagas)(PayslipMain);
export default ReducedScreen;
