import React, { useState } from 'react';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import { Link } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import { Card_Orders_Qus } from '../constants/mockData';
import { images } from 'src/assets/img';
import ItemHeader from '@pages/help/components/ItemHeader';
import '../employee.scss';

export const renderQuestionAndAnswer = (QuesAndAns, indexValue, setIndexValue) => {
	const onOpenTab = (dataIndex) => {
		if (indexValue !== dataIndex) {
			setIndexValue(dataIndex)
		} else if (indexValue === dataIndex) {
			setIndexValue('')
		}
	}
	return QuesAndAns.map(
		({ question, answer }, i) =>
			question && (
				<Row className={i === QuesAndAns?.length - 1 ? 'questions-conter1' : 'questions-conter'} key={i}>
					<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question" onClick={() => onOpenTab(i)}>
						<EdenredTypo>{question}</EdenredTypo>
						{indexValue !== i ? (
							<ImageComponent src={images.add} className="icon" />
						) : (
							<>
								{indexValue === i ? (
									<ImageComponent src={images.minus} className="icon" />
								) : null}
							</>
						)}
					</Col>
					{indexValue === i ? (
						<>
							{answer.map(({ label, link, target }, j) => {
								const props = target
									? { onClick: () => target && window.open(link, target) }
									: {
										to: link,
									};
								return (
									<Col xl={24} md={24} lg={24} xs={24} sm={24} className="answer">
										<EdenredTypo>
											{`${answer.length !== 1 ? `${j + 1})` : ''} ${label}`}
											{link && (
												<Link {...props}>
													<EdenredTypo
														style={{
															textDecoration: 'underline',
															textUnderlinePosition: 'under',
															color: '#00344E',
														}}>
														here
													</EdenredTypo>
												</Link>
											)}
										</EdenredTypo>
									</Col>
								);
							})}{' '}
						</>
					) : null}
				</Row>
			)
	);
};
export const renderATMQuestionAndAnswer = (QuesAndAns, indexValue, setIndexValue) => {
	const onOpenTab = (dataIndex) => {
		if (indexValue !== dataIndex) {
			setIndexValue(dataIndex)
		} else if (indexValue === dataIndex) {
			setIndexValue('')
		}
	}
	return QuesAndAns.map(
		({ title, quesAndAns }, i) =>
			quesAndAns && (
				<Row className={i === QuesAndAns?.length - 1 ? 'questions-conter1' : 'questions-conter'} key={i}>
					{title && (
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question" onClick={() => onOpenTab(i)}>
							<EdenredTypo>{`${title}`}</EdenredTypo>
							{indexValue !== i ? (
								<ImageComponent src={images.add} className="icon" />
							) : (
								<>
									{indexValue === i ? (
										<ImageComponent src={images.minus} className="icon" />
									) : null}
								</>
							)}
						</Col>
					)}
					{indexValue === i ? (
						<>
							{quesAndAns.map(({ question, answer }) => (
								<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question-item">
									{question && <EdenredTypo className="atmQuestion">{`${question}`}</EdenredTypo>}
									{answer.map(({ label, link, target, list }, j) => {
										const props = target
											? { onClick: () => target && window.open(link, target) }
											: {
												to: link,
											};
										return (
											<>
												<Col xl={24} md={24} lg={24} xs={24} sm={24} className="answer-data">
													<EdenredTypo>
														{`${answer.length !== 1 ? `${j + 1})` : ''} ${label}`}
														{link && (
															<Link {...props}>
																<EdenredTypo
																	style={{
																		textDecoration: 'underline',
																		textUnderlinePosition: 'under',
																		color: '#00344E',
																	}}>
																	here
																</EdenredTypo>
															</Link>
														)}
													</EdenredTypo>
												</Col>
												{list && (
													<Col xl={24} md={24} lg={24} xs={24} sm={24} className="answer">
														<ul>
															{list?.map((lis) => (
																<li
																	style={{
																		padddingBottom: '15px',
																	}}>
																	<EdenredTypo>{lis}</EdenredTypo>
																</li>
															))}
														</ul>
													</Col>
												)}
											</>
										);
									})}
								</Col>
							))}
						</>
					) : null}
				</Row>
			)
	);
};

const CardOrderFaqs = () => {
	const [indexValue, setIndexValue] = useState('');
	return (
		<>
			<Col className="employee-presentational">
				<Row className="employee-col">
					<Col xl={24} md={24} lg={24}>
						<ItemHeader title='Card Orders' />
						<Col className="employee-questions">{renderQuestionAndAnswer(Card_Orders_Qus, indexValue, setIndexValue)}</Col>
					</Col>
				</Row>
			</Col>
		</>
	);
};
export default CardOrderFaqs;
