import React from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { isEmailValid } from '@helpers';
import ETypo from '@pages/DesignSystem/Typo';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './personal-info-presentational.scss';

function PersonalInfo({ employeeDetailState, handleValueChanged, employeeDetails, nationalityData, type, placeholder }) {
	let user = employeeDetailState?.dob;
	const options = {
		allowInput: true,
		dateFormat: 'd/m/Y',
		disableMobile: true,
	};
	let nDate = user?.split('/').reverse().join('-');
	let currentDate = moment().format('YYYY-MM-DD');
	let buttonDisable = nDate > currentDate;
	const emailValid = isEmailValid(employeeDetailState?.emailId);
	const profileSwitchRender = (type) => {
		switch (type) {
			case 'nationality':
				return (
					<MultiSelect
						placeholder="Nationality"
						value={employeeDetailState?.nationality}
						onChange={(event) => handleValueChanged('nationality', event?.countryCode)}
						// disabled={employeeDetails?.isInactivatedforDeletion}
						disabled={true}
						Option={nationalityData}
						showIcon={false}
						dropDownValue="countryName"
						compareDropDownValue="countryCode"
					/>
				);
			case 'dob':
				return (
					<>
						<Flatpickr
							onChange={(dateObj, formatStr) => handleValueChanged('dob', formatStr)}
							onKeyDown={(e) => e.keyCode >= 65 && e.keyCode <= 90 && e.preventDefault()}
							className={buttonDisable ? 'profile_dob_error' : 'date-picker-input_profile'}
							value={user}
							{...{
								options,
								placeholder: 'DD/MM/YYYY',
								disabled: employeeDetails?.isInactivatedforDeletion,
							}}
						/>
						{buttonDisable && (
							<ETypo light b4 color="#ab0c00">
								Date of birth Cannot be Future Date
							</ETypo>
						)}
					</>
				);
			case 'mobile':
				return (
					<Inputbox
						type="number"
						labels="Mobile Number"
						value={employeeDetailState?.mobileNo}
						leftImage={<img src={not_focus_icons.countryImage} style={{ marginRight: '8px' }} />}
						edit={false}
						onChange={(event) => handleValueChanged('mobileNo', event)}
						disabled={true}
						className="forBottomMargin"
						placeholder={placeholder}
					/>
				);
			case 'email':
				return (
					<Inputbox
						type="text"
						labels="Email Address"
						value={employeeDetailState?.emailId}
						disabled={employeeDetails?.isInactivatedforDeletion}
						edit={true}
						onChange={(event) => handleValueChanged('emailId', event)}
						className="forBottomMargin"
						isErrorMessage={employeeDetailState?.emailId && !emailValid}
						errorMessage="Enter a valid email address"
						placeholder={placeholder}
					/>
				);
		}
	};
	return <div>{profileSwitchRender(type)}</div>;
}

export default PersonalInfo;
