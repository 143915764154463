import additionWatcher from '../addition/saga/additionSaga';
import allowanceWatcher from '../allowance/saga/allowanceSage';
import deductionWatcher from '../deduction/saga/deductionSaga';
import documentsExpiryWatcher from '../DocumentsExpiry/saga/documentsExpirySaga';
import departmentWatcher from '../Leaves/Departments/saga/departmentSaga';
import employeePayrollWatcher from '../UploadPayrollDetails/saga/upload-payroll-details-saga';
import announcementWatcher from '../Announcements/saga/announcementSaga';
import { all } from 'redux-saga/effects';
import expiryWatcher from '@pages/payroll-plus-new/sagas/payroll-plus-new-saga';
import uploadLeaveWatcher from '../Leaves/UploadLeaveDetails/saga/uploadLeaveSaga';

export default function* rootSaga(action) {
	yield all([
		additionWatcher(action),
		deductionWatcher(action),
		allowanceWatcher(action),
		employeePayrollWatcher(action),
		announcementWatcher(action),
		documentsExpiryWatcher(action),
		departmentWatcher(action),
		// expiryWatcher(action),
		uploadLeaveWatcher(action),
	]); // add all the watcher functions
}
