import React from 'react';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import { useHistory } from 'react-router-dom';
import '../EdenRedHeader/EdenRedHeader.scss';
import { SITEMAP } from 'src/routes/sitemap';

const EdenRedHeader = ({ isLogoActive = true }) => {
	const history = useHistory();

	const goToDashboard = () => {
		if (isLogoActive) {
			history.push(SITEMAP.dashboard.index);
		}
	};
	return (
		<header className="header-wrapper">
			<div className="header">
				<ImageComponent src={images.edenredSVG} onClick={goToDashboard} />
			</div>
		</header>
	);
};

export default EdenRedHeader;
