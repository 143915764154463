import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './index.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import action from '../Employee/action';
import UnEmploymentInsuranceDragger from './UnEmploymentInsuranceDragger';
import { Row } from 'antd';
import ETypo from '@pages/DesignSystem/Typo';
import InvoiceService from 'src/services/invoice-service';
import { icons } from 'src/assets/icons';
import ErrorTable from '@sharedComponent/error-table';
import Toast from '@pages/DesignSystem/Toast';

function Index(props) {
	const [missingEmpFile, setMissingEmpFile] = useState(null);
	const [saveFileLoading, setSaveFileLoading] = useState(false);
	const [savedSuccess, setSavedSuccess] = useState(false);
	const [successToastForUpload, setSuccessToastForUpload] = useState(false);
	const [errorData, setErrorData] = useState(null);

	const _invoiceService = new InvoiceService();

	const missingEmpTemplateLoading = useSelector((state) => state?.unemploymentReducer?.missingEmpTemplateLoading);
	const missingEmpTemplateMsg = useSelector((state) => state?.unemploymentReducer?.missingEmpTemplateMsg);

	const dispatch = useDispatch();

	const history = useHistory();

	const download = () => {
		dispatch(action.creators.missingEmpTemplateDownload());
	};

	const onSubmitProof = async () => {
		let formData = new FormData();
		formData.append('file', missingEmpFile);
		setErrorData(null);
		setSavedSuccess(false);
		setSaveFileLoading(true);
		_invoiceService
			.uploadMissingEmpoloye(formData)
			.then((res) => {
				if (res) {
					setSaveFileLoading(false);
					if (res.data === '') {
						setSuccessToastForUpload(true);
						setErrorData(null);
						setMissingEmpFile(null);
						setSavedSuccess(true);
					}
					setErrorData(res?.data);
				} else {
					setSaveFileLoading(false);
					setSuccessToastForUpload(true);
				}
			})
			.catch(() => {
				setSaveFileLoading(false);
				setSuccessToastForUpload(false);
			});
	};

	return (
		<div className="single-update bulk-update">
			{missingEmpTemplateMsg && (
				<Toast
					color="#8EF9B3"
					visible={true}
					clearPopUp={() => {
						dispatch(action.creators.missingEmpTemplateDownloadSuccess(null));
					}}>
					{missingEmpTemplateMsg}
				</Toast>
			)}
			<Toast
				color="#8EF9B3"
				visible={successToastForUpload}
				clearPopUp={() => {
					setSuccessToastForUpload(false);
				}}>
				Uploaded Successfully.
			</Toast>
			<Row className="single-update-header">
				<ETypo bold h3>
					Update Missing Details Using Spreadsheet
				</ETypo>
				<div className="action-layer">
					<EdenredButtons
						style={{ marginRight: '40px' }}
						{...{
							btnType: 'secondary',
						}}
						onClick={() => history.push('/un-employment-insurance/single-update')}>
						Update in Table
					</EdenredButtons>
					<EdenredButtons
						// style={{ width: '84px' }}
						loading={saveFileLoading}
						{...{
							btnType: 'primary',
						}}
						onClick={() => onSubmitProof()}
						disabled={missingEmpFile ? false : true}>
						Next
					</EdenredButtons>
				</div>
			</Row>
			<div className="unemployment-insurance-upload">
				<div className="upload-row">
					<div className="upload-number">1</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						Download an excel template with a list of all employees with the missing information.
						{missingEmpTemplateLoading ? (
							<div style={{ height: '15px', width: '15px', marginLeft: '5px' }} className={`loader secondary_loader`} />
						) : (
							<Link to={'#'} onClick={() => download()} style={{ textTransform: 'none' }}>
								{' '}
								Here
							</Link>
						)}
					</div>
				</div>
				<div className="upload-row">
					<div className="upload-number">2</div>
					<div>Fill in the missing details.</div>
				</div>
				<div className="upload-row">
					<div className="upload-number">3</div>
					<div>Upload the updated spreadsheet and click “Next” for your employees to be updated.</div>
				</div>
				<div style={{ marginTop: '8px' }}>
					<UnEmploymentInsuranceDragger
						loadingState={savedSuccess}
						onRemoveUpload={() => {
							setErrorData(null);
							setMissingEmpFile(null);
						}}
						onChangeHandler={(info) => setMissingEmpFile(info)}
					/>
				</div>
				{errorData?.length > 0 ? (
					<>
						<div
							style={{
								backgroundColor: '#FFF6F5',
								borderRadius: '8px',
								minHeight: '52px',
								display: 'flex',
								alignItems: 'center',
								margin: '15px 0',
							}}>
							<div style={{ padding: '15px' }}>
								<img src={icons?.focus_state.ErrorIcon} alt="error" />
							</div>
							<div>Please correct the following errors and upload the file again.</div>
						</div>
						<ErrorTable data={errorData} />
					</>
				) : (
					''
				)}
			</div>
		</div>
	);
}

export default Index;
