import React from 'react';
import CompanyInformationFunctional from './components/company-information-functional';
import './company-information.scss';
import './company-information-mobile.scss';

const CompanyInformation = () => {
	return <CompanyInformationFunctional />;
};

export default CompanyInformation;
