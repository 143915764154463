import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ImageComponent from '@sharedComponent/image-component';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { debounceHandler, entriesDropDown } from '@helpers';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Table from '@pages/DesignSystem/Table/Table';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ClevertapReact from 'src/utils/clever-tap';
import Selectors from '../selector';
import action from '../action';
import './approve.scss';

const Approved = ({
	getApprovedEmployee,
	hrApprovedValidEmploye,
	submitValidEmployee,
	getApprovedValidloading,
	handelSubmitSuccessPopup,
	submitSuccessPopup,
	submitValidloading,
	isSetup = false,
	onSubmissionSuccess,
	onClickCancel,
}) => {
	const history = useHistory();
	const empList = useSelector((state) => state?.expiryCountReducer?.approvedValidEmploye);
	const [pagination, setPagination] = useState({
		pageNumber: 1,
		pageSize: 10,
		searchNameId: '', // to search by name or Id
	});
	useEffect(() => {
		getApprovedEmployee(pagination);
	}, [pagination]);

	useEffect(() => {
		if (submitSuccessPopup && typeof onSubmissionSuccess === 'function') {
			onSubmissionSuccess();
		} else {
			submitSuccessPopup && history.push({ pathname: isSetup ? '/setup' : '/hrdocuments', state: 'success' });
			submitSuccessPopup && handelSubmitSuccessPopup(false);
		}
		return () => submitSuccessPopup && handelSubmitSuccessPopup(false);
	}, [submitSuccessPopup]);

	const handlePageChange = (page, value) => {
		setPagination({ ...pagination, [value]: page });
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'firstName',
			key: 'firstName',
			sort: false,
			render: (firstName, row) => {
				return <Col className="tables-detail">{`${firstName ? firstName : ''} ${row.lastName ? row.lastName : ''}`}</Col>;
			},
		},
		{
			title: 'Employee ID',
			dataIndex: 'employeeId',
			key: 'employeeId',
			sort: false,
			render: (employeeId) => {
				return <Col className="tables-detail">{employeeId}</Col>;
			},
		},
		{
			title: 'Document Type',
			dataIndex: 'emiratesExpiry',
			key: 'emiratesExpiry',
			sort: false,
			render: (emiratesExpiry, row) => {
				return <Col className="tables-detail">{getDocType(row)}</Col>;
			},
		},
	];

	const getDocType = (rowData) => {
		const check = [
			{ key: 'passportId', string: 'Passport' },
			{ key: 'workPermitNumber', string: 'Labour Card' },
			{ key: 'emiratesId', string: 'Emirates' },
			{ key: 'visaNumber', string: 'Visa Number' },
			{ key: 'emiratesExpiry', string: 'Emirates' },
			{ key: 'labourCardExpiry', string: 'Labour Card' },
			{ key: 'passportExpiry', string: 'Passport' },
			{ key: 'visaExpiry', string: 'Visa Number' },
			{ key: 'labourCardNumber', string: 'Labour Card' },
		];

		let data = (check || [])
			.filter((localObj) => rowData[localObj.key])
			.map((obj) => obj.string)
			.join();
		data = Array.from(new Set(data.split(','))).toString();
		return data;
	};
	const approvedValidEmploye = hrApprovedValidEmploye;

	const handleSearch = (value) => {
		setPagination((prev) => ({ ...prev, searchNameId: value }));
	};
	return (
		<div className="approve-container">
			<div className="approve-inner-div">
				<div className="approve-header-div">
					<ETypo className="approve-header">You're Updating the following employees</ETypo>
					<p>Click 'Submit' to proceed</p>
				</div>
				<div className="new_design_hr_upload">
					<EdenredButtons
						btnType="secondary"
						className="new_hr_upload_cancel"
						onClick={() => {
							if (typeof onClickCancel === 'function') {
								onClickCancel();
							} else {
								history.push('/hrdocuments/uploaddocument');
							}
						}}>
						Cancel
					</EdenredButtons>
					<EdenredButtons
						disabled={approvedValidEmploye?.length === 0}
						loading={submitValidloading}
						onClick={() => {
							submitValidEmployee(approvedValidEmploye);
							ClevertapReact.event('Bulk_Submit');
						}}>
						Submit
					</EdenredButtons>
				</div>
			</div>
			<div className="hr_upload_preview">
				<div className="table-container" style={{ maxHeight: '70%', overflow: 'auto' }}>
					<div className="new_upload_preview">
						<div className="new_preview_entries">
							<p>Show</p>
							<MultiSelect
								{...{
									Option: entriesDropDown,
									value: pagination?.pageSize,
									dropDownValue: 'value',
									searchVisibility: false,
									onChange: (page) => handlePageChange(page?.value, 'pageSize'),
								}}
							/>
							<p>Entries</p>
						</div>
						<div className="new_preview_search_filter">
							<div className="new_design_search">
								<Inputbox
									{...{
										leftImage: <ImageComponent src={focus_state_icons.NewSearch} />,
										placeholder: 'Search by Name, Employee ID...',
										className: 'new_design_input',
										onChange: (event) => debounceHandler(event.target.value, handleSearch),
									}}
								/>
							</div>
						</div>
					</div>
					<div className="hr_preview_table">
						<Table className="table-details" data={approvedValidEmploye || []} columns={columns} tableLoading={getApprovedValidloading} />
					</div>
					<div className="hr-pagination-bar">
						<div className="hr-pagination-count">
							Showing {approvedValidEmploye?.length} out of {empList?.totalCount} Employees
						</div>
						<Pagination
							currentPage={pagination?.pageNumber}
							totalCount={empList?.totalCount || 1}
							pageSize={pagination?.pageSize}
							onPageChange={(page) => handlePageChange(page, 'pageNumber')}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		hrApprovedValidEmploye: Selectors.approvedValidEmploye(state),
		getApprovedValidloading: Selectors.getApprovedValidloading(state),
		submitValidloading: Selectors.submitValidloading(state),
		submitSuccessPopup: Selectors.submitSuccessPopup(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getApprovedEmployee: (payload) => dispatch(action.creators.getApprovedEmployee(payload)),
		submitValidEmployee: (reqObj) => dispatch(action.creators.submitValidEmployee(reqObj)),
		handelSubmitSuccessPopup: (flag) => dispatch(action.creators.handelSubmitSuccessPopup(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Approved);
export default Connected;
