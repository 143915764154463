import React from 'react';
import { Row, Col, Switch, Card } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import { images } from '@assets/img/index';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons/index';
import EdenredButton from '@sharedComponent/button';
import EdenredModal from '@sharedComponent/modal';
import { useHistory } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';

const ContentPannel = ({ list, onChange }) => {
	return (
		<Row className="content_list_main">
			<Col className="content_list">
				<Row className="horizontal"></Row>
				<Row className="content_detail">
					<Col xl={4} md={4} lg={4} sm={4} xs={4} className="toggle_switch">
						<Switch onChange={(checked) => onChange(list, checked)} checked={list.checked} className="switch" />
					</Col>
					<Col xl={20} md={20} lg={20} xs={20} xs={20}>
						<Col xs={24} sm={24} lg={24} md={24} xl={24} className="header">
							<EdenredTypo> {list.title}</EdenredTypo>
						</Col>
						<Col xs={24} sm={24} lg={24} md={24} xl={24} className="detail">
							<EdenredTypo>{list.description}</EdenredTypo>
						</Col>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

const UserActionPresent = ({ visible, modalSubmit, showModal, closeModal, afterOpenModal, list, loading, onChange }) => {
	const history = useHistory();

	return (
		<>
			<EdenredHeader />
			<Row className="main-container">
				{/* column-1 */}
				<Col xl={8} md={6} lg={8} sm={24} xs={24} className="left-pannel">
					<Row>
						<Col xl={24} sm={24} xs={24} className="logo">
							<ImageComponent src={images.logo} />
						</Col>
					</Row>
					<Row className="left_bubble">
						<Col xl={24} md={24} lg={24}>
							<ImageComponent src={images.leftbubble} />
						</Col>
					</Row>
				</Col>

				{/* column-2 */}
				<Col xs={24} sm={24} md={12} xl={8} lg={8} className="center-pannel">
					<Col xs={24} sm={24} md={24} xl={24} lg={24} className="header_app">
						<EdenredTypo>What Activities Do You Want To Approve?</EdenredTypo>
					</Col>
					<Col xs={24} sm={24} md={24} xl={24} lg={24} className="header_app_mobile">
						<EdenredTypo>What Activities of the User Account do you want to Approve?</EdenredTypo>
					</Col>
					<Card className="card_action">
						<Row style={{ width: '100%' }}>
							<Col xl={3} sm={4} xs={4} md={3}>
								<ImageComponent src={icons.focus_state.Warning} className="c" />
							</Col>
							<Col xl={21} md={21} sm={20} xs={20} className="action_apporve">
								<EdenredTypo>
									Disabling any of these actions would mean that the user account can directly perform them WITHOUT your approval. Disable this only if you want to make submissions directly from the user account WITHOUT approval.
								</EdenredTypo>
							</Col>
						</Row>
					</Card>
					{list.slice(0, 4).map((list, i) => (
						<ContentPannel list={list} onChange={onChange} />
					))}
					<hr className="horizontal" />
					<EdenredButton loading={loading} type="primary" style={{ marginTop: '5%' }} onClick={showModal}>
						Next
					</EdenredButton>
					<EdenredModal
						isOpen={visible}
						modalSubmit={modalSubmit}
						className="modal_card"
						afterOpenModal={closeModal}
						getDeleterole={modalSubmit}
						user="Important"
						content_web="The user will be able to run payroll, order cards, quest replacement cards and cancel cards WITHOUT your approval."
						content_mobile="The user will be able to run payroll, order cards, quest replacement cards and cancel cards WITHOUT your approval."
					/>
				</Col>

				{/* column-3 */}
				<Col xl={8} md={6} lg={8} className="right-pannel">
					<Row className="sub_pannel">
						<Col xl={24} lg={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
							<ImageComponent src={images.rightbubble} />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default UserActionPresent;
