import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EDragger from '@pages/DesignSystem/Dragger';
import { icons } from 'src/assets/icons';
import { focus_state_icons } from 'src/assets/icons/focus_state';

function UploadAttachment({
	uploadLoad = false,
	getUploadFile,
	saveGetUploadLogoStatus = [],
	setFileUpload,
	companySettingUpload = false,
	className,
	coorporateImageApi = false,
	setupload = () => {},
	dragandDropEdit,
	deleteIcon = focus_state_icons.NewDelete,
	draggerStatus,
	setIsDisabledComp,
	deleteProfileImg = null,
	size = 250000,
	acceptedTypes,
}) {
	const [hasErrorFile, setHasErrorFile] = useState(false);
	const [loader, setLoader] = useState(false);
	const [lastmodified, setLastmodified] = useState(null);
	const allowedFileTypes = ['png', 'jpeg', 'jpg', 'JPG', 'JPEG'];
	const props = {
		accept: '.png,.jpeg,.jpg,.PNG,.JPEG,.JPG',
		multiple: false,
		onChange(info) {
			if (coorporateImageApi) {
				coorporateImageApi();
			}
			if (info.file.status === 'uploading') {
				let fileList = [...info.fileList];
				fileList = fileList.slice(-1);
				const file = fileList[0].originFileObj;
				setFileUpload(file);
				if (allowedFileTypes.indexOf(file.name && file.name.split('.').splice(-1)[0]) >= 0 && file.size < size && file.size > 0) {
					fileList = fileList.map((file) => {
						if (file.response) {
							file.url = file.response.url;
						}
						return file;
					});
					setLastmodified('add');
					setLoader(true);
					setHasErrorFile(false);
					setupload(true);
				} else {
					setLoader(false);
					setHasErrorFile(true);
				}
			}
			setIsDisabledComp && setIsDisabledComp(false);
			getUploadFile && getUploadFile();
		},
		onRemove() {
			setLastmodified('remove');
			setLoader(false);
			setHasErrorFile(false);
			if (dragandDropEdit && lastmodified == 'add') {
				setupload(true);
			} else {
				setupload(false);
			}
		},
	};
	const handleDragger = (file) => {
		if (allowedFileTypes.indexOf(file.name && file.name.split('.').splice(-1)[0]) >= 0 && file.size < size && file.size > 0) {
			setFileUpload(file);
			setLastmodified('add');
			setLoader(true);
			setHasErrorFile(false);
			setupload(true);
		} else {
			setLoader(false);
			setHasErrorFile(true);
		}
	};
	const onRemoveUpload = () => {
		setLastmodified('remove');
		setLoader(false);
		setHasErrorFile(false);
		if (dragandDropEdit && lastmodified == 'add') {
			setupload(true);
		} else {
			setupload(false);
		}
		if (deleteProfileImg && saveGetUploadLogoStatus.length) {
			deleteProfileImg();
		}
	};
	const draggerIcon = () => {
		if (uploadLoad) {
			return focus_state_icons.hrUploading;
		} else if (loader) {
			return focus_state_icons.NewDesignCheck;
		} else if (saveGetUploadLogoStatus?.length !== 0) {
			return saveGetUploadLogoStatus;
		} else {
			return focus_state_icons.hrUpload;
		}
	};

	return (
		<div className="dragger-container-div">
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className={companySettingUpload ? `${className}` : 'attachement-header'}>
				<EDragger
					{...{
						className: saveGetUploadLogoStatus?.length !== 0 ? 'no_image' : '',
						idleIcon: draggerIcon(),
						uploadDeleteIcon: deleteIcon,
						onChange: handleDragger,
						onRemoveUpload,
						acceptedTypes: acceptedTypes || '.png,.jpeg,.jpg,.PNG,.JPEG,.JPG',
						draggerStatus,
					}}
				/>
			</Col>
			{hasErrorFile && (
				<Col xl={21} md={21} sm={24} lg={21} xs={24} className="card_error" style={{ marginTop: 0 }}>
					<Card className="card_action">
						<Row>
							<Col xl={1} sm={3} xs={3} md={2}>
								<ImageComponent src={icons.focus_state.Error} />
							</Col>
							<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
								Invalid format. Please upload one of these formats {allowedFileTypes.join()} and make sure the file size below 2MB
							</Col>
						</Row>
					</Card>
				</Col>
			)}
		</div>
	);
}

export default UploadAttachment;
