import React from 'react';
import { Card, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '../../../../assets/icons/index';

const CompanyDocumentCard = ({ content }) => {
	const Document = icons.focus_state.newUpload;

	const history = useHistory();
	const ViewDetails = (e, data, flag) => {
		e.preventDefault();
		data['signatory'] = [];
		history.push({ pathname: '/company/upload-spreadsheet', state: flag ? content : data });
	};

	return (
		<Col xl={24} lg={24} md={24} style={{ boxShadow: 'none' }} className={`company_document_card_column`}>
			<Card className="company_document_card">
				{window?.screen?.width > 425 && (
					<Col className="document_icon">
						<ImageComponent src={content?.title === 'Power of Attorney' ? icons.focus_state.newUpload : Document} />
					</Col>
				)}
				<Col className="card_content_section">
					{content?.card_type && <ETypo className="card_type">{content?.card_type}</ETypo>}
					{content?.title && (
						<ETypo h3 className="card_title">
							{content?.title}
						</ETypo>
					)}
					{content?.license_type && <ETypo className="license_type">{content?.license_type}</ETypo>}
					{content?.license_id && <ETypo className="license_id">{content?.license_id}</ETypo>}
					{content?.hint && (
						<ETypo style={{ fontSize: '14px' }} light className="hint">
							{content.hint}
						</ETypo>
					)}
					{content?.hint2 && <ETypo className="hint">{content.hint2}</ETypo>}
					{content?.expiresOn && <ETypo className="expires_on">Expires on: {content?.expiresOn}</ETypo>}
					{(content?.status === 'feedbackError' || content?.status === 'feedbackWarning') && (
						<ETypo className={`document_validity_status ${content.status}`}>
							{content?.status === 'feedbackError' ? 'Expired' : 'Expires Soon'}
						</ETypo>
					)}
				</Col>
				{window.screen.width > 425 && (
					<Col xl={6} lg={6} md={6} sm={3} xs={3} className="document_update_button">
						<EdenredButtons btnType={'primary'} onClick={(e) => ViewDetails(e, content, content?.isUpload)} className={'upload_detail'}>
							{'Upload'}
						</EdenredButtons>
					</Col>
				)}
				{window.screen.width < 425 && (
					<Col xl={3} lg={2} md={2} sm={3} xs={3} className="document_update_button_icon">
						<ImageComponent src={Document} style={{ width: '50px' }} />
						<EdenredButtons onClick={(e) => ViewDetails(e, content, content.isUpload)} type="link">
							Update
						</EdenredButtons>
					</Col>
				)}
			</Card>
		</Col>
	);
};

export default CompanyDocumentCard;
