import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import EdenredTypo from '@sharedComponent/typography';
import EdenredSearch from '@sharedComponent/search-input';
import EdenredButton from '@sharedComponent/button';
import EdenRedCard from '@sharedComponent/card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons/index';
import { EmployeListExcelDownload } from '@sharedComponent/excel/excel';
import { changeEmployeeNameCase, numberWithCommas, entriesDropDown } from '@helpers';
import { CLEVER_TAP_EVENTS } from '@constants/app-constants';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import ETypo from '@pages/DesignSystem/Typo';
import Table from '@pages/DesignSystem/Table/Table';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Toast from '@pages/DesignSystem/Toast';
import { images } from 'src/assets/img';
import ClevertapReact from 'src/utils/clever-tap';
import PayrollService from 'src/services/payroll-service';
import './../approve-requests.scss';

const column = [
	{
		key: 'name',
		title: 'Name',
		width: '40%',
		dataIndex: 'name',
		render: (name) => {
			return (
				<div>
					<ETypo b2 light color="#181919">
						{name}
					</ETypo>
				</div>
			);
		},
	},

	{
		key: 'employeeCode',
		title: 'Employee ID',
		dataIndex: 'employeeCode',
		width: '38%',
		render: (employeeCode) => {
			return (
				<div>
					<ETypo b2 light color="#181919">
						{employeeCode}
					</ETypo>
				</div>
			);
		},
	},
	{
		key: 'salary',
		title: 'Salary Amount',
		dataIndex: 'salary',
		width: '22%',
		align: 'right',
		render: (salary) => {
			return (
				<div>
					<ETypo b2 light color="#181919">
						{salary}
					</ETypo>
				</div>
			);
		},
	},
];
const WpsTable = () => {
	const [pageSize, setPageSize] = useState(10);
	const { state } = useLocation();
	const history = useHistory();
	const [searchedText, setSearchedText] = useState('');
	const [employeeSalary, setEmployeeSalary] = useState([]);
	const [employeesHeader, setEmployeesHeader] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [employeeSalary1, setEmployeeSalary1] = useState([]);
	const [approveModal, setApproveModal] = useState(false);
	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [loading, setloading] = useState(false);
	const _payrollService = new PayrollService();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});

	const getPayrollDetailsByUploadID = (uploadID, type) => {
		_payrollService
			.getAllPayrollDetailsByUploadID(uploadID, type, '', pageSize, currentPageNumber, searchedText)
			.then((response) => {
				console.log('#ERE#', response.data);
				let employeesSalaryData = [...response?.data?.employeeSalaryList];
				let employeesHeaderData = {};
				employeesHeaderData.headerAmount = response?.data?.headerAmount;
				employeesHeaderData.headerEmployeeCount = response?.data?.headerEmployeeCount;
				employeesHeaderData.headerType = response?.data?.headerType;
				employeesSalaryData.map((employee) => {
					employee.name = changeEmployeeNameCase(employee?.name);
					employee.amount = `AED ${numberWithCommas(employee?.salary)}`;
					return employee;
				});
				setEmployeesHeader(employeesHeaderData);
				setEmployeeSalary(employeesSalaryData);
				setEmployeeSalary1(employeesSalaryData);
				setTotalCount(response?.data?.totalCount);
			})
			.catch((error) => {
				setEmployeeSalary([]); // TODO - PLEASE REMOVE IT AND DONT USE CONST LIKE LIST USE USESTATE() / CREATE A FILE FOR MOCK DATA
				setEmployeeSalary1([]); // TODO - PLEASE REMOVE IT AND DONT USE CONST LIKE LIST USE USESTATE() / CREATE A FILE FOR MOCK DATA
			});
	};
	// future ref
	// const getPayrollDetailsByUploadIDBank = (uploadID, type, previousEmployeesSalaryData) => {
	// 	_payrollService
	// 		.getPayrollDetailsByUploadID(uploadID, type)
	// 		.then((response) => {
	// 			let employeesSalaryData = [...response.data.employeeSalaryList];
	// 			let employeesHeaderData = {};
	// 			employeesHeaderData.headerAmount = response.data.headerAmount;
	// 			employeesHeaderData.headerEmployeeCount = response.data.headerEmployeeCount;
	// 			employeesHeaderData.headerType = response.data.headerType;
	// 			employeesSalaryData.map((employee) => {
	// 				employee.name = changeEmployeeNameCase(employee.name);
	// 				employee.amount = changeNumbertoCurrency(employee.salary);
	// 				return employee;
	// 			});
	// 			setEmployeesHeader(employeesHeaderData);
	// 			setEmployeeSalary([...previousEmployeesSalaryData, employeesSalaryData]);
	// 			setEmployeeSalary1([...previousEmployeesSalaryData, employeesSalaryData]);
	// 		})
	// 		.catch((error) => {
	// 			setEmployeeSalary([...previousEmployeesSalaryData]); // TODO - PLEASE REMOVE IT AND DONT USE CONST LIKE LIST USE USESTATE() / CREATE A FILE FOR MOCK DATA
	// 			setEmployeeSalary1([...previousEmployeesSalaryData]); // TODO - PLEASE REMOVE IT AND DONT USE CONST LIKE LIST USE USESTATE() / CREATE A FILE FOR MOCK DATA
	// 		});
	// };

	// const onFilterBySearch = async (value) => {
	// 	console.log('first', value);
	// 	if (value) {
	// 		let data = (await employeeSalary1) || [];
	// 		let filteredData = await data.filter((val) => {
	// 			const emp_name = val?.name?.toLowerCase()?.replace(/ /g, '');
	// 			const emp_id = val?.employeeId?.toString();
	// 			if (emp_name?.search(value?.toLowerCase()?.replace(/ /g, '')) !== -1 || emp_id?.search(value?.toString()) !== -1) {
	// 				return val;
	// 			}
	// 		});
	// 		setEmployeeSalary(filteredData);
	// 	} else {
	// 		setEmployeeSalary(employeeSalary1);
	// 	}
	// };

	useEffect(() => {
		getPayrollDetailsByUploadID(state && state?.uploadId, 'C3');
	}, []);

	useEffect(() => {
		getPayrollDetailsByUploadID(state && state?.uploadId, 'C3');
	}, [searchedText, pageSize, currentPageNumber]);

	const closeModal = () => setApproveModal(false);
	const statusUpdate = async () => {
		setloading(true);
		let payrollSelectedRuns = state?.uploadId ? [state?.uploadId] : null;
		let payload = [
			{
				Amount: employeesHeader?.headerAmount,
				TypeMonthYear: employeesHeader?.headerType,
				['No of employees']: employeesHeader?.headerEmployeeCount,
			},
		];
		let body = await {
			payrollAction: 'Approve',
			payrollSelectedRuns: payrollSelectedRuns,
		};
		const res = await _payrollService.approvePayrollReview(body);
		if (res) {
			setloading(false);
			setApproveModal(false);
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: 'Approved Successfully',
				subMessage: null,
			});
			ClevertapReact.event(CLEVER_TAP_EVENTS.ApproveSelected_Payroll, payload);
		} else {
			setApproveModal(false);
			setloading(false);
		}
	};
	const closePopup = () => {
		setpopupVisible(false);
		history.push('/payroll/approve_requests');
	};
	const handleViewCardTable = (pageNumber) => setPageSize(pageNumber?.value);

	return (
		<>
			<Toast width={'30px'} color="#8EF9B3" textcolor="#484B52" visible={popupVisible} clearPopUp={closePopup}>
				{popupMessage?.titleMessage}
			</Toast>
			<EdenredHeader search />
			<Row className="card-request-payroll-detail-container">
				<div className="card-request-payroll-header-container">
					<ETypo h2 bold className="request-payroll-page-title">
						{employeesHeader?.headerType && employeesHeader?.headerType}
					</ETypo>
					<div className="card-request-payroll-payroll-button-container">
						<div className="payroll-amount-container">
							<ETypo b1 light color="#737780">
								Payroll Amount:
								<ETypo b1 bold color="#00344E">
									{' '}
									{`${employeesHeader?.headerAmount || '0'} for ${employeesHeader?.headerEmployeeCount || '0'}`}
								</ETypo>
							</ETypo>
						</div>
						<EdenredButtons btnType="secondary" onClick={() => history.push('/payroll/approve_requests')} className="cancle-button">
							<ETypo b1 medium color="#484B52">
								<ImageComponent src={images.leftsarrow} style={{ marginRight: '3px' }}>
									{' '}
								</ImageComponent>{' '}
								Go Back
							</ETypo>
						</EdenredButtons>
						<EdenredButtons onClick={() => setApproveModal(true)} btnType="primary" className="save-button">
							<ETypo b1 medium color="#FFFFFF">
								Approve Payroll
							</ETypo>
						</EdenredButtons>
					</div>
				</div>
				<div className="payroll-card-info-board">
					<div className="payroll-search-and-show-count-container">
						<div className="gap-container">
							<EdenredSearch
								value={searchedText}
								placeholder="Search by Name, Employee ID..."
								onChange={(event) => setSearchedText(event.target.value)}
							/>
							<EmployeListExcelDownload
								dataSet={employeeSalary ? employeeSalary : []}
								filename={`employeelist`}
								element={
									<EdenredButtons btnType="secondary">
										<ETypo b2 medium color="#484B52">
											{/* add new image/ */}
											<ImageComponent
												style={{ marginRight: '4px', height: '14px' }}
												src={icons.focus_state.payrollDownloadArrow}></ImageComponent>{' '}
											Download Employee List
										</ETypo>
									</EdenredButtons>
								}
							/>
						</div>
						<div className="payroll-entries-select-container">
							<ETypo b2 light color="#484B52">
								Show
							</ETypo>
							<MultiSelect
								{...{
									Option: entriesDropDown,
									value: pageSize,
									dropDownValue: 'value',
									searchVisibility: false,
									onChange: (page) => handleViewCardTable(page, 'pageSize'),
									checkboxIcon: (
										<span className="entries_check_box">
											<img src={icons.not_focus.tick} alt="check" />
										</span>
									),
								}}
							/>
							<ETypo b2 light color="#484B52">
								Entries
							</ETypo>
						</div>
					</div>
					<div className="payroll-table-card-container">
						<Table columns={column} data={employeeSalary} />
					</div>

					<div className="payroll-card-pagination-container">
						<ETypo b3 light color="#484B52">
							Showing {employeeSalary.length} out of {totalCount} Employees
						</ETypo>
						<Pagination
							{...{
								currentPage: currentPageNumber,
								totalCount: totalCount,
								pageSize: pageSize,
								onPageChange: (page) => setCurrentPageNumber(page),
							}}
						/>
					</div>
				</div>

				{/* mobile view */}
			</Row>
			{/* mobile_view */}
			<Row className="Wps_table_container_moblie_view">
				<Col sm={24} xs={24} className="wps_table-july">
					<EdenredTypo>{employeesHeader?.headerType && employeesHeader?.headerType}</EdenredTypo>
				</Col>
				<Col sm={24} xs={24} className="wps_table-july">
					<EdenredTypo>{`${employeesHeader?.headerAmount && employeesHeader?.headerAmount} for ${
						employeesHeader?.headerEmployeeCount && employeesHeader?.headerEmployeeCount
					}`}</EdenredTypo>
				</Col>
				<Col sm={24} xs={24} style={{ padding: '4% 0%' }} className="approve_request">
					<EdenredButton onClick={() => setApproveModal(true)} style={{ fontSize: '14px', padding: '0px', width: '65%' }}>
						Approve Payroll
					</EdenredButton>
				</Col>
				<Col sm={24} xs={24}>
					<Col sm={24} xs={24} className="card_table">
						{employeeSalary.map((content, index) => (
							<Col xs={24} sm={24} style={{ padding: '1%' }}>
								<EdenRedCard content={content} index={index} type="EmployeeTable" />
							</Col>
						))}
					</Col>
				</Col>
			</Row>
			<Edenredmodal
				desc="Payroll will run immediately"
				title={`Do you want to  approve this payroll
								submission ?`}
				onOkay={statusUpdate}
				onCancelButton="Cancel"
				onOkayButton="Save"
				onCancel={closeModal}
				isOpen={approveModal}
				close={closeModal}
				loading={loading}
			/>
		</>
	);
};
export default WpsTable;
