import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import moment from 'moment';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import EdenredEmployeeCard from '@sharedComponent/card/employees/employees-card';
import ImageComponent from '@sharedComponent/image-component';
import EdenredSearch from '@sharedComponent/search-input';
import EdenredTypo from '@sharedComponent/typography';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Table from '@pages/DesignSystem/Table/Table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import { icons } from 'src/assets/icons';
import EmployeeService from 'src/services/employee-service';

const ViewCardOrderDetails = (props) => {
	let { content } = props?.history?.location?.state || { content: {} };
	let [employeeDetails, setEmployeeDetails] = useState([]);
	let [isDataLoading, setIsDataLoading] = useState(false);
	let [searchedText, setSearchedText] = useState('');
	let [currentPage, setCurrentPage] = useState(1);
	let [totalDataCount, setTotalDataCount] = useState(0);
	let [noOfDataToShow, setNoOfDataToShow] = useState(10);
	const [tableData, setTableData] = useState([]);
	let employeeApi = new EmployeeService();
	useEffect(() => {
		init();
	}, [noOfDataToShow, totalDataCount, currentPage]);

	function init() {
		content && content?.orderStatus && getRequestDetails();
	}

	async function getRequestDetails() {
		setIsDataLoading(true);
		let payload = {
			details: {
				uploadId: content?.orderStatus?.uploadId,
				employeeId: content?.orderStatus?.employeeId,
			},
		};
		await fetchRequestDetails(payload);
		setIsDataLoading(false);
	}

	async function fetchRequestDetails(payload) {
		try {
			let response = await employeeApi.getRequestDetails(payload, { pageNumber: currentPage, pageSize: noOfDataToShow });
			if (response && response?.data) {
				setEmployeeDetails(response?.data?.employeeDetails);
				setTotalDataCount(response?.data?.totalCount);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (searchedText) {
			const filteredTableData = employeeDetails?.filter((data) => {
				return (
					data?.employeeName?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0 ||
					data?.employeeCode?.toString()?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0
				);
			});
			setTableData(filteredTableData);
		} else {
			setTableData(employeeDetails);
		}
	}, [searchedText, employeeDetails]);

	const columnKeys = [
		{ labelKey: 'Name', valueKey: 'employeeName' },
		{ labelKey: 'Employee Code', valueKey: 'employeeCode' },
	];

	const columns = [
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'employeeName',
			title: 'Name',
			dataIndex: 'employeeName',
			width: '11.7%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val}</p>;
			},
		},
		{
			sorter: (a, b) => a.employeeCode.localeCompare(b.employeeCode),
			key: 'employeeCode',
			title: 'Employee ID',
			dataIndex: 'employeeCode',
			width: '11.7%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val}</p>;
			},
		},
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'viewDetails',
			dataIndex: 'viewDetails',
			width: '11.7%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return (
					<Link
						to={{
							pathname: '/employees/viewemployee',
							state: { tableData: val1 },
						}}>
						<p className="view_card_details_table_cell_data navigate_link">View Employee Details</p>
						<ImageComponent src={icons.focus_state.cardViewArrow} />
					</Link>
				);
			},
		},
	];
	return (
		<>
			{isDataLoading ? (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			) : (
				<div style={{ width: '100%', height: '100%' }} className="display_none_max_width_600px">
					<section className="view_card_details_title_container_wrapper">
						<div className="title_section">
							<h1>{content && content?.orderStatus && content?.orderStatus?.name}</h1>
							<h3>{`Order placed at ${moment(content && content?.orderStatus && content?.orderStatus?.orderPlaced).format(
								'hh:mm A'
							)} on ${moment(content && content?.orderStatus && content?.orderStatus?.orderPlaced).format('DD/MM/YYYY')}`}</h3>
						</div>
						<div className="buttons_wrapper">
							<ExcelDownload
								dataSet={tableData}
								columnKeys={columnKeys}
								filename={`employeelist`}
								element={<EdenredButtons btnType="secondary">Download Employee Listaa</EdenredButtons>}
							/>
						</div>
					</section>
					<section className="view_card_details_table_wrapper">
						<div className="header">
							<div className="search-option">
								<EdenredSearch
									onChange={(event) => setSearchedText(event.target.value)}
									value={searchedText}
									placeholder="Search name, employee ID..."
									prefix={<ImageComponent src={icons.not_focus.Search1} className="search_image" />}
								/>
							</div>
							<div className="no_of_data_to_show">
								<p>show</p>
								<MultiSelect
									onChange={(data) => setNoOfDataToShow(parseInt(data.name))}
									Option={[
										{
											name: '10',
										},
										{
											name: '20',
										},
										{
											name: '30',
										},
									]}
									searchVisibility={false}
									value={`${noOfDataToShow}`}
									dropDownValue={'name'}
									multiSelect={false}
								/>
								<p>Entries</p>
							</div>
						</div>
						<div>
							<Table columns={columns} data={tableData} sticky={true} tableLoading={false} />
						</div>
						<dev className="pagination-bar card_status_table_pagination_wrapper ">
							<span>
								Showing {tableData?.length <= noOfDataToShow ? tableData?.length : noOfDataToShow} out of {totalDataCount} employees
							</span>
							<Pagination
								className="pagination-bar padding_0"
								currentPage={currentPage}
								totalCount={totalDataCount}
								pageSize={noOfDataToShow}
								onPageChange={(page) => setCurrentPage(page)}
							/>
						</dev>
					</section>
				</div>
			)}
			<Col xl={24} md={24} lg={24} style={{ width: '100%', height: '100%' }} className="display_none_min_width_600px">
				<EdenredHeader />
				{isDataLoading ? (
					<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				) : (
					<Row className="view_card_order_details_container">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="view_card_order_details_title">
							<EdenredTypo>{content && content?.orderStatus && content?.orderStatus?.name}</EdenredTypo>
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="view_card_order_details_description">
							<EdenredTypo>{`Order placed at ${moment(content && content?.orderStatus && content?.orderStatus?.orderPlaced).format(
								'hh:mm A'
							)} on ${moment(content && content?.orderStatus && content?.orderStatus?.orderPlaced).format('DD/MM/YYYY')}`}</EdenredTypo>
						</Col>
						<Col xl={12} lg={15} md={10} xs={24} sm={24} className="view_card_order_details_search_field" style={{ paddingRight: '1%' }}>
							<EdenredSearch
								onChange={(event) => setSearchedText(event.target.value)}
								placeholder="Search name, employee ID..."
								prefix={<ImageComponent src={icons.not_focus.Search1} className="search_image" />}
							/>
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="view_card_order_employee_details_card_section">
							{tableData?.map((employee) => (
								<Col key={employee?.employeeCode} xl={12} lg={12} md={12} sm={24} xs={24} className="employee_details_card_column">
									<Link
										to={{
											pathname: '/employees/viewemployee',
											state: { tableData: employee },
										}}>
										<EdenredEmployeeCard type="EmployeeDetails" className="employee_details_card" content={employee} />
									</Link>
								</Col>
							))}
						</Col>
						<Col xl={8} lg={8} md={12} sm={24} xs={24} className="view_card_order_employee_details_download_button">
							<ExcelDownload
								dataSet={tableData}
								columnKeys={columnKeys}
								filename={`employeelist`}
								element={
									<EdenredButton className="download_emp_list">
										<ImageComponent src={icons.not_focus.Download} />
										Download Employee List
									</EdenredButton>
								}
							/>
						</Col>
					</Row>
				)}
			</Col>
		</>
	);
};
export default ViewCardOrderDetails;
