import React from 'react';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import './unemployment-insurance-banner.scss';

export const UnemploymentInsuranceAvoidFine = () => {
	return (
		<div className="avoid-fines fade-right">
			<div className="avoid-info">
				<h3 className="avoid-fines-title">Live Tracking of Employees’ Unemployment Insurance Policy Status</h3>
				<div className="avoid-fines-points">
					<div className="item">
						<span>Subscribed</span>
						<span className="circle"></span>
					</div>
					<div className="item">
						<span>Missing Details</span>
						<span className="circle"></span>
					</div>
					<div className="item">
						<span>Pending</span>
					</div>
				</div>
			</div>
			<div className="avoid-img">
				<img src={images.unemploymentGif} alt="unemploymentGif" />
			</div>
		</div>
	);
};
