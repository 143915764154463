import './subscriptionPopup.scss';

import { Col, Modal, Row, Spin } from 'antd';

import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import { icons } from 'src/assets/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export default function SubscriptionPopup({
	visible,
	message,
	subMessage,
	subMessage1,
	closePopup,
	autoFixEmployeeFileError,
	temp,
	index,
	className,
	okText,
	cancelText,
	loading = false,
	setSubscription,
	getEmployeeById,
	EmployeeID,
}) {
	// const []
	const history = useHistory();
	const closeModal = () => {
		// history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '1', EmployeeID: EmployeeID } });
		// getEmployeeById(EmployeeID);
		setSubscription(false);
	};
	const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#ffffff' }} spin />;
	// console.log('temp', temp, index);
	return (
		<Modal
			className={`modal_container1 replacementCardModal document-expire upload-pay-popup ${className}`}
			centered
			footer={false}
			closable={false}
			maskClosable={false}
			bodyStyle={{ height: '194px', padding: '0px' }}
			maskStyle={{ backgroundColor: 'white', opacity: '0.8' }}
			width="450px"
			visible={visible}
			onOk={() => false}
			onCancel={() => true}>
			<div className="main_popup_container">
				<div className="message_container">
					<div style={{ position: 'absolute', marginBottom: '210px' }}>
						<ImageComponent src={icons.not_focus.subcriptionLock} />
					</div>
					<div
						style={{
							width: '100%',
							minHeight: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							marginTop: '80px',
						}}>
						{message && <EdenredTypo className="popup_heading_text">Subscription Required</EdenredTypo>}
						{subMessage && (
							<EdenredTypo className="popup_message_text">
								To get full access to all our features please subscribe to our Premium Package
							</EdenredTypo>
						)}
						{subMessage1 && <EdenredTypo className="popup_message_text">{subMessage1}</EdenredTypo>}
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
					<div className="routing_container" style={{ cursor: 'pointer', backgroundColor: '#eff1f5' }} onClick={() => closeModal()}>
						<span>
							<EdenredTypo className="back_router_text" style={{ color: '#484b52', fontWeight: 'bold' }}>
								{cancelText}
							</EdenredTypo>
						</span>
					</div>
					<div
						className="routing_container"
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push('/landingNew');
						}}>
						<span>
							<EdenredTypo className="back_router_text">{okText ? okText : 'Yes'}</EdenredTypo>
						</span>
					</div>
				</div>
			</div>
		</Modal>
	);
}
