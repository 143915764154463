import React from 'react';
import { Row, Col, Form } from 'antd';
import EdenredButton from '@sharedComponent/button';
import Input from '@sharedComponent/text-field';
import { images } from '@assets/img/index';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import ClevertapReact from 'src/utils/clever-tap';

const CreatePasswordPresentational = ({ password, principles, onFinish, onPasswordChange, loading }) => {
	const [formRef] = Form.useForm();
	formRef.setFieldsValue({
		password,
	});
	return (
		<Row className="create_password__container">
			<Col xs={24} sm={24} md={16} xl={10} className="left_area">
				{/* row-1 */}
				<Row className="logo_row">
					<Col xl={24} xs={24} sm={20} md={24} lg={32} className="logo">
						<ImageComponent src={images.logo} />
					</Col>
				</Row>
				{/* row-2 */}
				<Row>
					<Col xl={18} lg={18} md={18} sm={12} xs={6.5} className="create_password__header">
						<EdenredTypo bold>Create </EdenredTypo>
					</Col>
					<Col xl={18} lg={18} md={18} sm={12} xs={{ span: 17.5 }} className="create_password__header">
						<EdenredTypo bold>New Password</EdenredTypo>
					</Col>
				</Row>
				{/* row-3 */}
				<Row className="form_label">
					<Col xs={24} sm={20} md={16} xl={18}>
						<Form onFinish={onFinish} form={formRef}>
							<Form.Item name="password" rules={[{ required: true, message: 'Password Required!' }]} className="password_form_item">
								<Input
									labels="New Password"
									link
									type="password"
									onPasswordChange={onPasswordChange}
									placeholder={principles[0].label}
								/>
							</Form.Item>
							<Row className="validation">
								{principles.map((principle, index) => (
									<Col key={index} className={`validation_principle ${principle.rule(password) ? 'line_through' : ''}`}>
										<EdenredTypo b3>{principle.label}</EdenredTypo>
									</Col>
								))}
							</Row>
							<Col className="update_password">
								<Form.Item>
									<EdenredButton
										onClick={() => {
											ClevertapReact.event('Update Password');
										}}
										loading={loading}
										style={{ fontWeight: 'bold' }}
										disabled={!(principles.filter((principle) => principle.rule(password)).length === 2)}>
										Update Password
									</EdenredButton>
								</Form.Item>
							</Col>
						</Form>
					</Col>
				</Row>
			</Col>

			<Col xs={16} sm={24} md={16} xl={14} className="right_area_container">
				<Row className="right_area" xs={16} sm={24} md={16} xl={14}>
					<Col xs={16} sm={24} md={16} xl={12} className="we_brand">
						<Col className="we_brand_text">
							<EdenredTypo bold>We</EdenredTypo>
						</Col>
						<Col className="we_brand_text">
							<EdenredTypo bold>Brand</EdenredTypo>
						</Col>
						<Col>
							<ImageComponent src={images.MultiDevices} className="laptop_img" />
						</Col>
					</Col>
					<Col xs={16} sm={24} md={16} xl={12} className="are_new">
						<Col className="are_new_text">
							<EdenredTypo bold>Are</EdenredTypo>
						</Col>
						<Col className="are_new_text">
							<EdenredTypo bold>New</EdenredTypo>
						</Col>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default CreatePasswordPresentational;
