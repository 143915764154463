import React, { useLayoutEffect, useState } from 'react';
import { Col, Row } from 'antd';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
// import ContentLoader from '@sharedComponent/content-loader'; to be used afterwards
import Table from '@pages/DesignSystem/Table/Table';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { entriesDropDown } from '@helpers';
import { icons } from 'src/assets/icons';
import './leavestatusPresentational.scss';
import Pagination from '@pages/DesignSystem/Table/Pagination';

function LeaveStatusPresentational({ columns, onGoingLeave, currentLeave, countLoader, onPageNumberandSizeSelect, payload, pageCount }) {
	const [col, setCol] = useState(true);
	const [gol, setGol] = useState(false);
	const handleTab = (type) => {
		switch (type) {
			case 'COL':
				onPageNumberandSizeSelect(0, 'leaveStatus');
				setCol(true);
				setGol(false);
				break;
			case 'GOL':
				onPageNumberandSizeSelect(1, 'leaveStatus');
				setGol(true);
				setCol(false);
				break;
		}
	};
	return (
		<Col className="leave-status-container">
			<Row className="leave-status-section">
				<div className="leave-status-header">
					<ETypo h2 bold className="edenred-heading-color">
						Leave Status
					</ETypo>
				</div>
				<div className="tab-switch-container">
					<div className="tab-section">
						<EdenredButtons
							{...{
								className: 'button-leave-status-left',
								onClick: () => handleTab('COL'),
								btnType: col ? 'primary' : 'secondary',
								style: { marginTop: '5px', marginBottom: '0px' },
							}}>
							<ETypo b1 medium className={col ? 'secondary-color' : 'edenred-heading-color'}>
								Currently on Leave
							</ETypo>
						</EdenredButtons>
						<EdenredButtons
							{...{
								className: 'button-leave-status-right',
								onClick: () => handleTab('GOL'),
								btnType: gol ? 'primary' : 'secondary',
								style: { marginTop: '5px', marginBottom: '0px' },
							}}>
							<ETypo b1 medium className={gol ? 'secondary-color' : 'edenred-heading-color'}>
								Going on Leave
							</ETypo>
						</EdenredButtons>
					</div>
				</div>
				<div className="leave-table-section">
					<div className="page-size-selector-container">
						<div className="leave_dropDown_entries">
							<p>Show</p>
							<MultiSelect
								{...{
									Option: entriesDropDown,
									value: payload.pageSize,
									dropDownValue: 'value',
									searchVisibility: false,
									onChange: (page) => onPageNumberandSizeSelect(page.value, 'pageSize'),
									checkboxIcon: (
										<span className="entries_check_box">
											<img src={icons.not_focus.tick} alt="check" />
										</span>
									),
								}}
							/>
							<p>Entries</p>
						</div>
					</div>
					<div className="table-container-leave-workflow">
						<Table className="leave-status-table" columns={columns} data={col ? currentLeave : onGoingLeave} loading={countLoader} />
					</div>
					<div className="leave-pagination-bar">
						<ETypo light b3 color="#484B52">
							Showing {col ? currentLeave?.length : onGoingLeave?.length} out of {col ? currentLeave?.length : onGoingLeave?.length}{' '}
							Employees
						</ETypo>
						<Pagination
							{...{
								currentPage: payload.pageNumber,
								totalCount: pageCount || 1,
								pageSize: payload.pageSize,
								onPageChange: (page) => onPageNumberandSizeSelect(page, 'pageNumber'),
							}}
						/>
					</div>
				</div>
			</Row>
		</Col>
	);
}
export default LeaveStatusPresentational;
