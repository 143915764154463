import React from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenRedCard from '@sharedComponent/card';
import { icons } from 'src/assets/icons';
import EdenredHeader from '@pages/main-layout/components/main-header';
import PayrollService from 'src/services/payroll-service';
import DividerComponent from '@pages/DesignSystem/Divider';
import '../../../../DesktopFix.css';

const useful_files = [
	{
		image: icons.focus_state.contantedit,
		title: 'Salary File Template',
		description: 'Our new salary file template is clearer and easy-to-use',
		templateName: 'Salary',
	},
	{
		image: icons.focus_state.userNewLogo,
		title: 'Employee File',
		description: 'Use this file to add employees in bulk,',
		templateName: 'Employee',
	},
	{
		image: icons.focus_state.bank,
		title: 'Bank Transfer',
		description: 'Employee leaving your company? Request a bank transfer with their balance.',
		templateName: 'BankTransfer',
	},
	{
		image: icons.focus_state.receipt,
		title: 'Cheque Issuance',
		description: 'Employee leaving your company? Request a cheque with their balance.',
		templateName: 'ChequeIssuance',
	},
];

const UseFulFilesPresentational = () => {
	const _payrollService = new PayrollService();
	const download = (templateName) => {
		console.log({ templateName });
		_payrollService
			.usefullfileDownload(templateName, 'GET', 'blob')
			.then(({ data }) => {
				const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: data.type }));

				const link = document.createElement('a');

				link.href = downloadUrl;

				link.setAttribute(
					'download',
					`${templateName === 'BankTransfer' || templateName === 'ChequeIssuance' ? `${templateName}.doc` : `${templateName}.xls`}`
				); //any other extension
				// link.setAttribute('download', 'file.xls'); //any other extension

				document.body.appendChild(link);

				link.click();

				link.remove();
			})
			.catch((err) => console.log(err));
	};
	return (
		<>
			<EdenredHeader />
			<Row className="UsefulFiles_container">
				<Col xl={24} md={24} lg={24} className="UsefulFiles_header">
					<EdenredTypo>Useful Files</EdenredTypo>
				</Col>
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="card_content_list">
					{useful_files.map((useful, index) => (
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="UsefulFiles_Card">
							<div onClick={() => download(useful.templateName)}>
								<EdenRedCard type="Useful_files" content={useful} />
								{index < useful_files?.length - 1 && <DividerComponent />}
							</div>
						</Col>
					))}
				</Col>
			</Row>
		</>
	);
};
export default UseFulFilesPresentational;
