import React, { useState } from 'react';
import EdenredTypo from '@sharedComponent/typography';
import RadioButton from './RadioGroup';

const Index = () => {
	const prop = [
		{
			label: 'hello',
			value: 'Male',
			name: 'sujai',
		},
		{
			label: 'happy',
			value: 'Female',
			name: 'sujai',
		},
	];

	return (
		<>
			<div style={{ margintop: '20px' }}>
				<EdenredTypo h1 bold>
					Radio groups
				</EdenredTypo>

				<RadioButton prop={prop} />
			</div>
		</>
	);
};

export default Index;
