import './text-field.scss';
import React, { useState } from 'react';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons/index';

const Input = ({
	labels = '',
	placeholder = ' ',
	edit = '',
	onChange,
	value,
	icon,
	type,
	link,
	disabled,
	validation = false,
	onPasswordChange,
	className = '',
	labelClassName = '',
	countryCode = false,
	countryCodeClassName,
	name,
	onKeyDown = false,
	pattern,
	maxLength,
	minDate,
	maxDate,
	isloading = false,
	isError = false,
	errorMessage = 'lease enter 16 digits',
	maxlength,
}) => {
	const [hide, setHide] = useState(false);
	const [editDisabled, setEditDisabled] = useState(true);

	const handlePasswordChange = (event) => {
		onPasswordChange(event.target.value);
	};

	const toggleShow = () => {
		setHide(!hide);
	};

	const handleEditOption = () => {
		setEditDisabled(false);
	};
	const FloatingInput = () => {
		return (
			<div className="floating-label">
				<input
					type={link ? (hide ? type : 'text') : type}
					value={value}
					max={maxDate ? maxDate : '9999-12-31'}
					min={minDate && minDate}
					onChange={onPasswordChange ? handlePasswordChange : onChange}
					placeholder={placeholder}
					disabled={editDisabled && disabled}
					name={name}
					onKeyDown={onKeyDown}
					maxLength={maxLength}
					className={`${isError ? 'floating-input-error' : 'floating-input'} ${className ? ' ' + className : ''}`}
					pattern={pattern}
				/>
				{isError && <div className="error-show"> {errorMessage}</div>}
				{isloading ? (
					<div className="dropdown_loader"></div>
				) : (
					edit && <ImageComponent src={icons.not_focus.Edit} className="input_img" onClick={handleEditOption} />
				)}
				{icon ? <div className="input_img"> {icon} </div> : null}
				{link ? (
					hide ? (
						<EdenredTypo medium b3 onClick={toggleShow} className="link_text">
							Show
						</EdenredTypo>
					) : (
						<EdenredTypo medium b3 onClick={toggleShow} className="link_text">
							Hide
						</EdenredTypo>
					)
				) : null}
				{labels && <label className={`form-field__label${labelClassName ? ' ' + labelClassName : ''}`}>{labels}</label>}
			</div>
		);
	};

	return !countryCode ? (
		FloatingInput()
	) : (
		<div className={`${countryCodeClassName}`}>
			<ImageComponent src={countryCode.icon} />
			<EdenredTypo>{countryCode.code}</EdenredTypo>
			{FloatingInput()}
		</div>
	);
};

export default Input;
