import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import EdenredModal from '@sharedComponent/modal';
import EmployeeService from 'src/services/employee-service';

import EdenredSearch from '@sharedComponent/search-input';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Table from '@pages/DesignSystem/Table/Table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { entriesDropDown } from '@helpers';

const DeleteEmployeesInBulkSubmit = () => {
	const [submitDeleteModal, setSubmitDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	let employeeApi = new EmployeeService();
	let history = useHistory();
	const [popupVisible, setpopupVisible] = useState(false);
	let [pageSize, setPageSize] = useState(10);

	let [searchedText, setSearchedText] = useState('');
	const { state } = useLocation();

	const { inValidEmployees, validEmployees } = state || {
		inValidEmployees: [],
		validEmployees: [],
	};
	let [pageNumber, setPageNumber] = useState(1);
	let [employeeDetails, setEmployeeDetails] = useState([]);
	const [employeesListBackUp, setEmployeesListBackUp] = useState([]);

	useEffect(() => {
		if (validEmployees && Array.isArray(validEmployees)) {
			setEmployeeDetails(validEmployees);
			setEmployeesListBackUp(validEmployees);
		}
	}, [state]);

	const showModal = () => {
		setSubmitDeleteModal(true);
	};

	const closeModal = () => {
		setSubmitDeleteModal(false);
	};

	const selectedEmployee = employeesListBackUp?.map((emp) => emp.employeeCode);

	useEffect(() => {
		if (searchedText) {
			const filteredTableData = employeesListBackUp?.filter((data) => {
				return (
					data?.employeeName?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0 ||
					data?.employeeCode?.toString()?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0
				);
			});
			setEmployeeDetails(filteredTableData);
			if (pageNumber && pageSize) {
				let start = (pageNumber - 1) * pageSize;
				let end = start + pageSize;
				let f = filteredTableData.slice(start, end);
				setEmployeeDetails(f);
			}
		} else {
			let a = employeesListBackUp;
			let start = (pageNumber - 1) * pageSize;
			let end = start + pageSize;
			let f = a?.slice(start, end);
			if (f?.length === 0) {
				setPageNumber(1);
			}
			setEmployeeDetails(f);
		}
	}, [searchedText, employeesListBackUp, pageNumber, pageSize]);

	async function deleteEmployees() {
		setLoading(true);
		let DeleteEmployeeType = state.DeleteEmployeeType || 'Immediately';
		try {
			employeeApi
				.deleteEmployeesBulk(selectedEmployee, DeleteEmployeeType)
				.then((res) => res.json())
				.then((res) => {
					setpopupVisible(true);
					setLoading(false);
				});
		} catch (err) {
			setLoading(false);
		}
	}

	const closePopup = () => {
		setpopupVisible(false);
		setLoading(false);
		history.push('/employees/manage_employee');
	};

	const columns = [
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'employeeName',
			title: 'Name',
			dataIndex: 'employeeName',
			width: '11.7%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val}</p>;
			},
		},
		{
			sorter: (a, b) => a.employeeCode.localeCompare(b.employeeCode),
			key: 'employeeCode',
			title: 'Employee ID',
			dataIndex: 'employeeCode',
			width: '11.7%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val}</p>;
			},
		},
	];

	return (
		<>
			{/* <Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup> */}
			<Edenredmodal
				isOpen={popupVisible}
				title="The employees have been deleted"
				desc="You can no longer run payroll for these employees. You will stop being invoiced for any deleted C3Pay cards."
				onOkay={closePopup}
				onOkayButton="Return To Manage Employees"
			/>
			<Col className="delete-employee-in-bulk-submit">
				<Row className="delete_multiple_employees_submit_container">
					<section className="view_card_details_title_container_wrapper delete_employee_view_card_details_title_container_wrapper">
						<div className="title_section">
							<h1>You’re deleting the following employees.</h1>
							<h3>Click “submit” to proceed</h3>
						</div>
						<div className="buttons_wrapper">
							<EdenredButtons btnType="secondary" onClick={() => history.goBack()}>
								Cancel
							</EdenredButtons>
							<EdenredButtons btnType="primary" loading={loading} onClick={deleteEmployees}>
								Submit
							</EdenredButtons>
						</div>
					</section>
					<div style={{ width: '100%', height: '100%' }} className="display_none_max_width_600px">
						<section className="view_card_details_table_wrapper">
							<div className="header">
								<div className="search-option">
									<EdenredSearch
										onChange={(event) => setSearchedText(event.target.value)}
										value={searchedText}
										placeholder="Search name, employee ID..."
										prefix={<ImageComponent src={icons.not_focus.Search1} className="search_image" />}
									/>
								</div>
								<div className="no_of_data_to_show">
									<p>show</p>
									<MultiSelect
										{...{
											Option: entriesDropDown,
											value: pageSize,
											dropDownValue: 'value',
											searchVisibility: false,
											onChange: (page) => setPageSize(page.value),
											checkboxIcon: (
												<span className="entries_check_box">
													<img src={icons.not_focus.tick} alt="check" />
												</span>
											),
										}}
									/>
									<p>Entries</p>
								</div>
							</div>
							<div>
								<Table columns={columns} data={employeeDetails} sticky={true} tableLoading={false} />
							</div>
							<dev className="pagination-bar card_status_table_pagination_wrapper ">
								<ETypo b3 light color="#484B52">
									Showing {employeeDetails?.length} out of {employeesListBackUp?.length} employees
								</ETypo>
								<Pagination
									className="pagination-bar padding_0"
									currentPage={pageNumber}
									totalCount={employeesListBackUp?.length}
									pageSize={pageSize}
									onPageChange={(page) => setPageNumber(page)}
								/>
							</dev>
						</section>
					</div>

					<EdenredModal
						isOpen={submitDeleteModal}
						getDeleterole={closeModal}
						closeModal={closeModal}
						go_Back={false}
						yes="Return To Manage Employees"
						link="/employees/manage_employee"
						className="delete_multiple_employees_submit_modal"
						user="The employees have been deleted"
						content_web="Your verifier needs to approve this to confirm the removal of your employees if your approval settings are active."
						content_mobile="Your verifier needs to approve this to confirm the removal of your employees if your approval settings are active."
					/>
				</Row>
			</Col>
		</>
	);
};

export default DeleteEmployeesInBulkSubmit;
