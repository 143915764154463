import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { useHistory } from 'react-router-dom';
import userManager from '@config/user-manager';
import mfaActions from '@pages/auth/mfa/mfaActions';
import { MFA_CONSTANTS } from '@pages/auth/mfa/constants';
import { asyncLocalStorage } from '@helpers';
import { ALL_EMPLOYEE_CLAIMS, ALL_PAYROLL_CLAIMS, API_STATUS_CODES, APP_VARIABLES, PERMISSIONS } from '@constants/app-constants';
import { SITEMAP } from 'src/routes/sitemap';
import { CorporateService } from 'src/services';
import { message } from 'antd';
import { COOKIE_CONSTANTS, deletingCookie, gettingCookie, settingCookie } from 'src/helpers/cookies';
import { employeeVerifyActions } from '@pages/employees/EmployeeVerification/redux';
import { mergeAllClaims } from 'src/utils/claims';
import SharedServices, { GET_PROFILE } from './app-action-reducer/services';
import sharedActions from "@sharedComponent/app-action-reducer/action";

// Currenlty the callback component route work like this	
// API calls - Corporate -> Users -> User Navigation -> MFA -> CBD -> Employee verify -> dashboard
const CallbackPage = () => {
	const dispatch = useDispatch()
	const auth = useSelector((state) => state.auth)
	const employeeVerification = useSelector((state) => state.employees?.verification)
	const history = useHistory();
	const [userData, setUserData] = useState(null)
	const [failure, setFailure] = useState(false)
	const [isCorporateIdExist, setIsCorporateIdExist] = useState(false)
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID)
	// These check are related to a new users checks
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	const allClaims = useMemo(() => mergeAllClaims(userData), [userData]);

	useEffect(() => {
		if (userData && auth.mfa.mode) {
			mfaPageRoutig()
		}
		if (failure) {
			makeProperLogin()
		}
	}, [auth.mfa?.mode, failure]);

	// page route check to employee verification page
	useEffect(() => {
		if (employeeVerification) {
			// previously we have employee verify check here before dashboard but removed the flow now
			redirectToDashboard()
		}
	}, [employeeVerification]);


	// calling employee verification api once corporate id is ready.
	useEffect(() => {
		if (isCorporateIdExist) {
			callEmployeeVerifyCheck()
		}
	}, [isCorporateIdExist]);

	// --------------Page redirect functions-------------------------

	function redirectToMfa() {
		history.replace({ pathname: SITEMAP.auth.mfa, state: { user: userData } });
	}

	function redirectToTermsAndConditions() {
		if (gettingCookie(COOKIE_CONSTANTS.mfa.inProgress)) {
			deletingCookie(COOKIE_CONSTANTS.mfa.inProgress)
		}
		history.replace({ pathname: SITEMAP.termsAndConditions.index, state: { user: userData } });
	}

	function redirectToDashboard() {
		// while moving to dashboard checking cbtclient check in cookies and deleting if exists
		if (gettingCookie(COOKIE_CONSTANTS.cbdClient.tc)) {
			deletingCookie(COOKIE_CONSTANTS.cbdClient.tc);
		}
		// If not mfa removing the mfa progress flag to avoid redirecting
		if (gettingCookie(COOKIE_CONSTANTS.mfa.inProgress)) {
			deletingCookie(COOKIE_CONSTANTS.mfa.inProgress)
		}
		history.replace({ pathname: SITEMAP.dashboard.index, state: { user: userData } });
	}

	// check mfa api
	const checkMfa = () => {
		dispatch(mfaActions.creators.checkingMfa());
	}

	const isOnlyEmployeeProfile = () => {
		if (Array.isArray(allClaims) && allClaims?.length <= 0) {
			return true;
		}
		const CLAIMS_FOR_NAVIGATING_TO_HOME = {
			toShow: [
				PERMISSIONS.MANAGE_LEAVES,
				PERMISSIONS.MANAGE_DOCUMENTS,
				PERMISSIONS.MANAGE_ANNOUNCEMENTS,
				PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,
				...ALL_EMPLOYEE_CLAIMS,
			],
			notToShow: [...ALL_PAYROLL_CLAIMS],
		};

		const NAVIGATE_TO_HOME = {
			HAS_VALID_CLAIMS: CLAIMS_FOR_NAVIGATING_TO_HOME.toShow.some((_claims) => allClaims.indexOf(_claims) >= 0),
			DOES_NOT_CONTAIN_UNWANTED_CLAIMS:
				CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow && !CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow.some((values) => allClaims.indexOf(values) >= 0),
		};

		if (NAVIGATE_TO_HOME.HAS_VALID_CLAIMS === true && NAVIGATE_TO_HOME.DOES_NOT_CONTAIN_UNWANTED_CLAIMS === true) {
			return true;
		} else {
			return false;
		}
	}

	// getting user details from /Users/me api
	const getUserDetails = async (oidcUser) => {
		try {
			const sharedServices = new SharedServices()
			const res = await sharedServices.services(GET_PROFILE)
			const { employeeClaims, hrClaims, payrollClaims, teamClaims, user } = res?.data || {}
			const allClaims = [...hrClaims, ...payrollClaims, ...teamClaims];
			const isOnlyEmployee = allClaims.length === 0 && employeeClaims.length > 0
			dispatch(sharedActions.creators.setUser(res.data))
			if (isOnlyEmployee) {
				dispatch(sharedActions.creators.setIsOnlyEmployeeExits(isOnlyEmployee))
			}
			if (user?.roles && user?.roles?.length > 0 && user?.roles[0].name === 'Verifier') {
				dispatch(sharedActions.creators.getRoles())
			}
			// User navigation api
			dispatch(sharedActions.creators.getUserNavigation())
			/**
		   * loginstep=1 new user first time login show carousel popup
		   * loginstep=2 new user first time login how card based on claims
		   * loginstep=0 completed login step for new user to skip first time login
		   */
			if (!user?.isPasswordReset) {
				history.push({
					pathname: SITEMAP.user.createPassword,
					state: {
						user: oidcUser
					},
				});
			}
			else if (!isOnlyEmployeeExits && user?.loginStep === 1 && isOnlyEmployeeProfile() === false) {
				history.push({
					pathname: SITEMAP.user.loginStep,
					state: {
						loginStep: user?.loginStep,
						user: oidcUser
					},
				});
			}
			else {
				// Mfa call check
				checkMfa()
			}

		} catch (error) {
			message.error('Something went wrong')
		}
	};


	// ----------------------------Corporates api check --------------------
	const callCorporates = async (oidcUser) => {
		try {
			const corporateService = new CorporateService()
			const res = await corporateService.getAllCorporates()
			const corporateId = res.data?.corporates?.length ? res.data?.corporates[0]?.corporateId : null;
			const corporateName = res.data?.corporates?.length ? res.data?.corporates[0]?.corporateName : null;
			await asyncLocalStorage.setItem(APP_VARIABLES.CORPORATE_ID, corporateId);
			await asyncLocalStorage.setItem(APP_VARIABLES.CORPORATE_NAME, corporateName);
			// after the corporates api calling the Users to get user details
			await getUserDetails(oidcUser)
		} catch (error) {
			console.log({ error })
		}
	}



	// mfa page route
	function mfaPageRoutig() {
		function isMfa() {
			return auth.mfa.mode === MFA_CONSTANTS.mfa.SETUP || auth.mfa.mode === MFA_CONSTANTS.mfa.REQUIRED || auth.mfa.mode === MFA_CONSTANTS.mfa.LOCKED
		}
		function isNotMfa() {
			return auth.mfa.mode === MFA_CONSTANTS.mfa.OFF || auth.mfa.mode === MFA_CONSTANTS.mfa.VERIFIED
		}
		if (isMfa()) {
			redirectToMfa()
		}
		else if (isNotMfa()) {
			checkCbdClient()
		} else {
			checkCbdClient()
		}
	}

	// Checking Cbd client check
	async function checkCbdClient() {
		try {
			const corporateService = new CorporateService()
			const res = await corporateService.checkCbdClient()
			if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
				throw new Error('Something went wrong')
			}
			const { data } = res.data || {}
			// If we get data from the response and if its a cbdclient and client not accepted TC we redirect to TC page
			if (data && data?.isCbdClient && !data?.isCbdClientAcceptedTc) {
				settingCookie(COOKIE_CONSTANTS.cbdClient.tc, 'true')
				redirectToTermsAndConditions()
			}
			else if (corporateId) {
				setIsCorporateIdExist(true)
			}
			else {
				redirectToDashboard()
			}

		} catch (error) {
			if (error?.message) {
				message.error(error?.message);
			}
			console.error(error, 'cbd client api')
			if (corporateId) {
				setIsCorporateIdExist(true)
			}
		}
	}

	// ---------------- Employee verification api ------------------------->
	const callEmployeeVerifyCheck = async () => {
		dispatch(employeeVerifyActions.creators.employeeVerify())
	}

	// -------------- Callback page route --------------------------->

	function makeProperLogin() {
		// If the user not logout properly and tries to login callback will fail
		// so in that case we direct to relogin again properly 			
		localStorage.clear()
		userManager.signinRedirect('/');
	}

	const successCallback = async (user) => {
		setUserData(user)
		localStorage.setItem(APP_VARIABLES.ID_TOKEN, user.id_token);
		asyncLocalStorage
			.setItem(APP_VARIABLES.ACCESS_TOKEN, JSON.stringify({ access_token: user.access_token, username: user.profile.username }))
			.then(function () {
				callCorporates(user)
			});

	};

	const errorCallback = (error) => {
		setFailure(true)
		console.log('SSO ERROR ::', error);
	};

	return (
		<CallbackComponent
			userManager={userManager}
			successCallback={successCallback}
			errorCallback={errorCallback}>
			<div>Redirecting...</div>
		</CallbackComponent>
	);
}

export default connect()(CallbackPage);
