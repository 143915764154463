import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../monthwise-action';
import Axios from 'axios';

export function* monthwiseDepartmentService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/department`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getMonthWiseDepartmentSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getMonthWiseDepartmentFail(err));
	}
}

export default function* salaryMonthWiseWatcher() {
	yield takeEvery(Actions.types.GET_MONTHWISE_DEPARTMENT_START, monthwiseDepartmentService);
}
