import React, { useState, useEffect, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '@sharedComponent/app-action-reducer/action';
import {
	ALL_EMPLOYEE_CLAIMS,
	ALL_PAYROLL_CLAIMS,
	APP_VARIABLES,
	EMITTER_CONSTANT,
	FILE_TEMPLATES,
	PERMISSIONS,
	announcementClaimCheck,
	documentClaimCheck,
	leaveClaimCheck,
	payrollClaimCheck,
} from '@constants/app-constants';
import AppEmitter, { downloadRandomImage } from 'src/utils/emitter';
import { useInitHotjar } from 'src/helpers/hooks';
import PayrollService from 'src/services/payroll-service';
import SharedServices, { GET_TEMPLATES } from '@sharedComponent/app-action-reducer/services';
import MainLayoutPresentational from './main-layout-presentational';
import useRedirectBasedOnClaim from 'src/hooks/useRedirectBasedOnClaim/useRedirectBasedOnClaim';
import { mergeAllClaims } from 'src/utils/claims';
import { successToast } from 'src/utils/toast';
import { default as payrollPlusActions } from 'src/pages/payroll-plus-new/action';
import StatusToast from '@pages/DesignSystem/StatusToast';
import { SITEMAP } from 'src/routes/sitemap';

let TIMER_CALL = '';
const MainLayoutFunctional = ({ username, currentUser, subscription }) => {
	const [openKeys, setOpenKeys] = useState([]);
	const [fileTemplates, setfileTemplates] = useState([
		{
			id: 0,
			name: 'employee-file.xlsx',
			value: FILE_TEMPLATES.EMPLOYEE_FILE,
			fileUrl:
				'https://eaec3portalstored.blob.core.windows.net/receipt/09465add-8ccd-4943-bf88-9669a902b89b_Edenred_D061220_Status.xlsx?sv=2019-07-07&ss=b&srt=sco&se=2030-12-09T07%3A17%3A11Z&sp=racupwdl&sig=PQhglZaSdsHWtnqF5KJKDpw5SbAXrnafXcz75pUGZRk%3D',
		},
	]);
	const rootKeys = ['sub1', 'sub2', 'sub3'];
	const defaultOpenKeys = ['sub1'];
	const [loading, setLoading] = useState(true);
	const [loadingRefresh, setLoadingRefresh] = useState(false);
	const _payrollService = new PayrollService();
	const _sharedServices = new SharedServices();
	const [approveReqCount, setApproveReqCount] = useState();
	const history = useHistory();
	const dispatch = useDispatch();
	const { user } = currentUser;
	const PayrollClaims = (currentUser && currentUser?.payrollClaims) || [];
	const hrClaims = (currentUser && currentUser?.hrClaims?.map((data) => data?.value)) || [];
	const teamClaims = (currentUser && currentUser?.teamClaims?.map((data) => data?.value)) || [];
	const employeeClaims = (currentUser && currentUser?.employeeClaims) || [];
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	const user_Name = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const email = useSelector((state) => state?.sharedStates?.currentUser?.user?.userEmail);
	const corporate_Id = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	const corporateId = useSelector((state) => state?.sharedStates?.corporateId);
	const corporateIds = useSelector((state) => state?.sharedStates?.corporateIds);
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);
	const payrollClaims = (currentUser && currentUser?.payrollClaims?.map((data) => data?.value)) || [];
	const claimsCheck = [...payrollClaimCheck, ...documentClaimCheck, ...announcementClaimCheck, ...leaveClaimCheck];
	const checkUserClaims = [...hrClaims, ...payrollClaims].filter((data) => claimsCheck.includes(data));

	let postBankRes = useSelector((state) => {
		return state?.payrollPlusState?.postRakBankDetailsResponse;
	});
	let isToastMessageAvailable = useSelector((state) => state?.payrollPlusState?.postRakBankToastRes);
	const setup = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSetupSkipped = useSelector((state) => state?.hrSetup?.isHrSetupSkipped);
	const userSubscriptionDetails = useSelector((state) => state?.sharedStates?.subscriptionProds?.[0]);
	const [hrNavState, setHrNavState] = useState(); // added extra flag to check navigation for intro page and to skip (unemployed insurance)
	useRedirectBasedOnClaim();

	//custom hooks of hotjar user attribute
	useInitHotjar(user_Name, corporate_Id, email);
	const isHrSetupDone = () => {
		if (userSubscriptionDetails?.isFreeTrailOn || userSubscriptionDetails?.isSubscriptionOn === true) {
			if (setup?.hrOnBoardingFlowCompleted !== true && isSetupSkipped === false && checkUserClaims.length > 0) {
				history.push('/new-hr-features/summary');
				return true;
			} else {
				return false;
			}
		}
	};
	useEffect(() => {
		if (user) {
			if (!user.isPasswordReset) {
				setLoading(false);
				history.push(SITEMAP.user.createPassword);
			} else if (!isOnlyEmployeeExits && user?.loginStep === 1 && isOnlyEmployeeProfile() === false) {
				setLoading(false);
				history.push({
					pathname: SITEMAP.user.loginStep,
					state: {
						loginStep: user?.loginStep,
					},
				});
			} else if (corporateId && typeof corporateId === 'string' && corporateIds && corporateIds.length > 0) {
				if (isOnlyEmployeeProfile() === false) {
					setHrNavState(true);
					if (isHrSetupDone() === true) {
						history.push('/new-hr-features/summary');
					}
				}
				const [corporate] = corporateIds.filter((_corporate) => _corporate.corporateId === corporateId);
				if (
					hrNavState &&
					corporate &&
					(corporate.isUnemploymentInsuranceSubmit === undefined || corporate.isUnemploymentInsuranceSubmit === false)
				) {
					/**
					 * To open requested screen on new window, Not to redirect them to insurance screen
					 */
					const _currentLocationRoute = window.location.href;
					const IS_CARD_ORDER_STATUS =
						_currentLocationRoute.includes('/employees/card/order/status') && _currentLocationRoute.endsWith('/status');
					const IS_BANK_DETAILS =
						_currentLocationRoute.includes('/payroll/bank_details') && _currentLocationRoute.endsWith('/bank_details');
					const IS_PROOF_OF_TRANSFER =
						_currentLocationRoute.includes('/payroll/proof_of_transfer') && _currentLocationRoute.endsWith('/proof_of_transfer');
					const IS_PAYROLL_HISTORY = _currentLocationRoute.includes('/payroll/history') && _currentLocationRoute.endsWith('/history');
					if (!(IS_CARD_ORDER_STATUS || IS_BANK_DETAILS || IS_PROOF_OF_TRANSFER || IS_PAYROLL_HISTORY) && hrNavState) {
						history.push('/un-employment-insurance/intro');
					}
					setLoading(false);
				} else {
					// history.push(routePathTo(PayrollClaims, hrClaims, teamClaims, employeeClaims));
					if (isHrSetupDone() === true) {
						history.push('/new-hr-features/summary');
					} else {
						if (isOnlyEmployeeProfile() === true) {
							history.push('/home');
						}
					}
					setLoading(false);
				}
			} else {
				// history.push(routePathTo(PayrollClaims, hrClaims, teamClaims, employeeClaims));
				if (isHrSetupDone() === true) {
					history.push('/new-hr-features/summary');
				} else {
					if (isOnlyEmployeeProfile() === true) {
						history.push('/home');
					}
				}
				setLoading(false);
			}
			dispatch(Actions.creators.getPendingStatusStart());
			// payroll/run/channel/next_steps
		}
	}, [user]);

	function isOnlyEmployeeProfile() {
		if (Array.isArray(allClaims) && allClaims.length <= 0) {
			return true;
		}
		const CLAIMS_FOR_NAVIGATING_TO_HOME = {
			toShow: [
				PERMISSIONS.MANAGE_LEAVES,
				PERMISSIONS.MANAGE_DOCUMENTS,
				PERMISSIONS.MANAGE_ANNOUNCEMENTS,
				PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,
				...ALL_EMPLOYEE_CLAIMS,
			],
			notToShow: [...ALL_PAYROLL_CLAIMS],
		};

		const NAVIGATE_TO_HOME = {
			HAS_VALID_CLAIMS: CLAIMS_FOR_NAVIGATING_TO_HOME.toShow.some((_claims) => allClaims.indexOf(_claims) >= 0),
			DOES_NOT_CONTAIN_UNWANTED_CLAIMS:
				CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow && !CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow.some((values) => allClaims.indexOf(values) >= 0),
		};

		if (NAVIGATE_TO_HOME.HAS_VALID_CLAIMS === true && NAVIGATE_TO_HOME.DOES_NOT_CONTAIN_UNWANTED_CLAIMS === true) {
			return true;
		} else {
			return false;
		}
	}

	// const routePathTo = (PayrollClaims, hrClaims, teamClaims, employeeClaims) => {
	// 	if (PayrollClaims?.length === 0) {
	// 		if (employeeClaims?.length > 0 || hrClaims?.includes(HrClaims?.MANAGE_LEAVES)) {
	// 			return '/home';
	// 		} else if (hrClaims?.includes(HrClaims?.MANAGE_DOCUMENTS)) {
	// 			return '/hrdocuments';
	// 		} else if (hrClaims?.includes(HrClaims?.MANAGE_ANNOUNCEMENTS)) {
	// 			return '/announcement';
	// 		} else if (teamClaims?.includes(userClaims?.MANAGE_ROLE)) {
	// 			return '/manage_team';
	// 		} else {
	// 			return '/company/information';
	// 		}
	// 	} else {
	// 		return history?.location?.pathname;
	// 	}
	// };
	const getTemplate = () => {
		_sharedServices
			.services(GET_TEMPLATES)
			.then((res) => {
				setfileTemplates(res.data.fileTemplates);
			})
			.catch((err) => {});
	};

	useEffect(() => {
		AppEmitter.addListener(EMITTER_CONSTANT.SAVE_FILE, (value) => downloadRandomImage(value, fileTemplates));
		if (currentUser.user) {
			return function cleanup() {
				clearTimeout(TIMER_CALL);
			};
		}
	}, [currentUser, fileTemplates, _payrollService]);

	const getApproveRequestCount = () => {
		setLoadingRefresh(true);
		_payrollService
			.getApproveRequestCountAPI()
			.then((res) => {
				setApproveReqCount(res && res.data && res.data.pendingRequest ? res.data.pendingRequest : null);
				setLoadingRefresh(false);
			})
			.catch((error) => {
				console.log(error);
				setLoadingRefresh(false);
			});
	};

	const onOpenChange = (items) => {
		const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
		if (rootKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(items);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : defaultOpenKeys);
		}
	};

	useEffect(() => {
		subscription();
	}, []);

	const toastExecuter = () => {
		successToast({
			msg: postBankRes,
			icon: 'Mail',
		});
	};
	useEffect(() => {
		isToastMessageAvailable && toastExecuter();
		setTimeout(() => {
			dispatch(payrollPlusActions?.creators?.postRakBankToast(false));
		}, 5000);
	}, [isToastMessageAvailable]);

	return (
		<>
			<StatusToast />
			<MainLayoutPresentational
				{...{
					loading,
					approveReqCount,
					getApproveRequestCount,
					loadingRefresh,
					onOpenChange,
					openKeys,
					username,
				}}
			/>
		</>
	);
};

function mapStateToProps({ sharedStates }) {
	const { currentUser, currentUserDetailsOIDC, setupStatusDetail } = sharedStates || {};
	const { username } = currentUserDetailsOIDC?.user?.profile || {};
	return { username, currentUser, setupStatusDetail };
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRoles: () => dispatch(Actions.creators.getRoles()),
		subscription: () => dispatch(Actions.creators.subscription()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutFunctional);
