import React from 'react';
import moment from 'moment';
import { popUpNotification } from '@constants/app-constants';
import { CustomAlertNotification } from '@pages/Leave-Management/request-leave/alert-notification/alert-notification';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import UploadPayrollPopup from 'src/utils/uploadPayrollPopup';
import Popup from 'src/utils/popup/popups';
import './indes.scss';

function PayrollDatePicker({ label, placeholder, dataPicker, date, employeeDetail, payRollRun1, defaultDate }) {
	const datevalid = 'Leaving date must be greater than Joining date';
	const joindate = moment(employeeDetail?.joiningDate).format('DD/MM/YYYY');
	const leave = moment(date).format('DD/MM/YYYY');
	const datepick = date !== undefined && joindate.split('/').reverse().join('-') > leave.split('/').reverse().join('-');
	const options = {
		allowInput: true,
		dateFormat: 'd/m/Y',
		disableMobile: true,
	};
	const value = moment(employeeDetail?.leavingDate).format('DD/MM/YYYY');
	return (
		<div className="payroll-flatpickrs">
			<DatePickers
				onChange={(dateObj, formatStr) => dataPicker(dateObj, formatStr, payRollRun1)}
				onKeyDown={(e) => e.keyCode >= 65 && e.keyCode <= 90 && e.preventDefault()}
				value={value}
				{...{
					label,
					showDatesIcon: false,
					options,
					placeholder,
				}}
			/>
			{datepick && <p className="date-validate-picker">{datevalid}</p>}
		</div>
	);
}

export default PayrollDatePicker;

export const PopUp = ({ savePopup, handleSavePayroll, savePayrollRunLoading, savedSuccessfully, isCancle, notification }) => {
	return (
		<>
			<UploadPayrollPopup
				visible={savePopup}
				closePopup={() => handleSavePayroll('closeSavePopUpRun')}
				autoFixEmployeeFileError={() => handleSavePayroll('handleSavePopUpRun')}
				message={popUpNotification.saveMessage}
				subMessage={popUpNotification?.subSaveMessage}
				className="payroll-save-popup"
				loading={savePayrollRunLoading}
				okText="Save"
				cancelText="Cancel"
			/>
			<UploadPayrollPopup
				visible={isCancle}
				closePopup={() => handleSavePayroll('closeCancelPopUpRun')}
				autoFixEmployeeFileError={() => handleSavePayroll('handleCancelPopUpRun')}
				message={popUpNotification.cancelMessage}
				subMessage={popUpNotification?.subCancelMessage}
				className="payroll-cancel-popup"
			/>
			<Popup
				visible={savedSuccessfully}
				message={{ titleMessage: popUpNotification?.successMessage }}
				subMessage={null}
				closePopup={() => handleSavePayroll('closeSuccessPopUpRun')}
			/>
			<CustomAlertNotification
				{...{
					when: notification,
					message: 'Do you want to leave this page? Your changes will not be saved.',
					okText: 'Yes, I want to leave',
					cancelText: 'Stay on Page',
					onOK: () => true,
					onCancel: () => true,
				}}
			/>
		</>
	);
};
