import React from 'react';
import { useHistory } from 'react-router-dom';
import AddIndividualHeader from '@pages/employees/add-individual-header/add-individual-header';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import HintCard from '@pages/DesignSystem/hindcard';
import { ADD_INDIVIDUAL_EMP } from '@constants/app-constants';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { getObjectFromArray } from '@helpers';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './add-individual-emp-document.scss';

const AddIndividualEmpDocumentPresenational = ({
	empPersonalDetails,
	empDocumentDetails,
	handleEmpDocDetails,
	bankNameOption,
	employeeDocumentDetails,
	disableButton,
	handleConformButton,
	isEmpDocumentLoading,
	empInfo,
	validateMessage,
	setEmployeeDocumentDetails,
}) => {
	const history = useHistory();

	return (
		<div className="add_emp_document_main">
			<AddIndividualHeader
				{...{
					showConform: true,
					loadingConform: isEmpDocumentLoading,
					handleBackButton: () => history.goBack(),
					disableConform: disableButton,
					handleConformButton,
				}}
			/>
			<div className="add_emp_document_card">
				<div className="add_emp_personal_info">
					<div className="add_emp_personal_header">
						<div className="add_emp_name">
							<ETypo medium h4 color="#181919">
								{empInfo?.firstName} {empInfo?.lastName}
							</ETypo>
							<ETypo light b2 color="#181919" style={{ marginTop: '8px' }}>
								{empInfo?.account === 'c3pay' ? 'C3Pay Cardholder' : 'Bank Accountholder'}
							</ETypo>
						</div>
						<div className="add_emp_button">
							<EdenredButtons
								btnType="secondary"
								onClick={() => setEmployeeDocumentDetails({ ...employeeDocumentDetails, isEditable: true })}
								leftIcon={<ImageComponent src={focus_state_icons?.editDetails} />}>
								Edit
							</EdenredButtons>
						</div>
					</div>
					<div className="add_emp_personal_detail">
						{empPersonalDetails?.map((data) => (
							<div className="emp_personal_text">
								<ETypo medium b2 color="#484B52">
									{data?.title}
								</ETypo>
								<ETypo light b2 color="#484B52" style={{ marginTop: '4px' }}>
									{data?.value}
								</ETypo>
							</div>
						))}
					</div>
				</div>
				{!!validateMessage?.length && (
					<div className="document_error">
						{validateMessage.map((data, index) => {
							if (data.errorMessage && typeof data.errorMessage === 'string') {
								return (
									<HintCard
										key={`error-${index}`}
										{...{
											hint: data.errorMessage,
											icon: focus_state_icons?.hrDocumentExpired,
											bgColor: '#FFF6F5',
										}}
									/>
								);
							} else {
								return <></>;
							}
						})}
					</div>
				)}
				{empInfo?.account === ADD_INDIVIDUAL_EMP?.BANK && (
					<div className="add_emp_bank_info">
						<ETypo medium h4>
							Enter bank details
						</ETypo>
						<div className="add_emp_bank_details">
							<div className="add_emp_bank_filed">
								<MultiSelect
									{...{
										label: 'Bank Name',
										placeholder: 'Select Bank Name',
										value: employeeDocumentDetails?.bankName,
										onChange: (e) => handleEmpDocDetails(e?.bankName, 'bankName'),
										required: true,
										Option: bankNameOption,
										dropDownValue: 'bankName',
									}}
								/>
							</div>
							<div className="add_emp_bank_filed">
								<Inputbox
									{...{
										label: 'IBAN Number',
										placeholder: 'Enter IBAN Number',
										value: employeeDocumentDetails?.Iban,
										onChange: (e) => handleEmpDocDetails(e?.target?.value, 'Iban'),
										required: true,
										maxLength: 23,
										isErrorMessage:
											employeeDocumentDetails?.Iban &&
											!!getObjectFromArray(validateMessage, 'columnName', 'Iban')?.errorMessage,
										errorMessage: getObjectFromArray(validateMessage, 'columnName', 'Iban')?.errorMessage,
									}}
								/>
							</div>
						</div>
					</div>
				)}
				<div className="add_emp_document_info">
					<ETypo medium h4>
						Enter document details
					</ETypo>
					<div className="add_emp_document_details">
						{empDocumentDetails?.map(
							({ label, placeholder, value, Component, onChange, param, required, maxLength, isErrorMessage, errorMessage }) => (
								<div className="add_emp_doc_filed">
									<Component
										{...{ label, placeholder, value, onChange, param, required, maxLength, isErrorMessage, errorMessage }}
									/>
								</div>
							)
						)}
					</div>
				</div>
				<div className="add_emp_doc_bottom">
					<ETypo light small color="#2B769C" style={{ textDecoration: 'underline' }}>
						{empInfo?.firstName} {empInfo?.lastName} has not received the Emirates ID yet
					</ETypo>
				</div>
			</div>
			<Edenredmodal
				{...{
					isOpen: employeeDocumentDetails?.isEditable,
					onOkayButton: 'Ok',
					title: 'Employee not Created',
					onOkay: () => setEmployeeDocumentDetails({ ...employeeDocumentDetails, isEditable: false }),
				}}
			/>
		</div>
	);
};

export default AddIndividualEmpDocumentPresenational;
