import React from 'react';
import { useHistory } from 'react-router-dom';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { images } from '@assets/img';
import ETypo from '@pages/DesignSystem/Typo';
import { Col } from 'antd';
import './itemHeader.scss';

const ItemHeader = ({ title = '', description = '', goback = null }) => {
	const history = useHistory();

	const goingBack = () => {
		if (goback && typeof goback === 'function') {
			goback();
		} else {
			history.goBack();
		}
	};

	return (
		<Col className="item_header">
			<div className={description ? 'item_header_text' : ''}>
				<ETypo bold h2>
					{title}
				</ETypo>
				{description && (
					<ETypo b1 light>
						{description}
					</ETypo>
				)}
			</div>
			<div>
				<EdenredButtons onClick={goingBack} btnType="secondary" leftIcon={<ImageComponent className="leftIcon" src={images.leftsarrow} />}>
					Go Back
				</EdenredButtons>
			</div>
		</Col>
	);
};

export default ItemHeader;
