import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import InvoiceDetailFunctional from './components/invoice-detail-functional';
import reducer from './invoiceDetailReducer';
import saga from './saga';
import './invoice.scss';

const InvoiceDetailPage = () => {
	return <InvoiceDetailFunctional />;
};

const ReducedScreen = withReducer('invoiceState', reducer, saga)(InvoiceDetailPage);

export default ReducedScreen;
