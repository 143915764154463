import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { Card, Col, Row, Upload } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import ErrorTable from '@sharedComponent/error-table';
import FreshdeskWidgetHelper from '@sharedComponent/freshdesk/FreshdeskWidgetHelper';
import ImageComponent from '@sharedComponent/image-component';
import { HR_ALLOWED_FILETYPES, HR_SETUP_UPLOAD } from '@constants/app-constants';
import EDragger from '@pages/DesignSystem/Dragger';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ClevertapReact from 'src/utils/clever-tap';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { withReducer } from 'src/store/reducerLoader';
import Selectors from '../Upload-document/selector';
import action from '../Upload-document/action';
import reducer from '../Upload-document/reducer';
import sagas from '../Upload-document/saga/index';
import '../component/uploaddocument.scss';
import ETypo from '@pages/DesignSystem/Typo';
import BankDetailsPresentational from './../../bank_details/components/bank-details-presentational';
import { images } from 'src/assets/img';

const Uploaddocument = ({
	uploadDocuments,
	hrUploadLoading,
	hrDocumentUpload,
	downloadTemplate,
	isHrDocUploaded,
	filterData,
	resetDocuments,
	saveDocumentUploadReset,
	setUpUpload = [],
	isSetup = false,
	setUpName = '',
	hintText = '',
	description = '',
	draggerHeader = '',
	nextPath = '',
	docLink = '',
	tableData = [],
	isLeaveUploadLoading = false,
	leaveUploadDetails = [],
	leaveApproveUploadSaga = () => {},
	uploadType = 'hrDocument',
	isLeaveDocUploaded,
	isleaveApproveUpload = false,
	resetUploadDocuments = () => {},
	onClickCancel,
	onClickNext,
	descriptions,
}) => {
	const [modalDrag, setModalDrag] = useState(false);
	const [hasErrorFile, setHasErrorFile] = useState(false);
	const [fileLists, setfileLists] = useState([]);
	const history = useHistory();
	const [counter, setcounter] = useState(0);
	const [errordataList, setErrorDataList] = useState([]);
	const location = useLocation();
	const nFilterDocument = location?.state?.filterDocument;
	const [files, setFiles] = useState({});
	const [filterDocument, setFilterDocument] = useState(
		nFilterDocument
			? nFilterDocument
			: {
					documentTypeFilter: null,
					expiryDateFilter: null,
					employeeIdNameFilter: '',
					pageNumber: 1,
					pageSize: 20,
					reportType: '',
			  }
	);
	const [uploads, setUploads] = useState([]);
	const refValue = useRef();

	// for setUp screen
	const uploadLoading = isSetup && uploadType !== 'hrDocument' ? isLeaveUploadLoading : hrUploadLoading;
	const documentUpload = isSetup && uploadType !== 'hrDocument' ? leaveUploadDetails : hrDocumentUpload;
	const isDocUploaded = isSetup && uploadType !== 'hrDocument' ? isLeaveDocUploaded : isHrDocUploaded;
	const resetDocument = isSetup && uploadType !== 'hrDocument' ? resetUploadDocuments : saveDocumentUploadReset;
	const uploadCancelPush = isSetup && uploadType !== 'hrDocument' ? '/setup' : '/hrdocuments';
	const dispatch = useDispatch();

	useEffect(() => {
		resetDocument();
	}, []);

	useEffect(() => {
		setUploads(documentUpload);
	}, [documentUpload]);

	useEffect(() => {
		if (isDocUploaded === 'loading' || isDocUploaded === false) {
			setModalDrag(false);
		} else {
			setModalDrag(true);
		}
	}, [isDocUploaded]);

	useEffect(() => {
		isDocUploaded === false && setfileLists([]);
	}, [isDocUploaded]);

	const isUploadButtonDisabled = useMemo(() => {
		return isDocUploaded === 'yes' || isDocUploaded === 'loading' || isDocUploaded === false || fileLists?.length === 0;
	}, [isDocUploaded, fileLists]);

	// for setUp screen
	const isHrDocSetup = isSetup && uploadType === 'hrDocument';
	const upload = [
		{
			description: '1',
			list: (
				<span className="spanA">
					{isHrDocSetup ? HR_SETUP_UPLOAD?.[0] : 'Download our template'}
					<Link
						to={isHrDocSetup ? docLink : '/hrdocuments/uploaddocument'}
						onClick={() => {
							isSetup ? dispatch(action.creators.setupTemplateStart()) : downloadTemplate();
							ClevertapReact.event('Bulk_Download_Template');
						}}
						className="forHere">
						Here
					</Link>
				</span>
			),
		},
		{
			description: '2',
			list: (
				<span className="spanA">
					{isHrDocSetup ? HR_SETUP_UPLOAD?.[1] : 'Download a filtered spreadsheet from the table'}
					{!isSetup && (
						<Link
							to=""
							onClick={() => {
								history.goBack();
								ClevertapReact.event('Bulk_Download_FilteredSpreadsheet');
							}}
							className="forHere">
							Here
						</Link>
					)}
				</span>
			),
		},
	];

	const uploadFile = (file) => {
		if (isSetup && uploadType !== 'hrDocument') {
			leaveApproveUploadSaga(file);
		} else {
			uploadDocuments(file);
		}
		setModalDrag(true);
	};

	useEffect(() => {
		const FreshdeskWidgetElement = document.getElementById('freshworks-container');
		if (FreshdeskWidgetElement && counter >= 2) {
			var element = document.getElementById('freshworks-container');
			element.classList.add('freshdeskShow');
			element.classList.remove('freshdeskHide');
		}
		return () => {
			const FreshdeskWidgetElement = document.getElementById('freshworks-container');
			if (FreshdeskWidgetElement) {
				var element = document.getElementById('freshworks-container');
				element.classList.add('freshdeskHide');
				element.classList.remove('freshdeskShow');
			}
		};
	}, [counter]);

	if (counter >= 2) {
		let widgetDetails = { wigetid: '67000002930' };
		FreshdeskWidgetHelper(widgetDetails);
	}

	useEffect(() => {
		if (nFilterDocument) {
			filterData(filterDocument);
		}
	}, [nFilterDocument]);

	const handleDragger = (file) => {
		if (HR_ALLOWED_FILETYPES.indexOf(file.name && file.name.split('.').splice(-1)[0]) >= 0) {
			uploadFile(file);
			setfileLists(file);
			setHasErrorFile(false);
		} else {
			setHasErrorFile(true);
		}
		ClevertapReact.event('Bulk_Upload_Spreadsheet_DragDrop');
	};
	const onRemoveUpload = () => {
		setUploads([]);
		setHasErrorFile(false);
		setfileLists([]);
		setModalDrag(false);
		setFiles({});
		setErrorDataList([]);
		resetDocuments();
		resetDocument();
	};
	const props = {
		acceptedTypes: '.xlsx,.xls',
		onChange(info) {
			if (!info?.fileList?.length) {
				resetDocuments();
				return;
			}
			if (info.file.status === 'uploading') {
				setErrorDataList([]);
				setFiles(info);
				let fileList = [...info.fileList];
				fileList = fileList.slice(-1);
				const file = fileList[0].originFileObj;
				if (HR_ALLOWED_FILETYPES.indexOf(file.name && file.name.split('.').splice(-1)[0]) >= 0) {
					uploadFile(file);
					fileList = fileList.map((file) => {
						if (file.response) {
							file.url = file.response.url;
						}
						return file;
					});
					setHasErrorFile(false);
				} else {
					setHasErrorFile(true);
					setModalDrag(true);
				}
				setfileLists(fileList);
			}
			ClevertapReact.event('Bulk_Upload_Spreadsheet_DragDrop');
		},
		onRemove() {
			setUploads([]);
			setHasErrorFile(false);
			setfileLists([]);
			setModalDrag(false);
			setFiles({});
			setErrorDataList([]);
			resetDocuments();
		},
	};

	const loadErrorData = (errorDataArray) => {
		if (!errordataList.length) {
			setErrorDataList(errorDataArray);
		} else {
			let newArrayData = [...errordataList, ...errorDataArray];
			setErrorDataList(newArrayData);
		}
	};
	const handleSetupCancel = () => isSetup && history.push({ pathname: '/setup' });
	const nextOnClick = (param) => {
		if (param === 'save') {
			isSetup && cleverTapEvent(uploadType);
			ClevertapReact.event('Bulk_Submit', null);
			// '/setup/leave/upload-table'
			if (typeof onClickNext === 'function') {
				onClickNext({ data: tableData, files: files });
				return;
			}
			history.push({
				pathname:
					isSetup && !isleaveApproveUpload && nextPath
						? nextPath
						: isleaveApproveUpload
						? '/setup/leave/upload-table'
						: '/hrdocuments/uploaddocument/approved',
				state: isSetup ? { data: tableData, files: files } : 'detail',
			});
		} else {
			if (typeof onClickCancel === 'function') {
				onClickCancel();
				return;
			}
			history.push(isSetup ? '/setup' : uploadCancelPush);
		}
	};
	const cleverTapEvent = (value) => {
		switch (value) {
			case 'hrDocument':
				ClevertapReact.event('Setup_docs_excelsubmit', { status: uploads?.validationMessage?.length > 0 ? 'Error' : 'Success' });
				break;
			case 'leavesApproval':
				ClevertapReact.event('Setup_leave_excelsubmit', { status: uploads?.validationMessage?.length > 0 ? 'Error' : 'Success' });
				break;
			case 'employeePayroll':
				ClevertapReact.event('SubmitExcel_EmployeePayroll', { status: uploads?.validationMessage?.length > 0 ? 'Error' : 'Success' });
				break;
			default:
				break;
		}
	};
	const draggerIcon = (value) => {
		if (uploadLoading) {
			return focus_state_icons.hrUploading;
		} else if (value === 'yes') {
			return focus_state_icons.NewDesignErro;
		} else if (value === 'no') {
			return focus_state_icons.NewDesignCheck;
		} else if (!value || hasErrorFile) {
			return focus_state_icons.NewDesignErro;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = (value) => {
		if (uploadLoading) {
			return 'uploading';
		} else if (value === 'yes') {
			return 'error';
		} else if (value === 'no') {
			return 'done';
		} else if (!value || hasErrorFile) {
			return 'error';
		} else {
			return 'new';
		}
	};
	// for setUp screen
	const mainSetup =
		Array.isArray(descriptions) && description.length > 0 ? descriptions : isSetup && uploadType !== 'hrDocument' ? setUpUpload : upload;
	return (
		<>
			<Col className="hr-upload" style={{ width: isSetup ? '960px' : '100%' }}>
				<EdenredHeader />
				<Row className="UploadSpreadsheet forEmployees">
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="UploadSpreadsheet_header">
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<ETypo bold h2>
								{isSetup ? setUpName : 'Upload Updated Spreadsheet'}
							</ETypo>
							{isSetup && (
								<ETypo light b2 style={{ marginTop: '8px' }}>
									{description}
								</ETypo>
							)}
						</div>
						<div className="new_design_hr_upload">
							<EdenredButtons
								btnType="secondary"
								leftIcon={isSetup ? <ImageComponent src={images.leftsarrow} /> : ''}
								className="new_hr_upload_cancel"
								onClick={() => {
									nextOnClick('cancel');
								}}>
								{isSetup ? 'Back' : 'Cancel'}
							</EdenredButtons>
							<EdenredButtons
								disabled={isUploadButtonDisabled}
								loading={uploadLoading}
								onClick={() => {
									nextOnClick('save');
									ClevertapReact.event('Bulk_Next');
								}}>
								{isSetup ? 'Submit' : 'Next'}
							</EdenredButtons>
						</div>
					</Col>
					<Col className="hr-upload-inner">
						<div className="abc">
							<div className={isSetup ? 'upload_spreadsheet_steps_container_setup' : 'upload_spreadsheet_steps_container'}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: isSetup ? '47%' : '100%',
										height: '132px',
										gap: '16px',
									}}>
									{' '}
									{!isSetup && description && (
										<div className="UploadSpreadsheet_description">
											<p className="heading-description">{description}</p>
										</div>
									)}
									{!isSetup && (
										<div className="UploadSpreadsheet_header_desc">
											<p className="heading-description">Update documents in bulk by uploading an updated spreadsheet</p>
										</div>
									)}
									{mainSetup?.map((content, index) => (
										<div key={index} className="upload_spreadsheet_steps_row">
											<div className="upload_spreadsheet_steps_icon">
												{/* <EdenredTypo>{content.description}</EdenredTypo> */}
												<ETypo b2 medium color="#313338">
													{content.description}
												</ETypo>
											</div>
											<div className="upload_spreadsheet_steps_content">
												<ETypo b1 light color="#484B52">
													{content.list}
												</ETypo>
											</div>
										</div>
									))}
								</div>
								{/* style={{ display: isSetup ? 'flex' : 'none', flexDirection: 'column', width: '50%', minHeight: '132px' }} */}
								<div style={{ display: isSetup ? 'flex' : 'none' }} className="dragger-section-setup">
									<EDragger
										{...{
											idleIcon: draggerIcon(isDocUploaded),
											draggerStatus: draggerStatus(isDocUploaded),
											uploadDeleteIcon: focus_state_icons.NewDelete,
											onChange: handleDragger,
											onRemoveUpload,
											refValue,
										}}></EDragger>
								</div>
							</div>
							<div style={{ display: isSetup ? 'flex' : 'none', flexDirection: 'column', width: '100%' }}>
								{uploads?.validationMessage?.length > 0 ? (
									<div className="warning-message-container">
										<ImageComponent style={{ marginLeft: '8px' }} src={focus_state_icons.empUploadErrorMessage}></ImageComponent>
										<ETypo style={{ width: '73%' }} className="errormessage-text-style" b1 light color="##00344E">
											Please correct the following errors and upload the file again. You can continue with these employees
											excluded or correct the file and upload it again,
										</ETypo>
										{/* <input type="file" id="filesystem" accept=".xls, .xlsx"> */}
										<ETypo
											onClick={() => refValue.current.click()}
											style={{
												cursor: 'pointer',
											}}
											b2
											color="#0E5274"
											bold
											textDecoration="underline"
											// className="Here-text"
										>
											Reupload Spreadsheet
										</ETypo>
									</div>
								) : (
									<div className="messageBox">
										<ImageComponent src={focus_state_icons.hintIcon}></ImageComponent>
										<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<ETypo b1 light color="#00344E">
												{hintText}
											</ETypo>
										</div>
									</div>
								)}
								{uploads?.validationMessage?.length > 0 ? (
									<Fragment>
										<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error" style={{ marginTop: '10px' }}>
											<ErrorTable data={uploads && uploads?.validationMessage} loadErrorData={loadErrorData} />
										</Col>
									</Fragment>
								) : (
									''
								)}
							</div>
						</div>
						<div style={{ display: isSetup ? 'none' : 'flex', flexDirection: 'column' }}>
							{hintText && (
								<Col xl={24} md={24} lg={24} sm={24} xs={24}>
									<div className="hint-box">
										<ImageComponent src={not_focus_icons.TipBulb} style={{ width: '32px' }} />
										<p className="hint-box-text">{hintText}</p>
									</div>
								</Col>
							)}
							<Col xl={24} md={24} lg={24} className="dragger_card_container topCont">
								{draggerHeader && (
									<Col xl={21} md={21} sm={24} lg={21} xs={24} className="setup_typo_dragger">
										<span>{draggerHeader}</span>
									</Col>
								)}
								<EDragger
									{...{
										idleIcon: draggerIcon(isDocUploaded),
										draggerStatus: draggerStatus(isDocUploaded),
										uploadDeleteIcon: focus_state_icons.NewDelete,
										onChange: handleDragger,
										onRemoveUpload,
									}}
								/>

								{uploads?.validationMessage?.length > 0 ? (
									<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error">
										<Card className="card_action">
											<Row>
												<Col xl={1} lg={1} sm={1} xs={3} md={1}>
													<ImageComponent src={focus_state_icons.hrDocumentExpired} />
												</Col>
												<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
													Please correct the following errors and upload the file again.
												</Col>
												<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
													Please correct the following errors and upload the file again.
												</Col>
											</Row>
										</Card>
									</Col>
								) : (
									''
								)}
								{hasErrorFile && (
									<Col xl={24} md={24} sm={24} lg={21} xs={24} className="card_error" style={{ marginTop: 15 }}>
										<Card className="card_action">
											<Row>
												<Col xl={1} lg={1} sm={1} xs={3} md={1}>
													<ImageComponent src={focus_state_icons.hrDocumentExpired} />
												</Col>
												<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
													Invalid format. Please upload one of these formats {HR_ALLOWED_FILETYPES.join()}
												</Col>
												<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
													Please correct the following errors and upload the file again.
												</Col>
											</Row>
										</Card>
									</Col>
								)}
								{uploads?.validationMessage?.length > 0 ? (
									<Fragment>
										<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error" style={{ marginTop: '10px' }}>
											<ErrorTable data={uploads && uploads?.validationMessage} loadErrorData={loadErrorData} />
										</Col>
									</Fragment>
								) : (
									''
								)}
							</Col>
						</div>
					</Col>
				</Row>
			</Col>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		hrUploadLoading: Selectors.uploadLoad(state),
		hrDocumentUpload: Selectors.documentUpload(state),
		uploadError: Selectors.uploadError(state),
		templateLoading: Selectors.templateLoading(state),
		templateDowload: Selectors.templateDowload(state),
		templateError: Selectors.templateError(state),
		filterLoader: Selectors.filterLoading(state),
		filterdata: Selectors.filterDetail(state),
		filterError: Selectors.filterError(state),
		isHrDocUploaded: Selectors.isDocUploaded(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		uploadDocuments: (file) => dispatch(action.creators.saveDocumentUploadStart(file)),
		resetDocuments: (file) => dispatch(action.creators.saveDocumentUploadReset()),
		downloadTemplate: () => dispatch(action.creators.saveTemplateStart()),
		filterData: (filterDocument) => dispatch(action.creators.saveFilterDataStart(filterDocument)),
		saveDocumentUploadReset: () => dispatch(action.creators.saveDocumentUploadReset()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Uploaddocument);
const ReducedScreen = withReducer('uploadReducer', reducer, sagas)(Connected);
export default ReducedScreen;
