import { createSelector } from 'reselect';
import { initState } from './overTimeReducer';

const overTimeReducer = (state) => state.companySettingReducer || initState;
const loading = createSelector(overTimeReducer, (cnter) => cnter.overTimeSaveLoading);
const isOverTimeSaved = createSelector(overTimeReducer, (cnter) => cnter.isOverTimeSaved);
const overTimePercentLoading = createSelector(overTimeReducer, (cnter) => cnter.overTimePercentLoading);
const overTimePercentData = createSelector(overTimeReducer, (cnter) => cnter.overTimePercentData);
const overTimeDataInfo = createSelector(overTimeReducer, (cnter) => cnter.overTimeDataInfo);
const overTimeDataLoading = createSelector(overTimeReducer, (cnter) => cnter.overTimeDataLoading);
const overTimeSalaryData = createSelector(overTimeReducer, (cnter) => cnter.overTimeSalaryData);
const overTimeSalaryDataLoading = createSelector(overTimeReducer, (cnter) => cnter.overTimeSalaryDataLoading);
const CompanySettingValues = createSelector(overTimeReducer, (cnter) => cnter.companySettingStatus);
const SaveCompanySettingValues = createSelector(overTimeReducer, (cnter) => cnter.saveCompanySettingStatus);
const saveCompanySettingLoading = createSelector(overTimeReducer, (cnter) => cnter.saveCompanySettingLoading);
const isCompanySaveSuccess = createSelector(overTimeReducer, (cnter) => cnter.iscompanySaveSuccess);
const iscompanySettingSaveSuccess = createSelector(overTimeReducer, (cnter) => cnter.iscompanySettingSaveSuccess);
const overTimeLabelLoading = createSelector(overTimeReducer, (cnter) => cnter.overTimeLabelLoading);
const overTimeLabelData = createSelector(overTimeReducer, (cnter) => cnter.overTimeLabelData);
const saveGetUploadLogoLoading = createSelector(overTimeReducer, (cnter) => cnter.saveGetUploadLogoLoading);
const saveGetUploadLogoError = createSelector(overTimeReducer, (cnter) => cnter.saveGetUploadLogoError);
const saveGetUploadLogoStatus = createSelector(overTimeReducer, (cnter) => cnter.saveGetUploadLogoStatus);
const companySettingStatusList = createSelector(overTimeReducer, (cnter) => cnter.companySettingStatusList);

export default {
	overTimeReducer,
	loading,
	overTimePercentLoading,
	overTimePercentData,
	isOverTimeSaved,
	overTimeDataInfo,
	overTimeDataLoading,
	overTimeSalaryData,
	overTimeSalaryDataLoading,
	CompanySettingValues,
	SaveCompanySettingValues,
	saveCompanySettingLoading,
	isCompanySaveSuccess,
	iscompanySettingSaveSuccess,
	overTimeLabelLoading,
	overTimeLabelData,
	saveGetUploadLogoLoading,
	saveGetUploadLogoError,
	saveGetUploadLogoStatus,
	companySettingStatusList

};
