import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { ADD_INDIVIDUAL_EMP, EMPLOYEE_DETAILS_KEY, EMPLOYEE_ERROR_KEY } from '@constants/app-constants';
import { getObjectFromArray } from '@helpers';
import action from '../action';
import AddIndividualEmpDocumentPresenational from './add-individual-emp-document-presentational';

const AddIndividualEmpDocumentFunctional = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const empInfo = JSON.parse(localStorage.getItem(ADD_INDIVIDUAL_EMP?.EMPLOYEE_INFO));
	const bankNameOption = useSelector((state) => state?.documentReducer?.bankNamedata);
	const isEmpDocumentLoading = useSelector((state) => state?.documentReducer?.isEmpDocumentLoading);
	const empDocumentResponse = useSelector((state) => state?.documentReducer?.empDocumentResponse);
	const isindividualDocSaved = useSelector((state) => state?.documentReducer?.isindividualDocSaved);
	const [passportValidationError, setPassportValidationError] = useState({ state: false, errorMessage: '' });

	const [employeeDocumentDetails, setEmployeeDocumentDetails] = useState({
		empId: '',
		wpsPersonId: '',
		wpsEstablishmentId: '',
		labourCardNumber: '',
		emiratesId: '',
		passportNumber: '',
		bankName: '',
		Iban: '',
		isEditable: false,
	});
	const [validateMessage, setValidationMessage] = useState([]);
	const [passportValidation, setPassportValidation] = useState({
		placeholder: 'XXX000000',
		maxLength: 12,
	});
	useEffect(() => {
		handlePassportValidationLength(empInfo?.nationality);
	}, [empInfo?.nationality]);
	const handlePassportValidationLength = (nationality) => {
		switch (nationality) {
			case 'India':
				setPassportValidation({ placeholder: 'X0000000', maxLength: 8 });
				break;
			case 'Pakistan':
				setPassportValidation({ placeholder: 'XX0000000', maxLength: 9 });
				break;
			case 'Bangladesh':
				setPassportValidation({ placeholder: 'XX0000000', maxLength: 9 });
				break;
			case 'Philippines':
				setPassportValidation({ placeholder: 'XX0000000', maxLength: 9 });
				break;
			case 'Egypt':
				setPassportValidation({ placeholder: 'X00000000', maxLength: 9 });
				break;
			default:
				break;
		}
	};
	useEffect(() => {
		setValidationMessage(empDocumentResponse?.validationMessage || []);
	}, [empDocumentResponse]);
	useEffect(() => {
		dispatch(action.creators.getBankNameDataStart());
	}, []);

	const handlePassportValidation = (e, param) => {
		const value = e.toUpperCase();
		const val = value.replace(/[^A-Za-z0-9]/g, '');
		setEmployeeDocumentDetails({ ...employeeDocumentDetails, [param]: val });
		let pattern;
		let patterns;
		if (empInfo?.nationality === 'India') {
			pattern = /[A-Za-z]{1}[0-9]{7}/;
			patterns = /[A-Za-z]{2}[0-9]{6}/;
		} else if (empInfo?.nationality === 'Bangladesh') {
			patterns = /[A-Za-z]{2}[0-9]{7}/;
			pattern = /[A-Za-z]{1}[0-9]{8}/;
		} else if (empInfo?.nationality === 'Pakistan') {
			pattern = /[A-Za-z]{2}[0-9]{7}/;
		} else if (empInfo?.nationality === 'Egypt') {
			pattern = /[A-Za-z]{1}[0-9]{8}/;
		} else if (empInfo?.nationality === 'Philippines') {
			patterns = /[A-Za-z]{2}[0-9]{7}/;
			pattern = /[A-Za-z]{1}[0-9]{7}[A-Za-z]{1}/;
		} else {
			pattern = /[A-Za-z0-9]/;
		}
		if (
			(val.length !== 0 && pattern.test(val)) ||
			(val.length !== 0 &&
				(empInfo?.nationality === 'Philippines' || empInfo?.nationality === 'Bangladesh' || empInfo?.nationality === 'India') &&
				patterns.test(val)) ||
			val?.length === 0
		) {
			setPassportValidationError({ state: false, errorMessage: '' });
		} else {
			setPassportValidationError({ state: true, errorMessage: 'Invalid Passport Number' });
		}
	};

	const empPersonalDetails = [
		{
			title: 'Date of Birth',
			value: empInfo?.dob,
		},
		{
			title: 'Nationality',
			value: empInfo?.nationality,
		},
		{
			title: 'Mobile',
			value: empInfo?.mobile || '-',
		},
		{
			title: 'Email Address',
			value: empInfo?.email || '-',
		},
	];

	useEffect(() => {
		let ab = [
			{
				columnName: 'LabourCardNo',
				rowId: [],
				errorMessage: 'The length of Labour Card Number should be  between 5 to 9 digit.',
				errorType: 'Required',
			},
		];

		if (
			(employeeDocumentDetails?.labourCardNumber && employeeDocumentDetails?.labourCardNumber?.length < 5) ||
			employeeDocumentDetails?.labourCardNumber?.length > 9
		) {
			setValidationMessage(ab);
		}
	}, [employeeDocumentDetails?.labourCardNumber]);

	const empDocumentDetails = [
		{
			label: 'Employee ID',
			placeholder: 'Enter Employee ID',
			value: employeeDocumentDetails?.empId,
			Component: Inputbox,
			onChange: (e) => handleEmpDocDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.EMP_ID),
			param: 'empId',
			required: true,
			isErrorMessage:
				employeeDocumentDetails?.empId &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.EMP_ID)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.EMP_ID)?.errorMessage,
		},
		{
			label: 'WPS Person ID',
			placeholder: '14-Digit Number',
			value: employeeDocumentDetails?.wpsPersonId,
			Component: Inputbox,
			onChange: (e) => handleEmpDocDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.WPS_PERSON_ID),
			param: 'wpsPersonId',
			required: false,
			maxLength: 14,
			isErrorMessage:
				employeeDocumentDetails?.wpsPersonId &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.WPS_PERSON_ID)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.WPS_PERSON_ID)?.errorMessage,
		},
		{
			label: 'WPS Establishment ID',
			placeholder: '13-Digit Number',
			value: employeeDocumentDetails?.wpsEstablishmentId,
			Component: Inputbox,
			onChange: (e) => handleEmpDocDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.WPS_ESTABLISH_ID),
			param: 'wpsEstablishmentId',
			required: false,
			maxLength: 13,
			isErrorMessage:
				employeeDocumentDetails?.wpsEstablishmentId &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.WPS_ESTABLISH_ID)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.WPS_ESTABLISH_ID)?.errorMessage,
		},
		{
			label: 'Labour Card Number',
			type: 'number',
			placeholder: '9-Digit Number',
			value: employeeDocumentDetails?.labourCardNumber,
			Component: Inputbox,
			onChange: (e) => handleEmpDocDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.LABOURCARD_NO),
			param: 'labourCardNumber',
			required: false,
			isErrorMessage:
				employeeDocumentDetails?.labourCardNumber &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.LABOURCARD_NO)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.LABOURCARD_NO)?.errorMessage,
		},
		{
			label: 'Emirates ID',
			placeholder: '15-Digit Number',
			value: employeeDocumentDetails?.emiratesId,
			Component: Inputbox,
			onChange: (e) => handleEmpDocDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.EMIRATES_ID),
			param: 'emiratesId',
			required: true,
			maxLength: 15,
			isErrorMessage:
				employeeDocumentDetails?.emiratesId &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.EMIRATES_ID)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.EMIRATES_ID)?.errorMessage,
		},
		{
			label: 'Passport Number',
			placeholder: passportValidation.placeholder,
			value: employeeDocumentDetails?.passportNumber,
			Component: Inputbox,
			onChange: (e) => handleEmpDocDetails(e?.target?.value, EMPLOYEE_DETAILS_KEY?.PASSPORT_NO),
			param: 'passportNumber',
			required: true,
			maxLength: passportValidation.maxLength,
			isErrorMessage:
				(employeeDocumentDetails?.passportNumber &&
					!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.PASSPORT_NO)?.errorMessage) ||
				passportValidationError?.state,
			errorMessage: passportValidationError?.errorMessage
				? passportValidationError?.errorMessage
				: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.PASSPORT_NO)?.errorMessage,
		},
	];
	useEffect(() => {
		if (isindividualDocSaved && validateMessage?.length === 0) {
			history.push({
				pathname: '/employees/add/multiple/employees/submit/nextsteps',
				state: { noOfEmployees: 1 },
			});
			dispatch(action.creators.isIndividualEmpDocSaved(false));
		}
	}, [isindividualDocSaved]);

	const handleEmpDocDetails = (value, param) => {
		if (param === 'passportNumber') {
			handlePassportValidation(value, param);
		} else {
			setEmployeeDocumentDetails({ ...employeeDocumentDetails, [param]: value });
		}
		switch (param) {
			case EMPLOYEE_DETAILS_KEY?.EMP_ID:
				clearValidationField(EMPLOYEE_ERROR_KEY?.EMP_ID);
				break;
			case EMPLOYEE_DETAILS_KEY?.WPS_PERSON_ID:
				clearValidationField(EMPLOYEE_ERROR_KEY?.WPS_PERSON_ID);
				break;
			case EMPLOYEE_DETAILS_KEY?.WPS_ESTABLISH_ID:
				clearValidationField(EMPLOYEE_ERROR_KEY?.WPS_ESTABLISH_ID);
				break;
			case EMPLOYEE_DETAILS_KEY?.LABOURCARD_NO:
				clearValidationField(EMPLOYEE_ERROR_KEY?.LABOURCARD_NO);
				break;
			case EMPLOYEE_DETAILS_KEY?.EMIRATES_ID:
				clearValidationField(EMPLOYEE_ERROR_KEY?.EMIRATES_ID);
				break;
			case EMPLOYEE_DETAILS_KEY?.PASSPORT_NO:
				clearValidationField(EMPLOYEE_ERROR_KEY?.PASSPORT_NO);
				break;
			default:
				break;
		}
	};
	const clearValidationField = (columnName) => {
		const error = validateMessage?.map((data) => {
			return {
				...data,
				errorMessage: data?.columnName === columnName ? '' : data?.errorMessage,
			};
		});
		setValidationMessage(error);
	};
	const handleConformButton = () => {
		dispatch(action.creators.saveIndividualEmployeeDocStart({ ...empInfo, ...employeeDocumentDetails }));
	};
	const disableButton = useMemo(
		() =>
			!!empDocumentDetails?.filter((data) => data?.required && !data?.value)?.length ||
			(empInfo?.account === ADD_INDIVIDUAL_EMP?.BANK && (!employeeDocumentDetails?.bankName || !employeeDocumentDetails?.Iban)),
		[employeeDocumentDetails]
	);
	return (
		<AddIndividualEmpDocumentPresenational
			{...{
				empPersonalDetails,
				empDocumentDetails,
				handleEmpDocDetails,
				bankNameOption,
				employeeDocumentDetails,
				disableButton,
				handleConformButton,
				isEmpDocumentLoading,
				empDocumentResponse,
				empInfo,
				validateMessage,
				setEmployeeDocumentDetails,
			}}
		/>
	);
};

export default AddIndividualEmpDocumentFunctional;
