import React from 'react';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';

const PreparingStatementSoftCopy = () => {
	return (
		<>
			<EdenredHeader />
			<Row className="preparing-statement-container">
				<Col xs={24} sm={24} className="preparing-statement-header">
					<EdenredTypo>Preparing statement</EdenredTypo>
				</Col>
				<Col xs={24} sm={24} className="preparing-statement-statement">
					<ImageComponent src={icons.not_focus.Statements} />
				</Col>
				<Col xs={24} sm={24} className="preparing-statement-content">
					<EdenredTypo>You'll receive Uzair’s statement for: 1 Jun 2020 - 1 Jul 2020 by mail.</EdenredTypo>
				</Col>
			</Row>
		</>
	);
};

export default PreparingStatementSoftCopy;
