import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import { connect, useSelector } from 'react-redux';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButton from '@sharedComponent/button';
import SharedServices, { GET_PROFILE, SET_LOGIN_STEP } from '@sharedComponent/app-action-reducer/services';
import Action from '@sharedComponent/app-action-reducer/action';
import { SETUP_FINAL, SETUP_REDIRECT } from '@constants/app-constants';
import { withReducer } from 'src/store/reducerLoader';
import UserService from 'src/services/user-service';
import { images } from 'src/assets/img';
import action from './action';
import selector from './selector';
import reducer from './reducer';
import saga from './saga/index';
import './landing-page.scss';
import MessageBox from '@pages/DesignSystem/MessageBox';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { getBorderClassColor } from '@helpers';
import { element } from 'prop-types';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';

const LandingPage = ({ redirectToSetup, setupClientStatusLoading, setupClientStatusDetail, getClientStatusSaga, setUser }) => {
	const history = useHistory();
	const [statusCompleted, setStatusCompleted] = useState('');
	const [loading, setLoading] = useState(false);
	const userApi = new UserService();
	const api = new SharedServices();
	const fromNavbarStatus = history?.location?.state?.fromNavbarStatus || '';
	const logInStep = useSelector((state) => state?.sharedStates?.currentUser?.user?.loginStep);

	useEffect(() => {
		getSetupStatus();
		getClientStatusSaga();
	}, []);

	const statusDetails = [
		{
			name: 'Payslips & Automatic Payroll Calculations',
			param: 'card-permission',
			image: images?.calc,
			className: 'payslip',
		},
		{
			name: 'Leave Management',
			param: 'leave',
			image: images?.trip,
			className: 'managment',
		},
		{
			name: 'Document Management',
			param: 'document',
			image: images?.doc,
			className: 'managment',
		},
		{
			name: 'Announcements',
			param: 'announcments',
			image: images?.msg,
			className: 'msg',
		},
	];
	const setUpFinal = [
		{ param: 'card-permission', image: images.payrollplus, className: 'payslip', name: 'Payroll Cards' },
		{ param: 'leave', image: images.salary, className: 'managment', name: 'Salary Processing' },
		{ param: 'document', image: images.manage, className: 'managment', name: 'Manage Employees' },
	];

	const getSetupStatus = async () => {
		setStatusCompleted('Loading');
		await userApi
			.getSetupStatus()
			.then((res) => {
				setStatusCompleted(res && res?.data && res?.data?.state ? 'Completed' : 'NotCompleted');
			})
			.catch((err) => {
				setStatusCompleted('Failed');
			});
	};

	const goTodashboard = async () => {
		localStorage.setItem(SETUP_REDIRECT.SETUP_REDIRECT_TO, 'dontRedirectToSetup');
		redirectToSetup('dontRedirectToSetup');
		if (logInStep === 0) {
			history.push('/');
		} else {
			setLoading(true);
			await api
				.services(SET_LOGIN_STEP, {
					step: 0,
				})
				.then(() => {
					api.services(GET_PROFILE)
						.then((res) => {
							setUser(res?.data);
							setLoading(false);
							history.push('/');
						})
						.catch((err) => {
							setLoading(false);
						});
				})
				.catch(() => {
					setLoading(false);
				});
		}
	};

	const congratulationsCards = [
		{ title: 'Payroll Cards', image: icons.focus_state.setupcardicon },
		{ title: 'Salary Processing', image: icons.focus_state.setupmoneyicon },
		{ title: 'Manage Employees', image: icons.focus_state.setupusericon },
	];

	const featuresCards = [
		{ title: 'Payslips & Automatic Payroll Calculations', image: icons.focus_state.setupcalculatoricon },
		{ title: 'Leave Management', image: icons.focus_state.setupweathericon },
		{ title: 'Document Management', image: icons.focus_state.setupfoldericon },
		{ title: 'Send Accouncements', image: icons.focus_state.setupannoucementicon },
	];

	const messageTitle = statusCompleted === 'Completed' ? 'Your Setup is done!' : 'You didn’t finish your Setup!';
	const message =
		statusCompleted === 'Completed'
			? 'Your data is ready to be used in our platform. You’ll be able to update your data from dashboard.'
			: 'We encourage you to complete your Setup to enjoy the new products. In case you want to skip for now you’ll be able to update your data from dashbaord.';

	console.log('dsadsad', statusCompleted);
	return (
		<Row className={setupClientStatusLoading ? 'setUp_status_load' : 'landing_last'}>
			{/* <SetupHeader /> */}
			{setupClientStatusLoading ? (
				<DotLoader size={80} color={'#e91d24'} loading />
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<SetupHeader />
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="main-content">
						<Col xl={22} lg={22} md={22} sm={22} xs={22} className="content">
							<Row xl={24} lg={24} md={24} sm={24} xs={24} className="title-and-navigation-section">
								<div>
									<ETypo h2 bold color="#00344E">
										Setup
									</ETypo>
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', gap: '8px' }}>
									{statusCompleted === 'NotCompleted' && (
										<EdenredButtons
											onClick={() => history.push({ pathname: '/setup', state: { fromNavbarStatus: fromNavbarStatus } })}
											btnType="secondary">
											Continue Setup
										</EdenredButtons>
									)}
									<EdenredButtons
										loading={loading}
										onClick={() => {
											ClevertapReact.event('Onboarding_GoToDashboard');
											goTodashboard();
										}}>
										Go to Dashboard
									</EdenredButtons>
								</div>
							</Row>

							<Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', marginTop: '32px' }}>
								<MessageBox
									status={statusCompleted === 'Completed' ? 'SUCCESS' : 'WARNING'}
									title={messageTitle}
									message={message}></MessageBox>
							</Col>
							{setupClientStatusDetail !== 'Old Corporate' ? (
								<div className="setup-congratulation">
									<ETypo h2 bold color="#00344E">
										Congratulations!
									</ETypo>
									<div style={{ marginTop: '16px' }}>
										<ETypo h5 light color="#313338">
											{SETUP_FINAL?.NEW_CORPORATE}
										</ETypo>
									</div>
									<div className="congratulation-cards">
										{congratulationsCards?.map((data, index) => (
											<div className="congratulation-card">
												<ImageComponent src={data.image}></ImageComponent>
												<ETypo h4 medium color="#00344E">
													{data.title}
												</ETypo>
											</div>
										))}
									</div>
								</div>
							) : (
								''
							)}
							<div className="setup-section-container">
								<ETypo h2 bold color="#00344E">
									{SETUP_FINAL?.FROM_LOGIN}
								</ETypo>
								<div style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
									<ETypo h5 light color="#313338">
										{SETUP_FINAL?.SETUP_FINAL_DESC}
									</ETypo>
								</div>
								<div className="content-section">
									{featuresCards?.map((data, index) => (
										<div className="content-card">
											<ImageComponent src={data.image}></ImageComponent>
											<ETypo style={{ textAlign: 'center' }} h4 medium color="#00344E">
												{data.title}
											</ETypo>
										</div>
									))}
								</div>
							</div>
						</Col>
					</Col>
				</div>
			)}
		</Row>
	);
};
const mapStateToProps = (state) => {
	return {
		setupClientStatusLoading: selector.setupClientStatusLoading(state),
		setupClientStatusDetail: selector.setupClientStatusDetail(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getClientStatusSaga: () => dispatch(action.creators.getClientStatusStart()),
		redirectToSetup: (flag) => dispatch(Action.creators.redirectToSetup(flag)),
		setUser: (flag) => dispatch(Action.creators.setUser(flag)),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(LandingPage);
const ReducedScreen = withReducer('setUpFinalReducer', reducer, saga)(Connected);
export default ReducedScreen;
