import React from 'react';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import ItemHeader from '../../components/ItemHeader';
import './wps-penalty.scss';

const WpsPenalty = () => {
	return (
		<Col className="wps-penalty-presentational">
			<Row className="wps-penalty-presentational-row">
				<ItemHeader title="WPS Penalties" />
				<Row className="wps-penalty-presentational-new-row">
					<Col className="sub-content">
						<EdenredTypo>
							In case of failure to comply with WPS regulations (Pay atleast 80% of your employees 70% of their salaries mentioned on
							the contract), the following penalties will be imposed by the government - the salary for each month is due one day after
							the month ends (1st) and the following are the penalties imposed in case of delays: <br />
							<br /> The following penalties apply for companies who employee more than 100 employees:.
						</EdenredTypo>
					</Col>
					<Col xl={24} md={24} lg={24} xs={24} sm={24} style={{ paddingTop: '16px' }}>
						<Row className="days-calculate">
							<Col xl={5} md={5} lg={5} xs={7} sm={7} className="count">
								<EdenredTypo>10 Days</EdenredTypo>
							</Col>
							<Col xl={18} md={18} lg={18} xs={16} sm={16} className="content">
								<EdenredTypo>
									The employer will be sent a reminder to ensure that all employees are paid by the 15th of the month with a warning
									that there will be penalties if any further delay takes place.
								</EdenredTypo>
							</Col>
						</Row>
						<Row className="down-arrow">
							<Col xl={5} md={5} lg={5} xs={6} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
								<ImageComponent src={icons.not_focus.Down} />
							</Col>
							<Col xl={19} md={19} lg={19} xs={18} sm={18}></Col>
						</Row>
						<Row className="days-calculate">
							<Col xl={5} md={5} lg={5} xs={7} sm={7} className="count">
								<EdenredTypo>16 Days</EdenredTypo>
							</Col>
							<Col xl={18} md={18} lg={18} xs={16} sm={16} className="content">
								<EdenredTypo>The employer will be suspended from granting any more work permits.</EdenredTypo>
							</Col>
						</Row>
						<Row className="down-arrow">
							<Col xl={5} md={5} lg={5} xs={6} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
								<ImageComponent src={icons.not_focus.Down} />
							</Col>
							<Col xl={19} md={19} lg={19} xs={18} sm={18}></Col>
						</Row>
						<Row className="days-calculate">
							<Col xl={5} md={5} lg={5} xs={7} sm={7} className="count">
								<EdenredTypo>30 Days</EdenredTypo>
							</Col>
							<Col xl={18} md={18} lg={18} xs={16} sm={16} className="content">
								<EdenredTypo>
									Extending the permit suspension to all establishments under the employer. Utilising the bank guarantee of the
									employer taken out against each employee visa.. Judicial action against employer.
								</EdenredTypo>
							</Col>
						</Row>
						<Row className="down-arrow">
							<Col xl={5} md={5} lg={5} xs={6} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
								<ImageComponent src={icons.not_focus.Down} />
							</Col>
							<Col xl={19} md={19} lg={19} xs={18} sm={18}></Col>
						</Row>
						<Row className="days-calculate">
							<Col xl={5} md={5} lg={5} xs={7} sm={7} className="count">
								<EdenredTypo>60 Days</EdenredTypo>
							</Col>
							<Col xl={18} md={18} lg={18} xs={16} sm={16} className="content">
								<EdenredTypo>
									AED 1,000 fine for each employee with delayed wages (Max. AED 50,000 fine). Work permit ban will last for 2
									months, even if wages are paid.
								</EdenredTypo>
							</Col>
						</Row>
					</Col>
					<Col xl={24} lg={24} md={24} xs={24} sm={24} className="footer-penalty-content">
						<EdenredTypo>
							For companies with less than 100 employees: <br /> <br />
							The work permit ban, fines and court referral will be imposed on the employer if wages are outstanding for a period of 60
							days. In case of repeat offences, the same penalties as mentioned above for companies with more than 100 employees are
							imposed.
						</EdenredTypo>
					</Col>
				</Row>
			</Row>
		</Col>
	);
};
export default WpsPenalty;
