import { createSelector } from 'reselect';
import { initState } from './reducer';

const counterReducer = (state) => state.counterReducer || initState;

const isAnnouncementDataLoading = createSelector(counterReducer, (cnter) => cnter.isAnnouncementDataLoading);
const announcementData = createSelector(counterReducer, (cnter) => cnter.announcementData);
const announcementDataSave = createSelector(counterReducer, (cnter) => cnter.announcementDataSave);
const announcementSuccess = createSelector(counterReducer, (cnter) => cnter.announcementSuccess);
const isAnnouncementLoading = createSelector(counterReducer, (cnter) => cnter.isAnnouncementLoading);
const isAnnouncementPopUP = createSelector(counterReducer, (cnter) => cnter.isAnnouncementPopUP);
const isAnnouncementToastVisible = createSelector(counterReducer, (cnter) => cnter.isAnnouncementToastVisible);

export default {
	counterReducer,
	isAnnouncementDataLoading,
	announcementData,
	announcementDataSave,
	announcementSuccess,
	isAnnouncementLoading,
	isAnnouncementPopUP,
	isAnnouncementToastVisible,
};
