import React, { useState } from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '../index';

const ModelEight = () => {
	const [isOpen, setisOpen] = useState(false);
	return (
		<>
			<EdenredButtons
				{...{
					btnType: 'primary',
					onClick: () => setisOpen(true),
					style: { marginTop: '20px' },
				}}>
				Modal With congratulations and two button
			</EdenredButtons>
			<Edenredmodal
				{...{
					title: 'Do You Want To Continue?',
					onOkay: 'abc',
					onOkayButton: 'Cancel',
					desc: 'Edenred',
					onCancelButton: 'Save',
					textcenter: true,
					onCancel: () => setisOpen(false),
					isOpen: isOpen,
					close: () => setisOpen(false),
					imagedown: true,
					celebration: true,
					image: true,
				}}
			/>
		</>
	);
};

export default ModelEight;
