const POST_BANK_ACCOUNT_START = 'POST_BANK_ACCOUNT_START';
const POST_BANK_SUCCESS = 'POST_BANK_SUCCESS';
const POST_BANK_FAILED = 'POST_BANK_FAILED';
const GET_BANK_NAME_START = 'GET_BANK_NAME_START';
const GET_BANK_NAME_SUCCESS = 'GET_BANK_NAME_SUCCESS';
const GET_BANK_NAME_FAILED = 'GET_BANK_NAME_FAILED';
const BANK_ACCOUNT_POPUP = 'BANK_ACCOUNT_POPUP';
const BANK_ACCOUNT_ROUTE = 'BANK_ACCOUNT_ROUTE';
const postBankAccountStart = (payload, history) => {
	return {
		type: POST_BANK_ACCOUNT_START,
		payload,
		history,
	};
};
const postBankAccountSuccess = (response) => {
	return {
		type: POST_BANK_SUCCESS,
		response,
	};
};
const postBankAccountFailed = (response) => {
	return {
		type: POST_BANK_FAILED,
		response,
	};
};
const getBankNameStart = () => {
	return {
		type: GET_BANK_NAME_START,
	};
};
const getBankNameSuccess = (response) => {
	return {
		type: GET_BANK_NAME_SUCCESS,
		response,
	};
};
const getBankNameFailed = (error) => {
	return {
		type: GET_BANK_NAME_FAILED,
		error,
	};
};
const BankAccountPopUp = (flag) => {
	return {
		type: BANK_ACCOUNT_POPUP,
		flag,
	};
};
const BankAccountRoute = (flag) => {
	return {
		type: BANK_ACCOUNT_ROUTE,
		flag,
	};
};

export default {
	types: {
		POST_BANK_ACCOUNT_START,
		POST_BANK_SUCCESS,
		POST_BANK_FAILED,
		GET_BANK_NAME_START,
		GET_BANK_NAME_SUCCESS,
		GET_BANK_NAME_FAILED,
		BANK_ACCOUNT_POPUP,
		BANK_ACCOUNT_ROUTE,
	},
	creators: {
		postBankAccountStart,
		postBankAccountSuccess,
		postBankAccountFailed,
		getBankNameStart,
		getBankNameSuccess,
		getBankNameFailed,
		BankAccountPopUp,
		BankAccountRoute,
	},
};
