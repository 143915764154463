import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import setupActions from 'src/components/shared/app-action-reducer/action';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { images } from 'src/assets/img';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ClevertapReact from 'src/utils/clever-tap';
import './leaveSetup.scss';

const LeaveSetup = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	return (
		<div className="wrappers">
			<span className="titles-container">
				<ETypo h2>Leaves to Approve</ETypo>
			</span>
			<div className="content-wrapper">
				<div className="hero-contents">
					<div className="image-container">
						<ImageComponent src={images.Leave_Approve_Empty} />
					</div>
					<div className="text-container">
						<span>You did not receive any leave request yet.</span>
						<ETypo h5>Setup now to start you managing your leave requests from here</ETypo>
					</div>
					<span>
						<EdenredButtons
							children="Get started!"
							onClick={() => {
								ClevertapReact.event('V1_1_Leave_GetStarted');
								dispatch(setupActions.creators.postHrSetupStatus({ hrLeaveCompleted: true }));
								history.push('/leave/departments');
							}}
							btnType="primary"
							rightIcon={<ImageComponent className="rightIcon" src={focus_state_icons.RightArrow} />}
						/>
					</span>
				</div>
			</div>
		</div>
	);
};

export default LeaveSetup;
