import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import { useHistory } from 'react-router-dom';
import ETypo from '@pages/DesignSystem/Typo';
import Actions from '../invoiceDetailAction';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { numberWithCommas } from '@helpers';
import { PulseLoader } from 'react-spinners';
import ViewInvoiceProof from './view-invoice-proof';
import { INVOICE_STATUSES, INVOICE_STATUS_TEXT } from '../constants';

const InvoiceHistory = () => {
	const [openReceiptProof, setOpenReceiptProof] = useState(false);
	const [receiptId, setReceiptId] = useState(null);
	const dispatch = useDispatch();

	const { invoiceHistoryLoading, invoiceReceipts } = useSelector((state) => state?.invoiceState) || {}

	useEffect(() => {
		dispatch(Actions.creators.invoiceHistoryStart());
	}, []);

	let greenColorStatus = [INVOICE_STATUSES.autoSuccess, INVOICE_STATUSES.manualsuccess];
	let yellowColorStatus = [INVOICE_STATUSES.new, INVOICE_STATUSES.autofailed, INVOICE_STATUSES.pending];
	let redColorStatus = [INVOICE_STATUSES.rejected, INVOICE_STATUSES.manualfailedDisputed, INVOICE_STATUSES.generalerror];

	const INVOICE_DONE = 'done';
	const INVOICE_PENDING = 'pending';
	const INVOICE_FAIL = 'fail';

	const getStatusColor = (invoice) => {
		let statusLowerCase = invoice?.status?.toLowerCase();
		if (greenColorStatus.includes(statusLowerCase)) {
			return INVOICE_DONE;
		} else if (yellowColorStatus.includes(statusLowerCase)) {
			return INVOICE_PENDING;
		} else if (redColorStatus.includes(statusLowerCase)) {
			return INVOICE_FAIL;
		}
	};

	const getStatusIcon = (invoice) => {
		let statusLowerCase = invoice?.status?.toLowerCase();
		if (greenColorStatus.includes(statusLowerCase)) {
			return focus_state_icons?.hrDocumentUptoDate;
		} else if (yellowColorStatus.includes(statusLowerCase)) {
			return focus_state_icons?.payrollTransferHistory;
		} else if (redColorStatus.includes(statusLowerCase)) {
			return focus_state_icons?.hrDocumentExpired;
		} else {
			return null;
		}
	};

	const history = useHistory();

	// show invoice statuses new text based on the invoice status
	function showInvoiceStatus(invoice) {
		// Here description was considered as a reason for the status rejected
		const { status, description } = invoice;
		const transformedStatus = status?.toLowerCase()
		function successStatus() {
			if (greenColorStatus.includes(transformedStatus)) {
				return true
			}
			return false
		}
		function pendingStatus() {
			if (yellowColorStatus.includes(transformedStatus)) {
				return true
			}
			return false
		}
		function rejectStatus() {
			if (redColorStatus.includes(transformedStatus)) {
				return true
			}
			return false
		}
		if (successStatus()) {
			return INVOICE_STATUS_TEXT.success
		} else if (pendingStatus()) {
			return INVOICE_STATUS_TEXT.pending
		} else if (rejectStatus() && description) {
			return `${INVOICE_STATUS_TEXT.reject}: ${description}`
		} else if (rejectStatus() && !description) {
			return `${INVOICE_STATUS_TEXT.reject}`
		}
		else {
			return null
		}
	}
	return (
		<>
			<Row className="payment-history-header">
				<ETypo bold h2>
					Payment History
				</ETypo>
				<div>
					<EdenredButtons
						leftIcon={<ImageComponent src={images.leftsarrow} />}
						{...{
							btnType: 'secondary',
							onClick: () => history.goBack(),
						}}>
						Back
					</EdenredButtons>
				</div>
			</Row>
			<Row className="invoice-history-wrapper">
				{invoiceHistoryLoading ? (
					<div style={{ width: '100%', height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<PulseLoader size={20} color={'#e91d24'} loading />
					</div>
				) : (
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="invoice-history-card">
						{invoiceReceipts?.length > 0
							? invoiceReceipts?.map((invoice, index) => {
								let color = getStatusColor(invoice);
								let icon = getStatusIcon(invoice);
								return (
									<div key={index} className={`payment-history payment-history__${color} fade-right`}>
										<div className="payment-icon">
											<div className="payment-history-icon-container">
												{icon ? <img className="payment-history-icon" src={icon} alt={`${invoice?.status}`} /> : ''}
											</div>
										</div>
										<div className="payment-left">
											<ETypo bold h5>
												AED {numberWithCommas(invoice.amount)} by {invoice.receiptType}
											</ETypo>
											<p>Invoice Upload Date: {moment(invoice?.createdDate).format('D MMM YY')}</p>
											<p className="payment-status">{showInvoiceStatus(invoice)}</p>
										</div>
										<div
											className="payment-right"
											onClick={() => {
												setOpenReceiptProof(true);
												setReceiptId(invoice.id);
											}}>
											<span className="underline">View Submitted Proof</span>
										</div>
									</div>
								);
							})
							: 'No Records found'}
					</Col>
				)}
			</Row>
			<ViewInvoiceProof {...{ openReceiptProof, setOpenReceiptProof, receiptId, setReceiptId }} />
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(InvoiceHistory);
