const GET_CLIENT_STATUS_START = 'GET_CLIENT_STATUS_START';
const GET_CLIENT_STATUS_SUCCESS = 'GET_CLIENT_STATUS_SUCCESS';
const GET_CLIENT_STATUS_FAIL = 'GET_CLIENT_STATUS_FAIL';

const getClientStatusStart = () => {
	return {
		type: GET_CLIENT_STATUS_START,
	};
};
const getClientStatusSuccess = (res) => {
	return {
		type: GET_CLIENT_STATUS_SUCCESS,
		res,
	};
};
const getClientStatusFail = () => {
	return {
		type: GET_CLIENT_STATUS_FAIL,
	};
};

export default {
	types: {
		GET_CLIENT_STATUS_START,
		GET_CLIENT_STATUS_SUCCESS,
		GET_CLIENT_STATUS_FAIL,
	},
	creators: {
		getClientStatusStart,
		getClientStatusSuccess,
		getClientStatusFail,
	},
};
