import SetupHeader from '@pages/setup-Screen/components/setup-header';
import React from 'react';
import UserRole from '../../team-permission/change-users-role/user-role-page';
import '@pages/setup-team-permission/setupPermission.scss';

const Permissionassign = () => {
	return (
		<>
			<SetupHeader />
			<div className="setupPermissions-container">
				<UserRole issetup={true} />
			</div>
		</>
	);
};

export default Permissionassign;
