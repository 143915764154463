import React from 'react';
import Feedboxtitle from './feedboxtitle';
import Feedboxtitledesc from './feedboxtitledesc';

const Feedboxexample = () => {
	return (
		<div>
			<Feedboxtitle />
			<Feedboxtitledesc />
		</div>
	);
};

export default Feedboxexample;
