import ETypo from '@pages/DesignSystem/Typo';
import React from 'react';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import './helper-banner.scss';

const HelperBanner = ({ text }) => {
	return (
		<div className="helper-banner">
			<ImageComponent className="info-icon" src={icons?.not_focus.infoLight} alt="info" />
			<div className="info-content">
				<ETypo medium b2>
					{text}
				</ETypo>
			</div>
		</div>
	);
};

export default HelperBanner;
