import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { icons } from 'src/assets/icons';
import EdenredTypo from '@sharedComponent/typography';
import EdenRedCard from '@sharedComponent/card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import EdenredTeamPayroll from '@sharedComponent/card/teamPayroll/team-payroll-card';
import { checkHrSubscription, getDateFormat, setDateFormat, viewDateFormat } from '@helpers';
import { APP_VARIABLES, USER_ROLE } from '@constants/app-constants';
import './teams-payroll.scss';
import ImageComponent from '@sharedComponent/image-component';
import { truncate } from 'lodash';

const TeamsPayrollPresentational = (values) => {
	const [isHrSubscribed, setIsHrSubscribed] = useState([]);
	const history = useHistory();
	const name = values?.values?.values?.values;
	const value = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const teampayroll_files = [
		{
			// image: icons.focus_state.AddUser,
			title: 'Payroll Only',
			description: 'Select role and permissions to manage payroll and employees.',
			templateName: '/permission/user',
			button: '1',
		},
		{
			image: icons.focus_state.Password,
			title: 'HR Only',
			description: 'Select permissions to manage leaves, documents and/or announcements.',
			templateName: '/hrpermission/user',
		},
		{
			image: icons.focus_state.Password,
			title: 'Payroll & HR',
			description: 'Select role and permissions to manage payroll, employees, leaves, documents and/or announcements.',
			templateName: '/permission/user',
			button: '2',
		},
	];

	useEffect(() => {
		if (value) {
			setIsHrSubscribed(teampayroll_files);
		} else {
			setIsHrSubscribed(teampayroll_files.filter((item) => item.title !== 'HR Only' && item.title !== 'Payroll & HR'));
		}
	}, [value]);

	console.log('isHrSubscribed', isHrSubscribed);

	const handlechange = (url, name, button) => {
		history.push({
			pathname: url,
			state: name,
			button: button,
		});
	};
	return (
		<>
			<EdenredHeader />
			<Row className="UsefulFiles_container">
				<Col xl={24} md={24} lg={24} className="UsefulFiles_header">
					<EdenredTypo>
						Select <span>{name?.username}</span>’s Role
					</EdenredTypo>
				</Col>
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="card_content_list">
					{isHrSubscribed?.map((teampayroll, index) => {
						return (
							<Col xl={24} md={24} lg={24} xs={24} sm={24} className="UsefulFiles_Card" key={index}>
								<div
									onClick={() =>
										!value && teampayroll?.title != 'Payroll Only'
											? null
											: handlechange(teampayroll.templateName, name, teampayroll.button)
									}>
									<EdenredTeamPayroll
										type="teampayroll_files"
										content={teampayroll}
										symbolicon="salary_reports"
										className="teampayroll"
										value={value}
									/>
								</div>
							</Col>
						);
					})}
				</Col>
				{/* {value ? null : (
					<Col xl={24} md={24} lg={24} xs={24} sm={24} className="teampayroll_Card">
						<ImageComponent src={icons.focus_state.Password} className="image" />
						<Col xl={24} md={24} lg={24} className="teampayroll_header">
							<EdenredTypo className="text2">
								Premium Subscription required.
								{''}
								<span className="text1">Know More</span>
							</EdenredTypo>
						</Col>
					</Col>
				)} */}
			</Row>
		</>
	);
};

export default TeamsPayrollPresentational;
