import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import '../replacement-card.scss';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { Link, useLocation } from 'react-router-dom';
import EdenredDropdown from '@sharedComponent/dropdown/dropdown';
import { successToast } from 'src/utils/toast';
import Popup from 'src/utils/popup/popups';

const StatementDelivered = ({ history }) => {
	const option = [
		{ index: 0, label: 'HDFC' },
		{ index: 1, label: 'State bank' },
		{ index: 2, label: 'icici' },
	];

	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	const { state } = useLocation();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		if (window.screen.width <= 600) {
			history.push({ pathname: '/employees/preparingStatement-HardCopy', state: { ...state } });
		} else {
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: 'WPS Details Updated successfully',
				subMessage: 'You’ll receive Amit’s statement for 1 Jun 2020 - 1 Jul 2020 by courier at your office address.',
			});

			// history.push({
			// 	pathname: '/employees/viewemployee',
			// 	state: {
			// 		response: console.log(
			// 			successToast({
			// 				msg: 'WPS Details Updated successfully',
			// 				subMsg: 'You’ll receive Amit’s statement for 1 Jun 2020 - 1 Jul 2020 by courier at your office address.',
			// 				icon: 'Mail',
			// 			})
			// 		),
			// 		...state,
			// 	},
			// });
		}
	};

	const closePopup = () => {
		setpopupVisible(false);

		history.push({
			pathname: '/employees/viewemployee',
			state: { ...state },
		});
	};

	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
			<EdenredHeader />
			<Row className="statement-delivered-container">
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="statement-delivered-header">
					<EdenredTypo>Where Do You Want Your Statement Delivered?</EdenredTypo>
				</Col>
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="statement-delivered-form">
					<Form form={form} onFinish={onFinish}>
						<Row>
							<Col xl={12} md={12} lg={12} xs={24} sm={24} className="statement-delivered-fromItem">
								<Form.Item name="Address">
									<Input placeholder="Address" labels="Address" labelClassName="inputlabelrequired" />
								</Form.Item>
							</Col>
							<Col xl={12} md={12} lg={12} xs={24} sm={24} className="statement-delivered-fromItem">
								<Form.Item name="BuildingName">
									<Input placeholder="Building Name" labels="Building Name" labelClassName="inputlabelrequired" />
								</Form.Item>
							</Col>
							<Col xl={12} md={12} lg={12} xs={24} sm={24} className="statement-delivered-fromItem">
								<Form.Item name="Area/Street">
									<Input placeholder="Area/Street" labels="Area/Street" labelClassName="inputlabelrequired" />
								</Form.Item>
							</Col>
							<Col xl={12} md={12} lg={12} xs={24} sm={24} className="statement-delivered-fromItem">
								<Form.Item name="Emirate">
									<EdenredDropdown type="dropdownfield" options={option} labels="Emirate" />
								</Form.Item>
							</Col>
							<Col xl={12} md={12} lg={12} xs={24} sm={24} className="statement-delivered-fromItem">
								<Form.Item name="NearestLandmark(optional)">
									<Input
										labels="Nearest Landmark (optional)"
										placeholder="Nearest Landmark (optional)"
										labelClassName="inputlabelrequired"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row className="statement-button">
							<Col xl={12} md={12} lg={12} xs={24} sm={24}>
								<Form.Item shouldUpdate={true}>
									{() => (
										<EdenredButton
											disabled={
												!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length
											}>
											Request
										</EdenredButton>
									)}
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</>
	);
};
export default StatementDelivered;
