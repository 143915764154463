const GET_NATIONALITY_START = 'GET_NATIONALITY_START';
const GET_NATIONALITY_DATA_SUCCESS = ' GET_NATIONALITY_DATA_SUCCESS';
const GET_NATIONALITY_DATA_ERROR = 'GET_NATIONALITY_DATA_ERROR';
const INVITE_EMPTOPORTAL_START = 'INVITE_EMPTOPORTAL_START';
const INVITE_EMPTOPORTAL_SUCCESS = 'INVITE_EMPTOPORTAL_SUCCESS';
const INVITE_EMPTOPORTAL_FAIL = 'INVITE_EMPTOPORTAL_FAIL';
const UPDATE_EMAIL_START = 'UPDATE_EMAIL_START';
const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
const UPDATE_EMAIL_FAIL = 'UPDATE_EMAIL_FAIL';
const CONFORM_EMAIL = 'CONFORM_EMAIL';

const getNationalityData = () => {
	return {
		type: GET_NATIONALITY_START,
	};
};

const getNationalityDataSuccess = (res) => {
	return {
		type: GET_NATIONALITY_DATA_SUCCESS,
		res,
	};
};

const getNationalityDataError = () => {
	return {
		type: GET_NATIONALITY_DATA_ERROR,
		payload: {},
	};
};

const inviteEmpToPortalStart = (payload) => {
	return {
		type: INVITE_EMPTOPORTAL_START,
		payload,
	};
};

const inviteEmpToPortalSuccess = (res) => {
	return {
		type: INVITE_EMPTOPORTAL_SUCCESS,
		res,
	};
};

const inviteEmpToPortalFail = (err) => {
	return {
		type: INVITE_EMPTOPORTAL_FAIL,
		err,
	};
};
const updateEmailStart = (payload) => {
	return {
		type: UPDATE_EMAIL_START,
		payload,
	};
};
const updateEmailSuccess = (res) => {
	return {
		type: UPDATE_EMAIL_SUCCESS,
		res,
	};
};
const updateEmailFail = (error) => {
	return {
		type: UPDATE_EMAIL_FAIL,
		error,
	};
};
const conformEmail = (flag) => {
	return {
		type: CONFORM_EMAIL,
		flag,
	};
};
export default {
	types: {
		GET_NATIONALITY_START,
		GET_NATIONALITY_DATA_SUCCESS,
		GET_NATIONALITY_DATA_ERROR,
		INVITE_EMPTOPORTAL_START,
		INVITE_EMPTOPORTAL_SUCCESS,
		INVITE_EMPTOPORTAL_FAIL,
		UPDATE_EMAIL_START,
		UPDATE_EMAIL_SUCCESS,
		UPDATE_EMAIL_FAIL,
		CONFORM_EMAIL,
	},
	creators: {
		getNationalityData,
		getNationalityDataSuccess,
		getNationalityDataError,
		inviteEmpToPortalStart,
		inviteEmpToPortalSuccess,
		inviteEmpToPortalFail,
		updateEmailStart,
		updateEmailSuccess,
		updateEmailFail,
		conformEmail,
	},
};
