import React, { useState, useEffect } from 'react';
import { Col, Divider, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EmployeeService from '../../../services/employee-service';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import LandingModal from '@sharedComponent/landing-page-modal/landing-modal';
import ContentLoader from '@sharedComponent/content-loader';
import { corpData, testGroup } from './landingpage-constant';
import { APP_VARIABLES } from '@constants/app-constants';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import './billing-screen.scss';

const BillingScreen = () => {
	const history = useHistory();
	let employeeApi = new EmployeeService();
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const [popup, setPopup] = useState(false);
	const [employeeData, setEmployeeData] = useState({
		employeeCount: '',
		plusmf: '',
		dataloading: false,
	});
	const group = history?.location?.state;
	useEffect(() => {
		getemployeeCount();
	}, []);
	const getemployeeCount = () => {
		setEmployeeData({ ...employeeData, dataloading: true });
		employeeApi
			.getEmployeeStatusCount()
			.then((response) => {
				const { group, monthlyFee } = corpData?.find((data) => data.corpatae === parseInt(corporateId));
				const plus = testGroup[group]
					.filter((data) => response?.data.activeEmployeeCount >= data?.min && data?.max >= response?.data.activeEmployeeCount)
					.map((data) => parseFloat(data?.rate) + parseFloat(monthlyFee));

				setEmployeeData({
					...employeeData,
					dataloading: false,
					employeeCount: response.data.activeEmployeeCount,
					plusmf: plus,
				});
			})
			.catch((error) => {
				setEmployeeData({ ...employeeData, dataloading: false });
			});
	};

	const handleClose = () => {
		history.push('/');
		setPopup(false);
	};
	const openPopUp = () => {
		ClevertapReact.event('HR_LP_Billing_Continue');
		setPopup(true);
	};
	const earlyBird = parseFloat((employeeData?.employeeCount * employeeData.plusmf[0] * 0.15).toFixed(2));
	const final =
		employeeData?.employeeCount * employeeData.plusmf[0] -
		employeeData?.employeeCount * employeeData.plusmf[0] * 0.15 +
		(employeeData?.employeeCount * employeeData.plusmf[0] - employeeData?.employeeCount * employeeData.plusmf[0] * 0.15) * 0.05;

	return (
		<>
			{' '}
			{employeeData?.dataloading ? (
				<ContentLoader />
			) : (
				<Row className="billing-screen">
					<Col xl={21} lg={21} md={21} sm={21} xs={21} className="for-flex-align">
						<p className="billing-info">Billing Information</p>
						<div>
							<button
								className="back-button"
								onClick={() => {
									ClevertapReact.event('HR_LP_Billing_Back');
									history.push('/landingNew');
								}}>
								Back
							</button>
							<button className="continue-button" onClick={openPopUp}>
								Continue
							</button>
						</div>
					</Col>
					<Col xl={21} lg={21} md={21} sm={21} xs={21} className="billing-info-outer ">
						<Row>
							<Col xl={15} lg={15} md={15} sm={24} xs={24} className="billing-info-inner">
								<div className="billing-info-div">
									<p className="billing-info-text">Description</p>
									<p className="billing-info-value">Payroll & HR - Pro</p>
								</div>
								<div className="billing-info-div">
									<p className="billing-info-text">Number of Employees</p>
									<p className="billing-info-value">{employeeData?.employeeCount || 0}</p>
								</div>
								<div className="billing-info-div">
									<p className="billing-info-text">Monthly Price per Employee</p>
									<p className="billing-info-value">AED {employeeData.plusmf[0] || 0}</p>
								</div>
								<div className="billing-info-div">
									<p className="billing-info-text">
										Monthly{' '}
										<span className="billing-info-inside-text">
											( AED {employeeData.plusmf[0]} x {employeeData?.employeeCount} Emp. )
										</span>
									</p>
									<p className="billing-info-value">AED {parseFloat(employeeData?.employeeCount * employeeData.plusmf[0]) || 0}</p>
								</div>
								<div className="billing-info-div">
									<p className="billing-info-text">
										Early Bird Discount <span className="billing-info-inside-text">(Save 15%)</span>
									</p>
									<p className="billing-info-value-red">- AED {earlyBird || 0}</p>
								</div>
								<Divider style={{ margin: '12px' }} />
								<div className="billing-info-div">
									<p className="billing-info-text">
										Monthly Total <span className="billing-info-inside-text"> (including 5% VAT)</span>
									</p>
									<p className="billing-info-Total">AED {parseFloat(final.toFixed(2)) || 0}</p>
								</div>
							</Col>
						</Row>
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<div className="hint-box">
								<div className="image-box">
									<ImageComponent src={not_focus_icons.YellowAlert} style={{ width: '14px' }} />
								</div>
								<p className="hint-box-text">
									Your monthly bill will depend on the number of employees in the portal on the billing date.
								</p>
							</div>
						</Col>
					</Col>
					<LandingModal {...{ visible: popup, handleClose }}>
						<div>
							<div className="for-landing-model-container">
								<Row style={{ justifyContent: 'center' }}>
									<Col xl={22} lg={22} md={22} sm={24} xs={24}>
										<ImageComponent src={not_focus_icons.SuccessImg} style={{ marginTop: '2%' }} />
									</Col>
								</Row>
								<Row style={{ justifyContent: 'center' }}>
									<Col xl={20} lg={20} md={20} sm={24} xs={24} style={{ marginTop: '5%' }}>
										<p className="landing-modal-header">Thank you for your interest!</p>
										<p className="landing-modal-text">Upgraded Payroll and HR is not available yet.</p>
										<p className="landing-modal-text"> Do not worry, you will not be charged.</p>
										<p className="landing-modal-text">We will email you once the new features are launched.</p>
									</Col>
								</Row>
							</div>
						</div>
					</LandingModal>
				</Row>
			)}
		</>
	);
};

export default BillingScreen;
