import React from 'react';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Transition from 'react-transition-group/Transition';
import { Row, Col } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';

const Fade = ({ children, position, ...props }) => (
	<Transition
		{...props}
		timeout={{
			enter: 0,
			exit: 0,
		}}>
		{children}
	</Transition>
);

const defaultOptions = {
	position: toast.POSITION.TOP_CENTER,
	transition: Zoom,
	autoClose: 2000,
	hideProgressBar: true,
	onClose: null,
};

export function successToast(data, custom = false) {
	return toast.success(
		<Col xl={24} style={{ display: 'flex', flexDirection: 'row' }} className={`toast-container ${custom ? 'overflow-toast' : ''}`}>
			<Col xl={2} md={2} xs={4} sm={4}>
				<ImageComponent src={icons.not_focus[data.icon]} />
			</Col>
			<Col xl={20} md={22} xs={20} sm={20} className="toast_modal_size">
				<Row className="center-content">
					<Col xl={24} lg={24} md={24} className="toast-text">
						<EdenredTypo>{data.msg}</EdenredTypo>
					</Col>
					{data.subMsg && (
						<Col xl={24} lg={24} md={24} className="toast-subtext">
							<EdenredTypo>{data.subMsg}</EdenredTypo>
						</Col>
					)}
				</Row>
			</Col>
			<Col xl={2} md={2} className="close">
				<ImageComponent src={icons.not_focus.Close} style={{ width: '24px', height: '24px' }} />
			</Col>
		</Col>,
		({}, defaultOptions)
	);
}

export function errorToast(data) {
	return toast.error(
		<Col xl={24} style={{ display: 'flex', flexDirection: 'row' }} className="toast-container">
			<Col xl={2} md={2} xs={4} sm={4}>
				<ImageComponent src={icons.not_focus.Error} />
			</Col>
			<Col xl={20} md={22} xs={20} sm={20} className="toast_modal_size">
				<Row>
					<Col xl={24} lg={24} md={24} className="toast-text">
						<EdenredTypo>{data.msg}</EdenredTypo>
					</Col>
					{data.subMsg && (
						<Col xl={24} lg={24} md={24} className="toast-subtext">
							<EdenredTypo>{data.subMsg}</EdenredTypo>
						</Col>
					)}
				</Row>
			</Col>
			<Col xl={2} md={2} xs={20} sm={20}>
				<ImageComponent
					src={icons.not_focus.Close}
					style={{ width: '20px', height: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}
				/>
			</Col>
		</Col>,
		({}, defaultOptions)
	);
}
