import { Fragment } from 'react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, Col, Divider, Row } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import PayrollService from 'src/services/payroll-service';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import ContentLoader from '@sharedComponent/content-loader';
import { checkWithClaims } from '@helpers';
import { userClaims } from '@constants/';
import './rechecking-failed-employee.scss';

const RecheckingFailedEmployee = ({ currentUser }) => {
	const [employeeList, setEmployeeList] = useState({
		data: [],
		loading: true,
	});
	const api = new PayrollService();
	const {
		state: { cardDetail },
	} = useLocation();
	const { uploadId } = useParams();
	const type = cardDetail && cardDetail.accountType === 'L' ? 'C3' : 'BANK';
	const history = useHistory();

	useEffect(() => {
		getEmployeeList();
	}, []);

	const getEmployeeList = () => {
		api.getPayrollRejectedList(uploadId, type)
			.then((response) => {
				setEmployeeList({
					...employeeList,
					loading: false,
					data: response.data.employeeSalaryList,
				});
			})
			.catch((err) => {
				setEmployeeList({
					...employeeList,
					loading: false,
					data: [],
				});
			});
	};

	console.log('employeeList', employeeList);

	const { loading, data } = employeeList;

	const handlePassData = (tableData) => {
		history.push({ pathname: '/employees/viewemployee', state: { tableData: { ...tableData, employeeId: tableData.employeeId } } });
	};

	const columnKeys = [
		{ labelKey: 'Name', valueKey: 'name' },
		{ labelKey: 'Employee Code', valueKey: 'employeeId' },
	];

	const hasPermission = checkWithClaims(currentUser.claims, userClaims.MANAGE_PAYROLL);

	return (
		<>
			<EdenredHeader />
			<Col xl={24} md={24} lg={24} className="rechecking-failed-employee-col">
				<Row className="rechecking-failed-employee-row">
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="rechecking-run-payroll">
						<EdenredTypo>
							Run Payroll Again For The Failed Employees After Rechecking Their WPS Person ID And WPS Establishment ID
						</EdenredTypo>
					</Col>
					<Col xl={24} md={24} lg={24} sm={24} xs={24} className="salary">
						<EdenredTypo>Salary processing failed for these employees: </EdenredTypo>
					</Col>
					{loading ? (
						<Col xl={24}>
							<Row justify="center">
								<Col>
									<ContentLoader />
								</Col>
							</Row>
						</Col>
					) : (
						<Fragment>
							{/* <Col xl={24} md={24} lg={24} sm={24} xs={24} className="aed-employee">
								<EdenredTypo>AED 11,000.00 for 4 employees </EdenredTypo>
							</Col> */}
							<Col xl={24} md={24} lg={24} sm={24} xs={24}>
								<Row className="header">
									<Col xl={7} md={7} lg={7} className="row-1">
										<EdenredTypo>Name</EdenredTypo>
									</Col>
									<Col xl={5} md={5} lg={5} className="row-1">
										<EdenredTypo>Employee ID</EdenredTypo>
									</Col>
									<Col xl={6} md={6} lg={6} className="row-1">
										<EdenredTypo>Salary Amount</EdenredTypo>
									</Col>
									<Col xl={6} md={6} lg={6}></Col>
								</Row>
							</Col>
							<Col xl={24} md={24} lg={24} sm={24} xs={24} className="divider">
								<Divider style={{ margin: '10px 0px 24px 0px' }} />
							</Col>
							{data.map((employee, index) => (
								<>
									<Col xl={24} md={24} lg={24} sm={24} xs={24}>
										<Row className="content">
											<Col xl={7} md={7} lg={7} className="row-1">
												<EdenredTypo>{employee.name}</EdenredTypo>
											</Col>
											<Col xl={5} md={5} lg={5} className="row-1">
												<EdenredTypo>{employee.employeeCode}</EdenredTypo>
											</Col>
											<Col xl={6} md={6} lg={6} className="row-1">
												<EdenredTypo>{employee.salary}</EdenredTypo>
											</Col>
											<Col
												xl={6}
												md={6}
												lg={6}
												className="arrow"
												onClick={() => handlePassData(employee)}
												style={{ cursor: 'pointer' }}>
												<EdenredTypo>
													View Details
													<ImageComponent src={icons.not_focus.Right} />
												</EdenredTypo>
											</Col>
										</Row>
										<Col xl={24} md={24} lg={24} sm={24} xs={24} className="divider">
											<Divider style={{ margin: '20px 0px' }} />
										</Col>
									</Col>
								</>
							))}
							<Col xl={24} md={24} lg={24} sm={24} xs={24}>
								{data.map((employee, index) => (
									<Card className="cards" onClick={() => handlePassData(employee)} style={{ cursor: 'pointer' }}>
										<Row className="user-card">
											<Col xs={20} sm={20}>
												<Row className="mobile-view-content">
													<Col xs={24} sm={24} className="user">
														<EdenredTypo>{employee.name}</EdenredTypo>
													</Col>
													<Col xs={24} sm={24} className="id">
														<EdenredTypo>{employee.employeeId}</EdenredTypo>
													</Col>
													<Col xs={24} sm={24} className="amount">
														<EdenredTypo>{employee.amount}</EdenredTypo>
													</Col>
												</Row>
											</Col>
											<Col xs={4} sm={4} className="image-arrow">
												<ImageComponent src={icons.not_focus.Right} />
											</Col>
										</Row>
									</Card>
								))}
							</Col>
							<Col xl={24} md={24} lg={24} sm={24} xs={24}>
								<Row className="button-run">
									{hasPermission && (
										<Col className="payroll-button">
											<EdenredButton
												onClick={() => {
													history.push({ pathname: '/run-payroll', state: { empList: data } });
												}}>
												Run Payroll Again
											</EdenredButton>
										</Col>
									)}
									{/* <Col className="download-button">
										<EdenredButton type="outline-g">Download Employee List</EdenredButton>
									</Col> */}
									<ExcelDownload
										dataSet={data.length > 0 ? data : []}
										columnKeys={columnKeys}
										filename={`${moment(new Date()).format('MM/DD/YYYY')}_Failed_Employee_list`}
										element={
											<Col className="download-button" style={!hasPermission ? { marginLeft: 0 } : {}}>
												<EdenredButton type="outline-g">Download Employee List</EdenredButton>
											</Col>
										}
									/>
								</Row>
							</Col>
						</Fragment>
					)}
				</Row>
			</Col>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: SelectorsApp.currentUser(state),
	};
};

export default connect(mapStateToProps, null)(RecheckingFailedEmployee);
