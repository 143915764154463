import React from 'react';
import { Row } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import CompanyInformationWebPresentational from './company-information-web-presentational';
import CompanyMobilePresentational from './company-mobile-presentational';

const companyDocuments = [
	{
		title: 'Main Trade License',
		isUpload: true,
		type: 'MAIN_TRADE_LICENSE',
	},
	{
		title: 'Memorandum of Association',
		//future Purpose
		//hint: 'If your authorised signatory is listed in your trade license, it is not mandatory to provide your trade license. If your company is a sole proprietorship, it is not mandatory to provide your memorandum of association.',
		hint2: '',
		isUpload: true,
		type: 'MEMORANDUM_OF_ASSOCIATION',
	},
	{
		title: 'Power of Attorney',
		hint: '',
		isUpload: true,
		type: 'POWER_OF_ATTORNEY',
	},
	{
		title: 'Signatory & Owner Passport',
		hint: '',
		isUpload: false,
		type: 'SIGNATORY_AND_OWNER_PASSPORT',
	},
	{
		title: 'Signatory & Owner Visa',
		hint: '',
		isUpload: false,
		type: 'SIGNATORY_AND_OWNER_VISA',
	},
];
const MainTradeLicenseSteps = [
	{
		step: 1,
		description: ' Your trade license',
	},
	{
		step: 2,
		description: ' Memorandum of Association',
	},
	{
		step: 3,
		description: ' Signatory Emirates ID, Passport and Visa',
	},
];

const CompanyInformationPresentational = (props) => {
	return (
		<>
			<Row className="company_information_web_presentational">
				<CompanyInformationWebPresentational {...props} companyDocuments={companyDocuments} steps={MainTradeLicenseSteps} />
			</Row>
			<Row className="company_mobile_presentational">
				<EdenredHeader />
				<CompanyMobilePresentational {...props} companyDocuments={companyDocuments} steps={MainTradeLicenseSteps} />
			</Row>
		</>
	);
};

export default CompanyInformationPresentational;
