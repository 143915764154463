import moment from 'moment';
import React from 'react';

const OptionsContent = ({
	data,
	handleDropdown,
	handleToggle,
	mainData,
	icon,
	isOpen,
	dropDownValue,
	dropDownSubValue,
	dateFormat,
	showOptionStatus,
	justifyOption,
}) => {
	const handleCheck = (clickedData, mainData) => {
		data?.subMenu?.length > 0 ? handleToggle() : handleDropdown(clickedData, mainData);
	};
	return (
		<div className="dropdown-list-sub" style={{ paddingLeft: isOpen ? 12 : 0 }}>
			<div
				className="dropdown_inner_list"
				onClick={() => handleCheck?.(data, mainData)}
				style={{ justifyContent: justifyOption ? 'space-between' : '', width: '100%' }}>
				<div className="dropdown_inner_list_main">
					{dateFormat ? moment(data?.[dropDownValue]).format(dateFormat) : data?.[dropDownValue]}
				</div>
				<div
					className={`dropdown_inner_list_sub ${!data?.isActive ? 'inactive_option' : ''} ${
						data?.[dropDownSubValue] === 'Salaries Due' ? 'dropdown-inner-list-sub-due-data' : 'dropdown-inner-list-sub-data'
					}`}>
					{data?.[dropDownSubValue]}

					{(showOptionStatus && data?.isActive) || (data?.[dropDownSubValue] === 'Salaries Due' && <div className="option_status" />)}
				</div>
			</div>

			{data?.subMenu?.length > 0 && (
				<div role="button" onClick={() => handleToggle()}>
					{icon}
				</div>
			)}
		</div>
	);
};
export default OptionsContent;
