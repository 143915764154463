import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './history-reducer';
import saga from './saga/index';
import SalaryHistoryFunctional from '../salary-history-employee/components/salary-history-functional';

const SalaryHistory = () => {
	return <SalaryHistoryFunctional />;
};
const ReducedScreen = withReducer('salaryHistory', reducer, saga)(SalaryHistory);
export default ReducedScreen;
