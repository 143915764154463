import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import { useLocation, useHistory } from 'react-router-dom';
import Input from '@sharedComponent/text-field';

const AddIndividualEmploymentID = () => {
	const { state } = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	const { payrollChannel } = state;
	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		const { employeeId } = values;
		if (payrollChannel === 'WPS') {
			history.push({ pathname: '/employees/add/individual/employee/wps/personid', state: { ...state, employeeId } });
		} else {
			history.push({
				pathname: '/employees/add/individual/employee/labourcardnumber',
				state: { ...state, wpsPersonId: '', wpsEstablishmentId: '', employeeId },
			});
		}
	};

	return (
		<>
			<EdenredHeader />
			<Row className="individual_employee_employment_id_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="individual_employee_employment_id_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName : ''}’s Employee ID?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="individual_employee_employment_id_description">
					<EdenredTypo>
						<span>This can be an employee ID number you already use for your staff.</span>
						{` `}
						<span>If you don't have an employee ID number for your staff, please assign a unique number for {state.firstName}.</span>
					</EdenredTypo>
				</Col>
				<Col xl={10} lg={14} md={18} sm={24} xs={24} className="individual_employee_employment_id_form_section">
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="employeeId"
							rules={[
								{
									required: true,
									message: 'Please provide the employmentID of the employee',
								},
								{
									type: 'regexp',
									pattern: /^([A-Za-z0-9_-]*)$/gi,
									message: 'Please use Alphanumeric or special characters like _ or -.',
								},
								{
									max: 21,
									message: 'The Employee Id should not greater than 21 Characters',
								},
							]}>
							<Input
								placeholder="Employee ID"
								labels="Employee ID"
								pattern={['/^([A-Za-z0-9_-]*)$/gi']}
								labelClassName="inputlabelrequired"
							/>
						</Form.Item>
						<Form.Item shouldUpdate={true}>
							{() => (
								<EdenredButton
									disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
									Next
								</EdenredButton>
							)}
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default AddIndividualEmploymentID;
