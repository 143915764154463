import { Col } from 'antd';
import React from 'react';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect } from 'react-redux';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import HintCard from '@pages/DesignSystem/hindcard';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { onlyNumberForNumberType } from '@helpers';
import InvoiceDragger from '../invoice-dragger';
import TimeInput from '../time-input';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import BankDetailsTransferCode from './bank-details-transfer-code';
import moment from 'moment';
import ETypo from '@pages/DesignSystem/Typo';

const BankTransfer = ({ isFileSize, modalDrag, allowedFileTypes, onChangeInvoiceForm, chequeDepositPayload, clearInvoiceFileState }) => {
	const invoiceOnChange = (info) => {
		onChangeInvoiceForm((preState) => ({ ...preState, InvoiceReceiptFile: info }));
	};

	const getTimeVal = (val) => {
		onChangeInvoiceForm((preState) => ({ ...preState, PaymentTime: val }));
	};

	const handlePaymentDate = (dateObj, formatStr) => {
		onChangeInvoiceForm((preState) => ({ ...preState, PaymentDate: formatStr }));
	};

	const { Amount, PaymentTime, PaymentDate } = chequeDepositPayload;
	return (
		<Col>
			<Col
				xl={24}
				lg={24}
				md={24}
				sm={24}
				xs={24}
				className="invoice-bank-details"
				style={{
					minHeight: '150px',
					boxShadow: '0px 16px 32px -24px #BDBDBD',
					backgroundColor: '#FFFFFF',
					marginBottom: '15px',
					borderRadius: '8px',
					padding: '32px',
				}}>
				<ETypo bold h4>
					Transfer Funds to below Account
				</ETypo>
				<BankDetailsTransferCode />
			</Col>
			<div
				style={{
					minHeight: '150px',
					boxShadow: '0px 16px 32px -24px #BDBDBD',
					backgroundColor: '#FFFFFF',
					padding: '32px',
					borderRadius: '8px',
				}}>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="dragger_submit">
					<ETypo bold h4 style={{ paddingBottom: '24px', display: 'inline-block' }}>
						Upload proof of funds below if you forgot to add the transfer code
					</ETypo>
					<InvoiceDragger
						loadingState={chequeDepositPayload.isSavedSuccess}
						onRemoveUpload={clearInvoiceFileState}
						onChangeHandler={(info) => invoiceOnChange(info)}
					/>
				</Col>
				{(isFileSize || modalDrag) && (
					<Col style={{ marginTop: '10px' }}>
						<HintCard
							{...{
								icon: focus_state_icons.hrDocumentExpired,
								bgColor: '#fff6f5',
								hint: isFileSize
									? 'File must be smaller than 4MB!'
									: modalDrag
									? `Invalid format. Please upload one of these formats ${allowedFileTypes.join()}`
									: '',
							}}
						/>
					</Col>
				)}
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="ProofOfInvoice_payment">
					<Col xl={6} md={6} lg={6} sm={8} xs={24}>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="proof-of-invoice-input">
							<Inputbox
								label="Payment Amount On Slip"
								required={true}
								prefix={'AED'}
								value={Amount}
								onChange={(e) => {
									let val = e.target.value;
									onChangeInvoiceForm((preState) => ({ ...preState, Amount: val }));
								}}
								type="number"
								onKeyDown={(event) => onlyNumberForNumberType(event)}
								placeholder="Enter amount on slip"
							/>
						</Col>
					</Col>
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<DatePickers
							{...{
								value: PaymentDate,
								onChange: (dateObj, formatStr) => handlePaymentDate(dateObj, formatStr),
								label: 'Date of Slip',
								required: true,
							}}
						/>
					</Col>
					<Col xl={6} lg={6} md={6} sm={24} xs={24} className="proof-of-invoice-input">
						<TimeInput value={PaymentTime} required={true} {...{ getTimeVal, label: 'Time of Payment' }} />
					</Col>
				</Col>
			</div>
		</Col>
	);
};
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(BankTransfer);
