import React from 'react';
import { Row, Col, Switch, Card, Divider } from 'antd';
import { Link } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons/index';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ContentLoader from '@sharedComponent/content-loader';
import { APP_VARIABLES } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';
// import EdenRedCard from '@sharedComponent/card';

const ActionApprovePresentational = ({
	list,
	apiLoading,
	loading,
	modalSubmit,
	changeClaims,
	Verifier,
	username,
	hrclaims,
	allClaims,
	verifiedData,
}) => {
	const value = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const ContentPannel = ({ list, changeClaims }) => {
		return (
			<Row className="main_content">
				<Col className="content_list">
					<Row className="content_detail_toggle">
						<Col xl={3} md={5} sm={5} xs={5} className="toggle_switch">
							<Switch onChange={(checked) => changeClaims(checked, list?.value)} checked={list?.isSelected} className="switch" />
						</Col>
						<Col xl={21} md={19} sm={5} xs={19}>
							<Row className="header">
								<EdenredTypo style={{ color: list.isSelected === false ? '#9A9EA6' : '#484B52' }}>{list.title}</EdenredTypo>
							</Row>
							<Row className="detail">
								<EdenredTypo style={{ color: list.isSelected === false ? '#9A9EA6' : '#484B52' }}>{list.description}</EdenredTypo>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};
	const Leaves = ({ Leaves, changeClaims }) => {
		return (
			<Row className={Leaves.title === 'Approval Workflow' ? 'main_content2' : 'main_content1'}>
				<Col className="content_list">
					<Row className="content_detail_toggle">
						<Col xl={3} md={5} sm={5} xs={5} className="toggle_switch">
							<Switch className="switch" onChange={(checked) => changeClaims(checked, Leaves?.value)} checked={Leaves?.isSelected} />
						</Col>
						<Col xl={21} md={19} sm={5} xs={19}>
							<Row className="header">
								<EdenredTypo>{Leaves.title}</EdenredTypo>
							</Row>
							<Row className="detail">
								<EdenredTypo>{Leaves.description}</EdenredTypo>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};
	return (
		<>
			<EdenredHeader cancel />
			{apiLoading ? (
				<div className="loader-role">
					<ContentLoader />
				</div>
			) : (
				<>
					<Col xs={24} sm={24} md={24} xl={24} className="action-approve-pannel">
						<EdenredTypo className="verify">Change Payroll Permissions for {username}</EdenredTypo>
						<Col xl={24} lg={24} md={24} className="approve_content_list">
							<Col xl={24} md={24} className="user">
								<span className="user_title">Verifier</span>
								{/* <EdenredTypo className="user_description">Users can manage payroll and employee records with an verifier’s approval.</EdenredTypo> */}
							</Col>

							{allClaims?.administratonClaims?.map((list, i) => (
								<Col>
									<ContentPannel key={i} list={list} changeClaims={changeClaims} />
									{list?.length === i + 1 ? null : (
										<Col>
											<Divider style={{ margin: ' 0px 0px' }} />
										</Col>
									)}
								</Col>
							))}
						</Col>
						<Card className="card_action_pannel">
							<Row style={{ width: '100%' }}>
								<Col xl={2} lg={2} sm={3} xs={3} md={4}>
									<ImageComponent src={icons.focus_state.Warning} className="explem" />
								</Col>
								<Col xl={22} lg={22} md={20} sm={21} xs={21} className="action_apporve">
									Disabling any of these actions would mean that your user can directly perform them WITHOUT a verifier's approval.
								</Col>
							</Row>
						</Card>
						{
							<>
								{allClaims?.hrClaims?.length > 0 && value && (
									<Col xl={24} lg={24} md={24} className="approve_content_list1">
										<Col xl={24} md={24} className="user">
											<EdenredTypo className="user_title">HR</EdenredTypo>
										</Col>
										{apiLoading && (
											<div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
												<ContentLoader />
											</div>
										)}
										{allClaims?.hrClaims?.map((list, i) => (
											<Col>
												<Leaves Leaves={list} changeClaims={changeClaims} />
												{hrclaims?.length === i + 1 ? null : (
													<Col>
														<Divider style={{ margin: ' 0px 0px' }} />
													</Col>
												)}
											</Col>
										))}
									</Col>
								)}
							</>
						}
						<Col xl={12} lg={12} md={12} sm={24} className="button-verifier-role">
							<EdenredButton loading={loading} onClick={modalSubmit} style={{ marginTop: '32px', width: '340px' }}>
								Save Changes
							</EdenredButton>
						</Col>
					</Col>
				</>
			)}
		</>
	);
};
export default ActionApprovePresentational;
