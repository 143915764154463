import React from 'react';
import { Col } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import { amountWithCommas } from '@helpers';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { PayrollSelectDropdown } from './payroll-select-dropdown';

export const PayrollInput = ({ value, type, onChange, placeholder, prefix, defaultValue, onKeyDown, onInput, disable, min }) => {
	return (
		<div class="searchformfld">
			<Inputbox
				classname="candidateName"
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				defaultValue={defaultValue}
				prefix={prefix}
				onKeyDown={onKeyDown}
				onInput={onInput}
				disabled={disable}
				min={min}
				label={placeholder}
			/>
		</div>
	);
};

export const AddAllowance = ({
	handleInput,
	addAllowance,
	i,
	payrollDetails,
	handleDropDown,
	handleDeleteAllowance,
	employeeDetails,
	isPayrollSettingClaim,
	allowanceList,
	nAllowanceList,
	nAllowance,
}) => {
	nAllowance.push(nAllowanceList?.[i - 1]?.allowance);
	return (
		<>
			<Col xl={11} lg={11} md={11} sm={11} xs={11} className="payroll-setting-sub-dropdown">
				<PayrollSelectDropdown
					{...{
						addAllowance,
						payrollDetails,
						value: addAllowance?.allowance,
						dropDownValue: 'name',
						label: 'Allowance',
						placeholder: 'Allowance',
						Option: allowanceList?.filter(({ name: id1 }) => !nAllowance?.includes(id1)),
						onChange: (data) => handleDropDown(data, 'allowance', i),
						disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
					}}
				/>
			</Col>
			<Col xl={6} lg={6} md={6} sm={8} xs={8} className="payroll-setting-sub-input">
				<PayrollInput
					{...{
						onChange: (e) => handleInput(e, 'aed', i),
						payrollDetails,
						addAllowance,
						value: amountWithCommas(addAllowance?.aed),
						prefix: 'AED',
						defaultValue: payrollDetails.aed ? null : 0,
						type: 'text',
						disable: employeeDetails?.isInactivatedforDeletion || isPayrollSettingClaim?.length === 0,
					}}
				/>
			</Col>
			<Col style={{ pointerEvents: isPayrollSettingClaim?.length === 0 ? 'none' : '' }} className="payroll-setting-sub-img">
				<ImageComponent className="payroll-img-trash" onClick={() => handleDeleteAllowance(i)} src={focus_state_icons?.NewDelete} />
			</Col>
		</>
	);
};
