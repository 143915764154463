import Actions from './action';
export const initState = {
	payrollPlusFilterData: [],
	payrollPlusEmpTableData: [],
	payrollSalaryStatus: [],
	additionType: [],
	allowanceType: [],
	deductionType: [],
	payrollTableCount: 0,
	dynamicHeaderSuccess: false,
	payrollPlusDataLoader: false,
	repeatprevpayrollLoader: false,
	payrollPopupFlag: false,
	flagPopup: false,
	salaryStaging: false,
	postRakBankDetailsLoading: false,
	postRakBankDetailsResponse: '',
	postRakBankToastRes: false,
	isTransferFundPopUpVisible: false,
	isRakBankAccountHolder: false,
	wpsNonWpsPopupFlag: false,
	updateStagingLoader: false,
	updateStagingMode: '',
	updateStagingEmployees: [],
	salaryLimitExceeds: false,
};

const counterReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_PAYROLL_FILTER_DROPDOWN_DATA_SUCCESS: {
			return {
				...state,
				payrollPlusFilterData: action.res,
			};
		}
		case Actions.types.GET_PAYROLL_EMPLOYEE_TABLE_DATA: {
			return {
				...state,
				payrollPlusDataLoader: true,
				payrollPlusEmpTableData: [],
			};
		}

		case Actions.types.GET_PAYROLL_EMPLOYEE_TABLE_DATA_SUCCESS: {
			return {
				...state,
				payrollPlusDataLoader: false,
				payrollPlusEmpTableData: action.res,
			};
		}
		case Actions.types.GET_PAYROLL_EMPLOYEE_TABLE_DATA_ERROR: {
			return {
				...state,
				payrollPlusDataLoader: false,
			};
		}

		case Actions.types.GET_SALARY_STATUS_SUCCESS: {
			return {
				...state,
				payrollSalaryStatus: action.res,
			};
		}
		case Actions.types.GET_PAYROLL_EMPLOYEE_TABLE_COUNT_SUCCESS: {
			return {
				...state,
				payrollTableCount: action.res,
			};
		}

		case Actions.types.GET_PAYROLL_DYNAMIC_HEADER_SUCCESS: {
			return {
				...state,
				additionType: action.addition,
				allowanceType: action.allowance,
				deductionType: action.deduction,
				dynamicHeaderSuccess: true,
			};
		}
		case Actions.types.BULK_UPDATE_PAYROLL: {
			return {
				...state,
				payrollPopupFlag: true,
				payrollPlusEmpTableData: [],
			};
		}
		case Actions.types.BULK_UPDATE_PAYROLL_SUCCESS: {
			return {
				...state,
				payrollPopupFlag: false,
			};
		}
		case Actions.types.BULK_UPDATE_PAYROLL_ERROR: {
			return {
				...state,
				payrollPopupFlag: false,
			};
		}
		case Actions.types.UPDATE_STAGING: {
			return {
				...state,
				updateStagingLoader: true,
				updateStagingMode: action?.payload.status,
				payrollPlusEmpTableData: [],
				updateStagingEmployees: []
			};
		}
		case Actions.types.UPDATE_STAGING_SUCCESS: {
			return {
				...state,
				updateStagingLoader: false,
				updateStagingMode: '',
				updateStagingEmployees: action.payload || []
			};
		}
		case Actions.types.UPDATE_STAGING_ERROR: {
			return {
				...state,
				updateStagingLoader: false,
				updateStagingMode: '',
				updateStagingEmployees: []
			};
		}
		case Actions.types.EMPLOYEE_SALARY_STAGINGS_SUCCESS: {
			return {
				...state,
				salaryStaging: false,
			};
		}
		case Actions.types.CHANGE_WPS_NON_WPS_POPUP: {
			return {
				...state,
				wpsNonWpsPopupFlag: action.flag,
			};
		}
		case Actions.types.POPUP_TRIGGER: {
			return {
				...state,
				flagPopup: action.res,
			};
		}

		case Actions.types.POST_RAKBANK_DETAILS: {
			return {
				...state,
				postRakBankDetailsLoading: true,
			};
		}
		case Actions.types.POST_RAKBANK_DETAILS_SUCCESS: {
			return {
				...state,
				postRakBankDetailsLoading: false,
				postRakBankDetailsResponse: action.res,
			};
		}
		case Actions.types.POST_RAKBANK_DETAILS_FAIL: {
			return {
				...state,
				postRakBankDetailsLoading: false,
				postRakBankDetailsResponse: {},
				// flagPopup: action.res,
			};
		}
		case Actions.types.POST_RAKBANK_TOAST: {
			return {
				...state,
				postRakBankToastRes: action.flag,
			};
		}

		case Actions.types.TRANSFER_FUND_POPUP_OPEN_CLOSE: {
			return {
				...state,
				isTransferFundPopUpVisible: action.flag,
			};
		}

		case Actions.types.RAK_BANK_ACCOUNT_HOLDER: {
			return {
				...state,
				isRakBankAccountHolder: action.flag,
			};
		}
		case Actions.types.PAYROLL_EMPLOYEE_TABLE_DATA_LOADER: {
			return {
				...state,
				payrollPlusDataLoader: action.payload,
			};
		}
		case Actions.types.SALARY_LIMIT_EXCEEDS: {
			return {
				...state,
				salaryLimitExceeds: action.payload,
			};
		}
		default:
			return state;
	}
};

export default counterReducer;
