import React from 'react';
import { withReducer } from '@store/reducerLoader';
import ProfileFunctional from './components/profile-functional';
import reducer from './reducer';
import saga from './saga';

import './profile.scss';

const ProfileDashboard = () => {
	return <ProfileFunctional />;
};

const ProfileScreen = withReducer('profileReducer', reducer, saga)(ProfileDashboard);
export default ProfileScreen;
