import React, { useState } from 'react';
import { Col, Row, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EDragger from '@pages/DesignSystem/Dragger';
import Inputbox from '@pages/DesignSystem/Input-Field';
import PayrollService from 'src/services/payroll-service';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';

const PleaseUploadSpreadsheet = ({}) => {
	const [modalDrag, setModalDrag] = useState(false);
	const [isupload, setIsupload] = useState(false);
	const [form] = Form.useForm();
	const [files, setFiles] = useState({});
	const { state } = useLocation();
	const history = useHistory();
	const [signatoryName, setsignatoryName] = useState(null);
	const _payrollService = new PayrollService();
	const [uploadloading, setuploadloading] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({ titleMessage: '', subMessage: '' });

	const handleDragger = (info) => {
		setFiles(info);
		setModalDrag(true);
		setIsupload(true);
	};
	const onRemoveUpload = () => {
		setFiles({});
		setModalDrag(false);
		setIsupload(false);
	};

	const handleUpload = (value) => {
		if (value === 'save') {
			let formData = new FormData();
			let params = {};
			if (files) {
				if (state) {
					params = {
						Type: (state && state.type) || '',
					};
				}
				if (signatoryName) {
					params['signatoryName'] = signatoryName;
				}
				formData.append('file', files);
				setuploadloading(true);
				_payrollService
					.companyUpload(formData, params)
					.then((response) => {
						console.clear();
						setuploadloading(false);
						setIsupload(true);
						setpopupVisible(true);
						setPopupMessage({
							titleMessage: 'Saved successfully..',
							subMessage: null,
						});
					})
					.catch((err) => {
						setIsupload(false);
						setuploadloading(false);
						setFiles({});
						setModalDrag(false);
						setsignatoryName(null);
						form.resetFields();
					});
			}
		} else {
			history.push('/company/information');
		}
	};
	const closePopup = () => {
		setpopupVisible(false);
		let data = state;
		if (signatoryName) {
			data.signatory.push({ username: signatoryName });
			history.push({ pathname: '/company/addnew/signatory', state: state });
		} else {
			history.push({ pathname: '/company/information', state: { showSuccessMessage: true } });
		}
	};
	const draggerIcon = () => {
		if (uploadloading) {
			return focus_state_icons.hrUploading;
		} else if (isupload) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (uploadloading) {
			return 'uploading';
		} else if (isupload) {
			return 'done';
		} else {
			return 'new';
		}
	};
	const checkDisabled = state?.isUpload ? !files?.name : !(files?.name && signatoryName);

	return (
		<>
			<Edenredmodal isOpen={popupVisible} title={popupMessage?.titleMessage} onOkay={closePopup} onOkayButton="OK" />
			<EdenredHeader />
			<Row className="please-upload-spreadsheet">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="upload-spreadsheet-header">
					<ETypo bold h2>
						Upload Document
					</ETypo>
					<div className="new_desgin_info_button">
						<EdenredButtons className="new_desgin_cancel" btnType="secondary" onClick={() => handleUpload('cancel')}>
							Cancel
						</EdenredButtons>
						<EdenredButtons btnType="primary" loading={uploadloading} disabled={checkDisabled} onClick={() => handleUpload('save')}>
							Submit Document
						</EdenredButtons>
					</div>
				</Col>
				<div className="new_design_Info">
					<Col xl={24} md={24} lg={24} className="dragger_card_container">
						<EDragger
							{...{
								idleIcon: draggerIcon(),
								draggerStatus: draggerStatus(),
								uploadDeleteIcon: focus_state_icons.NewDelete,
								onChange: handleDragger,
								onRemoveUpload,
								acceptedTypes: '.png,.pdf,.jpg,.jpeg,.doc,.docx',
							}}
						/>
					</Col>
					{!state?.isUpload ? (
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<Form form={form} style={{ display: 'block' }}>
								<Row classNamxe="please-row">
									<Col xl={8} lg={8} md={10} sm={24} xs={24} className="input-field-expiry-date">
										<Form.Item name="Name">
											<Inputbox
												label="Name of signatory or owner:"
												className="inputlabelrequired"
												value={signatoryName}
												onChange={(e) => setsignatoryName(e.target.value)}
												placeholder="Name"
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Col>
					) : (
						''
					)}
				</div>
			</Row>
		</>
	);
};

export default PleaseUploadSpreadsheet;
