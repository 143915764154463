import React from 'react';
import { Row } from 'antd';
import Tooltip from '../index';

const TooltipExample = () => {
	return (
		<div style={{ width: '70%' }}>
			<Row style={{ justifyContent: 'center', marginTop: '40px', gap: '20px' }}>
				{' '}
				<Tooltip direction="up" content="Hello World">
					ToopTip Top
				</Tooltip>
				<Tooltip direction="down" content="Hello World">
					ToopTip Bottom
				</Tooltip>
			</Row>
			<Row style={{ justifyContent: 'center', marginTop: '40px', gap: '20px' }}>
				<Tooltip direction="left" content="Hello World">
					ToopTip Left
				</Tooltip>
				<Tooltip direction="right" content="Hello World">
					ToopTip Right
				</Tooltip>
			</Row>
		</div>
	);
};

export default TooltipExample;
