import Addition from './Addition';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import React from 'react';
import '../allowance/index.scss';

const AdditionPage = () => {
	return (
		<main className="wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<Addition cleverTop={true} />
			</div>
		</main>
	);
};

export default AdditionPage;
