import React, { useState, useEffect } from 'react';
import EmployeeService from 'src/services/employee-service';
import CardOrderStatusPresentational from './card-order-status-presentational';
import moment from 'moment';

const CardOrderStatusFunctional = () => {
	let [pendingRequest, setPendingRequest] = useState([]);
	let [isDataLoading, setIsDataLoading] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [contentLoading, setcontentLoading] = useState(false);

	let employeeApi = new EmployeeService();

	// useEffect(() => {
	// 	if (!isDataLoading) {
	// 		tableScrollEvent();
	// 	}
	// }, [!isDataLoading]);

	useEffect(() => {
		fetchPendingRequests(pageNumber);
	}, [pageNumber]);

	const setCallPageNumber = (page) => {
		setPageNumber(page);
		console.log({ page });
	};

	const tableScrollEvent = () => {
		const node = document.querySelector('.card-order-status-col-presentational');
		// const node = document.querySelector('.card_order_status_card_section');

		console.log('pageNumber event', pageNumber);
		node.addEventListener('scroll', () => {
			const perc = (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;

			console.log({ perc });
			if (perc >= 99.5) {
				console.log('TODO: Scrolling has reached bottom, load more data, or do whatever...');
				console.log({ pageNumber });
				setTimeout(() => {
					setPageNumber((pageNumber) => pageNumber + 1);
				});
				console.log('after', { pageNumber });
				// setCallPageNumber((pageNumber) => pageNumber + 1);
				// props.fetchPendingRequests();
			}
		});
	};
	const handleOnScroll = () => {
		setPageNumber(pageNumber + 1);
	};
	// console.log(pageNumber);
	async function fetchPendingRequests(page) {
		try {
			let params = {};
			params['pageNumber'] = page;
			params['pageSize'] = 20;
			setcontentLoading(true);
			let response = await employeeApi.getCardStatus(params);
			if (response && response.data) {
				let data =
					response.data.requests.map((response) => {
						response.name = `${response.numberOfRequest} Cards`;
						response.page = pageNumber;
						response.noOfCards = response.numberOfRequest;
						response.color = response.color;
						let orderPlacedDate = moment(response.orderPlaced);
						response.isSelected = false;
						response.order = `Order Placed: ${orderPlacedDate.format('DD MMMM YYYY')}, ${orderPlacedDate.format('h:mm a')}`;
						return response;
					}) || [];

				console.log({ data });
				setPendingRequest([...pendingRequest, ...data]);
				setcontentLoading(false);
			}
		} catch (err) {}
	}

	return (
		<CardOrderStatusPresentational
			contentLoading={contentLoading}
			fetchPendingRequests={fetchPendingRequests}
			pendingRequest={pendingRequest}
			loading={isDataLoading}
			handleOnScroll={handleOnScroll}
		/>
	);
};

export default CardOrderStatusFunctional;
