const PUT_ANNOUNCEMENT_UPLOAD = 'PUT_ANNOUNCEMENT_UPLOAD';
const PUT_ANNOUNCEMENT_UPLOAD_LOADING = 'PUT_ANNOUNCEMENT_UPLOAD_LOADING';
const PUT_ANNOUNCEMENT_UPLOAD_FAIL = 'PUT_ANNOUNCEMENT_UPLOAD_FAIL';
const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
const SET_ANNOUNCEMENT_DATA = 'SET_ANNOUNCEMENT_DATA';
const SET_ANNOUNCEMENT_ERROR = 'SET_ANNOUNCEMENT_ERROR';

const putAnnouncementUpload = (payload) => {
	return {
		type: PUT_ANNOUNCEMENT_UPLOAD,
		payload,
	};
};
const putAnnouncementUploadLoading = (payload) => {
	return {
		type: PUT_ANNOUNCEMENT_UPLOAD_LOADING,
		payload,
	};
};
const putAnnouncementUploadFail = () => {
	return {
		type: PUT_ANNOUNCEMENT_UPLOAD_FAIL,
	};
};
const getAnnouncement = () => {
	return {
		type: GET_ANNOUNCEMENT,
	};
};
const setAnnouncementData = (res) => {
	return {
		type: SET_ANNOUNCEMENT_DATA,
		res,
	};
};
const setAnnouncementError = (error) => {
	return {
		type: SET_ANNOUNCEMENT_ERROR,
		error,
	};
};
export default {
	types: {
		PUT_ANNOUNCEMENT_UPLOAD,
		PUT_ANNOUNCEMENT_UPLOAD_LOADING,
		PUT_ANNOUNCEMENT_UPLOAD_FAIL,
		GET_ANNOUNCEMENT,
		SET_ANNOUNCEMENT_DATA,
		SET_ANNOUNCEMENT_ERROR,
	},
	creators: {
		putAnnouncementUpload,
		putAnnouncementUploadLoading,
		putAnnouncementUploadFail,
		getAnnouncement,
		setAnnouncementData,
		setAnnouncementError,
	},
};
