import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { APP_VARIABLES, DEFAULT_SHOW, SHOW_ONLY_IN_DEV } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';
import { mergeAllClaims } from 'src/utils/claims';
import { allMenus } from './helpers';

function useMenus() {
	const [result, setResult] = useState({});
	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);

	function getMenusToShow() {
		let menusToShow = [];
		function subMenusWithValidClaims(_menu) {
			const subMenus = [];
			const HAS_SUBMENU = _menu.subMenus.length > 0;
			if (HAS_SUBMENU) {
				for (const _subMenu of _menu.subMenus) {
					if (checkSubscription === false && _subMenu.forSubscribedUsersOnly) continue;
					const SUBMENU_HAS_CLAIM = _subMenu.toShow.some((values) => {
						if (values === SHOW_ONLY_IN_DEV) return true;
						if (values === DEFAULT_SHOW) return true;
						return allClaims.indexOf(values) >= 0;
					});
					if (SUBMENU_HAS_CLAIM) {
						const SHOULD_CHANGE_PATH = _subMenu.key === 'run-payroll' && checkSubscription === true;
						subMenus.push({
							..._subMenu,
							path: _subMenu.path,
							// path: SHOULD_CHANGE_PATH ? '/payroll-plus' : _subMenu.path,
						});
					}
				}
			}
			return subMenus;
		}

		for (const _menu of allMenus) {
			// If user is not subscriber & menu is only for subscriber users then skip (don't add menu)
			if (checkSubscription === false && _menu.forSubscribedUsersOnly) continue;
			// If claims contains values in NotToShow of menu then skip (don't add menu)
			if (_menu.notToShow && _menu.notToShow.some((values) => allClaims.indexOf(values) >= 0)) continue;

			const MENU_HAS_CLAIM = _menu.toShow.some((values) => {
				if (values === SHOW_ONLY_IN_DEV) return true;
				if (values === DEFAULT_SHOW) return true;
				return allClaims.indexOf(values) >= 0;
			});
			if (MENU_HAS_CLAIM) {
				const _subMenu = subMenusWithValidClaims(_menu);
				const SHOULD_CHANGE_PATH = _menu.key === 'payroll' && checkSubscription === true;
				let result = {
					..._menu,
					path: _menu.path,
					// path: SHOULD_CHANGE_PATH ? '/payroll-plus' : _menu.path,
					subMenus: _subMenu,
				};
				menusToShow.push(result);
			}
		}

		return menusToShow;
	}

	useEffect(() => {
		const menuToShow = getMenusToShow();
		setResult((prevState) => {
			return {
				...prevState,
				menus: menuToShow,
			};
		});
	}, [currentUser]);

	return {
		...result,
	};
}

export default useMenus;
