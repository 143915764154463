import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { debounceHandler, removeNullFromObject } from '@helpers';
import EdenredModal from '@sharedComponent/modal';
import ContentLoader from '@sharedComponent/content-loader';
import { SAVE_CANCEL_MSG } from '@constants/app-constants';
import Selectors from './selector';
import Actions from './action';
import EmployeeDocumentPresentational from './emp-document-presentational';

const EmployeeDocumentFunctional = ({
	getEmployeeIndividualData,
	getEmployeeIndividualDataReset,
	updateProfileData,
	uploadDataLoading,
	updatedData,
	getDeleteImages,
	employeeDocumentImages,
	empImageLoading,
	employeeValues = {},
	empDataLoading,
	getEmployeeDocumentImages,
	deleteImagesLoading,
	employeeDetails,
	setNavigateTabCheck,
	navigateTabCheck,
	buttonFlag,
	setButtonFlag,
	hasErrors,
	setHasErrors,
	empDocState,
	setEmpDocState,
	backupData,
	setBackupData,
	imageData,
	setImageData,
	empDocumentForm,
}) => {
	let history = useHistory();
	const dispatch = useDispatch();
	const [enableSave, setenableSave] = useState(false);
	const [preview, setPreview] = useState(false);
	const [expiryDates, setExpiryDates] = useState({
		labour: false,
		emirates: false,
		visa: false,
		passport: false,
	});
	const employeeId = history.location.state?.tableData?.employeeId || employeeDetails?.id;
	const isDuplicateCheck = useSelector((state) => state?.individualData?.duplicateCheckData);
	const getEmployeeIndividualDataCall = () => {
		getEmployeeIndividualData(employeeId);
		getEmployeeDocumentImages(employeeId);
	};
	useEffect(() => {
		getEmployeeIndividualDataCall();
		return () => {
			getEmployeeIndividualDataReset();
		};
	}, []);

	useEffect(() => {
		empDocumentForm.resetFields();
	}, [empDocState?.labourCardExpiryDate, empDocState?.emiratesExpiryDate, empDocState?.visaExpiryDate, empDocState?.passportExpiryDate]);

	useEffect(() => {
		empDocumentForm.setFieldsValue(empDocState);
	}, [empDocState]);

	useEffect(() => {
		if (!empDataLoading) {
			if (!!Object.keys(employeeValues).length) {
				setEmpDocState({ ...employeeValues });
				setBackupData({ ...employeeValues });
				setHasErrors(true);
				setenableSave(false);
				setImageData({ labourCardScan: '', emiratesFrontScan: '', emiratesBackScan: '', visaScan: '', passportScan: '' });
			}
		}
	}, [empDataLoading]);

	const draggerChange = (value, type) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '5',
		});
		switch (type) {
			case 'Labour Card Page':
				setImageData({ ...imageData, labourCardScan: value });
				break;
			case 'Emirates ID Front':
				setImageData({ ...imageData, emiratesFrontScan: value });
				break;
			case 'Emirates ID Back':
				setImageData({ ...imageData, emiratesBackScan: value });
				break;
			case 'Visa Page':
				setImageData({ ...imageData, visaScan: value });
				break;
			case 'Passport Page':
				setImageData({ ...imageData, passportScan: value });
				break;
			default:
				break;
		}
		setButtonFlag(!!value);
		if (!enableSave) {
			setHasErrors(false);
			setenableSave(true);
		}
	};
	useEffect(() => {
		setExpiryDates({
			...expiryDates,
			labour: !empDocState?.labourCardExpiryDate && imageData?.labourCardScan,
			emirates: !empDocState?.emiratesExpiryDate && (imageData?.emiratesFrontScan || imageData?.emiratesBackScan),
			visa: !empDocState?.visaExpiryDate && imageData?.visaScan,
			passport: !empDocState?.passportExpiryDate && imageData?.passportScan,
		});
	}, [imageData, empDocState?.labourCardExpiryDate, empDocState?.emiratesExpiryDate, empDocState?.visaExpiryDate, empDocState?.passportExpiryDate]);

	const handleCancelModal = () => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '5',
		});
		setPreview(true);
	};

	const handleCloseModal = () => setPreview(false);

	const handleCancel = () => {
		setImageData({ labourCardScan: '', emiratesFrontScan: '', emiratesBackScan: '', visaScan: '', passportScan: '' });
		setEmpDocState({});
		setPreview(false);
		setButtonFlag(false);
		empDocumentForm.resetFields();
		getEmployeeIndividualDataReset();
		getEmployeeIndividualDataCall();
	};

	const handleValueChanged = (value, type) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '5',
		});
		if (value === '' || value) {
			setEmpDocState({
				...empDocState,
				[type]: value,
			});
			empDocumentForm.setFieldsValue({
				[type]: value,
			});
		} else {
			empDocumentForm.setFieldsValue({
				[type]: empDocState[type],
			});
		}
		// setButtonFlag(true);
		if (type === 'emiratesIdNumber' && value?.length === 15 && !empDocumentForm.getFieldsError().some(({ errors }) => errors.length)) {
			debounceHandler(value, handleDocumentDuplicate);
		}
	};

	const handleDocumentDuplicate = (value) => {
		const payload = {
			docId: value || '',
			docType: 'emirates',
		};
		dispatch(Actions.creators.checkDuplicateDocumentStart(payload));
	};
	const handleDateChanged = (value, type) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '5',
		});
		let dateValue = value === 'Invalid date' || !value ? '' : value;
		empDocumentForm.setFieldsValue({ [type]: dateValue });
		setEmpDocState({
			...empDocState,
			[type]: dateValue,
		});
		setButtonFlag(true);
	};
	const handleFinish = (e) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '5',
		});
		if (
			!empDocumentForm.getFieldsError().some(({ errors }) => errors.length) &&
			!expiryDates?.labour &&
			!expiryDates?.emirates &&
			!expiryDates?.visa &&
			!expiryDates?.passport &&
			!isDuplicateCheck
		) {
			updateProfileData(employeeId, empDocState, imageData);
		}
	};

	const handleFormChange = (e) => {
		const hasErrorss = empDocumentForm.getFieldsError().some(({ errors }) => errors.length);
		const save = e ? false : true;
		setHasErrors(hasErrorss || save);
	};
	const disabledButton = useMemo(
		() => JSON.stringify(removeNullFromObject(empDocState)) === JSON.stringify(removeNullFromObject(backupData)),
		[empDocState, backupData]
	);

	if (preview)
		return (
			<EdenredModal
				{...{
					isOpen: preview,
					getDeleterole: handleCancel,
					afterOpenModal: handleCloseModal,
					go_Back: 'No',
					className: 'modal_card_approve',
					user: SAVE_CANCEL_MSG?.CANCEL_MSG,
					content_web: SAVE_CANCEL_MSG?.CANCEL_DESC,
				}}
			/>
		);

	if (!(!empImageLoading && !empDataLoading))
		return (
			<div className="emp_doc_loader">
				<ContentLoader />
			</div>
		);

	return (
		<EmployeeDocumentPresentational
			{...{
				backupData,
				employeeValues,
				empDataLoading,
				empImageLoading,
				empDocState,
				empDocumentForm,
				draggerChange,
				employeeDocumentImages,
				handleDateChanged,
				handleFinish,
				hasErrors,
				handleFormChange,
				handleValueChanged,
				imageData,
				updatedData,
				handleCancelModal,
				disabledButton,
				uploadDataLoading,
				getDeleteImages,
				employeeId,
				setHasErrors,
				buttonFlag,
				deleteImagesLoading,
				isDuplicateCheck,
				expiryDates,
				setButtonFlag,
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		employeeValues: Selectors.employeeValues(state),
		employeeDocumentImages: Selectors.employeeDocumentImages(state),
		empDataLoading: Selectors.empDataLoading(state),
		uploadDataLoading: Selectors.uploadDataLoading(state),
		updatedData: Selectors.updatedData(state),
		deleteImages: Selectors.deleteImages(state),
		deleteImagesLoading: Selectors.deleteImagesLoading(state),
		empImageLoading: Selectors.empImageLoading(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEmployeeIndividualData: (id) => dispatch(Actions.creators.getEmployeeIndividualData(id)),
		getEmployeeIndividualDataReset: () => dispatch(Actions.creators.getEmployeeIndividualDataReset()),
		getEmployeeDocumentImages: (id) => dispatch(Actions.creators.getEmployeeDocumentImages(id)),
		updateProfileData: (id, upData, imageData) => dispatch(Actions.creators.updateProfileData(id, upData, imageData)),
		getDeleteImages: (docid, Empid) => dispatch(Actions.creators.getDeleteImages(docid, Empid)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(EmployeeDocumentFunctional);
export default Connected;
