import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import ETypo from '@pages/DesignSystem/Typo';

export const OutStandingInvoiceBanner = ({ icon = icons?.focus_state?.InfoWarnig }) => {
	return (
		<div className="alert-info-box error" style={{ marginBottom: '15px' }}>
			<div className="info-icon">
				<img src={icon} alt="warning" />
			</div>
			<div className="info-content">
				There is an outstanding invoice due for payment. Kindly make the payment to avoid any disruption to your services. If you have already
				paid, please upload proof of payment.
				<br />
				<ETypo b1 color="unset">
					Ignore this message if you have already paid upto last month invoice. Your SOA will be updated soon
				</ETypo>
			</div>
			<div className="info-action">
				<Link
					style={{ color: '#181919' }}
					className="info-action-link"
					to={{
						pathname: `/proof-of-invoice`,
					}}>
					Upload Proof
				</Link>
				<img src={icons?.focus_state?.RightIcon} alt="icon" />
			</div>
		</div>
	);
};
