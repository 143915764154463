import Actions from './action';

export const initState = {
	loading: false,
	employeedetail: [],
	error: '',
	downloadLoading: false,
	submitSuccessPopup: false,
	downloadDetail: [],
	downloadError: '',
	filterLoading: false,
	filterDetail: {},
	documentExpiryList: [],
	oldFilterDetail: {},
	filterError: '',
	saveLoading: false,
	popupVisible: false,
	saveDetail: [],
	approvedValidEmploye: [],
	saveError: '',
	excelDownloadLoading: false,
	pdfDownloadLoading: false,
};

const expiryCountReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SET_POPUP_VISIBLE: {
			return {
				...state,
				popupVisible: action.flag,
			};
		}
		case Actions.types.GET_APPROVED_EMPLOYEE: {
			return {
				...state,
				getApprovedValidloading: true,
			};
		}
		case Actions.types.GET_APPROVED_EMPLOYEE_SUCCESS: {
			return {
				...state,
				approvedValidEmploye: action.res,
				getApprovedValidloading: false,
			};
		}
		case Actions.types.GET_APPROVED_EMPLOYEE_ERROR: {
			return {
				...state,
				getApprovedValidloading: false,
			};
		}
		case Actions.types.HANDEL_SUBMIT_SUCCESS_POPUP: {
			return {
				...state,
				submitSuccessPopup: action.flag,
			};
		}
		case Actions.types.SUBMIT_VALID_EMPLOYEE: {
			return {
				...state,
				submitValidloading: true,
			};
		}
		case Actions.types.SUBMIT_VALID_EMPLOYEE_SUCCESS: {
			return {
				...state,
				submitValidloading: false,
			};
		}
		case Actions.types.SUBMIT_VALID_EMPLOYEE_ERROR: {
			return {
				...state,
				submitValidloading: false,
			};
		}
		case Actions.types.GET_EMPLOYEECOUNT_START: {
			return {
				...state,
				loading: true,
			};
		}
		case Actions.types.GET_EMPLOYEECOUNT_SUCCESS: {
			return {
				...state,
				loading: false,
				employeedetail: action.response,
			};
		}
		case Actions.types.GET_EMPLOYEECOUNT_FAIL: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}
		case Actions.types.SAVE_DOWNLOAD_START: {
			return {
				...state,
				excelDownloadLoading: action.downloadDocument.reportType === 'excel' ? true : false,
				pdfDownloadLoading: action.downloadDocument.reportType === 'pdf' ? true : false,
			};
		}
		case Actions.types.SAVE_DOWNLOAD_SUCCESS: {
			return {
				...state,
				excelDownloadLoading: action.reportType === 'excel' && false,
				pdfDownloadLoading: action.reportType === 'pdf' && false,
				downloadDetail: action.response,
			};
		}
		case Actions.types.SAVE_DOWNLOAD_FAIL: {
			// console.log('SAVE_DOWNLOAD_FAIL');
			return {
				...state,
				excelDownloadLoading: action.reportType === 'excel' && false,
				pdfDownloadLoading: action.reportType === 'pdf' && false,
				downloadError: action.error,
			};
		}
		case Actions.types.SAVE_FILTERDATA_START: {
			const isMobile = window?.matchMedia('only screen and (max-width: 760px)').matches;
			return {
				...state,
				filterLoading: true,
				// ...(!isMobile && { filterDetail: {} }),
				documentExpiryList: [],
				oldFilterDetail: {},
			};
		}
		case Actions.types.SAVE_FILTERDATA_SUCCESS: {
			// console.log('SAVE_FILTERDATA_SUCCESS');
			return {
				...state,
				filterLoading: false,
				filterDetail: action.response,
				oldFilterDetail: action.response,
				documentExpiryList: [...state.documentExpiryList, ...(action?.response?.documentExpiryList || [])],
			};
		}
		case Actions.types.SAVE_FILTERDATA_FAIL: {
			// console.log('SAVE_FILTERDATA_FAIL');
			return {
				...state,
				filterLoading: false,
				filterDetail: { documentExpiryList: [] },
				oldFilterDetail: { documentExpiryList: [] },
				filterError: action.error,
				documentExpiryList: [],
			};
		}
		case Actions.types.SAVE_BUTTON_START: {
			// console.log('SAVE_BUTTON_START');
			return {
				...state,
				saveLoading: true,
			};
		}
		case Actions.types.SAVE_BUTTON_SUCCESS: {
			// console.log('SAVE_BUTTON_SUCCESS');
			return {
				...state,
				saveLoading: false,
				saveDetail: action.response,
			};
		}
		case Actions.types.SAVE_BUTTON_FAIL: {
			// console.log('SAVE_BUTTON_FAIL');
			return {
				...state,
				saveLoading: false,
				saveError: action.error,
			};
		}
		default:
			return state;
	}
};

export default expiryCountReducer;
