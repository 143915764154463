import React, { useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APPROVE_WORKFLOW_CONSTANT, popUpNotification } from '@constants/app-constants';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { checkWithHRClaims } from '@helpers';
import { TeamsClaim } from '@constants/index';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { ApprovalDropdown } from './approval-dropdown';
import { PopUp } from './pop-up';
import { images } from 'src/assets/img';
import './approval-workflow.scss';
import Toast from '@pages/DesignSystem/Toast';

function ApprovalWorkflowPresentational({
	approvalWorkflowDetails,
	approvalUserListed,
	approver,
	handleApprovalDropDown,
	isApprovalWorkflowLoading,
	isApprovalWorkflowUsertLoading,
	handleApproveButton,
	handleApprovePopup,
	isButtonEnabled,
	isApprovalWorkflowSubmitLoading,
	isApproveSavePopUp,
	isApproveSavedSuccessfully,
	userData,
	payload,
	nDisable,
	isSetup = false,
}) {
	const history = useHistory();
	const approvalWorkflow = useSelector((state) => state?.sharedStates?.currentUser?.teamClaims);
	const manageApproveWorkflow = checkWithHRClaims(approvalWorkflow, TeamsClaim?.MANAGE_ROLE) || false;
	const [isApproved, setIsApproved] = useState(false);
	const handleRoutes = () => {
		if (manageApproveWorkflow) {
			history.push(isSetup ? '/admissionpermission' : '/invite_team_member');
		} else {
			setIsApproved(true);
		}
	};

	return (
		<Row className="approval-workflow-main-header">
			<Toast left={'40%'} visible={isApproveSavedSuccessfully} color={'#8EF9B3'} clearPopUp={handleApprovePopup}>
				{popUpNotification?.successMessage}
			</Toast>
			<Edenredmodal
				{...{
					isOpen: isApproved,
					title: APPROVE_WORKFLOW_CONSTANT?.MANAGE_ROLE_POPUP_MESSAGE,
					onOkayButton: APPROVE_WORKFLOW_CONSTANT?.MANAGE_ROLES_POPUP_BUTTON,
					onOkay: () => setIsApproved(false),
				}}
			/>
			<Col className="approval-workflow-header">
				<Row className="title-and-button-container">
					<ETypo h2 bold className="workflow-heading-color">
						Approval Workflow
					</ETypo>
					{/* <Row className="title-button-container">
						<EdenredButtons
							leftIcon={<ImageComponent src={images.leftsarrow} />}
							{...{
								btnType: 'secondary',
								onClick: () => handleApproveButton('cancel'),
							}}>
							Back
						</EdenredButtons>
						<EdenredButtons
							{...{
								disabled: nDisable,
								btnType: 'primary',
								onClick: () => handleApproveButton('save'),
							}}>
							Save
						</EdenredButtons>
					</Row> */}
					<div className="title-button-container">
						<EdenredButtons
							leftIcon={<ImageComponent src={images.leftsarrow} />}
							{...{
								btnType: 'secondary',
								onClick: () => handleApproveButton('cancel'),
							}}>
							Back
						</EdenredButtons>
						<EdenredButtons
							{...{
								disabled: nDisable,
								btnType: 'primary',
								onClick: () => handleApproveButton('save'),
							}}>
							Save Changes
						</EdenredButtons>
					</div>
				</Row>
				{isApprovalWorkflowLoading || isApprovalWorkflowUsertLoading ? (
					<Skeleton width={'100%'} height={'30%'} />
				) : (
					<>
						<Col className="workflow-wrapper">
							<Row>
								<Col className="approval-workflow-sub-header">
									<Row className="approver-header-card">
										<Col className="approval-workflow-empty" />
										{approver?.map((data) => (
											<>
												<Col className="approval-workflow-approver">
													<ETypo b1 medium className="approver-style">{`Approver ${data?.orderId}`}</ETypo>
													<ETypo b2 light className="field-type">
														{data?.orderId === 1 ? 'Mandatory' : 'Optional'}
													</ETypo>
												</Col>
											</>
										))}
									</Row>
								</Col>
							</Row>
							<Col className="approval-workflow-sub-header-1">
								<Col className="approval-workflow-head-container">
									<Col className="approval-workflow-container">
										{approvalWorkflowDetails?.map((data) => (
											<div className="workflow-typo-container">
												<ETypo b1 medium className="approver-style">
													{data?.departmentName}
												</ETypo>
											</div>
										))}
									</Col>
									<Col className="approval-workflow-dropdown">
										{payload?.map((deparment, idx) => (
											<ApprovalDropdown
												className="approval-workflow-dropdown"
												{...{
													deparment,
													approver,
													handleApprovalDropDown,
													approvalUserListed,
													idx,
													userData,
												}}
											/>
										))}
									</Col>
								</Col>
							</Col>
							<Col className="workflow-hint">
								<ImageComponent src={focus_state_icons.ApproveWorkflowHint} />
								<p className="workflow-text">
									{APPROVE_WORKFLOW_CONSTANT?.APPROVE_WORKFLOW_HINT}
									<span onClick={handleRoutes}> here</span>.
								</p>
							</Col>
						</Col>
						<PopUp
							{...{
								loading: isApprovalWorkflowSubmitLoading,
								isSavePopUp: isApproveSavePopUp,
								handlePopUp: handleApprovePopup,
								saveMessage: popUpNotification?.saveMessage,
								subSaveMessage: popUpNotification?.subSaveMessage,
								isCancelPopUp: isButtonEnabled?.cancelPopUp,
								cancelMessage: popUpNotification?.cancelMessage,
								subCancelMessage: popUpNotification?.subCancelMessage,
								savedSuccessfully: isApproveSavedSuccessfully,
								successMessage: popUpNotification?.successMessage,
								notification: isButtonEnabled?.notification,
								notificationMessage: 'Save before leave the page',
								okPopUpText: 'Yes, Cancel',
								cancelPopUpText: 'No, Come Back',
							}}
						/>
					</>
				)}
			</Col>
		</Row>
	);
}

export default ApprovalWorkflowPresentational;
