import React from 'react';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import ManageTeamFunctional from '../../team-permission/manage-team-members/components/manage-team-functional';
import './teamspermission.scss';
import { loadSubscriptionsStart } from './../../../store/redux-oidc/actions/index';

const Teamspermissionpresentational = () => {
	return (
		<>
			<SetupHeader />
			<div className="setup-permission">
				{/* <ManageTeam /> */}
				<ManageTeamFunctional issetup={true} />
				{/* <div>im pavam</div> */}
			</div>
		</>
	);
};
export default Teamspermissionpresentational;
// style={{}}
