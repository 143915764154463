import React from 'react';
import './index.scss';
import ImageComponent from './../../../components/shared/image-component';
import ETypo from '../Typo';
import { icons } from 'src/assets/icons';
export default function MessageBox({ icon = null, title = '', message = '', status = 'SUCCESS' }) {
	const showIcon = (statusforIcon) => {
		switch (statusforIcon) {
			case 'SUCCESS':
				return icons.focus_state.messageboxsuccess;
			case 'WARNING':
				return icons.focus_state.messageboxwarning;
		}
	};

	const showColor = (statusforColor) => {
		switch (statusforColor) {
			case 'SUCCESS':
				return '#8EF9B3';
			case 'WARNING':
				return '#FFDC91';
		}
	};
	return (
		<div className="message-box-container">
			<div className="messsage-box-icon-container" style={{ backgroundColor: showColor(status) }}>
				<ImageComponent src={icon ? icon : showIcon(status)}></ImageComponent>
			</div>
			<div className="Message-box-message-conatiner">
				{title && (
					<ETypo b2 medium color="#00344E">
						{title}
					</ETypo>
				)}
				<ETypo b2 light color="#00344E">
					{message}
				</ETypo>
			</div>
		</div>
	);
}
