const SEND_INVITE = 'SEND_INVITE';
const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
const SEND_INVITE_FAILED = 'SEND_INVITE_FAILED';
const GET_CLAIMS = 'GET_CLAIMS';
const GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS';
const GET_CLAIMS_FAILED = 'GET_CLAIMS_FAILED';
const GET_EMPLOYEES_START = 'GET_EMPLOYEES_START';
const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
const GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED';
const GET_EMPLOYEE_USER_START = 'GET_EMPLOYEE_USER_START';
const GET_EMPLOYEE_USER_SUCCESS = 'GET_EMPLOYEE_USER_SUCCESS';
const GET_EMPLOYEE_USER_FAIL = 'GET_EMPLOYEE_USER_FAIL';

const sendInvite = () => {
	return {
		type: SEND_INVITE,
	};
};
const sendInviteSuccess = (response) => {
	return {
		type: SEND_INVITE_SUCCESS,
		response,
	};
};
const sendInviteFailed = () => {
	return {
		type: SEND_INVITE_FAILED,
	};
};
const getClaims = (roleId) => {
	return {
		type: GET_CLAIMS,
		roleId,
	};
};
const getClaimsSuccess = (response) => {
	return {
		type: GET_CLAIMS_SUCCESS,
		response,
	};
};
const getClaimsFailed = () => {
	return {
		type: GET_CLAIMS_FAILED,
	};
};
const getEmployeesStart = () => {
	return {
		type: GET_EMPLOYEES_START,
	};
};
const getEmployeesSuccess = (res) => {
	return {
		type: GET_EMPLOYEES_SUCCESS,
		res,
	};
};
const getEmployeesFailed = (error) => {
	return {
		type: GET_EMPLOYEES_FAILED,
		error,
	};
};

const getEmployeeUserStart = (payload) => {
	return {
		type: GET_EMPLOYEE_USER_START,
		payload,
	};
};
const getEmployeeUserSuccess = (response) => {
	return {
		type: GET_EMPLOYEE_USER_SUCCESS,
		response,
	};
};
const getEmployeeUserFail = (error) => {
	return {
		type: GET_EMPLOYEE_USER_FAIL,
		error,
	};
};

export default {
	types: {
		SEND_INVITE,
		SEND_INVITE_SUCCESS,
		SEND_INVITE_FAILED,
		GET_CLAIMS,
		GET_CLAIMS_SUCCESS,
		GET_CLAIMS_FAILED,
		GET_EMPLOYEES_START,
		GET_EMPLOYEES_SUCCESS,
		GET_EMPLOYEES_FAILED,
		GET_EMPLOYEE_USER_START,
		GET_EMPLOYEE_USER_SUCCESS,
		GET_EMPLOYEE_USER_FAIL,
	},
	creators: {
		sendInvite,
		sendInviteSuccess,
		sendInviteFailed,
		getClaims,
		getClaimsSuccess,
		getClaimsFailed,
		getEmployeesStart,
		getEmployeesSuccess,
		getEmployeesFailed,
		getEmployeeUserStart,
		getEmployeeUserSuccess,
		getEmployeeUserFail,
	},
};
