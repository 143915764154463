import React, { useEffect, useState } from 'react';
import { Col, Row, Divider } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EmployeeService from 'src/services/employee-service';
import { DotLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { images } from 'src/assets/img';
import { useHistory } from 'react-router-dom';

const TransactionHistoryPresentational = () => {
	const [isDataLoading, setIsDataLoading] = useState(false);
	let history = useHistory();
	const changeNumbertoCurrency = (number) => {
		let amount = number.toLocaleString('az-AE', {
			// style: 'currency',
			// currency: 'AED',
			// currencyDisplay: 'code',
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		});
		let splitAmount = amount.toString().split('.');
		let currency = (
			<>
				<EdenredTypo className="integer_part">{splitAmount[0]}</EdenredTypo>
				<EdenredTypo className="decimal_part">.{splitAmount[1]}</EdenredTypo>
			</>
		);
		return currency;
	};

	let employeeApi = new EmployeeService();

	const [transactionHistory, settransactionHistory] = useState([]);
	const { state } = useLocation();

	let availableBalance = localStorage.getItem('amount') || '0';
	useEffect(() => {
		getTransactionHistory();
	}, []);

	async function getTransactionHistory() {
		setIsDataLoading(true);
		try {
			let response = await employeeApi.getTransactionHistoryApi();
			if (response && response.data) {
				settransactionHistory(response.data || []);
			}
		} catch (err) {
			console.log(err);
		}
		setIsDataLoading(false);
	}
	return (
		<>
			<Col className="transaction_history_presentational">
				{/* <EdenredHeader /> */}
				{isDataLoading && (
					<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				)}
				{!isDataLoading && (
					<Row className="transaction-history-container">
						<div className="title_and_button_wrapper">
							<h1>Transaction History</h1>
							<EdenredButtons
								onClick={() => {
									history.goBack();
								}}
								btnType="secondary"
								leftIcon={<img src={images.leftsarrow} />}
								className="go-back-button">
								Back
							</EdenredButtons>
						</div>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="transaction-history-col">
							{/* header */}
							<Row className="transaction-history-header">
								<Col xl={24} lg={24} md={24} xs={24} sm={24}>
									<EdenredTypo>Available Balance</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} className="aed-amount">
									{changeNumbertoCurrency(Number(availableBalance))}
									<EdenredTypo className="aed">AED</EdenredTypo>
								</Col>
							</Row>
							{/* table header col */}
							<Row className="transaction-history-table-header">
								<Col xl={6} lg={6} md={6}>
									<EdenredTypo className="column">Date</EdenredTypo>
								</Col>
								<Col xl={12} lg={12} md={12}>
									<EdenredTypo className="column">Description</EdenredTypo>
								</Col>
								<Col xl={6} lg={6} md={6}>
									<EdenredTypo className="column">Amount</EdenredTypo>
								</Col>
							</Row>
							{/* table content */}
							{transactionHistory.length > 0 ? (
								transactionHistory.map((historydetail, index) => {
									return (
										<Row className="transaction-history-table-content">
											<Col lg={24} md={24} xl={24} className="month-header">
												<EdenredTypo>{historydetail.month}</EdenredTypo>
											</Col>
											<Divider />
											{historydetail.transactions.map((transaction_detail, i) => (
												<>
													<Col xl={24} lg={24} md={24} className="content-detail">
														<Row className="transaction_detail_row">
															<Col xl={6} lg={6} md={6}>
																<EdenredTypo className="transaction_date">{transaction_detail.date}</EdenredTypo>
															</Col>
															<Col xl={12} lg={12} md={12}>
																<EdenredTypo className="transaction_detail">
																	{transaction_detail.description}
																</EdenredTypo>
															</Col>
															<Col xl={6} lg={6} md={6}>
																<EdenredTypo
																	className={`transaction_amount${
																		transaction_detail.amount.split('')[0] === '+' ? ' credit' : ''
																	}`}>
																	{changeNumbertoCurrency(transaction_detail.amount)}
																</EdenredTypo>
															</Col>
														</Row>
													</Col>
													<Divider />
												</>
											))}
										</Row>
									);
								})
							) : (
								<div className="transaction-history-table-content" style={{ textAlign: 'center', color: '#ddd', fontWeight: 600 }}>
									No records found
								</div>
							)}

							{/* table content mobile */}
							{transactionHistory.length > 0 ? (
								transactionHistory.map((historydetail, index) => {
									return (
										<Row className="transaction-history-table-mobile">
											<Col lg={24} md={24} xl={24} className="month-header">
												<EdenredTypo>{historydetail.month}</EdenredTypo>
											</Col>
											<Divider />
											{historydetail.transactions.map((transaction_detail, i) => (
												<>
													<Col xs={24} sm={24} className="content-detail">
														<Row className="transaction_detail_section">
															<Col xs={16} sm={16} className="transaction_date_detail_column">
																<EdenredTypo className="transaction_detail">
																	{transaction_detail.description}
																</EdenredTypo>
																<EdenredTypo className="transaction_date">{transaction_detail.date}</EdenredTypo>
															</Col>
															<Col xs={8} sm={8} className="transaction_amount_column">
																<EdenredTypo
																	className={`transaction_amount${
																		transaction_detail.amount.split('')[0] === '+' ? ' credit' : ''
																	}`}>
																	{changeNumbertoCurrency(transaction_detail.amount)}
																</EdenredTypo>
															</Col>
														</Row>
													</Col>
													<Divider />
												</>
											))}
										</Row>
									);
								})
							) : (
								<div className="transaction-history-table-mobile" style={{ textAlign: 'center', color: '#ddd', fontWeight: 600 }}>
									No records found
								</div>
							)}
						</Col>
					</Row>
				)}
			</Col>
		</>
	);
};

export default TransactionHistoryPresentational;
