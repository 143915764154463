import React from 'react';
import { Col, Row } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import EdenRedCard from '@sharedComponent/card';
import EdenredButton from '@sharedComponent/button';
import { Link } from 'react-router-dom';

const InvoicesPresentational = () => {
	const companyInvoice = [
		{
			header: 'AED 50,000.00',
			subheader: 'Due by: 30 July 2020',
		},
		{
			header: 'AED 50,000.00',
			subheader: 'Due by: 30 July 2020',
		},
	];
	return (
		<>
			<Row className="invoice-presentational-container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					{window.screen.width > 425 && (
						<Row className="invoice-header">
							<Col xl={24} md={24} lg={24} sm={24} xs={24}>
								<EdenredTypo>Your Invoices</EdenredTypo>
							</Col>
						</Row>
					)}
					<Row className="invoice-content">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<EdenredTypo>You can clear pending invoice amounts by tranferring money to our account.</EdenredTypo>
						</Col>
					</Row>
					<Row className="invoices-card">
						<Col xl={17} lg={17} md={17}>
							{companyInvoice.map((company) => (
								<EdenRedCard type="CompanyInvoice" content={company} className="company-invoices-card" />
							))}
						</Col>
					</Row>
					<Row className="invoices-button">
						<Link to="/payroll/bank_details">
							<Col xl={24} md={24} lg={24} sm={24} xs={24} className="view-our">
								<EdenredButton>View Our Account Details</EdenredButton>
							</Col>
						</Link>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default InvoicesPresentational;
