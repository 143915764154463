import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import EdenredButton from '@sharedComponent/button';
import Input from '@sharedComponent/text-field';
import { images } from '@assets/img/index';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const ForgotPasswordPresentational = ({ onFinish, form, loading }) => {
	const [username, setUsername] = useState('');

	const handleUserName = (e) => {
		setUsername(e.target.value);
	};

	return (
		<Row className="forgot_password__container">
			<Col xs={24} sm={24} md={16} xl={10} className="forgot_password_left_area">
				{/* row-1 */}
				<Row className="forgot_password_logo_row">
					<Col xl={24} xs={24} sm={20} md={24} lg={32} className="forgot_password_logo">
						{/* <ImageComponent src={images.logo} /> */}
					</Col>
				</Row>
				{/* row-2 */}
				<Row>
					<Col xl={22} lg={22} md={22} sm={12} xs={6.5} className="forgot_password__header">
						<EdenredTypo bold>Enter Your</EdenredTypo>
					</Col>
					<Col xl={18} lg={18} md={18} sm={12} xs={{ span: 17.5 }} className="forgot_password__header">
						<EdenredTypo bold>Username</EdenredTypo>
					</Col>
				</Row>
				{/* row-3 */}
				<Row className="forgot_password_form_label">
					<ToastContainer />
					<Col xs={24} sm={20} md={16} xl={18}>
						<Form form={form} onFinish={onFinish}>
							<Form.Item name="username">
								<Input
									labels="Username"
									type="text"
									placeholder="XXXXXXX"
									className="custom_username_input"
									value={username}
									onChange={handleUserName}
								/>
							</Form.Item>
							<Col className="forgot_password_submit">
								<Form.Item shouldUpdate={true}>
									{() => (
										// <Link role="button" to="/login">
										<EdenredButton
											loading={loading}
											disabled={
												!form.isFieldsTouched(true) ||
												form.getFieldsError().filter(({ errors }) => errors.length).length ||
												username.length === 0
											}>
											Submit
										</EdenredButton>
										// </Link>
									)}
								</Form.Item>
							</Col>
						</Form>
					</Col>
					<Col xs={24} sm={20} md={16} xl={18} className="forgot_password_help">
						<Link to="/">
							<EdenredTypo b3>Need help?</EdenredTypo>
						</Link>
					</Col>
				</Row>
			</Col>

			<Col xs={16} sm={24} md={16} xl={14} className="forgot_password_right_area_container">
				<Row className="forgot_password_right_area" xs={16} sm={24} md={16} xl={14}>
					<Col xs={16} sm={24} md={16} xl={12} className="we_brand">
						<Col className="we_brand_text">
							<EdenredTypo bold>We</EdenredTypo>
						</Col>
						<Col className="we_brand_text">
							<EdenredTypo bold>Brand</EdenredTypo>
						</Col>
						<Col>
							<ImageComponent src={images.MultiDevices} className="laptop_img" />
						</Col>
					</Col>
					<Col xs={16} sm={24} md={16} xl={12} className="are_new">
						<Col className="are_new_text">
							<EdenredTypo bold>Are</EdenredTypo>
						</Col>
						<Col className="are_new_text">
							<EdenredTypo bold>New</EdenredTypo>
						</Col>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ForgotPasswordPresentational;
