import React from 'react';
import Teamspermissionpresentational from './teams-permission-presentational';

const Teamspermissionfunctional = () => {
	return (
		<div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
			<Teamspermissionpresentational />
		</div>
	);
};

export default Teamspermissionfunctional;
