import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import Axios from 'axios';

export function* employeesBalanceService(employeeId) {
	console.log('empid', employeeId);
	console.log('API Call');
	const employeeID = employeeId.employeeId;
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		console.log('getLeaveBalanceSuccess');
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/${employeeID}/details`;
		console.log('uri', uri);
		const res = yield call(Axios.get, uri);
		console.log('foractive getLeaveBalanceSuccess', res);
		yield put(Actions.creators.leaveBalanceSuccess(res.data));
	} catch (err) {
		console.log('getCurrenlyLeaveFail');

		yield put(Actions.creators.leaveBalanceFail(err));
	}
}
export function* employeesLeaveTypeService(action) {
	console.log('API Call');

	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		console.log('getLeaveBalanceSuccess');
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leavetype`;
		console.log('uri', uri);
		const res = yield call(Axios.get, uri);
		console.log('foractive', res);
		// yield put(Actions.creators.leaveTypeStart(action.empId));
		yield put(Actions.creators.leaveTypeSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.leaveTypeFail(err));
	}
}

export function* saveLeaveTypeService(action) {
	console.log('API Call');

	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		console.log('getLeaveBalanceSuccess');
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/save-leave-balance`;
		console.log('uri', uri);
		const res = yield call(Axios.post, uri, action?.payload);
		console.log('foractive', res);
		// yield put(Actions.creators.leaveTypeStart(action.empId));
		yield put(Actions.creators.saveLeaveTypeSuccess(res.data));
		yield put(Actions.creators.saveLeaveSucceessfulPopUp(true));
	} catch (err) {
		yield put(Actions.creators.saveLeaveTypeFail(err));
	}
}

export default function* leaveBalanceSagaWatcher() {
	console.log('worker..');
	yield takeEvery(Actions.types.GET_LEAVEBALANCE_START, employeesBalanceService);
	yield takeEvery(Actions.types.GET_LEAVETYPE_START, employeesLeaveTypeService);
	yield takeEvery(Actions.types.SAVE_LEAVETYPE_START, saveLeaveTypeService);
}
