import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Action from '@sharedComponent/app-action-reducer/action';
import { APP_VARIABLES, setupClaims } from '@constants/app-constants';
import { HrClaims } from '@constants/';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import PayrollService from 'src/services/payroll-service';
import ClevertapReact from 'src/utils/clever-tap';
import SetUpPresentational from '../components/setup-presentational';
import UploadPayrollPopup from '../../../utils/uploadPayrollPopup';
import selectors from '../selectors';
import action from '../action';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import EdenredModal from '@sharedComponent/modal';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
// import {not_focus_icons}

function SetUpFunctional({ setupLoading, setupDetails, getSetupSaga }) {
	const _payrollService = new PayrollService();
	const history = useHistory();
	const [setupSkipPopup, setSetupSkipPopup] = useState(false);
	const [isStatusCompeleted, setIsStatusCompeleted] = useState(false);
	const [isUserExists, setIsUserExists] = useState(true);
	const [fromNavbar, setFromNavbar] = useState('');
	const { hrClaims, payrollClaims, teamClaims } = useSelector((state) => state?.sharedStates?.currentUser);
	const allClaims = hrClaims?.concat(payrollClaims, teamClaims);
	const newClaims = allClaims?.map((data) => data?.value);
	const { comp_set, emp_pay, doc, leave } = setupDetails;
	const [isContinue, setIsContinue] = useState(true);

	useEffect(() => {
		getSetupSaga();
		getIsUserClaimExists();
	}, []);

	useEffect(() => {
		setFromNavbar(history?.location?.state);
	}, [history?.location?.state]);

	const getIsUserClaimExists = () => {
		let corprateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const body = {
			corprateId: corprateId,
			type: 'http://edenred.ae/claims/payroll/permission',
			value: 'Approve.Payroll.Requests',
		};
		_payrollService
			.getUserClaimsExist(body)
			.then((response) => {
				setIsUserExists(response?.data);
			})
			.catch((error) => {
				setIsUserExists(true);
			});
	};

	useEffect(() => {
		if (comp_set === 'Completed' && emp_pay === 'Completed' && doc === 'Completed' && leave === 'Completed') {
			setIsStatusCompeleted(true);
		} else {
			setIsStatusCompeleted(false);
		}
	}, [setupDetails]);

	const isClaimsExists = (allClaims, roles) => {
		return !allClaims?.includes(roles);
	};

	const isEmployeePayroll = isClaimsExists(newClaims, setupClaims?.MANAGE_EMPLOYEES) && isClaimsExists(newClaims, setupClaims?.MANAGE_PAYROLL);
	// buildingOutline
	// folderOutline
	// program2Outline
	// programOutline
	// sunfogoutline
	// userOutline

	console.log('sadada', setupDetails);

	const setupcard = [
		{
			image: focus_state_icons?.setupBuilding,
			defaultImage: not_focus_icons?.buildingOutline,
			heading: 'Company Settings',
			content: 'Create allowances, additions, deductions and set your overtime calculations.',
			status: setupDetails?.comp_set,
			isVisible: isEmployeePayroll,
			path: '/company-setup',
			cleverTap: 'Setup_Companysettings',
		},
		{
			image: focus_state_icons.setupUser,
			defaultImage: not_focus_icons?.userOutline,
			heading: 'Employee & Payroll Details',
			content: 'Automate payroll calculations by adding your employees contract information.',
			status: setupDetails?.emp_pay,
			isVisible: isEmployeePayroll,
			path: '/setupDocument',
			cleverTap: 'Setup_EmployeePayroll',
		},
		{
			image: focus_state_icons.setupPrograming,
			defaultImage: not_focus_icons?.program2Outline,
			heading: 'Roles & Permissions',
			content: 'Setup payroll and HR approvals and add other team members.',
			status: '',
			isVisible: isClaimsExists(newClaims, setupClaims?.MANAGE_ROLES),
			path: '/teamspermission',
			cleverTap: 'Setup_roles',
		},
		{
			image: focus_state_icons.setupedit,
			defaultImage: not_focus_icons?.programOutline,
			heading: 'Documents',
			content: 'Get automatic reminders for document expiry by uploading expiry dates of employees.',
			status: setupDetails?.doc,
			isVisible: isClaimsExists(newClaims, setupClaims?.MANAGE_DOCUMENTS),
			path: '/setupHrDocument',
			cleverTap: 'Setup_docsmgt',
		},
		{
			image: focus_state_icons.setupWeather,
			defaultImage: not_focus_icons?.sunfogoutline,
			heading: 'Leave Balances',
			content: "Setup your employees' leave balances to get them automatically calculated.",
			status: setupDetails?.leave,
			isVisible: isClaimsExists(newClaims, HrClaims?.APPROVAL_WORKFLOW),
			path: '/leave-approval-setup',
			cleverTap: 'Setup_leave',
		},
		{
			image: focus_state_icons.setupApproval,
			defaultImage: not_focus_icons?.userOutline,
			heading: 'Leave Approval workflow',
			content: 'Setup the leave approval workflow to manage all the requests in one place on the portal.',
			status: '',
			isVisible: isClaimsExists(newClaims, HrClaims?.APPROVAL_WORKFLOW),
			path: '/approve-workflow-setup',
			cleverTap: 'Setup_approvalworkflow',
		},
	];
	const handleSetUpCard = (value, cleverTap, setupStatus) => {
		ClevertapReact.event(cleverTap, { setupStatus });
		history.push({ pathname: value });
	};

	const handleSkipPop = () => {
		const cleverTapProperties = {
			companySettingsStatus: setupDetails?.comp_set,
			EmployeeAndPayrollDetailsStatus: setupDetails?.emp_pay,
			DocumentStatus: setupDetails?.doc,
			LeaveBalancesStatus: setupDetails?.leave,
		};
		ClevertapReact.event('Setup_continuelater', { cleverTapProperties });
		setSetupSkipPopup(true);
	};

	const handleSetupSkipPopup = (value) => {
		if (value === 'handleSkipPopUp') {
			setSetupSkipPopup(false);
			history.push({ pathname: '/landingpage', state: { status: isStatusCompeleted, fromNavbarStatus: fromNavbar } });
		} else {
			setSetupSkipPopup(false);
		}
	};
	useEffect(() => {
		var result = Object.keys(setupDetails).map((key) => setupDetails[key]);
		console.log('s3', setupcard);
		setupcard.map((res) => {
			if (res?.status == 'Missing info' || res?.status == 'No Data') {
				setIsContinue(false);
			}
		});
	}, [setupDetails]);

	console.log('sadsadsa', isContinue);
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', backgroundColor: '#F7F8FA' }}>
			<Edenredmodal
				// className="add-employee-popup"
				title={'Do You Want To Continue Later'}
				desc={
					"You are skipping the Setup process.We encourage you to include all the information to get the full experience in our portal.You will be able to continue the Setup later from the dashboard.',"
				}
				onOkay={() => handleSetupSkipPopup('handleSkipPopUp')}
				onCancelButton="Go Back"
				onOkayButton="Continue Later"
				onCancel={() => handleSetupSkipPopup('closeSkipPopUp')}
				close={() => handleSetupSkipPopup('closeSkipPopUp')}
				isOpen={setupSkipPopup}
				textcenter={true}
			/>

			<SetUpPresentational {...{ setupcard, isUserExists, handleSetUpCard, handleSkipPop, setupLoading, setupDetails, isStatusCompeleted }} />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		setupLoading: selectors.setupLoading(state),
		setupDetails: selectors.setup(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getSetupSaga: () => dispatch(action.creators.getSetup()),
		setupStatusUpdateSaga: () => dispatch(action.creators.setupStatusUpdateStart()),
		redirectToSetup: (flag) => dispatch(Action.creators.redirectToSetup(flag)),
		setUser: (roles) => dispatch(Action.creators.setUser(roles)),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(SetUpFunctional);

export default Connected;
