import React from 'react';
import SetupLeaveUploadFunctional from '../../setup-leave-approval/leave-upload-functional';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import './setupleave.scss';

const SetupleaveUpload = () => {
	return (
		<div className="setup-leave-balance-upload">
			<SetupHeader />
			<SetupLeaveUploadFunctional {...{ isleaveApproveUpload: true, isSetup: true }} />;
		</div>
	);
};

export default SetupleaveUpload;
