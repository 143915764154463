import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col } from 'antd';
import Flatpickr from 'react-flatpickr';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import 'flatpickr/dist/themes/light.css';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import {
	DATE_FORMAT,
	DOCUMENT_DOWNLOAD_CONSTANT,
	NOTIFICATION_POSITION,
	NOTIFICATION_TYPE,
	documentTypeOptions,
	expiryDateOptions,
	monthWise,
} from '@constants/app-constants';
import { checkForRules, checkTwoArraysByLooping, getBorderClassColor, openNotificationWithIcon } from '@helpers';
import Action from '@sharedComponent/app-action-reducer/action';
import ImageComponent from '@sharedComponent/image-component';
import ClevertapReact from 'src/utils/clever-tap';
import useLocalStorage from 'src/helpers/hooks';
import Selectors from '../selector';
import action from '../action';
import Expirydocument from './Expirydocument';
import Expirymodule from '../component/Expirymodule';
import DocumentSetup from '@pages/NewHrModuleSetup/DocumentsExpiry/DocumentSetup';

const Expirydocumentfunctional = ({
	counts,
	downloads,
	filterLoader,
	saveLoader,
	filterdata,
	popupVisible,
	setPopupVisible,
	documentcount,
	downloadData,
	filterData,
	saveData,
	downloadLoader,
	excelDownloadLoading,
	pdfDownloadLoading,
	savedata,
	oldFilterDetail,
	userNavigationMenu,
}) => {
	const [localFilterValues, setLocalFilterValues] = useLocalStorage('filters', { documentTypeFilter: null, expiryDateFilter: null });
	const [disablebutton, setDisableButton] = useState(false);
	const [oldValue, setOldValue] = useState(oldFilterDetail || {});
	const [show, setShow] = useState(false);
	const [detail, setDetail] = useState([]);
	const [hide, setHide] = useState(false);
	const [hrToast, setHrToast] = useState(false);
	const [visible, setvisible] = useState(false);
	const [isBlocking, setIsBlocking] = useState(false);
	const [employeesPagination, setEmployeesPagination] = useState({
		pagination: {
			current: 1,
			pageSize: 30,
		},
	});
	const [dates, setDates] = useState({
		labourCardExpiryDate: '',
		emiratesExpiryDate: '',
		visaExpiryDate: '',
		passportExpiryDate: '',
	});

	const [filterDocument, setFilterDocument] = useState({
		documentTypeFilter: null,
		expiryDateFilter: null,
		employeeIdNameFilter: '',
		pageNumber: 1,
		pageSize: 10,
		reportType: '',
		...localFilterValues,
	});

	const [saveDocument, setSaveDocument] = useState({
		citizenId: '915013560903527',
		dateOfBirth: '1976-06-01T00:00:00',
		emiratesIdNumber: '784199712345678',
		emiratesExpiryDate: '2022-10-16T00:00:00',
		employeeId: '1002',
		labourCardExpiryDate: '2022-10-16T00:00:00',
		labourCardNumber: '12345678901234',
		mobileNumber: '',
		name: 'yuva',
		passportExpiryDate: '2022-10-16T00:00:00',
		passportNumber: '123456789012',
		visaExpiryDate: '2022-10-16T00:00:00',
		visaNumber: '',
	});
	const { documentCompleted } = useSelector((state) => state?.sharedStates?.userNavigation);
	const userSubscriptionDetails = useSelector((state) => state?.sharedStates?.subscriptionProds?.[0]);
	const SORTING_VALUES = ['ascending', 'decending', 'default'];
	const FIELDS = ['Status', 'Name', 'EmployeeId', 'LabourCard', 'EID', 'Visa', 'Passport'];
	const STATE_VALUE = Object.assign(
		{},
		...FIELDS.map((value) => {
			return {
				[value]: 'ascending',
			};
		})
	);
	const CLEVER_TAP_EVENTS = Object.assign(
		{},
		...FIELDS.map((value) => {
			return {
				[value]: {
					ascending: `Sort_${value}_Ascending`,
					decending: `Sort_${value}_Decending`,
				},
			};
		})
	);

	const [sortingValues, setSortingValues] = useState(STATE_VALUE);

	const handleCleverTableClick = (value = null) => {
		value && ClevertapReact.event(value, null);
	};

	const handleClickColumn = (type) => {
		const previousValue = sortingValues[type];
		const previousIndex = SORTING_VALUES.findIndex((value) => value === previousValue);
		const index = previousIndex === 2 ? 0 : previousIndex + 1;
		const CleverTap = CLEVER_TAP_EVENTS[type];
		handleCleverTableClick(CleverTap[SORTING_VALUES[index - 1]]);
		setSortingValues({
			...sortingValues,
			[type]: SORTING_VALUES[index],
		});
	};
	const history = useHistory();

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		}, [value]);
		return ref.current;
	}

	const showdate = moment().format('YYYY-MM-DD');
	let details = filterdata?.documentExpiryList || [];
	let oldDetails = oldValue?.documentExpiryList || [];
	let totalCounts = filterdata?.totalCount || 0;
	let a = JSON.stringify(detail || []);
	let b = JSON.stringify(oldDetails || []);

	// const isModified = useMemo(() => a !== b, [dates]);
	const [isModified, modifiedData] = useMemo(() => checkTwoArraysByLooping(detail, oldDetails), [dates, detail]);

	useEffect(() => {
		setOldValue(JSON.parse(JSON.stringify(oldFilterDetail)));
	}, [oldFilterDetail]);

	useEffect(() => {
		if (details.length > 0) {
			setDetail(details);
		}
	}, [details]);

	const pickerOptions = {
		options: {
			allowInput: true,
			dateFormat: 'd/m/Y',
		},
		placeholder: `${DATE_FORMAT.DD_MM_YYYY}`,
	};

	const handlePassData = (val) => {
		let tableData = { ...val, employeeId: val?.employeeCode };
		history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '5', tableData } });
	};

	useEffect(() => {
		if (popupVisible) {
			if (detail.length > 0) {
				setFilterDocument({
					...filterDocument,
					// pageNumber: filterDocument.pageNumber - 1, // Fix for pagination issue Refer 16371
				});
			}
			setHrToast(popupVisible);
			setPopupVisible(false);
		}
	}, [popupVisible]);

	useEffect(() => {
		history?.location?.state === 'success' && setHrToast(true);
	}, [history?.location?.state]);
	const clearToastPopUp = () => setHrToast(false);

	const column = [
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'key',
			width: 15,
			sort: true,
			onHeaderCell: (column) => {
				handleClickColumn('Status');
				// return {
				// 	onClick: () => handleClickColumn('Name'),
				// };
			},
			render: (status, details, val) => {
				const filteredRules = checkForRules(details, showdate)?.filter((data) => data.rulePass);
				const leftPosition = filteredRules.length > 1 ? 48 : 35;
				return (
					<Col className="column-detail status_tooltip">
						{(filteredRules || [])?.reverse()?.map(({ img, name, value }, i, arr) => (
							<Tooltip direction="up" content={value}>
								<div className={`status_border ${name}`}>
									<ImageComponent key={i} style={{ left: leftPosition - i * 13, zIndex: 0 }} src={img} className="missingdate" />
								</div>
							</Tooltip>
						))}
					</Col>
				);
			},
		},
		{
			key: 'name',
			title: 'Name',
			width: '15%',
			onHeaderCell: (column) => {
				handleClickColumn('Name');
				// return {
				// 	onClick: () => handleClickColumn('Name'),
				// };
			},
			dataIndex: 'name',
			sort: (a, b) => a.name.localeCompare(b.name),
			render: (name, details, val, state) => {
				return (
					<Col
						className="column-detail name-detail"
						onClick={(e) => {
							handlePassData(details);
							ClevertapReact.event('Docs_EmployeeName');
						}}>
						{name}
					</Col>
				);
			},
		},
		{
			key: 'employeeId',
			title: 'Employee ID',
			dataIndex: 'employeeId',
			width: '15%',
			onHeaderCell: (column) => {
				handleClickColumn('EmployeeId');
				// return {
				// 	onClick: () => handleClickColumn('EmployeeId'),
				// };
			},
			align: 'left',
			sort: {
				compare: (a, b) => a.employeeId.localeCompare(b.employeeId),
			},
			render: (id) => {
				return (
					<Col className="column-detail" style={{ justifyContent: 'flex-start' }}>
						{id}
					</Col>
				);
			},
		},
		{
			title: 'Labour Card',
			dataIndex: 'labourCardExpiryDate',
			key: 'labourCardExpiryDate',
			width: '15%',
			onHeaderCell: (column) => {
				handleClickColumn('LabourCard');
				// return {
				// 	onClick: () => handleClickColumn('LabourCard'),
				// };
			},
			sort: {
				compare: (a, b) => new Date(a.labourCardExpiryDate) - new Date(b.labourCardExpiryDate),
			},
			render: (labourCardExpiryDate, details, val, state) => {
				return (
					<div className="custom-date-input" style={{ color: '#484b52' }}>
						<Flatpickr
							name="labourCardExpiryDate"
							className={`${getBorderClassColor(details.labourCardExpiryDate, showdate)} table-detail`}
							value={details.labourCardExpiryDate ? moment(details.labourCardExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
							onClose={(date, value) => {
								hanldePayTableData(
									moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD),
									'labourCardExpiryDate',
									details.employeeId,
									state
								);
								ClevertapReact.event(
									'Docs_Table_LabourCard',
									details.labourCardExpiryDate && moment(details.labourCardExpiryDate).format(DATE_FORMAT.DD_MM_YYYY)
								);
							}}
							{...pickerOptions}
						/>
					</div>
				);
			},
		},
		{
			title: 'Emirates ID',
			dataIndex: 'emiratesExpiryDate',
			key: 'emiratesExpiryDate',
			width: '15%',
			onHeaderCell: (column) => {
				handleClickColumn('EID');
				// return {
				// 	onClick: () => handleClickColumn('EID'),
				// };
			},
			sort: {
				compare: (a, b) => new Date(a.emiratesExpiryDate) - new Date(b.emiratesExpiryDate),
			},
			render: (emiratesExpiryDate, details, val, state) => {
				return (
					<div className="custom-date-input">
						<Flatpickr
							name="emiratesExpiryDate"
							className={`${getBorderClassColor(details.emiratesExpiryDate, showdate)} table-detail`}
							value={details.emiratesExpiryDate ? moment(details.emiratesExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
							onClose={(date, value) => {
								hanldePayTableData(
									moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD),
									'emiratesExpiryDate',
									details.employeeId,
									state
								);
								ClevertapReact.event(
									'Docs_Table_EmiratesID',
									details.emiratesExpiryDate && moment(details.emiratesExpiryDate).format(DATE_FORMAT.DD_MM_YYYY)
								);
							}}
							{...pickerOptions}
						/>
					</div>
				);
			},
		},
		{
			title: 'Visa',
			dataIndex: 'visaExpiryDate',
			key: 'visaExpiryDate',
			width: '15%',
			onHeaderCell: (column) => {
				handleClickColumn('Visa');
				// return {
				// 	onClick: () => handleClickColumn('Visa'),
				// };
			},
			sort: {
				compare: (a, b) => new Date(a.visaExpiryDate) - new Date(b.visaExpiryDate),
			},
			render: (visaExpiryDate, details, val, state) => {
				return (
					<div className="custom-date-input">
						<Flatpickr
							name="visaExpiryDate"
							className={`${getBorderClassColor(details.visaExpiryDate, showdate)} table-detail`}
							value={details.visaExpiryDate ? moment(details.visaExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
							onClose={(date, value) => {
								hanldePayTableData(
									moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD),
									'visaExpiryDate',
									details.employeeId,
									state
								);
								ClevertapReact.event(
									'Docs_Table_Visa',
									details.visaExpiryDate && moment(details.visaExpiryDate).format(DATE_FORMAT.DD_MM_YYYY)
								);
							}}
							{...pickerOptions}
						/>
					</div>
				);
			},
		},
		{
			title: 'Passport',
			dataIndex: 'passportExpiryDate',
			key: 'passportExpiryDate',
			width: '15%',
			onHeaderCell: (column) => {
				handleClickColumn('Passport');
				// return {
				// 	onClick: () => handleClickColumn('Passport'),
				// };
			},
			sort: {
				compare: (a, b) => new Date(a.passportExpiryDate) - new Date(b.passportExpiryDate),
			},
			render: (passportExpiryDate, details, val, state) => {
				return (
					<div className="custom-date-input">
						<Flatpickr
							name="passportExpiryDate"
							className={`${getBorderClassColor(details.passportExpiryDate, showdate)} table-detail`}
							value={details.passportExpiryDate ? moment(details.passportExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
							onClose={(date, value) => {
								hanldePayTableData(
									moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD),
									'passportExpiryDate',
									details.employeeId,
									state
								);
								ClevertapReact.event(
									'Docs_Table_Passport',
									details.passportExpiryDate && moment(details.passportExpiryDate).format(DATE_FORMAT.DD_MM_YYYY)
								);
							}}
							{...pickerOptions}
						/>
					</div>
				);
			},
		},
	];
	const hanldePayTableData = (value, label, emp_id, state) => {
		let data = state || [];
		let formatValue = value === 'Invalid date' ? null : value;
		if (formatValue) formatValue = moment(value, DATE_FORMAT.YYYY_MM_DD).format('yyyy-MM-DDTHH:mm:ss');
		data.map((val) => {
			if (val.employeeId === emp_id) {
				setIsBlocking(true);
				val[label] = formatValue;
				setDates(val[label]);
				setSaveDocument({
					...saveDocument,
					citizenId: val?.citizenId,
					dateOfBirth: val?.dateOfBirth,
					emiratesIdNumber: val?.emiratesIdNumber,
					emiratesExpiryDate: val?.emiratesExpiryDate,
					employeeId: val?.employeeId,
					labourCardExpiryDate: val?.labourCardExpiryDate,
					labourCardNumber: val?.labourCardNumber,
					mobileNumber: '',
					name: val?.name,
					passportExpiryDate: val?.passportExpiryDate,
					passportNumber: val?.passportNumber,
					visaExpiryDate: val?.visaExpiryDate,
					visaNumber: '',
				});
			}
		});
		setDetail([...data]);
		setDisableButton(true);
	};
	const onSearch = (employeeIdNameFilter = '', pageNumber = '') => {
		setFilterDocument({
			...filterDocument,
			employeeIdNameFilter,
			pageNumber,
		});
	};

	const filterClicked = (e) => {
		setShow(!show);
	};
	const downloadClicked = (e) => {
		setHide(!hide);
	};
	const handleClearAllFilter = () => {
		setFilterDocument({
			...filterDocument,
			documentTypeFilter: null,
			expiryDateFilter: null,
			employeeIdNameFilter: '',
			pageNumber: 1,
			pageSize: 10,
			reportType: '',
		});
		setLocalFilterValues({ documentTypeFilter: null, expiryDateFilter: null });
	};

	// const onDetaiisZero = () => {
	// 	setFilterDocument({
	// 		...filterDocument,
	// 		pageNumber: 1,
	// 	});
	// 	setFilterDocument({
	// 		...filterDocument,
	// 		pageNumber: 1,
	// 	});
	// };

	const handleEmployeesTable = (current, page) => {
		if (page === 'pageNumber') {
			setEmployeesPagination({
				...employeesPagination,
				pagination: {
					...employeesPagination.pagination,
					current: current,
				},
			});
			setFilterDocument({
				...filterDocument,
				pageNumber: current,
			});
		} else {
			setEmployeesPagination({
				...employeesPagination,
				pagination: {
					...employeesPagination.pagination,
					pageSize: current.value,
				},
			});
			setFilterDocument({
				...filterDocument,
				pageSize: current.value,
			});
		}
	};

	const totalCount = totalCounts;

	useEffect(() => {
		documentcount();
		filterData(filterDocument);
	}, [filterDocument]);

	useEffect(() => {
		if (filterdata?.documentExpiryList?.length == 0) {
			setFilterDocument({
				...filterDocument,
				pageNumber: 1,
			});
		}
	}, [filterdata?.documentExpiryList]);

	const handleDataCancel = () => {
		setvisible(true);
	};
	const closePopup = () => {
		setvisible(false);
	};

	const autoFixEmployeeFileError = () => {
		setvisible(false);
		filterData(filterDocument);
	};

	const handleDataSave = () => {
		setIsBlocking(false);
		saveData(modifiedData, filterDocument);
	};

	const savechangesMobile = (modifiedDatas) => {
		saveData(modifiedDatas, filterDocument);
		setDisableButton(false);
	};
	const closePopups = () => {
		documentcount();
		setPopupVisible(false);
	};
	const handleCancel = () => {
		filterData(filterDocument);
		setCancelButton(false);
	};
	const [cancelButton, setCancelButton] = useState(false);
	const CancelButton = () => {
		setCancelButton(true);
	};
	const handleNoCancel = () => {
		setCancelButton(false);
	};

	const downloadExcel = () => {
		downloadData({ ...filterDocument, reportType: 'excel' });
	};
	const downloadPdf = () => {
		downloadData({ ...filterDocument, reportType: 'pdf' });
	};

	const downloadDocumentType = (reportType = '') => {
		reportType && downloadData({ ...filterDocument, reportType });
	};
	const handleHrCard = (param) => {
		const value = documentStatus(param);
		const selected = !filterDocument?.expiryDateFilter?.includes(value);
		selected && setFilterDocument({ ...filterDocument, expiryDateFilter: [value], documentTypeFilter: [] });
	};
	const documentStatus = (value) => {
		switch (value) {
			case 'Expired Documents':
				return 'expired';
			case 'Expiring Soon':
				return 'in6months';
			case 'Missing Dates':
				return 'missingdates';
			case 'Up To Date':
				return 'uptodate';
			default:
				break;
		}
	};

	const handleExpiryDateDocumentType = (value, checked, isDocumentType = false, status) => {
		let expiryDateFilter = filterDocument?.expiryDateFilter || [];
		let documentTypeFilter = filterDocument?.documentTypeFilter || [];
		if (isDocumentType) {
			if (checked) {
				documentTypeFilter = (filterDocument?.documentTypeFilter || [])?.filter((data) => data !== value);
			} else {
				documentTypeFilter = [...(filterDocument?.documentTypeFilter || []), value];
			}
		} else {
			if (checked) {
				expiryDateFilter = (filterDocument?.expiryDateFilter || [])?.filter((data) => data !== value);
				if (!expiryDateFilter.length) {
					documentTypeFilter = [];
				}
			} else {
				expiryDateFilter = [...(filterDocument?.expiryDateFilter || []), value];
			}
			const isMonthWise = monthWise?.includes(value);
			if (isMonthWise) {
				const filterRestItems = monthWise.filter((data) => data !== value);
				expiryDateFilter = (expiryDateFilter || [])?.filter((data) => !filterRestItems.includes(data));
			}
		}
		setFilterDocument({ ...filterDocument, expiryDateFilter, documentTypeFilter, pageNumber: 1 });
		setEmployeesPagination({
			...employeesPagination,
			pagination: {
				...employeesPagination.pagination,
				current: 1,
			},
		});
		setLocalFilterValues({
			...localFilterValues,
			documentTypeFilter,
			expiryDateFilter,
		});
		setCleverTapEvents(value);
	};
	const setCleverTapEvents = (value) => {
		if (value === 'expired') {
			ClevertapReact.event('Filter_Expired');
		} else if (value === 'in1month') {
			ClevertapReact.event('Filter_In1month');
		} else if (value === 'in3months') {
			ClevertapReact.event('Filter_In3months');
		} else if (value === 'in6months') {
			ClevertapReact.event('Filter_In6months');
		} else if (value === 'missingdates') {
			ClevertapReact.event('Filter_MissingDates');
		} else if (value === 'labourcard') {
			ClevertapReact.event('Filter_LabourCard');
		} else if (value === 'emiratesid') {
			ClevertapReact.event('Filter_EmiratesID');
		} else if (value === 'visa') {
			ClevertapReact.event('Filter_Visa');
		} else if (value === 'passport') {
			ClevertapReact.event('Filter_Passport');
		}
	};
	const hasExpiryDateFilterData = !!filterDocument?.expiryDateFilter?.length;

	useEffect(() => {
		filterLoader && setDetail([]);
	}, [filterLoader]);

	const showEmptyFilterWarning = () =>
		openNotificationWithIcon(
			NOTIFICATION_TYPE.WARNING,
			DOCUMENT_DOWNLOAD_CONSTANT.Warning,
			DOCUMENT_DOWNLOAD_CONSTANT.PleaseSelect,
			NOTIFICATION_POSITION.BOTTOM_RIGHT
		);

	return (
		<>
			{documentCompleted !== true && (userSubscriptionDetails?.isFreeTrailOn || userSubscriptionDetails?.isSubscriptionOn === true) ? (
				<DocumentSetup />
			) : (
				<>
					<div className="web-view">
						<Expirydocument
							isBlocking={isBlocking}
							disablebutton={disablebutton}
							downloadLoading={downloadLoader}
							show={show}
							detail={detail}
							column={column}
							hide={hide}
							dates={dates}
							onSearch={onSearch}
							downloadExcel={downloadExcel}
							downloadPdf={downloadPdf}
							filterClicked={filterClicked}
							downloadClicked={downloadClicked}
							counts={counts}
							filterLoader={filterLoader}
							filterdata={filterdata}
							employeesPagination={employeesPagination}
							totalCount={totalCount}
							handleEmployeesTable={handleEmployeesTable}
							downloads={downloads}
							handleDataCancel={handleDataCancel}
							handleClearAllFilter={handleClearAllFilter}
							visible={visible}
							closePopup={closePopup}
							autoFixEmployeeFileError={autoFixEmployeeFileError}
							handleDataSave={handleDataSave}
							saveLoader={saveLoader}
							popupVisible={popupVisible}
							closePopups={closePopups}
							savedata={savedata}
							{...{
								expiryDateOptions,
								handleExpiryDateDocumentType,
								filterDocument,
								hasExpiryDateFilterData,
								documentTypeOptions,
								downloadDocumentType,
								handleClearAllFilter,
								excelDownloadLoading,
								pdfDownloadLoading,
								isModified,
								showEmptyFilterWarning,
								userNavigationMenu,
								hrToast,
								handleHrCard,
								clearToastPopUp,
							}}
						/>
					</div>
					<div className="mob-view" style={{ paddingBottom: 60 }}>
						<Expirymodule
							onSearch={onSearch}
							details={detail}
							counts={counts}
							filterLoader={filterLoader}
							filterdata={filterdata}
							downloads={downloads}
							downloadClicked={downloadClicked}
							downloadOnlyExcell={downloadExcel}
							filterData={filterData}
							setFilterDocument={setFilterDocument}
							filterDocument={filterDocument}
							detail={detail}
							handleCancel={handleCancel}
							CancelButton={CancelButton}
							handleNoCancel={handleNoCancel}
							cancelButton={cancelButton}
							savechanges={savechangesMobile}
							{...{
								expiryDateOptions,
								handleExpiryDateDocumentType,
								filterDocument,
								hasExpiryDateFilterData,
								documentTypeOptions,
								downloadDocumentType,
								handleClearAllFilter,
								totalCounts,
								setFilterDocument,
								downloadPdf,
								oldDetails,
								saveLoader,
								showEmptyFilterWarning,
							}}
						/>
					</div>
				</>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		countLoader: Selectors.loading(state),
		counts: Selectors.employeedetail(state),
		counterror: Selectors.error(state),
		downloadLoader: Selectors.downloadLoading(state),
		downloads: Selectors.downloadDetail(state),
		downloaderror: Selectors.downloadError(state),
		filterLoader: Selectors.filterLoading(state),
		filterdata: Selectors.filterDetail(state),
		documentExpiryList: Selectors.documentExpiryList(state),
		oldFilterDetail: Selectors.oldFilterDetail(state),
		filterError: Selectors.filterError(state),
		saveLoader: Selectors.saveLoading(state),
		savedata: Selectors.saveDetail(state),
		saveError: Selectors.saveError(state),
		popupVisible: Selectors.popupVisible(state),
		excelDownloadLoading: Selectors.excelDownloadLoading(state),
		pdfDownloadLoading: Selectors.pdfDownloadLoading(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		documentcount: () => dispatch(action.creators.getEmployeeCountStart()),
		downloadData: (downloadDocument) => dispatch(action.creators.saveDownloadStart(downloadDocument)),
		filterData: (filterDocument) => dispatch(action.creators.saveFilterDataStart(filterDocument)),
		saveData: (modifiedData, filterDocument) => dispatch(action.creators.saveButtonStart(modifiedData, filterDocument)),
		setPopupVisible: (flag) => dispatch(action.creators.setPopupVisible(flag)),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Expirydocumentfunctional);

export default Connected;
