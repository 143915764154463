import React from 'react';
import DocumentExpiry from './DocumentExpiry';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import './index.scss';

const DocumentsExpiry = () => {
	return (
		<main className="page-wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<DocumentExpiry navigate="/new-hr-features/preview-upload-document" cleverTap={false} />
			</div>
		</main>
	);
};

export default DocumentsExpiry;
