import { DOMAIN_URL } from '@constants/app-constants';
import Axios from 'axios';

class RoutingService{

    baseUrl = `${DOMAIN_URL}/Routing`;

    async getRouting() {
        return Axios.get(`${this.baseUrl}`);
    }

}

export default RoutingService;