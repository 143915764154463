import React, { useState, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { APP_VARIABLES } from '@constants/app-constants';
import LandingNewPresentational from './new-landing-presentational';
import { emprange, corpData, testGroup } from './landingpage-constant';
import ClevertapReact from 'src/utils/clever-tap';

const LandingNewFunctional = () => {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const history = useHistory();
	const [range, setRange] = useState('1-50');

	const {
		plus = 0,
		monthlyFee,
		group,
	} = useMemo(() => {
		const { group, monthlyFee } = corpData?.find((data) => parseInt(data?.corpatae) === parseInt(corporateId));
		const plus = testGroup[group].filter((data) => data?.range === range).map((data) => parseFloat(data?.rate) + parseFloat(monthlyFee));
		return {
			group,
			monthlyFee,
			plus,
		};
	}, [range]);

	const incrementdecrement = (type) => {
		if (type === 'increment') {
			ClevertapReact.event('HR_LP_EmpPlus', { group });
		}
		if (type === 'decrement') {
			ClevertapReact.event('HR_LP_EmpMinus');
		}
		let find = emprange.indexOf(range);
		type === 'increment' && (find = find + 1);
		type === 'decrement' && (find = find - 1);
		find >= 0 && find < 5 && setRange(emprange[find]);
	};
	const onClickable = (flag) => {
		if (flag === 'header') {
			ClevertapReact.event('HR_LP_GetDiscount');
		}
		if (flag === 'plus') {
			ClevertapReact.event('HR_LP_PricingUpgrade');
		}
		history.push({
			pathname: '/landingNew/billing',
			state: group,
		});
	};
	const icons = [
		{
			image: not_focus_icons.CardImage1,
			heading: 'Automate your overtime calculations',
		},
		{
			image: not_focus_icons.CardImage2,
			heading: 'Generate department- wise payroll reports',
		},
		{
			image: not_focus_icons.CardImage3,
			heading: 'Access employee documents wherever you are',
		},
		{
			image: not_focus_icons.CardImage4,
			heading: 'Configure approval workflow for your leave requests',
		},
		{
			image: not_focus_icons.CardImage6,
			heading: 'Managers can check overlapping leaves to manage workforce',
		},
		{
			image: not_focus_icons.CardImage5,
			heading: 'Managers can see who is on leave from their team',
		},
	];
	const imageText = [
		{
			image: not_focus_icons.VectorTick,
			text: 'Digitalised Payroll Process',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Run payroll for C3Pay & bank employees (WPS & Non WPS)',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Easy-to-use C3Pay payroll cards',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Free Unlimited Salary Processing for Bank Accounts',
			flag: 'wrong',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Manage Overtime Pay',
			flag: 'wrong',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Manage Additions and Deductions',
			flag: 'wrong',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Generate Payslips',
			flag: 'wrong',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Leave Management',
			flag: 'wrong',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Document Management',
			flag: 'wrong',
		},
		{
			image: not_focus_icons.VectorWrong,
			text: 'Live Announcements',
			flag: 'wrong',
		},
	];
	const secondContainer = [
		{
			image: not_focus_icons.VectorTick,
			text: 'Digitalised Payroll Process',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Run payroll for C3Pay & bank employees (WPS & Non WPS)',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Easy-to-use C3Pay payroll cards',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Free Unlimited Salary Processing for Bank Accounts',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Manage Overtime Pay',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Manage Additions and Deductions',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Generate Payslips',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Leave Management',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Document Management',
			flag: 'correct',
		},
		{
			image: not_focus_icons.VectorTick,
			text: 'Live Announcements',
			flag: 'correct',
		},
	];
	const cardLists = [
		{
			image: not_focus_icons?.BlackTick,
			text: 'Request Leaves',
		},
		{
			image: not_focus_icons?.BlackTick,
			text: 'Download Payslips',
		},
		{
			image: not_focus_icons?.BlackTick,
			text: 'Receive Announcements',
		},
	];
	const myRef = useRef(null);
	const upgradeDiv = () => {
		ClevertapReact.event('HR_LP_HeroUpgrade');
		myRef.current.scrollIntoView();
	};
	return (
		<>
			<LandingNewPresentational
				{...{
					icons,
					imageText,
					secondContainer,
					incrementdecrement,
					range,
					monthlyFee,
					plus,
					onClickable,
					upgradeDiv,
					myRef,
					cardLists,
					group,
				}}
			/>
		</>
	);
};

export default LandingNewFunctional;
