import React, { useState } from 'react';
import Medicalinsurancepresentational from './medical-insurance-presentational';
import './medical.scss';
import PayrollService from 'src/services/payroll-service';
import { useHistory } from 'react-router-dom';
const Medicalinsurancefunctional = () => {
	const history = useHistory();
	const _payrollService = new PayrollService();
	const [isSelect, setIsSelect] = useState({
		existing: true,
		new: false,
	});
	const [data, setData] = useState({
		Name: '',
		PhoneNumber: '',
		Renewal: '',
	});
	const handleSelect = (value) => {
		if (value === 'existing') {
			setIsSelect({ ...isSelect, existing: true, new: false });
		} else {
			setIsSelect({ ...isSelect, existing: false, new: true });
		}
	};
	const Handledata = (param, value) => {
		setData({ ...data, [param]: value });
	};

	const onFinish = () => {
		// setloading(true);
		let body = {
			// corporateId: getLocalvariable(APP_VARIABLES.CORPORATE_ID),
			name: data.Name,
			telephoneNumber: data.PhoneNumber,
			renewalMonth: data.Renewal,
		};
		let params = {};
		_payrollService
			.insuranceSignup(body, params)
			.then((res) => {
				// setloading(false);
				history.push('/setup');
				// setThankyou(true);
			})
			.catch((err) => {
				// setloading(false);
			});
	};
	return (
		<div>
			<Medicalinsurancepresentational isSelect={isSelect} handleSelect={handleSelect} Handledata={Handledata} onFinish={onFinish} data={data} />
		</div>
	);
};

export default Medicalinsurancefunctional;
