import React from 'react';
import SetupHrDocumentFunctional from './components/setup-hrdocument-functional';
function SetupHrDocumentMain() {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', background: ' #F7F8FA' }}>
			<SetupHrDocumentFunctional />
		</div>
	);
}

export default SetupHrDocumentMain;
