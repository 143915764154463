import React, { useState } from 'react';
import EdenredCarousel from '../EdenredCarousel';
import { carouselImage } from './silderContent';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './imageSlider.scss';

const ImageSlider = () => {
	const [count, setCount] = useState(1);
	const length = carouselImage?.length;
	const handleButton = (value) => {
		if (value === 'prev') {
			setCount(count === 0 ? length - 1 : count - 1);
		} else {
			setCount(count === length - 1 ? 0 : count + 1);
		}
	};
	return (
		<div className="imae_slider_main">
			<EdenredCarousel
				showPagination={false}
				pagination={count}
				prevButton={
					<div className="left-img" onClick={() => handleButton('prev')}>
						<img src={not_focus_icons?.Left} />
					</div>
				}
				nextButton={
					<div className="right-img" onClick={() => handleButton('next')}>
						<img src={not_focus_icons?.Right} />
					</div>
				}>
				{carouselImage?.map((data) => (
					<div className="image_slider">
						<div className="main_carousel">
							<img src={data?.image} />
						</div>
					</div>
				))}
			</EdenredCarousel>
		</div>
	);
};

export default ImageSlider;
