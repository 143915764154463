export const payrollTable ={
			employees: [
			  {
				employeeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				employeeName: "pranth",
				corporateId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				salary: 110,
				variablePay: 110,
				daysOnLeave: 110,
				endOfService: true,
				payrollChannel: "string",
				accountType: "string",
				establishmentId: "string",
				bankName: "absjg",
				isSelected:false
			  }
			]
		  }


export const AccountType = {
	data: [
		{
			label: 'C3 Pay Card',
			isSelected: false,
			value: 'C3 Pay Card',
		},
		{
			label: 'Bank Account',
			value: 'Bank Account',
			isSelected: false,
		},
	],
};

export const PayrollChannelList = {
	data: [
		{
			label: 'Wps',
			isSelected: false,
			value: 'Wps',
		},
		{
			label: 'Non Wps',
			isSelected: false,
			value: 'Non Wps',
		},
	],
};

export const BankNameList = {
	data: [
		{
			label: 'Mashreq',
			isSelected: false,
			value: 'Mashreq',
		},
		{
			label: 'Emirates NBD',
			isSelected: false,
			value: 'Emirates NBD',
		},
		{
			label: 'ADIB',
			isSelected: false,
			value: 'ADIB',
		},
		{
			label: 'Citibank',
			isSelected: false,
			value: 'Citibank',
		},
		{
			label: 'Union National Bank',
			isSelected: false,
			value: 'Union National Bank',
		},
	],
};

export const establishmentIds = {
	data: [
		{
			label: 'GLB-000012394719',
			isSelected: false,
			value: 'GLB-000012394719',
		},
		{
			label: 'GLB-000012394718',
			isSelected: false,
			value: 'GLB-000012394718',
		},
		{
			label: 'GLB-000012394717',
			isSelected: false,
			value: 'GLB-000012394717',
		},
		{
			label: 'GLB-000012394716',
			isSelected: false,
			value: 'GLB-000012394716',
		},
		{
			label: 'GLB-000012394715',
			isSelected: false,
			value: 'GLB-000012394715',
		},
		{
			label: 'GLB-000012394714',
			isSelected: false,
			value: 'GLB-000012394714',
		},
		{
			label: 'GLB-0000000123456',
			isSelected: false,
			value: 'GLB-0000000123456',
		},
	],
};

export const filterLookUpData = {
	payrollChannels: [
		{
			payrollChannelName: 'WPS',

		},
		{
			payrollChannelName: 'Non Wps',

		},
	],
	accountTypes: [
		{
			accountTypeCode: '',
			accountTypeName: 'C3Pay Card',

		},
		{
			accountTypeCode: '',
			accountTypeName: 'Bank Account',

		},
	],
	bankNames: [
		{
			bankName: 'Mashreq',

		},
		{
			bankName: 'Emirates NBD',

		},
		{
			bankName: 'ADIB',

		},
	],
	establishments: [
		{
			establishmentId: 'GLB - 0000000123456',

		},
		{
			establishmentId: 'GLB - 0000000123456',

		},

	],
};
