// once we work on the functionality, the selectors will be created here
// sample code

import { createSelector } from 'reselect';

import { initState } from './reducer';

const sharedStates = (state) => state.sharedStates || initState;
const roles = createSelector(sharedStates, (cnter) => cnter.roles);
const rolesLoading = createSelector(sharedStates, (cnter) => cnter.rolesLoading);
const claims = createSelector(sharedStates, (cnter) => cnter.claims);
const currentUser = createSelector(sharedStates, (cnter) => cnter.currentUser);
const subscriptionProds = createSelector(sharedStates, (cnter) => cnter.subscriptionProds);
const currentUserDetails = createSelector(sharedStates, (cnter) => cnter.currentUserDetails);
const corporateIds = createSelector(sharedStates, (cnter) => cnter.corporateIds);
const corporateLoading = createSelector(sharedStates, (cnter) => cnter.corporateLoading);
const corporateAPIStatus = createSelector(sharedStates, (cnter) => cnter.corporateAPIStatus);
const userNavigation = createSelector(sharedStates, (cnter) => cnter.userNavigationLoad);
const userNavigationData = createSelector(sharedStates, (cnter) => cnter.userNavigation);
const setupStatus = createSelector(sharedStates, (cnter) => cnter.setupStatus);
const setupStatusDetail = createSelector(sharedStates, (cnter) => cnter.setupStatusDetail);
const redirectStatus = createSelector(sharedStates, (cnter) => cnter.redirectStatus);
const isRakbankLoading = createSelector(sharedStates, (cnter) => cnter.isRakbankLoading);
const rakbankdetails = createSelector(sharedStates, (cnter) => cnter.rakbankdetails);

export default {
	sharedStates,
	roles,
	rolesLoading,
	claims,
	currentUser,
	subscriptionProds,
	currentUserDetails,
	corporateIds,
	corporateLoading,
	corporateAPIStatus,
	userNavigation,
	userNavigationData,
	setupStatus,
	setupStatusDetail,
	redirectStatus,
	isRakbankLoading,
	rakbankdetails,
};
