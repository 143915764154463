const GET_LEAVETOAPPROVE_START = 'GET_LEAVETOAPPROVE_START';
const GET_LEAVETOAPPROVE_SUCCESS = 'GET_LEAVETOAPPROVE_SUCCESS';
const GET_LEAVETOAPPROVE_FAIL = 'GET_LEAVETOAPPROVE_FAIL';
const GET_OVERLAPPING_LEAVES_START = 'GET_OVERLAPPING_LEAVES_START';
const GET_OVERLAPPING_LEAVES_SUCCESS = ' GET_OVERLAPPING_LEAVES_SUCCESS';
const GET_OVERLAPPING_LEAVES_FAIL = ' GET_OVERLAPPING_LEAVES_FAIL';
const GET_APPROVEREJECT_START = 'GET_APPROVEREJECT_START';
const GET_APPROVEREJECT_SUCCESS = 'GET_APPROVEREJECT_SUCCESS';
const GET_APPROVEREJECT_FAIL = 'GET_APPROVEREJECT_FAIL';

const getLeaveToApproveStart = () => {
	return {
		type: GET_LEAVETOAPPROVE_START,
	};
};

const getLeaveToApproveSuccess = (response) => {
	return {
		type: GET_LEAVETOAPPROVE_SUCCESS,
		response,
	};
};

const getLeaveToApproveFail = (error) => {
	return {
		type: GET_LEAVETOAPPROVE_FAIL,
		error,
	};
};

const getOverLappingLeavesStart = (post) => {
	return {
		type: GET_OVERLAPPING_LEAVES_START,
		post,
	};
};
const getOverLappingLeavesSuccess = (response) => {
	return {
		type: GET_OVERLAPPING_LEAVES_SUCCESS,
		response,
	};
};
const getOverLappingLeavesFail = (error) => {
	return {
		type: GET_OVERLAPPING_LEAVES_FAIL,
		error,
	};
};

const getApproveStart = (Data) => {
	return {
		type: GET_APPROVEREJECT_START,
		Data,
	};
};
const getApproveSuccess = (response) => {
	return {
		type: GET_APPROVEREJECT_SUCCESS,
		response,
	};
};
const getApproveFail = (error) => {
	return {
		type: GET_APPROVEREJECT_FAIL,
		error,
	};
};
export default {
	types: {
		GET_LEAVETOAPPROVE_START,
		GET_LEAVETOAPPROVE_SUCCESS,
		GET_LEAVETOAPPROVE_FAIL,
		GET_OVERLAPPING_LEAVES_START,
		GET_OVERLAPPING_LEAVES_SUCCESS,
		GET_OVERLAPPING_LEAVES_FAIL,
		GET_APPROVEREJECT_START,
		GET_APPROVEREJECT_SUCCESS,
		GET_APPROVEREJECT_FAIL,
	},
	creators: {
		getLeaveToApproveStart,
		getLeaveToApproveSuccess,
		getLeaveToApproveFail,
		getOverLappingLeavesStart,
		getOverLappingLeavesSuccess,
		getOverLappingLeavesFail,
		getApproveStart,
		getApproveSuccess,
		getApproveFail,
	},
};
