import { APP_VARIABLES, DOMAIN_URL, VERSION } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';
import Axios from 'axios';

class UserService {
	baseUrl = `${DOMAIN_URL}/Users`;
	setupUrl = `${DOMAIN_URL}/SetupStatus`;
	//? /* GET */
	getUserClaims = (userName) => Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${userName}/permission`);
	getAllClaimsApi = () => Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/all-claims`);
	getSetupStatus = () => Axios.get(`${this.setupUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`);
	getRolesId = () => Axios.get(`${DOMAIN_URL}/Roles`);
	requestNewPassword = (userName) => {
		return Axios.post(`${this.baseUrl}/${userName}/actions/request-reset-password`);
	};

	async getDuplicatestatusApi(userName) {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${userName}/check-duplicate`);
	}

	baseUrlUserNavigation = `${DOMAIN_URL}`;

	postUserNavigationMenu = (payload) => {
		return Axios.post(`${this.baseUrlUserNavigation}/UserMenuNavigationStatus/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, payload);
	};
}

export default UserService;
