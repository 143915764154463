const MFA_LOADING = "MFA_LOADING"
const MFA_PROCESSING = "MFA_PROCESSING"
const OTP_LOADING = "OTP_LOADING"
const UPATE_NUMBER_LOADING = "UPATE_NUMBER_LOADING"
const CHECK_MFA = "CHECK_MFA"
const SET_MFA_RESPONSE = "SET_MFA_RESPONSE"
const CLEAR_MFA_RESPONSE = "CLEAR_MFA_RESPONSE"
const VERIFY_MFA = "VERIFY_MFA"
const VERIFY_OTP = "VERIFY_OTP"
const SEND_OTP = "SEND_OTP"
const SET_OTP_RESPONSE = "SET_OTP_RESPONSE"
const CLEAR_OTP_RESPONSE = "CLEAR_OTP_RESPONSE"
const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER"
const VERIFY_UPDATE_PHONE_NUMBER = "VERIFY_UPDATE_PHONE_NUMBER"
const MFA_ERROR = "MFA_ERROR"
const UPDATE_PHONE_NUMBER_ERROR = "UPDATE_PHONE_NUMBER_ERROR"
const CLEAR_ERROR = "CLEAR_ERROR"

export const mfaLoading = (payload) => ({
    type: MFA_LOADING,
    payload,
});
export const mfaProcessing = (payload) => ({
    type: MFA_PROCESSING,
    payload,
});
export const otpLoading = (payload) => ({
    type: OTP_LOADING,
    payload,
});
export const updatePhoneNumberLoading = (payload) => ({
    type: UPATE_NUMBER_LOADING,
    payload,
});
export const checkingMfa = (payload, token) => ({
    type: CHECK_MFA,
    payload,
    token
});
export const settingMfa = (payload) => ({
    type: SET_MFA_RESPONSE,
    payload,
});
export const clearMfaData = (payload) => ({
    type: CLEAR_MFA_RESPONSE,
    payload,
});
export const verifyMfa = (payload) => ({
    type: VERIFY_MFA,
    payload,
});
export const verifyOtp = (payload) => ({
    type: VERIFY_OTP,
    payload,
});
export const sendOtp = (payload) => ({
    type: SEND_OTP,
    payload,
});
export const setOtpData = (payload) => ({
    type: SET_OTP_RESPONSE,
    payload,
});
export const clearOtpData = (payload) => ({
    type: CLEAR_OTP_RESPONSE,
    payload,
});
export const updatePhoneNumber = (payload) => ({
    type: UPDATE_PHONE_NUMBER,
    payload,
});
export const verifyUpdatePhoneNumber = (payload) => ({
    type: VERIFY_UPDATE_PHONE_NUMBER,
    payload,
});
export const mfaError = (payload) => ({
    type: MFA_ERROR,
    payload,
});
export const updatePhoneNumberError = (payload) => ({
    type: UPDATE_PHONE_NUMBER_ERROR,
    payload,
});
export const clearError = (payload) => ({
    type: CLEAR_ERROR,
    payload,
});

export default {
    types: {
        MFA_LOADING,
        MFA_PROCESSING,
        OTP_LOADING,
        UPATE_NUMBER_LOADING,
        CHECK_MFA,
        SET_MFA_RESPONSE,
        CLEAR_MFA_RESPONSE,
        VERIFY_MFA,
        VERIFY_OTP,
        SEND_OTP,
        SET_OTP_RESPONSE,
        CLEAR_OTP_RESPONSE,
        UPDATE_PHONE_NUMBER,
        VERIFY_UPDATE_PHONE_NUMBER,
        MFA_ERROR,
        UPDATE_PHONE_NUMBER_ERROR,
        CLEAR_ERROR
    },
    creators: {
        mfaLoading,
        mfaProcessing,
        otpLoading,
        updatePhoneNumberLoading,
        checkingMfa,
        settingMfa,
        clearMfaData,
        verifyMfa,
        verifyOtp,
        sendOtp,
        setOtpData,
        clearOtpData,
        updatePhoneNumber,
        verifyUpdatePhoneNumber,
        mfaError,
        updatePhoneNumberError,
        clearError
    }
}