import React from 'react';
import { connect } from 'react-redux';
import { withReducer } from 'src/store/reducerLoader';
import Cardholder from './Card-holder';
import reducer from './reducer';
import sagas from './saga/index';
import Selectors from './selector';
import Actions from './action';

const BankAccountfunctional = () => {
	return <Cardholder />;
};

const mapStateToProps = (state) => {
	return {
		isbankAccountPopUP: Selectors.isbankAccountPopUP(state),
		bankName: state.appReducer.bankName,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		postBankAccountSuccess: (initial) => dispatch(Actions.creators.postBankAccountSuccess(initial)),
		bankNameDropDown: () => dispatch(Actions.creators.getBankNameStart()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(BankAccountfunctional);
const ReducedScreen = withReducer('appReducer', reducer, sagas)(Connected);
export default ReducedScreen;
