import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import './step.scss';
const Step = ({
	iconSrc,
	descriptionText,
	btnText,
	className = '',
	btnType,
	btnClassName,
	btnOnClick,
	btnLeftIcon = '',
	btnRightIcon = '',
	stepNo = 1,
	isBtnLoading,
}) => {
	return (
		<main className="step-wrapper">
			<section className="step-title">Step {stepNo}:</section>
			<section className={`description-wrapper ${className}`}>
				<div className="description">
					{iconSrc && <ImageComponent src={iconSrc} className="description-icon" />}
					{descriptionText && <ETypo B2>{descriptionText}</ETypo>}
				</div>
				{btnText && (
					<span className={isBtnLoading ? 'btnStyle' : ''}>
						<EdenredButtons
							btnType={btnType}
							children={btnText}
							className={btnClassName}
							onClick={btnOnClick}
							leftIcon={btnLeftIcon}
							rightIcon={btnRightIcon}
							loading={isBtnLoading}
						/>
					</span>
				)}
			</section>
		</main>
	);
};

export default Step;
