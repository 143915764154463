import './more-submenu.scss';

import { Col, Row } from 'antd';

import { APP_VARIABLES } from '@constants/app-constants';
import Actions from '../../components/shared/app-action-reducer/action';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { Link } from 'react-router-dom';
import React from 'react';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect } from 'react-redux';
import { icons } from 'src/assets/icons';
import { roles } from '@constants/';
import userManager from 'src/config/user-manager';
import { TeamsClaim } from '@constants/';
import { checkWithClaims } from '@helpers';

// import { checkWithClaims } from '@helpers';
// import { userClaims } from '@constants/';

const MoreSubMenu = ({ currentUser, removeUser }) => {
	const role = (currentUser && currentUser.roles && currentUser.roles[0].name) || null;
	const teamsClaims = (currentUser && currentUser?.teamClaims) || [];
	// const claims = (currentUser && currentUser.claims) || [];
	const isTeamsExists = checkWithClaims(teamsClaims, TeamsClaim?.MANAGE_ROLE) || false;
	const more_submenus = [
		{
			icon: icons.not_focus.User,
			content: 'My Profile',
			path: '/dashboard/profile',
		},
		isTeamsExists && {
			icon: icons.not_focus.Approvals,
			content: 'Team Permissions',
			path: '/manage_team',
		},
		{
			icon: icons.not_focus.Info,
			content: 'Company',
			path: '/company/information',
		},
		{
			icon: icons.not_focus.Help,
			content: 'Help',
			path: '/help',
		},
		{
			icon: icons.not_focus.Health,
			content: 'Health Insurance',
			path: '/healthinsurance',
		},
		{
			icon: icons.not_focus.SwitchOff,
			content: 'Log Out',
			path: '/signin-oidc',
		},
	];

	const handleLogout = () => {
		removeUser();
		localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
		userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
		userManager.removeUser(); // removes the user data from sessionStorage
		localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
		localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
		localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
		localStorage.removeItem('amount');
		localStorage.removeItem('transferFundsPopupVisible');
		localStorage.removeItem('showUnemploymentBanner');
	};
	return (
		<Row className="more_SubMenuPage_Container">
			<EdenredHeader />
			<Col xs={24} sm={24} className="SubMenuPage">
				<Row className="SubMenuPage_card">
					<Col sm={24} xs={24} className="SubMenuPage_card_content">
						<Col sm={24} xs={24} style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
							<Col className="SubMenuPage_link_horizontal"></Col>
						</Col>

						{more_submenus.map(
							(moreSubmenu, index) =>
								moreSubmenu !== false && (
									<>
										<Link
											to={moreSubmenu.path}
											key={index}
											onClick={() => {
												moreSubmenu.content === 'Log Out' && handleLogout();
											}}>
											<Row className="submenu_item_card_row">
												<Col xs={4} sm={3} className="submenuitem_logo">
													<ImageComponent src={moreSubmenu.icon} />
												</Col>
												<Col xs={18} sm={18} className="submenuitem_title">
													<EdenredTypo>{moreSubmenu.content}</EdenredTypo>
												</Col>
												<Col xs={2} sm={2} className="right_arrow">
													<ImageComponent src={icons.not_focus.Right} />
												</Col>
											</Row>
										</Link>
										{index !== more_submenus.length - 1 ? <Col xs={24} sm={24} className="horizontal_line"></Col> : null}
									</>
								)
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: SelectorsApp.currentUser(state),
		user: state.oidc.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		removeUser: () => dispatch(Actions.creators.removeUser()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreSubMenu);
