import React from 'react';
import { Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';

function IndividualEmployeeOrderCard() {
	const history = useHistory();
	const { state } = useLocation();
	const basicEmployeeDetails = {
		...state,
		mobileNumber: '+971 ' + state.mobileNumber,
	};

	const C3PayCardOrBankAccount = (orderType) => {
		history.push({
			pathname: '/employees/add/individual/employee/select/channel/type',
			state: {
				...basicEmployeeDetails,
				orderType: orderType,
			},
		});
	};
	return (
		<>
			<EdenredHeader />
			<Row className="individual_employee_order_card_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="individual_employee_order_card_title">
					<EdenredTypo>Do You Want To Order A C3Pay Card For {basicEmployeeDetails && basicEmployeeDetails.firstName ? basicEmployeeDetails.firstName : ''}?</EdenredTypo>
				</Col>
				<Col xl={22} lg={22} md={22} sm={24} xs={24} className="individual_employee_order_card_description">
					<EdenredTypo>
						Please select “No” Only if {state.firstName || ''} has a bank account that you will transfer salaries to.
					</EdenredTypo>
				</Col>
				<Row className="order_card_or_bank_account_button_section">
					<Col xl={8} lg={11} md={12} sm={12} xs={12} className="redirect_to_bank_account_button">
						<EdenredButton noShadow={true} type={'outline-g'} onClick={() => C3PayCardOrBankAccount('Bank Account')}>
							No
						</EdenredButton>
					</Col>
					<Col xl={8} lg={11} md={12} sm={12} xs={12} className="order_card_button">
						<EdenredButton onClick={() => C3PayCardOrBankAccount('C3PayCard')}>Yes</EdenredButton>
					</Col>
				</Row>
			</Row>
		</>
	);
}

export default IndividualEmployeeOrderCard;
