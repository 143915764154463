import React from 'react';
import { useHistory } from 'react-router-dom';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import DividerComponent from '@pages/DesignSystem/Divider';
import AddIndividualHeader from '@pages/employees/add-individual-header/add-individual-header';
import { icons } from 'src/assets/icons';
import './add-bulk-individual-employee.scss';
import ClevertapReact from 'src/utils/clever-tap';

const AddBulkIndividualEmployeeCard = () => {
	const history = useHistory();
	const addBulkIndividualDetail = [
		{
			id: 1,
			image: icons.focus_state.addIndividual,
			name: 'Add Individual Employee',
			desc: 'A new employee joined your company recently? Add his/her details here.',
			path: '/employee/add-individual-employee',
			CleverTapEventName: 'Add_IndividualEmployees',
		},
		{
			id: 2,
			image: icons.focus_state.addBulk,
			name: 'Add Employee(s) with a Spreadsheet',
			desc: 'Use our spreadsheet template to add a list of new employees',
			path: '/employees/add/multiple/employees/upload',
			CleverTapEventName: 'Add_BulkEmployees',
		},
	];
	return (
		<div className="add_bulk_individual_container">
			<AddIndividualHeader {...{ handleBackButton: () => history.push('/employees/manage_employee') }} />
			<div className="add_bulk_individual_card">
				{addBulkIndividualDetail?.map((data) => (
					<>
						<div
							key={data?.id}
							className="add_bulk_individual_inner_card"
							onClick={() => {
								ClevertapReact.event(data.CleverTapEventName, null);
								history.push(data?.path);
							}}>
							<div className="add_bulk_individual_card_img">
								<ImageComponent src={data?.image} />
							</div>
							<div className="add_bulk_individual_card_content">
								<ETypo medium b1>
									{data?.name}
								</ETypo>
								<ETypo light b1 color="#484B52" style={{ marginTop: '4px' }}>
									{data?.desc}
								</ETypo>
							</div>
							<div className="add_bulk_individual_card_arrow">
								<ImageComponent src={icons.not_focus.Right} />
							</div>
						</div>
						{addBulkIndividualDetail?.length > data?.id && <DividerComponent />}
					</>
				))}
			</div>
		</div>
	);
};

export default AddBulkIndividualEmployeeCard;
