import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import Actions from '../OverTimeAction';

export function* getOverTimePercentService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/overtimestandardpercentage`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getOvertimePolicyPercentageSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getOvertimePolicyPercentageFail(err));
	}
}
export function* getOverTimeDataService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/overtimepolicy`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getOverTimeDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getOverTimeDataFail(err));
	}
}
export function* getOverTimeSalaryDataService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/overtimesalaryperday`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getOverTimeSalaryPerDaySuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getOverTimeSalaryPerDayFail(err));
	}
}
export function* saveOverTimeDataService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const payload = {
		overtimeSalaryPerDayType: {
			salaryType: action.overtimeData?.salaryType,
			days: action.overtimeData?.days,
			hoursADay: parseInt(action.overtimeData?.hoursADay),
		},
		overtimePolicies: [
			action.overtimePolicies?.map((data) => {
				return {
					overtimeType: data?.overtimeType,
					standardPercentage: data?.standardPercentage,
					id: data?.id,
				};
			}),
		][0],
	};
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/overtimepolicy`;
		const res = yield call(Axios.post, uri, payload, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
		yield put(Actions.creators.saveOverTimeSuccess(res.data));
		yield put(Actions.creators.getOverTimeData());
	} catch (err) {
		yield put(Actions.creators.saveOverTimeFail(err));
	}
}
export function* companySettingService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/corporatesetting`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getCompanySettingSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getCompanySettingFail(err));
	}
}
export function* saveCompanySettingService(data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const types = data?.data;
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/corporatesetting`;
		const res = yield call(Axios.post, uri, types);
		yield put(Actions.creators.saveCompanySettingSuccess(res?.data));
		yield put(Actions.creators.companyAddEditOrg(false));
		yield put(Actions.creators.getCompanySettingStart());
	} catch (err) {
		yield put(Actions.creators.saveCompanySettingFail(err));
	}
}
export function* saveUploadLogoService(data) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const types = data?.data;
	let formData = new FormData();
	formData.append('imageUpload', types);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/logo/save`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.saveUploadLogoSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.saveUploadLogoFail(err));
	}
}

export function* overTimeLabelService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/overtimetype`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.overtimeLabelSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.overtimeLabelFail(err));
	}
}

export function* getUploadLogoService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/companyLogo`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		const image = res.data.size > 0 ? URL.createObjectURL(res?.data) : [];
		yield put(Actions.creators.getUploadLogoSuccess(image));
	} catch (err) {
		yield put(Actions.creators.getUploadLogoFail(err));
	}
}
export function* defaultApproverService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Users/${corporateId}/all-user/hr-permission`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getDefaultApproverSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getDefaultApproverFail(err));
	}
}
export default function* overtimeWatcher() {
	yield takeEvery(Actions.types.GET_OVERTIME_POLICY_PERCENT_START, getOverTimePercentService);
	yield takeEvery(Actions.types.SAVE_OVERTIME_DATA_START, saveOverTimeDataService);
	yield takeEvery(Actions.types.GET_OVERTIME_DATA_START, getOverTimeDataService);
	yield takeEvery(Actions.types.GET_OVERTIME_SALARY_START, getOverTimeSalaryDataService);
	yield takeEvery(Actions.types.GET_COMPANYSETTING_START, companySettingService);
	yield takeEvery(Actions.types.SAVE_COMPANYSETTING_START, saveCompanySettingService);
	yield takeEvery(Actions.types.SAVE_UPLOADLOGO_START, saveUploadLogoService);
	yield takeEvery(Actions.types.GET_OVERTIME_LABEL_START, overTimeLabelService);
	yield takeEvery(Actions.types.GET_UPLOADLOGO_START, getUploadLogoService);
	yield takeEvery(Actions.types.GET_DEFAULT_APPROVER_START, defaultApproverService);
}
