import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';
import EdenredHeader from '@pages/main-layout/components/main-header';

const CompanyIndustry = ({ history }) => {
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = () => {
		history.push({
			pathname: '/company/trade/licenses',
			state: {
				response: `Edengreen Sharjah has been added as a trade license`,
			},
		});
	};
	return (
		<>
			<EdenredHeader />
			<Row className="company-industry-container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company-industry-header">
					<EdenredTypo>What Industry Does This Branch Belong To?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Form form={form} onFinish={onFinish}>
						<Row className="company-industry-form">
							<Col xl={11} lg={11} md={12} sm={24} xs={24} className="industry-text-field">
								<Form.Item name="Industry">
									<Input labels="Industry" labelClassName="inputlabelrequired" />
								</Form.Item>
							</Col>
						</Row>
						<Col xl={8} lg={8} md={8} sm={24} xs={24} className="industry-button">
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Submit
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default CompanyIndustry;
