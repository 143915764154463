import Axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { templateDownload } from '@helpers';
import Actions from '../action';

export function* uploadDocumentService(file) {
	let formData = new FormData();
	formData.append('file', file.file);
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/data/upload`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.saveDocumentUploadSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.saveDocumentUploadFail(err));
	}
}
export function* templateService() {
	try {
		const uri = `${DOMAIN_URL}/FileTemplate/EmiratesDocument/Download`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.saveTemplateSuccess(res.data));
		templateDownload(res.data, 'EmployeesExpiryDocument.xls');
	} catch (err) {
		yield put(Actions.creators.saveTemplateFail(err));
	}
}
export function* filterService(filterDocument) {
	const filterData = filterDocument.filterDocument;
	let request = {
		documentTypeFilter: filterData.documentTypeFilter,
		expiryDateFilter: filterData.expiryDateFilter,
		employeeIdNameFilter: filterData.employeeIdNameFilter,
		pageNumber: filterData.pageNumber,
		pageSize: filterData.pageSize,
		reportType: filterData.reportType,
	};
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/data`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.saveFilterDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.saveFilterDataFail(err));
	}
}
export function* setupTemplateServices() {
	let request = {
		documentTypeFilter: null,
		employeeIdNameFilter: '',
		expiryDateFilter: null,
		pageNumber: 0,
		pageSize: 0,
		reportType: 'excel',
	};
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/Data/Download`;
		const res = yield call(Axios.post, uri, request, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.setupTemplateSuccess(res.data));
		templateDownload(res.data, 'EmployeesExpiryDocument.xls');
	} catch (err) {
		yield put(Actions.creators.setupTemplateFail(err));
	}
}

export default function* uploadWatcher() {
	yield takeEvery(Actions.types.SAVE_DOCUMENTUPLOAD_LOADING, uploadDocumentService);
	yield takeEvery(Actions.types.SAVE_TEMPLATE_LOADING, templateService);
	yield takeEvery(Actions.types.SAVE_FILTERDATA_START, filterService);
	yield takeEvery(Actions.types.SETUP_TEMPLATE_START, setupTemplateServices);
}
