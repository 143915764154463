import React from 'react';
import { useSelector } from 'react-redux';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import PayrollPermissionPresentational from './payroll-Permission-Persentational';

const AuthorizedAccess = UnauthorizedAccess(PayrollPermissionPresentational);
const PayrollPermission = ({ isSetup = false }) => {
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.roles[0]?.name);
	return <PayrollPermissionPresentational {...{ currentUser, isSetup }} />;
};

export default PayrollPermission;
