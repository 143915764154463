import { Select } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { APP_VARIABLES, SETUP_REDIRECT } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect } from 'react-redux';
import { icons } from 'src/assets/icons';
import './company-search-dropdown.scss';

const { Option } = Select;

const CompanySearchDropDown = ({ corporateIds = [] }) => {
	const companyList = corporateIds.map((corporate) => {
		return {
			label: corporate.corporateName,
			value: corporate.corporateName,
			corporateId: corporate.corporateId,
			id: corporate.corporateId,
		};
	});
	const corporateid = Object.assign(
		{},
		...corporateIds.filter((company) => company.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
	).corporateId;
	const corporateName = Object.assign(
		{},
		...corporateIds.filter((company) => company.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
	).corporateName;

	const defaultValue = `${corporateName}-${corporateid}`;
	const [value, setValue] = useState(defaultValue);
	const [dropdownEnabled, setdropdownEnabled] = useState(false);
	const [prevValue, setprevValue] = useState(value);
	const [options, setOptions] = useState(companyList);
	const [focused, setfocused] = useState(false);
	const [backupoptions, setbackupOptions] = useState(companyList);
	const onSearch = (searchText) => {
		if (searchText) {
			setOptions(setSerachVals(searchText));
		} else {
			setOptions(companyList);
		}
	};

	// extracts name and id from corporates irrespective of hyphens from name
	function extractCorporateNameAndId(input) {
		// checking  last hyphen index value
		const lastHyphenIndex = input.lastIndexOf('-');
		if (lastHyphenIndex !== -1) {
			// checking after hyphen it must need to be corporate id(number)
			const checkCorporateId = Number(input.slice(lastHyphenIndex + 1).trim());
			// If its not a number then it must be a text so we return null and keep default value to display.
			if (isNaN(checkCorporateId)) {
				return null;
			}
			const name = input.slice(0, lastHyphenIndex).trim();
			const id = input.slice(lastHyphenIndex + 1).trim();
			return { name, id };
		}
		return null;
	}

	const onSelect = (data) => {
		const corporatesData = extractCorporateNameAndId(data);
		setfocused(false);
		setdropdownEnabled(false);
		// checking while selecting corporates, its a same one dont proceed
		const isCurrentCorporateIdSelected = corporatesData.id === localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		if (isCurrentCorporateIdSelected) {
			return null;
		}
		setValue(corporatesData?.name);
		setprevValue(data);
		localStorage.setItem(APP_VARIABLES.CORPORATE_ID, corporatesData.id);
		localStorage.setItem(APP_VARIABLES.CORPORATE_NAME, corporatesData.name);
		localStorage.removeItem(SETUP_REDIRECT.SETUP_REDIRECT_TO);
		window.location.reload();
	};
	const onChange = (data) => {
		setValue(data);
	};
	const handleBlur = () => {
		setValue(prevValue);
		setdropdownEnabled(false);
		setfocused(false);
	};
	const setSerachVals = (searchVal, repeat = 1) => {
		return companyList.filter((obj) => {
			// return obj;
			let searchNewStr = searchVal.toLowerCase();
			let searchStr = obj.label.toLowerCase();
			let searchId = obj.id.toString();
			if (searchStr.search(searchNewStr) !== -1 || searchId.search(searchNewStr) !== -1) {
				return obj;
			}
		});
	};
	const Icon = !focused ? DownOutlined : UpOutlined;

	function displayCorporates(currentValue) {
		const corporatesValue = {
			name: '',
			id: null,
		};
		if (extractCorporateNameAndId(currentValue)) {
			corporatesValue.name = extractCorporateNameAndId(currentValue).name;
			corporatesValue.id = extractCorporateNameAndId(currentValue).id;
		} else {
			// if the hyphen not presents in corporate name then using default values
			const id = companyList.find((data) => data.label === currentValue)?.id;
			corporatesValue.name = currentValue;
			corporatesValue.id = id;
		}
		return corporatesValue;
	}

	return (
		<div className="company-search-dropdown-container">
			{backupoptions.length === 1 ? (
				<div className="company-search-dropdown-text-area" onClick={() => setdropdownEnabled(!dropdownEnabled)}>
					<div className="value" style={{ paddingLeft: '0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
						{displayCorporates(value).name}
					</div>
					<span className="company-id">{displayCorporates(value).id}</span>
				</div>
			) : (
				<div>
					<ImageComponent
						className="icon"
						src={dropdownEnabled ? icons.not_focus.Up : icons.not_focus.Down}
						onClick={() => setdropdownEnabled(!dropdownEnabled)}
					/>
					{!dropdownEnabled ? (
						<div className="company-search-dropdown-text-area" onClick={() => setdropdownEnabled(!dropdownEnabled)}>
							<div className="value" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
								{displayCorporates(value).name}
							</div>
							<span className="company-id">{displayCorporates(value).id}</span>
						</div>
					) : (
						<Select
							autoFocus
							className="company-search-dropdown custom-dd"
							dropdownClassName="company-search-dropdown-list"
							defaultValue={options.length > 0 ? options[0].value : ''}
							showSearch
							value={value}
							style={{ width: '85%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
							open={focused}
							onSelect={onSelect}
							showArrow={false}
							onSearch={onSearch}
							onChange={onChange}
							onFocus={() => setfocused(true)}
							onBlur={handleBlur}
							prefix={<Icon className="site-form-item-icon" />}>
							{options.map((option, i) => (
								<Option key={option.value} value={`${option.label}-${option.id}`}>
									{option.label} <span className="company-id">{option.id}</span>
								</Option>
							))}
						</Select>
					)}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(CompanySearchDropDown);
