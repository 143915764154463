import { ConsoleSqlOutlined } from '@ant-design/icons';
import React, { Fragment, useEffect, useRef, useState, version } from 'react';
import ApproveRequestsPresentational from './approve-requests-presentational';
import PayrollService from 'src/services/payroll-service';

const ApproveRequestsFunctional = () => {
	const _payrollService = new PayrollService();
	const [blockedData, setblockedData] = useState(null);

	useEffect(() => {
		/// api call the function
		checkBlockedData();
	}, []);

	const checkBlockedData = () => {
		_payrollService.getFinancialBlockdata().then((response) => {
			setblockedData(response.data);
		});
	};

	return (
		<>
			{/* <Toast></Toast> */}
			<ApproveRequestsPresentational {...{ blockedData }} />
		</>
	);
};

export default ApproveRequestsFunctional;
