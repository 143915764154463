import React from 'react';
import { useHistory } from 'react-router-dom';
import ApprovedPage from '@pages/payroll/EmployeesExpirymodule/component/ApprovedPage';
import './PreviewUploadedDocumentData.scss';

function PreviewUploadedDocumentData() {
	const history = useHistory();
	const employeesList = history.location.state.data;
	return (
		<>
			<div className="documents-preview-table">
				<ApprovedPage
					{...{
						isSetup: true,
						employeesList,
						onSubmissionSuccess: () => {
							history.go(-2);
						},
						onClickCancel: () => {
							history.goBack();
						},
					}}
				/>
			</div>
		</>
	);
}

export default PreviewUploadedDocumentData;
