export function settingCookie(name, value, days) {
    let expires = "";
    // Days or time count in milliseconds
    // END_OF_SESSION : 0,
    // SECOND : 1000,
    // MINUTE : 1000 * 60,
    // HOUR : 1000 * 60 * 60,
    // DAY : 1000 * 60 * 60 * 24,
    // YEAR : 1000 * 60 * 60 * 24 * 365,
    // NEVER : 1000 * 60 * 60 * 24 * 365 * 20
    if (days && typeof days === 'number') {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + "; path=/;secure";
    document.cookie = `${name}=${value || ''}${expires};path=/;secure`;
}

export function gettingCookie(name = '') {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function deletingCookie(name, expire) {
    document.cookie = `${name}= ; expires = ${expire};path=/`
}

export const COOKIE_CONSTANTS = Object.freeze({
    device: {
        name: 'deviceId',
        expiryInDays: 30
    },
    cbdClient: {
        // terms and conditions
        tc: 'cbdtcInProgress'
    },
    mfa: {
        inProgress: 'mfa-in-progress',
    }
})
