import React, { useState, useEffect } from 'react';
import { icons } from 'src/assets/icons';
import index from './../Multiselect/index';

const Checkbox = (props) => {
	const {
		borderColor,
		borderRadius,
		borderStyle,
		borderWidth,
		checkbox,
		className,
		checked,
		disabled,
		containerClassName,
		containerStyle,
		label,
		labelClassName,
		labelStyle,
		name,
		onChange,
		reference,
		right,
		size,
		style,
		value,
		icon,
		tabIndex,
		labelPadding = '10px',
		checkHeight,
		...rest
	} = props;
	const [check, setCheck] = useState(checked);

	const toggle = (e) => {
		e.preventDefault();
		if (disabled) {
			return null;
		}
		setCheck(check);
		onChange && onChange(!check);
	};

	useEffect(() => {
		setCheck(checked);
	}, [checked]);

	return (
		<label
			style={{
				...containerStyle,
				display: 'flex',
				alignItems: 'center',
				padding: labelPadding,
				cursor: 'pointer',
			}}
			className={containerClassName}
			onClick={(e) => toggle(e)}>
			{(right && label && (
				<span className={labelClassName} style={{ paddingRight: '5px' }}>
					{label}
				</span>
			)) ||
				null}
			{checkbox || (
				<span style={disabled ? { cursor: 'not-allowed' } : {}}>
					<div
						style={{
							...style,
							height: size,
							width: size,
							borderWidth: borderWidth,
							borderColor: borderColor,
							borderStyle: borderStyle,
							borderRadius: borderRadius,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							pointerEvents: disabled ? 'none' : 'auto',
							opacity: disabled ? 0.2 : 1,
							cursor: disabled ? 'not-allowed' : '',
							background: 'transparent',
							zIndex: '20',
						}}
						tabIndex={tabIndex}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
								toggle(e);
							}
						}}
						className={className}>
						{(check &&
							(icon ? (
								icon
							) : (
								<div
									style={{
										backgroundColor: '#8EF9B3',
										borderRadius: borderRadius,
										width: '100%',
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									<img src={icons.not_focus.tick} alt="check" />
								</div>
							))) ||
							null}
						<input
							{...rest}
							ref={reference}
							type="checkbox"
							name={name}
							checked={check}
							value={value}
							onChange={toggle}
							disabled={disabled}
							hidden
						/>
					</div>
				</span>
			)}
			{(!right && label && (
				<span className={labelClassName} style={labelStyle}>
					{label}
				</span>
			)) ||
				null}
		</label>
	);
};
Checkbox.defaultProps = {
	borderColor: '#00344E',
	borderStyle: 'solid',
	borderWidth: 1,
	checked: false,
	disabled: false,
	right: false,
	name: '',
	borderRadius: 4,
	size: 16,
	checkHeight: '100%',
	style: {},
	className: '',
	labelStyle: { marginLeft: 5 },
	labelClassName: '',
	containerStyle: {},
	containerClassName: '',
	value: '',
	onChange: null,
};
export default Checkbox;
