import { ALL_EMPLOYEE_CLAIMS, ALL_HR_CLAIMS, ALL_PAYROLL_CLAIMS } from '@constants/app-constants';
import { getDaysCountByTwoDates } from '@helpers';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import { mergeAllClaims } from 'src/utils/claims';
import ClevertapReact from 'src/utils/clever-tap';
import { RenderLargeBannerContent } from './render-large-banner-content';
import './unemployment-insurance-banner.scss';

export const UnemploymentInsuranceBanner = ({ setShowUnemploymentInsuAvoid, doItLaterNavigation }) => {
	const { unEmploymentBannerLoading, unEmploymentBannerData } = useSelector((state) => state?.sharedStates);
	const history = useHistory();
	const [showUnemploymentInsuBanner, setShowUnemploymentInsuBanner] = useState(false);

	useEffect(() => {
		const showBanner = JSON.parse(localStorage.getItem('showUnemploymentBanner'));
		setShowUnemploymentInsuBanner(showBanner);
	}, [JSON.parse(localStorage.getItem('showUnemploymentBanner'))]);

	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);

	function onClickDoItLater() {
		if (doItLaterNavigation) {
			doItLaterNavigation();
		} else {
			ClevertapReact.event('Later_Unemp');
			const SHOULD_CONTAIN = allClaims.some((_claims) => [...ALL_EMPLOYEE_CLAIMS, ...ALL_HR_CLAIMS].indexOf(_claims) >= 0);
			const SHOULD_NOT_CONTAIN = !allClaims.some((_claims) => [...ALL_PAYROLL_CLAIMS].indexOf(_claims) >= 0);

			if (SHOULD_CONTAIN && SHOULD_NOT_CONTAIN) {
				history.push('/home');
			} else {
				history.push('/');
			}
		}
	}

	return (
		<>
			{JSON.parse(localStorage.getItem('showUnemploymentBanner')) || doItLaterNavigation ? (
				<div className="avoid-fines-header fade-right">
					<h2 className="avoid-fines-heading">Avoid WPS Salaries Deductions For Unemployment Insurance</h2>

					<div style={{ display: 'flex' }}>
						<EdenredButtons
							btnType="secondary"
							onClick={() => {
								onClickDoItLater();
								localStorage.setItem('showUnemploymentBanner', false);
								if (setShowUnemploymentInsuAvoid) {
									setShowUnemploymentInsuAvoid(false);
								}
							}}>
							Do It Later
						</EdenredButtons>

						<EdenredButtons
							style={{ width: '113px', marginLeft: '10px' }}
							onClick={() => {
								history.push('/un-employment-insurance/employee');
							}}>
							Get Started
						</EdenredButtons>
					</div>
				</div>
			) : (
				''
			)}

			<div className={`${getDaysCountByTwoDates() > 0 ? 'alert-error-box' : 'alert-info-box'} error unemployment-insurance-banner fade-right`}>
				<div style={{ display: 'flex', gap: '64px' }}>
					<div className={`${getDaysCountByTwoDates() > 0 ? 'info' : 'info-img'}`}>
						{getDaysCountByTwoDates() > 0 ? (
							<>
								<img src={icons.focus_state.Warning} style={{ height: '48px', width: '48px' }} alt="warning" />
								<h2>
									{unEmploymentBannerLoading ? (
										<div style={{ height: '15px', width: '15px' }} className={`loader secondary_loader`} />
									) : unEmploymentBannerData?.data?.totalEmployee ? (
										getDaysCountByTwoDates() + ' Days'
									) : (
										'0 Days'
									)}
								</h2>
								<small>to Unemployment Insurance Deadline!</small>
							</>
						) : (
							<img src={images.expiredImg} style={{ height: '100%', width: '100%' }} alt="expiredImg" />
						)}
					</div>
					<div className="alert-info-content">
						<RenderLargeBannerContent />
					</div>
				</div>
			</div>
		</>
	);
};
