import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../payroll-setting-action';
import Axios from 'axios';

export function* payrollService(employeeId) {
	const employeeID = employeeId?.employeeId;
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/PayrollSetting/${corporateId}/${employeeID}/setting`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getPayrollSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getPayrollFail(err));
	}
}

export function* contractTypeService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/PayrollSetting/${corporateId}/contracttype`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getContractTypeSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getContractTypeFail(err));
	}
}

export function* departmentService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/department`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getDepartmentSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getDepartmentFail(err));
	}
}
export function* allowanceService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/allowance`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getAllowanceSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getAllowanceFail(err));
	}
}
export function* payrollSubmitService(payload) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const response1 = payload?.payload;
	try {
		const uri = `${DOMAIN_URL}/PayrollSetting/${corporateId}/save`;
		const res = yield call(Axios.post, uri, response1);
		yield put(Actions.creators.getPayrollSubmitSuccess(res.data));
		yield put(Actions.creators.handleSettingSavePopUp(false));
		yield put(Actions.creators.savedSettingSucceessfulPopUp(true));
	} catch (err) {
		yield put(Actions.creators.getPayrollSubmitFail(err));
	}
}
export default function* payrollWatcher() {
	yield takeEvery(Actions.types.GET_PAYROLL_START, payrollService);
	yield takeEvery(Actions.types.GET_CONTRACTTYPE_START, contractTypeService);
	yield takeEvery(Actions.types.GET_DEPARTMENT_START, departmentService);
	yield takeEvery(Actions.types.GET_ALLOWANCE_START, allowanceService);
	yield takeEvery(Actions.types.GET_PAYROLLSUBMIT_START, payrollSubmitService);
}
