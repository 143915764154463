import React, { useState } from 'react';
import Switch from './index.js';

function Example() {
	const [value, setValue] = useState(false);

	return (
		<div className="app">
			<Switch isOn={value} handleToggle={() => setValue(!value)} leftText="No" rightText="Yes" />
		</div>
	);
}

export default Example;
