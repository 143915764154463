import React, { Fragment, useState } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import { DATE_FORMAT, popUpMessage } from '@constants/app-constants';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Popup from 'src/utils/popup/popups';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import OverlapCard from '../Leave-to-approve/Component/overlapCard';
import './customDatePicker.scss';
import { icons } from 'src/assets/icons';

export const LeaveRquestDatePicker = ({ value, employeeData, handleDatepickers, leaveValid, disabled }) => {
	const datepick = employeeData?.startDate > employeeData?.endDate;
	const [showDateDefault, setShowDateDefault] = useState(false);
	const options = {
		allowInput: true,
		dateFormat: 'd/m/Y',
		disableMobile: true,
		mode: 'range',
	};
	const handleBorder = () => {
		if (employeeData?.rangeDate && !leaveValid) {
			return '#9A9EA6';
		} else if ((datepick || leaveValid) && employeeData?.rangeDate) {
			return '#FF9691';
		} else if (disabled) {
			return '#F7F8FA';
		} else if (showDateDefault) {
			return '#71b0d0';
		} else {
			return '#DFE1E6';
		}
	};
	const Component = !employeeData?.nameId ? Tooltip : Fragment;
	return (
		<div className="date-picker-section">
			<Component
				content={
					<ETypo light b2 color="#FFFFFF">
						Select employee before
						<br />
						select leave date
					</ETypo>
				}>
				<div className="date-picker-container">
					<Flatpickr
						{...{
							disabled,
							options,
							onFocus: () => {
								console.log('Q7 onFocus ');
								setShowDateDefault(true);
							},
							className: 'date-picker-input',
							onChange: (dateObj, formatStr) => {
								handleDatepickers(value, dateObj, formatStr);
							},
							onClose: () => {
								setShowDateDefault(false);
							},
							onKeyDown: (e) => e.keyCode >= 65 && e.keyCode <= 90 && e.preventDefault(),
						}}
					/>

					<div
						className={
							showDateDefault
								? `date-picker-layout-active ${disabled ? `date-picker-disabled` : 'date-picher-pointer'}`
								: `date-picker-layout ${disabled ? `date-picker-disabled` : 'date-picher-pointer'}`
						}
						style={{ border: `1px solid ${handleBorder()}` }}>
						<ImageComponent className="date-icon-style" src={focus_state_icons.time} />
						<div className="from-to-container">
							<div className="from-container">
								<ETypo b2 medium className={employeeData?.rangeDate ? 'from-to-active-style' : 'from-to-default-style'}>
									{employeeData?.rangeDate
										? moment(employeeData?.startDate).format(DATE_FORMAT?.DD_MM_YYYY)
										: showDateDefault
										? 'DD/MM/YYYY'
										: 'From'}
								</ETypo>
							</div>
							<div className="to-container">
								<ETypo b2 medium className={employeeData?.rangeDate ? 'from-to-active-style' : 'from-to-default-style'}>
									{employeeData?.rangeDate
										? moment(employeeData?.endDate).format(DATE_FORMAT?.DD_MM_YYYY)
										: showDateDefault
										? 'DD/MM/YYYY'
										: 'To'}
								</ETypo>
							</div>
							<ImageComponent
								className="date-icon-style"
								src={showDateDefault ? icons.not_focus.up : icons.not_focus.down}></ImageComponent>
						</div>
					</div>
					<div className="error"></div>
				</div>
			</Component>
		</div>
	);
};
export const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
	return <input {...props} defaultValue={defaultValue} ref={inputRef} className="date-picker-input" />;
};
export const DateValidate = ({
	leaves,
	handleOverlap,
	setIsModalVisible,
	isModalVisible,
	isOverLappingLoad,
	leaveBalanceLists,
	employeeData,
	employee,
	isEmployeeRequest,
}) => {
	const totalDays = moment(employeeData?.endDate).diff(moment(employeeData?.startDate), 'd');
	let balance = leaveBalanceLists?.balance ?? 0;
	let addedTotalDays = totalDays >= 0 ? parseInt(totalDays + 1) : 0;
	return (
		<>
			<Col className="days-balance-overlapping-container">
				<Row className="overlapping-section">
					<Row className="overlapping-leave-balance-value">
						<Row className="overlapping-container">
							<ETypo b2 medium className="balance-and-days-style">
								Balance{' '}
								<ETypo b3 light style={{ marginLeft: '8px' }} className={balance - addedTotalDays >= 0 ? '' : 'red-text-minus'}>
									{balance - addedTotalDays}
								</ETypo>
							</ETypo>
						</Row>
						<Row className="overlapping-container">
							<ETypo b2 medium className="balance-and-days-style">
								Days
								<ETypo b3 light style={{ marginLeft: '8px' }}>
									{totalDays + 1}
								</ETypo>
							</ETypo>
						</Row>
					</Row>
					<Row className="overlapping-leave-balance-value-over">
						<Row className="overlapping-container" onClick={handleOverlap}>
							<ETypo b2 medium className="overlaping-leaves-style">
								Overlapping Leaves
								<ImageComponent src={focus_state_icons.leaveArrow} className="arrow-leave"></ImageComponent>
							</ETypo>
						</Row>
					</Row>
				</Row>
			</Col>
			<OverlapCard
				title="Overlapping Leaves"
				subtitle={employee?.department}
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				dataOverlap={leaves}
				loader={isOverLappingLoad}
			/>
		</>
	);
};
export const SuccessPopUp = ({ isCreateSaved, handleSuccessPopups, popUp, content = false }) => {
	return <Edenredmodal isOpen={isCreateSaved} title={popUpMessage?.successMessage} onOkay={handleSuccessPopups} onOkayButton="Ok" />;
};
export const LeavePopUp = ({ employeeData, popUp, handleLeavePopups }) => {
	return (
		<Popup
			visible={popUp?.leaveAbliable}
			message={{ titleMessage: ` ${employeeData?.leaveType} Leave is not Applicable in Probational Period...` }}
			closePopup={handleLeavePopups}
		/>
	);
};
