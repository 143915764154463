import ImageComponent from '@sharedComponent/image-component';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import './accordian.scss';

const AccordianContent = ({ group }) => {
	const [active, setActive] = useState([
		{
			question: 'What is counted as an employee?',
			answer: 'An employee is any employee in your company, C3Pay Cardholder and/or a Bank Account holder.',
			id: 0,
			isActive: false,
		},
		{
			question: 'How will I get charged?',
			answer: ' You will get charged based on the number of employees in the portal on the day of the invoice. Your old monthly invoice will be replaced by this one. ',
			id: 1,
			isActive: false,
		},
		// {
		// 	question: 'Do I get a free trial?',
		// 	answer: 'An employee is any employee in your company, C3Pay Cardholder and/or a Bank Account holder.',
		// 	id: 2,
		// 	isActive: false,
		// },
		{
			question: 'Can I cancel my subscription at any time?',
			answer: 'Yes. If you ever decide that you want to go back to simple salary processing, simply cancel your subscription from Company tab.',
			id: 2,
			isActive: false,
		},
		{
			question: 'Will managers who approve leaves be able to view payroll details?',
			answer: 'No. You will be able to manage the permissions of the user who has access to the portal and will decide what they can see.',
			id: 3,
			isActive: false,
		},
	]);
	const answerDiv = (id) => {
		ClevertapReact.event(`HR_LP_FAQ${id + 1}`);
		const accordianContent = active?.map((data) => {
			if (id === data?.id) {
				return {
					...data,
					isActive: !data.isActive,
				};
			} else {
				return data;
			}
		});
		setActive(accordianContent);
	};

	return (
		<div className="accordian-contents">
			<p className="frequently-asked-heading">Frequently Asked Questions</p>
			{active?.map((data, index) => (
				<Row style={{ justifyContent: 'center', marginTop: '1%' }} key={index}>
					<Col xl={20} lg={20} md={20} sm={20} xs={20} className="frequently-asked-div" onClick={() => answerDiv(data.id)}>
						<p className="accordian-questions">{data.question}</p>
						<ImageComponent src={data?.isActive ? not_focus_icons.MinusImage : not_focus_icons.AddImage} style={{ cursor: 'pointer' }} />
					</Col>
					{data?.isActive && (
						<Col xl={20} lg={20} md={20} sm={20} xs={20} className="frequently-asked-answer">
							<p className="accordian-answer">{data.answer}</p>
						</Col>
					)}
				</Row>
			))}
		</div>
	);
};

export default AccordianContent;
