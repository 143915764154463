import Actions from './action';

export const initState = {
	isEmployeeTemplateLoading: false,
	employeeTemplateDetail: [],
	isEmployeeUploadLoading: false,
	EmployeeUploadDetails: [],
	isPayrollUploaded: 'loading',
	isEmployeePayrollLoading: false,
	employeePayrollDetails: {},
};

const employeePayrollReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.EMPLOYEE_PAYROLL_TEMPLATE_START: {
			return {
				...state,
				isEmployeeTemplateLoading: true,
			};
		}
		case Actions.types.EMPLOYEE_PAYROLL_TEMPLATE_SUCCESS: {
			return {
				...state,
				isEmployeeTemplateLoading: false,
				employeeTemplateDetail: action?.res,
			};
		}
		case Actions.types.EMPLOYEE_PAYROLL_TEMPLATE_FAIL: {
			return {
				...state,
				isEmployeeTemplateLoading: false,
				employeeTemplateDetail: [],
			};
		}
		case Actions.types.EMPLOYEE_PAYROLL_UPLOAD_START: {
			return {
				...state,
				isEmployeeUploadLoading: true,
				isPayrollUploaded: 'loading',
			};
		}
		case Actions.types.EMPLOYEE_PAYROLL_UPLOAD_SUCCESS: {
			let isErrorData = 'yes';
			if (action.res && action.res.validationMessage) {
				isErrorData = 'yes';
			} else {
				isErrorData = 'no';
			}
			return {
				...state,
				isEmployeeUploadLoading: false,
				isPayrollUploaded: isErrorData,
				EmployeeUploadDetails: action?.res,
			};
		}
		case Actions.types.EMPLOYEE_PAYROLL_UPLOAD_FAIL: {
			return {
				...state,
				isEmployeeUploadLoading: false,
				isPayrollUploaded: false,
				EmployeeUploadDetails: [],
			};
		}
		case Actions.types.GET_EMPLOYEE_PAYROLL_START: {
			return {
				...state,
				isEmployeePayrollLoading: true,
			};
		}
		case Actions.types.GET_EMPLOYEE_PAYROLL_SUCCESS: {
			return {
				...state,
				isEmployeePayrollLoading: false,
				employeePayrollDetails: action.res,
			};
		}
		case Actions.types.GET_EMPLOYEE_PAYROLL_FAIL: {
			return {
				...state,
				isEmployeePayrollLoading: false,
				employeePayrollDetails: [],
			};
		}
		case Actions.types.EMP_RESET_DOCUMENT: {
			return {
				...state,
				isEmployeeUploadLoading: false,
				isPayrollUploaded: 'loading',
				EmployeeUploadDetails: [],
			};
		}
		default:
			return state;
	}
};

export default employeePayrollReducer;
