import React, { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import ImageComponent from '@sharedComponent/image-component';
import useOnClickOutside from '@pages/payroll-plus-new/components/useOnClickOutside';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import FilterComponent from './filter-component';
import './filter.scss';

const FilterButton = ({
	filterImage,
	filterText,
	filterData = [],
	selectedFilter,
	className = '',
	onClick = () => {},
	disabled = false,
	style,
	loading = false,
	showIndicate = false,
	filterClearAll,
	closeFilter = false,
	value = null,
	compareValue = '',
	filterDropDownValue = 'filterValue',
	openedFilter = '',
	cleverTabEvent = null,
}) => {
	const [filterClicked, setFilteClicked] = useState(false);
	const [filterObject, setFilterObject] = useState();
	const filterRef = useRef(null);
	useOnClickOutside(filterRef, () => {
		if (filterClicked) setFilteClicked(false);
	});
	useEffect(() => {
		const nFilterData = filterData?.map((data, index) => {
			return {
				...data,
				labelKey: uuid(),
				filterOpen: openedFilter === data?.filterLabel,
				filterValue: data?.filterValue?.map((item) => {
					const isSelected = value ? value?.includes(item?.[compareValue || filterDropDownValue]) : item?.isSelected;
					return {
						...item,
						valueKey: uuid(),
						isLoading: false,
						isSelected: isSelected,
					};
				}),
			};
		});
		setFilterObject(nFilterData);
	}, [filterData, value]);

	const handleFilterClick = (e) => {
		e.stopPropagation();
		setFilteClicked(!filterClicked);
		onClick && onClick();
	};
	const active = useMemo(() => {
		const atleastOneSelected = filterObject?.filter((data) => data?.filterValue?.some((item) => item?.isSelected))?.length > 0;
		return atleastOneSelected;
	}, [filterObject]);

	const handleupdate = (params) => {
		setFilterObject(params);
	};
	return (
		<div className={`main_filter_button ${className}`} style={style}>
			<div
				className={`filter_conatiner ${disabled && 'filter_disabled'} ${active && showIndicate && 'filter_indicate'}`}
				onClick={(e) => handleFilterClick(e)}>
				{filterImage ? filterImage : <ImageComponent src={focus_state_icons.NewFilter} />}
				<p className="new_design_filter_typo">{filterText ? filterText : 'Filters'}</p>
			</div>
			{filterClicked && (
				<FilterComponent
					{...{
						cleverTabEvent,
						filterObject,
						handleupdate,
						setFilterObject,
						selectedFilter,
						loading,
						filterClearAll,
						setFilteClicked,
						active,
						filterRef,
						closeFilter,
					}}
				/>
			)}
		</div>
	);
};

export default FilterButton;
