import { images } from "src/assets/img"

export const Data = [
    {
        order: '1',
        imgUrl: images?.automated,
        desc: 'Automated Payroll Calculations & Reports',
        className: 'landing_cards_automated',
    },
    {
        order: '2',
        imgUrl: images?.manageleave,
        desc: 'Manage Leave Requests in one place',
        className: 'landing_cards_manageleave',
    },
    {
        order: '3',
        imgUrl: images?.sendAnnouncement,
        desc: 'Send Announcements To Your Employees',
        className: 'landing_cards_sendAnnouncement',
    },
    {
        order: '4',
        imgUrl: images?.automatic,
        desc: 'Automatic Document Expiry Reminders',
        className: 'landing_cards_automatic',
    },
]

export const Constants = [
    {
        mainText: 'You asked, we listened.',
        mainTextSub: 'We can now help you with everything Payroll and HR!',
        subText: 'We have added a selection of new features to allow to manage your HR and Payroll better'
    }
]