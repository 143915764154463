import React, { useState } from 'react';
import './radiogroup.scss';
import ETypo from '../Typo';

function RadioButton({ options, title = null, onchange }) {
	const [checkboxOption, setCheckboxOption] = useState(options || []);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const changeClicked = (param, clickedIndex) => {
		setSelectedIndex(clickedIndex);
		onchange(param?.target?.value);
		let optionObjClone = checkboxOption;
		options.map((ref, index) => {
			if (index == clickedIndex) {
				optionObjClone[index].isChecked = true;
			} else {
				optionObjClone[index].isChecked = false;
			}
		});

		setCheckboxOption(optionObjClone);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
			{title && (
				<div className="title-holder">
					<ETypo b3 medium color="#181919">
						{title}
					</ETypo>
				</div>
			)}
			<div className="radio-button-container">
				{checkboxOption?.map((items, index) => (
					<div className="radio">
						<input id type="radio" name="radio" onChange={(e) => changeClicked(e, index)} value={items.value} checked={items.isChecked} />
						<label for="radio" class="form-control">
							{index === selectedIndex ? (
								<ETypo b2 medium color="#181919">
									{items.value}
								</ETypo>
							) : (
								<ETypo b2 light color="#181919">
									{items.value}
								</ETypo>
							)}
						</label>
					</div>
				))}
			</div>
		</div>
	);
}

export default RadioButton;
