import Deduction from './deduction';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import React from 'react';
import '../allowance/index.scss';

const DeductionPage = () => {
	return (
		<main className="wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<Deduction cleverTap={true} />
			</div>
		</main>
	);
};

export default DeductionPage;
