import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import '../request-statement.scss';
import { getMonthLabelFromNumber, getYears, groupByKey } from '@helpers';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';

import { images } from 'src/assets/img';
import VideoPlayerPopUpModal from '../../../../video-tutorial/video-player-modal/videoPlayerPopUpModal';

const RequestStatement = ({ history }) => {
	const customStyles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(255, 255, 255, 0.75)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'absolute',
			top: '16rem',
			left: '1rem',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'white',
			overflow: 'auto',
			borderRadius: '16px',
			outline: 'none',
			padding: '15px',
			width: '90%',
			boxShadow: '0px 10px 20px rgba(174, 182, 183, 0.5)',
		},
	};
	const { state } = useLocation();
	const [Loading, setLoading] = useState(false);
	const [routingPath, setRoutingPath] = useState(null);
	const [preview, SetPreviewVideo] = useState(false);
	const [isvideoModalVisible, setIsvideoModalVisible] = useState(false);
	const [isVideoPaused, setIsVideoPaused] = useState(false);

	const ClosePreviewVideo = () => {
		SetPreviewVideo(false);
		setIsVideoPaused(true);
		setIsvideoModalVisible(false);
	};

	const startVideo = () => {
		setIsVideoPaused(false);
		setIsvideoModalVisible(true);
		SetPreviewVideo(true);
	};

	return (
		<>
			<Row className="Request-statement-container">
				<Col className="Request-statement-presentational">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="Request-statement" style={{ maxWidth: '44%' }}>
						<Col xl={14} md={8} lg={12} sm={12} xs={24} style={{ marginRight: '120px', marginLeft: '100px' }}>
							<Col xl={20} md={8} lg={20} sm={24} xs={24} style={{ padding: '95px 0px 0px 0px' }}>
								<div>
									<ImageComponent
										src={images.Mobile_Card_Statement}
										style={{ width: '100%', cursor: 'pointer' }}
										onClick={() => SetPreviewVideo(true)}
									/>
								</div>
							</Col>
						</Col>
						<Col md={24} className="Request-statement">
							<Col md={24}>
								<div style={{ padding: '190px 10px 15px 0px' }}>
									<Col md={24}>
										<EdenredTypo bold className="statement-heading">
											Your Employees can now get statements on the C3Pay Mobile App!
										</EdenredTypo>
									</Col>
								</div>
								<Col md={24}>
									<div className="statement-sub-heading">
										Employees can get a detailed soft copy statement <br />
										with all of their transactions
									</div>
								</Col>
								<Col md={24}>
									<div className="statement-bullet-point">
										<ImageComponent src={images.Green_Tick} style={{ paddingRight: '10px' }} />
										Statements are emailed instantly to your employees.
									</div>
								</Col>
								<Col md={24}>
									<div className="statement-bullet-point">
										<ImageComponent src={images.Green_Tick} style={{ paddingRight: '10px' }} />
										Statements are only accessible by your employees.
									</div>
								</Col>
								<Row>
									<Col md={12}>
										<div style={{ padding: '20px 0px 15px 0px', height: '48px' }}>
											<EdenredButton onClick={() => startVideo()}>Watch Video</EdenredButton>
										</div>
									</Col>
								</Row>
								<VideoPlayerPopUpModal
									visible={preview}
									video={'https://vimeo.com/660583119'}
									closeVideo={ClosePreviewVideo}
									isvideoModalVisible={setIsvideoModalVisible}
									startVideo={startVideo}
									isVideoPaused={isVideoPaused}></VideoPlayerPopUpModal>
							</Col>
						</Col>
					</Col>
				</Col>
			</Row>
		</>
	);
};
export default RequestStatement;
