import Actions from './profile-action';

export const initState = {
	nationalityData: [],
	isInviteLoading: false,
	inviteSuccess: '',
	isEmailUpdateLoad: false,
	emailUpdated: [],
	emailUpdateError: '',
	emailConformation: false,
};
const counterReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_NATIONALITY_START: {
			return {
				...state,
			};
		}
		case Actions.types.GET_NATIONALITY_DATA_SUCCESS: {
			return {
				...state,
				nationalityData: action.res,
			};
		}
		case Actions.types.GET_NATIONALITY_DATA_ERROR: {
			return {
				...state,
				nationalityData: [],
			};
		}
		case Actions.types.INVITE_EMPTOPORTAL_START: {
			return {
				...state,
				isInviteLoading: true,
			};
		}
		case Actions.types.INVITE_EMPTOPORTAL_SUCCESS: {
			return {
				...state,
				isInviteLoading: false,
				inviteSuccess: action.res,
			};
		}
		case Actions.types.INVITE_EMPTOPORTAL_FAIL: {
			return {
				...state,
				isInviteLoading: false,
				inviteSuccess: '',
			};
		}
		case Actions.types.UPDATE_EMAIL_START: {
			return {
				...state,
				isEmailUpdateLoad: true,
			};
		}
		case Actions.types.UPDATE_EMAIL_SUCCESS: {
			return {
				...state,
				isEmailUpdateLoad: false,
				emailUpdated: action?.res,
			};
		}
		case Actions.types.UPDATE_EMAIL_FAIL: {
			return {
				...state,
				isEmailUpdateLoad: false,
				emailUpdated: [],
				emailUpdateError: action?.error,
			};
		}
		case Actions.types.CONFORM_EMAIL: {
			return {
				...state,
				emailConformation: action?.flag,
			};
		}
		default:
			return state;
	}
};
export default counterReducer;
