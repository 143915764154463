import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import React from 'react';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import Checkbox from '../Checkbox/Checkbox';
import './TableSelectors.scss';

const Index = ({ type }) => {
	return (
		<Checkbox
			tabIndex={3}
			borderColor="#00344E"
			borderRadius={7}
			size={22}
			label={<EdenredTypo b3>Flyers Soft!</EdenredTypo>}
			name="sujai"
			icon={
				<div
					style={{
						backgroundColor: '',
						borderRadius: 4,
						width: '27px',
						height: '19px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						cursor: 'pointer',
					}}
					className={`${type}_selector`}>
					<ImageComponent src={not_focus_icons[type]} />
				</div>
			}
		/>
	);
};

export default Index;
