const GET_EMPLOYEECOUNT_START = 'GET_EMPLOYEECOUNT_START';
const GET_EMPLOYEECOUNT_SUCCESS = 'GET_EMPLOYEECOUNT_SUCCESS';
const GET_EMPLOYEECOUNT_FAIL = 'GET_EMPLOYEECOUNT_FAIL';
const SAVE_DOWNLOAD_START = 'SAVE_DOWNLOAD_START';
const SAVE_DOWNLOAD_SUCCESS = 'SAVE_DOWNLOAD_SUCCESS';
const SAVE_DOWNLOAD_FAIL = 'SAVE_DOWNLOAD_FAIL';
const SAVE_FILTERDATA_START = 'SAVE_FILTERDATA_START';
const SAVE_FILTERDATA_SUCCESS = 'SAVE_FILTERDATA_SUCCESS';
const SAVE_FILTERDATA_FAIL = 'SAVE_FILTERDATA_FAIL';
const SAVE_BUTTON_START = 'SAVE_BUTTON_START';
const SAVE_BUTTON_SUCCESS = 'SAVE_BUTTON_SUCCESS';
const SAVE_BUTTON_FAIL = 'SAVE_BUTTON_FAIL';

const SET_POPUP_VISIBLE = 'SET_POPUP_VISIBLE';

const GET_APPROVED_EMPLOYEE = 'GET_APPROVED_EMPLOYEE';
const GET_APPROVED_EMPLOYEE_SUCCESS = 'GET_APPROVED_EMPLOYEE_SUCCESS';
const GET_APPROVED_EMPLOYEE_ERROR = 'GET_APPROVED_EMPLOYEE_ERROR';

const SUBMIT_VALID_EMPLOYEE = 'SUBMIT_VALID_EMPLOYEE';
const SUBMIT_VALID_EMPLOYEE_SUCCESS = 'SUBMIT_VALID_EMPLOYEE_SUCCESS';
const SUBMIT_VALID_EMPLOYEE_ERROR = 'SUBMIT_VALID_EMPLOYEE_ERROR';

const HANDEL_SUBMIT_SUCCESS_POPUP = 'HANDEL_SUBMIT_SUCCESS_POPUP';

const getEmployeeCountStart = () => {
	return {
		type: GET_EMPLOYEECOUNT_START,
	};
};

const getApprovedEmployee = (payload) => {
	return {
		type: GET_APPROVED_EMPLOYEE,
		payload: payload,
	};
};

const handelSubmitSuccessPopup = (flag) => {
	return {
		type: HANDEL_SUBMIT_SUCCESS_POPUP,
		flag,
	};
};

const getApprovedEmployeeSuccess = (res) => {
	return {
		type: GET_APPROVED_EMPLOYEE_SUCCESS,
		res,
	};
};

const getApprovedEmployeeError = () => {
	return {
		type: GET_APPROVED_EMPLOYEE_ERROR,
	};
};

const submitValidEmployee = (req) => {
	return {
		type: SUBMIT_VALID_EMPLOYEE,
		req,
	};
};

const submitValidEmployeeSuccess = (res) => {
	return {
		type: SUBMIT_VALID_EMPLOYEE_SUCCESS,
		res,
	};
};

const submitValidEmployeeError = () => {
	return {
		type: SUBMIT_VALID_EMPLOYEE_ERROR,
	};
};

const getEmployeeCountSuccess = (response) => {
	return {
		type: GET_EMPLOYEECOUNT_SUCCESS,
		response,
	};
};
const getEmployeeCountFail = (error) => {
	console.log('getEmployeeCountFail');
	return {
		type: GET_EMPLOYEECOUNT_FAIL,
		error,
	};
};
const saveDownloadStart = (downloadDocument) => {
	console.log('saveDownloadStart');
	return {
		type: SAVE_DOWNLOAD_START,
		downloadDocument,
	};
};
const saveDownloadSuccess = (response, reportType) => {
	console.log('saveDownloadSuccess');
	return {
		type: SAVE_DOWNLOAD_SUCCESS,
		response,
		reportType,
	};
};
const saveDownloadFail = (error, reportType) => {
	console.log('saveDownloadFail');
	return {
		type: SAVE_DOWNLOAD_FAIL,
		error,
		reportType,
	};
};
const saveFilterDataStart = (filterDocument) => {
	// console.log('saveFilterDateStart');
	return {
		type: SAVE_FILTERDATA_START,
		filterDocument,
	};
};
const saveFilterDataSuccess = (response) => {
	// console.log('saveFilterDateSuccess');
	return {
		type: SAVE_FILTERDATA_SUCCESS,
		response,
	};
};
const saveFilterDataFail = (error) => {
	console.log('saveFilterDateFail');
	return {
		type: SAVE_FILTERDATA_FAIL,
		error,
	};
};
const saveButtonStart = (modifiedData, filterDocument) => {
	console.log('modifiedDataaction', modifiedData);
	return {
		type: SAVE_BUTTON_START,
		modifiedData,
		filterDocument,
	};
};
const setPopupVisible = (flag) => {
	return {
		type: SET_POPUP_VISIBLE,
		flag,
	};
};
const saveButtonSuccess = (response) => {
	console.log('saveButtonSuccess');
	return {
		type: SAVE_BUTTON_SUCCESS,
		response,
	};
};
const saveButtonFail = (error) => {
	console.log('saveButtonFail');
	return {
		type: SAVE_BUTTON_FAIL,
		error,
	};
};

export default {
	types: {
		GET_EMPLOYEECOUNT_START,
		GET_EMPLOYEECOUNT_SUCCESS,
		GET_EMPLOYEECOUNT_FAIL,
		SAVE_DOWNLOAD_START,
		SAVE_DOWNLOAD_SUCCESS,
		SAVE_DOWNLOAD_FAIL,
		SAVE_FILTERDATA_START,
		SAVE_FILTERDATA_SUCCESS,
		SAVE_FILTERDATA_FAIL,
		SAVE_BUTTON_START,
		SAVE_BUTTON_SUCCESS,
		SAVE_BUTTON_FAIL,

		GET_APPROVED_EMPLOYEE,
		GET_APPROVED_EMPLOYEE_SUCCESS,
		GET_APPROVED_EMPLOYEE_ERROR,

		HANDEL_SUBMIT_SUCCESS_POPUP,

		SUBMIT_VALID_EMPLOYEE,
		SUBMIT_VALID_EMPLOYEE_SUCCESS,
		SUBMIT_VALID_EMPLOYEE_ERROR,
		SET_POPUP_VISIBLE,
	},
	creators: {
		getEmployeeCountStart,
		getEmployeeCountSuccess,
		getEmployeeCountFail,
		saveDownloadStart,
		saveDownloadSuccess,
		saveDownloadFail,
		saveFilterDataStart,
		saveFilterDataSuccess,
		saveFilterDataFail,
		saveButtonStart,
		saveButtonSuccess,
		saveButtonFail,

		getApprovedEmployee,
		getApprovedEmployeeSuccess,
		getApprovedEmployeeError,

		handelSubmitSuccessPopup,

		submitValidEmployee,
		submitValidEmployeeSuccess,
		submitValidEmployeeError,
		setPopupVisible,
	},
};
