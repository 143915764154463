import React, { useMemo } from 'react';

import { Col, Select } from 'antd';

const { Option } = Select;

const BankNameDropDown = ({ placeholder, onChange, routingState, bankName, disable }) => {
	return (
		<Col className="bank-dropdown-select">
			<Select // onSearch={onSearch}
				onChange={onChange}
				placeholder={placeholder}
				showSearch
				disabled={disable}
				value={routingState.bankName || null}
				dropdownClassName="payroll-setting-dropdown-list">
				{' '}
				{bankName &&
					bankName?.map((data) => {
						return (
							<Option key={data?.bankName} value={`${data?.bankName}code:${data?.bankRoutingCode}`} label={data?.bankName}>
								{data?.bankName}
							</Option>
						);
					})}{' '}
			</Select>{' '}
		</Col>
	);
};
export default BankNameDropDown;
