import { put, takeEvery, call } from 'redux-saga/effects';
import Axios from 'axios';
import { message } from 'antd';

import Actions from '../action';
import { appConstant, apiUrl } from '@constants';
import { getAuthToken, getAuthKey, history } from '@helpers';

const authToken = getAuthToken();
const authKey = getAuthKey();

export function* usersWorker(action) {
	try {
		const uri = `${appConstant.domain}${apiUrl.GET_ALL_USER}`;
		const { history } = action;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getUsersSuccess(res.data));
	} catch (err) {
		let mes = err.toString(err) === 'Error: Request failed with status code 400' ? appConstant.userNotExist : 'Something went wrong';
		message.error(mes);
		// yield put(Actions.creators.getUser());
	}
}

export default function* appWatcher() {
	yield takeEvery(Actions.types.GET_USERS, usersWorker);
}
