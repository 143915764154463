import React from 'react';
import { Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './successfull-presentational.scss';
const SuccessfullMessage = ({ handleHistory }) => {
	return (
		<Row className="sucessfull-Container">
			<Col xl={18} lg={18} md={18}>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="for-success-image">
						<ImageComponent src={not_focus_icons.AnnouncementSuccess} />
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="for-success-image">
						<p className="announcement-sent">Your Announcement Has Been Sent!</p>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="for-success-image">
						<p className="message-reached">Your message reached 40/50 of your employees</p>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="for-success-image">
						<p className="check-history" onClick={handleHistory}>
							Check History
						</p>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default SuccessfullMessage;
