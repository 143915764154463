import React from 'react';
import { Row, Col, Card } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';

const WeTransferSteps = [
	{
		step: 1,
		description: 'Create a folder for each employee on your computer',
	},
	{
		step: 2,
		description: 'Add a picture of the front side of their Emirates ID and a picture of the back side of their Emirates ID into each folder',
	},
	{
		step: 3,
		description: 'Name each folder with their respective Employee ID',
	},
	{
		step: 4,
		description: 'Zip all the folders in to 1 compressed file and rename it with your Branch ID',
	},
	{
		step: 5,
		description: 'Go to: https://edenreduae.wetransfer.com/',
	},
	{
		step: 6,
		description: 'Click “Add your files” and select the ZIP file created',
	},
	{
		step: 7,
		description: 'Keep the “Email to” as compliance@edenred.ae',
	},
	{
		step: 8,
		description: 'Enter your email address',
	},
	{
		step: 9,
		description: 'Put your BR ID and number of employees in the “Message” section on WeTransfer',
	},
	{
		step: 10,
		description: 'Click Transfer button',
	},
];

const UpdateEmployeesEmiratesId = () => {
	return (
		<Row className="update_employees_emirates_id_container" style={{ height: '80vh' }}>
			<Col xl={21} lg={24} md={24} sm={24} xs={24} className="update_employees_emirates_id_title">
				<EdenredTypo>
					As a mandate by the UAE Central Bank, we now require the updated Emirates ID copy (front & back) of your employees
				</EdenredTypo>
			</Col>
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="update_employees_emirates_id_hint">
				<EdenredTypo>Here are the options available for you to provide the copies to us:</EdenredTypo>
			</Col>
			<Col xl={22} lg={24} md={24} sm={24} xs={24} className="update_employees_emirates_id_c3pay_option">
				<Col xl={4} lg={6} md={8} sm={9} xs={9} className="option_card">
					<Card>
						<ImageComponent src={images.C3Pay} />
						<EdenredTypo>Option A:</EdenredTypo>
					</Card>
				</Col>
				<EdenredTypo>
					Cardholders can download the C3Pay app and update the Emirates ID on the C3Pay app directly. We recommend that cardholders
					register on the C3Pay app, and upload the Emirates ID themselves whenever possible, if they have a smartphone - as this is a lot
					simpler, faster and an efficient method for all.
				</EdenredTypo>
			</Col>
			<Col xl={21} lg={24} md={24} sm={24} xs={24} className="update_employees_emirates_id_wetransfer_option">
				<Col xl={4} lg={6} md={8} sm={9} xs={9} className="option_card">
					<Card>
						<ImageComponent src={images.WeTransfer} />
						<EdenredTypo>Option B:</EdenredTypo>
					</Card>
				</Col>
				<Col className="wetransfer_steps_container">
					{WeTransferSteps.map((step, index) => (
						<Row key={index} className="wetransfer_step_row">
							<Col xl={1} lg={1} md={1} sm={2} xs={2} className="wetransfer_step_icon">
								<EdenredTypo>{step.step}</EdenredTypo>
							</Col>
							<Col xl={23} lg={23} md={23} sm={20} xs={20} className="wetransfer_step_content">
								<EdenredTypo>{step.description}</EdenredTypo>
							</Col>
						</Row>
					))}
				</Col>
			</Col>
		</Row>
	);
};

export default UpdateEmployeesEmiratesId;
