import React from 'react';
import { Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import EdenredHeader from '@pages/main-layout/components/main-header';
import AutoReconciliationFunctional from '@pages/payroll/bank_details/components/auto-reconciliation-functional';
import { APP_VARIABLES, CLEVER_TAP_EVENTS } from '@constants/app-constants';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ClevertapReact from 'src/utils/clever-tap';
import { images } from 'src/assets/img';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { focus_state_icons } from 'src/assets/icons/focus_state';

const statuses = [
	{
		name: 'Check',
		icon: not_focus_icons.payrolluser,
		status: 'Payroll needs to be approved if your approval settings are active.',
	},
	{
		name: 'Pay Salary',
		icon: not_focus_icons.payrolltelegram,
		status: 'Transfer funds to pay salaries here, if you have not done so yet ',
		path: '/payroll/bank_details',
		cleverTapEvent: CLEVER_TAP_EVENTS.NextSteps_TransferFunds,
	},
	{
		name: 'Upload Proof',
		icon: not_focus_icons.payrollupload,
		status: 'Upload proof of funds transfer ',
		path: '/payroll/proof_of_transfer',
		cleverTapEvent: CLEVER_TAP_EVENTS.NextSteps_UploadProof,
	},
	{
		name: 'Track Employees Salary',
		icon: not_focus_icons.payrollLocation,
		status: 'Track when you employees will get paid ',
		path: '/payroll/history',
		cleverTapEvent: CLEVER_TAP_EVENTS.NextSteps_TrackPayout,
	},
];

const NextStepsPayroll = () => {
	const history = useHistory();
	return (
		<div className="run_payroll_final_next_step">
			<EdenredHeader />
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="next_run_payroll_header_fixed">
				<ETypo bold h2>
					Run Payroll
				</ETypo>
				<div className="payroll_review_button">
					<EdenredButtons btnType="secondary" leftIcon={<ImageComponent src={images.leftsarrow} />} onClick={() => history.push('/')}>
						Back
					</EdenredButtons>
				</div>
			</Col>
			<Row className="next_page_payroll_container">
				<div className="next_page_payroll_info_wrapper">
					<div className="next_page_payroll_info">
						<ImageComponent src={focus_state_icons?.deliveredSuccess} />
						<ETypo medium b1>
							Your Payroll table now includes allowances, additions and deductions. Use these columns for a more accurate payroll run.
						</ETypo>
					</div>
				</div>
				<Col xl={16} lg={16} md={20} sm={24} xs={24} className="next_page_payroll_header">
					<ETypo medium h4>
						Next Steps:
					</ETypo>
				</Col>
				<Col xl={16} lg={16} md={20} sm={24} xs={24} className="next_page_payroll_message">
					<ETypo light b2 color="#484B52">
						Your payroll has been submitted.
					</ETypo>
				</Col>
				<Col xl={22} lg={22} md={22} sm={24} xs={24} className="next_page_payroll_status">
					{statuses?.map((status, index) => (
						<Row key={index} className="next_page_payroll_status_container">
							<Col className="next_page_payroll_status_icon">
								<ImageComponent src={status?.icon} />
							</Col>
							<Col xl={20} lg={20} md={20} sm={20} xs={20} className="next_page_payroll_status_content">
								<ETypo b1 light>
									{status?.status}
									{status?.name !== 'Check' ? (
										<Link
											to={status?.path ? status?.path : ''}
											target="_blank"
											onClick={() => {
												status?.path &&
													localStorage.setItem(
														APP_VARIABLES.REDIRECT_TO,
														JSON.stringify({
															url: status?.path,
														})
													);
												status?.cleverTapEvent && ClevertapReact.event(status?.cleverTapEvent);
											}}>
											<ETypo medium b1 color="#0E5274">
												Here
											</ETypo>
										</Link>
									) : null}
								</ETypo>
							</Col>
						</Row>
					))}
				</Col>
				<Col xl={24} lg={24} md={20} sm={24} xs={24} className="next_page_payroll_footer_message">
					<ETypo b2 light color="#484B52">
						To view these instructions again please check your payroll status.
					</ETypo>
				</Col>
			</Row>
		</div>
	);
};

export default NextStepsPayroll;
