import React from 'react';
import { useHistory } from 'react-router-dom';
import AddIndividualHeader from '@pages/employees/add-individual-header/add-individual-header';
import ETypo from '@pages/DesignSystem/Typo';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import { ADD_INDIVIDUAL_EMP } from 'src/constants/app-constants';
import './add-individual.scss';
import RadioButton from '@pages/DesignSystem/RadioBox/RadioGroup';

const AddIndividualPresentational = ({
	addIndividualEmpDetails,
	disableNextButton,
	handleNextButton,
	cardAccountDetails,
	handleAccountSelect,
	errorCheck,
	isSaveIndividualLoading,
	handleGenderSelect,
}) => {
	const history = useHistory();
	return (
		<div className="add_individual_emp_container">
			<AddIndividualHeader
				{...{
					showNext: true,
					loadingNext: isSaveIndividualLoading,
					handleNextButton,
					disableNext: disableNextButton || errorCheck,
					handleBackButton: () => history.goBack(),
				}}
			/>
			<div className="add_individual_emp_detail_card">
				<div className="add_individual_emp_text">
					<ETypo medium h4>
						{ADD_INDIVIDUAL_EMP?.PERSONALDETAILS}
					</ETypo>
				</div>
				<div className="add_individual_emp_form">
					{addIndividualEmpDetails?.map(
						({
							label,
							value,
							placeholder,
							onChange,
							required,
							param,
							id,
							Component,
							Option,
							dropDownValue,
							compareDropDownValue,
							prefix,
							leftImage,
							isErrorMessage,
							errorMessage,
							maxLength,
							onBlur,
						}) => (
							<div key={id} className={`add_individual_emp_field ${param}`}>
								<Component
									{...{
										label,
										value,
										placeholder,
										onChange,
										required,
										Option,
										dropDownValue,
										compareDropDownValue,
										prefix,
										leftImage,
										isErrorMessage,
										errorMessage,
										maxLength,
										onBlur,
									}}
								/>
							</div>
						)
					)}
					<RadioButton
						options={[
							{ value: 'Male', isChecked: false },
							{ value: 'Female', isChecked: false },
						]}
						title={'Gender'}
						onchange={handleGenderSelect}>
						{' '}
					</RadioButton>
				</div>
			</div>
			<div className="add_individual_emp_account_card">
				<div className="add_individual_emp_account_card_title">
					<ETypo medium h4>
						{ADD_INDIVIDUAL_EMP?.ORDERC3PAY}
					</ETypo>
				</div>
				<div className="add_individual_emp_account_card_desc margin-top-8">
					<ETypo light b1 color="#737780">
						{ADD_INDIVIDUAL_EMP?.SELECTNO}
					</ETypo>
				</div>
				<div className="add_individual_emp_account_card_select margin-top-24">
					{cardAccountDetails?.map((data) => (
						<div
							className={`add_individual_emp_account ${data?.isSelected ? 'show_box_shadow' : ''}`}
							onClick={() => handleAccountSelect(data?.param)}>
							<Checkbox
								{...{
									checked: data?.isSelected,
									labelPadding: '0px',
									size: 14,
									checkHeight: 12,
								}}
							/>
							<ETypo medium b1>
								{data?.text}
							</ETypo>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AddIndividualPresentational;
