import React from 'react';
import ManageTeamFunctional from './components/manage-team-functional';
import { withReducer } from '@store/reducerLoader';
import reducer from './reducer';
import sagas from './sagas';
import './manage-team.scss';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess = UnauthorizedAccess(ManageTeamFunctional);
const ManageTeam = ({ issetup }) => {
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.roles[0]?.name);
	return <ManageTeamFunctional {...{ currentUser, issetup }} />;
};

const ReducedScreen = withReducer('manageTeam', reducer, sagas)(ManageTeam);

export default ReducedScreen;
