import React, { useState } from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '../index';

const ModelOne = () => {
	const [isOpen, setisOpen] = useState(false);
	return (
		<>
			<EdenredButtons
				{...{
					btnType: 'primary',
					onClick: () => setisOpen(true),
					style: { marginTop: '20px' },
				}}>
				Modal With heart and Rightside button
			</EdenredButtons>
			<Edenredmodal
				{...{
					title: 'Title Here',
					desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
					onOkay: 'abc',
					onCancelButton: 'Cancel',
					onOkayButton: 'Save',
					onCancel: () => setisOpen(false),
					isOpen: isOpen,
					close: () => setisOpen(false),
					btnleft: true,
					heart: true,
					left: true,
					celebration: true,
				}}
			/>
		</>
	);
};

export default ModelOne;
