import EDragger from '@pages/DesignSystem/Dragger';
import React, { useEffect, useState } from 'react';
import EdenredTypo from '@sharedComponent/typography';
import ETypo from '../../DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import '../../DesignSystem/Dragger/edragger.scss';

const UnEmploymentInsuranceDragger = ({
	loadingText1 = 'Uploading Document',
	loadingText2 = 'This can take a few seconds...',
	idleText1 = 'Drag and Drop',
	idleText2 = 'Or Click Here',
	successIcon = focus_state_icons.NewDesignCheck,
	errorIcon = focus_state_icons.NewDesignErro,
	idleIcon = focus_state_icons.hrUpload,
	uploadIcon = focus_state_icons.NewDesignHrUploading,
	uploadDeleteIcon = focus_state_icons.NewDelete,
	onChangeHandler,
	onRemoveUpload,
	className = '',
	draggerStatus = 'new',
	fileFormat = null,
	fileMaxSize = null,
	refValue = () => {},
	loadingState,
}) => {
	const statusVariant = { done: 'done', uploading: 'uploading', error: 'error' };
	const [status, setStatus] = useState(null);
	const [fileInfo, setFilesInfo] = useState(null);
	const getBordersAndIcons = (mode) => {
		if (status === statusVariant.done) {
			return mode === 'border' ? '2px dashed #9A9EA6' : successIcon;
		} else if (status === statusVariant.uploading) {
			return mode === 'border' ? '2px dashed #DFE1E6' : uploadIcon;
		} else if (status === statusVariant.error) {
			return mode === 'border' ? '2px dashed #FF9691' : errorIcon;
		} else {
			return mode === 'border' ? '2px dashed #eff1f5' : idleIcon;
		}
	};
	useEffect(() => {
		setStatus(draggerStatus);
	}, [draggerStatus]);

	useEffect(() => {
		/**
		 * Handle to clear file state after saved successfully.
		 */
		if (loadingState) {
			onRemove();
		}
	}, [loadingState]);

	const onUpload = (info) => {
		setStatus('uploading');
		if (info) {
			setStatus('done');
			setFilesInfo(info);
			onChangeHandler && onChangeHandler(info);
		} else {
			setStatus('new');
			setFilesInfo('new');
		}
	};

	const onRemove = () => {
		document.getElementById('invoiceFilesystem').value = '';
		setStatus('new');
		setFilesInfo('new');
		onRemoveUpload && onRemoveUpload();
	};
	const textComponent = (text) => {
		return (
			<div className="dragger_hint">
				<EdenredTypo className="dragger_text">{text === 'title' ? idleText1 : loadingText1}</EdenredTypo>
				<EdenredTypo className="dragger_text">{text === 'title' ? idleText2 : loadingText2}</EdenredTypo>
				{fileFormat && (
					<ETypo light b2 style={{ textAlign: 'center' }}>
						{fileFormat}
					</ETypo>
				)}
				{fileMaxSize && (
					<ETypo light b2 style={{ textAlign: 'center' }}>
						{fileMaxSize}
					</ETypo>
				)}
			</div>
		);
	};
	return (
		<div className={`main-container-dragger ${className}`}>
			<div className="dragger">
				<div
					className="dragger-container"
					style={{
						border: getBordersAndIcons('border'),
					}}>
					<div className="imageuploadIndicator">
						<ImageComponent src={getBordersAndIcons()}></ImageComponent>
					</div>
					{status === 'new' ? (
						textComponent('title')
					) : status === 'uploading' ? (
						textComponent('upload')
					) : status === 'done' ? (
						<div className="dragger_hint">
							<EdenredTypo className="dragger_text_2">
								{fileInfo?.name}
								<ImageComponent src={uploadDeleteIcon} onClick={onRemove} className="deleteIcon"></ImageComponent>
							</EdenredTypo>

							{fileInfo && fileInfo?.size && (
								<EdenredTypo className="dragger_subtext">{(fileInfo?.size / 1024 / 1024).toFixed(2)} MB</EdenredTypo>
							)}
						</div>
					) : (
						<div className="dragger_hint">
							<EdenredTypo className="dragger_error_text">Error...</EdenredTypo>
						</div>
					)}
				</div>
				<input
					ref={refValue}
					type="file"
					id="invoiceFilesystem"
					accept=".xls, .xlsx"
					// disabled={status === statusVariant.done || status === statusVariant.error}
					className="file-input"
					onChange={(e) => onUpload(e.target.files[0])}></input>
			</div>
		</div>
	);
};

export default UnEmploymentInsuranceDragger;
