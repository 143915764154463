import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { Col, Spin } from 'antd';
import React from 'react';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { LoadingOutlined } from '@ant-design/icons';
import { checkApprovePopup } from '@constants/app-constants';

function MobilePopupCard({ visible, message, subMessage, closePopup, handleOnClick, loading, leave }) {
	const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#ffffff' }} spin />;
	const closeModal = () => {
		closePopup();
	};
	const click = (id) => {
		handleOnClick(id);
	};
	return (
		<>
			{visible ? (
				<>
					<Col className="main-mob-parent-popup1">
						<div className="icon-centered-leaveap">
							<ImageComponent src={not_focus_icons.Rectanglemob} />
						</div>
						<div className="icon-centered-leaveap1">
							{checkApprovePopup.includes(leave) && message == 'Do you want to approve this leave?' ? (
								<ImageComponent src={not_focus_icons.Wrong} style={{ width: '15px', height: '15px' }} onClick={() => closeModal()} />
							) : null}
						</div>

						<div className={checkApprovePopup.includes(leave) ? 'message-leave-mob1' : 'message-leave-mob'}>
							<EdenredTypo className="b1 bold " style={{ color: '#00344E', fontSize: '18px' }}>
								{checkApprovePopup.includes(leave) && message == 'Do you want to approve this leave?' ? (
									'Do You Want To Approve This Leave?'
								) : (
									<>{message}</>
								)}
							</EdenredTypo>
							<div>
								{checkApprovePopup.includes(leave) && message == 'Do you want to approve this leave?' ? (
									<EdenredTypo style={{ fontSize: '16px' }}>
										Pick if you want to deduct the days from annual leave or unpaid leave below
									</EdenredTypo>
								) : (
									''
								)}
							</div>
						</div>
						<div className="mob-btn-leave-ap">
							{checkApprovePopup.includes(leave) && message == 'Do you want to approve this leave?' ? (
								<div>
									<EdenredButton className="cancel-button-leaveapprove" onClick={() => click('Umrah')}>
										Unpaid
									</EdenredButton>
								</div>
							) : (
								<div>
									<EdenredButton className="cancel-button-leaveapprove" onClick={() => closeModal()}>
										Cancel
									</EdenredButton>
								</div>
							)}

							{message == 'Do you want to approve this leave?' ? (
								<div>
									{checkApprovePopup.includes(leave) ? (
										<>
											<EdenredButton className="accept-button-leaveapprove" onClick={() => click('Annual')}>
												Annual
											</EdenredButton>
										</>
									) : (
										<>
											{loading ? (
												<Spin indicator={antIcon} />
											) : (
												<EdenredButton className="accept-button-leaveapprove" onClick={() => click()}>
													Approve
												</EdenredButton>
											)}
										</>
									)}
								</div>
							) : (
								<div>
									<EdenredButton className="accept-button-leaveapprove" onClick={() => click()}>
										{loading ? <Spin indicator={antIcon} /> : 'Reject'}
									</EdenredButton>
								</div>
							)}
						</div>
					</Col>
				</>
			) : null}
		</>
	);
}

export default MobilePopupCard;
