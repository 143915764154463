import React from 'react';
import { Col, Row } from 'antd';
import CompanySetting from '@pages/Leave-Management/company-settings/company-setting';
import EdenredTypo from '@sharedComponent/typography';
import SetupHeader from '@pages/setup-Screen/components/setup-header';

const SetUpPresentational = () => {
	return (
		<>
			<SetupHeader />
			<Row className="company_setup">
				<Col className="company_setup_container">
					<CompanySetting isSetUp={true} />
				</Col>
			</Row>
		</>
	);
};

export default SetUpPresentational;
