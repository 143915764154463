import React from 'react';

import './typography.scss';

const EdenredTypo = ({ children, className = '', ...rest }) => {
	{
		/* props for this components are h1(headerStyle) and regular(fontWeightStyle)
            example code ==>    
                <EdenredTypo h1 regular>EdenredTypo</EdenredTypo>
        */
	}
	const [headerStyle = 'h1', fontWeightStyle = 'regular'] = Object.keys(rest);
	return (
		<span {...rest} className={`edenredTypo ${headerStyle} ${fontWeightStyle} ${className}`}>
			{children}
		</span>
	);
};

export default EdenredTypo;
