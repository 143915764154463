import React from 'react';
import { Row, Col, Modal } from 'antd';
import './replacement-popup.scss';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';

export default function SuccessPopup({ visible, message, closePopup, className }) {
	// const []

	// console.log('Insidepopup');

	const closeModal = () => {
		closePopup();
	};
	return (
		<Modal
			className={`modal_container replacementCardModal ${className}`}
			centered
			footer={false}
			closable={false}
			maskClosable={false}
			bodyStyle={{ height: '194px', padding: '0px' }}
			maskStyle={{ backgroundColor: 'white', opacity: '0.8' }}
			width="450px"
			visible={visible}
			onOk={() => false}
			onCancel={() => true}>
			<div className="main_popup_container">
				<div className="message_container">
					{!message.subMessage ? (
						<EdenredTypo className="popup_heading_text">{message.titleMessage}</EdenredTypo>
					) : (
						<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
							<EdenredTypo className="popup_heading_text">{message.titleMessage}</EdenredTypo>
							<EdenredTypo className="popup_message_text">{message.subMessage}</EdenredTypo>
						</div>
					)}
				</div>
				<div className="routing_container" style={{ cursor: 'pointer' }} onClick={() => closeModal()}>
					<span>
						<EdenredTypo className="back_router_text">Ok</EdenredTypo>
					</span>
				</div>
			</div>
		</Modal>
	);
}
