import React from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import ETypo from '@pages/DesignSystem/Typo';
import HelperBanner from './HelperBanner';
import { INSURANCE_PLAN_CONSTANTS } from './constants.jsx';
import './index.scss';

function InsurancePlan() {
	const history = useHistory();
	return (
		<div className="monthly-plan-wrapper">
			<div>
				<section className="header">
					<ETypo h2 bold>
						{INSURANCE_PLAN_CONSTANTS.headerText}
					</ETypo>
					<EdenredButtons
						onClick={() => {
							ClevertapReact.event('Continue_MonthlyPlan');
							history.push({
								pathname: '/un-employment-insurance/terms-and-conditions',
								state: INSURANCE_PLAN_CONSTANTS.invoicePlan,
							});
						}}>
						Next
					</EdenredButtons>
				</section>
				<section className="plan-details">
					<div className="plan-details-annual">
						<div className="plan-details-header">
							<ETypo bold h5>
								{INSURANCE_PLAN_CONSTANTS.policyCoverage}
							</ETypo>
							<ETypo h5 medium>
								{INSURANCE_PLAN_CONSTANTS.planType}
							</ETypo>
						</div>
						<div className="plan-details-content">
							{INSURANCE_PLAN_CONSTANTS.planContents.map((plan) => {
								return (
									<div className="plan-row" key={plan.id}>
										<div className="plan-label">{plan.label}</div>
										<div className="plan-value">{plan.value}</div>
									</div>
								);
							})}
						</div>
					</div>
					<HelperBanner text={INSURANCE_PLAN_CONSTANTS.helperBannerText} />
				</section>
			</div>
		</div>
	);
}

function MonthlyPlanRow({ title, value, type = 'CONTENT', index }) {
	const isEven = index % 2 === 0 ? true : false;
	return (
		<>
			<p className={`title ${type} ${isEven ? 'even' : 'odd'}`}>{title}</p>
			<p className={`value ${type} ${isEven ? 'even' : 'odd'}`}>{value}</p>
		</>
	);
}

export default InsurancePlan;
