import React from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';

const ReplaceCardNextStep = () => {
	const { state } = useLocation();
	const history = useHistory();

	const statuses = [
		{
			name: 'Check',
			icon: icons.focus_state.hrDocumentUptoDate,
			status: 'Your verifier needs to approve the request',
		},
		{
			name: 'Pay Salary',
			icon: icons.focus_state.TimeTransper,
			status: 'You’ll receive your replacement card at your office within 7 working days',
		},
		{
			name: 'Upload Proof',
			icon: icons.focus_state.RouteSquare,
			status: `You can continue to run payroll for ${state?.employeeName}. Track your card order status`,
		},
	];
	return (
		<>
			<EdenredHeader />
			<Row className="next_page_employees_container">
				<Col className="next_page_employees_header">
					<ETypo bold h2>
						Card Replacement
					</ETypo>
					<div>
						<EdenredButtons
							onClick={() => history.push('/employees/manage_employee')}
							btnType="secondary"
							leftIcon={<img src={images?.leftsarrow} />}>
							Return to Employee Profile
						</EdenredButtons>
					</div>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="next_page_employees_status">
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<ETypo medium h4>
							Next Steps:
						</ETypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<ETypo light b3 color="#484B52">
							You’ve added {state?.employeeName} as an employee
							{/* You’ve added Meredith Grey as an employee */}
							{/* You've requested a replacement card for {state?.tableData?.employeeName}. */}
						</ETypo>
					</Col>
					{/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<ETypo light b3 color="#484B52">
							If the card is compromised, please ask the employee to block it immediately by calling 600567772.
						</ETypo>
					</Col> */}
					{statuses?.map((status, index) => (
						<Row key={index} className="next_page_employees_status_container">
							<Col className="next_page_employees_status_icon">
								<ImageComponent src={status?.icon} />
							</Col>
							<Col xl={20} lg={20} md={20} sm={20} xs={20} className="next_page_employees_status_content">
								<EdenredTypo>
									{status?.status}
									{status?.name === 'Upload Proof' ? (
										<Link to="/employees/card/order/status" style={{ paddingLeft: '5px' }}>
											HERE
										</Link>
									) : null}
								</EdenredTypo>
							</Col>
						</Row>
					))}
				</Col>
				{/* <Row className="next_page_employees_button_row">
					<Col xl={5} lg={5} md={5} sm={24} xs={24} className="next_page_employees_button">
						<Link to="/employees/manage_employee">
							<EdenredButton type="outline-g">Return to Manage Employees</EdenredButton>
						</Link>
					</Col>
				</Row> */}
			</Row>
		</>
	);
};

export default ReplaceCardNextStep;
