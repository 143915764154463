import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { APP_VARIABLES, checkBalanceLeaveTypeArray, checkOverlappingleaves } from '@constants/app-constants';
import { DATE_FORMAT_LEAVE } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import ViewSubmittedProof from '@pages/payroll/proof-of-transfer/components/view-submitted-proof';
import { checkLeaveTypeArray } from '@constants/app-constants';
import Modalcarousel from '@sharedComponent/modal-carousel';
import { checkHrSubscription, checkWithClaims } from '@helpers';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Toast from '@pages/DesignSystem/Toast';
import { HrClaims } from '@constants/index';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import UserService from 'src/services/user-service';
import ClevertapReact from 'src/utils/clever-tap';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import OverlapCard from './overlapCard';
import { DateVaild } from '../index';
import LeaveToApproveNoRecords from './leave-to-approve-norecords';
import './leave-to-approve.scss';
function LeavesToApprovePresentational({
	data,
	isModalVisible,
	setIsModalVisible,
	showModal,
	dataOverlap,
	popupState,
	handlePopupClose,
	popupMessageState,
	approveMessage,
	rejectMessage,
	handlePopupClick,
	handlePopupClick1,
	overLappingLeaveLoader,
	Department,
	userNavigationMenu,
	value,
	showToast,
	setShowToast,
}) {
	const [viewLeaveApprove, setViewLeaveApprove] = useState(false);
	const [image, setImage] = useState(null);
	const [attachmentname, setAttachmentname] = useState();
	const openSubmittedProofModal = (item) => {
		ClevertapReact.event('Leave_DocAttachment', { leaveType: item?.leaveType });
		setViewLeaveApprove(true);
		setImage(item.attachment);
		setAttachmentname(item.attachmentLinkName);
	};
	const [popupNew, setPopupNew] = useState(false);
	const [nextSlide, setNextSlide] = useState(0);
	const MenuAPI = new UserService();
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	const hrClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims) || [];
	const isLeaveBalanceApproveExists = checkWithClaims(hrClaims, HrClaims?.APPROVAL_WORKFLOW) || false;
	useEffect(() => {
		if (!isOnlyEmployeeExits && (!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('leave')) && isSubcribed) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);

	const handleClose = () => setPopupNew(false);
	const dataObj = [
		{
			image: not_focus_icons.Leave01,
			text: 'Manage leave requests in one place',
		},
		{
			image: not_focus_icons.Leave02,
			text: 'Setup up to 3 levels of approval workflow',
		},
		{
			image: not_focus_icons.Leave2,
			text: 'Request leaves from C3Pay App or Edenred Portal',
		},
		{
			image: not_focus_icons.Leave3,
			text: 'View overlapping leaves from the same departments',
		},
		{
			image: not_focus_icons.Leave4,
			text: 'Approve or reject leave requests',
		},
	];
	const handleSlideChange = (flag, status) => {
		let payload = {
			...navbarClaims,
			tourGuide: navbarClaims?.tourGuide ? `${navbarClaims?.tourGuide},${status}` : status,
		};
		if (nextSlide < dataObj?.length - 1) {
			setNextSlide((prev) => prev + 1);
		}
		if (flag == false) {
			MenuAPI.postUserNavigationMenu(payload)
				.then((res) => {
					userNavigationMenu();
					setPopupNew(false);
				})
				.catch((error) => {
					setPopupNew(false);
				});
		}
	};
	const history = useHistory();
	const closeSubmittedProofModal = () => setViewLeaveApprove(false);
	const viewSubmittedProofStylesWithoutHeight = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'relative',
			top: '0px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'auto',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			width: '415px',
			minWidth: '415px',
			boxShadow: 'none',
		},
	};

	const closePopup = () => {
		setShowToast(false);
	};
	return (
		<>
			<Col className="Leave_approve_main_container">
				<div>
					{!data || data?.length === 0 ? (
						<LeaveToApproveNoRecords
							onClickAddEditLeaveBalance={() => {
								history.push('/add-edit-leave-balance');
							}}
						/>
					) : (
						<>
							<Row className="wps_payroll_container_page">
								<div className="page">
									<ETypo h2 bold>
										Leaves to Approve
									</ETypo>
								</div>
								<Row>
									{data?.length !== 0 && isLeaveBalanceApproveExists && (
										<EdenredButtons
											{...{
												onClick: () => history.push('/add-edit-leave-balance'),
												btnType: 'secondary',
												style: { padding: '8px 16px 8px 16px', marginTop: '5px', marginBottom: '0px' },
											}}>
											Add/Edit Balances
										</EdenredButtons>
									)}
									<EdenredButtons
										{...{
											onClick: () => history.push('/leaves/request_leave'),
											btnType: 'primary',
											style: {
												padding: '7px 16px 7px 16px',
												marginTop: '5px',
												marginBottom: '0px',
												marginLeft: '8px',
												lineHeight: '15px',
											},
										}}>
										Request Leave
									</EdenredButtons>
								</Row>
							</Row>
							<div className="card_component">
								{data?.map((item) => (
									<Col className="each-card" key={item?.employeeCode}>
										<Row className="card-info-container">
											<Col className="card-name">
												<ETypo
													className="heading-color"
													h3
													bold
													onClick={() =>
														history.push({
															pathname: '/employees/viewemployee',
															state: {
																selectedTab: '4',
																tableData: { employeeId: item?.employeeCode, Id: item?.employeeId },
															},
														})
													}>
													{item?.employeeName || '-'}
												</ETypo>
												<div className="employeeid-department">
													<ETypo b2 light className="employeeid-department-font">
														{item?.employeeId || '-'}
													</ETypo>
													<ETypo b2 light className="employeeid-department-font">
														{!item?.departmentName ? '-' : <span> {item?.departmentName}</span>}
													</ETypo>
												</div>
											</Col>
											<Col className="card-status">
												<div className={`status-container ${item?.leaveType}`}>
													<div className="status-image-container">
														<ImageComponent
															className="status-image"
															src={`${focus_state_icons?.[item?.leaveType?.replace(/\s/g, '')]}`}
														/>
														<Col className="leave-type-container">
															<ETypo b3 light className="heading-color">
																Leave Type
															</ETypo>
															<ETypo b2 medium className="leave-status-type-font heading-color">
																{item?.leaveType}
															</ETypo>
														</Col>
													</div>
												</div>
											</Col>
										</Row>
										<Row className="employee-section-container">
											<Row justify="space-between" className="Date-info-container">
												<Row>
													<Col className="Date-container">
														<ETypo b2 medium className="heading-color">
															Date
														</ETypo>
														<ETypo b2 light className="heading-color date-view">
															{moment(item?.startDate).format(DATE_FORMAT_LEAVE.DD_MM_YYYY)} -{' '}
															{moment(item?.endDate).format(DATE_FORMAT_LEAVE.DD_MM_YYYY)}
														</ETypo>
													</Col>
													<Col className="Date-container">
														<ETypo b2 medium className="heading-color">
															Days
														</ETypo>
														<ETypo b2 light className="heading-color date-view">
															{item?.leaveDays} {item?.leaveDays === 1 ? 'Day' : 'Days'}
														</ETypo>
													</Col>
													<Col className="Date-container">
														{checkBalanceLeaveTypeArray?.includes(item?.leaveType) ? null : (
															<>
																<ETypo b2 medium className="heading-color">
																	Balance
																</ETypo>
																<ETypo
																	b2
																	light
																	color={item?.remainingBalance < 0 ? '#ff9691' : '#00344e'}
																	className="date-view">
																	{!item?.remainingBalance ? 0 : item?.remainingBalance}{' '}
																	{item?.remainingBalance === 1 ? 'Day' : 'Days'}
																</ETypo>
															</>
														)}
													</Col>
												</Row>
												<Row className="overlapping-Leaves">
													{checkOverlappingleaves?.includes(item?.leaveType) ? null : (
														<Row className="overlapingContainer">
															<a onClick={() => showModal(item)}>
																<ETypo b2 medium style={{ color: '#0E8AFF' }}>
																	Overlapping Leaves
																</ETypo>
															</a>
															<ImageComponent src={focus_state_icons.leaveArrow} className="leave-arrow-style" />
														</Row>
													)}
												</Row>
											</Row>
										</Row>
										{checkLeaveTypeArray?.includes(item?.leaveType) ? null : (
											<Row className="employee-visa-passport-section-container">
												<Row className="visa-passport-info-container">
													<DateVaild {...{ leaveToApproveDates: item }} />
												</Row>
											</Row>
										)}
										<Row className="notes-and-attachment-container">
											<Col className="notes-container">
												<ETypo b2 medium className="heading-color">
													Notes
												</ETypo>
												<Col className="flexRow">
													<p className={!item.note ? 'text21' : 'text2'}>{!item.note ? 'No Note' : item?.note}</p>
												</Col>
											</Col>
											<Col className="attachment-container">
												<ETypo b2 medium className="heading-color">
													Attachment
												</ETypo>
												<Col className="flexRow">
													{!item?.attachment ? (
														<p className="text31">No attachment</p>
													) : (
														<>
															<ImageComponent src={focus_state_icons.paperclip} className="attachment-icon" />
															<ETypo
																b1
																bold
																className="attachment-text"
																onClick={() => openSubmittedProofModal(item)}
																style={{ cursor: 'pointer' }}>
																view
															</ETypo>
														</>
													)}
												</Col>
											</Col>
										</Row>
										{checkLeaveTypeArray?.includes(item?.leaveType) ? (
											<Row className="employee-visa-passport-section-container"></Row>
										) : null}

										<Row className="employee-leave-status-and-button-container">
											<Row className="approve-status-style">
												<ImageComponent
													src={item?.canApproveReject ? focus_state_icons.SuccessIndicator : focus_state_icons.pending}
													className="status-style"
												/>
												<div style={{ width: '81%', lineHeight: 'normal' }}>
													<ETypo b2 light className="heading-color date-view">
														{item?.approvalStatus}
													</ETypo>
												</div>
											</Row>
											<Row className="leave-approve-button">
												<EdenredButtons
													{...{
														btnType: 'secondary',
														disabled: !item.canApproveReject,
														secondaryactive: false,
														className: 'reject-button-style',
														onClick: () => handlePopupClick(rejectMessage, item, 'Reject'),
														style: {
															padding: '8px, 16px, 8px, 16px',
															marginBottom: '0px',
															lineHeight: '15px',
														},
													}}>
													Reject
												</EdenredButtons>
												<EdenredButtons
													{...{
														btnType: 'primary',
														onClick: () => handlePopupClick(approveMessage, item, 'Approve'),
														disabled: !item.canApproveReject,
														className: 'approve-button-style',
														style: {
															padding: '8px, 16px, 8px, 16px',
															marginBottom: '0px',
															marginLeft: '10px',
															lineHeight: '15px',
														},
													}}>
													Approve
												</EdenredButtons>
											</Row>
										</Row>
									</Col>
								))}
							</div>
						</>
					)}
				</div>
				<div className="leave-approve-outer-web">
					<OverlapCard
						{...{
							title: 'Overlapping Leaves',
							subtitle: Department,
							isModalVisible,
							setIsModalVisible,
							dataOverlap: dataOverlap,
							loader: overLappingLeaveLoader,
						}}
					/>
				</div>
				<div className="leave-approve-outer-mob"></div>
				<Edenredmodal
					{...{
						isOpen: popupState,
						title: popupMessageState,
						onCancelButton: 'Cancel',
						onOkayButton: popupMessageState?.toString()?.includes('reject') ? 'Reject' : 'Approve',
						onOkay: handlePopupClick1,
						textcenter: true,
						onCancel: handlePopupClose,
						close: handlePopupClose,
					}}
				/>
			</Col>
			<ViewSubmittedProof
				viewSubmittedProof={viewLeaveApprove}
				setViewSubmittedProofModal={setViewLeaveApprove}
				openSubmittedProofModal={openSubmittedProofModal}
				closeSubmittedProofModal={closeSubmittedProofModal}
				customStyles={viewSubmittedProofStylesWithoutHeight}
				leaveToApproveImage={image}
				attachmentname={attachmentname}
				leaveToApproveDownload={true}
				buttonText="Download Attachment"
				buttonModalStyle={{
					display: 'none',
				}}
			/>

			<Toast
				visible={showToast}
				color="#8EF9B3"
				textcolor="#484B52"
				clearPopUp={closePopup}
				size={value?.status === 'Approved' ? '450px' : '300px'}>
				{value?.status === 'Approved'
					? `${value?.leaveType}  Leave for ${value?.employeeName} successfully approved`
					: 'Leave request rejected'}
			</Toast>
			{/* <Modalcarousel
				{...{ visible: popupNew, handleClose, data: dataObj, handleSlideChange, initialSlide: nextSlide, status: 'leave' }}></Modalcarousel> */}
		</>
	);
}

export default LeavesToApprovePresentational;
