import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Card } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { icons } from '@assets/icons/index';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButton from '@sharedComponent/button';
import EdenredModal from '@sharedComponent/modal';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ContentLoader from '@sharedComponent/content-loader';
import TeamPermission, { CREATE_USER } from '@pages/team-permission/services';
import { checkHrSubscription, getObjectFromArray } from '@helpers';
import { APP_VARIABLES, CLEVER_TAP_EVENTS, INVITE_TEAMS_CONSTANTS, roleNames } from '@constants/app-constants';
import Chips from '@pages/DesignSystem/Chips';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import UserService from 'src/services/user-service';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import action from './action';
import './permission.scss';
import ClevertapReact from 'src/utils/clever-tap';

const PayrollPermissionPresentational = ({ afterOpenModal, closeModal, visible, getDeleterole, username, isSetup }) => {
	const { state, button } = useLocation();
	const [payrollClaimsList, setPayrollClaimsList] = useState([]);
	const [teamClaimsList, setTeamClaimsList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isClaimsLoaded, setIsClaimLoaded] = useState(true);
	const [hrClaimList, setHrClaimList] = useState([]);
	const [rolesId, setRolesId] = useState([]);
	const [addclaim, setaddclaim] = useState([]);
	const [button1, setbutton] = useState(false);
	const [button2, setbutton2] = useState(false);
	const [button3, setbutton3] = useState(false);
	const [button4, setbutton4] = useState(false);
	const [button5, setbutton5] = useState(false);
	const [button6, setbutton6] = useState(false);
	const [button7, setbutton7] = useState(false);
	const [buttonEnable1, setbuttonEnable1] = useState(false);
	const [buttonEnable2, setbuttonEnable2] = useState(false);
	const [buttonEnable3, setbuttonEnable3] = useState(false);
	const [buttonEnable4, setbuttonEnable4] = useState(false);
	const [buttonEnable5, setbuttonEnable5] = useState(false);
	const [isButtonClicked, setIsButtonClicked] = useState(false);
	const [onHover, setOnHover] = useState({
		onTeamsHover: false,
		onPayrollHover: false,
		onHrHover: false,
	});
	const user = false;
	const verifier = false;
	const userAPI = new UserService();
	const api = new TeamPermission();
	const dispatch = useDispatch();
	const save_Button = (value) => {
		if (value === INVITE_TEAMS_CONSTANTS?.CANCEL_BUTTON) {
			isSetup ? history.push('/admissionpermission') : history.push('/invite_team_member');
		} else {
			setIsButtonClicked(true);
		}
	};
	const closeTeamsPop = () => setIsButtonClicked(false);

	const changeClaims = (checked, value) => {
		switch (value?.title) {
			case 'Manage Employees':
				setbutton2(checked);
				break;
			case 'Manage Payroll':
				setbutton3(checked);
				break;
			case 'Manage Documents':
				setbuttonEnable1(checked);
				break;
			case 'Manage Announcements':
				setbuttonEnable2(checked);
				break;
			case 'Manage Roles & Permissions':
				setbuttonEnable5(checked);
				break;
			default:
				break;
		}
		if (checked) {
			addclaim.push(value);
		} else {
			for (var i = 0; i < addclaim.length; i++) {
				if (value.title === addclaim[i].title) {
					const index = addclaim.indexOf(value);
					if (index > -1) {
						addclaim.splice(index, 1);
					}
				}
			}
		}
		setbutton(addclaim?.length > 0);
	};
	const changeClaims1 = (checked, value) => {
		switch (value?.title) {
			case 'Approve Payroll Requests':
				setbutton4(checked);
				break;
			case 'Approve Card Cancellation':
				setbutton5(checked);
				break;
			case 'Approve Card Orders':
				setbutton6(checked);
				break;
			case 'Approve Card Replacement':
				setbutton7(checked);
				break;
			case 'Manage Leaves':
				setbuttonEnable3(checked);
				break;
			case 'Setup Leave Approval Workflow and Edit Leave Balances':
				setbuttonEnable4(checked);
				break;
			default:
				break;
		}
		if (checked) {
			addclaim.push(value);
		} else {
			for (var i = 0; i < addclaim.length; i++) {
				if (value.title === addclaim[i].title) {
					const index = addclaim.indexOf(value);
					if (index > -1) {
						addclaim.splice(index, 1);
					}
				}
			}
		}
		setbutton(addclaim?.length > 0);
	};
	const handleSelectAll = (roles, value) => {
		addclaim.push(...roles);
		if (value === 'teams') {
			setbuttonEnable5(true);
		}
		if (value === 'payroll') {
			setbutton2(true);
			setbutton3(true);
			setbutton4(true);
			setbutton5(true);
			setbutton6(true);
			setbutton7(true);
		}
		if (value === 'hr') {
			setbuttonEnable1(true);
			setbuttonEnable2(true);
			setbuttonEnable3(true);
			setbuttonEnable4(true);
		}
		setbutton(addclaim?.length > 0);
	};
	useEffect(() => {
		getallClaims();
		getRoles();
	}, []);
	const getallClaims = () => {
		userAPI
			.getAllClaimsApi()
			.then((response) => {
				setHrClaimList(response.data.hrClaims);
				setPayrollClaimsList(response.data.payrollClaims);
				setTeamClaimsList(response.data.teamClaims);
				setIsClaimLoaded(false);
			})
			.catch((err) => {
				setIsClaimLoaded(false);
			});
	};
	const getRoles = async () => {
		await userAPI
			.getRolesId()
			.then((response) => {
				setRolesId(response?.data?.roles);
			})
			.catch((err) => {
				setRolesId([]);
			});
	};
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const Leaves = ({ leave, changeClaims }) => {
		return (
			<Row className="content_list1">
				<div>
					{leave.title === 'Manage Leaves' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={buttonEnable3}
							onChange={(e) => changeClaims1(e, leave)}
						/>
					) : (
						''
					)}
					{leave.title === 'Setup Leave Approval Workflow and Edit Leave Balances' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={buttonEnable4}
							onChange={(e) => changeClaims1(e, leave)}
						/>
					) : (
						''
					)}
					{leave.title === 'Manage Documents' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={buttonEnable1}
							onChange={(e) => changeClaims(e, leave)}
						/>
					) : (
						''
					)}
					{leave.title === 'Manage Announcements' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={buttonEnable2}
							onChange={(e) => changeClaims(e, leave)}
						/>
					) : (
						''
					)}
					{leave.title === 'Manage Roles & Permissions' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={buttonEnable5}
							onChange={(e) => changeClaims(e, leave)}
						/>
					) : (
						''
					)}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Col className="value">
						<ETypo b1 medium>
							{leave.title}
						</ETypo>
					</Col>
					<Col className="value1">
						<ETypo b1 light>
							{leave.description}
						</ETypo>
					</Col>
				</div>
			</Row>
		);
	};
	const Permission = ({ role, changeClaims }) => {
		return (
			<Row className={user ? 'content_list' : 'content_list1'}>
				<div>
					{role.title === 'Manage Employees' ? (
						<Checkbox
							className="new-design-roles"
							tabIndex={3}
							borderWidth={1}
							labelPadding="0px"
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={button2}
							onChange={(e) => changeClaims(e, role)}
						/>
					) : null}
					{role.title === 'Manage Payroll' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={button3}
							onChange={(e) => changeClaims(e, role)}
						/>
					) : null}
					{role.title === 'Approve Payroll Requests' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={button4}
							onChange={(e) => changeClaims1(e, role)}
						/>
					) : null}
					{role.title === 'Approve Card Cancellation' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={button5}
							onChange={(e) => changeClaims1(e, role)}
						/>
					) : null}
					{role.title === 'Approve Card Orders' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={button6}
							onChange={(e) => changeClaims1(e, role)}
						/>
					) : null}
					{role.title === 'Approve Card Replacement' ? (
						<Checkbox
							borderWidth={1}
							labelPadding="0px"
							className="new-design-roles"
							tabIndex={3}
							borderColor="#00344E"
							borderRadius={4}
							size={16}
							checked={button7}
							onChange={(e) => changeClaims1(e, role)}
						/>
					) : null}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Col className="value">
						<ETypo b1 medium>
							{role?.title}
						</ETypo>
					</Col>
					<Col className="value1">
						<ETypo b1 light>
							{role?.description}
						</ETypo>
					</Col>
				</div>
			</Row>
		);
	};
	let history = useHistory();
	const handleMouseHover = (value, state) => {
		setOnHover({ [state]: value });
	};
	const saveClicked = () => {
		setLoading(true);
		const { name, username, email, emp_Id, emp_Code, checkEmail } = state?.value;
		var roleId = [];
		ClevertapReact.event(CLEVER_TAP_EVENTS.SendInvite_InviteTeamMember);
		const employeeId = emp_Code || '';
		const nestUserName = username;
		const userFirstLastName = name || '';
		const newPayrollLists = [...payrollClaimsList];
		let hrClaims = addclaim?.filter((o1) => hrClaimList?.some((o2) => o1?.value === o2?.value))?.map((item) => item?.value);
		let payrollClaims = addclaim?.filter((o1) => payrollClaimsList?.some((o2) => o1?.value === o2?.value))?.map((item) => item?.value);
		let teamClaims = addclaim?.filter((o1) => teamClaimsList?.some((o2) => o1?.value === o2?.value))?.map((item) => item?.value);
		if (hrClaims?.length > 0) {
			roleId.push(getObjectFromArray(rolesId, 'name', roleNames?.HR)?.id);
		}
		if (payrollClaims?.length > 0) {
			roleId.push(getObjectFromArray(rolesId, 'name', roleNames?.PAYROLL)?.id);
		}
		if (teamClaims?.length > 0) {
			roleId.push(getObjectFromArray(rolesId, 'name', roleNames?.MANAGE_ROLES)?.id);
		}
		if (
			hrClaims?.length === hrClaimList?.length &&
			payrollClaims?.length === newPayrollLists?.length &&
			teamClaims?.length === teamClaimsList?.length
		) {
			roleId.push(getObjectFromArray(rolesId, 'name', roleNames?.SUPER_ADMIN)?.id);
		}
		if (state?.param === 'exisiting') {
			roleId.push(getObjectFromArray(rolesId, 'name', roleNames?.EMPLOYEE)?.id);
		}
		api.services(CREATE_USER, { username }, { employeeId, email, roleId, userFirstLastName, nestUserName, payrollClaims, hrClaims, teamClaims })
			.then((res) => {
				const payload = {
					email: email,
					emp_Id: emp_Id,
				};
				state?.param === 'exisiting' && checkEmail === '' && dispatch(action?.creators?.updateEmployeeEmailStart(payload));
				setIsButtonClicked(false);
				setLoading(false);
				history.push({
					pathname: isSetup ? '/teamspermission' : '/manage_team',
					state: {
						response: INVITE_TEAMS_CONSTANTS?.NEW_ROLE_SAVED,
						type: 'Invite',
					},
				});
			})
			.catch((err) => {
				setLoading(false);
			});
	};
	const roleState = (state) => {
		switch (state) {
			case 'Manage Employees':
				return button2;
			case 'Manage Payroll':
				return button3;
			case 'Approve Payroll Requests':
				return button4;
			case 'Approve Card Cancellation':
				return button5;
			case 'Approve Card Orders':
				return button6;
			case 'Approve Card Replacement':
				return button7;
			case 'Manage Leaves':
				return buttonEnable3;
			case 'Setup Leave Approval Workflow and Edit Leave Balances':
				return buttonEnable4;
			case 'Manage Documents':
				return buttonEnable1;
			case 'Manage Announcements':
				return buttonEnable2;
			case 'Manage Roles & Permissions':
				return buttonEnable5;
			default:
				return false;
		}
	};
	return (
		<>
			<EdenredHeader cancel />
			<Row xl={24} md={24} className="new_mainContainers">
				{isClaimsLoaded ? (
					<div className="loader-role">
						<ContentLoader />
					</div>
				) : (
					<>
						<Col xl={24} md={24} className="header_content">
							<ETypo bold h2>
								Assign Permissions To <span>{state?.value?.username}</span>
							</ETypo>
							<div className="new_desgin_new_roles_button">
								<EdenredButtons
									className="new_desgin_cancel"
									btnType="secondary"
									leftIcon={<ImageComponent src={focus_state_icons.InviteCancelButton} />}
									onClick={() => save_Button(INVITE_TEAMS_CONSTANTS.CANCEL_BUTTON)}>
									{INVITE_TEAMS_CONSTANTS.CANCEL_BUTTON}
								</EdenredButtons>
								<EdenredButtons
									btnType="primary"
									disabled={(!user || !verifier) && !button1}
									rightIcon={
										<ImageComponent
											src={
												(!user || !verifier) && !button1 ? focus_state_icons.disabledRightArrow : focus_state_icons.RightArrow
											}
										/>
									}
									onClick={() => save_Button(INVITE_TEAMS_CONSTANTS.ADD_ADMIN_BUTTON)}>
									{INVITE_TEAMS_CONSTANTS.ADD_ADMIN_BUTTON}
								</EdenredButtons>
							</div>
						</Col>
						<Col xl={24} md={24} className="new_design_new_roles">
							<Col
								xl={24}
								md={24}
								className="header_content1"
								onMouseEnter={() => handleMouseHover(true, 'onTeamsHover')}
								onMouseLeave={() => handleMouseHover(false, 'onTeamsHover')}>
								<Chips classname="new_desgin_chip" color="#CCECE6">
									Roles & Permissions
								</Chips>
								{onHover?.onTeamsHover && (
									<p className="hover_select_all" onClick={() => handleSelectAll(teamClaimsList, 'teams')}>
										Select All
									</p>
								)}
							</Col>
							{teamClaimsList?.map((role) => (
								<Col xl={24} md={24} className={`toggle_content`}>
									<Leaves leave={role} changeClaims={changeClaims} />
									<Col xl={24} md={24} lg={24} xs={24} sm={24} className="align_horizontal">
										<Divider style={{ margin: '0px 0px' }} />
									</Col>
								</Col>
							))}
							<Col xl={24} md={24} className="userbackground">
								<Col
									xl={24}
									md={24}
									className="header_content1"
									onMouseEnter={() => handleMouseHover(true, 'onPayrollHover')}
									onMouseLeave={() => handleMouseHover(false, 'onPayrollHover')}>
									<Chips classname="new_desgin_chip" color="#FEF5E8">
										Payroll
									</Chips>
									{onHover.onPayrollHover && (
										<p className="hover_select_all" onClick={() => handleSelectAll(payrollClaimsList, 'payroll')}>
											Select All
										</p>
									)}
								</Col>
								<Col xl={24} md={24} xs={24} sm={24} className="toggle_para">
									{payrollClaimsList?.map((role, index) => (
										<Col xl={24} md={24} xs={24} sm={24} key={role.index} className={`toggle_content`}>
											<Permission role={role} changeClaims={changeClaims} />
											{payrollClaimsList?.length === index + 1 && !isSubcribed ? null : (
												<Col xl={24} md={24} lg={24} xs={24} sm={24} className="align_horizontal">
													<Divider style={{ margin: '0px 0px' }} />
												</Col>
											)}
										</Col>
									))}
								</Col>
							</Col>
							{verifier ? (
								<Card className="card_action_pannel" style={{ marginTop: '10px' }}>
									<Row style={{ width: '100%' }}>
										<Col xl={2} lg={2} sm={3} xs={3} md={4}>
											<ImageComponent src={icons.focus_state.Warning} className="explem" />
										</Col>
										<Col xl={22} lg={22} md={20} sm={21} xs={21} className="action_apporve">
											Disabling any of these actions would mean that your user can directly perform them WITHOUT a verifier's
											approval.
										</Col>
									</Row>
								</Card>
							) : null}
							{isSubcribed ? (
								<Col xl={24} md={24} className="userbackground">
									<Col
										xl={24}
										md={24}
										className="header_content1"
										onMouseEnter={() => handleMouseHover(true, 'onHrHover')}
										onMouseLeave={() => handleMouseHover(false, 'onHrHover')}>
										<Chips classname="new_desgin_chip" color="#E2D2FE">
											HR
										</Chips>
										{onHover.onHrHover && (
											<p className="hover_select_all" onClick={() => handleSelectAll(hrClaimList, 'hr')}>
												Select All
											</p>
										)}
									</Col>
									<Col xl={24} md={24} xs={24} sm={24} className="toggle_para">
										{hrClaimList.map((leave, index) => (
											<Col xl={24} md={24} xs={24} sm={24} key={leave.index} className={`toggle_content`}>
												<Leaves leave={leave} changeClaims={changeClaims} />
												{hrClaimList?.length === index + 1 ? null : (
													<Col xl={24} md={24} lg={24} xs={24} sm={24} className="align_horizontal">
														<Divider style={{ margin: '0px 0px' }} />
													</Col>
												)}
											</Col>
										))}
									</Col>
								</Col>
							) : (
								''
							)}
						</Col>
						{button === '2' ? (
							<Col xl={10} md={10} xs={24} sm={24} className="save_change">
								<EdenredButton
									loading={loading}
									onClick={() =>
										history.push({
											pathname: '/hrpermission/user',
											state: state,
											Claim: addclaim,
											user: user,
											verifier: verifier,
										})
									}>
									Next
								</EdenredButton>
							</Col>
						) : (
							''
						)}
					</>
				)}
				<EdenredModal
					isOpen={visible}
					afterOpenModal={afterOpenModal}
					getDeleterole={getDeleterole}
					closeModal={closeModal}
					loading={loading}
					className="modal_card_role"
					user={`Remove ${username}?`}
					content_web={`${username} will not be able to login anymore.
					          Permissions can be reassigned among existing users.`}
					content_mobile={`${username} will not be able to login anymore.`}
				/>
				<Edenredmodal
					{...{
						className: 'new_design_role',
						topImage: <ImageComponent src={focus_state_icons.NewRole} />,
						isOpen: isButtonClicked,
						onOkayButton: 'Assign Role',
						onCancelButton: 'Cancel',
						onOkay: saveClicked,
						onCancel: closeTeamsPop,
						loading: loading,
						title: `Do You Want To Assign New Role & Permissions To ${state?.value?.username}?`,
						desc: (
							<ul style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '90%' }}>
								<li>They wil receive an email with their credentials to log in.</li>
								<li>You will be able to edit them in the future.</li>
							</ul>
						),
					}}
				/>
			</Row>
		</>
	);
};
export default PayrollPermissionPresentational;
