import Actions from '../action';
import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { templateDownload } from '@helpers';

export function* downloadLeaveTemplateService() {
	yield put(Actions.creators.putDownloadLeaveTemplateLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/download-leave-balance`;
		const response = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.getDownloadLeaveTemplateData(response.data));
		yield put(Actions.creators.putDownloadLeaveTemplateLoading(false));

		templateDownload(response.data, 'LeaveTemplate.xls');
	} catch (error) {
		yield put(Actions.creators.getDownloadLeaveTemplateError(error));
		yield put(Actions.creators.putDownloadLeaveTemplateLoading(false));
	}
}
export function* uploadLeaveData(action) {
	yield put(Actions.creators.putUploadLeaveLoading(true));
	let formData = new FormData();
	formData.append('file', action.payload);
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leave-balance-upload`;
		const response = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.putUploadLeaveLoading(false));
		yield put(Actions.creators.putUploadLeaveValidationMessage(response.data));
	} catch (error) {
		yield put(Actions.creators.putUploadLeaveLoading(false));
		yield put(Actions.creators.putUploadLeaveError(error));
	}
}
export function* submitUploadLeaveData(action) {
	yield put(Actions.creators.putUploadLeaveLoading(true));
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/bulk-update?userName=${action?.payload}`;
		const response = yield call(Axios.put, uri);
		yield put(Actions.creators.putUploadLeaveLoading(false));
	} catch (error) {
		yield put(Actions.creators.putUploadLeaveLoading(false));
		yield put(Actions.creators.putUploadLeaveError(error));
	}
}

export default function* uploadLeaveWatcher() {
	yield takeLatest(Actions.types.GET_DOWNLOAD_LEAVE_TEMPLATE, downloadLeaveTemplateService);
	yield takeLatest(Actions.types.POST_UPLOAD_LEAVE, uploadLeaveData);
	yield takeLatest(Actions.types.SUBMIT_UPLOAD_LEAVE, submitUploadLeaveData);
}
