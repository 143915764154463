import EmployeeService from 'src/services/employee-service';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import React, { useEffect, useState } from 'react';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import './announcements-summary.scss';
const AnnouncementsSummary = ({ infoShow = false, handleTitleMessage, handleBodyMessage, announcementMessage }) => {
	let [employeeCount, setEmployeeCount] = useState({});
	let [isEmployeeCountLoading, setIsEmployeeCountLoading] = useState(false);
	const employeeApi = new EmployeeService();
	async function fetchEmployeeCount() {
		setIsEmployeeCountLoading(true);
		try {
			let response = await employeeApi.getDashboardEmployeeCount();
			if (response && response.data) {
				setIsEmployeeCountLoading(false);
				setEmployeeCount(response?.data?.employeeCount);
			}
		} catch (err) {
			setIsEmployeeCountLoading(false);
		}
	}
	useEffect(() => {
		fetchEmployeeCount();
	}, []);
	return (
		<>
			<main className="announcement-wrapper">
				{infoShow && (
					<section className="info-wrapper">
						<ImageComponent src={focus_state_icons?.information} />
						<p className="info-message">
							With the C3Pay App, you can easily send important announcements to your employees and ensure they receive them instantly
							as push notifications on their mobile phones. Try it out now!
						</p>
					</section>
				)}
				<section className="announcement-container">
					<div className="notification-container">
						<div className="notification-title-container">
							<p className="notification-title">Notification Title</p>
							<Inputbox
								className={`title-input ${announcementMessage?.title !== '' ? 'input-value' : ''}`}
								maxLength="40"
								placeholder="Write your notification title here"
								onChange={(e) => handleTitleMessage(e.target.value)}
								value={announcementMessage?.title}
							/>
							<p className="characters">{announcementMessage?.title?.trim().length}/40 Characters</p>
						</div>
						<div className="notification-text-container">
							<p className="notification-text">Notification Text</p>
							<Inputbox
								inputType="textarea"
								className={`message-input ${announcementMessage?.body !== '' ? 'input-value' : ''}`}
								maxLength="120"
								placeholder="Write your message here "
								onChange={(e) => handleBodyMessage(e.target.value)}
								value={announcementMessage?.body}
							/>
							<p className="characters">{announcementMessage?.body?.trim().length}/120 Characters</p>
						</div>
						<Tooltip
							content="To send the announcement to all 
								your employees you need to 
								send an invitation to the portal 
								from “Employees” table."
							direction="up">
							<div className="announcement-sent-employee-count-container">
								<ImageComponent src={focus_state_icons?.useremployee} />
								<div className="sent-employee-container">
									<div className="message-sent">You will send the announcement to:</div>
									{isEmployeeCountLoading === false && (
										<div className="message-sent-count">
											{employeeCount?.c3AppUsersCount}/{employeeCount?.c3CardHoldersCount} employees
										</div>
									)}
								</div>

								<ImageComponent src={not_focus_icons?.EssentialMessage} />
							</div>
						</Tooltip>
					</div>
					<div>
						<ImageComponent src={focus_state_icons?.NotificationAnnouncements} />
					</div>
				</section>
			</main>
		</>
	);
};

export default AnnouncementsSummary;
