import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { ADD_INDIVIDUAL_EMP, DATE_VALID_MSG, EMPLOYEE_DETAILS_KEY, EMPLOYEE_ERROR_KEY } from '@constants/app-constants';
import { getObjectFromArray } from '@helpers';
import action from '../action';
import AddIndividualContractPresentational from './add-individual-contract-presentational';

const AddIndividualContractFunctional = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const empInfo = JSON.parse(localStorage.getItem(ADD_INDIVIDUAL_EMP?.EMPLOYEE_INFO));
	const Departmentdata = useSelector((state) => state?.contractReducer?.Departmentdata);
	const contractdata = useSelector((state) => state?.contractReducer?.contractdata);
	const isContractDetailSaveLoading = useSelector((state) => state?.contractReducer?.isContractDetailSaveLoading);
	const contractDetailResponse = useSelector((state) => state?.contractReducer?.contractDetailResponse);
	const isContractDetailSaved = useSelector((state) => state?.contractReducer?.isContractDetailSaved);
	const [empContractSelected, setEmpContractSelected] = useState();
	const [contractDetails, setContractDetails] = useState({
		joiningDate: '',
		endDate: '',
		department: '',
		designation: '',
		contractName: 'UnLimited',
	});
	const [validateMessage, setValidationMessage] = useState([]);
	useEffect(() => {
		setValidationMessage(contractDetailResponse?.validationMessage || []);
	}, [contractDetailResponse]);
	const empContractDetails = [
		{
			id: 1,
			label: 'Joining Date',
			placeholder: 'Select Joining Date',
			Component: DatePickers,
			value: contractDetails?.joiningDate,
			param: 'joiningDate',
			required: true,
			onChange: (e, date) => handleContractdate(date, EMPLOYEE_DETAILS_KEY?.JOINING_DATE),
			hidden: true,
			isErrorMessage:
				contractDetails?.joiningDate &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.JOINING_DATE)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.JOINING_DATE)?.errorMessage,
		},
		{
			id: 2,
			label: 'Contract End Date',
			placeholder: 'Select Contract End Date',
			Component: DatePickers,
			value: contractDetails?.endDate,
			param: 'endDate',
			required: true,
			onChange: (e, date) => handleContractdate(date, EMPLOYEE_DETAILS_KEY?.END_DATE),
			hidden: contractDetails?.contractName === ADD_INDIVIDUAL_EMP?.LIMITED,
			isErrorMessage: contractDetails?.endDate && contractDetails?.endDate < contractDetails?.joiningDate,
			errorMessage: DATE_VALID_MSG?.GREATER_THAN_FROM,
		},
		{
			id: 3,
			label: 'Department',
			placeholder: 'Choose Department',
			Component: MultiSelect,
			value: contractDetails?.department,
			param: 'department',
			required: true,
			onChange: (e) => handleContractdate(e?.name, EMPLOYEE_DETAILS_KEY?.DEPARTMENT),
			Option: Departmentdata,
			dropDownValue: 'name',
			hidden: true,
			isErrorMessage:
				contractDetails?.department &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.DEPARTMENT)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.DEPARTMENT)?.errorMessage,
		},
		{
			id: 4,
			label: 'Designation',
			placeholder: 'Enter Designation',
			Component: Inputbox,
			value: contractDetails?.designation,
			param: 'designation',
			required: true,
			onChange: (e) => handleContractdate(e?.target?.value, EMPLOYEE_DETAILS_KEY?.DESIGNATION),
			hidden: true,
			isErrorMessage:
				contractDetails?.designation &&
				!!getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.DESIGNATION)?.errorMessage,
			errorMessage: getObjectFromArray(validateMessage, EMPLOYEE_ERROR_KEY?.COLUMN_NAME, EMPLOYEE_ERROR_KEY?.DESIGNATION)?.errorMessage,
		},
	];
	useEffect(() => {
		dispatch(action.creators.getDepartmentDataStart());
		dispatch(action.creators.getContractDataStart());
	}, []);
	useEffect(() => {
		const contractDetail = contractdata?.map((data) => {
			return {
				...data,
				Id: uuid(),
				isSelected: data?.name === ADD_INDIVIDUAL_EMP?.UNLIMITED,
			};
		});
		setEmpContractSelected(contractDetail);
	}, [contractdata]);
	useEffect(() => {
		if (isContractDetailSaved) {
			localStorage.setItem(ADD_INDIVIDUAL_EMP?.EMPLOYEE_INFO, JSON.stringify({ ...empInfo, ...contractDetails }));
			history.push({ pathname: '/employee/add-individual-document/' });
			dispatch(action.creators.isContractDetailSaved(false));
		}
	}, [isContractDetailSaved]);
	const handleContractSelect = (param) => {
		setContractDetails({
			...contractDetails,
			contractName: param?.name,
			endDate: param?.name === ADD_INDIVIDUAL_EMP?.UNLIMITED ? '' : contractDetails?.endDate,
		});
		const selectedContract = empContractSelected?.map((data) => {
			return {
				...data,
				isSelected: data?.Id === param?.Id,
			};
		});
		setEmpContractSelected(selectedContract);
	};
	const handleContractdate = (value, param) => {
		setContractDetails({ ...contractDetails, [param]: value });
		switch (param) {
			case EMPLOYEE_DETAILS_KEY?.JOINING_DATE:
				clearValidationField(EMPLOYEE_ERROR_KEY?.JOINING_DATE);
				break;
			case EMPLOYEE_DETAILS_KEY?.DEPARTMENT:
				clearValidationField(EMPLOYEE_ERROR_KEY?.DEPARTMENT);
				break;
			case EMPLOYEE_DETAILS_KEY?.DESIGNATION:
				clearValidationField(EMPLOYEE_ERROR_KEY?.DESIGNATION);
				break;
			default:
				break;
		}
	};
	const clearValidationField = (columnName) => {
		const error = validateMessage?.map((data) => {
			return {
				...data,
				errorMessage: data?.columnName === columnName ? '' : data?.errorMessage,
			};
		});
		setValidationMessage(error);
	};
	const handleNextButton = () => dispatch(action.creators.saveContractDetailStart(contractDetails));
	const disableNextButton = useMemo(
		() => !!empContractDetails?.filter((data) => data?.required && data?.hidden && !data?.value)?.length,
		[contractDetails, empContractDetails]
	);
	return (
		<AddIndividualContractPresentational
			{...{ empContractDetails, disableNextButton, handleNextButton, handleContractSelect, empContractSelected, isContractDetailSaveLoading }}
		/>
	);
};

export default AddIndividualContractFunctional;
