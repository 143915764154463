import React from 'react';
import { Col } from 'antd';
import { amountWithCommas } from '@helpers';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import './../indes.scss';

export const DynamicAdditionsInput = ({
	value,
	index,
	handleAdditionChange,
	handleAdditionRemove,
	handleAdditionChangeDropdown,
	additionListed,
	name,
	placeholder,
	buttondisabel,
	isDisable,
	isPayrollRunClaims,
}) => {
	return (
		<>
			<Col className="input_text_part">
				<Col className="dropdown-inner-container width_60">
					<MultiSelect
						{...{
							placeholder,
							onChange: (data) => handleAdditionChangeDropdown(data, 'name', index, 'additions'),
							value: name,
							disabled: buttondisabel && !isDisable?.[index] ? true : false || isPayrollRunClaims?.length === 0,
							dropDownValue: 'name',
							Option: additionListed,
							searchVisibility: false,
						}}
					/>
					{/* 
						future ref
						<Select
						placeholder={placeholder}
						onChange={(data) => handleAdditionChangeDropdown(data, 'name', index, 'additions')}
						value={name || null}
						autoFocus={false}
						disabled={buttondisabel && !isDisable?.[index] ? true : false || isPayrollRunClaims?.length === 0}
						dropdownClassName="payroll-run-addition-dropdown-list"
						dropdownRender={(menu) => (
							<div>
								{menu}
								<Divider style={{ margin: '4px 0' }} />
								<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center' }}>
									<p className="dropdown-settings">
										Add more additions in{' '}
										<Link to={'/company/company-setting'}>
											<span className="a">Company Settings</span>
										</Link>
									</p>
								</div>
							</div>
						)}>
						{additionListed &&
							additionListed?.map((option, index) => (
								<Option key={index} value={`${option?.name}id:${option?.id}`}>
									{option?.name}
								</Option>
							))}
					</Select> */}
				</Col>
				<Col className="input-text22">
					<Inputbox
						{...{
							name: 'value',
							value: amountWithCommas(value),
							prefix: 'AED',
							type: 'text',
							disabled: buttondisabel && !isDisable?.[index] ? true : false || isPayrollRunClaims?.length === 0,
							onChange: (e) => handleAdditionChange(e?.target?.value, e?.target?.name, index, 'additions'),
						}}
					/>
				</Col>
				<Col className={buttondisabel && !isDisable?.[index] ? 'delete_button1' : 'delete_button'}>
					<ImageComponent
						className={buttondisabel && !isDisable?.[index] ? 'delete_image1' : 'delete_image'}
						disabled={buttondisabel && !isDisable?.[index]}
						src={focus_state_icons?.NewDelete}
						onClick={() => handleAdditionRemove?.(index, 'additions')}
					/>
				</Col>
			</Col>
		</>
	);
};
export const DynamicDeductionsInput = ({
	value,
	index,
	handleDedutionChange,
	handleDeductionRemove,
	handleDeductionChangeDropdown,
	deductionListed,
	name,
	placeholder,
	buttondisabel,
	isPayrollRunClaims,
}) => {
	return (
		<Col className="input_text_part1">
			<Col className="dropdown-inner-container width_60">
				<MultiSelect
					{...{
						placeholder,
						onChange: (data) => handleDeductionChangeDropdown(data, 'name', index, 'deductions'),
						value: name,
						disabled: buttondisabel || isPayrollRunClaims?.length === 0,
						dropDownValue: 'name',
						Option: deductionListed,
						searchVisibility: false,
					}}
				/>
				{/*
					future ref
				 	<Select
					placeholder={placeholder}
					onChange={(data) => handleDeductionChangeDropdown(data, 'name', index, 'deductions')}
					value={name || null}
					autoFocus={false}
					disabled={buttondisabel || isPayrollRunClaims?.length === 0}
					dropdownClassName="payroll-run-addition-dropdown-list"
					dropdownRender={(menu) => (
						<div>
							{menu}
							<Divider style={{ margin: '4px 0' }} />
							<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 15, alignItems: 'center', justifyContent: 'center' }}>
								<p className="dropdown-settings">
									Add more deductions in{' '}
									<Link to={'/company/company-setting'}>
										<span className="a">Company Settings</span>
									</Link>
								</p>
							</div>
						</div>
					)}>
					{deductionListed?.map((option, index) => (
						<Option key={index} value={`${option?.name}id:${option?.id}`}>
							{option?.name}
						</Option>
					))}
				</Select> */}
			</Col>
			<Col className={buttondisabel ? 'input-text21' : 'input-text22'}>
				<Inputbox
					{...{
						name: 'value',
						value: amountWithCommas(value),
						prefix: 'AED',
						type: 'text',
						onChange: (e) => handleDedutionChange(e?.target?.value, e?.target?.name, index, 'deductions'),
						disabled: buttondisabel || isPayrollRunClaims?.length === 0,
					}}
				/>
			</Col>
			<Col className={buttondisabel || isPayrollRunClaims?.length === 0 ? 'delete_button1' : 'delete_button'}>
				<ImageComponent
					src={focus_state_icons?.NewDelete}
					className={buttondisabel || isPayrollRunClaims?.length === 0 ? 'delete_image1' : 'delete_image'}
					onClick={() => (buttondisabel || isPayrollRunClaims?.length === 0 ? null : handleDeductionRemove?.(index, 'handleRemove'))}
				/>
			</Col>
		</Col>
	);
};
