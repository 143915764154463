import React from 'react';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import { getBorderClassColor, getObjectFromArray } from '@helpers';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ETypo from '@pages/DesignSystem/Typo';
import ClevertapReact from 'src/utils/clever-tap';
import 'flatpickr/dist/themes/light.css';
import EmpDragger from './emp-dragger';
import DatePicker from './DatePicker';
import './emp-documents.scss';

const EDIT = true;

const defaultInputProps = {
	disabled: !EDIT,
	EDIT,
	edit: EDIT,
	type: 'text',
};

const EmployeeDocumentPresentational = ({
	handleFormChange,
	handleDateChanged,
	handleValueChanged,
	draggerChange,
	employeeDocumentImages,
	empDocState,
	deleteImagesLoading,
	getDeleteImages,
	employeeId,
	empDocumentForm,
	isDuplicateCheck,
	expiryDates,
	imageData,
}) => {
	const showdate = moment().format('YYYY-MM-DD');
	const formInputs = {
		labourCard: [
			{
				formProps: {
					name: 'workPermitNumber',
					className: 'inputBox',
					initialValue: empDocState?.workPermitNumber,
					rules: [
						{ min: 5, max: 9, message: 'The length of Labour Card Number should be  between 5 to 9 digit.' },
						{
							pattern: new RegExp(/^([a-zA-Z0-9]+)$/),
							message: 'Re-enter the Labour Card  Number using only alphanumeric characters.',
						},
					],
				},
				inputProps: {
					onChange: (e) => handleValueChanged(e.target.value, 'workPermitNumber'),
					label: 'Labour Card Number',
					maxLength: 9,
					...defaultInputProps,
				},
			},
			{
				formProps: {
					name: 'labourCardNumber',
					className: 'inputBox',
					initialValue: empDocState?.labourCardNumber,
					rules: [
						{ min: 14, message: 'The length of WPS Person ID  should be 14 characters.' },
						{
							pattern: new RegExp(/^([a-zA-Z0-9]+)$/),
							message: 'Re-enter the WPS Person ID using only alphanumeric characters.',
						},
					],
				},
				inputProps: {
					onChange: (e) => handleValueChanged(e.target.value, 'labourCardNumber'),
					label: 'WPS Person ID',
					maxLength: 14,
					...defaultInputProps,
				},
			},
			{
				formProps: {
					name: 'labourIdExpiryDate',
					className: 'inputBox',
					initialValue: empDocState?.labourCardExpiryDate && moment(empDocState?.labourCardExpiryDate || '').format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'labourCardExpiryDate');
					},
					label: 'Expiry Date',
					value: moment(empDocState?.labourCardExpiryDate).format('DD/MM/YYYY'),
					className: `${getBorderClassColor(empDocState?.labourCardExpiryDate, showdate)} floating-input`,
					...defaultInputProps,
				},
				InputComponent: DatePicker,
				showRequired: expiryDates?.labour ? 'This field is required' : '',
			},
		],
		emirates: [
			{
				formProps: {
					name: 'emiratesIdNumber',
					className: 'inputBox',
					initialValue: empDocState?.emiratesIdNumber,
					rules: [
						{ min: 15, message: 'The length of Emirates ID should be 15 characters' },
						{
							pattern: new RegExp(/^[0-9]+$/),
							message: 'Re-enter the Emirates ID Number using only numeric characters.',
						},
						{
							validator(rule, value, callback) {
								if (isDuplicateCheck) {
									callback(isDuplicateCheck);
								}
								if (empDocState && empDocState?.emiratesIdNumber) {
									if (value.length > 2 && value.substring(0, 3) !== '784') {
										callback('First three numbers has to be 784');
									}
									callback();
								}
							},
						},
					],
				},
				inputProps: {
					onChange: (e) => handleValueChanged(e.target.value, 'emiratesIdNumber'),
					label: 'Emirates ID Number',
					maxLength: 15,
					...defaultInputProps,
				},
			},
			{
				formProps: {
					name: 'emiratesIdIssueDate',
					className: 'inputBox',
					initialValue: empDocState?.emiratesIssueDate && moment(empDocState?.emiratesIssueDate || '').format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'emiratesIssueDate');
					},
					label: 'Issue Date',
					className: 'floating-input',
					value: moment(empDocState?.emiratesIssueDate || '').format('DD/MM/YYYY'),
					...defaultInputProps,
				},
				InputComponent: DatePicker,
			},
			{
				formProps: {
					name: 'emiratesIdExpiryDate',
					className: 'inputBox',
					initialValue: empDocState?.emiratesExpiryDate && moment(empDocState?.emiratesExpiryDate || '').format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'emiratesExpiryDate');
					},
					label: 'Expiry Date',
					value: empDocState?.emiratesExpiryDate && moment(empDocState?.emiratesExpiryDate || '').format('DD/MM/YYYY'),
					className: `${getBorderClassColor(empDocState?.emiratesExpiryDate, showdate)} floating-input`,
					...defaultInputProps,
				},
				InputComponent: DatePicker,
				showRequired: expiryDates?.emirates ? 'This field is required' : '',
			},
		],
		visa: [
			{
				formProps: {
					name: 'visaNumber',
					className: 'inputBox',
					initialValue: empDocState?.visaNumber,
					rules: [
						{
							pattern: new RegExp(/^([\/0-9]+)$/),

							message: 'Re-enter the Visa Number using only numeric characters or /.',
						},

						{
							validator(rule, value, callback) {
								const ar = ['101', '201', '301', '401'];
								if (empDocState && empDocState?.visaNumber) {
									if (value.length > 2 && !ar.includes(value.substring(0, 3))) {
										callback('First three numbers has to be 101,201,301,401');
									}
									callback();
								}
							},
						},
					],
				},
				inputProps: {
					onChange: (e) => handleValueChanged(e.target.value, 'visaNumber'),
					label: 'Visa Number',
					maxLength: 16,
					type: 'tel',
					...defaultInputProps,
				},
			},
			{
				formProps: {
					name: 'visaIdIssueDate',
					className: 'inputBox',
					initialValue: moment(empDocState?.visaIssueDate || '').format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'visaIssueDate');
					},
					label: 'Issue Date',
					value: empDocState?.visaIssueDate && moment(empDocState?.visaIssueDate || '').format('DD/MM/YYYY'),
					className: 'floating-input',
					...defaultInputProps,
				},
				InputComponent: DatePicker,
			},
			{
				formProps: {
					name: 'visaIdExpiryDate',
					className: 'inputBox',
					initialValue: empDocState?.visaExpiryDate && moment(empDocState?.visaExpiryDate || '').format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'visaExpiryDate');
					},
					label: 'Expiry Date',
					value: moment(empDocState?.visaExpiryDate || '').format('DD/MM/YYYY'),
					className: `${getBorderClassColor(empDocState?.visaExpiryDate, showdate)} floating-input`,
					...defaultInputProps,
				},
				InputComponent: DatePicker,
				showRequired: expiryDates?.visa ? 'This field is required' : '',
			},
		],
		passport: [
			{
				formProps: {
					name: 'passportNumber',
					className: 'inputBox',
					initialValue: empDocState?.passportNumber,
					rules: [
						{
							pattern: new RegExp(/^([a-zA-Z0-9 ]+)$/),
							message: 'Re-enter the Passport Number using only alphanumeric characters or spaces.',
						},
					],
				},
				inputProps: {
					onChange: (e) => handleValueChanged(e.target.value, 'passportNumber'),
					label: 'Passport Number',
					maxLength: 12,
					...defaultInputProps,
				},
			},
			{
				formProps: {
					name: 'passportIdIssueDate',
					className: 'inputBox',
					initialValue: empDocState?.passportIssueDate && moment(empDocState?.passportIssueDate).format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'passportIssueDate');
					},
					label: 'Issue Date',
					value: moment(empDocState?.passportIssueDate || '').format('DD/MM/YYYY'),
					className: 'floating-input',
					...defaultInputProps,
				},
				InputComponent: DatePicker,
			},
			{
				formProps: {
					name: 'passportIdExpiryDate',
					className: 'inputBox',
					initialValue: empDocState?.passportExpiryDate && moment(empDocState?.passportExpiryDate).format('DD/MM/YYYY'),
				},
				inputProps: {
					onChange: (e, dateString) => {
						handleDateChanged(moment(dateString, 'DD/MM/YYYY').format('yyyy-MM-DDTHH:mm:ss'), 'passportExpiryDate');
					},
					label: 'Expiry Date',
					value: moment(empDocState?.passportExpiryDate || '').format('DD/MM/YYYY'),
					className: `${getBorderClassColor(empDocState?.passportExpiryDate, showdate)} floating-input`,
					...defaultInputProps,
				},
				InputComponent: DatePicker,
				showRequired: expiryDates?.passport ? 'This field is required' : '',
			},
		],
	};
	const setCleverTapEvents = (clevertapName) => {
		switch (clevertapName) {
			case 'workPermitNumber':
				ClevertapReact.event('Profile_Labour_LabourCardNumber');
				break;
			case 'labourCardNumber':
				ClevertapReact.event('Profile_WPS_PersonID');
				break;
			case 'labourIdExpiryDate':
				ClevertapReact.event('Profile_Labour_ExpiryDate');
				break;
			case 'emiratesIdNumber':
				ClevertapReact.event('Profile_EID_Number');
				break;
			case 'emiratesIdIssueDate':
				ClevertapReact.event('Profile_EID_IssueDate');
				break;
			case 'emiratesIdExpiryDate':
				ClevertapReact.event('Profile_EID_ExpiryDate');
				break;
			case 'visaNumber':
				ClevertapReact.event('Profile_Visa_Number');
				break;
			case 'visaIdIssueDate':
				ClevertapReact.event('Profile_Visa_IssueDate');
				break;
			case 'visaIdExpiryDate':
				ClevertapReact.event('Profile_Visa_ExpiryDate');
				break;
			case 'passportNumber':
				ClevertapReact.event('Profile_Passport_Number');
				break;
			case 'passportIdIssueDate':
				ClevertapReact.event('Profile_Passport_IssueDate');
				break;
			case 'passportIdExpiryDate':
				ClevertapReact.event('Profile_Passport_ExpiryDate');
				break;
			default:
				break;
		}
	};
	const cleverTapProperties = {
		WorkPermitNumber: empDocState?.workPermitNumber ? 'yes' : 'no',
		PersonalNumber: empDocState?.labourCardNumber ? 'yes' : 'no',
		LabourCardExpiryDate: empDocState?.labourCardExpiryDate ? 'yes' : 'no',
		LabourCardImage: imageData?.labourCardScan ? 'yes' : 'no',
		EmiratesIdNumber: empDocState?.emiratesIdNumber ? 'yes' : 'no',
		EmiratesIdIssueDate: empDocState?.emiratesIssueDate ? 'yes' : 'no',
		EmiratesIdExpiryDate: empDocState?.emiratesExpiryDate ? 'yes' : 'no',
		EmiratesIdFrontPage: imageData?.emiratesFrontScan ? 'yes' : 'no',
		EmiratesIdBackPage: imageData?.emiratesBackScan ? 'yes' : 'no',
		VisaNumber: empDocState?.visaNumber ? 'yes' : 'no',
		VisaIssueDate: empDocState?.visaIssueDate ? 'yes' : 'no',
		VisaExpiryDate: empDocState?.visaExpiryDate ? 'yes' : 'no',
		VisaImage: imageData?.visaScan ? 'yes' : 'no',
		PassportNumber: empDocState?.passportNumber ? 'yes' : 'no',
		PassportImage: imageData?.passportScan ? 'yes' : 'no',
	};
	return (
		<Form form={empDocumentForm} className="empDocument" onFieldsChange={() => handleFormChange('true')} autoComplete="off">
			<Row xl={24} md={24} lg={24} sm={24} xs={24} className="empDocumentRow">
				<Col xl={8} md={24} lg={8} sm={24} xs={24} className="subtab">
					<Row>
						<span className="label">Labour Card</span>
					</Row>
					{formInputs?.labourCard?.map(({ formProps, inputProps, InputComponent = Inputbox, showRequired }, index) => {
						let clevertapNames = formProps?.name;
						return (
							<>
								<Form.Item {...formProps} onClick={() => setCleverTapEvents(clevertapNames)}>
									<InputComponent {...inputProps} />
								</Form.Item>
								{showRequired && <p className="showRequired">{showRequired}</p>}
							</>
						);
					})}
				</Col>
				<Col xl={7} md={7} lg={7} sm={24} xs={24} className="subtab dragger_emp_profile">
					<Row>
						<ETypo light b2 className="dlabel">
							Labour Card Page
						</ETypo>
					</Row>
					<EmpDragger
						{...{
							emessage: 'Labour Card Page',
							draggerChange,
							employeeId,
							deleteImagesLoading,
							getDeleteImages,
							...{
								img: getObjectFromArray(employeeDocumentImages?.documents, 'documentTypeName', 'Labour card'),
							},
						}}
					/>
				</Col>
			</Row>
			<Row xl={24} md={24} lg={24} sm={24} xs={24} className="empDocumentRow">
				<Col xl={8} md={24} lg={8} sm={24} xs={24} className="subtab">
					<Row>
						<span className="label"> Emirates ID</span>
					</Row>
					{formInputs?.emirates?.map(({ formProps, inputProps, InputComponent = Inputbox, showRequired }, index) => {
						let clevertapNames = formProps?.name;
						return (
							<>
								<Form.Item {...formProps} onClick={() => setCleverTapEvents(clevertapNames)}>
									<InputComponent {...inputProps} />
								</Form.Item>
								{showRequired && <p className="showRequired">{showRequired}</p>}
							</>
						);
					})}
				</Col>
				<Col xl={7} md={7} lg={7} sm={24} xs={24} className="subtab dragger_emp_profile">
					<Row>
						<ETypo light b2 className="dlabel">
							Emirates ID Front
						</ETypo>
					</Row>
					<EmpDragger
						{...{
							emessage: 'Emirates ID Front',
							draggerChange,
							employeeId,
							deleteImagesLoading,
							getDeleteImages,
							...{
								img: getObjectFromArray(employeeDocumentImages?.documents, 'documentTypeName', 'EID front side'),
							},
						}}
					/>
				</Col>
				<Col xl={7} md={7} lg={7} sm={24} xs={24} className="subtab dragger_emp_profile2">
					<Row>
						<ETypo light b2 className="dlabel">
							Emirates ID Back
						</ETypo>
					</Row>
					<EmpDragger
						{...{
							emessage: 'Emirates ID Back',
							draggerChange,
							employeeId,
							deleteImagesLoading,
							getDeleteImages,
							...{
								img: getObjectFromArray(employeeDocumentImages?.documents, 'documentTypeName', 'EID back side'),
							},
						}}
					/>
				</Col>
			</Row>
			<Row xl={24} md={24} lg={24} sm={24} xs={24} className="empDocumentRow">
				<Col xl={8} md={24} lg={8} sm={24} xs={24} className="subtab">
					<Row>
						<span className="label">Visa</span>
					</Row>
					{formInputs?.visa?.map(({ formProps, inputProps, InputComponent = Inputbox, showRequired }, index) => {
						let clevertapNames = formProps?.name;
						return (
							<>
								<Form.Item {...formProps} onClick={() => setCleverTapEvents(clevertapNames)}>
									<InputComponent {...inputProps} />
								</Form.Item>
								{showRequired && <p className="showRequired">{showRequired}</p>}
							</>
						);
					})}
				</Col>
				<Col xl={7} md={7} lg={7} sm={24} xs={24} className="subtab dragger_emp_profile">
					<Row>
						<ETypo light b2 className="dlabel">
							Visa Page
						</ETypo>
					</Row>
					<EmpDragger
						{...{
							emessage: 'Visa Page',
							draggerChange,
							employeeId,
							deleteImagesLoading,
							getDeleteImages,
							...{
								img: getObjectFromArray(employeeDocumentImages?.documents, 'documentTypeName', 'Visa'),
							},
						}}
					/>
				</Col>
			</Row>
			<Row xl={24} md={24} lg={24} sm={24} xs={24} className="empDocumentRow">
				<Col xl={8} md={24} lg={8} sm={24} xs={24} className="subtab">
					<Row>
						<span className="label">Passport</span>
					</Row>
					{formInputs?.passport?.map(({ formProps, inputProps, InputComponent = Inputbox, showRequired }, index) => {
						let clevertapNames = formProps?.name;
						return (
							<>
								<Form.Item {...formProps} onClick={() => setCleverTapEvents(clevertapNames)}>
									<InputComponent {...inputProps} />
								</Form.Item>
								{showRequired && <p className="showRequired">{showRequired}</p>}
							</>
						);
					})}
				</Col>
				<Col xl={7} md={7} lg={7} sm={24} xs={24} className="subtab dragger_emp_profile">
					<Row>
						<ETypo light b2 className="dlabel">
							Passport Page
						</ETypo>
					</Row>
					<EmpDragger
						{...{
							emessage: 'Passport Page',
							draggerChange,
							employeeId,
							deleteImagesLoading,
							getDeleteImages,
							...{
								img: getObjectFromArray(employeeDocumentImages?.documents, 'documentTypeName', 'Passport'),
							},
						}}
					/>
				</Col>
			</Row>
			{/* <Row>
				<Col xl={3} md={8} lg={3} sm={8} xs={8} className="subButtonRow">
					<Form.Item>
						<EdenredButton
							btnType="cancel"
							className="cancelButton"
							disabled={hasErrors || (!buttonFlag && disabledButton)}
							style={{ boxShadow: 'none' }}
							onClick={() => {
								handleCancelModal();
								ClevertapReact.event('Profile_DocsMgt_Cancel');
							}}>
							Cancel
						</EdenredButton>
					</Form.Item>
				</Col>

				<Col xl={3} md={8} lg={3} sm={8} xs={8} className="subButtonRow">
					<Form.Item shouldUpdate="true">
						<EdenredButton
							btnType="primary"
							onClick={() => {
								handleFinish();
								ClevertapReact.event('Docs_profile_submit', { cleverTapProperties });
							}}
							className="saveButton"
							disabled={hasErrors || (!buttonFlag && disabledButton)}
							style={{ boxShadow: 'none' }}
							loading={uploadDataLoading}>
							Save
						</EdenredButton>
					</Form.Item>
				</Col>
			</Row> */}
		</Form>
	);
};
export default EmployeeDocumentPresentational;
