import React from 'react';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const EmployeListExcelDownload = ({ filename, dataSet, element, hideSalary = false }) => {
	// element props - with_custom_download_element eg: custom download button

	let COLUMNS = [
		{
			name: 'Employee ID',
			value: 'employeeCode',
		},
		{
			name: 'Name',
			value: 'name',
		},
		{
			name: 'IBan Number',
			value: 'iban',
		},
	];

	if (!hideSalary) {
		COLUMNS = [
			...COLUMNS,
			{
				name: 'Salary Amount',
				value: 'amount',
			},
		];
	}
	return (
		<ExcelFile filename={filename} element={element}>
			<ExcelSheet data={dataSet} name={'Employees'}>
				{COLUMNS.map((col, i) => (
					<ExcelColumn label={col.name} value={col.value} />
				))}
			</ExcelSheet>
		</ExcelFile>
	);
};
