import React, { useEffect, useState } from 'react';
import statusToastReducer from './reducer';
import './statusToast.scss';
import { withReducer } from 'src/store/reducerLoader';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';

function Index() {
	const [showToast, setShowToast] = useState(false);

	const statusToastState = useSelector((state) => state.statusToast);
	const dispatch = useDispatch();

	useEffect(() => {
		if (showToast === false) {
			setTimeout(() => {
				dispatch(actions.creators.clearStatusToast());
			}, 1000);
		}
	}, [showToast]);

	useEffect(() => {
		if (typeof statusToastState.toastType === 'string') {
			setShowToast(true);
			setTimeout(() => {
				setShowToast(false);
			}, 3000);
		}
	}, [statusToastState]);

	function toastType() {
		const _toastType = statusToastState.toastType;
		let result = '';
		if (typeof _toastType === 'string') {
			result = _toastType.toLocaleLowerCase();
		}
		return result;
	}

	return (
		<>
			<section className={`status-toast-wrapper ${showToast === true ? 'show' : 'hide'} ${toastType()}`}>
				<p>{typeof statusToastState.message === 'string' && `${statusToastState.message}`}</p>
			</section>
		</>
	);
}

const StatusToast = withReducer('statusToast', statusToastReducer)(Index);

export default StatusToast;
