import Actions from './payroll-setting-action';

export const initState = {
	isPayrollLoading: false,
	payrollDetails: [],
	payrollError: '',
	isDepartmentLoading: false,
	departmentDetails: [],
	departmentError: '',
	isAllowanceLoading: false,
	allowanceDetails: [],
	allowanceError: '',
	isContractTypeLoading: false,
	ContractTypeDetails: [],
	ContractTypeError: '',
	isPayrollSubmitLoading: false,
	payrollSubmitted: [],
	payrollSubmittedFail: '',
	settingSavePopUp: false,
	savedSettingSuccessfully: false,
};

const payrollReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_PAYROLL_START: {
			return {
				...state,
				isPayrollLoading: true,
			};
		}
		case Actions.types.GET_PAYROLL_SUCCESS: {
			return {
				...state,
				isPayrollLoading: false,
				payrollDetails: action.response,
			};
		}
		case Actions.types.CLEAR_PAYROLL: {
			return {
				...state,
				isPayrollLoading: false,
				payrollDetails: [],
			};
		}
		case Actions.types.GET_PAYROLL_FAIL: {
			return {
				...state,
				isPayrollLoading: false,
				payrollDetails: [],
				payrollError: action.error,
			};
		}
		case Actions.types.GET_DEPARTMENT_START: {
			return {
				...state,
				isDepartmentLoading: true,
			};
		}
		case Actions.types.GET_DEPARTMENT_SUCCESS: {
			return {
				...state,
				isDepartmentLoading: false,
				departmentDetails: action.response,
			};
		}
		case Actions.types.GET_DEPARTMENT_FAIL: {
			return {
				...state,
				isDepartmentLoading: false,
				departmentDetails: [],
				departmentError: action.error,
			};
		}
		case Actions.types.GET_ALLOWANCE_START: {
			return {
				...state,
				isAllowanceLoading: true,
			};
		}
		case Actions.types.GET_ALLOWANCE_SUCCESS: {
			return {
				...state,
				isAllowanceLoading: false,
				allowanceDetails: action.response,
			};
		}
		case Actions.types.GET_ALLOWANCE_FAIL: {
			return {
				...state,
				isAllowanceLoading: false,
				allowanceDetails: [],
				allowanceError: action.error,
			};
		}
		case Actions.types.GET_CONTRACTTYPE_START: {
			return {
				...state,
				isContractTypeLoading: true,
			};
		}
		case Actions.types.GET_CONTRACTTYPE_SUCCESS: {
			return {
				...state,
				isContractTypeLoading: false,
				ContractTypeDetails: action.response,
			};
		}
		case Actions.types.GET_CONTRACTTYPE_FAIL: {
			return {
				...state,
				isContractTypeLoading: false,
				ContractTypeDetails: [],
				ContractTypeError: action.error,
			};
		}
		case Actions.types.GET_PAYROLLSUBMIT_START: {
			return {
				...state,
				isPayrollSubmitLoading: true,
			};
		}
		case Actions.types.GET_PAYROLLSUBMIT_SUCCESS: {
			return {
				...state,
				isPayrollSubmitLoading: false,
				payrollSubmitted: action.response,
			};
		}
		case Actions.types.GET_PAYROLLSUBMIT_FAIL: {
			return {
				...state,
				isPayrollSubmitLoading: false,
				payrollSubmitted: [],
				payrollSubmittedFail: action.error,
			};
		}
		case Actions.types.HANDLE_SETTING_SAVE_POPUP: {
			return {
				...state,
				settingSavePopUp: action.flag,
			};
		}
		case Actions.types.SAVED_SETTING_SUCCEESSFUL_POPUP: {
			return {
				...state,
				savedSettingSuccessfully: action.flag,
			};
		}
		default:
			return state;
	}
};
export default payrollReducer;
