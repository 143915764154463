import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { APP_VARIABLES } from '@constants/app-constants';
import { approvalButtonDiable } from '@helpers';
import ClevertapReact from 'src/utils/clever-tap';
import action from '../approval-action';
import Selectors from '../approval-selector';
import ApprovalWorkflowPresentational from './approval-workflow-presentational';

function ApprovalWorkflowFunctional({
	isApprovalWorkflowLoading,
	approvalWorkflowDetails,
	isApprovalWorkflowUsertLoading,
	approvalWorkflowUserDetails,
	isApprovalWorkflowSubmitLoading,
	isApproveSavePopUp,
	isApproveSavedSuccessfully,
	approvalWorkflowSaga,
	approvalWorkflowUserSaga,
	approvalWorkflowSubmitSaga,
	handleSavePopUpSaga,
	savedSucceessfulPopUp,
	isSetup,
}) {
	const history = useHistory();
	const [approver, setApprover] = useState([]);
	const [payload, setPayload] = useState([]);
	const [newApprovalDetails, setNewApprovalDetails] = useState([]);
	const [isButtonEnabled, setIsButtonEnabled] = useState({
		savePopUp: false,
		cancelPopUp: false,
		notification: false,
	});
	useEffect(() => {
		approvalWorkflowSaga();
		approvalWorkflowUserSaga();
	}, []);
	useEffect(() => {
		let index = approvalWorkflowDetails
			?.map((data) => data?.users?.length)
			?.indexOf(Math.max(...approvalWorkflowDetails?.map((data) => data?.users?.length)));
		let approvers = approvalWorkflowDetails[index]?.users || [];
		let nApprovers = [];
		if (approvers?.length < 3) {
			nApprovers = Array(3)
				?.fill('')
				?.map((data, i) => {
					return {
						...data,
						orderId: i + 1,
					};
				});
		} else {
			nApprovers = Array(approvers?.length)
				?.fill('')
				?.map((data, i) => {
					return {
						...data,
						orderId: i + 1,
					};
				});
		}
		setApprover(nApprovers);
	}, [approvalWorkflowDetails]);

	useEffect(() => {
		generatePayloadMock();
	}, [approver]);
	const generatePayloadMock = () => {
		const maxUsers = Math.max(...approvalWorkflowDetails?.map((a) => a.users.length));
		const payloads = approvalWorkflowDetails?.map((deparment, index) => {
			const users =
				deparment.users.length === maxUsers && maxUsers > 2
					? deparment.users
					: Array(3)
							.fill('')
							.map((d, i) => {
								return {
									...d,
									id: deparment?.users?.[i]?.id || '',
									orderId: i + 1,
									approverName: deparment?.users?.[i]?.approverName || 'None',
								};
							});
			return {
				...deparment,
				users: users,
			};
		});
		setNewApprovalDetails(payloads);
		setPayload(payloads);
	};
	const handleApprovalDropDown = (data, departmentId, orderId) => {
		setIsButtonEnabled({ ...isButtonEnabled, notification: true });
		let workFlowData = payload?.map((deparment) => {
			if (deparment.departmentId === departmentId) {
				return {
					...deparment,
					users: deparment.users.map((approver, i) => {
						if (approver?.orderId === orderId) {
							return {
								...approver,
								approverName: data?.approverName,
							};
						}
						return approver;
					}),
				};
			}
			return deparment;
		});
		let nWorkFlowData = workFlowData?.map((deparment) => {
			if (deparment.departmentId === departmentId) {
				if (deparment?.users?.[1]?.approverName === 'None') {
					return {
						...deparment,
						users: deparment?.users?.map((data) => {
							if (data?.orderId === 3) {
								return {
									...data,
									approverName: 'None',
								};
							} else {
								return {
									...data,
								};
							}
						}),
					};
				}
			}
			return deparment;
		});
		setPayload(nWorkFlowData);
	};
	const handleApproveButton = (value) => {
		if (value === 'save') {
			ClevertapReact.event('Leave_approval_save');
			handleSavePopUpSaga(true);
		} else if (value === 'cancel') {
			isSetup && history.push({ pathname: '/setup' });
			setIsButtonEnabled({ cancelPopUp: true });
		}
	};
	const handleApprovePopup = (value) => {
		switch (value) {
			case 'closeSavePopUp': {
				handleSavePopUpSaga(false);
				break;
			}
			case 'handleSavePopUp': {
				if (isApproveSavePopUp) {
					setIsButtonEnabled({ ...isButtonEnabled, notification: false });
					let nPayload = [];
					const request = payload?.map((data) => {
						return data?.users?.map((data1) => {
							nPayload.push({
								managerid: data1?.approverName,
								order: data1?.orderId,
								userType: 'User',
								departmentId: data?.departmentId,
							});
						});
					});
					const approvalPayload = nPayload.filter((dta) => dta?.managerid && dta?.managerid !== 'None');
					const payloads = {
						leaveApprovalWorkFlows: approvalPayload,
					};
					approvalWorkflowSubmitSaga(payloads);
				}
				break;
			}
			case 'closeCancelPopUp': {
				setIsButtonEnabled({ cancelPopUp: false });
				break;
			}
			case 'handleCancelPopUp': {
				generatePayloadMock();
				setIsButtonEnabled({ ...isButtonEnabled, notification: false });
				setIsButtonEnabled({ cancelPopUp: false });
			}
			case 'closeSuccessPopUp': {
				savedSucceessfulPopUp(false);
				if (isSetup) {
					history.push({ pathname: '/setup' });
				} else {
					isApproveSavedSuccessfully && setPayload([]);
					isApproveSavedSuccessfully && approvalWorkflowSaga();
				}
				break;
			}
		}
	};

	const disabledFunction = useMemo(() => approvalButtonDiable(newApprovalDetails, payload), [approvalWorkflowDetails, payload]);
	return (
		<ApprovalWorkflowPresentational
			{...{
				isApprovalWorkflowLoading,
				isApprovalWorkflowUsertLoading,
				handleApproveButton,
				handleApprovePopup,
				approvalWorkflowDetails,
				approver,
				isApproveSavePopUp,
				handleApprovalDropDown,
				isButtonEnabled,
				isApprovalWorkflowSubmitLoading,
				isApproveSavePopUp,
				isApproveSavedSuccessfully,
				userData: approvalWorkflowUserDetails.userRolesAndClaims,
				payload,
				nDisable: disabledFunction,
				isSetup,
			}}
		/>
	);
}
const mapStateToProps = (state) => {
	return {
		isApprovalWorkflowLoading: Selectors.isApprovalWorkflowLoading(state),
		approvalWorkflowDetails: Selectors.approvalWorkflowDetails(state),
		isApprovalWorkflowUsertLoading: Selectors.isApprovalWorkflowUsertLoading(state),
		approvalWorkflowUserDetails: Selectors.approvalWorkflowUserDetails(state),
		isApprovalWorkflowSubmitLoading: Selectors.isApprovalWorkflowSubmitLoading(state),
		isApproveSavePopUp: Selectors.isSavePopUp(state),
		isApproveSavedSuccessfully: Selectors.isSavedSuccessfully(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		approvalWorkflowSaga: () => dispatch(action.creators.getApprovalWorkflowStart()),
		approvalWorkflowUserSaga: () => dispatch(action.creators.getApprovalWorkflowUserStart()),
		approvalWorkflowSubmitSaga: (payloads) => dispatch(action.creators.getApprovalWorkflowSubmitStart(payloads)),
		handleSavePopUpSaga: (flag) => dispatch(action.creators.handleSavePopUp(flag)),
		savedSucceessfulPopUp: (flag) => dispatch(action.creators.savedSucceessfulPopUp(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(ApprovalWorkflowFunctional);
export default Connected;
