import { EventEmitter } from 'fbemitter';

export const downloadRandomImage = (value, fileTemplates = []) => {
	const { fileUrl, name } = Object.assign({}, ...fileTemplates.filter((file) => file.value === value));
	console.clear();
	console.log(fileUrl, name);
	fetch(fileUrl)
		.then((response) => {
			if (response.ok) {
				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = name;
					a.click();
				});
				window.location.href = response.url;
			}
		})
		.catch((err) => {
			console.log(err);
		});
};

const AppEmitter = new EventEmitter();
export default AppEmitter;
