import React, { useEffect, useState, useLocation } from 'react';
import { Row, Col, Modal } from 'antd';
import './videoPlayerPopUpModal.scss';
import Vimeo from '@u-wave/react-vimeo';

export default function VideoPlayerPopUpModal({ visible, video, closeVideo, isVideoPaused, startVideo }) {
	const [isVisible, setIsVisible] = useState(visible);
	const [paused, setPaused] = useState(isVideoPaused);

	window.onload = function () {
		let frameElement = document.getElementById('player');
	};

	// console.log('Insidepopup', isVisible);

	const closeModal = () => {
		closeVideo();
	};

	return (
		// <Col>
		// 	<Modal
		// 		className="video_player_modal_container"
		// 		centered
		// 		footer={false}
		// 		closable={true}
		// 		// maskClosable={}
		// 		bodyStyle={{ height: '70vh', minHeight: 'none', padding: '0px', margin: '0px', background: '#181919' }}
		// 		// style={{height:"70vh"}}
		// 		maskStyle={{ backgroundColor: 'white', opacity: '0.8' }}
		// 		width="50vw"
		// 		closeIcon={<span style={{ color: 'white' }}>x</span>}
		// 		afterClose={() => closeModal()}
		// 		visible={visible}
		// 		onCancel={() => closeModal()}>
		// 		<Col className="main_popup_video_container">
		// 			{/* <Vimeo
		// 				className="vimeo_video_container"
		// 				video={video}
		// 				autopause={true}
		// 				dnt={true}
		// 				paused={isVideoPaused}
		// 				onPlay={() => startVideo()}
		// 			/> */}
		// 			{/* onPlay={() => startVideo()} */}
		// 		</Col>
		// 	</Modal>
		// </Col>
		<>
			{visible && (
				<div className="video_player_modal_containers">
					<div className="video_modal_popup">
						<button className="video_close_btn" onClick={() => closeModal()}>
							X
						</button>
						<video className="modal_video_container" autopause={true} controls paused={isVideoPaused} onPlay={() => startVideo()}>
							<source src={video} type="video/mp4"></source>
						</video>
					</div>
				</div>
			)}
		</>
	);
}
