import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LEAVES_CONSTANTS } from '@constants/app-constants';
import { checkRoles, getObjectLength } from '@helpers';
import ClevertapReact from 'src/utils/clever-tap';
import EmployeeService from 'src/services/employee-service';
import action from '../action';
import Selectors from '../selector';
import RequestLeavePresentational from './request-leave-presentational';

function RequestLeaveFunctional({
	employeesListDetails,
	isLeaveTypesLoad,
	leaveTypesLists,
	leaveTypeDetails,
	overLappingLists,
	overLappingDetails,
	isOverLappingLoad,
	leaveBalanceDetails,
	leaveBalanceLists,
	documentValidDetails,
	documentValidLists,
	createDetails,
	isCreateSaved,
	getCreateSaved,
	leaveComfirmPopupFlag,
	handleLeaveConfirmPopup,
	documentValidLoading,
	isCreateLoading,
	leaveValid,
	departmentLeaveSaga,
	departmentLeave,
	isEmployeeRequest = false,
	employeeDetails = {},
	leavePopupMsg,
}) {
	let employeeApi = new EmployeeService();
	const empId = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeCode);
	const roles = useSelector((state) => state?.sharedStates?.currentUser?.roles) || [];
	const [popUp, setPopUp] = useState({
		savePopup: false,
		cancelPopup: false,
		cancelButton: false,
		successPopUp: false,
		showDialog: false,
		leaveAbliable: false,
	});
	const [documentDetails, setDocumentDetails] = useState({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isUnpaidLeave, setIsUnpaidLeave] = useState(false);
	const [leaveNotAppliable, setLeaveNotAppliable] = useState(false);
	const [employeeData, setEmployeeData] = useState({
		status: '',
		nameId: null,
		empCode: '',
		leaveType: null,
		startDate: '',
		endDate: '',
		rangeDate: '',
		notes: '',
		length: '',
		gender: '',
	});
	const [fileUpload, setFileUpload] = useState([]);
	const [size, setSize] = useState(false);
	const [notification, setNotification] = useState(false);
	const [empid, setEmpId] = useState('');
	const [employeeRequestDetails, setEmployeeRequestDetails] = useState({});
	const [isEmpLeaveRequest, setIsEmpLeaveRequest] = useState({
		forMyself: getObjectLength(checkRoles(roles, 'Employee')) > 0,
		forMyTeam: false,
	});
	const [employeeOptions, setEmployeeOptions] = useState([]);
	const { leaveTypes = [] } = leaveTypesLists;
	const { leaves = [] } = overLappingLists;
	const { employee = {} } = departmentLeave;
	const dispatch = useDispatch();

	useEffect(() => {
		Object?.keys(employeeDetails)?.length !== 0 && isEmployeeRequest && setEmployeeRequestDetails(employeeDetails);
	}, [employeeDetails]);

	useEffect(() => {
		if (Object?.keys(employeeRequestDetails)?.length !== 0) {
			const value = `${employeeRequestDetails?.name}code:${employeeRequestDetails?.employeeId}id:${employeeRequestDetails?.id} ${employeeRequestDetails?.gender}`;
			leaveBalanceDetails(employeeData);
			dispatch(
				action.creators.getLeaveValidStart({ employeeData: '', startDate: '', endDate: '', val: employeeRequestDetails?.employeeId || '' })
			);
			documentValidDetails(employeeRequestDetails?.id);
			departmentLeaveSaga(employeeRequestDetails?.id);
			setEmployeeData((prev) => ({ ...prev, nameId: value, gender: employeeRequestDetails?.gender }));
			setEmpId(employeeRequestDetails?.employeeId);
		}
	}, [employeeRequestDetails]);

	useEffect(() => {
		setDocumentDetails(documentValidLists);
	}, [employeesListDetails, leaveTypesLists, overLappingLists, documentValidLists]);

	useEffect(() => {
		leaveTypeDetails();
	}, []);

	useEffect(() => {
		if (employeeData?.nameId && employeeData?.startDate && employeeData?.endDate && employeeData?.startDate <= employeeData?.endDate) {
			dispatch(
				action.creators.getLeaveValidStart({
					employeeData: empid,
					startDate: employeeData?.startDate,
					endDate: employeeData?.endDate,
					val: '',
				})
			);
		}
	}, [employeeData?.nameId, employeeData?.startDate, employeeData?.endDate]);

	useEffect(() => {
		const joinDate = leaveBalanceLists?.joiningDate;
		const probetionalPeriod = leaveBalanceLists?.probationPeriod;
		const leave = moment().format('YYYY-MM-DD') <= moment(joinDate).add(probetionalPeriod, 'months').format('YYYY-MM-DD');
		setLeaveNotAppliable(leaveBalanceLists?.probationPeriod !== 0 && leave);
	}, [leaveBalanceLists]);

	useEffect(() => {
		setEmployeeData((prev) => ({
			...prev,
			leaveType: employeeData?.nameId && leaveValid !== LEAVES_CONSTANTS.LEAVE_VALID ? (leaveNotAppliable ? 'Unpaid' : 'Annual') : null,
		}));
	}, [leaveNotAppliable, employeeData?.nameId]);

	useEffect(() => {
		leaveBalanceDetails(employeeData);
	}, [employeeData?.nameId, employeeData?.leaveType]);

	const checkForEmployee = (employee, leaveTypeGender, leaveValid) => {
		if (leaveValid != '' && leaveValid === LEAVES_CONSTANTS.LEAVE_VALID) {
			return employee;
		} else {
			return !!!employee;
		}
	};

	const leaveTypeGender = (leaveTypes) => {
		const joinDate = leaveBalanceLists?.joiningDate;
		const probetionalPeriod = leaveBalanceLists?.probationPeriod;
		const leave = moment().format('YYYY-MM-DD') <= moment(joinDate).add(probetionalPeriod, 'months').format('YYYY-MM-DD');
		const gender = isEmployeeRequest ? employeeDetails?.gender : employeeData?.gender;
		return (leaveTypes || [])
			?.filter((data) => (gender === 'M' ? data?.leaveTypeName !== 'Maternity' : data?.leaveTypeName !== 'Paternal'))
			?.filter((item) =>
				leaveBalanceLists && leaveBalanceLists?.probationPeriod !== 0 && leave
					? item?.leaveTypeName !== 'Annual' && item?.leaveTypeName !== 'Sick'
					: item
			)
			?.sort((a, b) => a.leaveTypeName.localeCompare(b.leaveTypeName));
	};
	const leaveDetail = [
		{
			name: 'Name,Employee ID',
			placeholder: 'Select Employee',
			value: employeeOptions,
			param: 'nameId',
		},
	];
	const leaveDetail1 = [
		{
			name: 'Leave Type',
			placeholder: 'Leave Type',
			value: leaveValid != '' && leaveValid === LEAVES_CONSTANTS.LEAVE_VALID ? [] : leaveTypeGender(leaveTypes),
			param: 'leaveType',
			checkRule: (employee) => checkForEmployee(employee, leaveTypeGender, leaveValid),
		},
	];
	const handleSavePopup = () => handleLeaveConfirmPopup(false);
	const leaveRequestSaveWeb = () => {
		setDocumentDetails([]);
		if (leavePopupMsg.toString().includes('Submit')) {
			createDetails(employeeData, fileUpload);
		} else {
			handleLeaveConfirmPopup(false, 'Cancel');
		}
		setPopUp({ savePopup: false });
		setEmployeeData((prevState) => ({
			...prevState,
			status: '',
			startDate: '',
			employeeName: '',
			empCode: '',
			endDate: '',
			rangeDate: '',
			notes: '',
			length: '',
			nameId: '',
		}));
		setFileUpload({});
	};
	const leaveRequestcancel = () => setPopUp({ cancelPopup: false });
	const handleSuccessPopups = () => {
		getCreateSaved(false);
		setNotification(false);
	};
	const submitMobButton = () => setPopUp({ cancelButton: true });

	const handleMobCancel = (data) => {
		if (data === 'cancel') {
			setEmployeeData({
				status: '',
				nameId: null,
				leaveType: null,
				startDate: '',
				endDate: '',
				rangeDate: '',
				notes: '',
				length: '',
				gender: '',
				empCode: '',
			});
			setPopUp({ cancelButton: false });
		} else if (data === 'save') {
			setPopUp({ cancelButton: false });
			createDetails(employeeData, fileUpload, true);
			setEmployeeData({
				status: '',
				nameId: null,
				leaveType: null,
				startDate: '',
				endDate: '',
				employeeName: '',
				empCode: '',
				rangeDate: '',
				notes: '',
				length: '',
				gender: '',
			});
			setFileUpload({});
		}
	};
	const handleOverlap = () => {
		setIsModalVisible(true);
		overLappingDetails(employeeData);
	};
	useEffect(() => {
		setNotification(!isEmployeeRequest && !isEmpLeaveRequest?.forMyself && employeeData?.nameId && leaveValid !== LEAVES_CONSTANTS.LEAVE_VALID);
	}, [employeeData?.nameId, leaveValid]);

	const onChange = (param, value) => {
		const length = employeeOptions?.[0]?.employeeCode.toString().length;
		let unpaid = value?.leaveTypeName === 'Unpaid';
		setIsUnpaidLeave(unpaid);
		if (param === 'nameId') {
			const nameId = `${value?.employeeName}code:${value?.employeeCode}id:${value?.employeeId} ${value?.gender}`;
			const empCode = value?.employeeCode;
			const empId = value?.employeeId;
			const empGender = value?.gender;
			setEmployeeData((prev) => ({ ...prev, nameId: nameId, empCode: value?.employeeName, length: length, gender: empGender }));
			dispatch(action.creators.getLeaveValidStart({ employeeData: '', startDate: '', endDate: '', val: empCode }));
			documentValidDetails(empId);
			departmentLeaveSaga(empId);
			setEmpId(empCode);
		} else if (param === 'leaveType') {
			setEmployeeData((prev) => ({ ...prev, leaveType: value?.leaveTypeName, length: length }));
		}
	};
	const handleDatepickers = (value, dateObj, formatStr) => {
		const startDate = formatStr?.split('to')?.[0]?.split('/').reverse().join('-').replace(/ +/g, '');
		const endDate = formatStr?.split('to')?.[1]?.split('/').reverse().join('-').replace(/ +/g, '') || startDate;
		setEmployeeData((prev) => ({
			...prev,
			startDate: startDate,
			endDate: endDate,
			rangeDate: formatStr,
		}));
	};
	const handleTextArea = (e) => {
		let textField = e?.target?.value;
		setEmployeeData({ ...employeeData, notes: textField });
	};
	const submitButton = () => {
		const totalDays = moment(employeeData?.endDate).diff(moment(employeeData?.startDate), 'd');
		let balance = leaveBalanceLists?.balance ?? 0;
		let addedTotalDays = totalDays >= 0 ? parseInt(totalDays + 1) : 0;
		const cleverTapProporties = {
			leaveType: employeeData?.leaveType,
			StartDate: employeeData?.startDate,
			EndDate: employeeData?.endDate,
			Days: totalDays + 1,
			BalanceRemaining: balance - addedTotalDays,
			Note: employeeData?.notes ? 'yes' : 'no',
			Attachment: fileUpload ? 'yes' : 'no',
		};
		ClevertapReact.event('Leave_request_submit', { ...cleverTapProporties });
		handleLeaveConfirmPopup(true, 'save');
	};
	const handleLeavePopups = () => setPopUp({ ...popUp, leaveAbliable: false });

	const handleEmpReqSelect = (value) => {
		if (value === 'forMyself') {
			setIsEmpLeaveRequest({ ...isEmpLeaveRequest, forMyself: true, forMyTeam: false });
		} else {
			setIsEmpLeaveRequest({ ...isEmpLeaveRequest, forMyself: false, forMyTeam: true });
			setEmployeeData({
				status: '',
				nameId: null,
				leaveType: null,
				startDate: '',
				endDate: '',
				rangeDate: '',
				notes: '',
				length: '',
				gender: '',
				empCode: '',
			});
			setFileUpload({});
		}
	};
	useEffect(() => {
		isEmpLeaveRequest?.forMyself && getObjectLength(checkRoles(roles, 'Employee')) > 0 && getEmpDetail(empId);
	}, [isEmpLeaveRequest?.forMyself]);
	const getEmpDetail = async (empId) => {
		try {
			let response = await employeeApi.getEmployeeById(empId);
			if (response && response?.data) {
				let finalResp = { ...response.data.employee };
				setEmployeeRequestDetails(finalResp);
			} else {
				setEmployeeRequestDetails({});
			}
		} catch (err) {
			setEmployeeRequestDetails({});
		}
	};
	const cancelLeave = () => {
		handleLeaveConfirmPopup(true, 'cancel');
	};

	return (
		<RequestLeavePresentational
			{...{
				leaveDetail,
				leaveDetail1,
				submitButton,
				isCreateLoading,
				popUp,
				handleSavePopup,
				leaveRequestSaveWeb,
				leaveRequestcancel,
				submitMobButton,
				handleMobCancel,
				leaves,
				handleDatepickers,
				isModalVisible,
				setIsModalVisible,
				handleOverlap,
				isOverLappingLoad,
				leaveBalanceLists,
				onChange,
				isUnpaidLeave,
				documentDetails,
				employeeData,
				handleTextArea,
				setFileUpload,
				handleSuccessPopups,
				size,
				setSize,
				documentValidLoading,
				isCreateSaved,
				leaveComfirmPopupFlag,
				leaveTypeGender,
				isLeaveTypesLoad,
				notification,
				handleLeavePopups,
				leaveValid,
				employee,
				leaveNotAppliable,
				isEmployeeRequest,
				isEmpLeaveRequest,
				handleEmpReqSelect,
				cancelLeave,
				leavePopupMsg,
				roles,
				setEmployeeOptions
			}}
		/>
	);
}
const mapStateToProps = (state) => {
	return {
		isEmployeesListLoader: Selectors.employeeListLoading(state),
		employeesListDetails: Selectors.employeeList(state),
		isLeaveTypesLoad: Selectors.leaveTypeLoading(state),
		leaveTypesLists: Selectors.leaveType(state),
		isOverLappingLoad: Selectors.overLappingLoading(state),
		overLappingLists: Selectors.overLappingLists(state),
		leaveBalanceLists: Selectors.leaveBalanceLists(state),
		documentValidLoading: Selectors.documentValidLoading(state),
		documentValidLists: Selectors.documentValidLists(state),
		isCreateLoading: Selectors.createLoading(state),
		isCreateSaved: Selectors.isCreateSaved(state),
		leaveComfirmPopupFlag: Selectors.leaveComfirmPopupFlag(state),
		leaveValid: Selectors.leaveValid(state),
		departmentLeave: Selectors.departmentLeave(state),
		leavePopupMsg: Selectors.leavePopupMsg(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		leaveTypeDetails: () => dispatch(action.creators.getLeaveTypeStart()),
		overLappingDetails: (employeeData) => dispatch(action.creators.getOverLappingStart(employeeData)),
		leaveBalanceDetails: (employeeData) => dispatch(action.creators.getLeaveBalanceStart(employeeData)),
		documentValidDetails: (employeeData) => dispatch(action.creators.getDocumentValidStart(employeeData)),
		createDetails: (employeeData, fileUpload, toggle) => dispatch(action.creators.getCreateStart(employeeData, fileUpload, toggle)),
		getCreateSaved: (flag) => dispatch(action.creators.getCreateSaved(flag)),
		handleLeaveConfirmPopup: (flag, msg) => dispatch(action.creators.handleLeaveConfirmPopup(flag, msg)),
		departmentLeaveSaga: (flag) => dispatch(action.creators.getDepartmentLeaveStart(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(RequestLeaveFunctional);
export default Connected;
