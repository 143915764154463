import React, { useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import PayrollRun from '@pages/payroll-run/payroll-run';
import PayrollSetting from '@pages/payroll-plus/payroll-settings';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import PayrollProfile from '@pages/payroll-plus/payroll-profile';
import EmployeeHistoryFunctional from '@pages/Leave-Management/leaves-status/Component/employeeHistoryFunctional';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import EmpDocumentMain from './Emp-Documents/emp-doument-main';

const EmployeeProfileIndividualTab = ({
	navigateTabCheck,
	handleClick,
	openDeleteModal,
	employeeDetails,
	updateWPSDetails,
	getEmployeeById,
	accountType,
	bankOptions,
	payrollClaims,
	mainProfile,
	setNavigateTabCheck,
	employeeDetailState,
	setEmployeeDetailState,
	additions,
	setAdditions,
	deductions,
	setDeductions,
	gratuity,
	setGratuity,
	runEmployeeAllowanceList,
	setRunEmployeeAllowanceList,
	notification,
	setNotification,
	employeeAllowance,
	setEmpolyeeAllowance,
	empolyeeSalary,
	setEmpolyeeSalary,
	termiation,
	setTerminationType,
	overTimes,
	setOverTimes,
	month,
	setmonth,
	year,
	setyear,
	savePayrollValue,
	setSaveValue,
	gratutiycal,
	setGratuityCal,
	payrollDetails,
	setPayrollDetails,
	addAllowance,
	setAddAllowance,
	isEnabled,
	setIsEnabled,
	hrClaims,
	sampleData,
	setSampleData,
	leaveTypelist,
	setLeaveTypelist,
	buttonFlag,
	setButtonFlag,
	hasErrors,
	setHasErrors,
	empDocState,
	setEmpDocState,
	backupData,
	setBackupData,
	expiryDates,
	setExpiryDates,
	imageData,
	setImageData,
	empDocumentForm,
	employeeHistory,
	payrollTab,
	leavesTab,
	documentsTab,
	setAlertPopup,
	leavingDate,
	setLeavingDate,
	terminationId,
	setTerminationId,
}) => {
	const { TabPane } = Tabs;
	const isSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const [salaryHistoryViewMore, setSalaryHistoryViewMore] = useState(false);
	const columnKeys = [
		{ labelKey: 'Sl. No.', valueKey: 'serialNo' },
		{ labelKey: 'Emp Id', valueKey: 'employeCode' },
		{ labelKey: 'First Name', valueKey: 'firstName' },
		{ labelKey: 'Last Name', valueKey: 'lastName' },
		{ labelKey: 'Salary', valueKey: 'salary' },
		{ labelKey: 'Processed Date', valueKey: 'processedDateModified' },
		{ labelKey: 'Type', valueKey: 'channel' },
		{ labelKey: 'Month/Year', valueKey: 'monthYear' },
		{ labelKey: 'WPS Person Id', valueKey: 'wpsPersonId' },
		{ labelKey: 'WPS Establishment Id', valueKey: 'wpsEstablishmentId' },
	];
	//salary history view more
	let viewSalaryContent = '';
	let slicedSalaryData = [];
	if (salaryHistoryViewMore) {
		viewSalaryContent = 'Less';
		slicedSalaryData = employeeHistory.slice(0, employeeHistory?.length);
	} else {
		viewSalaryContent = 'More';
		slicedSalaryData = employeeHistory.slice(0, 5);
	}
	const handleTabChange = (key) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			selectedTab: key,
		});
		navigateTabCheck?.isTabClicked && setAlertPopup(true);
	};
	return (
		<>
			<Tabs defaultActiveKey={navigateTabCheck?.currentTab} onChange={handleTabChange}>
				<TabPane tab="Profile" key="1">
					<PayrollProfile
						{...{
							handleClick,
							openDeleteModal,
							employeeDetails,
							updateWPSDetails,
							getEmployeeById,
							accountType,
							bankOptions,
							claims: payrollClaims,
							mainProfile,
							setNavigateTabCheck,
							navigateTabCheck,
							employeeDetailState,
							setEmployeeDetailState,
						}}
					/>
				</TabPane>

				{/*  commented based on new requirement
				{isSubscription && payrollTab?.length > 0 ? (
					<TabPane tab="Payroll Run" key="2">
						<PayrollRun
							{...{
								employeeDetails,
								getEmployeeById,
								setNavigateTabCheck,
								navigateTabCheck,
								additions,
								setAdditions,
								deductions,
								setDeductions,
								gratuity,
								setGratuity,
								runEmployeeAllowanceList,
								setRunEmployeeAllowanceList,
								notification,
								setNotification,
								employeeAllowance,
								setEmpolyeeAllowance,
								empolyeeSalary,
								setEmpolyeeSalary,
								termiation,
								setTerminationType,
								overTimes,
								setOverTimes,
								month,
								setmonth,
								year,
								setyear,
								savePayrollValue,
								setSaveValue,
								gratutiycal,
								setGratuityCal,
								leavingDate,
								setLeavingDate,
								terminationId,
								setTerminationId,
							}}
						/>
					</TabPane>
				) : (
					''
				)} */}
				{isSubscription && payrollTab.length > 0 ? (
					<TabPane tab="Payroll Settings" key="3">
						<PayrollSetting
							{...{
								employeeDetails,
								getEmployeeById,
								setNavigateTabCheck,
								navigateTabCheck,
								payrollDetails,
								setPayrollDetails,
								addAllowance,
								setAddAllowance,
								isEnabled,
								setIsEnabled,
								notification,
								setNotification,
							}}
						/>
					</TabPane>
				) : (
					''
				)}
				<TabPane tab="Salary History" key="6" className="individual_salary_profile">
					<Row className="emp_profile_salary_history">
						{employeeHistory && employeeHistory?.length ? (
							<Col className="download-salary-history">
								<ExcelDownload
									{...{
										dataSet: employeeHistory,
										columnKeys: columnKeys,
										className: 'download-salary-history',
										filename: `Salary_History`,
										element: (
											<EdenredButtons btnType="secondary" leftIcon={<img src={focus_state_icons?.DownloadFiles} />}>
												Download Salary History
											</EdenredButtons>
										),
									}}
								/>
							</Col>
						) : null}
						<Row className="emp_profile_table">
							<Row xl={24} md={24} lg={24} className="salary-history-header">
								<Col className="salary-history-header-title">
									<ETypo medium b2>
										Month
									</ETypo>
								</Col>
								<Col className="salary-history-header-title">
									<ETypo medium b2>
										Amount
									</ETypo>
								</Col>
							</Row>
							{slicedSalaryData && slicedSalaryData?.length > 0 ? (
								<>
									{slicedSalaryData?.map((emp) => (
										<Row className="subSalary">
											<Col className="salary-history-content">
												<ETypo medium b2 color="#484B52">
													{emp?.displayMonth}
												</ETypo>
											</Col>
											<Col className="salary-history-content">
												<ETypo light b2 color="#181919">
													{emp?.displayAmount}
												</ETypo>
											</Col>
										</Row>
									))}
									<Col className="salary_history_more" onClick={() => setSalaryHistoryViewMore(!salaryHistoryViewMore)}>
										<ETypo medium b1 color="#0E5274">
											View {viewSalaryContent}
										</ETypo>
									</Col>
								</>
							) : (
								<div style={{ textAlign: 'center', color: '#ddd', width: '100%' }}>No data found</div>
							)}
						</Row>
					</Row>
				</TabPane>
				{isSubscription && leavesTab?.length > 0 ? (
					<TabPane tab="Leaves" key="4">
						<EmployeeHistoryFunctional
							{...{
								employeeDetails,
								claims: hrClaims,
								setNavigateTabCheck,
								navigateTabCheck,
								sampleData,
								setSampleData,
								leaveTypelist,
								setLeaveTypelist,
							}}
						/>
					</TabPane>
				) : (
					''
				)}
				{isSubscription && documentsTab?.length > 0 ? (
					<TabPane tab="Documents" key="5">
						<EmpDocumentMain
							{...{
								employeeDetails,
								getEmployeeById,
								setNavigateTabCheck,
								navigateTabCheck,
								buttonFlag,
								setButtonFlag,
								hasErrors,
								setHasErrors,
								empDocState,
								setEmpDocState,
								backupData,
								setBackupData,
								expiryDates,
								setExpiryDates,
								imageData,
								setImageData,
								empDocumentForm,
							}}
						/>
					</TabPane>
				) : (
					''
				)}
			</Tabs>
		</>
	);
};

export default EmployeeProfileIndividualTab;
