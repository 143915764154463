import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ETypo from '@pages/DesignSystem/Typo';
import { SITEMAP } from 'src/routes/sitemap';
import { icons } from '@assets/icons';
import { DotLoader } from 'react-spinners';
import './CardsBlockCard.scss';

const CardsBlockCard = () => {
	const employeeVerification = useSelector((state) => state?.employees?.verification);
	const { invalidEmployees, underReviewEmployees } = employeeVerification || {};
	const employeesCount = invalidEmployees || 0;
	const employeesUnderReviewCount = underReviewEmployees || 0;
	const isCardBlocked = employeesCount || employeesUnderReviewCount;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!employeeVerification) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [employeeVerification]);

	const showLoader = () => {
		return <DotLoader size={50} color={'#e91d24'} loading />;
	};

	const pageRouteLink = SITEMAP.employees.verification.dashboard.index;

	const showCards = () => {
		if (isCardBlocked) {
			return (
				<Card bordered={false} data-testid="card-blocked-container-test" className="cards_block_card">
					<Col xl={10} lg={8} md={8} sm={8} xs={8} className="cards_block_card_col_1">
						<ImageComponent src={focus_state_icons.notSetUp} />
						<ETypo className="card_blocked_title_text" h3 bold data-testid="card-blocked-title-test">
							Update Now!
						</ETypo>
						<ETypo b2 color="#484B52" medium data-testid="card-blocked-desc-test" className="card_blocked_desc_text">
							Cards are going to be blocked <br /> due to Missing Details
						</ETypo>
					</Col>
					<Col xl={14} lg={11} md={11} sm={11} xs={11} className="cards_block_card_col_2">
						<Link to={pageRouteLink}>
							<Card className="yellow_card">
								<div className="yellow_card_col_1">
									<ImageComponent src={icons.focus_state.warningLightHexagon} />
									<ETypo h3 bold>
										{employeesCount}
									</ETypo>
									<ETypo b1 medium>
										Cards Going to be Blocked
									</ETypo>
								</div>
								<span>
									Update missing details by{' '}
									<Link to={pageRouteLink} className="link">
										<ETypo b1>clicking here</ETypo>
									</Link>
								</span>
							</Card>
						</Link>
					</Col>
				</Card>
			);
		}
		return (
			<Card bordered={false} className="cards_active_card">
				<div data-testid="card-not-blocked-container-test">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="cards_active_card_col_1">
						<ImageComponent src={icons.focus_state.squareRoundSuccessTick} />
						<ETypo h3 bold color="#181919" className="cards_active_text" data-testid="card-not-blocked-title-test">
							All Cards are active!
						</ETypo>
					</Col>
				</div>
			</Card>
		);
	};

	return (
		<div className={`cards_block_container ${loading ? 'cards_block_loader' : ''}`} data-testid="card-block-container-test">
			{loading ? showLoader() : showCards()}
		</div>
	);
};

export default CardsBlockCard;
