import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { checkWithClaims } from '@helpers';
import { HrClaims, PayrollClaims } from '@constants/index';
import { images } from 'src/assets/img';
import ClevertapReact from 'src/utils/clever-tap';
import './leave-to-approve.scss';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import setupAction from 'src/components/shared/app-action-reducer/action';

function LeaveToApproveNoRecords({ onClickAddEditLeaveBalance }) {
	const history = useHistory();
	const hrClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims) || [];
	const payrollClaims = useSelector((state) => state?.sharedStates?.currentUser?.payrollClaims) || [];
	const isLeaveBalanceApproveExists = checkWithClaims(hrClaims, HrClaims?.APPROVAL_WORKFLOW) || false;
	const isManageEmployeeExists = checkWithClaims(payrollClaims, PayrollClaims?.MANAGE_EMPLOYEE) || false;
	const store = useSelector((state) => state?.sharedStates?.userNavigation);
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(setupAction.creators.postHrSetupStatus({ hrOnBoardingFlowCompleted: false }));
	// }, []);

	const handleStatusCard = (value) => {
		ClevertapReact.event(value === 'leaveBalance' ? 'Leave_AddEdit_Leavebalance' : 'Leave_InviteEmployees');

		if (typeof onClickAddEditLeaveBalance === 'function' && value === 'leaveBalance') {
			onClickAddEditLeaveBalance();
		} else {
			history.push({ pathname: value === 'invitePortal' ? '/employees/manage_employee' : '/leave/upload' });
		}
	};

	if (store?.hrOnBoardingFlowCompleted === false) {
		return (
			<>
				<Row className="wps_payroll_container_page">
					<div className="page">
						<ETypo bold h2>
							Leaves to Approve
						</ETypo>
					</div>
					<div className="update-and-request-button-container"></div>
				</Row>
				<Col className="center">
					<div>
						<ImageComponent src={images.Leave_Approve_Empty} className="empty_image" />
					</div>
					<Col style={{ marginTop: '24px' }}>
						<ETypo font={isLeaveBalanceApproveExists ? 'light' : 'medium'} b1 color="#484B52" className="text">
							You did not receive any leave request yet.
						</ETypo>
					</Col>
					<Col style={{ marginTop: '8px' }}>
						<ETypo medium h5>
							Setup now to start you managing your leave requests from here
						</ETypo>
					</Col>
					<div className="status_new_changes">
						<EdenredButtons
							children="Get Started"
							btnType="primary"
							onClick={() => {
								dispatch(setupAction.creators.postHrSetupStatus({ hrOnBoardingFlowCompleted: true }));
								history.push('/leave/departments');
							}}
						/>
					</div>
				</Col>
			</>
		);
	} else {
		return (
			<>
				<Row className="wps_payroll_container_page">
					<div className="page">
						<ETypo bold h2>
							Leaves to Approve
						</ETypo>
					</div>
					<div className="update-and-request-button-container">
						{!isManageEmployeeExists && isLeaveBalanceApproveExists && (
							<EdenredButtons
								{...{
									onClick: () => history.push('/leave/upload'),
									btnType: 'secondary',
									style: { padding: '8px 16px 8px 16px', marginTop: '5px', marginBottom: '0px', width: '206px' },
								}}>
								Add/Edit leave balances
							</EdenredButtons>
						)}
						<EdenredButtons
							{...{
								onClick: () => history.push('/leaves/request_leave'),
								btnType: 'primary',
								style: {
									padding: '7px 16px 7px 16px',
									marginTop: '5px',
									marginBottom: '0px',
									marginLeft: '8px',
									lineHeight: '15px',
									width: '100%',
								},
							}}>
							Request Leave
						</EdenredButtons>
					</div>
				</Row>
				<Col className="center">
					<div>
						<ImageComponent src={images.Leave_Approve_Empty} className="empty_image" />
					</div>
					<Col style={{ marginTop: '24px' }}>
						<ETypo font={isLeaveBalanceApproveExists ? 'light' : 'medium'} b1 color="#484B52" className="text">
							{isManageEmployeeExists && isLeaveBalanceApproveExists
								? 'You did not receive any leave request yet.'
								: 'Looks like nobody wants to go on leave yet.'}
						</ETypo>
					</Col>
					{isManageEmployeeExists && isLeaveBalanceApproveExists && (
						<Col style={{ marginTop: '8px' }}>
							<ETypo medium h5 className="status_request">
								To start receiving leave requests you can:
							</ETypo>
						</Col>
					)}
					{isManageEmployeeExists && isLeaveBalanceApproveExists ? (
						<div className="status_new_changes">
							<EdenredButtons
								{...{
									className: 'invite_portal',
									btnType: 'secondary',
									onClick: () => handleStatusCard('invitePortal'),
									leftIcon: <ImageComponent src={focus_state_icons.NewUser} />,
								}}>
								Invite employees to the portal
							</EdenredButtons>
							<EdenredButtons
								{...{
									className: 'to_leave',
									btnType: 'secondary',
									onClick: () => handleStatusCard('leaveBalance'),
									leftIcon: <ImageComponent src={focus_state_icons.NewDocEdit} />,
								}}>
								Add/Edit leave balance
							</EdenredButtons>
						</div>
					) : (
						<div className="status_new_changes">
							<EdenredButtons
								noShadow={true}
								className="Request_button1"
								onClick={() => history.push('/leaves/leaves_statuses')}
								style={{ width: '100%' }}>
								Leave Status
							</EdenredButtons>
						</div>
					)}
				</Col>
			</>
		);
	}
}

export default LeaveToApproveNoRecords;
