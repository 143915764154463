import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkHrSubscription, entriesDropDown, formatHRCardCounts } from '@helpers';
import { APP_VARIABLES, hrFilterData } from '@constants/app-constants';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ImageComponent from '@sharedComponent/image-component';
import Modalcarousel from '@sharedComponent/modal-carousel';
import Table from '@pages/DesignSystem/Table/Table';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Toast from '@pages/DesignSystem/Toast';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import { useComponentVisible } from 'src/helpers/hooks';
import ClevertapReact from 'src/utils/clever-tap';
import UserService from 'src/services/user-service';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { CustomAlertNotification } from 'src/components/shared/alert-notification/alert-notification';
import ImageIcon from './ImageIcon';
import PromptComponent from './PromptComponent';
import useOutsideClick from './useOutsideClick';
import HrDocumentHeader from './hr-document-header';
import './Expirydocument.scss';

const Expirydocument = ({
	onSearch,
	detail,
	counts,
	column,
	filterLoader = false,
	handleDataCancel,
	stagingLoader,
	saveLoader,
	handleClearAllFilter,
	fromUploadSheetPage,
	excelDownloadLoading,
	pdfDownloadLoading,
	blockedData,
	isRepeatMode,
	employeesPagination,
	handleEmployeesTable,
	visible,
	closePopup,
	autoFixEmployeeFileError,
	handleDataSave,
	savedata,
	handleExpiryDateDocumentType,
	filterDocument,
	downloadLoading,
	downloadDocumentType,
	isBlocking,
	isModified,
	showEmptyFilterWarning,
	userNavigationMenu,
	filterdata,
	hrToast,
	handleHrCard,
	clearToastPopUp,
}) => {
	const ref1 = useRef();
	const { isComponentVisible: isComponentVisibleProp, setIsComponentVisible: setIsComponentVisibleProp } = useComponentVisible(false);
	const history = useHistory();
	// for future reference
	// const filterClicked = (e) => {
	// 	if (window.screen.width <= 600) {
	// 		history.push('/filter');
	// 		return;
	// 	}
	// 	setShow(!show);
	// };

	// function useWindowSize() {
	// 	useLayoutEffect(() => {
	// 		function updateSize() {
	// 			setSize([window.innerHeight - 330, window.innerHeight - (28 + 40 + 560.5 - 1400)]);
	// 		}
	// 		window.addEventListener('resize', updateSize);
	// 		updateSize();
	// 		return () => window.removeEventListener('resize', updateSize);
	// 	}, []);
	// 	return size;
	// }

	// let zoom = window.innerHeight;
	// let overflow = zoom <= 763 ? 'scroll' : 'unset';
	// const scrollRef = useRef(null);
	useOutsideClick(ref1, () => {
		if (isComponentVisibleProp) {
			setIsComponentVisibleProp(!isComponentVisibleProp);
		}
	});

	// const [final2, final] = useWindowSize();
	const cardItems = useMemo(() => formatHRCardCounts(counts), [counts]);
	const [popupNew, setPopupNew] = useState(false);
	const [nextSlide, setNextSlide] = useState(0);
	const MenuAPI = new UserService();
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	useEffect(() => {
		if (!isOnlyEmployeeExits && navbarClaims && (!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('document')) && isSubcribed) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);

	const handleClose = () => {
		setPopupNew(false);
	};
	const data = [
		{
			image: not_focus_icons.Document1,
			text: 'Never lose a document or pay a fine',
		},
		{
			image: not_focus_icons.Document2,
			text: 'Update expiry dates of your employees',
		},
		{
			image: not_focus_icons.Document3,
			text: 'Store employee documents as images',
		},
		{
			image: not_focus_icons.Document5,
			text: 'Check expired & expiring soon documents',
		},
		{
			image: not_focus_icons.Document6,
			text: 'Get monthly reminders in your inbox',
		},
	];
	const handleSlideChange = (flag, status) => {
		let payload = {
			...navbarClaims,
			tourGuide: navbarClaims?.tourGuide ? `${navbarClaims?.tourGuide},${status}` : status,
		};
		if (nextSlide < data?.length - 1) {
			setNextSlide((prev) => prev + 1);
		}
		if (flag == false) {
			MenuAPI.postUserNavigationMenu(payload)
				.then((res) => {
					userNavigationMenu();
					setPopupNew(false);
				})
				.catch((error) => {
					setPopupNew(false);
				});
		}
	};
	const handleFilterValue = () => {
		!filterLoader && setIsComponentVisibleProp(!isComponentVisibleProp);
		ClevertapReact.event('Click_Filters');
	};
	const selectedFilter = (filter) => {
		const value = documentStatus(filter?.value);
		const selected = filterDocument?.documentTypeFilter?.includes(value) || filterDocument?.expiryDateFilter?.includes(value);
		const isDocumentType = filter?.label === 'Document Type';
		handleExpiryDateDocumentType(value, selected, isDocumentType);
	};
	const documentStatus = (value) => {
		switch (value) {
			case 'Expired':
				return 'expired';
			case 'in 1 Month':
				return 'in1month';
			case 'in 3 Months':
				return 'in3months';
			case 'in 6 Months':
				return 'in6months';
			case 'Missing Dates':
				return 'missingdates';
			case 'Labour Card':
				return 'labourcard';
			case 'Emirates ID':
				return 'emiratesid';
			case 'Visa':
				return 'visa';
			case 'Passport':
				return 'passport';
			case 'Expired Documents':
				return 'expired';
			case 'Expiring Soon':
				return 'in6months';
			case 'Missing Dates':
				return 'missingdates';
			case 'Up To Date':
				return 'uptodate';
			default:
				break;
		}
	};
	return (
		<>
			<EdenredHeader />
			<Fragment>
				<PromptComponent when={isBlocking} title="Please Save" />{' '}
				<CustomAlertNotification
					{...{
						className: 'hr_alert_popup',
						when: isBlocking,
						message: 'Are you sure you want to redirect without saving?',
						okText: 'Yes',
						cancelText: 'No',
						onOK: () => true,
						onCancel: () => true,
					}}
				/>
				{/* using different toast component
				 <Toast visible={hrToast} clearPopUp={clearToastPopUp} color="#8EF9B3" textcolor="#484B52">
					Your changes has been saved
				</Toast> */}
				{blockedData ? (
					<Col xl={24} className="payroll_warning">
						<Row justify="center">
							<Col xl={13}>
								<Row>
									<Col xl={2} sm={2} xs={2} md={2}>
										<ImageComponent src={icons.focus_state.Warning} className="c" />
									</Col>
									<Col xl={22} sm={22} xs={22} md={22} className="description">
										<span>{blockedData.corporateMessage}</span>
									</Col>
									<Col xl={24} className="timestamp">
										<Col>
											<span>{blockedData.suspensionMessage}</span>
										</Col>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				) : (
					<Col className="hr_document_new">
						<Col className="hr-table-presentation-outer">
							<Col className="runpayroll-content forHrdoc">
								<Col className="run-payroll-table-header">
									<ETypo bold h2>
										Documents
									</ETypo>
									<div className="new_design_hr">
										<EdenredButtons
											{...{
												btnType: 'secondary',
												className: 'new_hr_update',
												leftIcon: <ImageComponent src={images.plusIcon} />,
												onClick: () => {
													history.push({
														pathname: '/documents/documents-upload',
														state: { savedetails: savedata, filterDocument: filterDocument, updateCleverTap: true },
													});
													ClevertapReact.event('Docs_BulkUpload');
													ClevertapReact.event('V1_1_Docs_UploadBulk');
												},
											}}>
											Update In Bulk
										</EdenredButtons>
										<EdenredButtons
											{...{
												loading: stagingLoader || saveLoader,
												btnType: 'secondary',
												className: 'new_hr_cancel',
												onClick: () => {
													handleDataCancel();
													ClevertapReact.event('Docs_Table_Cancel');
												},
												loading: stagingLoader,
											}}>
											Cancel
										</EdenredButtons>
										<EdenredButtons
											{...{
												disabled: !isModified,
												onClick: () => {
													handleDataSave();
													ClevertapReact.event('Docs_Table_Save');
												},
												loading: stagingLoader || saveLoader,
											}}>
											Save
										</EdenredButtons>
									</div>
								</Col>
								<Col className="run-payroll-table-filter-wrapper">
									<Row className="webSummary">
										{console.log('cardItems', cardItems)}
										{cardItems?.map((props, i) => (
											<ImageIcon key={i} {...props} handleHrCard={handleHrCard} />
										))}
									</Row>
									<div className="hr-table-outer">
										<div className="new_hr_table">
											<Row align="middle" className="filter-area main-div-hr">
												<HrDocumentHeader
													{...{
														entriesDropDown,
														employeesPagination,
														handleEmployeesTable,
														fromUploadSheetPage,
														isRepeatMode,
														hrFilterData,
														handleFilterValue,
														filterLoader,
														handleClearAllFilter,
														excelDownloadLoading,
														pdfDownloadLoading,
														downloadDocumentType,
														downloadLoading,
														isComponentVisibleProp,
														detail,
														setIsComponentVisibleProp,
														filterDocument,
														showEmptyFilterWarning,
														onSearch,
														selectedFilter,
													}}
												/>
											</Row>
											<Col className="runpayroll-table-wrapper">
												{console.log('detail', detail)}
												<Table columns={column} data={detail} tableLoading={filterLoader} />
											</Col>
											<Col xl={24}>
												<div className="hr-pagination-bar">
													<div className="hr-pagination-count">
														Showing {detail?.length} out of {filterdata?.totalCount} Employees
													</div>
													<Pagination
														{...{
															currentPage: filterDocument?.pageNumber,
															totalCount: filterdata?.totalCount || 1,
															pageSize: filterDocument?.pageSize,
															onPageChange: (page) => handleEmployeesTable(page, 'pageNumber'),
														}}
													/>
												</div>
											</Col>
										</div>
									</div>
								</Col>
							</Col>
							<Edenredmodal
								{...{
									isOpen: visible,
									onCancel: closePopup,
									onOkay: autoFixEmployeeFileError,
									title: 'Are you sure you want to cancel?',
									subMessage: 'Changes will not be saved',
								}}
							/>
						</Col>
					</Col>
				)}
				{/* <Modalcarousel
					{...{ visible: popupNew, handleClose, data, handleSlideChange, initialSlide: nextSlide, status: 'document' }}></Modalcarousel> */}
			</Fragment>
		</>
	);
};
export default Expirydocument;
