import React from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import sharedStates from '@sharedComponent/app-action-reducer/selectors';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';


function UnauthorizedAccess(Component) {
	const currentPathName = window.location.pathname 
	const profileCompleted =["/team-payroll","/permission/user","/hrpermission/user","/payroll/approve_requests"]
	return function AuthorizedAccess({currentUser,currentClaims,UserClaims,...props}) {
		const nClaims=currentClaims?.flatMap(data=>data?.value)

	  if (profileCompleted?.includes(currentPathName)&&currentUser==="User"){
	  return <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' ,fontSize:"28px",gap:"10px",flexDirection:"column"}}>
			<ImageComponent style={{width:"50px"}} src={icons.not_focus.subcriptionLock}/>
			<p style={{fontFamily:"Montserrat-Regular" }}>Unauthorized Access</p>
			</Col>  
	  } 
	  else if(currentClaims?.length===0&&currentUser==="User"||!nClaims?.includes(UserClaims)&&currentUser==="User"){
		return <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' ,fontSize:"28px",gap:"10px",flexDirection:"column"}}>
				<ImageComponent style={{width:"50px"}} src={icons.not_focus.subcriptionLock}/>
				<p style={{fontFamily:"Montserrat-Regular" }}>Unauthorized Access</p>
				</Col>  
	  }
	  else{
		  return <Component {...props} />;
	  }
	};
  }
export default UnauthorizedAccess;


