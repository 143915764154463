import React from 'react';
import DefaultCheckbox from './Example/DefaultCheckbox';

const Index = () => {
	return (
		<>
			<DefaultCheckbox />
		</>
	);
};

export default Index;
