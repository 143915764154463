import React from 'react';
import LandingNewFunctional from './component/new-landing-functional';

const LandingNewMain = () => {
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<LandingNewFunctional />
		</div>
	);
};

export default LandingNewMain;
