import Axios from 'axios';
import { message } from 'antd';
import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { appConstant, apiUrl } from '@constants';
import Actions from '../action';

export function* sendInviteWorker(action) {
	try {
		const uri = `${appConstant.domain}${apiUrl.GET_ALL_USER}`;
		const { history } = action;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getUsersSuccess(res.data));
	} catch (err) {
		let mes = err.toString(err) === 'Error: Request failed with status code 400' ? appConstant.userNotExist : 'Something went wrong';
		message.error(mes);
		// yield put(Actions.creators.getUser());
	}
}
export function* claimsWorker({ roleId }) {
	try {
		const uri = `${appConstant.domain}${apiUrl.GET_CLAIMS}${roleId}/claims`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getClaimsSuccess(res.data.claims));
	} catch (err) {
		let mes = err.toString(err) === 'Error: Request failed with status code 400' ? appConstant.userNotExist : 'Something went wrong';
		message.error(mes);
		// yield put(Actions.creators.getUser());
	}
}
export function* getEmployeesService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}?status=ACTIVE`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeesSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeesFailed(err));
	}
}
export function* employeeUserServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const emp_Id = action?.payload;
	try {
		const uri = `${DOMAIN_URL}/Users/${corporateId}/${emp_Id}/employee-user`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeeUserSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeeUserFail(err));
	}
}

export default function* appWatcher() {
	yield takeEvery(Actions.types.SEND_INVITE, sendInviteWorker);
	yield takeEvery(Actions.types.GET_CLAIMS, claimsWorker);
	yield takeEvery(Actions.types.GET_EMPLOYEES_START, getEmployeesService);
	yield takeEvery(Actions.types.GET_EMPLOYEE_USER_START, employeeUserServices);
}
