import Action from './action';

export const initState = {
	employeeBalanceLoading: false,
	employeeBalance: [],
	employeeBalanceError: '',
	employeeLeaveTypeLoading: false,
	employeeLeaveType: [],
	employeeLeaveTypeError: '',
	saveLeaveLoading: false,
	saveLeaveDetail: [],
	saveLeaveError: '',
	saveLeaveSuccessfully: false,
};

const leaveBalanceReducer = (state = initState, action) => {
	console.log('reducer');
	console.log('Action', Action.types);
	switch (action.type) {
		case Action.types.GET_LEAVEBALANCE_START: {
			console.log('GET_LEAVEBALANCE_START');
			return {
				...state,
				employeeBalanceLoading: true,
			};
		}
		case Action.types.GET_LEAVEBALANCE_SUCCESS: {
			console.log('GET_LEAVEBALANCE_SUCCESSS');
			console.log('action.response', Action.response);
			console.log('employeeBalance', state.employeeList);
			return {
				...state,
				employeeBalanceLoading: false,
				employeeBalance: action?.response,
			};
		}
		case Action.types.GET_LEAVEBALANCE_FAIL: {
			console.log('GET_LEAVEBALANCE_FAIL');
			return {
				...state,
				employeeBalanceLoading: false,
				employeeBalanceError: action.error,
			};
		}
		case Action.types.GET_LEAVETYPE_START: {
			console.log('GET_LEAVETYPE_START');
			return {
				...state,
				employeeLeaveTypeLoading: true,
			};
		}
		case Action.types.GET_LEAVETYPE_SUCCESS: {
			console.log('action.response GET_LEAVETYPE_SUCCESS', action?.response?.leaveTypes);
			console.log('employeeBalance', state.employeeList);
			return {
				...state,
				employeeLeaveTypeLoading: false,
				employeeLeaveType: action?.response || [],
			};
		}
		case Action.types.GET_LEAVETYPE_FAIL: {
			console.log('GET_LEAVETYPE_FAIL');
			return {
				...state,
				employeeLeaveTypeLoading: false,
				employeeLeaveTypeError: action.error,
			};
		}

		case Action.types.SAVE_LEAVETYPE_START: {
			return {
				...state,
				saveLeaveLoading: true,
			};
		}
		case Action.types.SAVE_LEAVETYPE_SUCCESS: {
			return {
				...state,
				saveLeaveLoading: false,
				saveLeaveDetail: action?.response || [],
			};
		}
		case Action.types.SAVE_LEAVETYPE_FAIL: {
			return {
				...state,
				saveLeaveLoading: false,
				saveLeaveError: action.error,
			};
		}
		case Action.types.SAVE_LEAVE_SUCCEESSFUL_POPUP: {
			return {
				...state,
				saveLeaveSuccessfully: action.flag,
			};
		}
		default:
			return state;
	}
};

export default leaveBalanceReducer;
