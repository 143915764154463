import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ImageComponent from '@sharedComponent/image-component';
import { STEPPER_STATUS_KEY, PAYROLL_EMPLOYEE_STATUS_KEY } from '@constants/app-constants';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ContentLoader from '@sharedComponent/content-loader';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Stepper from '@pages/DesignSystem/stepper';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Toast from '@pages/DesignSystem/Toast';
import { icons } from 'src/assets/icons';
import PayrollService from 'src/services/payroll-service';
import ClevertapReact from 'src/utils/clever-tap';
import PayrollStatusContent from './payroll-status-content';

const mobile_view_status_messages = {
	waiting_for_salary_funds: {
		steps: [
			{
				step: 1,
				description: ' Transfer salary funds to our account: ',
				link: { name: ' View account Details', path: '/payroll/bank_details' },
			},
			{
				step: 2,
				description: ' After transferring the salary funds: ',
				link: { name: ' Upload Proof of Payment', path: '/payroll/proof_of_transfer' },
			},
			{
				step: 3,
				description: ' Status will be updated within 30 minutes of uploading your proof of payment.',
			},
		],
	},
	expected_salary_payout: {
		message: 'You’ll be notified when employees receive their salaries.',
	},
};

const PayrollStatusMobileViewContent = ({ status }) => {
	switch (status) {
		case 1:
			return (
				<Col xl={21} md={21} sm={24} lg={21} xs={24} className="payroll_status_yellow_card_mobile_view">
					<Card className="tooltip_yellow_card">
						<Row className="tip_note_content">
							<Col sm={3} xs={3} className="tip_note_icon">
								<ImageComponent src={icons.focus_state.Tip} />
							</Col>
							<Col sm={18} xs={18} className="tip_note_message">
								Please remind your verifier to approve your payroll run
							</Col>
						</Row>
					</Card>
				</Col>
			);
		case 2:
			return (
				<Col sm={24} xs={24} className="payroll_status_yellow_card_mobile_view">
					{mobile_view_status_messages.waiting_for_salary_funds?.steps?.map((step, index) => (
						<Col key={index} sm={24} xs={24} className="payroll_status_step_content">
							<EdenredTypo>
								{step?.description}
								{step?.link ? <Link to={step?.link?.path}>{step?.link?.name}</Link> : null}
							</EdenredTypo>
						</Col>
					))}
				</Col>
			);
		case 3:
			return (
				<Col sm={24} xs={24} className="payroll_status_yellow_card_mobile_view">
					<EdenredTypo className="expected_salary_payout_message">
						{mobile_view_status_messages?.expected_salary_payout?.message}
					</EdenredTypo>
				</Col>
			);
		default:
			return null;
	}
};

const PayrollStatus = () => {
	const [contents, setcontents] = useState([]);

	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const history = useHistory();
	const location = useLocation();
	const cardDetail = location?.state?.cardDetail;
	const [stepModal, setStepModal] = useState(false);
	const [state, setstate] = useState({
		loading: true,
		statusResponse: null,
		stepCount: 0,
	});
	const { uploadId } = useParams();

	const [isLoading, setIsLoading] = useState(false);
	const showModal = () => {
		ClevertapReact.event('CancelPayroll_PayrollStatus', null);
		setStepModal(true);
	};

	const afterOpenModal = () => {
		setStepModal(false);
	};
	const closeModal = () => {
		setStepModal(false);
	};

	const onModalSumbit = () => {
		setIsLoading(true);
		api.cancelPayroll({ payrollAction: 'Reject', payrollSelectedRuns: [uploadId] })
			.then((res) => {
				setIsLoading(false);
				setStepModal(false);
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: 'Your payroll run was cancelled',
					subMessage: null,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const api = new PayrollService();
	const { loading, statusResponse, stepCount } = state;
	const getPayrollStatus = () => {
		let type = null;
		if (cardDetail) {
			type = cardDetail.accountType === 'L' ? 'C3' : 'BANK';
		}
		api.getPayrollStatus(uploadId, type)
			.then((result) => {
				setstate({
					...state,
					loading: false,
					statusResponse: result.data,
				});
			})
			.catch((err) => {
				setstate({
					...state,
					loading: false,
				});
			});
	};

	useEffect(() => {
		if (statusResponse) {
			const {
				payrollSubmittedByUser,
				approvedByVerifier,
				salaryFundsAreReady,
				salaryProcessed,
				yourEmployeesPaid,
				submittedDate,
				lastUpdatedDate,
				status,
			} = statusResponse?.payrollStatus;

			let payrollSubmitted = status?.find((element) => element?.payrollStatusType === 'submitted' && element);
			let payrollApproved = status?.find((element) => element?.payrollStatusType === 'approved' && element);
			let payrollFundsReady = status?.find((element) => element?.payrollStatusType === 'fundsReady' && element);
			let payrollProcessed = status?.find((element) => element?.payrollStatusType === 'processed' && element);
			let payrollPaid = status?.find((element) => element?.payrollStatusType === 'paid' && element);

			let payrollSteps = [
				{
					id: 1,
					title: payrollSubmitted?.date ? moment(payrollSubmitted?.date).format('MMM DD') : '',
					errorTitle: 'Submitted Failed',
					description:
						payrollSubmitted?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? 'Processing Payroll' : 'Payroll Submitted',
					status:
						payrollSubmitted?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: payrollSubmitted?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: payrollSubmitted?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',

					errorMessage: payrollSubmitted?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && payrollSubmitted?.message,
					errorLinkText: payrollSubmitted?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && '',
					onerrorLinkClick: () => {},
					message: payrollSubmitted?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && payrollSubmitted?.message,
					messageLinkText: payrollSubmitted?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && '',
					onMessageLinkClick: () => {},
				},
				{
					id: 2,
					title: payrollApproved?.date ? moment(payrollApproved?.date).format('MMM DD') : '',
					errorTitle: 'Approved Failed',
					description:
						payrollApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? ' Processing approval' : 'Approved by verifier',
					status:
						payrollApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: payrollApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: payrollApproved?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: payrollApproved?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && payrollApproved?.message,
					errorLinkText: payrollApproved?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && 'Check Now',
					onerrorLinkClick: () => {},
					message: payrollApproved?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && payrollApproved?.message,
					messageLinkText: payrollApproved?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && '',
					onMessageLinkClick: () => {},
				},
				{
					id: 3,
					title: payrollFundsReady?.date ? moment(payrollFundsReady?.date).format('MMM DD') : '',
					errorTitle: 'Funds failed',
					description:
						payrollFundsReady?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? ' Processing salary funds '
							: 'Salary funds ready',
					status:
						payrollFundsReady?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: payrollFundsReady?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: payrollFundsReady?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: payrollFundsReady?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && payrollFundsReady?.message,
					errorLinkText: payrollFundsReady?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && 'Check Now',
					onerrorLinkClick: () => {},
					message: payrollFundsReady?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && payrollFundsReady?.message,
					messageLinkText: payrollFundsReady?.messageType === PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && '',
					onMessageLinkClick: () => {},
				},
				{
					id: 4,
					title: payrollProcessed?.date ? moment(payrollProcessed?.date).format('MMM DD') : '',
					errorTitle: 'Payroll processing failed',
					description:
						payrollProcessed?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? 'Processing salaries' : 'Salaries processed',
					status:
						payrollProcessed?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: payrollProcessed?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: payrollProcessed?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: payrollProcessed?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && payrollProcessed?.message,
					errorLinkText: ' Check Now',
					onerrorLinkClick: () => {
						history.push({ pathname: `/payroll/rechecking_failed_employee/${uploadId}`, state: { state: uploadId, cardDetail } });
					},
					message: payrollProcessed?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && payrollProcessed?.message,
					messageLinkText: '',
					onMessageLinkClick: () => {},
				},
				{
					id: 5,
					title: payrollPaid?.date ? moment(payrollPaid?.date).format('MMM DD') : '',
					errorTitle: 'Payroll  failed',
					description:
						payrollPaid?.status == PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK ? 'Processing payment' : 'Your employees have been paid!',
					status:
						payrollPaid?.status === PAYROLL_EMPLOYEE_STATUS_KEY.COMPLETED_CHECK
							? STEPPER_STATUS_KEY.COMPLETED
							: payrollPaid?.status === PAYROLL_EMPLOYEE_STATUS_KEY.PROCESSING_CHECK
							? STEPPER_STATUS_KEY.PROCESSING
							: payrollPaid?.status === PAYROLL_EMPLOYEE_STATUS_KEY.ERROR_CHECK
							? STEPPER_STATUS_KEY.FAILED
							: '',
					errorMessage: payrollPaid?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && payrollPaid?.message,
					errorLinkText: payrollPaid?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_ERROR && 'Check Now',
					onerrorLinkClick: () => {
						history.push({ pathname: `/payroll/rechecking_failed_employee/${uploadId}`, state: { state: uploadId, cardDetail } });
					},
					message: payrollPaid?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && payrollPaid?.message,
					messageLinkText: payrollPaid?.messageType == PAYROLL_EMPLOYEE_STATUS_KEY.MESSAGE_SUCCESS && '',
					onMessageLinkClick: () => {},
				},
			];
			const temp = [
				{
					label: 'payrollSubmittedByUser',
					value: payrollSubmittedByUser,
					date: submittedDate,
				},
				{
					label: 'approvedByVerifier',
					value: approvedByVerifier,
					date: '',
				},
				{
					label: 'salaryFundsAreReady',
					value: salaryFundsAreReady,
					date: '',
				},
				{
					label: 'salaryProcessed',
					value: salaryProcessed,
					date: '',
				},
				{
					label: 'yourEmployeesPaid',
					value: yourEmployeesPaid,
					date: lastUpdatedDate,
				},
			];

			const index = temp?.filter((data) => data?.value)?.length;
			const tempContent = temp?.map(({ date }, i) => {
				return {
					...contents[i],
					date,
				};
			});

			setstate({
				...state,
				stepCount: index,
			});

			setcontents(payrollSteps);
		}
	}, [statusResponse]);

	useEffect(() => {
		getPayrollStatus();
	}, []);

	const navigateToviewPayroll = () => {
		history.push(`/payroll/failedDetail/${uploadId}`, { state: uploadId, cardDetail });
	};

	// const stepCountTemp = `4.3`;
	const stepCountTemp = stepCount === 3 ? 4.0 : `${stepCount}.3`;

	const expectedPayoutTime = statusResponse && statusResponse && statusResponse.payrollStatus?.expectedPayoutTime;
	const employeesCount = parseInt(statusResponse && statusResponse && statusResponse.payrollStatus?.employeesCount) || 0;
	const rejectedCount = parseInt(statusResponse && statusResponse && statusResponse.payrollStatus?.rejectedCount) || 0;

	const isRejected = employeesCount === rejectedCount;

	const handlepayrollcanc = () => {
		ClevertapReact.event('CancelPayroll_PayrollStatus');
	};

	const closePopup = () => {
		setpopupVisible(false);
		history.push('/payroll/history');
	};

	const steps = [
		{
			id: 1,
			heading: 'July 11',
			para: 'Payroll Submitted',
			status: 'COMPLETED',
		},
		{
			id: 2,
			heading: 'July 11',
			para: 'Approved by verifier',
			status: 'COMPLETED',
		},
		{
			id: 3,
			heading: 'July 12',
			para: 'Salary funds are ready',
			status: 'COMPLETED',
		},
		{
			id: 4,
			heading: 'July 11',
			para: 'Salaries processed',
			status: 'COMPLETED',
			error: 'Payroll for 2 employee failed ',
			errorLink: '',
			errorLinkText: 'Check Now',
		},
		{
			id: 5,
			heading: 'July 11',
			para: 'Your employees have been paid!',
			status: '',
		},
	];

	return (
		<>
			{/* <Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup> */}
			<Toast visible={popupVisible} color="#8EF9B3" textcolor="#484B52" clearPopUp={closePopup}>
				{popupMessage.titleMessage}
			</Toast>
			<EdenredHeader />
			{loading ? (
				<div className="payroll-step-component-container">
					<ContentLoader />
				</div>
			) : (
				// <Row className="payroll_status_container">
				// 	{/** Status:
				// 	 * 1 --> PENDING_APPROVAL
				// 	 * 2 --> WAITING_FOR_SALARY_FUNDS
				// 	 * 3 --> EXPECTED_PAYOUT
				// 	 * 4 --> SALARIES_CREDITED
				// 	 * 5 --> SALARY PROCESSING FAILED FOR (No of) Some Employees
				// 	 * 6 --> SALARY PROCESSING FAILED FOR ALL Employees
				// 	 */}{' '}
				// 	<Fragment>
				// 		<PayrollStatusContent
				// 			expectedPayoutTime={expectedPayoutTime}
				// 			status={isRejected ? 6 : stepCount}
				// 			completedText={
				// 				rejectedCount
				// 					? `Salary Processing Failed For ${rejectedCount} Employees`
				// 					: 'Salaries Have Been Credited To Employees!'
				// 			}
				// 		/>
				// 		<Divider />
				// 		<Col xl={20} lg={20} md={20} sm={12} xs={16} className="payroll_status_payroll_amount_details">
				// 			<EdenredTypo className="desktop_view_content">
				// 				{`${statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerAmount} - ${
				// 					statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerType
				// 				} (${statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerEmployeeCount})`}
				// 			</EdenredTypo>
				// 			<EdenredTypo className="mobile_view_content">
				// 				<span>{`${statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerType}`}</span>
				// 				<span>{`${statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerAmount} for ${
				// 					statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerEmployeeCount
				// 				}`}</span>
				// 				{/* <span>AED 528,000.00 for 150 employees</span> */}
				// 			</EdenredTypo>
				// 		</Col>
				// 		<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_status_payroll_steps_progress_bar">
				// 			<EdenredStep
				// 				uploadId={uploadId}
				// 				activeStep={`${stepCountTemp}`}
				// 				contents={contents}
				// 				rejectedCount={rejectedCount}
				// 				employeesCount={employeesCount}
				// 				cardDetail={cardDetail}
				// 			/>
				// 		</Col>
				// 		<PayrollStatusMobileViewContent status={stepCount} />
				// 		{rejectedCount !== 0 && (
				// 			<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error" style={{ paddingTop: 15, paddingBottom: 15 }}>
				// 				<Card className="card_action">
				// 					<Row>
				// 						<Col xl={1} sm={3} xs={3} md={2}>
				// 							<ImageComponent src={icons.focus_state.Error} />
				// 						</Col>
				// 						<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
				// 							{isRejected
				// 								? `Run payroll again after checking all WPS Person IDs and WPS Establishment IDs. Contact +97145214000 for
				// 							help`
				// 								: 'Run payroll again ONLY for the failed employees after rechecking their WPS Person ID and WPS Establishment ID.'}
				// 						</Col>
				// 						<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
				// 							{isRejected
				// 								? `Run payroll again after checking all WPS Person IDs and WPS Establishment IDs. Contact +97145214000 for
				// 							help`
				// 								: 'Run payroll again ONLY for the failed employees after rechecking their WPS Person ID and WPS Establishment ID.'}
				// 						</Col>
				// 					</Row>
				// 				</Card>
				// 			</Col>
				// 		)}
				// 		<Row className="payroll_status_button_section">
				// 			<Col xl={5} lg={8} md={10} sm={17} xs={12} className="payroll_status_view_button">
				// 				<EdenredButton noShadow={true} onClick={navigateToviewPayroll}>
				// 					View Payroll Details
				// 				</EdenredButton>
				// 			</Col>
				// 			<Col sm={24} xs={24} className="payroll_status_view_button_mobile">
				// 				<EdenredButton noShadow={true} onClick={navigateToviewPayroll}>
				// 					Payroll Details
				// 				</EdenredButton>
				// 			</Col>
				// 			<Col xl={5} lg={8} md={10} sm={20} xs={12} className="payroll_status_cancel_button" onClick={() => handlepayrollcanc()}>
				// 				<EdenredButton
				// 					disabled={
				// 						statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.approvedByVerifier === true
				// 					}
				// 					noShadow={true}
				// 					type={'outline-g'}
				// 					onClick={showModal}>
				// 					<ImageComponent src={icons.not_focus.Close} />
				// 					Cancel Payroll
				// 				</EdenredButton>
				// 			</Col>
				// 		</Row>
				// 	</Fragment>
				// 	<EdenredModal
				// 		{...{
				// 			isOpen: stepModal,
				// 			afterOpenModal: afterOpenModal,
				// 			closeModal: closeModal,
				// 			getDeleterole: onModalSumbit,
				// 			user: 'Cancel Payroll',
				// 			content_mobile: 'Do you want to cancel this payroll run?',
				// 			content_web: 'Do you want to cancel this payroll run?',
				// 			style: { width: '100%' },
				// 			className: 'modal_cancel_payroll',
				// 			go_Back: 'No',
				// 		}}
				// 	/>
				// </Row>

				<>
					{/* <EdenredStep
						uploadId={uploadId}
						activeStep={`${stepCountTemp}`}
						contents={contents}
						rejectedCount={rejectedCount}
						employeesCount={employeesCount}
						cardDetail={cardDetail}
					/> */}
					<div className="payroll-step-component-container">
						<div className="payroll-status-header-container">
							<ETypo h2 bold color="#00344E">
								{statusResponse &&
								statusResponse?.payrollStatus &&
								typeof statusResponse?.payrollStatus?.currentPayrollStatus === 'string'
									? statusResponse?.payrollStatus?.currentPayrollStatus
									: ''}
							</ETypo>
							<div className="button-container-payroll-status">
								<EdenredButtons
									btnType="secondary"
									onClick={showModal}
									disabled={
										statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.approvedByVerifier === true
									}>
									{' '}
									Cancel Payroll
								</EdenredButtons>
								<EdenredButtons onClick={navigateToviewPayroll}>View Payroll Details</EdenredButtons>
							</div>
						</div>
						<div className="payroll-step-container" style={{ borderRadius: stepCount > 2 || isRejected ? '0px' : '8px' }}>
							<ETypo H4 medium color="#00344E">
								{`${statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerAmount}`}
							</ETypo>
							<div className="step-component-detail-container">
								<div className="step-component-first-section">
									<ETypo b1 light color="#484B52">
										{statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerType}
									</ETypo>
								</div>
								<ETypo style={{ marginLeft: '8px' }} b1 light color="#484B52">
									{statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.headerEmployeeCount}
								</ETypo>
							</div>
							<div className="step-component-main">
								<Stepper state={contents && contents}></Stepper>
							</div>
						</div>
						{stepCount > 2 || isRejected ? (
							<div className="divider">
								<div className="payroll-divider"></div>
							</div>
						) : null}
						<div
							className="next-step-container"
							style={{
								marginTop: stepCount > 2 || isRejected ? '0px' : '22px',
								borderRadius: stepCount > 2 || isRejected ? '0px' : '8px',
							}}>
							<PayrollStatusContent
								expectedPayoutTime={expectedPayoutTime}
								status={isRejected ? 6 : stepCount}
								completedText={
									rejectedCount
										? `Salary Processing Failed For ${rejectedCount} Employees`
										: 'Salaries Have Been Credited To Employees!'
								}
							/>
						</div>
					</div>
					{/* <EdenredModal
						{...{
							isOpen: stepModal,
							afterOpenModal: afterOpenModal,
							closeModal: closeModal,
							getDeleterole: onModalSumbit,
							user: 'Cancel Payroll',
							content_mobile: 'Do you want to cancel this payroll run?',
							content_web: 'Do you want to cancel this payroll run?',
							style: { width: '100%' },
							className: 'modal_cancel_payroll',
							go_Back: 'No',
						}}
					/> */}
					<Edenredmodal
						{...{
							isOpen: stepModal,
							onCancel: closeModal,
							onOkay: onModalSumbit,
							title: 'Cancel Payroll',
							desc: 'Do you want to cancel this payroll run?',
							textcenter: true,
							loading: isLoading,
						}}
					/>
				</>
			)}
		</>
	);
};

export default PayrollStatus;
