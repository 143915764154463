import axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';

export const USERS = '/Users';
export const GET_USERS = 'GET_USERS';
export const UPDATE_CLAIMS = 'UPDATE_CLAIMS';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';

export default class TeamPermission {
	services(type, params, data) {
		switch (type) {
			case GET_USERS:
				return axios.get(
					`${DOMAIN_URL}/Users/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}?pageNumber=${params?.pageNumber}&pageSize=${
						params?.pageSize
					}&search=${params?.search}&filter=${params?.filter}`
				);
			case UPDATE_CLAIMS:
				return axios.post(
					`${DOMAIN_URL}/Users/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${params.username}/actions/claims/modify`,
					data
				);
			case CREATE_USER:
				return axios.post(`${DOMAIN_URL}/Users/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${params.username}`, data);
			case DELETE_USER:
				return axios.delete(`${DOMAIN_URL}/Users/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${params.username}`);
			default:
				break;
		}
	}
}
