import React from 'react';
import { Card, Col, Divider, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { DotLoader } from 'react-spinners';
import { icons } from '@assets/icons';
import { DASH_BOARD_CONSTANT } from '@constants/app-constants';

const EmployeesPaidCard = ({ isEmployeePaidLoading, employeePaidByMonth }) => {
	const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const currentMonth = month[new Date().getMonth()];
	const currentYear = new Date().getFullYear().toString();
	return (
		<Col xl={12} lg={24} md={24} sm={24} xs={24} className="employee_paid_card_column">
			<Card className="employee_paid_card">
				<Row className="employee_paid_card_title_row">
					<Col xxl={1} xl={1} lg={1} md={1} className="employee_paid_icon">
						<div className="dollar_content_div">
							<ImageComponent src={icons.focus_state.CashPickUp} />
						</div>
					</Col>
					<Col xxl={9} xl={8} lg={8} md={8} className="employee_paid_card_title empPaid">
						<EdenredTypo style={{ color: '#181919' }}>% Of Employees Paid</EdenredTypo>
					</Col>
				</Row>
				{isEmployeePaidLoading ? (
					<Col
						style={{
							minHeight: '120px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
						}}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				) : (
					<Col xl={24} lg={24} md={24} className="employee_paid_card_details_section">
						{employeePaidByMonth?.map((content, index) => {
							let perc1 = (content?.totalEmployeePaid / content?.totalEmployeeProcessed) * 100;
							let users = perc1.toFixed(2);
							let user = users.split('.');
							let con = user[0];
							let con1 = user[1] > '00' ? `.${user[1]}` : null;
							if (isNaN(con)) con = 0;
							return (
								<>
									<Row
										key={index}
										className={
											currentMonth === content?.month && currentYear === content?.year
												? 'employee_paid_current_month_detail_row'
												: 'employee_paid_detail_row'
										}>
										<Col xl={7} lg={7} md={7} xs={7} sm={5} className="employee_paid_month">
											<EdenredTypo>{content?.month}</EdenredTypo>
										</Col>
										<Col
											xl={9}
											lg={9}
											md={9}
											xs={9}
											sm={8}
											className={`employee_paid_percentage${con < 80 ? ' low_percentage' : ''}`}>
											<EdenredTypo>
												{con}
												{con1} %
											</EdenredTypo>
										</Col>
										<Col
											xl={8}
											lg={8}
											md={8}
											xs={8}
											sm={11}
											className={
												currentMonth === content?.month && currentYear === content?.year
													? 'employee_paid_no_of_employee'
													: 'employee_paid_no_of_employee_prev'
											}>
											<EdenredTypo>
												({content?.totalEmployeePaid} / {content?.totalEmployeeProcessed} Employees)
											</EdenredTypo>
										</Col>
									</Row>
									<Divider style={{ margin: '6px' }} />
								</>
							);
						})}
					</Col>
				)}
				<Row className="employee_paid_card_footer">
					<Col xl={20} lg={20} md={20} className="employee_paid_info_section">
						<ImageComponent src={icons.not_focus.Infonew} style={{ width: '16px' }} />
						<EdenredTypo className="employee_info_text">{DASH_BOARD_CONSTANT?.WPS_PERCENTAGE}</EdenredTypo>
					</Col>
				</Row>
			</Card>
		</Col>
	);
};

export default EmployeesPaidCard;
