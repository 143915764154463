import React, { useCallback, useEffect, useState } from 'react';
import Chips from '../Chips/index';
import { icons } from '@assets/icons/index';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import './sidenavbar.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { APP_VARIABLES, SETUP_REDIRECT } from '@constants/app-constants';
import userManager from 'src/config/user-manager';
import { verifierClaimsArr } from '@constants/index';
import { useSelector } from 'react-redux';
import { arrayObjToArray } from '@helpers';
import useSideEffects from './hooks/useSideEffects';
import useMenus from './hooks/useMenus';
import ClevertapReact from 'src/utils/clever-tap';

const SideNavBar = ({ removeUser, currentUser }) => {
	const [mainmenu, setMainmenu] = useState([]);
	const [navbar, setNavbar] = useState(false);
	const [size, setSize] = useState(false);
	const approveReqCount = useSelector((state) => state?.sharedStates?.PendingStatusDetail?.pendingRequest);
	const role = (currentUser && currentUser?.roles && currentUser?.roles) || null;
	const emp_Id = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeCode);
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits) || false;
	const userName = currentUser?.user?.userName;
	const location = useLocation();
	const history = useHistory();

	const { menus } = useMenus();
	useSideEffects({ setMainmenu, menus, setSize, location });

	const onMainMenuClick = (menuKey) => {
		if (menuKey !== 'logout') {
			let pathToPush = null;
			const head = mainmenu?.map((item) => {
				if (item.key === menuKey) {
					pathToPush = item.path;
					return {
						...item,
						isSelect: !item.isSelect,
						subMenus: item.subMenus
							? item.subMenus.map((subMenu, index) => {
									if (index === 0) {
										return {
											...subMenu,
											isSelect: true,
										};
									} else {
										return { ...subMenu };
									}
							  })
							: [],
					};
				}
				return { ...item, isSelect: false };
			});
			setMainmenu(head);
			if (typeof pathToPush === 'string') {
				history.push({ pathname: pathToPush });
			}
		} else {
			removeUser();
			localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
			userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
			userManager.removeUser(); // removes the user data from sessionStorage
			localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
			localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
			localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
			localStorage.removeItem('isRedirectNeeded');
			localStorage.removeItem('isRedirectVerifierNeeded');
			localStorage.removeItem('amount');
			localStorage.removeItem('transferFundsPopupVisible');
			localStorage.removeItem('showUnemploymentBanner');
			localStorage.removeItem(SETUP_REDIRECT.SETUP_REDIRECT_TO);
		}
	};

	const onSubMenuClick = (menuKey) => {
		let pathToPush = null;
		let itemTemp;
		const submenus = mainmenu?.map((item) => {
			let menusIsSelected = false;
			return {
				...item,
				subMenus: item?.subMenus?.map((items) => {
					if (items.key === menuKey) {
						pathToPush = items.path;
						menusIsSelected = true;
						itemTemp = item;
						return { ...items, isSelect: true };
					}
					return { ...items, isSelect: false };
				}),
				isSelect: menusIsSelected,
			};
		});
		setMainmenu(submenus);
		if (typeof pathToPush === 'string') {
			history.push({ pathname: pathToPush });
		}
	};

	const menuFilter = (array, role) => {
		return array?.filter((items) => items.isnavigation === role);
	};
	const payrollMenuItems = menuFilter(mainmenu, 'payroll');
	const hrMenuItems = menuFilter(mainmenu, 'hr');
	const defaultMenuItems = menuFilter(mainmenu, 'default');
	const bottomMenuItems = menuFilter(mainmenu, 'bottom');

	const currentUserApproverClaimesArr = payrollMenuItems?.map((pClaims) => {
		if (verifierClaimsArr.includes(pClaims.value)) {
			return pClaims.value;
		}
	});

	return (
		<section className={navbar || size ? 'responsive' : 'navbar'}>
			<div className="side-nav-outer">
				<div className="side-nav-inner">
					<div className="side-nav-top">
						<div
							className="profile"
							onClick={() =>
								history.push({ pathname: '/dashboard/profile', state: { tableData: { employeeId: emp_Id }, mainProfile: true } })
							}>
							<div className="profile-image">
								<ImageComponent src={icons.focus_state.User} className="user" />
							</div>
							<div className="profile-content">
								<p className="h5 side-nav-user-name">{userName ? userName : ''}</p>
								<p className="verifier">
									{isOnlyEmployeeExits
										? 'Account'
										: arrayObjToArray(role, 'name')?.includes('SuperAdmin')
										? 'Super Admin'
										: 'Admin'}
								</p>
							</div>

							<div className="profile-icon">
								<ImageComponent src={images.navicon} onClick={() => setNavbar(!navbar)} className="navicon" />
							</div>
						</div>
						{currentUserApproverClaimesArr && (
							<div className="pending-outer">
								<div
									className="pending"
									onClick={() => history.push({ pathname: '/dashboard/pending-alerts', state: { approveReqCount, role } })}>
									<div className="image">
										<div className="pending-image">
											<ImageComponent src={images.pending} />
										</div>
									</div>
									<div className="pending-content">
										<p className="alert">
											{approveReqCount && approveReqCount?.totalRequest ? approveReqCount?.totalRequest : 0} Pending Alerts
										</p>
										<p className="details">View Details</p>
									</div>
									<div className="pending-arrow">
										<ImageComponent src={icons.not_focus.up} className="pending-arrow-image" />
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="make_scroll">
						<div className="side-nav-items">
							{payrollMenuItems?.map((item) => (
								<GetMenuItem menus={item} onMainMenuClick={onMainMenuClick} mainmenu={mainmenu} onSubMenuClick={onSubMenuClick} />
							))}
						</div>
						<div className="side-nav-items">
							{hrMenuItems?.map((item) => (
								<GetMenuItem menus={item} onMainMenuClick={onMainMenuClick} mainmenu={mainmenu} onSubMenuClick={onSubMenuClick} />
							))}
						</div>
						<div className="side-nav-items">
							{defaultMenuItems?.map((item) => (
								<GetMenuItem menus={item} onMainMenuClick={onMainMenuClick} mainmenu={mainmenu} onSubMenuClick={onSubMenuClick} />
							))}
						</div>
					</div>
					<div className="side-nav-items">
						{bottomMenuItems?.map((item) => (
							<GetMenuItem menus={item} onMainMenuClick={onMainMenuClick} mainmenu={mainmenu} onSubMenuClick={onSubMenuClick} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SideNavBar;

const GetMenuItem = ({ menus, onMainMenuClick, onSubMenuClick }) => {
	if (menus?.type === 'common') {
		return (
			<div className="experience">
				<p className="experience-content">Experience better HR and Payroll</p>
				<button>Know more </button>
			</div>
		);
	} else {
		return (
			<div className="noresponsive">
				<div className={` fs-nav-out ${menus?.isSelect ? 'fs-nav-out-active' : ''}`} onClick={() => onMainMenuClick(menus?.key)}>
					<div className="fs-nav-item">
						<ImageComponent
							src={menus?.isSelect ? menus.activeIcon : menus.icon}
							className={`fs-icon ${menus?.isSelect ? 'small' : 'big'} `}
						/>
						<div className={menus?.isSelect ? 'fs-nav-label-active' : 'fs-nav-label-out'}>{menus.label} </div>
						<div className="icn-chips">
							{menus?.chip && (
								<Chips color="#EEDCBA">
									<p className="text">New</p>
								</Chips>
							)}
						</div>
					</div>
					<div className="fs-nav-arrow">
						{menus?.subMenus?.length > 0 && (
							<ImageComponent src={menus?.isSelect ? icons.not_focus.up : icons.not_focus.down} className="fs-sub-menu-arrow" />
						)}
					</div>
				</div>
				<div className="submenu" data-test="hyy">
					{menus?.subMenus?.length > 0 &&
						menus?.isSelect &&
						menus?.subMenus?.map((item) => <Getsubmenu menus={item} onSubMenuClick={onSubMenuClick} />)}
				</div>
			</div>
		);
	}
};
const Getsubmenu = ({ menus, onSubMenuClick }) => {
	return (
		<div
			className={` fs-nav-submenu ${menus.isSelect ? 'fs-nav-submenu-active' : ''} `}
			onClick={() => {
				cleverTapFunction(menus.cleverTapValue);
				onSubMenuClick(menus?.key);
			}}>
			{/* <ImageComponent src={menus.icon} className="fs-icon" /> */}
			<p className="label">{menus.label} </p>
		</div>
	);
};

const cleverTapFunction = (CleverTabValue) => {
	ClevertapReact.event(CleverTabValue);
};
