import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { announcementClaimCheck, DATE_FORMAT } from '@constants/app-constants';
import Action from '@sharedComponent/app-action-reducer/action';
import ClevertapReact from 'src/utils/clever-tap';
import Selectors from './selector';
import Actions from './action';
import AnnouncementPresentational from './announcement-presentational';

function AccouncementFunctional({
	isAnnouncementDataLoading,
	announcementData,
	AnnouncementHistory,
	getAnnouncementData,
	updateAnnouncementData,
	announcementSuccess,
	isAnnouncementLoading,
	forAnnouncementLoading,
	announcementPopUp,
	userNavigationMenu,
	isAnnouncementToastVisible,
	announcementsToastVisible,
}) {
	let hrClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims);
	let employeeClaims = useSelector((state) => state?.sharedStates?.currentUser?.employeeClaims);
	const nHrClaims = [];
	const claims = hrClaims?.map((data) => {
		if (announcementClaimCheck.includes(data?.value)) {
			nHrClaims.push(data?.value);
		}
	});
	const [announcement, setAnnouncement] = useState([]);
	const [isToastVisible, setIsToastVisible] = useState(false);
	const [state, setstate] = useState(false);
	const [size, setSize] = useState(false);
	const [mobPopUp, setMobPopUp] = useState(false);
	const [initial, setInitial] = useState({
		body: '',
		title: '',
	});
	const handleHeadingChange = (e) => {
		setInitial({ ...initial, title: e.target.value });
	};
	const handleTextChange = (e) => {
		setInitial({ ...initial, body: e.target.value });
	};
	useEffect(() => {
		const handleResize = () => {
			const width = window?.innerWidth;
			setSize(width <= 600);
		};
		window.addEventListener('resize', handleResize);
		window.onresize = handleResize();
	}, []);
	useEffect(() => {
		getAnnouncementData();
	}, []);
	useEffect(() => {
		if (announcementData && !!Object.keys(announcementData).length) {
			setAnnouncement({ ...announcementData });
			setInitial({ ...initial, body: '', title: '' });
		}
	}, [announcementData]);
	useEffect(() => {
		if (isAnnouncementToastVisible) {
			setIsToastVisible(isAnnouncementToastVisible);
			announcementsToastVisible(false);
		}
	}, [isAnnouncementToastVisible]);

	const clearPopUp = () => setIsToastVisible(false);

	const popup = () => forAnnouncementLoading(true);

	const handleSavePopup = () => {
		forAnnouncementLoading(false);
		setMobPopUp(false);
	};
	const leaveRequestSave = () => {
		const cleverTapProperties = {
			title: initial?.title?.length,
			text: initial?.body?.length,
			date: moment().format(DATE_FORMAT?.YYYY_MM_DD),
		};
		ClevertapReact.event('Ann_send', { cleverTapProperties });
		setstate(false);
		setMobPopUp(false);
		AnnouncementHistory(false);
		updateAnnouncementData(initial);
	};
	const popUpMobile = () => setMobPopUp(true);
	const handleHistory = () => {
		AnnouncementHistory(false);
		getAnnouncementData();
		setInitial({ body: '', title: '' });
	};

	return (
		<AnnouncementPresentational
			{...{
				isAnnouncementDataLoading,
				announcement,
				popup,
				handleSavePopup,
				leaveRequestSave,
				state,
				size,
				mobPopUp,
				popUpMobile,
				handleHeadingChange,
				handleTextChange,
				initial,
				announcementSuccess,
				handleHistory,
				isAnnouncementLoading,
				announcementPopUp,
				employeeClaims,
				nHrClaims,
				userNavigationMenu,
				isToastVisible,
				clearPopUp,
			}}
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		announcementData: Selectors.announcementData(state),
		isAnnouncementDataLoading: Selectors.isAnnouncementDataLoading(state),
		announcementSuccess: Selectors.announcementSuccess(state),
		isAnnouncementLoading: Selectors.isAnnouncementLoading(state),
		announcementPopUp: Selectors.isAnnouncementPopUP(state),
		isAnnouncementToastVisible: Selectors.isAnnouncementToastVisible(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAnnouncementData: () => dispatch(Actions.creators.getAnnouncementData()),
		updateAnnouncementData: (initial) => dispatch(Actions.creators.updateAnnouncementData(initial)),
		AnnouncementHistory: (flag) => dispatch(Actions.creators.announcementSuccess(flag)),
		forAnnouncementLoading: (flag) => dispatch(Actions.creators.announcementPopUp(flag)),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
		announcementsToastVisible: (flag) => dispatch(Actions.creators.announcementsToastVisible(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(AccouncementFunctional);
export default Connected;
