import React from 'react';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { images } from 'src/assets/img';
import ItemHeader from '@pages/help/components/ItemHeader';
import CardItemLink from '@pages/help/components/CardItemLink';
import { SITEMAP } from 'src/routes/sitemap';
import './labour-law.scss';

const LabourLawFaqs = () => {
	const history = useHistory();

	const labourLawFaqs = [
		{
			url: SITEMAP.help.labourLaws.wpsRules,
			image: images.wps,
			title: 'WPS Rules',
		},
		{
			url: SITEMAP.help.labourLaws.gratuityCalculate,
			image: images.end,
			title: 'End Of Service / Gratuity Calculation',
		},
		{
			url: SITEMAP.help.labourLaws.overTimePay,
			image: images.calculation,
			title: 'Overtime Pay Calculation',
		},
		{
			url: SITEMAP.help.labourLaws.leaveCalculation,
			image: images.leavecalculation,
			title: 'Leave Calculation',
		},
		{
			url: SITEMAP.help.labourLaws.wpsPenalty,
			image: images.penalities,
			title: 'WPS Penalties',
		},
	];

	return (
		<Col xs={24} sm={24} xl={24} md={24} lg={24} className="labour-presentational">
			<ItemHeader title="UAE Labour Law & WPS" goback={() => history.push(SITEMAP.help.index)} />
			<CardItemLink items={labourLawFaqs} />
		</Col>
	);
};

export default LabourLawFaqs;
