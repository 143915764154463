const UPDATE_EMPLOYEE_EMAIL_START = 'UPDATE_EMPLOYEE_EMAIL_START';
const UPDATE_EMPLOYEE_EMAIL_SUCCESS = 'UPDATE_EMPLOYEE_EMAIL_SUCCESS';
const UPDATE_EMPLOYEE_EMAIL_FAIL = 'UPDATE_EMPLOYEE_EMAIL_FAIL';

const updateEmployeeEmailStart = (payload) => {
	return {
		type: UPDATE_EMPLOYEE_EMAIL_START,
		payload,
	};
};
const updateEmployeeEmailSuccess = (res) => {
	return {
		type: UPDATE_EMPLOYEE_EMAIL_SUCCESS,
		res,
	};
};
const updateEmployeeEmailFail = (error) => {
	return {
		type: UPDATE_EMPLOYEE_EMAIL_FAIL,
		error,
	};
};

export default {
	types: {
		UPDATE_EMPLOYEE_EMAIL_START,
		UPDATE_EMPLOYEE_EMAIL_SUCCESS,
		UPDATE_EMPLOYEE_EMAIL_FAIL,
	},
	creators: {
		updateEmployeeEmailStart,
		updateEmployeeEmailSuccess,
		updateEmployeeEmailFail,
	},
};
