import React, { useState } from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '../index';

const ModelEleven = () => {
	const [isOpen, setisOpen] = useState(false);

	return (
		<>
			<EdenredButtons
				{...{
					btnType: 'primary',
					onClick: () => setisOpen(true),
					style: { marginTop: '20px' },
				}}>
				Modal With congratulations and onebutton
			</EdenredButtons>

			<Edenredmodal
				{...{
					title: 'Do You Want To Continue?',
					desc: 'Edenred',
					onCancelButton: 'Save',
					onCancel: () => setisOpen(false),
					isOpen: isOpen,
					close: () => setisOpen(false),
					imagedown: true,
					celebration: true,
					image: true,
					textcenter: true,
				}}
			/>
		</>
	);
};

export default ModelEleven;
