import React from 'react';
import ApprovedPage from '@pages/payroll/EmployeesExpirymodule/component/ApprovedPage';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import './uploadDocumentPreview.scss';

const UploadDocumentPreview = ({ cleverTap = true }) => {
	const history = useHistory();
	const updateClever = history?.location?.state?.updateCleverTap ?? false;
	return (
		<div className="documents-preview-table">
			<ApprovedPage
				isSetup={true}
				onSubmissionSuccess={() => {
					updateClever
						? ClevertapReact.event('V1_1_Docs_UploadBulk_Save_Submit')
						: ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStartedBulk_Save_Submit' : 'V1_1_DocsOnboardBulk_Save_Submit');
					history.push('/hrdocuments');
				}}
				onClickCancel={() => {
					updateClever
						? ClevertapReact.event('V1_1_Docs_UploadBulk_Save_Cancel')
						: ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStartedBulk_Save_Cancel' : 'V1_1_DocsOnboardBulk_Save_Cancel');
					history.goBack();
				}}
			/>
		</div>
	);
};

export default UploadDocumentPreview;
