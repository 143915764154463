import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';

const ImageIcon = ({ img, title, value, isMobile = false, param, handleHrCard }) => {
	return (
		<Col {...{ xl: 6, lg: 6, md: 6, sm: 6, xs: 6, ...(isMobile && { xl: 6, lg: 6, md: 6, sm: 6, xs: 15 }) }} className="summary">
			<div className="hr_card_new" onClick={() => handleHrCard(title)}>
				<div className={`forWarnings ${param}`}>
					<img src={img} className={isMobile ? 'forWarnings' : 'docImage'} />
				</div>
				<div className="entireSummary">
					<p className="summaryDocs">{title}</p>
					<p className="forEmploy">{value}</p>
				</div>
			</div>
		</Col>
	);
};

export default ImageIcon;

ImageIcon.propTypes = {
	img: PropTypes.string,
	title: PropTypes.string,
	value: PropTypes.string,
	isMobile: PropTypes.bool,
};
