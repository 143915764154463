import React, { useState } from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import Toast from '@pages/DesignSystem/Toast';

const BankDetailsTransferCodeDesign = ({
	transferSalary,
	createRakankAccDetails,
	transferCode,
	showTransferCode,
	accNumber,
	proceedPopUp,
	setProceedPopUp,
	handleProceeedPopUp,
	handleCreatePop,
	errorMessage,
	tabInfo,
	postLoader,
	initialAccNumber,
	setPopUpCard,
	showParollBnkDetailPopup,
	closePopUpCard,
}) => {
	const [copyToastSuccess, setCopyToastSuccess] = useState(false);
	return (
		<>
			<Row className="invoice-bankdetails_container">
				{/* <Toast
					color=""
					visible={true}
					clearPopUp={() => {
						setCopyToastSuccess(false);
					}}></Toast> */}
				<div className="invoice-fund-info-and-transfer-fund-container">
					<div className="invoice-info-cell">
						<div className="bank-title">
							<ETypo b1 medium className="bank-acc-title">
								Beneficiary Details
							</ETypo>
						</div>
						{tabInfo?.map((res, index) => {
							return (
								<div className="invoice-each-info-cell">
									<div className="name">
										<ETypo b2 medium color="#00344E">
											{res?.title}
										</ETypo>
									</div>
									<div className="value">
										<ETypo b2 light color="#181919">
											{res?.value}
										</ETypo>
									</div>
								</div>
							);
						})}
					</div>
					{transferSalary && (
						<div className="bank-info">
							<div className="bank-title">
								<ETypo b1 medium className="bank-acc-title">
									{showTransferCode ? 'Mandatory Transfer Code' : 'RAKBANK Account Number'}
								</ETypo>
							</div>

							{showTransferCode ? (
								<>
									<div className="invoice-accnumber-box">
										<ETypo b1 medium className="acc-number-style">
											{transferCode}
										</ETypo>
										<span
											style={{ cursor: 'pointer' }}
											onClick={() => {
												return !copyToastSuccess
													? (navigator.clipboard.writeText(transferCode), setCopyToastSuccess(true))
													: setCopyToastSuccess(false);
											}}>
											<img src={focus_state_icons?.Copy} alt="copy" />
										</span>
									</div>
									{copyToastSuccess ? <div className="invoice-copy-message">{`${transferCode} Copied Successfully.`}</div> : ''}
								</>
							) : (
								<>
									<div className="invoice-accnumber-box">
										<ETypo b1 medium className="acc-number-style">
											{accNumber}
										</ETypo>
										<span
											style={{ cursor: 'pointer' }}
											onClick={() => {
												navigator.clipboard.writeText(accNumber);
												setCopyToastSuccess(true);
												return !copyToastSuccess
													? (navigator.clipboard.writeText(accNumber), setCopyToastSuccess(true))
													: setCopyToastSuccess(false);
											}}>
											<img src={focus_state_icons?.Copy} alt="copy" />
										</span>
									</div>
									{copyToastSuccess ? <div className="invoice-copy-message">{`${accNumber} Copied Successfully.`}</div> : ''}
								</>
							)}

							<div className="click-here-box">
								<div className="click-here-style">
									{showTransferCode ? (
										<ETypo b2 light className="click-here-para-style">
											Non RAKBANK Accounts, use below code as the reference, for RAKBANK Corporate accounts
											<Link
												to={'#'}
												onClick={() => setPopUpCard(true)}
												className="click-here-font-style"
												style={{ color: '#00344E', fontWeight: 700 }}>
												{' '}
												click here
											</Link>
										</ETypo>
									) : (
										<ETypo b2 light className="click-here-para-style">
											For RAKBANK Corporate Accounts, you can use your account number as a reference, For non RAKBANK accounts
											<Link
												to={'#'}
												onClick={() => setProceedPopUp(true)}
												className="click-here-font-style"
												style={{ color: '#00344E', fontWeight: 700 }}>
												{' '}
												Click Here
											</Link>
										</ETypo>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				<Edenredmodal
					{...{
						onOkay: () => handleCreatePop(accNumber),
						onOkayButton: 'ok',
						close: () => closePopUpCard(false),
						isOpen: showParollBnkDetailPopup,
						onOkayDisable: initialAccNumber === accNumber || errorMessage,
					}}>
					<div className="enter-accnt-number-pop-up-container">
						<div className="bank-acct-title">
							<ETypo h4 bold>
								Enter RAKBANK Account Number
							</ETypo>
						</div>
						<div b1 light className="bank-acct-info">
							If you are using RAKBANK Corporate Account to transfer your funds to us, you don’t need a transfer code, we will take your
							account number as a reference!
						</div>
						<div className="bank-acct-input">
							<Inputbox
								value={accNumber}
								onChange={(e) => (e?.target?.value?.length < 14 ? createRakankAccDetails(e.target.value, 'popUpEditor') : null)}
								placeholder={'Enter Account Number'}
								type="number"
								loading={postLoader}
								isErrorMessage={errorMessage}
								errorMessage="The account Number must be 13 digits"
								rightIcon={<img src={focus_state_icons.bankDetailEdit} alt="eyeIcon" />}
							/>
						</div>
					</div>
				</Edenredmodal>
				<Edenredmodal
					{...{
						onOkay: () => handleProceeedPopUp(),
						onCancelButton: 'Cancel',
						onOkayButton: 'Proceed',
						onCancel: () => setProceedPopUp(false),
						isOpen: proceedPopUp,
						close: () => setProceedPopUp(false),
					}}>
					<div className="proceed-container">
						<div className="proceed-header">
							<ETypo h4 bold className="proceed-acct-header-style">
								Don’t have a RAKBANK Account Number?
							</ETypo>
						</div>
						<div className="proceed-info">
							<ETypo b1 light className="proceed-acct-info">
								If you are using RAKBANK Corporate Account, you can use your account number as a reference. Otherwise, please use a
								Transfer Code and click proceed.
							</ETypo>
						</div>
					</div>
				</Edenredmodal>
			</Row>
		</>
	);
};
export default BankDetailsTransferCodeDesign;
