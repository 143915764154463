import Actions from './action';

export const initState = {
	isNationationalityLoading: false,
	nationalitydata: [],
	isSaveIndividualLoading: false,
	individualEmployeeResponse: [],
	isEmpPersonalDetailSaved: false,
};

const addIndividualReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_START:
			return {
				...state,
				isSaveIndividualLoading: true,
			};
		case Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_SUCCESS:
			return {
				...state,
				isSaveIndividualLoading: false,
				individualEmployeeResponse: action.res,
			};
		case Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_FAIL:
			return {
				...state,
				isSaveIndividualLoading: false,
				individualEmployeeResponse: [],
			};

		case Actions.types.GET_NATIONALITY_DATA_START:
			return {
				...state,
				isNationationalityLoading: true,
			};
		case Actions.types.GET_NATIONALITY_DATA_SUCCESS:
			return {
				...state,
				isNationationalityLoading: false,
				nationalitydata: action?.res,
			};
		case Actions.types.GET_NATIONALITY_DATA_FAIL:
			return {
				...state,
				isNationationalityLoading: false,
				nationalitydata: [],
			};
		case Actions.types.EMP_PERSONAL_DETAIL_SAVED:
			return {
				...state,
				isEmpPersonalDetailSaved: action?.flag,
			};
		default:
			return state;
	}
};
export default addIndividualReducer;
