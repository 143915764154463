import MobileCarousel from '@sharedComponent/carousel/MobileCarousel';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { Col, Divider, Row } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { images } from 'src/assets/img';
import './leave-status-mobile.scss';

const mockData = [
	// {
	// 	name: 'Amit Pahandit Ramasubramanian',
	// 	empid: 'Gil1098',
	// 	job: 'Customer Service',
	// 	leaveReason: 'Hajj',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	name: 'Amit new maran',
	// 	empid: 'Gil1098',
	// 	job: 'Customer Service',
	// 	leaveReason: 'Unpaid',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	name: 'Rhun yash naren',
	// 	empid: 'Gil1098',
	// 	job: 'Customer Service',
	// 	leaveReason: 'Annual',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	name: 'Raham prem',
	// 	empid: 'Gil1098',
	// 	job: 'Customer Service',
	// 	leaveReason: 'Hajj',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	name: 'Namas kanten raj',
	// 	empid: 'Gil1098',
	// 	job: 'Customer Service',
	// 	leaveReason: 'Hajj',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	name: 'Rumas hasam nizam',
	// 	empid: 'Gil1098',
	// 	job: 'Customer Service',
	// 	leaveTypeName: 'Hajj',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	name: 'James borg',
	// 	empid: 'Gil1098',
	// 	department: 'Customer Service',
	// 	leaveTypeName: 'Hajj',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	// {
	// 	employeeName: 'Saber',
	// 	employeeId: 'Gil1098',
	// 	department: 'Customer Service',
	// 	leaveTypeName: 'Hajj',
	// 	startDate: '2020-09-01T00:00:00',
	// 	endDate: '2022-09-10T00:00:00',
	// 	numberOfDays: 0,
	// },
	{
		employeeName: 'Mark Pahandit Ramasubramanian end',
		employeeId: 'Gil1098',
		department: 'Customer Service',
		leaveTypeName: 'Hajj',
		startDate: '2020-09-01T00:00:00',
		endDate: '2022-09-10T00:00:00',
		numberOfDays: 0,
	},
];

function LeaveStatusMobile({ currentLeave, onGoingLeave, countLoader }) {
	// const [goingOn, setGoingOn] = useState();
	// const [currentlyOn, setCurrentlyOn] = useState();
	console.log('currentLeave', currentLeave, onGoingLeave, countLoader);
	if (countLoader) return <div>loading</div>;

	return (
		<Row className="main-class-leavestatus">
			<Col xs={24}>
				<div>
					<ImageComponent src={images.edenredSVG} style={{ width: '80px' }} />
				</div>
			</Col>
			<Col xs={24} className="main-title-container">
				<EdenredTypo className="medium Main-title-leave-status">Leave Statuses</EdenredTypo>
			</Col>
			<Col xs={24} className="carosuel-card-leave">
				<MobileCarousel
					title="Currently on leave"
					data={currentLeave || []}
					slidesPerRow={4}
					slidesToShow={1}
					slidesToScroll={1}
					dots={true}
				/>
			</Col>
			<Col xs={24} className="carosuel-card-leave">
				<MobileCarousel title="Going on leave" data={onGoingLeave} slidesPerRow={4} slidesToShow={1} slidesToScroll={1} dots={true} />
			</Col>
		</Row>
	);
}

export default LeaveStatusMobile;
