import React from 'react';
import { SITEMAP } from 'src/routes/sitemap';
import { Route } from 'react-router-dom';
import CardOrderFaqs from '@pages/help/employee/pages/cardOrderFaqs';
import ATMPinFaqs from '@pages/help/employee/pages/ATMPinFaqs';
import CardUsage from '@pages/help/employee/pages/cardUsageFaqs';
import NewEmployeeFaqs from '@pages/help/employee/pages/newEmployeeFaqs';
import EmployeeRequestFaqs from '@pages/help/employee/pages/employeeRequestFaqs';
import EmployeeFaqs from './EmployeeFaqs';

const Employee = () => {
	return (
		<>
			<Route path={SITEMAP.help.employee.atmPin} component={ATMPinFaqs} exact />
			<Route path={SITEMAP.help.employee.cardOrder} component={CardOrderFaqs} exact />
			<Route path={SITEMAP.help.employee.cardUsage} component={CardUsage} exact />
			<Route path={SITEMAP.help.employee.newEmployee} component={NewEmployeeFaqs} exact />
			<Route path={SITEMAP.help.employee.employeeRequests} component={EmployeeRequestFaqs} exact />
			<Route path={SITEMAP.help.employee.index} component={EmployeeFaqs} exact />
		</>
	);
};

export default Employee;
