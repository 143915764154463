// once we work on the functionality, the selectors will be created here
// sample code

import { createSelector } from 'reselect';

import { initState } from './reducer';

const manageTeam = (state) => state.manageTeam || initState;
const users = createSelector(manageTeam, (cnter) => cnter.users);
const usersLoading = createSelector(manageTeam, (cnter) => cnter.usersLoading);

export default {
	manageTeam,
	users,
	usersLoading,
};
