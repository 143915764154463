import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { DotLoader } from 'react-spinners';
import EdenredSearch from '@sharedComponent/search-input';
import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredEmployeeCard from '@sharedComponent/card/employees/employees-card';
import { EmployeListExcelDownload } from '@sharedComponent/excel/excel';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { entriesDropDown } from '@helpers';
import Table from '@pages/DesignSystem/Table/Table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Toast from '@pages/DesignSystem/Toast';
import EmployeeService from 'src/services/employee-service';
import { icons } from 'src/assets/icons';
import '../approve-employee-request.scss';

const CardRequestDetail = (props) => {
	const [downloadContent, setdownloadContent] = useState([]);
	const { uploadId, employeeId, massCorpDelUploadId } = useParams();
	let { content } = props?.history?.location.state;
	let [isDataLoading, setIsDataLoading] = useState(false);
	let [tableDataLoading, setTableDataLoading] = useState(false);
	let [employeeDetails, setEmployeeDetails] = useState([]);
	let [searchedText, setSearchedText] = useState('');
	let [pageSize, setPageSize] = useState(10);
	let [totalPageCount, setTotalPageCount] = useState(1);
	let [pageNumber, setPageNumber] = useState(1);
	let [confirmApproval, setConfirmApproval] = useState(false);
	let [selectedAction, setSelectedAction] = useState('');
	let employeeApi = new EmployeeService();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});

	useEffect(() => {
		getRequestDetails('page');
	}, []);

	useEffect(() => {
		if (employeeDetails.length) {
			setdownloadContent(
				employeeDetails.map(({ employeeName, employeeCode, iban }) => {
					return {
						name: employeeName,
						employeeCode,
						iban,
					};
				})
			);
		}
	}, [employeeDetails]);

	const column = [
		{
			key: 'employeeName',
			title: 'Name',
			width: '38%',
			dataIndex: 'employeeName',
			render: (employeeName) => {
				return (
					<div style={{ display: 'flex', height: '48px', alignItems: 'center' }}>
						<ETypo b2 light color="#181919">
							{employeeName}
						</ETypo>
					</div>
				);
			},
		},

		{
			key: 'employeeCode',
			title: 'Employee ID',
			dataIndex: 'employeeCode',
			width: '38%',
			// align: 'left',
			render: (val) => {
				return (
					<div style={{ display: 'flex', height: '48px', alignItems: 'center' }}>
						<ETypo b2 light color="#181919">
							{val}
						</ETypo>
					</div>
				);
			},
		},
		{
			key: 'employeeId',
			title: ' ',
			dataIndex: 'employeeId',
			width: '24%',
			align: 'right',
			render: (iban, employee, employeeId, employeeCode) => {
				return (
					<div style={{ display: 'flex', height: '48px', alignItems: 'center' }}>
						<Link
							to={{
								pathname: '/employees/viewemployee',
								state: {
									tableData: {
										employeeCode: employee.employeeCode,
										employeeId: employee.employeeId,
										employeeName: employee,
										iban: employee.iban,
									},
								},
							}}
							style={{
								fontFamily: 'Montserrat-medium',
								fontStyle: 'normal',
								fontWeight: '500',
								fontSize: '14px',
								lineHeight: '16px',
								textDecoration: 'underline',
								color: '#0E5274',
							}}>
							{' '}
							View Employee Details
						</Link>
						<ImageComponent
							style={{ marginLeft: '4px', width: '20px', alignItems: 'center' }}
							src={icons.focus_state.cardViewArrow}></ImageComponent>
					</div>
				);
			},
		},
	];

	async function getRequestDetails(flag) {
		if (flag == 'page') {
			setIsDataLoading(true);
		} else {
			setTableDataLoading(true);
		}
		let payload = {
			details: {
				uploadId: uploadId === 'null' ? null : uploadId,
				employeeId: employeeId === 'null' ? null : employeeId,
				massCorpDelUploadId: massCorpDelUploadId === 'null' ? null : massCorpDelUploadId,
			},
		};
		await fetchRequestDetails(payload);
		flag == 'page' ? setIsDataLoading(false) : setTableDataLoading(false);
	}

	async function fetchRequestDetails(payload) {
		try {
			let response = await employeeApi.getRequestDetails(payload, { pageNumber: pageNumber, pageSize: pageSize, searchFilter: searchedText });
			if (response && response.data) {
				setEmployeeDetails(response.data.employeeDetails);
				setTotalPageCount(response.data.totalCount);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleCardAction(action) {
		setSelectedAction(action);
		setConfirmApproval(true);
	}

	let tableData = [...employeeDetails];
	useEffect(() => {
		getRequestDetails('table');
	}, [pageSize, pageNumber, searchedText]);

	async function handleConfirmation() {
		setConfirmApproval(false);
		let payload = {
			cardRequestAction: {
				uploadIds: content?.uploadId ? [content?.uploadId] : [],
				employeeIds: content?.employeeId ? [content?.employeeId] : [],
				massCorpDelUploadId: content?.massCorpDelUploadId ? [content?.massCorpDelUploadId] : [],
				action: selectedAction === 'Approve' ? 'approve' : 'reject',
			},
		};
		setIsDataLoading(true);
		let reviewedSuccess = await reviewPendingRequest(payload);
		setIsDataLoading(false);
		if (reviewedSuccess) {
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: `${selectedAction === 'Approve' ? 'Card order approved.' : 'Card order rejected.'}`,
				subMessage: null,
			});
		}
	}
	async function reviewPendingRequest(payload) {
		try {
			let response = await employeeApi.reviewPendingRequest(payload);
			if (response) {
				return response;
			}
		} catch (err) {
			console.log(err);
		}
	}
	const closePopup = () => {
		setpopupVisible(false);
		props.history.push('/employees/approve_employee_request');
	};
	const handleViewCardTable = (res) => setPageSize(res.value);

	return (
		<>
			<Toast visible={popupVisible} color="#8EF9B3" textcolor="#484B52" clearPopUp={closePopup}>
				{popupMessage?.titleMessage}
			</Toast>
			<EdenredHeader search />
			{isDataLoading && (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			)}
			{!isDataLoading && (
				<Row className="card-request-detail-container">
					<Col xl={24} md={24} lg={24}>
						<div className="card-request-header-container">
							<ETypo h2 bold className="request-page-title">
								{content?.name}
							</ETypo>
							<div className="card-request-button-container">
								{downloadContent?.length !== 0 && (
									<EmployeListExcelDownload
										hideSalary
										dataSet={downloadContent ? downloadContent : []}
										filename={`employeelist`}
										element={<EdenredButtons btnType="secondary">Download Employee List</EdenredButtons>}
									/>
								)}
								<EdenredButtons onClick={() => handleCardAction('Approve')} btnType="primary">
									Approve Card Order
								</EdenredButtons>
							</div>
						</div>
						<div className="card-request-sub-header">
							<ETypo b2 light color="#484B52">
								{content?.order2}
							</ETypo>
						</div>
						<div className="card-info-board">
							<div className="search-and-show-count-container">
								<EdenredSearch
									value={searchedText}
									placeholder="Search by Name, Employee ID..."
									onChange={(event) => setSearchedText(event.target.value)}
								/>
								<div className="entries-select-container">
									<ETypo b2 light color="#484B52">
										Show
									</ETypo>
									<MultiSelect
										{...{
											Option: entriesDropDown,
											value: pageSize,
											dropDownValue: 'value',
											searchVisibility: false,
											onChange: (page) => handleViewCardTable(page, 'pageSize'),
											checkboxIcon: (
												<span className="entries_check_box">
													<img src={icons.not_focus.tick} alt="check" />
												</span>
											),
										}}
									/>
									<ETypo b2 light color="#484B52">
										Entries
									</ETypo>
								</div>
							</div>
							<div className="table-card-container">
								{tableDataLoading ? (
									<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
										<DotLoader size={50} color={'#e91d24'} loading />
									</Col>
								) : (
									<Table columns={column} data={tableData} />
								)}
							</div>

							<div className="emp-card-pagination-container">
								<ETypo b3 light color="#484B52">
									Showing {tableData.length} out of {totalPageCount} Employees
								</ETypo>
								<Pagination
									{...{
										currentPage: 1,
										totalCount: totalPageCount,
										pageSize: pageSize,
										onPageChange: (page) => setPageNumber(page),
									}}
								/>
							</div>
							<Edenredmodal
								title={`Do you want to ${selectedAction} this card order ?`}
								onOkay={handleConfirmation}
								onCancelButton="Cancel"
								onOkayButton="Save"
								onCancel={() => setConfirmApproval(false)}
								isOpen={confirmApproval}
								close={() => setConfirmApproval(false)}
							/>
						</div>

						{/* mobile view */}
						<Row className="card-request-mobile-view">
							<Col xs={12} sm={12} className="card-request-mobile-reject">
								<EdenredButton type="outline-g" onClick={() => handleCardAction('Reject')}>
									Reject
								</EdenredButton>
							</Col>
							<Col xs={12} sm={12} className="card-request-mobile-reject">
								<EdenredButton onClick={() => handleCardAction('Approve')}>Approve</EdenredButton>
							</Col>
							<Col style={{ paddingTop: '12px' }}>
								{tableData.map((content) => (
									<EdenredEmployeeCard type="ApproveRequestTable" content={content} className="approverequestcard" />
								))}
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
};
export default CardRequestDetail;
