import React from 'react';
import Inputbox from '../index';
import { not_focus_icons } from 'src/assets/icons/not_focus';

const InputExample = () => {
	return (
		<div style={{ width: '100%' }}>
			<Inputbox
				label="Text "
				type="text"
				isErrorMessage={true}
				rightIcon={<img src={not_focus_icons.eyeFocus} className="input-text-eyeIcon" alt="eyeIcon" />}
				errorIcon={<img src={not_focus_icons.dangerIcon} className="input-text-eyeIcon" alt="eyeIcon" />}
			/>
			<Inputbox label="Number" type="number" rightIcon={<img src={not_focus_icons.eyeFocus} className="input-text-eyeIcon" alt="eyeIcon" />} />
			<Inputbox
				label="Number With Prefix"
				type="number"
				prefix="+971"
				rightIcon={<img src={not_focus_icons.eyeFocus} className="input-text-eyeIcon" alt="eyeIcon" />}
			/>
			<Inputbox
				label="Number With Prefix And CountryImage"
				type="number"
				prefix="+971"
				rightIcon={<img src={not_focus_icons.eyeFocus} className="input-text-eyeIcon" alt="eyeIcon" />}
				leftImage={<img src={not_focus_icons.countryImage} className="input-text-countryIcon" alt="eyeIcon" />}
			/>
			<Inputbox
				label="Disable"
				type="textarea"
				disabled={true}
				rightIcon={<img src={not_focus_icons.eyeFocus} className="input-text-eyeIcon" alt="eyeIcon" />}
			/>
		</div>
	);
};

export default InputExample;
