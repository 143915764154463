import React from 'react';

const OptionsContent = ({ data, handleDropdown, handleToggle, mainData, icon, isOpen }) => {
	console.log('mainData', mainData);
	const handleCheck = (clickedData, mainData) => {
		data?.subMenu?.length > 0 ? handleToggle() : handleDropdown(clickedData, mainData);
	};

	return (
		<div className="dropdown-list-sub" style={{ paddingLeft: isOpen ? 12 : 0 }}>
			<div className="dropdown_inner_list" onClick={() => handleCheck?.(data, mainData)}>
				<div className="dropdown_inner_list_main">{data?.name}</div>
				<div className="dropdown_inner_list_sub">{data?.sub}</div>
			</div>

			{data?.subMenu?.length > 0 && (
				<div role="button" onClick={() => handleToggle()}>
					{icon}
				</div>
			)}
		</div>
	);
};
export default OptionsContent;
