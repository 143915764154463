import React, { Fragment } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { debounceHandler } from '@helpers';
import FilterButton from '@pages/DesignSystem/filter';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';
import DownloadDoc from './DownloadDoc';

const HrDocumentHeader = ({
	entriesDropDown,
	handleEmployeesTable,
	fromUploadSheetPage,
	isRepeatMode,
	hrFilterData,
	handleFilterValue,
	filterLoader,
	handleClearAllFilter,
	excelDownloadLoading,
	pdfDownloadLoading,
	downloadDocumentType,
	downloadLoading,
	isComponentVisibleProp,
	detail,
	setIsComponentVisibleProp,
	filterDocument,
	showEmptyFilterWarning,
	onSearch,
	selectedFilter,
}) => {
	return (
		<Fragment>
			<div className={`search-container ${!fromUploadSheetPage && !isRepeatMode ? 'search-container-1' : 'search-container-2'} secound-div`}>
				<div style={{ width: '50%' }}>
					<Inputbox
						{...{
							placeholder: 'Search by Name, Employee ID...',
							className: 'serach-box',
							onChange: (e) => debounceHandler(e.target.value, onSearch),
							disabled: filterLoader,
							onClick: () => ClevertapReact.event('DocsMgt_SearchBar'),
							prefix: filterLoader ? (
								<div
									style={{
										width: 25,
										height: 25,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									<LoadingOutlined className="search-icon" />
								</div>
							) : (
								<ImageComponent className="search-icon" src={focus_state_icons.NewSearch} />
							),
						}}
					/>
				</div>
				<div className="first-div">
					{!fromUploadSheetPage && !isRepeatMode && (
						<FilterButton
							{...{
								filterData: hrFilterData,
								onClick: handleFilterValue,
								loading: filterLoader,
								showIndicate: true,
								cleverTabEvent: { Filter_ClearAll: 'Filter_ClearAll' },
								selectedFilter,
								filterClearAll: handleClearAllFilter,
								disabled: filterLoader,
								value: (!!filterDocument?.expiryDateFilter?.length || !!filterDocument?.documentTypeFilter?.length) && [
									...filterDocument?.expiryDateFilter,
									...filterDocument?.documentTypeFilter,
								],
								compareValue: 'filterParam',
								filterDropDownValue: 'filterValue',
							}}
						/>
					)}
				</div>
				<DownloadDoc
					{...{
						excelDownloadLoading,
						pdfDownloadLoading,
						downloadDocumentType,
						downloadLoading,
						isComponentVisibleProp,
						detail,
						setIsComponentVisibleProp,
						filterDocument,
						showEmptyFilterWarning,
					}}
				/>
			</div>
			<div className="hr_dropDown_entries">
				<p>Show</p>
				<MultiSelect
					{...{
						Option: entriesDropDown,
						value: filterDocument?.pageSize,
						dropDownValue: 'value',
						searchVisibility: false,
						onChange: (page) => handleEmployeesTable(page, 'pageSize'),
						checkboxIcon: (
							<span className="entries_check_box">
								<img src={icons.not_focus.tick} alt="check" />
							</span>
						),
					}}
				/>
				<p>Entries</p>
			</div>
		</Fragment>
	);
};

export default HrDocumentHeader;
