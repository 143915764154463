import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Table from '@pages/DesignSystem/Table/Table';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { Col, Row } from 'antd';
import { entriesDropDown } from '@helpers';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons';
import { SITEMAP } from 'src/routes/sitemap';
import EmployeeService from 'src/services/employee-service';
import { employeeVerifyActions } from '../redux';
import { API_STATUS_CODES } from '@constants/app-constants';
import EdenredSearch from '@sharedComponent/search-input';
import moment from 'moment';

const EmployeePreview = () => {
	const location = useLocation();
	const { dashboard } = SITEMAP.employees.verification;
	const employeeUnderReviewPage = dashboard.employeeUnderReview;
	const employeeVerificationData = useSelector((state) => state.employees.data);
	const history = useHistory();
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState(1);
	const [pageEntires, setPageEntires] = useState(10);
	const [searchvalue, setSearchValue] = useState('');
	const { employeeList: list, loading } = employeeVerificationData || {};
	const [employeeList, setEmployeeList] = useState([]);
	const [showEmployeeList, setShowEmployeeList] = useState([]);

	const locationStateUser = location?.state?.user || null;

	useEffect(() => {
		if (!list || !list?.length) {
			const routeLink = dashboard.index;
			if (locationStateUser) {
				history.push({ pathname: routeLink, state: { user: locationStateUser } });
			} else {
				history.push(routeLink);
			}
		}
	}, []);

	useEffect(() => {
		if (list) {
			setEmployeeList(list);
		}
	}, []);

	useEffect(() => {
		if (searchvalue?.length > 0) {
			const searchedData = employeeList?.filter((data) => {
				return (
					data?.employeeId?.toString()?.toLowerCase()?.indexOf(searchvalue?.toLowerCase()) >= 0 ||
					data?.firstName?.toString()?.toLowerCase()?.indexOf(searchvalue?.toLowerCase()) >= 0 ||
					data?.lastName?.toString()?.toLowerCase()?.indexOf(searchvalue?.toLowerCase()) >= 0 ||
					data?.passportNumber?.toString()?.toLowerCase()?.indexOf(searchvalue?.toLowerCase()) >= 0
				);
			});
			setShowEmployeeList(searchedData);
			if (pagination && pageEntires) {
				const start = (pagination - 1) * pageEntires;
				const end = start + pageEntires;
				const foundData = searchedData.slice(start, end);
				setShowEmployeeList(foundData);
			}
		} else {
			const listData = employeeList || [];
			const start = (pagination - 1) * pageEntires;
			const end = start + pageEntires;
			const paginatedData = listData?.slice(start, end);
			if (paginatedData.length === 0) {
				setPagination(1);
			}

			setShowEmployeeList(paginatedData);
		}
	}, [searchvalue, pagination, pageEntires, employeeList]);

	const columns = [
		{
			sorter: (a, b) => a.employeeId.localeCompare(b.employeeId),
			key: 'employeeId',
			title: 'Employee Id',
			dataIndex: 'employeeId',
			sort: true,
			render: (val, val1) => {
				return <Col>{val}</Col>;
			},
		},
		{
			sorter: (a, b) => a.firstName - b.firstName,
			key: 'firstName',
			title: 'First name',
			dataIndex: 'firstName',
			sort: true,
			render: (val) => {
				return <>{val}</>;
			},
		},
		{
			sorter: (a, b) => a.lastName - b.lastName,
			key: 'lastName',
			title: 'Last name',
			dataIndex: 'lastName',
			sort: true,
			render: (val) => {
				return <>{val}</>;
			},
		},
		{
			sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
			key: 'dateOfBirth',
			title: 'Date Of Birth',
			dataIndex: 'dateOfBirth',
			sort: true,
			render: (val) => {
				const date = moment(val || '').format('D MMMM YYYY');
				return <>{date}</>;
			},
		},
		{
			sorter: (a, b) => a.passportNumber - b.passportNumber,
			key: 'passportNumber',
			title: 'Passport Number',
			dataIndex: 'passportNumber',
			sort: true,
			render: (val) => {
				return <>{val}</>;
			},
		},
		{
			sorter: (a, b) => a.nationality - b.nationality,
			key: 'nationality',
			title: 'Nationality',
			dataIndex: 'nationality',
			sort: true,
			render: (val) => {
				return <>{val}</>;
			},
		},
	];

	const submitEmployees = async () => {
		dispatch(employeeVerifyActions.creators.uploadEmployeesLoading(true));
		try {
			const employeeService = new EmployeeService();
			const body = {
				employees: employeeList,
			};
			const res = await employeeService.evaluateEmployeeList(body);
			dispatch(employeeVerifyActions.creators.uploadEmployeesLoading(false));
			if (res.status === API_STATUS_CODES.SUCCESS) {
				if (locationStateUser) {
					history.push({ pathname: employeeUnderReviewPage, state: { user: locationStateUser } });
				} else {
					history.push(employeeUnderReviewPage);
				}
				dispatch(employeeVerifyActions.creators.clearEmployeeData());
			}
		} catch (error) {
			console.log({ error });
			dispatch(employeeVerifyActions.creators.uploadEmployeesLoading(false));
		}
	};

	return (
		<Row data-testid="employee-preview-test" className="employee_preview_container">
			<Col xl={24} md={24} lg={24} className="employee_preview_container_col_1">
				<div className="employee_preview_container_col_1_header" data-testid="employee-preview-header-test">
					<ETypo bold h2 data-testid="employee-preview-header-title-test">
						You’re updating the following employees.
					</ETypo>
					<ETypo b1 data-testid="employee-preview-header-desc-test">
						Click “submit” to proceed
					</ETypo>
				</div>
				<div className="employee_preview_container_col_1_btns">
					<EdenredButtons
						btnType="secondary"
						onClick={() => {
							dispatch(employeeVerifyActions.creators.clearEmployeeData());
							history.goBack();
						}}>
						Cancel
					</EdenredButtons>
					<EdenredButtons loading={loading} onClick={submitEmployees}>
						Submit
					</EdenredButtons>
				</div>
			</Col>
			<Col xl={24} md={24} lg={24} className="employee_preview_container_col_2 white_outer">
				<section className="employee_table_header_container">
					<EdenredSearch
						value={searchvalue}
						placeholder="Search by Name, Employee ID, Passport Number"
						onChange={(event) => setSearchValue(event.target.value)}
					/>
					<div className="show_entries">
						<ETypo b2 light color="#484B52">
							Show
						</ETypo>
						<MultiSelect
							{...{
								Option: entriesDropDown,
								value: pageEntires,
								dropDownValue: 'value',
								searchVisibility: false,
								onChange: (page) => setPageEntires(page.value),
								checkboxIcon: (
									<span className="entries_check_box">
										<ImageComponent src={icons.not_focus.tick} alt="check" />
									</span>
								),
							}}
						/>
						<ETypo b2 light color="#484B52">
							Entries
						</ETypo>
					</div>
				</section>
				{showEmployeeList?.length ? (
					<Table columns={columns} data={showEmployeeList} />
				) : (
					<div className="no_data_found">
						<ImageComponent src={icons.focus_state.searchEmployeeCard} />
						&nbsp; No data found
					</div>
				)}
				<div className="pagination-bar employee_table_pagination_wrapper ">
					<ETypo light b3 color="#484B52">
						Showing {showEmployeeList?.length} out of {employeeList?.length} Employees
					</ETypo>

					<Pagination
						className="pagination-bar padding_0"
						totalCount={employeeList?.length}
						currentPage={pagination}
						pageSize={pageEntires}
						onPageChange={(page) => setPagination(page)}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default EmployeePreview;
