import { createSelector } from 'reselect';

import { initState } from './reducer';

const leaveApprovalReducer = (state) => state.leaveApprovalReducer || initState;

const isLeaveTemplateLoading = createSelector(leaveApprovalReducer, (cnter) => cnter.isLeaveTemplateLoading);
const leaveTemplateDetail = createSelector(leaveApprovalReducer, (cnter) => cnter.leaveTemplateDetail);
const isLeaveUploadLoading = createSelector(leaveApprovalReducer, (cnter) => cnter.isLeaveUploadLoading);
const leaveUploadDetails = createSelector(leaveApprovalReducer, (cnter) => cnter.leaveUploadDetails);
const isLeaveDocUploaded = createSelector(leaveApprovalReducer, (cnter) => cnter.isLeaveDocUploaded);
const isBalanceSubmitted = createSelector(leaveApprovalReducer, (cnter) => cnter.isBalanceSubmitted);
const isBalanceLoading = createSelector(leaveApprovalReducer, (cnter) => cnter.isBalanceLoading);
const isGetBalanceLoading = createSelector(leaveApprovalReducer, (cnter) => cnter.isGetBalanceLoading);
const getLeaveBalance = createSelector(leaveApprovalReducer, (cnter) => cnter.getLeaveBalance);

export default {
	isLeaveTemplateLoading,
	leaveTemplateDetail,
	isLeaveUploadLoading,
	leaveUploadDetails,
	isLeaveDocUploaded,
	isBalanceSubmitted,
	isBalanceLoading,
	isGetBalanceLoading,
	getLeaveBalance,
};
