import Action from './action';
import AnnouncementsSummary from './AnnouncementsSummary';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import React, { useState } from 'react';
import SetupTitle from '../Component/SetupTitle/SetupTitle';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ClevertapReact from 'src/utils/clever-tap';
import './employee-announcement.scss';
const EmployeeAnnouncement = () => {
	const [announcementModal, setAnnouncementModal] = useState(false);
	const [announcementMessage, setAnnouncementMessage] = useState({
		title: '',
		body: '',
	});
	const [modalOpen, setModalOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const handleTitleMessage = (title) => {
		setAnnouncementMessage((prev) => ({ ...prev, title: title }));
	};
	const handleBodyMessage = (body) => {
		setAnnouncementMessage((prev) => ({ ...prev, body: body }));
	};
	const handleSendAnnouncement = () => {
		ClevertapReact.event('V1_1_Announ_sendConfirm', { ...announcementMessage });
		dispatch(Action.creators.putAnnouncementUpload(announcementMessage));
		setAnnouncementMessage({ title: '', body: '' });
		history.push('/new-hr-features/announcement');
	};

	return (
		<>
			<Edenredmodal
				title="You didn’t send any announcement"
				desc="You’ll be able to send announcements anytime from the navigation."
				onOkay={() => setModalOpen(false)}
				onCancelButton="Skip For Now"
				onOkayButton="Try Now"
				onCancel={() => history.push('/')}
				isOpen={modalOpen}
				heart={true}
				imageSrc={focus_state_icons.warningNew}
			/>
			<Edenredmodal
				title="You will be sending this message for FREE as an app notification to 40/50 of your employees:"
				desc={
					<div className="modal-description">
						<section className="announcement-modal-title-container">
							<span className="announcement-modal-title">Title:</span>
							{announcementMessage?.title}
						</section>
						<section className="announcement-modal-message-container">
							<span className="announcement-modal-message">Description:</span>"{announcementMessage?.body}"
						</section>
					</div>
				}
				onOkay={() => handleSendAnnouncement()}
				onCancelButton="Edit Message"
				onOkayButton="Send Announcement"
				onCancel={() => {
					ClevertapReact.event('V1_1_Announ_sendEdit');
					setAnnouncementModal(false);
				}}
				isOpen={announcementModal}
				close={() => setAnnouncementModal(false)}
				btnleft={true}
				left={true}
				textStart={true}
			/>
			<div className="employee-announcement-wrapper">
				<EdenRedHeader />
				<div className="announcement-text-container">
					<SetupTitle
						title={`Send your first announcement`}
						rightBtnType={'primary'}
						rightBtnDisabled={!(announcementMessage?.body !== '' && announcementMessage?.title !== '')}
						rightBtnText={'Send Announcement'}
						rightBtnClick={() => {
							ClevertapReact.event('V1_1_Announ_send');
							setAnnouncementModal(true);
						}}
						leftBtnType="secondary"
						leftBtnText={'Do It Later'}
						leftBtnClick={() => {
							ClevertapReact.event('V1_1_Announ_later');
							setModalOpen(true);
						}}
					/>
					<AnnouncementsSummary
						infoShow={true}
						handleBodyMessage={handleBodyMessage}
						handleTitleMessage={handleTitleMessage}
						announcementMessage={announcementMessage}
					/>
				</div>
			</div>
		</>
	);
};

export default EmployeeAnnouncement;
