import React from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import EmployeeHistoryFunctional from '@pages/Leave-Management/leaves-status/Component/employeeHistoryFunctional';
import { HrClaims } from '@constants/';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { checkRoles, getObjectLength } from '@helpers';

const HomePresentational = ({
	PayrollClaims,
	hrClaims,
	nHrClaims,
	employeeDetails,
	leaveTypelist,
	setLeaveTypelist,
	sampleData,
	setSampleData,
	isLeaveEdited,
	handelSaveChanges,
	nPayrollClaims,
	roles,
}) => {
	const history = useHistory();
	const newHrClaims = hrClaims?.map((data) => data?.value) || [];
	const isEmployeeRole =
		PayrollClaims?.length === 0 && getObjectLength(checkRoles(roles, 'Employee')) > 0 && !newHrClaims?.includes(HrClaims?.MANAGE_LEAVES);
	const isEmployeeCheck = getObjectLength(checkRoles(roles, 'Employee')) > 0 && employeeDetails?.employeeId;
	const home_card_details = [
		{
			id: 0,
			header: 'Leaves To Approve',
			text: 'New Leave Request(s)',
			count: 0,
			link: '/leaves/leaves_to_approve',
		},
		{
			id: 0,
			header: 'Who Is On Leave?',
			text: 'Employee(s) on Leave',
			count: 0,
			link: '/leaves/leaves_statuses',
		},
	];
	return (
		<Row className="home_main_page">
			<Col className="home_main_header">
				<ETypo bold h2>
					Home
				</ETypo>
				<div className="home_header_button">
					<EdenredButtons
						btnType={isEmployeeCheck ? 'secondary' : 'primary'}
						onClick={() => history.push(isEmployeeRole ? '/employee/request_leave' : '/leaves/request_leave')}>
						Request Leave
					</EdenredButtons>
					{isEmployeeCheck && (
						<EdenredButtons disabled={isLeaveEdited} onClick={handelSaveChanges}>
							Save Changes
						</EdenredButtons>
					)}
				</div>
			</Col>
			{nHrClaims?.length > 0 ? (
				<Col xl={24} md={24} lg={24} sm={24} xs={24} className="home_card">
					{home_card_details?.map((data) => (
						<div className="home_leaves_to_approve">
							<ETypo medium h4>
								{data?.header}
							</ETypo>
							<div className="leaves_to_approve_card">
								<div className="leaves_to_approve_count">
									<ETypo medium h3 color="#181919">
										{data?.count}
									</ETypo>
								</div>
								<div className="leaves_to_approve_text">
									<ETypo medium b2>
										{data?.text}
									</ETypo>
									<ETypo medium b1 color="#0E5274" style={{ marginTop: '5px' }}>
										<a href={data?.link}>View Here</a>
									</ETypo>
								</div>
							</div>
						</div>
					))}
				</Col>
			) : null}
			{isEmployeeCheck ? (
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<EmployeeHistoryFunctional
						{...{ claims: hrClaims, employeeDetails, leaveTypelist, setLeaveTypelist, sampleData, setSampleData }}
					/>
				</Col>
			) : null}
		</Row>
	);
};

export default HomePresentational;
