export const country_flags = {
	Australia: require('./Australia.png'),
	Bahrain: require('./Bahrain.png'),
	Bangladesh: require('./Bangladesh.png'),
	Belgium: require('./Belgium.png'),
	Bhutan: require('./Bhutan.png'),
	Botswana: require('./Botswana.png'),
	Brazil: require('./Brazil.png'),
	Brunei: require('./Brunei.png'),
	Cambodian: require('./Cambodian.png'),
	Cameroon: require('./Cameroon.png'),
	Denmark: require('./Denmark.png'),
	DRPCongo: require('./DRPCongo.png'),
	Ethiopia: require('./Ethiopia.png'),
	Europe: require('./Europe.png'),
	Fiji: require('./Fiji.png'),
	France: require('./France.png'),
	Georgia: require('./Georgia.png'),
	Greece: require('./Greece.png'),
	HongKong: require('./HongKong.png'),
	India: require('./India.png'),
	Ireland: require('./Ireland.png'),
	Jamaica: require('./Jamaica.png'),
	Jordan: require('./Jordan.png'),
	Kenya: require('./Kenya.png'),
	Kuwait: require('./Kuwait.png'),
	Lebanon: require('./Lebanon.png'),
	Malawi: require('./Malawi.png'),
	Malaysia: require('./Malaysia.png'),
	Maldives: require('./Maldives.png'),
	Mauritania: require('./Mauritania.png'),
	Moldova: require('./Moldova.png'),
	Morocco: require('./Morocco.png'),
	Nepal: require('./Nepal.png'),
	NewZealand: require('./NewZealand.png'),
	NorthernMarianaIslands: require('./NorthernMarianaIslands.png'),
	Oman: require('./Oman.png'),
	Pakistan: require('./Pakistan.png'),
	Palau: require('./Palau.png'),
	Palestine: require('./Palestine.png'),
	Philippines: require('./Philippines.png'),
	Portugal: require('./Portugal.png'),
	Rwanda: require('./Rwanda.png'),
	Senegal: require('./Senegal.png'),
	Seychelles: require('./Seychelles.png'),
	Singapur: require('./Singapur.png'),
	SouthAfrica: require('./SouthAfrica.png'),
	Spain: require('./Spain.png'),
	SriLanka: require('./SriLanka.png'),
	Tanzania: require('./Tanzania.png'),
	Thailand: require('./Thailand.png'),
	Tunisia: require('./Tunisia.png'),
	Uganda: require('./Uganda.png'),
	UnitedArabEmirates: require('./UnitedArabEmirates.svg'),
	UnitedKingdom: require('./UnitedKingdom.png'),
	UnitedStatesofAmerica: require('./UnitedStatesofAmerica.png'),
	Vietnam: require('./Vietnam.png'),
	Zambia: require('./Zambia.png'),
};
