const GET_ANNOUNCEMENT_DATA = 'GET_ANNOUNCEMENT_DATA';
const GET_ANNOUNCEMENT_DATA_SUCCESS = ' GET_ANNOUNCEMENT_DATA_SUCCESS';
const GET_ANNOUNCEMENT_DATA_ERROR = 'GET_ANNOUNCEMENT_DATA_ERROR';
const UPDATE_ANNOUNCEMENT_DATA = 'UPDATE_ANNOUNCEMENT_DATA';
const UPDATE_ANNOUNCEMENT_DATA_SUCCESS = 'UPDATE_ANNOUNCEMENT_DATA_SUCCESS';
const UPDATE_ANNOUNCEMENT_DATA_ERROR = 'UPDATE_ANNOUNCEMENT_DATA_ERROR';
const ANNOUNCEMENT_SUCCESS = 'ANNOUNCEMENT_SUCCESS';
const ANNOUNCEMENT_POPUP = 'ANNOUNCEMENT_POPUP';
const ANNOUNCEMENTS_TOAST_VISIBLE = 'ANNOUNCEMENTS_TOAST_VISIBLE';

const getAnnouncementData = (response) => {
	return {
		type: GET_ANNOUNCEMENT_DATA,
		payload: response,
	};
};

const getAnnouncementDataSuccess = (res) => {
	return {
		type: GET_ANNOUNCEMENT_DATA_SUCCESS,
		res,
	};
};

const getAnnouncementDataError = () => {
	return {
		type: GET_ANNOUNCEMENT_DATA_ERROR,
		payload: {},
	};
};

const updateAnnouncementData = (initial) => {
	return {
		type: UPDATE_ANNOUNCEMENT_DATA,
		payload: initial,
	};
};

const updateAnnouncementDataSuccess = (res) => {
	return {
		type: UPDATE_ANNOUNCEMENT_DATA_SUCCESS,
		res,
	};
};

const updateAnnouncementDataError = () => {
	return {
		type: UPDATE_ANNOUNCEMENT_DATA_ERROR,
		payload: {},
	};
};
const announcementSuccess = (flag) => {
	return {
		type: ANNOUNCEMENT_SUCCESS,
		flag,
	};
};
const announcementPopUp = (flag) => {
	return {
		type: ANNOUNCEMENT_POPUP,
		flag,
	};
};
const announcementsToastVisible = (flag) => {
	return {
		type: ANNOUNCEMENTS_TOAST_VISIBLE,
		flag,
	};
};
export default {
	types: {
		GET_ANNOUNCEMENT_DATA,
		GET_ANNOUNCEMENT_DATA_SUCCESS,
		GET_ANNOUNCEMENT_DATA_ERROR,
		UPDATE_ANNOUNCEMENT_DATA,
		UPDATE_ANNOUNCEMENT_DATA_SUCCESS,
		UPDATE_ANNOUNCEMENT_DATA_ERROR,
		ANNOUNCEMENT_SUCCESS,
		ANNOUNCEMENT_POPUP,
		ANNOUNCEMENTS_TOAST_VISIBLE,
	},

	creators: {
		getAnnouncementData,
		getAnnouncementDataSuccess,
		getAnnouncementDataError,
		updateAnnouncementData,
		updateAnnouncementDataSuccess,
		updateAnnouncementDataError,
		announcementSuccess,
		announcementPopUp,
		announcementsToastVisible,
	},
};
