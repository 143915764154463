import React from 'react';
import ApproveRequestsFunctional from './components/approve-requests-functional';
import './approve-requests.scss';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess=UnauthorizedAccess(ApproveRequestsFunctional)
const ApproveRequests = () => {
	let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
	return 	<AuthorizedAccess {...{currentUser}}/>;
};

export default ApproveRequests;
