import React, { Fragment } from 'react';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './indes.scss';

const EdenredDropDown = ({ placeholder, value, index, options = [], onChange, newSalary, defaultSalary, currentmonth, salary }) => {
	const nOption = options?.map((data) => {
		return {
			...data,
			isActive: data?.salaryProcessStatus !== 'Salaries Due' ? false : true,
		};
	});
	return (
		<Fragment>
			<MultiSelect
				{...{
					placeholder,
					searchVisibility: false,
					Option: nOption,
					value: newSalary,
					onChange: (data) => onChange(data?.salaryDate),
					dropDownValue: 'salaryDate',
					dropDownSubValue: 'salaryProcessStatus',
					dateFormat: 'MMMM YYYY',
					showIcon: false,
					prefix: <img style={{ marginRight: '8px' }} src={not_focus_icons?.payrollCalender} />,
					justifyOption: true,
					showOptionStatus: true,
				}}
			/>
		</Fragment>
	);
};

export default EdenredDropDown;

export const DropDownGratuity = ({
	label,
	placeholder,
	value,
	index,
	options = [],
	empolyee,
	onChangeTermination,
	payRollRun1,
	date1,
	disabledValue,
}) => {
	return (
		<Fragment>
			<MultiSelect
				{...{
					value: empolyee || null,
					placeholder: placeholder,
					onChange: (data) => onChangeTermination(data, payRollRun1, date1),
					dropDownValue: 'name',
					Option: options,
					searchVisibility: false,
					label,
				}}
			/>
		</Fragment>
	);
};
