import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useLocation, withRouter } from 'react-router-dom';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from '@assets/icons/index';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredEmployeeCard from '@sharedComponent/card/employees/employees-card';

const SelectPayrollChannel = (props) => {
	const { state } = useLocation();
	const [clicked, setClicked] = useState(false);
	const channelOptions = [
		{
			title: 'Non WPS',
			description: "Your payroll wont be run under the Ministry of Labour's (MoL) WPS.",
			icon: clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox,
		},
		{
			title: 'WPS',
			description: "Your payroll will be run under the Ministry of Labour's (MoL) WPS.",
			icon: !clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox,
		},
	];

	const selectedChannel = () => {
		if (clicked === true) {
			props.history.push({
				pathname: '/employees/add/individual/employee/employment/id',
				state: { ...state, payrollChannel: channelOptions[0].title },
			});
		} else {
			props.history.push({
				pathname: '/employees/add/individual/employee/employment/id',
				state: { ...state, payrollChannel: channelOptions[1].title },
			});
		}
	};

	return (
		<>
			<EdenredHeader />
			<Row className="select_payroll_channel_container">
				<Col xl={16} lg={24} md={24} sm={24} xs={24} className="select_payroll_channel_title">
					<EdenredTypo>What Is The Payroll Channel For {state && state.firstName ? state.firstName : ''}? </EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="select_payroll_channel_description">
					<EdenredTypo>You can run payroll without using WPS later, even if you select WPS.</EdenredTypo>
				</Col>
				<Row className="select_payroll_channel_card_section">
					{channelOptions.map((option, index) => (
						<Col xl={8} lg={12} md={20} sm={12} xs={12} key={index} className="select_payroll_channel_card_column">
							<EdenredEmployeeCard
								className={
									option.icon === icons.focus_state.Check
										? 'select_payroll_channel_select_card'
										: 'select_payroll_channel_unselect_card'
								}
								type="PayrollChannel"
								content={option}
								onClick={() => setClicked(!clicked)}
							/>
						</Col>
					))}
				</Row>
				<Col xl={8} lg={12} md={22} sm={24} xs={24} className="select_payroll_channel_button_section">
					<EdenredButton onClick={selectedChannel}>Next</EdenredButton>
				</Col>
			</Row>
		</>
	);
};

export default withRouter(SelectPayrollChannel);
