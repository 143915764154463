import React from 'react';
import propTypes from 'prop-types';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import Inputbox from '../Input-Field';
import EdenredButtons from '../button/EdenredButton';
import './modal-new.scss';

const Edenredmodal = ({
	title = '',
	desc = '',
	onOkay = null,
	onCancel = null,
	linkname,
	onCancelButton = 'No',
	onOkayButton = 'Yes',
	href,
	isOpen = false,
	left = false,
	textcenter = false,
	heart = false,
	close = null,
	btnleft = false,
	onsecondary = null,
	secondarybtn = '',
	celebration = true,
	imagedown = false,
	image = true,
	field = '',
	fieldonchange = '',
	fieldlabel = '',
	loading = false,
	secondaryBtnLoading = false,
	topImage = null,
	className = '',
	fieldValue = '',
	errorMessage = '',
	onOkayDisable = false,
	children,
	content,
	target = '_self',
	hrefOnClick = () => { },
	imageSrc,
	iconSrc,
	textStart = false,
	mainModalClassName = '',
}) => {
	const containsBothButton = !!onOkay && !!onCancel;
	const width = containsBothButton ? 'two-button' : 'one-button';
	return (
		isOpen && (
			<div className={`modal ${className}`}>
				<div className={`mainmodal ${mainModalClassName}`}>
					<div className="container">
						{content ? (
							content
						) : (
							<>
								{imagedown ? (
									<>
										<div className="image-close">
											{close && <ImageComponent className="class-close" src={images.close} onClick={close} />}
										</div>
										<div className="celebration">
											{image && <ImageComponent src={celebration ? images.celebration : images.heart} />}
										</div>
									</>
								) : (
									<div className="image-row">
										<div className={left ? 'image-left' : 'image-container'}>
											{heart && <ImageComponent src={imageSrc ? imageSrc : images.heart} />}

											<div className="image-closer">
												{close && (
													<ImageComponent className="class-close" src={iconSrc ? iconSrc : images.close} onClick={close} />
												)}
											</div>
										</div>
									</div>
								)}
								{topImage && <div className="top-image">{topImage}</div>}
								{title && (
									<p className={` modal-title ${textcenter ? 'titlecenter' : ''} ${textStart ? 'titleStart' : ''}`}>{title}</p>
								)}
								{desc && <p className={` modal-desc ${textcenter ? 'titlecenter' : ''} ${textStart ? 'titleStart' : ''}`}>{desc}</p>}
								{field && (
									<Inputbox
										type="text"
										onChange={fieldonchange}
										placeholder={field}
										label={fieldlabel}
										value={fieldValue}
										isErrorMessage={errorMessage}
										errorMessage={errorMessage}
									/>
								)}
								{children}
								<div className="button-flex">
									{btnleft && <div className="btn-space"></div>}
									{onCancel && (
										<EdenredButtons className={width} onClick={onCancel} btnType="secondary">
											{onCancelButton}
										</EdenredButtons>
									)}
									{onOkay && (
										<EdenredButtons disabled={onOkayDisable} className={width} onClick={!loading && onOkay} btnType="primary">
											{loading ? <div className="loader" /> : onOkayButton}
										</EdenredButtons>
									)}
								</div>
								{linkname && (
									<div className="linktab">
										<a href={href} onClick={hrefOnClick ? hrefOnClick : ''} className="link" target={target}>
											{linkname}
										</a>
									</div>
								)}
								{secondarybtn && (
									<EdenredButtons className="secondarybtn" onClick={!secondaryBtnLoading && onsecondary} btnType="secondary">
										{secondaryBtnLoading ? <div className="secondary_loader" /> : secondarybtn}
									</EdenredButtons>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		)
	);
};

export default Edenredmodal;
Edenredmodal.propTypes = {
	title: propTypes.string,
	desc: propTypes.string,
	onOkay: propTypes.func,
	onCancel: propTypes.func,
	linkname: propTypes.string,
	onCancelButton: propTypes.string,
	onOkayButton: propTypes.string,
	href: propTypes.string,
	isOpen: propTypes.bool,
	className: propTypes.string,
	afterOpenModal: propTypes.func,
};
