export const UN_EMPLOYMENT_TAB_KEYS = {
    SUBSCRIBED: 'subscribed',
    MISSING_DETAILS: 'missing_details',
    PENDING: 'pending',
    CANCELLED: 'cancelled',
};

export const UN_EMPLOYMENT_TAB_LABELS = {
    SUBSCRIBED: 'Subscribed',
    MISSING_DETAILS: 'Missing Details',
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
};