import React from 'react';
import { useSelector } from 'react-redux';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './saga/index';
import RequestLeaveFunctional from './component/request-leave-functional';

function RequestLeaves({ isEmployeeRequest, employeeDetails }) {
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.roles?.[0]?.name || '');
	let currentClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims || []);
	return <RequestLeaveFunctional {...{ currentUser, currentClaims, UserClaims: 'Manage.Leaves', isEmployeeRequest, employeeDetails }} />;
}
const ReducedScreen = withReducer('requestLeavesReducer', reducer, saga)(RequestLeaves);

export default ReducedScreen;
