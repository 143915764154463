import React, { useState } from 'react';
import Counter from '../index';
import './date.scss';

const Counter6 = () => {
	const [count, setCount] = useState(0);
	// date picker need in future
	// const [date, setDate] = useState('');
	const onIncrement = () => {
		setCount(count + 1);
	};
	const onDecrement = () => {
		setCount(count - 1);
	};
	// date picker need in future
	// const style = {
	// 	left: '36px',
	// 	position: 'absolute',
	// 	fontFamily: 'Montserrat-medium',
	// 	fontSize: '12px',
	// 	fontWeight: '500',
	// 	lineHeight: '14px',
	// 	textAlign: 'left',
	// 	letterSpacing: '0em',
	// 	content: attr(placeholder),
	// };
	// const time = {
	// 	left: '36px',
	// 	position: 'absolute',
	// 	fontFamily: 'Montserrat-medium',
	// 	fontSize: '12px',
	// 	fontWeight: '500',
	// 	lineHeight: '14px',
	// 	textAlign: 'left',
	// 	letterSpacing: '0em',
	// 	content: attr(value),
	// };
	return (
		<>
			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				<Counter grey={true} width="146px" twoButton={true} handleIncrement={onIncrement} handleDecrement={onDecrement} content={count} />
			</div>
			{/* <div className="addc">
				<input
					type="date"
					className="date"
					value={date}
					// style={date ? time : style}
					onChange={(e) => setDate(e.target.value)}
					placeholder="Select Date"
				/>
			</div> */}
		</>
	);
};

export default Counter6;
