import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, withRouter, useHistory } from 'react-router-dom';

import ClevertapReact from 'src/utils/clever-tap';
import ContentLoader from '@sharedComponent/content-loader';
import EdenRedCard from '@sharedComponent/card';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import { EmployeListExcelDownload } from '@sharedComponent/excel/excel';
import PayrollService from 'src/services/payroll-service';
import { icons } from '@assets/icons/index';
import { successToast } from 'src/utils/toast';

const PayrollChannelPresentational = (props, { user }) => {
	const _payrollService = new PayrollService();
	const [clicked, setClicked] = useState(false);
	const [emppStagingLoader, setEmppStagingLoader] = useState(false);
	const [downloadingExecl, setdownloadingExecl] = useState(false);
	const [EmpList, setEmplist] = useState(false);
	const [countEmp, setcountEmp] = useState('');
	const [stagingEmployeeList, setStagingEmployeeList] = useState([]);
	const channelOptions = [
		{
			title: 'Non WPS',
			description: "Your payroll wont be run under the Ministry of Labour's (MoL) WPS.",
			icon: clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox,
			isSelected: false,
		},
		{
			title: 'WPS',
			description: "Your payroll will be run under the Ministry of Labour's (MoL) WPS.",
			icon: !clicked ? icons.focus_state.Check : icons.not_focus.EmptyBox,
			isSelected: false,
		},
	];
	const { state } = useLocation();
	const history = useHistory();

	console.log('statessss', state.month);

	const getStagedEmployeesList = async () => {
		setEmppStagingLoader(true);
		await _payrollService
			.getStagedEmployeesListUrl()
			.then((response) => {
				console.log('RESO :::', response.data);
				setStagingEmployeeList(response.data);
				setEmppStagingLoader(false);
			})
			.catch((error) => {
				setEmppStagingLoader(false);
			});
	};

	useEffect(() => {
		getStagedEmployeesList();
	}, []);

	const selectedChannel = () => {
		// console.log('STATE ::', state.tabledata);
		console.log('STATE ::', stagingEmployeeList);
		if (clicked === true) {
			if (state.type === 'payrollPlus') {
				ClevertapReact.event('Next_MthYear', { Type: 'Non Wps' });
				history.push({
					pathname: '/payroll/run/channel/now_or_later',
					state: {
						...state,
						payrollForMonth: state.month,
						payrollForYear: state.year,
						channelOptions: channelOptions[0],
						endofService: state.tabledata,
					},
				});
			} else {
				props.history.push({
					pathname: '/payroll/run/channel/nonwps',
					state: { ...state, channelOptions: channelOptions[0], endofService: state.tabledata },
				});
			}
		} else {
			let empName = [];
			state &&
				// state.tabledata.length > 0 &&
				// state.tabledata.map((td) => {
				stagingEmployeeList.length > 0 &&
				stagingEmployeeList.map((td) => {
					if (td.establishmentId && td.labourCardNo && (td.wpsPersonId || td.wpsPersonid) && td.payrollChannel === 'WPS') {
					} else {
						if (td.employeCode) {
							empName.push({ employeeCode: td.employeCode });
							setEmplist(empName);
						} else {
							empName.push({ employeeCode: td.employeeCode });
							setEmplist(empName);
						}
						//empName.push(td.employeeId);
					}
				});
			// Count of Empolyee
			setcountEmp(empName.length);
			if (empName.length > 0) {
				setdownloadingExecl(true);
				// successToast(
				// 	{
				// 		msg: `WPS details are missing for ‘${empName.length}’ \n employees. Please contact us on support.ae@edenred.com.`,
				// 		icon: 'Error',
				// 	},
				// 	true
				// );
				// console.log("buttonref",buttonRef);
				// buttonRef.current.Click();
			} else {
				if (state.type === 'payrollPlus') {
					ClevertapReact.event('Next_MthYear', { Type: 'Wps' });
					history.push({
						pathname: '/payroll/run/channel/now_or_later',
						state: {
							...state,
							payrollForMonth: state.month,
							payrollForYear: state.year,
							channelOptions: channelOptions[1],
							endofService: state.tabledata,
						},
					});
				} else {
					ClevertapReact.event('Next_SPChannel');
					props.history.push({
						pathname: '/payroll/run/channel/wps',
						state: { ...state, channelOptions: channelOptions[1], endofService: state.tabledata },
					});
				}
			}
		}
	};
	console.log('EmpList:', EmpList);
	return (
		<>
			<EdenredHeader />
			<Row className="payroll_channel_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_channel_header">
					<EdenredTypo>Select Payroll Channel</EdenredTypo>
				</Col>
				{emppStagingLoader ? (
					<div style={{ height: '110px' }}>
						<ContentLoader />
					</div>
				) : (
					<>
						<Row className="payroll_channel_select_card_section">
							{channelOptions.map((option, index) => (
								<Col xl={9} lg={12} md={20} sm={24} xs={24} key={index} className="payroll_channel_select_cards">
									<EdenRedCard
										className={
											option.icon === icons.focus_state.Check ? 'payroll_channel_select_card' : 'payroll_channel_unselect_card'
										}
										type="PayrollChannel"
										content={option}
										onClick={() => setClicked(!clicked)}
										user={state}
									/>
								</Col>
							))}
						</Row>
						<Col xl={9} lg={9} md={9} sm={24} xs={24} className="payroll_channel_button_section">
							<EdenredButton onClick={selectedChannel}>Next</EdenredButton>
						</Col>
					</>
				)}
			</Row>
			{downloadingExecl && (
				// <div>
				<div
					style={{ position: 'absolute', margin: '15px', top: '105px' }}
					class="alert alert-dismissible fade alert-danger show"
					role="alert">
					<p>
						WPS Details are missing for ‘{countEmp}’ employees.
						<EmployeListExcelDownload
							hideSalary
							dataSet={EmpList ? EmpList : []}
							filename={`employeelist`}
							element={<a id="ex"> Click Here</a>}
						/>{' '}
						to download the list.
					</p>
					<p>
						You can update their WPS Establishment ID and WPS Person ID from the 'employees' section or write to us on
						support.ae@edenred.com for help.
					</p>
					<button
						type="button"
						class="close"
						data-dismiss="alert"
						aria-label="Close"
						style={{ outline: 'none', cursor: 'pointer', position: 'absolute', top: '-13px', right: 0 }}>
						<span aria-hidden="true" onClick={() => setdownloadingExecl(false)}>
							x
						</span>
					</button>
				</div>
				// </div>
			)}
		</>
	);
};

export default withRouter(PayrollChannelPresentational);
