import React from 'react';
import TeamsPayrollPresentational from './teams-payroll-presentational';

const TeamsPayrollFunctional = (values) => {
	return (
		<div>
			<TeamsPayrollPresentational {...{ values }} />
		</div>
	);
};

export default TeamsPayrollFunctional;
