import SetupHeader from '@pages/setup-Screen/components/setup-header';
import React from 'react';
import { Row, Col } from 'antd';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';
import FloatingInput from '../../../components/shared/text-field/index';
import { useHistory } from 'react-router-dom';
import { icons } from 'src/assets/icons';
const Medicalinsurancepresentational = ({ isSelect, handleSelect, data, Handledata, onFinish }) => {
	const history = useHistory();
	const values = [
		{
			name: 'Myself',
			value: 'existing',
		},
		{
			name: 'Someone Else',
			value: 'new',
		},
	];
	return (
		<div>
			<SetupHeader />
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="medical">
				<Col xl={16} lg={22} md={22} sm={22} xs={22} className="medical-content">
					<p className="heading">Who Handles Insurance In Your Company?</p>
					<Col xl={8} lg={8} md={24} sm={24} xs={24}>
						<FloatingInput
							name="Name"
							type="text"
							labels={
								<>
									Name<span style={{ color: 'red' }}>*</span>
								</>
							}
							onChange={(e) => {
								Handledata(e.target.name, e.target.value);
							}}
						/>
						<FloatingInput
							name="PhoneNumber"
							className="phonenumber"
							type="number"
							labels={
								<>
									Mobile Number <span style={{ color: 'red' }}>*</span>
								</>
							}
							onChange={(e) => {
								Handledata(e.target.name, e.target.value);
							}}
							countryCode={{ icon: icons.countries.UnitedArabEmirates, code: '+971' }}
							countryCodeClassName="medicalinsurancecountry"
						/>
						<FloatingInput
							name="Renewal"
							className="renewal"
							type="text"
							labels={
								<>
									Month of Renewal <span style={{ color: 'red' }}>*</span>
								</>
							}
							onChange={(e) => {
								Handledata(e.target.name, e.target.value);
							}}
						/>
					</Col>
					<Col xl={8} lg={8} md={8} sm={8} xs={8}>
						<button className={data.Name && data.PhoneNumber && data.Renewal ? 'submit-active' : 'submit'} onClick={() => onFinish()}>
							Submit
						</button>
						<button
							className="cancel"
							onClick={() => {
								history.push('/setup');
							}}>
							{' '}
							Cancel
						</button>
					</Col>
				</Col>
			</Col>
		</div>
	);
};

export default Medicalinsurancepresentational;
