import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Actions from '@sharedComponent/app-action-reducer/action';
import EmployeeService from 'src/services/employee-service';
import Popup from 'src/utils/popup/popups';
import ApproveEmployeeRequestPresentational from './approve-employee-request-presentational';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '@pages/DesignSystem/Toast';
import { EMPLOYEES_PAGE_CONSTANTS } from '@constants/app-constants';

const ApproveEmployeeRequestFunctional = () => {
	const [modalopen, setModalopen] = useState(true);
	const [visible, setVisible] = useState(false);
	let [selectedAction, setSelectedAction] = useState('');
	const [modalopenMobile, setModalopenMobile] = useState(false);
	let [pendingRequest, setPendingRequest] = useState([]);
	const [isSelectAll, setisSelectAll] = useState(false);
	const [isSelectedCount, setisSelectedCount] = useState(0);
	let [isDataLoading, setIsDataLoading] = useState(false);
	let employeeApi = new EmployeeService();
	const dispatch = useDispatch();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	let [cardType, setCardType] = useState('');

	// useEffect
	useEffect(() => {
		init();
	}, []);

	function init() {
		getPendingRequest();
	}

	function handleCardAction(index, action) {
		let updatedPendingRequest = [...pendingRequest];
		updatedPendingRequest[index].isSelected = true;
		setPendingRequest(updatedPendingRequest);
		showModal(action);
	}

	// checkbox clicked
	const isSelectClicked = (index) => {
		let updatedPendingRequest = [...pendingRequest];
		updatedPendingRequest[index].isSelected = !updatedPendingRequest[index].isSelected;
		let selectionExist = updatedPendingRequest.filter((request) => request.isSelected);
		if (selectionExist.length > 0) {
			setModalopen(true);
			setModalopenMobile(true);
			setisSelectedCount(selectionExist.length);
		} else {
			setModalopen(false);
			setModalopenMobile(true);
			setisSelectedCount(0);
		}
		/** future refernce
		 * setPendingRequest([...updatedPendingRequest]);
		let data = (await approveRequest) || [];
		await data.map((val, ind) => {
			if (ind === index) {
				val.isSelect = val.isSelect === true ? false : true;
			}
			return val;
		});

		let isSelected = await data.filter((val) => val.isSelect === true);
		if (isSelected && isSelected[0]) {
			await setisSelectAll(isSelected.length === data.length);
			await setModalopen(true);
			await setModalopenMobile(true);
		} else {
			await setisSelectAll(false);
			await setModalopen(false);
			await setModalopenMobile(false);
		}
		await setisSelectedCount(isSelected.length || 0);
		await setApproveRequest([...data]);
		 */
	};

	// select all(modal checkbox)
	const isSelectedAllClicked = () => {
		let updatedPendingRequest = [...pendingRequest];
		updatedPendingRequest.forEach((request) => {
			request.isSelected = !isSelectAll;
		});
		if (!isSelectAll) {
			setisSelectedCount(updatedPendingRequest.length);
		} else {
			setisSelectedCount(0);
		}
		setisSelectAll(!isSelectAll);
		/**future reference
			 * let isdata = await isSelectAll;
					let data = (await approveRequest) || [];
					if (isdata) {
						await data.map((val) => (val.isSelect = false));
						await setisSelectedCount(0);
						await setModalopen(false);
					} else {
						await data.map((val) => (val.isSelect = true));
						await setisSelectedCount(data.length || 0);
					}
					await setisSelectAll(!isSelectAll);
					await setApproveRequest([...data]);
		*/
	};

	// modal
	const showModal = (action) => {
		setVisible(true);
		setModalopenMobile(false);
		setSelectedAction(action);
	};

	async function handleReview() {
		function titleMessage() {
			const toastText = {
				action:
					selectedAction === EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve
						? 'approved'
						: selectedAction === EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject
						? 'rejected'
						: '',
			};
			if (cardType === EMPLOYEES_PAGE_CONSTANTS.cards.type.all) {
				return `Card request ${toastText.action}!`;
			}
			return `Card ${(cardType || '')?.toLowerCase()} ${toastText.action}!`;
		}
		let payload = getApprovalPayload();
		setIsDataLoading(true);
		let reviewedSuccess = await reviewPendingRequest(payload);
		setIsDataLoading(false);
		if (reviewedSuccess) {
			setisSelectedCount(0);
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: titleMessage(),
				subMessage: null,
			});
			dispatch(Actions.creators.getPendingStatusStart());
		}
	}

	async function reviewPendingRequest(payload) {
		try {
			let response = await employeeApi.reviewPendingRequest(payload);
			if (response) {
				return response;
			}
		} catch (err) {
			console.log(err);
		}
	}

	function getApprovalPayload() {
		let emps = pendingRequest.reduce((acc, request) => {
			if (request.isSelected) {
				acc.push(request.employeeId);
			}
			return acc;
		}, []);
		return {
			cardRequestAction: {
				uploadIds: pendingRequest.reduce((acc, request) => {
					if (request.isSelected && request.uploadId && request.cardRequestType == 'Order') {
						acc.push(request.uploadId);
					}
					return acc;
				}, []),
				employeeIds: pendingRequest.reduce((acc, request) => {
					if (request.isSelected && request.employeeId && request.cardRequestType == 'Reissue') {
						acc.push(request.employeeId);
					}
					return acc;
				}, []),
				massCorpDelUploadId: pendingRequest.reduce((acc, request) => {
					if (request.isSelected && request.massCorpDelUploadId && request.cardRequestType == 'Deletion') {
						acc.push(request.massCorpDelUploadId);
					}
					return acc;
				}, []),
				action: selectedAction === 'Approve' ? 'approve' : 'reject',
			},
		};
	}

	const closeModal = (action) => {
		setVisible(false);
		setCardType('');
		setSelectedAction('');
	};
	const getDeleterole = () => {
		setVisible(false);
		handleReview();
	};

	async function getPendingRequest() {
		setIsDataLoading(true);
		await fetchPendingRequests();
		setIsDataLoading(false);
	}

	async function fetchPendingRequests() {
		try {
			let response = await employeeApi.getPendingRequest();
			if (response && response?.data) {
				let data = response?.data?.requests?.map((response) => {
					response.name = `${response.numberOfRequest}${response?.numberOfRequest > 1 ? ' Cards' : ' Card'} `;
					let orderPlacedDate = moment(response?.orderPlaced);
					response.isSelected = false;
					response['cardRequestType'] = response?.cardRequestType || null;
					response.order = ` ${
						response['cardRequestType'] == 'Reissue'
							? 'Order Placed'
							: response['cardRequestType'] == 'Deletion'
							? 'Deletion Made'
							: response['cardRequestType'] == 'Order'
							? 'Addition Made'
							: 'Order Placed'
					}: ${orderPlacedDate.format('DD MMMM YYYY')} at ${orderPlacedDate.format('h:mm a')}`;
					response.order2 = ` ${
						response['cardRequestType'] == 'Reissue'
							? 'Order Placed'
							: response['cardRequestType'] == 'Deletion'
							? 'Deletion Made'
							: response['cardRequestType'] == 'Order'
							? 'Addition Made'
							: 'Order Placed'
					} at ${orderPlacedDate.format('h:mm a')}. ${orderPlacedDate.format('DD MMMM YYYY')}`;
					return response;
				});
				setPendingRequest(data);
			}
		} catch (err) {}
	}
	const closePopup = () => {
		setpopupVisible(false);
		getPendingRequest();
	};

	return (
		<>
			<Toast visible={popupVisible} color="#8EF9B3" textcolor="#484B52" clearPopUp={closePopup}>
				{popupMessage.titleMessage}
			</Toast>
			<ApproveEmployeeRequestPresentational
				{...{
					approveRequest: pendingRequest,
					isSelectClicked,
					visible,
					action: selectedAction,
					modalopenMobile,
					getDeleterole,
					modalopen,
					loading: isDataLoading,
					showModal,
					closeModal,
					isSelectAll,
					isSelectedAllClicked,
					isSelectedCount,
					handleCardAction,
					selectedAction,
					cardType,
					setCardType,
				}}
			/>
		</>
	);
};
export default ApproveEmployeeRequestFunctional;
