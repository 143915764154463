import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Tooltip } from 'antd';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import { EMPLOYEES_PAGE_CONSTANTS } from '@constants/app-constants';
import './employees.scss';

const EdenredEmployeeCard = ({
	type,
	onClick,
	className,
	content,
	isSelectClicked,
	showModal,
	handleCheckBox,
	style,
	handlepassdata,
	selected,
	isSelectedCount,
	index,
	setCardType,
}) => {
	const renderSwitchCard = (type) => {
		switch (type) {
			case 'ManageEmployeeTableCard':
				return (
					<Row className="manage-employee-table-card">
						<Col xl={4} md={4} lg={4} sm={3} xs={3} className="icon-col">
							<ImageComponent
								src={selected?.indexOf(content?.employeeCode) >= 0 ? icons?.focus_state?.Check : icons?.not_focus?.EmptyBox}
								onClick={() => handleCheckBox(content?.employeeCode)}
							/>
						</Col>
						<Col xl={4} md={4} lg={4} sm={18} xs={18} className="content_col">
							<Row>
								<Col sm={24} xs={24} className="user_col">
									{content?.employeeName}
								</Col>
								<Col sm={24} xs={24} className="wps_col">
									{content?.payrollChannel}
								</Col>
								<Col sm={24} xs={24} className="emp_col">
									{content?.employeeCode}
								</Col>
							</Row>
						</Col>
						<Col xl={4} md={4} lg={4} sm={2} xs={2} className="right_col">
							<ImageComponent src={icons.not_focus.Right} onClick={() => handlepassdata(content?.index)} />
						</Col>
					</Row>
				);
			case 'ViewEmployeeCard':
				return (
					<Row className="view-employee-web-card" style={{ padding: 8 }}>
						<Col className="view-employe-image">
							<ImageComponent src={content?.icon} />
						</Col>
						<Col className="view-employee-content">{content?.label}</Col>
					</Row>
				);
			case 'ViewEmployeeCardMobile':
				return (
					<Row className="view-employee-mobile-card">
						<Col xs={3} sm={3} className="view-employe-image">
							<ImageComponent src={content?.icon} />
						</Col>
						<Col xs={18} sm={18} className="view-employee-content">
							{content?.label}
						</Col>
						<Col xs={3} sm={3}>
							<ImageComponent src={icons.not_focus.Right} style={{ width: '24px', height: '24px' }} />
						</Col>
					</Row>
				);
			case 'ApproveEmployeeRequest':
				return (
					<>
						<Row className="edenredCard_desktop_view_approve_employee_requests">
							<div className="employee-request-upper-section">
								<div className="employee-request-checkbox-container">
									<Checkbox
										{...{
											borderWidth: 1,
											borderRadius: 4,
											checked: content?.isSelected,
											size: 14,
											onChange: () => isSelectClicked(content?.index),
											icon: (
												<div className="employee_plus_table_check_box">
													<img src={icons.not_focus.tick} alt="check" />
												</div>
											),
										}}
									/>
								</div>
								<div className="employee-date-and-button-container">
									<div className="employee-request-date-container">
										<div className="employee-month-submited-section">
											<ETypo h5 bold>
												{content?.name}
											</ETypo>
											<Link
												className="view-detail-style"
												to={{
													pathname: `/employees/card-request-detail/${content?.uploadId}/${content?.employeeId}/${content?.massCorpDelUploadId}`,
													state: { content },
												}}>
												View Details
											</Link>
										</div>
										<ETypo b1 light className="card-ordered-date">
											{content?.order || ''}
										</ETypo>
									</div>
									<div className="employee-request-cancel-approve-container">
										<EdenredButtons
											onClick={() => {
												setCardType(content?.cardRequestType);
												showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject);
											}}
											disabled={isSelectedCount > 1}
											{...{
												btnType: 'secondary',
											}}>
											<ETypo b1 medium color="#484B52">
												{EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject}
											</ETypo>
										</EdenredButtons>
										<EdenredButtons
											onClick={() => {
												setCardType(content?.cardRequestType);
												showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve);
											}}
											disabled={isSelectedCount > 1}
											{...{
												btnType: 'primary',
												style: { marginLeft: '6px' },
											}}>
											<ETypo b1 medium color="#FFFFFF">
												{EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve}
											</ETypo>
										</EdenredButtons>
									</div>
								</div>
							</div>
							<div className="employee-request-down-section">
								<div className="employee-request-amount-container">
									<div className="total-employee-style">
										<ETypo b1 medium color="#484B52">
											{content?.cardRequestType || ''}
										</ETypo>
									</div>
								</div>
							</div>
						</Row>
						{/* mobile-view */}
						<Row className="approve-employee-request-mobile-card">
							<Col xs={3} sm={3} className="approve-employee-emptybox">
								<ImageComponent
									src={content?.isSelected ? icons.focus_state.Check : icons.not_focus.EmptyBox}
									onClick={() => isSelectClicked(content?.index)}
								/>
							</Col>
							<Col xs={18} sm={18} className="approve-request-content-center">
								<Row className="approve-employee-content">
									<Col xs={24} sm={24} className="card">
										{content?.name}
									</Col>
									<Col xs={24} sm={24} className="order">
										{content?.order}
									</Col>
									<Col xs={24} sm={24} className="deletion">
										{content?.deletion}
									</Col>
								</Row>
							</Col>
							<Col xs={3} sm={3} className="approve-request-rightarrow">
								<Link
									to={{
										pathname: '/employees/card-request-detail',
										state: { content },
									}}>
									<ImageComponent src={icons.not_focus.Right} />
								</Link>
							</Col>
							<Col xs={24} sm={24} className="approve-request-button">
								<Row className="approve-request-arrow">
									<Col xs={12} sm={12} className="approve-reject">
										<EdenredButton
											type="outline-g"
											onClick={() => {
												setCardType(content?.cardRequestType);
												showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject);
											}}
											disabled={isSelectedCount > 1}>
											{EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject}
										</EdenredButton>
									</Col>
									<Col xs={12} sm={12} className="approve-approve">
										<EdenredButton
											onClick={() => {
												setCardType(content?.cardRequestType);
												showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve);
											}}
											disabled={isSelectedCount > 1}>
											{EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve}
										</EdenredButton>
									</Col>
								</Row>
							</Col>
						</Row>
					</>
				);
			case 'NormalDetail':
				return (
					<Row className="normal-detail-mobile-card">
						<Col xs={20} sm={20}>
							<Row className="normal-detail-content">
								<Col xs={24} sm={24} className="normal-header">
									<EdenredTypo>{content?.header}</EdenredTypo>
								</Col>
								<Col xs={24} sm={24}>
									<EdenredTypo>{content?.cardNumber}</EdenredTypo>
								</Col>
								<Col xs={24} sm={24}>
									<EdenredTypo>{content?.personId}</EdenredTypo>
								</Col>
								<Col xs={24} sm={24}>
									<EdenredTypo>{content?.establishmentID}</EdenredTypo>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			case 'EmployeeDocument':
				return (
					<Row className="normal-detail-mobile-card">
						<Col xs={20} sm={20}>
							<Row className="normal-detail-content">
								<Col xs={24} sm={24} className="normal-header">
									<EdenredTypo>{content?.EmployeeDocuments}</EdenredTypo>
								</Col>
								<Col xs={24} sm={24}>
									<EdenredTypo>{content?.PassportNumber}</EdenredTypo>
								</Col>
							</Row>
						</Col>
					</Row>
				);

			case 'AddEmployeesType':
				return (
					<Row className="add_employees_type_desktop_row">
						<Col xl={4} lg={4} md={6} sm={6} xs={6} className="add_employees_type_icon">
							<ImageComponent src={content?.icon} />
						</Col>
						<Col xl={20} lg={20} md={18} sm={18} xs={18} className="add_employees_type_details">
							<EdenredTypo className="add_employees_type_title">{content?.title}</EdenredTypo>
							<EdenredTypo className="add_employees_type_description">{content?.description}</EdenredTypo>
						</Col>
					</Row>
				);
			case 'PayrollChannel':
				return (
					<Row className="select_payroll_channel_desktop_row">
						<Col xl={6} lg={6} md={6} className="icon_select_option_part">
							<ImageComponent src={content?.icon} />
						</Col>
						<Col xl={16} lg={16} md={18} className="select_payroll_channel_content">
							<Row>
								<Col xl={24} lg={24} md={24} className="select_payroll_channel_card_title">
									<EdenredTypo>{content?.title}</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} className="select_payroll_channel_card_description">
									<EdenredTypo b3>{content?.description}</EdenredTypo>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			case 'ApproveRequestTable':
				return (
					<Row className="approve-request-table">
						<Col sm={20} xs={20} className="approve-request-table-content">
							<Row>
								<Col sm={24} xs={24} className="approve-request-table-title">
									<EdenredTypo>{content?.employeeName}</EdenredTypo>
								</Col>
								<Col sm={24} xs={24} className="approve-request-table-subtitle">
									<EdenredTypo>{content?.employeeCode}</EdenredTypo>
								</Col>
							</Row>
						</Col>
						<Col xs={4} sm={4} className="approve-request-tablet-image">
							<Link
								to={{
									pathname: '/employees/viewemployee',
									state: { tableData: content },
								}}>
								<ImageComponent src={icons.not_focus.Right} />
							</Link>
						</Col>
					</Row>
				);
			case 'OrderDetailsCard':
				return (
					<Row className="card_order_status_both_view_row">
						<Col xl={18} lg={18} md={18} sm={22} xs={22} className="order_details_card_column">
							<Col xl={24} md={24} lg={24} sm={24} xs={24} className="order_details_card_title">
								{content?.noOfCards !== 1 ? (
									<EdenredTypo> {content?.noOfCards} Cards</EdenredTypo>
								) : (
									<EdenredTypo> {content?.noOfCards} Card</EdenredTypo>
								)}
							</Col>
							<Col xl={24} md={24} lg={24} sm={24} xs={24} className="order_details_card_order_placed">
								<EdenredTypo>{content?.order}</EdenredTypo>
							</Col>
							<Col xl={24} md={24} lg={24} sm={24} xs={24} className="order_details_card_order_placed">
								<EdenredTypo>{content?.expectedDelivery ? content?.expectedDelivery : content?.status}</EdenredTypo>
							</Col>
						</Col>
						<Col xl={6} md={6} lg={6} sm={2} xs={2} className="view_order_details">
							<EdenredTypo>View Order Details</EdenredTypo>
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);
			case 'EmployeeDetails':
				return (
					<Row className="view_employee_details_both_view_row">
						<Col xl={15} md={15} lg={18} sm={18} xs={18} className="view_employee_name_code">
							<Tooltip title={content?.employeeName}>
								<EdenredTypo className="view_employee_name"> {content?.employeeName}</EdenredTypo>
							</Tooltip>
							<EdenredTypo className="view_employee_code">{content?.employeeCode}</EdenredTypo>
						</Col>
						<Col xl={9} md={9} lg={6} sm={2} xs={2} className="view_employee_details">
							<Link
								to={{
									pathname: '/employees/viewemployee',
									state: { tableData: content },
								}}>
								<EdenredTypo>View Details</EdenredTypo>
								<ImageComponent src={icons.not_focus.Right} />
							</Link>
						</Col>
					</Row>
				);
			case 'PendingAlerts':
				// style={{
				// 	marginTop: stepCount > 2 || isRejected ? '0px' : '22px',
				// 	borderRadius: stepCount > 2 || isRejected ? '0px' : '8px',
				// }}>
				return (
					<>
						{index > 0 && (
							<div className="divider">
								<div className="payroll-divider"></div>
							</div>
						)}
						<div className="pending-alerts-card-section">
							<div className="pending-order-contents1">
								<div className="alert-icon-holder">
									<ImageComponent src={content?.icons} />
								</div>

								<ETypo b1 medium color="#00344E">
									{content?.text}
								</ETypo>
							</div>
							<ImageComponent className="salaryIcons" src={icons.not_focus.NavigateRight} style={{ height: '17px' }} />
						</div>
					</>
				);
			default:
				return null;
		}
	};

	const renderSwitchMobileCard = (type) => {
		switch (type) {
			case 'AddEmployeesType':
				return (
					<Row className="add_employees_type_mobile_row">
						<Col sm={3} xs={3} className="add_employees_type_icon">
							<ImageComponent src={content?.icon} />
						</Col>
						<Col sm={19} xs={19} className="add_employees_type_details">
							<EdenredTypo className="add_employees_type_title">{content?.mobile_title}</EdenredTypo>
							<EdenredTypo className="add_employees_type_description">{content?.description}</EdenredTypo>
						</Col>
						<Col sm={2} xs={2} className="arrow_icon">
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);
			case 'PayrollChannel':
				return (
					<Row className="select_payroll_channel_mobile_row">
						<Col className="icon_select_option_part">
							<ImageComponent src={content?.icon} />
						</Col>
						<Col className="select_payroll_channel_card_title">
							<EdenredTypo>{content?.title}</EdenredTypo>
						</Col>
					</Row>
				);
			default:
				return null;
		}
	};

	return (
		<Card className={`edenredEmployeesCard_main ${className}`} onClick={onClick} style={style}>
			{renderSwitchCard(type)}
			{renderSwitchMobileCard(type)}
		</Card>
	);
};
export default EdenredEmployeeCard;
