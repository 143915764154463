import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const loadScript = (link) => {
	let jsScripts = document.createElement('script');
	jsScripts.setAttribute('src', link);
	jsScripts.setAttribute('type', 'text/javascript');
	jsScripts.setAttribute('defer', 'true');
	document.body.appendChild(jsScripts);
};
const FreshdeskWidgetHelper = (widgetstatus) => {
	// const history = useHistory();

	let link = 'https://widget.freshworks.com/widgets/67000002930.js';
	window.fwSettings = {
		widget_id: '67000002930',
		locale: 'en',
	};
	if ('function' != typeof window.FreshworksWidget) {
		var n = function () {
			n.q.push(arguments);
		};
		n.q = [];
		window.FreshworksWidget = n;
	}
	loadScript(link);
};
export default FreshdeskWidgetHelper;
