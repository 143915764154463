import React from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredEmployeeCard from '@sharedComponent/card/employees/employees-card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { icons } from 'src/assets/icons';
import { Link } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';

const AddEmployeesTypes = [
	{
		title: 'Add individual employee',
		mobile_title: 'Add individual employee',
		description: 'A new employee joined your company recently? Add his details here.',
		icon: icons.focus_state.AddUser,
		redirect: '/employees/add/individual/employee',
		CleverTapEventName: 'Add_IndividualEmployees',
	},
	{
		title: 'Add Employee(s) with Spreadsheet',
		mobile_title: 'Add employees in bulk',
		description: 'Use our spreadsheet template to add a list of new employees.',
		icon: icons.focus_state.AddUsers,
		redirect: '/employees/add/multiple/employees/upload',
		CleverTapEventName: 'Add_BulkEmployees',
	},
];

const AddEmployeesPresentational = () => {
	return (
		<>
			<EdenredHeader />
			<Row className="add_employees_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_employees_title">
					<EdenredTypo>How Do You Wish To Add Employees?</EdenredTypo>
				</Col>
				<Row className="add_employees_type_card_section">
					{AddEmployeesTypes.map((AddEmployeesType, index) => (
						<Col
							xl={12}
							lg={18}
							md={24}
							sm={24}
							xs={24}
							onClick={() => ClevertapReact.event(AddEmployeesType.CleverTapEventName, null)}
							key={index}
							className="add_employee_card_column">
							<Link to={AddEmployeesType.redirect}>
								<EdenredEmployeeCard type={'AddEmployeesType'} content={AddEmployeesType} />
							</Link>
						</Col>
					))}
				</Row>
			</Row>
		</>
	);
};

export default AddEmployeesPresentational;
