import React from 'react';
import Expirydocumentfunctional from './component/Expirydocumentfunctional';
import { withReducer } from '@store/reducerLoader';
import reducer from './reducer';
import saga from './saga/index';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { useSelector } from 'react-redux';

const AuthorizedAccess=UnauthorizedAccess(Expirydocumentfunctional)

function Employeesmodule() {
	let currentClaims = useSelector((state)=>state?.sharedStates?.currentUser?.hrClaims);
	let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
	return ( <AuthorizedAccess {...{currentUser,currentClaims,UserClaims:"Manage.Documents"}}/>
		
	);
}
const ReducedScreen = withReducer('expiryCountReducer', reducer, saga)(Employeesmodule);
export default ReducedScreen;
