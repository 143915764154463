import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { entriesDropDown, onlyNumberForNumberType } from '@helpers';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import Inputbox from '@pages/DesignSystem/Input-Field';
import EdenredSearch from '@sharedComponent/search-input';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { icons } from 'src/assets/icons';
import action from '../Employee/action';
import ErrorTable from '@sharedComponent/error-table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Toast from '@pages/DesignSystem/Toast';
import Table from '@pages/DesignSystem/Table/Table';
import ImageComponent from '@sharedComponent/image-component';

function MissingDetails({ checkEmiratesNumber, checkMobileNumber, selectedEmployeeList, setSelectedEmployeeList }) {
	let [pageSize, setPageSize] = useState(10);
	let [pageNumber, setPageNumber] = useState(1);
	let [searchedText, setSearchedText] = useState('');
	const [employeeList, setEmployeeList] = useState([]);

	const history = useHistory();
	const dispatch = useDispatch();

	const { missingEmpLoading, missingEmpList, errorData, successFlag } = useSelector((state) => state?.unemploymentReducer) || {}

	useEffect(() => {
		setEmployeeList(missingEmpList?.missingDetail);
		setSelectedEmployeeList([]);
	}, [missingEmpList]);

	useEffect(() => {
		dispatch(action.creators.getMissingEmployeeList(pageSize, pageNumber, searchedText));
	}, [pageSize, pageNumber, searchedText]);

	const onChangeEmployeeList = (event, rowData) => {
		employeeList.map((company) => {
			if (selectedEmployeeList?.find((selected) => selected?.employeeId === rowData?.employeeId)) {
				let value = event.target.value;
				rowData[event.target.name] = value;
				let key = event.target.name;
				if (value > 0) {
					setSelectedEmployeeList((prevState) => {
						const newState = prevState?.map((obj) => {
							if (obj?.employeeId === rowData?.employeeId) {
								return { ...obj, [key]: value };
							}
							return obj;
						});
						return newState;
					});
				} else {
					const selectedArr = selectedEmployeeList.filter((record) => {
						return record.employeeId !== rowData.employeeId;
					});
					setSelectedEmployeeList(selectedArr);
				}
			} else if (company.employeeId === rowData.employeeId) {
				let value = event.target.value;
				rowData[event.target.name] = value;
				setSelectedEmployeeList((preState) => [...preState, rowData]);
			}
		});
	};

	const handleRowSelect = (isChecked, row) => {
		if (!isChecked) {
			const arr = selectedEmployeeList?.filter((company) => {
				return company.employeeId !== row.employeeId;
			});
			setSelectedEmployeeList(arr);
		} else {
			setSelectedEmployeeList((preState) => [...preState, row]);
		}
	};

	const getIsSelectAll = () => {
		return selectedEmployeeList?.length === employeeList?.length;
	};

	const handleSelectedAll = (isChecked) => {
		if (isChecked) {
			setSelectedEmployeeList(employeeList);
		} else {
			setSelectedEmployeeList([]);
		}
	};
	const columns = [
		{
			sortDirections: ['ascend'],
			fixed: 'left',
			align: 'center',
			scroll: 'hidden',
			title: (
				<Checkbox
					{...{
						checked: selectedEmployeeList.length > 0 && getIsSelectAll(),
						size: 16,
						borderWidth: 1,
						borderRadius: 4,
						onChange: (isChecked) => handleSelectedAll(isChecked),
					}}
				/>
			),
			dataIndex: 'isSelected',
			width: '5%',
			render: (isSelected, row) => {
				return (
					<Col>
						<Checkbox
							{...{
								size: 16,
								borderWidth: 1,
								borderRadius: 4,
								checked: selectedEmployeeList?.findIndex((data) => data.employeeId === row.employeeId) > -1,
								onChange: (isChecked) => handleRowSelect(isChecked, row),
							}}
						/>
					</Col>
				);
			},
		},
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'employeeName',
			title: 'Name',
			dataIndex: 'employeeName',
			width: '25%',
			hidden: false,
			sort: true,
			activeTab: true,
			render: (val, rowData) => {
				return <Col
					style={{
						display: 'grid',
						gap: '2px'
					}}
				>
					<span style={{
						textDecoration: 'underline',
						color: '#2B769C',
						cursor: 'pointer',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
						{val}
					</span>
					<span style={{
						color: '#181919',
						fontSize: ' 10px',
						fontStyle: 'normal',
						fontWeight: 300,
						lineHeight: '12px', /* 120% */
					}}>
						{rowData?.employeeId || ''}
					</span>
				</Col>
			},
		},
		{
			key: 'emiratesId',
			title: 'Emirates ID',
			dataIndex: 'emiratesId',
			width: '15%',
			hidden: false,
			activeTab: true,
			render: (val, row) => {
				return (
					<>
						<Inputbox
							label=""
							name="emiratesId"
							onChange={(e) => {
								if (e.target?.value?.length <= 15) {
									onChangeEmployeeList(e, row);
								}
							}}
							value={val ? val : ''}
							type="number"
							onKeyDown={(event) => onlyNumberForNumberType(event)}
							placeholder="Enter Here"
						/>
						{checkEmiratesNumber(val) ? (
							<span className="error-message-text">Emirates ID should start with 784 and should 15 digit.</span>
						) : (
							''
						)}
					</>
				);
			},
		},
		{
			key: 'mobileNumber',
			title: 'Mobile Number',
			dataIndex: 'mobileNumber',
			width: '20%',
			hidden: false,
			activeTab: true,
			render: (val, row) => {
				return (
					<>
						<Inputbox
							label=""
							placeholder="009715XX"
							name="mobileNumber"
							onChange={(e) => {
								if (e.target?.value?.length <= 14) {
									onChangeEmployeeList(e, row);
								}
							}}
							value={val ? val : ''}
							type="number"
							onKeyDown={(event) => onlyNumberForNumberType(event)}
						/>
						{checkMobileNumber(val) ? (
							<span className="error-message-text">Mobile Number should start with 009715 and should 14 digit.</span>
						) : (
							''
						)}
					</>
				);
			},
		},
	];

	const handleViewCardTable = (res) => setPageSize(res.value);

	return (
		<div className="single-update">
			<Toast
				color="#8EF9B3"
				visible={successFlag}
				clearPopUp={() => {
					dispatch(action.creators.setSuccessToastFlag(false));
				}}>
				Saved Successfully.
			</Toast>
			<Row className="single-update-content">
				<div className="search-and-show-count-container center">
					<div style={{ width: '70%', display: 'flex', gap: '25px' }}>
						<div style={{ marginRight: '30px', minWidth: '55%' }}>
							<EdenredSearch
								value={searchedText}
								placeholder="Search by Name, Employee ID..."
								onChange={(event) => setSearchedText(event.target.value)}
							/>
						</div>
						<EdenredButtons
							style={{ width: 'fit-content' }}
							leftIcon={<ImageComponent src={icons.not_focus.ContentEdit} />}
							{...{
								btnType: 'secondary',
							}}
							onClick={() => history.push('/un-employment-insurance/bulk-update')}>
							Update bulk with Excel
						</EdenredButtons>
					</div>
					<div className="entries-select-container center">
						<ETypo b2 light color="#484B52" style={{ paddingRight: '10px' }}>
							Show
						</ETypo>
						<MultiSelect
							{...{
								Option: entriesDropDown,
								value: pageSize,
								dropDownValue: 'value',
								searchVisibility: false,
								onChange: (page) => handleViewCardTable(page, 'pageSize'),
								checkboxIcon: (
									<span className="entries_check_box">
										<img src={icons.not_focus.tick} alt="check" />
									</span>
								),
							}}
						/>
						<ETypo b2 light color="#484B52" style={{ paddingLeft: '10px' }}>
							Entries
						</ETypo>
					</div>
				</div>
				{errorData?.length > 0 ? (
					<>
						<div
							style={{
								backgroundColor: '#FFF6F5',
								borderRadius: '8px',
								minHeight: '52px',
								display: 'flex',
								alignItems: 'center',
								margin: '15px 0',
								width: '100%',
							}}>
							<div style={{ padding: '15px' }}>
								<img src={icons?.focus_state.ErrorIcon} alt="error" />
							</div>
							<div>Please correct the following errors.</div>
						</div>
						<ErrorTable data={errorData} isShowAction={false} />
					</>
				) : (
					''
				)}
				<Table columns={columns} data={employeeList} tableLoading={missingEmpLoading} />

				<div className="emp-card-pagination-container">
					<ETypo b3 light color="#484B52">
						Showing {employeeList?.length} out of {missingEmpList?.missingDetailCount} Employees
					</ETypo>
					<Pagination
						{...{
							currentPage: pageNumber,
							totalCount: missingEmpList?.missingDetailCount,
							pageSize: pageSize,
							onPageChange: (page) => setPageNumber(page),
						}}
					/>
				</div>
			</Row>
		</div>
	);
}

export default MissingDetails;
