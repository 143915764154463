import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { icons } from 'src/assets/icons';
import PropTypes from 'prop-types';
import ContentManager from './ContentManager';
import useOnClickOutside from '@pages/payroll-plus-new/components/useOnClickOutside';
import Tooltip from '../Tool-Tip';
import { options } from '../Json/DesignSystem';
import './MultiSelect.scss';

const MultiSelect = ({
	multiSelect = false,
	selectedValue,
	onChange,
	Option = [],
	searchHolder = 'Search options',
	searchVisibility,
	icon,
	background,
	dropDownValue = '',
	dropDownSubValue = '',
	value = '',
	checkboxIcon = null,
	showIcon = true,
	placeholder = 'Select',
	isError = false,
	loading = false,
	disabled = false,
	dateFormat = '',
	showBgValue = false,
	prefix = '',
	compareDropDownValue = '',
	showTooltip = false,
	toolTipText = '',
	label = '',
	required = false,
	showOptionStatus = false,
	justifyOption = false,
	onClear = () => {},
}) => {
	const ref = useRef(null);
	const ref1 = useRef(null);
	const [dropdownClicked, setDropdownClicked] = useState(false);
	const [count, setCount] = useState(0);
	const [clearAll, setClearAll] = useState(false);
	const [filter, setFilter] = useState([]);
	const [duplicateFilter, setDuplicateFilter] = useState([]);
	const [searchKey, setSearchKey] = useState('');
	const [finalFilter, setFinalFilter] = useState([]);
	const [selectAll, setSelectAll] = useState({
		name: 'Select all',
		id: '4eaefb40-458b-ed11-9d78-0022486a0412',
		isSelected: false,
		key: 'select-all',
	});
	useOnClickOutside(ref, () => {
		if (dropdownClicked) setDropdownClicked(false);
	});
	useEffect(() => {
		// We check the date format for the employee view page.
		//If we change the monthPicker and edit some data, and then click "Save Changes," we may not get the salary month in the monthPicker if the format is different.
		//Therefore, we need to check this condition here.
		const data = Option?.map((items, index) => {
			if (
				(value && moment(items?.[compareDropDownValue || dropDownValue]).format(dateFormat) === value) ||
				items?.[compareDropDownValue || dropDownValue] === value
			) {
				return {
					...items,
					key: index,
					isSelected: true,
				};
			} else {
				return {
					...items,
					key: index,
					isSelected: false,
				};
			}
		});
		const finalSelectedData = data?.filter((items) => items?.isSelected);
		setFinalFilter(finalSelectedData);
		setFilter(data);
		setDuplicateFilter(data);
	}, [Option, value]);
	useEffect(() => {
		const count1 = filter?.filter((value) => value?.isSelected)?.length;
		setCount(count1);
		if (count1 > 0) {
			setClearAll(true);
		} else {
			setClearAll(false);
		}
	}, [filter]);
	const handleDropdown = (data, item) => {
		selectedValue && selectedValue(data);
		let newOptions;
		let _selectAll = {
			...selectAll,
		};
		if (multiSelect) {
			if (data.key === 'select-all') {
				_selectAll = {
					...selectAll,
					isSelected: !selectAll.isSelected,
				};
				newOptions = filter?.map((value) => {
					return {
						...value,
						isSelected: !selectAll.isSelected,
					};
				});
			} else {
				if (selectAll.isSelected === true) {
					_selectAll = {
						...selectAll,
						isSelected: false,
					};
				}
				newOptions = filter?.map((value) => {
					if (value?.key === data?.key) {
						if (!value?.isSelected || !data?.isSelected) {
							return {
								...value,
								isSelected: true,
							};
						} else {
							return {
								...value,
								isSelected: false,
							};
						}
					}
					return value;
				});
			}
		} else {
			newOptions = filter?.map((value) => {
				if (value?.key === data?.key) {
					return {
						...value,
						isSelected: true,
					};
				} else {
					return {
						...value,
						isSelected: false,
					};
				}
			});
		}
		const finalSelectedData = newOptions?.filter((items) => items?.isSelected);
		setFinalFilter(finalSelectedData);
		setFilter(newOptions);
		if (multiSelect) {
			setSelectAll({ ..._selectAll });
		}
		if (multiSelect) {
			onChange && onChange(finalSelectedData);
		} else {
			onChange && onChange(data);
		}
	};

	const handleDropdownClick = (e) => {
		e.stopPropagation();
		setDropdownClicked(!dropdownClicked);
	};

	const filterSearch = (search) => {
		setSearchKey(search);
		if (search) {
			const filtered = filter?.filter((data) => {
				const employeeName = data?.[dropDownValue]?.toString()?.toLowerCase();
				const employeeId = data?.[dropDownSubValue]?.toString()?.toLowerCase();
				const searchValue = search?.toString()?.toLowerCase();
				if (search && (employeeName?.includes(searchValue) || employeeId?.includes(searchValue))) {
					return data;
				}
			});
			setFilter(filtered);
		} else {
			setFilter(duplicateFilter);
		}
	};
	const getBorderColor = () => {
		if (finalFilter?.length > 0 && !isError && value?.toString()?.toLowerCase() !== 'none') {
			return '#9A9EA6';
		} else if (finalFilter?.length > 0 && isError) {
			return '#FF9691';
		} else if (disabled) {
			return '#F7F8FA';
		} else if (value?.toString()?.toLowerCase() === 'none') {
			return '#DFE1E6';
		} else if (finalFilter?.length > 0) {
			return '#dfe1e6';
		} else {
			return '#dfe1e6';
		}
	};
	const handleClose = (item) => {
		const newOptions = filter?.map((value) => {
			if (value?.key === item?.key) {
				return {
					...value,
					isSelected: false,
				};
			}
			return value;
		});
		const filtered = finalFilter?.filter((items) => items?.key !== item?.key);
		setFinalFilter(filtered);
		setFilter(newOptions);
		onChange(filtered);
	};

	const handleCloseAll = () => {
		const data = Option?.map((items, index) => {
			let newObj = {
				...items,
				key: index,
				isSelected: false,
			};
			return newObj;
		});
		setFinalFilter([]);
		setFilter(data);
		if (multiSelect === true) {
			setSelectAll({ ...selectAll, isSelected: false });
		}
		onClear();
	};

	useEffect(() => {
		let selectId = document.getElementById('selected');
		if (selectId) {
			selectId.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [dropdownClicked]);

	const Component = showTooltip && disabled ? Tooltip : Fragment;
	return (
		<div className="multi-select-dropdown-component">
			{label && (
				<div className="multiSelect_dropdown_label">
					{label}
					{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
				</div>
			)}
			<div className="multiSelect-dropdown-container">
				<div className="dropdown_header">
					<Component content={toolTipText} style={{ width: '100%' }}>
						<div
							ref={(ref, ref1)}
							onClick={(e) => !loading && !disabled && handleDropdownClick(e)}
							className={`dropdown_inner ${disabled ? 'dropdown_disabled' : ''}`}
							style={dropdownClicked ? { border: '1px solid #71b0d0' } : { border: `1px solid ${getBorderColor()}` }}>
							<div className="dropDown-menu">
								{prefix && prefix}
								{finalFilter?.length > 0 ? (
									finalFilter?.map((item, index) =>
										index < 2 ? (
											<>
												<div
													key={index}
													className={`dropdown_default ${showBgValue ? 'dropdown_default_bg' : ''} ${
														item?.isActive ? 'dropdown_status_icon_flex' : ''
													}`}
													style={{ background: background }}>
													<span
														className={` ${
															item?.[dropDownValue]?.toString()?.toLowerCase() === 'none'
																? 'dropdown_none'
																: 'dropdown_inner_list_main'
														}`}>
														{dateFormat ? moment(item?.[dropDownValue]).format(dateFormat) : item?.[dropDownValue]}
													</span>
													{item?.[dropDownSubValue] &&
														(item?.isActive ? (
															<div className="dropdown_status_icon"></div>
														) : (
															<span className={`dropdown_inner_list_sub`}>{item?.[dropDownSubValue]}</span>
														))}
													{multiSelect && (
														<img
															className="dropdown_inner_image"
															src={icons.not_focus.crossIcon}
															alt="close"
															onClick={() => handleClose(item, index)}
														/>
													)}
												</div>
											</>
										) : (
											''
										)
									)
								) : (
									<div className="dropdown_placeHolder">{placeholder}</div>
								)}
								{count > 2 && <span className="selectCount"> + {count - 2}</span>}
							</div>
							<div role="button" className="dropdown_arrow" onClick={(e) => !loading && !disabled && handleDropdownClick(e)}>
								{multiSelect && clearAll && (
									<span className="dropdown-clear" onClick={() => handleCloseAll()}>
										Clear
									</span>
								)}
								{loading ? (
									<div className="dropdown_loader" />
								) : (
									<img
										className="arrow-inner"
										src={dropdownClicked ? icons.not_focus.up : icons.not_focus.down}
										alt="dropDown arrow"
									/>
								)}
							</div>
						</div>
					</Component>
					{dropdownClicked ? (
						<div ref={ref} className="dropdown_list" style={{ width: `100%`, marginTop: '12px' }}>
							{searchVisibility && (
								<div className="dropdown_list_search_main">
									<img src={icons.not_focus.searchInput} alt="search-input" />
									<input
										placeholder={searchHolder}
										spellCheck={false}
										value={searchKey}
										onChange={(e) => filterSearch(e?.target.value)}
										className="dropdown"
									/>
								</div>
							)}
							<div className="dropdown_inner_list" onClick={(e) => !multiSelect && handleDropdownClick(e)}>
								<>
									{multiSelect === true && (
										<ContentManager
											{...{
												data: selectAll,
												handleDropdown,
												icon,
												dropDownValue,
												dropDownSubValue,
												checkboxIcon,
												showIcon,
												dateFormat,
												showOptionStatus,
												justifyOption,
											}}
										/>
									)}
									{filter?.map((data) => {
										return (
											<div id={value === data?.salaryDate ? 'selected' : ''} key={data?.key}>
												<ContentManager
													{...{
														data,
														handleDropdown,
														icon,
														dropDownValue,
														dropDownSubValue,
														checkboxIcon,
														showIcon,
														dateFormat,
														showOptionStatus,
														justifyOption,
													}}
												/>
											</div>
										);
									})}
								</>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};
MultiSelect.defaultProps = {
	Option: [],
	searchVisibility: true,
	icon: <img src={icons.not_focus.arrowRight} alt="arrow" />,
};

export default MultiSelect;

MultiSelect.propTypes = {
	selectedValue: PropTypes.string,
	onChange: PropTypes.string,
	Option: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	searchVisibility: PropTypes.bool,
};
