import React from 'react';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { DASH_BOARD_CONSTANT } from '@constants/app-constants';
import EdenredButton from '@sharedComponent/button';
import { userClaims } from '@constants/index';
import EdenredTypo from '@sharedComponent/typography';
import { checkWithClaims } from '@helpers';
import { topFiftyClients } from '../../top-50-clients';

const CutOffTimingsCard = ({ currentUser, corporateDetails }) => {
	const hrClaims = (currentUser && currentUser?.hrClaims) || [];
	const payrollClaims = (currentUser && currentUser?.payrollClaims) || [];
	const teamClaims = (currentUser && currentUser?.teamClaims) || [];

	const claims = [...hrClaims, ...payrollClaims, ...teamClaims];
	const checkIsTopFifty = topFiftyClients.includes(corporateDetails?.corporateId);
	const staticPayoutTimingsForTopFiftyClients = [
		{
			title: 'WPS Cut Off',
			time: (
				<>
					4:30 PM <span>(Mon-Sat)</span>
				</>
			),
		},
		{
			title: 'Non WPS Cut Off',
			time: (
				<>
					4:30 PM <span>(Mon-Sat)</span>
				</>
			),
		},
	];
	const staticPayoutTimings = [
		{
			title: 'WPS Cut Off',
			time: (
				<>
					4:30 PM <span>(Mon-Sat)</span>
				</>
			),
		},
		{
			title: 'Non WPS Cut Off',
			time: (
				<>
					4:30 PM <span>(Mon-Sat)</span>
				</>
			),
		},
	];
	const verifierUserName = currentUser && currentUser?.user?.userName;

	return (
		<Col xl={7} lg={12} md={12} sm={24} className="wps_nonwps_timing_details_card_column">
			<Card className="wps_nonwps_timing_details_card">
				{!checkWithClaims(claims, userClaims.MANAGE_ROLE) ? (
					<>
						<Col xl={24} lg={24} md={24} sm={24}>
							<Row className="wps_nonwps_timing_details_row">
								{checkIsTopFifty &&
									staticPayoutTimingsForTopFiftyClients?.map((timing, index) => (
										<Col xl={12} lg={12} md={12} className="wps_nonwps_timing_details_column" key={index}>
											<EdenredTypo className="wps_nonwps_title">{timing?.title}</EdenredTypo>
											<EdenredTypo className="wps_nonwps_timing">{timing?.time}</EdenredTypo>
										</Col>
									))}
								{!checkIsTopFifty &&
									staticPayoutTimings?.map((timing, index) => (
										<Col xl={12} lg={12} md={12} className="wps_nonwps_timing_details_column" key={index}>
											<EdenredTypo className="wps_nonwps_title">{timing?.title}</EdenredTypo>
											<EdenredTypo className="wps_nonwps_timing">{timing?.time}</EdenredTypo>
										</Col>
									))}
							</Row>
							{checkWithClaims(claims, userClaims.MANAGE_PAYROLL) && (
								<Col className="view_details_button_row">
									<p className="Click_here_to_modify">
										<Link to="/payroll/timings">
											<EdenredButton type="outline-g">View Details</EdenredButton>
										</Link>
									</p>
								</Col>
							)}
						</Col>
					</>
				) : (
					<Row className="change-verifier">
						<Col xl={24} lg={24} md={24} sm={24}>
							<div>
								<div className="approval_setting_text">
									<p className="change-approval-settings">Change your approval settings</p>
									<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
										<p className="change-approval-content">{DASH_BOARD_CONSTANT?.APPROVER_CONTENT}</p>
									</div>
								</div>
								<p className="Click_here_to_modify">
									<Link to={{ pathname: '/user_role/' + verifierUserName, state: { claims } }}>
										<EdenredButton type="outline-g">Click Here To Modify</EdenredButton>
									</Link>
								</p>
							</div>
						</Col>
					</Row>
				)}
			</Card>
		</Col>
	);
};

export default CutOffTimingsCard;
