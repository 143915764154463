import { Descriptions } from 'antd';
import AdditionActions from '../addition/action';
import AllowanceActions from '../allowance/action';
import DeductionActions from '../deduction/action';
import EmployeeUploadActions from '../UploadPayrollDetails/action';
import AnnouncementActions from '../Announcements/action';
import DocumentsExpiryActions from '../DocumentsExpiry/action';
import DepartmentActions from '../Leaves/Departments/action';
import uploadLeaveActions from '../Leaves/UploadLeaveDetails/action';
import HrFeatureAccessActions from '../HrFeatureAccess/action';
import UploadLeaveActions from '../Leaves/UploadLeaveDetails/action';
import Actions from '../HrFeatureAccess/action';

const initialState = {
	allowance: {
		loading: false,
		data: [],
	},
	addition: {
		loading: false,
		data: [],
	},
	deduction: {
		loading: false,
		data: [],
	},
	employeePayrollUpload: {
		isEmployeeUploadLoading: false,
		employeeUploadDetails: [],
		downloadTemplate: {
			loading: false,
			data: [],
		},
	},
	announcementList: {
		loading: false,
		message: [],
	},
	documentsExpiry: {
		downloadTemplate: {
			loading: false,
			data: [],
		},
		uploadDocument: {
			loading: false,
			data: [],
		},
	},
	department: {
		loading: false,
		data: [],
	},
	leaves: {
		isFileUploaded: false,
		downloadTemplate: {
			loading: false,
			data: [],
		},
		uploadDocument: {
			loading: false,
			data: [],
		},
	},
	corporateSettings: [],
	loading: false,
	isHrSetupSkipped: false,
	isEmployeeAllowanceStatus: false,
	isEmployeeNotUpload: false,
};

const hrSetupReducer = (state = initialState, action) => {
	switch (action.type) {
		case AllowanceActions.types.PUT_ALLOWANCE_LOADING: {
			return {
				...state,
				allowance: {
					...state.allowance,
					loading: action.payload,
				},
			};
		}
		case AllowanceActions.types.GET_ALLOWANCE_DATA: {
			return {
				...state,
				allowance: {
					...state.allowance,
					data: action.response,
				},
			};
		}

		case AllowanceActions.types.PUT_ALLOWANCE_ERROR: {
			return {
				...state,
				allowance: {
					...state.allowance,
					error: action.error,
				},
			};
		}
		case AdditionActions.types.PUT_ADDITION_LOADING: {
			return {
				...state,
				addition: {
					...state.addition,
					loading: action.payload,
				},
			};
		}
		case AdditionActions.types.GET_ADDITION_DATA: {
			return {
				...state,
				addition: {
					...state.addition,
					data: action.response,
				},
			};
		}
		case AdditionActions.types.PUT_ADDITION_ERROR: {
			return {
				...state,
				addition: {
					...state.addition,
					error: action.error,
				},
			};
		}
		case DeductionActions.types.GET_DEDUCTION_DATA: {
			return {
				...state,
				deduction: {
					...state.deduction,
					data: action.response,
				},
			};
		}
		case DeductionActions.types.PUT_DEDUCTION_LOADING: {
			return {
				...state,
				deduction: {
					...state.deduction,
					loading: action.payload,
				},
			};
		}
		case DeductionActions.types.PUT_DEDUCTION_ERROR: {
			return {
				...state,
				deduction: {
					...state.deduction,
					error: action.error,
				},
			};
		}
		case EmployeeUploadActions.types.EMPLOYEE_PAYROLL_UPLOAD_LOADING: {
			return {
				...state,
				employeePayrollUpload: {
					...state.employeePayrollUpload,
					isEmployeeUploadLoading: action.payload,
				},
			};
		}
		case EmployeeUploadActions.types.EMPLOYEE_PAYROLL_UPLOAD_SUCCESS: {
			return {
				...state,
				employeePayrollUpload: {
					...state.employeePayrollUpload,
					employeeUploadDetails: action?.res,
				},
			};
		}
		case EmployeeUploadActions.types.EMPLOYEE_PAYROLL_UPLOAD_FAIL: {
			return {
				...state,
				employeePayrollUpload: {
					...state.employeePayrollUpload,
					employeeUploadDetails: [],
				},
			};
		}
		case EmployeeUploadActions.types.EMP_RESET_DOCUMENT: {
			return {
				...state,
				employeePayrollUpload: {
					...state.employeePayrollUpload,
					employeeUploadDetails: [],
				},
			};
		}
		case EmployeeUploadActions.types.SET_EMPLOYEE_UPLOAD_DOCUMENT: {
			const { payload } = action;
			console.log(payload, Object.keys(payload), 'payload');
			if (Object.keys(payload)[0] === 'isEmployeeAllowanceStatus') {
				return {
					...state,
					isEmployeeAllowanceStatus: payload?.isEmployeeAllowanceStatus,
				};
			} else if (Object.keys(payload)[0] === 'isEmployeeNotUpload') {
				return {
					...state,
					isEmployeeNotUpload: payload?.isEmployeeNotUpload,
				};
			} else {
				return state;
			}
		}
		case EmployeeUploadActions.types.PUT_PAYROLL_DOWNLOAD_TEMPLATE_LOADING: {
			return {
				...state,
				employeePayrollUpload: {
					...state.employeePayrollUpload,
					downloadTemplate: {
						...state.employeePayrollUpload.downloadTemplate,
						loading: action?.payload,
					},
				},
			};
		}
		case EmployeeUploadActions.types.GET_PAYROLL_DOWNLOAD_TEMPLATE_ERROR: {
			return {
				...state,
				employeePayrollUpload: {
					...state.employeePayrollUpload,
					downloadTemplate: {
						...state.employeePayrollUpload.downloadTemplate,
						error: action?.payload,
					},
				},
			};
		}
		case AnnouncementActions.types.PUT_ANNOUNCEMENT_UPLOAD_LOADING: {
			return {
				...state,
				announcementList: {
					...state.announcementList,
					loading: action.payload,
				},
			};
		}
		case AnnouncementActions.types.PUT_ANNOUNCEMENT_UPLOAD_FAIL: {
			return {
				...state,
				announcementList: {
					...state.announcementList,
					error: action?.error,
				},
			};
		}
		case AnnouncementActions.types.SET_ANNOUNCEMENT_DATA: {
			return {
				...state,
				announcementList: {
					...state.announcementList,
					message: [action?.res],
				},
			};
		}
		case AnnouncementActions.types.SET_ANNOUNCEMENT_ERROR: {
			return {
				...state,
				announcementList: {
					...state.announcementList,
					error: action?.error,
				},
			};
		}
		case DocumentsExpiryActions.types.GET_DOWNLOAD_TEMPLATE_DATA: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					downloadTemplate: {
						...state.documentsExpiry.downloadTemplate,
						data: action?.payload,
					},
				},
			};
		}
		case DocumentsExpiryActions.types.GET_DOWNLOAD_TEMPLATE_ERROR: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					downloadTemplate: {
						...state.documentsExpiry.downloadTemplate,
						error: action?.payload,
					},
				},
			};
		}
		case DocumentsExpiryActions.types.PUT_DOWNLOAD_TEMPLATE_LOADING: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					downloadTemplate: {
						...state.documentsExpiry.downloadTemplate,
						loading: action?.payload,
					},
				},
			};
		}
		case DocumentsExpiryActions.types.PUT_UPLOAD_LOADING: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					uploadDocument: {
						...state.documentsExpiry.uploadDocument,
						loading: action?.payload,
					},
				},
			};
		}
		case DocumentsExpiryActions.types.PUT_UPLOAD_VALIDATION_MESSAGE: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					uploadDocument: {
						...state.documentsExpiry.uploadDocument,
						data: action?.payload,
					},
				},
			};
		}
		case DocumentsExpiryActions.types.PUT_UPLOAD_ERROR: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					uploadDocument: {
						...state.documentsExpiry.uploadDocument,
						error: action?.payload,
					},
				},
			};
		}
		case DocumentsExpiryActions.types.PUT_UPLOAD_RESET: {
			return {
				...state,
				documentsExpiry: {
					...state.documentsExpiry,
					uploadDocument: {
						loading: false,
						error: '',
						data: [],
					},
				},
			};
		}
		case DepartmentActions.types.PUT_DEPARTMENT_LOADING: {
			return {
				...state,
				department: {
					...state.department,
					loading: action.payload,
				},
			};
		}
		case DepartmentActions.types.GET_DEPARTMENT_DATA: {
			return {
				...state,
				department: {
					...state.department,
					data: action.response,
				},
			};
		}

		case DepartmentActions.types.PUT_DEPARTMENT_ERROR: {
			return {
				...state,
				department: {
					...state.department,
					error: action.error,
				},
			};
		}

		case uploadLeaveActions.types.SET_IS_FILE_UPLOADED: {
			return {
				...state,
				leave: {
					...state.leave,
					isFileUploaded: action.payload,
				},
			};
		}
		case HrFeatureAccessActions.types.SET_HR_SETUP_STATUS_SKIPPED: {
			return {
				...state,
				isHrSetupSkipped: action.payload,
			};
		}
		// leaves
		case UploadLeaveActions.types.GET_DOWNLOAD_LEAVE_TEMPLATE_DATA: {
			return {
				...state,
				leaves: {
					...state.leaves,
					downloadTemplate: {
						...state.leaves.downloadTemplate,
						data: action?.payload,
					},
				},
			};
		}
		case UploadLeaveActions.types.GET_DOWNLOAD_LEAVE_TEMPLATE_ERROR: {
			return {
				...state,
				leaves: {
					...state.leaves,
					downloadTemplate: {
						...state.leaves.downloadTemplate,
						error: action?.payload,
					},
				},
			};
		}
		case UploadLeaveActions.types.PUT_DOWNLOAD_LEAVE_TEMPLATE_LOADING: {
			return {
				...state,
				leaves: {
					...state.leaves,
					downloadTemplate: {
						...state.leaves.downloadTemplate,
						loading: action?.payload,
					},
				},
			};
		}
		case uploadLeaveActions.types.PUT_UPLOAD_LEAVE_LOADING: {
			return {
				...state,
				leaves: {
					...state.leaves,
					uploadDocument: {
						...state.leaves.uploadDocument,
						loading: action?.payload,
					},
				},
			};
		}
		case uploadLeaveActions.types.PUT_UPLOAD_LEAVE_VALIDATION_MESSAGE: {
			return {
				...state,
				leaves: {
					...state.leaves,
					uploadDocument: {
						...state.leaves.uploadDocument,
						data: action?.payload,
					},
				},
			};
		}
		case uploadLeaveActions.types.PUT_UPLOAD_LEAVE_ERROR: {
			return {
				...state,
				leaves: {
					...state.leaves,
					uploadDocument: {
						...state.leaves.uploadDocument,
						error: action?.payload,
					},
				},
			};
		}
		case uploadLeaveActions.types.PUT_UPLOAD_LEAVE_RESET: {
			return {
				...state,
				leaves: {
					...state.leaves,
					uploadDocument: {
						loading: false,
						error: '',
						data: [],
					},
				},
			};
		}
		case uploadLeaveActions.types.SET_FILE_UPLOAD_STATUS: {
			return {
				...state,
				leaves: {
					...state.leaves,
					isFileUploaded: action?.payload,
				},
			};
		}
		case Actions.types.GET_CORPORATE_SETTINGS_DATA: {
			return {
				...state,
				corporateSettings: action.response,
			};
		}
		case Actions.types.PUT_CORPORATE_SETTINGS_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case Actions.types.PUT_CORPORATE_SETTINGS_ERROR: {
			return {
				...state,
				error: action.error,
			};
		}
		default:
			return state;
	}
};
export default hrSetupReducer;
