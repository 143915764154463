import React from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import HelpHeader from './HelpHeader';
import CardItemLink from 'src/pages/help/components/CardItemLink';
import ItemHeader from '@pages/help/components/ItemHeader';
import { images } from '@assets/img';
import { SITEMAP } from 'src/routes/sitemap';
import './help-faqs.scss';

const HelpFaqs = () => {
	const history = useHistory();

	const faqContents = [
		{
			url: SITEMAP.help.payroll,
			image: icons.focus_state.emptyWalletAdd,
			title: 'Payroll',
		},
		{
			url: SITEMAP.help.invoice,
			image: icons.focus_state.moneySlip,
			title: 'Invoices',
		},
		{
			url: SITEMAP.help.employee.index,
			image: icons.focus_state.userNewLogo,
			title: 'Employees',
		},
		{
			url: SITEMAP.help.labourLaws.index,
			image: images.penalities,
			title: 'UAE Labor Law & WPS',
		},
	];

	return (
		<Col className="help_section_container">
			<Row className="help_section_row">
				<ItemHeader title="Frequently Asked Questions" goback={() => history.push(SITEMAP.dashboard.index)} />
			</Row>
			<div className="help_section_contents">
				<Row className="help_section_row">
					<HelpHeader />
				</Row>
				<Row className="help_section_row">
					<CardItemLink items={faqContents} />
				</Row>
			</div>
		</Col>
	);
};
export default HelpFaqs;
