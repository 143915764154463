const SAVE_PROFILE_IMAGE_START = 'SAVE_PROFILE_IMAGE_START';
const SAVE_PROFILE_IMAGE_SUCCESS = 'SAVE_PROFILE_IMAGE_SUCCESS';
const SAVE_PROFILE_IMAGE_FAIL = 'SAVE_PROFILE_IMAGE_FAIL';

const DELETE_PROFILE_IMAGE_START = 'DELETE_PROFILE_IMAGE_START';
const DELETE_PROFILE_IMAGE_SUCCESS = 'DELETE_PROFILE_IMAGE_SUCCESS';
const DELETE_PROFILE_IMAGE_FAIL = 'DELETE_PROFILE_IMAGE_FAIL';

const SHOW_SUCCESS_SAVE_MSG = 'SHOW_SUCCESS_SAVE_MSG';

const saveProfileImageStart = (file) => {
	return {
		type: SAVE_PROFILE_IMAGE_START,
		file,
	};
};
const saveProfileImageSuccess = (response) => {
	return {
		type: SAVE_PROFILE_IMAGE_SUCCESS,
		response,
	};
};
const saveProfileImageFail = (error) => {
	return {
		type: SAVE_PROFILE_IMAGE_FAIL,
		error,
	};
};
const showSuccessSaveMsg = (flag) => {
	return {
		type: SHOW_SUCCESS_SAVE_MSG,
		flag,
	};
};

const deleteProfileImageStart = () => {
	return {
		type: DELETE_PROFILE_IMAGE_START,
	};
};
const deleteProfileImageSuccess = (response) => {
	return {
		type: DELETE_PROFILE_IMAGE_SUCCESS,
		response,
	};
};
const deleteProfileImageFail = (error) => {
	return {
		type: DELETE_PROFILE_IMAGE_FAIL,
		error,
	};
};

export default {
	types: {
		SAVE_PROFILE_IMAGE_START,
		SAVE_PROFILE_IMAGE_SUCCESS,
		SAVE_PROFILE_IMAGE_FAIL,

		DELETE_PROFILE_IMAGE_START,
		DELETE_PROFILE_IMAGE_SUCCESS,
		DELETE_PROFILE_IMAGE_FAIL,

		SHOW_SUCCESS_SAVE_MSG,
	},
	creators: {
		saveProfileImageStart,
		saveProfileImageSuccess,
		saveProfileImageFail,

		deleteProfileImageStart,
		deleteProfileImageSuccess,
		deleteProfileImageFail,

		showSuccessSaveMsg,
	},
};
