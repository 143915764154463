import React from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredCarousel from '../EdenredCarousel';
import { carouselObj1, carouselObj2, carouselObj3, carouselObj4 } from './silderContent';

const SlidersPrimary = () => {
	return (
		<div style={{ display: 'flex', flexWrap: 'wrap', gap: '80px' }}>
			<div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={1}>
						{carouselObj1?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={2}>
						{carouselObj1?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={3}>
						{carouselObj1?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={4}>
						{carouselObj1?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={5}>
						{carouselObj1?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
			</div>
			<div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={1}>
						{carouselObj2?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={2}>
						{carouselObj2?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={3}>
						{carouselObj2?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={4}>
						{carouselObj2?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
			</div>
			<div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={1}>
						{carouselObj3?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={2}>
						{carouselObj3?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={3}>
						{carouselObj3?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
			</div>
			<div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={1}>
						{carouselObj4?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
				<div style={{ marginTop: '40px' }}>
					<EdenredCarousel onlySlider={true} pagination={2}>
						{carouselObj4?.map((data) => (
							<div className="main_carousel">
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content1}
								</ETypo>
								<ETypo headerStyle={'h4'} fontWeightStyle={'regular'}>
									{data?.content2}
								</ETypo>
							</div>
						))}
					</EdenredCarousel>
				</div>
			</div>
		</div>
	);
};

export default SlidersPrimary;
