import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { propsAreEqual } from '@helpers';

const BarChart = ({ payroll }) => {
	console.log('payroll : ', payroll);
	useEffect(() => {
		const ctx = document.getElementById('myChart');
		Chart.defaults.global.legend.labels.usePointStyle = true;
		let temp = [];
		let arr = payroll?.map((data) => temp.push(data.totalWPS));
		// var total = 0;
		// for (var i in arr) {
		// 	total += temp[i];
		// }
		// let step = total / 6;
		// let rounded = Math.round(step / 100) * 100;
		new Chart(ctx, {
			type: 'bar',
			data: {
				labels: payroll?.map((data) => data.month),
				// labels: ['Jan 20', 'Feb', 'Mar', 'Apr', 'May 20', 'Jun', 'Jul', 'Aug', 'Seb', 'Oct', 'Nov', 'Dec'],
				datasets: [
					{
						label: 'WPS',
						data: payroll?.map((data) => data.totalWPS),
						backgroundColor: '#0E8AFF',
						hoverBackgroundColor: '#0E8AFF',
						hoverBorderWidth: 0,
						barPercentage: 0.5,
						barThickness: 8,
					},
					{
						label: 'Non WPS',
						data: payroll?.map((data) => data.totalNonWPS),
						backgroundColor: '#FF9691',
						hoverBackgroundColor: '#FF9691',
						hoverBorderWidth: 0,
						barThickness: 8,
						barPercentage: 0.5,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				events: ['click'], // TODO - IF YOU WANT THE TOOLTIP WHILE HOVER THEN IT WILL CAUSE ERROR (DATA MAY CHANGE WHILE HOVER )
				scales: {
					gridLines: {
						showBorder: false,
					},
					xAxes: [
						{
							offset: true,
							color: 'pink',
							ticks: {
								beginAtZero: false,
							},
							zeroLineColor: '#ffff',

							// stacked: true,
							gridLines: { display: false },
						},
					],
					yAxes: [
						{
							ticks: {
								// beginAtZero: true,
								// stepSize: rounded,
								// offset: true,
								// stepSize: 20.0,
								min: 0, // it is for ignoring negative step.
								beginAtZero: true,
								callback: function (value, index, values) {
									if (Math.floor(value) === value) {
										return value;
									}
								},
							},
							gridLines: {
								drawBorder: false,
							},

							// stacked: true,
							axisLabel: {
								margin: 10,
								textStyle: {
									fontSize: 14,
								},
							},
						},
					],
				},

				legend: {
					display: true,
					position: 'right',
					align: 'end',
					labels: {
						fontColor: '#333',
					},
				},
			},
		});
	}, [payroll]);

	return (
		<>
			<div className="App">
				<canvas id="myChart" />
			</div>
		</>
	);
};

export default React.memo(BarChart, propsAreEqual);
