import Action from '../action.js';
import ToastAction from '@pages/DesignSystem/StatusToast/actions';
import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* announcementUploadService(action) {
	yield put(Action.creators.putAnnouncementUploadLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Announcement/${corporateId}/announcement`;
		const res = yield call(Axios.post, uri, action.payload);
		yield put(Action.creators.putAnnouncementUploadLoading(false));
		yield put(ToastAction.creators.putSuccessStatusToast({ message: 'Your announcement was sent successfully' }));
		yield put(Action.creators.getAnnouncement());
	} catch (err) {
		yield put(Action.creators.putAnnouncementUploadFail(err));
		yield put(Action.creators.putAnnouncementUploadLoading(false));
	}
}
export function* setAnnouncementService() {
	yield put(Action.creators.putAnnouncementUploadLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Announcement/${corporateId}/announcementhistory`;
		const res = yield call(Axios.get, uri);
		yield put(Action.creators.putAnnouncementUploadLoading(false));
		yield put(Action.creators.setAnnouncementData(res.data));
	} catch (err) {
		yield put(Action.creators.setAnnouncementError(err));
		yield put(Action.creators.putAnnouncementUploadLoading(false));
	}
}
export default function* announcementWatcher() {
	yield takeEvery(Action.types.PUT_ANNOUNCEMENT_UPLOAD, announcementUploadService);
	yield takeEvery(Action.types.GET_ANNOUNCEMENT, setAnnouncementService);
}
