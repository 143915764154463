import { createSelector } from 'reselect';
import { initState } from './profile-reducer';

const counterReducer = (state) => state.counterReducer || initState;

const nationalityData = createSelector(counterReducer, (cnter) => cnter.nationalityData);
const isInviteLoading = createSelector(counterReducer, (cnter) => cnter.isInviteLoading);
const inviteSuccess = createSelector(counterReducer, (cnter) => cnter.inviteSuccess);
const isEmailUpdateLoad = createSelector(counterReducer, (cnter) => cnter.isEmailUpdateLoad);
const emailUpdated = createSelector(counterReducer, (cnter) => cnter.emailUpdated);
const emailConformation = createSelector(counterReducer, (cnter) => cnter.emailConformation);
export default {
	nationalityData,
	isInviteLoading,
	inviteSuccess,
	isEmailUpdateLoad,
	emailUpdated,
	emailConformation,
};
