import { createSelector } from 'reselect';

import { initState } from './reducer';

const expiryCountReducer = (state) => state.expiryCountReducer || initState;
const loading = createSelector(expiryCountReducer, (cnter) => cnter.loading);
const employeedetail = createSelector(expiryCountReducer, (cnter) => cnter.employeedetail);
const error = createSelector(expiryCountReducer, (cnter) => cnter.error);
const downloadLoading = createSelector(expiryCountReducer, (cnter) => cnter.downloadLoading);
const downloadDetail = createSelector(expiryCountReducer, (cnter) => cnter.downloadDetail);
const downloadError = createSelector(expiryCountReducer, (cnter) => cnter.downloadError);
const filterLoading = createSelector(expiryCountReducer, (cnter) => cnter.filterLoading);
const filterDetail = createSelector(expiryCountReducer, (cnter) => cnter.filterDetail);
const documentExpiryList = createSelector(expiryCountReducer, (cnter) => cnter.documentExpiryList);
const oldFilterDetail = createSelector(expiryCountReducer, (cnter) => cnter.oldFilterDetail);
const filterError = createSelector(expiryCountReducer, (cnter) => cnter.filterError);
const saveLoading = createSelector(expiryCountReducer, (cnter) => cnter.saveLoading);
const saveDetail = createSelector(expiryCountReducer, (cnter) => cnter.saveDetail);
const saveError = createSelector(expiryCountReducer, (cnter) => cnter.saveError);

const approvedValidEmploye = createSelector(expiryCountReducer, (cnter) => cnter.approvedValidEmploye?.emiratesDocumentStagings);
const submitValidloading = createSelector(expiryCountReducer, (cnter) => cnter.submitValidloading);
const getApprovedValidloading = createSelector(expiryCountReducer, (cnter) => cnter.getApprovedValidloading);

const submitSuccessPopup = createSelector(expiryCountReducer, (cnter) => cnter.submitSuccessPopup);
const popupVisible = createSelector(expiryCountReducer, (cnter) => cnter.popupVisible);
const excelDownloadLoading = createSelector(expiryCountReducer, (cnter) => cnter.excelDownloadLoading);
const pdfDownloadLoading = createSelector(expiryCountReducer, (cnter) => cnter.pdfDownloadLoading);

export default {
	expiryCountReducer,
	loading,
	employeedetail,
	error,
	downloadLoading,
	downloadDetail,
	downloadError,
	filterLoading,
	filterDetail,
	documentExpiryList,
	filterError,
	saveLoading,
	saveDetail,
	approvedValidEmploye,
	getApprovedValidloading,
	submitValidloading,
	submitSuccessPopup,
	saveError,
	excelDownloadLoading,
	pdfDownloadLoading,
	popupVisible,
	oldFilterDetail,
};
