import React, { useState } from 'react';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import Toast from '../index';

const ToastOne = () => {
	return (
		<>
			<Toast color="#181919" timeout={3000} heart={true} size="240px" icon={<ImageComponent src={images.toasterheart} />}>
				Lorem ipsum dolor sit
			</Toast>

			<Toast color="#181919" timeout={3000} heart={false}>
				Lorem ipsum dolor sit
			</Toast>
		</>
	);
};

export default ToastOne;
