import { Col, Row, Popover } from 'antd';
import React, { Fragment } from 'react';
import ImageComponent from '@sharedComponent/image-component';
import { Link } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import './step-component.scss';

const EdenredPafStep = ({ contents = [], activeStep = '1.0', rejectedCount = 1, employeesCount = 1, uploadId, cardDetail }) => {
	const allEmployeeFailed = employeesCount === rejectedCount;
	const [value1, value2] = activeStep.split('.');
	const activeStepFloat = parseFloat(activeStep);
	const activeParent = parseInt(value1);
	const activeChild = parseInt(value2);
	return (
		<Fragment>
			<Row className="step_container">
				{contents.map(({ date, content, processingContent }, parent) => {
					console.log('content', content);
					let parentProcessed = parent < activeParent || parseFloat(parent + 1) < activeStepFloat ? 'processed' : '';
					let parentProcessing = parent + 1 === activeParent && parseFloat(parent + 1) === activeStepFloat ? 'processing' : '';
					let isRejected = parentProcessed && parent + 1 === 4 && allEmployeeFailed;
					let image =
						parent + 1 < activeParent || (parseFloat(parent + 1) < activeStepFloat && !isRejected)
							? images.check
							: !parentProcessing && isRejected
							? images.error
							: images.process;
					return (
						<Col className="dot_content_container" key={parent}>
							<Row>
								<Col xl={24} className="all_dot_container">
									<Row className="flxr_spc">
										<Col xl={6} className="dot_container">
											<Col className={`initial_dot ${parentProcessed} ${parentProcessing} ${isRejected ? 'rejected' : ''}`}>
												{parent < activeParent && <ImageComponent src={image} />}
											</Col>
										</Col>
										{Array(3)
											.fill('')
											.map((v, child) => {
												let childProcessing =
													(child < activeChild && parent < activeParent) ||
													parseFloat(`${parent + 1}.${child + 1}`) < activeStepFloat
														? 'processed'
														: '';
												return (
													contents.length !== parent + 1 && (
														<Col key={child} xl={6} className="dot_container">
															<Col
																className={`dot ${childProcessing} ${isRejected ? 'rejected' : ''}`}
																style={isRejected ? { backgroundColor: '#dfe1e6' } : {}}></Col>
														</Col>
													)
												);
											})}
									</Row>
								</Col>
								<Col className="date_content">
									{/* {parentProcessed && !parentProcessing && !isRejected ? (
										<Col xl={24} className="date">
											{date}
										</Col>
									) : null} */}

									<Col xl={24} className="content" style={{ paddingRight: 20 }}>
										<span
											style={{
												color: parentProcessed || parentProcessing ? '#484b52' : '#dfe1e6',
											}}>
											{parentProcessing ? processingContent : content}
										</span>
									</Col>
								</Col>
							</Row>
						</Col>
					);
				})}
			</Row>
			<Row className="step_container_mob">
				{contents.map(({ date, content, processingContent }, parent) => {
					// let image = parent + 1 < activeParent || parseFloat(parent + 1) < activeStepFloat ? images.check : images.process;
					let parentProcessed = parent < activeParent || parseFloat(parent + 1) < activeStepFloat ? 'processed' : '';
					let parentProcessing = parent + 1 === activeParent && parseFloat(parent + 1) === activeStepFloat ? 'processing' : '';
					let isRejected = parentProcessed && parent + 1 === 4 && allEmployeeFailed;
					let image =
						parent + 1 < activeParent || (parseFloat(parent + 1) < activeStepFloat && !isRejected)
							? images.check
							: !parentProcessing && isRejected
							? images.error
							: images.process;

					return (
						<Col className="dot_content_container" key={parent}>
							<Row>
								<Col xl={24} sm={24} xs={24}>
									<Row>
										<Col xl={6} sm={6} xs={6} className="dot_container">
											<Col className={`initial_dot ${parentProcessed} ${parentProcessing} ${isRejected ? 'rejected' : ''}`}>
												{parent < activeParent && <ImageComponent src={image} />}
											</Col>
											{Array(3)
												.fill('')
												.map((v, child) => {
													let childProcessing =
														(child < activeChild && parent < activeParent) ||
														parseFloat(`${parent + 1}.${child + 1}`) < activeStepFloat
															? 'processed'
															: '';
													return (
														contents.length !== parent + 1 && (
															<Col key={child} xl={6} className="dot_container">
																<Col
																	className={`dot ${childProcessing} ${isRejected ? 'rejected' : ''}`}
																	style={isRejected ? { backgroundColor: '#dfe1e6' } : {}}></Col>
															</Col>
														)
													);
												})}
										</Col>
										<Col xl={18} sm={14} xs={14} className="date_content">
											{/* {parentProcessed && !parentProcessing && !isRejected ? (
												<Col xl={24} className="date">
													{date}
												</Col>
											) : null} */}
											{!isRejected && (
												<Col
													xl={24}
													className="content"
													style={{
														paddingTop: parentProcessed && !parentProcessing ? 0 : 8,
														color: parentProcessed || parentProcessing ? 'black' : '#dfe1e6',
													}}>
													<span>{parentProcessing ? processingContent : content}</span>
												</Col>
											)}
											{isRejected && (
												<Fragment>
													<Col xl={24} className="date" style={{ color: '#E60A14', fontSize: 16 }}>
														Salary Processing Failed
													</Col>
													<Col xl={24} className="content">
														<span
															style={{
																color: parentProcessed || parentProcessing ? '#484b52' : '#dfe1e6',
															}}>
															File rejected by WPS
														</span>
													</Col>
												</Fragment>
											)}
											{rejectedCount !== 0 && !parentProcessing && parent + 1 === 4 && activeParent === 4 && !isRejected && (
												<Col xl={24} className="content" style={{ paddingRight: 10 }}>
													<div
														style={{
															color: '#E60A14',
															fontSize: 12,
															lineHeight: 1.5,
															paddingTop: 5,
														}}>
														{/* {parentProcessed ? content : processingContent} */}
														{`Payroll for ${rejectedCount} employees failed. `}
														<Link
															to={{
																pathname: `/payroll/rechecking_failed_employee/${uploadId}`,
															}}
															style={{ color: '#E60A14', textDecoration: 'underline' }}>
															Check now.
														</Link>
													</div>
												</Col>
											)}
										</Col>
										<Col sm={4} xs={4} className="tooltip">
											<Popover placement="left" content="Your verifier is yet to approvepayroll">
												<Col
													className="popover"
													style={{
														display: parentProcessed || parentProcessing ? 'flex' : 'none',
													}}>
													<ImageComponent src={icons.not_focus.Help} />
												</Col>
											</Popover>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					);
				})}
			</Row>
		</Fragment>
	);
};

export default EdenredPafStep;
