import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { leaveApproveClaim } from '@constants/app-constants';
import ETypo from '@pages/DesignSystem/Typo';
import { withReducer } from 'src/store/reducerLoader';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import EmployeehistorySection from './employeehistorySection';
import EmployeeHistorySectionMobile from './employeeHistorySectionMobile';
import selector from './employeeHistorySaga/selector';
import action from './employeeHistorySaga/action';
import reducer from './employeeHistorySaga/reducer';
import saga from './employeeHistorySaga/saga/index';
import './historySection.scss';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

const EmployeeHistoryFunctional = ({
	LeaveBalance,
	employeeDetails,
	employeeBalance,
	LeaveType,
	leaveTypeData,
	countLoader,
	saveLeaveLoading,
	SaveLeave,
	saveLeaveSucceessfulPopUp,
	saveLeaveClose,
	claims,
	setShowLeave = () => {},
	showLeave,
	setNavigateTabCheck = () => {},
	navigateTabCheck,
	sampleData,
	setSampleData = () => {},
	leaveTypelist,
	setLeaveTypelist = () => {},
}) => {
	const employeeId = employeeDetails?.employeeId || history?.location?.state?.tableData?.Id;
	const history = useHistory();
	useEffect(() => {
		LeaveType(employeeId);
		LeaveBalance(employeeId);
	}, [employeeId]);

	useEffect(() => {
		if (employeeBalance) constructLeaveTypes(employeeBalance?.leaveBalance, leaveTypeData?.leaveTypes);
	}, [employeeBalance, leaveTypeData]);

	const { leaveHistory } = employeeBalance;
	let hrClaims = [];
	const [leaveNotesPopUp, setLeaveNotesPopUp] = useState({
		leaveNotes: '',
		isLeaeNotesAvailable: false,
	});
	let newclaim = claims?.map((item) => {
		if (leaveApproveClaim.includes(item?.value)) {
			hrClaims.push(item);
		}
	});
	const columns = [
		{
			title: '',
			dataIndex: '',
			key: '',
			width: '5%',
			render: (name, row) => {
				return <Col className={row?.status === 'Approved' ? 'approvalColor' : 'approvalColorReject'} />;
			},
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '15%',
			render: (name) => {
				return (
					<Col>
						<ETypo medium b2 color="#484B52">
							{name}
						</ETypo>
					</Col>
				);
			},
		},

		{
			title: 'Leave Type',
			dataIndex: 'leaveTypeName',
			key: 'leaveTypeName',
			width: '15%',
			render: (name) => {
				return (
					<Col className=" departDetails">
						<ETypo light b2 color="#181919">
							{name}
						</ETypo>
					</Col>
				);
			},
		},
		{
			title: 'From - To',
			dataIndex: 'startDate',
			key: 'startDate',
			width: '20%',
			render: (startData, data) => {
				return (
					<Col className="departDetails">
						<ETypo light b2 color="#181919">
							{moment(data?.startDate).format('DD/MM/YYYY')} - {moment(data?.endDate).format('DD/MM/YYYY')}
						</ETypo>
					</Col>
				);
			},
		},
		{
			title: 'Total Days',
			dataIndex: 'totalDays',
			key: 'totalDays',
			width: '10%',
			render: (name) => {
				return (
					<Col className="departDetails">
						<ETypo light b2 color="#181919">
							{name}
						</ETypo>
					</Col>
				);
			},
		},
		{
			title: 'Note',
			dataIndex: 'note',
			key: 'note',
			width: '15%',
			render: (name) => {
				return (
					<Col className="departDetails-history" onClick={() => handleLeaveNotes(name)}>
						<ETypo
							family={name ? 'medium' : 'light'}
							b2
							color={name ? '#2B769C' : '#DFE1E6'}
							style={{ textDecoration: name ? 'underline' : '', cursor: name ? 'pointer' : 'not-allowed' }}
							className="leavehistory-text">
							{name ? 'View Note' : 'No Note'}
						</ETypo>
					</Col>
				);
			},
		},
		{
			title: 'Attachments',
			dataIndex: 'attachmentLinkName',
			key: 'attachmentLinkName',
			width: '20%',
			render: (name, row) => {
				return (
					<Col
						onClick={name != null ? () => openSubmittedProofModal(row.attachedDocumentLink, row.attachmentLinkName) : null}
						className={name ? 'attachments' : 'attachments1'}>
						<ETypo family={name ? 'medium' : 'light'} b2 color={name ? '#2B769C' : '#DFE1E6'} className="leavehistory-text">
							{name?.substring ? 'View Attachment' : 'No Attachment'}
						</ETypo>
					</Col>
				);
			},
		},
	];
	const handleLeaveNotes = (name) => {
		setLeaveNotesPopUp({ ...leaveNotesPopUp, isLeaeNotesAvailable: true, leaveNotes: name });
	};
	const constructLeaveTypes = (balance, leaveType) => {
		let nLeaveTypes = [];
		let nBalance = balance?.sort(function (a, b) {
			return a.leaveTypeId - b.leaveTypeId;
		});
		let nLeaveType = leaveType?.sort(function (a, b) {
			return a.leaveTypeId - b.leaveTypeId;
		});
		const arr2 = [
			{ leaveTypeId: 1, balance: 0, leaveTypeName: 'Annual' },
			{ leaveTypeId: 2, balance: 0, leaveTypeName: 'Sick' },
			{ leaveTypeId: 3, balance: 0, leaveTypeName: 'Unpaid' },
			{ leaveTypeId: 4, balance: 0, leaveTypeName: 'Maternity' },
			{ leaveTypeId: 5, balance: 0, leaveTypeName: 'Paternal' },
			{ leaveTypeId: 6, balance: 0, leaveTypeName: 'Bereavement' },
		];
		const nAArray = nBalance?.concat(arr2);
		let set = new Set();
		let unionArray = nAArray?.filter((item) => {
			if (!set.has(item.leaveTypeId)) {
				set.add(item.leaveTypeId);
				return true;
			}
			return false;
		}, set);
		let nUnionArray = unionArray?.sort(function (a, b) {
			return a.leaveTypeId - b.leaveTypeId;
		});
		const leaveTypeGender = (nLeaveType || [])?.filter((data) =>
			employeeDetails?.gender === 'M' ? data?.leaveTypeName !== 'Maternity' : data?.leaveTypeName !== 'Paternal'
		);
		const leaveTypeGenderBalance = (nUnionArray || [])?.filter((data) =>
			employeeDetails?.gender === 'M' ? data?.leaveTypeName !== 'Maternity' : data?.leaveTypeName !== 'Paternal'
		);
		!!leaveTypeGender?.length &&
			leaveTypeGender.map((data, i) => {
				let nObj = {};
				const arraylist = ['Annual', 'Sick'];
				const LeaveTypeId = data?.leaveTypeId;
				let balLeaveTypeId = leaveTypeGenderBalance?.[i]?.leaveTypeId;
				nObj = {
					isVisible:
						leaveTypeGenderBalance?.[i]?.probationPeriod !== 0
							? moment().format('YYYY-MM-DD') <=
									moment(leaveTypeGenderBalance?.[i]?.joiningDate)
										.add(leaveTypeGenderBalance?.[i]?.probationPeriod, 'months')
										.format('YYYY-MM-DD') && arraylist.includes(data?.leaveTypeName)
							: false,
					LeaveTypeId: LeaveTypeId,
					LeaveBalanceId: leaveTypeGenderBalance?.[i]?.leaveBalanceId,
					leaveTypeName: data?.leaveTypeName,
					Image: focus_state_icons?.[data?.leaveTypeName + 'Leave'],
					Status: 'Remaining :',
					Days:
						LeaveTypeId === balLeaveTypeId
							? `${(leaveTypeGenderBalance?.length && leaveTypeGenderBalance?.[i]?.balance) || 0} Days`
							: `0 Days`,
				};

				nLeaveTypes.push(nObj);
			});
		nLeaveTypes = nLeaveTypes?.sort(function (a, b) {
			return a.leaveTypeId - b.leaveTypeId;
		});
		setLeaveTypelist([...nLeaveTypes]);
		setSampleData([...nLeaveTypes]);
	};

	const [viewLeaveApprove, setViewLeaveApprove] = useState(false);
	const [Image, setImage] = useState(null);
	const [attachment, setAttachmentname] = useState();
	const [viewMore, SetViewMore] = useState(false);
	const openSubmittedProofModal = (name, name1) => {
		setViewLeaveApprove(true);
		setImage(name);
		setAttachmentname(name1);
	};
	const closeSubmittedProofModal = () => setViewLeaveApprove(false);

	const [edit, setEdit] = useState(false);
	const getHandleValue = () => {
		LeaveType(employeeId || history?.location?.state?.tableData?.Id);
		LeaveBalance(employeeId || history?.location?.state?.tableData?.Id);
		saveLeaveClose(false);
		setShowLeave({ ...showLeave, history: true });
		setEdit(false);
		history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '4' } });
	};
	const handleEditLeave = () => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '4',
		});
		setShowLeave({ ...showLeave, history: true });
		setEdit(false);
	};
	const handleHisEditButton = () => {
		setShowLeave({ ...showLeave, history: false });
		setEdit(true);
	};
	const closeLeaveNotesPopUp = () => {
		setLeaveNotesPopUp({ ...leaveNotesPopUp, isLeaeNotesAvailable: false, leaveNotes: '' });
	};
	return (
		<div>
			<div className="web-View-leave">
				<Edenredmodal
					{...{
						isOpen: leaveNotesPopUp?.isLeaeNotesAvailable,
						desc: leaveNotesPopUp?.leaveNotes,
						close: closeLeaveNotesPopUp,
						textcenter: true,
					}}
				/>
				<EmployeehistorySection
					{...{
						columns,
						data: leaveTypelist,
						sampleData,
						setSampleData,
						viewLeaveApprove: viewLeaveApprove,
						setViewLeaveApprove,
						openSubmittedProofModal,
						historyDetail: leaveHistory,
						closeSubmittedProofModal,
						Image,
						attachment,
						countLoader,
						saveLeaveLoading,
						SaveLeave,
						employeeId,
						saveLeaveSucceessfulPopUp,
						getHandleValue,
						setEdit,
						handleEditLeave,
						edit,
						hrClaims,
						handleHisEditButton,
						setNavigateTabCheck,
						navigateTabCheck,
						SetViewMore,
						viewMore,
					}}
				/>
			</div>
			<div className="mob-view-leave">
				<EmployeeHistorySectionMobile sampleData={leaveTypelist} historyDetail={leaveHistory} />
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		countLoader: selector.employeeBalanceLoading(state),
		employeeBalance: selector.employeeBalance(state),
		counterror: selector.employeeBalanceError(state),
		leaveTypeLoader: selector.employeeLeaveTypeLoading(state),
		leaveTypeData: selector.employeeLeaveType(state),
		leaveTypeerror: selector.employeeLeaveTypeError(state),
		saveLeaveLoading: selector.saveLeaveLoading(state),
		saveLeaveDetail: selector.saveLeaveDetail(state),
		saveLeaveError: selector.saveLeaveError(state),
		saveLeaveSucceessfulPopUp: selector.saveLeaveSuccessfully(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		LeaveBalance: (employeeId) => dispatch(action.creators.leaveBalanceStart(employeeId)),
		LeaveType: (empId) => dispatch(action.creators.leaveTypeStart(empId)),
		SaveLeave: (payload) => dispatch(action.creators.saveLeaveTypeStart(payload)),
		saveLeaveClose: (flag) => dispatch(action.creators.saveLeaveSucceessfulPopUp(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(EmployeeHistoryFunctional);
const ReducedScreen = withReducer('leaveBalanceReducer', reducer, saga)(Connected);

export default ReducedScreen;
