import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import { AccountType, BankName, CardStatus, EmiratesID, EstablishmentID, EmployeeChannelList } from './manage-employee';
import { Link } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';

const ManageEmployeesFilter = (props) => {
	const [filterData, setFilterData] = useState({
		accountTypes: [],
		bankNames: [],
		cardStatuses: [],
		emiratesIdStatuses: [],
		establishments: [],
		payrollChannels: [],
	});
	let queryParamFieldMapping = {
		accountTypes: 'accountType',
		bankNames: 'bankName',
		cardStatuses: 'cardStatus',
		emiratesIdStatuses: 'emiratesIdStatus',
		establishments: 'establishmentId',
		payrollChannels: 'payrollChannel',
	};
	let labelFieldMapping = {
		accountTypes: 'accountTypeName',
		bankNames: 'bankName',
		cardStatuses: 'cardStatus',
		emiratesIdStatuses: 'emiratesIdStatus',
		establishments: 'establishmentId',
		payrollChannels: 'payrollChannelName',
	};

	useEffect(() => {
		let filterOption = props.history.location.state.filterData;
		setFilterData(filterOption);
	}, []);

	const checkClicked = (index, type, isSelected) => {
		let updatedFilterData = { ...filterData };
		if (type === 'Payroll Channel') {
			let updated = [...updatedFilterData.payrollChannels];
			updated[index].isSelected = !isSelected;
		} else if (type === 'Account Type') {
			let updated = [...updatedFilterData.accountTypes];
			updated[index].isSelected = !isSelected;
		} else if (type === 'Establishment ID') {
			let updated = [...updatedFilterData.establishments];
			updated[index].isSelected = !isSelected;
		} else if (type === 'Card Status') {
			let updated = [...updatedFilterData.cardStatuses];
			updated[index].isSelected = !isSelected;
		} else if (type === 'Emirates ID Status') {
			let updated = [...updatedFilterData.emiratesIdStatuses];
			updated[index].isSelected = !isSelected;
		} else if (type === 'Bank Name') {
			let updated = [...updatedFilterData.bankNames];
			updated[index].isSelected = !isSelected;
		}
		setFilterData({ ...updatedFilterData });
	};

	const { accountTypes, bankNames, cardStatuses, emiratesIdStatuses, establishments, payrollChannels } = filterData;

	function handleSelectAllFilter() {
		let updatedFilterData = { ...filterData };
		Object.keys(updatedFilterData).forEach((key) => {
			updatedFilterData[key].forEach((value) => {
				value.isSelected = true;
			});
		});
		setFilterData({ ...updatedFilterData });
	}

	return (
		<>
			<EdenredHeader close />
			<Row className="manage-employee-filter-container">
				<Col xs={18} sm={18} className="Filter">
					<EdenredTypo bold>Filter</EdenredTypo>
				</Col>
				<Col xs={6} sm={6} classNmae="select_all">
					<EdenredButton type="link" style={{ color: '#71B0D0', fontSize: '12px', display: 'contents' }} onClick={handleSelectAllFilter}>
						Select All
					</EdenredButton>
				</Col>
				<Row className="scroll-filter">
					{/* Employee_Channel_List */}
					<Col sm={24} xs={24}>
						<Row xs={24} sm={24}>
							<EdenredTypo h6 bold>
								Employee Channel:
							</EdenredTypo>
						</Row>
					</Col>

					{payrollChannels.map((filter, index) => {
						return (
							<Col className="wps_nonwps" key={index}>
								<EdenredButton
									onClick={() => checkClicked(index, 'Payroll Channel', filter.isSelected)}
									type="outline-g"
									icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
									{filter.payrollChannelName}
								</EdenredButton>
							</Col>
						);
					})}

					{/* Account Type: */}
					<Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Account Type:
						</EdenredTypo>
					</Col>
					{accountTypes.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								onClick={() => checkClicked(index, 'Account Type', filter.isSelected)}
								type="outline-g"
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.accountTypeName}
							</EdenredButton>
						</Col>
					))}

					{/* Establishment ID: */}
					<Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Establishment ID:{' '}
						</EdenredTypo>
					</Col>
					{establishments.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								onClick={() => checkClicked(index, 'Establishment ID', filter.isSelected)}
								type="outline-g"
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.establishmentId}
							</EdenredButton>
						</Col>
					))}

					{/* Bank Name: */}
					{/* <Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Card Status
						</EdenredTypo>
					</Col>
					{cardStatuses.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								onClick={() => checkClicked(index, "Card Status", filter.isSelected)}
								type="outline-g"
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.cardStatus}
							</EdenredButton>
						</Col>
					))} */}

					{/* Emirates ID Status: */}
					{/* <Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Emirates ID Status
						</EdenredTypo>
					</Col>
					{emiratesIdStatuses.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								onClick={() => checkClicked(index, 'Emirates ID Status', filter.isSelected)}
								type="outline-g"
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.emiratesIdStatus}
							</EdenredButton>
						</Col>
					))} */}
					{/* Bank Name: */}
					<Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Bank Name
						</EdenredTypo>
					</Col>
					{bankNames.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								onClick={() => checkClicked(index, 'Bank Name', filter.isSelected)}
								type="outline-g"
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.bankName}
							</EdenredButton>
						</Col>
					))}
				</Row>
				<Col sm={24} xs={24} className="appy_buttom">
					<Link
						to={{
							pathname: '/employees/manage_employee',
							state: { filterData },
						}}>
						<EdenredButton>Apply Filters</EdenredButton>
					</Link>
				</Col>
			</Row>
		</>
	);
};
export default ManageEmployeesFilter;
