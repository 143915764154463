import React from 'react';
import reducer from './reducer';
import Updatedocument from './Component/updateNew';
import { withReducer } from 'src/store/reducerLoader';
import runSaga from '../upload-employees/saga/index';

const UpdateMain = () => {
	return <Updatedocument />;
};
const ReducedScreen = withReducer('uploadReducer', reducer, runSaga)(UpdateMain);
export default ReducedScreen;
