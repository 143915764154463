import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '@assets/icons';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { Col, Row } from 'antd';
import { SITEMAP } from 'src/routes/sitemap';
import './employeeUnderReview.scss';

const EmployeeUnderReview = () => {
	const location = useLocation();
	const history = useHistory();
	const locationStateUser = location?.state?.user || null;

	const goToDashboard = () => {
		if (locationStateUser) {
			history.push({ pathname: SITEMAP.dashboard.index, state: { user: locationStateUser } });
		} else {
			history.push(SITEMAP.dashboard.index);
		}
	};

	return (
		<Row>
			<Col className='post_dashboard_col_1'>
				<ImageComponent
					className='post_dashboard_col_1_image'
					src={icons.focus_state.sandLoadingWithBubbleBg}
				/>
			</Col>
			<Col className='post_dashboard_col_2'>
				<div className="text_contents">
					<ETypo bold h2>
						Employee Details Under Review
					</ETypo>
					<ETypo light h5>
						You have succesfully updated the employee details, We will review it and update soon. You can check any time about this in
						Pending Alerts.
					</ETypo>
				</div>
				<div>
					<EdenredButtons onClick={goToDashboard} btnType="secondary">
						Go to Dashboard
					</EdenredButtons>
				</div>
			</Col>
		</Row>
	);
};

export default EmployeeUnderReview;
