import { createSelector } from 'reselect';

import { initState } from './reducer';

const uploadReducer = (state) => state.uploadReducer || initState;
const uploadLoad = createSelector(uploadReducer, (cnter) => cnter.uploadLoading);
const documentUpload = createSelector(uploadReducer, (cnter) => cnter.documentUpload);
const uploadError = createSelector(uploadReducer, (cnter) => cnter.uploadError);
const templateLoading = createSelector(uploadReducer, (cnter) => cnter.templateLoading);
const templateDowload = createSelector(uploadReducer, (cnter) => cnter.templateDowload);
const templateError = createSelector(uploadReducer, (cnter) => cnter.templateError);
const isDocUploaded = createSelector(uploadReducer, (cnter) => cnter.isDocUploaded);

export default {
	uploadReducer,
	uploadLoad,
	documentUpload,
	uploadError,
	templateLoading,
	templateDowload,
	templateError,
	isDocUploaded,
};
