import React from 'react';
import LeaveUploadTable from '../../setup-leave-approval/leave-upload-table';
import './leaveApproveTable.scss';

const SetupLeaveApproveTable = () => {
	return (
		<div className="leaveapprove-table">
			<LeaveUploadTable {...{ isleaveApproveUpload: true, leavePath: '/leaves/leaves_to_approve' }} />;
		</div>
	);
};

export default SetupLeaveApproveTable;
