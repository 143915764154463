import React, { useEffect } from 'react';
import { DotLoader } from 'react-spinners';
import { Col, Row } from 'antd';
import { APP_VARIABLES } from '@constants/app-constants';
import { useDispatch, useSelector } from 'react-redux';
import { checkHrSubscription, checkWithHRClaims, checkAnyClaims } from '@helpers';
import { HrClaims } from '@constants/';
import { EmployeesPaidCard, MonthlySalaryProcessCard, SetupNotCompleteCard } from '../cards';
import CardsBlockCard from '../cards/thirdSection/CardsBlockCard';
import { employeeVerifyActions } from '@pages/employees/EmployeeVerification/redux';

const DashboardThirdSection = ({ currentUser, isEmployeePaidLoading, employeePaidByMonth, isPayrollAmountLoading, employeePaidByPayroll }) => {
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits) || false;
	const status = useSelector((state) => state?.sharedStates?.setupStatus);
	const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const showCardActivity = useSelector((state) => state?.employees?.verification?.featureEnabled);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(employeeVerifyActions.creators.employeeVerify());
	}, []);

	const showLoader = () => {
		return <DotLoader size={50} color={'#e91d24'} loading />;
	};

	const isClaimsExits = (roles, claim1, claim2) => {
		return (
			(!currentUser?.payrollClaims?.length && roles?.length === 1 && checkAnyClaims(roles, [claim1, claim2])) ||
			(!currentUser?.payrollClaims?.length && roles?.length === 2 && checkWithHRClaims(roles, claim1) && checkWithHRClaims(roles, claim2))
		);
	};
	const isSetupVisible =
		!isOnlyEmployeeExits &&
		status === 'notCompleted' &&
		checkSubscription &&
		!isClaimsExits(currentUser?.hrClaims, HrClaims?.MANAGE_LEAVES, HrClaims?.MANAGE_ANNOUNCEMENTS);

	const ShowFirstCard = () => {
		if (showCardActivity) {
			return (
				<Col xl={12} lg={24} md={24} sm={24} xs={24} className="setup_portal_web">
					<CardsBlockCard />
				</Col>
			);
		} else if (!showCardActivity) {
			return <EmployeesPaidCard {...{ isEmployeePaidLoading, employeePaidByMonth }} />;
		}
		return (
			<Col xl={12} lg={24} md={24} sm={24} xs={24} className="setup_portal_web">
				{showLoader()}
			</Col>
		);
	};

	const ShowSecondCard = () => {
		if (isSetupVisible) {
			return (
				<Col xl={12} lg={24} md={24} sm={24} xs={24} className="setup_portal_web">
					<SetupNotCompleteCard />
				</Col>
			);
		}
		return <MonthlySalaryProcessCard {...{ isPayrollAmountLoading, employeePaidByPayroll }} />;
	};

	return (
		<Row className="main_dashboard_web_third_row fade-right">
			<ShowFirstCard />
			<ShowSecondCard />
		</Row>
	);
};

export default DashboardThirdSection;
