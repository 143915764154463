const GET_EMPLOYEE_INDIVIDUAL_DATA = 'GET_EMPLOYEE_INDIVIDUAL_DATA';
const GET_EMPLOYEE_INDIVIDUAL_DATA_SUCCESS = ' GET_EMPLOYEE_INDIVIDUAL_DATA_SUCCESS';
const GET_EMPLOYEE_INDIVIDUAL_DATA_ERROR = 'GET_EMPLOYEE_INDIVIDUAL_DATA_ERROR';
const GET_EMPLOYEE_INDIVIDUAL_DATA_RESET = 'GET_EMPLOYEE_INDIVIDUAL_DATA_RESET';
const GET_EMPLOYEE_DOCUMENT_IMAGES = 'GET_EMPLOYEE_DOCUMENT_IMAGES';
const GET_EMPLOYEE_DOCUMENT_IMAGES_SUCCESS = ' GET_EMPLOYEE_DOCUMENT_IMAGES_SUCCESS';
const GET_EMPLOYEE_DOCUMENT_IMAGES_ERROR = 'GET_EMPLOYEE_DOCUMENT_IMAGES_ERROR';
const GET_DELETE_IMAGES = 'GET_DELETE_IMAGES';
const GET_DELETE_IMAGES_SUCCESS = 'GET_DELETE_IMAGES_SUCCESS';
const GET_DELETE_IMAGES_ERROR = 'GET_DELETE_IMAGES_ERROR';
const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA ';
const UPDATE_PROFILE_DATA_SUCCESS = 'UPDATE_PROFILE_DATA _SUCCESS ';
const UPDATE_PROFILE_DATA_ERROR = 'UPDATE_PROFILE_DATA _ERROR';
const CHECK_DUPLICATE_DOCUMENT_START = 'CHECK_DUPLICATE_DOCUMENT_START';
const CHECK_DUPLICATE_DOCUMENT_SUCCESS = 'CHECK_DUPLICATE_DOCUMENT_SUCCESS';
const CHECK_DUPLICATE_DOCUMENT_FAIL = 'CHECK_DUPLICATE_DOCUMENT_FAIL';

const updateProfileData = (id, upData, imageData) => {
	return {
		type: UPDATE_PROFILE_DATA,
		payload: id,
		upData,
		imageData,
	};
};

const updateProfileDataSuccess = (res) => {
	return {
		type: UPDATE_PROFILE_DATA_SUCCESS,
		res,
	};
};

const updateProfileDataError = () => {
	return {
		type: UPDATE_PROFILE_DATA_ERROR,
		payload: {},
	};
};
const getEmployeeIndividualData = (response) => {
	return {
		type: GET_EMPLOYEE_INDIVIDUAL_DATA,
		payload: response,
	};
};

const getEmployeeIndividualDataSuccess = (res) => {
	return {
		type: GET_EMPLOYEE_INDIVIDUAL_DATA_SUCCESS,
		res,
	};
};

const getEmployeeIndividualDataError = () => {
	return {
		type: GET_EMPLOYEE_INDIVIDUAL_DATA_ERROR,
		payload: {},
	};
};

const getEmployeeIndividualDataReset = () => {
	return {
		type: GET_EMPLOYEE_INDIVIDUAL_DATA_RESET,
		payload: {},
	};
};

const getEmployeeDocumentImages = (response) => {
	return {
		type: GET_EMPLOYEE_DOCUMENT_IMAGES,
		payload: response,
	};
};

const getEmployeeDocumentImagesSuccess = (res) => {
	return {
		type: GET_EMPLOYEE_DOCUMENT_IMAGES_SUCCESS,
		res,
	};
};

const getDeleteImages = (docId, empId) => {
	return {
		type: GET_DELETE_IMAGES,
		payload: docId,
		empId,
	};
};
const getDeleteImagesSuccess = (res) => {
	return {
		type: GET_DELETE_IMAGES_SUCCESS,
		res,
	};
};
const getDeleteImagesError = (res) => {
	return {
		type: GET_DELETE_IMAGES_ERROR,
		res,
	};
};

const getEmployeeDocumentImagesError = () => {
	return {
		type: GET_EMPLOYEE_DOCUMENT_IMAGES_ERROR,
		payload: {},
	};
};
const checkDuplicateDocumentStart = (payload) => ({
	type: CHECK_DUPLICATE_DOCUMENT_START,
	payload,
});
const checkDuplicateDocumentSuccess = (res) => ({
	type: CHECK_DUPLICATE_DOCUMENT_SUCCESS,
	res,
});
const checkDuplicateDocumentFail = (err) => ({
	type: CHECK_DUPLICATE_DOCUMENT_FAIL,
	err,
});

export default {
	types: {
		GET_EMPLOYEE_INDIVIDUAL_DATA,
		GET_EMPLOYEE_INDIVIDUAL_DATA_SUCCESS,
		GET_EMPLOYEE_INDIVIDUAL_DATA_ERROR,
		GET_EMPLOYEE_INDIVIDUAL_DATA_RESET,
		GET_EMPLOYEE_DOCUMENT_IMAGES,
		GET_EMPLOYEE_DOCUMENT_IMAGES_SUCCESS,
		GET_EMPLOYEE_DOCUMENT_IMAGES_ERROR,
		GET_DELETE_IMAGES,
		GET_DELETE_IMAGES_SUCCESS,
		GET_DELETE_IMAGES_ERROR,
		UPDATE_PROFILE_DATA,
		UPDATE_PROFILE_DATA_SUCCESS,
		UPDATE_PROFILE_DATA_ERROR,
		CHECK_DUPLICATE_DOCUMENT_START,
		CHECK_DUPLICATE_DOCUMENT_SUCCESS,
		CHECK_DUPLICATE_DOCUMENT_FAIL,
	},
	creators: {
		getEmployeeIndividualData,
		getEmployeeIndividualDataSuccess,
		getEmployeeIndividualDataError,
		getEmployeeIndividualDataReset,
		getEmployeeDocumentImages,
		getEmployeeDocumentImagesSuccess,
		getEmployeeDocumentImagesError,
		getDeleteImages,
		getDeleteImagesSuccess,
		getDeleteImagesError,
		updateProfileData,
		updateProfileDataSuccess,
		updateProfileDataError,
		checkDuplicateDocumentStart,
		checkDuplicateDocumentSuccess,
		checkDuplicateDocumentFail,
	},
};
