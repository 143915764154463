import React, { useEffect } from 'react';
import { message } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ALL_EMPLOYEE_CLAIMS, ALL_PAYROLL_CLAIMS, APP_VARIABLES, PERMISSIONS } from '@constants/app-constants';
import { asyncLocalStorage, userLogout } from '@helpers';
import SharedServices, { GET_PROFILE } from '../services';
import Actions from '../action';
import Selectors from '../selectors';
import LoadingPresentational from './loading-presentational';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen/useResponsiveScreen';
import { SITEMAP } from 'src/routes/sitemap';
import { COOKIE_CONSTANTS, gettingCookie } from 'src/helpers/cookies';

const LoadingFunctional = ({
	getRoles,
	getCorporateId,
	user,
	setUser,
	setUserDetails,
	corporateLoading,
	corporateAPIStatus,
	getSetupStatusSaga,
	getSetupCountSaga,
	setupStatus,
	setIsOnlyEmployeeExits,
	getUserNavigation,
	getUnEmploymentInsuranceDetailsBanner,
}) => {
	const api = new SharedServices();
	const history = useHistory();
	const dispatch = useDispatch();
	useResponsiveScreen();
	const { unEmploymentBannerData } = useSelector((state) => state?.sharedStates);
	const userExists = useSelector((state) => state?.sharedStates?.currentUser?.user);
	const isUserNavigationExists = useSelector((state) => state?.sharedStates?.userNavigation);

	window.onload = async () => {
		sessionStorage.getItem('ref');
		localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
		localStorage.getItem(APP_VARIABLES.ID_TOKEN);
		sessionStorage.setItem('ref', 1);
	};
	useEffect(() => {
		// If the user navigation data not exits then the api call will be called.
		corporateAPIStatus && !isUserNavigationExists && getUserNavigation();
		corporateAPIStatus && getSetupStatusSaga();
		corporateAPIStatus && !userExists && userStepAPI();
		corporateAPIStatus && getSetupCountSaga();
		corporateAPIStatus && dispatch(Actions.creators.getRakBankDetails());
		corporateAPIStatus && dispatch(Actions.creators.getCorporateListToShowInvoiceMenu());
		if (
			!unEmploymentBannerData ||
			localStorage.getItem('showUnemploymentBanner') === undefined ||
			localStorage.getItem('showUnemploymentBanner') === null
		) {
			corporateAPIStatus && getUnEmploymentInsuranceDetailsBanner();
		}
	}, [corporateAPIStatus]);

	const userStepAPI = async () => {
		const username = user && user.profile && user.profile.username;
		await api
			.services(GET_PROFILE, {
				username,
			})
			.then((res) => {
				setUser(res.data);
				const { employeeClaims, hrClaims, payrollClaims, teamClaims } = res?.data;
				const notEmployees = [...hrClaims, ...payrollClaims, ...teamClaims];
				setIsOnlyEmployeeExits(notEmployees?.length === 0 && employeeClaims?.length > 0);
				const user = res.data.user;
				const profileRes = res;
				/**loginstep=1 new user first time login show carousel popup
				 * loginstep=2 new user first time login how card based on claims
				 * loginstep=0 completed login step for new user to skip first time login
				 */
				if (user.loginStep !== 0 && user.loginStep !== 2) {
					history.push({
						pathname: SITEMAP.user.loginStep,
						state: {
							loginStep: user.loginStep,
							setupStatus,
						},
					});
				} else if (!user.isPasswordReset) {
					history.push(SITEMAP.user.createPassword);
				}
				if (
					profileRes &&
					profileRes.data &&
					profileRes.data.roles &&
					profileRes.data.roles.length > 0 &&
					profileRes.data.roles[0].name === 'Verifier'
				) {
					getRoles();
				}
			})
			.catch((err) => {});
	};
	const getProfileAndRoles = async () => {
		await getCorporateId();
	};
	const checkMfa = () => {
		// checking mfa check was in progress from cookie
		const mfaCheck = gettingCookie(COOKIE_CONSTANTS.mfa.inProgress);
		const notMfaPage = window.location.pathname !== SITEMAP.auth.mfa;
		// if mfa check was in on progress
		if (mfaCheck && notMfaPage) {
			message.warn('Redirecting...');
			history.push({ pathname: SITEMAP.auth.mfa, state: { user } });
		}
	};
	const checkCbt = () => {
		// checking cbtclient check was in progress from cookie
		const cbdClientCheck = gettingCookie(COOKIE_CONSTANTS.cbdClient.tc);
		const notTCPage = window.location.pathname !== SITEMAP.termsAndConditions.index;
		// if cbdClient check was in on progress
		if (cbdClientCheck && notTCPage) {
			userLogout();
		}
	};

	useEffect(() => {
		const username = user && user.profile && user.profile.username;
		if (username) {
			asyncLocalStorage
				.setItem(APP_VARIABLES.USER_DETAILS, JSON.stringify(user))
				.then(function () {
					return asyncLocalStorage.getItem(APP_VARIABLES.USER_DETAILS);
				})
				.then(function () {
					setUserDetails({ user, checkedForUser: true });
				});

			localStorage.setItem(APP_VARIABLES.ID_TOKEN, user.id_token);
			asyncLocalStorage
				.setItem(APP_VARIABLES.ACCESS_TOKEN, JSON.stringify({ access_token: user.access_token, username: user.profile.username }))
				.then(async () => {
					checkMfa();
					checkCbt();
					await getProfileAndRoles(username);
				});
		}
	}, [user && user.profile && user.profile.username]);

	return <LoadingPresentational {...{ corporateLoading }} />;
};

function isOnlyEmployeeProfile(allClaims) {
	if (Array.isArray(allClaims) && allClaims.length <= 0) {
		return true;
	}

	const CLAIMS_FOR_NAVIGATING_TO_HOME = {
		toShow: [
			PERMISSIONS.MANAGE_LEAVES,
			PERMISSIONS.MANAGE_DOCUMENTS,
			PERMISSIONS.MANAGE_ANNOUNCEMENTS,
			PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,
			...ALL_EMPLOYEE_CLAIMS,
		],
		notToShow: [...ALL_PAYROLL_CLAIMS],
	};

	const NAVIGATE_TO_HOME = {
		HAS_VALID_CLAIMS: CLAIMS_FOR_NAVIGATING_TO_HOME.toShow.some((_claims) => allClaims.indexOf(_claims) >= 0),
		DOES_NOT_CONTAIN_UNWANTED_CLAIMS:
			CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow && !CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow.some((values) => allClaims.indexOf(values) >= 0),
	};

	if (NAVIGATE_TO_HOME.HAS_VALID_CLAIMS === true && NAVIGATE_TO_HOME.DOES_NOT_CONTAIN_UNWANTED_CLAIMS === true) {
		return true;
	} else {
		return false;
	}
}

const mapStateToProps = (state) => {
	return {
		roles: Selectors.roles(state),
		user: state.oidc?.user || state.sharedStates.currentUserDetailsOIDC.user,
		corporateLoading: state?.sharedStates?.corporateLoading,
		corporateAPIStatus: state?.sharedStates?.corporateAPIStatus,
		setupStatus: state?.sharedStates?.setupStatus,
		setupStatusDetail: state?.sharedStates?.setupStatusDetail,
		redirectStatus: state?.sharedStates?.redirectStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRoles: () => dispatch(Actions.creators.getRoles()),
		getSetupStatusSaga: () => dispatch(Actions.creators.getSetupStatusStart()),
		getSetupCountSaga: () => dispatch(Actions.creators.getSetupCountStart()),
		getCorporateId: () => dispatch(Actions.creators.getCorporateId()),
		getClaims: (roles) => dispatch(Actions.creators.getClaims(roles)),
		setUser: (roles) => dispatch(Actions.creators.setUser(roles)),
		setIsOnlyEmployeeExits: (flag) => dispatch(Actions.creators.setIsOnlyEmployeeExits(flag)),
		setUserDetails: (details) => dispatch(Actions.creators.setUserDetails(details)),
		getUserNavigation: (details) => dispatch(Actions.creators.getUserNavigation()),
		getUnEmploymentInsuranceDetailsBanner: (details) => dispatch(Actions.creators.getUnEmploymentInsuranceDetailsBanner()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(LoadingFunctional);

export default Connected;
