import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react';
import Actions from '../action';
import { amountWithCommas, amountWithNoCommas, findObjFromArray, numberWithCommas, twoDigitAfterDec } from '@helpers';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import { connect, useDispatch } from 'react-redux';
import { Col } from 'antd';
import ClevertapReact from 'src/utils/clever-tap';
import { gsap, TweenMax } from 'gsap';
import { getEmpTotalSalary } from '../sagas/payroll-plus-new-saga';
import { default as CustomToolTip } from '@pages/DesignSystem/Tool-Tip';
import Inputbox from '@pages/DesignSystem/Input-Field';
import moment from 'moment';
import PayrollPlusNewHrFeaturePresentational from './PayrollPlusNewHrFeaturePresentation';
import Selectors from '../selectors';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { useHistory, useLocation } from 'react-router-dom';
import './filterContents.scss';

const PayrollPlusNewHrFeatureFunctional = ({
	getSalaryData,
	payrollSalaryStatus,
	additionData,
	deductionData,
	allowanceData,
	popupFlag,
	flagPopup,
	salaryStaging,
	wpsNonWpsPopupFlag,
	getPayrollDynamicHeader,
}) => {
	const location = useLocation();
	const [dropDownValueState, setDropDownValueState] = useState([]);
	const [payrollFilter, setPayrollFilter] = useState(false);
	const [empData, setEmpData] = useState({ tableData: [], selectedData: [], selectedByPage: [] });
	const [dropdownIcon, setDropdownIcon] = useState(false);
	const [toogleScroll, setToogleScroll] = useState(true);
	const [totalSalary, setTotalSalary] = useState([]);
	const [selectionListener, setSelectionListener] = useState(false);
	const [popup, setPopup] = useState(false);
	const [nextHide, setNextHide] = useState(false);
	const [date, setDate] = useState({});
	const [popupValue, setPopupValue] = useState();
	const [onChangePopup, setOnChangePopup] = useState(false);
	const [requestParams, setRequestParams] = useState({
		accountType: '',
		bankName: '',
		establishment: '',
		includeEmployeeCounts: false,
		pageNumber: 1,
		pageSize: 100,
		payrollBlockCheck: true,
		payrollChannel: '',
		previousPayrollUploadId: '',
		previoustotalPaidEmps: null,
		search: '',
		status: 'ACTIVE',
		month: moment().format('MM'),
		year: moment().format('yyyy'),
	});
	const [isPopupVisible, setIsPopupVisible] = useState({
		isWpsNonWps: false,
		data: [],
		endofService: [],
		payrollPlus: {},
		runPayroll: {},
		message: '',
		wpsNonWps: '',
		monthYearSelect: {},
		selectDate: '',
		status: 'wpsNonWps',
		type: '',
	});
	const history = useHistory();
	let init = useRef(null);
	let tableRef = useRef(null);
	const dispatch = useDispatch();
	const messagesEndRef = useRef(null);
	gsap.registerPlugin(ScrollToPlugin);
	const totalSal = 0;
	useEffect(() => {
		getPayrollDynamicHeader();
	}, []);
	useEffect(() => {
		const temp = location?.state?.empList.map((data) => {
			return {
				...data,
				isSelected: true,
			};
		});
		setEmpData((prev) => ({ ...prev, tableData: temp, selectedData: temp }));
	}, []);

	useEffect(() => {
		getSalaryData();
	}, []);

	useEffect(() => {
		if (flagPopup) {
			setPopup(true);
		}
	}, [flagPopup]);

	useEffect(() => {
		setPopup(false);
	}, [salaryStaging]);

	useEffect(() => {
		setRequestParams({
			...requestParams,
			month: moment(date?.salaryDate).format('MM'),
			year: moment(date?.salaryDate).format('YYYY'),
		});
	}, [date]);
	useEffect(() => {
		handleTotalSalary(empData.tableData, totalSal);
	}, [totalSal]);

	useEffect(() => {
		handleSalary(empData?.selectedData);
	}, [empData?.selectedData, empData?.tableData]);
	useEffect(() => {
		setNextHide(empData?.length === 0);
		const finalVal = empData?.selectedData?.map((data) => {
			setNextHide(data?.isSelected);
		});
	}, [empData?.selectedData, empData?.tableData]);
	// const buttonDisable = !empData?.selectedData?.every((item) => item?.isSelected === true && item?.salary !== 0);
	const handleSalaryForOne = (selectedData) => {
		const data = [selectedData].map((item) => {
			const val = Number(item?.endOfService);
			const datas = item?.basicSalary;
			return (
				item.employeeHRAdditionStaging.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0) +
				item.employeeHRAllowanceStaging.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0) +
				parseFloat(Number(datas) ?? 0) +
				parseFloat(val) +
				parseFloat(item.overtimePayAmount ?? 0) -
				item.employeeHRDeductionStaging.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0)
			);
		});
		const finalData = data.reduce((a, b) => a + b, 0);
		return finalData;
	};

	const handleSalary = (selectedData) => {
		const data = selectedData.map((item) => {
			const val = item?.endofService;
			const datas = item?.basicSalary;
			return (
				item.employeeHRAdditionStaging.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0) +
				item.employeeHRAllowanceStaging.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0) +
				parseFloat(datas ?? 0) +
				parseFloat(val?.value ?? 0) +
				parseFloat(item.overtimePayAmount ?? 0) -
				item.employeeHRDeductionStaging.reduce(function (accumulator, currentValue) {
					if (currentValue.value === '') {
						return 0;
					}
					return parseFloat(accumulator) + parseFloat(currentValue?.value);
				}, 0)
			);
		});
		const finalData = data.reduce((a, b) => a + b, 0);
		setTotalSalary(finalData);
	};

	const onChangeHandler = (employeeIdNameFilter) => setRequestParams({ ...requestParams, search: employeeIdNameFilter });

	const handlePagination = (pagination, param) => {
		setRequestParams({
			...requestParams,
			[param]: pagination,
		});
	};
	const onChange = (data) => {
		ClevertapReact.event('SelectMonth');
		setDate({
			...date,
			salaryDate: data?.salaryDate,
		});
		setRequestParams({
			...requestParams,
			month: moment(data?.salaryDate).format('MM'),
			year: moment(data?.salaryDate).format('YYYY'),
		});
	};
	const employeeTotalCount = useMemo(() => new Set(empData?.selectedData), [empData.selectedData]);
	useEffect(() => {
		setSelectionListener(employeeTotalCount?.size > 0);
	}, [employeeTotalCount]);

	const handleChange = (filterType, dropdownType, data) => {
		let lDropDownValueState = (dropDownValueState || [])?.map((dropdown) => {
			if (dropdown.name === filterType) {
				let tempOption = (dropdown?.options || [])?.map((option) => {
					const lDropdownType = option?.payrollChannelName || option?.accountTypeName || option?.establishmentId || option?.bankName;
					if (lDropdownType === dropdownType) {
						return {
							...option,
							isSelected: data || !option?.isSelected,
						};
					} else return option;
				});
				const isAllSelected = tempOption?.filter((value) => value?.isSelected)?.length === tempOption?.length;
				return {
					...dropdown,
					isAllSelected,
					options: tempOption,
				};
			}
			return dropdown;
		});
		setDropDownValueState([...lDropDownValueState]);
	};

	const handleTotalSalary = (data) => {
		const totalValue = data?.map((item) => {
			return (item.totalSalary = data);
		});
		return totalValue;
	};
	const handleSelect = (emp_id, selectAll) => {
		setOnChangePopup(true);
		let employeesSelected = empData?.selectedData;
		let toggledData = empData?.tableData?.map((employee) => {
			if (emp_id === employee?.employeeId) {
				employee.isSelected = !employee?.isSelected;
				if (employee?.isSelected) {
					employeesSelected.push(employee);
				} else {
					employeesSelected = employeesSelected?.filter((data) => emp_id !== data?.employeeId);
				}
			}
			if (!emp_id) {
				employee.isSelected = selectAll;
				if (employee?.isSelected) {
					employeesSelected.push(employee);
				}
				if (!employee.isSelected) {
					employeesSelected = employeesSelected?.filter((data) => !data?.employeeId);
				}
			}
			return employee;
		});
		setEmpData({
			...empData,
			tableData: [...toggledData],
			selectedData: [...employeesSelected],
		});
	};
	const handleInputs = (employeeId, fieldName, keyName, e) => {
		let value = amountWithNoCommas(twoDigitAfterDec(e));
		setOnChangePopup(true);
		let temp = empData?.tableData?.map((employee) => {
			if (employeeId === employee?.employeeId) {
				if (fieldName === 'basicSalary') {
					employee[fieldName] = Number(value);
				} else {
					employee[fieldName].map((data) => {
						if (data.allowanceTypeName === keyName || data.additionTypeName === keyName || data.deductionTypeName === keyName) {
							data.value = Number(value) || 0;
						}
						return data;
					});
				}
				employee.totalSalary = handleSalaryForOne(employee);
			}
			return employee;
		});
		setEmpData({ ...empData, tableData: [...temp] });
	};
	const cleverTapProperties = {
		TotalSalary: totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 }),
		NumberofEmployees: employeeTotalCount?.size,
		Month: moment(date?.salaryDate).format('MM'),
		year: moment(date?.salaryDate).format('YYYY'),
	};
	useEffect(() => {
		setIsPopupVisible({
			...isPopupVisible,
			isWpsNonWps: wpsNonWpsPopupFlag,
		});
	}, [wpsNonWpsPopupFlag]);

	const handleNext = (data, month, year) => {
		ClevertapReact.event('V1_1_Payroll_Table_Next', { ...cleverTapProperties });
		setOnChangePopup(false);
		dispatch(Actions.creators.updateStaging(data, { status: 'next', month: month, year: year }));
		setIsPopupVisible({
			...isPopupVisible,
			// isWpsNonWps: true,
			message: 'next',
			type: 'payrollPlus',
			status: 'wpsNonWps',
			monthYearSelect: { month: month, year: year },
			payrollPlus: { month: month, year: year },
		});
	};

	const handleGratuity = (employeeId, e) => {
		let value = amountWithNoCommas(twoDigitAfterDec(e));
		let endOfServiceValue = Number(value);

		setOnChangePopup(true);
		let temp = empData?.tableData?.map((employee) => {
			if (employeeId === employee?.employeeId) {
				employee.endOfService = !isNaN(endOfServiceValue) ? endOfServiceValue : 0;
				employee.totalSalary = handleSalaryForOne(employee);
			}
			return employee;
		});
		setEmpData({ ...empData, tableData: [...temp] });
	};

	const handleSelectedDataByPage = (isAllDataSelected) => {
		let toggledData = empData?.tableData?.map((employee) => {
			if (isAllDataSelected) {
				employee.isSelected = false;
			} else {
				employee.isSelected = true;
			}

			return employee;
		});
		const selectedData = empData?.tableData.filter((data) => data?.isSelected === true);
		setEmpData({
			...empData,
			tableData: toggledData,
			selectedData: selectedData,
		});
	};

	const isAllDataSelected = useMemo(
		() => empData?.selectedData?.length === empData?.tableData?.length,
		[empData?.tableData, empData?.selectedData]
	);

	const columns = [
		{
			sortDirections: ['ascend'],
			fixed: 'left',
			align: 'center',
			scroll: 'hidden',
			title: (
				<Checkbox
					{...{
						checked: isAllDataSelected,
						size: 16,
						borderWidth: 1,
						borderRadius: 4,
						onChange: () => handleSelectedDataByPage(isAllDataSelected),
					}}
				/>
			),
			dataIndex: 'isSelected',
			width: '20%',
			render: (isSelected, row) => {
				return (
					<Col ref={(el) => (init = el)}>
						<Checkbox
							{...{
								size: 16,
								borderWidth: 1,
								borderRadius: 4,
								checked: empData.selectedData.findIndex((data) => data.employeeId === row.employeeId) > -1,
								onChange: () => handleSelect(row.employeeId),
								disabled: row.paidForSelectedMonthYear,
							}}
						/>
					</Col>
				);
			},
		},

		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			sort: true,
			title: 'Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			width: '20%',
			fixed: 'left',
			render: (val, rowData) => {
				return (
					<Col
						disabled={rowData.basicSalary === 0}
						className="name"
						style={{
							color: '#0E5274',
							textUnderlinePosition: 'under',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}>
						{val}
					</Col>
				);
			},
		},
		{
			sorter: (a, b) => a.employeeId.localeCompare(b.employeeId),
			sort: true,
			title: 'Employee ID',
			width: '20%',
			dataIndex: 'employeeId',
			key: 'employeeId',
			fixed: 'left',
		},
		{
			title: 'Total Salary',
			sort: true,
			width: '20%',
			dataIndex: 'totalSalary',
			key: 'totalSalary',
			fixed: 'left',
			sorter: (a, b) => a?.totalSalary - b?.totalSalary,
			render: (totalSalary, rowData) => (
				<Col
					disabled={rowData.salary === 0}
					className="name"
					style={{ fontFamily: 'Montserrat-Medium', color: '#2B769C', fontWeight: '500', fontSize: '14px', lineHeight: '16px' }}>
					{`AED ${totalSalary.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? 0}`}
				</Col>
			),
		},
		{
			title: 'Basic Salary',
			sort: true,
			dataIndex: 'basicSalary',
			key: 'basicSalary',
			width: '20%',
			sorter: (a, b) => a?.basicSalary - b?.basicSalary,
			render: (basicSalary, rowData) => {
				return (
					<div>
						<Inputbox
							className="payrollPlus prehighlight-bolds"
							disabled={false}
							style={{
								borderColor: false ? '#E60A14' : null,
							}}
							prefix={'AED'}
							type="text"
							value={amountWithCommas(Number(basicSalary)) ?? 0}
							onChange={(e) => handleInputs(rowData.employeeId, 'basicSalary', rowData?.basicSalary, e.target.value ?? 0)}
						/>
					</div>
				);
			},
		},
		...allowanceData?.map((data) => {
			return {
				title: `${data?.name}`,
				dataIndex: `${data?.name}`,
				key: `${data?.name}`,
				width: '20%',
				sort: true,
				sorter: {
					compare: (a, b) => {
						let c = findObjFromArray(a.employeeHRAllowanceStaging, data.name, 'allowanceTypeName');
						let d = findObjFromArray(b.employeeHRAllowanceStaging, data.name, 'allowanceTypeName');
						return c?.value - d?.value;
					},
				},
				render: (overtimePay, rowData) => {
					const res = findObjFromArray(rowData.employeeHRAllowanceStaging, data.name, 'allowanceTypeName');
					return (
						<div>
							<Inputbox
								className="payrollPlus prehighlight-bolds"
								style={{ borderColor: false ? '#E60A14' : null }}
								prefix={'AED'}
								disabled={false}
								value={amountWithCommas(Number(res?.value)) ?? 0}
								type="text"
								onChange={(e) => handleInputs(rowData.employeeId, 'employeeHRAllowanceStaging', data.name, e.target.value ?? 0)}
							/>
						</div>
					);
				},
			};
		}),
		...additionData?.map((data, rowData) => {
			return {
				title: `${data?.name}`,
				dataIndex: `${data?.name}`,
				key: `${data?.name}`,
				width: '20%',
				sort: true,
				sorter: {
					compare: (a, b) => {
						let c = findObjFromArray(a.employeeHRAdditionStaging, data.name, 'additionTypeName');
						let d = findObjFromArray(b.employeeHRAdditionStaging, data.name, 'additionTypeName');
						return c?.value - d?.value;
					},
				},
				render: (text, rowData, index) => {
					const res = findObjFromArray(rowData?.employeeHRAdditionStaging, data?.name, 'additionTypeName');
					return (
						<div>
							<Inputbox
								className="payrollPlus prehighlight-bolds"
								style={{ borderColor: false ? '#E60A14' : null }}
								prefix={'AED'}
								disabled={false}
								value={numberWithCommas(Number(res?.value)) ?? 0}
								type="text"
								onChange={(e) => handleInputs(rowData?.employeeId, 'employeeHRAdditionStaging', data?.name, e?.target?.value ?? 0)}
							/>
						</div>
					);
				},
			};
		}),
		...deductionData?.map((data, index, index2) => {
			return {
				title: `${data?.name}`,
				dataIndex: `${data?.name}`,
				key: `${data?.name}`,
				width: '20%',
				sort: true,
				sorter: {
					compare: (a, b) => {
						let c = findObjFromArray(a.employeeHRDeductionStaging, data.name, 'deductionTypeName');
						let d = findObjFromArray(b.employeeHRDeductionStaging, data.name, 'deductionTypeName');
						return c?.value - d?.value;
					},
				},
				render: (overtimePay, rowData) => {
					const res = findObjFromArray(rowData.employeeHRDeductionStaging, data.name, 'deductionTypeName');
					return (
						<div>
							<Inputbox
								className="payrollPlus prehighlight-bolds"
								disabled={false}
								style={{ borderColor: false ? '#E60A14' : null }}
								prefix={'AED'}
								value={amountWithCommas(Number(res?.value)) ?? 0}
								type="text"
								onChange={(e) => handleInputs(rowData?.employeeId, 'employeeHRDeductionStaging', data?.name, e.target.value ?? 0)}
							/>
						</div>
					);
				},
			};
		}),
		{
			title: 'Gratuity',
			dataIndex: 'gratuity',
			key: 'gratuity',
			width: '20%',
			sort: true,
			sorter: (a, b) => a?.endOfService - b?.endOfService,
			render: (gratuity, rowData) => (
				<Inputbox
					className="payrollPlus prehighlight-bolds"
					disabled={false}
					style={{ borderColor: false ? '#E60A14' : null }}
					prefix={'AED'}
					type="text"
					value={amountWithCommas(Number(rowData?.endOfService)) ?? 0}
					onChange={(e) => {
						handleGratuity(rowData.employeeId, e.target.value ?? 0);
					}}
				/>
			),
		},
	];

	const scrollEvent = () => {
		let temp = tableRef?.children[0]?.children[0]?.children[2]?.children[1]?.children[0]?.children[0].children[0]?.children[0]?.children[1];
		TweenMax.to(temp, 2, { scrollTo: { x: toogleScroll ? 1900 : -1900 } });
		setToogleScroll(!toogleScroll);
	};
	return (
		<div ref={(el) => (tableRef = el)} style={{ width: '100%', alignSelf: 'flex-start' }}>
			<PayrollPlusNewHrFeaturePresentational
				{...{
					columns,
					dropDownValueState,
					handleChange,
					payrollFilter,
					setPayrollFilter,
					empData,
					onChangeHandler,
					handlePagination,
					payrollSalaryStatus,
					dropdownIcon,
					setDropdownIcon,
					messagesEndRef,
					init,
					tableRef,
					scrollEvent,
					toogleScroll,
					popup,
					setPopup,
					popupFlag,
					totalSalary,
					employeeTotalCount,
					selectionListener,
					handleNext,
					onChange,
					date,
					setDate,
					popupValue,
					flagPopup,
					requestParams,
					onChangePopup,
					nextHide,
					// buttonDisable,
					isPopupVisible,
					setIsPopupVisible,
					setDropDownValueState,
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		payrollSalaryStatus: Selectors.payrollSalaryStatus(state),
		additionData: Selectors.additionData(state),
		deductionData: Selectors.deductionData(state),
		allowanceData: Selectors.allowanceData(state),
		popupFlag: Selectors.popupFlag(state),
		flagPopup: Selectors.flagPopup(state),
		salaryStaging: Selectors.salaryStaging(state),
		wpsNonWpsPopupFlag: Selectors.wpsNonWpsPopupFlag(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSalaryData: () => dispatch(Actions.creators.getSalaryStatus()),
		getPayrollDynamicHeader: () => dispatch(Actions.creators.getPayrollDynamicHeader()),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(PayrollPlusNewHrFeatureFunctional);
export default Connected;
