import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { emp_Request } from '../constants/employeeRequestData';
import { renderQuestionAndAnswer } from './cardOrderFaqs';
import ItemHeader from '@pages/help/components/ItemHeader';
import '../employee.scss';

const EmployeeRequestFaqs = () => {
	const [indexValue, setIndexValue] = useState();
	return (
		<>
			<Col className="employee-presentational">
				<Row className="employee-col">
					<Col xl={24} md={24} lg={24}>
						<ItemHeader title='Employee Requests' />
						<Col className="employee-questions"> {renderQuestionAndAnswer(emp_Request, indexValue, setIndexValue)}</Col>
					</Col>
				</Row>
			</Col>
		</>
	);
};
export default EmployeeRequestFaqs;
