import './newLable.scss'
import React from 'react';
import { Row, Col, Card } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
const EdenredNewLable = () => {
	return (
		<>
        <Col className='edenredLable'>
        <EdenredTypo className='text'>New</EdenredTypo>
        </Col>
        </>
	);
};

export default EdenredNewLable;