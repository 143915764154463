import { createSelector } from 'reselect';
import { initState } from './reducer';

const payrollPlus = (state) => state.counter || initState;

const dropdownValues = createSelector(payrollPlus, (cnter) => cnter.payrollPlusFilterData);
const employeeTableData = createSelector(payrollPlus, (cnter) => cnter.payrollPlusEmpTableData);
const empCount = createSelector(payrollPlus, (cnter) => cnter.payrollTableCount);
const payrollTableLoader = createSelector(payrollPlus, (cnter) => cnter.payrollPlusDataLoader);
const payrollSalaryStatus = createSelector(payrollPlus, (cnter) => cnter.payrollSalaryStatus);
const additionData = createSelector(payrollPlus, (cnter) => cnter.additionType);
const deductionData = createSelector(payrollPlus, (cnter) => cnter.deductionType);
const allowanceData = createSelector(payrollPlus, (cnter) => cnter.allowanceType);
const dynamicHeaderSuccess = createSelector(payrollPlus, (cnter) => cnter.dynamicHeaderSuccess);
const popupFlag = createSelector(payrollPlus, (cnter) => cnter.payrollPopupFlag);
const flagPopup = createSelector(payrollPlus, (cnter) => cnter.flagPopup);
const salaryStaging = createSelector(payrollPlus, (cnter) => cnter.salaryStaging);
const postRakBankDetailsLoading = createSelector(payrollPlus, (cnter) => cnter.postRakBankDetailsLoading);
const postRakBankDetailsResponse = createSelector(payrollPlus, (cnter) => cnter.postRakBankDetailsResponse);
const postRakBankToastRes = createSelector(payrollPlus, (cnter) => cnter.postRakBankToastRes);
const wpsNonWpsPopupFlag = createSelector(payrollPlus, (cnter) => cnter.wpsNonWpsPopupFlag);
const salaryLimitExceeds = createSelector(payrollPlus, (cnter) => cnter.salaryLimitExceeds);

export default {
	dropdownValues,
	employeeTableData,
	empCount,
	payrollTableLoader,
	payrollSalaryStatus,
	additionData,
	deductionData,
	allowanceData,
	dynamicHeaderSuccess,
	popupFlag,
	flagPopup,
	salaryStaging,
	postRakBankDetailsLoading,
	postRakBankDetailsResponse,
	postRakBankToastRes,
	wpsNonWpsPopupFlag,
	salaryLimitExceeds
};
