import React from 'react';
import { Row, Col } from 'antd';
import './sub_menu.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';
import EdenredHeader from '@pages/main-layout/components/main-header';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { roles } from '@constants/';
import { PayrollClaims } from '@constants/';
import { checkWithClaims } from '@helpers';
// import { checkWithClaims } from '@helpers';
// import { userClaims } from '@constants/';

const SubMenuPage = ({ currentUser }) => {
	// mobile claim check
	const payrollClaims = (currentUser && currentUser?.payrollClaims) || [];
	const isApproveExists = checkWithClaims(payrollClaims, PayrollClaims.APPROVE_PAYROLL_REQUEST) || false;
	const payroll_submenus = [
		{
			icon: icons.not_focus.Payroll,
			content: 'Run Payroll',
			path: '/payroll/check_how_to_run',
		},
		{
			icon: icons.not_focus.Tracking,
			content: 'Payroll Status',
			path: '/payroll/history',
		},
		{
			icon: icons.not_focus.Document,
			content: 'Proof of Funds Transfer',
			path: '/payroll/proof_of_transfer',
		},
		isApproveExists && {
			icon: icons.not_focus.Approvals,
			content: 'Approve Requests',
			path: '/payroll/approve_requests',
		},
		{
			icon: icons.not_focus.Clock,
			content: 'Payroll Timings',
			path: '/payroll/timings',
		},
		{
			icon: icons.not_focus.BankTransfer,
			content: 'Our Bank Details',
			path: '/payroll/bank_details',
		},
		{
			icon: icons.not_focus.BankTransfer,
			content: 'Salary Reports',
			path: '/Salary',
		},
		{
			icon: icons.not_focus.Spreadsheet,
			content: 'Useful Files',
			path: '/payroll/useful_files',
		},
	];
	return (
		<Row className="SubMenuPage_Container">
			<EdenredHeader />
			<Col xs={24} sm={24} className="SubMenuPage">
				<Row className="SubMenuPage_card">
					<Col sm={24} xs={24} className="SubMenuPage_card_content">
						<Col sm={24} xs={24} style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
							<Col className="SubMenuPage_link_horizontal"></Col>
						</Col>

						{payroll_submenus?.map(
							(payroll, index) =>
								payroll && (
									<>
										<Link to={payroll.path} key={index}>
											<Row className="submenu_item_card_row">
												<Col xs={4} sm={3} className="submenuitem_logo">
													<ImageComponent src={payroll?.icon} />
												</Col>
												<Col xs={18} sm={18} className="submenuitem_title">
													<EdenredTypo>{payroll?.content}</EdenredTypo>
												</Col>
												<Col xs={2} sm={2} className="right_arrow">
													<ImageComponent src={icons?.not_focus?.Right} />
												</Col>
											</Row>
										</Link>
										{index !== payroll_submenus?.length - 1 ? <Col xs={24} sm={24} className="horizontal_line"></Col> : null}
									</>
								)
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: SelectorsApp.currentUser(state),
		user: state.oidc.user,
	};
};

export default connect(mapStateToProps, null)(SubMenuPage);
