import Actions from './action';

export const initState = {
	isBanknameLoading: false,
	bankNamedata: [],
	isEmployeeDataLoading: false,
	employeeData: [],
	isEmpDocumentLoading: false,
	empDocumentResponse: [],
	isindividualDocSaved: false,
};

const documentReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_BANKNAME_DATA_START:
			return {
				...state,
				isBanknameLoading: true,
			};
		case Actions.types.GET_BANKNAME_DATA_SUCCESS:
			return {
				...state,
				isBanknameLoading: false,
				bankNamedata: action?.res?.routings,
			};
		case Actions.types.GET_BANKNAME_DATA_FAIL:
			return {
				...state,
				isBanknameLoading: false,
				bankNamedata: [],
			};
		case Actions.types.GET_EMPLOYEE_DETAILS_START:
			return {
				...state,
				isEmployeeDataLoading: true,
			};
		case Actions.types.GET_EMPLOYEE_DETAILS_SUCCESS:
			return {
				...state,
				isEmployeeDataLoading: false,
				employeeData: action?.res,
			};
		case Actions.types.GET_EMPLOYEE_DETAILS_FAIL:
			return {
				...state,
				isEmployeeDataLoading: false,
				employeeData: [],
			};
		case Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_START:
			return {
				...state,
				isEmpDocumentLoading: true,
			};
		case Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_SUCCESS:
			return {
				...state,
				isEmpDocumentLoading: false,
				empDocumentResponse: action?.res,
			};
		case Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_FAIL:
			return {
				...state,
				isEmpDocumentLoading: false,
				empDocumentResponse: [],
			};
		case Actions.types.INDIVIDUAL_EMPDOC_SAVED:
			return {
				...state,
				isindividualDocSaved: action?.flag,
			};
		default:
			return state;
	}
};
export default documentReducer;
