import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import IndividualEmployeePresentational from './individual-employee-presentational';

const IndividualEmployeeFunctional = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onSubmitEmployeeDetails = (basicEmployeeDetails) => {
		history.push({
			pathname: '/employees/add/individual/employee/order/card',
			state: basicEmployeeDetails,
		});
	};

	return <IndividualEmployeePresentational form={form} onSubmitEmployeeDetails={onSubmitEmployeeDetails} />;
};

export default IndividualEmployeeFunctional;
