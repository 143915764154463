import { APP_VARIABLES } from '@constants/app-constants';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import userManager from 'src/config/user-manager';
import { MFA_CONSTANTS } from '../constants';

const AccountLocked = () => {
	const [tryAgain, setTryAgain] = useState(false);
	const auth = useSelector((state) => state.auth);
	useEffect(() => {
		function lockPeriodUTCTime() {
			if (auth.otp?.lockExpirationTime) {
				// UTC date time will ends with Z and checking is that the format
				if (!auth.otp?.lockExpirationTime.endsWith('Z')) {
					return `${auth.otp?.lockExpirationTime}Z`;
				}
				return auth.otp?.lockExpirationTime;
			} else if (auth.mfa?.lockExpirationTime) {
				// UTC date time will ends with Z and checking is that the format
				if (!auth.mfa?.lockExpirationTime.endsWith('Z')) {
					return `${auth.mfa?.lockExpirationTime}Z`;
				}
				return auth.mfa?.lockExpirationTime;
			} else return '';
		}

		const lockPeriod = lockPeriodUTCTime();
		// const lockPeriod = auth.otp?.lockExpirationTime
		// 	? auth.otp?.lockExpirationTime
		// 	: (auth.mfa?.lockExpirationTime && !auth.mfa?.lockExpirationTime.endsWith('Z')
		// 			? `${auth.mfa?.lockExpirationTime}Z`
		// 			: auth.mfa?.lockExpirationTime) || '';
		const lockPeriodTime = new Date(lockPeriod).getTime();
		const persistTimerKey = MFA_CONSTANTS.timerKey;

		// Check if the local timer key is already stored in localStorage
		const localTimerKey = localStorage.getItem(persistTimerKey);

		if (!localTimerKey) {
			// If not stored, save the backend time in localStorage
			localStorage.setItem(persistTimerKey, lockPeriodTime.toString());
		}

		const currentTime = Date.now();
		const storedBackendTime = Number(localStorage.getItem(persistTimerKey));

		// Calculate the time difference
		const timeDifference = storedBackendTime - currentTime;
		const timeOutId = setTimeout(() => {
			setTryAgain(true);
			localStorage.removeItem(persistTimerKey);
			clearTimeout(timeOutId); // Stop checking once the button is enabled
		}, timeDifference); // Check every second

		// Cleanup function to clear the interval when the component unmounts
		return () => clearTimeout(timeOutId);
	}, []);

	const handleTryAgain = () => {
		userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
		userManager.removeUser();
		localStorage.clear();
		userManager.signinRedirect('/');
	};
	return (
		<div className="try-again-container">
			<div className="try-again-text">
				<ETypo h3 bold>
					Verification Failed!
				</ETypo>
				<ETypo b1 light className="try-again-msg">
					Oops! you have reached the maximum number of attempts. Please try again after {MFA_CONSTANTS.lockedPeriod}
				</ETypo>
			</div>
			<span className="try-again-btn-wrapper">
				<EdenredButtons className="try-again-btn" children={'Try Again'} btnType="primary" disabled={!tryAgain} onClick={handleTryAgain} />
			</span>
		</div>
	);
};

export default AccountLocked;
