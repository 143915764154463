import Allowance from './Allowance';
import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import React from 'react';
import './index.scss';

const AllowancePage = () => {
	return (
		<main className="wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<Allowance cleverTap={true} />
			</div>
		</main>
	);
};

export default AllowancePage;
