export const not_focus_icons = {
	ContentEdit: require('./content-edit.svg'),
	invoice: require('./invoice.svg'),
	eyeFocus: require('./eye.svg'),
	countryImage: require('./countryImage.svg'),
	minus: require('./minus.svg'),
	plus: require('./plus1.svg'),
	AddCard: require('./AddCard.svg'),
	AddUser: require('./AddUser.svg'),
	AddUsers: require('./AddUsers.svg'),
	Approvals: require('./Approvals.svg'),
	ApproveCards: require('./ApproveCards.svg'),
	Balance: require('./Balance.svg'),
	BankTransfer: require('./BankTransfer.svg'),
	Camera: require('./Camera.svg'),
	Card: require('./Card.svg'),
	CardStatement: require('./CardStatement.svg'),
	CashPickUp: require('./CashPickUp.svg'),
	Check: require('./Check.svg'),
	CheckBox: require('./CheckBox.svg'),
	Cheque: require('./Cheque.svg'),
	Clock: require('./Clock.svg'),
	Close: require('./Close.svg'),
	Company_info: require('./Info.svg'),
	Email: require('../focus_state/Email.svg'),
	Dashboard: require('./Dashboard.svg'),
	Document: require('./Document.svg'),
	Down: require('./Down.svg'),
	Download1: require('./Download-1.svg'),
	Download: require('./Download.svg'),
	Download_Salary_Disabled: require('./Download_Salary_Disabled.svg'),
	documentDownload_v2: require('./documentDownload_v2.svg'),
	Edit: require('./Edit.svg'),
	NewEdit: require('./newEdit.svg'),
	EmployeeManage: require('./EmployeeManage.svg'),
	EmptyBox: require('./EmptyBox.svg'),
	Error: require('./Error.svg'),
	ExchangeRate: require('./ExchangeRate.svg'),
	Exit: require('./Exit.svg'),
	Filter: require('./Filter.svg'),
	Frame1: require('./Frame-1.svg'),
	Frame: require('./Frame.svg'),
	Gist: require('./Gist.svg'),
	Help: require('./Help.svg'),
	Hide: require('./Hide.svg'),
	Home: require('./Home.svg'),
	Homebank: require('./Building.svg'),
	Receiptbank: require('./receipt.svg'),
	ID: require('./ID.svg'),
	Income: require('./Income.svg'),
	Info: require('./Info.svg'),
	Infonew: require('./infoNew.svg'),
	KebabMenu: require('./KebabMenu.svg'),
	Laptop: require('./Laptop.svg'),
	Left: require('./Left.svg'),
	Location: require('./Location.svg'),
	Loyalty: require('./Loyalty.svg'),
	Mail: require('./Mail.svg'),
	Menu: require('./Menu.svg'),
	More: require('./More.svg'),
	NOLTopUp: require('./NOLTopUp.svg'),
	Notification: require('./Notification.svg'),
	Password: require('./Password.svg'),
	Payroll1: require('./Payroll-1.svg'),
	Payroll: require('./Payroll.svg'),
	PDF: require('./PDF.svg'),
	PDF_Disabled: require('./PDF_Disabled.svg'),
	Retry: require('./Retry.svg'),
	Right: require('./Right.svg'),
	Search1: require('./Search-1.svg'),
	Search: require('./Search.svg'),
	SearchNew: require('./Search-new.svg'),
	SendMoney: require('./SendMoney.svg'),
	Settings: require('./Settings.svg'),
	Show: require('./Show.svg'),
	SMS: require('./SMS.svg'),
	Spending: require('./Spending.svg'),
	Spinner: require('./Spinner.svg'),
	Spreadsheet: require('./Spreadsheet.svg'),
	SwitchOff: require('./SwitchOff.svg'),
	Telephone: require('./Telephone.svg'),
	Tip: require('./Tip.svg'),
	TopUp: require('./TopUp.svg'),
	Tracking: require('./Tracking.svg'),
	Transfer: require('./Transfer.svg'),
	Trash: require('./Trash.svg'),
	Up: require('./Up.svg'),
	Update: require('./Update.svg'),
	Upload: require('./Upload.svg'),
	User: require('./User.svg'),
	Wallet: require('./Wallet.svg'),
	Warning: require('./Warning.svg'),
	Website: require('./Website.svg'),
	EmptyWhite: require('./Vector.svg'),
	ArrowUp: require('./ArrowUp.png'),
	ArrowDown: require('./ArrowDown.png'),
	rightdisable: require('./rightdisable.svg'),
	Excell: require('./Excell.svg'),
	Excell_Disabled: require('./Excell_Disabled.svg'),
	Health: require('./health.svg'),
	UnSelectedLeft: require('./unSelectedleftnew.svg'),
	UnSelectedRight: require('./unselectedrightnew.svg'),
	VideoTutorialNotFocused: require('./VideoTutorialNotFocused.svg'),
	UptoDate: require('./UptoDate.png'),
	// Download: require('./Download.png'),
	// EmptyBox: require('./EmptyBox.png'),
	EExcell: require('./Excell.png'),
	EPDF: require('./PDF.png'),
	AnnualLeave: require('./AnnualLeave.svg'),
	sickLeave: require('./SickLeave.svg'),
	Unpaid: require('./UnpaidLeave.svg'),
	Maternity: require('./MaternityLeave.svg'),
	Paternal: require('./PaternityLeave.svg'),
	Compassionate: require('./CompassionateLeave.svg'),
	Hajj: require('./HajjLeave.svg'),
	Edenred: require('./edenred.svg'),
	Annual: require('./AnnualLeave.svg'),
	Compensatory: require('./CompOffLeave.svg'),
	Parental: require('./PaternityLeave.svg'),
	Sick: require('./SickLeave.svg'),
	Umrah: require('./UmrahLeave.svg'),
	downmobarrow: require('./downmobarrow.svg'),
	uparrowmob: require('./uparrowmob.svg'),
	Logo: require('./edenred.svg'),
	ExpireSoon: require('./ExpireSoon.svg'),
	Rectanglemob: require('./Rectanglemob.svg'),
	No_attachment: require('./Vector_no.svg'),
	UpToDate: require('./UpToDate.svg'),
	MissingFile: require('./Missing.svg'),
	Expired: require('./Alert.svg'),
	Delete: require('./Trash.svg'),
	EmptyCircle: require('./Vector.svg'),
	Calender: require('./Calendar.svg'),
	Plus: require('./Plus.svg'),
	CalenderBefore: require('./Calendar.svg'),
	CalendarBefore: require('./CalendarBefore.svg'),
	plusSvg: require('./plusSvg.svg'),
	incPlusSvg: require('./incPlusSvg.svg'),
	incMinusSvg: require('./incMinusSvg.svg'),
	divideSvg: require('./divideSvg.svg'),
	closeSvg: require('./closeSvg.svg'),
	infoSvg: require('./infoSvg.svg'),
	infoSvgNew: require('./infoSvgNew.svg'),
	errorSvg: require('./errorSvg.svg'),
	PenImage: require('./penimage.svg'),
	RightNav: require('./Rightnav.svg'),
	CardStatementNew: require('./CardStatementNew.svg'),
	Atm: require('./ATM.svg'),
	ApproveCardsNew: require('./ApproveCardsNew.svg'),
	DeleteImage: require('./DeleteImage.svg'),
	HistoryFrame: require('./FrameHistory.svg'),
	AnnouncementSuccess: require('./announcementsuccess.svg'),
	Withouttick: require('./withoutTick.svg'),
	Withtick: require('./withTick.svg'),
	curvedBracket: require('./Rectangle 556.svg'),
	curvedLine: require('./Vector71.svg'),
	Wrong: require('./wrong.svg'),
	up: require('./Up.svg'),
	down: require('./Down.svg'),
	subcriptionLock: require('./SubscriptionLock.svg'),
	searchInput: require('./search-input.svg'),
	arrowRight: require('./ArrowDropdown.svg'),
	tick: require('./tick.svg'),
	checked: require('./checked.png'),
	success_icn: require('./success_icon.svg'),
	warning_icn: require('./Warning_icn.svg'),
	Help_icn: require('./message-question.svg'),
	Error_icn: require('./Error_icon.svg'),
	halfSelect: require('./halfSelect.svg'),
	Upwhite: require('./upchange.svg'),
	Downwhite: require('./downchange.svg'),
	dash: require('./Grid.svg'),
	payrollnew: require('./Money.svg'),
	payrollnew_1: require('./payrollnew.svg'),
	Usersnew: require('./Usersnew.svg'),
	Buildingnew: require('./buildingnew.svg'),
	leavenew: require('./leavesnew.svg'),
	docnew: require('./docnew.svg'),
	announcenew: require('./announcenew.svg'),
	heartnew: require('./heartnew.svg'),
	Gridnew: require('./Gridnew.svg'),
	folderheart: require('./folderheart.svg'),
	helpnew: require('./helpnew.svg'),
	logoutnew: require('./logoutnew.svg'),
	dangerIcon: require('./dangerIcon.svg'),
	crossIcon: require('./Essetional.svg'),
	TipBulb: require('./TipBulb.svg'),
	Payslip: require('./Group 497.svg'),
	payslipIcon: require('./payslip.svg'),
	invite: require('./invite.svg'),
	yellowInfo: require('./yellowInfo.svg'),
	ArrowRight: require('./arrow-right.svg'),
	NavigateRight: require('./navigateright.svg'),
	userNew: require('./UserNew.svg'),
	SettingsNew: require('./SettingsNew.svg'),
	DocumentNew: require('./DocumentNew.svg'),
	MedicalInsDocs: require('./MedicalInsurance.svg'),
	rightArrowDisabled: require('./rightArrowDisabled.svg'),
	// ArrowRight: require('./arrow-right.svg'),
	LeftImage1: require('./Group 11661.svg'),
	RightImage1: require('./Group 674.svg'),
	LeftImage2: require('./illustration.svg'),
	RightImage2: require('./illustrations.svg'),
	LeftImage3: require('./announcements.svg'),
	CardImage1: require('./img1.svg'),
	CardImage2: require('./img2.svg'),
	CardImage3: require('./img3.svg'),
	CardImage4: require('./img4.svg'),
	CardImage5: require('./img5.svg'),
	CardImage6: require('./img6.svg'),
	PriceCardBg: require('./bg1.svg'),
	IconInfo: require('./IconInfo.svg'),
	VectorTick: require('./VectorStroke.svg'),
	VectorWrong: require('./VectorWrong.svg'),
	MinusImage: require('./Essetional.svg'),
	AddImage: require('./Essetional2.svg'),
	VectorMinus: require('./VectorMinus.svg'),
	VectorAdd: require('./Vectoradd.svg'),
	HeaderImg: require('./header-img.png'),
	YellowAlert: require('./yellowalert.svg'),
	SuccessImg: require('./Successimg.svg'),
	BlackTick: require('./blacktick.svg'),
	LangingC3: require('./edenred-1.png'),
	Comments: require('./Comments.svg'),
	EmployeeRequest: require('./Leave.svg'),
	CustomModal: require('./custom-modal.svg'),
	announcementModel: require('./carouselone.svg'),
	announcementMode1: require('./Communicate.svg'),
	announcementModel2: require('./carouseltwo.svg'),
	Document1: require('./Document1.svg'),
	Document2: require('./Document2.svg'),
	Document3: require('./Document3.svg'),
	Document5: require('./Document5.svg'),
	Document6: require('./Document6.svg'),
	Leave01: require('./Leave1.png'),
	Leave02: require('./Leave02.svg'),
	Leave1: require('./Leave1.svg'),
	Leave2: require('./Leave2.svg'),
	Leave3: require('./Leave3.svg'),
	Leave4: require('./Leave4.svg'),
	Payro1: require('./Payro1.png'),
	Payroll2: require('./Payroll2.svg'),
	Payroll3: require('./Payroll3.svg'),
	Payroll4: require('./Payroll4.svg'),
	Profile1: require('./Profile1.svg'),
	Profile2: require('./Profile2.svg'),
	Profile3: require('./Profile3.svg'),
	Profile4: require('./Profile4.svg'),
	Empolyee1: require('./Employee1.svg'),
	Empolyee2: require('./Employee2.svg'),
	Empolyee3: require('./Employee3.svg'),
	CustomModal: require('./custom-modal.svg'),
	hammer: require('./Security.svg'),
	InviteToPortal: require('./inviteportal.svg'),
	NewCalendar: require('./newcalendar.svg'),
	uploadhint: require('./uploadhint.svg'),
	uploadv2: require('./Upload_icon_v2.svg'),
	payrollCalender: require('./payrollCalender.svg'),
	uploadSpreadsheet: require('./uploadSpreadsheet.svg'),
	RedTick: require('./RedTick.svg'),
	payrollLocation: require('./payrollLocation.svg'),
	payrolltelegram: require('./payrolltelegram.svg'),
	payrollupload: require('./payrollupload.svg'),
	payrolluser: require('./payrolluser.svg'),
	payslipMenuInactive: require('./payslipMenuInactive.svg'),
	homeMenuInactive: require('./homeMenuInactive.svg'),
	bankdetailCheck: require('./BankDetailCheck.svg'),
	bankdetailsendmoney: require('./BankDetailSendMoney.svg'),
	bankdetailtracking: require('./BankDetailTracking.svg'),
	bankdetailupload: require('./BankDetailUpload.svg'),

	statusVerified: require('./status_verified.svg'),
	statusDelivered: require('./status_delivered.svg'),
	statusCanceled: require('./status_canceled.svg'),
	orderCard: require('./orderCard.svg'),
	atmDispute: require('./atmDispute.svg'),
	c3payToBank: require('./c3payToBank.svg'),
	requestCard: require('./requestCard.svg'),
	inactiveSalaryDownload: require('./inactiveSalaryDownload.svg'),
	tickBox: require('./tickBox.svg'),
	updating: require('./Processsing.svg'),
	failed: require('./failed.svg'),
	sliderBackground: require('./slider_background.svg'),

	approveEmployeeRequestIcon: require('./approveEmployeeRequestIcon.svg'),
	approvePayrollRequestIcon: require('./approvePayrollRequestIcon.svg'),
	checkCardOrderStatusIcon: require('./checkCardOrderStatusIcon.svg'),
	downloadEmployeeDetailsIcon: require('./downloadEmployeeDetailsIcon.svg'),
	inviteTeamMembersIcon: require('./inviteTeamMembersIcon.svg'),
	runPayrollIcon: require('./approveEmployeeRequestIcon.svg'),
	checkDocumentsExpiringIcon: require('./checkDocumentsExpiringIcon.svg'),
	manageEmployeesIcon: require('./manageEmployeesIcon.svg'),

	manageLeavesIcon: require('./manageLeavesIcon.svg'),
	approveCardOrdersIcon: require('./approveCardOrdersIcon.svg'),
	approveCardReplacementIcon: require('./approveCardReplacementIcon.svg'),
	approveCardOrderCancellation: require('./approveCardOrderCancellation.svg'),
	addEditLeaveBalances: require('./addEditLeaveBalances.svg'),
	sendAnnouncementsIcon: require('./sendAnnouncementsIcon.svg'),

	payslipForYourEmployees: require('./payslipForYourEmployees.svg'),
	cardOrdersEasilyIcon: require('./cardOrdersEasilyIcon.svg'),
	trackLeavesEasilyIcon: require('./trackLeavesEasilyIcon.svg'),
	neverPayAFineIcon: require('./neverPayAFineIcon.svg'),
	communicateInSeconds: require('./communicateInSeconds.svg'),
	noMorePaperWorkIcon: require('./noMorePaperWorkIcon.svg'),
	downloadNew: require('./frameNew.svg'),

	arrowRightNew: require('./arrowRightNew.svg'),
	arrowLeftNew: require('./arrowLeftNew.svg'),

	supportEmailIcon: require('./support_email_icon.svg'),
	supportHelpIcon: require('./support_help_icon.svg'),

	//setup non active state

	buildingOutline: require('./buildingsoutline.svg'),
	folderOutline: require('./folderoutline.svg'),
	program2Outline: require('./program2outline.svg'),
	programOutline: require('./programoutline.svg'),
	sunfogoutline: require('./sun-fogoutline.svg'),
	userOutline: require('./useroutline.svg'),
	danger2: require('./Danger_2.svg'),

	info2: require('./info_2.svg'),
	tickHexagon: require('./tick-hexagon.svg'),
	security_1_active: require('./Security_1_active.svg'),
	security_1_inactive: require('./Security_1_inactive.svg'),
	documentPayslip: require('./documentpayslip.svg'),
	remainder: require('./remainder.svg'),
	setupCalculatorIcon: require('./setupcalculatoricon.png'),
	emails: require('./Emails.svg'),
	selectLeftArrow: require('./selectLeftArrow.svg'),
	EssentialMessage: require('./EssentialMessage.svg'),
	noAnnouncement: require('./noAnnouncement.svg'),
	newFeature: require('./newfeature.svg'),
	hrPreviewDocumentsImg: require('./hrPreviewDocumentImg.png'),
	hrPreviewLeaveImg: require('./hrPreviewLeaveImg.png'),
	// Arrows
	leftArrow: require('./left-chevron.svg'),
	rightArrow: require('./right-chevron.svg'),
	// new icons
	infoLight: require('./info-light.svg'),
	xmarkBold: require('./xmark-bold.svg'),
	limitError: require('./limit-error.svg'),
	gradientCalender: require('./payroll-gradient-calendar.svg'),
	questionMarkCircle: require('./question-help-circle.svg')
};
