import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { templateDownload } from '@helpers';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import EdenredDates from '@sharedComponent/date-fileds';
import { useQuery } from 'src/helpers/hooks';
import { SITEMAP } from 'src/routes/sitemap';
import { SalaryReportService } from 'src/services/payroll-service';
import SALARY_REPORTS_CONSTANTS from './constants';

const CardsReport = () => {
	const [loading, setLoading] = useState(false);
	const search = useQuery();
	const history = useHistory();
	const cardReportType = search.get('type');
	const [fileSuccessPopup, setFileSuccessPopup] = useState(false);
	const [fileSuccessText, setFileSuccessText] = useState({
		titleMessage: '',
		subMessage: '',
	});

	// If someone access this page without card type by changing in url will redirects to cards page
	useEffect(() => {
		if (cardReportType) {
			if (
				cardReportType !== SALARY_REPORTS_CONSTANTS.cards.reportType.issue &&
				cardReportType !== SALARY_REPORTS_CONSTANTS.cards.reportType.delete
			) {
				history.push(SITEMAP.payroll.salaryReports.index);
			}
		} else {
			history.push(SITEMAP.payroll.salaryReports.index);
		}
	}, [cardReportType]);

	const showTitle = () => {
		if (cardReportType === SALARY_REPORTS_CONSTANTS.cards.reportType.issue) {
			return SALARY_REPORTS_CONSTANTS.cards.reportPageTitle.issue;
		} else if (cardReportType === SALARY_REPORTS_CONSTANTS.cards.reportType.delete) {
			return SALARY_REPORTS_CONSTANTS.cards.reportPageTitle.delete;
		}
		return null;
	};

	// we get from and to date from the onSubmit function
	const downloadReport = async (fromDate, toDate) => {
		const fileName = () => {
			if (cardReportType === SALARY_REPORTS_CONSTANTS.cards.reportType.issue) {
				return SALARY_REPORTS_CONSTANTS.cards.reportFileName.issue;
			} else if (cardReportType === SALARY_REPORTS_CONSTANTS.cards.reportType.delete) {
				return SALARY_REPORTS_CONSTANTS.cards.reportFileName.delete;
			}
			return SALARY_REPORTS_CONSTANTS.cards.reportFileName.other;
		};

		setLoading(true);
		const salaryReport = new SalaryReportService();
		try {
			const res = await salaryReport.downloadReport(fromDate, toDate, cardReportType);
			if (res.data?.type !== 'text/plain') {
				setLoading(false);
				setFileSuccessPopup(true);
				setFileSuccessText({
					titleMessage: SALARY_REPORTS_CONSTANTS.salaryForm.fileDownloadedText.success,
					subMessage: null,
				});
				templateDownload(res.data, fileName());
			} else {
				setLoading(false);
				setFileSuccessPopup(true);
				setFileSuccessText({
					titleMessage: SALARY_REPORTS_CONSTANTS.salaryForm.fileDownloadedText.noDataFound,
					subMessage: null,
				});
			}
		} catch (error) {
			setLoading(false);
		}
	};
	return (
		<>
			<Edenredmodal
				isOpen={fileSuccessPopup}
				title={fileSuccessText?.titleMessage}
				onOkayButton="Ok"
				onOkay={() => setFileSuccessPopup(false)}
			/>
			<EdenredDates
				loading={loading}
				type="salary_report_cards"
				downloadReportType="excel"
				title={showTitle()}
				onsubmit={downloadReport}
				cardReportType={cardReportType}
			/>
			;
		</>
	);
};

export default CardsReport;
