import './expirymodule.scss';

import { Col, Input, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { checkTwoArraysByLooping, debounceHandler, formatHRCardCounts } from '@helpers';

import Accordian from './customaccordian';
import ContentLoader from '@sharedComponent/content-loader';
import { DATE_FORMAT } from '@constants/app-constants';
import EdenredButton from '@sharedComponent/button';
import Filter from './filter/index';
import ImageIcon from './ImageIcon';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingOutlined } from '@ant-design/icons';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import moment from 'moment';

const Expirymodule = ({
	counts,
	filterData,
	filterDocument,
	onSearch,
	details,
	handleCancel,
	CancelButton,
	handleNoCancel,
	cancelButton,
	downloadOnlyExcell,
	savechanges,
	expiryDateOptions,
	hasExpiryDateFilterData,
	documentTypeOptions,
	downloadDocumentType,
	handleExpiryDateDocumentType,
	handleClearAllFilter,
	totalCounts,
	setFilterDocument,
	downloadPdf,
	oldDetails,
	filterLoader,
	saveLoader,
	showEmptyFilterWarning,
}) => {
	const [localDetails, setLocalDetails] = useState(details || []);
	const [filterState, setFilterState] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

	let height = window.innerHeight - 64;

	const filters = () => {
		if (filterState) {
			setFilterState(false);
		} else {
			setFilterState(true);
		}
	};

	useEffect(() => {
		setLocalDetails(details);
	}, [details]);

	const [isModified, modifiedData] = useMemo(() => checkTwoArraysByLooping(localDetails, oldDetails), [localDetails]);

	const cardItems = formatHRCardCounts(counts);

	const handleDetailsChange = async (value, label, emp_id) => {
		let data = JSON.parse(JSON.stringify(localDetails || []));
		let formatValue = value === 'Invalid date' ? null : value;
		if (formatValue) formatValue = moment(value, DATE_FORMAT.YYYY_MM_DD).format('yyyy-MM-DDTHH:mm:ss');
		await data.map((val, i, arr) => {
			if (val.employeeId === emp_id) {
				val[label] = formatValue;
			}
		});
		await setLocalDetails([...data]);
		await setActiveIndex(null);
	};

	const hasData = !!details?.length;
	const hasFilter = !!(filterDocument?.documentTypeFilter || [])?.length || !!(filterDocument?.expiryDateFilter || [])?.length;
	return (
		<>
			{filterState ? (
				<div className="onlyFilter">
					<Filter
						{...{
							setFilterState,
							filterData,
							filterDocument,
							expiryDateOptions,
							handleExpiryDateDocumentType,
							documentTypeOptions,
							downloadDocumentType,
							hasExpiryDateFilterData,
							handleClearAllFilter,
						}}
					/>
				</div>
			) : (
				<div className="headContainer">
					<div className={cancelButton ? 'opacityContainer' : 'opacityContainer1'} style={{ paddingBottom: hasData ? 170 : 0 }}>
						<Row className="topLogo">
							<div className="ant-col-xl-5 ant-col-lg-5 ant-col-md-5 ant-col-sm-5">
								<img src={images.edenredSVG} className="forLogo" />
							</div>
							<div className="ant-col-xl-5 ant-col-lg-5 ant-col-md-5 ant-col-sm-5 downloadImg">
								<img src={images.hR_download} className="cloud" onClick={() => downloadPdf()} />
							</div>
						</Row>
						<Row>
							<div className="ant-col-xl-5 ant-col-lg-5 ant-col-md-24 ant-col-xs-24">
								<p className="expDoc">Expiry Documents</p>
							</div>
						</Row>
						<Row>
							<div className="ant-col-xs-8  ant-col-md-12">
								<button class="btn mobFilter" onClick={() => filters()}>
									<img style={{ height: 24 }} src={icons.not_focus.Filter} />
									<p className="forSearch"> Filters</p>
								</button>
							</div>
							<div className="ant-col-xs-1"></div>
							<div className="ant-col-xs-15 ant-col-md-12">
								<Input
									className="btn search"
									prefix={
										filterLoader ? (
											<div
												style={{
													width: 25,
													height: 25,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}>
												<LoadingOutlined className="search-icon" />
											</div>
										) : (
											<img src={images.hR_search} />
										)
									}
									placeholder="Search"
									disabled={filterLoader}
									onChange={(e) => debounceHandler(e.target.value, onSearch)}
								/>
							</div>
						</Row>

						<Row className="summaryRow">
							{cardItems.map((props, i) => (
								<ImageIcon key={i} {...props} isMobile />
							))}
						</Row>

						{filterLoader ? (
							<Col style={{ height: '25vh', position: 'absolute', width: '100%' }}>
								<ContentLoader />
							</Col>
						) : null}

						<InfiniteScroll
							dataLength={details?.length}
							style={{ padding: '0 8px 20px 8px' }}
							next={() => setFilterDocument({ ...filterDocument, pageSize: filterDocument?.pageSize + 30 })}
							hasMore={true}
							// loader={
							// 	<Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							// 		<PulseLoader size={20} color={'#e91d24'} loading />
							// 	</Col>
							// }
							height={`${height}px`}
							endMessage={
								<p style={{ textAlign: 'center' }}>
									<b>Yay! You have seen it all</b>
								</p>
							}>
							{details?.map((data, index, allData) => (
								<Accordian
									key={index}
									detail={data}
									{...{ setSaveButtonEnabled, handleDetailsChange, totalDetails: allData, setActiveIndex, index }}
								/>
							))}
						</InfiniteScroll>
					</div>
					{hasData ? (
						<Col className="saveChangeOverlay">
							{!cancelButton && (
								<div className="saveChange">
									<Row>
										<p className="doYou"> Do you want to save changes?</p>
									</Row>
									<Row style={{ height: '60%', alignItems: 'center' }}>
										<Col xs={11}>
											<EdenredButton
												disabled={!isModified || saveLoader}
												className="cancelSave-btn cancel"
												type="outline-g"
												onClick={CancelButton}>
												Cancel
											</EdenredButton>
										</Col>
										<Col xs={1} />
										<Col xs={12}>
											<EdenredButton
												loading={saveLoader}
												className="cancelSave-btn"
												disabled={!isModified}
												onClick={() => savechanges(modifiedData)}>
												Save
											</EdenredButton>
										</Col>
									</Row>
								</div>
							)}
							{cancelButton ? (
								<div className="cancelContainer">
									<Row>
										<p className="doYou"> Are You Sure You Want To Cancel?</p>
									</Row>
									<Row>
										<p className="changesWillNot">Changes will not be saved</p>
									</Row>

									<Row style={{ paddingTop: 30 }}>
										<Col xs={11}>
											<EdenredButton className="cancelSave-btn cancel" type="outline-g" onClick={handleNoCancel}>
												No
											</EdenredButton>
										</Col>
										<Col xs={1} />
										<Col xs={12}>
											<EdenredButton className="cancelSave-btn" onClick={handleCancel}>
												Yes, Cancel
											</EdenredButton>
										</Col>
									</Row>
								</div>
							) : null}
						</Col>
					) : null}
				</div>
			)}
		</>
	);
};
export default Expirymodule;
