import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ContentManager from './ContentManager';
import useOnClickOutside from '@pages/payroll-plus-new/components/useOnClickOutside';
import { icons } from 'src/assets/icons';
import './SelectDropdown.scss';

const SelectDropdown = ({ selectedValue, onChange, Option = [], placeholder = '', searchVisibility, icon, disabled = false, className = '' }) => {
	const ref = useRef(null);
	const ref1 = useRef(null);
	const [dropdownClicked, setDropdownClicked] = useState(false);
	const [dropdownValue, setDropdownValue] = useState();
	const [searchKey, setSearchKey] = useState('');

	useOnClickOutside(ref, () => {
		if (dropdownClicked) setDropdownClicked(false);
	});

	const handleDropdown = (data, item) => {
		console.log('data1', data, item);
		const finalObject = {
			name: item ? item?.name : data?.name || '',
			sub: item ? item?.sub : data?.sub || '',
			subMenu: {
				subName: item ? data?.name : '',
				subSub: item ? data?.sub : '',
			},
		};
		setDropdownValue(finalObject);
		onChange(finalObject);
		setDropdownClicked(false);
		selectedValue && selectedValue(data);
	};

	const handleDropdownClick = (e) => {
		e.stopPropagation();
		setDropdownClicked(!dropdownClicked);
	};

	const filtered = useMemo(() => {
		const filtered = Option?.filter((data) => {
			if (data?.name?.toLowerCase().includes(searchKey?.toLowerCase())) {
				return data;
			}
			if (data?.sub?.toLowerCase().includes(searchKey?.toLowerCase())) {
				return data;
			} else if (searchKey === '') {
				return data;
			}
		});
		return filtered;
	}, [searchKey]);

	useEffect(() => {
		setSearchKey(dropdownClicked ? searchKey : '');
	}, [dropdownClicked]);

	return (
		<div className={`select-dropdown-container ${className}`} style={{ cursor: disabled ? 'not-allowed' : '' }}>
			<div className="dropdown_header" style={disabled ? { pointerEvents: 'none', opacity: 0.7, cursor: 'not-allowed' } : {}}>
				<div
					ref={(ref, ref1)}
					onClick={(e) => handleDropdownClick(e)}
					className="dropdown_inner"
					style={dropdownClicked ? { border: '1px solid #71b0d0' } : { border: '1px solid #dfe1e6' }}>
					<div className="dropdown_default">
						<span className="dropdown_inner_list_main">{dropdownValue?.name || placeholder}</span>
						<span className={`dropdown_inner_list_sub`}>{dropdownValue?.sub}</span>
						<span className={`dropdown_inner_list_main`} style={{ paddingLeft: '8px	' }}>
							{dropdownValue?.subMenu?.subName}
						</span>
						<span className={`dropdown_inner_list_sub`}>{dropdownValue?.subMenu?.subSub}</span>
					</div>
					<div role="button" className="dropdown_arrow" onClick={(e) => handleDropdownClick(e)}>
						<img className="arrow-inner" src={dropdownClicked ? icons.not_focus.up : icons.not_focus.down} alt="dropDown arrow" />
					</div>
				</div>
				{dropdownClicked ? (
					<div ref={ref} className="dropdown_list" style={{ width: `100%`, marginTop: '12px' }}>
						{searchVisibility && (
							<div className="dropdown_list_search_main">
								<img src={icons.not_focus.searchInput} alt="search-input" />
								<input
									placeholder={'Search options'}
									spellCheck={false}
									value={searchKey}
									onChange={(e) => setSearchKey(e?.target.value)}
									className="dropdown"
								/>
							</div>
						)}
						<div className="dropdown_inner_list">
							{filtered?.map((data) => (
								<ContentManager {...{ data, handleDropdown, icon }} />
							))}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
SelectDropdown.defaultProps = {
	Option: [],
	placeholder: 'Default',
	searchVisibility: true,
	icon: <img src={icons.not_focus.arrowRight} alt="arrow" />,
};

export default SelectDropdown;

SelectDropdown.propTypes = {
	selectedValue: PropTypes.string,
	onChange: PropTypes.string,
	Option: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	searchVisibility: PropTypes.bool,
};
