import React, { useEffect, useState } from 'react';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import Actions from '../invoiceDetailAction';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import IframeComponent from '@sharedComponent/iframe-component';

const ViewInvoiceProof = ({ openReceiptProof, setOpenReceiptProof, receiptId, setReceiptId }) => {
	const dispatch = useDispatch();

	const { invoiceReceiptLoading, invoiceReceiptUrl } = useSelector((state) => state?.invoiceState) || {};
	const initState = { url: null, type: null };
	const [file, setFileUrl] = useState(initState);

	useEffect(() => {
		if (receiptId) {
			dispatch(Actions.creators.invoiceReceiptDownloadStart(receiptId));
		}
	}, [receiptId]);

	useEffect(() => {
		if (invoiceReceiptUrl) {
			setFileUrl((file) => ({
				...file,
				...{ type: invoiceReceiptUrl?.type, url: invoiceReceiptUrl ? window.URL.createObjectURL(invoiceReceiptUrl) : null },
			}));
		}
	}, [invoiceReceiptUrl]);

	const customStyles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 9,
		},
		content: {
			position: 'relative',
			top: '0px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'auto',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			boxShadow: 'none',
		},
	};

	return (
		<Modal isOpen={openReceiptProof} style={customStyles} centered className="view-submited-invoice-proof">
			<div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
				<ImageComponent
					src={icons.not_focus.Close}
					style={{
						cursor: 'pointer',
						height: '25px',
						width: '25px',
						filter: 'invert(100%) sepia(90%) saturate(2%) hue-rotate(337deg) brightness(104%) contrast(101%)',
					}}
					onClick={() => {
						setOpenReceiptProof(false);
						setFileUrl(initState);
						setReceiptId(null);
					}}
				/>
			</div>
			<div style={{ width: '40vw', height: '70vh', backgroundColor: '#FFFFFF' }} id="view-invoice-proof">
				{file?.type !== 'image/jpeg' || file?.type !== 'image/png' || file?.type !== 'image/jpg' ? (
					<IframeComponent src={file?.url} loading={invoiceReceiptLoading} height="100%" width="100%" />
				) : (
					<ImageComponent src={file?.url} style={{ width: '100%', height: 'auto' }} />
				)}
			</div>
		</Modal>
	);
};
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(ViewInvoiceProof);
