import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './sagas/index';
import SetUpFunctional from './components/setup-functional';
function SetUpMain() {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<SetUpFunctional />
		</div>
	);
}
const ReducedScreen = withReducer('setUpScreenReducer', reducer, saga)(SetUpMain);
export default ReducedScreen;
