import React, { useState, useEffect } from 'react';
import { DotLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import Input from '@sharedComponent/text-field/index';
import EdenredTypo from '@sharedComponent/typography';
import './reconciliation-card.scss';

export default function autoreconcillationcard({
	accNumber,
	showTransferCode,
	setShowTransferCode,
	page = undefined,
	createRakankAccDetails,
	errorMessage,
	transferCode,
	postLoader,
	postRakBankThroughClickHere,
}) {
	return (
		<div className="recons-card-container">
			<div className="recons-card-section">
				<div className="recons-account-container">
					{false ? (
						<DotLoader size={50} color={'#e91d24'} loading />
					) : (
						<div className="rcons-card-section">
							<div className="account-title-section">
								<EdenredTypo className="account-title">{showTransferCode ? 'Transfer Code' : 'RAKBANK Account Number'}</EdenredTypo>
							</div>
							{showTransferCode ? (
								<div className="recons-account-number">
									<EdenredTypo className="account-style">{transferCode}</EdenredTypo>
								</div>
							) : (
								<div className="editable-recons-account-number">
									<Input
										value={accNumber}
										isloading={postLoader}
										placeholder="Enter RakBank Account Number"
										onChange={(e) => (e.target.value.length < 14 ? createRakankAccDetails(e.target.value) : null)}
										type={'number'}
										className={errorMessage ? 'reconc-account-input-error' : 'reconc-account-input'}
										edit={true}></Input>
									{errorMessage && <div className="error-container">Account Number must be 13 digits.</div>}
								</div>
							)}
						</div>
					)}
				</div>
				<div className="reconc-text-container">
					<div className="rcons-text-section">
						{showTransferCode ? (
							<>
								{page == 'bankdetail' && (
									<div className="recoci-text">
										Enter this number as your reference on your business banking portal while sending your salary funds to us so
										we can automatically update your balance and process salaries.{' '}
									</div>
								)}
								<div className="recoci-text">
									Using a RAKBANK Corporate Account to send us salary funds? You don’t need a transfer code,{' '}
									<Link to="#" onClick={() => postRakBankThroughClickHere(false)} className="clickHere_text">
										Click Here
									</Link>
								</div>
							</>
						) : (
							<div className="recoci-text">
								Don’t have a RAKBANK Corporate Account? We will generate a transfer code you can enter while sending us salary funds{' '}
								<Link to="#" onClick={() => postRakBankThroughClickHere(true)} className="clickHere_text">
									Click Here
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
