const GET_APPROVALWORKFLOW_START = 'GET_APPROVALWORKFLOW_START';
const GET_APPROVALWORKFLOW_SUCCESS = 'GET_APPROVALWORKFLOW_SUCCESS';
const GET_APPROVALWORKFLOW_FAIL = 'GET_APPROVALWORKFLOW_FAIL';
const GET_APPROVALWORKFLOW_USER_START = 'GET_APPROVALWORKFLOW_USER__START';
const GET_APPROVALWORKFLOW_USER__SUCCESS = 'GET_APPROVALWORKFLOW_USER__SUCCESS';
const GET_APPROVALWORKFLOW_USER__FAIL = 'GET_APPROVALWORKFLOW_USER__FAIL';
const GET_APPROVALWORKFLOW_SUBMIT_START = 'GET_APPROVALWORKFLOW_SUBMIT_START';
const GET_APPROVALWORKFLOW_SUBMIT_SUCCESS = 'GET_APPROVALWORKFLOW_SUBMIT_SUCCESS';
const GET_APPROVALWORKFLOW_SUBMIT_FAIL = 'GET_APPROVALWORKFLOW_SUBMIT_FAIL';
const HANDLE_SAVE_POPUP = 'HANDLE_SAVE_POPUP';
const SAVED_SUCCEESSFUL_POPUP = 'SAVED_SUCCEESSFUL_POPUP';

const getApprovalWorkflowStart = () => {
	return {
		type: GET_APPROVALWORKFLOW_START,
	};
};
const getApprovalWorkflowSuccess = (response) => {
	return {
		type: GET_APPROVALWORKFLOW_SUCCESS,
		response,
	};
};
const getApprovalWorkflowFail = (error) => {
	return {
		type: GET_APPROVALWORKFLOW_FAIL,
		error,
	};
};
const getApprovalWorkflowUserStart = () => {
	return {
		type: GET_APPROVALWORKFLOW_USER_START,
	};
};
const getApprovalWorkflowUserSuccess = (response) => {
	return {
		type: GET_APPROVALWORKFLOW_USER__SUCCESS,
		response,
	};
};
const getApprovalWorkflowUSerFail = (error) => {
	return {
		type: GET_APPROVALWORKFLOW_USER__FAIL,
		error,
	};
};
const getApprovalWorkflowSubmitStart = (payload) => {
	return {
		type: GET_APPROVALWORKFLOW_SUBMIT_START,
		payload,
	};
};
const getApprovalWorkflowSubmitSuccess = (response) => {
	return {
		type: GET_APPROVALWORKFLOW_SUBMIT_SUCCESS,
		response,
	};
};
const getApprovalWorkflowSubmitFail = (error) => {
	return {
		type: GET_APPROVALWORKFLOW_SUBMIT_FAIL,
		error,
	};
};
const handleSavePopUp = (flag) => {
	return {
		type: HANDLE_SAVE_POPUP,
		flag,
	};
};
const savedSucceessfulPopUp = (flag) => {
	return {
		type: SAVED_SUCCEESSFUL_POPUP,
		flag,
	};
};

export default {
	types: {
		GET_APPROVALWORKFLOW_START,
		GET_APPROVALWORKFLOW_SUCCESS,
		GET_APPROVALWORKFLOW_FAIL,
		GET_APPROVALWORKFLOW_USER_START,
		GET_APPROVALWORKFLOW_USER__SUCCESS,
		GET_APPROVALWORKFLOW_USER__FAIL,
		GET_APPROVALWORKFLOW_SUBMIT_START,
		GET_APPROVALWORKFLOW_SUBMIT_SUCCESS,
		GET_APPROVALWORKFLOW_SUBMIT_FAIL,
		HANDLE_SAVE_POPUP,
		SAVED_SUCCEESSFUL_POPUP,
	},
	creators: {
		getApprovalWorkflowStart,
		getApprovalWorkflowSuccess,
		getApprovalWorkflowFail,
		getApprovalWorkflowUserStart,
		getApprovalWorkflowUserSuccess,
		getApprovalWorkflowUSerFail,
		getApprovalWorkflowSubmitStart,
		getApprovalWorkflowSubmitSuccess,
		getApprovalWorkflowSubmitFail,
		handleSavePopUp,
		savedSucceessfulPopUp,
	},
};
