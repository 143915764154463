export const cardUsuageDatas = [
    {
        question: `How do my employees activate their cards?`,
        answer: [
            {
                label: 'Your employees can activate their cards by downloading the C3Pay Mobile App from the Play Store or App Store and scanning their Emirates ID on the app.',
                link: '',
            },
        ],
    },
    {
        question: 'Where are C3 ATMs installed?',
        answer: [
            {
                label: 'A full list of C3 ATMs can be found ',
                link: 'https://eae-clientonboardingui-web-p.azurewebsites.net/atmLocations',
                target: '_blank',
            }
        ],
    }
]