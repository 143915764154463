import React from 'react';
import Table from '@pages/DesignSystem/Table/Table';
import { Card, Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { Link } from 'react-router-dom';
import ETypo from '@pages/DesignSystem/Typo';
import { DotLoader } from 'react-spinners';
import { icons } from '@assets/icons';
import moment from 'moment';
import Chips from '@pages/DesignSystem/Chips';
import { getCardStatus } from '@helpers';

const CardOrderHistoryCard = ({ isCardHistoryLoading, cardOrderTotalData, totalCardRequest }) => {
	const dataSourc = [
		...totalCardRequest?.map((history, index) => ({
			key: index,
			...history,
			Cards: history?.noOfCards,
			Ordered: history?.orderPlaced,
			Status: history?.status,
		})),
	];
	const columns = [
		{
			title: 'Cards',
			dataIndex: 'Cards',
			key: 'Cards',
			render: (Cards, card) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Link to={{ pathname: '/employees/card/order/track/status', state: { orderStatus: card } }}>
							<ETypo b1 style={{ color: '#0E5274', textDecoration: 'underline', marginBottom: '0px' }}>
								{Cards} Cards
							</ETypo>
						</Link>
					</div>
				);
			},
		},
		{
			title: 'Ordered on',
			dataIndex: 'Ordered ',
			key: 'orderPlaced',
			render: (orderPlaced, Ordered) => {
				let a = Ordered.Ordered.replace('at ', '');
				let b = moment(a).format('DD/MMM/YYYY');
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<p style={{ color: '#181919', marginBottom: '0px' }}>{b}</p>
					</div>
				);
			},
		},
		{
			title: 'Status',
			dataIndex: 'Status',
			key: 'Status',
			render: (Status) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Chips color={getCardStatus(Status)?.color}>{getCardStatus(Status)?.status}</Chips>
					</div>
				);
			},
		},
	];
	return (
		<Col xl={12} lg={24} md={24} sm={24} xs={24} className="card_order_history_column">
			<Card className="card_order_history card-resp">
				<Row className="card_order_history_title_row">
					<div className="card_order_title">
						<div className="card_order_div">
							<ImageComponent src={icons.focus_state.AddCard} />
						</div>
						<EdenredTypo style={{ color: '#181919' }}>Card Order History</EdenredTypo>
					</div>
					<Link to="/employees/card/order/status">
						<ETypo b1="b1" style={{ textDecoration: 'underline' }} color="#0E5274">
							View All
						</ETypo>
						<ImageComponent src={icons.focus_state.RightClrArrow} style={{ paddingLeft: '10px', paddingBottom: '4px' }} />
					</Link>
				</Row>
				{isCardHistoryLoading ? (
					<Col
						style={{
							minHeight: '120px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
						}}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				) : (
					<Col xl={24} lg={24} md={24} className="card_order_history_details_section">
						{cardOrderTotalData === 0 ? (
							<Row
								className="card_order_history_detail_row"
								style={{
									height: '120px',
									minHeight: '120px',
									maxHeight: '120px',
									display: 'flex',
									alignItems: 'center',
								}}>
								<Col xs={23} sm={23} style={{ display: 'grid', placeItems: 'center' }}>
									<ImageComponent src={icons.focus_state.Nocard} />
									<p className="payroll_status_head">No cards ordered</p>
									<p className="payroll_status_text" style={{ display: 'flex', marginBottom: '0px' }}>
										Order new cards
										<Link to="/run-payroll">
											<p
												style={{
													color: '#0E5274',
													marginLeft: '4px',
													textDecoration: 'underline',
												}}>
												HERE.
											</p>
										</Link>
									</p>
								</Col>
							</Row>
						) : (
							<Table columns={columns} data={dataSourc} />
						)}
					</Col>
				)}
			</Card>
		</Col>
	);
};

export default CardOrderHistoryCard;
