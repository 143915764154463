import React from 'react';
import propTypes from 'prop-types';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import './progress.scss';
const Progressbar = () => {
	const array = [
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.progresstick,
			isprogess: false,
			status: 'success',
			textcolor: 'bluecolor',
		},
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.progressdanger,
			isprogess: false,
			status: 'error',
			textcolor: 'bluecolor',
		},
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.progresspending,
			isprogess: false,
			status: 'pending',
			textcolor: 'bluecolor',
		},
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.greytick,
			isprogess: false,
			status: 'disable',
			textcolor: 'disable',
		},
	];
	return (
		<div style={{ display: 'flex' }}>
			{array.map((items) => (
				<div className="progessbar-outer">
					<div className="progessbar">
						<div className={`imagebox ${items?.status}`}>
							<ImageComponent src={items?.icon} className="icon" />
						</div>
						<div className="border">
							<div className={`border1 ${items?.status}`}></div>
							<div className={`border1 ${items.status}`}></div>
							<div className={`border1 ${items.status}`}></div>
							<div className={`border1 ${items.status}`}></div>
						</div>
					</div>
					<div>
						<p className={items.status !== 'disable' ? 'title' : 'disable-title'}>{items.title}</p>
						<p className={items.status !== 'disable' ? 'desc' : 'disable'}>{items.Description}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Progressbar;
Progressbar.propTypes = {
	value: propTypes.object,
};
