import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { getMonthLabelFromNumber, numberWithCommas, checkHrSubscription } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import Actions from '@sharedComponent/app-action-reducer/action';
import PayrollAction from '../../../payroll-plus-new/action';
import { monthsNames } from '@constants/index';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import PayrollService from 'src/services/payroll-service';
import ClevertapReact from 'src/utils/clever-tap';
import { DotLoader } from 'react-spinners';
import { UnemploymentInsuranceBanner } from '@pages/dashboard/main-dashboard/components/unemployment-insurance-banner';
import { UnemploymentInsuranceAvoidFine } from '@pages/dashboard/main-dashboard/components/unemployment-insurance-avoid-fine';

const SubmitPayroll = ({ history }) => {
	const _payrollService = new PayrollService();
	const [loading, setloading] = useState(false);
	const [getPayrollDataLoading, setGetPayrollDataLoading] = useState(true);
	const [getPayrollSummaryLoading, setGetPayrollSummaryLoading] = useState(false);
	const [isUserExists, setIsUserExists] = useState(true);
	const [payrollRunPopup, setPayrollRunPopup] = useState(false);
	const [showSummaryPayroll, setShowSummaryPayroll] = useState(false);
	const dispatch = useDispatch();
	const [submit_payroll_details, setSubitdetail] = useState([
		{
			content: 'Payroll amount',
			value: '',
			key: 0,
		},
		{
			content: 'Payroll type',
			value: '',
			key: 1,
		},
		{
			content: 'Payroll date',
			value: '',
			key: 2,
		},
		{
			content: 'Employees',
			value: '',
			key: 3,
		},
	]);
	const [stateYes, setStateYes] = useState([]);
	const { state } = useLocation();
	const { invoiceDetails, unEmploymentBannerData } = useSelector((state) => state?.sharedStates);
	const getPayrollSummaryData = () => {
		let corprateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		setGetPayrollSummaryLoading(true);
		_payrollService
			.getPayrollSummaryUrl(corprateId, Number(state?.monthYearSelect?.month), Number(state?.monthYearSelect?.year))
			.then((response) => {
				setGetPayrollDataLoading(false);
				setGetPayrollSummaryLoading(false);
				let data = submit_payroll_details?.map((val, ind) => {
					if (ind === 0) {
						val.value = response?.data?.totalSalary ? 'AED ' + numberWithCommas(response?.data?.totalSalary) : 0;
					} else if (ind === 1) {
						val.value = state?.channelOptions?.title || '';
					} else if (ind === 2) {
						val.value = getMonthLabelFromNumber(parseInt(state?.monthYearSelect?.month) - 1) + ' ' + state?.monthYearSelect?.year;
					} else if (ind === 3) {
						val.value = response?.data?.totalEmployee || 0;
					}
					return val;
				});
				setSubitdetail([...data]);
			})
			.catch((error) => {
				setGetPayrollSummaryLoading(false);
			});
	};
	const getIsUserClaimExists = () => {
		let corprateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const body = {
			corprateId: corprateId,
			type: 'http://edenred.ae/claims/payroll/permission',
			value: 'Approve.Payroll.Requests',
		};
		_payrollService
			.getUserClaimsExist(body)
			.then((response) => {
				setIsUserExists(response?.data);
			})
			.catch((error) => {});
	};
	useEffect(() => {
		getPayrollSummaryData();
		getIsUserClaimExists();
		let value = state?.endofService?.map((item) => {
			item?.endofservice && stateYes.push(item);
		});
	}, []);
	const isSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const handlesubmit = async () => {
		const format1 = 'YYYY-MM-DDThh:mm:ss';
		const { selectDate, payrollRunDate, monthYearSelect } = state;
		const runPayrollLaterDate = selectDate;
		const dateTime = payrollRunDate ? moment(payrollRunDate).format(format1) : moment(runPayrollLaterDate || '').format(format1);
		let body = await {
			payrollRunDate: dateTime,
			payrollChannel: submit_payroll_details?.[1]?.value || null,
			year: parseInt(monthYearSelect?.year) || null,
			month: parseInt(monthYearSelect?.month) || null,
			isPayrollPlusProcess: isSubscription,
		};
		setloading(true);
		await _payrollService
			.submitPayroll(body)
			.then((response) => {
				setloading(false);
				ClevertapReact.event('Submit_Payroll', {
					Amount: submit_payroll_details?.[0]?.value || null,
					Type: submit_payroll_details?.[1]?.value || null,
					Month: parseInt(monthYearSelect?.month) || null,
					Year: parseInt(monthYearSelect?.year) || null,
					No_of_employees: submit_payroll_details?.[3]?.value || null,
					Submitted_time: new Date(dateTime).toISOString(),
					EndofService: stateYes?.length > 0 ? 'Yes' : 'No',
				});
				history.push({ pathname: '/payroll/run/channel/next_steps', ...state });
				dispatch(Actions.creators.getPendingStatusStart());
				dispatch(PayrollAction.creators.changeWpsNonWpsPopup(false));
			})
			.catch((error) => {
				setloading(false);
			});
	};
	const handlePopup = (param) => {
		param === 'yes' && history.push(state?.type === 'payrollPlus' ? '/payroll-plus' : '/run-payroll');
		setPayrollRunPopup(false);
	};
	const doItLaterNavigation = () => {
		setShowSummaryPayroll(true);
	};

	return (
		<>
			<EdenredHeader />
			{/* To display banner for non-subscribed unemployment insurance corprates and only for WPS type
			 * Hiding temporarily
			 */}
			{/* {unEmploymentBannerData?.data?.displayBanner && !showSummaryPayroll && state?.channelOptions?.title === 'WPS' ? (
				<div style={{ padding: '40px 40px 0 40px', display: 'grid', rowGap: '20px' }} className="fade-right">
					<UnemploymentInsuranceBanner {...{ doItLaterNavigation }} />
					<UnemploymentInsuranceAvoidFine />
				</div>
			) : ( */}
			<Row className="submit_payroll_container fade-right">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="submit_payroll_header">
					<ETypo bold h2>
						Review Payroll
					</ETypo>
					<div className="payroll_review_button">
						<EdenredButtons btnType="secondary" onClick={() => setPayrollRunPopup(true)}>
							Cancel
						</EdenredButtons>
						<EdenredButtons loading={loading} onClick={handlesubmit} disabled={getPayrollSummaryLoading}>
							Submit Payroll
						</EdenredButtons>
					</div>
				</Col>
				{getPayrollDataLoading ? (
					<Col style={{ minHeight: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				) : (
					<div className="payroll_review_card">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="submit_payroll_description">
							<ETypo light b1 color="#484B52">
								Please confirm whether the following information is correct before submitting payroll.
							</ETypo>
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<Row gutter={24} className="submit_payroll_detail_section">
								<Col xl={14} lg={20} md={20} sm={24} xs={24} className="submit_payroll_detail">
									{submit_payroll_details?.map((detail, index) => (
										<Col xl={24} key={index} lg={24} md={24} sm={24} xs={24} className="submit_payroll_detail_table">
											<ETypo medium b1 className="submit_payroll_detail_content">
												{detail?.content}
											</ETypo>
											<ETypo light b1 color="#181919" className="submit_payroll_detail_value">
												{detail?.value}
											</ETypo>
										</Col>
									))}
								</Col>
							</Row>
						</Col>
						{!isUserExists && (
							<Col xl={24} lg={24} md={24} sm={24} xs={24} className="submit_payroll_button">
								<div style={{ marginTop: '20px' }}>
									<p className="user_exists">Payroll will be processed immediately as you've set no approval for your payroll.</p>
									<p className="user_exists">you can set approvals for payroll in Team Permissions.</p>
								</div>
							</Col>
						)}
					</div>
				)}
				<Edenredmodal
					{...{
						isOpen: payrollRunPopup,
						title: 'Do You Want to Cancel Run Payroll?',
						onOkayButton: 'Yes',
						onCancelButton: 'No',
						onOkay: () => handlePopup('yes'),
						onCancel: () => handlePopup('no'),
					}}
				/>
			</Row>
			{/* )} */}
		</>
	);
};

export default SubmitPayroll;
