import ContentLoader from '@sharedComponent/content-loader';
import React, { useEffect } from 'react';
import { icons } from 'src/assets/icons';
import './_table.scss';

const formatColumn = (columns) => {
	const result = columns?.map((column) => ({
		...column,
		currentSort: column?.currentSort || 'default',
	}));
	return result;
};

const useSortableData = (items, config = null) => {
	const [sortConfig, setSortConfig] = React.useState(config);
	const sortedItems = React.useMemo(() => {
		let sortableItems = [...items];

		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [items, sortConfig]);
	const requestSort = (key) => {
		let direction = 'ascending';
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};
	return { items: sortedItems, requestSort, sortConfig };
};

const Table = ({ columns = [], data = [], tableLoading = false, sticky = false, noOfStickyColumn = 4, onRowSelectedColor = null }) => {
	const [columnState, setColumnState] = React.useState(formatColumn(columns));
	const { items, requestSort } = useSortableData(data);
	const [columnSortState, setColumnSortState] = React.useState(() => {
		if (columns.length > 0) {
			return columns.map((_, index) => {
				return { ascending: true, descending: false, index: index };
			});
		} else {
			return [];
		}
	});

	const sortmethod = (column, columnIndex) => {
		requestSort(column.dataIndex);
		setColumnSortState((prevState) => {
			return prevState.map((colSortState, index) => {
				if (columnIndex === index) {
					return { ascending: false, descending: true, index: index };
				} else {
					return { ...colSortState, ascending: true, descending: true };
				}
			});
		});
	};

	const sortdesmethod = (column, columnIndex) => {
		requestSort(column.dataIndex);
		setColumnSortState((prevState) => {
			return prevState.map((colSortState, index) => {
				if (columnIndex === index) {
					return { ascending: true, descending: false, index: index };
				} else {
					return { ...colSortState, ascending: true, descending: true };
				}
			});
		});
	};

	useEffect(() => {
		setColumnState(formatColumn(columns));
	}, [columns]);
	return (
		<table className="edenred-table">
			<thead>
				<tr>
					{columnState?.map((column, columnIndex) => {
						return (
							<th
								className={`${sticky && columnIndex < noOfStickyColumn ? 'sticky_table_header' : ''} index_${columnIndex + 1}`}
								width={column?.width}
								style={{ textAlign: column?.align ? column?.align : 'left' }}
								key={columnIndex}>
								<div
									onClick={() => {
										if (column && column.onHeaderCell) {
											column.onHeaderCell();
										}
										return column?.sort && columnSortState?.[columnIndex]?.ascending === true
											? column?.sort
												? sortmethod(column, columnIndex)
												: null
											: column?.sort
												? sortdesmethod(column, columnIndex)
												: null;
									}}
									style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div>{column?.title}</div>
									{column?.title === '' || !column?.sort ? null : (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{column?.sort && columnSortState?.[columnIndex]?.ascending === true ? (
												<img
													src={icons.not_focus.Upwhite}
													alt=""
													style={{ width: '9px', height: '12px', marginLeft: '3px' }}
													onClick={() => (column?.sort ? sortmethod(column, columnIndex) : null)}
												/>
											) : (
												<img
													src={icons.not_focus.up}
													alt=""
													style={{ width: '15px', height: '12px' }}
												// onClick={() => (column?.sort ? sortmethod(column, columnIndex) : null)}
												/>
											)}

											{column?.sort && columnSortState?.[columnIndex]?.descending === true ? (
												<img
													src={icons.not_focus.Downwhite}
													alt=""
													style={{ width: '9px', height: '12px', marginLeft: '3px' }}
													onClick={() => (column?.sort ? sortdesmethod(column, columnIndex) : null)}
												/>
											) : (
												<img
													src={icons.not_focus.down}
													alt=""
													style={{ width: '15px', height: '12px' }}
												// onClick={() => (column?.sort ? sortdesmethod(column, columnIndex) : null)}
												/>
											)}
										</div>
									)}
								</div>
							</th>
						);
					})}
				</tr>
			</thead>
			{tableLoading ? (
				<td
					colSpan={columnState.length}
					style={{
						width: '100%',
						color: 'white',
						height: '200px',
						columnSpan: '5',
					}}>
					<div
						style={{
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<ContentLoader />
					</div>
				</td>
			) : (
				<tbody style={{ width: '100%' }}>
					{items?.map((data, rowIndex, array) => (
						<tr key={rowIndex} className="table_row">
							{columnState?.map(({ dataIndex, render }, index) => {
								return (
									<td
										key={index}
										style={
											typeof onRowSelectedColor === 'string' && data?.isSelected === true
												? {
													backgroundColor: onRowSelectedColor,
												}
												: {}
										}
										className={`table_column ${sticky && index < noOfStickyColumn ? 'sticky_table_column ' : ''} index_${index + 1}`}>
										{render?.(data[dataIndex], data, rowIndex, array) || data[dataIndex]}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			)}
		</table>
	);
};

export default React.memo(Table);
