import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';

class UnemploymentService {
    baseUrl = `${DOMAIN_URL}/UnemploymentInsurance/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`;
    checkEmployeeSubscribed() {
        return Axios.get(`${this.baseUrl}/subscription-status`);
    }
    verifyEmployeeInsurance(body) {
        return Axios.post(`${this.baseUrl}/subscription-verification`, body);
    }
}

export default UnemploymentService;
