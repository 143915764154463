import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { DATE_FORMAT } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import { checkForRules, getBorderClassColor } from '@helpers';
import Alert from '../../../../../assets/img/Alert.png';
import Warning from '../../../../../assets/img/Warning.png';
import docImg from '../../../../../assets/img/Missingdates.png';
import Navigation from '../../../../../assets/img/Navigation/Vector.png';
import Navigationup from '../../../../../assets/img/Vector(up).png';
import './customaccordian.scss';

function Accordian({ index, detail, setSaveButtonEnabled, totalDetails, handleDetailsChange, setActiveIndex }) {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [dates, setDates] = useState({
		labourCardExpiryDate: '',
		emiratesExpiryDate: '',
		visaExpiryDate: '',
		passportExpiryDate: '',
	});

	// console.log('totalDetails', totalDetails);

	let id = detail?.employeeId;

	const hanldePayTableData = (value, label, emp_id) => {
		console.log('objectobject', value, label, emp_id);

		handleDetailsChange(value, label, emp_id);
		const formatValue = value === 'Invalid date' ? '' : value;
		if (id === emp_id) {
			console.log(id);
			{
				detail[label] = formatValue;
			}
			setDates(detail[label]);
			setDates({ ...dates, label: value });
			setSaveButtonEnabled(true);
		}
	};

	const showdate = moment().format(DATE_FORMAT.YYYY_MM_DD);

	useEffect(() => {
		[detail].map((data) => {
			setDates({
				...dates,
				labourCardExpiryDate: moment(detail?.labourCardExpiryDate).format('YYYY-MM-DD'),
				emiratesExpiryDate: moment(detail?.emiratesExpiryDate).format('YYYY-MM-DD'),
				visaExpiryDate: moment(detail?.visaExpiryDate).format('YYYY-MM-DD'),
				passportExpiryDate: moment(detail?.passportExpiryDate).format('YYYY-MM-DD'),
			});
		});
	}, [detail]);

	const images = {
		expired: Alert,
		missingdates: docImg,
		in1month: Warning,
		in3months: Warning,
		in6months: Warning,
	};

	const renderIcons = (detail) => {
		const filteredRules = checkForRules(detail, showdate)?.filter((data) => data.rulePass);
		const leftPosition = filteredRules.length > 1 ? 20 : 7;
		return (
			<>
				{(filteredRules || [])?.reverse()?.map(({ img }, i, arr) => (
					<ImageComponent key={i} style={{ left: leftPosition - i * 13, zIndex: 0 }} src={img} className="alertIcon" />
				))}
			</>
		);
	};

	const pickerOptions = {
		options: {
			allowInput: true,
			dateFormat: 'd/m/Y',
			disableMobile: true,
		},
		placeholder: `${DATE_FORMAT.DD_MM_YYYY}`,
	};

	return (
		<div className="forContainer">
			<Row className="nameSymbol">
				<Col xs={15}>
					<div>
						<p className="forAlign">{detail?.name}</p>
					</div>
					<div>
						<p>{detail?.employeeId}</p>
					</div>
				</Col>
				<Col xs={4} className="allSigns">
					{renderIcons(detail)}
				</Col>
			</Row>
			<Row className={`navigationIcon${isCollapsed ? '1' : ''}`}>
				<img src={Navigation} onClick={() => setIsCollapsed(!isCollapsed)} />
			</Row>
			<Row>
				{isCollapsed && (
					<div className="detail">
						<div className="outerContainer">
							<p className="Labour-headings">Labour Card</p>
							<Flatpickr
								onOpen={() => setActiveIndex(index)}
								name="labourCardExpiryDate"
								className={`laborContainer ${getBorderClassColor(detail?.labourCardExpiryDate, showdate)} table-detail`}
								defaultValue={detail?.labourCardExpiryDate ? moment(detail?.labourCardExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
								onChange={(date, value) => {
									const param1 = moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD);
									const param2 = 'labourCardExpiryDate';
									const param3 = id;
									hanldePayTableData(param1, param2, param3);
								}}
								{...pickerOptions}
							/>
						</div>
						<div className="outerContainer">
							<p className="Labour-headings">Emirates ID</p>
							<Flatpickr
								onOpen={() => setActiveIndex(index)}
								name="emiratesExpiryDate"
								className={`laborContainer ${getBorderClassColor(detail?.emiratesExpiryDate, showdate)} table-detail`}
								defaultValue={detail?.emiratesExpiryDate ? moment(detail?.emiratesExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
								onChange={(date, value) => {
									hanldePayTableData(
										moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD),
										'emiratesExpiryDate',
										id
									);
								}}
								{...pickerOptions}
							/>
						</div>
						<div className="outerContainer">
							<p className="Labour-headings">Visa</p>
							<Flatpickr
								onOpen={() => setActiveIndex(index)}
								name="visaExpiryDate"
								className={`laborContainer ${getBorderClassColor(detail?.visaExpiryDate, showdate)} table-detail`}
								defaultValue={detail?.visaExpiryDate ? moment(detail?.visaExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
								onChange={(date, value) => {
									hanldePayTableData(moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD), 'visaExpiryDate', id);
								}}
								{...pickerOptions}
							/>
						</div>
						<div className="outerContainer">
							<p className="Labour-headings">Passport</p>
							<Flatpickr
								onOpen={() => setActiveIndex(index)}
								name="passportExpiryDate"
								className={`laborContainer ${getBorderClassColor(detail?.passportExpiryDate, showdate)} table-detail`}
								defaultValue={detail?.passportExpiryDate ? moment(detail?.passportExpiryDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
								onChange={(date, value) => {
									hanldePayTableData(
										moment(value, DATE_FORMAT.DD_MM_YYYY).format(DATE_FORMAT.YYYY_MM_DD),
										'passportExpiryDate',
										id
									);
								}}
								{...pickerOptions}
							/>
						</div>
						<Row className="navigationUp">
							<img src={Navigationup} onClick={() => setIsCollapsed(!isCollapsed)} />
						</Row>
					</div>
				)}
			</Row>
		</div>
	);
}

export default Accordian;
