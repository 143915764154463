import React from 'react';
import LeaveUploadTable from '../../setup-leave-approval/leave-upload-table';
import './previewUploadedData.scss';

const PreviewUploadedData = () => {
	return (
		<div className="leaveapprove-table">
			<LeaveUploadTable {...{ isleaveApproveUpload: true, leavePath: '/leaves/leaves_to_approve', shouldHideHeader: true }} />;
		</div>
	);
};

export default PreviewUploadedData;
