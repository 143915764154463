import action from './action';
import ClevertapReact from 'src/utils/clever-tap';
import Edenredmodal from '../../DesignSystem/Edenredmodal/index';
import HeroContent from '../Component/HeroContent/HeroContent';
import React, { useEffect, useState } from 'react';
import setupAction from '../../../components/shared/app-action-reducer/action';
import SetupTitle from '../Component/SetupTitle/SetupTitle';
import Stepper from '../Component/Stepper/Stepper';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import hrFeatureAction from '../HrFeatureAccess/action';
import { icons } from 'src/assets/icons';
import '../allowance/allowance.scss';

const Deduction = ({ cleverTap = false }) => {
	const initialDeductionList = [
		{
			name: 'Absent',
			isActive: false,
			id: '',
		},
		{
			name: 'Loan',
			isActive: false,
			id: '',
		},
		{
			name: 'Traffic Fine',
			isActive: false,
			id: '',
		},
		{
			name: 'Unpaid Leave',
			isActive: false,
			id: '',
		},
	];

	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const location = useLocation();
	const stepperItems = location?.state?.editUploadSpreadsheet
		? [
				{
					name: '1 - Allowance',
					isActive: false,
					isCompleted: true,
					navigate: '/hr-features/allowance',
					editUploadSpreadsheet: true,
				},
				{
					name: '2 - Additions',
					isActive: false,
					isCompleted: true,
					navigate: '/hr-features/addition',
					editUploadSpreadsheet: true,
				},
				{
					name: '3 - Deductions',
					isActive: true,
					isCompleted: false,
					navigate: '/hr-features/deduction',
					editUploadSpreadsheet: true,
				},
		  ]
		: [
				{
					name: '1 - Allowance',
					isActive: false,
					isCompleted: true,
					navigate: '/new-hr-features/allowance',
				},
				{
					name: '2 - Additions',
					isActive: false,
					isCompleted: true,
					navigate: '/new-hr-features/addition',
				},
				{
					name: '3 - Deductions',
					isActive: true,
					isCompleted: false,
					navigate: '/new-hr-features/deduction',
				},
				{
					name: '4 - Upload Data (Optional)',
					isActive: false,
					isCompleted: false,
					navigate: '/new-hr-features/uploadPayrollDetails',
				},
		  ];
	// const storeData = useSelector((state) => state?.hrSetup?.deduction?.data);
	const storeData = useSelector((state) => state?.hrSetup?.corporateSettings?.deductionTypes);
	// const convertedList = storeData?.map((item) => ({ id: item?.id, name: item.name, isActive: true })); // adding checked property to the list(checkbox status)
	let mergeList = [];
	if (storeData) {
		const uniqueList = initialDeductionList.filter((item) => !storeData.some((element) => element.name === item.name)); // removing same categories
		mergeList = [...uniqueList, ...storeData]; // merging initial list with backend data
	} else {
		mergeList = [...initialDeductionList];
	}
	const [modalOpen, setModalOpen] = useState({ state: false, value: '' });
	const dispatch = useDispatch();
	const [deductionList, setDeductionList] = useState([...mergeList]);
	const [postList, setPostList] = useState([]);
	const [newDeductionItem, setNewDeductionItem] = useState('');
	const [showModal, setShowModal] = useState('');
	const { deduction, deductionEmployeeStatus, loading } = useSelector((state) => state?.hrSetup);

	const history = useHistory();
	const [isAlreadyExists, setIsAlreadyExists] = useState(false);

	const handleCheckboxState = (isSelected, value, isActive, isEmployeeExists) => {
		ClevertapReact.event(cleverTap ? 'V1_1_deductions_AddNew' : 'V1_1_UploadSpreadEdit_deductions_AddNew');
		if (isActive === true && isEmployeeExists === true) {
			setModalOpen({ state: true, value: modalOpen?.value });
		} else {
			// changing checkbox state
			const deductionListCopy = [...deductionList]; // storing deduction list
			const indexOfValue = deductionListCopy.findIndex((item) => item.name === value); // finding index of the element
			if (indexOfValue > -1) {
				deductionListCopy[indexOfValue].isActive = isSelected;
				setDeductionList(deductionListCopy);
			}
		}
	};

	const addNewDeduction = (value = '') => {
		ClevertapReact.event('V1_1_deductions_AddNew');
		// add new category to the deduction list
		setShowModal('AddNewDeduction');
		const isValueNewItem = !deductionList.some((item) => item.name == value); // checking value is unique or not

		if (value !== '' && isValueNewItem) {
			setDeductionList((prev) => [...prev, { id: '', name: value.trim(), isActive: true }]);
			setNewDeductionItem('');
			setShowModal('');
		}
	};
	const closeModal = () => {
		setShowModal('');
		setNewDeductionItem('');
		setIsAlreadyExists(false);
	};
	const saveChanges = () => {
		const filteredDeductionList = [...deductionList].filter((item) => item?.isActive === true); // storing checked item to the new variable
		const isEdited = JSON.stringify(storeData.filter((item) => item?.isActive === true)) !== JSON.stringify(filteredDeductionList); // comparing backend data with user selected list
		const finalList = filteredDeductionList.map((item) => ({ name: item?.name, id: item?.id || '' })); // converting list to match backend list format
		setPostList([...finalList]);
		if (finalList.length > 0) {
			var cleverTapData = finalList.map((data) => data.name);
		}
		const cleverTapProperties = {
			DeductionTypes: cleverTapData.toString(),
		};
		ClevertapReact.event(
			cleverTap ? 'V1_1_deductions_Next' : 'V1_1_UploadSpreadEdit_deductions_Save',
			cleverTapData.length > 0 ? cleverTapProperties : null
		);
		if (isEdited && finalList && finalList.length > 0) {
			dispatch(action.creators.postDeduction(finalList));
		}
		if (finalList && finalList.length > 0) {
			dispatch(setupAction.creators.postHrSetupStatus({ hrSetupDone: true }));
			dispatch(setupAction.creators.postHrSetupStatus({ payrollPlusBanner: true }));
			history.push(location?.state?.editUploadSpreadsheet ? '/uploadSpreadsheetPlus' : '/new-hr-features/uploadPayrollDetails');
		} else {
			setShowModal('SkipOrSaveChanges');
		}
	};
	const onSkipForNow = () => {
		ClevertapReact.event(cleverTap ? 'V1_1_deductions_PopSkip' : 'V1_1_UploadSpreadEdit_deductions_PopSkip');
		dispatch(action.creators.postDeduction(postList));
		history.push(location?.state?.editUploadSpreadsheet ? '/uploadSpreadsheetPlus' : '/new-hr-features/uploadPayrollDetails');
	};

	const handleChange = (event) => {
		const value = event?.target?.value;
		setNewDeductionItem(value);
		let isUnique = deductionList.some((item) => item?.name.toLowerCase() === value.trim().toLowerCase());
		if (isUnique === false) {
			setIsAlreadyExists(false);
		} else {
			setIsAlreadyExists(true);
		}
	};

	useEffect(() => {
		dispatch(hrFeatureAction.creators.getCorporateSettings());
		dispatch(action.creators.getDeduction());
	}, []);

	return (
		<>
			<main className="main-wrapper">
				<div className="container_title_box">
					<SetupTitle
						title="What type of DEDUCTIONS do you have?"
						rightBtnClick={saveChanges}
						rightBtnRightIcon={focus_state_icons.RightArrow}
						rightBtnText={cleverTap ? 'Next' : 'Save & Finish'}
						rightBtnType="primary"
					/>
				</div>
				<div className="hero-content">
					<Stepper
						listOfTabs={stepperItems}
						// handleTabClick={} (under discussion)
					/>
					<HeroContent
						title="Deductions are a type of variable pay that is subtracted from the total salary. Please select or add the types of deductions that are applied to your employees' salaries during the payroll run."
						list={deductionList}
						handleCheckboxState={handleCheckboxState}
						onAddNewItem={() => addNewDeduction(newDeductionItem)}
						addNewItemButtonTitle={'Add New Deduction'}
						loader={deduction?.loading}
					/>
				</div>
				{modalOpen?.state && deduction?.loading === false && (
					<Edenredmodal
						title="Warning"
						desc={`You cannot unselect ${modalOpen?.value} because payroll has been processed with this deduction`}
						onOkay={() => setModalOpen({ state: false, value: '' })}
						onOkayButton="Okay"
						isOpen={modalOpen?.state}
						imageSrc={icons.not_focus?.info2}
						heart={true}
					/>
				)}
			</main>
			{showModal === 'SkipOrSaveChanges' && (
				<Edenredmodal
					title="You didn’t add new Deduction"
					desc="Adding deduction types will help you to better track and manage employee pay."
					onOkay={() => {
						ClevertapReact.event(cleverTap ? 'V1_1_deductions_PopAdd' : 'V1_1_UploadSpreadEdit_deductions_PopAdd');
						setShowModal('');
					}}
					onCancelButton="Skip For Now"
					onOkayButton="Add Deduction"
					onCancel={onSkipForNow}
					isOpen={showModal !== ''}
					imageSrc={focus_state_icons?.warningNew}
					heart={true}
				/>
			)}
			{modalOpen?.state && deduction?.loading === false && (
				<Edenredmodal
					title="Deduction Cannot be Unselected"
					desc={`Deduction is a part of your employees' contract and cannot be edited `}
					onOkay={() => setModalOpen({ state: false, value: '' })}
					onOkayButton="Ok, Got it"
					isOpen={modalOpen?.state}
					imageSrc={focus_state_icons?.warningNew}
					heart={true}
				/>
			)}
			{showModal === 'AddNewDeduction' && (
				<Edenredmodal
					field="Enter Deduction"
					fieldlabel="Add New deduction"
					onOkay={() => addNewDeduction(newDeductionItem)}
					onCancelButton="Cancel"
					onOkayButton="Add"
					onOkayDisable={newDeductionItem.trim() === '' || isAlreadyExists}
					onCancel={closeModal}
					isOpen={showModal !== ''}
					close={closeModal}
					fieldonchange={handleChange}
					fieldValue={newDeductionItem}
					errorMessage={isAlreadyExists && 'Deduction already exists'}
				/>
			)}
		</>
	);
};

export default Deduction;
