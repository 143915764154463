import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import Axios from 'axios';
export function* postBankAccount(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/migrate-c3pay-to-bank-account`;
		const res = yield call(Axios.post, uri, action.payload);
		yield put(Actions.creators.postBankAccountSuccess(res.data || false));
		if (action?.history && res?.data === '') {
			action.history.push('/employees/manage_employee');
		} else {
			yield put(Actions.creators.BankAccountRoute('/employees/manage_employee'));
		}
		yield put(Actions.creators.BankAccountPopUp(true));
	} catch (err) {
		yield put(Actions.creators.BankAccountPopUp(false));
		yield put(Actions.creators.postBankAccountFailed('', false));
	}
}
export function* getBankName() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);

	try {
		const url = `${DOMAIN_URL}/Employees/${corporateId}/bank-list`;
		const res = yield call(Axios.get, url);
		yield put(Actions.creators.getBankNameSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getBankNameFailed(err));
	}
}

export default function* payrollProfileWatcher() {
	yield takeEvery(Actions.types.POST_BANK_ACCOUNT_START, postBankAccount);
	yield takeEvery(Actions.types.GET_BANK_NAME_START, getBankName);
}
