import React, { useEffect, useRef, useState } from 'react';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './index.scss';
import ETypo from '../Typo';
import { icons } from 'src/assets/icons';

const TAIL = {
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
	BOTH: 'BOTH',
};

function MonthPicker({
	label,
	className,
	disabled,
	value,
	onChange,
	isDateError,
	dateErrorMessage,
	rangeDateActivePlaceHolder,
	rangeDateFromPlaceHolder = 'Month/Year',
	rangeDateToPlaceHolder = 'Month/Year',
	fromMonthDefaultValue = '', // 10[M]-1[D]-2022[Y]
	toMonthDefaultValue = '', // 11-1-2022
	required,
	isRange = true,
}) {
	const [currentYear, setCurrentYear] = useState(() => {
		const _dateNow = new Date();
		return `${_dateNow.getFullYear()}-${_dateNow.getMonth() + 1}-1`.replace(/-/gi, '/');
	});
	const [showRangeDateDefault, setShowRangeDateDefault] = useState(false);
	const [months, setMonths] = useState({
		0: {
			title: 'Jan',
			selected: false,
			tail: null,
		},
		1: {
			title: 'Feb',
			selected: false,
			tail: null,
		},
		2: {
			title: 'Mar',
			selected: false,
			tail: null,
		},
		3: {
			title: 'Apr',
			selected: false,
			tail: null,
		},
		4: {
			title: 'May',
			selected: false,
			tail: null,
		},
		5: {
			title: 'Jun',
			selected: false,
			tail: null,
		},
		6: {
			title: 'Jul',
			selected: false,
			tail: null,
		},
		7: {
			title: 'Aug',
			selected: false,
			tail: null,
		},
		8: {
			title: 'Sep',
			selected: false,
			tail: null,
		},
		9: {
			title: 'Oct',
			selected: false,
			tail: null,
		},
		10: {
			title: 'Nov',
			selected: false,
			tail: null,
		},
		11: {
			title: 'Dec',
			selected: false,
			tail: null,
		},
	});
	const [selectedMonth, setSelectedMonth] = useState({
		from: null,
		to: null,
	});

	const monthWrapperComponentRef = useRef(null);
	const isMonthChangedRef = useRef(false);

	useEffect(() => {
		let result = {};

		for (const month in months) {
			const HAS_FROM_SELECTED_MONTH = selectedMonth.from !== null;
			const HAS_TO_SELECTED_MONTH = selectedMonth.to !== null;

			const _currentYearDateObj = new Date(currentYear);
			const _currentMonthDateObj = new Date(`${parseInt(month) + 1}-1-${_currentYearDateObj.getFullYear()}`);
			const _selectedFromDateObj = new Date(selectedMonth.from || '');
			const _selectedToDateObj = new Date(selectedMonth.to || '');

			const IS_SAME_FROM_DATE =
				+_currentMonthDateObj ===
				+new Date(`${_selectedFromDateObj.getMonth() + 1}-${_selectedFromDateObj.getDate()}-${_selectedFromDateObj.getFullYear()}`);
			const IS_SAME_TO_DATE =
				+_currentMonthDateObj ===
				+new Date(`${_selectedToDateObj.getMonth() + 1}-${_selectedToDateObj.getDate()}-${_selectedToDateObj.getFullYear()}`);
			const HAS_FROM_DATE = _selectedFromDateObj ? true : false;
			const HAS_TO_DATE = _selectedToDateObj ? true : false;
			const IS_IN_BETWEEN_DATE =
				HAS_FROM_SELECTED_MONTH & HAS_TO_SELECTED_MONTH &&
				_currentMonthDateObj > _selectedFromDateObj &&
				_currentMonthDateObj < _selectedToDateObj;

			if (isRange === false && HAS_FROM_DATE && HAS_TO_DATE) {
				result = {
					...result,
					[month]: {
						...months[month],
						selected:
							parseInt(_currentYearDateObj.getFullYear()) === parseInt(_selectedFromDateObj.getFullYear()) &&
								parseInt(_selectedFromDateObj.getMonth()) === parseInt(month)
								? true
								: false,
						tail: null,
					},
				};
			} else if (HAS_FROM_DATE && IS_SAME_FROM_DATE) {
				const IS_SAME_MONTH = +_selectedFromDateObj === +_selectedToDateObj;
				const FROM_MONTH_LESSER_TO_MONTH = _selectedFromDateObj < _selectedToDateObj;
				result = {
					...result,
					[month]: {
						...months[month],
						selected: true,
						tail: HAS_TO_SELECTED_MONTH ? (IS_SAME_MONTH ? null : FROM_MONTH_LESSER_TO_MONTH ? TAIL.RIGHT : TAIL.LEFT) : null,
					},
				};
			} else if (HAS_TO_DATE && IS_SAME_TO_DATE) {
				result = {
					...result,
					[month]: {
						...months[month],
						selected: true,
						tail: _selectedFromDateObj < _selectedToDateObj ? TAIL.LEFT : TAIL.RIGHT,
					},
				};
			} else if (IS_IN_BETWEEN_DATE) {
				result = {
					...result,
					[month]: {
						...months[month],
						tail: TAIL.BOTH,
						selected: false,
					},
				};
			} else {
				result = {
					...result,
					[month]: {
						...months[month],
						tail: null,
						selected: false,
					},
				};
			}
		}

		setMonths(result);
	}, [selectedMonth, currentYear]);

	useEffect(() => {
		if (typeof onChange === 'function') {
			onChange(selectedMonth);
			onCloseDatePicker()
		}
	}, [selectedMonth]);

	useEffect(() => {
		if (fromMonthDefaultValue && toMonthDefaultValue && fromMonthDefaultValue.length > 6 && toMonthDefaultValue.length > 6) {
			setSelectedMonth({
				from: fromMonthDefaultValue,
				to: toMonthDefaultValue,
			});
		}
	}, [fromMonthDefaultValue, toMonthDefaultValue]);

	function onMonthClicked(monthIndex) {
		isMonthChangedRef.current = true;
		const _monthIndex = monthIndex + 1;
		const HAS_FROM_SELECTED_MONTH = selectedMonth.from !== null;
		const HAS_TO_SELECTED_MONTH = selectedMonth.to !== null;

		if (isRange === false) {
			const _currentYearDateObj = new Date(currentYear);
			const daysInMonth = new Date(_currentYearDateObj.getFullYear(), _currentYearDateObj.getMonth(), 0).getDate() - 5;
			let result = {
				from: `${_currentYearDateObj.getFullYear()}-${_monthIndex}-1`.replace(/-/gi, '/'),
				to: `${_currentYearDateObj.getFullYear()}-${_monthIndex}-${daysInMonth}`.replace(/-/gi, '/'),
			};
			setSelectedMonth(result);
		} else if (HAS_FROM_SELECTED_MONTH && HAS_TO_SELECTED_MONTH) {
			const _currentYearDateObj = new Date(currentYear);
			let result = {
				from: `${_currentYearDateObj.getFullYear()}-${_monthIndex}-1`.replace(/-/gi, '/'),
				to: null,
			};
			setSelectedMonth(result);
		} else if (selectedMonth.from === null || selectedMonth.to !== null) {
			const _currentYearDateObj = new Date(currentYear);
			setSelectedMonth((prevState) => ({
				...prevState,
				from: `${_currentYearDateObj.getFullYear()}-${_monthIndex}-1`.replace(/-/gi, '/'),
			}));
		} else if (selectedMonth.to === null || selectedMonth.from !== null) {
			setSelectedMonth((prevState) => {
				const _currentYearDateObj = new Date(currentYear);
				const _fromDateObj = new Date(selectedMonth.from);

				const _currentMonthDateObj = new Date(`${_monthIndex}-1-${_currentYearDateObj.getFullYear()}`);

				if (_fromDateObj < _currentMonthDateObj) {
					return {
						...prevState,
						to: `${_currentYearDateObj.getFullYear()}-${_monthIndex}-1`.replace(/-/gi, '/'),
					};
				} else {
					return {
						...prevState,
						from: `${_currentYearDateObj.getFullYear()}-${_monthIndex}-1`.replace(/-/gi, '/'),
					};
				}
			});
		}
	}

	const returnAsArray = () => {
		const result = [];
		for (const month in months) {
			result.push(months[month]);
		}
		return result;
	};

	function getYear() {
		const date = new Date(currentYear);
		return date.getFullYear();
	}

	const leftTail = (_months) => {
		if (_months.tail === TAIL.LEFT || _months.tail === TAIL.BOTH) {
			return 'active';
		} else {
			return '';
		}
	};

	const rightTail = (_months) => {
		if (_months.tail === TAIL.RIGHT || _months.tail === TAIL.BOTH) {
			return 'active';
		} else {
			return '';
		}
	};

	function previousYear() {
		setCurrentYear((prevState) => {
			const _dateNow = new Date(prevState);
			return `${_dateNow.getFullYear() - 1}-${_dateNow.getDate()}-1`.replace(/-/gi, '/');
		});
	}

	function nextYear() {
		setCurrentYear((prevState) => {
			const _dateNow = new Date(prevState);
			const currentYear = new Date();
			const result = `${_dateNow.getFullYear() + 1}-${_dateNow.getDate()}-1`.replace(/-/gi, '/');
			const _resultDate = new Date(result);

			if (_resultDate > currentYear) {
				return prevState;
			} else {
				return result;
			}
		});
	}

	function _formatter(month) {
		const options = { month: 'short', year: 'numeric' };
		const [_month, date, year] = month.split(month);

		const result = new Date(month).toLocaleDateString('en-US', options);
		return result;
	}

	const onSelectDatePicker = () => {
		const isActive = monthWrapperComponentRef?.current?.classList?.contains('active')
		if (isActive) {
			onCloseDatePicker()
		} else {
			monthWrapperComponentRef.current.classList.add('active');
			setShowRangeDateDefault(true);
		}
	};

	const onCloseDatePicker = () => {
		monthWrapperComponentRef.current.classList.remove('active');
		if (isMonthChangedRef.current === true) {
			monthWrapperComponentRef.current.classList.add('changed');
		} else {
			monthWrapperComponentRef.current.classList.add('default');
		}
		setShowRangeDateDefault(false);
	};

	return (
		<div tabindex="3" style={{ position: 'relative' }} onClick={onSelectDatePicker} onBlur={onCloseDatePicker}>
			<div className="range_date_picker_container">
				{label && (
					<p className="date_label">
						{label}
						{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
					</p>
				)}
				<div className="date_range_picker">
					<div
						ref={monthWrapperComponentRef}
						className={`date_picker_main ${className} ${disabled ? 'disabled_date' : 'active_date_field'} ${value ? 'non_empty_date_field' : 'empty_date_field'
							} ${isDateError ? 'date_error_field' : ''}`}>
						<img src={not_focus_icons?.payrollCalender} />
						<div className="date_range_picker_content">
							<div className="range_from_container">
								<ETypo b2 medium color={selectedMonth.from ? '#181919' : '#9a9ea6'}>
									{selectedMonth.from && selectedMonth.from !== 'Invalid date'
										? _formatter(selectedMonth.from)
										: showRangeDateDefault && rangeDateActivePlaceHolder
											? rangeDateActivePlaceHolder
											: rangeDateFromPlaceHolder}
								</ETypo>
							</div>
							<span className="ranger_picker_divider" />
							<div className="range_to_container">
								<ETypo b2 medium color={selectedMonth.to ? '#181919' : '#9a9ea6'}>
									{selectedMonth.to && selectedMonth.to !== 'Invalid date'
										? _formatter(selectedMonth.to)
										: showRangeDateDefault & rangeDateActivePlaceHolder
											? rangeDateActivePlaceHolder
											: rangeDateToPlaceHolder}
								</ETypo>
							</div>
						</div>
						<img className="arrow-inner" src={showRangeDateDefault ? icons.not_focus.up : icons.not_focus.down} />
					</div>
					{isDateError && <p className="date_field_error_message">{dateErrorMessage}</p>}
				</div>
			</div>

			{showRangeDateDefault && (
				<div
					className="date-picker-wrapper"
					style={{ position: 'absolute', zIndex: '2', width: '100%', top: '38px' }}>
					<label>{label}</label>

					<div className="date-picker">
						<section className="header">
							<h3>{getYear()}</h3>
							<div>
								<img src={not_focus_icons.arrowLeftNew} alt="" onClick={(e) => {
									e.stopPropagation()
									previousYear()
								}} />
								<img src={not_focus_icons.arrowRightNew} alt="" onClick={(e) => {
									e.stopPropagation()
									nextYear()
								}} />
							</div>
						</section>

						<div className="divider" />

						<section className="body">
							{returnAsArray().map((_months, index) => (
								<div key={index} className="month_wrapper" onClick={() => onMonthClicked(index)}>
									<div className={`left ${leftTail(_months)}`} />
									<div className={`right ${rightTail(_months)}`} />
									<div className="month">
										<p className={_months.selected ? ' selected ' : ''}>{_months.title}</p>
									</div>
								</div>
							))}
						</section>
					</div>
				</div>
			)}
		</div>
	);
}

export default MonthPicker;
