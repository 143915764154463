import Axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DATE_FORMAT, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import moment from 'moment';

export function* addIndividualDocumentServices() {
	try {
		const uri = `${DOMAIN_URL}/Routing`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getBankNameDataSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getBankNameDataFail(err));
	}
}
export function* getEmployeeDataServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}?search=${action?.empId}&status=PENDINGVERIFIERAPPROVAL`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeeDetailSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeeDetailFail(err));
	}
}
export function* saveIndividualDocumentServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const res = action?.payload;
	let joiningDate = res?.joiningDate ? moment(res?.joiningDate, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD) : null;
	const payload = {
		employeeId: res?.empId,
		wpsPersonId: res?.wpsPersonId,
		wpsEstablishmentId: res?.wpsEstablishmentId,
		labourCardNumber: res?.labourCardNumber,
		emiratesId: res?.emiratesId,
		passportNumber: res?.passportNumber,
		bankName: res?.bankName || '',
		iban: res?.Iban || '',
		emiratesIdExpiryDate: moment().format(DATE_FORMAT?.YYYY_MM_DD),
		flag: '3',
		joiningDate: joiningDate,
		contractEndDate: res?.endDate ? moment(res?.endDate, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD) : '',
		designation: res?.designation,
		department: res?.department,
		contractType: res?.contractName?.toLowerCase(),
		basicSalary: 0,
		firstName: res?.firstName,
		lastName: res?.lastName,
		gender: res?.gender,
		dateofBirth: moment(res?.dob, 'DD/MM/YYYY').format(DATE_FORMAT?.YYYY_MM_DD),
		mobileNumber: res?.mobile,
		nationality: res?.nationality,
		email: res?.email,
		salaryProcessingChannel: res?.account,
	};

	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/individual-employee/save`;
		const res = yield call(Axios.post, uri, payload);
		yield put(Actions.creators.saveIndividualEmployeeDocSuccess(res?.data));
		yield put(Actions.creators.isIndividualEmpDocSaved(res?.data ? false : true));
	} catch (err) {
		yield put(Actions.creators.saveIndividualEmployeeDocFail(err));
	}
}
export default function* addIndividualDocumentWatcher() {
	yield takeEvery(Actions.types.GET_BANKNAME_DATA_START, addIndividualDocumentServices);
	yield takeEvery(Actions.types.GET_EMPLOYEE_DETAILS_START, getEmployeeDataServices);
	yield takeEvery(Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_START, saveIndividualDocumentServices);
}
