import action from './action';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import HeroContent from '@pages/NewHrModuleSetup/Component/HeroContent/HeroContent';
import React, { useEffect, useState } from 'react';
import Stepper from '@pages/NewHrModuleSetup/Component/Stepper/Stepper';
import SetupTitle from '@pages/NewHrModuleSetup/Component/SetupTitle/SetupTitle';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import hrFeatureAction from '../../HrFeatureAccess/action';
import './departments.scss';

const Departments = ({ navigate = '/leave/upload-leave', CleverTap = true }) => {
	const stepperItems = [
		{
			name: '1 - Departments',
			isActive: true,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/departments' : '/leave/departments',
		},
		{
			name: '2 - Upload Data',
			isActive: false,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/upload-leave' : '/leave/upload-leave',
		},
		{
			name: '3 - How To Set Up Approval Workflow',
			isActive: false,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/setup-approval-workflow' : '/leave/setup-approval-workflow',
		},
		{
			name: '4 - How To Invite Employees',
			isActive: false,
			isCompleted: false,
			navigate: !CleverTap ? '/new-hr-features/invite-employees' : '/leave/invite-employees',
		},
	];
	const initialDepartmentsList = [
		{
			name: 'HR',
			isActive: false,
			id: '',
		},
		{
			name: 'Compliance',
			isActive: false,
			id: '',
		},
		{
			name: 'Legal',
			isActive: false,
			id: '',
		},
		{
			name: 'Sales',
			isActive: false,
			id: '',
		},
		{
			name: 'Accounts',
			isActive: false,
			id: '',
		},
		{
			name: 'Finance',
			isActive: false,
			id: '',
		},
		{
			name: 'Leadership',
			isActive: false,
			id: '',
		},
	];
	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	// const storeData = useSelector((state) => state?.hrSetup?.department?.data?.departmentList);
	const storeData = useSelector((state) => state?.hrSetup?.corporateSettings?.departments) || [];

	// const convertedList = storeData?.map((item) => ({ id: item?.id, name: item?.name, isActive: true })) || []; // adding checked property to the list(checkbox status)
	let mergeList = [];
	if (storeData) {
		const uniqueList = initialDepartmentsList.filter((item) => !storeData.some((element) => element.name === item.name)); // removing same categories
		mergeList = [...uniqueList, ...storeData]; // removing same categories
	} else {
		mergeList = [...initialDepartmentsList];
	}
	const dispatch = useDispatch();

	const [departmentsList, setDepartmentsList] = useState([...mergeList]);
	const [postList, setPostList] = useState([]);
	const [newDepartmentsItem, setNewDepartmentsItem] = useState('');
	const [showModal, setShowModal] = useState('');
	const history = useHistory();
	const [isAlreadyExists, setIsAlreadyExists] = useState(false);

	const handleCheckboxState = (isSelected, value) => {
		// changing checkbox state
		const departmentsListCopy = [...departmentsList]; // storing departments list
		const indexOfValue = departmentsListCopy.findIndex((item) => item.name === value); // finding index of the element
		departmentsListCopy[indexOfValue].isActive = isSelected;
		setDepartmentsList(departmentsListCopy);
	};
	const addNewDepartments = (value = '') => {
		// add new category to the departments list
		setShowModal('AddNewDepartments');
		const isValueNewItem = !departmentsList.some((item) => item.name == value); // checking value is unique or not
		if (value !== '' && isValueNewItem) {
			setDepartmentsList((prev) => [...prev, { id: '', name: value.trim(), isActive: true }]);
			setNewDepartmentsItem('');
			setShowModal('');
		}
	};
	const closeModal = () => {
		setShowModal('');
		setNewDepartmentsItem('');
		setIsAlreadyExists(false);
	};

	const saveChanges = () => {
		const filteredDepartmentsList = [...departmentsList].filter((item) => item?.isActive === true); // storing checked item to the new temp variable
		const isEdited = JSON.stringify(storeData.filter((item) => item?.isActive === true)) !== JSON.stringify(filteredDepartmentsList); // checking user checked list with data from store
		const finalList = filteredDepartmentsList.map((item) => ({ name: item?.name, id: item?.id || '' })); // converting list format to match database
		setPostList([...finalList]);
		ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStarted_Next' : 'V1_1_Leave_Depart_Next', { ...finalList });
		if (isEdited && finalList && finalList.length > 0) {
			dispatch(action.creators.postDepartment(finalList, userName));
		}
		if (finalList && finalList.length > 0) {
			history.push(navigate);
		} else {
			setShowModal('SkipOrSaveChanges');
		}
	};

	// to save & push (popup)
	const onSkipForNow = () => {
		ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStarted_PopSkip' : 'V1_1_Leave_Depart_PopSkip');
		dispatch(action.creators.postDepartment(postList, userName));
		history.push(navigate);
	};

	const handleChange = (event) => {
		const value = event?.target?.value;
		setNewDepartmentsItem(value);
		let isUnique = departmentsList.some((item) => item?.name.toLowerCase() === value.trim().toLowerCase());
		if (isUnique === false) {
			setIsAlreadyExists(false);
		} else {
			setIsAlreadyExists(true);
		}
	};

	useEffect(() => {
		dispatch(hrFeatureAction.creators.getCorporateSettings());
		dispatch(action.creators.getDepartment());
	}, []);
	useEffect(() => {
		setDepartmentsList(mergeList);
	}, [storeData]);
	return (
		<>
			<main className="main-wrapper">
				<div className="container_title_box">
					<SetupTitle
						title="What DEPARTMENTS do you have?"
						rightBtnClick={saveChanges}
						rightBtnRightIcon={focus_state_icons.RightArrow}
						rightBtnText="Next"
						rightBtnType="primary"
					/>
				</div>
				<div className="hero-content">
					<Stepper
						listOfTabs={stepperItems}
						// handleTabClick={} (under discussion)
					/>
					<HeroContent
						title="Departments are ..."
						list={departmentsList}
						handleCheckboxState={handleCheckboxState}
						onAddNewItem={() => {
							ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStarted_AddNew' : 'V1_1_Leave_Depart_AddNew');
							addNewDepartments(newDepartmentsItem);
						}}
						addNewItemButtonTitle="Add New Departments"
					/>
				</div>
			</main>

			{showModal === 'SkipOrSaveChanges' && (
				<Edenredmodal
					title="You didn’t add new departmentss"
					desc="You’ll be able to add more departmentss in company settings in the future."
					onOkay={() => {
						ClevertapReact.event(CleverTap ? 'V1_1_Leave_GetStarted_PopAdd' : 'V1_1_Leave_Depart_PopAdd');
						setShowModal('');
					}}
					onCancelButton="Skip For Now"
					onOkayButton="Add departments"
					onCancel={onSkipForNow}
					isOpen={showModal !== ''}
					imageSrc={focus_state_icons?.warningNew}
					heart={true}
				/>
			)}
			{showModal === 'AddNewDepartments' && (
				<Edenredmodal
					field="Enter department"
					fieldlabel="Add New department"
					onOkay={() => {
						addNewDepartments(newDepartmentsItem);
					}}
					onCancelButton="Cancel"
					onOkayButton="Add"
					onOkayDisable={newDepartmentsItem.trim() === '' || isAlreadyExists}
					onCancel={closeModal}
					isOpen={showModal !== ''}
					close={closeModal}
					fieldonchange={handleChange}
					fieldValue={newDepartmentsItem}
					errorMessage={isAlreadyExists && 'Departments already exists'}
				/>
			)}
		</>
	);
};

export default Departments;
