import Actions from './action';
export const initState = {
	sendingInvitation: false,
	claims: [],
	claimsLoading: false,
	getEmployeesDetail: [],
	isEmployeesLoading: false,
	getEmployeesError: '',
	isEmployeeUserLoading: false,
	employeeUserDetail: [],
	employeeUserError: '',
};

const appReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SEND_INVITE: {
			return {
				...state,
				sendingInvitation: true,
			};
		}
		case Actions.types.SEND_INVITE_SUCCESS: {
			return {
				...state,
				sendingInvitation: false,
			};
		}
		case Actions.types.SEND_INVITE_FAILED: {
			return {
				...state,
				sendingInvitation: false,
			};
		}
		case Actions.types.GET_CLAIMS: {
			return {
				...state,
				claimsLoading: true,
			};
		}
		case Actions.types.GET_CLAIMS_SUCCESS: {
			return {
				...state,
				claimsLoading: false,
				claims: action.response,
			};
		}
		case Actions.types.GET_CLAIMS_FAILED: {
			return {
				...state,
				claimsLoading: false,
			};
		}
		case Actions.types.GET_EMPLOYEES_START: {
			return {
				...state,
				isEmployeesLoading: true,
			};
		}
		case Actions.types.GET_EMPLOYEES_SUCCESS: {
			return {
				...state,
				isEmployeesLoading: false,
				getEmployeesDetail: action.res,
			};
		}
		case Actions.types.GET_EMPLOYEES_FAILED: {
			return {
				...state,
				isEmployeesLoading: false,
				getEmployeesDetail: [],
				getEmployeesError: action.error,
			};
		}
		case Actions.types.GET_EMPLOYEE_USER_START: {
			return {
				...state,
				isEmployeeUserLoading: true,
			};
		}
		case Actions.types.GET_EMPLOYEE_USER_SUCCESS: {
			return {
				...state,
				isEmployeeUserLoading: false,
				employeeUserDetail: action?.response,
			};
		}
		case Actions.types.GET_EMPLOYEE_USER_FAIL: {
			return {
				...state,
				isEmployeeUserLoading: false,
				employeeUserDetail: [],
				employeeUserError: action?.error,
			};
		}
		default:
			return state;
	}
};

export default appReducer;
