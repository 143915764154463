import { createSelector } from 'reselect';

import { initState } from './reducer';

const PayRollRunReducer = (state) => state.PayRollRunReducer || initState;
const salaryPaidStatusLoading = createSelector(PayRollRunReducer, (cnter) => cnter.salaryPaidStatusLoading);
const salaryPaidStatus = createSelector(PayRollRunReducer, (cnter) => cnter.salaryPaidStatus);
const salaryPadiStatusError = createSelector(PayRollRunReducer, (cnter) => cnter.salaryPadiStatusError);
const payRollRunLoading = createSelector(PayRollRunReducer, (cnter) => cnter.payRollRunLoading);
const payRollRun = createSelector(PayRollRunReducer, (cnter) => cnter.payRollRun);
const payRollRunLists = createSelector(PayRollRunReducer, (cnter) => cnter.payRollRunLists);
const payRollRunError = createSelector(PayRollRunReducer, (cnter) => cnter.payRollRunError);
const additionLoading = createSelector(PayRollRunReducer, (cnter) => cnter.additionLoading);
const additionValue = createSelector(PayRollRunReducer, (cnter) => cnter.additionValue);
const additionError = createSelector(PayRollRunReducer, (cnter) => cnter.additionError);
const deductionLoading = createSelector(PayRollRunReducer, (cnter) => cnter.deductionLoading);
const deductionValue = createSelector(PayRollRunReducer, (cnter) => cnter.deductionValue);
const deductionError = createSelector(PayRollRunReducer, (cnter) => cnter.deductionError);
const overTimePayCalLoading = createSelector(PayRollRunReducer, (cnter) => cnter.overTimePayCalLoading);
const overTimePayCal = createSelector(PayRollRunReducer, (cnter) => cnter.overTimePayCal);
const overTimePayError = createSelector(PayRollRunReducer, (cnter) => cnter.overTimePayCalError);
const terminationTypeLoading = createSelector(PayRollRunReducer, (cnter) => cnter.terminationTypeLoading);
const terminationType = createSelector(PayRollRunReducer, (cnter) => cnter.terminationType);
const terminationTypeError = createSelector(PayRollRunReducer, (cnter) => cnter.terminationTypeError);
const savePopUp = createSelector(PayRollRunReducer, (cnter) => cnter.savePopUp);
const savedSuccessfully = createSelector(PayRollRunReducer, (cnter) => cnter.savedSuccessfully);
const savePayrollRunLoading = createSelector(PayRollRunReducer, (cnter) => cnter.savePayrollRunLoading);
const savePayrollRun = createSelector(PayRollRunReducer, (cnter) => cnter.savePayrollRun);
const savePayrollRunError = createSelector(PayRollRunReducer, (cnter) => cnter.savePayrollRunError);
const modifiedMonthYear = createSelector(PayRollRunReducer, (cnter) => cnter.modifiedMonthYear);
console.log('selector', payRollRun);

export default {
	PayRollRunReducer,
	salaryPaidStatusLoading,
	salaryPaidStatus,
	salaryPadiStatusError,
	payRollRunLoading,
	payRollRun,
	payRollRunError,
	additionLoading,
	additionValue,
	additionError,
	deductionLoading,
	deductionValue,
	deductionError,
	overTimePayCalLoading,
	overTimePayCal,
	overTimePayError,
	terminationTypeLoading,
	terminationType,
	terminationTypeError,
	savePopUp,
	savePayrollRunLoading,
	savePayrollRun,
	savePayrollRunError,
	savedSuccessfully,
	payRollRunLists,
	modifiedMonthYear
};
