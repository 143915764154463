import { ALL_EMPLOYEE_CLAIMS, PERMISSIONS } from '@constants/app-constants';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import EmployeeService from 'src/services/employee-service';

export const QUICK_MENUS = [
	{
		title: 'Run Payroll',
		icon: not_focus_icons.runPayrollIcon,
		subTitle: 'Pay your employees!',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/run-payroll',
		},
		toShow: [PERMISSIONS.MANAGE_PAYROLL],
		priority: 1,
		cleverTapValue: 'Onboarding_RunPayroll',
	},
	{
		title: 'Manage Employees',
		icon: not_focus_icons.manageEmployeesIcon,
		subTitle: 'Add new employees and order cards for them.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/manage_employee',
		},
		toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		cleverTapValue: 'Onboarding_ManageEmployees',
	},
	{
		title: 'Manage Leaves',
		icon: not_focus_icons.manageLeavesIcon,
		subTitle: 'Approve any pending leave or view who is on leave.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/leaves/leaves_to_approve',
		},
		toShow: [PERMISSIONS.MANAGE_LEAVES],
		cleverTapValue: 'Onboarding_manageLeaves',
	},
	{
		title: 'Invite Team Members',
		icon: not_focus_icons.downloadEmployeeDetailsIcon,
		subTitle: 'Add and team members and their permissions.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/manage_employee',
		},
		toShow: [PERMISSIONS.MANAGE_EMPLOYEES, PERMISSIONS.MANAGE_ROLES_AND_PERMISSIONS],
		cleverTapValue: 'Onboarding_InviteTeam',
	},
	{
		title: 'Check Documents Expiring',
		icon: not_focus_icons.checkDocumentsExpiringIcon,
		subTitle: 'View what employees’ documents are expiring soon.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/hrdocuments',
		},
		toShow: [PERMISSIONS.MANAGE_DOCUMENTS],
		cleverTapValue: 'Onboarding_checkDocumentExpiring',
	},
	{
		title: 'Approve Payroll Requests',
		icon: not_focus_icons.approvePayrollRequestIcon,
		subTitle: 'Approve any pending payroll request from users.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/payroll/approve_requests',
		},
		toShow: [PERMISSIONS.APPROVE_PAYROLL_REQUEST, PERMISSIONS.APPROVE_PAYROLL_REQUEST],
		cleverTapValue: 'Onboarding_ApprovePayroll',
	},
	{
		title: 'Approve Card Orders',
		icon: not_focus_icons.approveCardOrdersIcon,
		subTitle: 'Approve any pending card order requests.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/approve_employee_request',
		},
		toShow: [PERMISSIONS.APPROVE_CARD_ORDERS, PERMISSIONS.APPROVE_CARD_REPLACEMENT, PERMISSIONS.APPROVE_CARD_CANCELLATION],
		cleverTapValue: 'Onboarding_approveCardOrders',
	},
	{
		title: 'Approve Card Replacement',
		icon: not_focus_icons.approveCardReplacementIcon,
		subTitle: 'Approve any pending card replacement requests.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/approve_employee_request',
		},
		toShow: [PERMISSIONS.APPROVE_CARD_ORDERS, PERMISSIONS.APPROVE_CARD_REPLACEMENT, PERMISSIONS.APPROVE_CARD_CANCELLATION],
		cleverTapValue: 'Onboarding_approveCardReplacement',
	},
	{
		title: 'Approve Card Cancellation',
		icon: not_focus_icons.approveCardOrderCancellation,
		subTitle: 'Approve any pending card cancellation requests.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/approve_employee_request',
		},
		toShow: [PERMISSIONS.APPROVE_CARD_ORDERS, PERMISSIONS.APPROVE_CARD_REPLACEMENT, PERMISSIONS.APPROVE_CARD_CANCELLATION],
		cleverTapValue: 'Onboarding_approveCardOrderCancellation',
	},
	{
		title: 'Add/Edit Leave Balances',
		icon: not_focus_icons.addEditLeaveBalances,
		subTitle: 'Add or edit your employees’ leave balances.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/leave/upload',
		},
		toShow: [PERMISSIONS.MANAGE_LEAVES, PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES],
		cleverTapValue: 'Onboarding_addEditLeaveBalances',
	},
	{
		title: 'Send Announcements',
		icon: not_focus_icons.sendAnnouncementsIcon,
		subTitle: 'Communicate with your employees via notifications.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/announcement',
		},
		toShow: [PERMISSIONS.MANAGE_ANNOUNCEMENTS, PERMISSIONS.VIEW_ANNOUNCEMENT],
		cleverTapValue: 'Onboarding_sendAnnouncements',
	},
	{
		title: 'Download Employee Details',
		icon: not_focus_icons.downloadEmployeeDetailsIcon,
		subTitle: 'Get spreadsheet of employee details and account numbers.',
		actionType: 'DOWNLOAD',
		action: {
			downloadEmployeeDetails: async (status) => {
				status({
					loading: true,
				});
				const employeeApi = new EmployeeService();
				const queryParams = {
					status: 'ACTIVE',
					includeEmployeeCounts: true,
					pageSize: '',
					pageNumber: 1,
				};
				try {
					const response = await employeeApi.getEmployees(queryParams);
					if (response && response.data) {
						return response.data.employees;
					}
				} catch (err) {
					status({
						error: err,
						loading: false,
					});
				}
				status({
					loading: false,
				});
			},
		},
		toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		cleverTapValue: 'Onboarding_DownloadDetails',
	},
	{
		title: 'Approve Employee Requests',
		icon: not_focus_icons.approveEmployeeRequestIcon,
		subTitle: 'Approve any pending employee requests from users.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/approve_employee_request',
		},
		toShow: [PERMISSIONS.APPROVE_CARD_ORDERS, PERMISSIONS.APPROVE_CARD_REPLACEMENT, PERMISSIONS.APPROVE_CARD_CANCELLATION],
		cleverTapValue: 'Onboarding_ApproveEmp',
	},

	{
		title: 'Check Card Order Status',
		icon: not_focus_icons.checkCardOrderStatusIcon,
		subTitle: 'Find out where the cards you’ve ordered are.',
		actionType: 'NAVIGATE',
		action: {
			navigateTo: '/employees/card/order/status',
		},
		toShow: [PERMISSIONS.APPROVE_CARD_ORDERS, PERMISSIONS.APPROVE_CARD_REPLACEMENT, PERMISSIONS.APPROVE_CARD_CANCELLATION],
		cleverTapValue: 'Onboarding_CheckCardOrderStatus',
	},
];
