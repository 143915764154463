import React from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { useSelector } from 'react-redux';

const UpdateEmployeesHeader = ({ disableContinueButton, setproceedToUpload = () => { } }) => {
	const { loading, errorData } = useSelector((state) => state.employees.data) || {};
	const invalidEmployeesCount = useSelector((state) => state.employees?.verification?.invalidEmployees) || 0;

	const handleClick = () => {
		setproceedToUpload(true);
	};

	const isDisableContinueButton = () => {
		if (disableContinueButton || (errorData && errorData?.length)) {
			return true;
		}
		return false;
	};

	return (
		<>
			<div data-testid="update-employees-header-contents-test" style={{ display: 'grid', gap: '8px' }}>
				<ETypo bold h2 data-testid="update-employees-header-title-test">
					{invalidEmployeesCount} Cards to be blocked due to missing details
				</ETypo>
				<ETypo b1 data-testid="update-employees-header-desc-test">
					Update the missing details to avoid card blocking.
				</ETypo>
			</div>
			<div style={{ display: 'flex', gap: '20px' }}>
				<EdenredButtons loading={loading} disabled={isDisableContinueButton()} onClick={handleClick}>
					Next
				</EdenredButtons>
			</div>
		</>
	);
};

export default UpdateEmployeesHeader;
