import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DotLoader } from 'react-spinners';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import EdenRedCard from '@sharedComponent/card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { EmployeListExcelDownload } from '@sharedComponent/excel/excel';
import { numberWithCommas, changeEmployeeNameCase, entriesDropDown } from '@helpers';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Inputbox from '@pages/DesignSystem/Input-Field';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Table from '@pages/DesignSystem/Table/Table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import PayrollService from 'src/services/payroll-service';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import { withReducer } from 'src/store/reducerLoader';
import action from '../action';
import reducer from '../reducer';
import sagas from '../saga/index';

const FailedEmployDetail = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const downloadPdfLoading = useSelector((state) => state.payrollStatusPdf.isDownloadPdfLoading);
	const [employeesList, setEmployeesList] = useState([]);
	const [employeeDownload, setEmployeeDownload] = useState([]);
	const [employeesHeader, setEmployeesHeader] = useState([]);
	const [employeesListBackUp, setEmployeesListBackUp] = useState([]);
	const [employeesListLoader, setEmployeesListLoader] = useState(false);
	const [employeeTotalCount, setEmployeeTotalCount] = useState(1);
	const [viewStatusTable, setViewStatusTable] = useState({
		pageNumber: 1,
		pageSize: 10,
	});

	let [searchedText, setSearchedText] = useState('');

	const _payrollService = new PayrollService();
	const location = useLocation();
	const { state } = location;
	const getPayrollDetailsByUploadID = (uploadID, search = '') => {
		let type = null;
		if (state && state?.cardDetail) {
			type = state?.cardDetail?.accountType === 'L' ? 'C3' : 'BANK';
		}
		setEmployeesListLoader(true);
		_payrollService
			.getPayrollDetailsByUploadID(uploadID, type, '', viewStatusTable?.pageNumber, viewStatusTable?.pageSize)
			.then((response) => {
				let employeesData = [...response?.data?.employeeSalaryList];
				let employeesHeaderData = {};
				employeesHeaderData.headerAmount = response?.data?.headerAmount;
				employeesHeaderData.headerEmployeeCount = response?.data?.headerEmployeeCount;
				employeesHeaderData.headerType = response?.data?.headerType;
				employeesData.map((employee) => {
					employee.name = changeEmployeeNameCase(employee?.name);
					employee.amount = numberWithCommas(employee?.salary);
					return employee;
				});
				setEmployeesList(employeesData);
				setEmployeesHeader(employeesHeaderData);
				setEmployeesListBackUp(employeesData);
				setEmployeesListLoader(false);
				setEmployeeTotalCount(response?.data?.totalCount);
			})
			.catch((error) => {
				setEmployeesListLoader(false);
				setEmployeesList([]); // TODO - PLEASE REMOVE IT AND DONT USE CONST LIKE LIST USE USESTATE() / CREATE A FILE FOR MOCK DATA
			});
	};

	const { uploadId } = useParams();
	useEffect(() => {
		getPayrollDetailsByUploadID(uploadId);
	}, [viewStatusTable]);
	/**
	 * To get all data from api.
	 * If we need all data from api, Will no need to send pagesize param to the api.
	 */
	useEffect(() => {
		let type = null;
		if (state && state?.cardDetail) {
			type = state?.cardDetail?.accountType === 'L' ? 'C3' : 'BANK';
		}
		_payrollService
			.getPayrollDetailsByUploadIDForDownloadReport(uploadId, type, '', 1)
			.then((response) => {
				let employeesData = [...response?.data?.employeeSalaryList];
				let employeesHeaderData = {};
				employeesHeaderData.headerAmount = response?.data?.headerAmount;
				employeesHeaderData.headerEmployeeCount = response?.data?.headerEmployeeCount;
				employeesHeaderData.headerType = response?.data?.headerType;
				employeesData.map((employee) => {
					employee.name = changeEmployeeNameCase(employee?.name);
					employee.amount = numberWithCommas(employee?.salary);
					return employee;
				});
				setEmployeeDownload(employeesData);
			})
			.catch((error) => {
				setEmployeesListLoader(false);
				setEmployeesList([]);
			});
	}, []);

	const onFilterBySearch = (value) => {
		let nEmpList = employeesListBackUp?.filter((v) => {
			return v?.employeeCode?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0 || v?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0;
		});
		setEmployeesList(nEmpList);
	};

	useEffect(() => {
		if (searchedText) {
			const filteredTableData = employeesListBackUp?.filter((data) => {
				return (
					data?.employeeId?.toString()?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0 ||
					data?.name?.toString()?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0
				);
			});

			setEmployeesList(filteredTableData);
		} else {
			setEmployeesList(employeesListBackUp);
		}
	}, [searchedText, employeesListBackUp]);

	const tableColumn = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
			render: (employeeName, row) => (
				<ETypo b2 medium color="#484B52" className="emp-name-style">
					{row?.name}
				</ETypo>
			),
		},
		{
			title: 'Employee ID',
			dataIndex: 'employeeCode',
			key: 'employeeCode',
			width: '15%',
			render: (employeeCode, row) => (
				<ETypo b2 light color="#181919" className="status-other-field-style">
					{row?.employeeCode}
				</ETypo>
			),
		},
		{
			title: 'Salary Amount',
			dataIndex: 'amount',
			key: 'amount',
			width: '15%',
			render: (amount) => (
				<ETypo b2 medium color="#484B52" className="status-other-field-style">
					AED {amount}
				</ETypo>
			),
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: '10%',
			render: (row, row1) => (
				<div className="column_link">
					<Link
						to={{
							pathname: '/employees/viewemployee',
							state: { tableData: { employeeId: row1?.employeeId, employeeName: row1?.name } },
						}}>
						<ETypo medium b1 color="#0E5274">
							View Details
							<ImageComponent src={icons.not_focus.Right} />
						</ETypo>
					</Link>
				</div>
			),
		},
	];
	const handlePayrollViewTable = (page, param) => setViewStatusTable({ ...viewStatusTable, [param]: page });

	return (
		<Col xl={24} md={24} lg={24} sm={24} xs={24} style={{ height: '100%', width: '100%' }}>
			{employeesListLoader ? (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} employeesListLoader />
				</Col>
			) : (
				<Col>
					<EdenredHeader search placeholder="Search by Name or Employee ID" onInput={(e) => onFilterBySearch(e?.target?.value)} />
					<Row className="failed_employe_details">
						<Row className="card_status_view_header">
							<ETypo bold h2>
								{employeesHeader?.headerType}
							</ETypo>
							<Col className="failed_employe_details_sub_header">
								<ETypo light b1 color="#737780">
									Payroll Amount:
									<ETypo
										medium
										b1
										style={{
											marginLeft: '8px',
										}}>{`${employeesHeader?.headerAmount} for ${employeesHeader?.headerEmployeeCount}`}</ETypo>
								</ETypo>
								<EdenredButtons
									btnType="secondary"
									onClick={() => history.push('/payroll/history')}
									leftIcon={<ImageComponent src={images?.leftsarrow} />}>
									Go Back
								</EdenredButtons>
							</Col>
						</Row>
						<Row className="view_payroll_status_container">
							<Row className="failed_employe_box">
								<div className="search_download_detail">
									<div className="failed_employe_search">
										<Inputbox placeholder="Search by Name, Employee ID..." onChange={(e) => setSearchedText(e.target.value)} />
									</div>
									<div className="failed_employe_download_button">
										<EmployeListExcelDownload
											dataSet={employeeDownload || []}
											filename={`employeelist`}
											element={
												<EdenredButtons
													className="pdf_dowmload_list"
													leftIcon={<ImageComponent src={icons.focus_state.payrollDownloadArrow} />}
													btnType="secondary">
													Download Employee List
												</EdenredButtons>
											}
										/>
									</div>
									<div className="pdf_dowmload_main">
										<EdenredButtons
											className="pdf_dowmload"
											loading={downloadPdfLoading}
											leftIcon={<ImageComponent src={icons.focus_state.payrollDownloadArrow} />}
											btnType="secondary"
											onClick={() => {
												const payload = employeeDownload?.map((data) => data?.salaryId);
												dispatch(action?.creators?.getDownloadPdfStart(payload));
											}}>
											Download PDF
										</EdenredButtons>
									</div>
								</div>
								<Col className="failed_employe_dselect_entries">
									<ETypo light b2 color="#484B52">
										Show
									</ETypo>
									<MultiSelect
										{...{
											Option: entriesDropDown,
											searchVisibility: false,
											dropDownValue: 'value',
											value: viewStatusTable?.pageSize,
											onChange: (page) => handlePayrollViewTable(page?.value, 'pageSize'),
										}}
									/>
									<ETypo light b2 color="#484B52">
										Entries
									</ETypo>
								</Col>
							</Row>
							<Row className="failed_employe_header_table">
								<Table
									{...{
										data: employeesList,
										columns: tableColumn,
									}}
								/>
							</Row>
							<Row className="view_payroll_pagination">
								<ETypo b3 light color="#484B52">
									Showing {employeesList?.length} out of {employeeTotalCount} Employees
								</ETypo>
								<Pagination
									{...{
										currentPage: viewStatusTable?.pageNumber,
										totalCount: employeeTotalCount,
										pageSize: viewStatusTable?.pageSize,
										onPageChange: (page) => handlePayrollViewTable(page, 'pageNumber'),
									}}
								/>
							</Row>
						</Row>
					</Row>
					<Row className="failed_employe_details_mobile_view">
						<Col sm={24} xs={24}>
							<Col sm={24} xs={24} className="failed_content">
								{employeesHeader?.headerType} <br />
								{`${employeesHeader?.headerAmount} for ${employeesHeader?.headerEmployeeCount}`}
							</Col>
							{employeesList?.map((employee, index) => (
								<Col sm={24} xs={24} key={index} className="employee_card_column">
									<Link
										to={{
											pathname: '/employees/viewemployee',
											state: { tableData: { employeeId: employee?.employeeId, employeeName: employee?.name } },
										}}>
										<EdenRedCard type="EmployeeFailedDetails" className="employee_card" content={employee} />
									</Link>
								</Col>
							))}
							<Col sm={24} xs={24} className="download_employee_button_section">
								<EmployeListExcelDownload
									dataSet={employeeDownload || []}
									filename={`employeelist`}
									element={<EdenredButton>Download Employee List</EdenredButton>}
								/>
							</Col>
						</Col>
					</Row>
				</Col>
			)}
		</Col>
	);
};
const ReducedScreen = withReducer('payrollStatusPdf', reducer, sagas)(FailedEmployDetail);
export default ReducedScreen;
