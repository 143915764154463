import React from 'react';
import ETypo from '../../Typo';
import EdenredButtons from '../EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';

const SecondaryButtons = () => {
	return (
		<div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Secondary Button With No Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Secondary Button With Left Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						leftIcon: <ImageComponent className="leftIcon" src={images.btn} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Secondary Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						rightIcon: <ImageComponent className="rightIcon" src={images.btn} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Secondary Button With Double Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						leftIcon: <ImageComponent className="leftIcon" src={images.btn} />,
						rightIcon: <ImageComponent className="rightIcon" src={images.btn} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Secondary Button With No Text
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						rightIcon: <ImageComponent src={images.btn} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}></EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Secondary Button With No Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						disabled: true,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Secondary Button With left Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						disabled: true,
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Secondary Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						disabled: true,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Secondary Button With Double Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						disabled: true,
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Disabled Secondary Button With No Text
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						inactive: true,
						rightIcon: <ImageComponent src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}></EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Secondary Button With No Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						secondaryactive: true,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Secondary Button With Left Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						secondaryactive: true,
						leftIcon: <ImageComponent className="leftIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Secondary Button With Right Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						secondaryactive: true,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Secondary Button With Double Icon
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						secondaryactive: true,
						leftIcon: <ImageComponent className="lefticon" src={images.primary_disabled} />,
						rightIcon: <ImageComponent className="rightIcon" src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}>
					OK
				</EdenredButtons>
			</div>
			<div style={{ width: '100%', justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginBottom: '5px' }}>
					Inactive Secondary Button With No Text
				</ETypo>
				<EdenredButtons
					{...{
						btnType: 'secondary',
						secondaryactive: true,
						rightIcon: <ImageComponent src={images.primary_disabled} />,
						style: { marginTop: '5px', marginBottom: '0px' },
					}}></EdenredButtons>
			</div>
		</div>
	);
};

export default SecondaryButtons;
