import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenRedCard from '@sharedComponent/card';
import { domain } from '@constants/app-constants';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { useLocation } from 'react-router-dom';
import TeamsPayrollPage from './../../teams-payroll/index';

const InviteTeamMemberType = () => {
	const { state } = useLocation();
	return (
		<>
			<TeamsPayrollPage {...{values:state}}/>
		</>
	);
};

export default InviteTeamMemberType;
