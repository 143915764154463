import React from 'react';
import { Col, Row } from 'antd';
import { Tooltip } from 'antd';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ImageComponent from '@sharedComponent/image-component';
import Headersection from './header-screen';
import CardImage from './card-image';
import Imagetext from './image-text';
import PriceCard from './price-card';
import AccordianContent from './accordian';
import './new-landing.scss';

const LandingNewPresentational = ({
	icons,
	imageText,
	secondContainer,
	incrementdecrement,
	range,
	plus,
	monthlyFee,
	onClickable,
	upgradeDiv,
	myRef,
	cardLists,
	group,
}) => {
	return (
		<Row className="for-text-center">
			<Col>
				<div className="landing_page_header">
					<Headersection {...{ onClickable, upgradeDiv }} />
				</div>
				<Row style={{ justifyContent: 'center', marginTop: '10%' }}>
					<Col xl={18} lg={20} md={20} sm={22} xs={22}>
						<p className="top-heading-text">
							Say goodbye to excel sheets and manual work; synchronise your HR processes in a few clicks.
						</p>
					</Col>
				</Row>
				<div
					style={{
						padding: '5% 0 5% 0',
						background: 'linear-gradient(180deg, #FBFCFF 0%, rgba(251, 252, 255, 0) 0.01%, rgba(251, 252, 255, 0.9375) 100%)',
					}}>
					<CardImage
						paraText="Basic Salary, overtime, allowances, additions, deductions."
						headingtext="Get payslips instantly with the details you need:"
						topText="UPGRADED PAYROLL"
						Image={not_focus_icons.LeftImage1}
						left={true}
					/>
				</div>
				<div style={{ margin: '5% 0 5% 0' }}>
					<CardImage
						paraText="No manual balance calculations, no forms, only real-time tracking."
						headingtext="Automate leave requests to save time and reduce errors."
						topText="LEAVE MANAGEMENT"
						Image={not_focus_icons.RightImage1}
						left={false}
					/>
				</div>
				<div style={{ padding: '5% 0 5% 0', background: '#FBFCFF' }}>
					<CardImage
						paraText="Store employee documents safely and quickly access them when you need."
						headingtext="Avoid fines, we'll remind you before documents expire"
						topText="DOCUMENT MANAGEMENT"
						Image={not_focus_icons.RightImage2}
						left={true}
					/>
				</div>
				<div style={{ margin: '6% 0 5% 0' }}>
					<CardImage
						paraText="Send notifications directly to their mobile phones instead of printing circulars."
						headingtext="Communicate with all employees in seconds."
						topText="LIVE ANNOUNCEMENTS"
						Image={not_focus_icons.LeftImage3}
						left={false}
					/>
				</div>
				<div className="card-color">
					<CardImage
						list={cardLists}
						headingtext="No more time-consuming paperwork, your employees can"
						subHead={
							<div className="change-content">
								on C3Pay App <span className="small-content">(C3Pay cardholders) </span>
								<br /> and on Edenred Portal <span className="small-content">(Bank Account holders) *</span>
							</div>
						}
						Image={not_focus_icons.LangingC3}
						left={false}
						paraText={<span className="param-text">Bank Account holders will not have admin access</span>}
					/>
				</div>
				<div style={{ marginTop: '7%' }}>
					<Imagetext {...{ icons }} />
				</div>
				<Row ref={myRef}>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="price-card-bg">
						<Row style={{ justifyContent: 'center' }}>
							<Col xl={12} lg={18} md={18} sm={18} xs={18}>
								<h1 className="price-card-heading">Flexible Payroll & HR Software. With Flexible Prices.</h1>
							</Col>
						</Row>
						<Row style={{ justifyContent: 'center' }}>
							<Col xl={10} lg={24} md={24} sm={24} xs={24} className="total-count-row">
								<p className="total-employees">Number of employees:</p>
								<div className="total-employee-counter">
									<span style={{ cursor: 'pointer' }} onClick={() => incrementdecrement('decrement')}>
										<ImageComponent src={not_focus_icons.VectorMinus} />
									</span>

									<input className="input-box" value={range}></input>
									<span style={{ cursor: 'pointer' }} onClick={() => incrementdecrement('increment')}>
										<ImageComponent src={not_focus_icons.VectorAdd} />
									</span>
								</div>
								<Tooltip placement="right" title="For C3Pay and bank account holders" overlayClassName="toolTipOverlay">
									<div>
										<ImageComponent src={not_focus_icons.IconInfo} style={{ cursor: 'pointer' }} />
									</div>
								</Tooltip>
							</Col>
						</Row>
						<Row style={{ justifyContent: 'center' }}>
							<Col xl={20} lg={20} md={20} sm={24} xs={24}>
								<Row style={{ justifyContent: 'center', marginTop: '2%' }}>
									<Col xl={8} lg={12} md={12} sm={20} xs={20}>
										<PriceCard
											{...{
												imageText,
												cardPaid: 'per card / month',
												plan: 'Basic',
												month: `${monthlyFee}`,
												cardtext: 'Ideal for managing your entire payroll processes for all c3pay card holders',
											}}
										/>
									</Col>
									<Col xl={8} lg={12} md={12} sm={20} xs={20}>
										<PriceCard
											{...{
												imageText: secondContainer,
												cardPaid: 'per employee / month',
												plan: 'Plus',
												month: `${plus}`,
												cardtext: 'Manage payroll and HR processes all-in-one place for all your employees',
												onClickable,
											}}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<AccordianContent {...{ group }} />
			</Col>
		</Row>
	);
};

export default LandingNewPresentational;
