import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { cardUsuageDatas } from '../constants/cardUsageMockData';
import { renderQuestionAndAnswer } from './cardOrderFaqs';
import ItemHeader from '@pages/help/components/ItemHeader';
import '../employee.scss';

const CardUsageFaqs = () => {
	const [indexValue, setIndexValue] = useState();
	return (
		<>
			<Col className="employee-presentational">
				<Row className="employee-col">
					<Col xl={24} md={24} lg={24}>
						<ItemHeader title="Card Usage" />
						<Col className="employee-questions">
							{renderQuestionAndAnswer(cardUsuageDatas, indexValue, setIndexValue)}
						</Col>
					</Col>
				</Row>
			</Col>
		</>
	);
};
export default CardUsageFaqs;
