import Actions from './action';

export const initState = {
	isLeaveTemplateLoading: false,
	leaveTemplateDetail: [],
	isLeaveUploadLoading: false,
	leaveUploadDetails: [],
	isLeaveDocUploaded: 'loading',
	isBalanceLoading: false,
	isBalanceSubmitted: false,
	isGetBalanceLoading: false,
	getLeaveBalance: [],
	isLeaveApproverSubmitted: false,
};

const leaveApprovalReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.LEAVE_APPROVAL_TEMPLATE_START: {
			return {
				...state,
				isLeaveTemplateLoading: true,
			};
		}
		case Actions.types.LEAVE_APPROVAL_TEMPLATE_SUCCESS: {
			return {
				...state,
				isLeaveTemplateLoading: false,
				leaveTemplateDetail: action?.res,
			};
		}
		case Actions.types.LEAVE_APPROVAL_TEMPLATE_FAIL: {
			return {
				...state,
				isLeaveTemplateLoading: false,
				leaveTemplateDetail: [],
			};
		}
		case Actions.types.LEAVE_APPROVAL_UPLOAD_START: {
			return {
				...state,
				isLeaveUploadLoading: true,
				isLeaveDocUploaded: 'loading',
			};
		}
		case Actions.types.LEAVE_APPROVAL_UPLOAD_SUCCESS: {
			let isErrorData = 'yes';
			if (action.res && action.res.validationMessage) {
				isErrorData = 'yes';
			} else {
				isErrorData = 'no';
			}
			return {
				...state,
				isLeaveUploadLoading: false,
				isLeaveDocUploaded: isErrorData,
				leaveUploadDetails: action?.res,
			};
		}
		case Actions.types.LEAVE_APPROVAL_UPLOAD_FAIL: {
			return {
				...state,
				isLeaveUploadLoading: false,
				isLeaveDocUploaded: false,
				leaveUploadDetails: [],
			};
		}
		case Actions.types.GET_LEAVE_BALANCE_SUBMIT_START: {
			return {
				...state,
				isGetBalanceLoading: true,
			};
		}
		case Actions.types.GET_LEAVE_BALANCE_SUBMIT_SUCCESS: {
			return {
				...state,
				isGetBalanceLoading: false,
				getLeaveBalance: action.res,
			};
		}
		case Actions.types.GET_LEAVE_BALANCE_SUBMIT_FAIL: {
			return {
				...state,
				isGetBalanceLoading: false,
				getLeaveBalance: [],
			};
		}

		case Actions.types.LEAVE_BALANCE_SUBMIT_START: {
			return {
				...state,
				isBalanceSubmitted: false,
				isBalanceLoading: true,
			};
		}
		case Actions.types.LEAVE_BALANCE_SUBMIT_SUCCESS: {
			return {
				...state,
				isBalanceSubmitted: true,
				isBalanceLoading: false,
			};
		}
		case Actions.types.LEAVE_BALANCE_SUBMIT_FAIL: {
			return {
				...state,
				isBalanceSubmitted: false,
				isBalanceLoading: false,
			};
		}
		case Actions.types.SUBMIT_LEAVE_APPROVER: {
			return {
				...state,
				isLeaveApproverSubmitted: action.res,
			};
		}
		case Actions.types.SAVE_DOCUMENT_RESET: {
			return {
				...state,
				isLeaveUploadLoading: false,
				isLeaveDocUploaded: 'loading',
				leaveUploadDetails: [],
			};
		}
		default:
			return state;
	}
};

export default leaveApprovalReducer;
