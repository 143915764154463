import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
	client_id: process.env.REACT_APP_CLIENT_ID,
	redirect_uri: `${process.env.REACT_APP_LOGIN_URL}/signin-oidc`,
	post_logout_redirect_uri: `${process.env.REACT_APP_LOGIN_URL}/`,
	response_type: 'code',
	scope: 'openid email erae-payroll-client-api',
	authority: process.env.REACT_APP_AUTHORITY,
	acr_values: 'tenant:ae',
	clockSkew: 300 * 60,
	automaticSilentRenew: false,
	filterProtocolClaims: false,
	loadUserInfo: false,
	ui_locales: 'en',
};
const userManager = createUserManager(userManagerConfig);

export default userManager;
