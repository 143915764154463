import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { new_emp_Qus } from '../constants/newEmployeeMockData';
import { renderQuestionAndAnswer } from './cardOrderFaqs';
import ItemHeader from '@pages/help/components/ItemHeader';
import '../employee.scss';

const NewEmployeeFaqs = () => {
	const [indexValue, setIndexValue] = useState();
	return (
		<>
			<Col className="employee-presentational">
				<Row className="employee-col">
					<Col xl={24} md={24} lg={24}>
						<ItemHeader title='New Employee' />
						<Col className="employee-questions"> {renderQuestionAndAnswer(new_emp_Qus, indexValue, setIndexValue)}</Col>
					</Col>
				</Row>
			</Col>
		</>
	);
};
export default NewEmployeeFaqs;
