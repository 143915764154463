const GET_DEPARTMENT = 'GET_DEPARTMENT';
const GET_DEPARTMENT_DATA = 'GET_DEPARTMENT_DATA';
const POST_DEPARTMENT = 'POST_DEPARTMENT';
const PUT_DEPARTMENT_ERROR = 'PUT_DEPARTMENT_ERROR';
const PUT_DEPARTMENT_LOADING = 'PUT_DEPARTMENT_LOADING';

const getDepartment = () => ({
	type: GET_DEPARTMENT,
});
const putDepartmentLoading = (loadingStatus) => ({
	type: PUT_DEPARTMENT_LOADING,
	payload: loadingStatus,
});
const getDepartmentData = (response) => ({
	type: GET_DEPARTMENT_DATA,
	response,
});

const postDepartment = (data, userName) => ({
	type: POST_DEPARTMENT,
	payload: data,
	userName: userName,
});

const putDepartmentError = (error) => ({
	type: PUT_DEPARTMENT_ERROR,
	payload: error,
});

export default {
	types: {
		GET_DEPARTMENT: GET_DEPARTMENT,
		GET_DEPARTMENT_DATA: GET_DEPARTMENT_DATA,
		POST_DEPARTMENT: POST_DEPARTMENT,
		PUT_DEPARTMENT_ERROR: PUT_DEPARTMENT_ERROR,
		PUT_DEPARTMENT_LOADING: PUT_DEPARTMENT_LOADING,
	},
	creators: {
		getDepartment,
		getDepartmentData,
		postDepartment,
		putDepartmentError,
		putDepartmentLoading,
	},
};
