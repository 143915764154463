import React, { useState } from 'react';
import EdenredDateform from '@sharedComponent/date-fileds';
import { checkHrSubscription, templateDownload } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import SalaryService from 'src/services/payroll-service';
import SALARY_REPORTS_CONSTANTS from '@pages/salary-reports/constants';

const SalarymonthwisePresentational = ({ isFromSalaryTransferPage }) => {
	const _SalaryService = new SalaryService();
	let title = 'Salary Check Per Employee';
	if (isFromSalaryTransferPage) {
		title = 'Salary Transfer Certificate';
	}

	const [loading, setloading] = useState(false);
	const [reporttype, setreporttype] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const onsubmit = (body) => {
		setreporttype(body.reportType);
		setloading(true);
		isSubcribed
			? _SalaryService
					.salaryReportPerEmployeeSubscribe(body, 'GET', 'blob')
					.then(({ data }) => {
						if (data.type !== 'text/plain') {
							setloading(false);
							templateDownload(data, `${body.reportType === 'Excel' ? `${body.salarymethod}.xlsx` : `${body.salarymethod}.pdf`}`);
							setpopupVisible(true);
							setPopupMessage({
								titleMessage: `Report downloaded successfully`,
								subMessage: null,
							});
						} else {
							setpopupVisible(true);
							setPopupMessage({
								titleMessage: `There is no data for this Report..`,
								subMessage: null,
							});
							setloading(false);
						}
					})

					.catch((err) => {
						setloading(false);
						setloading(false);
					})
			: _SalaryService
					.salaryReportperemployee(body, 'GET', 'blob')
					.then(({ data }) => {
						if (data.type !== 'text/plain') {
							setloading(false);
							templateDownload(data, `${body.reportType === 'Excel' ? `${body.salarymethod}.xlsx` : `${body.salarymethod}.pdf`}`);
							setpopupVisible(true);
							setPopupMessage({
								titleMessage: `Report downloaded successfully`,
								subMessage: null,
							});
						} else {
							setpopupVisible(true);
							setPopupMessage({
								titleMessage: `There is no data for this Report..`,
								subMessage: null,
							});
							setloading(false);
						}
					})

					.catch((err) => {
						setloading(false);
						setloading(false);
					});
	};

	const onfinish = (body) => {
		setreporttype(body.reportType);
		setloading(true);
		_SalaryService
			.transfercertificate(body, 'GET', 'blob')
			.then(({ data }) => {
				if (data.type !== 'text/plain') {
					setloading(false);
					templateDownload(data, 'transfercertificate');
					setpopupVisible(true);
					setPopupMessage({
						titleMessage: `Report downloaded successfully`,
						subMessage: null,
					});
				} else {
					setloading(false);

					setpopupVisible(true);
					setPopupMessage({
						titleMessage: `There is no data for this Report..`,
						subMessage: null,
					});
				}
			})
			.catch((err) => {
				setloading(false);
				setloading(false);
			});
	};

	const closePopup = () => setpopupVisible(false);

	return (
		<>
			<Edenredmodal isOpen={popupVisible} title={popupMessage?.titleMessage} onOkayButton="Ok" onOkay={closePopup} />
			<EdenredDateform
				title={title}
				type={
					isFromSalaryTransferPage ? SALARY_REPORTS_CONSTANTS.salaryForm.type.perType : SALARY_REPORTS_CONSTANTS.salaryForm.type.perEmployee
				}
				onsubmit={onsubmit}
				onfinish={onfinish}
				loading={loading}
				reporttype={reporttype}
				{...{ isFromSalaryTransferPage }}
			/>
		</>
	);
};
export default SalarymonthwisePresentational;
