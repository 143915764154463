import React, { useState } from 'react';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '../index';

const ModelThree = () => {
	const [isOpen, setisOpen] = useState(false);
	return (
		<>
			<EdenredButtons
				{...{
					btnType: 'primary',
					onClick: () => setisOpen(true),
					style: { marginTop: '20px' },
				}}>
				Modal With primary Button And secondatry button
			</EdenredButtons>
			<Edenredmodal
				{...{
					title: 'Do You Want To Continue?',
					desc: 'Edenred',
					onCancelButton: 'Save',
					onCancel: () => setisOpen(false),
					isOpen: isOpen,
					secondarybtn: 'Secondary CTA',
					heart: true,
					center: true,
					textcenter: true,
				}}
			/>
		</>
	);
};

export default ModelThree;
