import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import { DotLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import EdenredEmployeesCard from '@sharedComponent/card/employees/employees-card';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { EMPLOYEES_PAGE_CONSTANTS } from '@constants/app-constants';

const ApproveEmployeeRequestPresentational = (props) => {
	let {
		approveRequest,
		isSelectClicked,
		visible,
		getDeleterole,
		showModal,
		closeModal,
		isSelectAll,
		isSelectedAllClicked,
		isSelectedCount,
		loading,
		action,
		selectedAction,
		cardType,
		setCardType,
	} = props;

	const cardActionModalTitle = (action = '') => {
		if (cardType === EMPLOYEES_PAGE_CONSTANTS.cards.type.all) {
			return `Do you want to ${action?.toLowerCase()} these card requests ?`;
		}
		return `Do you want to ${action?.toLowerCase()} this card ${(cardType || '')?.toLowerCase()} ?`;
	};

	return (
		<>
			<Col className="approve_employees_request_presentational">
				<EdenredHeader />
				{loading && (
					<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				)}
				{!loading && (
					<Row className="approve_requests_employee_container">
						<Col className="approve_requests_employee_title">
							<div className="title-name-and-select-section-employee-style">
								<ETypo h2 bold className="title-font-employee-color">
									Card Requests
								</ETypo>

								<ETypo b1 medium className="select-all-employee-section" onClick={isSelectedAllClicked}>
									{isSelectAll || isSelectedCount === approveRequest?.length ? 'Deselect All' : 'Select All'}
								</ETypo>
							</div>
							<div className="button-employee-section">
								<EdenredButtons
									disabled={isSelectedCount === 0 ? true : false}
									onClick={() => {
										setCardType(EMPLOYEES_PAGE_CONSTANTS.cards.type.all);
										showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject);
									}}
									{...{
										btnType: 'secondary',
									}}>
									Reject Selected ({isSelectedCount || 0})
								</EdenredButtons>
								<EdenredButtons
									onClick={() => {
										setCardType(EMPLOYEES_PAGE_CONSTANTS.cards.type.all);
										showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve);
									}}
									disabled={isSelectedCount === 0 ? true : false}
									{...{
										btnType: 'primary',
										style: { marginLeft: '8px' },
									}}>
									Approve Selected ({isSelectedCount || 0})
								</EdenredButtons>
							</div>
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="approve_requests_employee_card_section">
							{approveRequest?.length !== 0 ? (
								approveRequest?.map((content, ind) => {
									return (
										<Col className="approve_requests_employee_card_row ">
											<EdenredEmployeesCard
												isSelectClicked={() => isSelectClicked(ind)}
												isSelectedCount={isSelectedCount}
												showModal={(action) => props.handleCardAction(ind, action)}
												{...{
													type: 'ApproveEmployeeRequest',
													key: ind,
													content: content,
													className: 'approve_requests_employee_payroll_card',
													onClickApprove: showModal,
													setCardType,
												}}
											/>
										</Col>
									);
								})
							) : (
								<div style={{ textAlign: 'center', color: '#ddd', fontWeight: 600 }}>No records found</div>
							)}
						</Col>
						<Edenredmodal
							{...{
								title: cardActionModalTitle(selectedAction),
								onOkay: getDeleterole,
								onCancel: closeModal,
								isOpen: visible,
								close: closeModal,
							}}
						/>
					</Row>
				)}
			</Col>

			{/**modal_approve_request_employee -mobile view */}
			{isSelectedCount > 0 && (
				<Row className="modal_approve_employee_request_mobile_View">
					<Col sm={24} xs={24} className="payroll_run_header">
						<EdenredTypo>Card Requests:</EdenredTypo>
					</Col>
					<Col sm={24} xs={24} className="payroll_run_edenredtypo">
						<EdenredTypo>{isSelectedCount} Selected.</EdenredTypo>
					</Col>
					<Col sm={24} xs={24} className="reject_button">
						<Col sm={12} xs={12} style={{ fontWeight: 'bold', padding: '2%' }}>
							<EdenredButton
								style={{ fontWeight: 'bold' }}
								type="outline-g"
								onClick={() => {
									setCardType(EMPLOYEES_PAGE_CONSTANTS.cards.type.all);
									showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject);
								}}
								disabled={isSelectedCount === 0 ? true : false}>
								{EMPLOYEES_PAGE_CONSTANTS.cards.actions.reject}
							</EdenredButton>
						</Col>
						<Col sm={12} xs={12} style={{ fontWeight: 'bold', padding: '2%' }}>
							<EdenredButton
								style={{ fontWeight: 'bold' }}
								onClick={() => {
									setCardType(EMPLOYEES_PAGE_CONSTANTS.cards.type.all);
									showModal(EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve);
								}}
								disabled={isSelectedCount === 0 ? true : false}>
								{EMPLOYEES_PAGE_CONSTANTS.cards.actions.approve}
							</EdenredButton>
						</Col>
					</Col>
					<Col sm={24} xs={24} className="payroll_run">
						<EdenredButton type="link" onClick={isSelectedAllClicked}>
							{isSelectAll || isSelectedCount === approveRequest.length ? 'Deselect All Card Requests' : 'Select All Card Requests'}
						</EdenredButton>
					</Col>
				</Row>
				// ===================================================
				// new design
				// <Row style={{ width: '100%', alignSelf: 'flex-start' }}>
				// 		<Row className="approve_requests_container">
				// 			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="approve_requests_title">
				// 				<div className="Title-name-and-select-section-style">
				// 					<ETypo h2 bold className="title-font-color">
				// 						Card Requests
				// 					</ETypo>

				// 					<ETypo b1 medium className="select-all-section" onClick={isSelectedAllClicked}>
				// 						{isSelectAll === true || isSelectedCount === payrollruns.length ? 'Deselect All' : 'Select All'}
				// 					</ETypo>
				// 				</div>
				// 				<div className="button-section">
				// 					<EdenredButtons
				// 						{...{
				// 							btnType: 'secondary',
				// 							disabled: isSelectedCount === 0 ? true : false,
				// 							onClick: () => showModal('', 'Reject'),
				// 						}}>
				// 						Reject Selected ({isSelectedCount || 0})
				// 					</EdenredButtons>
				// 					<EdenredButtons
				// 						{...{
				// 							btnType: 'primary',
				// 							style: { marginLeft: '8px' },
				// 							disabled: isSelectedCount === 0 ? true : false,
				// 							onClick: () => showModal('', 'Approve'),
				// 						}}>
				// 						Approve Selected ({isSelectedCount || 0})
				// 					</EdenredButtons>
				// 				</div>
				// 			</Col>
				// 			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="approve_requests_card_section">
				// 				{payrollruns && payrollruns.length > 0 ? (
				// 					payrollruns.map((payrollRun, index) => (
				// 						<Col className="approve_requests_card_row">
				// 							<EdenRedCard
				// 								{...{
				// 									type: 'ApproveRequests',
				// 									key: index,
				// 									content: payrollRun,
				// 									className: 'approve_requests_payroll_card',
				// 									handlecheckbox: handlemodalopen,
				// 									onClickApprove: showModal,
				// 									isSelectClicked,
				// 									viewDetailsClicked,
				// 									isSelectedCount,
				// 								}}
				// 							/>
				// 						</Col>
				// 					))
				// 				) : (
				// 					<div style={{ textAlign: 'center', color: '#ddd', fontWeight: 600 }}>No records found</div>
				// 				)}
				// 			</Col>
				// 		</Row>
				// 		{modalopenMobile && (
				// 			<Row className="modal_approve_request_mobile_View">
				// 				<Col sm={24} xs={24} className="payroll_run_header">
				// 					<ETypo h2 bold className="heading-font">
				// 						Approve payroll
				// 					</ETypo>
				// 				</Col>
				// 				<Col sm={24} xs={24} className="payroll_run_edenredtypo">
				// 					<EdenredTypo h6 bold>
				// 						{isSelectedCount || 0} Selected.
				// 					</EdenredTypo>
				// 				</Col>
				// 				<Col sm={24} xs={24} className="reject_button">
				// 					<Col sm={12} xs={12} style={{ fontWeight: 'bold', padding: '2%' }}>
				// 						<EdenredButton
				// 							style={{ fontWeight: 'bold' }}
				// 							loading={loading}
				// 							type="outline-g"
				// 							onClick={() => showModal('', 'Reject')}>
				// 							Reject
				// 						</EdenredButton>
				// 					</Col>
				// 					<Col sm={12} xs={12} style={{ fontWeight: 'bold', padding: '2%' }}>
				// 						<EdenredButton style={{ fontWeight: 'bold' }} loading={loading} onClick={() => showModal('', 'Approve')}>
				// 							Approve
				// 						</EdenredButton>
				// 					</Col>
				// 				</Col>
				// 				<Col sm={24} xs={24} className="payroll_run">
				// 					<EdenredButton type="link" onClick={isSelectedAllClicked}>
				// 						{isSelectAll === true || isSelectedCount === payrollruns.length
				// 							? 'Deselectall All Payroll Runs'
				// 							: 'Select All Payroll Runs'}
				// 					</EdenredButton>
				// 				</Col>
				// 			</Row>
				// 		)}
				// 		<EdenredModal
				// 			{...{
				// 				isOpen: approveModal,
				// 				afterOpenModal,
				// 				getDeleterole: updateRunPayrollReview,
				// 				closeModal,
				// 				loading,
				// 				go_Back: 'No',
				// 				className: 'modal_card_approve',
				// 				user: `Do you want to ${reviewStatus} this payroll
				// 	submission?`,
				// 				content_web: reviewStatus !== 'Reject' ? 'Payroll will run immediately.' : '',
				// 				content_mobile: reviewStatus !== 'Reject' ? 'Payroll will run immediately.' : '',
				// 			}}
				// 		/>
				// 	</Row>
			)}
		</>
	);
};
export default ApproveEmployeeRequestPresentational;
