export const mergeAllClaims = (currentUser) => {
	const employeeClaims = [];
	for (let obj in currentUser?.employeeClaims) {
		employeeClaims.push(currentUser?.employeeClaims?.[obj]?.value);
	}
	const hrClaimsValue = [];
	for (let obj in currentUser?.hrClaims) {
		hrClaimsValue.push(currentUser?.hrClaims?.[obj]?.value);
	}
	const payrollClaimsValue = [];
	for (let obj in currentUser?.payrollClaims) {
		payrollClaimsValue.push(currentUser?.payrollClaims?.[obj]?.value);
	}
	const teamsClaimsValue = [];
	for (let obj in currentUser?.teamClaims) {
		teamsClaimsValue.push(currentUser?.teamClaims?.[obj]?.value);
	}
	return [...hrClaimsValue, ...payrollClaimsValue, ...teamsClaimsValue, ...employeeClaims];
};
