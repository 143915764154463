import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { DotLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import { arrayToObject, numberWithCommas, twoDigitAfterDec } from '@helpers';
import { managePayrollClaim } from '@constants/app-constants';
import ClevertapReact from 'src/utils/clever-tap';
import action from '../action';
import Selectors from '../selector';
import PayrollRunPresentational from './payroll-run-presentational';

const DEFATULT_ADDITIONS = {
	additionTypeId: '',
	id: '',
	value: 0,
	index: 0,
	name: '',
};
const DEFATULT_DEDUCTIONS = {
	deductionTypeId: '',
	id: '',
	value: 0,
	index: 0,
	name: '',
};
function PayrollRunFunctional({
	employeeDetails,
	setDefaultTab,
	getEmployeeById,
	payrollRunData = [],
	payRollRun = '',
	salaryProcessStatus = [],
	salaryPaidStatus = '',
	salaryPaidStatusLoading = false,
	savePayrollRun = [],
	additionsCall = '',
	deductionsCall = '',
	additionValue = [],
	deductionValue = [],
	terminationType = [],
	terminationTypes = '',
	overTimePayCal = [],
	overTimePayCalculation = '',
	handleSaveSaga,
	savePopup,
	savePayrollRunLoading,
	savedSucceessfulPopUp,
	savedSuccessfully,
	payRollRunLoading,
	payRollRunLists,
	modifiedMonthYear,
	getModifiedMonthYearReducer,
	setNavigateTabCheck,
	navigateTabCheck,
	additions,
	setAdditions,
	deductions,
	setDeductions,
	gratuity,
	setGratuity,
	runEmployeeAllowanceList,
	setRunEmployeeAllowanceList,
	notification,
	setNotification,
	employeeAllowance,
	setEmpolyeeAllowance,
	empolyeeSalary,
	setEmpolyeeSalary,
	termiation,
	setTerminationType,
	overTimes,
	setOverTimes,
	month,
	setmonth,
	year,
	setyear,
	savePayrollValue,
	setSaveValue,
	gratutiycal,
	setGratuityCal,
	leavingDate,
	setLeavingDate,
	terminationId,
	setTerminationId,
}) {
	const history = useHistory();
	const [additionFinal, setAdditionFinal] = useState([]);
	const [deductionFinal, setDeductionFinal] = useState([]);
	const [isCancle, setIsCancle] = useState(false);
	const [saveButton, setSaveButton] = useState(false);
	const [saveCancleButton, setSaveCancleBuuton] = useState(false);
	// const [notification, setNotification] = useState(false);
	const [buttondisabel, setbuttondisabel] = useState(false);
	const [defaultSalary, setDefaultSalary] = useState({});
	const gratutitycalValue1 = [
		{
			value: gratutiycal ? gratutiycal : 0,
			index: 0,
		},
	];
	// checking claims
	const payrollClaims = useSelector((state) => state?.sharedStates?.currentUser?.payrollClaims) || [];
	let isPayrollRunClaims = [];
	const data = payrollClaims?.map((item) => {
		if (managePayrollClaim?.includes(item?.value)) {
			isPayrollRunClaims.push(item);
		}
	});
	const newPayroll = JSON.parse(JSON.stringify(payRollRunLists));
	useEffect(() => {
		salaryProcessStatus(employeeDetails?.employeeId || history?.location.state?.tableData?.employeeCode);
		additionsCall();
		deductionsCall();
		overTimePayCal();
		terminationType();
	}, []);

	useEffect(() => {
		let modifiedData = additions?.map((item, i) => {
			return {
				...item,
				index: i,
			};
		});
		setAdditions([...modifiedData]);
	}, [additionFinal]);

	useEffect(() => {
		let modifiedData = deductions?.map((item, i) => {
			return {
				...item,
				index: i,
			};
		});
		setDeductions([...modifiedData]);
	}, [deductionFinal]);

	useEffect(() => {
		payrollRundatas(payRollRun, additionValue);
		payrollRundatas1(payRollRun, deductionValue);
		var details = payRollRun?.employeeContract;
		details?.leavingDate && terminationTypeMethod(payRollRun);
	}, [payRollRun, deductionValue, additionValue]);

	const payrollRundatas = (payRollRun, additionValue) => {
		const concateArray = payRollRun?.additions?.map((itm) => ({
			...additionValue?.find((item) => item.id === itm.additionTypeId && item),
			...itm,
		}));
		var modifiedData = concateArray?.map((item, i) => {
			return {
				...item,
				additionTypeId: item?.additionTypeId,
				value: numberWithCommas(item?.value),
				id: item?.id,
				name: item?.name,
				index: i,
			};
		});
		modifiedData && setAdditions([...modifiedData]);
	};
	const payrollRundatas1 = (payRollRun, deductionValue) => {
		const concateArray1 = payRollRun?.deductions?.map((itm) => ({
			...deductionValue?.find((item) => item?.id === itm?.deductionTypeId && item),
			...itm,
		}));
		var modifiedData = concateArray1?.map((item, i) => {
			return {
				...item,
				deductionTypeId: item?.deductionTypeId,
				value: numberWithCommas(item?.value),
				id: item?.id,
				name: item?.name,
				index: i,
			};
		});
		modifiedData && setDeductions([...modifiedData]);
	};

	const terminationTypeMethod = (payRollRun, date, type) => {
		let data = payRollRun?.employeeContract;
		let salary = payRollRun?.employeeBasicSalary;
		let leaveingdate = date != undefined ? moment(date).format('YYYY/MM/DD') : moment(data?.leavingDate).format('YYYY/MM/DD');
		let joiningdate = moment(data?.joiningDate).format('YYYY/MM/DD');
		var years = moment(leaveingdate).diff(moment(joiningdate), 'year');
		var months = moment(leaveingdate).diff(moment(joiningdate), 'months') - years * 12;
		moment(joiningdate).add(years, 'years').add(months, 'months');
		let days = moment(leaveingdate).diff(moment(joiningdate), 'days');
		let salary_per_day = salary?.value / 30;
		//var basicsalaryCal = (salary?.value * 24) / 30;
		let no_of_days = parseInt(days + 1);
		let salary_per_days = salary_per_day;
		let period = no_of_days / 365;
		let Gratuity;
		if (years >= 1 && years < 5) {
			//Here we are calculate more than  and eaqual to one year less than 5 years
			Gratuity = salary_per_days * period * 21;
		} else if (years >= 5) {
			// const gDays = moment(joiningdate).add(4, "years").add(11, "months").add(29, "days").format('YYYY/MM/DD')
			// let nDays = moment(gDays).diff(moment(joiningdate), 'days'); For Future
			// // Here getting the 4 years 11 months 29 days to find the period
			// let period1 = 1825 / 365;
			// // Here We are finding te values beyound 5 years
			// let days = parseInt(no_of_days) - 1825;
			// let period2 = days / 365;
			// // Here we calculate 4 year 11 months 29 days calculation
			// let Gratuity1 = salary_per_days * period1 * 21;
			// Here we calculate remaing days for after 4 years 11 months 29 days
			let Gratuity2 = salary_per_days * period * 30;
			Gratuity = Gratuity2;
		} else {
			Gratuity = 0;
		}
		let GratuityRound = Math.round(Gratuity);
		setGratuityCal(GratuityRound);
	};

	const handlePayrollRun = (type) => {
		if (type === 'additions') {
			setAdditions((prev) => [...prev, { ...DEFATULT_ADDITIONS, index: additions.length }]);
		} else {
			setDeductions((prev) => [...prev, { ...DEFATULT_DEDUCTIONS, index: deductions.length }]);
		}
		setSaveButton(true);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
	};

	const handleChangeDropdown = (data, name, index, type) => {
		if (type === 'additions') {
			let modifiedData = additions?.map((d) => {
				if (d.index === index) {
					return { ...d, [name]: data?.name, additionTypeId: data?.id };
				}
				return d;
			});
			setAdditions([...modifiedData]);
		} else {
			let modifiedData = deductions.map((d) => {
				if (d.index === index) {
					return { ...d, [name]: data?.name, deductionTypeId: data?.id };
				}
				return d;
			});
			setDeductions([...modifiedData]);
		}
		setSaveButton(true);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
	};

	const handleChange = (data, name, index, type) => {
		if (type === 'additions') {
			let modifiedData = additions?.map((d) => {
				if (d?.index === index) {
					return { ...d, [name]: twoDigitAfterDec(data) };
				}
				return d;
			});
			setAdditions([...modifiedData]);
		} else {
			let modifiedData = deductions?.map((d) => {
				if (d?.index === index) {
					return { ...d, [name]: twoDigitAfterDec(data) };
				}
				return d;
			});
			setDeductions([...modifiedData]);
		}
		setSaveButton(true);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
	};

	const handleRemove = (index, type) => {
		if (type === 'additions') {
			let finalList = additions?.filter((d) => d.index !== index);
			setAdditionFinal([...finalList]);
			setAdditions([...finalList]);
		} else {
			let finalList = deductions?.filter((d) => d.index !== index);
			setDeductionFinal([...finalList]);
			setDeductions([...finalList]);
		}
		setSaveButton(true);
		setNotification(true);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '2',
		});
	};

	useEffect(() => {
		if (salaryPaidStatus.salaryTypeList && salaryPaidStatus.salaryTypeList.length > 0) {
			currentmonth(salaryPaidStatus.salaryTypeList);
		}
	}, [salaryPaidStatus]);
	const currentmonth = (salary) => {
		let currentMonth = salary?.filter((item, index) => {
			if (moment(item?.salaryDate).format('M-Y') === moment().format('M-Y') && item?.salaryProcessStatus === 'Salaries Paid') {
				return {
					salaryDate: item.salaryDate,
					salaryProcessStatus: item.salaryProcessStatus,
				};
			} else if (
				moment(employeeDetails.joiningDate).format('M-Y') === moment().format('M-Y') &&
				moment(item.salaryDate).format('M-Y') === moment().format('M-Y')
			) {
				return {
					salaryDate: item.salaryDate,
					salaryProcessStatus: item.salaryProcessStatus,
				};
			} else if (moment(employeeDetails.joiningDate).format('M-Y') != moment().format('M-Y')) {
				const final = salary?.filter((final) => final.salaryProcessStatus === 'Salaries Paid');
				const dataa = final.length - 1;
				if (moment(final[dataa]?.salaryDate).add(1, 'M').format('MM YYYY') === moment(item?.salaryDate).format('MM YYYY')) {
					return {
						salaryDate: item.salaryDate,
						salaryProcessStatus: item.salaryProcessStatus,
					};
				}
			}
		});
		const nDate = modifiedMonthYear == 0 ? currentMonth?.[0]?.salaryDate : moment(modifiedMonthYear).format('MMMM YYYY');
		setDefaultSalary({
			...defaultSalary,
			salaryDate: nDate,
			salaryProcessStatus: currentMonth?.[0]?.salaryProcessStatus,
		});
		const newSalaryProcess = salary?.filter((data) => moment(data?.salaryDate).format('MMMM YYYY') === modifiedMonthYear);

		if (modifiedMonthYear === 0 && currentMonth?.[0]?.salaryProcessStatus === 'Salaries Paid') {
			setbuttondisabel(true);
		} else if (modifiedMonthYear !== 0 && newSalaryProcess?.[0]?.salaryProcessStatus === 'Salaries Paid') {
			setbuttondisabel(true);
		} else {
			setbuttondisabel(false);
		}
		let nM0nth = moment(modifiedMonthYear).format('M');
		let nYear = moment(modifiedMonthYear).format('Y');
		let month1 = modifiedMonthYear === 0 ? moment(currentMonth?.[0].salaryDate).format('M') : nM0nth;
		let year2 = modifiedMonthYear === 0 ? moment(currentMonth?.[0].salaryDate).format('Y') : nYear;
		setmonth(month1);
		setyear(year2);

		payrollRunData(employeeDetails, month1, year2);
	};
	const onChange = (data) => {
		setDefaultSalary({
			...defaultSalary,
			salaryDate: data,
		});
		var salary = salaryPaidStatus.salaryTypeList;
		let compare = salary.filter((items) => data === moment(items.salaryDate).format('MMMM YYYY'));

		if (compare?.[0]?.salaryProcessStatus === 'Salaries Paid') {
			setbuttondisabel(true);
		} else {
			setbuttondisabel(false);
		}
		var month1 = moment(data).format('M');
		var year1 = moment(data).format('Y');
		getModifiedMonthYearReducer(data);
		setmonth(month1);
		setyear(year1);
		payrollRunData(employeeDetails, month1, year1);
	};
	const savePayroll = (type, saveValue) => {
		const cleverTapProperties = {
			BasicSalary: empolyeeSalary?.value || 0,
			OvertimeOnWeekdays: overTimes?.[0]?.hours || 0,
			OvertimeOnWeekends: overTimes?.[1]?.hours || 0,
			OvertimeOnPH: overTimes?.[2]?.hours || 0,
			Gratuity: gratutitycalValue1?.[0]?.value || 0,
			TypeOfTermination: termiation || payRollRun?.employeeContract?.terminationType,
			...arrayToObject(additions, 'value', 'Addition', true),
			...arrayToObject(deductions, 'value', 'Deduction', true),
			...arrayToObject(employeeAllowance, 'value', 'Allowance', true),
		};
		if (type === 'save') {
			ClevertapReact.event('Save_PayrollRun', { cleverTapProperties });
			setSaveValue(saveValue);
			handleSaveSaga(true);
		} else {
			setIsCancle(true);
		}
		setNotification(false);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '2',
		});
	};

	const handleSavePayroll = (value) => {
		switch (value) {
			case 'closeSavePopUpRun': {
				return handleSaveSaga(false);
			}
			case 'handleSavePopUpRun': {
				savePayrollRun(employeeDetails, savePayrollValue, month, year, gratuity);
				setNotification(false);
				setNavigateTabCheck({
					...navigateTabCheck,
					isTabClicked: false,
					tabKey: '2',
				});
				break;
			}
			case 'handleCancelPopUpRun': {
				setIsCancle(false);
				payrollRunData(employeeDetails, month, year);
				setSaveButton(false);
				setGratuityCal(0);
				setGratuity(false);
				setSaveCancleBuuton(false);
				setNotification(false);
				setNavigateTabCheck({
					...navigateTabCheck,
					isTabClicked: false,
					tabKey: '2',
				});
				break;
			}
			case 'closeCancelPopUpRun': {
				setIsCancle(false);
				setNotification(false);
				setNavigateTabCheck({
					...navigateTabCheck,
					isTabClicked: false,
					tabKey: '2',
				});
				break;
			}
			case 'closeSuccessPopUpRun': {
				savedSucceessfulPopUp(false);
				savedSuccessfully && getEmployeeById(employeeDetails.id);
				history.push({ pathname: '/employees/viewemployee', state: { selectedTab: '2' } });
				break;
			}
		}
	};
	const dataPicker = (dateObj, formatStr, payRollRun1) => {
		const result = formatStr.split('/').reverse().join('-');
		setLeavingDate(result);
		terminationTypeMethod(payRollRun1, result);
		setSaveButton(true);
	};
	const onChangeTermination = (data, payRollRun1, date1) => {
		setTerminationType(data.id);
		setTerminationId(data.id);
		setSaveButton(true);
	};

	if (salaryPaidStatusLoading || payRollRunLoading || salaryPaidStatus?.salaryTypeList?.length <= 0) {
		return (
			<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
				<DotLoader size={90} color={'#e91d24'} loading />
			</div>
		);
	}

	return (
		<>
			<PayrollRunPresentational
				{...{
					additions,
					deductions,
					type: DEFATULT_ADDITIONS.type,
					value: DEFATULT_ADDITIONS.value,
					index: DEFATULT_ADDITIONS.index,
					handlePayrollRun,
					handleChangeDropdown,
					handleChange,
					handleRemove,
					salaryPaidStatusVal: salaryPaidStatus.salaryTypeList,
					payrolldata: payRollRun,
					onChange,
					savePayroll,
					additionValues: additionValue,
					deductionValues: deductionValue,
					defaultSalary,
					overTimePayCalculation,
					terminationTypes,
					dataPicker: dataPicker,
					employeeDetail: payRollRun.employeeContract,
					gratutitycalValue1,
					onChangeTermination,
					savePopup,
					savePayrollValue,
					handleSavePayroll,
					savePayrollRunLoading,
					savedSuccessfully,
					isCancle,
					saveButton,
					payRollRunLoading,
					salaryPaidStatusLoading,
					setGratuity,
					gratuity,
					setSaveCancleBuuton,
					saveCancleButton,
					setNotification,
					notification,
					buttondisabel,
					setGratuityCal,
					setTerminationType,
					setDefaultTab,
					month,
					year,
					payRollRun: newPayroll,
					isPayrollRunClaims,
					setNavigateTabCheck,
					navigateTabCheck,
					employeeAllowance,
					setEmpolyeeAllowance,
					overTimes,
					setOverTimes,
					empolyeeSalary,
					setEmpolyeeSalary,
					runEmployeeAllowanceList,
					setRunEmployeeAllowanceList,
					setSaveValue,
					leavingDate,
					setLeavingDate,
					terminationId,
					setTerminationId,
				}}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		salaryPaidStatusLoading: Selectors.salaryPaidStatusLoading(state),
		salaryPaidStatus: Selectors.salaryPaidStatus(state),
		salaryPaidStatusError: Selectors.salaryPadiStatusError(state),
		payRollRunLoading: Selectors.payRollRunLoading(state),
		payRollRun: Selectors.payRollRun(state),
		payRollRunLists: Selectors.payRollRunLists(state),
		payRollRunError: Selectors.payRollRunError(state),
		additionLoading: Selectors.additionLoading(state),
		additionValue: Selectors.additionValue(state),
		additionError: Selectors.additionError(state),
		deductionLoading: Selectors.deductionLoading(state),
		deductionValue: Selectors.deductionValue(state),
		deductionError: Selectors.deductionError(state),
		overTimePayCalLoading: Selectors.overTimePayCalLoading(state),
		overTimePayCalculation: Selectors.overTimePayCal(state),
		overTimePayCalError: Selectors.overTimePayError(state),
		terminationTypeLoading: Selectors.terminationTypeLoading(state),
		terminationTypes: Selectors.terminationType(state),
		terminationTypeError: Selectors.terminationTypeError(state),
		savePopup: Selectors.savePopUp(state),
		savePayrollRunLoading: Selectors.savePayrollRunLoading(state),
		savePayrollRun: Selectors.savePayrollRun(state),
		savePayrollRunErorr: Selectors.savePayrollRunError(state),
		savedSuccessfully: Selectors.savedSuccessfully(state),
		modifiedMonthYear: Selectors.modifiedMonthYear(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		salaryProcessStatus: (employeeDetails) => dispatch(action.creators.getSalaryProcessStatusStart(employeeDetails)),
		payrollRunData: (employeeDetails, month, year) => dispatch(action.creators.getPayrollRunStart(employeeDetails, month, year)),
		additionsCall: () => dispatch(action.creators.getAdditionValueStart()),
		deductionsCall: () => dispatch(action.creators.getDeductionValueStart()),
		handleSaveSaga: (flag) => dispatch(action.creators.getSavePopup(flag)),
		savedSucceessfulPopUp: (flag) => dispatch(action.creators.savedSucceessfulPopUp(flag)),
		savePayrollRun: (employeeDetails, saveValue, month, year, gratuity) =>
			dispatch(action.creators.getSavePayrollRunStart(employeeDetails, saveValue, month, year, gratuity)),
		overTimePayCal: () => dispatch(action.creators.getOverTimePayCalStart()),
		terminationType: () => dispatch(action.creators.getTerminationTypeStart()),
		getModifiedMonthYearReducer: (value) => dispatch(action.creators.getModifiedMonthYearReducer(value)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(PayrollRunFunctional);

export default Connected;
