import React from 'react';
import RunPayrollTablePresentational from './run-payroll-table-presentation';

const RunPayrollPresentational = ({
	handleFilterHeader,
	show,
	totalEmpSelect,
	totalEmpAmt,
	totalVariableAmt,
	columns,
	data,
	loading,
	handletoggleswitch,
	checkedIndex,
	checkAll,
	hanldePayTableData,
	filterClicked,
	paytableDataDummy,
	checkClicked,
	SetPayTableData,
	handleDataNext,
	stagingLoader,
	onSearch,
	selectedFilterCount,
	hasError,
	getrunpayrollTable,
	tableFilter,
	fromUploadSheetPage,
	settableFilter,
	totalFilterCount,
	dropdwon,
	setDropdown,
	setDropdown1,
	handleClearAllFilter,
	setTotalFilterCount,
	indupdateTotalFilterCount,
	payrollPagination,
	handleTableChange,
	activeEmployeeCount,
	blockedData,
	isRepeatMode,
	totalCount,
	isPopupVisible,
	setIsPopupVisible,
	nextLoader,
	isSalaryExceeds,
}) => {
	return (
		<RunPayrollTablePresentational
			data={data}
			loading={loading}
			handletoggleswitch={handletoggleswitch}
			totalEmpSelect={totalEmpSelect}
			totalEmpAmt={totalEmpAmt}
			totalVariableAmt={totalVariableAmt}
			columns={columns}
			dataSource={data}
			paytableDataDummy={paytableDataDummy}
			checkedIndex={checkedIndex}
			checkAll={checkAll}
			hanldePayTableData={hanldePayTableData}
			handleFilterHeader={handleFilterHeader}
			filterClicked={filterClicked}
			show={show}
			settableFilter={settableFilter}
			SetPayTableData={SetPayTableData}
			checkClickedfunc={checkClicked}
			handleDataNext={handleDataNext}
			stagingLoader={stagingLoader}
			onSearch={onSearch}
			selectedFilterCount={selectedFilterCount}
			hasError={hasError}
			getrunpayrollTable={getrunpayrollTable}
			tableFilter={tableFilter}
			totalFilterCount={totalFilterCount}
			dropdwon={dropdwon}
			setDropdown={setDropdown}
			setDropdown1={setDropdown1}
			handleClearAllFilter={handleClearAllFilter}
			setTotalFilterCount={setTotalFilterCount}
			indupdateTotalFilterCount={indupdateTotalFilterCount}
			payrollPagination={payrollPagination}
			handleTableChange={handleTableChange}
			activeEmployeeCount={activeEmployeeCount}
			fromUploadSheetPage={fromUploadSheetPage}
			blockedData={blockedData}
			isRepeatMode={isRepeatMode}
			totalCount={totalCount}
			{...{ isPopupVisible, setIsPopupVisible, nextLoader, isSalaryExceeds }}
		/>
	);
};

export default RunPayrollPresentational;
