import Action from './action';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import React, { useState } from 'react';
import setupAction from 'src/components/shared/app-action-reducer/action';
import SetupTitle from '../Component/SetupTitle/SetupTitle';
import Step from '../Component/Step/Step';
import UploadDocument from '../UploadPayrollDetails/UploadDocument';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClevertapReact from 'src/utils/clever-tap';
import './documentExpiry.scss';

const uploadType = [
	{
		id: 1,
		option: 'Option 1:',
		icons: focus_state_icons?.Date,
		typeTitle: 'Documents Table',
		typeDescription: "Enter your employees' document expiry dates one by one into the documents table.",
		btnText: 'Add In The Table',
	},
	{
		id: 2,
		option: 'Option 2:',
		icons: focus_state_icons?.excelFrame,
		typeTitle: 'Excel Spreadsheet',
		typeDescription: 'Download your new template with your employees’ documents details and upload it to get reminders.',
		btnText: 'Add In bulk',
	},
];
const DocumentExpiry = ({
	isShowAddOptions = false,
	isShowSecondaryBtn = true,
	navigate = '/documents/preview-upload-document',
	cleverTap = true,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const uploadData = useSelector((state) => state?.hrSetup?.documentsExpiry?.uploadDocument || '');
	const downloadTemplate = useSelector((state) => state?.hrSetup?.documentsExpiry?.downloadTemplate || '');
	const [draggerStatus, setDraggerStatus] = useState(true);
	const [showModal, setShowaModal] = useState(false);
	const [showDragger, setShowDragger] = useState(isShowAddOptions);
	const updateClever = history?.location?.state?.updateCleverTap ?? false;
	const handleUploadChange = (data) => {
		dispatch(Action.creators.postUpload(data));
		setDraggerStatus(false);
	};
	const handleRemove = () => {
		setDraggerStatus(true);
		dispatch(Action.creators.putUploadReset());
	};
	const handleSubmit = () => {
		ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStartedBulk_Save' : 'V1_1_DocsOnboardBulk_Save');
		dispatch(setupAction.creators.postHrSetupStatus({ documentCompleted: true }));
		updateClever ? history.push({ pathname: navigate, state: { updateCleverTap: true } }) : history.push(navigate);
	};
	const steps = [
		{
			stepNo: 1,
			iconSrc: focus_state_icons?.ClipboardText,
			descriptionText: `Prepare your employees’ documents (Emirates ID, Visa, Passport & Labour Card).`,
		},
		{
			stepNo: 2,
			iconSrc: focus_state_icons?.documentDownload,
			descriptionText: `Download your new documents Excel template.`,
			btnType: 'secondary',
			btnText: 'Download template',
		},
	];
	const handleAddInTable = () => {
		ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStarted_AddTable' : 'V1_1_DocsOnboard_AddTable');
		dispatch(setupAction.creators.setHrSetupStatus({ documentCompleted: true }));
		history.push('/hrdocuments');
	};
	const buttonStatus = () => {
		// handling submit button state
		if (showDragger === false) {
			return 'secondary';
		} else if (draggerStatus === true) {
			return 'secondary';
		} else if (uploadData?.data?.validationMessage?.length > 0) {
			return 'secondary';
		} else if (uploadData?.loading) {
			return 'secondary';
		} else if (uploadData?.error) {
			return 'secondary';
		} else {
			return 'primary';
		}
	};
	return (
		<>
			<div className="containers">
				<div className="title-box">
					<SetupTitle
						title={`Add document's expiry dates to get alerts`}
						rightBtnType={buttonStatus()}
						rightBtnDisabled={buttonStatus() == 'secondary'}
						rightBtnText={showDragger && 'Save & Finish'}
						rightBtnClick={handleSubmit}
						leftBtnType="secondary"
						leftBtnText={isShowSecondaryBtn && 'Do It Later'}
						leftBtnClick={() => {
							showDragger
								? ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStartedBulk_later' : 'V1_1_DocsOnboardBulk_later')
								: ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStarted_later' : 'V1_1_DocsOnboard_later');
							setShowaModal(true);
						}}
						leftBtnRightIcon={focus_state_icons.RightClrArrow}
					/>
				</div>
				<div className="content-wrapper">
					<div className="main-content">
						{showDragger === false && (
							<div className="hero-content">
								<div className="info_tag">
									<ImageComponent className="rightIcon" src={focus_state_icons.information} />
									<span>
										<ETypo H4>
											You can now get alerts before your employees' documents expire. Upload the expiry dates of Emirates ID and
											Passport using one of the methods below
										</ETypo>
									</span>
								</div>
								<div className="upload-options-wrapper">
									{uploadType.map((item) => (
										<div className="option-wrapper">
											<span>
												<ETypo H5>{item?.option}</ETypo>
											</span>
											<div className="option-content">
												<ImageComponent
													src={item?.icons}
													className={item?.typeTitle === 'Documents Table' ? 'icon-container' : ''}
												/>
												<span>
													<ETypo H4>{item?.typeTitle}</ETypo>
												</span>
												<span className="description-center">{item?.typeDescription}</span>
												<span>
													<EdenredButtons
														btnType="secondary"
														children={item?.btnText}
														onClick={() =>
															item?.id === 1
																? handleAddInTable()
																: (ClevertapReact.event(
																		cleverTap ? 'V1_1_Docs_GetStarted_AddBulk' : 'V1_1_DocsOnboard_AddBulk'
																  ),
																  setShowDragger(true))
														}
													/>
												</span>
											</div>
										</div>
									))}
								</div>
							</div>
						)}
						{showDragger && (
							<div className="steps-wrapper">
								{steps.map((item) => (
									<Step
										stepNo={item?.stepNo}
										iconSrc={item?.iconSrc}
										descriptionText={item?.descriptionText}
										btnType={item?.btnType}
										btnText={item?.btnText}
										className="step-description-style"
										isBtnLoading={downloadTemplate?.loading}
										btnOnClick={() => {
											dispatch(
												Action.creators.getDownloadTemplate({
													documentTypeFilter: null,
													employeeIdNameFilter: '',
													expiryDateFilter: null,
													pageNumber: 0,
													pageSize: 0,
													reportType: 'excel',
												})
											);
											updateClever
												? ClevertapReact.event('V1_1_Docs_UploadBulk_download')
												: ClevertapReact.event(
														cleverTap ? 'V1_1_Docs_GetStartedBulk_download' : 'V1_1_DocsOnboardBulk_download'
												  );
										}}
									/>
								))}
								<div className="step-3-container">
									<span>
										<ETypo H5>Step 3:</ETypo>
									</span>
									<UploadDocument
										uploadData={uploadData?.data?.validationMessage || uploadData?.error || ''}
										uploadingStatus={uploadData?.loading || ''}
										onChange={(data) => handleUploadChange(data)}
										draggerStatus={draggerStatus}
										onRemove={handleRemove}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<Edenredmodal
				title="You didn’t add employees’ details"
				desc="You're missing out on alerts before documents expire."
				onCancelButton="Skip For Now"
				onOkay={() => {
					ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStarted_PopAdd' : 'V1_1_DocsOnboard_PopAdd');
					setShowaModal(false);
				}}
				onOkayButton="Add Now"
				onCancel={() => {
					ClevertapReact.event(cleverTap ? 'V1_1_Docs_GetStarted_PopSkip' : 'V1_1_DocsOnboard_PopSkip');
					dispatch(setupAction.creators.setHrSetupStatus({ documentCompleted: true }));
					history.push('/hrdocuments');
				}}
				isOpen={showModal}
				heart={true}
				imageSrc={focus_state_icons.warningNew}
			/>
		</>
	);
};

export default DocumentExpiry;
