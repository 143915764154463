import EdenRedHeader from '../Component/EdenRedHeader/EdenRedHeader';
import HrFeatureSummary from './HrFeatureSummary';
import React, { useEffect } from 'react';
import { default as additionAction } from '@pages/NewHrModuleSetup/addition/action';
import { default as allowanceAction } from '@pages/NewHrModuleSetup/allowance/action';
import { default as deductionAction } from '@pages/NewHrModuleSetup/deduction/action';
import { default as departmentAction } from '@pages/NewHrModuleSetup/Leaves/Departments/action';
import announcementAction from '@pages/NewHrModuleSetup/Announcements/action';
import action from './action';
import { useDispatch } from 'react-redux';
import './hr-feature-access.scss';

const HrFeatureAccess = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(allowanceAction.creators.getAllowance());
		dispatch(additionAction.creators.getAddition());
		dispatch(deductionAction.creators.getDeduction());
		dispatch(announcementAction.creators.getAnnouncement());
		dispatch(departmentAction.creators.getDepartment());
		dispatch(action.creators.getCorporateSettings());
	}, []);
	return (
		<div className="hr-feature-access-container">
			<EdenRedHeader />
			<div className="contents">
				<HrFeatureSummary />
			</div>
		</div>
	);
};

export default HrFeatureAccess;
