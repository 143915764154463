import React, { useMemo } from 'react';
import { Row, Col, Divider } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ContentLoader from '@sharedComponent/content-loader';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES, INVITE_TEAMS_CONSTANTS } from '@constants/app-constants';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import Chips from '@pages/DesignSystem/Chips';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';

const leaves = [
	{
		name: 'Manage Leaves',
		description: 'User can approve and view leaves for their department. Assign in leave approval workflow page.',
	},
	{
		name: 'Approval Workflow',
		description: 'User can approve and view leaves for their department. Assign in leave approval workflow page.',
	},
	{
		name: 'Manage Documents',
		description: 'User can add document details and receive notifications about the expiry dates.',
	},
	{
		name: 'Manage Announcements',
		description: 'user can send a notification announcement to employees.',
	},
];
const UserRolePresentational = ({
	showModal,
	closeModal,
	visible,
	roles,
	compareRoles,
	getDeleterole,
	changeClaims,
	loading,
	passedClaims,
	deleteLoading,
	username,
	allClaims,
	isClaimsLoaded,
	save_button,
	closeLastEmployee,
	isLastEmployee,
	issetup,
	handleMouseHover,
	onHover,
	handleSelectAll,
	isOnlyEmployeeExists,
	userClaims,
}) => {
	const isDisabled = useMemo(() => {
		return JSON.stringify(roles) === JSON.stringify(compareRoles);
	}, [roles, compareRoles]);
	const selectedRoles = userClaims?.hrClaims?.length > 0 || userClaims?.payrollClaims?.length > 0 || userClaims?.teamClaims?.length > 0;
	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const Permission = ({ role, changeClaims }) => {
		return (
			<Row className="content_list">
				<div>
					<Checkbox
						className="new-design-roles"
						tabIndex={3}
						borderColor="#00344E"
						borderRadius={4}
						borderWidth={1}
						labelPadding="0px"
						size={16}
						checked={role?.isSelected}
						onChange={(e) => changeClaims(e, role?.value)}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }} onClick={() => changeClaims(!role?.isSelected, role?.value)}>
					<Col className="value">{role?.title}</Col>
					<Col className="value1">{role?.description}</Col>
				</div>
			</Row>
		);
	};
	const Leaves = ({ leave, changeClaims }) => {
		return (
			<Row className="content_list1">
				<div>
					<Checkbox
						className="new-design-roles"
						tabIndex={3}
						borderColor="#00344E"
						borderRadius={4}
						borderWidth={1}
						labelPadding="0px"
						size={16}
						checked={leave?.isSelected}
						onChange={(e) => changeClaims(e, leave?.value)}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }} onClick={() => changeClaims(!leave?.isSelected, leave?.value)}>
					<Col className="value">{leave?.title}</Col>
					<Col className="value1">{leave?.description}</Col>
				</div>
			</Row>
		);
	};
	const value = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	return (
		<div className="assign-permission-container">
			<EdenredHeader cancel />
			<Row className="mainContainer">
				{isClaimsLoaded ? (
					<div className="loader-role">
						<ContentLoader />
					</div>
				) : (
					<div>
						<Col xl={24} md={24} className="header_content">
							<ETypo bold h2>
								Assign Permissions To {username}
							</ETypo>
							<div className="new_desgin_edit_roles_button">
								<EdenredButtons
									className="new_desgin_cancel"
									btnType="secondary"
									leftIcon={<ImageComponent src={focus_state_icons.InviteCancelButton} />}
									onClick={() => save_button(INVITE_TEAMS_CONSTANTS.CANCEL_BUTTON)}>
									{INVITE_TEAMS_CONSTANTS.CANCEL_BUTTON}
								</EdenredButtons>
								{!isOnlyEmployeeExists && selectedRoles && (
									<EdenredButtons
										className="new_desgin_remove"
										loading={deleteLoading}
										btnType="secondary"
										leftIcon={<ImageComponent src={focus_state_icons.NewDelete} />}
										onClick={showModal}>
										{INVITE_TEAMS_CONSTANTS.REMOVE_ADMIN}
									</EdenredButtons>
								)}
								<EdenredButtons
									loading={loading}
									className="new_desgin_save"
									btnType="primary"
									disabled={isDisabled}
									rightIcon={<ImageComponent src={focus_state_icons.NewTick} />}
									onClick={() => save_button(INVITE_TEAMS_CONSTANTS.SAVE_CHANGE)}>
									{issetup ? INVITE_TEAMS_CONSTANTS.SUBMIT : INVITE_TEAMS_CONSTANTS.SAVE_CHANGE}
								</EdenredButtons>
							</div>
						</Col>
						<Col xl={24} md={24} className="userbackground">
							<Col xl={24} md={24} className="userbackground1">
								<Col
									xl={24}
									md={24}
									className="user"
									onMouseEnter={() => handleMouseHover(true, 'onTeamsHover')}
									onMouseLeave={() => handleMouseHover(false, 'onTeamsHover')}>
									<Chips classname="new_desgin_chip" color="#CCECE6">
										Roles & Permissions
									</Chips>
									{onHover.onTeamsHover && (
										<p className="hover_select_all" onClick={() => handleSelectAll(allClaims?.teamClaims, 'teamClaims')}>
											Select All
										</p>
									)}
								</Col>
								<Col xl={24} md={24} xs={24} sm={24} className="toggle_para">
									{allClaims?.teamClaims?.map((role) => (
										<Col xl={24} md={24} xs={24} sm={24} key={leaves.index} className={`toggle_content`}>
											<Leaves {...{ leave: role, changeClaims }} />
											<Col xl={24} md={24} lg={24} xs={24} sm={24} className="align_horizontal">
												<Divider style={{ margin: '0px 0px' }} />
											</Col>
										</Col>
									))}
								</Col>
							</Col>
							<Col
								xl={24}
								md={24}
								className="user"
								onMouseEnter={() => handleMouseHover(true, 'onPayrollHover')}
								onMouseLeave={() => handleMouseHover(false, 'onPayrollHover')}>
								<Chips classname="new_desgin_chip" color="#FEF5E8">
									Payroll
								</Chips>
								{onHover.onPayrollHover && (
									<p className="hover_select_all" onClick={() => handleSelectAll(allClaims?.payrollClaims, 'payrollClaims')}>
										Select All
									</p>
								)}
							</Col>

							<Col xl={24} md={24} xs={24} sm={24} className="toggle_para1">
								{allClaims?.payrollClaims?.map((role, index) => (
									<Col xl={24} md={24} xs={24} sm={24} key={role?.index} className={`toggle_content`}>
										<Permission {...{ passedClaims, changeClaims, role }} />
										{allClaims?.payrollClaims?.length === index + 1 && !isSubcribed ? null : (
											<Col xl={24} md={24} lg={24} xs={24} sm={24} className="align_horizontal">
												<Divider style={{ margin: '0px 0px' }} />
											</Col>
										)}
									</Col>
								))}
							</Col>
							{allClaims?.hrClaims?.length > 0 &&
								(isSubcribed ? (
									<Col
										disable={!value}
										xl={24}
										md={24}
										className="userbackground1"
										style={!value ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
										<Col
											xl={24}
											md={24}
											className="user"
											onMouseEnter={() => handleMouseHover(true, 'onHrHover')}
											onMouseLeave={() => handleMouseHover(false, 'onHrHover')}>
											<Chips classname="new_desgin_chip" color="#E2D2FE">
												HR
											</Chips>
											{onHover.onHrHover && (
												<p className="hover_select_all" onClick={() => handleSelectAll(allClaims?.hrClaims, 'hrClaims')}>
													Select All
												</p>
											)}
										</Col>
										<Col xl={24} md={24} xs={24} sm={24} className="toggle_para1">
											{isClaimsLoaded && (
												<div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
													<ContentLoader />
												</div>
											)}
											{allClaims?.hrClaims.map((leave, index) => (
												<Col xl={24} md={24} xs={24} sm={24} key={leaves.index} className={`toggle_content`}>
													<Leaves {...{ changeClaims, leave }} />
													{leaves?.length === index + 1 ? null : (
														<Col xl={24} md={24} lg={24} xs={24} sm={24} className="align_horizontal">
															<Divider style={{ margin: '0px 0px' }} />
														</Col>
													)}
												</Col>
											))}
										</Col>
									</Col>
								) : (
									''
								))}
						</Col>
					</div>
				)}
				<Edenredmodal
					{...{
						className: 'new_design_delete',
						topImage: <ImageComponent src={focus_state_icons.NewDelete} />,
						isOpen: visible,
						onOkayButton: 'Remove Role',
						onCancelButton: 'Cancel',
						onOkay: getDeleterole,
						onCancel: closeModal,
						loading: loading,
						title: 'Do you want to remove Roles & Permissions to this employee?',
						desc: 'You can still add them in the future again.',
					}}
				/>
				<Edenredmodal
					{...{
						className: 'new_design_last',
						topImage: <ImageComponent src={focus_state_icons.NewLastRole} />,
						isOpen: isLastEmployee,
						close: closeLastEmployee,
						title: 'You Cannot Remove “Manage Roles & Permissions”',
						desc: (
							<div>
								<p style={{ margin: 0 }}>At least one of your admins need to have access to Manage Roles & Permissions.</p>
								<p style={{ margin: 0 }}>
									{`Please add first this permission to another admin to be able to remove it from ${username}.`}
								</p>
							</div>
						),
					}}
				/>
			</Row>
		</div>
	);
};
export default UserRolePresentational;
