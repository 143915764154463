const GET_DEDUCTION = 'GET_DEDUCTION';
const GET_DEDUCTION_DATA = 'GET_DEDUCTION_DATA';
const POST_DEDUCTION = 'POST_DEDUCTION';
const PUT_DEDUCTION_LOADING = 'PUT_DEDUCTION_LOADING';
const PUT_DEDUCTION_ERROR = 'PUT_DEDUCTION_ERROR';

const getDeduction = () => ({
	type: GET_DEDUCTION,
});

const getDeductionData = (response) => ({
	type: GET_DEDUCTION_DATA,
	response,
});
const postDeduction = (data, userName) => ({
	type: POST_DEDUCTION,
	payload: data,
	userName: userName,
});

const putDeductionLoading = (loadingStatus) => ({
	type: PUT_DEDUCTION_LOADING,
	payload: loadingStatus,
});
const putDeductionError = (error) => ({
	type: PUT_DEDUCTION_ERROR,
	payload: error,
});

export default {
	types: {
		GET_DEDUCTION: GET_DEDUCTION,
		GET_DEDUCTION_DATA: GET_DEDUCTION_DATA,
		PUT_DEDUCTION_LOADING: PUT_DEDUCTION_LOADING,
		POST_DEDUCTION: POST_DEDUCTION,
		PUT_DEDUCTION_ERROR: PUT_DEDUCTION_ERROR,
	},
	creators: {
		getDeduction,
		getDeductionData,
		postDeduction,
		putDeductionLoading,
		putDeductionError,
	},
};
