import React, { useState } from 'react';
import { Col } from 'antd';
import { icons } from '@assets/icons';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import PayrollService from 'src/services/payroll-service';

const HelpHeader = () => {
	const [loading, setLoading] = useState(false);
	const download = () => {
		setLoading(true);
		const _payrollService = new PayrollService();
		_payrollService
			.usefullfileDownload('TradeLicense', 'GET', 'blob')
			.then(({ data }) => {
				const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: data.type }));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', 'edenred_trade_license.pdf');
				document.body.appendChild(link);
				link.click();
				link.remove();
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	return (
		<Col className="edenred_about_card">
			<div className="address">
				<ImageComponent className="" src={icons.focus_state.locationBlue} />
				<div>
					<p className="edenred">Edenred UAE</p>
					<p className="single-business">Single Business Tower, Office 4301, Business Bay, P.O.Box: 34920, Dubai, UAE</p>
				</div>
			</div>
			<div className="download_buttons">
				<EdenredButtons btnType="primary" className="download" loading={loading} onClick={download}>
					Download Our Trade License
				</EdenredButtons>
				<EdenredButtons btnType="secondary">
					<a href="/edenred-privacy-policy.pdf" download>
						<ETypo b1>Privacy Policy</ETypo>
					</a>
				</EdenredButtons>
			</div>
		</Col>
	);
};

export default HelpHeader;
