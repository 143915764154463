import { createSelector } from 'reselect';

import { initState } from './per-employee-reducer';

const salaryPerEmployee = (state) => state.salaryPerEmployee || initState;
const isPerEmployeeLoading = createSelector(salaryPerEmployee, (cnter) => cnter.isPerEmployeeLoading);
const perEmployeeDetails = createSelector(salaryPerEmployee, (cnter) => cnter.perEmployeeDetails);
const perEmployeeError = createSelector(salaryPerEmployee, (cnter) => cnter.perEmployeeError);

export default {
	salaryPerEmployee,
	isPerEmployeeLoading,
	perEmployeeDetails,
	perEmployeeError,
};
