import React, { useMemo } from 'react';
import { Col } from 'antd';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import '../../payroll-plus/component/payroll-select-dropdown.scss';

export const ApprovalDropdown = ({ defaultValue, approver, userData, deparment, handleApprovalDropDown }) => {
	let newUser = [];
	const userarray = userData?.map((user) => {
		if (user?.user !== null) {
			newUser.push(user?.user?.userName);
		}
	});
	const options = useMemo(() => {
		newUser.unshift('None');
		return newUser?.map((data) => {
			return {
				approverName: data,
			};
		});
	}, [deparment, newUser]);
	const isValueSelected = deparment.users.map((approver, i) => {
		if (approver?.approverName === '' || approver?.approverName === undefined || approver?.approverName === 'None') {
			return true;
		} else {
			return false;
		}
	});
	let nApproverName = [];
	return (
		<Col className="approver-dropdown-select">
			{approver?.map(({ orderId }, index) => {
				nApproverName.push(deparment?.users?.[index - 1]?.approverName);
				return (
					<MultiSelect
						{...{
							placeholder: 'Select Employee',
							onChange: (e) => handleApprovalDropDown(e, deparment?.departmentId, orderId, deparment),
							searchVisibility: true,
							value: deparment?.users?.[index]?.approverName || null,
							disabled: index > 0 ? isValueSelected[index - 1] : null,
							dropDownValue: 'approverName',
							Option: index === 0 ? options?.slice(1) : options.filter((data) => !nApproverName?.includes(data?.approverName)),
							showTooltip: true,
							toolTipText: 'Select previous approver to unlock this approver',
							showIcon: false,
						}}
					/>
				);
			})}
		</Col>
	);
};
