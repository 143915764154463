import React from 'react';
import { withReducer } from '@store/reducerLoader';
import RunPayrollNewFunctional from './components/run-payroll-new-functional';
import reducer from './reducer';
import sagas from './sagas';

const RunPayrollNew = () => {
	return <RunPayrollNewFunctional />;
};
const ReducedScreen = withReducer('filterDropdownValues', reducer, sagas)(RunPayrollNew);
export default ReducedScreen;
