import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { Col } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { focus_state_icons } from 'src/assets/icons/focus_state';

const EmployeeProfileHeader = ({
	history,
	nPayrollClaims,
	isShowLoader,
	handleProfileSave,
	buttonDisable,
	profileSaveButtonEnable,
	employeeDetailState,
	isEmailValided,
	isLeaveEdited,
	employeeDetails,
	openDeleteModal,
	handleSaveProfileButton,
	navigateTabCheck,
	handleDisableProfileButton,
}) => {
	const individualData = useSelector((state) => state?.individualData);

	return (
		<Col className="employee_profile_fixed_header">
			<ETypo bold h2>
				{history?.location?.state?.mainProfile ? 'Home' : 'Employee Details'}
			</ETypo>
			<div className="employee_profile_header_button">
				{history?.location?.state?.mainProfile ? (
					nPayrollClaims?.length > 0 && (
						<EdenredButtons
							loading={isShowLoader}
							onClick={handleProfileSave}
							disabled={(buttonDisable || profileSaveButtonEnable || !employeeDetailState?.dob || !isEmailValided) && isLeaveEdited}>
							Save Changes
						</EdenredButtons>
					)
				) : (
					<div className="Profile_save_delete_Button">
						{employeeDetails?.salaryChannel === 'CREATINGACCOUNTNO' ? null : (
							<EdenredButtons
								btnType="secondary"
								onClick={openDeleteModal}
								leftIcon={<ImageComponent src={focus_state_icons?.NewDelete} />}
								disabled={employeeDetails?.isDeleted === true ? true : false}>
								Delete Employee
							</EdenredButtons>
						)}
						<EdenredButtons
							onClick={() => handleSaveProfileButton(navigateTabCheck?.selectedTab)}
							disabled={handleDisableProfileButton(navigateTabCheck?.selectedTab)}
							btnType="primary"
							loading={individualData && individualData?.uploadDataLoading}>
							Save Changes
						</EdenredButtons>
					</div>
				)}
			</div>
		</Col>
	);
};

export default EmployeeProfileHeader;
