import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '@pages/auth/login/login-page';
import Actions from '@sharedComponent/app-action-reducer/action';
import MainLayout from '@pages/main-layout/main-layout-page';
// import { asyncLocalStorage } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';
import { asyncLocalStorage } from '@helpers';
import AppLoader from '@sharedComponent/app-loader';

function HomePage(props) {
	const {
		location: { state },
	} = useHistory();
	const { user, currentUserDetailsOIDC } = props;

	const setReduxValue = (user) => {
		asyncLocalStorage
			.setItem(APP_VARIABLES.USER_DETAILS, JSON.stringify(user))
			.then(function () {
				return asyncLocalStorage.getItem(APP_VARIABLES.USER_DETAILS);
			})
			.then(function (details) {
				props.setUserDetails({ user: JSON.parse(details), checkedForUser: true });
			});
	};

	const checkUser = async () => {
		const userDetailsFromStorage = await asyncLocalStorage.getItem(APP_VARIABLES.USER_DETAILS);
		const formattedUserDetailsFromStorage = JSON.parse(userDetailsFromStorage);
		if (state?.user) {
			setReduxValue(state.user);
		} else {
			if (formattedUserDetailsFromStorage) {
				setReduxValue(formattedUserDetailsFromStorage);
			} else if (user) {
				setReduxValue(user);
			} else {
				await props.setUserDetails({
					user: null,
					checkedForUser: true,
				});
				// await userManager.signinRedirect();
			}
		}
	};

	useEffect(() => {
		checkUser();
	}, []);

	if (!currentUserDetailsOIDC.checkedForUser) {
		return <AppLoader />;
	}

	return currentUserDetailsOIDC.user ? <MainLayout hasUser /> : <Login />;
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		currentUserDetailsOIDC: state.sharedStates.currentUserDetailsOIDC,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUserDetails: (details) => dispatch(Actions.creators.setUserDetails(details)),
		dispatch,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
