import React from 'react';
import { useHistory } from 'react-router-dom';
import ApprovedPage from '@pages/payroll/EmployeesExpirymodule/component/ApprovedPage';
import './setupUploadEmployee.scss';
import SetupHeader from '@pages/setup-Screen/components/setup-header';

function SetupUploadEmployeeList() {
	const history = useHistory();
	const employeesList = history.location.state.data;
	return (
		<>
			<SetupHeader />
			<div className="employeeListTable">
				<ApprovedPage {...{ isSetup: true, employeesList }} />
			</div>
		</>
	);
}

export default SetupUploadEmployeeList;
