import { APP_VARIABLES } from '@constants/app-constants';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import Input from '@sharedComponent/text-field';
import EdenredTypo from '@sharedComponent/typography';
import { Row, Col, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

const AddEstablishmentIdToEmployee = () => {
	const { state } = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		const { wpsEstablishmentId } = values;
		history.push({ pathname: '/employees/add/individual/employee/labourcardnumber', state: { ...state, wpsEstablishmentId } });
	};
	return (
		<>
			<EdenredHeader />
			<Row className="add_establishment_id_to_employee_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_establishment_id_to_employee_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName : ''}’s WPS Establishment ID?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_establishment_id_to_employee_description">
					<EdenredTypo>
						In case you have recently obtained a new trade license, please add the WPS Establishment ID for this trade license in{` `}
						<Link
							to={{ pathname: '/company/information' }}
							target="_blank"
							onClick={() =>
								localStorage.setItem(
									APP_VARIABLES.REDIRECT_TO,
									JSON.stringify({
										url: '/company/information',
									})
								)
							}>
							Manage Company Information
						</Link>
					</EdenredTypo>
				</Col>
				<Col xl={10} lg={20} md={24} sm={24} xs={24} className="add_establishment_id_to_employee_form_section">
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="wpsEstablishmentId"
							rules={[
								{ required: true, message: 'Please provide the Establishment ID to the employee' },
								{
									max: 13,
									message: 'Establishment ID - Max 13 characters only allowed',
								},
							]}>
							<Input placeholder="Establishment ID" labels="Establishment ID" labelClassName="inputlabelrequired" />
						</Form.Item>
						<Col xl={18} lg={18} md={24} sm={24} xs={24}>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default AddEstablishmentIdToEmployee;
