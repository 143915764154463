import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import Input from '@sharedComponent/text-field';
import EdenredTypo from '@sharedComponent/typography';
import { images } from 'src/assets/img';
import { icons } from 'src/assets/icons';

const AddEmiratesIdNumber = () => {
	const [show, setShow] = useState({
		button: true,
		card: false,
	});
	const { state } = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		const { emiratesIdNumber } = values;
		history.push({ pathname: '/employees/add/individual/employee/passportnumber', state: { ...state, emiratesIdNumber } });
	};

	return (
		<>
			<EdenredHeader />
			<Row className="add_emirates_id_number_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_emirates_id_number_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName + '’s' : ''} Emirates ID Number?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_emirates_id_number_description">
					<EdenredTypo>Found 15-digit number on the front side of the employee’s Emirated ID</EdenredTypo>
				</Col>
				<Row className="add_emirates_id_number_form_image_row">
					<Col xl={11} lg={12} md={18} sm={24} xs={24} className="add_emirates_id_number_form_section">
						<Form form={form} onFinish={onFinish}>
							<Form.Item
								name="emiratesIdNumber"
								rules={[
									{ required: true, message: 'Please provide the Emirates ID of the employee' },
									{
										max: 15,
										min: 15,
										message: 'The emirates Id Number should be 15 Characters',
									},
								]}>
								<Input placeholder="15-Digit Number" labels="Emirates ID" labelClassName="inputlabelrequired" />
							</Form.Item>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Form>
					</Col>
					<Col xl={13} lg={12} md={22} sm={24} xs={24} className="add_emirates_id_card_image">
						<ImageComponent src={images.EmiratesIDCard} />
					</Col>
				</Row>
				{show.button && (
					<Col xl={12} lg={12} md={18} sm={24} xs={24} className="add_emirates_id_number_link_button">
						<EdenredButton type="button" onClick={() => setShow({ button: false, card: true })}>
							{state && state.firstName ? state.firstName : ''} Has Not Received The Emirates ID Yet
						</EdenredButton>
					</Col>
				)}
				{show.card && (
					<Col xl={24} lg={16} md={16} sm={24} xs={24} className="add_emirates_id_number_link_button">
						<Row className="add_temporary_emirates_id_number_card">
							<Col xl={1} lg={1} md={1} sm={24} xs={24} className="add_temporary_emirates_id_number_tip_icon">
								<ImageComponent src={icons.focus_state.Tip} />
							</Col>
							<Col xl={23} lg={20} md={20} sm={24} xs={24} className="add_temporary_emirates_id_number_hint">
								<EdenredTypo>
									Please enter the number 784111111111111 for now. You need to add{' '}
									{state && state.firstName ? state.firstName + '’s' : ''} Emirates ID details as soon as he receives them.
								</EdenredTypo>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
		</>
	);
};

export default AddEmiratesIdNumber;
