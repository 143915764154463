import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery } from 'redux-saga/effects';

import Actions from '../action';
import Axios from 'axios';

export function* payslipDataService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/PortalPayslip/${corporateId}/${action?.employeeID}/list?pageNumber=${action?.payslipDocument?.pageNumber}&pageSize=${action?.payslipDocument?.pageSize}`;
		const res = yield call(Axios.get, url);
		yield put(Actions.creators.getPayslipDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getPayslipDataError(err));
	}
}
export function* payslipDownloadService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/PortalPayslip/${corporateId}/${action?.employeeID}/${action?.salMonth}/${action?.salYear}/download`;
		const res = yield call(Axios.get, url, {});
		yield put(Actions.creators.getPayslipDownloadSuccess(res.data));
		// const downloadUrl = window.URL.createObjectURL(res.data);
		const link = document.createElement('a');
		link.href = res.data;
		link.target = 'blank';
		// link.setAttribute('download', 'Payslip.pdf'); //any other extension
		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
	} catch (err) {
		yield put(Actions.creators.getPayslipDownloadError(err));
	}
}

export default function* paySlipWatcher() {
	yield takeEvery(Actions.types.GET_PAYSLIP_DATA_START, payslipDataService);
	yield takeEvery(Actions.types.GET_PAYSLIP_DOWNLOAD_START, payslipDownloadService);
}
