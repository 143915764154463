import React from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import EdenRedCard from '@sharedComponent/card';
import DividerComponent from '@pages/DesignSystem/Divider';
import './cardItemLink.scss';

const CardItemLink = ({
	items = [
		{
			url: '',
		},
	],
}) => {
	return (
		<Col xl={24} md={24} lg={24} xs={24} sm={24} className="card_content_list">
			{items.map((item, index) => (
				<ItemLink key={index} {...{ item, index, items }} />
			))}
		</Col>
	);
};

const ItemLink = ({ items, item, index }) => {
	return (
		<Col xl={24} md={24} lg={24} xs={24} sm={24} className="UsefulFiles_Card">
			<Link to={item.url}>
				<Row>
					<Col xl={24} md={24} lg={24} xs={24} sm={24}>
						<div className="card-container">
							<EdenRedCard type="Useful_files" content={item} symbolicon="salary_reports" />
							{index < items?.length - 1 && <DividerComponent />}
						</div>
					</Col>
				</Row>
			</Link>
		</Col>
	);
};

export default CardItemLink;
