import React from 'react';
import EdenredTypo from '@sharedComponent/typography';
import SelectDropdown from '@pages/DesignSystem/ComboBox/SelectDropdown';
import { options } from '@pages/DesignSystem/Json/DesignSystem';
import '../SelectDropdown.scss';

const SelectDropdownWithSearch = () => {
	return (
		<div>
			<div style={{ width: '50%' }}>
				<EdenredTypo h2 bold style={{ fontSize: '18px' }}>
					Select Dropdown With Waterfall Search
				</EdenredTypo>
				<SelectDropdown
					className="select-dropdown-without-placeholder"
					onChange={(data) => console.log('datass', data)}
					Option={options}
					placeholder="Normal Select"
				/>
			</div>
		</div>
	);
};

export default SelectDropdownWithSearch;
