import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../approval-action';
import Axios from 'axios';

export function* approvalWorkflowService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/LeaveApproval/${corporateId}/approvalworkflow`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getApprovalWorkflowSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getApprovalWorkflowFail(err));
	}
}
export function* approvalWorkflowUserService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Users/${corporateId}/all-user/hr-permission?pageNumber=1&pageSize=50`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getApprovalWorkflowUserSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getApprovalWorkflowUSerFail(err));
	}
}
export function* approvalWorkflowSubmitService(payload) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const request = payload?.payload;
	try {
		const uri = `${DOMAIN_URL}/LeaveApproval/${corporateId}/createapprovalworkflow`;
		const res = yield call(Axios.post, uri, request);
		yield put(Actions.creators.getApprovalWorkflowSubmitSuccess(res.data));
		yield put(Actions.creators.handleSavePopUp(false));
		yield put(Actions.creators.savedSucceessfulPopUp(true));
	} catch (err) {
		yield put(Actions.creators.getApprovalWorkflowSubmitFail(err));
		yield put(Actions.creators.handleSavePopUp(false));
	}
}
export default function* approvalWorkflowWatcher() {
	yield takeEvery(Actions.types.GET_APPROVALWORKFLOW_START, approvalWorkflowService);
	yield takeEvery(Actions.types.GET_APPROVALWORKFLOW_USER_START, approvalWorkflowUserService);
	yield takeEvery(Actions.types.GET_APPROVALWORKFLOW_SUBMIT_START, approvalWorkflowSubmitService);
}
