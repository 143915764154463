import React from 'react';
import { Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import './image-text.scss';

const Imagetext = ({ icons }) => {
	return (
		<div>
			<Row style={{ justifyContent: 'center' }}>
				<Col xl={18} lg={20} md={20} sm={22} xs={22}>
					<p className="top-heading-text">Experience better Payroll and HR</p>
				</Col>
			</Row>
			<Row style={{ justifyContent: 'center' }}>
				<Col xl={20} lg={24} md={24} sm={24} xs={24}>
					<Row className="">
						{icons?.map((data) => (
							<Col xl={8} lg={8} md={8} sm={12} xs={12} className="image-alignment">
								<ImageComponent src={data.image} />
								<h6 className="image-text">{data?.heading}</h6>
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default Imagetext;
