import { createSelector } from 'reselect';

import { initState } from './reducer';

const uploadReducer = (state) => state.uploadReducer || initState;
const uploadLoad = createSelector(uploadReducer, (cnter) => cnter.uploadLoading);
const documentUpload = createSelector(uploadReducer, (cnter) => cnter.documentUpload);
const uploadError = createSelector(uploadReducer, (cnter) => cnter.uploadError);
const submitLoading = createSelector(uploadReducer, (cnter) => cnter.submitLoading);
const submitUpload = createSelector(uploadReducer, (cnter) => cnter.submitUpload);
const submitError = createSelector(uploadReducer, (cnter) => cnter.submitError);
const templateLoading = createSelector(uploadReducer, (cnter) => cnter.templateLoading);
const templateDowload = createSelector(uploadReducer, (cnter) => cnter.templateDowload);
const templateError = createSelector(uploadReducer, (cnter) => cnter.templateError);
const filterLoading = createSelector(uploadReducer, (cnter) => cnter.filterLoading);
const filterDetail = createSelector(uploadReducer, (cnter) => cnter.filterDetail);
const filterError = createSelector(uploadReducer, (cnter) => cnter.filterError);
const isDocUploaded = createSelector(uploadReducer, (cnter) => cnter.isDocUploaded);

export default {
    uploadReducer,
    uploadLoad,
    documentUpload,
    uploadError,
    submitLoading,
    submitUpload,
    submitError,
    templateLoading,
    templateDowload,
    templateError,
    filterLoading,
    filterDetail,
    isDocUploaded,
    filterError

};
