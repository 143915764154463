import React from 'react';
import { Row, Col } from 'antd';
import DotLoader from 'react-spinners/DotLoader';

const AppLoader = ({ width = '10%', height = '10%' }) => {
	return (
		<Row justify="center" align="middle" style={{ height: '100vh', width: '100%' }}>
			<Col
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 2,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: 'white',
					width: '100%',
					height: '100%',
				}}>
				<DotLoader size={100} color={'#e91d24'} loading />
			</Col>
		</Row>
	);
};

export default AppLoader;
