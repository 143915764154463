import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

export const CustomAlertNotification = ({ when, onOK, onCancel, message, okText, cancelText, popupValue, selectedTab, description }) => {
	const history = useHistory();
	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');
	useEffect(() => {
		if (when) {
			history.block((prompt) => {
				if (prompt.pathname === '/payroll/run/channel') {
					setCurrentPath(prompt.pathname);
					setShowPrompt(false);
					return null;
				} else {
					setCurrentPath(prompt.pathname);
					setShowPrompt(true);
					return 'goo';
				}
			});
		} else {
			history.block(() => {});
		}

		return () => {
			history.block(() => {});
		};
	}, [history, when]);

	const handleOK = useCallback(async () => {
		if (onOK) {
			const canRoute = await Promise.resolve(onOK());
			if (canRoute) {
				history.block(() => {});
				history.push({ pathname: currentPath, state: { selectedTab, tableData: popupValue } });
			}
		}
	}, [currentPath, history, onOK]);

	const handleCancel = useCallback(async () => {
		setShowPrompt(false);
	}, [currentPath, history, onCancel]);

	return showPrompt ? (
		<Edenredmodal
			isOpen={showPrompt}
			onCancel={handleCancel}
			onOkay={handleOK}
			title={message}
			desc={description}
			className="request-leave-save-popup"
			onOkayButton={okText}
			onCancelButton={cancelText}
		/>
	) : null;
};
