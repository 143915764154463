import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons';
import { isFloat } from '@helpers';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';

const AmountBalanceCard = () => {
	const history = useHistory();
	const availableBalance = localStorage.getItem('amount') || '0';

	const changeNumbertoCurrency = (number = 0) => {
		const tempNumber = number;
		const amount = `AED ${tempNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${!isFloat(tempNumber) ? '.00' : ''}`;
		const splitAmount = amount.toString().split('.');
		const splitAmount2 = splitAmount[0]
			.toString()
			.split(/(\s+)/)
			.filter((e) => {
				return e.trim().length > 0;
			});
		if (splitAmount2?.[1]?.length > 5) {
			if (window.innerWidth < 1400) {
				let currency = (
					<>
						<EdenredTypo className="amount_interger_part">{splitAmount2?.[1]}</EdenredTypo>
						<EdenredTypo className="amount_decimal_part">.{splitAmount?.[1]}</EdenredTypo>
					</>
				);
				return currency;
			} else if (window.innerWidth < 1000) {
				let currency = (
					<>
						<EdenredTypo className="amount_interger_part">{splitAmount2?.[1]}</EdenredTypo>
					</>
				);
				return currency;
			} else {
				let currency = (
					<>
						<EdenredTypo className="amount_interger_part">{splitAmount2?.[1]}</EdenredTypo>
						<EdenredTypo className="amount_decimal_part">.{splitAmount?.[1]}</EdenredTypo>
						<EdenredTypo className="curency_name">{splitAmount2?.[0]}</EdenredTypo>
					</>
				);
				return currency;
			}
		} else {
			let currency = (
				<>
					<EdenredTypo className="amount_interger_part">{splitAmount2?.[1]}</EdenredTypo>
					<EdenredTypo className="amount_decimal_part">.{splitAmount?.[1]}</EdenredTypo>
					<EdenredTypo className="curency_name">{splitAmount2?.[0]}</EdenredTypo>
				</>
			);
			return currency;
		}
	};
	return (
		<Col xl={7} lg={24} md={24} sm={24} className="available_balance_card_column">
			<Card className="available_balance_card">
				<Col xl={24} lg={24} md={24} className="available_balance_details_row">
					<div className="available_balance_image">
						<ImageComponent src={icons.focus_state.MoneyNew} />
					</div>
					<Col xl={18} lg={18} md={18} className="available_balance_details">
						<EdenredTypo className="available_balance_title">Available Balance</EdenredTypo>
						<Row className="available_balance-details-row">
							<Col xl={24} sm={24} lg={24}>
								{changeNumbertoCurrency(Number(availableBalance))}
							</Col>
						</Row>
					</Col>
				</Col>

				<Col className="view_button_row">
					<Link
						to="#"
						onClick={() => {
							history.push({
								pathname: '/dashboard/transaction-history',
								state: {
									availableBalance: availableBalance,
								},
							});
						}}>
						<EdenredButton type="outline-g">View Transaction History</EdenredButton>
					</Link>
				</Col>
			</Card>
		</Col>
	);
};

export default AmountBalanceCard;
