import React, { Children } from 'react';
import { Modal } from 'antd';
import './landing-modal.scss';

const LandingModal = ({ visible = false, className = '', destroyOnClose = false, closable = true, children, handleClose }) => {
	return (
		<Modal
			visible={visible}
			destroyOnClose={destroyOnClose}
			className={`for-success-modal ${className}`}
			centered
			footer={false}
			closable={closable}
			maskClosable={false}
			bodyStyle={{ height: '194px', padding: '0px' }}
			maskStyle={{ backgroundColor: 'white', opacity: '0.8' }}
			height="288.51"
			onOk={() => false}
			onCancel={handleClose}>
			{children}
		</Modal>
	);
};

export default LandingModal;
