import React from 'react';
import Feedbox from '../feedbox';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from 'src/assets/icons/not_focus';

const Feedboxtitledesc = () => {
	return (
		<div>
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Help Alert Box with Title And Description
				</ETypo>
			</div>
			<Feedbox {...{ type: 'Help', desc: 'Edenred', Icon: <ImageComponent src={not_focus_icons.Help_icn} /> }} />
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Success Alert Box With Title And Description
				</ETypo>
			</div>
			<Feedbox
				{...{
					type: 'Success',
					desc: 'Content is the information contained within communication media. This includes internet, cinema, television, radio, audio CDs, books, magazines, physical art, and live event content. It’s directed at an end-user or audience in the sectors of publishing, art, and communication',
					Icon: <ImageComponent src={not_focus_icons.success_icn} />,
				}}
			/>{' '}
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Warning Alert Box With Title And Description
				</ETypo>
			</div>
			<Feedbox {...{ type: 'Warning', desc: 'Edenred', Icon: <ImageComponent src={not_focus_icons.warning_icn} /> }} />
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Error Alert Box With Title And Description
				</ETypo>
			</div>
			<Feedbox {...{ type: 'Error', desc: 'Edenred', Icon: <ImageComponent src={not_focus_icons.Error_icn} /> }} />
		</div>
	);
};

export default Feedboxtitledesc;
