import React from 'react';
import { Col, Row } from 'antd';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredTypo from '@sharedComponent/typography';
import CompanyDetails from './company-details';
import CompanyDocumentCard from './company-document-card';

const CompanyInformationWebPresentational = (props) => {
	return (
		<Row className="company_information_container">
			{window.screen.width > 425 && (
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company_information_title">
					<ETypo h2>Company Information</ETypo>
				</Col>
			)}
			<CompanyDetails />
			<Row className="company_documents_details_row">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company_documents_title">
					<EdenredTypo>Upload Documents</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company_documentation_card_section">
					{props?.companyDocuments?.map((companyDocument, index) => (
						<CompanyDocumentCard content={companyDocument} key={index} />
					))}
				</Col>
			</Row>
			<div className="new_revamp">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_main_trade_license_section">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_main_trade_license_description">
						<EdenredTypo>To add a new Main Trade License, please send a copy of the following to support.ae@edenred.com:</EdenredTypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_main_trade_license_step_button_section">
						{props?.steps?.map((step, index) => (
							<Row key={index} className="add_new_main_trade_license_steps_container">
								<Col xl={1} lg={1} md={1} sm={2} xs={2} className="add_new_main_trade_license_step_icon">
									<EdenredTypo>{step?.step}</EdenredTypo>
								</Col>
								<Col xl={22} lg={22} md={22} sm={20} xs={20} className="add_new_main_trade_license_step_content">
									<EdenredTypo>{step?.description}</EdenredTypo>
								</Col>
							</Row>
						))}
					</Col>
				</Col>
			</div>
		</Row>
	);
};

export default CompanyInformationWebPresentational;
