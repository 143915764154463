import React, { useState } from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { not_focus_icons } from '@assets/icons/not_focus';
import ImageComponent from '@sharedComponent/image-component';
import { useDispatch } from 'react-redux';
import { employeeVerifyActions } from '../redux';

const DownloadSheet = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const getInvalidEmployeeList = async () => {
		setLoading(true);
		dispatch(employeeVerifyActions.creators.downloadInvalidEmployees());
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};

	return (
		<>
			<div className="download_sheet_text_content" data-testid="download-sheet-text-contents-test">
				<ETypo h4 medium data-testid="download-sheet-title-test">
					Download List of Employees with Incorrect Details
				</ETypo>
				<ETypo color="#484B52" b1 light data-testid="download-sheet-desc-test">
					You can download the list of employees going to be blocked and update them.
				</ETypo>
			</div>
			<div>
				<EdenredButtons
					loading={loading}
					onClick={getInvalidEmployeeList}
					type="outline-g"
					leftIcon={<ImageComponent className="leftIcon" src={not_focus_icons.documentDownload_v2} />}
					btnType="secondary">
					Download Employee List
				</EdenredButtons>
			</div>
		</>
	);
};

export default DownloadSheet;
