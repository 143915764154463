import React from 'react';
import { Row, Col } from 'antd';
import EdenRedCard from '@sharedComponent/card';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';
import { Link } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';

const content = [
	{
		image: icons.focus_state.Spreadsheet,
		header: 'Use a spreadsheet with salaries',
		link: '/upload/salary/spreadsheet_or_sif',
	},
	{
		image: icons.focus_state.Laptop,
		header: 'Add and edit salaries online',
		link: '/run-payroll',
	},
	{
		image: icons.focus_state.Statements,
		header: 'Upload a direct SIF file',
		link: '/upload/salary/spreadsheet_or_sif',
	},
];

const CheckRunPayroll = () => {
	return (
		<>
			<EdenredHeader />
			<Row className="check_runpayroll_container">
				<Col xs={24} sm={24} className="check_runpayroll_title">
					<EdenredTypo>How Do You Want To Run Payroll?</EdenredTypo>
				</Col>
				{content.map((content_list) => (
					<Col xs={24} sm={24} className="check_run_payroll">
						<Link to={content_list.link}>
							<EdenRedCard type="Check_Run_Payroll" content={content_list} />
						</Link>
					</Col>
				))}
				<Col xs={24} sm={24} className="check_timings_link">
					<Link to={{ pathname: '/payroll/timings' }}>Check Payroll Timings</Link>
				</Col>
			</Row>
		</>
	);
};
export default CheckRunPayroll;
