import React from 'react';
import { Card, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import ETypo from '@pages/DesignSystem/Typo';
import './setupNotCompleteCard.scss';

const SetupNotCompleteCard = () => {
	const history = useHistory();
	const setupCountLoading = useSelector((state) => state?.sharedStates?.setupCountloading) || false;
	const setupCount = useSelector((state) => state?.sharedStates?.setupCountDetail);
	const setup_card_detail = [
		{
			name: 'No Data',
			param: 'no_data',
			image: not_focus_icons.infoSvgNew,
			count: `${setupCount?.noData || 0}/${setupCount?.total || 4}`,
			id: 1,
		},
		{
			name: 'Missing Info',
			param: 'missing_data',
			image: focus_state_icons.setupWarningNew,
			count: `${setupCount?.missing || 0}/${setupCount?.total || 4}`,
			id: 2,
		},
		{
			name: 'Completed',
			param: 'completed_data',
			image: focus_state_icons.SetupCheckNew,
			count: ` ${setupCount?.completed || 0}/${setupCount?.total || 4}`,
			id: 3,
		},
	];
	return (
		<Card className="setup_dashboard_main_web">
			<Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: 'flex' }}>
				<Col xl={15} lg={17} md={17} sm={17} xs={17} className="setup_left_content">
					<div className="not_up_container">
						<ImageComponent src={focus_state_icons.notSetUp} />
					</div>
					<ETypo h3 bold color="#181919" style={{ marginTop: '8px' }}>
						Setup Not Completed
					</ETypo>
					<ETypo b1 color="#484B52" style={{ marginTop: '8px', textAlign: 'center' }}>
						Complete it to enjoy the new products.
					</ETypo>
					<div
						className="setup_button"
						onClick={() => {
							ClevertapReact.event('Setup_ continue_setup');
							history.push('/setup');
						}}>
						<div className="button">Continue Setup</div>
					</div>
				</Col>
				<Col xl={9} lg={7} md={7} sm={7} xs={7}>
					<div className="setup_card">
						{setup_card_detail?.map((data) => (
							<div key={data?.id} className={`card_details ${data?.param}`}>
								<ImageComponent src={data?.image} style={{ height: '24px', marginTop: '2px' }} />
								{setupCountLoading ? (
									<ClipLoader size={22} color={'#484b52'} loading />
								) : (
									<span className="setup_count">{data?.count}</span>
								)}
								<span className="setup_name">{data?.name}</span>
							</div>
						))}
					</div>
				</Col>
			</Col>
		</Card>
	);
};

export default SetupNotCompleteCard;
