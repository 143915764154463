import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import './index.scss';
import 'antd/dist/antd.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import myStore from './store/createStore';
import userManager from '@config/user-manager';
import Root from './store/redux-oidc/root';
import setupAxiosInterceptors from './globals/interceptors';
const store = myStore();
setupAxiosInterceptors(store);

//test
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			{/* <OidcProvider store={store} userManager={userManager}> */}
			<BrowserRouter>
				{/* <Root> */}
				<App />
				{/* </Root> */}
			</BrowserRouter>
			{/* </OidcProvider> */}
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
