const GET_DEPARTMENT_DATA_START = 'GET_DEPARTMENT_DATA_START';
const GET_DEPARTMENT_DATA_SUCCESS = 'GET_DEPARTMENT_DATA_SUCCESS';
const GET_DEPARTMENT_DATA_FAIL = 'GET_DEPARTMENT_DATA_FAIL';
const GET_CONTRACT_DATA_START = 'GET_CONTRACT_DATA_START';
const GET_CONTRACT_DATA_SUCCESS = 'GET_CONTRACT_DATA_SUCCESS';
const GET_CONTRACT_DATA_FAIL = 'GET_CONTRACT_DATA_FAIL';
const SAVE_CONTRACT_DETAIL_START = 'SAVE_CONTRACT_DETAIL_START';
const SAVE_CONTRACT_DETAIL_SUCCESS = 'SAVE_CONTRACT_DETAIL_SUCCESS';
const SAVE_CONTRACT_DETAIL_FAIL = 'SAVE_CONTRACT_DETAIL_SUCCESS';
const EMP_CONTRACT_DETAIL_SAVED = 'EMP_CONTRACT_DETAIL_SAVED';

const getDepartmentDataStart = () => ({
	type: GET_DEPARTMENT_DATA_START,
});
const getDepartmentDataSuccess = (res) => ({
	type: GET_DEPARTMENT_DATA_SUCCESS,
	res,
});
const getDepartmentDataFail = (err) => ({
	type: GET_DEPARTMENT_DATA_FAIL,
	err,
});
const getContractDataStart = () => ({
	type: GET_CONTRACT_DATA_START,
});
const getContractDataSuccess = (res) => ({
	type: GET_CONTRACT_DATA_SUCCESS,
	res,
});
const getContractDataFail = (err) => ({
	type: GET_CONTRACT_DATA_FAIL,
	err,
});
const saveContractDetailStart = (payload) => ({
	type: SAVE_CONTRACT_DETAIL_START,
	payload,
});
const saveContractDetailStartSuccess = (res) => ({
	type: SAVE_CONTRACT_DETAIL_SUCCESS,
	res,
});
const saveContractDetailStartFail = (err) => ({
	type: SAVE_CONTRACT_DETAIL_FAIL,
	err,
});
const isContractDetailSaved = (flag) => ({
	type: EMP_CONTRACT_DETAIL_SAVED,
	flag,
});

export default {
	types: {
		GET_DEPARTMENT_DATA_START,
		GET_DEPARTMENT_DATA_SUCCESS,
		GET_DEPARTMENT_DATA_FAIL,
		SAVE_CONTRACT_DETAIL_START,
		SAVE_CONTRACT_DETAIL_SUCCESS,
		SAVE_CONTRACT_DETAIL_FAIL,
		EMP_CONTRACT_DETAIL_SAVED,
		GET_CONTRACT_DATA_START,
		GET_CONTRACT_DATA_SUCCESS,
		GET_CONTRACT_DATA_FAIL,
	},
	creators: {
		getDepartmentDataStart,
		getDepartmentDataSuccess,
		getDepartmentDataFail,
		saveContractDetailStart,
		saveContractDetailStartSuccess,
		saveContractDetailStartFail,
		isContractDetailSaved,
		getContractDataStart,
		getContractDataSuccess,
		getContractDataFail,
	},
};
