import Actions from './history-action';
export const initState = {
	isDepartmentLoading: false,
	departmentDetails: [],
	departmentError: '',
};

const salaryHistory = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_HISTORY_DEPARTMENT_START: {
			return {
				...state,
				isDepartmentLoading: true,
			};
		}
		case Actions.types.GET_HISTORY_DEPARTMENT_SUCCESS: {
			return {
				...state,
				isDepartmentLoading: false,
				departmentDetails: action.response,
			};
		}
		case Actions.types.GET_HISTORY_DEPARTMENT_FAIL: {
			return {
				...state,
				isDepartmentLoading: false,
				departmentDetails: [],
				departmentError: action.error,
			};
		}
		default:
			return state;
	}
};
export default salaryHistory;
