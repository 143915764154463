import '../main-layout.scss';

import { Col, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import EdenredSearch from '@sharedComponent/search-input';
import EdenredTypo from '@sharedComponent/typography';
import FreshdeskWidgetHelper from '@sharedComponent/freshdesk/FreshdeskWidgetHelper';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';

const EdenredHeader = ({ close, search, cancel, profile, placeholder = 'search here', onInput }) => {
	let history = useHistory();
	const handleClose = () => {
		history.goBack();
	};

	return (
		<Row
			style={{ position: 'fixed', width: '100%', height: '65px', padding: '12px 12px 5px 12px', zIndex: '1', background: 'white' }}
			className="header_main_container">
			<Col xs={8} sm={8}>
				<Link to="/">
					<ImageComponent src={images.logo} />
				</Link>
			</Col>
			{close && (
				<Col xs={16} sm={16} className="close" onClick={handleClose}>
					<EdenredTypo>Close</EdenredTypo> <ImageComponent src={icons.not_focus.Close} style={{ width: '25px', height: '25px' }} />
				</Col>
			)}
			{cancel && (
				<Col xs={16} sm={16} className="close cancel" onClick={handleClose}>
					Cancel
				</Col>
			)}
			{search && (
				<Col xs={16} sm={16} className="close">
					<EdenredSearch style={{ height: '40px' }} placeholder={placeholder} onInput={onInput} />
				</Col>
			)}
			{profile && (
				<Col xs={16} sm={16} className="profile_icon">
					<Link to={profile.link}>
						<ImageComponent src={icons.focus_state.User} />
					</Link>
				</Col>
			)}
		</Row>
	);
};

export default EdenredHeader;
