import React from 'react';
import { Link } from 'react-router-dom';
import SetupHrDocumentPresentation from '../components/setup-hrdocument-presentational';
function setupHrDocumentFunctional() {
	const setUpUpload = [
		{
			description: '1',
			list: (
				<span className="spanAA">
					Download the documents spreadsheet
					<Link
						to="/hrdocuments/uploaddocument"
						// onClick={() => {
						// 	downloadTemplate();
						// 	ClevertapReact.event('Bulk_Download_Template');
						// }}
						className="forHereNew">
						Here
					</Link>
				</span>
			),
		},
		{
			description: '2',
			list: (
				<span className="spanAA">
					Fill in your employees' document details and upload the spreadsheet below.
					<Link
						to=""
						// onClick={() => {
						// 	history.goBack();
						// 	ClevertapReact.event('Bulk_Download_FilteredSpreadsheet');
						// }}
						className="forHereNew"></Link>
				</span>
			),
		},
	];
	return (
		<div>
			<SetupHrDocumentPresentation {...{ setUpUpload }} />
		</div>
	);
}

export default setupHrDocumentFunctional;
