import React from 'react';
import { useSelector } from 'react-redux';
import { withReducer } from 'src/store/reducerLoader';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import saga from './saga/index';
import reducer from './reducer';
import LeavesToApproveFunctional from './Component/leaves-to-approve-functional';
import './Component/leave-to-approve.scss';

const AuthorizedAccess = UnauthorizedAccess(LeavesToApproveFunctional);

function LeavesToApprove() {
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.roles?.[0]?.name) || '';
	let currentClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims) || [];
	return <AuthorizedAccess {...{ currentUser, currentClaims, UserClaims: 'Manage.Leaves' }} />;
}
const ReducedScreen = withReducer('LeavetoApproveReducer', reducer, saga)(LeavesToApprove);
export default ReducedScreen;
