import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import '../replacement-card.scss';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { icons } from 'src/assets/icons';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import EdenredModal from '@sharedComponent/modal';
import EmployeeService from 'src/services/employee-service';
import { useLocation } from 'react-router-dom';
import { APP_VARIABLES } from '@constants/app-constants';
import ClevertapReact from 'src/utils/clever-tap';
import Popup from 'src/utils/replacement-popup';

const ReplacementCard = ({ history, corporateIds }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [empId, setEmpId] = useState(null);
	const { state } = useLocation();
	const [cardloading, setcardloading] = useState(false);
	const [replacementCardMessage, setReplacementCardMessage] = useState(null);
	const [confirmationModelOpen, setConfirmationModelOpen] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [isAllowtoNavigate, setisAllowtoNavigate] = useState(false);

	let employeeApi = new EmployeeService();

	useEffect(() => {
		setReplacementCardMessage('The last replacement card for this employee was delivered in 20 May 2021');
		console.log({ state });
		setEmpId(history?.location?.state?.tableData?.employeeId);
	}, []);

	const showModal = () => {
		setModalOpen(true);
	};
	const afterOpenModal = () => {
		setModalOpen(false);
	};
	const afterConfirmationOpenModal = () => {
		setConfirmationModelOpen(false);
	};
	const [address, setAddress] = useState(null);
	const getAddress = async () => {
		let address = Object.assign(
			{},
			...(corporateIds || []).filter((corporate) => corporate.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
		);
		if (address) {
			const { corporateName, address2, address3, zipCode } = address;
			setAddress(`${address2}, ${address3}`);
		}
	};

	useEffect(() => {
		getAddress();
	}, []);

	const getDeleterole = async () => {
		try {
			setcardloading(true);
			let response = await employeeApi.replacementCard({}, empId);
			setcardloading(false);

			ClevertapReact.event('Request_ReplacementCard', {
				employee_id: state.tableData.employeeId,
				employee_name: state.tableData.employeeName,
			});

			if (response && response.status === 200) {
				console.log('response99', response);
				setModalOpen(false);
				let responseMessage = response.data;
				let splicedString = responseMessage.slice(0, 60);

				let sampleString = 'The last replacement card for this employee was delivered on';
				if (splicedString === sampleString) {
					// show popup with past message
					setReplacementCardMessage(responseMessage);
					setConfirmationModelOpen(true);
				} else {
					//show popup with
					if (response.data == '') {
						setisAllowtoNavigate(true);
					} else {
						setisAllowtoNavigate(false);
					}
					setpopupVisible(true);
					setPopupMessage({
						titleMessage: response.data ? response.data : 'Replacement card requested successfully',
						subMessage: null,
					});
				}

				// setModalOpen(false);
				// ClevertapReact.event('Request_ReplacementCard', {
				// 	employee_id: state.tableData.employeeId,
				// 	employee_name: state.tableData.employeeName,
				// });
				// history.push({ pathname: '/employees/nextEmployee_step', state: { ...state } });
			}
		} catch (err) {
			setisAllowtoNavigate(false);
			setcardloading(false);

			setModalOpen(false);
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: 'Something went wrong',
				subMessage: null,
			});
		}
	};

	const confirmationsRequest = async () => {
		setcardloading(true);
		try {
			let response = await employeeApi.replacementCard({ confirmation: 'yes' }, empId);
			setcardloading(false);
			//if responce has some message show popup
			if (response && response.status === 200) {
				// show normal popup
				if (response.data == '') {
					setisAllowtoNavigate(true);
				} else {
					setisAllowtoNavigate(false);
				}
				setConfirmationModelOpen(false);
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: response.data ? response.data : 'Replacement card requested successfully',
					subMessage: null,
				});
			}
		} catch (err) {
			console.log(err);
			setisAllowtoNavigate(false);
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: 'Something went wrong',
				subMessage: null,
			});
		}
	};

	const closePopup = () => {
		setpopupVisible(false);

		if (isAllowtoNavigate) {
			history.push({ pathname: '/employees/nextEmployee_step', state: { ...state } });
		}
	};

	// const confirmationsRequest = async () => {
	// 	setcardloading(true);
	// 	try {
	// 		let response = await employeeApi.replacementCard({ confirmation: 'yes' }, empId);
	// 		setcardloading(false);
	// 		if (response && response.status === 200) {
	// 			// show normal popup
	// 			setConfirmationModelOpen(false);
	// 			setpopupVisible(true);
	// 			setPopupMessage({
	// 				titleMessage: 'Replacement card requested successfully',
	// 				subMessage: null,
	// 			});
	// 		}
	// 	} catch (err) {
	// 		console.log(err);
	// 		setpopupVisible(true);
	// 		setPopupMessage({
	// 			titleMessage: 'Something went wrong',
	// 			subMessage: null,
	// 		});
	// 	}
	// };

	// const closePopup = () => {
	// 	setpopupVisible(false);
	// 	history.push({ pathname: '/employees/nextEmployee_step', state: { ...state } });
	// };

	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup} className="message-text-alignment"></Popup>
			<EdenredHeader />
			<Row className="replacement-card-container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Row className="replacement-card-header">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<EdenredTypo>Request Replacement Card</EdenredTypo>
						</Col>
					</Row>
					<Row className="replacement-card-subheader">
						<Col xl={24} md={24} lg={24} sm={24} xs={24}>
							<EdenredTypo>The replacement card will be delivered to your office address:</EdenredTypo>
						</Col>
					</Row>
					<Row className="replacement-card-location">
						<Col xl={1} md={3} lg={2} sm={3} xs={3}>
							<ImageComponent src={icons.focus_state.Location} />
						</Col>
						<Col xl={22} md={19} lg={22} sm={21} xs={21}>
							<EdenredTypo>{address ? address : ''}</EdenredTypo>
						</Col>
					</Row>
					{/* <Col xl={24} md={24} lg={24} sm={24} xs={24} className="replacement-order">
						<Row className="replacement-card-change">
							<Col xl={8} md={15} lg={12} sm={18} xs={18} className="replacement-card">
								<EdenredTypo>Card Charges</EdenredTypo>
							</Col>
							<Col xl={16} md={9} lg={12} sm={6} xs={6}>
								<EdenredTypo>AED 00</EdenredTypo>
							</Col>
							<Col xl={10} lg={15} md={19} sm={24} xs={24} className="replacement-horizontal"></Col>
						</Row>
						<Row className="replacement-card-change">
							<Col xl={8} md={15} lg={12} sm={18} xs={18} className="replacement-card">
								<EdenredTypo>Card Charges</EdenredTypo>
							</Col>
							<Col xl={16} md={9} lg={12} sm={6} xs={6}>
								<EdenredTypo>AED 00</EdenredTypo>
							</Col>
							<Col xl={10} lg={15} md={19} sm={24} xs={24} className="replacement-horizontal"></Col>
						</Row>
						<Row className="replacement-card-change">
							<Col xl={8} md={15} lg={12} sm={18} xs={18} className="replacement-card">
								<EdenredTypo>Card Charges</EdenredTypo>
							</Col>
							<Col xl={16} md={9} lg={12} sm={6} xs={6}>
								<EdenredTypo>AED 00</EdenredTypo>
							</Col>
						</Row>
					</Col> */}
					<Row className="replacement-excluding">
						{/* <Col xl={10} md={21} lg={16} sm={24} xs={24} className="excluding">
							<EdenredTypo>*excluding VAT</EdenredTypo>
						</Col> */}
						<Col xl={10} lg={13} md={20} sm={24} xs={24}>
							<EdenredButton onClick={showModal}>Request Replacement Card</EdenredButton>
						</Col>
					</Row>
				</Col>
				<EdenredModal
					isOpen={confirmationModelOpen}
					afterOpenModal={afterConfirmationOpenModal}
					getDeleterole={confirmationsRequest}
					go_Back="No"
					className="replacement-modal"
					user={`${replacementCardMessage},\n
					Do you want to request a replacement card for ${state?.tableData?.employeeName}?`}
					content_web=""
					loading={cardloading}
				/>
				<EdenredModal
					isOpen={modalOpen}
					afterOpenModal={afterOpenModal}
					getDeleterole={getDeleterole}
					go_Back="No"
					className="replacement-modal"
					user={`Do you want to request a replacement card for ${state?.tableData?.employeeName}?`}
					content_web=""
					loading={cardloading}
				/>
			</Row>
		</>
	);
};
// export default ReplacementCard;
const mapStateToProps = (state) => {
	return {
		corporateIds: SelectorsApp.corporateIds(state),
	};
};

export default connect(mapStateToProps, null)(ReplacementCard);
