import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './custom-tab.scss';
import BankTransfer from './bank-transfer/bank-transfer';
import CashDepositAtAtm from './cash-deposit-at-atm';
import CashDepositAtBranch from './cash-deposit-at-branch';
import ChequeDeposit from './cheque-deposit';
import { initFormState, invoiceTabKeys } from './invoice-detail-presentational';

const CustomTab = ({ tabArr, getPayload, onChangeInvoiceForm, chequeDepositPayload, resetAllInvoiceState, clearInvoiceFileState }) => {
	const [activeTab, setActiveTab] = useState(tabArr[0].key);
	const getActiveComponent = () => {
		let activeTabContent;
		switch (activeTab) {
			case invoiceTabKeys.BANK_TRANSFER:
				activeTabContent = (
					<BankTransfer {...{ getPayload, onChangeInvoiceForm, chequeDepositPayload, clearInvoiceFileState, resetAllInvoiceState }} />
				);
				break;
			case invoiceTabKeys.CASH_DEPOSITE_AT_ATM:
				activeTabContent = (
					<CashDepositAtAtm {...{ getPayload, onChangeInvoiceForm, chequeDepositPayload, clearInvoiceFileState, resetAllInvoiceState }} />
				);
				break;
			case invoiceTabKeys.CASH_DEPOSITE_AT_BRANCH:
				activeTabContent = (
					<CashDepositAtBranch
						{...{ getPayload, onChangeInvoiceForm, chequeDepositPayload, clearInvoiceFileState, resetAllInvoiceState }}
					/>
				);
				break;
			case invoiceTabKeys.CHEQUE_DEPOSIT:
				activeTabContent = (
					<ChequeDeposit {...{ getPayload, onChangeInvoiceForm, chequeDepositPayload, clearInvoiceFileState, resetAllInvoiceState }} />
				);
				break;
			default:
				break;
		}
		return activeTabContent;
	};

	return (
		<div className="ed-tab-container">
			<div className="ed-tab-header">
				{tabArr.map((tab) => {
					return (
						<div
							key={tab.key}
							onClick={() => {
								setActiveTab(tab.key);
								onChangeInvoiceForm({ ...initFormState, ReceiptType: tab.key });
							}}
							className={`ed-tab-items ed-tab-items-1 ${activeTab === tab.key ? 'ed-tab-items__active' : ''}`}>
							{tab.tabHeading}
						</div>
					);
				})}
			</div>
			<div className="ed-tab-content invoice-details">{getActiveComponent()}</div>
		</div>
	);
};

CustomTab.defaultProps = {
	tabArr: [
		{
			tabHeading: 'Tab1',
			key: 'tab1',
		},
		{
			tabHeading: 'Tab2',
			key: 'tab2',
		},
		{
			tabHeading: 'Tab3',
			key: 'tab3',
		},
		{
			tabHeading: 'Tab4',
			key: 'tab4',
		},
	],
};

export default CustomTab;
CustomTab.propTypes = {
	tabArr: PropTypes.array,
};
