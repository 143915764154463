import action from './action';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';
import { images } from '../../../assets/img/index';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import setupAction from '../../../components/shared/app-action-reducer/action';
import { announcementClaimCheck, documentClaimCheck, leaveClaimCheck, payrollClaimCheck } from '@constants/app-constants';
import { isArray } from 'lodash';
import ContentLoader from '@sharedComponent/content-loader';
import ClevertapReact from 'src/utils/clever-tap';
import { icons } from 'src/assets/icons';
import './HrFeatureSummary.scss';

const HrFeatureSummary = () => {
	const [id, setId] = useState(1);
	const [modalOpen, setModalOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const isDeductionCompleted = useSelector((state) => state?.sharedStates?.userNavigation?.hrSetupDone);

	const { hrClaims, payrollClaims } = useSelector((state) => state?.sharedStates?.currentUser);

	let allCliams = [];
	if (isArray(hrClaims) && isArray(payrollClaims)) {
		allCliams = [...hrClaims, ...payrollClaims];
	}
	let contentList = [];
	const payrollList = [
		{
			id: 1,
			name: 'Manage additions, deductions & allowances',
			icons: not_focus_icons.setupCalculatorIcon,
			focusIcons: focus_state_icons.setupcalculatoricon,
			videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo1(aad).mp4',
			infoTitle: 'Speed up payroll processing!',
			infoSubTitle: 'Start managing allowances, additions, deductions and end of service in just a few clicks.',
			setupTime: 'Setup in 3 minutes',
			navigate: isDeductionCompleted ? '/new-hr-features/uploadPayrollDetails' : '/new-hr-features/allowance',
			cleverTapTryNow: 'v1_1_Intro_Payroll_TryNow',
		},
		{
			id: 2,
			name: 'Get payslips automatically with breakdown details',
			icons: not_focus_icons.documentPayslip,
			focusIcons: focus_state_icons.documentPayslip,
			infoTitle: 'Speed up payroll processing!',
			infoSubTitle: 'Manage overtime, additions, deductions and end of service in just a few clicks.',
			videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo2_payslips.mp4',
			setupTime: 'Setup in 4 minutes',
			navigate: '/new-hr-features/allowance',
			cleverTapTryNow: 'v1_1_Intro_Payslips_TryNow',
		},
	];
	const announcementList = {
		id: 4,
		name: 'Send announcements to your employees',
		icons: not_focus_icons.emails,
		focusIcons: focus_state_icons.emails,
		infoTitle: 'Communicate with all employees in seconds',
		infoSubTitle: 'Send notifications directly to their mobile phones instead of printing circulars.',
		imageSource: icons.not_focus.LeftImage3,
		setupTime: '',
		navigate: '/new-hr-features/setupAnnouncement',
		cleverTapTryNow: 'v1_1_Intro_Announ_TryNow',
	};
	const documentList = {
		id: 3,
		name: 'Get alerts before documents expire',
		icons: not_focus_icons.remainder,
		focusIcons: focus_state_icons.remainder,
		infoTitle: 'Avoid fines, we’ll remind you before documents expire',
		infoSubTitle: 'Store employee document safely and quickly access them when you need.',
		videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/hr-videos/HRVideo3_DocumentManagement_1080.mp4',
		setupTime: '',
		navigate: '/new-hr-features/documentsExpiry',
		cleverTapTryNow: 'v1_1_Intro_Docs_TryNow',
	};

	const leaveList = {
		id: 5,
		name: 'Digitize and automate leave management',
		icons: not_focus_icons.leavenew,
		focusIcons: focus_state_icons.autoLeaves,
		infoTitle: 'Save time and reduce errors!',
		infoSubTitle: 'No manual balance calculations, no forms, only real-time tracking.',
		videoSource: 'https://eae-c3pay-cdn-p.azureedge.net/hr-videos/HRVideo4_LeaveManagement.mp4',
		setupTime: 'Setup in 6 minutes',
		navigate: '/new-hr-features/departments',
		cleverTapTryNow: 'v1_1_Intro_Leave_TryNow',
	};
	const claverTap = (selectedId) => {
		switch (id) {
			case 1:
				return { CleverTapRight: 'v1_1_Intro_Payroll_Next', CleverTapLeft: 'v1_1_Intro_Payroll_Section' };
			case 2:
				return { CleverTapRight: 'v1_1_Intro_Payslips_Next', CleverTapLeft: 'v1_1_Intro_Payslips_Section' };
			case 3:
				return { CleverTapRight: 'v1_1_Intro_Docs_Next', CleverTapLeft: 'v1_1_Intro_Docs_Section' };
			case 4:
				return { CleverTapRight: 'v1_1_Intro_Announ_Next', CleverTapLeft: 'v1_1_Intro_Announ_Section' };
			case 5:
				return { CleverTapRight: 'v1_1_Intro_Leave_Next', CleverTapLeft: 'v1_1_Intro_Leave_Section' };
			default:
				return {};
		}
	};
	const handleChange = (contentId) => {
		if (contentId === 'add') {
			ClevertapReact.event(claverTap(id)?.CleverTapRight);
			setId((prev) => prev + 1);
		} else if (contentId === 'sub') {
			ClevertapReact.event(claverTap(id)?.CleverTapLeft);
			setId((prev) => prev - 1);
		} else {
			ClevertapReact.event(claverTap(id)?.CleverTapLeft);
			setId(contentId);
		}
	};

	const postAndPushToDashboard = () => {
		ClevertapReact.event('V1_1_Skip_never');
		dispatch(setupAction.creators.postHrSetupStatus({ hrOnBoardingFlowCompleted: true }));
		pushToDashboard('post');
	};

	const pushToDashboard = (mode) => {
		mode === 'push' && ClevertapReact.event('V1_1_Skip_later');
		dispatch(action.creators.setHrSetupStatusSkipped(true));
		history.push('/');
	};

	const list = allCliams.map((data) => {
		if (payrollClaimCheck.includes(data?.value)) return payrollList;
		if (documentClaimCheck.includes(data?.value)) return documentList;
		if (announcementClaimCheck.includes(data?.value)) return announcementList;
		if (leaveClaimCheck.includes(data?.value)) return leaveList;
	});
	const closeModal = () => {
		setModalOpen(false);
	};
	const newList = list.flat().filter((data) => data != undefined);
	newList.sort((a, b) => a.id - b.id);
	contentList = Array.from(new Set(newList));
	return (
		<div className="intro-container">
			<header className="header-container">
				<h2 className="header-title">
					<span className="header-title-sub">NEW!</span> You have exclusive access to HR features!
				</h2>
				{contentList.length >= 0 && (
					<div className="header-select-btn">
						<img
							alt="selectLeftBtn"
							className="select-btn"
							src={id === 1 ? not_focus_icons.selectLeftArrow : focus_state_icons.selectLeftArrow}
							onClick={() => {
								id !== 1 && handleChange('sub');
							}}></img>
						{id !== contentList.length ? (
							<img
								src={focus_state_icons.selectRightArrow}
								alt="selectRightBtn"
								className="select-btn"
								onClick={() => handleChange('add')}></img>
						) : (
							<span>
								<EdenredButtons
									children={'Skip'}
									btnType="secondary"
									disabled={false}
									onClick={() => {
										ClevertapReact.event('v1_1_Intro_Leave_Skip');
										setModalOpen(true);
									}}
									rightIcon={<ImageComponent src={images?.close} />}
								/>
							</span>
						)}

						<Edenredmodal
							title="You are skipping"
							desc="You’ll be able to continue from the Payroll and Dashboard in the future."
							onOkay={() => pushToDashboard('push')}
							onCancelButton="Don’t Show Again"
							onOkayButton="Do It Later"
							onCancel={postAndPushToDashboard}
							isOpen={modalOpen}
							heart={true}
							imageSrc={focus_state_icons.warningNew}
							close={closeModal}
						/>
					</div>
				)}
			</header>
			{contentList.length <= 0 ? (
				<ContentLoader />
			) : (
				<main className="intro-content-container">
					<section className="list-container">
						{contentList?.map((content, index) => {
							return (
								<div className={`list ${id === index + 1 ? 'list-active' : ''}`} onClick={() => handleChange(index + 1)} key={index}>
									<img
										src={id === index + 1 ? content.focusIcons : content.icons}
										alt="setupCalculatorIcon"
										className="list-icon"></img>
									<h5 className={`list-text ${id === index + 1 ? 'list-text-active' : ''}`}>{content?.name}</h5>
								</div>
							);
						})}
					</section>
					{/* here we get list of new hr features and their demo video */}
					{contentList?.map(
						(content, index) =>
							id === index + 1 && (
								<section className="intro-content-video-container" key={index}>
									{content?.videoSource && (
										<video
											className="video-container"
											// poster="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AAOElcx.img"
											autoPlay
											controls>
											<source src={content?.videoSource} type="video/mp4" />
										</video>
									)}
									{content?.imageSource && <img className="intro-image-container" src={content?.imageSource} alt=""></img>}
									<div className="details-container">
										<h2 className="details-title">{content?.infoTitle}</h2>
										<h5 className="details-text">{content?.infoSubTitle}</h5>
										<div className="tryNow-btn-container">
											<h6 className="setup-time">{content?.setupTime}</h6>
											<span>
												<EdenredButtons
													children="Try Now!"
													onClick={() => {
														ClevertapReact.event(content?.cleverTapTryNow);
														dispatch(setupAction.creators.postHrSetupStatus({ hrOnBoardingFlowCompleted: true }));
														history.push(content?.navigate);
													}}
													btnType="primary"
													disabled={false}
													rightIcon={<ImageComponent src={focus_state_icons?.RightArrow} />}
													className="try-now-btn"
												/>
											</span>
										</div>
									</div>
								</section>
							)
					)}
				</main>
			)}
		</div>
	);
};

export default HrFeatureSummary;
