const GET_ROLES = 'GET_ROLES';
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
const GET_ROLES_FAILED = 'GET_ROLES_FAILED';
const GET_CORPORATE_ID = 'GET_CORPORATE_ID';
const GET_CORPORATE_ID_SUCCESS = 'GET_CORPORATE_ID_SUCCESS';
const GET_CORPORATE_ID_FAILED = 'GET_CORPORATE_ID_FAILED';
const GET_CLAIMS = 'GET_CLAIMS';
const GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS';
const GET_CLAIMS_FAILED = 'GET_CLAIMS_FAILED';
const SUBSCRIPTION = 'SUBSCRIPTION';
const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
const SUBSCRIPTION_FAILED = 'SUBSCRIPTION_FAILED';
const SET_USER = 'SET_USER';
const SET_USER_DETAILS = 'SET_USER_DETAILS';
const REMOVE_USER = 'REMOVE_USER';
const USER_NAVIGATION = 'USER_NAVIGATION';
const USER_NAVIGATION_SUCCUESS = 'USER_NAVIGATION_SUCCUESS';
const USER_NAVIGATION_FAIL = 'USER_NAVIGATION_FAIL';
const GET_SETUP_STATUS_START = 'GET_SETUP_STATUS_START';
const GET_SETUP_STATUS_SUCCESS = 'GET_SETUP_STATUS_SUCCESS';
const GET_SETUP_STATUS_FAIL = 'GET_SETUP_STATUS_FAIL';
const REDIRECT_TO_SETUP = 'REDIRECT_TO_SETUP';
const GET_PENDING_STATUS_START = 'GET_PENDING_STATUS_START';
const GET_PENDING_STATUS_SUCCESS = 'GET_PENDING_STATUS_SUCCESS';
const GET_PENDING_STATUS_FAIL = 'GET_PENDING_STATUS_FAIL';
const IS_ONLY_EMPLOYEE_EXISTS = 'IS_ONLY_EMPLOYEE_EXISTS';
const GET_SETUP_COUNT_START = 'GET_SETUP_COUNT_START';
const GET_SETUP_COUNT_SUCCESS = 'GET_SETUP_COUNT_SUCCESS';
const GET_SETUP_COUNT_FAIL = 'GET_SETUP_COUNT_FAIL';
const GET_RAKBANK_DETAIL_START = 'GET_RAKBANK_DETAIL_START';
const GET_RAKBANK_DETAIL_SUCCESS = 'GET_RAKBANK_DETAIL_SUCCESS';
const GET_RAKBANK_DETAIL_FAIL = 'GET_RAKBANK_DETAIL_FAIL';

const GET_PROFILE_IMAGE_START = 'GET_PROFILE_IMAGE_START';
const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS';
const GET_PROFILE_IMAGE_FAIL = 'GET_PROFILE_IMAGE_FAIL';
const SET_HR_SETUP_STATUS = 'SET_HR_SETUP_STATUS';
const POST_HR_SETUP_STATUS = 'POST_HR_SETUP_STATUS';

const GET_INVOICE_INFO_START = 'GET_INVOICE_INFO_START';
const GET_INVOICE_INFO_SUCCESS = 'GET_INVOICE_INFO_SUCCESS';
const GET_INVOICE_INFO_FAIL = 'GET_INVOICE_INFO_FAIL';

const SET_SHOW_EWA_POPUP = 'SET_SHOW_EWA_POPUP';

const GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU = 'GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU';
const GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_SUCCESS = 'GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_SUCCESS';
const GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_FAIL = 'GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_FAIL';

const GET_UNEMPLOYMENT_INSURANCE_BANNER = 'GET_UNEMPLOYMENT_INSURANCE_BANNER';
const GET_UNEMPLOYMENT_INSURANCE_BANNER_SUCCESS = 'GET_UNEMPLOYMENT_INSURANCE_BANNER_SUCCESS';
const GET_UNEMPLOYMENT_INSURANCE_BANNER_FAIL = 'GET_UNEMPLOYMENT_INSURANCE_BANNER_FAIL';
// features flag type
const CHECK_FEATURES_FLAG = 'CHECK_FEATURES_FLAG';
const SET_FEATURES_FLAG = 'SET_FEATURES_FLAG';

const setHrSetupStatus = (payload) => {
	return {
		type: SET_HR_SETUP_STATUS,
		payload: payload,
	};
};

const postHrSetupStatus = (payload) => ({
	type: POST_HR_SETUP_STATUS,
	payload: payload,
});

const getRoles = () => {
	return {
		type: GET_ROLES,
	};
};
const getRolesSuccess = (response) => {
	return {
		type: GET_ROLES_SUCCESS,
		response,
	};
};
const getRolesFailed = () => {
	return {
		type: GET_ROLES_FAILED,
	};
};

const getCorporateId = () => {
	return {
		type: GET_CORPORATE_ID,
	};
};
const getCorporateIdSuccess = (corporateId, corporateIds) => {
	return {
		type: GET_CORPORATE_ID_SUCCESS,
		corporateId,
		corporateIds,
	};
};
const getCorporateIdFailed = () => {
	return {
		type: GET_CORPORATE_ID_FAILED,
	};
};

const getClaims = () => {
	return {
		type: GET_CLAIMS,
	};
};
const getClaimsSuccess = (roleType, response) => {
	return {
		type: GET_CLAIMS_SUCCESS,
		response,
		roleType,
	};
};
const getClaimsFailed = () => {
	return {
		type: GET_CLAIMS_FAILED,
	};
};

const subscription = () => {
	return {
		type: SUBSCRIPTION,
	};
};
const subscriptionSuccess = (response) => {
	return {
		type: SUBSCRIPTION_SUCCESS,
		response,
	};
};
const subscriptionFailed = () => {
	return {
		type: SUBSCRIPTION_FAILED,
	};
};
const setUser = (response) => {
	return {
		type: SET_USER,
		response,
	};
};
const setUserDetails = (response) => {
	return {
		type: SET_USER_DETAILS,
		response,
	};
};
const removeUser = () => {
	return {
		type: REMOVE_USER,
	};
};

const getUserNavigation = () => {
	return {
		type: USER_NAVIGATION,
	};
};

const getUnEmploymentInsuranceDetailsBanner = () => {
	return {
		type: GET_UNEMPLOYMENT_INSURANCE_BANNER,
	};
};

const getUnEmploymentInsuranceDetailsBannerSuccess = (res) => {
	return {
		type: GET_UNEMPLOYMENT_INSURANCE_BANNER_SUCCESS,
		res,
	};
};

const getUnEmploymentInsuranceDetailsBannerFail = () => {
	return {
		type: GET_UNEMPLOYMENT_INSURANCE_BANNER_FAIL,
	};
};

const getUserNavigationSuccess = (response, invoiceFlag) => {
	return {
		type: USER_NAVIGATION_SUCCUESS,
		response,
		invoiceFlag,
	};
};
const getUserNavigationFail = () => {
	return {
		type: USER_NAVIGATION_FAIL,
	};
};
const getSetupStatusStart = () => {
	return {
		type: GET_SETUP_STATUS_START,
	};
};
const getSetupStatusSucess = (res) => {
	return {
		type: GET_SETUP_STATUS_SUCCESS,
		res,
	};
};
const getSetupStatusFail = () => {
	return {
		type: GET_SETUP_STATUS_FAIL,
	};
};
const redirectToSetup = (flag) => {
	return {
		type: REDIRECT_TO_SETUP,
		flag,
	};
};
const getPendingStatusStart = () => ({
	type: GET_PENDING_STATUS_START,
});
const getPendingStatusSuccess = (res) => ({
	type: GET_PENDING_STATUS_SUCCESS,
	res,
});
const getPendingStatusFail = (err) => ({
	type: GET_PENDING_STATUS_FAIL,
	err,
});
const setIsOnlyEmployeeExits = (flag) => ({
	type: IS_ONLY_EMPLOYEE_EXISTS,
	flag,
});
const getSetupCountStart = () => ({
	type: GET_SETUP_COUNT_START,
});
const getSetupCountSuccess = (res) => ({
	type: GET_SETUP_COUNT_SUCCESS,
	res,
});
const getSetupCountFail = (err) => ({
	type: GET_SETUP_COUNT_FAIL,
	err,
});

const getRakBankDetails = () => ({
	type: GET_RAKBANK_DETAIL_START,
});

const getCorporateListToShowInvoiceMenu = () => ({
	type: GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU,
});

const getCorporateListToShowInvoiceMenuSuccess = (res) => ({
	type: GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_SUCCESS,
	res,
});

const getCorporateListToShowInvoiceMenuFail = () => ({
	type: GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_FAIL,
});

const getRakBankDetailsSuccess = (res) => ({
	type: GET_RAKBANK_DETAIL_SUCCESS,
	res,
});

const getRakBankDetailsFail = (error) => ({
	type: GET_RAKBANK_DETAIL_FAIL,
	error,
});
const getProfileImageStart = (imgPath) => {
	return {
		type: GET_PROFILE_IMAGE_START,
		imgPath,
	};
};
const getProfileImageSuccess = (img) => {
	return {
		type: GET_PROFILE_IMAGE_SUCCESS,
		img,
	};
};
const getProfileImageFail = (error) => {
	return {
		type: GET_PROFILE_IMAGE_FAIL,
		error,
	};
};

const getInvoiceInfoStart = () => {
	return {
		type: GET_INVOICE_INFO_START,
	};
};

const getInvoiceInfoSuccess = (data) => {
	return {
		type: GET_INVOICE_INFO_SUCCESS,
		data,
	};
};
const getInvoiceInfoFail = (error) => {
	return {
		type: GET_INVOICE_INFO_FAIL,
		error,
	};
};
const setShowEwaPopup = (flag) => {
	return {
		type: SET_SHOW_EWA_POPUP,
		flag,
	};
};

const checkFeaturesFlag = () => {
	return {
		type: CHECK_FEATURES_FLAG,
	};
};
const setFeaturesFlag = (payload) => {
	return {
		type: SET_FEATURES_FLAG,
		payload,
	};
};

export default {
	types: {
		GET_ROLES,
		GET_ROLES_SUCCESS,
		GET_ROLES_FAILED,
		GET_CORPORATE_ID,
		GET_CORPORATE_ID_SUCCESS,
		GET_CORPORATE_ID_FAILED,
		GET_CLAIMS,
		GET_CLAIMS_SUCCESS,
		GET_CLAIMS_FAILED,
		SUBSCRIPTION,
		SUBSCRIPTION_SUCCESS,
		SUBSCRIPTION_FAILED,
		SET_USER,
		SET_USER_DETAILS,
		REMOVE_USER,
		USER_NAVIGATION,
		USER_NAVIGATION_SUCCUESS,
		USER_NAVIGATION_FAIL,
		GET_SETUP_STATUS_START,
		GET_SETUP_STATUS_SUCCESS,
		GET_SETUP_STATUS_FAIL,
		REDIRECT_TO_SETUP,
		GET_PENDING_STATUS_START,
		GET_PENDING_STATUS_SUCCESS,
		GET_PENDING_STATUS_FAIL,
		IS_ONLY_EMPLOYEE_EXISTS,
		GET_SETUP_COUNT_START,
		GET_SETUP_COUNT_SUCCESS,
		GET_SETUP_COUNT_FAIL,
		GET_RAKBANK_DETAIL_START,
		GET_RAKBANK_DETAIL_SUCCESS,
		GET_RAKBANK_DETAIL_FAIL,

		GET_PROFILE_IMAGE_START,
		GET_PROFILE_IMAGE_SUCCESS,
		GET_PROFILE_IMAGE_FAIL,
		SET_HR_SETUP_STATUS,
		POST_HR_SETUP_STATUS,

		GET_INVOICE_INFO_START,
		GET_INVOICE_INFO_SUCCESS,
		GET_INVOICE_INFO_FAIL,

		GET_UNEMPLOYMENT_INSURANCE_BANNER,
		GET_UNEMPLOYMENT_INSURANCE_BANNER_SUCCESS,
		GET_UNEMPLOYMENT_INSURANCE_BANNER_FAIL,

		GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU,
		GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_SUCCESS,
		GET_CORPORATE_LIST_TO_SHOW_INVOICE_MENU_FAIL,

		SET_SHOW_EWA_POPUP,
		//features flag type
		CHECK_FEATURES_FLAG,
		SET_FEATURES_FLAG
	},
	creators: {
		getRoles,
		getRolesSuccess,
		getRolesFailed,
		getCorporateId,
		getCorporateIdSuccess,
		getCorporateIdFailed,
		getClaims,
		getClaimsSuccess,
		getClaimsFailed,
		subscription,
		subscriptionSuccess,
		subscriptionFailed,
		setUser,
		setUserDetails,
		removeUser,
		getUserNavigation,
		getUserNavigationSuccess,
		getUserNavigationFail,
		getSetupStatusStart,
		getSetupStatusSucess,
		getSetupStatusFail,
		redirectToSetup,
		getPendingStatusStart,
		getPendingStatusSuccess,
		getPendingStatusFail,
		setIsOnlyEmployeeExits,
		getSetupCountStart,
		getSetupCountSuccess,
		getSetupCountFail,
		getRakBankDetails,
		getRakBankDetailsSuccess,
		getRakBankDetailsFail,

		getProfileImageStart,
		getProfileImageSuccess,
		getProfileImageFail,
		setHrSetupStatus,
		postHrSetupStatus,

		setShowEwaPopup,

		getInvoiceInfoStart,
		getInvoiceInfoSuccess,
		getInvoiceInfoFail,

		getUnEmploymentInsuranceDetailsBanner,
		getUnEmploymentInsuranceDetailsBannerSuccess,
		getUnEmploymentInsuranceDetailsBannerFail,

		getCorporateListToShowInvoiceMenu,
		getCorporateListToShowInvoiceMenuSuccess,
		getCorporateListToShowInvoiceMenuFail,
		// feature flag action
		checkFeaturesFlag,
		setFeaturesFlag
	},
};
