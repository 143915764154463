import React from 'react';
import Uploaddocument from '@pages/payroll/EmployeesExpirymodule/component/Upload-document';
import './addEditLeaveBalance.scss';
import { useHistory } from 'react-router-dom';

const AddEditLeaveBalancePresentational = ({
	leaveApproval,
	isLeaveUploadLoading,
	isLeaveDocUploaded,
	leaveUploadDetails,
	leaveApproveUploadSaga,
	isleaveApproveUpload = true,
	saveDocumentUploadReset,
	isSetup = true,
}) => {
	const history = useHistory();
	return (
		<>
			<div className="setup_leave_approval">
				<div className="setup_tabs">
					<Uploaddocument
						{...{
							setUpUpload: leaveApproval,
							isSetup: isSetup,
							setUpName: 'Leave Balances',
							draggerHeader: 'Upload Your Spreadsheet Here:',
							hintText: 'To complete this setup, fill in the leave balances of annual leave and sick leave.',
							nextPath: '/leave-approval-table-setup',
							docLink: '/leave-approval-setup',
							tableData: leaveUploadDetails,
							isLeaveUploadLoading,
							leaveUploadDetails,
							leaveApproveUploadSaga,
							uploadType: 'leavesApproval',
							isLeaveDocUploaded,
							isleaveApproveUpload,
							resetUploadDocuments: saveDocumentUploadReset,
							onClickCancel: () => {
								history.goBack();
							},
							onClickNext: (data) => {
								history.push({
									pathname: '/add-edit-leave-balance/preview-table',
									state: { data: data.tableData, files: data.files },
								});
							},
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default AddEditLeaveBalancePresentational;
