import React from 'react';
import { Row } from 'antd';
import { CardOrderHistoryCard, PayrollHistoryCard } from '../cards';

const DashboardSecondSection = ({ payrollHistory, totalCardRequest, isPayrollHistoryLoading, isCardHistoryLoading, cardOrderTotalData }) => {
	return (
		<Row className="main_dashboard_web_second_row fade-right">
			{/* payroll history */}
			<PayrollHistoryCard {...{ isPayrollHistoryLoading, payrollHistory }} />
			{/* card order History*/}
			<CardOrderHistoryCard {...{ isCardHistoryLoading, cardOrderTotalData, totalCardRequest }} />
		</Row>
	);
};

export default DashboardSecondSection;
