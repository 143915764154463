import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Expand from 'react-expand-animated';
import { LoadingOutlined } from '@ant-design/icons';
import PayrollBlock from '@sharedComponent/payroll-block';
import { CLEVER_TAP_EVENTS } from '@constants/app-constants';
import ContentLoader from '@sharedComponent/content-loader';
import EdenRedCard from '@sharedComponent/card';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import PayrollNoData from '@pages/payroll/payrollNodata/PayrollNoData';
import Table from '@pages/DesignSystem/Table/Table';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import PayrollService from 'src/services/payroll-service';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';
import useOutsideClickpayroll from './useOutsideClickpayroll';
import RunPayrolltableHeader from './run-payroll-table-header';
import WpsNonWpsPopupFunctional from './wps-nonwps-popup-functional';
import TransferFundPopUpFunctional from '@pages/payroll-plus-new/components/TransferFundPopUpFunctional';
import useMediaQuery from 'src/hooks/useMediaQuery/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import Action from '@sharedComponent/app-action-reducer/action';
import { PAYROLL_CONSTANTS } from '@pages/payroll/constants';
import { amountWithCommas, amountWithDecimalsCount } from '@helpers';
import '../run-payroll-table.scss';
import '../runpayroll-table-mobile.scss';

const RunPayrollTablePresentational = ({
	handleFilterHeader,
	checkedIndex,
	columns,
	dataSource,
	loading,
	paytableDataDummy,
	totalEmpSelect,
	totalEmpAmt,
	handletoggleswitch,
	checkAll,
	hanldePayTableData,
	handleDataNext,
	stagingLoader,
	onSearch,
	checkClickedfunc, // TODO - NEED TO ENABLE THIS
	hasError,
	getrunpayrollTable,
	selectedFilterCount,
	handleClearAllFilter,
	dropdwon,
	setDropdown1,
	payrollPagination,
	activeEmployeeCount,
	fromUploadSheetPage,
	blockedData,
	isRepeatMode,
	totalCount,
	handleTableChange,
	isPopupVisible,
	setIsPopupVisible,
	nextLoader,
	isSalaryExceeds,
}) => {
	const isMobile = useMediaQuery('(max-width: 400px)');
	const _payrollService = new PayrollService();
	const [isData, setIsData] = useState(true);
	const [prevPayrollData, setPrevPayrollData] = useState([]);
	const [show, setShow] = useState(false);
	const [uploadIdLoading, setUploadIdLoading] = useState(false);
	const [tableShow, setTableShow] = useState(true);
	const [totalPageNumber, setTotalPageNumber] = useState(0);
	const ref = useRef();
	const [pageNumber, setPageNumber] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [prevPayRollDate, setPrevPayRollDate] = useState({});
	const [handleNextClick, setHandleNextClick] = useState(false);
	const featuresFlag = useSelector((state) => state?.sharedStates?.features);
	const dispatch = useDispatch();

	const filterClicked = (e) => {
		if (window.screen.width <= 600) {
			history.push('/filter');
			return;
		}
		setShow(!show);
	};
	useOutsideClickpayroll(ref, () => {
		show && setShow(false);
	});

	const handleDropdown = async (label, value) => await setDropdown1({ ...dropdwon, [label]: value });

	const checkDropdownAll = async (type) => {
		let data = await [];
		switch (type) {
			case 'Payroll Channel':
				data = await setDropdownDataAll(dropdwon && dropdwon.payrollChannelDD);
				await handleDropdown('payrollChannelDD', data);
				break;
			case 'Account Type':
				data = await setDropdownDataAll(dropdwon && dropdwon.accountTypeDD);
				await handleDropdown('accountTypeDD', data);
				break;
			case 'Establishment ID':
				data = await setDropdownDataAll(dropdwon && dropdwon.establishmentIdsDD);
				await handleDropdown('establishmentIdsDD', data);
				break;
			case 'Bank Name':
				data = await setDropdownDataAll(dropdwon && dropdwon.bankNameDD);
				await handleDropdown('bankNameDD', data);
				break;
			default:
				break;
		}
		await filterbasedOnDropdown();
	};

	const filterbasedOnDropdown = async (isSelected = false) => {
		let dataList = ((await isSelected) ? paytableDataDummy : dataSource) || [];
		let accountTypeList = await (dropdwon.accountTypeDD || [])?.filter((val) => val?.isSelected);
		let payrollChannelDDlist = await (dropdwon.payrollChannelDD || [])?.filter((val) => val?.isSelected);
		let bankNameDDList = await (dropdwon.bankNameDD || [])?.filter((val) => val?.isSelected);
		let establishmentIdsDDList = await (dropdwon.establishmentIdsDD || [])?.filter((val) => val?.isSelected);
		let filteredDropdown = await {
			accountTypeList,
			payrollChannelDDlist,
			bankNameDDList,
			establishmentIdsDDList,
		};
		checkClickedfunc(filteredDropdown);
	};

	const loadPreviousPayroll = () => getPreviousPayroll(1);

	const getPreviousPayroll = (pageCount) => {
		setIsLoading(true);
		try {
			_payrollService.getPreviousPayroll({ pageNumber: pageCount, pageSize: 3 }).then((res) => {
				if (res.status == 200) {
					setPrevPayrollData(res.data.lastPayrollDetails);
					if (totalPageNumber == 0) setTotalPageNumber(Math.ceil(res.data.totalCount / 3));
					setIsLoading(false);
				} else {
					console.log(res.status);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getPreviousPayroll(pageNumber);
	}, [pageNumber]);

	const pageChange = (isAble, mode) => {
		if (!isAble && mode === 'nextPage') {
			nextPage();
		}

		if (!isAble && mode === 'prevPage') {
			prevPage();
		}
	};

	const nextPage = () => setPageNumber(pageNumber + 1);

	const prevPage = () => setPageNumber(pageNumber - 1);

	const handlePayrollFilterSelect = (data) => checkClicked(data?.index, data?.label, !data?.isSelected);

	const handlePayrollFilterClear = () => handleClearAllFilter(false);

	// TODO -  NEED TO FILTER VIA REST API
	const checkClicked = async (index, type, isSelected) => {
		let data = await [];
		switch (type) {
			case 'Payroll Channel':
				data = await setDropdownData(dropdwon && dropdwon.payrollChannelDD, index);
				await handleDropdown('payrollChannelDD', data);
				break;
			case 'Account Type':
				data = await setDropdownData(dropdwon && dropdwon.accountTypeDD, index);
				await handleDropdown('accountTypeDD', data);
				break;
			case 'Establishment ID':
				data = await setDropdownData(dropdwon && dropdwon.establishmentIdsDD, index);
				await handleDropdown('establishmentIdsDD', data);
				break;
			case 'Bank Name':
				data = await setDropdownData(dropdwon && dropdwon.bankNameDD, index);
				await handleDropdown('bankNameDD', data);
				break;
			default:
				break;
		}
		await filterbasedOnDropdown(isSelected); // TODO - NEED TO FILETER VIA REST API
	};

	const setDropdownData = async (data = [], index) => {
		await data.map((val, ind) => {
			if (ind === index) {
				val.isSelected = !val.isSelected;
			}
			return val;
		});
		return data;
	};

	const setDropdownDataAll = async (data = []) => {
		let isChecked = (await data?.filter((val) => !val?.isSelected)) || [];
		await data.map((val) => {
			val.isSelected = isChecked?.length > 0;
			return val;
		});
		return data;
	};

	const repeatPayRoll = (params) => {
		setPrevPayRollDate({
			previousPayrollUploadId: params.uploadId,
			previoustotalPaidEmps: params.totalPaidEmps,
		});

		let prevPayRollParams = {
			bankName: null,
			establishmentId: null,
			accountType: null,
			payrollChannel: null,
			pageSize: null,
			pageNumber: null,
			search: null,
			previousPayrollUploadId: params.uploadId,
			previoustotalPaidEmps: params.totalPaidEmps,
		};
		if (params.uploadId && params.totalPaidEmps > 100) {
			setUploadIdLoading(true);
			_payrollService
				.postEmployeeSalaryStaging(params.uploadId)
				.then((response) => {
					setUploadIdLoading(false);
					let tempEmployeeSalaries = [];
					response.data &&
						response.data.employees.map((emp) => {
							tempEmployeeSalaries.push({
								...emp,
								totol_sal: params.uploadId ? emp.previousSalary : emp.salary,
								variable_pay: emp.variablePay,
							});
						});
					history.push({
						pathname: '/payroll/run/channel',
						state: { tabledata: tempEmployeeSalaries },
					});
				})
				.catch((error) => {
					setUploadIdLoading(false);
				});
		} else {
			getrunpayrollTable(prevPayRollParams);
			setTableShow(!tableShow);
		}
	};

	const history = useHistory();
	const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;
	// let locale = {
	// 	emptyText: (
	// 		<div>
	// 			<PayrollNoData tableLoading={tableLoading.spinning} url={'/employees/add/multiple/employees/upload'} />
	// 		</div>
	// 	),
	// };
	useEffect(() => {
		setIsData(dataSource?.length > 0);
	}, [dataSource]);

	useEffect(() => {
		if (dataSource.length > 0) {
			setIsData(true);
		} else if (dataSource.length <= 0) {
			setIsData(false);
		}
	}, [dataSource]);

	useEffect(() => {
		if (handleNextClick && featuresFlag) {
			handleDataNext('next');
			setHandleNextClick(false);
		}
	}, [featuresFlag]);

	const handleNext = () => {
		if (!featuresFlag) {
			setHandleNextClick(true);
			dispatch(Action.creators.checkFeaturesFlag());
		} else {
			handleDataNext('next');
		}
	};

	return (
		<>
			<EdenredHeader />
			<Fragment>
				{blockedData ? (
					<div style={{ padding: '40px' }}>
						<div style={{ marginBottom: '27px' }}>
							<ETypo bold h2>
								Payroll Table
							</ETypo>
						</div>
						<div
							style={{
								minWidth: '100%',
								minHeight: '70vh',
								display: 'flex',
								alignItems: 'center',
								backgroundColor: '#FFFFFF',
							}}>
							<PayrollBlock
								corporateMessage={blockedData.corporateMessage}
								suspensionMessage={blockedData.suspensionMessage}></PayrollBlock>
						</div>
					</div>
				) : (
					<Col className="run-payroll-table-presentation-wrapper">
						<Col className={dataSource.length > 0 ? 'runpayroll-content' : ' runpayroll-content Manage-payroll-nodata'}>
							<Col className="run-payroll-table-header">
								<ETypo bold h2>
									{PAYROLL_CONSTANTS.runPayroll.header.title}
								</ETypo>
								<div className="run_payroll_header_content">
									<div className="unSub_payroll_amount">
										<ETypo light b1 color="#737780">
											{PAYROLL_CONSTANTS.runPayroll.header.payrollAmountText}
										</ETypo>
										<ETypo medium b1>
											AED {amountWithCommas(amountWithDecimalsCount(totalEmpAmt))} for {totalEmpSelect} employees
										</ETypo>
									</div>
									{/* <EdenredButtons
										btnType="secondary"
										disabled={hasError || totalEmpSelect <= 0}
										onClick={() => handleDataNext('save')}
										loading={stagingLoader}>
										Save
									</EdenredButtons> */}
									{isMobile === false && (
										<EdenredButtons
											btnType="primary"
											disabled={hasError || totalEmpSelect <= 0 || isSalaryExceeds}
											onClick={handleNext}
											loading={handleNextClick || nextLoader}>
											{PAYROLL_CONSTANTS.runPayroll.header.nextText}
										</EdenredButtons>
									)}
								</div>
							</Col>
							<Col className="run-payroll-table-filter-wrapper run-payroll-table-web">
								<RunPayrolltableHeader
									{...{
										fromUploadSheetPage,
										isRepeatMode,
										filterClicked,
										onSearch,
										tableShow,
										setTableShow,
										loadPreviousPayroll,
										ref,
										handleDropdown,
										checkDropdownAll,
										checkClicked,
										dropdwon,
										selectedFilterCount,
										handleClearAllFilter,
										totalCount,
										show,
										payrollPagination,
										handleTableChange,
										handlePayrollFilterSelect,
										handlePayrollFilterClear,
									}}
								/>
								<Expand open={tableShow} duration={700}>
									<Col className="runpayroll-table-wrapper">
										<Table columns={columns} data={dataSource} tableLoading={loading} />
									</Col>
								</Expand>
								<Expand open={!tableShow} duration={700}>
									<Col className="runpayroll-previous">
										<div className="prev-payroll-text-container">
											<EdenredTypo h6="true" className="prev-payroll-text">
												Previous payroll runs:
											</EdenredTypo>
										</div>
										<div className="paper-box-container">
											{!isLoading ? (
												prevPayrollData.map((item) => (
													<div className="paper-box">
														<div>
															<EdenredTypo className="payroll-card-text1" h6="true" style={{ display: 'flex' }}>
																<span
																	style={{
																		background: '#F7F8FA',
																		borderRadius: '50%',
																		width: '32px',
																		height: '32px',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		marginRight: '10px',
																	}}>
																	<img style={{ width: '80%' }} src={icons.focus_state.Calendar}></img>
																</span>
																{item.submittedDate}
															</EdenredTypo>
														</div>
														<div className="line" />
														<div>
															<div style={{ paddingBottom: 3 }}>
																<EdenredTypo h6="true" className="payroll-card-text2">
																	{item.bank + item.c3Pay} Total Employees
																</EdenredTypo>
															</div>
															<div className="cards-accounts">
																<EdenredTypo h6="true" className="payroll-card-text3">
																	{item.c3Pay} C3Pay Cards
																</EdenredTypo>
																<div className="vertical-line" />
																<EdenredTypo className="payroll-card-text3" h6="true">
																	{item.bank} Bank Accounts
																</EdenredTypo>
															</div>
														</div>
														<div className="aed-container">
															<EdenredTypo className="payroll-card-text4" h6="true">
																AED{' '}
																{item && item.totalSalary
																	? item.totalSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
																	: 0}
															</EdenredTypo>
															<button
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	repeatPayRoll({
																		submittedDatedate: item.submittedDate,
																		uploadId: item.uploadId,
																		totalPaidEmps: item.bank + item.c3Pay,
																	});
																	ClevertapReact.event(CLEVER_TAP_EVENTS.repeat, {
																		repeatamount: item.totalSalary,
																		noofemployees: parseInt(item.bank) + parseInt(item.c3Pay),
																	});
																}}
																disabled={loading || uploadIdLoading ? true : false}
																className={`payroll-card-button ${loading || uploadIdLoading ? 'disabled' : ''}`}>
																{loading || uploadIdLoading ? (
																	<Spin indicator={antIcon} />
																) : (
																	<img
																		style={{ paddingLeft: '10px', paddingRight: '15px' }}
																		src={icons.focus_state.Repeat}></img>
																)}{' '}
																Repeat
															</button>
														</div>
													</div>
												))
											) : (
												<ContentLoader />
											)}
										</div>
										<div className="icon-box-container">
											<ImageComponent
												style={{ marginRight: '8px', cursor: 'pointer' }}
												onClick={() => pageChange(pageNumber == 1 && true, 'prevPage')}
												src={pageNumber == 1 ? icons.not_focus.UnSelectedLeft : icons.focus_state.SelectedLeft}
											/>
											<ImageComponent
												style={{ cursor: 'pointer' }}
												onClick={() => pageChange(pageNumber >= totalPageNumber && true, 'nextPage')}
												src={
													pageNumber >= totalPageNumber ? icons.not_focus.UnSelectedRight : icons.focus_state.SelectedRight
												}
											/>
										</div>
										<div onClick={() => setTableShow(true)} style={{ cursor: 'pointer' }}>
											<div className="orange-box">
												<img className="upicon" src={icons.focus_state.UpIcon}></img>
											</div>
											<div className="white-box">
												<EdenredTypo h6="true" className="show-payroll-text">
													Show Payroll Table Again
												</EdenredTypo>
											</div>
										</div>
									</Col>
								</Expand>
								<div className="payroll_pagination">
									<div className="payroll-pagination-count">
										<ETypo light small color="#484B52">
											{dataSource?.length} out of {activeEmployeeCount} Employees
										</ETypo>
									</div>
									{fromUploadSheetPage === false && (
										<Pagination
											{...{
												currentPage: payrollPagination?.pagination?.current,
												totalCount: activeEmployeeCount,
												pageSize: payrollPagination?.pagination?.pageSize,
												onPageChange: (page) => handleTableChange(page, 'current', prevPayRollDate),
											}}
										/>
									)}
								</div>
							</Col>
						</Col>
						{isMobile && isData && (
							<Col className="footer-parent-payroll">
								<Row align="middle" className="runpayroll-bottom-fixed-card">
									<div className="sub-footer-payroll">
										<Col xl={7} lg={7} md={8} sm={8} xs={24} className="left-content">
											<Col className="left-content-box">
												<Col className="header">Payroll Amount:</Col>
												<Col className="body">
													AED {totalEmpAmt} for {totalEmpSelect} employees.
												</Col>
											</Col>
										</Col>
										<Col xl={17} lg={17} md={16} sm={16} xs={16}>
											<Row style={{ width: '100%' }}>
												<Col xl={17} lg={17} md={12} xs={24} className="upload-btn">
													<Link
														className="upload-payroll-btn"
														to="/upload/salary/spreadsheet_or_sif"
														onClick={() => ClevertapReact.event(CLEVER_TAP_EVENTS.Uploadexcel_PayrollTable)}>
														<EdenredButton type="outline-g">Upload Excel Or Direct SIF</EdenredButton>
													</Link>
												</Col>
												<Col xl={7} lg={7} md={12} className="next-btn">
													{/* <Link to="/payroll/run/channel"> */}
													<EdenredButton
														disabled={hasError || totalEmpSelect <= 0 || isSalaryExceeds}
														onClick={handleNext}
														loading={handleNextClick || nextLoader}>
														{PAYROLL_CONSTANTS.runPayroll.header.nextText}
													</EdenredButton>
													{/* </Link> */}
												</Col>
											</Row>
										</Col>
									</div>
								</Row>
							</Col>
						)}
						{/* mobile - area */}
						<Col className="run-payroll-mobile-table-view">
							<Row className="run_payroll_mobile_view">
								<Col sm={24} xs={24} className="run_payroll_card">
									{dataSource?.map((user, index) => {
										return (
											<EdenRedCard
												key={index}
												type="RunPayroll_Mobile"
												checkedIndex={checkedIndex}
												index={index}
												handletoggleswitch={handletoggleswitch}
												payroll_table_Data={user}
												hanldePayTableData={hanldePayTableData}
												className="card_list_padding"
											/>
										);
									})}
								</Col>
							</Row>
							{totalEmpSelect > 0 ? (
								<Row className="filter_button_view">
									<Col xs={24} sm={24} className="modal_filter_card">
										<Col xs={24} sm={24} className="filter_card__amount">
											<EdenredTypo h6="true">Payroll Amount:</EdenredTypo>
											<EdenredTypo bold="true" className="filter_card__AED">
												AED {totalEmpAmt} for {totalEmpSelect} employees.
											</EdenredTypo>
										</Col>
										<Col xs={24} sm={24} className="filter_card__button">
											{/* As in desktop view also we dont have save option, so disabling it in mobile view too */}
											{/* <Col xs={12} sm={10} style={{ padding: '2%' }}>
												<EdenredButton type="outline-g">Save</EdenredButton>
											</Col> */}
											<Col xs={12} sm={10} style={{ padding: '2%' }}>
												<EdenredButton
													disabled={hasError || totalEmpSelect <= 0 || isSalaryExceeds}
													loading={handleNextClick || nextLoader}
													onClick={handleNext}>
													{PAYROLL_CONSTANTS.runPayroll.header.nextText}
												</EdenredButton>
											</Col>
										</Col>
										<Row style={{ width: '100%', paddingTop: '0%', paddingBottom: '12px' }}>
											<Col sm={24} xs={24} className="Deselect" onClick={handleFilterHeader}>
												{checkAll ? 'Deselect All Employees' : 'Select All Employees'}
											</Col>
										</Row>
									</Col>
								</Row>
							) : null}
						</Col>
						<WpsNonWpsPopupFunctional {...{ isPopupVisible, setIsPopupVisible }} />
						<TransferFundPopUpFunctional />
					</Col>
				)}
			</Fragment>
		</>
	);
};

export default RunPayrollTablePresentational;
