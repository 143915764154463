import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import TeamPermission, { DELETE_USER, UPDATE_CLAIMS } from '@pages/team-permission/services';
import { INVITE_TEAMS_CONSTANTS } from '@constants/app-constants';
import Action from '@sharedComponent/app-action-reducer/action';
import SharedServices, { GET_PROFILE } from '@sharedComponent/app-action-reducer/services';
import UserService from 'src/services/user-service';
import Popup from 'src/utils/popup/popups';
import UserRolePresentational from './user-role-presentational';

const UserRoleFunctional = ({ claims: { User }, issetup = false }) => {
	const [claimList, setclaimList] = useState([]);
	const [campareRoles, setCampareRoles] = useState([]);
	const [apiLoading, setapiLoading] = useState(true);
	const [allClaims, setAllClaims] = useState();
	const [userClaims, setUserClaims] = useState();
	const [user, setUserState] = useState();
	const [isClaimsLoaded, setIsClaimLoaded] = useState(true);
	const [alertPop, setAlertPop] = useState(false);
	let history = useHistory();
	let { username } = useParams();
	const historyState = useLocation();
	const apiService = new SharedServices();
	const [loading, setLoading] = useState(false);
	const [deleteLoading, setdeleteLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [isLastEmployee, setIsLastEmployee] = useState(false);
	const [onHover, setOnHover] = useState({
		onTeamsHover: false,
		onPayrollHover: false,
		onHrHover: false,
	});
	const lastEmployee = historyState?.state?.list?.filter((data) => data?.teamClaims?.length !== 0);
	const currentUserClaims = historyState?.state?.list?.filter((data) => data?.user?.userName === userClaims?.user?.userName) || [];
	const loginUSername = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const dispatch = useDispatch();
	const isOnlyEmployeeExists = history?.location?.state?.isemployeeUserExists || false;
	const showModal = () => {
		const condition1 =
			lastEmployee?.length === 1 &&
			(!allClaims?.teamClaims?.[0]?.isSelected || allClaims?.teamClaims?.[0]?.isSelected) &&
			currentUserClaims?.[0]?.teamClaims?.length !== 0;
		if (condition1) {
			setIsLastEmployee(true);
		} else {
			setVisible(true);
		}
	};

	const closeModal = () => setVisible(false);

	useEffect(() => {
		getUserClaims();
	}, []);

	useEffect(() => {
		getallClaims();
	}, [userClaims]);

	const api = new TeamPermission();
	const userAPI = new UserService();

	const getUserClaims = () => {
		userAPI
			.getUserClaims(username)
			.then((response) => {
				setUserClaims(response.data);
				setUserState(response.data);
			})
			.catch((error) => {});
	};
	const getallClaims = () => {
		userAPI
			.getAllClaimsApi()
			.then((response) => {
				let hrClaimData = response.data.hrClaims;
				let payrollClaims = response.data.payrollClaims;
				let teamClaims = response.data.teamClaims;
				let allClaimsData = response.data;
				let hrClaimsArray = userClaims?.hrClaims?.map((obj) => obj?.value);
				let payrollClaimsClaims = userClaims?.payrollClaims?.map((obj) => obj?.value);
				let teamClaimsClaims = userClaims?.teamClaims?.map((obj) => obj?.value);
				let defaultHrIsSelected = hrClaimData?.map((data) => {
					return {
						...data,
						isSelected: hrClaimsArray?.includes(data.value),
					};
				});
				let defaultPayrollIsSelected = payrollClaims?.map((data) => {
					return {
						...data,
						isSelected: payrollClaimsClaims?.includes(data.value),
					};
				});
				let defaultTeamClaimsSelected = teamClaims?.map((data) => {
					return {
						...data,
						isSelected: teamClaimsClaims?.includes(data.value),
					};
				});
				let mergedArray = [...defaultHrIsSelected, ...defaultPayrollIsSelected, ...defaultTeamClaimsSelected];
				setclaimList(mergedArray);
				setCampareRoles(mergedArray);

				let finalObj = {
					...allClaimsData,
					hrClaims: defaultHrIsSelected,
					payrollClaims: defaultPayrollIsSelected,
					teamClaims: defaultTeamClaimsSelected,
				};

				setAllClaims(finalObj);
				setIsClaimLoaded(false);
			})
			.catch((err) => {
				setapiLoading(false);
			});
	};
	const generateClaims = (claims = []) => {
		setclaimList(
			User.map((user) => {
				return {
					...user,
					isSelected: claims.indexOf(user.value) >= 0,
				};
			})
		);
	};

	useEffect(() => {
		generateClaims();
	}, [User]);

	Array.prototype.remove = function () {
		var what,
			a = arguments,
			L = a.length,
			ax;
		while (L && this.length) {
			what = a[--L];
			while ((ax = this.indexOf(what)) !== -1) {
				this.splice(ax, 1);
			}
		}
		return this;
	};
	const save_button = (type) => {
		if (type === INVITE_TEAMS_CONSTANTS.SAVE_CHANGE) {
			if (lastEmployee?.length === 1 && !allClaims?.teamClaims?.[0]?.isSelected && currentUserClaims?.[0]?.teamClaims?.length !== 0) {
				setIsLastEmployee(true);
			} else {
				saveClicked();
			}
		} else {
			if (issetup) {
				history.push('/teamspermission');
			} else {
				history.push('/manage_team');
			}
		}
	};
	const closeLastEmployee = () => {
		setIsLastEmployee(false);
	};

	const saveClicked = () => {
		const addClaims = claimList.filter((claim) => claim.isSelected && claim.value).map((claim) => claim.value);
		const removeClaimsArr = claimList.filter((claim) => !claim.isSelected && claim.value).map((claim) => claim.value);
		setAlertPop(addClaims?.length === 0);
		if (addClaims?.length !== 0) {
			setLoading(true);
			api.services(
				UPDATE_CLAIMS,
				{ username },
				{
					removeClaims: removeClaimsArr,
					addClaims: addClaims,
				}
			)
				.then((addClaimsResponse) => {
					setLoading(false);
					apiService
						.services(GET_PROFILE)
						.then((profRes) => {
							dispatch(Action.creators.setUser(profRes.data));
						})
						.catch((err) => {});
					history.push({
						pathname:
							loginUSername === username && !allClaims?.teamClaims?.[0]?.isSelected
								? '/'
								: issetup
								? '/teamspermission'
								: '/manage_team',
						state: {
							response: INVITE_TEAMS_CONSTANTS.EDIT_ROLE_SAVED,
						},
					});
				})
				.catch((addClaimsErr) => {
					setLoading(false);
				});
		}
	};

	const getDeleterole = () => {
		setLoading(true);
		setdeleteLoading(true);
		api.services(DELETE_USER, { username })
			.then((res) => {
				setdeleteLoading(false);
				setLoading(false);
				history.push({
					pathname: issetup ? '/teamspermission' : '/manage_team',
					state: {
						response: `${username} removed successfully`,
					},
				});
				closeModal();
			})
			.catch((err) => {
				setdeleteLoading(false);
				setLoading(false);
			});
	};

	const changeClaims = (isSelected, value) => {
		let newObj = {};
		if (isSelected) {
			newObj = {
				...allClaims,
				hrClaims: allClaims?.hrClaims?.map((val) => (val.value === value ? { ...val, isSelected: true } : val)),
				payrollClaims: allClaims?.payrollClaims?.map((val) => (val.value === value ? { ...val, isSelected: true } : val)),
				teamClaims: allClaims?.teamClaims?.map((val) => (val.value === value ? { ...val, isSelected: true } : val)),
			};
		} else {
			newObj = {
				...allClaims,
				hrClaims: allClaims?.hrClaims?.map((val) => (val.value === value ? { ...val, isSelected: false } : val)),
				payrollClaims: allClaims?.payrollClaims?.map((val) => (val.value === value ? { ...val, isSelected: false } : val)),
				teamClaims: allClaims?.teamClaims?.map((val) => (val.value === value ? { ...val, isSelected: false } : val)),
			};
		}
		setAllClaims(newObj);
		let tempClaimList = claimList?.map((claim) => {
			if (claim?.value === value) {
				return {
					...claim,
					isSelected,
				};
			} else return claim;
		});
		setclaimList([...tempClaimList]);
	};
	const handleAlertPop = () => {
		setAlertPop(false);
	};
	const handleMouseHover = (value, state) => {
		setOnHover({ [state]: value });
	};
	const handleSelectAll = (roles, value) => {
		const newObj = {
			...allClaims,
			[value]: allClaims?.[value]?.map((data) => {
				return {
					...data,
					isSelected: true,
				};
			}),
		};
		setAllClaims(newObj);
		const notSameValue = claimList?.filter((data) => roles?.every((item) => data?.value !== item?.value));
		const selectedRoles = claimList
			?.filter((data) => roles?.some((item) => data?.value === item?.value))
			?.map((role) => {
				return {
					...role,
					isSelected: true,
				};
			});
		const selectAll = [...selectedRoles, ...notSameValue];
		setclaimList(selectAll);
	};
	return (
		<>
			<Popup
				className={'should_haveClaims_popup'}
				visible={alertPop}
				message={{
					titleMessage: 'Should have Alteast one Roles & Permissions? ',
					subMessage: null,
				}}
				okText={'Ok'}
				closePopup={handleAlertPop}
			/>
			<UserRolePresentational
				{...{
					apiLoading,
					showModal,
					closeModal,
					visible,
					roles: claimList,
					compareRoles: campareRoles,
					save_button,
					getDeleterole,
					changeClaims,
					loading,
					deleteLoading,
					allClaims,
					username,
					isClaimsLoaded,
					userClaims: user,
					saveClicked,
					closeLastEmployee,
					isLastEmployee,
					issetup,
					isOnlyEmployeeExists,
					handleMouseHover,
					onHover,
					handleSelectAll,
				}}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		claims: SelectorsApp.claims(state),
	};
};

const Connected = connect(mapStateToProps, null)(UserRoleFunctional);

export default Connected;
