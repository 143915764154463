import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';
import Axios from 'axios';

class PayoutTimingService {
	baseUrl = `${DOMAIN_URL}/PayoutTiming`;

	async getPayoutTiming() {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`);
	}
}

export default PayoutTimingService;
