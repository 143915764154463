import Axios from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';

export function* getAnnouncementData() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const url = `${DOMAIN_URL}/Announcement/${corporateId}/announcementhistory`;
		const res = yield call(Axios.get, url);
		yield put(Actions.creators.getAnnouncementDataSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getAnnouncementDataError(err));
	}
}

export function* updateAnnouncementData(initial) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const request = initial?.payload;
	try {
		const controller = new AbortController();
		let res;
		const url = `${DOMAIN_URL}/Announcement/${corporateId}/announcement`;
		setTimeout(() => {
			if (!res?.status) {
				controller.abort();
			}
		}, 10000);
		res = yield call(Axios.post, url, request, {
			signal: controller.signal,
		});
		yield put(Actions.creators.updateAnnouncementDataSuccess(res.data));
		yield put(Actions.creators.announcementPopUp(false));
		yield put(Actions.creators.getAnnouncementData());
		yield put(Actions.creators.announcementSuccess(true));
		yield put(Actions.creators.announcementsToastVisible(true));
	} catch (err) {
		yield put(Actions.creators.announcementPopUp(false));
		yield put(Actions.creators.announcementsToastVisible(false));
		yield put(Actions.creators.updateAnnouncementDataError(err || 'something went wrong'));
	}
}

export default function* announcementWatcher() {
	yield takeEvery(Actions.types.GET_ANNOUNCEMENT_DATA, getAnnouncementData);
	yield takeLatest(Actions.types.UPDATE_ANNOUNCEMENT_DATA, updateAnnouncementData);
}
