import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredTypo from '@sharedComponent/typography';
import ETypo from '@pages/DesignSystem/Typo';
import VideoTutorialService from 'src/services/videoTutorial-service';
import { icons } from 'src/assets/icons';
import '../video-tutorial.scss';

const VideoSegmentContainer = ({ title, folderId, loadVideo, length }) => {
	const [startNum, setStartNum] = useState(0);
	const [endNum, setEndNum] = useState(4);

	// const _videoTutorialService = new VideoTutorialService();
	// const [videos, setVideos] = useState([]);
	const [nextPage, setNextPage] = useState(length > 4 ? true : false);
	const [previousPage, setPreviousPage] = useState(false);
	// const [videoLoading, setVideoLoading] = useState(true);
	let history = useHistory();
	useEffect(() => {
		// getAllVideos();
		// getvideoList();
	}, []);
	// const getvideoList = () => {
	// 	setVideoList(folderId.slice(startNum, endNum));
	// };
	// const getAllVideos = async () => {
	// 	setVideoLoading(true);
	// 	try {
	// 		let videosOfFolder = [];
	// 		_videoTutorialService.getInitialVideos({}, folderId, 4).then((response) => {
	// 			let videoData = folderId;
	// 			console.log('get', response);
	// 			videoData.forEach((res, index) => {
	// 				videosOfFolder.push({
	// 					name: res.name,
	// 					description: res.description,
	// 					link: res.link,
	// 					thumbnail: res.pictures.sizes[1].link_with_play_button,
	// 				});
	// 			});
	// 			setNextPage(response.data.paging.next);
	// 			setPreviousPage(response.data.paging.previous);
	// 			setVideos([...videosOfFolder]);
	// 			setVideoLoading(false);
	// 		});
	// 	} catch (error) {
	// 		console.log(error);
	// 		setVideoLoading(false);
	// 	}
	// };

	const openModel = (link) => {
		console.log('v7 segment modal link:', link);
		loadVideo(link);
	};
	const pagination = (direction, length) => {
		if (direction === 'left') {
			// paginateVideos(previousPage);
			setStartNum(startNum - 4);
			setEndNum(endNum - 4);
			setNextPage(endNum >= length || endNum - 4 === 4 ? true : false);
			setPreviousPage(endNum - 4 <= 4 ? false : true);
		}
		if (direction === 'right' && nextPage) {
			// paginateVideos(nextPage);
			setStartNum(endNum);
			setEndNum(endNum + 4);
			setNextPage(endNum + 4 < length ? true : false);
			setPreviousPage(endNum >= 4 ? true : false);
		}
	};

	// const paginateVideos = (path) => {
	// 	setVideoLoading(true);
	// 	try {
	// 		let videosOfFolder = [];
	// 		_videoTutorialService.paginateVideos({}, path).then((response) => {
	// 			let videoData = folderId;
	// 			console.log('videodata', videoData);
	// 			videoData.forEach((res, index) => {
	// 				videosOfFolder.push({
	// 					name: res.name,
	// 					// description: res.description,
	// 					link: res.link,
	// 					// thumbnail: res.pictures.sizes[1].link_with_play_button,
	// 				});
	// 			});
	// 			setNextPage(response.data.paging.next);
	// 			setPreviousPage(response.data.paging.previous);
	// 			setVideos([...videosOfFolder]);
	// 			setVideoLoading(false);
	// 		});
	// 	} catch (error) {
	// 		console.log(error);
	// 		setVideoLoading(false);
	// 	}
	// };

	return (
		<>
			<Row id={folderId} className="video_segment_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Row className="video_header">
						<Col xl={12} lg={12} md={12} sm={12} xs={12} className="video-title-parent">
							<EdenredTypo className="video_title">{title}</EdenredTypo>
						</Col>
						<Col lg={12} xl={12} md={12} sm={12} xs={12} className="paginator_container">
							<Row style={{ width: '100%', height: '100%' }}>
								<Col xl={24} lg={24} md={24} sm={24} xs={24} className="view_all_container">
									<Row className="navigation_holder">
										<div className="paginate_div">
											<div style={{ padding: '1px 15px' }}>
												<img
													style={{ width: '20px', height: '17px', margin: '0px 10px 0px 0px' }}
													onClick={() => previousPage && pagination('left', length)}
													className="navigateImage"
													alt=""
													src={previousPage ? icons.focus_state.SelectedLeft : icons.not_focus.UnSelectedLeft}
												/>

												<img
													style={{ width: '20px', height: '17px' }}
													alt=""
													onClick={() => nextPage && pagination('right', length)}
													className="navigateImage"
													src={nextPage ? icons.focus_state.SelectedRight : icons.not_focus.UnSelectedRight}
												/>
											</div>
										</div>

										<p
											disabled={folderId.length == 0 ? true : false}
											style={{ cursor: folderId.length == 0 ? 'not-allowed' : 'pointer' }}
											onClick={() =>
												folderId.length > 0 &&
												history.push({ pathname: '/view_all', state: { titleName: title, folder_Id: folderId } })
											}
											className="video_viewall_button">
											<ETypo>View All</ETypo>
										</p>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					{folderId ? (
						folderId.length ? (
							<Row className="video_container">
								{folderId.slice(startNum, endNum).map((item, index) => {
									console.log('v2 item.thumbnail : ', item.thumbnail, item);
									return (
										<Col
											xl={6}
											lg={7}
											md={10}
											sm={10}
											xs={10}
											className={index == 3 ? 'video_Card_Wrapper_without_padding' : 'video_Card_Wrapper'}>
											<Row className="video_holder">
												{/* <img className="img_style" style={{ objectFit: 'contain' }} src={icons.focus_state[item.image]} /> */}
												<img
													className="img_style"
													alt=""
													style={{ objectFit: 'cover' }}
													src={item.thumbnail}
													onClick={() => openModel(item.link)}
												/>
											</Row>
											<Row className="video_title_holder">
												<EdenredTypo>{item.name}</EdenredTypo>
											</Row>
										</Col>
									);
								})}
							</Row>
						) : (
							<div style={{ justifyContent: 'center', alignItems: 'center' }} className="video_container">
								<EdenredTypo className="folder_not_found_text">No videos found</EdenredTypo>
							</div>
						)
					) : (
						<Row className="video_container">
							<ContentLoader></ContentLoader>
						</Row>
					)}
				</Col>
			</Row>
		</>
	);
};

export default VideoSegmentContainer;
