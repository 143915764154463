import React from 'react';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import OverlapCard from './overlapCard';
import MobileLeaveToApproveCard from './MobileLeaveToApproveCard';
import MobilePopupCard from './custom-popup/MobilePopupCard';
import LeaveToApproveNoRecords from './leave-to-approve-norecords';

function LeavesToApproveMob({
	data,
	isModalVisible = false,
	setIsModalVisible,
	showModal,
	dataOverlap,
	popupStateMob,
	afterOpenModal,
	handlePopupCloseMob,
	popupMessageStateMob,
	setPopupMessageState,
	handlePopupClickMob,
	handlePopupClick1,
	approveMessage,
	rejectMessage,
	overLappingLeaveLoader,
	approveRejectLoading,
	leaveType,
	Department,
}) {
	return (
		<>
			<div className="main-mob-leave-approve">
				<div>
					<ImageComponent src={images.edenredSVG} style={{ width: '80px' }} />
				</div>
				<Row>
					<Col className="text-container-leave-approve1">
						<EdenredTypo className="bold mob-leave-approve-text1">Leaves to Approve</EdenredTypo>
					</Col>
					{data?.length === 0 ? (
						<LeaveToApproveNoRecords />
					) : (
						<>
							{data &&
								data?.map((data) => (
									<>
										<Col xl={24} style={{ width: '100%', margin: '10px 0px' }}>
											<MobileLeaveToApproveCard
												cardData={data}
												isModalVisible={isModalVisible}
												setIsModalVisible={setIsModalVisible}
												showModal={showModal}
												dataOverlap={dataOverlap}
												handlePopupClick={handlePopupClickMob}
												approveMessage={approveMessage}
												rejectMessage={rejectMessage}
											/>
										</Col>
									</>
								))}
						</>
					)}
					<OverlapCard
						title="Overlapping Leaves"
						subtitle={Department}
						isModalVisible={isModalVisible}
						setIsModalVisible={setIsModalVisible}
						dataOverlap={dataOverlap}
						loader={overLappingLeaveLoader}
					/>
					{/* <EdenredModal
					isOpen={popupState}
					afterOpenModal={afterOpenModal}
					closeModal={handlePopupClose}
					user=""
					content_mobile={popupMessageState}
					content_web={popupMessageState}
					style={{ width: '100%' }}
					className="modal_cancel_payroll"
					go_Back="No"
				/> */}
				</Row>
			</div>

			<MobilePopupCard
				visible={popupStateMob}
				closePopup={handlePopupCloseMob}
				message={popupMessageStateMob}
				handleOnClick={handlePopupClick1}
				//className="leave-appove-mob-popup"
				loading={approveRejectLoading}
				leave={leaveType}
			/>
		</>
	);
}

export default LeavesToApproveMob;
