import Actions from './action';
export const initState = {
	filterData: [],
	employeeDatas: [],
	datas: [],
	totalCount: 0,
	payrollDataLoader: false,
	repeatprevpayrollLoader: false,
};

const counterReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_PREVIOUS_PAYROLL_DATA: {
			return {
				...state,
				repeatprevpayrollLoader: true,
			};
		}

		case Actions.types.GET_PREVIOUS_PAYROLL_DATA_SUCCESS: {
			return {
				...state,
				datas: action.response,
				repeatprevpayrollLoader: false,
			};
		}
		case Actions.types.GET_PREVIOUS_PAYROLL_DATA_ERROR: {
			return {
				...state,
				repeatprevpayrollLoader: false,
			};
		}
		case Actions.types.GET_FILTER_DROPDOWN_DATA_SUCCESS: {
			return {
				...state,
				filterData: action.res,
			};
		}
		case Actions.types.GET_EMPLOYEE_TABLE_DATA: {
			return {
				...state,
				payrollDataLoader: true,
			};
		}

		case Actions.types.GET_EMPLOYEE_TABLE_DATA_SUCCESS: {
			return {
				...state,
				employeeDatas: action.res,
				payrollDataLoader: false,
			};
		}
		case Actions.types.GET_EMPLOYEE_TABLE_DATA_ERROR: {
			return {
				...state,
				payrollDataLoader: false,
			};
		}
		case Actions.types.GET_TOTAL_COUNT_SUCCESS: {
			console.log('action.res', action.res);
			return {
				...state,
				totalCount: action.res,
			};
		}

		default:
			return state;
	}
};

export default counterReducer;
