import React from 'react';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { EmployeListExcelDownload } from '@sharedComponent/excel/excel';
import { monthsNames } from '@constants/index';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './wps-nonwps-popup.scss';
import DatePickers from '@pages/DesignSystem/date-flat-picker';

const WpsNonWpsPopupPresentational = ({
	isPopupVisible,
	handlePopup,
	monthYear,
	wpsNonWpsDetail,
	handlePopupCard,
	handleMonthYear,
	wpsNonwpsButtonDisable,
	monthDropDownList,
	yearDropDownList,
	handleWpsNonWps,
	handleMonthYearSelect,
	handleNowLater,
	handleSelectDate,
	emppStagingLoader,
	downloadingExecl,
	EmpList,
	countEmp,
	wpsLoader,
}) => {
	return (
		<Edenredmodal
			className="payroll_wps_nonwps"
			isOpen={isPopupVisible?.isWpsNonWps}
			title={isPopupVisible?.message !== 'next' ? isPopupVisible?.message : ''}
			close={handlePopup}>
			{isPopupVisible?.status === 'wpsNonWps' && (
				<div className="payroll_wps_nonWps">
					<ETypo bold h4>
						Select Payroll Channel:
					</ETypo>
					<div className="payroll_wps_nonWps_popup">
						{wpsNonWpsDetail?.map((data) => (
							<div
								key={data?.id}
								className={`payroll_popup_card1 ${data?.isSelected ? 'selected' : 'not_selected'}`}
								onClick={() => handlePopupCard(data?.param)}>
								<ETypo bold h4>
									{data?.title}
								</ETypo>
								<ETypo light b1 color="#737780">
									{data?.description}
								</ETypo>
								{data?.isSelected && (
									<div className="red_tick">
										<img src={not_focus_icons?.RedTick} />
									</div>
								)}
							</div>
						))}
					</div>
					{downloadingExecl && (
						<div className="payroll_wps_nonWs_alert">
							<ETypo light b3 color="#181919">
								WPS Details are missing for “{countEmp}” employee(s).{' '}
								<EmployeListExcelDownload
									hideSalary
									dataSet={EmpList ? EmpList : []}
									filename={`employeelist`}
									element={<a id="ex"> Click Here</a>}
								/>{' '}
								to download the list. You can update their WPS Establishment ID and WPS Person ID from the “employees” section or
								write to us on support.ae@edenred.com for help
							</ETypo>
						</div>
					)}
					<div className="payroll_wps_nonWps_popup_button">
						<EdenredButtons
							{...{
								onClick: () => handleWpsNonWps(wpsNonwpsButtonDisable?.[0]?.param, 'monthYearSelect'),
								disabled: !wpsNonwpsButtonDisable?.length || downloadingExecl,
								loading: wpsLoader,
							}}>
							Next
						</EdenredButtons>
					</div>
				</div>
			)}
			{isPopupVisible?.status === 'monthYearSelect' && (
				<div className="payroll_month_year_select">
					<ETypo bold h4>
						What month and year is this payroll for?
					</ETypo>
					<ETypo light b1 color="#737780" style={{ marginTop: '8px' }}>
						Select the month and date of the employees’ salary records with the Ministry of Labour under WPS system.
					</ETypo>
					<div className="month_year_dropdown">
						<div className="month_dropdown">
							<MultiSelect
								{...{
									Option: monthDropDownList,
									// value: monthYear?.month,
									onChange: (e) => handleMonthYear('month', monthsNames?.indexOf(e?.month) + 1),
									prefix: <img src={not_focus_icons?.payrollCalender} style={{ paddingRight: '5px' }} />,
									showIcon: false,
									dropDownValue: 'month',
									searchVisibility: false,
									label: 'Month',
									disabled: monthYear.year ? false : true,
									showTooltip: monthYear.year ? false : true,
									toolTipText: 'Please select year before Month',
								}}
							/>
						</div>
						<div className="year_dropdown">
							<MultiSelect
								{...{
									Option: yearDropDownList,
									// value: monthYear?.year,
									onChange: (e) => handleMonthYear('year', e?.year),
									prefix: <img src={not_focus_icons?.payrollCalender} style={{ paddingRight: '5px' }} />,
									showIcon: false,
									dropDownValue: 'year',
									searchVisibility: false,
									label: 'Year',
								}}
							/>
						</div>
					</div>
					<div className="payroll_month_year_button">
						<EdenredButtons
							{...{
								onClick: () => handleMonthYearSelect(monthYear, 'nowOrLater'),
								disabled: !(monthYear?.month && monthYear?.year),
							}}>
							Next
						</EdenredButtons>
					</div>
				</div>
			)}
			{isPopupVisible?.status === 'nowOrLater' && (
				<div className="payroll_now_later">
					<ETypo bold h4>
						Do You Want To Run Payroll Now or Later?
					</ETypo>
					<ETypo light b1 color="#737780" style={{ marginTop: '8px' }}>
						Select 'Now' if you want to run payroll now (payroll will run immediately on weekdays) or 'Later' to run payroll at a later
						date.
					</ETypo>
					<div className="payroll_now_later_button">
						<EdenredButtons
							{...{
								onClick: () => handleNowLater('', 'selectDate', 'later'),
								btnType: 'secondary',
							}}>
							Later
						</EdenredButtons>
						<EdenredButtons
							{...{
								onClick: () => handleNowLater('', 'review', 'now'),
							}}>
							Now
						</EdenredButtons>
					</div>
				</div>
			)}
			{isPopupVisible?.status === 'selectDate' && (
				<div className="payroll_select_date">
					<ETypo bold h4>
						When do you want to run payroll?
					</ETypo>
					<ETypo light b1 color="#737780" style={{ marginTop: '8px' }}>
						Please select a date to proceed
					</ETypo>
					<div className="paroll_select_date_dropdown">
						<DatePickers
							{...{
								onChange: (e, date) => handleMonthYear('proceedSelectedDate', date),
								dateFormat: 'F j, Y',
							}}
						/>
					</div>
					<div className="payroll_select_date_button">
						<EdenredButtons
							{...{
								onClick: () => handleSelectDate('', 'nowOrLater', 'cancel'),
								btnType: 'secondary',
							}}>
							Cancel
						</EdenredButtons>
						<EdenredButtons
							{...{
								onClick: () => handleSelectDate(monthYear?.proceedSelectedDate, 'review', 'Proceed'),
								disabled: !monthYear?.proceedSelectedDate,
							}}>
							Proceed
						</EdenredButtons>
					</div>
				</div>
			)}
		</Edenredmodal>
	);
};

export default WpsNonWpsPopupPresentational;
