import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { CLEVER_TAP_EVENTS } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import Inputbox from '@pages/DesignSystem/Input-Field';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Toast from '@pages/DesignSystem/Toast';
import ClevertapReact from 'src/utils/clever-tap';
import PayrollService from 'src/services/payroll-service';
import { icons } from 'src/assets/icons';
import '../health-insurance.scss';

const ChangePasswordPresentational = ({ healthInsurenceDetail }) => {
	const [loading, setloading] = useState(false);
	const [thankyou, setThankyou] = useState(false);
	const [Name, setName] = useState('');
	const [TelephoneNumber, setTelephoneNumber] = useState('');
	const [MonthRenewal, setMonthRenewal] = useState('');
	const _payrollService = new PayrollService();
	const [form] = Form.useForm();
	const onFinish = () => {
		setloading(true);
		let body = {
			name: Name,
			telephoneNumber: TelephoneNumber,
			renewalMonth: MonthRenewal,
		};
		let params = {};
		_payrollService
			.insuranceSignup(body, params)
			.then((res) => {
				setloading(false);
				setName('');
				setTelephoneNumber('');
				setMonthRenewal('');
				form.resetFields();
				setThankyou(true);
			})
			.catch((err) => {
				setloading(false);
			});
	};
	const clearPopUp = () => setThankyou(false);

	return (
		<Row className="Insurance_presentational_container">
			<Col className="Insurance-presentational">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="health-inusrance">
					<Col xl={15} md={15} lg={15} sm={24} xs={24} className="health_inc_banner">
						<div className="health_inc_left_card">
							<ImageComponent src={icons.focus_state.MedicalInsurance} />
							<div>
								<ETypo bold h1>
									Payroll and Medical Insurance All In One Place!
								</ETypo>
							</div>
							<div className="Insurance-content">
								<ETypo light h5>
									Leave the work to us. We're launching Group Health insurance so you can easily manage your employees' needs in one
									place.
								</ETypo>
							</div>
						</div>
					</Col>
					<Col xl={9} md={9} lg={9} sm={24} xs={24} className="data_slip">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ paddingRight: '32px' }}>
							<Col xl={24} lg={24} md={24} sm={24} xs={24} className="form-content">
								<ETypo bold h3>
									Get quotes in time for your next renewal.
								</ETypo>
							</Col>

							<Form form={form} onFinish={onFinish}>
								<Row className="health_inc_change_password_input">
									<Col xs={24} sm={24} md={24} xl={24} lg={24}>
										<Form.Item
											name="Name"
											onChange={(e) => {
												setName(e.target.value);
											}}>
											<Inputbox label="Name" type="text" labelClassName="inputlabelrequired" placeholder="Muhammad" />
										</Form.Item>
										<Form.Item
											name="Telephone Number"
											onChange={(e) => {
												setTelephoneNumber(e.target.value);
											}}>
											<Inputbox
												label="Telephone Number"
												type="number"
												labelClassName="inputlabelrequired"
												placeholder="+971XXXXXXXXX"
											/>
										</Form.Item>
										<Form.Item
											name="Month Renewal"
											onChange={(e) => {
												setMonthRenewal(e.target.value);
											}}>
											<Inputbox
												label="Month of Renewal"
												type="text"
												labelClassName="inputlabelrequired"
												placeholder="Example: January"
											/>
										</Form.Item>
										<Col className="health_inc_update_password">
											<Form.Item>
												<EdenredButtons
													onClick={() => {
														let payload = {
															Name: Name,
															TelephoneNumber: TelephoneNumber,
															MonthRenewal: MonthRenewal,
														};
														ClevertapReact.event(CLEVER_TAP_EVENTS.StayUpdated_HealthInsurance, payload);
													}}
													loading={loading}
													disabled={!Name && !TelephoneNumber && !MonthRenewal}>
													Stay Updated
												</EdenredButtons>
											</Form.Item>
										</Col>
									</Col>
								</Row>
							</Form>
						</Col>
					</Col>
				</Col>
				<Row xl={24} lg={24} md={24} sm={24} xs={24} className="main-card-container">
					{healthInsurenceDetail?.map((data) => (
						<Col id={data?.id} xl={6} lg={6} md={6} sm={6} xs={6} className="insurance_card_container">
							<div className="card-containers">
								<div className={`card-image-container ${data?.param}`}>
									<ImageComponent src={data?.image} />
								</div>
								<ETypo medium h4 style={{ marginTop: '10px', textAlign: 'center' }}>
									{data?.name}
								</ETypo>
							</div>
						</Col>
					))}
				</Row>
				<Col xl={24} lg={24} md={24} sm={24} xs={24}>
					<Col className="health-mobile-view">
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<ImageComponent src={icons.focus_state.Quotes} style={{ width: '100%', height: '100%' }} />
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<ImageComponent src={icons.focus_state.Price} style={{ width: '100%', height: '100%' }} />
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<ImageComponent src={icons.focus_state.Plans} style={{ width: '100%', height: '100%' }} />
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<ImageComponent src={icons.focus_state.AllInOne} style={{ width: '100%', height: '100%' }} />
						</Col>
					</Col>
				</Col>
			</Col>
			<Toast visible={thankyou} size="44%" color="#8EF9B3" left="28%" clearPopUp={clearPopUp}>
				Thank you. We will get in touch just before your next renewal
			</Toast>
		</Row>
	);
};

export default ChangePasswordPresentational;
