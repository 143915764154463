import Actions from './action';

export const initState = {
	isEmailDataLoading: false,
	emailDataSave: [],
	emailUpdated: [],
	emailUpdateError: '',
	isInvitePortalPopup: false,
	employeeNewData: [],
	employeeNewDataError: '',
	isEmployeeNewDataLoading: false,
	inviteCardStatus: '',
};

const EmailReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.POST_EMAIL_DATA: {
			return {
				...state,
				isEmailDataLoading: true,
			};
		}
		case Actions.types.POST_EMAIL_DATA_SUCCESS: {
			return {
				...state,
				isEmailDataLoading: false,
				emailDataSave: action.res,
			};
		}
		case Actions.types.POST_EMAIL_DATA_FAILED: {
			return {
				...state,
				isEmailDataLoading: false,
				emailDataSave: action.err,
			};
		}
		case Actions.types.UPDATE_EMAIL_NEW_START: {
			return {
				...state,
				isEmailUpdateLoad: true,
			};
		}
		case Actions.types.UPDATE_EMAIL_NEW_SUCCESS: {
			return {
				...state,
				isEmailUpdateLoad: false,
				emailUpdated: action?.res,
			};
		}
		case Actions.types.UPDATE_EMAIL_NEW_FAIL: {
			return {
				...state,
				isEmailUpdateLoad: false,
				emailUpdated: [],
				emailUpdateError: action?.error,
			};
		}
		case Actions.types.INVITE_PORTAL_POPUP: {
			return {
				...state,
				isInvitePortalPopup: action?.flag,
			};
		}
		case Actions.types.GET_NEW_DATA: {
			return {
				...state,
				isEmployeeNewDataLoading: true,
			};
		}
		case Actions.types.GET_NEW_DATA_SUCCESS: {
			return {
				...state,
				employeeNewData: action.res,
				isEmployeeNewDataLoading: false,
			};
		}
		case Actions.types.GET_NEW_DATA_ERROR: {
			return {
				...state,
				employeeNewDataError: action.res,
				isEmployeeNewDataLoading: false,
			};
		}
		case Actions.types.INVITE_CARD_STATUS: {
			return {
				...state,
				inviteCardStatus: action.status,
			};
		}

		default:
			return state;
	}
};
export default EmailReducer;
