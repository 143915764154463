// Our bank details page in payroll
const bankDetailsCommonData = [
    { title: 'Bank Name', value: 'Ras Al Khaimah Bank (RAK BANK)' },
    { title: 'Bank Branch', value: 'Umm Hurair, Dubai' },
    { title: 'Account Name', value: 'Edenred Prepaid Cards Management Services LLC' },
    { title: 'Swift Code', value: 'NRAKAEAK' },
]
const transferSalaryFunds = [
    { title: 'Account Number', value: '0332545791001' },
    { title: 'IBAN Number', value: 'AE830400000332545791001' },
    { title: 'Routing Code', value: '740810000' },
]

const payInvoices = [
    { title: 'Account Number', value: '0332545791003' },
    { title: 'IBAN Number', value: 'AE290400000332545791003' },
    { title: 'Routing Code', value: 'N/A' },
]


export const PAYROLL_CONSTANTS = Object.freeze({
    runPayroll: {
        header: {
            title: 'Run Payroll for',
            payrollAmountText: 'Payroll Amount:',
            saveText: 'Save',
            saveAndContinueText: 'Save & Continue',
            nextText: 'Next',

        }
    },
    employeeAccountType: {
        bank: 'Bank Accounts',
        card: 'C3Pay Cardholders'
    },
    salaryLimit: 50000,
    // Our Bank details page
    bankDetails: {
        title: 'Bank Details',
        description: 'The account you transfer salary funds to is DIFFERENT from the account which you’ll use to pay your invoice dues to us.',
        trasnferFundsTab: {
            title: 'Transfer Salary Funds',
            key: 'TSL',
            data: [...bankDetailsCommonData, ...transferSalaryFunds]
        },
        payInvoicesTab: {
            title: 'Pay Invoices',
            key: 'PI',
            data: [...bankDetailsCommonData, ...payInvoices],
        }
    }
})
