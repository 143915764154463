import React from 'react';
import propTypes from 'prop-types';
import './chips.scss';

const Chips = ({ color = '#D6EDFC', children, classname }) => {
	return (
		<div className={`chips ${classname}`} style={{ background: color }}>
			{children}
		</div>
	);
};

Chips.propTypes = {
	color: propTypes.string,
	children: propTypes.element,
};
export default Chips;
