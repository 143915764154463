import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import Input from '@sharedComponent/text-field';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';

const AddWPSPersonID = () => {
	const { state } = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		const { wpsPersonId } = values;
		history.push({ pathname: '/employees/add/individual/employee/establishmentid', state: { ...state, wpsPersonId } });
	};

	const handleRedirectLabourCardPage = () => {
		history.push({
			pathname: '/employees/add/individual/employee/labourcardnumber',
			state: { ...state, wpsPersonId: '', wpsEstablishmentId: '' },
		});
	};

	return (
		<>
			<EdenredHeader />
			<Row className="wps_person_id_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="wps_person_id_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName : ''}’s WPS Person ID?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="wps_person_id_description">
					<EdenredTypo>Found 14-digit number on the front side of the Employee's Work Permit/Labour Card.</EdenredTypo>
				</Col>
				<Row className="wps_person_id_form_image_row">
					<Col xl={11} lg={12} md={18} sm={24} xs={24} className="wps_person_id_form_section">
						<Form form={form} onFinish={onFinish}>
							<Form.Item
								name="wpsPersonId"
								rules={[
									{ required: true, message: 'Please provide the WPS Person ID of the employee' },
									{
										max: 14,
										message: 'Max length should be 14',
									},
								]}>
								<Input maxLength="14" placeholder="WPS Person ID" labels="WPS Person ID" labelClassName="inputlabelrequired" />
							</Form.Item>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Form>
					</Col>
					<Col xl={13} lg={12} md={22} sm={24} xs={24} className="wps_person_id_labour_card_image">
						<ImageComponent src={images.LabourCard} />
					</Col>
				</Row>
				<Col xl={12} lg={12} md={18} sm={24} xs={24} className="wps_person_id_link_button">
					<EdenredButton type="link" onClick={handleRedirectLabourCardPage}>
						I Don’t Know
					</EdenredButton>
				</Col>
			</Row>
		</>
	);
};

export default AddWPSPersonID;
