import React from 'react';
import moment from 'moment';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExcelDownload = ({ filename, dataSet, element, columnKeys }) => {
	let nDataSet = [...dataSet];
	nDataSet &&
		nDataSet.map((dataSet) => {
			let data = dataSet;
			data.bankName = data.bankName || '-';
			data.bankRoutingCode = data.bankRoutingCode || '-';
			data.rhfreceivedDate = data.rhfreceivedDate ? moment(new Date(data.rhfreceivedDate)).format('DD/MM/YYYY') : '';
			data.deletedDate = data.deletedDate ? moment(new Date(data.deletedDate)).format('DD/MM/YYYY') : '-';
			data.accountType = data.accountType || '-';
			data.bankAccountNo = data.bankAccountNo || '';
			return data;
		});
	return (
		<ExcelFile filename={filename} element={element}>
			<ExcelSheet data={dataSet} name="Salary_History">
				{columnKeys?.map((keyName, i) => {
					return <ExcelColumn key={i} label={keyName?.labelKey} value={keyName?.valueKey} />;
				})}
			</ExcelSheet>
		</ExcelFile>
	);
};
