import { useMemo } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import sharedActions from "@sharedComponent/app-action-reducer/action";
import SharedServices, { GET_PROFILE } from "@sharedComponent/app-action-reducer/services";
import { mergeAllClaims } from "src/utils/claims";
import { ALL_EMPLOYEE_CLAIMS, ALL_PAYROLL_CLAIMS, PERMISSIONS } from "@constants/app-constants";
import useCallMfa from "./useCallMfa";
import { SITEMAP } from "src/routes/sitemap";

const useUserLoginData = (userData) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
    const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
    const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);
    const { checkMfa } = useCallMfa(userData)

    const isOnlyEmployeeProfile = () => {
        if (Array.isArray(allClaims) && allClaims?.length <= 0) {
            return true;
        }
        const CLAIMS_FOR_NAVIGATING_TO_HOME = {
            toShow: [
                PERMISSIONS.MANAGE_LEAVES,
                PERMISSIONS.MANAGE_DOCUMENTS,
                PERMISSIONS.MANAGE_ANNOUNCEMENTS,
                PERMISSIONS.SETUP_LEAVE_APPROVAL_WORKFLOW_AND_EDIT_LEAVE_BALANCES,
                ...ALL_EMPLOYEE_CLAIMS,
            ],
            notToShow: [...ALL_PAYROLL_CLAIMS],
        };

        const NAVIGATE_TO_HOME = {
            HAS_VALID_CLAIMS: CLAIMS_FOR_NAVIGATING_TO_HOME.toShow.some((_claims) => allClaims.indexOf(_claims) >= 0),
            DOES_NOT_CONTAIN_UNWANTED_CLAIMS:
                CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow && !CLAIMS_FOR_NAVIGATING_TO_HOME.notToShow.some((values) => allClaims.indexOf(values) >= 0),
        };

        if (NAVIGATE_TO_HOME.HAS_VALID_CLAIMS === true && NAVIGATE_TO_HOME.DOES_NOT_CONTAIN_UNWANTED_CLAIMS === true) {
            return true;
        } else {
            return false;
        }
    }

    // getting user details from /Users/me api
    const getUserDetails = async () => {
        try {
            const sharedServices = new SharedServices()
            const res = await sharedServices.services(GET_PROFILE)
            const { employeeClaims, hrClaims, payrollClaims, teamClaims, user } = res?.data || {}
            const allClaims = [...hrClaims, ...payrollClaims, ...teamClaims];
            const isOnlyEmployee = allClaims.length === 0 && employeeClaims.length > 0
            dispatch(sharedActions.creators.setUser(res.data))
            if (isOnlyEmployee) {
                dispatch(sharedActions.creators.setIsOnlyEmployeeExits(isOnlyEmployee))
            }
            if (user?.roles && user?.roles?.length > 0 && user?.roles[0].name === 'Verifier') {
                dispatch(sharedActions.creators.getRoles())
            }
        } catch (error) {
            message.error('Something went wrong')
        }
    };

    const checkUserPasswordReset = (user) => {
        /**
           * loginstep=1 new user first time login show carousel popup
           * loginstep=2 new user first time login how card based on claims
           * loginstep=0 completed login step for new user to skip first time login
           */
        if (!user?.isPasswordReset) {
            history.push(SITEMAP.user.createPassword);
        }
        else if (!isOnlyEmployeeExits && user?.loginStep === 1 && isOnlyEmployeeProfile() === false) {
            history.push({
                pathname: SITEMAP.user.loginStep,
                state: {
                    loginStep: user?.loginStep,
                },
            });
        }
        else {
            // Mfa call check
            checkMfa()
        }
    }


    return {
        getUserDetails,
        checkUserPasswordReset
    }

}

export default useUserLoginData
