import React from 'react';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ItemHeader from '../../components/ItemHeader';
import './leave-calculation.scss';

const leave = [
	{
		title: 'Monday – Saturday (Between 4am - 9pm)',
		content: 'Employee is entitled to fully paid leave',
	},
	{
		title: 'Weekends/Between 9pm - 4am on Monday – Saturday',
		content: 'Employee is entitled to leave with 50% of the total salary paid',
	},
	{
		title: 'Public holidays',
		content: 'Unpaid leave',
	},
];
const leave1 = [
	{
		title: 'Employee has worked with the company for less than a year',
		content: 'The employee is entitled to 45 calendar days of leave with 50% of the total salary paid.',
	},
	{
		title: 'Employee has worked with the company for more than a year',
		content: 'The employee is entitled to 45 calendar days of fully paid leave.',
	},
];

const LeaveCalculation = () => {
	return (
		<Col xs={24} sm={24} xl={24} md={24} lg={24} className="leave-calculation-presentational">
			<ItemHeader title="Leave Calculation" />
			<Row className="payroll-col">
				<Col xl={24} md={24} lg={24}>
					<Row className="wps-rules-header">
						<EdenredTypo>How is leave calculated under UAE Labour Law?</EdenredTypo>
					</Row>
					<Row className="questions-conter">
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question">
							<EdenredTypo>Annual Leave</EdenredTypo>
						</Col>
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="answer">
							<EdenredTypo>
								An employee is entitled to 24 calendar days of paid annual leave on his first year with a company and 30 calendar days
								of paid annual leave in all subsequent years.
							</EdenredTypo>
						</Col>
					</Row>
					<Row className="questions-conter-medical">
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question">
							<EdenredTypo>Medical Leave</EdenredTypo>
						</Col>
						{leave.map((content) => (
							<>
								<Col xl={8} md={8} lg={8} xs={24} sm={24} className="border-table-header">
									<EdenredTypo>{content.title}</EdenredTypo>
								</Col>
								<Col xl={16} md={16} lg={16} xs={24} sm={24} className="border-table">
									<EdenredTypo>{content.content}</EdenredTypo>
								</Col>
							</>
						))}
					</Row>
					<Row className="questions-conter-medical">
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question">
							<EdenredTypo>Maternity Leave</EdenredTypo>
						</Col>
						{leave1.map((content) => (
							<>
								<Col xl={8} md={8} lg={8} xs={24} sm={24} className="border-table-header">
									<EdenredTypo>{content.title}</EdenredTypo>
								</Col>
								<Col xl={16} md={16} lg={16} xs={24} sm={24} className="border-table">
									<EdenredTypo>{content.content}</EdenredTypo>
								</Col>
							</>
						))}
					</Row>
					<Row className="questions-conter">
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="question">
							<EdenredTypo>Paternity Leave</EdenredTypo>
						</Col>
						<Col xl={24} md={24} lg={24} xs={24} sm={24} className="answer">
							<EdenredTypo>The employee is entitled to 5 calendar days of paid leave</EdenredTypo>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
export default LeaveCalculation;
