import React from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { CLEVER_TAP_EVENTS } from '@constants/app-constants';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import FilterButton from '@pages/DesignSystem/filter';
import { debounceHandler, entriesDropDown } from '@helpers';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { focus_state_icons } from 'src/assets/icons/focus_state';

const RunPayrolltableHeader = ({
	fromUploadSheetPage,
	isRepeatMode,
	onSearch,
	dropdwon,
	payrollPagination,
	handleTableChange,
	handlePayrollFilterSelect,
	handlePayrollFilterClear,
}) => {
	const history = useHistory();
	const nFilterData = [
		{
			filterLabel: 'Payroll Channel',
			count: 0,
			filterValue: dropdwon?.payrollChannelDD?.map((data) => {
				return {
					...data,
					filterValue: data?.payrollChannelName,
				};
			}),
		},
		{
			filterLabel: 'Account Type',
			count: 0,
			filterValue: dropdwon?.accountTypeDD?.map((data) => {
				return {
					...data,
					filterValue: data?.accountTypeName,
				};
			}),
		},
		{
			filterLabel: 'Establishment ID',
			count: 0,
			filterValue: dropdwon?.establishmentIdsDD?.map((data) => {
				return {
					...data,
					filterValue: data?.establishmentId,
				};
			}),
		},
		{
			filterLabel: 'Bank Name',
			count: 0,
			filterValue: dropdwon?.bankNameDD?.map((data) => {
				return {
					...data,
					filterValue: data?.bankName,
				};
			}),
		},
	];
	return (
		<Row align="middle" className="filter-area main-div-pay">
			<div className="run_payroll_search_download">
				<div className="top-bar">
					<Col className="search-runPayroll">
						{!fromUploadSheetPage && !isRepeatMode && (
							<Inputbox
								className="serach-box"
								prefix={<ImageComponent className="search-icon" src={focus_state_icons.NewSearch} />}
								placeholder="Search by Name or Employee ID."
								onChange={(e) => debounceHandler(e?.target?.value, onSearch)}
							/>
						)}
					</Col>
					{!fromUploadSheetPage && !isRepeatMode && (
						<div>
							<FilterButton
								{...{
									filterData: nFilterData,
									selectedFilter: handlePayrollFilterSelect,
									filterClearAll: handlePayrollFilterClear,
								}}
							/>
						</div>
					)}
					{fromUploadSheetPage === false && (
						<EdenredButtons
							btnType="secondary"
							leftIcon={<ImageComponent src={not_focus_icons?.uploadSpreadsheet} />}
							onClick={() => {
								history.push({
									pathname: '/upload/salary/spreadsheet_or_sif',
									state: { payrollService: 'RUN-PAYROLL' },
								});
								ClevertapReact.event(CLEVER_TAP_EVENTS.Uploadexcel_PayrollTable);
							}}>
							Upload Spreadsheet
						</EdenredButtons>
					)}
				</div>
				{fromUploadSheetPage === false && (
					<div className="run_payroll_dropDown_entries">
						<ETypo light b2 color="#484B52">
							Show
						</ETypo>
						<MultiSelect
							{...{
								Option: entriesDropDown,
								value: payrollPagination?.pagination?.pageSize || 1,
								dropDownValue: 'value',
								searchVisibility: false,
								onChange: (page) => handleTableChange(page?.value, 'pageSize'),
								checkboxIcon: (
									<span className="entries_check_box">
										<img src={icons.not_focus.tick} alt="check" />
									</span>
								),
							}}
						/>
						<ETypo light b2 color="#484B52">
							Entries
						</ETypo>
					</div>
				)}
				{fromUploadSheetPage === true && (
					<div>
						<EdenredButtons
							btnType="secondary"
							leftIcon={<ImageComponent src={not_focus_icons?.uploadSpreadsheet} />}
							onClick={() => {
								history.push({
									pathname: '/upload/salary/spreadsheet_or_sif',
									state: { payrollService: 'RUN-PAYROLL' },
								});
								ClevertapReact.event(CLEVER_TAP_EVENTS.Uploadexcel_PayrollTable);
							}}>
							Upload Spreadsheet
						</EdenredButtons>
					</div>
				)}
				{/* <Col
					className={`repeat-container ${tableShow ? 'repeat-container-selected' : 'repeat-container-unselected'} third-div`}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						ClevertapReact.event(CLEVER_TAP_EVENTS.repeatpreviouspayroll);
						setTableShow(!tableShow);
					}}>
					<ETypo b1 medium>
						Repeat Previous Payroll
						{tableShow ? (
							<DownOutlined onClick={() => loadPreviousPayroll()} style={{ paddingLeft: 8 }} />
						) : (
							<UpOutlined style={{ paddingLeft: 8 }} />
						)}
					</ETypo>
				</Col> */}
			</div>
		</Row>
	);
};

export default RunPayrolltableHeader;
