import React from 'react';
import EdenredTypo from '@sharedComponent/typography';
import Checkbox from '../Checkbox';

const DefaultCheckbox = () => {
	return (
		<div style={{ paddingTop: ' 10px' }}>
			<EdenredTypo h2 bold>
				Default Checkbox
			</EdenredTypo>
			<div style={{ display: 'flex' }}>
				<Checkbox
					tabIndex={3}
					borderColor="#00344E"
					borderRadius={7}
					size={22}
					label={<EdenredTypo b3>Flyers soft!</EdenredTypo>}
					name="sujai"
					onChange={(e) => console.log('dataa', e)}
				/>
				<Checkbox
					tabIndex={3}
					borderColor="#00344E"
					borderRadius={7}
					size={22}
					label={<EdenredTypo b3>Flyers soft!</EdenredTypo>}
					name="sujai"
					onChange={(e) => console.log('dataa', e)}
				/>
			</div>
		</div>
	);
};

export default DefaultCheckbox;
