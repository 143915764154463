import React from 'react';
import Modal from 'react-modal';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import { icons } from '@assets/icons/index';
import ImageComponent from '@sharedComponent/image-component';
import './modal.scss';
import { Link } from 'react-router-dom';

const EdenredModal = ({
	isOpen,
	className,
	afterOpenModal,
	closeModal,
	go_Back = 'Go Back',
	yes = 'Yes',
	user,
	content_web,
	content_mobile,
	delete_img,
	modalSubmit,
	getDeleterole,
	link,
	loading,
}) => {
	const disable = loading ? 'Yes disablebutton' : 'Yes singlebutton';
	return (
		<Modal isOpen={isOpen}  style={{zIndex:10}} className={className}>
			<Row className="Modal_Edenred">
				<Col xl={24} sm={24} xs={24} className="horizontal_mobile">
					<Col className="hori"></Col>
				</Col>
				{delete_img && (
					<Col xl={24} className="delete_modal">
						<ImageComponent src={icons.not_focus.Trash} />
					</Col>
				)}
				<Col xl={24} md={24} sm={24} xs={24} className="Remove_user">
					<EdenredTypo h5 bold className="user">
						{user}
					</EdenredTypo>
				</Col>
				<Col xl={24} md={24} sm={24} xs={24} className="user_login">
					<EdenredTypo h6>{content_web}</EdenredTypo>
				</Col>
				<Col sm={24} xs={24} className="user_login_mobile">
					<EdenredTypo h6>{content_mobile}</EdenredTypo>
				</Col>

				{/* web */}
				<Row className="Button_container">
					{go_Back && (
						<Col xl={12} md={12} xs={12} sm={12} onClick={afterOpenModal} className="Go_Back">
							{go_Back}
						</Col>
					)}
					{link ? (
						!loading ? (
							<Col
								xl={go_Back ? 12 : 24}
								md={go_Back ? 12 : 24}
								xs={go_Back ? 12 : 24}
								sm={go_Back ? 12 : 24}
								onClick={getDeleterole}
								className={go_Back ? `Yes` : 'Yes singlebutton'}>
								<Link to={link}>{yes}</Link>
							</Col>
						) : (
								<Col
									xl={go_Back ? 12 : 24}
									md={go_Back ? 12 : 24}
									xs={go_Back ? 12 : 24}
									sm={go_Back ? 12 : 24}
									onClick={getDeleterole}
									className={go_Back ? disable : 'Yes singlebutton'}>
									<span className="loader" />
								</Col>
							)
					) : !loading ? (
						<Col
							xl={go_Back ? 12 : 24}
							md={go_Back ? 12 : 24}
							xs={go_Back ? 12 : 24}
							sm={go_Back ? 12 : 24}
							onClick={getDeleterole}
							className={go_Back ? `Yes` : 'Yes singlebutton'}>
							{yes}
						</Col>
					) : (
								<Col
									xl={go_Back ? 12 : 24}
									md={go_Back ? 12 : 24}
									xs={go_Back ? 12 : 24}
									sm={go_Back ? 12 : 24}
									onClick={getDeleterole}
									className={go_Back ? disable : 'Yes singlebutton'}>
									<span className="loader" />
								</Col>
							)}
				</Row>

				{/* mobile */}
				<Row className="button_mobile_role">
					{go_Back && (
						<Col xl={12} md={12} xs={12} sm={12} className="gobackButton">
							<EdenredButton onClick={afterOpenModal} type="outline-g" style={{ fontWeight: 'bold' }}>
								{go_Back}
							</EdenredButton>
						</Col>
					)}
					<Col xl={go_Back ? 12 : 24} xs={go_Back ? 12 : 24} sm={go_Back ? 12 : 24} onClick={getDeleterole} className="YesButton">
						{link ? (
							!loading ? (
								<Link to={link}>
									<EdenredButton type="primary" style={{ fontWeight: 'bold' }}>
										{yes}
									</EdenredButton>
								</Link>
							) : (
									<EdenredButton type="primary" style={{ fontWeight: 'bold' }}>
										<span className="loader" />
									</EdenredButton>
								)
						) : !loading ? (
							<EdenredButton type="primary" style={{ fontWeight: 'bold' }}>
								{yes}
							</EdenredButton>
						) : (
									<EdenredButton type="primary" style={{ fontWeight: 'bold' }}>
										<span className="loader" />
									</EdenredButton>
								)}
					</Col>
				</Row>
			</Row>
		</Modal>
	);
};
export default EdenredModal;
