import React from 'react';
import { Card, Col, Row } from 'antd';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { DotLoader } from 'react-spinners';
import { icons } from '@assets/icons';
import BarChart from '../../bar-chart';

const MonthlySalaryProcessCard = ({ isPayrollAmountLoading, employeePaidByPayroll }) => {
	return (
		<Col xl={12} lg={24} md={24} sm={24} xs={24} className="payroll_amount_chart_card_column">
			<Card className="payroll_history_card">
				<Row className="payroll_history_card_title_row">
					<Col xl={1} lg={1} md={1} className="payroll_history_icon">
						<div className="payroll_content_div">
							<ImageComponent src={icons.focus_state.BankTransfer} />
						</div>
					</Col>
					<Col xl={8} lg={8} md={8} className="payroll_history_card_title payrollAm">
						<EdenredTypo style={{ color: '#181919' }}>Monthly Salary Processed</EdenredTypo>
					</Col>
				</Row>
				{isPayrollAmountLoading ? (
					<Col
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							minHeight: '120px',
						}}>
						<DotLoader size={50} color={'#e91d24'} loading />
					</Col>
				) : (
					<Col xl={24} lg={24} md={24} className="payroll_history_details_section">
						<BarChart payroll={employeePaidByPayroll} />
					</Col>
				)}
			</Card>
		</Col>
	);
};

export default MonthlySalaryProcessCard;
