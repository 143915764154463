import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import UploadPayrollPopup from 'src/utils/uploadPayrollPopup';
import selector from '../selector';
import action from '../action';
import SetupDocumentPresentational from './setup-document-presentational';
import './setupdocs.scss';
import ETypo from '@pages/DesignSystem/Typo';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

function SetupDoucumentFunctional({
	isEmployeeUploadLoading,
	isPayrollUploaded,
	EmployeeUploadDetails,
	employeePayrollTemplateSaga,
	employeePayrollUploadSaga,
	empDocumentUploadReset,
}) {
	const history = useHistory();
	const [setupPopup, setSetupPopup] = useState(false);
	const setUpUpload = [
		{
			description: '1',
			list: (
				<span className="spanAA">
					{/* <ETypo>

					</ETypo> */}
					Add your allowances and departments in
					<Link onClick={() => setSetupPopup(true)} className="forHereNew">
						Company Settings
					</Link>
					(before you download the spreadsheet)
				</span>
			),
		},
		{
			description: '2',
			list: (
				<span className="spanAA">
					Download the spreadsheet with the information of your existing employees
					<Link
						to="/setupDocument"
						onClick={() => {
							employeePayrollTemplateSaga();
						}}
						className="forHereNew">
						HERE.
					</Link>
				</span>
			),
		},
		{
			description: '3',
			list: <span className="spanA">Fill in your employees’ details and upload the spreadsheet below.</span>,
		},
	];
	const handleSetupSkipPopup = (value) => {
		if (value === 'handleSkipPopUp') {
			history.push('/company-setup');
		} else {
			setSetupPopup(false);
		}
	};
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
			{/* <UploadPayrollPopup
				{...{
					className: 'setup-company-popup',
					visible: setupPopup,
					okText: 'Company Settings',
					cancelText: 'Go Back',
					closePopup: () => handleSetupSkipPopup('closeSkipPopUp'),
					autoFixEmployeeFileError: () => handleSetupSkipPopup('handleSkipPopUp'),
					message: 'Check before you proceed',
					subMessage:
						'If you have departments in your company and allowances in your payroll, we recommend you go to Company Settings to configure Departments and Allowances.',
				}}
			/> */}
			<Edenredmodal
				title={'Check before you proceed'}
				desc={
					'If you have departments in your company and allowances in your payroll, we recommend you go to Company Settings to configure Departments and Allowances.'
				}
				isOpen={setupPopup}
				onOkay={() => handleSetupSkipPopup('handleSkipPopUp')}
				close={() => handleSetupSkipPopup('closeSkipPopUp')}
				onCancel={() => handleSetupSkipPopup('closeSkipPopUp')}
				onOkayButton={'Company Settings'}
				onCancelButton={'Go Back'}></Edenredmodal>
			<SetupDocumentPresentational
				{...{
					setUpUpload,
					isEmployeeUploadLoading,
					isPayrollUploaded,
					EmployeeUploadDetails,
					employeePayrollUploadSaga,
					empDocumentUploadReset,
				}}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		isEmployeeUploadLoading: selector.isEmployeeUploadLoading(state),
		EmployeeUploadDetails: selector.EmployeeUploadDetails(state),
		isPayrollUploaded: selector.isPayrollUploaded(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		employeePayrollTemplateSaga: () => dispatch(action.creators.employeePayrollTemplateStart()),
		employeePayrollUploadSaga: (file) => dispatch(action.creators.employeePayrollUploadStart(file)),
		empDocumentUploadReset: () => dispatch(action.creators.empDocumentUploadReset()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(SetupDoucumentFunctional);

export default Connected;
