import Action from './action';
import ClevertapReact from 'src/utils/clever-tap';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import React, { useState } from 'react';
import reducer from '@pages/NewHrModuleSetup/store/reducer';
import saga from '@pages/NewHrModuleSetup/store/rootSaga';
import Stepper from '../Component/Stepper/Stepper';
import Step from '../Component/Step/Step';
import SalaryMonthModal from './SalaryMonthModal/SalaryMonthModal';
import ToastAction from '@pages/DesignSystem/StatusToast/actions';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UploadDocument from './UploadDocument';
import { withReducer } from 'src/store/reducerLoader';
import PayrollService from 'src/services/payroll-service';
import moment from 'moment';
import './upload-payroll-documentation.scss';

const uploadType = [
	{
		id: 1,
		option: 'Option 1:',
		icons: focus_state_icons?.tableFrame,
		typeTitle: 'Payroll Table',
		typeDescription: "Enter your employees' data one by one into the payroll table.",
		btnText: 'Add On The Table',
	},
	{
		id: 2,
		option: 'Option 2:',
		icons: focus_state_icons?.excelFrame,
		typeTitle: 'Excel Spreadsheet',
		typeDescription: 'Easily upload data in bulk using the Excel template.',
		btnText: 'Add In bulk',
	},
];
const stepperItems = [
	{ name: '1 - Allowance', isActive: false, isCompleted: true, navigate: '/new-hr-features/allowance' },
	{ name: '2 - Additions', isActive: false, isCompleted: true, navigate: '/new-hr-features/addition' },
	{ name: '3 - Deductions', isActive: false, isCompleted: true, navigate: '/new-hr-features/deduction' },
	{ name: '4 - Upload Data (Optional)', isActive: true, isCompleted: false, navigate: '/new-hr-features/uploadPayrollDetails' },
];
const UploadPayrollDocumentation = () => {
	const _payrollService = new PayrollService();
	const [draggerStatus, setDraggerStatus] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [uploadShow, setUploadShow] = useState(true);
	const [showSalaryMonthModal, setShowSalaryMonthModal] = useState(false);
	const [uploadPayrollLoader, setUploadPayrollLoader] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { allowance, addition, deduction } = useSelector((state) => state?.hrSetup);
	const { employeeUploadDetails, isEmployeeUploadLoading, error, downloadTemplate } = useSelector((state) => state?.hrSetup?.employeePayrollUpload);
	const handleChange = (id) => {
		if (id === 1) {
			ClevertapReact.event('V1_1_Payrollupload_AddTable');
			dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeNotUpload: true }));
			dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeAllowanceStatus: true }));
			history.push('/payroll-plus');
		} else {
			ClevertapReact.event('V1_1_Payrollupload_AddBulk');
			setUploadShow(false);
		}
	};
	const handleUploadChange = (data) => {
		dispatch(Action.creators.employeePayrollUpload(data, false));
		setDraggerStatus(false);
	};
	const handleRemove = () => {
		setDraggerStatus(true);
		dispatch(Action.creators.empDocumentUploadReset());
	};
	const handleEdit = (text) => {
		if (text === 'Payrollupload') {
			ClevertapReact.event('V1_1_Payrollupload_edit');
		} else if (text === 'PayrollUploadBulk') {
			ClevertapReact.event('V1_1_PayrollUploadBulk_edit');
		}
		history.push('/new-hr-features/allowance');
	};
	const handleSave = () => {
		ClevertapReact.event('V1_1_PayrollUploadBulk_Save');
		if (employeeUploadDetails?.month === true) {
			setShowSalaryMonthModal(true);
		} else {
			dispatch(ToastAction.creators.putSuccessStatusToast({ message: 'Congrats! Your Automated Payroll Calculations have been setup!' }));
			dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeAllowanceStatus: true }));
			dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeNotUpload: false }));
			history.push('/payroll-plus');
		}
	};
	const handleCloseModal = () => {
		ClevertapReact.event('V1_1_PayrollUploadBulk_SavePopCancel');
		setShowSalaryMonthModal(false);
	};
	const UploadEmployeeData = (salaryMonthData) => {
		setUploadPayrollLoader(false);
		history.push({ pathname: '/payroll-plus', state: { salaryMonthData: salaryMonthData } });
	};
	const handleProceed = async (salaryMonthData) => {
		ClevertapReact.event('V1_1_PayrollUploadBulk_SavePopProceed');
		setUploadPayrollLoader(true);
		dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeAllowanceStatus: true }));
		dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeNotUpload: false }));
		try {
			const date = new Date(salaryMonthData?.salaryDate);
			const month = moment(date).format('MM');
			const year = moment(date).format('YYYY');
			const blobFileName = encodeURIComponent(employeeUploadDetails?.blobFileName);
			const res = await _payrollService.initialStaging(blobFileName, month, year);
			dispatch(ToastAction.creators.putSuccessStatusToast({ message: 'Congrats! Your Automated Payroll Calculations have been setup!' }));
			UploadEmployeeData(salaryMonthData);
		} catch (error) {
			console.log('11111111', error);
		}
	};
	const handleLater = () => {
		!uploadShow ? ClevertapReact.event('V1_1_PayrollUploadBulk_later') : ClevertapReact.event('V1_1_Payrollupload_later');
		setModalOpen(true);
	};
	return (
		<div className="upload-details-wrapper">
			<div className="content-container">
				<div className="title-wrapper">
					<section className="title-container">
						<div className="title">One last step: Upload your data</div>
						<span className="save-btn">
							<span>
								<EdenredButtons
									btnType="secondary"
									children="Do It Later"
									onClick={() => handleLater()}
									rightIcon={<ImageComponent src={not_focus_icons.arrowRight} />}
								/>
							</span>

							{draggerStatus === false &&
								isEmployeeUploadLoading === false &&
								!employeeUploadDetails?.validationMessage &&
								employeeUploadDetails != '' &&
								!error && (
									<span>
										<EdenredButtons btnType="primary" children="Save & Finished" onClick={() => handleSave()} />
									</span>
								)}
						</span>
						<Edenredmodal
							title="You didn’t add employees’ details"
							desc="You’ll be able to add it from the payroll table."
							onOkay={() => {
								ClevertapReact.event('V1_1_Payrollupload_PopAdd');
								setModalOpen(false);
							}}
							onCancelButton="Skip For Now"
							onOkayButton="Add Now"
							onCancel={() => {
								ClevertapReact.event('V1_1_Payrollupload_PopSkip');
								dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeNotUpload: true }));
								dispatch(Action.creators.setEmployeeUploadDocument({ isEmployeeAllowanceStatus: true }));
								history.push('/payroll-plus');
							}}
							isOpen={modalOpen}
							heart={true}
							imageSrc={focus_state_icons.warningNew}
						/>

						<SalaryMonthModal
							showModal={showSalaryMonthModal}
							handleCloseModal={() => handleCloseModal()}
							handleProceed={(date) => handleProceed(date)}
							loading={uploadPayrollLoader}
						/>
					</section>
					<section className="stepper-wrapper">
						<Stepper listOfTabs={stepperItems} />
					</section>
				</div>
				<div className="upload-wrapper">
					<section className="upload-content-container">
						{uploadShow === true && (
							<div className="upload-info-wrapper">
								<span className="info-content">
									<ImageComponent src={focus_state_icons?.deliveredSuccess} />
									<p className="upload-info">Your allowances, additions and deductions have been added!</p>
								</span>
								<span>
									<EdenredButtons
										btnType="secondary"
										className="edit-btn"
										children="Edit"
										onClick={() => handleEdit('Payrollupload')}
										leftIcon={<ImageComponent src={focus_state_icons?.edit} />}
									/>
								</span>
							</div>
						)}
						{uploadShow === true && (
							<div className="sub-info-wrapper">
								<span className="info-content">
									<ImageComponent src={focus_state_icons?.information} />

									<p className="description">
										Add your employees' basic salary, allowances, additions, and deductions with ease using one of the options
										below.
									</p>
								</span>
							</div>
						)}
						{/* Here we get two type of upload details one for manual data entry for employees basic allowance and other one for bulk
							upload of employees basic allowance.  */}
						{uploadShow ? (
							<div className="upload-type-wrapper">
								{uploadType?.map((details) => {
									return (
										<div className="upload-type">
											<div className="option">{details?.option}</div>
											<div className="section-type">
												<img src={details?.icons} alt="frame" className={details?.id === 2 ? `excel-icon` : `box-icon`}></img>
												<h5 className="type-title">{details?.typeTitle}</h5>
												<p className="type-description">{details?.typeDescription}</p>
												<span>
													<EdenredButtons
														btnType="secondary"
														children={details?.btnText}
														onClick={() => handleChange(details.id)}
														className="upload-btn"
													/>
												</span>
											</div>
										</div>
									);
								})}
							</div>
						) : (
							<div className="dragger-type-wrapper">
								{/* we click download button we get template for enter employees basic details. */}
								<Step
									stepNo={1}
									iconSrc={
										addition?.data != '' || deduction?.data != '' || allowance?.data != ''
											? focus_state_icons?.ClipboardText
											: focus_state_icons?.editNew
									}
									descriptionText={
										addition?.data != '' || deduction?.data != '' || allowance?.data != '' ? (
											<div className="edit-data">
												<p className="edit-data-title">Edit or add Allowances, Additions & Deductions.</p>
												<ul>
													<li className="allowanceData">
														Allowances:
														{allowance?.data?.allowanceList?.map((allowanceData) => (
															<> {allowanceData?.name},</>
														))}
													</li>
													<li className="allowanceData">
														Additions:
														{addition?.data?.map((additionData) => (
															<> {additionData?.name},</>
														))}
													</li>

													<li className="allowanceData">
														Deductions:
														{deduction?.data?.map((deductionData) => (
															<> {deductionData?.name},</>
														))}
													</li>
												</ul>
											</div>
										) : (
											"Add Allowances,Additions & Deductions whatever we don't care about copy"
										)
									}
									btnType="secondary"
									btnText="Edit"
									btnClassName="edit-btn-white"
									className={
										addition?.data != '' || deduction?.data != '' || allowance?.data != ''
											? 'description-background-color'
											: 'description-color'
									}
									btnLeftIcon={<ImageComponent src={focus_state_icons?.edit} />}
									btnOnClick={() => handleEdit('PayrollUploadBulk')}
								/>
								<Step
									stepNo={2}
									iconSrc={focus_state_icons?.documentDownload}
									descriptionText="Download the excel template and fill in your employees’ payroll details."
									btnType="secondary"
									btnText="Download template"
									btnClassName="download-btn"
									className="description-background-color"
									btnOnClick={() => {
										ClevertapReact.event('V1_1_PayrollUploadBulk_download');
										dispatch(Action.creators.getPayrollDownloadTemplate(false));
									}}
									isBtnLoading={downloadTemplate?.loading}
								/>
								{/* use to show the upload dragger while we click the bulk upload.if we upload the file we get success or error message. */}
								<div className="dragger-type">
									<div className="step">Step 3:</div>
									<div className="dragger-wrapper">
										<UploadDocument
											uploadData={employeeUploadDetails?.validationMessage || error || employeeUploadDetails == ''}
											uploadingStatus={isEmployeeUploadLoading}
											onChange={(data) => handleUploadChange(data)}
											draggerStatus={draggerStatus}
											onRemove={handleRemove}
										/>
									</div>
								</div>
							</div>
						)}
					</section>
				</div>
			</div>
		</div>
	);
};
const ReducedScreen = withReducer('hrSetup', reducer, saga)(UploadPayrollDocumentation);
export default ReducedScreen;
