import React from 'react';
import { useSelector } from 'react-redux';
import { withReducer } from 'src/store/reducerLoader';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import RunPayrollFuntional from './components/run-payroll-functional';
import reducer from '../../payroll-plus-new/reducer';
import sagas from '../../payroll-plus-new/sagas';

// import RunPayrolls from './components/RunPayroll';
import './run-payroll.scss';

const AuthorizedAccess = UnauthorizedAccess(RunPayrollFuntional);

const RunPayroll = () => {
	let currentClaims = useSelector((state) => state?.sharedStates?.currentUser?.claims);
	return (
		<AuthorizedAccess {...{ currentClaims, UserClaims: 'Manage.Payroll' }} />
		// <RunPayrolls />
	);
};

const ReducedScreen = withReducer('payrollPlusState', reducer, sagas)(RunPayroll);
export default ReducedScreen;
