import React from 'react';
import { MainRoutes } from 'src/routes';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { setUpPath } from '@constants/';
import AppLoader from '@sharedComponent/app-loader';
import './loading.scss';

const { Content } = Layout;

const LoadingPresentational = ({ corporateLoading }) => {
	let location = useLocation();
	const isMain = setUpPath.includes(location.pathname);

	if (corporateLoading) return <AppLoader />;
	return (
		<Layout className="mainroute_layout">
			<Content>
				<MainRoutes />
			</Content>
		</Layout>
	);
};

export default LoadingPresentational;
