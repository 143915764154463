import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';
import { ToastContainer, Zoom } from 'react-toastify';
import { Col, Row } from 'antd';
import { icons } from 'src/assets/icons';
import PayrollService from '../../../../../../services/payroll-service';
import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import EdenredModal from '@sharedComponent/modal';
import IframeComponent from '@sharedComponent/iframe-component';
import Popup from 'src/utils/popup/popups';

const EmpDocumentModal = ({
	closePreviewModal,
	deleteCleverTapEvents,
	url = null,
	blob = null,
	value,
	deleteImagesLoading,
	handleOpenDeleteModal,
	handleCloseDeleteModal,
	handleCloseDelete,
	deletePreview,
	emessage,
	viewPreviewModal,
	customStyles,
	view = 'desktop',
	buttonModalStyle = {
		display: 'none',
	},
}) => {
	const _payrollService = new PayrollService();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});

	const onDownloadClick = useCallback((e, url, blob) => {
		if (e.preventDefault) {
			e.preventDefault();
		}

		if (blob) {
			const link = document.createElement('a');
			link.href = blob;
			link.setAttribute('download', emessage);
			document.body.appendChild(link);
			link.click();
		}

		if (url) {
			const imageUrl = url;
			const encoded = encodeURIComponent(imageUrl);
			_payrollService
				.imageDownload(encoded)
				.then((response) => {
					const url = window.URL.createObjectURL(response.data);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${emessage}.${value}`);
					document.body.appendChild(link);
					link.click();
					link.remove();
				})
				.catch((error) => {
					return error;
				});
		}
	});

	const closePopup = () => {
		setpopupVisible(false);
	};

	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
			<Modal isOpen={viewPreviewModal} style={customStyles}>
				<Row style={{ display: 'flex' }}>
					{view === 'desktop' && (
						<Col style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
							<ImageComponent
								src={icons.not_focus.Close}
								style={{
									cursor: 'pointer',
									height: '25px',
									width: '25px',
									filter: 'invert(100%) sepia(90%) saturate(2%) hue-rotate(337deg) brightness(104%) contrast(101%)',
								}}
								onClick={closePreviewModal}
							/>
						</Col>
					)}
					<Col
						style={{
							backgroundColor: 'white',
							height: view === 'desktop' ? '70vh' : '435px',
							width: view === 'desktop' ? '415px' : '329px',
							minWidth: view === 'desktop' ? '415px' : '329px',
							overflow: 'auto',
						}}>
						<IframeComponent src={blob ? blob : url} height="100%" width="100%" />
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '10px 10px 0px 10px' }}>
						<EdenredButton
							type="outline-g"
							style={{
								backgroundColor: 'white',
								fontFamily: 'Montserrat-Regular',
								fontSize: '14px',
								color: '#00344E',
								borderRadius: '22px',
								minHeight: '32px',
								width: '254px',
								padding: '0',
							}}
							icon={<ImageComponent src={icons.not_focus.Download} style={{ height: '20px', width: '20px' }} />}
							onClick={(e) => onDownloadClick(e, url, blob)}>
							{`Download ${emessage}`}
						</EdenredButton>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '3px 10px 10px 10px' }}>
						<EdenredButton
							type="outline-g"
							style={{
								backgroundColor: 'white',
								fontFamily: 'Montserrat-Regular',
								fontSize: '14px',
								color: '#00344E',
								borderRadius: '22px',
								minHeight: '32px',
								width: '227px',
								padding: '0',
							}}
							icon={<ImageComponent src={icons.not_focus.Trash} style={{ height: '20px', width: '20px' }} />}
							onClick={handleOpenDeleteModal}>
							{`Delete ${emessage}`}
						</EdenredButton>
					</Col>
				</Row>
				<ToastContainer transition={Zoom} autoClose={true} />
			</Modal>
			<EdenredModal
				isOpen={deletePreview}
				getDeleterole={() => {
					handleCloseDeleteModal('', emessage);
					deleteCleverTapEvents();
				}}
				afterOpenModal={handleCloseDelete}
				go_Back="No"
				loading={deleteImagesLoading}
				className="modal_card_approve"
				user={`Delete ${emessage} ?`}
				content_web="This action  will remove the  picture"
				content_mobile="This action  will remove the  picture"
			/>
		</>
	);
};

export default EmpDocumentModal;
