import Actions from './action';

export const initState = {
	uploadLoading: false,
	documentUpload: [],
	uploadError: '',
	templateLoading: false,
	templateDowload: [],
	templateError: '',
	isDocUploaded: 'loading',
};

const uploadReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SAVE_DOCUMENT_UPLOAD_LOADING: {
			return {
				...state,
				uploadLoading: true,
				isDocUploaded: 'loading',
			};
		}
		case Actions.types.SAVE_DOCUMENT_UPLOAD_SUCCESS: {
			let isErrorData = 'yes';
			if (action.response && action.response.validationMessage) {
				isErrorData = 'yes';
			} else {
				isErrorData = 'no';
			}
			return {
				...state,
				uploadLoading: false,
				documentUpload: action.response,
				isDocUploaded: isErrorData,
			};
		}

		case Actions.types.SAVE_DOCUMENT_UPLOAD_FAIL: {
			return {
				...state,
				uploadLoading: false,
				uploadError: action.error,
				isDocUploaded: false,
			};
		}
		case Actions.types.SAVE_EMP_TEMPLATE_LOADING: {
			return {
				...state,
				templateLoading: true,
			};
		}
		case Actions.types.SAVE_EMP_TEMPLATE_SUCCESS: {
			return {
				...state,
				templateLoading: false,
				templateDowload: action.response,
			};
		}
		case Actions.types.SAVE_EMP_TEMPLATE_FAIL: {
			return {
				...state,
				uploadLoading: false,
				templateError: action.error,
			};
		}
		case Actions.types.SAVE_DOCUMENTUPLOAD_EMPLOYEE_RESET: {
			return {
				...state,
				uploadLoading: false,
				documentUpload: [],
				isDocUploaded: 'loading',
			};
		}
		default:
			return state;
	}
};

export default uploadReducer;
