const GET_DOWNLOAD_PDF_START="GET_DOWNLOAD_PDF_START"
const GET_DOWNLOAD_PDF_SUCCESS="GET_DOWNLOAD_PDF_SUCCESS"
const GET_DOWNLOAD_PDF_FAIL="GET_DOWNLOAD_PDF_FAIL"

const getDownloadPdfStart=(payload)=>({
    type:GET_DOWNLOAD_PDF_START,
    payload
})
const getDownloadPdfSuccess=(res)=>({
    type:GET_DOWNLOAD_PDF_SUCCESS,
    res
})
const getDownloadPdfFail=(err)=>({
    type:GET_DOWNLOAD_PDF_FAIL,
    err
})

export default {
	types: {
        GET_DOWNLOAD_PDF_START,
        GET_DOWNLOAD_PDF_SUCCESS,
        GET_DOWNLOAD_PDF_FAIL,
	},
	creators: {
        getDownloadPdfStart,
        getDownloadPdfSuccess,
        getDownloadPdfFail
	},
};