import React from 'react';
import { Row, Col, Form } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredHeader from '@pages/main-layout/components/main-header';
import Input from '@sharedComponent/text-field';
import EdenredButton from '@sharedComponent/button';
import { icons } from 'src/assets/icons';
import EdenredDropdown from '@sharedComponent/dropdown/dropdown';

const IndividualEmployeePresentational = ({ form, onSubmitEmployeeDetails }) => {
	const national = [
		{ index: 0, label: 'Indian' },
		{ index: 1, label: 'American' },
		{ index: 2, label: 'Japanese' },
		{ index: 3, label: 'Canadian' },
		{ index: 4, label: 'Spanish' },
	];
	return (
		<>
			<EdenredHeader />
			<Row className="individual_employee_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="individual_employee_title">
					<EdenredTypo>Enter The Following Details:</EdenredTypo>
				</Col>

				<Col xl={22} lg={22} md={22} sm={24} xs={24} className="individual_employee_details_form_section">
					<Form name="employeebasicdetails" form={form} onFinish={onSubmitEmployeeDetails} initialValues={{ remember: true }}>
						<Row className="individual_employee_details_form_items_row">
							<Form.Item
								name="firstName"
								rules={[
									{ required: true, message: 'Please provide the first name of the employee' },
									{
										max: 25,
										message: 'Max length should be 25',
									},
								]}>
								<Input placeholder="Mohammad" type="text" labels="First Name" labelClassName="inputlabelrequired" />
							</Form.Item>
							<Form.Item
								name="lastName"
								rules={[
									{ required: true, message: 'Please provide the last name of the employee' },
									{
										max: 25,
										message: 'Max length should be 25',
									},
								]}>
								<Input placeholder="Ahamad" type="text" labels="Last Name" labelClassName="inputlabelrequired" />
							</Form.Item>
						</Row>
						<Row className="individual_employee_details_form_items_row">
							<Form.Item name="dateOfBirth" rules={[{ required: true, message: 'Please provide the Date of Birth of the employee' }]}>
								<Input placeholder="DD/MM/YYYY" labels="Date of Birth" labelClassName="inputlabelrequired" type="date" />
							</Form.Item>
							<Form.Item name="nationality" rules={[{ required: true, message: 'Please provide the Nationality of the employee' }]}>
								<EdenredDropdown type="dropdownfield" labels="Nationality" options={national} />
							</Form.Item>
						</Row>
						<Row className="individual_employee_details_form_items_column">
							<Form.Item
								name="mobileNumber"
								rules={[
									{
										max: 10,
										message: 'Max length should be 10',
									},
								]}>
								<Input
									maxLength="10"
									placeholder="5XXXXXXXX"
									labels="Mobile Number"
									type="number"
									countryCodeClassName="mobile_number_input"
									countryCode={{ icon: icons.countries.UnitedArabEmirates, code: '+971' }}
								/>
							</Form.Item>
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										className="employee_details_next_button"
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}
										htmlType="submit">
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Row>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default IndividualEmployeePresentational;
