import { Col } from 'antd';
import React from 'react';
import { DotLoader } from 'react-spinners';

const ContentLoader = () => {
	return (
		<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
			<DotLoader size={50} color={'#e91d24'} loading />
		</Col>
	);
};

export default ContentLoader;
