import mfaActions from "./mfaActions";

const initialState = {
    mfa: {
        inProgress: false,
        mode: null,
        phoneNumber: null,
        loading: false,
    },
    otp: {
        loading: false,
        sent: false,
        inProgress: false,
        status: null
    },
    error: {}
}

const mfaReducer = (state = initialState, action) => {
    switch (action.type) {
        case mfaActions.types.MFA_LOADING:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    loading: action.payload
                }
            }
        case mfaActions.types.MFA_PROCESSING:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    inProgress: action.payload
                }
            }
        case mfaActions.types.SET_MFA_RESPONSE:
            return {
                ...state,
                mfa: {
                    ...state.mfa,
                    ...action.payload
                }
            }
        case mfaActions.types.SET_OTP_RESPONSE:
            return {
                ...state,
                otp: {
                    ...state.otp,
                    sent: action.payload.sent,
                    ...action.payload
                }
            }
        case mfaActions.types.CLEAR_OTP_RESPONSE:
            return {
                ...state,
                otp: initialState.otp
            }
        case mfaActions.types.CLEAR_MFA_RESPONSE:
            return {
                ...state,
                mfa: initialState.mfa
            }
        case mfaActions.types.OTP_LOADING:
            return {
                ...state,
                otp: {
                    ...state.otp,
                    loading: action.payload
                }
            }
        case mfaActions.types.VERIFY_OTP:
            return {
                ...state,
                otp: {
                    ...state.otp,
                    inProgress: action.payload
                }
            }
        case mfaActions.types.VERIFY_UPDATE_PHONE_NUMBER:
            return {
                ...state,
                otp: {
                    ...state.otp,
                    ...action.payload
                }
            }
        case mfaActions.types.MFA_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    mfa: action.payload
                }
            }
        case mfaActions.types.UPDATE_PHONE_NUMBER_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    updatePhoneNumber: action.payload
                }
            }
        case mfaActions.types.CLEAR_ERROR:
            return {
                ...state,
                error: {}
            }
        default:
            return state;
    }

}

export default mfaReducer