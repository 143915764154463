import EdenRedHeader from '@pages/NewHrModuleSetup/Component/EdenRedHeader/EdenRedHeader';
import React from 'react';
import InviteEmployees from './InviteEmployees';
import '../Departments/index.scss';

const InviteEmployeesPage = () => {
	return (
		<main className="wrapper">
			<EdenRedHeader />
			<div className="main-container">
				<InviteEmployees CleverTap={false} />
			</div>
		</main>
	);
};

export default InviteEmployeesPage;
