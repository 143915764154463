import React from 'react';
import IndividualEmployeeFunctional from './components/individual-employee-functional';
import './individual-employee.scss';
import './individual-employee-mobile.scss';

const IndividualEmployee = () => {
	return <IndividualEmployeeFunctional />;
};

export default IndividualEmployee;
