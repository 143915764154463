import React, { useState, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import DateRangePicker from './date-range-picker';
import './date-picker.scss';

const DatePickers = ({
	className = '',
	label = '',
	disabled = false,
	value = '',
	defaultValue = '',
	onFocus,
	onBlur,
	onChange,
	onKeyDown,
	placeholder = 'Select Date',
	dateFormat = 'd/m/Y',
	dateMode = 'single',
	maxDate,
	minDate,
	isErrorMessage = false,
	errorMessage = 'Invalid Date',
	rangeDateActivePlaceHolder = '',
	rangeDateFromPlaceHolder = 'From',
	rangeDateToPlaceHolder = 'To',
	dateCalender = 'calender',
	showDatesIcon = true,
	dateOption,
	required = false,
	dateFormatFlatPicker,
}) => {
	const options = {
		allowInput: true,
		dateFormat: dateFormat,
		maxDate: maxDate,
		minDate: minDate,
		disableMobile: true,
		mode: dateMode === 'range' ? 'range' : 'single',
		...dateOption,
	};
	const fp = useRef(null);

	return (
		<>
			{dateMode === 'single' ? (
				<div className="date_single_picker">
					{label && (
						<p className="date_label">
							{label}
							{required && <div style={{ color: '#F72717', marginLeft: '4px' }}>*</div>}
						</p>
					)}
					<div
						className={`date_picker_main ${className} ${disabled ? 'disabled_date' : 'active_date_field'} ${
							value ? 'non_empty_date_field' : 'empty_date_field'
						} ${isErrorMessage ? 'date_error_field' : ''}`}>
						{showDatesIcon && <img alt="" className="date_picker_calender_icon" src={not_focus_icons?.payrollCalender} />}
						<Flatpickr
							style={{
								display: 'flex',
								position: 'absolute',
								// left: '32px',
								// right: '0px',
								zIndex: '5',
								left: '0px',
								background: 'transparent',
								cursor: 'pointer',
								// width: '462px',
								paddingLeft: '33px',
								//////
							}}
							{...{
								disabled,
								options,
								onFocus,
								onBlur,
								value,
								onChange,
								onKeyDown,
								placeholder,
								defaultValue,
								mode: 'single',
							}}></Flatpickr>
						{showDatesIcon && <img style={{ position: 'absolute', right: '9px' }} className="arrow-inner" src={not_focus_icons.down} />}
					</div>
					{isErrorMessage && <p className="date_field_error_message">{errorMessage}</p>}
				</div>
			) : (
				<DateRangePicker
					{...{
						className,
						label,
						disabled,
						value,
						onFocus,
						onBlur,
						onChange,
						onKeyDown,
						isDateError: isErrorMessage,
						dateErrorMessage: errorMessage,
						options,
						rangeDateActivePlaceHolder,
						rangeDateFromPlaceHolder,
						rangeDateToPlaceHolder,
						defaultValue,
						dateMode,
						dateCalender,
						required,
						dateFormatFlatPicker,
					}}
				/>
			)}
		</>
	);
};
export default DatePickers;
