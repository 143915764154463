import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EdenredTypo from '@sharedComponent/typography';
import EdenRedCard from '@sharedComponent/card';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import PayrollService from 'src/services/payroll-service';
import ContentLoader from '@sharedComponent/content-loader';
import { CLEVER_TAP_EVENTS } from '@constants/app-constants';
import PayrollBlock from '@sharedComponent/payroll-block';
import Actions from '@sharedComponent/app-action-reducer/action';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ClevertapReact from 'src/utils/clever-tap';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Toast from '@pages/DesignSystem/Toast';

const ApproveRequestsPresentational = ({ blockedData }) => {
	const [checked, setChecked] = useState(false);
	const [modalopen, setModalopen] = useState(false);
	const [modalopenMobile, setModalopenMobile] = useState(false);
	const [approveModal, setApproveModal] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const dispatch = useDispatch();
	const _payrollService = new PayrollService();
	const rejectOrApprove = useRef('');

	useEffect(() => {
		getrunPayRollValues();
	}, []);

	const [payrollruns, setpayrollruns] = useState([]);
	const [isSelectedCount, setisSelectedCount] = useState(0);

	const viewDetailsClicked = (item) => {
		let uploadId = (item && item?.uploadId) || '1'; // TODO - GET UPLOAD ID FROM THIS ITEM
		// TODO - NEED TO CHECK PUSH BASED ON WPS /NON WPS
		// history.push({ pathname: '/payroll/nonwps_table', state: { uploadId } });
		history.push({ pathname: '/payroll/wps_table', state: { uploadId } });
	};

	const isSelectClicked = (value) => {
		let data = payrollruns || [];
		data.map((val, ind) => {
			if (ind === value?.index) {
				val.isSelect = !value?.isSelect;
			}
			return val;
		});
		let isSelected = data?.filter((val) => val?.isSelect); // to SHOW the select all modal
		if (isSelected && isSelected?.[0]) {
			if (isSelected?.length === data?.length) {
				setisSelectAll(true);
			}
			setModalopen(true);
			setModalopenMobile(true);
		} else {
			setisSelectAll(false);
			setModalopen(false);
			setModalopenMobile(false);
		}
		setisSelectedCount(isSelected?.length || 0);
		setpayrollruns([...data]);
	};

	const handlemodalopen = () => {
		setChecked(!checked);
		setModalopen(!modalopen);
		setModalopenMobile(!modalopenMobile);
	};
	const showModal = async (content, type) => {
		rejectOrApprove.current = type;
		if (type === 'Reject') {
			ClevertapReact.event('Reject_Payroll');
			ClevertapReact.event('RejectSelected_Payroll');
		} else if (type === 'Approve') {
			ClevertapReact.event('Approve_Payroll');
		} else if (type === 'RejectSelect') {
			ClevertapReact.event('RejectSelected_Payroll');
		}
		let data = (await payrollruns) || [];
		data.map((val, ind) => {
			if (ind === content?.index && content?.isSelect === false) {
				val.isSelect = true;
			}
			return val;
		});
		await setpayrollruns([...data]);
		await setReviewStatus(type);
		await setApproveModal(true);
		await setModalopenMobile(false);
	};
	const afterOpenModal = () => setApproveModal(false);
	let history = useHistory();

	// TODO - PLEASE USE ENUMS OR CONST VARIABLE
	const [reviewStatus, setReviewStatus] = useState('');
	const [loading, setloading] = useState(false);
	const [loadingData, setloadingData] = useState(true);

	const updateRunPayrollReview = async () => {
		let data = (await payrollruns) || [];
		let selectedData = (await data?.filter((val) => val?.isSelect)) || [];
		let payload = selectedData?.map((content) => {
			return {
				Amount: content?.total_amount,
				MonthYearType: content?.month_payroll,
				['No of employees']: content?.employees,
				['Submitted time']: content?.submitted_date,
			};
		});
		let payrollSelectedRuns = await [];
		await selectedData.forEach((val) => {
			payrollSelectedRuns.push(val?.uploadId); // TODO - PUSH SELECTED ID / USE uploadId ATTRUBUTE
		});
		let body = await {
			payrollAction: reviewStatus, // TODO - WHEN CLICK REJECT OR APPROVE BUTTON YOU NEED TO SET REIVEW STATUS BEFORE CALLING ANY METHOD
			payrollSelectedRuns: payrollSelectedRuns,
		};
		setloading(true);
		_payrollService
			.approvePayrollReview(body)
			.then((res) => {
				if (res) {
					setPopupMessage({
						titleMessage: `${reviewStatus === 'Approve' ? 'Approved Successfully' : 'Rejected Successfully'}`, // TODO - DONT GIVE MSG CONTENT DIRECTLY DIRECTLY ITS SHOULD BE COME FROM CONST IN SOMEWHERE
						subMessage: null,
					});
					setloading(false);
					setApproveModal(false);
					setisSelectedCount(0);
					setpopupVisible(true);
					if (reviewStatus === 'Approve' || reviewStatus === 'ApproveSelect') {
						ClevertapReact.event(CLEVER_TAP_EVENTS.ApproveSelected_Payroll, payload);
					}
					dispatch(Actions.creators.getPendingStatusStart());
					setModalopen(false);
					setModalopenMobile(false);
				} else {
					setApproveModal(false);
					setloading(false);
				}
				getrunPayRollValues();
			})
			.catch((err) => {
				setApproveModal(false);
				setloading(false);
			});
	};

	const getrunPayRollValues = () => {
		_payrollService
			.getApprovePayrollList()
			.then((getApprovePayrollListres) => {
				setloadingData(false);
				// TODO - SET - setpayrollruns METHOD HERE...
				if (getApprovePayrollListres && getApprovePayrollListres?.data && getApprovePayrollListres?.data?.payrollRunModels) {
					let resArr = [];
					let data =
						getApprovePayrollListres?.data &&
						getApprovePayrollListres?.data?.payrollRunModels?.map((val, ind) => {
							let obj = {};
							let submit_date = val?.payrollRunDetail?.submittedOn.split('on');
							obj.month_payroll =
								val?.payrollRunDetail?.monthYearAccountType + ' - ' + val?.payrollRunDetail?.payrollChannel + ' Payroll';
							obj.submitted_date =
								submit_date && submit_date?.length > 0 ? 'Payroll submitted on' + submit_date?.[1] + ' at ' + submit_date?.[0] : '';
							obj.employees = val?.payrollRunDetail?.numberOfEmployees + ' ' + 'Employees';
							obj.total_amount = val?.payrollRunDetail?.totalAmount;
							obj.path = '';
							obj.uploadId = val?.payrollRunDetail?.uploadId;
							obj.isSelect = false;
							obj.index = ind;
							resArr.push(obj);
						});
					setpayrollruns(resArr);
				} else {
					setpayrollruns([]);
				}
				// dummy(); // TODO - PLEASE REMOVE IT
			})
			.catch((error) => {
				setloadingData(false);
			});
	};

	const [isSelectAll, setisSelectAll] = useState(false);

	const isSelectedAllClicked = async () => {
		let isdata = await isSelectAll;
		let data = (await payrollruns) || [];
		if (isdata) {
			await data?.map((val) => (val.isSelect = false));
			await setisSelectedCount(0);
			await setModalopen(false);
		} else {
			await data.map((val) => (val.isSelect = true));
			await setisSelectedCount(data?.length || 0);
			await setModalopen(true);
		}
		await setisSelectAll(!isSelectAll);
		await setpayrollruns([...data]);
	};
	const closePopup = () => {
		setpopupVisible(false);
	};

	return (
		<>
			<Toast width={'30px'} color="#8EF9B3" textcolor="#484B52" visible={popupVisible} clearPopUp={closePopup}>
				{popupMessage.titleMessage}
			</Toast>
			<EdenredHeader />
			{blockedData ? (
				<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start' }}>
					<PayrollBlock corporateMessage={blockedData?.corporateMessage} suspensionMessage={blockedData?.suspensionMessage}></PayrollBlock>
				</div>
			) : loadingData ? (
				<ContentLoader />
			) : (
				<Row style={{ width: '100%', alignSelf: 'flex-start' }}>
					<Row className="approve_requests_container">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="approve_requests_title">
							<div className="Title-name-and-select-section-style">
								<ETypo h2 bold className="title-font-color">
									Approve payroll
								</ETypo>

								<ETypo b1 medium className="select-all-section" onClick={isSelectedAllClicked}>
									{isSelectAll || isSelectedCount === payrollruns?.length ? 'Deselect All' : 'Select All'}
								</ETypo>
							</div>
							<div className="button-section">
								<EdenredButtons
									{...{
										btnType: 'secondary',
										disabled: isSelectedCount === 0,
										onClick: () => showModal('', 'Reject'),
									}}>
									Reject Selected ({isSelectedCount || 0})
								</EdenredButtons>
								<EdenredButtons
									{...{
										btnType: 'primary',
										style: { marginLeft: '8px' },
										disabled: isSelectedCount === 0,
										onClick: () => showModal('', 'Approve'),
									}}>
									Approve Selected ({isSelectedCount || 0})
								</EdenredButtons>
							</div>
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="approve_requests_card_section">
							{payrollruns && payrollruns?.length > 0 ? (
								payrollruns?.map((payrollRun, index) => (
									<Col className="approve_requests_card_row">
										<EdenRedCard
											{...{
												type: 'ApproveRequests',
												key: index,
												content: payrollRun,
												className: 'approve_requests_payroll_card',
												handlecheckbox: handlemodalopen,
												onClickApprove: showModal,
												isSelectClicked,
												viewDetailsClicked,
												isSelectedCount,
											}}
										/>
									</Col>
								))
							) : (
								<div style={{ textAlign: 'center', color: '#ddd', fontWeight: 600 }}>No records found</div>
							)}
						</Col>
					</Row>
					{modalopenMobile && (
						<Row className="modal_approve_request_mobile_View">
							<Col sm={24} xs={24} className="payroll_run_header">
								<ETypo h2 bold className="heading-font">
									Approve payroll
								</ETypo>
							</Col>
							<Col sm={24} xs={24} className="payroll_run_edenredtypo">
								<EdenredTypo h6 bold>
									{isSelectedCount || 0} Selected.
								</EdenredTypo>
							</Col>
							<Col sm={24} xs={24} className="reject_button">
								<Col sm={12} xs={12} style={{ fontWeight: 'bold', padding: '2%' }}>
									<EdenredButton
										style={{ fontWeight: 'bold' }}
										loading={loading}
										type="outline-g"
										onClick={() => showModal('', 'Reject')}>
										Reject
									</EdenredButton>
								</Col>
								<Col sm={12} xs={12} style={{ fontWeight: 'bold', padding: '2%' }}>
									<EdenredButton style={{ fontWeight: 'bold' }} loading={loading} onClick={() => showModal('', 'Approve')}>
										Approve
									</EdenredButton>
								</Col>
							</Col>
							<Col sm={24} xs={24} className="payroll_run">
								<EdenredButton type="link" onClick={isSelectedAllClicked}>
									{isSelectAll || isSelectedCount === payrollruns.length
										? 'Deselectall All Payroll Runs'
										: 'Select All Payroll Runs'}
								</EdenredButton>
							</Col>
						</Row>
					)}
					<Edenredmodal
						{...{
							title: `Do you want to ${reviewStatus} this payroll
							submission?`,
							desc: rejectOrApprove.current !== 'Reject' ? 'Payroll will run immediately' : '',
							onOkay: updateRunPayrollReview,
							className: 'pop-status-modal',
							onCancel: afterOpenModal,
							isOpen: approveModal,
							close: afterOpenModal,
							textcenter: 'center',
							loading,
						}}
					/>
				</Row>
			)}
		</>
	);
};

export default ApproveRequestsPresentational;
