import React from 'react';
import { useSelector } from 'react-redux';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import { withReducer } from 'src/store/reducerLoader';
import PayrollPlusNewFunctional from './components/payroll-plus-new-functional';
import './payroll-plus-new.scss';
import reducer from './reducer';
import sagas from './sagas';

const AuthorizedAccess = UnauthorizedAccess(PayrollPlusNewFunctional);
const PayrollPlusNew = () => {
	let currentClaims = useSelector((state) => state?.sharedStates?.currentUser?.claims);
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser?.roles[0]?.name);
	return <AuthorizedAccess {...{ currentUser, currentClaims, UserClaims: 'Manage.Payroll' }} />;
};

// const ReducedScreen = withReducer('payrollPlusState', reducer, sagas)(PayrollPlusNew);
export default PayrollPlusNew;
