import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmployeeService from 'src/services/employee-service';
import EmpoyeeLeaveRequestPresentational from './presentational';

const EmployeeLeaveRequestFunctional = () => {
	let employeeApi = new EmployeeService();
	const emp_Id = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeCode);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [employeeDetails, setEmployeeDetails] = useState({});
	useEffect(() => {
		emp_Id && getEmployeeById(emp_Id);
	}, []);
	async function getEmployeeById(emp_Id) {
		setIsDataLoading(true);
		try {
			let response = await employeeApi.getEmployeeById(emp_Id);
			if (response && response.data) {
				setIsDataLoading(false);
				let finalResp = { ...response.data.employee };
				setEmployeeDetails(finalResp);
			} else {
				setEmployeeDetails();
			}
		} catch (err) {}
		setIsDataLoading(false);
	}
	return <EmpoyeeLeaveRequestPresentational {...{ employeeDetails, isDataLoading }} />;
};

export default EmployeeLeaveRequestFunctional;
