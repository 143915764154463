import React from 'react';
import { Col } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import AccordianPresentation from '@pages/DesignSystem/Accordian/accordian-presentaion';
import ItemHeader from '@pages/help/components/ItemHeader';
import './wps-rules.scss';

const WpsRules = () => {
	const faq = [
		{
			question: 'What is WPS?',
			answer: 'The Wage Protection System was launched by the UAE government in 2009. The system was launched with the sole aim of protecting all employees working in the UAE by ensuring they receive their payments on time.',
			id: 0,
			isActive: false,
		},
		{
			question: 'What companies are required to comply with WPS regulations?',
			answer: '	All companies registered under the following authorities are required to comply with WPS regulations',
			id: 1,
			isActive: false,
		},

		{
			question: 'What are the key rules that companies regulated by WPS must follow ?',
			answer: 'Pay atleast 80% of your employees each month,Pay atleast 70% of the salary	mentioned on the contract through WPS',
			id: 2,
			isActive: false,
		},
		{
			question: 'How do I find the WPS details of my employees?',
			answer: 'WPS Establishment ID:Also known as the company code, MoL ID or establishment number, the WPS Establishment ID is unique for each trade license. You can log-in to eNetwasal - MOHRE (https://eservices.mohre.gov.ae/enetwasal/login.aspx?lang=eng) and view your company code',
			id: 3,
			isActive: false,
		},
	];
	return (
		<>
			<EdenredHeader />
			<Col className="wpsrule-presentational ">
				<ItemHeader title="WPS Rules" />
				<Col className="questions" xl={24} lg={24} md={24} sm={24} xs={24}>
					<AccordianPresentation questions={faq} />
				</Col>
			</Col>
		</>
	);
};
export default WpsRules;
