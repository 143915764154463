import React from 'react';
import './payroll-block.scss';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import { Button, Col, Input, Row, Spin, Table } from 'antd';
const PayrollBlock = ({ corporateMessage, suspensionMessage }) => {
	return (
		<Col xl={24} className="payroll_warning">
			<Row justify="center">
				<Col xl={13} lg={15} md={13}>
					<Row>
						<Col xl={2} sm={24} xs={24} md={2} className="alert-image">
							<ImageComponent src={icons.focus_state.Warning} className="c" />
						</Col>
						<Col xl={22} sm={24} xs={24} md={22} className="description">
							<span className="coporate-message">{corporateMessage}</span>
						</Col>
						<Col xl={24} sm={24} xs={24} md={22} className="timestamp">
							<Col>
								<span>{suspensionMessage}</span>
							</Col>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default PayrollBlock;
