import Actions from '../action';
import Axios from 'axios';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { templateDownload } from '@helpers';
import ClevertapReact from 'src/utils/clever-tap';

export function* downloadTemplateService() {
	let request = {
		documentTypeFilter: null,
		employeeIdNameFilter: '',
		expiryDateFilter: null,
		pageNumber: 0,
		pageSize: 0,
		reportType: 'excel',
	};
	yield put(Actions.creators.putDownloadTemplateLoading(true));
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/Data/Download`;
		const response = yield call(Axios.post, uri, request, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.getDownloadTemplateData(response.data));
		yield put(Actions.creators.putDownloadTemplateLoading(false));

		templateDownload(response.data, 'Template.xls');
	} catch (error) {
		yield put(Actions.creators.getDownloadTemplateError(error));
		yield put(Actions.creators.putDownloadTemplateLoading(false));
	}
}
export function* uploadData(action) {
	yield put(Actions.creators.putUploadLoading(true));
	let formData = new FormData();
	formData.append('file', action.payload);
	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/EmiratesDocument/${corporateId}/data/upload`;
		const response = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.putUploadLoading(false));
		yield put(Actions.creators.putUploadValidationMessage(response.data));
		ClevertapReact.event(
			'V1_1_Docs_UploadBulk_upload',
			response.data.ValidationMessage === '' ? 'valid' : { ...response.data.ValidationMessage }
		);
	} catch (error) {
		yield put(Actions.creators.putUploadLoading(false));
		yield put(Actions.creators.putUploadError(error));
	}
}

export default function* documentsExpiryWatcher() {
	yield takeLatest(Actions.types.GET_DOWNLOAD_TEMPLATE, downloadTemplateService);
	yield takeLatest(Actions.types.POST_UPLOAD, uploadData);
}
