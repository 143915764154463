import React from 'react';
import './Switch.scss';

const Switch = ({ isOn = false, handleToggle = () => {}, leftText, rightText, disabled = false }) => {
	return (
		<div className="react_switch_main">
			{leftText && leftText}
			<div className={`react_switch ${isOn && 'react_switch_on'}`} onClick={!disabled && handleToggle}>
				<div className="react_switch_ball" />
			</div>
			{rightText && rightText}
		</div>
	);
};

export default Switch;
