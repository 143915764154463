import React from 'react';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './saga/index';
import AddIndividualEmpDocumentFunctional from './component/add-individual-emp-document-functional';

const AddIndividualEmpDocument = () => {
	return <AddIndividualEmpDocumentFunctional />;
};

const ReducedScreen = withReducer('documentReducer', reducer, saga)(AddIndividualEmpDocument);
export default ReducedScreen;
