import React from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { OutStandingInvoiceBanner } from '@sharedComponent/banner';
import { UnemploymentInsuranceBanner } from '../unemployment-insurance-banner';
import { AmountBalanceCard, GaugeChartsCard, CutOffTimingsCard } from '../cards';

const DashboardFirstSection = ({
	currentUser,
	employeeCount,
	isEmployeeCountLoading,
	corporateDetails,
	setShowUnemploymentInsuAvoid,
	invoiceFlag,
}) => {
	const { unEmploymentBannerData } = useSelector((state) => state?.sharedStates) || {};

	return (
		<section className="main_dashboard_web_first_row fade-right">
			{invoiceFlag && <OutStandingInvoiceBanner />}
			{unEmploymentBannerData?.data?.displayBanner ? (
				<div style={{ marginBottom: '15px' }}>
					<UnemploymentInsuranceBanner {...{ setShowUnemploymentInsuAvoid }} />
				</div>
			) : null}
			<Row>
				{/* Amount balance card */}
				<AmountBalanceCard />
				{/* gauge chart card */}
				<GaugeChartsCard {...{ employeeCount, isEmployeeCountLoading }} />
				{/* cut-off timings card */}
				<CutOffTimingsCard {...{ currentUser, corporateDetails }} />
			</Row>
		</section>
	);
};

export default DashboardFirstSection;
