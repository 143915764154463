const GET_CURRENTLYLEAVE_START = 'GET_CURRENTLYLEAVE_START';
const GET_CURRENTLYLEAVE_SUCCESS = 'GET_CURRENTLYLEAVE_SUCESS';
const GET_CURRENTLYLEAVE_FAIL = 'GET_CURRENTLYLEAVE_FAIL';

const getCurrenlyLeaveStart = (payload) => {
	return {
		type: GET_CURRENTLYLEAVE_START,
		payload,
	};
};
const getCurrenlyLeaveSuccess = (response) => {
	console.log('getCurrenlyLeaveSuccess');
	console.log('response', response);
	return {
		type: GET_CURRENTLYLEAVE_SUCCESS,
		response,
	};
};
const getCurrenlyLeaveFail = (error) => {
	console.log('getCurrenlyLeaveFail');
	return {
		type: GET_CURRENTLYLEAVE_FAIL,
		error,
	};
};
export default {
	types: {
		GET_CURRENTLYLEAVE_START,
		GET_CURRENTLYLEAVE_SUCCESS,
		GET_CURRENTLYLEAVE_FAIL,
	},
	creators: {
		getCurrenlyLeaveStart,
		getCurrenlyLeaveSuccess,
		getCurrenlyLeaveFail,
	},
};
