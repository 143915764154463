import { createSelector } from 'reselect';

import { initState } from './reducer';

const LeavetoApproveReducer = (state) => state.LeavetoApproveReducer || initState;
const leaveToapproveLoading = createSelector(LeavetoApproveReducer, (cnter) => cnter.leaveToapproveLoading);
const leaveToApprove = createSelector(LeavetoApproveReducer, (cnter) => cnter.leavetoapprove);
const leaveToApproveError = createSelector(LeavetoApproveReducer, (cnter) => cnter.leavetoapproveError);
const overLappingLeaveLoading = createSelector(LeavetoApproveReducer, (cnter) => cnter.overLappingLeavesLoading);
const overLappingLeave = createSelector(LeavetoApproveReducer, (cnter) => cnter.overLappingLeaves);
const overLappingLeaveError = createSelector(LeavetoApproveReducer, (cnter) => cnter.overLappingLeavesError);
const approveRejectLoading = createSelector(LeavetoApproveReducer, (cnter) => cnter.approveRejectLoading);
const approveReject = createSelector(LeavetoApproveReducer, (cnter) => cnter.approveReject);
const approveRejectError = createSelector(LeavetoApproveReducer, (cnter) => cnter.approveRejectError);

export default {
	LeavetoApproveReducer,
	leaveToapproveLoading,
	leaveToApprove,
	leaveToApproveError,
	overLappingLeaveLoading,
	overLappingLeave,
	overLappingLeaveError,
	approveRejectLoading,
	approveReject,
	approveRejectError,
};
