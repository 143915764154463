import { Switch } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserActionPresentational from './useraction-presentational';
import { CLEVER_TAP_EVENTS, domain, user } from '@constants/app-constants';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect } from 'react-redux';
import SharedServices, { SET_CLAIMS, SET_LOGIN_STEP } from '@sharedComponent/app-action-reducer/services';
import ClevertapReact from 'src/utils/clever-tap';

export const claimsValue = {
	'Payrol.Request': 'Payroll Requests',
	'Payroll.Request': 'Payroll Requests',
	'Card.Cancellation': 'Card Cancellation',
	'Card.Order': 'Card Order',
	'Card.Replacement': 'Card Replacement',
	'Manage.Employees': 'Manage Employees',
	'Manage.Payroll': 'Manage Payroll',
};
export const claimsDetail = {
	'Manage.Employees': 'Manage Employees',
	'Manage.Payroll': 'Manage Payroll',
	'Payrol.Request': 'Approve salary uploads and additions from users.',
	'Payroll.Request': 'Approve salary uploads and additions from users.',
	'Card.Cancellation': 'Approve employee additions and card orders from users.',
	'Card.Order': 'Approve all card cancellation requests from users.',
	'Card.Replacement': 'Approve all card replacement from users.',
};

const temp = [
	{
		key: 0,
		index: 0,
		toggle: true,
		checked: true,
		header: claimsValue['Payrol.Request'],
		detail: claimsDetail['Payrol.Request'],
		claimValue: 'Payrol.Request',
	},
	{
		key: 1,
		index: 1,
		toggle: true,
		checked: true,
		header: claimsValue['Card.Cancellation'],
		detail: claimsDetail['Card.Cancellation'],
		claimValue: 'Card.Cancellation',
	},
	{
		key: 2,
		index: 2,
		toggle: true,
		checked: true,
		header: claimsValue['Card.Order'],
		detail: claimsDetail['Card.Order'],
		claimValue: 'Card.Order',
	},
	{
		key: 3,
		index: 3,
		toggle: true,
		checked: true,
		header: claimsValue['Card.Replacement'],
		detail: claimsDetail['Card.Replacement'],
		claimValue: 'Card.Replacement',
	},
];

const UserActionFunctional = ({ claims: { Verifier } }) => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [visible, setVisible] = useState(false);
	const [list, setList] = useState([]);
	const [claims, setClaims] = useState([]);
	const [data, setData] = useState([]);
	const api = new SharedServices();

	// const showModal = () => {
	// 	setVisible(true);
	// };

	const showModal = () => {
		let s = data.filter((user) => user.checked === false) || [];
		if (s.length > 0) {
			setVisible(true);
		} else {
			nextClicked();
		}
	};

	const afterOpenModal = (e) => {
		setVisible(false);
	};

	const closeModal = () => {
		setVisible(false);
	};

	const generateValues = (getClaims = []) => {
		setData(
			getClaims.map((claim, i) => {
				return {
					...claim,
					checked: true,
				};
			})
		);
	};

	useEffect(() => {
		if (Verifier.length) generateValues(Verifier);
	}, [Verifier]);

	let permissionConst = [
		{
			name: 'Payroll',
			value: 'Approve.Payroll.Request',
		},
		{
			name: 'Order Cards',
			value: 'Approve.Card.Order',
		},
		{
			name: 'Card replacement',
			value: 'Approve.Card.Replacement',
		},
		{
			name: 'Card cancellation',
			value: 'Approve.Card.Cancellation',
		},
	];

	function onChange({ value }, checked) {
		setData(
			data.map((role) => {
				if (role.value === value) {
					return { ...role, checked };
				} else return role;
			})
		);
	}

	const modalSubmit = async () => {
		setVisible(false);
		await addUserClaims();
	};

	const addUserClaims = () => {
		setLoading(true);
		let addClaims = data ? data.filter((role) => role.checked) : [];
		let removeClaims = data ? data.filter((role) => role.checked === false) : [];
		addClaims = addClaims.map((role) => role.value);
		removeClaims = removeClaims.map((role) => role.value);
		permissionConst = permissionConst.map(({ name, value }) => {
			if (addClaims.includes(value)) {
				return {
					name,
					enabled: true,
				};
			} else if (removeClaims.includes(value)) {
				return {
					name,
					enabled: false,
				};
			}
		});

		var payload = permissionConst.reduce((obj, item) => Object.assign(obj, { [item.name]: item.enabled }), {});
		ClevertapReact.event(CLEVER_TAP_EVENTS.Next_Permissions, payload);
		// history.push('/approve_page');

		api.services(SET_CLAIMS, null, {
			addClaims,
			removeClaims,
		})
			.then(() => {
				setLoading(false);
				nextClicked();
			})
			.catch((addClaimsErr) => {
				setLoading(false);
			});
	};

	const nextClicked = () => {
		setLoading(true);
		api.services(SET_LOGIN_STEP, {
			step: 3,
		})
			.then(() => {
				setLoading(false);
				history.push('/approve_page');
			})
			.catch((getLoginStepErr) => {
				setLoading(false);
			});
	};

	return (
		<UserActionPresentational
			modalSubmit={modalSubmit}
			visible={visible}
			showModal={showModal}
			afterOpenModal={afterOpenModal}
			closeModal={closeModal}
			list={data}
			loading={loading}
			onChange={onChange}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		claims: SelectorsApp.claims(state),
	};
};

const Connected = connect(mapStateToProps, null)(UserActionFunctional);

export default Connected;
