const EMPLOYEE_PAYROLL_UPLOAD = 'EMPLOYEE_PAYROLL_UPLOAD';
const EMPLOYEE_PAYROLL_UPLOAD_LOADING = 'EMPLOYEE_PAYROLL_UPLOAD_LOADING';
const EMPLOYEE_PAYROLL_UPLOAD_SUCCESS = 'EMPLOYEE_PAYROLL_UPLOAD_SUCCESS';
const EMPLOYEE_PAYROLL_UPLOAD_FAIL = 'EMPLOYEE_PAYROLL_UPLOAD_FAIL';
const EMP_RESET_DOCUMENT = 'EMP_RESET_DOCUMENT';
const SET_EMPLOYEE_UPLOAD_DOCUMENT = 'SET_EMPLOYEE_UPLOAD_DOCUMENT';
const PUT_PAYROLL_DOWNLOAD_TEMPLATE_LOADING = 'PUT_PAYROLL_DOWNLOAD_TEMPLATE_LOADING';
const GET_PAYROLL_DOWNLOAD_TEMPLATE = 'GET_PAYROLL_DOWNLOAD_TEMPLATE';
const GET_PAYROLL_DOWNLOAD_TEMPLATE_DATA = 'GET_PAYROLL_DOWNLOAD_TEMPLATE_DATA';
const GET_PAYROLL_DOWNLOAD_TEMPLATE_ERROR = 'GET_PAYROLL_DOWNLOAD_TEMPLATE_ERROR';
const employeePayrollUpload = (payload, onBoardingStatus) => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD,
		payload,
		onBoardingStatus,
	};
};
const employeePayrollUploadLoading = (payload) => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD_LOADING,
		payload,
	};
};
const employeePayrollUploadSuccess = (res) => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD_SUCCESS,
		res,
	};
};
const employeePayrollUploadFail = () => {
	return {
		type: EMPLOYEE_PAYROLL_UPLOAD_FAIL,
	};
};
const empDocumentUploadReset = () => ({
	type: EMP_RESET_DOCUMENT,
});
const setEmployeeUploadDocument = (payload) => ({
	type: SET_EMPLOYEE_UPLOAD_DOCUMENT,
	payload: payload,
});
const putPayrollDownloadTemplateLoading = (loadingStatus) => ({
	type: PUT_PAYROLL_DOWNLOAD_TEMPLATE_LOADING,
	payload: loadingStatus,
});

const getPayrollDownloadTemplate = (payload) => ({
	type: GET_PAYROLL_DOWNLOAD_TEMPLATE,
	payload,
});

const getPayrollDownloadTemplateData = (data) => ({
	type: GET_PAYROLL_DOWNLOAD_TEMPLATE_DATA,
	payload: data,
});

const getPayrollDownloadTemplateError = (error) => ({
	type: GET_PAYROLL_DOWNLOAD_TEMPLATE_ERROR,
	payload: error,
});

export default {
	types: {
		EMPLOYEE_PAYROLL_UPLOAD,
		EMPLOYEE_PAYROLL_UPLOAD_LOADING,
		EMPLOYEE_PAYROLL_UPLOAD_SUCCESS,
		EMPLOYEE_PAYROLL_UPLOAD_FAIL,
		EMP_RESET_DOCUMENT,
		SET_EMPLOYEE_UPLOAD_DOCUMENT,
		GET_PAYROLL_DOWNLOAD_TEMPLATE,
		GET_PAYROLL_DOWNLOAD_TEMPLATE_DATA,
		GET_PAYROLL_DOWNLOAD_TEMPLATE_ERROR,
		PUT_PAYROLL_DOWNLOAD_TEMPLATE_LOADING,
	},
	creators: {
		employeePayrollUpload,
		employeePayrollUploadLoading,
		employeePayrollUploadSuccess,
		employeePayrollUploadFail,
		empDocumentUploadReset,
		setEmployeeUploadDocument,
		getPayrollDownloadTemplate,
		getPayrollDownloadTemplateData,
		getPayrollDownloadTemplateError,
		putPayrollDownloadTemplateLoading,
	},
};
