import { ALERT_POPUP, EMPLOYEE_POPUP_CONSTANT } from '@constants/app-constants';
import { isEmailValid } from '@helpers';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import profileAction from '@pages/payroll-plus/profile-action';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EmployeeProfilePopup = ({
	handleAlertPopup,
	alertPopup,
	employeeProfilePopup,
	handleC3PayToBankAccount,
	confirmationModelOpen,
	afterConfirmationOpenModal,
	confirmationsRequest,
	replacementCardMessage,
	employeeCardAddress,
	modalOpen,
	afterOpenModal,
	getDeleterole,
	cardloading,
	handleInviteEmployeeToPortal,
	setInvitePortalEmailId,
	invitePortalEmailId,
	employeeDetails,
	loading,
}) => {
	const dispatch = useDispatch();
	const inviteToPortal = useSelector((state) => state?.counterReducer?.emailConformation);
	const [kycStatus, setKycStatus] = useState(false);
	const [showModal, setShowModal] = useState(false);
	useEffect(() => {
		setKycStatus(employeeDetails?.kycStatus);
	}, [employeeDetails?.kycStatus]);
	const handleModalopen = () => {
		setShowModal(true);
	};
	const handleClose = () => {
		setShowModal(false);
		afterOpenModal();
	};
	const handleSucess = () => {
		setKycStatus(false);
		getDeleterole();
	};
	return (
		<>
			<Edenredmodal
				{...{
					isOpen: alertPopup,
					onCancel: () => handleAlertPopup('closeAlertPopUp'),
					onOkay: () => handleAlertPopup('handleAlertPopUp'),
					title: ALERT_POPUP?.LEAVE_MSG,
					className: 'payroll-profile-tab-alert-popup',
					onOkayButton: ALERT_POPUP?.LEAVE_OK,
					onCancelButton: 'Stay on Page',
				}}
			/>
			<Edenredmodal
				{...{
					isOpen: employeeProfilePopup?.isPopupVisible,
					onCancel: () => handleC3PayToBankAccount('close'),
					onOkay: () => handleC3PayToBankAccount('save', employeeProfilePopup?.param),
					close: () => handleC3PayToBankAccount('close'),
					textcenter: true,
					loading: loading,
					title: employeeProfilePopup?.popupMessage,
					desc: employeeProfilePopup?.popupSubMessage,
					onOkayButton: employeeProfilePopup?.okText,
					onCancelButton: employeeProfilePopup?.cancelText,
				}}
			/>
			<Edenredmodal
				isOpen={confirmationModelOpen}
				onCancel={afterConfirmationOpenModal}
				close={afterConfirmationOpenModal}
				onOkay={confirmationsRequest}
				title={`${replacementCardMessage},\n
                        ${EMPLOYEE_POPUP_CONSTANT?.REPLACE_CARD}\n ${employeeDetails?.name}?`}
				desc={
					<>
						<p>{EMPLOYEE_POPUP_CONSTANT?.REPLACE_CARD_ADD}</p>{' '}
						<p>
							{employeeCardAddress?.address2},{employeeCardAddress?.address3}.
						</p>
						{/* <p>{EMPLOYEE_POPUP_CONSTANT?.INVOICE}</p> */}
					</>
				}
				loading={cardloading}
				textcenter={true}
			/>
			<Edenredmodal
				title="This card was blocked due to missing or expired Emirates ID"
				onOkay={afterOpenModal}
				onOkayButton="Got It"
				onCancel={() => handleModalopen()}
				onCancelButton="I still want to replace"
				isOpen={kycStatus && modalOpen}
				loading={cardloading}
				children={
					<p
						style={{
							marginBottom: '0px',
							marginTop: '7px',
							fontFamily: 'Montserrat-Medium',
							fontWeight: 300,
							fontSize: '16px',
							textAlign: 'center',
							color: '#737780',
						}}>
						{`The card will be unblocked after the Emirates ID is updated by ${employeeDetails?.name} on the C3pay app`}
					</p>
				}
				close={afterOpenModal}
			/>
			<Edenredmodal
				isOpen={kycStatus ? showModal : modalOpen}
				onCancel={kycStatus ? handleClose : afterOpenModal}
				close={kycStatus ? handleClose : afterOpenModal}
				onOkay={() => handleSucess()}
				title={`${EMPLOYEE_POPUP_CONSTANT?.REPLACE_CARD} \n${employeeDetails?.name}?`}
				desc={
					<>
						<p>{EMPLOYEE_POPUP_CONSTANT?.REPLACE_CARD_ADD}</p>{' '}
						<p>
							{employeeCardAddress?.address2},{employeeCardAddress?.address3}.
						</p>
						{/* <p>{EMPLOYEE_POPUP_CONSTANT?.INVOICE}</p> */}
					</>
				}
				loading={cardloading}
				textcenter={true}
			/>
			<Edenredmodal
				{...{
					isOpen: inviteToPortal,
					onCancel: () => dispatch(profileAction.creators.conformEmail(false)),
					onCancelButton: 'Cancel',
					onOkay: handleInviteEmployeeToPortal,
					onOkayButton: 'Invite',
					title: EMPLOYEE_POPUP_CONSTANT?.EMAIL_CONFORM_TEXT,
					onOkayDisable: !isEmailValid(invitePortalEmailId),
					desc: (
						<ul style={{ textAlign: 'left' }}>
							<li>{EMPLOYEE_POPUP_CONSTANT?.EMAILE_DESC_1}</li>
							<li>{EMPLOYEE_POPUP_CONSTANT?.EMAIL_DESC_2}</li>
						</ul>
					),
					field: 'Add Employee Email',
					fieldlabel: 'Add Employee Email',
					fieldonchange: (e) => setInvitePortalEmailId(e?.target?.value),
					fieldValue: invitePortalEmailId,
					errorMessage: invitePortalEmailId && !isEmailValid(invitePortalEmailId) && 'Enter Valid Email',
				}}
			/>
		</>
	);
};

export default EmployeeProfilePopup;
