import { createSelector } from 'reselect';

import { initState } from './history-reducer';

const salaryHistory = (state) => state.salaryHistory || initState;
const isDepartmentLoading = createSelector(salaryHistory, (cnter) => cnter.isDepartmentLoading);
const departmentDetails = createSelector(salaryHistory, (cnter) => cnter.departmentDetails);
const departmentError = createSelector(salaryHistory, (cnter) => cnter.departmentError);

export default {
	salaryHistory,
	isDepartmentLoading,
	departmentDetails,
	departmentError,
};
