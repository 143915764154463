import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import mfaActions from "@pages/auth/mfa/mfaActions";
import useCallCbdClient from "./useCallCbdClient";
import { SITEMAP } from "src/routes/sitemap";
import { MFA_CONSTANTS } from "@pages/auth/mfa/constants";

const useCallMfa = (userData) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector((state) => state.auth)
    const { checkCbdClient } = useCallCbdClient(userData)

    useEffect(() => {
        if (auth.mfa.mode) {
            mfaPageRoutig()
        }
    }, [auth.mfa?.mode]);

    const redirectToMfa = () => {
        history.replace({ pathname: SITEMAP.auth.mfa, state: { user: userData } });
    }

    // mfa page route
    const mfaPageRoutig = () => {
        const isMfa = () => {
            return auth.mfa.mode === MFA_CONSTANTS.mfa.SETUP || auth.mfa.mode === MFA_CONSTANTS.mfa.REQUIRED || auth.mfa.mode === MFA_CONSTANTS.mfa.LOCKED
        }
        if (isMfa()) {
            redirectToMfa()
        }
        else {
            checkCbdClient()
        }
    }
    // check mfa api
    const checkMfa = () => {
        dispatch(mfaActions.creators.checkingMfa());
    }

    return {
        checkMfa
    }

}

export default useCallMfa
