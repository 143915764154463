import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { MainRoutes } from './routes';
import './App.css';
import LoadingScreen from '@sharedComponent/app-action-reducer/loading';
import StatusToast from '@pages/DesignSystem/StatusToast';
import { useSelector } from 'react-redux';
import { APP_VARIABLES } from '@constants/app-constants';
import ClevertapReact from './utils/clever-tap';

function App() {
	const data = useSelector((state) => state?.sharedStates);
	const currentUser = data?.currentUser;
	const subscription = data?.subscriptionProds?.[0];
	useEffect(() => {
		let accessToken = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
		const corprateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID) ? localStorage.getItem(APP_VARIABLES.CORPORATE_ID) : '';
		const corprateName = localStorage.getItem(APP_VARIABLES.CORPORATE_NAME) ? localStorage.getItem(APP_VARIABLES.CORPORATE_NAME) : '';
		if (currentUser?.user && accessToken) {
			ClevertapReact.profile({
				Site: {
					Name: currentUser?.user?.userName,
					Email: currentUser?.user?.userEmail,
				},
			});
			ClevertapReact.event('LoginSuccess');
			ClevertapReact.onUserLogin({
				Site: {
					Name: currentUser?.user?.userName,
					Email: currentUser?.user?.userEmail,
					Identity: currentUser?.user?.userId,
					CorprateId: corprateId,
					CompanyName: corprateName,
					Role: currentUser?.user?.roles && currentUser?.user?.roles?.length > 0 ? currentUser?.user?.roles[0]?.name : '',
					subscription: subscription?.isFreeTrailOn || subscription?.isSubscriptionOn === true ? 'subscribed' : 'unsubscribed',
				},
			});
			ClevertapReact.onUserLogin({
				Site: {
					Name: currentUser?.user?.userName,
					Email: currentUser?.user?.userEmail,
					Identity: currentUser?.user?.userId,
					Role: currentUser?.user?.roles && currentUser?.user?.roles?.length > 0 ? currentUser?.user?.roles[0]?.name : '',
				},
			});
		}
	}, [currentUser?.user, subscription?.isFreeTrailOn, subscription?.isSubscriptionOn]);
	return (
		<>
			<StatusToast />
			<LoadingScreen />;
		</>
	);
}

export default withRouter(App);
