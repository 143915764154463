import Axios from 'axios';
import { getLocalvariable } from '@helpers';
import { APP_VARIABLES, DOMAIN_URL, UN_EMPLOYMENT_INSURANCE_CONSTANTS } from '@constants/app-constants';

class PayrollService {
	//? /* GET */
	usefullfileDownload = (tempName, method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/FileTemplate/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${tempName}/Download`,
			method,
			responseType,
		});

	missingEmpDetailsDownload = (method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/UnemploymentInsurance/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/missing-details/download?paymentPlan=${UN_EMPLOYMENT_INSURANCE_CONSTANTS.insurancePlan}`,
			method,
			responseType,
		});

	getPayrollTableFilterData = (params) =>
		Axios.get(`${DOMAIN_URL}/Employees/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/filter-lookup-data`, { params });

	// Get financial block data
	// /api/v{version}/FinancialBlock/{corporateId}/check
	getFinancialBlockdata = (params) => Axios.get(`${DOMAIN_URL}/FinancialBlock/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/check`, { params });

	// getProofOfTransferFileURL = (params) => Axios.get(`${DOMAIN_URL}/Receipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${params.receiptId}`);
	getProofOfTransferFileURL = (params) => {
		const access_token = JSON.parse(localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN))?.access_token;
		return fetch(`${DOMAIN_URL}/Receipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${params.receiptId}/Download`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		}).then((res) => {
			return res.blob();
		});
	};

	// getProofOfTransferFileURL = (params) =>
	// 	Axios.get(`${DOMAIN_URL}/Receipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${params.receiptId}/Download`);
	// getApprovePayrollList = (params) => Axios.get(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/payroll-run`, { params });
	getApprovePayrollList = (params) => Axios.get(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/run`, { params });
	salaryReportsProcessed = (body, method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${body?.salarymethod}?fromDate=${body.fromDate}&toDate=${body.toDate
				}&reportType=${body.reportType}&salaryType=${body.salarytype}`,
			method,
			responseType,
		});
	salaryReportsNotProcessed = (body, method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/salarynotprocessed?fromDate=${body.fromDate}&toDate=${body.toDate
				}&reportType=${body.reportType}&salaryType=${body.salarytype}`,
			method,
			responseType,
		});
	//we change this api for download salary history details because we didn't want paySlip records.m
	salaryReportsSubscribe = (body, method, responseType) =>
		Axios.get(
			`${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/salaryprocessed?fromDate=${body.fromDate}&toDate=${body.toDate
			}&reportType=${body.reportType}&salaryType=${body.salarytype}`,
			{
				responseType: 'blob',
				headers: {
					Accept: 'application/pdf',
				},
			}
		);
	//salaryReports = (body) => Axios.get(`${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${body.salarytype}?fromDate = ${body.fromDate} &toDate= ${body.toDate}&reportType=${body.reportType} `);
	salaryReportperemployee = (body, method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/salaryperemployee?employeeId=${body.employeeId
				}&fromDate=${body.fromDate}&toDate=${body.toDate}&reportType=${body.reportType}`,
			method,
			responseType,
		});
	salaryReportPerEmployeeSubscribe = (body, method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/subscription/salaryperemployee?employeeId=${body.employeeId
				}&fromDate=${body.fromDate}&toDate=${body.toDate}&reportType=${body.reportType}`,
			method,
			responseType,
		});
	transfercertificate = (body, method, responseType) =>
		Axios.request({
			url: `${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/transfercertificate/?employeeId=${body.employeeId
				}&month=${body.month}&year=${body.year}`,
			method,
			responseType,
		});

	getApproveRequestCountAPI = () =>
		Axios.get(`${DOMAIN_URL}/Employees/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/dashboard/pendingrequestcount`);

	getPayrollTableData = (params) => {
		console.log('getPayrollTableData', params);
		return Axios.get(`${DOMAIN_URL}/Employees/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, { params });
	};

	getLastProccedMonth = (params) => {
		return Axios.get(`${DOMAIN_URL}/EmployeeSalaries/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/lastprocessedmonth`, { params });
	};
	getPayrollDetailsByUploadID = (uploadID, type, params, pageNumber, pageSize) => {
		return Axios.get(
			`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${uploadID}/${type}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			{ params }
		);
	};
	/**
	 *
	 * @param {*} uploadID
	 * @param {string} type
	 * @param {string} params
	 * @param {number} pageNumber
	 * @returns all data because we didn't send page size.
	 */
	getPayrollDetailsByUploadIDForDownloadReport = (uploadID, type, params, pageNumber) => {
		return Axios.get(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${uploadID}/${type}?pageNumber=${pageNumber}`, {
			params,
		});
	};
	getAllPayrollDetailsByUploadID = (uploadID, type, params, pageSize, currentPageNumber, searchedText) => {
		return Axios.get(
			`${DOMAIN_URL}/Payroll/${getLocalvariable(
				APP_VARIABLES.CORPORATE_ID
			)}/${uploadID}/All?searchFilter=${searchedText}&pageNumber=${currentPageNumber}&pageSize=${pageSize}`,
			{ params }
		);
	};
	getPayrollRejectedList = (uploadID, type = null, params) => {
		return Axios.get(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/payroll-details/${uploadID}/${type}/rejected`, {
			params,
		});
	};
	getPreviousPayroll = (params = {}) =>
		Axios.get(`${DOMAIN_URL}/EmployeeSalaries/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/previous-payroll`, { params });
	getPayrollHistory = (params = {}) => Axios.get(`${DOMAIN_URL}/EmployeeSalaries/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/`, { params });
	getPayrollStatus = (uploadID, type, params = {}) =>
		// Axios.get(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/payroll-process/${uploadID}/${type}/status`);
		Axios.get(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${uploadID}/${type}/status`);
	getPayrollTiming = () => Axios.get(`${DOMAIN_URL}/PayoutTiming/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`);
	getFundTransferHistory = (params = {}) => Axios.get(`${DOMAIN_URL}/Receipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/`, { params });

	// Tutorial video api
	getTutorialVideo = (templateName) => Axios.get(`${DOMAIN_URL}/FileTemplate/${templateName}/Download`);
	getPayrollVideoList = (templateName) => Axios.get(`${DOMAIN_URL}/FileTemplate/${templateName}/Download`);
	getEmployeeVideoList = (templateName) => Axios.get(`${DOMAIN_URL}/FileTemplate/${templateName}/Download`);
	ReplacementCardAndStatementsVideo = (templateName) => Axios.get(`${DOMAIN_URL}/FileTemplate/${templateName}/Download`);
	getTeamPermissionVideoList = (templateName) => Axios.get(`${DOMAIN_URL}/FileTemplate/${templateName}/Download`);
	// Tutorial video api

	//? /* POST */

	companyProfileSave = (body) => Axios.post(`${DOMAIN_URL}/Corporates/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/UpdateAddress`, body);
	companyUpload = (body, params) => Axios.post(`${DOMAIN_URL}/Corporates/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/upload`, body, { params });
	// Changed API because of Identity Dependency. Refer: https://dev.azure.com/edenred-eae/B2BSquad/_workitems/edit/13777
	// changePassword = (body) => Axios.post(`${DOMAIN_URL}/Users/me/actions/change-password`, body);
	changePassword = (body) => Axios.post(`${DOMAIN_URL}/Users/me/actions/new-user-change-password`, body);
	updateEmail = (body) => Axios.post(`${DOMAIN_URL}/Users/change-email`, body);
	insuranceSignup = (body, params) =>
		Axios.post(`${DOMAIN_URL}/Insurance/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/send-mail`, body, { params });
	cancelPayroll = (body, params) =>
		Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/run/review`, body, { params });

	approvePayrollReview = (body, params) =>
		Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/run/review`, body, { params });

	submitPayroll = (body, params) =>
		// Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/submit-payroll`, body, { params });
		Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/submit`, body, { params });

	EmployeeSalaryStaging = (body, params) =>
		Axios.post(`${DOMAIN_URL}/EmployeeSalaryStagings/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, body, { params });

	getStagedEmployeesListUrl = () =>
		Axios.post(`${DOMAIN_URL}/EmployeeSalaryStagings/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/employee-details`);
	postEmployeeSalaryStaging = (uploadId) =>
		Axios.post(
			`${DOMAIN_URL}/EmployeeSalaryStagings/${getLocalvariable(
				APP_VARIABLES.CORPORATE_ID
			)}/previous-payroll-stage/uploadId?uploadId=${uploadId}`
		);

	submitProof = (body, params) => Axios.post(`${DOMAIN_URL}/Receipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, body, { params });
	uploadRunPayrollExcel = (formData, params) =>
		// Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/upload-payroll-spreadsheet`, formData, { params });
		Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/upload`, formData, { params });

	employeePayrollUpload = (formData, params) =>
		Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/upload-payroll-hr`, formData, { params });
	initialStaging = (blobFileName, month, year) =>
		Axios.post(
			`${DOMAIN_URL}/Payroll/${getLocalvariable(
				APP_VARIABLES.CORPORATE_ID
			)}/initial-staging?month=${month}&year=${year}&blobFileName=${blobFileName}`
		);
	// Validate the uploaded spead sheet
	validateUploadSpreadSheet = (body, params) =>
		Axios.post(`${DOMAIN_URL}/Payroll/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/autofix-salary-file-error`, body, { params });

	// DELETE

	deleteReceiptDetails = (receiptId) => Axios.delete(`${DOMAIN_URL}/Receipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${receiptId}`);
	getPayrollSummaryUrl = (corprateId, month, year) =>
		Axios.post(`${DOMAIN_URL}/EmployeeSalaryStagings/${corprateId}/salary-stage-summary?month=${month}&year=${year}`);
	getUserClaimsExist = (body) =>
		Axios.post(`${DOMAIN_URL}/Users/${body?.corprateId}/check-userclaim-exists?claimType=${body?.type}&claimValue=${body?.value}`);
	//Image Download

	imageDownload = (encoded) =>
		Axios.get(`${DOMAIN_URL}/Download/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}?url=${encoded}`, { responseType: 'blob' });
}

export class SalaryReportService {
	downloadReport = (fromDate, toDate, cardReportType) =>
		Axios.get(`${DOMAIN_URL}/SalaryReport/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/card?fromDate=${fromDate}&toDate=${toDate}&type=${cardReportType}`,
			// This is to get a response a blob type.	
			{
				responseType: 'blob',
			}
		);
}

export default PayrollService;
