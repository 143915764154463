import Axios from 'axios';
import Actions from '../action';
import counterAction from '@pages/payroll-plus-new/action';
import ToastActions from '@pages/DesignSystem/StatusToast/actions';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import hrFeatureActions from '../../HrFeatureAccess/action';

export function* getAdditionServices() {
	yield put(Actions.creators.putAdditionLoading(true));
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/additiontype`;
	try {
		const response = yield call(Axios.get, url);
		yield put(Actions.creators.getAdditionData(response.data));
		yield put(Actions.creators.putAdditionLoading(false));
	} catch (error) {
		yield put(Actions.creators.putAdditionError(error));
		yield put(Actions.creators.putAdditionLoading(false));
	}
}

export function* sentAdditionData(action) {
	yield put(Actions.creators.putAdditionLoading(true));

	try {
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const url = `${DOMAIN_URL}/CorporateSetting/${corporateId}/additiontype?companySetupFlag=true`;
		yield call(Axios.post, url, action?.payload);
		yield put(Actions.creators.putAdditionLoading(false));
		yield put(ToastActions.creators.putSuccessStatusToast({ message: 'Your additions have been added!' }));
		yield put(Actions.creators.getAddition());
		yield put(hrFeatureActions.creators.getCorporateSettings());
		yield put(counterAction.creators.getPayrollDynamicHeader());
	} catch (error) {
		yield put(Actions.creators.putAdditionError(error));
		yield put(Actions.creators.putAdditionLoading(false));
	}
}
export default function* additionWatcher() {
	yield takeEvery(Actions.types.GET_ADDITION, getAdditionServices);
	yield takeLatest(Actions.types.POST_ADDITION, sentAdditionData);
}
