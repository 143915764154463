// landing page dev and uat corporate
// export const corpData = [
// 	{ corpatae: 33304, monthlyFee: 1, group: 'test1' },
// 	{ corpatae: 35972, monthlyFee: 1.5, group: 'test2' },
// 	{ corpatae: 60012, monthlyFee: 1, group: 'test1' },
// 	{ corpatae: 40808, monthlyFee: 1.5, group: 'test2' },
// 	{ corpatae: 99998, monthlyFee: 2, group: 'test3' },
// ];

/*landing page production corporate
 corporate id for landing page used static data below
 TODO:need api to get dynamic data for corporate id to show landing page (mandatory)*/
export const corpData = [
	{ corpatae: 99999, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 99998, monthlyFee: 2, group: 'test1' },
	{ corpatae: 60582, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 60566, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 60492, monthlyFee: 0, group: 'test1' },
	{ corpatae: 60425, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60319, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 12012, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 60318, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60316, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60311, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60285, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60264, monthlyFee: 1, group: 'test1' },
	{ corpatae: 60150, monthlyFee: 2, group: 'test1' },
	{ corpatae: 12026, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 12028, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 12030, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 12032, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 12035, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 12037, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 51811, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51810, monthlyFee: 2, group: 'test1' },
	{ corpatae: 51808, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51806, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12059, monthlyFee: 0, group: 'test1' },
	{ corpatae: 51801, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51798, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51797, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51794, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51789, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51788, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51786, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51781, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12084, monthlyFee: 0, group: 'test1' },
	{ corpatae: 12085, monthlyFee: 0, group: 'test1' },
	{ corpatae: 51777, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12087, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 51774, monthlyFee: 0, group: 'test1' },
	{ corpatae: 51769, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51762, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51760, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51759, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12098, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12099, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12100, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12101, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51755, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51753, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51751, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51750, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51742, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51737, monthlyFee: 2, group: 'test1' },
	{ corpatae: 51733, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51732, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51731, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51729, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51728, monthlyFee: 2, group: 'test1' },
	{ corpatae: 51726, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35659, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51569, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51469, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35514, monthlyFee: 1, group: 'test1' },
	{ corpatae: 13102, monthlyFee: 1, group: 'test1' },
	{ corpatae: 30026, monthlyFee: 2, group: 'test1' },
	{ corpatae: 13064, monthlyFee: 2, group: 'test1' },
	{ corpatae: 13414, monthlyFee: 2, group: 'test1' },
	{ corpatae: 15062, monthlyFee: 2, group: 'test1' },
	{ corpatae: 13065, monthlyFee: 2, group: 'test1' },
	{ corpatae: 60544, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 33094, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12056, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 35936, monthlyFee: 2, group: 'test1' },
	{ corpatae: 35435, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51668, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35037, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35545, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35807, monthlyFee: 1, group: 'test1' },
	{ corpatae: 13263, monthlyFee: 2, group: 'test1' },
	{ corpatae: 35975, monthlyFee: 2, group: 'test1' },
	{ corpatae: 34673, monthlyFee: 1, group: 'test1' },
	{ corpatae: 33065, monthlyFee: 1, group: 'test1' },
	{ corpatae: 14558, monthlyFee: 2, group: 'test1' },
	{ corpatae: 60602, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60601, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60589, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60588, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60585, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60584, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60578, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60577, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60567, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60551, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 12024, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12025, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60546, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60545, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60540, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60539, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60519, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60509, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60502, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 12045, monthlyFee: 4, group: 'test1' },
	{ corpatae: 12048, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60497, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60496, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60491, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60490, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60488, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60480, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60471, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12078, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60460, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60459, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60452, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60450, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60441, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60440, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12090, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12091, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60438, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60437, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60426, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60418, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60417, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60416, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60415, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60414, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12103, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60413, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60412, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60410, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12113, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60407, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60406, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12119, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60395, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60372, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60366, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60361, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12136, monthlyFee: 4, group: 'test1' },
	{ corpatae: 12137, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60346, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12142, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12143, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12144, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60345, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60344, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12153, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12154, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12155, monthlyFee: 5, group: 'test1' },
	{ corpatae: 12158, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60343, monthlyFee: 5, group: 'test1' },
	{ corpatae: 13660, monthlyFee: 5, group: 'test1' },
	{ corpatae: 13016, monthlyFee: 5, group: 'test1' },
	{ corpatae: 13061, monthlyFee: 3, group: 'test1' },
	{ corpatae: 14399, monthlyFee: 4, group: 'test1' },
	{ corpatae: 13580, monthlyFee: 3, group: 'test1' },
	{ corpatae: 33826, monthlyFee: 3, group: 'test1' },
	{ corpatae: 13362, monthlyFee: 3, group: 'test1' },
	{ corpatae: 12635, monthlyFee: 5, group: 'test1' },
	{ corpatae: 14569, monthlyFee: 3, group: 'test1' },
	{ corpatae: 33543, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60275, monthlyFee: 4, group: 'test1' },
	{ corpatae: 33719, monthlyFee: 3, group: 'test1' },
	{ corpatae: 13498, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60506, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60177, monthlyFee: 5, group: 'test1' },
	{ corpatae: 51012, monthlyFee: 3, group: 'test1' },
	{ corpatae: 14383, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60098, monthlyFee: 5, group: 'test1' },
	{ corpatae: 14886, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60101, monthlyFee: 5, group: 'test1' },
	{ corpatae: 35994, monthlyFee: 3, group: 'test1' },
	{ corpatae: 33361, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60095, monthlyFee: 5, group: 'test1' },
	{ corpatae: 14000, monthlyFee: 3, group: 'test1' },
	{ corpatae: 13776, monthlyFee: 3, group: 'test1' },
	{ corpatae: 51086, monthlyFee: 3, group: 'test1' },
	{ corpatae: 15095, monthlyFee: 3, group: 'test1' },
	{ corpatae: 14038, monthlyFee: 3, group: 'test1' },
	{ corpatae: 12055, monthlyFee: 3, group: 'test1' },
	{ corpatae: 35986, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60596, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60590, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60565, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60527, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60521, monthlyFee: 15, group: 'test1' },
	{ corpatae: 60512, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60494, monthlyFee: 7, group: 'test1' },
	{ corpatae: 60484, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60476, monthlyFee: 10, group: 'test1' },
	{ corpatae: 12020, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60475, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60456, monthlyFee: 6, group: 'test1' },
	{ corpatae: 60455, monthlyFee: 6, group: 'test1' },
	{ corpatae: 60446, monthlyFee: 12, group: 'test1' },
	{ corpatae: 13730, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60453, monthlyFee: 6, group: 'test1' },
	{ corpatae: 12809, monthlyFee: 12, group: 'test1' },
	{ corpatae: 60398, monthlyFee: 7, group: 'test1' },
	{ corpatae: 60208, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60091, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60547, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 60533, monthlyFee: 0, group: 'test1' },
	{ corpatae: 60215, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60079, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60077, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60071, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 60040, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51804, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51785, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 51778, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51772, monthlyFee: 2, group: 'test1' },
	{ corpatae: 51768, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51757, monthlyFee: 2, group: 'test1' },
	{ corpatae: 51756, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51747, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51736, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51701, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51690, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51651, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 51627, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51625, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51624, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51617, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51613, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51612, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51609, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51597, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12075, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 51596, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51595, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12082, monthlyFee: 0, group: 'test1' },
	{ corpatae: 51587, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51563, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51561, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51557, monthlyFee: 0, group: 'test1' },
	{ corpatae: 51556, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51544, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51493, monthlyFee: 2, group: 'test1' },
	{ corpatae: 51488, monthlyFee: 0.5, group: 'test1' },
	{ corpatae: 51486, monthlyFee: 1, group: 'test1' },
	{ corpatae: 60594, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60593, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60587, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60580, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 12011, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60553, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60534, monthlyFee: 3, group: 'test1' },
	{ corpatae: 12017, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60485, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 60454, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60433, monthlyFee: 4.5, group: 'test1' },
	{ corpatae: 60421, monthlyFee: 5, group: 'test1' },
	{ corpatae: 60295, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60290, monthlyFee: 4, group: 'test1' },
	{ corpatae: 60280, monthlyFee: 3.5, group: 'test1' },
	{ corpatae: 12587, monthlyFee: 7, group: 'test1' },
	{ corpatae: 12571, monthlyFee: 13, group: 'test1' },
	{ corpatae: 12561, monthlyFee: 10, group: 'test1' },
	{ corpatae: 12530, monthlyFee: 10, group: 'test1' },
	{ corpatae: 13992, monthlyFee: 2, group: 'test1' },
	{ corpatae: 14273, monthlyFee: 0.75, group: 'test1' },
	{ corpatae: 13843, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35675, monthlyFee: 1, group: 'test1' },
	{ corpatae: 12755, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51697, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35218, monthlyFee: 1, group: 'test1' },
	{ corpatae: 33709, monthlyFee: 1, group: 'test1' },
	{ corpatae: 13620, monthlyFee: 2, group: 'test1' },
	{ corpatae: 12933, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35987, monthlyFee: 1, group: 'test1' },
	{ corpatae: 34617, monthlyFee: 2.5, group: 'test1' },
	{ corpatae: 33772, monthlyFee: 1, group: 'test1' },
	{ corpatae: 14269, monthlyFee: 2, group: 'test1' },
	{ corpatae: 12617, monthlyFee: 1, group: 'test1' },
	{ corpatae: 51631, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35473, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35301, monthlyFee: 1, group: 'test1' },
	{ corpatae: 13564, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 12325, monthlyFee: 0, group: 'test1' },
	{ corpatae: 60548, monthlyFee: 1.5, group: 'test1' },
	{ corpatae: 51448, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35080, monthlyFee: 1, group: 'test1' },
	{ corpatae: 34753, monthlyFee: 1, group: 'test1' },
	{ corpatae: 14988, monthlyFee: 1, group: 'test1' },
	{ corpatae: 14127, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35851, monthlyFee: 1, group: 'test1' },
	{ corpatae: 35822, monthlyFee: 2, group: 'test1' },
	{ corpatae: 35340, monthlyFee: 1, group: 'test1' },
	{ corpatae: 34970, monthlyFee: 1, group: 'test1' },
	{ corpatae: 13733, monthlyFee: 3, group: 'test1' },
	{ corpatae: 12616, monthlyFee: 3, group: 'test1' },
	{ corpatae: 12222, monthlyFee: 3, group: 'test1' },
	{ corpatae: 14555, monthlyFee: 3, group: 'test1' },
	{ corpatae: 33563, monthlyFee: 3, group: 'test1' },
	{ corpatae: 60164, monthlyFee: 5, group: 'test1' },
	{ corpatae: 27028, monthlyFee: 3, group: 'test1' },
	{ corpatae: 12793, monthlyFee: 6, group: 'test1' },
	{ corpatae: 12570, monthlyFee: 10, group: 'test1' },
	{ corpatae: 12652, monthlyFee: 10, group: 'test1' },
	{ corpatae: 60140, monthlyFee: 7, group: 'test1' },
	{ corpatae: 51725, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51724, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51722, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51717, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51715, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51713, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51709, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51707, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51705, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51700, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51698, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51696, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51694, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51681, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51679, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51667, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51666, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51664, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51663, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51661, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12185, monthlyFee: 2.5, group: 'test2' },
	{ corpatae: 51660, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51658, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51653, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12197, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51652, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51648, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51647, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51646, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12207, monthlyFee: 0, group: 'test2' },
	{ corpatae: 12208, monthlyFee: 0, group: 'test2' },
	{ corpatae: 51644, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51642, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12215, monthlyFee: 1.5, group: 'test2' },
	{ corpatae: 12216, monthlyFee: 1.5, group: 'test2' },
	{ corpatae: 51637, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51634, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51630, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12231, monthlyFee: 0, group: 'test2' },
	{ corpatae: 12232, monthlyFee: 0, group: 'test2' },
	{ corpatae: 12233, monthlyFee: 0, group: 'test2' },
	{ corpatae: 12235, monthlyFee: 0, group: 'test2' },
	{ corpatae: 51629, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51626, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12240, monthlyFee: 2, group: 'test2' },
	{ corpatae: 12241, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51622, monthlyFee: 2, group: 'test2' },
	{ corpatae: 12244, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12245, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51621, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51620, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51619, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51611, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51608, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51607, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51604, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51633, monthlyFee: 1, group: 'test2' },
	{ corpatae: 38028, monthlyFee: 0, group: 'test2' },
	{ corpatae: 35748, monthlyFee: 2, group: 'test2' },
	{ corpatae: 33684, monthlyFee: 2, group: 'test2' },
	{ corpatae: 14418, monthlyFee: 1, group: 'test2' },
	{ corpatae: 13927, monthlyFee: 2, group: 'test2' },
	{ corpatae: 35982, monthlyFee: 1, group: 'test2' },
	{ corpatae: 13961, monthlyFee: 1, group: 'test2' },
	{ corpatae: 35189, monthlyFee: 1, group: 'test2' },
	{ corpatae: 33432, monthlyFee: 1, group: 'test2' },
	{ corpatae: 33057, monthlyFee: 1, group: 'test2' },
	{ corpatae: 14347, monthlyFee: 1, group: 'test2' },
	{ corpatae: 13875, monthlyFee: 1.5, group: 'test2' },
	{ corpatae: 12209, monthlyFee: 0.5, group: 'test2' },
	{ corpatae: 35850, monthlyFee: 1, group: 'test2' },
	{ corpatae: 35076, monthlyFee: 1, group: 'test2' },
	{ corpatae: 14220, monthlyFee: 1, group: 'test2' },
	{ corpatae: 35326, monthlyFee: 1, group: 'test2' },
	{ corpatae: 34776, monthlyFee: 1, group: 'test2' },
	{ corpatae: 13381, monthlyFee: 0, group: 'test2' },
	{ corpatae: 12720, monthlyFee: 1.35, group: 'test2' },
	{ corpatae: 60196, monthlyFee: 2.5, group: 'test2' },
	{ corpatae: 13252, monthlyFee: 2, group: 'test2' },
	{ corpatae: 35561, monthlyFee: 1, group: 'test2' },
	{ corpatae: 60341, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60339, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60307, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60294, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60282, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60278, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60277, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60274, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60272, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60270, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60269, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60268, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12201, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12203, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60266, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60263, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60256, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12212, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60254, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60253, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60245, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60241, monthlyFee: 3, group: 'test2' },
	{ corpatae: 12224, monthlyFee: 4, group: 'test2' },
	{ corpatae: 12226, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60238, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60236, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60225, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60224, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60218, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60217, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60210, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60206, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60205, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60203, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60194, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60192, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60191, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60188, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60169, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60168, monthlyFee: 4, group: 'test2' },
	{ corpatae: 12274, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60167, monthlyFee: 4, group: 'test2' },
	{ corpatae: 12276, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12277, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12281, monthlyFee: 4.5, group: 'test2' },
	{ corpatae: 12282, monthlyFee: 4.5, group: 'test2' },
	{ corpatae: 12283, monthlyFee: 4.5, group: 'test2' },
	{ corpatae: 12284, monthlyFee: 4.5, group: 'test2' },
	{ corpatae: 60165, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60158, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60146, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60145, monthlyFee: 4, group: 'test2' },
	{ corpatae: 12299, monthlyFee: 3, group: 'test2' },
	{ corpatae: 12301, monthlyFee: 3, group: 'test2' },
	{ corpatae: 12303, monthlyFee: 3, group: 'test2' },
	{ corpatae: 12305, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60144, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60139, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60137, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60127, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60121, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60110, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60108, monthlyFee: 4, group: 'test2' },
	{ corpatae: 12317, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60105, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60099, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60097, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60093, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60092, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60044, monthlyFee: 5, group: 'test2' },
	{ corpatae: 33417, monthlyFee: 3, group: 'test2' },
	{ corpatae: 33358, monthlyFee: 3, group: 'test2' },
	{ corpatae: 14361, monthlyFee: 3, group: 'test2' },
	{ corpatae: 13497, monthlyFee: 3, group: 'test2' },
	{ corpatae: 13349, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60597, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60532, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60331, monthlyFee: 5, group: 'test2' },
	{ corpatae: 33657, monthlyFee: 3, group: 'test2' },
	{ corpatae: 14694, monthlyFee: 3, group: 'test2' },
	{ corpatae: 14105, monthlyFee: 3, group: 'test2' },
	{ corpatae: 13941, monthlyFee: 3, group: 'test2' },
	{ corpatae: 13123, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60505, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60149, monthlyFee: 5, group: 'test2' },
	{ corpatae: 34040, monthlyFee: 4, group: 'test2' },
	{ corpatae: 33445, monthlyFee: 3, group: 'test2' },
	{ corpatae: 23039, monthlyFee: 4.25, group: 'test2' },
	{ corpatae: 13353, monthlyFee: 5, group: 'test2' },
	{ corpatae: 13268, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60463, monthlyFee: 5, group: 'test2' },
	{ corpatae: 33661, monthlyFee: 3, group: 'test2' },
	{ corpatae: 33357, monthlyFee: 3, group: 'test2' },
	{ corpatae: 27044, monthlyFee: 3.5, group: 'test2' },
	{ corpatae: 13903, monthlyFee: 3, group: 'test2' },
	{ corpatae: 13629, monthlyFee: 3, group: 'test2' },
	{ corpatae: 13607, monthlyFee: 5, group: 'test2' },
	{ corpatae: 13280, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12674, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60562, monthlyFee: 3.5, group: 'test2' },
	{ corpatae: 60443, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60435, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60432, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60431, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60430, monthlyFee: 15, group: 'test2' },
	{ corpatae: 60427, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60405, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60402, monthlyFee: 6, group: 'test2' },
	{ corpatae: 60397, monthlyFee: 12, group: 'test2' },
	{ corpatae: 60396, monthlyFee: 15, group: 'test2' },
	{ corpatae: 60349, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60348, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60347, monthlyFee: 12, group: 'test2' },
	{ corpatae: 60337, monthlyFee: 10, group: 'test2' },
	{ corpatae: 12395, monthlyFee: 10, group: 'test2' },
	{ corpatae: 60511, monthlyFee: 10, group: 'test2' },
	{ corpatae: 12510, monthlyFee: 10, group: 'test2' },
	{ corpatae: 12370, monthlyFee: 9, group: 'test2' },
	{ corpatae: 60489, monthlyFee: 20, group: 'test2' },
	{ corpatae: 60042, monthlyFee: 10, group: 'test2' },
	{ corpatae: 51481, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51465, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51445, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51444, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51442, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51439, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12104, monthlyFee: 2.5, group: 'test2' },
	{ corpatae: 51438, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51427, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51412, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51403, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12117, monthlyFee: 0, group: 'test2' },
	{ corpatae: 51389, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51386, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51382, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51371, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51364, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51357, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51319, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51313, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51303, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51287, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51283, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51275, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12148, monthlyFee: 0, group: 'test2' },
	{ corpatae: 51269, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51267, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51256, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51253, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51246, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51224, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51216, monthlyFee: 1, group: 'test2' },
	{ corpatae: 51212, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51201, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12183, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12184, monthlyFee: 2.5, group: 'test2' },
	{ corpatae: 51198, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51158, monthlyFee: 2, group: 'test2' },
	{ corpatae: 12192, monthlyFee: 0, group: 'test2' },
	{ corpatae: 12196, monthlyFee: 1, group: 'test2' },
	{ corpatae: 60259, monthlyFee: 3.5, group: 'test2' },
	{ corpatae: 60255, monthlyFee: 3, group: 'test2' },
	{ corpatae: 60211, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60202, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60193, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60163, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60123, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60094, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60087, monthlyFee: 3.5, group: 'test2' },
	{ corpatae: 60055, monthlyFee: 4, group: 'test2' },
	{ corpatae: 60032, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60028, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60025, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60023, monthlyFee: 5, group: 'test2' },
	{ corpatae: 60007, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12519, monthlyFee: 10, group: 'test2' },
	{ corpatae: 12491, monthlyFee: 6, group: 'test2' },
	{ corpatae: 12479, monthlyFee: 6.75, group: 'test2' },
	{ corpatae: 12444, monthlyFee: 6, group: 'test2' },
	{ corpatae: 34674, monthlyFee: 1, group: 'test2' },
	{ corpatae: 33273, monthlyFee: 1, group: 'test2' },
	{ corpatae: 30042, monthlyFee: 2, group: 'test2' },
	{ corpatae: 14368, monthlyFee: 2, group: 'test2' },
	{ corpatae: 12841, monthlyFee: 0, group: 'test2' },
	{ corpatae: 14386, monthlyFee: 0.75, group: 'test2' },
	{ corpatae: 14086, monthlyFee: 1, group: 'test2' },
	{ corpatae: 12925, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51009, monthlyFee: 2, group: 'test2' },
	{ corpatae: 33774, monthlyFee: 1, group: 'test2' },
	{ corpatae: 14061, monthlyFee: 1, group: 'test2' },
	{ corpatae: 60370, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51337, monthlyFee: 1, group: 'test2' },
	{ corpatae: 35461, monthlyFee: 1, group: 'test2' },
	{ corpatae: 34983, monthlyFee: 1, group: 'test2' },
	{ corpatae: 33322, monthlyFee: 1, group: 'test2' },
	{ corpatae: 33010, monthlyFee: 2, group: 'test2' },
	{ corpatae: 13563, monthlyFee: 1.5, group: 'test2' },
	{ corpatae: 12930, monthlyFee: 0.5, group: 'test2' },
	{ corpatae: 12920, monthlyFee: 2, group: 'test2' },
	{ corpatae: 12180, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51079, monthlyFee: 2, group: 'test2' },
	{ corpatae: 51017, monthlyFee: 2, group: 'test2' },
	{ corpatae: 35839, monthlyFee: 1, group: 'test2' },
	{ corpatae: 35283, monthlyFee: 1, group: 'test2' },
	{ corpatae: 14882, monthlyFee: 2, group: 'test2' },
	{ corpatae: 14259, monthlyFee: 2, group: 'test2' },
	{ corpatae: 35390, monthlyFee: 1, group: 'test2' },
	{ corpatae: 35287, monthlyFee: 1, group: 'test2' },
	{ corpatae: 33307, monthlyFee: 1, group: 'test2' },
	{ corpatae: 60030, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12254, monthlyFee: 3, group: 'test2' },
	{ corpatae: 12041, monthlyFee: 5, group: 'test2' },
	{ corpatae: 12891, monthlyFee: 4.5, group: 'test2' },
	{ corpatae: 13986, monthlyFee: 3, group: 'test2' },
	{ corpatae: 34037, monthlyFee: 4, group: 'test2' },
	{ corpatae: 33443, monthlyFee: 3, group: 'test2' },
	{ corpatae: 12460, monthlyFee: 10, group: 'test2' },
	{ corpatae: 12714, monthlyFee: 8, group: 'test2' },
	{ corpatae: 27016, monthlyFee: 10, group: 'test2' },
	{ corpatae: 12114, monthlyFee: 7, group: 'test2' },
	{ corpatae: 51603, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51600, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51593, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51592, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51589, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51582, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51581, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51579, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51578, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51575, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51571, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51570, monthlyFee: 1.5, group: 'test3' },
	{ corpatae: 51568, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51566, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51554, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51551, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51546, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51541, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51540, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51538, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51534, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51531, monthlyFee: 1, group: 'test3' },
	{ corpatae: 12319, monthlyFee: 2, group: 'test3' },
	{ corpatae: 12320, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51529, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51526, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51522, monthlyFee: 2, group: 'test3' },
	{ corpatae: 12328, monthlyFee: 0, group: 'test3' },
	{ corpatae: 51521, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51519, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51517, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51516, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51514, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51506, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51503, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51500, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51497, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51492, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51489, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51484, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51482, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51478, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51477, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51476, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51475, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51472, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51471, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51468, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51464, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51463, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51462, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51461, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51453, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51441, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51440, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51436, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51432, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51431, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35508, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35099, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35042, monthlyFee: 1, group: 'test3' },
	{ corpatae: 14881, monthlyFee: 2, group: 'test3' },
	{ corpatae: 14525, monthlyFee: 2, group: 'test3' },
	{ corpatae: 14469, monthlyFee: 2, group: 'test3' },
	{ corpatae: 12967, monthlyFee: 2, group: 'test3' },
	{ corpatae: 60581, monthlyFee: 1.5, group: 'test3' },
	{ corpatae: 35947, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35043, monthlyFee: 1, group: 'test3' },
	{ corpatae: 34953, monthlyFee: 1, group: 'test3' },
	{ corpatae: 15048, monthlyFee: 0, group: 'test3' },
	{ corpatae: 13521, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35177, monthlyFee: 1, group: 'test3' },
	{ corpatae: 34127, monthlyFee: 1.25, group: 'test3' },
	{ corpatae: 33672, monthlyFee: 2, group: 'test3' },
	{ corpatae: 30050, monthlyFee: 2, group: 'test3' },
	{ corpatae: 14996, monthlyFee: 2, group: 'test3' },
	{ corpatae: 13878, monthlyFee: 0.5, group: 'test3' },
	{ corpatae: 13206, monthlyFee: 1, group: 'test3' },
	{ corpatae: 12775, monthlyFee: 0, group: 'test3' },
	{ corpatae: 51802, monthlyFee: 1, group: 'test3' },
	{ corpatae: 60027, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60026, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60013, monthlyFee: 4, group: 'test3' },
	{ corpatae: 60005, monthlyFee: 4, group: 'test3' },
	{ corpatae: 51813, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12340, monthlyFee: 5, group: 'test3' },
	{ corpatae: 12341, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51796, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51783, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51780, monthlyFee: 4, group: 'test3' },
	{ corpatae: 51779, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51767, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51766, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51765, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12358, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51744, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51741, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12361, monthlyFee: 5, group: 'test3' },
	{ corpatae: 12362, monthlyFee: 5, group: 'test3' },
	{ corpatae: 12363, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51734, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51720, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51719, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51704, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51692, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12392, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51691, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51678, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51662, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51657, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51656, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51643, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51641, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51640, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12410, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51638, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12414, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51636, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51628, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12418, monthlyFee: 4, group: 'test3' },
	{ corpatae: 12419, monthlyFee: 4, group: 'test3' },
	{ corpatae: 12420, monthlyFee: 4, group: 'test3' },
	{ corpatae: 12421, monthlyFee: 4, group: 'test3' },
	{ corpatae: 12422, monthlyFee: 4, group: 'test3' },
	{ corpatae: 51601, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51599, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51564, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51562, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51549, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12434, monthlyFee: 4, group: 'test3' },
	{ corpatae: 12435, monthlyFee: 4, group: 'test3' },
	{ corpatae: 51547, monthlyFee: 3.5, group: 'test3' },
	{ corpatae: 51542, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51523, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51512, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51509, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12449, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51501, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51495, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51494, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51491, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51460, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51459, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51395, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51372, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51369, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51358, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51345, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51324, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51320, monthlyFee: 3, group: 'test3' },
	{ corpatae: 60161, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60142, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60118, monthlyFee: 3, group: 'test3' },
	{ corpatae: 60116, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51305, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51294, monthlyFee: 3, group: 'test3' },
	{ corpatae: 33844, monthlyFee: 3, group: 'test3' },
	{ corpatae: 14892, monthlyFee: 3, group: 'test3' },
	{ corpatae: 14828, monthlyFee: 4, group: 'test3' },
	{ corpatae: 14256, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13933, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13284, monthlyFee: 5, group: 'test3' },
	{ corpatae: 12040, monthlyFee: 4, group: 'test3' },
	{ corpatae: 60262, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60131, monthlyFee: 5, group: 'test3' },
	{ corpatae: 33498, monthlyFee: 3, group: 'test3' },
	{ corpatae: 33493, monthlyFee: 3, group: 'test3' },
	{ corpatae: 14857, monthlyFee: 3, group: 'test3' },
	{ corpatae: 14547, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13979, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13589, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13341, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60359, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60242, monthlyFee: 5, group: 'test3' },
	{ corpatae: 60122, monthlyFee: 4, group: 'test3' },
	{ corpatae: 33733, monthlyFee: 3, group: 'test3' },
	{ corpatae: 33547, monthlyFee: 3, group: 'test3' },
	{ corpatae: 33340, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13826, monthlyFee: 5, group: 'test3' },
	{ corpatae: 13792, monthlyFee: 4, group: 'test3' },
	{ corpatae: 60297, monthlyFee: 7, group: 'test3' },
	{ corpatae: 12081, monthlyFee: 6, group: 'test3' },
	{ corpatae: 60293, monthlyFee: 6, group: 'test3' },
	{ corpatae: 60279, monthlyFee: 8, group: 'test3' },
	{ corpatae: 60250, monthlyFee: 8, group: 'test3' },
	{ corpatae: 60216, monthlyFee: 10, group: 'test3' },
	{ corpatae: 60185, monthlyFee: 10, group: 'test3' },
	{ corpatae: 60176, monthlyFee: 7, group: 'test3' },
	{ corpatae: 60174, monthlyFee: 10, group: 'test3' },
	{ corpatae: 60151, monthlyFee: 15, group: 'test3' },
	{ corpatae: 60132, monthlyFee: 15, group: 'test3' },
	{ corpatae: 60126, monthlyFee: 15, group: 'test3' },
	{ corpatae: 12369, monthlyFee: 9, group: 'test3' },
	{ corpatae: 12236, monthlyFee: 10, group: 'test3' },
	{ corpatae: 60231, monthlyFee: 10, group: 'test3' },
	{ corpatae: 33013, monthlyFee: 10, group: 'test3' },
	{ corpatae: 12677, monthlyFee: 10, group: 'test3' },
	{ corpatae: 12251, monthlyFee: 10, group: 'test3' },
	{ corpatae: 60249, monthlyFee: 10, group: 'test3' },
	{ corpatae: 60159, monthlyFee: 10, group: 'test3' },
	{ corpatae: 51146, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51141, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51128, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51098, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51093, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51075, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51041, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51039, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51029, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51027, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51022, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51020, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51019, monthlyFee: 2, group: 'test3' },
	{ corpatae: 51011, monthlyFee: 2, group: 'test3' },
	{ corpatae: 44659, monthlyFee: 0, group: 'test3' },
	{ corpatae: 44658, monthlyFee: 0, group: 'test3' },
	{ corpatae: 44655, monthlyFee: 0, group: 'test3' },
	{ corpatae: 44654, monthlyFee: 0, group: 'test3' },
	{ corpatae: 44553, monthlyFee: 0, group: 'test3' },
	{ corpatae: 44552, monthlyFee: 0, group: 'test3' },
	{ corpatae: 44551, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42835, monthlyFee: 0, group: 'test3' },
	{ corpatae: 12242, monthlyFee: 2, group: 'test3' },
	{ corpatae: 42830, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42817, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42816, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42813, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42806, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42803, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42758, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42757, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42754, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42753, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42751, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42710, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42639, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42637, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42634, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42632, monthlyFee: 0, group: 'test3' },
	{ corpatae: 42630, monthlyFee: 0, group: 'test3' },
	{ corpatae: 60003, monthlyFee: 3.5, group: 'test3' },
	{ corpatae: 51388, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51279, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51255, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51233, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51218, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51208, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12092, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51194, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51169, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51157, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51156, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51155, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51153, monthlyFee: 3, group: 'test3' },
	{ corpatae: 51152, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12441, monthlyFee: 6, group: 'test3' },
	{ corpatae: 12355, monthlyFee: 10, group: 'test3' },
	{ corpatae: 12139, monthlyFee: 6, group: 'test3' },
	{ corpatae: 12124, monthlyFee: 8, group: 'test3' },
	{ corpatae: 33210, monthlyFee: 1, group: 'test3' },
	{ corpatae: 33189, monthlyFee: 1, group: 'test3' },
	{ corpatae: 14911, monthlyFee: 1, group: 'test3' },
	{ corpatae: 12246, monthlyFee: 0.5, group: 'test3' },
	{ corpatae: 51559, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51424, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35245, monthlyFee: 1, group: 'test3' },
	{ corpatae: 33655, monthlyFee: 2, group: 'test3' },
	{ corpatae: 33372, monthlyFee: 1, group: 'test3' },
	{ corpatae: 14858, monthlyFee: 2, group: 'test3' },
	{ corpatae: 13857, monthlyFee: 0.5, group: 'test3' },
	{ corpatae: 51560, monthlyFee: 1, group: 'test3' },
	{ corpatae: 51520, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35841, monthlyFee: 2, group: 'test3' },
	{ corpatae: 34896, monthlyFee: 2, group: 'test3' },
	{ corpatae: 34553, monthlyFee: 2, group: 'test3' },
	{ corpatae: 33283, monthlyFee: 1, group: 'test3' },
	{ corpatae: 14697, monthlyFee: 1, group: 'test3' },
	{ corpatae: 14003, monthlyFee: 2, group: 'test3' },
	{ corpatae: 13672, monthlyFee: 2, group: 'test3' },
	{ corpatae: 12847, monthlyFee: 2, group: 'test3' },
	{ corpatae: 60197, monthlyFee: 2.5, group: 'test3' },
	{ corpatae: 51415, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35897, monthlyFee: 1, group: 'test3' },
	{ corpatae: 35248, monthlyFee: 1, group: 'test3' },
	{ corpatae: 33696, monthlyFee: 2, group: 'test3' },
	{ corpatae: 33492, monthlyFee: 2, group: 'test3' },
	{ corpatae: 14433, monthlyFee: 1, group: 'test3' },
	{ corpatae: 13752, monthlyFee: 1, group: 'test3' },
	{ corpatae: 13592, monthlyFee: 2, group: 'test3' },
	{ corpatae: 60404, monthlyFee: 5, group: 'test3' },
	{ corpatae: 51071, monthlyFee: 3, group: 'test3' },
	{ corpatae: 14089, monthlyFee: 3, group: 'test3' },
	{ corpatae: 14163, monthlyFee: 3, group: 'test3' },
	{ corpatae: 34578, monthlyFee: 4, group: 'test3' },
	{ corpatae: 33606, monthlyFee: 3, group: 'test3' },
	{ corpatae: 13287, monthlyFee: 5, group: 'test3' },
	{ corpatae: 23033, monthlyFee: 4.25, group: 'test3' },
	{ corpatae: 14363, monthlyFee: 3, group: 'test3' },
	{ corpatae: 12339, monthlyFee: 10, group: 'test3' },
	{ corpatae: 12308, monthlyFee: 10, group: 'test3' },
];
export const emprange = ['1-50', '51-300', '301-500', '501-1000', '1000+'];
export const testGroup = {
	test1: [
		{
			range: '1-50',
			min: 1,
			max: 50,
			rate: 15,
		},
		{
			range: '51-300',
			min: 51,
			max: 300,
			rate: 13,
		},
		{
			range: '301-500',
			min: 301,
			max: 500,
			rate: 10,
		},
		{
			range: '501-1000',
			min: 501,
			max: 1000,
			rate: 7,
		},
		{
			range: '1000+',
			min: 1000,
			max: 10000,
			rate: 5,
		},
	],
	test2: [
		{
			range: '1-50',
			min: 1,
			max: 50,
			rate: 10,
		},
		{
			range: '51-300',
			min: 51,
			max: 300,
			rate: 7,
		},
		{
			range: '301-500',
			min: 301,
			max: 500,
			rate: 5,
		},
		{
			range: '501-1000',
			min: 501,
			max: 1000,
			rate: 3,
		},
		{
			range: '1000+',
			min: 1000,
			max: 10000,
			rate: 2,
		},
	],

	test3: [
		{
			range: '1-50',
			min: 1,
			max: 50,
			rate: 5,
		},
		{
			range: '51-300',
			min: 51,
			max: 300,
			rate: 4,
		},
		{
			range: '301-500',
			min: 301,
			max: 500,
			rate: 3,
		},
		{
			range: '501-1000',
			min: 501,
			max: 1000,
			rate: 2,
		},
		{
			range: '1000+',
			min: 1000,
			max: 10000,
			rate: 1,
		},
	],
};
