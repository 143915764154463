import { createSelector } from 'reselect';

import { initState } from './reducer';

const leaveBalanceReducer = (state) => state.leaveBalanceReducer || initState;
const employeeBalanceLoading = createSelector(leaveBalanceReducer, (cnter) => cnter.employeeBalanceLoading);
const employeeBalance = createSelector(leaveBalanceReducer, (cnter) => cnter.employeeBalance);
const employeeBalanceError = createSelector(leaveBalanceReducer, (cnter) => cnter.employeeBalanceError);
const employeeLeaveTypeLoading = createSelector(leaveBalanceReducer, (cnter) => cnter.employeeLeaveTypeLoading);
const employeeLeaveType = createSelector(leaveBalanceReducer, (cnter) => cnter.employeeLeaveType);
const employeeLeaveTypeError = createSelector(leaveBalanceReducer, (cnter) => cnter.employeeLeaveTypeError);
const saveLeaveLoading = createSelector(leaveBalanceReducer, (cnter) => cnter.saveLeaveLoading);
const saveLeaveDetail = createSelector(leaveBalanceReducer, (cnter) => cnter.saveLeaveDetail);
const saveLeaveError = createSelector(leaveBalanceReducer, (cnter) => cnter.saveLeaveError);
const saveLeaveSuccessfully = createSelector(leaveBalanceReducer, (cnter) => cnter.saveLeaveSuccessfully);

export default {
	leaveBalanceReducer,
	employeeBalanceLoading,
	employeeBalance,
	employeeBalanceError,
	employeeLeaveTypeLoading,
	employeeLeaveType,
	employeeLeaveTypeError,
	saveLeaveLoading,
	saveLeaveDetail,
	saveLeaveError,
	saveLeaveSuccessfully,
};
