import { UN_EMPLOYMENT_TAB_LABELS } from '../constants';
import Actions from './action';
export const initState = {
	isUnEmploymentLoading: false,
	unEmploymentCardHolderData: [],
	unEmploymentEidData: [],

	missingEmpTemplateLoading: false,
	missingEmpTemplateMsg: null,
	updateMissingEmpLoading: false,
	errorData: null,

	successFlag: false,

	missingEmpLoading: false,
	missingEmpList: [],

	unEmploymentEmpLoading: false,

	unEmploymentSubscribedEmp: [],
	unEmploymentPendineEmp: [],
	unEmploymentCancelledEmp: [],

	downloadDetailedReportMsg: null,
	downloadDetailedReportLoading: false,
};

const unemploymentReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.PUT_UNEMPLOYMENT_EMPLOYEE_LOADING: {
			return {
				...state,
				isUnEmploymentLoading: action.payload.loading,
			};
		}

		case Actions.types.SET_SUCCESS_TOAST_FLAG: {
			return {
				...state,
				successFlag: action.flag,
			};
		}

		case Actions.types.GET_UNEMPLOYMENT_START: {
			return {
				...state,
				isUnEmploymentLoading: true,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_CARD_SUCCESS: {
			return {
				...state,
				isUnEmploymentLoading: false,
				unEmploymentCardHolderData: action?.res,
			};
		}

		case Actions.types.GET_MISSING_EMPLOYEE_LIST: {
			return {
				...state,
				missingEmpLoading: true,
			};
		}
		case Actions.types.GET_MISSING_EMPLOYEE_LIST_SUCCESS: {
			return {
				...state,
				missingEmpLoading: false,
				missingEmpList: action?.res,
			};
		}
		case Actions.types.GET_MISSING_EMPLOYEE_LIST_FAIL: {
			return {
				...state,
				missingEmpLoading: false,
				missingEmpList: [],
			};
		}

		case Actions.types.GET_UN_EMPLOYMENT_LIST_WITH_STATUS: {
			return {
				...state,
				unEmploymentEmpLoading: true,
			};
		}
		case Actions.types.GET_UN_EMPLOYMENT_LIST_WITH_STATUS_SUCCESS: {
			if (action.status === UN_EMPLOYMENT_TAB_LABELS.SUBSCRIBED) {
				return {
					...state,
					unEmploymentEmpLoading: false,
					unEmploymentSubscribedEmp: action?.res,
				};
			} else if (action.status === UN_EMPLOYMENT_TAB_LABELS.PENDING) {
				return {
					...state,
					unEmploymentEmpLoading: false,
					unEmploymentPendineEmp: action?.res,
				};
			} else if (action.status === UN_EMPLOYMENT_TAB_LABELS.CANCELLED) {
				return {
					...state,
					unEmploymentEmpLoading: false,
					unEmploymentCancelledEmp: action?.res,
				};
			}
			break;
		}
		case Actions.types.GET_UN_EMPLOYMENT_LIST_WITH_STATUS_FAIL: {
			return {
				...state,
				unEmploymentEmpLoading: false,
				unEmploymentEmp: [],
			};
		}

		case Actions.types.GET_UNEMPLOYMENT_ALL_COUNT: {
			return {
				...state,
				unemploymentAllCountLoading: true,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_ALL_COUNT_SUCCESS: {
			return {
				...state,
				unemploymentAllCountLoading: false,
				unemploymentAllCount: action?.res,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_ALL_COUNT_FAIL: {
			return {
				...state,
				unemploymentAllCountLoading: false,
				unemploymentAllCount: [],
			};
		}

		case Actions.types.DOWNLOAD_DETAILED_REPORT_START: {
			return {
				...state,
				downloadDetailedReportLoading: true,
				downloadDetailedReportMsg: null,
			};
		}
		case Actions.types.DOWNLOAD_DETAILED_REPORT_SUCCESS: {
			return {
				...state,
				downloadDetailedReportLoading: false,
				downloadDetailedReportMsg: action.res,
			};
		}
		case Actions.types.DOWNLOAD_DETAILED_REPORT_FAIL: {
			return {
				...state,
				downloadDetailedReportLoading: false,
				downloadDetailedReportMsg: null,
			};
		}

		case Actions.types.MISSING_EMP_TEMPLATE_DOWNLOAD: {
			return {
				...state,
				missingEmpTemplateLoading: true,
				missingEmpTemplateMsg: null,
			};
		}
		case Actions.types.MISSING_EMP_TEMPLATE_DOWNLOAD_SUCCESS: {
			return {
				...state,
				missingEmpTemplateLoading: false,
				missingEmpTemplateMsg: action.res,
			};
		}
		case Actions.types.MISSING_EMP_TEMPLATE_DOWNLOAD_FAIL: {
			return {
				...state,
				missingEmpTemplateLoading: false,
				missingEmpTemplateMsg: null,
			};
		}

		case Actions.types.UPDATE_MISSING_EMPLOYEE_LIST: {
			return {
				...state,
				updateMissingEmpLoading: true,
				errorData: null,
			};
		}
		case Actions.types.UPDATE_MISSING_EMPLOYEE_LIST_SUCCESS: {
			return {
				...state,
				updateMissingEmpLoading: false,
				errorData: action.res,
			};
		}
		case Actions.types.UPDATE_MISSING_EMPLOYEE_LIST_FAIL: {
			return {
				...state,
				updateMissingEmpLoading: false,
				errorData: null,
			};
		}

		case Actions.types.GET_UNEMPLOYMENT_EID_SUCCESS: {
			return {
				...state,
				isUnEmploymentLoading: false,
				unEmploymentEidData: action?.res,
			};
		}
		case Actions.types.GET_UNEMPLOYMENT_FAIL: {
			return {
				...state,
				isUnEmploymentLoading: false,
				unEmploymentCardHolderData: [],
				unEmploymentEidData: [],
			};
		}
		default:
			return state;
	}
};
export default unemploymentReducer;
