import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { entriesDropDown } from '@helpers';
import EdenredSearch from '@sharedComponent/search-input';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { icons } from 'src/assets/icons';
import action from '../Employee/action';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Table from '@pages/DesignSystem/Table/Table';
import ImageComponent from '@sharedComponent/image-component';
import Toast from '@pages/DesignSystem/Toast';
import { UN_EMPLOYMENT_TAB_LABELS } from '../constants';

function PendingDetails() {
	let [pageSize, setPageSize] = useState(10);
	let [pageNumber, setPageNumber] = useState(1);
	let [searchedText, setSearchedText] = useState('');
	let [employeeList, setEmployeeList] = useState([]);

	const dispatch = useDispatch();

	const {
		unEmploymentEmpLoading,
		unEmploymentPendineEmp,
		downloadDetailedReportLoading,
		downloadDetailedReportMsg
	} = useSelector((state) => state?.unemploymentReducer) || {}

	useEffect(() => {
		setEmployeeList(unEmploymentPendineEmp?.unemploymentInsuranceDetail);
	}, [unEmploymentPendineEmp]);

	function getEmploymentListData() {
		dispatch(action.creators.getUnEmploymentListWithStatus(pageSize, pageNumber, searchedText, UN_EMPLOYMENT_TAB_LABELS.PENDING));
	}

	useEffect(() => {
		getEmploymentListData()
	}, [pageSize, pageNumber, searchedText]);

	const colors = {
		error: '#FFBECB',
		warning: '#FEF5E8',
		success: '#CCECE6',
		info: '#CEE8FF',
	};

	const getIssueChip = (val) => {
		if (val === 'New Joinee' || val === 'Worker Details Missing' || val === 'Technical Error') {
			return (
				<p
					className="view_card_details_table_cell_data"
					style={{ backgroundColor: colors.error, padding: '3px 8px', borderRadius: '8px', display: 'flex', width: 'fit-content' }}>
					{val}
				</p>
			);
		} else if (val === 'Processing Policy') {
			return (
				<p
					className="view_card_details_table_cell_data"
					style={{ backgroundColor: colors.warning, padding: '3px 8px', borderRadius: '8px', display: 'flex', width: 'fit-content' }}>
					{val}
				</p>
			);
		} else if (val === 'By Edenred') {
			return (
				<p
					className="view_card_details_table_cell_data"
					style={{ backgroundColor: colors.success, padding: '3px 8px', borderRadius: '8px', display: 'flex', width: 'fit-content' }}>
					{val}
				</p>
			);
		} else if (val === 'Externally') {
			return (
				<p
					className="view_card_details_table_cell_data"
					style={{ backgroundColor: colors.info, padding: '3px 8px', borderRadius: '8px', display: 'flex', width: 'fit-content' }}>
					{val}
				</p>
			);
		} else {
			return (
				<p
					className="view_card_details_table_cell_data"
					style={{ backgroundColor: colors.error, padding: '3px 8px', borderRadius: '8px', display: 'flex', width: 'fit-content' }}>
					{val}
				</p>
			);
		}
	};

	const columns = [
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'employeeName',
			title: 'Name',
			dataIndex: 'employeeName',
			width: '25%',
			hidden: false,
			sort: true,
			activeTab: true,
			render: (val, rowData) => {
				return <Col
					style={{
						display: 'grid',
						gap: '2px'
					}}
				>
					<span style={{
						textDecoration: 'underline',
						color: '#2B769C',
						cursor: 'pointer',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
						{val}
					</span>
					<span style={{
						color: '#181919',
						fontSize: ' 10px',
						fontStyle: 'normal',
						fontWeight: 300,
						lineHeight: '12px', /* 120% */
					}}>
						{rowData?.employeeId || ''}
					</span>
				</Col>
			},
		},
		{
			sorter: (a, b) => a.emiratesId.localeCompare(b.emiratesId),
			key: 'emiratesId',
			title: 'Emirates ID',
			dataIndex: 'emiratesId',
			width: '15%',
			hidden: false,
			sort: true,
			activeTab: true,
			render: (val, val1) => {
				return <p className="view_card_details_table_cell_data">{val || '-'}</p>;
			},
		},
		{
			sorter: (a, b) => a.reason.localeCompare(b.reason),
			key: 'reason',
			title: 'Issue',
			dataIndex: 'reason',
			width: '20%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => {
				return val ? getIssueChip(val) : '-';
			},
		},
		{
			sorter: (a, b) => a.action.localeCompare(b.action),
			key: 'action',
			title: 'Action',
			dataIndex: 'action',
			width: '15%',
			hidden: false,
			activeTab: true,
			render: (val, val1) => (val ? <div dangerouslySetInnerHTML={{ __html: val }} /> : '-'),
		},
	];

	const handleViewCardTable = (res) => setPageSize(res.value);

	return (
		<div className="single-update">
			{downloadDetailedReportMsg && (
				<Toast
					color="#8EF9B3"
					visible={true}
					clearPopUp={() => {
						dispatch(action.creators.downloadDetailedReportSuccess(null));
					}}>
					{downloadDetailedReportMsg}
				</Toast>
			)}
			<Row className="single-update-content">
				<div className="search-and-show-count-container center">
					<div style={{ width: '70%', display: 'flex', gap: '25px' }}>
						<div style={{ marginRight: '30px', minWidth: '55%' }}>
							<EdenredSearch
								value={searchedText}
								placeholder="Search by Name, Employee ID..."
								onChange={(event) => setSearchedText(event.target.value)}
							/>
						</div>
						<EdenredButtons
							style={{ width: 'fit-content' }}
							{...{
								btnType: 'secondary',
							}}
							leftIcon={
								downloadDetailedReportLoading ? (
									<div className={`loader secondary_loader`} style={{ width: '15px', height: '15px' }} />
								) : (
									<ImageComponent src={icons.not_focus.ContentEdit} />
								)
							}
							onClick={() => {
								dispatch(action.creators.downloadDetailedReportStart(UN_EMPLOYMENT_TAB_LABELS.PENDING));
							}}>
							Download Detailed Report
						</EdenredButtons>
					</div>
					<div className="entries-select-container center">
						<ETypo b2 light color="#484B52" style={{ paddingRight: '10px' }}>
							Show
						</ETypo>
						<MultiSelect
							{...{
								Option: entriesDropDown,
								value: pageSize,
								dropDownValue: 'value',
								searchVisibility: false,
								onChange: (page) => handleViewCardTable(page, 'pageSize'),
								checkboxIcon: (
									<span className="entries_check_box">
										<img src={icons.not_focus.tick} alt="check" />
									</span>
								),
							}}
						/>
						<ETypo b2 light color="#484B52" style={{ paddingLeft: '10px' }}>
							Entries
						</ETypo>
					</div>
				</div>

				<Table columns={columns} data={employeeList} tableLoading={unEmploymentEmpLoading} />

				<div className="emp-card-pagination-container">
					<ETypo b3 light color="#484B52">
						Showing {employeeList?.length} out of {unEmploymentPendineEmp?.totalCount} Employees
					</ETypo>
					<Pagination
						{...{
							currentPage: pageNumber,
							totalCount: unEmploymentPendineEmp?.totalCount,
							pageSize: pageSize,
							onPageChange: (page) => setPageNumber(page),
						}}
					/>
				</div>
			</Row>
		</div>
	);
}

export default PendingDetails;
