import React, { useEffect, useState } from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import EDragger from '@pages/DesignSystem/Dragger';
import { focus_state_icons } from '@assets/icons/focus_state';
import { Card, Col, Row } from 'antd';
import FadeIn from '@sharedComponent/AnimatedComponent';
import ErrorTable from '@sharedComponent/error-table';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from '@assets/icons/not_focus';
import { useDispatch, useSelector } from 'react-redux';
import HintCard from '@pages/DesignSystem/hindcard';
import { SITEMAP } from 'src/routes/sitemap';
import { useHistory, useLocation } from 'react-router-dom';
import { employeeVerifyActions } from '../redux';
import { CustomAlertNotification } from '@pages/Leave-Management/request-leave/alert-notification/alert-notification';
import { UPLOAD_SHEET_CONSTANTS } from './constants';

const UploadSheet = ({ disableContinueButton, setDisableContinueButton = () => {}, proceedToUpload, setproceedToUpload = () => {} }) => {
	const [hasErrorFile, setHasErrorFile] = useState(false);
	const [file, setFile] = useState(null);
	const [invalidFileType, setInvalidFileType] = useState(false);
	const [invalidFileSize, setInvalidFileSize] = useState(false);
	const [excelError, setExcelError] = useState([]);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const employeeVerificationData = useSelector((state) => state.employees.data);
	const [errordataList, setErrorDataList] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const { dashboard } = SITEMAP.employees.verification;
	const previewLink = dashboard.employeePreview;
	const locationStateUser = location?.state?.user || null;

	useEffect(() => {
		if (proceedToUpload && file) {
			dispatch(employeeVerifyActions.creators.uploadInvalidEmployees(file));
			setShowAlert(false);
		}
		return () => {
			setproceedToUpload(false);
			setInvalidFileSize(false);
			setInvalidFileType(false);
			setHasErrorFile(false);
			setExcelError([]);
		};
	}, [proceedToUpload]);

	useEffect(() => {
		if (!file && !disableContinueButton) {
			setDisableContinueButton(true);
		}
		if (file || excelError?.length) {
			setShowAlert(true);
		}
		return () => {
			setShowAlert(false);
		};
	}, [file]);

	useEffect(() => {
		const { employeeList, errorData } = employeeVerificationData || {};
		if (employeeList && !errorData) {
			if (locationStateUser) {
				history.push({ pathname: previewLink, state: { user: locationStateUser } });
			} else {
				history.push(previewLink);
			}
		} else if (errorData) {
			setShowAlert(true);
			setHasErrorFile(true);
			setExcelError(errorData);
			setproceedToUpload(false);
		}
	}, [employeeVerificationData]);

	const draggerIcon = () => {
		if (hasErrorFile) {
			return focus_state_icons.NewDesignErro;
		} else if (file) {
			return focus_state_icons.NewDesignCheck;
		} else {
			return focus_state_icons.hrUpload;
		}
	};
	const draggerStatus = () => {
		if (file) {
			return 'done';
		} else {
			return 'new';
		}
	};

	const validExcelFiles = (file) => {
		const isFileSizeLessThan4mb = file.size / 1024 / 1024 < 4; // < 4mb check
		const validMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
		const validFileType = validMimeTypes.includes(file.type);
		const validFileSize = isFileSizeLessThan4mb;
		if (!validFileType) {
			setInvalidFileType(true);
		}
		if (!validFileSize) {
			setInvalidFileSize(true);
		}
		return validFileType && validFileSize;
	};

	const handleDragger = (file) => {
		if (validExcelFiles(file)) {
			setFile(file);
			setDisableContinueButton(false);
		} else {
			setHasErrorFile(true);
			setDisableContinueButton(true);
		}
	};

	const loadErrorData = (errorDataArray) => {
		if (!errordataList.length) {
			setErrorDataList(errorDataArray);
		} else {
			let newArrayData = [...errordataList, ...errorDataArray];
			setErrorDataList(newArrayData);
		}
	};
	const onRemoveUpload = () => {
		setFile(null);
		setInvalidFileSize(false);
		setInvalidFileType(false);
		setHasErrorFile(false);
		setExcelError([]);
		setproceedToUpload(false);
		setDisableContinueButton(true);
		dispatch(employeeVerifyActions.creators.clearEmployeeData());
	};

	return (
		<>
			<CustomAlertNotification
				{...{
					when: showAlert,
					message: 'Unsaved Changes',
					description: (
						<>
							Are you sure you want to leave this page?
							<br />
							Your Changes may not be saved
						</>
					),
					okText: 'Leave',
					cancelText: 'Cancel',
					onOK: () => {
						onRemoveUpload();
						return true;
					},
					onCancel: () => true,
				}}
			/>
			<div className="upload_sheet_text_content" data-testid="upload-sheet-text-contents-test">
				<ETypo h4 medium data-testid="upload-sheet-title-test">
					{UPLOAD_SHEET_CONSTANTS.cardTitle}
				</ETypo>
				<ETypo color="#484B52" b1 light>
					<ol className="upload_sheet_desc" data-testid="upload-sheet-desc-test">
						{UPLOAD_SHEET_CONSTANTS.cardDesc.map((desc, i) => (
							<li key={i}>{desc}</li>
						))}
					</ol>
				</ETypo>
			</div>
			<div>
				<EDragger
					{...{
						idleIcon: draggerIcon(),
						draggerStatus: draggerStatus(),
						uploadDeleteIcon: focus_state_icons.NewDelete,
						onChange: handleDragger,
						onRemoveUpload,
						acceptedTypes: '.xls, .xlsx,',
						fileMaxSize: 'Max file size: 4Mb',
					}}
				/>
				{(invalidFileSize || invalidFileType) && (
					<Col style={{ marginTop: '10px' }}>
						<HintCard
							{...{
								icon: focus_state_icons.hrDocumentExpired,
								bgColor: '#fff6f5',
								hint: invalidFileSize
									? 'File must be smaller than 4MB!'
									: invalidFileType
									? `Invalid format. Please upload one of these formats ${['.xls', '.xlsx'].join()}`
									: '',
							}}
						/>
					</Col>
				)}
				{hasErrorFile ? (
					<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error">
						<Card className="card_action">
							<Row>
								<Col xl={1} sm={3} xs={3} md={2}>
									<ImageComponent src={not_focus_icons.Expired} />
								</Col>
								<Col xl={22} md={20} sm={21} xs={21} className="tip_web-view">
									Please correct the following errors and upload the file again.
								</Col>
								<Col xl={22} md={20} sm={21} xs={21} className="tip_mobile-view">
									Please correct the following errors and upload the file again.
								</Col>
							</Row>
						</Card>
					</Col>
				) : (
					''
				)}
				{excelError.length > 0 ? (
					<FadeIn delay={0.5} style={{ height: 'unset' }}>
						<Col xl={24} md={24} sm={24} lg={24} xs={24} className="card_error">
							<ErrorTable data={excelError} loadErrorData={loadErrorData} />
						</Col>
					</FadeIn>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default UploadSheet;
