import { createSelector } from 'reselect';

import { initState } from './monthwise-reducer';

const salaryMonthWise = (state) => state.salaryMonthWise || initState;
const isDepartmentLoading = createSelector(salaryMonthWise, (cnter) => cnter.isDepartmentLoading);
const departmentDetails = createSelector(salaryMonthWise, (cnter) => cnter.departmentDetails);
const departmentError = createSelector(salaryMonthWise, (cnter) => cnter.departmentError);

export default {
	salaryMonthWise,
	isDepartmentLoading,
	departmentDetails,
	departmentError,
};
