import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { syncLocalStorage } from '@helpers';
import { API_STATUS_CODES, APP_VARIABLES } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import Selectors from '@sharedComponent/app-action-reducer/selectors';
import ETypo from '@pages/DesignSystem/Typo';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { useSelector } from 'react-redux';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { UnemploymentInsuranceBanner } from './unemployment-insurance-banner';
import { UnemploymentInsuranceAvoidFine } from './unemployment-insurance-avoid-fine';
import EwaPopup from './ewaPopup';
import { OutStandingInvoiceBanner, UnemploymentRenewalBanner } from '@sharedComponent/banner';
import UserService from 'src/services/user-service';
import { LOCAL_STORAGE_KEYS } from '@constants/localStorage';
import { DashboardFirstSection, DashboardFooter, DashboardSecondSection, DashboardThirdSection } from './sections/index';
import '../../../../DesktopFix.css';

const MainDashboardWebPresentational = (props) => {
	const {
		currentUser,
		employeeCount,
		isEmployeeCountLoading,
		payrollHistory,
		totalCardRequest,
		isPayrollHistoryLoading,
		isCardHistoryLoading,
		cardOrderTotalData,
		isEmployeePaidLoading,
		employeePaidByMonth,
		isPayrollAmountLoading,
		employeePaidByPayroll,
	} = props || {};
	const { invoiceDetails, unEmploymentBannerData, showInvoiceMenuFlag } = useSelector((state) => state?.sharedStates) || {};
	const [showUnemploymentInsuAvoid, setShowUnemploymentInsuAvoid] = useState(false);
	const [showBeforeRenewalBanner, setShowBeforeRenewalBanner] = useState(false);
	const history = useHistory();
	useEffect(() => {
		isShowRenewalBanner();
	}, [isDashboardRenewalBanner, unEmploymentBannerData]);

	useEffect(() => {
		getCoprateIds();
	}, []);

	useEffect(() => {
		setShowUnemploymentInsuAvoid(JSON.parse(localStorage.getItem('showUnemploymentBanner')));
	}, [JSON.parse(localStorage.getItem('showUnemploymentBanner'))]);

	const isDashboardRenewalBanner = syncLocalStorage.getItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner);

	function isCorporateBulkSubscribed() {
		const { displayBanner } = unEmploymentBannerData ? unEmploymentBannerData?.data : {};
		// if the unEmploymentBannerData display banner was false means client was bulk subscribed and we show the banner
		if (displayBanner === false) {
			return true;
		}
		return false;
	}
	const isBulkSubscibed = isCorporateBulkSubscribed();

	function isShowRenewalBanner() {
		const parsedRenewalKey = isDashboardRenewalBanner ? JSON.parse(isDashboardRenewalBanner) : '';
		if (!isBulkSubscibed) {
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner);
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal);
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal);
		}
		// if the user not seen a banner yet it will come as false and we need to show the banner
		if (parsedRenewalKey === false && isBulkSubscibed) {
			setShowBeforeRenewalBanner(true);
		}
	}

	const [corporateDetails, setCorporateDetails] = useState({
		address2: null,
		address3: null,
		amount: null,
		cbin: null,
		city: null,
		corporateId: null,
		corporateName: null,
		countryCode: null,
		emailId: null,
		embossingName: null,
		rmName: null,
		zipCode: null,
		phNumber: null,
		email: null,
		name: null,
	});

	const getCoprateIds = () => {
		let corporate = Object.assign(
			{},
			...(props?.corporateIds || []).filter((corporate) => corporate.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
		);
		if (corporate) {
			if (corporate?.rmName === 'CSU' || corporate?.rmName === '' || !corporate?.rmName) {
				setCorporateDetails({ ...corporate, name: null, email: 'support.ae@edenred.com', phNumber: ' +971 4 521 4000 ' });
			} else if (corporate?.rmName === 'Marcel') {
				setCorporateDetails({ ...corporate, name: ' Marcel Daniel ', email: ' marcel.daniel@edenred.com ', phNumber: ' +971 56 4335249 ' });
			} else if (corporate?.rmName === 'Bahaa') {
				setCorporateDetails({ ...corporate, name: ' Bahaa Assouma ', email: ' bahaa.assouma@edenred.com ', phNumber: ' +971 54 4483470 ' });
			} else if (corporate?.rmName === 'Melissa') {
				setCorporateDetails({ ...corporate, name: ' Melissa Lobo ', email: ' melissa.lobo@edenred.com ', phNumber: ' +971 52 7425148 ' });
			} else if (corporate?.rmName === 'Priyanka Sengar') {
				setCorporateDetails({
					...corporate,
					name: ' Priyanka Sengar ',
					email: ' priyanka.sengar@edenred.com ',
					phNumber: ' +971 50 4275370 ',
				});
			} else if (corporate?.rmName === 'Reshma Prasad') {
				setCorporateDetails({
					...corporate,
					name: 'Reshma Prasad',
					email: 'reshma.prasad@edenred.com',
					phNumber: '+971 50 4275370',
				});
			}
		}
	};
	const hrOnBoardingFlowCompleted = useSelector((state) => state?.sharedStates?.userNavigation?.hrOnBoardingFlowCompleted) || false;
	const { subscriptionProds } = useSelector((state) => state?.sharedStates);

	async function iloeRenewalBannerAction() {
		const userMenuApi = new UserService();
		const payload = {
			iloeCheckNow: true,
		};
		setShowBeforeRenewalBanner(false);
		const res = await userMenuApi.postUserNavigationMenu(payload);
		// make sure its status 200 and not 500 error
		if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
			return;
		} else if (res.status === API_STATUS_CODES.SUCCESS) {
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner);
		}
	}

	// Props for each sections
	const dashboardFirstSectionProps = {
		currentUser,
		employeeCount,
		isEmployeeCountLoading,
		setShowUnemploymentInsuAvoid,
		corporateDetails,
	};
	const dashboardSecondSectionProps = {
		payrollHistory,
		totalCardRequest,
		isPayrollHistoryLoading,
		isCardHistoryLoading,
		cardOrderTotalData,
	};
	const dashboardThirdSectionProps = {
		currentUser,
		isEmployeePaidLoading,
		employeePaidByMonth,
		isPayrollAmountLoading,
		employeePaidByPayroll,
	};

	const showInvoiceBanner = (menuFlag = false, details = {}) => {
		// If the invoice menu on sidenav was enabled
		// and if dashboard flag was true from invoice details then only this banner will be shown
		if (menuFlag && details && details?.dashboard) {
			return true;
		}
		return false;
	};

	return (
		<>
			<EwaPopup />
			{showBeforeRenewalBanner && <UnemploymentRenewalBanner action={iloeRenewalBannerAction} />}
			{hrOnBoardingFlowCompleted !== true && !subscriptionProds.includes('') && (
				<div className="dashboard-info-wrapper">
					<div className="dashboard">
						<ImageComponent src={focus_state_icons?.discount} />
						<ETypo medium b1>
							NEW! Get automated calculations and payslips with the new features: Allowances, Additions, and Deductions. Setup now!
						</ETypo>
					</div>
					<span>
						<EdenredButtons
							btnType="secondary"
							children="Get Started"
							className="getStart-btn"
							onClick={() => history.push('/new-hr-features/summary')}
							rightIcon={<ImageComponent src={not_focus_icons?.arrowRight} />}
						/>
					</span>
				</div>
			)}

			{unEmploymentBannerData?.data?.displayBanner && showUnemploymentInsuAvoid ? (
				<>
					{/* we show invoice banner only when the below condition was true*/}
					{showInvoiceBanner(showInvoiceMenuFlag, invoiceDetails) && <OutStandingInvoiceBanner />}
					<UnemploymentInsuranceBanner {...{ setShowUnemploymentInsuAvoid }} /> <UnemploymentInsuranceAvoidFine />
				</>
			) : (
				<>
					<DashboardFirstSection {...dashboardFirstSectionProps} invoiceFlag={showInvoiceBanner(showInvoiceMenuFlag, invoiceDetails)} />
					<DashboardSecondSection {...dashboardSecondSectionProps} />
					<DashboardThirdSection {...dashboardThirdSectionProps} />
					<DashboardFooter corporateDetails={corporateDetails} />
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		corporateIds: Selectors.corporateIds(state),
		user: state.oidc.user,
	};
};
const Connected = connect(mapStateToProps, null)(MainDashboardWebPresentational);
export default Connected;
