import './replacement-popup.scss';

import { Col, Modal, Row, Spin } from 'antd';

import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import { icons } from 'src/assets/icons';
import { LoadingOutlined } from '@ant-design/icons';

export default function UploadPayrollPopup({
	visible,
	message,
	subMessage,
	subMessage1,
	closePopup,
	autoFixEmployeeFileError,
	temp,
	index,
	className,
	okText,
	cancelText,
	loading = false,
	destroyOnClose = false,
}) {
	// const []

	const closeModal = () => {
		closePopup();
	};
	const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#ffffff' }} spin />;
	return (
		<Modal
			destroyOnClose={destroyOnClose}
			className={`modal_container replacementCardModal document-expire upload-pay-popup ${className}`}
			centered
			footer={false}
			closable={false}
			maskClosable={false}
			bodyStyle={{ height: '194px', padding: '0px' }}
			maskStyle={{ backgroundColor: 'white', opacity: '0.8' }}
			width="450px"
			visible={visible}
			onOk={() => false}
			onCancel={() => true}>
			<div className="main_popup_container">
				<div className="message_container">
					<div className={className === 'announcement-save-popup' ? 'popup_heading_div1' : 'popup_heading_div'}>
						{message && (
							<EdenredTypo className={className === 'announcement-save-popup' ? 'popup_heading_text1' : 'popup_heading_text'}>
								{message}
							</EdenredTypo>
						)}
						{subMessage && <EdenredTypo className="popup_message_text">{subMessage}</EdenredTypo>}
						{subMessage1 && <EdenredTypo className="popup_message_text">{subMessage1}</EdenredTypo>}
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
					<div className="routing_container" style={{ cursor: 'pointer', backgroundColor: '#eff1f5' }} onClick={() => closeModal()}>
						<span>
							<EdenredTypo className="back_router_text" style={{ color: '#484b52' }}>
								{cancelText ? cancelText : 'No'}
							</EdenredTypo>
						</span>
					</div>
					<div
						className="routing_container"
						style={{ cursor: 'pointer' }}
						onClick={() => !loading && autoFixEmployeeFileError(temp, index)}>
						<span>
							{loading ? (
								<Spin indicator={antIcon} />
							) : (
								<EdenredTypo className="back_router_text">{okText ? okText : 'Yes'}</EdenredTypo>
							)}
						</span>
					</div>
				</div>
			</div>
		</Modal>
	);
}
