import React from 'react';
import { images } from 'src/assets/img';
import { useHistory } from 'react-router-dom';
import './PayrollNoData.scss';

const PayrollNoData = ({ tableLoading, url }) => {
	const history = useHistory();
	return (
		!tableLoading && (
			<div className="payroll-nodata-outter">
				<div className="payroll-nodata-inner">
					<img src={images.Payroll} alt="" srcset="" />
					<div className="payroll-nodata-text">
						<p>Add your employees now to start</p>
						<p>processing their salaries!</p>
					</div>
					<div className="payroll-nodata-button">
						<button onClick={() => history.push(url ? url : '/employees/add/multiple/employees/upload')}>Add Employee</button>
					</div>
				</div>
			</div>
		)
	);
};

export default PayrollNoData;
