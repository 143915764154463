import React from 'react';
import { images } from 'src/assets/img';
import './progressbar1.scss';
const Progressbar1 = () => {
	const array = [
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.progresstick,
			isprogess: false,
			status: 'success',
			textcolor: 'bluecolor',
		},
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.progressdanger,
			isprogess: false,
			status: 'success',
			textcolor: 'bluecolor',
		},
		{
			title: 'payroll',
			Description: 'payroll values defention',
			icon: images.greytick,
			isprogess: false,
			status: 'disable',
			textcolor: 'disable',
		},
	];
	return (
		<div style={{ display: 'flex' }}>
			{array.map((items) => (
				<div className="progessbar-inner">
					<div className="progessbar">
						<div className={`border ${items?.status}`}></div>
					</div>
					<div>
						<p className={items.status !== 'disable' ? 'title' : 'disable-title'}>{items.title}</p>
						<p className={items.status !== 'disable' ? 'desc' : 'disable'}>{items.Description}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Progressbar1;
