import React from 'react';
import CardOrderStatusFunctional from './components/card-order-status-functional';

import './card-order-status.scss';
import './card-order-status-mobile.scss';

const CardOrderStatus = () => {
	return <CardOrderStatusFunctional />;
};

export default CardOrderStatus;
