import React from 'react';
import UnauthorizedAccess from 'src/helpers/UnauthorizedAccess';
import {  useSelector } from 'react-redux';
import TeamsPayrollFunctional from './components/teams-payroll-functional';

const AuthorizedAccess=UnauthorizedAccess(TeamsPayrollFunctional)

const TeamsPayrollPage = (values) => {
	let currentUser = useSelector((state)=>state?.sharedStates?.currentUser?.roles[0]?.name);
	return (
		<div>
			<AuthorizedAccess {...{currentUser, values, }} />
		</div>
	);
};

export default TeamsPayrollPage;
