import React from 'react';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import InviteTeamMember from '../../team-permission/invite-team-member/invite-team-member-page';
const Admissionpermission = () => {
	return (
		<>
			<SetupHeader />
			<div className="setup-invite-teammember">
				<InviteTeamMember issetup={true} />
			</div>
		</>
	);
};
// style={{  }}

export default Admissionpermission;
