export const videoList = [
	{
		name: 'C3Pay - ATM PIN',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_English.mp4',
				name: 'Signup_ATM PIN_English',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284995756-79aec1ddeec95c5a1517c9a4ecca76f35a4857152d33e987a_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Bangla.mp4',
				name: 'Signup_ATM PIN_Bangla',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284994590-8e7b8b9d9e0ebb569f5562bde38317a54cf5be586750412fa_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Hindi.mp4',
				name: 'Signup_ATM PIN_Hindi',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284993114-c5b24d45248156fb55bd9191f73a3113a8ee4da20020c214a_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Nepali.mp4',
				name: 'Signup_ATM PIN_Nepali',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284992565-9e42befb0a6c877d71b7d4b1438243a152114640fd39df30f_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Arabic.mp4',
				name: 'Signup_ATM PIN_Arabic',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284991388-2a9049ebb5828fe18ed61c904007b9c24dc6241d565084a09_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Sinhala.mp4',
				name: 'Signup_ATM PIN_Sinhala',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284990687-d173915cd5f0fe27f99aa558ef1e216115e745a1ff68e397e_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Tagalog.mp4',
				name: 'Signup_ATM PIN_Tagalog',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284989576-d546548e2a77d16334f2968c911bbd6811d33920a04d8d53c_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Signup_ATM_PIN_Tagalog.mp4',
				name: 'Signup_ATM PIN_Urdu',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284988901-9ba82df95a914a134ea964bf3bcf3a4a3f5d3fd0ec37d7355_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'C3Pay - Bill Payments',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/(new)_bills_payment_nol_flow.mp4',
				name: '(New) Bills Payment Nol Flow',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1433592376-c39a5e9b8f2b9b4fd9ea5dc51f96f4bf7b54d10eded1d5c60e3c70e1db6dbf36-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/(new)_bill_payments_philippines.mp4',
				name: '(New) Bill Payments Philippines',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1433592105-0f4c624458a75bbfa67e79a34d1baaea7f22847c33f19467c78b80e603fc6087-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/(New)_Bill_Payments_Pakistan.mp4',
				name: '(New) Bill Payments Pakistan',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1433597497-17c4bd1a0987d46c777e4f62183c2094d2dcde70a644a8942f9c2cdaa6538574-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/(new)_bill_payments_nigeria.mp4',
				name: '(New) Bill Payments Nigeria',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1433591314-a84a78497dc0da76b3c6c1f0d12bbdc91ce40537884c60fb26358765dd82487c-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/(new)_bill_payments_nepal.mp4',
				name: '(New) Bill Payments Nepal',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1433523313-51dc5dc0fed993f3f71640045e99bc8d6f5cb2d1f5006070aa8355639f17c11c-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/(new)_bills_payment_nol_flow.mp4',
				name: '(New) Bill Payments India',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1433514894-44ca507c324652a1d304c056e2da965972d161de13bad0b6c746ab67aa6c5928-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Local_Bill_Payments__UAE.mp4',
				name: 'Local Bill Payments: UAE',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1395731007-4183c60093ecc07675fb39700e556b9594377cdfc69d5437ebdef339c5bdf807-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/billpayments/Bill_Payments__Nigeria.mp4',
				name: 'Bill Payments: Nigeria',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1395730345-6a7b746053c0ceed76a0e59339f180fbc6cee934babaec16ce185c9521811dbc-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: '	https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/billpayments/Bill_Payments__Nepal.mp4',
				name: 'Bill Payments: Nepal',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1395727689-5072d7ec2d08428292c43626243f6c127440b052b84724109e50c75de41b73ed-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/billpayments/Bill_Payments__Philippines.mp4',
				name: 'Bill Payments: Philippines',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1395728920-32365de5f6291a808981d3359224ff08dc52669b71994df8228e3f0bb37d4594-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/billpayments/Bill_Payments__Pakistan.mp4',
				name: 'Bill Payments: Pakistan',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1395727399-bccf9de72c3080419dbb89c2180933abff17246812d4566b2399f144f7b326c2-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: '	https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/billpayments/Bill_Payments__India.mp4',
				name: 'Bill Payments: India',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1395725273-c107a9001b7ce5d18eb8bc76d3be9a4d054ce91c01ddcf582798290df717821d-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'C3Pay - Money Transfer',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Money_Transfer_Sri_Lanka.mp4',
				name: 'Money Transfer_Sri Lanka',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1283182803-6d66f0f51b9322ce3814f8bf1f3788c6a196a459e2e787d9b_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Money_Transfer_Philippines.mp4',
				name: 'Money Transfer_Philippines',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1283182367-a0a95c3353af964bb57b9c974c61637317aa8be5d14d30880_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Money_Transfer_Pakistan_(Urdu).mp4',
				name: 'Money Transfer_Pakistan (Urdu)',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1283179524-d93679162a4ae9d6ad0c68077ab9bc1237f48510679eaeb03_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Money_Transfer_Nepal_(Nepali).mp4',
				name: 'Money Transfer_Nepal (Nepali)',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1283180077-829c269098bda365cc8c8227ab5bfe6c7207a271d2c768b5a_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Money_Transfer_India_(Hindi).mp4',
				name: 'Money Transfer_India (Hindi)',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1283178044-f102c3d7490d5449196af55bcb078727d9c70cf630b47cc8c_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Money_Transfer_Bangladesh_(Bangla).mp4',
				name: 'Money Transfer_Bangladesh (Bangla)',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1283178627-abc61e4abc333bcee45e8ecf40b69e6401e8933f6883d372f_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/How_to_send_money_with_the_C3Pay_app.mp4',
				name: 'How to send money with the C3Pay app',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F991131584-152c502c81b2f5e27c9d81cc3fc679a22c864031909da208cdf6b0fa2833b3ee-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'C3Pay - Referral Program',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Referral_Program_Hindi.mp4',
				name: 'Referral Program_English',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1295610590-97afec6fb701316538efe488f61cb1fa2fdee1ca75b88bbcc_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Referral_Program_English.mp4',
				name: 'Referral Program_Hindi',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1295609406-588cf4c196444f8312f6dcf8028882d61a7594da9b6cafaed_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'Employees',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_fill_in_the_Employee_File_template.mp4',
				name: 'How to fill in the Employee File template',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1291216012-c1d09cc29b4930f7d7dc0bf9faaf4d9ebcf678f19d3855a1e_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://vimeo.com/623313080',
				name: 'How to get your ATM PIN on the C3Pay app',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1265599378-cb27964e9a34823b197705b91018bd5e05296039dbf1d1b7f_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_raise_an_ATM_dispute.mp4',
				name: 'How to raise an ATM dispute',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1147308064-21eb604b2c0e2342ca431ef1ec7adfc2effec4c07427a3333962e08d743a7c24-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/What_are_the_common_errors_on_employee_files.mp4',
				name: 'What are the common errors on employee files',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508937-96a31f7b8107468a2559095e9ca7ac51d9f75c9779f4c5e6038fd3062c9bc6cd-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/What_are_the_available_employee_actions.mp4',
				name: 'What are the available employee actions',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508535-cf756bb00cd70b2f3b41817510c8ec8f936dfd2eb0b4be5a32df87337db229d3-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_download_account_numbers (720p).mp4',
				name: 'How to download account numbers',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508107-6fe95f9f0416a46e5eb85391212fb3bf1c9ff094697d79f3347cb89ef943c0a6-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_delete_an_employee.mp4',
				name: 'How to delete an employee',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508139-5dd277e9b40db9c5a213f3eedf69c2d77366a4b7ea13480bdd6c4acd2f38ac6c-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: `https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_check_card's_current_status_-_active_or_deleted.mp4`,
				name: `How to check card's current status - active or deleted`,
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508097-39e7c4668485c5d3741bc4ea2c6083c69cd510c445ccaf8aa3ccc0f8d102e09b-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://vimeo.com/500474428',
				name: 'How to add a bank account',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035507729-80120e497b6d1f4205de6b876274e0552321fb081ebd45ed456eb21755676c62-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_check_your_card_order_status.mp4',
				name: 'How to check your card order status',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1034632824-1c91c5eb50f32805f471091110684e1105ecb5c448f82a938b281a1e377fa245-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_verify_new_card_orders.mp4',
				name: 'How to verify new card orders',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1034631357-a122300725b58a20bee66b45d4d3f86ef3dc04f7907a8e95964884ec16f765de-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_order_new_cards.mp4',
				name: 'How to order new cards',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1034631203-cf1d1eadc52c2a6b02cfe4030a5cafb122904c2cbb0134f2b8dbd8c12aaffbfa-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'HRPayrollPlus',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo2_payslips.mp4',
				name: 'HRVideo2_Payslips',
				thumbnail: 'https://i.vimeocdn.com/video/1697213400-5d5b1070a32548fed8645bdcc0551738abcf648b1c7e073103da56262afc7901-d',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/HR/hrvideo1(aad).mp4',
				name: 'HRVideo1(AAD)',
				thumbnail: 'https://i.vimeocdn.com/video/1697211876-12c4331c43f396b79a8b7cf5c692486191d359b8c29077a8e10e0b8f32f15062-d',
			},
		],
	},
	{
		name: 'New Portal Tutorials',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/request_for_card_replacement. (720p).mp4',
				name: 'Request for card replacement',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372356-144147ff89d1204d5c7c59029476cb991515b6b7f137579c75bf071db9384086-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/how_to_verify_employee_deletion_request_ (720p).mp4',
				name: 'How to verify employee deletion request?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372631-b498f0e7deae3a4859e463b5c8033dd220b1063040b8b99c674c877963644c2b-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/how_to_raise_an_atm_dispute_ (720p).mp4',
				name: 'How to raise an atm dispute?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372340-654da2aa3b0e60908bb119e670656af51ec81fd71e008e33488d2f324a74873b-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/how_to_download_your_employees_account_numbers_ (720p).mp4',
				name: 'How to download your employees account numbers?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372290-f0293e26b1e0d85c676d168216cb8f4053d9db18b0cff599837e761e41291fd5-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/how_to_download_your_employees_account_numbers_ (720p).mp4',
				name: 'How to download your employees account numbers?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372233-6ce15bde36ec139e221b26bd387010f1a5f05e98fb88f24aee5b8cd02253d2eb-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_delete_an_employee.mp4',
				name: 'How to delete an employee?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372163-36c62d7ab315cf4b80e3ff9056ac9ed203aef6f562959fd08aac5890138e2538-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/approval_of_card_replacement_request. (720p).mp4',
				name: 'Approval of Card Replacement Request.',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1676372153-b1cd4fcd7727f88892c1e2bd553f6fb95fb3343bc6a9fa9f32d7aa7d19dce689-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/how_to_order_new_cards_for_employees_ (720p).mp4',
				name: 'How to order new cards for employees?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1645147690-b2f5460a79f9614be77aa37254e2f66290f73ea679d8112a1c7216a62b91a6b5-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/New_Portal/how_to_verify_new_card_orders_ (720p).mp4',
				name: 'How to verify new card orders?',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1645097613-914738a4c0ecf147a1f92062f64c6cc66afb0d3fcbda1bcb7c9ff54fe42b0dab-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'Payroll',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_update_the_WPS_information_of_your_employees.mp4',
				name: 'How to update the WPS information of your employees',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1221213664-555cf1cdf4d040762e44114dd489e63e99dc00713b9ebbd2dce631bd352cd946-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_check_your_payroll_account_statement (720p).mp4',
				name: 'How To Check Your Payroll Account Statement',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1126697325-39eb539b6d3b2633db8411d0145f7bb3d8cdc2ff74283c0266c7c71939381c1e-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_download_salary_reports (720p).mp4',
				name: 'How To Download Salary Reports',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1121108980-1337ebf9da478e18acce0a6f58f61a29553d8ac77e6087749756fab924a5524e-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/What_are_the_common_errors_on_salary_files.mp4',
				name: 'What are the common errors on salary files',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508797-7d792ea5daca58709d7e8a5f69f65b8748a26fe49942c5b92793208e8183cd36-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_verify_salary_processing_requests (720p).mp4',
				name: 'How to verify salary processing requests',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508511-fdb329be856f75af21c5bca6b18c776e8be7cfafb2b4af470dfe32ad49a55366-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_see_wps_and_non_wps_cut-off_timings (720p).mp4',
				name: 'How to see WPS and Non WPS cut-off timings',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508346-a07e45f1e6b8b33780815f13172304c82ecbe7a54ac85b7de12808b0dce10bc3-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_see_the_available_payroll_balance (720p).mp4',
				name: 'How to see the available payroll balance',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508388-f74bf01556febbe17f07b102a33f533ba47533cdd389435e3e3c81152fd1c1de-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_upload_your_proof_of_fund_transfer (720p).mp4',
				name: 'How to upload your proof of fund transfer',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1034632302-6723e944861f9c3df00e92f08ed9ca751807d59b632aeb4d6d02d3959f2cbd58-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/how_to_check_your_payroll_status (720p).mp4',
				name: 'How to check your payroll status',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1034632208-8d71b3c6cf7216fcbbc2cc2a23b412fcd28a13540e4422bcdd756ce64c402a1c-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_process_salaries.mp4',
				name: 'How to process salaries',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1219956777-9271a24d944274f7f6138f86ffc567a9c3fa93c1c088f90d94fb2d60cd2aeccb-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{ name: 'My videos', videoUrl: [] },
	{
		name: 'Recordings',
		videoUrl: [
			{
				link: 'https://vimeo.com/771891324/a2add43c4f',
				name: 'New Recording - 11/17/2022, 10:53:35 AM',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1549117882-a8d0ec128e97fb49e8e29592dd6cb3ec66fdc042cb2644c1ef4168d3d204c2ff-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'Replacement Cards and Statements',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_request_card_statements.mp4',
				name: 'How to request card statements',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508242-cf7d7ec6cad84abe1098abade426a0fa983a348d2dfe875c825862b28a25f722-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_request_card_replacements.mp4',
				name: 'How to request card replacements',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035508120-e358048832334eaafd1691f426f18e99e834c8d67d27118bb4bdb64fc8aca3d5-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'Team Permissions',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_change_verifier_approval_settings.mp4',
				name: 'How to change verifier approval settings',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1414589235-a263b135214c2af21bb7d2a1f4e3e999bee2d450449440b06e5e72095f0d9ec9-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/Old_Portal/How_to_add_new_users_and_difference_between_a_verifier_and_a_user.mp4',
				name: 'How to add new users and difference between a verifier and a user',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1035507785-a234ecb6aa721dc56a31c547b4fa6246a111df8ab6c07d03782ee1965acb1508-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
	{
		name: 'Unemployment Insurance',
		videoUrl: [
			{
				link: 'https://eae-c3pay-cdn-p.azureedge.net/money-transfer-videos/eng_version_audion_edited.mp4',
				name: 'Eng Version_Audion Edited',
				thumbnail:
					'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1577690552-4da1e96542d53a828edd4798d3909e5a5bd0042b8b59ebba9047a9ed50fb33f9-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
			},
		],
	},
];
