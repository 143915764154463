import React from 'react';
import { Col, Row } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import Uploaddocument from '@pages/payroll/EmployeesExpirymodule/component/Upload-document';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import './uploadSheet.scss';
import EdenredHeader from '@pages/main-layout/components/main-header';

const SetupLeaveUploadPresentational = ({
	leaveApproval,
	isLeaveUploadLoading,
	isLeaveDocUploaded,
	leaveUploadDetails,
	leaveApproveUploadSaga,
	isleaveApproveUpload,
	saveDocumentUploadReset,
	isSetup,
}) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
			{!isleaveApproveUpload && <SetupHeader />}
			<Row className="setup_leave_approval">
				{/* <Col xl={24} lg={24} md={24} sm={24} xs={24} className="setup_typo">
						<EdenredTypo>Leaves Balances</EdenredTypo>
					</Col> */}
				<Col className="setup_tabs">
					<Uploaddocument
						{...{
							setUpUpload: leaveApproval,
							isSetup: isSetup,
							setUpName: 'Leave Balances',
							draggerHeader: 'Upload Your Spreadsheet Here:',
							hintText: 'To complete this setup, fill in the leave balances of annual leave and sick leave.',
							nextPath: '/leave-approval-table-setup',
							docLink: '/leave-approval-setup',
							tableData: leaveUploadDetails,
							isLeaveUploadLoading,
							leaveUploadDetails,
							leaveApproveUploadSaga,
							uploadType: 'leavesApproval',
							isLeaveDocUploaded,
							isleaveApproveUpload,
							resetUploadDocuments: saveDocumentUploadReset,
						}}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default SetupLeaveUploadPresentational;
