import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkHrSubscription } from '@helpers';
import { APP_VARIABLES } from '@constants/app-constants';

const PREFIX = 'hr-filters-';

// hooks for handling local storage values
export default function useLocalStorage(key, initialValue) {
	const prefixedKey = PREFIX + key;

	const [value, setValue] = useState(() => {
		const jsonValue = localStorage.getItem(prefixedKey);
		if (jsonValue != null) return JSON.parse(jsonValue);

		if (typeof initialValue === 'function') {
			return initialValue();
		} else {
			return initialValue;
		}
	});

	useEffect(() => {
		localStorage.setItem(prefixedKey, JSON.stringify(value));
	}, [prefixedKey, value]);

	return [value, setValue];
}

export function useComponentVisible(initialIsVisible) {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef(null);

	const handleHideDropdown = (event) => {
		if (event.key === 'Escape') {
			setIsComponentVisible(false);
		}
	};

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleHideDropdown, true);
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('keydown', handleHideDropdown, true);
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
}
export function useQuery() {
	return new URLSearchParams(window.location.search);
}

export function useProtectedSidebarCreate(claimsArray, formatMenu, nEmployeeClaims, payrollClaimsValue) {
	const history = useHistory();

	const menuFormatted = formatMenu;

	const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));

	const [filteredMenu, setfilteredMenu] = useState([]);

	const cliamsRedirectObject = [
		{
			key: 'Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances',
			path: '/leaves/leaves_to_approve',
		},
		{
			key: 'Manage.Announcements',
			path: '/announcement',
		},
		{
			key: 'Manage.Documents',
			path: '/hrdocuments',
		},
		{
			key: 'Manage.Leaves',
			path: '/leaves/leaves_to_approve',
		},
		{
			key: 'Manage.Employees',
			path: '/',
		},
		{
			key: 'Manage.Payroll',
			path: '/',
		},
	];
	useEffect(() => {
		let menuBuffer = [];

		// let redirectString;
		// if (!localStorage.getItem('isRedirectNeeded')) {
		// 	cliamsRedirectObject.map((obj) => {
		// 		if (claimsArray.includes(obj.key)) {
		// 			redirectString = obj.path;
		// 			console.log('Logger::', redirectString);
		// 			// console.log('Placeholder::', claimsArray);
		// 		}
		// 	});
		// 	history.push(redirectString);
		// }
		// New Teams and Permission
		// Dashboard
		if (
			claimsArray.includes('Approve.Payroll.Requests') ||
			claimsArray.includes('Approve.Card.Cancellation') ||
			claimsArray.includes('Approve.Card.Orders') ||
			claimsArray.includes('Approve.Card.Replacement') ||
			(claimsArray.includes('Manage.Employees') && claimsArray.includes('Manage.Payroll')) ||
			(claimsArray.includes('Manage.Employees') && !claimsArray.includes('Manage.Payroll')) ||
			(!claimsArray.includes('Manage.Employees') && claimsArray.includes('Manage.Payroll'))
		) {
			const dashboardObject = menuFormatted.find((el) => el.key === 'dashboard');
			menuBuffer = [...menuBuffer, dashboardObject];
		}
		// Home
		const nHomePayrollClaims = payrollClaimsValue?.filter((data) => !data?.includes('Manage.Dashboard.View'));
		if (
			(claimsArray.includes('Manage.Leaves') && nHomePayrollClaims?.length === 0) ||
			(nEmployeeClaims?.length > 0 && nHomePayrollClaims?.length === 0)
		) {
			const homeObject = menuFormatted.find((el) => el.key === 'home');
			menuBuffer = [...menuBuffer, homeObject];
		}
		// Employee Leave Request
		if (
			(nEmployeeClaims?.length > 0 && claimsArray?.length === 0) ||
			(nEmployeeClaims?.length > 0 &&
				payrollClaimsValue?.length === 0 &&
				!claimsArray.includes('Manage.Leaves') &&
				!claimsArray.includes('Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances'))
		) {
			const empLeaveRequest = menuFormatted.find((el) => el.key === 'RequestLeave');
			menuBuffer = [...menuBuffer, empLeaveRequest];
		}
		// if (claimsArray.includes('Approve.Payroll.Requests')) {
		// 	const payrollObject = menuFormatted.find((el) => el.key === 'payroll');
		// 	if (claimsArray.includes('Manage.Roles.And.Permissions')) {
		// 		payrollObject.subMenus = payrollObject.subMenus.filter((el) => el.key !== 'run-payroll');
		// 	} else {
		// 		payrollObject.subMenus = payrollObject.subMenus;
		// 	}
		// 	menuBuffer = [...menuBuffer, payrollObject];
		// }

		// if (
		// 	claimsArray.includes('Approve.Card.Cancellation') ||
		// 	claimsArray.includes('Approve.Card.Order') ||
		// 	claimsArray.includes('Approve.Card.Replacement')
		// ) {
		// 	const employeesObject = menuFormatted.find((el) => el.key === 'employees');

		// 	menuBuffer = [...menuBuffer, employeesObject];
		// }
		//
		// if (claimsArray.includes('Manage.Employees') && claimsArray.includes('Manage.Payroll')) {
		// 	const dashboardObject = menuFormatted.find((el) => el.key === 'dashboard');

		// 	menuBuffer = [...menuBuffer, dashboardObject];
		// }

		// if (claimsArray.includes('Manage.Employees') && !claimsArray.includes('Manage.Payroll')) {
		// 	const dashboardObject = menuFormatted.find((el) => el.key === 'dashboard');

		// 	menuBuffer = [...menuBuffer, dashboardObject];
		// }

		// if (!claimsArray.includes('Manage.Employees') && claimsArray.includes('Manage.Payroll')) {
		// 	const dashboardObject = menuFormatted.find((el) => el.key === 'dashboard');

		// 	menuBuffer = [...menuBuffer, dashboardObject];
		// }
		// Payroll
		if (claimsArray.includes('Manage.Payroll') || claimsArray.includes('Approve.Payroll.Requests')) {
			const payrollObject = menuFormatted.find((el) => el.key === 'payroll');
			if (claimsArray.includes('Manage.Payroll') === false && claimsArray.includes('Approve.Payroll.Requests')) {
				payrollObject.subMenus = payrollObject.subMenus.filter((el) => el.key !== 'run-payroll');
			} else if (claimsArray.includes('Approve.Payroll.Requests') === false) {
				payrollObject.subMenus = payrollObject.subMenus.filter((el) => el.key !== 'approve-requests');
			} else {
				payrollObject.subMenus = payrollObject.subMenus;
			}
			menuBuffer = [...menuBuffer, payrollObject];
		}
		// Employee
		if (
			claimsArray.includes('Approve.Card.Cancellation') ||
			claimsArray.includes('Approve.Card.Orders') ||
			claimsArray.includes('Approve.Card.Replacement') ||
			claimsArray.includes('Manage.Employees')
		) {
			let employeesObject = menuFormatted.find((el) => el.key === 'employees');
			if (
				!claimsArray.includes('Approve.Card.Cancellation') &&
				!claimsArray.includes('Approve.Card.Orders') &&
				!claimsArray.includes('Approve.Card.Replacement')
			) {
				employeesObject.subMenus = employeesObject.subMenus.filter((el) => el.key !== 'approve-requests');
			} else {
				employeesObject.subMenus = employeesObject.subMenus;
			}
			//employeesObject = menuFormatted.filter((el) => el.key !== 'dashboard');
			//setfilteredMenu(filteredMenu.push(employeesObject));

			menuBuffer = [...menuBuffer, employeesObject];
		}
		// Leaves
		const leave = claimsArray.includes('Manage.Leaves');
		const approve = claimsArray.includes('Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances');
		if (leave) {
			const leaveObject = menuFormatted.find((el) => el.key === 'leaves');
			if (checkSubscription) {
				leaveObject.isVisible = true;
			}
			//console.log('leaveObject', leaveObject);
			if (leaveObject) {
				if (!approve) {
					const i = leaveObject.subMenus.findIndex((el) => el.key === 'approvalworkflow');
					if (i > -1) {
						leaveObject.subMenus = leaveObject.subMenus.filter((obj) => obj.key !== 'approvalworkflow');
					}
				}
				menuBuffer = [...menuBuffer, leaveObject];
			}
		}
		if (leave === false && approve) {
			const leaveObject = menuFormatted.find((el) => el.key === 'leaves');
			if (checkSubscription) {
				leaveObject.isVisible = true;
			}
			//console.log('leaveObject', leaveObject);
			if (leaveObject) {
				const i = leaveObject.subMenus.findIndex((el) => el.key === 'leavestoapprove');
				const j = leaveObject.subMenus.findIndex((el) => el.key === 'leavestatues');
				if (i > -1 && j > -1) {
					leaveObject.subMenus = leaveObject.subMenus.filter((obj) => obj.key !== 'leavestoapprove' && obj.key !== 'leavestatues');
				}
				menuBuffer = [...menuBuffer, leaveObject];
			}
		}
		// Hr Document
		if (claimsArray.includes('Manage.Documents')) {
			const documentObejct = menuFormatted.find((el) => el.key === 'documents');
			if (checkSubscription) {
				documentObejct.isVisible = true;
			}

			//console.log('documentObejct', documentObejct);

			//setfilteredMenu(filteredMenu.push(documentObejct));

			menuBuffer = [...menuBuffer, documentObejct];
		}
		// Payslip
		if (nEmployeeClaims?.length > 0) {
			const announcementObject = menuFormatted.find((el) => el.key === 'payslip');
			if (checkSubscription) {
				announcementObject.isVisible = true;
			}
			//setfilteredMenu(filteredMenu.push(announcementObject));

			menuBuffer = [...menuBuffer, announcementObject];
		}
		// Announcements
		if (claimsArray.includes('Manage.Announcements') || nEmployeeClaims?.length > 0) {
			const announcementObject = menuFormatted.find((el) => el.key === 'announcements');
			if (checkSubscription) {
				announcementObject.isVisible = true;
			}
			//setfilteredMenu(filteredMenu.push(announcementObject));

			menuBuffer = [...menuBuffer, announcementObject];
		}

		// Company
		const companyObject = menuFormatted.find((el) => el.key === 'company');
		if (companyObject && claimsArray?.length !== 0) {
			if (
				claimsArray.includes('Manage.Payroll') ||
				claimsArray.includes('Manage.Employees') ||
				claimsArray.includes('Approve.Payroll.Requests') ||
				claimsArray.includes('Approve.Card.Cancellation') ||
				claimsArray.includes('Approve.Card.Orders') ||
				claimsArray.includes('Approve.Card.Replacement')
			) {
				const submenu = companyObject.subMenus.findIndex((el) => el.key === 'company-setting');
				if (submenu > -1) {
					if (!claimsArray.includes('Manage.Roles.And.Permissions')) {
						companyObject.subMenus = companyObject.subMenus.filter((obj) => obj.key !== 'team-permissions');
					} else {
						companyObject.subMenus = companyObject.subMenus;
					}
				}
			} else {
				const submenu = companyObject.subMenus.findIndex((el) => el.key === 'company-setting');
				if (submenu > -1) {
					if (claimsArray.includes('Manage.Roles.And.Permissions')) {
						companyObject.subMenus = companyObject.subMenus.filter((obj) => obj.key !== 'company-setting');
					} else {
						companyObject.subMenus = companyObject.subMenus.filter((obj) => obj.key === 'company-information');
					}
				}
			}
			menuBuffer = [...menuBuffer, companyObject];
		}
		//
		const commonObject = menuFormatted.find((el) => el.key === 'designsystem');
		menuBuffer = [...menuBuffer, commonObject];
		//For hot fix
		//const teamObject = menuFormatted.find((el) => el.key === 'team-permissions');
		// Footer
		// hide menu if employee role is exists
		if (claimsArray?.length !== 0) {
			const healthObject = menuFormatted.find((el) => el.key === 'healthInsurance');
			const videoObject = menuFormatted.find((el) => el.key === 'videoTutorials');
			const helpObject = menuFormatted.find((el) => el.key === 'help');

			menuBuffer = [...menuBuffer, healthObject, videoObject, helpObject];
		}
		const logoutObject = menuFormatted.find((el) => el.key === 'logout');
		const unemploymentInsuranceObject = menuFormatted.find((el) => el.key === 'unemployment-insurance');
		//For hot fix
		menuBuffer = [...menuBuffer, unemploymentInsuranceObject, logoutObject];

		//console.log('menuBuffed', menuBuffer);

		setfilteredMenu(menuBuffer);
		localStorage.setItem('isRedirectNeeded', true);
		localStorage.setItem('isRedirectVerifierNeeded', true);
	}, []);

	return filteredMenu;
}
// old teams and permission
export function useProtectedVerifierSidebarCreate(claimsArray, formatMenu) {
	const history = useHistory();

	const menuFormatted = formatMenu;

	const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));

	const [filteredMenu, setfilteredMenu] = useState([]);

	const cliamsRedirectObject = [
		{
			key: 'Approval.Workflow',
			path: '/leaves/leaves_to_approve',
		},
		{
			key: 'Manage.Announcements',
			path: '/announcement',
		},
		{
			key: 'Manage.Documents',
			path: '/hrdocuments',
		},
		{
			key: 'Manage.Leaves',
			path: '/leaves/leaves_to_approve',
		},
		{
			key: 'Approve.Payroll.Request',
			path: '/',
		},
		{
			key: 'Approve.Card.Cancellation',
			path: '/',
		},
		{
			key: 'Approve.Card.Order',
			path: '/',
		},
		{
			key: 'Approve.Card.Replacement',
			path: '/',
		},
	];
	useEffect(() => {
		let menuBuffer = [];

		// if (!localStorage.getItem('isRedirectVerifierNeeded')) {
		// 	cliamsRedirectObject.map((obj) => {
		// 		if (claimsArray.includes(obj.key)) {
		// 			history.push(obj.path);
		// 		}
		// 	});
		// }

		// if (
		// 	claimsArray.includes('Approve.Payroll.Request') ||
		// 	claimsArray.includes('Approve.Card.Cancellation') ||
		// 	claimsArray.includes('Approve.Card.Order') ||
		// 	claimsArray.includes('Approve.Card.Replacement')
		// ) {
		// 	const dashboardObject = menuFormatted.find((el) => el.key === 'dashboard');
		// 	menuBuffer = [...menuBuffer, dashboardObject];
		// }

		// if (claimsArray.includes('Approve.Payroll.Request')) {
		// 	const payrollObject = menuFormatted.find((el) => el.key === 'payroll');

		// 	menuBuffer = [...menuBuffer, payrollObject];
		// }

		// if (
		// 	claimsArray.includes('Approve.Card.Cancellation') ||
		// 	claimsArray.includes('Approve.Card.Order') ||
		// 	claimsArray.includes('Approve.Card.Replacement')
		// ) {
		// 	const employeesObject = menuFormatted.find((el) => el.key === 'employees');

		// 	menuBuffer = [...menuBuffer, employeesObject];
		// }
		if (claimsArray.includes('Manage.Leaves')) {
			const leaveObject = menuFormatted.find((el) => el.key === 'leaves');
			if (checkSubscription) {
				leaveObject.isVisible = true;
			}
			//console.log('leaveObject', leaveObject);

			if (leaveObject) {
				if (!claimsArray.includes('Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances')) {
					const i = leaveObject.subMenus.findIndex((el) => el.key === 'approvalworkflow');

					if (i > -1) {
						leaveObject.subMenus = leaveObject.subMenus.filter((obj) => obj.key !== 'approvalworkflow');
					}
				}

				menuBuffer = [...menuBuffer, leaveObject];
			}
		}
		if (claimsArray.includes('Manage.Documents')) {
			const documentObejct = menuFormatted.find((el) => el.key === 'documents');
			if (checkSubscription) {
				documentObejct.isVisible = true;
			}

			//console.log('documentObejct', documentObejct);

			//setfilteredMenu(filteredMenu.push(documentObejct));

			menuBuffer = [...menuBuffer, documentObejct];
		}
		if (claimsArray.includes('Manage.Announcements')) {
			const announcementObject = menuFormatted.find((el) => el.key === 'announcements');
			if (checkSubscription) {
				announcementObject.isVisible = true;
			}
			//setfilteredMenu(filteredMenu.push(announcementObject));

			menuBuffer = [...menuBuffer, announcementObject];
		}
		const companyObject = menuFormatted.find((el) => el.key === 'company');
		if (companyObject) {
			if (!claimsArray.includes('Manage.Payroll')) {
				const submenu = companyObject.subMenus.findIndex((el) => el.key === 'company-setting');
				if (submenu > -1) {
					companyObject.subMenus = companyObject.subMenus.filter((obj) => obj.key !== 'company-setting');
				}
			}

			menuBuffer = [...menuBuffer, companyObject];
		}

		const healthObject = menuFormatted.find((el) => el.key === 'healthInsurance');
		const videoObject = menuFormatted.find((el) => el.key === 'videoTutorials');
		const helpObject = menuFormatted.find((el) => el.key === 'help');
		const logoutObject = menuFormatted.find((el) => el.key === 'logout');
		const designSystem = menuFormatted.find((el) => el.key === 'designSystem');
		//For hot fix
		menuBuffer = [...menuBuffer, healthObject, videoObject, helpObject, logoutObject, designSystem];

		setfilteredMenu(menuBuffer);
		localStorage.setItem('isRedirectVerifierNeeded', true);
	}, []);

	return filteredMenu;
}

// HotJar Identity API
export const useInitHotjar = (userName, corporateId, email) => {
	const [isHotjarEnabled, setIsHotjarEnabled] = useState(false);
	const user_Id = +new Date();
	useEffect(() => {
		if (!!userName && corporateId && !isHotjarEnabled) {
			window.hj('identify', user_Id, {
				userName: userName,
				corporateId: corporateId,
				email: email,
			});
			setIsHotjarEnabled(true);
		}
	}, [userName, corporateId]);
};
