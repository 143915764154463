import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import { Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from 'src/assets/icons';
import { getMonths, getYears } from '@helpers';
import EdenredHeader from '@pages/main-layout/components/main-header';
import moment from 'moment';
import ClevertapReact from 'src/utils/clever-tap';
const { Option } = Select;

const RunPayrollNowLater = ({ }) => {
	const [show, setShow] = useState(false);
	const customStyles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(255, 255, 255, 0.75)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'absolute',
			top: '17rem',
			left: '1rem',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'white',
			overflow: 'auto',
			borderRadius: '16px',
			outline: 'none',
			padding: '15px',
			width: '90%',
			boxShadow: '0px 10px 20px rgba(174, 182, 183, 0.5)',
		},
	};

	const yearLength = 6;
	const [years, setyears] = useState(
		getYears(12, 0).map((year) => {
			return {
				value: year,
			};
		})
	);
	const [currentIndex, setcurrentIndex] = useState(0);
	const [indexObject, setindex] = useState({
		start: currentIndex,
		end: currentIndex + yearLength,
	});
	const { start, end } = indexObject;
	const hasPreviousData = start >= yearLength;
	const hasNextData = years.slice(end, end + yearLength).length !== 0;
	const yearData = years.slice(indexObject.start, indexObject.end);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const monthDDList = [
		{ index: 0, label: 'January', isSelected: false },
		{ index: 1, label: 'February', isSelected: false },
		{ index: 2, label: 'March', isSelected: false },
		{ index: 3, label: 'April', isSelected: false },
		{ index: 4, label: 'May', isSelected: false },
		{ index: 5, label: 'June', isSelected: false },
		{ index: 6, label: 'July', isSelected: false },
		{ index: 7, label: 'August', isSelected: false },
		{ index: 8, label: 'September', isSelected: false },
		{ index: 9, label: 'October', isSelected: false },
		{ index: 10, label: 'November', isSelected: false },
		{ index: 11, label: 'December', isSelected: false },
	];
	const [Months, setMonths] = useState(monthDDList);
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

	const date = new Date().getDate();
	let Dates = getMonths(selectedMonth, selectedYear);
	const [selectedDate, setSelectedDate] = useState(null);

	const [viewDateModal, setViewDateModal] = useState(false);
	const [viewMonthModal, setViewMonthModal] = useState(false);
	const [viewYearModal, setViewYearModal] = useState(false);

	const handleYears = (type) => {
		switch (type) {
			case 'PRE':
				if (hasPreviousData) {
					setindex({
						...indexObject,
						start: start - yearLength,
						end: end - yearLength,
					});
				}
				break;
			case 'AFT':
				if (hasNextData) {
					setindex({
						...indexObject,
						start: start + yearLength,
						end: end + yearLength,
					});
				}
				break;

			default:
				break;
		}
	};

	const openDateModal = () => {
		setViewDateModal(true);
	};

	const closeDateModal = () => {
		setViewDateModal(false);
	};
	const openMonthModal = () => {
		setViewMonthModal(true);
	};

	const closeMonthModal = () => {
		setViewMonthModal(false);
	};

	const openYearModal = () => {
		setViewYearModal(true);
	};

	const closeYearModal = () => {
		setViewYearModal(false);
	};

	const isMonthSelected = (month) => {
		initializeMonth(month && month.index);
	};

	useEffect(() => {
		// initializeMonth(new Date().getMonth());
		handleChangeYear(new Date().getFullYear(), 1);
	}, []);

	useEffect(() => {
		Modal.setAppElement('body');
	}, [Modal]);

	const initializeMonth = (data) => {
		let months = Months;
		months.map((month, i) => {
			if (month.index === data) {
				month.isSelected = true;
				setSelectedMonth(month.label);
			} else {
				month.isSelected = false;
			}
		});
		setMonths([...months]);
		closeMonthModal();
	};

	const handleChangeDate = (date) => {
		setSelectedDate(date);
	};

	const handleChangeMonth = (monthItem) => {
		setSelectedMonth(monthItem);
	};

	// const handleChangeYear = (year) => { // TODO -NEED TO REMOVE
	// 	setSelectedYear(year);
	// };

	const handleChangeYear = async (year, defalut = 0) => {
		setSelectedYear(year);
		let currentYear = await new Date().getFullYear();
		let date = await new Date().setFullYear(year);
		let currentIndex = await new Date(date).getMonth();
		let newMonth = await [];
		if (currentYear == year) {
			newMonth = await monthDDList.filter((value) => {
				if (value.index >= currentIndex) {
					return value;
				}
			});
		} else {
			newMonth = await monthDDList;
		}
		await setMonths([...newMonth]);
		console.log('defalut', defalut);
		if (defalut == 1) {
			let month = monthDDList.filter((value) => value.index == new Date().getMonth());
			console.log(month && month[0].label);
			if (month && month[0].label) {
				console.log({ selectedMonth });
				setSelectedMonth(month[0].label);
				// setSelectedMonth(moment().month(month[0].label).format('MM'));
				console.log({ selectedMonth });
			}
		}
	};
	const { state } = useLocation();

	const format1 = 'YYYY-MM-DDThh:mm:ss';
	var date1 = new Date();
	const dateTime = moment(date1).format(format1);

	const history = useHistory();

	const {
		location: {
			state: { payrollForMonth, payrollForYear },
		},
	} = history;

	const handleNowLater = () => {
		ClevertapReact.event('Now_PayrollDate');
		history.push({
			pathname: '/payroll/run/channel/submit_payroll',
			state: { ...state, payrollRunDate: dateTime, payrollForMonth, payrollForYear },
		});
	};

	const handlenextdata = () => {
		history.push({
			pathname: '/payroll/run/channel/submit_payroll',
			state: { ...state, selectedMonth, selectedYear, selectedDate, payrollForMonth, payrollForYear },
		});
	};

	return (
		<>
			<EdenredHeader />
			<Row className="run_payroll_now_later_container">
				<Col xl={16} lg={16} md={16} sm={24} xs={24} className="run_payroll_now_later_header">
					<EdenredTypo>Do You Want To Run Payroll Now or Later?</EdenredTypo>
				</Col>
				<Col xl={22} lg={22} md={22} sm={24} xs={24} className="run_payroll_now_later_description">
					<EdenredTypo>
						Select 'Now' if you want to run payroll now (payroll will run immediately on weekdays) or 'Later' to run payroll at a later
						date.
					</EdenredTypo>
				</Col>
				<Row className="run_payroll_now_later_button_section">
					<Col xl={8} lg={8} md={8} sm={12} xs={12} className="run_payroll_later_button">
						<EdenredButton noShadow={true} type={'outline-g'} onClick={() => {
							ClevertapReact.event('Later_PayrollDate');
							setShow(true)
						}}>
							Later
						</EdenredButton>
					</Col>
					<Col xl={8} lg={8} md={8} sm={12} xs={12} className="run_payroll_now_button">
						{/* <Link to="/payroll/run/channel/submit_payroll"> */}
						<EdenredButton onClick={() => handleNowLater()}>Now</EdenredButton>
						{/* </Link> */}
					</Col>
				</Row>
				{show && (
					<Row className="run_payroll_later_part">
						<Col xl={16} lg={16} md={16} sm={24} xs={24} className="run_payroll_now_later_header">
							<EdenredTypo>When do you want to run payroll?</EdenredTypo>
						</Col>
						<Row className="run_payroll_now_later_section">
							<Col xl={6} lg={4} md={4} className="run_payroll_now_later_date">
								<Select defaultValue={date} onChange={handleChangeDate}>
									{Dates.map((date, index) => (
										<Option key={index} value={date}>
											{date}
										</Option>
									))}
								</Select>
							</Col>
							<Col xl={7} lg={7} md={7} className="run_payroll_now_later_date">
								<Select value={selectedMonth} onChange={handleChangeMonth}>
									{Months.map((monthItem, index) => {
										return (
											<Option key={index} value={monthItem.label}>
												{monthItem.label}
											</Option>
										);
									})}
								</Select>
							</Col>
							<Col xl={7} lg={5} md={5} className="run_payroll_now_later_date">
								<Select defaultValue={new Date().getFullYear()} onChange={handleChangeYear}>
									{years.map((year, index) => (
										<Option key={index} value={year.value}>
											{year.value}
										</Option>
									))}
								</Select>
							</Col>
						</Row>
						<Row className="run_payroll_now_later_mobile_view_section">
							<Col sm={6} xs={6} className="run_payroll_now_later_select_date" onClick={openDateModal}>
								<EdenredTypo medium>{selectedDate || date}</EdenredTypo>
								<ImageComponent src={icons.not_focus.Down} />
							</Col>
							<Modal isOpen={viewDateModal} onRequestClose={closeDateModal} style={customStyles}>
								<Row>
									{Dates.map((date, index) => (
										<Col sm={3} style={{ padding: '1% 1% 1% 1%' }}>
											<EdenredButton
												onClick={() => {
													setSelectedDate(date);
													closeDateModal();
												}}
												className={`${selectedDate === date ? 'isSelected ' : ''}month_modal_button date_modal_button`}>
												{date}
											</EdenredButton>
										</Col>
									))}
								</Row>
							</Modal>
							<Col sm={8} xs={8} className="run_payroll_now_later_select_month" onClick={openMonthModal}>
								<EdenredTypo medium>
									{Months.filter((month) => month.isSelected === true)[0] &&
										Months.filter((month) => month.isSelected === true)[0].label}
								</EdenredTypo>
								<ImageComponent src={icons.not_focus.Down} />
							</Col>
							<Modal isOpen={viewMonthModal} onRequestClose={closeMonthModal} style={customStyles}>
								<Row>
									{Months.map((monthItem, index) => {
										return (
											<Col sm={3} style={{ padding: '1% 1% 1% 1%' }}>
												<EdenredButton
													onClick={() => isMonthSelected(monthItem)}
													className={
														monthItem.isSelected === true ? 'isSelected month_modal_button' : 'month_modal_button'
													}>
													{monthItem.label}
												</EdenredButton>
											</Col>
										);
									})}
								</Row>
							</Modal>
							<Col sm={8} xs={8} className="run_payroll_now_later_select_year" onClick={openYearModal}>
								<EdenredTypo medium>{selectedYear || new Date().getFullYear()}</EdenredTypo>
								<ImageComponent src={icons.not_focus.Down} />
							</Col>
							<Modal isOpen={viewYearModal} onRequestClose={closeYearModal} style={customStyles}>
								<Row className="date_modal">
									<Col sm={2} xs={2} className="icon_area" onClick={() => hasPreviousData && handleYears('PRE')}>
										{hasPreviousData ? <ImageComponent src={icons.not_focus.Down} className="left_arrow" /> : ''}
									</Col>
									<Col sm={20} xs={20}>
										<Row>
											{yearData.map((year, i) => (
												<Col key={i} sm={8} xs={8} style={{ padding: '1% 1% 1% 1%' }}>
													<EdenredButton
														onClick={() => {
															setSelectedYear(year.value);
															closeYearModal();
														}}
														className={`${selectedYear === year.value ? 'isSelected' : ''} month_modal_button`}>
														{year.value}
													</EdenredButton>
												</Col>
											))}
										</Row>
									</Col>
									<Col sm={2} xs={2} className="icon_area" onClick={() => hasNextData && handleYears('AFT')}>
										{hasNextData ? <ImageComponent src={icons.not_focus.Down} className="right_arrow" /> : ''}
									</Col>
								</Row>
							</Modal>
						</Row>
						<Col xl={8} lg={8} md={8} sm={24} xs={24} className="run_payroll_now_later_button">
							{/* <Link to="/payroll/run/channel/submit_payroll"> */}
							<EdenredButton onClick={handlenextdata}>Next</EdenredButton>
							{/* </Link> */}
						</Col>
					</Row>
				)}
			</Row>
		</>
	);
};

export default RunPayrollNowLater;
