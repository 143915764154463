import React, { useEffect, useState } from 'react';
import { Carousel, Col, Divider, row } from 'antd';
import './banknumber.scss';
import BankNameDropDown from './bank-name-dropdown';
import { useLocation, useHistory } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import { connect } from 'react-redux';
import { withReducer } from '@store/reducerLoader';
import sagas from './saga/index';
import reducer from './reducer';
import Selectors from './selector';
import Actions from './action';
import Popup from 'src/utils/popup/popups';
import { popUpNotification } from '@constants/app-constants';

const Banknumber = ({
	postBankAccountSuccess,
	bankName,
	bankNameDropDown,
	bankAccountLoader,
	isbankAccountPopUP,
	BankAccountPopUp,
	BankAccountRoute,
	bankRoute,
	bankAccount,
}) => {
	const history = useLocation();
	const prevHistory = useHistory();
	const [routingState, setRoutingState] = useState({
		bankName: '',
		bankRoutingCode: '',
	});
	const [popup, setPopup] = useState(false);
	const onChange = (event) => {
		console.log('event', event.split('code:')[0]);
		setRoutingState({
			bankName: event?.split('code:')[0],
			bankRoutingCode: event?.split('code:')[1],
		});
	};
	const handleSubmit = () => {
		const payload = {
			employeeId: history?.state?.employeeId,
			ibanNumber: history?.state?.iban,
			bankRoutingCode: routingState?.bankRoutingCode,
			bankName: routingState?.bankName,
		};
		postBankAccountSuccess(payload, history);
	};
	useEffect(() => {
		bankNameDropDown();
	}, []);
	useEffect(() => {
		if (bankAccount?.length !== 0) {
			setPopup(true);
		} else {
			setPopup(false);
		}
	}, [bankAccount]);

	const errorPopupClose = () => {
		if (bankRoute) {
			prevHistory.push({
				pathname: bankRoute,
				state: { empId: history?.state.employeeId, empName: history?.state?.empName },
			});
			BankAccountRoute();
		}
		BankAccountPopUp(false);
	};
	console.log('bankAccount', isbankAccountPopUP);
	return (
		<>
			<Popup
				visible={isbankAccountPopUP}
				message={{ titleMessage: bankAccount ? bankAccount : 'Saved Successfully' }}
				subMessage={null}
				closePopup={errorPopupClose}
			/>
			<row className="card-holder-banknumber">
				<Col xl={22} lg={22} md={22} sm={22} xs={22} className="main-box">
					<Col xl={20} lg={20} md={20} sm={20} xs={20} className="cardholder-card">
						<span className="card-name">What Is {history?.state?.empName}’s Bank Name?</span>
						<p className="card-title">Pick the bank used by the employee from the options given.</p>
						<Col xl={11} lg={11} md={11} sm={11} xs={11} className="text-box">
							<BankNameDropDown
								placeholder="Bank Name"
								className="select-field"
								bankName={bankName}
								routingState={routingState}
								onChange={(event) => onChange(event)}
							/>
						</Col>
						<Col xl={8} lg={8} md={8} sm={8} xs={8}>
							<EdenredButton
								type="primary"
								onClick={handleSubmit}
								disabled={!routingState?.bankName || !routingState?.bankRoutingCode}
								loading={bankAccountLoader}>
								Next
							</EdenredButton>
						</Col>
					</Col>
				</Col>
			</row>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		isbankAccountPopUP: Selectors.isbankAccountPopUP(state),
		bankName: Selectors.bankName(state),
		bankAccountLoader: Selectors.bankAccountLoader(state),
		bankAccount: Selectors.bankAccount(state),
		bankRoute: Selectors.bankRoute(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		postBankAccountSuccess: (initial, historyNew) => dispatch(Actions.creators.postBankAccountStart(initial, historyNew)),
		bankNameDropDown: () => dispatch(Actions.creators.getBankNameStart()),
		BankAccountPopUp: (flag) => dispatch(Actions.creators.BankAccountPopUp(flag)),
		BankAccountRoute: (flag) => dispatch(Actions.creators.BankAccountRoute(flag)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Banknumber);
const ReducedScreen = withReducer('appReducer', reducer, sagas)(Connected);
export default ReducedScreen;
