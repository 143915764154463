import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action.js';
import Axios from 'axios';
import { checkHrSubscription } from 'src/helpers/index.js';

export function* employeePayrollTemplateService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const isSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const employeeTemplate = isSubscription ? 'EmployeeDownloadSubscription_Template' : 'EmployeeDownloadNoSubscription_Template';
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/data/download`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.employeePayrollTemplateSuccess(res.data));
		const downloadUrl = window.URL.createObjectURL(res.data);
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', `employee.xls`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (err) {
		yield put(Actions.creators.employeePayrollTemplateFail(err));
	}
}

export function* employeePayrollUploadService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	let formData = new FormData();
	formData.append('file', action.payload);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/bulk-update-upload`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.employeePayrollUploaduccess(res.data));
	} catch (err) {
		yield put(Actions.creators.employeePayrollUploadFail(err));
	}
}
export function* employeePayrollServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const user = action?.payload?.userName;
	const pageNumber = action?.payload?.pageNumber;
	const pageSize = action?.payload?.pageSize;
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/employee-staging?userName=${user}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getEmployeePayrollSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getEmployeePayrollFail(err));
	}
}

export default function* employeePayrollWatcher() {
	yield takeEvery(Actions.types.EMPLOYEE_PAYROLL_TEMPLATE_START, employeePayrollTemplateService);
	yield takeEvery(Actions.types.EMPLOYEE_PAYROLL_UPLOAD_START, employeePayrollUploadService);
	yield takeEvery(Actions.types.GET_EMPLOYEE_PAYROLL_START, employeePayrollServices);
}
