const SAVE_DOCUMENT_UPLOAD_LOADING = 'SAVE_DOCUMENT_UPLOAD_LOADING';
const SAVE_DOCUMENT_UPLOAD_SUCCESS = 'SAVE_DOCUMENT_UPLOAD_SUCCESSS';
const SAVE_DOCUMENT_UPLOAD_FAIL = 'SAVE_DOCUMENT_UPLOAD_FAIL';
const SAVE_EMP_TEMPLATE_LOADING = 'SAVE_EMP_TEMPLATE_LOADING';
const SAVE_EMP_TEMPLATE_SUCCESS = 'SAVE_EMP_TEMPLATE_SUCCESS';
const SAVE_EMP_TEMPLATE_FAIL = 'SAVE_EMP_TEMPLATE_FAIL';
const SAVE_DOCUMENTUPLOAD_EMPLOYEE_RESET = 'SAVE_DOCUMENTUPLOAD_EMPLOYEE_RESET';

const saveDocumentUploadStart = (file) => {
	return {
		type: SAVE_DOCUMENT_UPLOAD_LOADING,
		file,
	};
};
const saveDocumentUploadSuccess = (response) => {
	return {
		type: SAVE_DOCUMENT_UPLOAD_SUCCESS,
		response,
	};
};
const saveDocumentUploadFail = (error) => {
	return {
		type: SAVE_DOCUMENT_UPLOAD_FAIL,
		error,
	};
};
const saveEmpTemplateStart = () => {
	return {
		type: SAVE_EMP_TEMPLATE_LOADING,
	};
};
const saveEmpTemplateSuccess = (response) => {
	return {
		type: SAVE_EMP_TEMPLATE_SUCCESS,
		response,
	};
};
const saveEmpTemplateFail = (error) => {
	return {
		type: SAVE_EMP_TEMPLATE_FAIL,
		error,
	};
};
const saveDocumentUploadEmployeeReset = () => {
	return {
		type: SAVE_DOCUMENTUPLOAD_EMPLOYEE_RESET,
	};
};
export default {
	types: {
		SAVE_DOCUMENT_UPLOAD_LOADING,
		SAVE_DOCUMENT_UPLOAD_SUCCESS,
		SAVE_DOCUMENT_UPLOAD_FAIL,
		SAVE_EMP_TEMPLATE_LOADING,
		SAVE_EMP_TEMPLATE_SUCCESS,
		SAVE_EMP_TEMPLATE_FAIL,
		SAVE_DOCUMENTUPLOAD_EMPLOYEE_RESET,
	},
	creators: {
		saveDocumentUploadStart,
		saveDocumentUploadSuccess,
		saveDocumentUploadFail,
		saveEmpTemplateStart,
		saveEmpTemplateSuccess,
		saveEmpTemplateFail,
		saveDocumentUploadEmployeeReset,
	},
};
