import React from 'react';
import { Col, Row } from 'antd';
import ViewSubmittedProof from '@pages/payroll/proof-of-transfer/components/view-submitted-proof';
import ContentLoader from '@sharedComponent/content-loader';
import ImageComponent from '@sharedComponent/image-component';
import ETypo from '@pages/DesignSystem/Typo';
import Table from '@pages/DesignSystem/Table/Table';
import Inputbox from '@pages/DesignSystem/Input-Field';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import EmployeeLeavesEdit from './employee-leaves-edit-presentational';
import './historySection.scss';

function EmployeehistorySection({
	columns,
	viewLeaveApprove,
	setViewLeaveApprove,
	openSubmittedProofModal,
	closeSubmittedProofModal,
	historyDetail,
	sampleData,
	Image,
	countLoader,
	attachment,
	saveLeaveLoading,
	SaveLeave,
	employeeId,
	saveLeaveSucceessfulPopUp,
	handleEditLeave,
	edit,
	getHandleValue,
	hrClaims,
	setNavigateTabCheck,
	navigateTabCheck,
	setSampleData,
	SetViewMore,
	viewMore,
}) {
	const tableLoading = {
		spinning: countLoader,
		indicator: <ContentLoader />,
	};
	const Hrclaims = hrClaims?.map((data) => data?.value);
	const isLeaveEditable = Hrclaims?.includes('Setup.Leave.Approval.Workflow.and.Edit.Leave.Balances');
	const leaveHistoryDataInitial = historyDetail?.slice(0, 3);
	const leaveHistoryData = historyDetail?.slice(0, historyDetail?.length);
	const viewSubmittedProofStylesWithoutHeight = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'relative',
			top: '0px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'auto',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			width: '415px',
			minWidth: '415px',
			boxShadow: 'none',
		},
	};
	const onlyNumberForNumberType = (evt) => {
		return (evt.key === '+' && evt.preventDefault()) || (evt.key === 'E' && evt.preventDefault()) || (evt.key === 'e' && evt.preventDefault());
	};
	const handleValueChanges = (event, leaveType) => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: true,
			tabKey: '4',
		});
		const Editvalues = sampleData?.map((values) => {
			if (values.leaveTypeName === leaveType) {
				return {
					...values,
					Days: `${event.target.value} Days`,
				};
			}
			return values;
		});
		setSampleData([...Editvalues]);
	};
	console.log('historyDetail', historyDetail, historyDetail?.slice(0, 3), viewMore);
	return (
		<>
			{edit ? (
				<Row>
					<EmployeeLeavesEdit
						{...{
							employeeId,
							sampleData,
							handleEditLeave,
							saveLeaveLoading,
							SaveLeave,
							saveLeaveSucceessfulPopUp,
							edit,
							getHandleValue,
							setNavigateTabCheck,
							navigateTabCheck,
						}}
					/>
				</Row>
			) : (
				<Row className="leaveStatusContainer">
					<Col xl={24} lg={24} md={24} className="leaveRow">
						<ETypo medium h4>
							Leave Balances
						</ETypo>
						<Row className="leaves_status_details">
							{sampleData?.map((item) => (
								<div className="leaveBalanceContainer">
									<div className={!item?.isVisible ? 'forAlignments' : 'forAlignments1'}>
										<ImageComponent src={item?.Image} className="leave_border" />
										{isLeaveEditable ? (
											<Inputbox
												{...{
													disabled: item?.isVisible,
													label: item?.leaveTypeName + ' Leave Remaining',
													value: item?.Days?.replace(/[^0-9-]/g, ''),
													name: item.leaveTypeName,
													onChange: (event) => handleValueChanges(event, item?.leaveTypeName),
													onKeyDown: (event) => onlyNumberForNumberType(event),
													prefix: 'Days',
												}}
											/>
										) : (
											<div className="forPadding">
												<ETypo medium b3 color={isLeaveEditable ? '#181919' : '#737780'}>
													{item?.leaveTypeName}
												</ETypo>
												<ETypo medium b2 color="#181919">
													{item?.Days}
												</ETypo>
											</div>
										)}
									</div>
								</div>
							))}
						</Row>
					</Col>

					<Row className="leaveStatusContainer">
						<Col xl={24} lg={24} md={24} className="leaveHistoryTop">
							<ETypo medium h4 style={{ marginBottom: '16px' }}>
								Leave History
							</ETypo>
							<Table data={viewMore ? leaveHistoryData : leaveHistoryDataInitial} columns={columns} loading={tableLoading} />
							{viewMore ? (
								<ETypo medium className="view-more-text" onClick={() => SetViewMore(false)}>
									View Less
								</ETypo>
							) : (
								<ETypo medium className="view-more-text" onClick={() => SetViewMore(true)}>
									View More
								</ETypo>
							)}
						</Col>
					</Row>
					<ViewSubmittedProof
						{...{
							viewSubmittedProof: viewLeaveApprove,
							setViewSubmittedProofModal: setViewLeaveApprove,
							openSubmittedProofModal,
							closeSubmittedProofModal,
							customStyles: viewSubmittedProofStylesWithoutHeight,
							leaveToApproveImage: Image,
							attachmentname: attachment,
							leaveToApproveDownload: true,
							buttonText: 'Download Attachment',
							buttonModalStyle: {
								display: 'none',
							},
						}}
					/>
				</Row>
			)}
		</>
	);
}
export default EmployeehistorySection;
