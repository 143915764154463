import React from 'react';
import PrimaryButtons from './Examples/PrimaryButtons';
import SecondaryButtons from './Examples/SecondaryButtons';
import LinkButtons from './Examples/LinkButtons';

const Index = () => {
	return (
		<>
			<PrimaryButtons />
			<SecondaryButtons />
			<LinkButtons />
		</>
	);
};

export default Index;
