import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import { filterLookUpData, PayrollChannelList } from './payroll';
import { Link } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';

const payroll_channel = [
	{
		channel_label: 'WPS',
	},
	{
		channel_label: 'Non WPS',
	},
];
const account_type = [
	{
		type_label: 'C3Pay Card',
	},
	{
		type_label: 'Bank Account',
	},
];
const Bank_Name = [
	{
		bank_label: 'Mashreq',
	},
	{
		bank_label: 'Emirates NBD',
	},
	{
		bank_label: 'ADIB',
	},
	{
		bank_label: 'Citibank',
	},
	{
		bank_label: 'Union National Bank',
	},
];
const Establishment = [
	{
		establish_label: 'GLB - 0000000123456',
	},
	{
		establish_label: 'GLB - 0000000123456',
	},
	{
		establish_label: 'GLB - 0000000123456',
	},
	{
		establish_label: 'GLB - 0000000123456',
	},
	{
		establish_label: 'GLB - 0000000123456',
	},
];

const RunPayrollFilter = () => {
	const [dropDown, setDropDown] = useState({ payrollChannelDD: [], accountTypeDD: [], bankNamesDD: [], establishmentsDD: [] });

	const setDropdownState = (label, value) => {
		setDropDown({ ...dropDown, [label]: value });
	};

	const handleDrop = async (type, index) => {
		switch (type) {
			case 'PC':
				let pcdata = await setDropdownList(dropDown.payrollChannelDD, index);
				await setDropdownState('payrollChannelDD', pcdata);
				break;
			case 'AT':
				let atdata = await setDropdownList(dropDown.accountTypeDD, index);
				await setDropdownState('accountTypeDD', atdata);
				break;
			case 'BN':
				let bndata = await setDropdownList(dropDown.bankNamesDD, index);
				await setDropdownState('bankNamesDD', bndata);
				break;
			case 'EID':
				let eiddata = await setDropdownList(dropDown.establishmentsDD, index);
				await setDropdownState('establishmentsDD', eiddata);
				break;
			default:
				break;
		}
		await setisDropSelectedstate();
	};

	const [isDropSeleccted, setIsDropSelected] = useState(false);
	const setisDropSelectedstate = () => {
		let pc = dropDown.payrollChannelDD.filter((val) => val.isSelected === true) || [];
		let at = dropDown.accountTypeDD.filter((val) => val.isSelected === true) || [];
		let bn = dropDown.bankNamesDD.filter((val) => val.isSelected === true) || [];
		let eid = dropDown.establishmentsDD.filter((val) => val.isSelected === true) || [];

		if (pc.length > 0 || at.length > 0 || bn.length > 0 || eid.length) {
			setIsDropSelected(true);
		} else {
			setIsDropSelected(false);
		}
	};

	const setDropdownList = async (data, index) => {
		await data.map((filter, ind) => {
			if (ind === index) {
				filter.isSelected = filter.isSelected === true ? false : true;
			}
			return filter;
		});
		return data;
	};

	const getDropdown = async () => {
		await setDropDown({
			...dropDown,
			payrollChannelDD: filterLookUpData.payrollChannels,
			accountTypeDD: filterLookUpData.accountTypes,
			bankNamesDD: filterLookUpData.bankNames,
			establishmentsDD: filterLookUpData.establishments,
		});
	};

	useEffect(() => {
		getDropdown();
	}, []);

	return (
		<>
			<EdenredHeader close />
			<Row className="RunPayrollFilter_mobile_view">
				<Col xs={18} sm={18} className="Filter">
					<EdenredTypo bold>Filter</EdenredTypo>
				</Col>
				<Col xs={6} sm={6} classNmae="select_all">
					<EdenredButton type="link" style={{ color: '#71B0D0', fontSize: '12px', display: 'contents' }}>
						Select All
					</EdenredButton>
				</Col>
				<Row className="scroll-filter">
					{/* payroll_channel */}
					<Col sm={24} xs={24}>
						<Row xs={24} sm={24}>
							<EdenredTypo h6 bold>
								Payroll Channel:
							</EdenredTypo>
						</Row>
					</Col>
					{/* {payroll_channel.map((filter, index) => (
					<Col className="wps_nonwps" key={index}>
						<EdenredButton
							onClick={() => handleDrop(index)}
							type="outline-g"
							icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
							{filter.channel_label}
						</EdenredButton>
					</Col>
				    ))} */}

					{dropDown.payrollChannelDD.map((filter, index) => {
						return (
							<Col className="wps_nonwps" key={index}>
								<EdenredButton
									onClick={() => handleDrop?.('PC', index)}
									type="outline-g"
									icon={<ImageComponent src={filter.isSelected ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
									{filter?.payrollChannelName}
								</EdenredButton>
							</Col>
						);
					})}

					{/* Account Type: */}
					<Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Account Type:
						</EdenredTypo>
					</Col>
					{dropDown.accountTypeDD.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								type="outline-g"
								onClick={() => handleDrop('AT', index)}
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.accountTypeName}
							</EdenredButton>
						</Col>
					))}
					{/* Bank Name: */}
					<Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Bank Name:
						</EdenredTypo>
					</Col>
					{dropDown.bankNamesDD.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								type="outline-g"
								onClick={() => handleDrop('BN', index)}
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.bankName}
							</EdenredButton>
						</Col>
					))}
					{/* Establishment ID: */}
					<Col xs={24} sm={24}>
						<EdenredTypo h6 bold>
							Establishment ID:{' '}
						</EdenredTypo>
					</Col>
					{dropDown.establishmentsDD.map((filter, index) => (
						<Col className="wps_nonwps">
							<EdenredButton
								type="outline-g"
								onClick={() => handleDrop('EID', index)}
								icon={<ImageComponent src={filter.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox} />}>
								{filter.establishmentId}
							</EdenredButton>
						</Col>
					))}
					<Col sm={24} xs={24} className="appy_buttom">
						{isDropSeleccted === true ? (
							<Link to="/run-payroll">
								<EdenredButton>Apply Filters</EdenredButton>
							</Link>
						) : (
							<Col xs={24} sm={24} className="appy_button_apply">
								<EdenredButton className="user_apply" type="outline-g">
									Apply Filters
								</EdenredButton>
							</Col>
						)}
					</Col>
				</Row>
				{/* <Col sm={24} xs={24} className="appy_buttom">
					{isDropSeleccted === true ? (
						<Link to="/run-payroll">
							<EdenredButton>Apply Filters</EdenredButton>
						</Link>
					) : (
						<Col xs={24} sm={24} className="appy_button_apply">
							<EdenredButton className="user_apply" type="outline-g">
								Apply Filters
							</EdenredButton>
						</Col>
					)}
				</Col> */}
			</Row>
		</>
	);
};
export default RunPayrollFilter;
