import React from 'react';
import { connect } from 'react-redux';
import action from '../per-employee-action';
import Selectors from '../per-employee-selecter';
import SalaryPerEmployeePresentational from './salary-per-employee-presentational';

const SalaryperEmployeeFunctional = ({ isFromSalaryTransferPage, isPerEmployeeLoading, perEmployeeDetails, perEmployeeSaga }) => {
	return <SalaryPerEmployeePresentational {...{ isFromSalaryTransferPage }} />;
};
const mapStateToProps = (state) => {
	return {
		isPerEmployeeLoading: Selectors.isPerEmployeeLoading(state),
		perEmployeeDetails: Selectors.perEmployeeDetails(state),
		perEmployeeError: Selectors.perEmployeeError(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		perEmployeeSaga: () => dispatch(action.creators.getPerEmployeeStart()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(SalaryperEmployeeFunctional);
export default Connected;
