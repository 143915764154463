import React, { useState } from 'react';
import ETypo from './Typo';
import Inputbox from './Input-Field/index';
import { Tabs } from 'antd';
import RadioBox from './RadioBox/index';
import ComboBox from './ComboBox/index';
import Checkbox from './Checkbox/index';
import Buttons from './button/Index';
import Carousel from './carousel/Index';
import MultiSelect from './Multiselect/index';
import EDragger from './Dragger';
import { Propstable } from './props-table';
import Index from './TableSelectors/Index';
import Modelexamples from './Edenredmodal/Example';
import Feedboxexample from './feedbox/Example/feedboxexample';
import ChipsExample from './Chips/Example/ChipsExample';
import { images } from 'src/assets/img';
import ImageComponent from '@sharedComponent/image-component';
import {
	button,
	carousel,
	checkbox,
	chip,
	combobox,
	dragger,
	feedbox,
	input,
	modal,
	radiobox,
	tableselector,
	tooltip,
	typo,
	tableprops,
	tablepagination,
	sidenavbar,
	counter,
	progressbar,
	header,
} from './Json/DesignSystem';
import TypoExample from './Typo/Example/TypoExample';
import InputExample from './Input-Field/Example/InputExample';
import TooltipExample from './Tool-Tip/Example/TooltipExample';
import Table from './Table/Table';
import Pagination from './Table/Pagination';
import Chips from './Chips/index';
import Sidenavbarfunctional from './sidenavbar/sidenavbarfunctional';
import Toast1 from './Toast/example/toast-one';
import Toast2 from './Toast/example/toast-two';
import Toast3 from './Toast/example/toast-three';
import Toast4 from './Toast/example/toast-four';
import Progressbar from './progressbar/index';
import Progressbar1 from './progressbar/progressbar1';
import Header from './header/index';
import Counter1 from './counter/example/counter-one';
import Counter2 from './counter/example/counter-two';
import Counter3 from './counter/example/counter-three';
import Counter4 from './counter/example/counter-four';
import Counter5 from './counter/example/counter-five';
import Counter6 from './counter/example/counter-six';
import Example from './togglebutton/example.js';
import './test.scss';
import FilterComponent from './filter';
import FilterButton from './filter';
const dataSource = [
	{
		key: '1',
		name: 'Mike',
		age: 32,
		address: '10 Downing Street',
	},
	{
		key: '2',
		name: 'John',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '3',
		name: 'Roshan',
		age: 24,
		address: '10 Downing Street',
	},
	{
		key: '4',
		name: 'Sujai',
		age: 32,
		address: '10 Downing Street',
	},
	{
		key: '5',
		name: 'Shubash',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '6',
		name: 'Yuva',
		age: 42,
		address: '10 Downing Street',
	},

	{
		key: '8',
		name: 'Aravind',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '9',
		name: 'Manjo',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '10',
		name: 'Ali Afroze',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '11',
		name: 'Thamodaran',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '12',
		name: 'Raja',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '13',
		name: 'Darani',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '14',
		name: 'Mohan',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '15',
		name: 'RajaRam',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '16',
		name: 'Naveen',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '17',
		name: 'Andrew',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '18',
		name: 'Abi',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '19',
		name: 'Gopi',
		age: 42,
		address: '10 Downing Street',
	},
	{
		key: '20',
		name: 'Aanathu',
		age: 42,
		address: '10 Downing Street',
	},
];

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		sort: true,
	},
	{
		title: 'Age',
		dataIndex: 'age',
		key: 'age',
		sort: true,
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
		render: () => {
			return (
				<div className="table-input">
					<Inputbox type="number" prefix="AED" className="input_field" />
				</div>
			);
		},
	},
	{
		title: 'Roles',
		dataIndex: 'roles',
		key: 'roles',
		render: () => {
			return (
				<div style={{ display: 'flex' }}>
					<Chips color="#E2D2FE">HR</Chips>
					<div style={{ paddingLeft: '10px' }}>
						<Chips color="#FEF5E8"> Payroll</Chips>
					</div>
				</div>
			);
		},
	},
	{
		title: '',
		dataIndex: '',
		key: '',
		render: () => {
			return (
				<div style={{ display: 'flex' }}>
					<ImageComponent src={images.edittable} />
					<ETypo h5 bold className="ed_text">
						Edit
					</ETypo>
				</div>
			);
		},
	},
];
const Trails = () => {
	const { TabPane } = Tabs;
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage1, setCurrentPage1] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(9);
	const [currentPage3, setCurrentPage3] = useState(20);
	const setCurrentPages = (page) => {
		setCurrentPage(page);
	};
	const setCurrentPages1 = (page) => {
		setCurrentPage1(page);
	};
	const setCurrentPages2 = (page) => {
		setCurrentPage2(page);
	};
	const setCurrentPages3 = (page) => {
		setCurrentPage3(page);
	};

	return (
		<div className="sharedComponent">
			<Tabs>
				<TabPane tab="Typo" key="1" style={{ justifyContent: 'center' }}>
					<div style={{ display: 'flex', paddingRight: '20px' }}>
						<TypoExample />
						<div style={{ width: '40%' }}>
							<Propstable data={typo} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Input" key="2">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px', gap: '20px' }}>
						<div style={{ width: '100%' }}>
							<InputExample />
							<textarea
								className="input-note-test"
								maxLength="120"
								placeholder="Write your message here "
								// onChange={initial.body.length <= 120 ? handleTextChange : ''}
							/>
							<p className="for-characters-div">0/120 Characters</p>
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={input} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="ToolTip" key="3">
					<div style={{ display: 'flex', paddingRight: '20px' }}>
						<TooltipExample />
						<div style={{ width: '30%' }}>
							<Propstable data={tooltip} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Buttons" key="4">
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
							<div style={{ width: '60%' }}>
								<Buttons />
							</div>
							<div style={{ width: '40%' }}>
								<Propstable data={button} />
							</div>
						</div>
					</>
				</TabPane>
				<TabPane tab="Modal" key="5">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '60%', rowGap: '20px' }}>
							<Modelexamples />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={modal} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="FeedBack Box" key="6">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '70%' }}>
							<Feedboxexample />
						</div>
						<div style={{ width: '30%' }}>
							<Propstable data={feedbox} />
						</div>
					</div>
				</TabPane>

				<TabPane tab="CheckBox" key="8">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '60%' }}>
							<Checkbox />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={checkbox} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Radiobox" key="9">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '60%' }}>
							<RadioBox />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={radiobox} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Chips" key="10">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<ChipsExample />
						<div style={{ width: '30%' }}>
							<Propstable data={chip} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Slider" key="11">
					<div style={{ display: 'flex', paddingRight: '10px', gap: '10%' }}>
						<div style={{ width: '50%' }}>
							<Carousel />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={carousel} />
						</div>
					</div>
				</TabPane>

				<TabPane tab="Dragger" key="13">
					<div style={{ display: 'flex', paddingRight: '20px', gap: '10%' }}>
						<div style={{ width: '50%' }}>
							<EDragger />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={dragger} />{' '}
						</div>
					</div>
				</TabPane>
				<TabPane tab="Table Selectors" key="14">
					<div style={{ display: 'flex', paddingRight: '20px', gap: '10%' }}>
						<div style={{ width: '50%' }}>
							<Index type="halfSelect" />
							<Index type="Error_icn" />
							<Index type="tick" />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={tableselector} />{' '}
						</div>
					</div>
				</TabPane>
				<TabPane tab="Table" key="15">
					<div style={{ display: 'flex', paddingRight: '20px', gap: '10%', flexDirection: 'column' }}>
						<Table columns={columns} data={dataSource} />
						<div style={{ paddingTop: '20px', display: 'flex', flexDirection: 'row-reverse' }}>
							{dataSource.length > 10 ? (
								<Pagination
									className="pagination-bar"
									currentPage={1}
									totalCount={dataSource.length}
									pageSize={1}
									onPageChange={(page) => setCurrentPage(page)}
								/>
							) : null}
						</div>
						<div style={{ width: '40%', marginTop: '30px', marginBottom: '30px' }}>
							<Propstable data={tableprops} />{' '}
						</div>
					</div>
				</TabPane>
				<TabPane tab="Table Pagination" key="16">
					<div style={{ display: 'flex', paddingRight: '20px', gap: '10%', flexDirection: 'column' }}>
						{/* <Table columns={columns} data={dataSource}/> */}
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<ETypo h4 regular>
									Show 20 out of 124 Employees
								</ETypo>
								<div style={{ paddingTop: '20px', display: 'flex' }}>
									<Pagination
										className="pagination-bar"
										currentPage={currentPage}
										totalCount={dataSource.length}
										pageSize={3}
										onPageChange={(page) => setCurrentPages(page)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<ETypo h4 regular>
									Show 20 out of 398 Employees
								</ETypo>
								<div style={{ paddingTop: '20px', display: 'flex' }}>
									<Pagination
										className="pagination-bar"
										currentPage={currentPage1}
										totalCount={dataSource.length}
										pageSize={1}
										onPageChange={(page) => setCurrentPages1(page)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<ETypo h4 regular>
									Show 20 out of 398 Employees
								</ETypo>
								<div style={{ paddingTop: '20px', display: 'flex' }}>
									<Pagination
										className="pagination-bar"
										currentPage={currentPage2}
										totalCount={dataSource.length}
										pageSize={1}
										onPageChange={(page) => setCurrentPages2(page)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<ETypo h4 regular>
									Show 20 out of 398 Employees
								</ETypo>
								<div style={{ paddingTop: '20px', display: 'flex' }}>
									<Pagination
										className="pagination-bar"
										currentPage={currentPage3}
										totalCount={dataSource.length}
										pageSize={1}
										onPageChange={(page) => setCurrentPages3(page)}
									/>
								</div>
							</div>
						</div>
						<div style={{ width: '40%', marginTop: '30px', marginBottom: '20px' }}>
							<Propstable data={tablepagination} />{' '}
						</div>
					</div>
				</TabPane>
				<TabPane tab="Nav bar" key="17">
					<div style={{ display: 'flex', paddingRight: '20px', gap: '10%', flexDirection: 'row' }}>
						{/* <Table columns={columns} data={dataSource}/> */}
						<div style={{ paddingTop: '20px', display: 'flex' }}>
							<Sidenavbarfunctional />
						</div>
						<div style={{ width: '40%', marginTop: '30px', marginBottom: '20px' }}>
							<Propstable data={sidenavbar} />{' '}
						</div>
					</div>
				</TabPane>
				<TabPane tab="Toast" key="18">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '70%' }}>
							<Toast1 />
							<Toast2 />
							<Toast3 />
							<Toast4 />
						</div>
						<div style={{ width: '30%' }}>
							<Propstable data={feedbox} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Progressbar" key="19">
					<div style={{ justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '70%' }}>
							<Progressbar />
							<Progressbar1 />
						</div>
						<div style={{ width: '30%' }}>
							<Propstable data={progressbar} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Header" key="20">
					<div style={{ justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '100%' }}>
							<Header icons={images.headerimg} />
						</div>
						<div style={{ width: '30%' }}>
							<Propstable data={header} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Counter" key="21">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '60%' }}>
							<Counter1 />
							<Counter2 />
							<Counter3 />
							<Counter4 />
							<Counter5 />
							<Counter6 />
						</div>
						<div style={{ width: '40%' }}>
							<Propstable data={counter} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="Combo box" key="7">
					<div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
						<div style={{ width: '60%' }}>
							<ComboBox />
						</div>{' '}
						<div style={{ width: '40%' }}>
							<Propstable data={combobox} />
						</div>
					</div>
				</TabPane>
				<TabPane tab="MultiSelect" key="12">
					<div style={{ display: 'flex', paddingRight: '20px' }}>
						<div style={{ width: '70%' }}>
							<MultiSelect />
						</div>
						<div style={{ width: '30%' }}>
							<Propstable data={combobox} />{' '}
						</div>
					</div>
				</TabPane>
				<TabPane tab="Toogle" key="22">
					<div style={{ display: 'flex', paddingRight: '20px' }}>
						<div style={{ width: '70%', display: 'flex', justifyContent: 'center' }}>
							<Example />
						</div>
						<div style={{ width: '30%' }}>
							<Propstable data={combobox} />{' '}
						</div>
					</div>
				</TabPane>
			</Tabs>
		</div>
	);
};
export default Trails;
