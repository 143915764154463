const GET_BANKNAME_DATA_START = 'GET_BANKNAME_DATA_START';
const GET_BANKNAME_DATA_SUCCESS = 'GET_BANKNAME_DATA_SUCCESS';
const GET_BANKNAME_DATA_FAIL = 'GET_BANKNAME_DATA_FAIL';
const GET_EMPLOYEE_DETAILS_START = 'GET_EMPLOYEE_DETAILS_START';
const GET_EMPLOYEE_DETAILS_SUCCESS = 'GET_EMPLOYEE_DETAILS_SUCCESS';
const GET_EMPLOYEE_DETAILS_FAIL = 'GET_EMPLOYEE_DETAILS_FAIL';
const SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_START = 'SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_START';
const SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_SUCCESS = 'SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_SUCCESS';
const SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_FAIL = 'SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_SUCCESS';
const INDIVIDUAL_EMPDOC_SAVED = 'INDIVIDUAL_EMPDOC_SAVED';

const getBankNameDataStart = () => ({
	type: GET_BANKNAME_DATA_START,
});
const getBankNameDataSuccess = (res) => ({
	type: GET_BANKNAME_DATA_SUCCESS,
	res,
});
const getBankNameDataFail = (err) => ({
	type: GET_BANKNAME_DATA_FAIL,
	err,
});
const getEmployeeDetailStart = (empId) => ({
	type: GET_EMPLOYEE_DETAILS_START,
	empId,
});
const getEmployeeDetailSuccess = (res) => ({
	type: GET_EMPLOYEE_DETAILS_SUCCESS,
	res,
});
const getEmployeeDetailFail = (err) => ({
	type: GET_EMPLOYEE_DETAILS_FAIL,
	err,
});
const saveIndividualEmployeeDocStart = (payload) => ({
	type: SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_START,
	payload,
});
const saveIndividualEmployeeDocSuccess = (res) => ({
	type: SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_SUCCESS,
	res,
});
const saveIndividualEmployeeDocFail = (err) => ({
	type: SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_FAIL,
	err,
});
const isIndividualEmpDocSaved = (flag) => ({
	type: INDIVIDUAL_EMPDOC_SAVED,
	flag,
});

export default {
	types: {
		GET_BANKNAME_DATA_START,
		GET_BANKNAME_DATA_SUCCESS,
		GET_BANKNAME_DATA_FAIL,
		GET_EMPLOYEE_DETAILS_START,
		GET_EMPLOYEE_DETAILS_SUCCESS,
		GET_EMPLOYEE_DETAILS_FAIL,
		SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_START,
		SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_SUCCESS,
		SAVE_INDIVIDUAL_EMPLOYEE_DOCUMENT_FAIL,
		INDIVIDUAL_EMPDOC_SAVED,
	},
	creators: {
		getBankNameDataStart,
		getBankNameDataSuccess,
		getBankNameDataFail,
		getEmployeeDetailStart,
		getEmployeeDetailSuccess,
		getEmployeeDetailFail,
		saveIndividualEmployeeDocStart,
		saveIndividualEmployeeDocSuccess,
		saveIndividualEmployeeDocFail,
		isIndividualEmpDocSaved,
	},
};
