import React from 'react';

export const LANGUAGES = Object.freeze({
    english: 'english',
    arabic: 'arabic',
});

export const TERMS_OF_SERVICE = Object.freeze({
    tableDataCount: 94,
    header: {
        [LANGUAGES.english]: {
            text: 'Definitions:',
        },
        [LANGUAGES.arabic]: {
            text: 'تعريفات:',
        },
    },
    data: [
        {
            [LANGUAGES.english]: {
                text: 'Terms of Service means this document that is read and accepted by the Customer.',
            },
            [LANGUAGES.arabic]: {
                text: 'شروط الخدمة :تعني الوثيقة التي يقرأها العميل ويوافق عليها',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Bank means RAK Bank or any other bank chosen by Edenred in order to open the Customer’s Payroll Account to manage the Payroll Funds.',
            },
            [LANGUAGES.arabic]: {
                text: 'البنك: ويعني بنك رأس الخيمة أو أي بنك آخر تختاره شركة ايدنريد من أجل فتح حساب لكشوفات مرتبات العميل من أجل إدارة أموال كشوفات المرتبات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Cardholder means an Employee of the Customer availing of the Services and approved by Edenred and holding a C3Pay Payroll Card. ',
            },
            [LANGUAGES.arabic]: {
                text: 'حامل البطاقة: ويعني الموظف التابع العميل الذي يستفيد من خدمات شركة ايدنريد والمعتمد لديها والذي يحمل بطاقة المرتبات "C3Pay ".',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Customer means any Customer using Edenred’s Payroll Card Services',
            },
            [LANGUAGES.arabic]: {
                text: 'العميل: يعني أي عميل يستخدم خدمات بطاقة الرواتب التي تقدمها إيدنريد',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Employee/s means any employee of the Customer availing of the Services and approved by Edenred and holding a C3Pay Payroll Card.',
            },
            [LANGUAGES.arabic]: {
                text: 'الموظف/ الموظفين: ويُقصد بهم أي موظف تابع للعميل يستفيد من خدمات شركة ايدنريد والمعتمد لديها ويحمل بطاقة المرتبات "C3Pay ".                ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Fee means all fees, charges and expenses (including, without limitation, incurred  charges and additional costs) applicable, from time to time, in relation to the Services payable by the Customer to Edenred.',
            },
            [LANGUAGES.arabic]: {
                text: 'الرسوم: وتعني كافة الرسوم والتكاليف والنفقات (بما في ذلك، بدون حصر، الرسوم المتكبدة والتكاليف الإضافية) المعمول بها، من وقتٍ لآخر، فيما يتعلق بالخدمات التي يدفع في مقابلها العميل للسادة/ ايدنريد.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Intellectual Property Rights means all intellectual property rights any part of the world which includes copyright, design rights, patents, patent applications and any rights to inventions, know-how, trade secrets, trademark and business names, logos and devices and service marks (whether registered or unregistered) and any applications therefore and all rights in confidential information.',
            },
            [LANGUAGES.arabic]: {
                text: 'حقوق الملكية الفكرية: وتعني كافة حقوق الملكية الفكرية في أي جزء من العالم والتي تشمل حقوق النشر وحقوق التصميم وبراءات الاختراع وطلبات براءات الاختراع وأي حقوق في الاختراعات والمعرفة والأسرار التجارية والعلامات التجارية وأسماء الأعمال والشعارات والأجهزة وعلامات الخدمة (سواء كانت مسجلة أو غير مسجلة) وأي طلبات خاصة بها وكافة الحقوق في المعلومات السرية. ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Losses means any and all losses, claims, actions, demands, liabilities, costs, expenses and damages of any nature whatsoever and whether or not reasonably foreseeable or avoidable',
            },
            [LANGUAGES.arabic]: {
                text: 'الخسائر: وتعني أي وكافة الخسائر والدعاوى والإجراءات والمطالبات والالتزامات والمصاريف والتكاليف والتعويضات من أي نوع كانت وما إذا كان من الممكن توقعها أو تجنبها بشكل معقول أم لا.                ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Payment Dates means the dates described in the Payroll File when the Customer intends to disburse the Salaries to its Employees.',
            },
            [LANGUAGES.arabic]: {
                text: 'تواريخ الدفع: وتعني التواريخ المذكورة في ملف كشف المرتبات عندما ينوي العميل صرف المرتبات لموظفيه.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Payroll Account means the bank account with the Bank maintained by Edenred for and on behalf of the Customer for the purpose of holding payroll funds.',
            },
            [LANGUAGES.arabic]: {
                text: 'حساب كشوفات المرتبات: ويعني الحساب المصرفي لدى البنك والذي تحتفظ به شركة ايدنريد لصالح ونيابةً عن العميل لغرض الاحتفاظ بأموال كشوفات المرتبات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'C3Pay Payroll Card means the plastic debit or pre-paid card issued by Edenred in affiliation with one of the operating banks in the UAE to the Employees',
            },
            [LANGUAGES.arabic]: {
                text: 'بطاقة المرتبات "C3Pay": وتعني بطاقة الخصم أو بطاقة الدفع المسبق البلاستيكية الصادرة عن شركة ايدنريد من خلال الربط مع أحد البنوك العاملة في دولة الإمارات العربية المتحدة للموظفين لغرض استلام المرتبات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Payroll File means the electronic file issued by the Customer to Edenred containing details of Salaries to be loaded on the C3Pay Payroll Cards.',
            },
            [LANGUAGES.arabic]: {
                text: 'ملف كشوفات المرتبات: ويعني الملف الإلكتروني الصادر من العميل إلى شركة ايدنريد والذي يحتوي على تفاصيل المرتبات التي سيتم تحميلها على بطاقات المرتبات "C3Pay".',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Payroll Funds means the funds assigned for Salaries processing.',
            },
            [LANGUAGES.arabic]: {
                text: 'أموال كشوفات المرتبات: وتعني الأموال المخصصة لتجهيز المرتبات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Employee File means  file contains  all the information requested by Edenred about the Employees to whom C3Pay Payroll cards will be issued.',
            },
            [LANGUAGES.arabic]: {
                text: 'ملف الموظف: ويعني الملف الذي يحتوي على كافة المعلومات التي طلبتها شركة ايدنريد حول الموظفين الذين سيتم إصدار بطاقات المرتبات "C3Pay" لهم.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Salary/ies means the amount payable by the Customer to its Employees in accordance with the Payroll File.',
            },
            [LANGUAGES.arabic]: {
                text: 'الراتب/ المرتبات: ويعني المبلغ القابل للدفع من قبل العميل لموظفيه وفقًا لملف كشوفات المرتبات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'Service/s means the payroll and card services provided by Edenred pursuant to this Terms of Service.',
            },
            [LANGUAGES.arabic]: {
                text: 'الخدمة/ الخدمات: وتعني خدمات كشوف المرتبات والبطاقة التي تقدمها شركة ايدنريد وفقًا لهذه شروط الخدمة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: 'WPS means the UAE Wage Protection System.',
            },
            [LANGUAGES.arabic]: {
                text: 'نظام حماية الأجور (WPS): ويعني نظام حماية الأجور في دولة الإمارات العربية المتحدة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1.	Range of Services',
            },
            [LANGUAGES.arabic]: {
                text: '1.	مجموعة الخدمات ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1.1.	Edenred shall provide the following Services:',
            },
            [LANGUAGES.arabic]: {
                text: '1.1.	تقدم شركة ايدنريد الخدمات التالية:',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1..1	Issuance of C3Pay Payroll Cards to the Employees of the Customer in accordance with any submitted Employee File.',
            },
            [LANGUAGES.arabic]: {
                text: '1.1.1.	إصدار بطاقات المرتبات "C3Pay" لموظفي العميل وفقًا لأي ملف موظف تم تقديمه.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: <span>1..2	Training to the Customer on the usage of Edenred Portal <a href='https://payroll.edenred.ae/' target='_blank' className='a-tag'> https://payroll.edenred.ae/</a> as well as other general procedures regarding the Services.</span>,
            },
            [LANGUAGES.arabic]: {
                text: <span>2.1.1.	تدريب العميل على استخدام بوابة شركة ايدنريد <a href='https://payroll.edenred.ae/' target='_blank' className='a-tag'>/https://payroll.edenred.ae</a> بالإضافة إلى الإجراءات العامة الأخرى المتعلقة بالخدمات.</span>,
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1..3	Providing Service support to the Customer during implementation and on an on-going basis.',
            },
            [LANGUAGES.arabic]: {
                text: '3.1.1	تقديم الدعم الخدماتي للعميل خلال التنفيذ وعلى أساس مستمر.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1..4	Processing of Payroll Files.',
            },
            [LANGUAGES.arabic]: {
                text: '4.1.1	معالجة ملفات المرتبات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1..5	Ensuring compliance with the Central Bank of the UAE’s regulations on the payment of Salaries, including, without limitation, providing the necessary reporting.',
            },
            [LANGUAGES.arabic]: {
                text: '5.1.1	ضمان الامتثال لأنظمة المصرف المركزي الإماراتي بخصوص دفع المرتبات، بما في ذلك، دون حصر، تقديم التقارير اللازمة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '1..6	Training the Employees on the card usage and other services offered.',
            },
            [LANGUAGES.arabic]: {
                text: '6.1.1	تدريب الموظفين على استخدام البطاقة والخدمات المقدمة الأخرى.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: "The Customer undertakes that every issued C3Pay Card to their respective Employees shall only and exclusively be used for the purpose of disbursing the Cardholders' Salaries and similar forms of remuneration.",
            },
            [LANGUAGES.arabic]: {
                text: '2.1.	يتعهد العميل بأن كل بطاقة C3PAY  صادرة لموظفية سيتم استخدامها فقط وحصريا لغرض صرف الرواتب ومافي حكمها لحاملى البطاقات',
            },
        },
        {
            [LANGUAGES.english]: {
                text: "2.	Issuance of C3Pay Payroll Cards",
            },
            [LANGUAGES.arabic]: {
                text: '2	إصدار بطاقات المرتبات "C3Pay":',
            },
        },
        {
            [LANGUAGES.english]: {
                text: "2.1.	Edenred shall make available to the Customer the required Employee File to be submitted for card ordering.  The Employee File will be sent to the Customer via email and made available on the Edenred corporate portal.",
            },
            [LANGUAGES.arabic]: {
                text: '1.2.	توفر شركة ايدنريد للعميل الملف المطلوب للموظف والذي يتم تقديمه لطلب البطاقة. سيتم إرسال ملف الموظف إلى العميل عبر البريد الإلكتروني وإتاحته على بوابة شركة ايدنريد. ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '2.2.	Edenred shall have the right to decline any Employee Application that is not filled properly or that contains untrue, inaccurate or misleading information. Edenred further reserves the right to decline to issue C3Pay Payroll Cards to any Employee at its sole discretion for any valid reason.  The Customer will perform Know Your Customer (KYC), and provide all necessary documents, after agreeing that only Employees whose identity they are able to verify, will be nominated as applicants for C3Pay Payroll Cards.',
            },
            [LANGUAGES.arabic]: {
                text: '2.2.	يحق لشركة ايدنريد رفض أي طلب خاص بموظف ما لم يتم تعبئته بشكل صحيح أو الذي يحتوي على معلومات غير صحيحة أو غير دقيقة أو مضللة. تحتفظ شركة ايدنريد كذلك بالحق في رفض إصدار بطاقات المرتبات "C3Pay" لأي موظف وفقًا لتقديرها الخاص لأي سبب وجيه. يقوم العميل بتنفيذ إجراء "أعرف عميلك"، وتقديم كل المستندات اللازمة، بعد الموافقة على أنه فقط الموظفين الذين يمكنهم التحقق من هويتهم، سيتم ترشيحهم كمتقدمين للحصول على بطاقات المرتبات "C3Pay".',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '2.3.	The Customer shall provide Employee File to Edenred, Edenred shall process and deliver the C3Pay Payroll Cards to the Customer within a period of seven (7) to ten (10) working days from the date of receiving the Employee File.',
            },
            [LANGUAGES.arabic]: {
                text: '3.3.	يقوم العميل بتقديم ملف الموظف لشركة ايدنريد، وتقوم الأخيرة بمعالجة بطاقات المرتبات "C3Pay" وتسليمها للعميل في غضون سبعة (7) إلى عشرة (10) أيام عمل من تاريخ استلام ملف الموظف.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '2.4.	Edenred is responsible for delivering all issued C3Pay Payroll Cards to each Employee who shall be responsible to activate their own Card to be able to use; if the Card is delivered to the Customer, the latter is responsible for delivering the Card to the respective Employee.',
            },
            [LANGUAGES.arabic]: {
                text: '4.2	 تكون شركة ايدنريد مسؤولة عن تسليم كل ما تصدره من بطاقات المرتبات "C3Pay" لكل موظف والذي يكون مسؤولاً عن تفعيل بطاقته الخاصة ليتمكن من استخدامها؛ وإذا تم تسليم البطاقة للعميل، يكون هذا الأخير مسؤول عن تسليم البطاقة للموظف المعني.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '2.5.	The Customer will have access, through the Edenred Corporate Portal, to a list containing the total number of C3Pay Payroll Cards Issued, names of the Employees to whom the C3Pay payroll cards are issued and their respective customer ID number.',
            },
            [LANGUAGES.arabic]: {
                text: '5.2	 يمكن للعميل الوصول، من خلال بوابة ايدنريد للشركات، إلى مجموعة تحتوي على العدد الإجمالي لبطاقات المرتبات "C3Pay" التي تم إصدارها، وأسماء الموظفين الذين تم إصدار بطاقات المرتبات "C3Pay" لهم ورقم العميل الخاص بهم.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.	Payroll Disbursement Process',
            },
            [LANGUAGES.arabic]: {
                text: '3	عملية صرف كشوفات المرتبات',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.1.	Customers shall process WPS salaries via Commercial Bank of Dubai. Edenred will load cards upon receiving the PAF (Payment Acceptance File) from Central Bank.',
            },
            [LANGUAGES.arabic]: {
                text: '1.3.	 يجب على العملاء معالجة رواتب نظام حماية الأجور (WPS) عبر بنك دبي التجاري. ستقوم ايدنريد بتحميل البطاقات عند استلام ملف قبول الدفع (PAF) من البنك المركزي.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.2.	Edenred shall act upon any Non WPS Payroll File received from the Customer and shall load the C3Pay Payroll Cards with the Salaries as stipulated in the Payroll File.',
            },
            [LANGUAGES.arabic]: {
                text: '2.3	ستتخذ ايدنريد  الإجراء المناسب بناءً على أي ملف أجور غير تابع لنظام حماية الأجور يتم استلامه من قبل العميل,  وستقوم بتحميل بطاقات الرواتب بالرواتب كما هو مبين في ملف الرواتب.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.3.	Edenred shall not act upon any Payroll File that contains any discrepancy, error or omission. Edenred shall notify the Customer upon discovery of errors  in the Payroll File and the Customer shall immediately rectify such information by issuing a new Non WPS Payroll File.',
            },
            [LANGUAGES.arabic]: {
                text: '3.3.	لا تتصرف شركة ايدنريد حيال أي ملف كشوفات مرتبات يحتوي على أي تناقض، أو خطأ أو سهو. تقوم شركة ايدنريد بإخطار العميل عند اكتشاف الأخطاء في ملف كشوفات المرتبات ويتعين على العميل تصحيح هذه المعلومات على الفور عن طريق إصدار ملف أجور غير تابع لنظام حماية الأجور جديد.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Edenred will facilitate same day disbursement on condition that Payroll File shall be received by Edenred and Payroll Funds shall be received and cleared in our Payroll Account not later than 16:30 (GST) Monday to Saturday.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4.	سوف تسعى شركة ايدنريد لتسهيل صرف المرتبات  في نفس اليوم شريطة أن يتم استلام ملف كشوفات المرتبات من قبل شركة ايدنريد وأن يتم استلام وأداء الأموال الخاصة بالمرتبات في حساب كشوف المرتبات لدينا في موعد لا يتجاوز الساعة 16:30 (بتوقيت الخليج) من الاثنين إلى السبت.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.1.	Edenred shall not be responsible for any delay in disbursing Salaries and shall not be held liable for any claim and/or penalties imposed  by respective authorities in connection with performing the Services if the Payroll File is not received by Edenred within the above subscribed period and if Payroll funds have not been received and cleared in our Payroll Account.',
            },
            [LANGUAGES.arabic]: {
                text: '1.4.3.	لن تكون شركة ايدنريد مسؤولة عن أي تأخير في صرف المرتبات ولن تكون مسؤولة عن أي مطالبة و/أو عقوبات مفروضة من قبل السلطات المعنية فيما يتعلق بأداء الخدمات إذا لم يتم استلام ملف كشوف المرتبات من قبل شركة ايدنريد خلال الفترة أعلاه وإذا لم يتم استلام الأموال الخاصة بالمرتبات وتسويتها في حساب المرتبات لدينا.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.2.	Edenred endeavors complete payments with the above, however any delay in processing Salary disbursement as a result of deficiencies shall be at the sole risk and liability of the Customer.',
            },
            [LANGUAGES.arabic]: {
                text: '2.4.3.	تعمل شركة ايدنريد على سداد كامل المدفوعات وفق ما ورد أعلاه، ولكن أي تأخير في معالجة صرف الراتب نتيجة لتقصير ما سيتحمل مخاطره ويكون على مسؤولية العميل وحده.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Customer’s Covenants, Fees, Indemnity and Liability',
            },
            [LANGUAGES.arabic]: {
                text: '4.	تعهدات ورسوم وتعويضات ومسؤولية العميل:',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer warrants that they fully understand the procedures and requirements for the provision of the Services as set out in this Terms of Service.',
            },
            [LANGUAGES.arabic]: {
                text: '1.4	يتعهد العميل بأنه فهم تمامًا الإجراءات والمتطلبات الخاصة بتقديم الخدمات كما هو منصوص عليه في هذه شروط الخدمة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer should provide the name and contact details of the person assigned an admin access to Edenred Corporate Portal.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4.	يتعين على العميل تقديم الاسم وتفاصيل الاتصال الخاصة بالشخص الذي تم تخصيص له الدخول والقيام بالتعديل  على حساب العميل الخاص ببوابة شركة ايدنريد للشركات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	With any Payroll File, the Customer shall ensure that the Salaries funds have been credited one (1) working day in advance to the Payroll Account in order to meet the total Salaries amounts on the Payroll File and to the nominated Edenred fee account to meet the applicable Fees.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4	في كل حالة لأي ملف كشوف مرتبات، يتعين على العميل التأكد من أن الأموال الخاصة بالمرتبات قد تم إيداعها قبل يوم عمل واحد (1) في حساب كشوفات المرتبات من أجل تلبية إجمالي مبالغ المرتبات في ملف كشوفات المرتبات وإلى حساب رسوم شركة ايدنريد المحدد لتلبية الرسوم المطبقة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Fees do not include any applicable value-added tax ("VAT") or any other taxes that may be applicable in the future by the respective authorities. Any VAT, if applicable, will be payable by the Customer. This additional amount will be added to the invoice at the appropriate rate.',
            },
            [LANGUAGES.arabic]: {
                text: '5.4	لا تشمل الرسوم أي ضريبة قيمة مضافة سارية أو أي ضرائب أخرى قد تكون قابلة للتطبيق في المستقبل من قبل السلطات المعنية. أي ضريبة قيمة مضافة، إن وجدت، ستكون مستحقة الدفع من قبل العميل. سيتم إضافة هذا المبلغ الإضافي إلى الفاتورة بالمعدّل المناسب',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	ATM Waiver - All Free ATM Waiver on Cash Withdrawal at any Bank ATM “that has its logo in the card” and All Free on Edenred ATMs in the UAE.',
            },
            [LANGUAGES.arabic]: {
                text: '6.5.	استثناء ماكينات الصرافة – كافة ماكينات الصرافة مجانية عند السحب النقدي من أي ماكينة صرافة تابعة للبنك الموجود على البطاقة وايضا عندما يتعلق الأمر بماكينات صرافة شركة ايدنريد في الإمارات العربية المتحدة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer agrees that Edenred may amend the Fees set forth in Schedule of Charges at its sole discretion after giving a 30 day notice to the Customer.',
            },
            [LANGUAGES.arabic]: {
                text: '7.5.	يوافق العميل على أنه يجوز لشركة ايدنريد تعديل الرسوم الواردة في جدول الرسوم وفقًا لتقديرها الخاص بعد تقديم إشعار بمدة 30 يومًا للعميل قبل تغير الرسوم.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	This Terms of Service shall enter in full force as of the date of signing the Terms of Service and shall be valid for one year (hereinafter the “Initial Term”)  and shall be automatically renewed for additional term (hereinafter a “Renewal Term”. For the avoidance of any doubt, the Initial Term and any Renewal Term are collectively referred to in this Terms of Service to as the “Term”) unless otherwise agreed by the Parties, during the term of the Terms of Service the monthly fee shall be adjusted by a percentage equal by a percentage equal to the percentage increase in the UAE inflation rate. Such adjustment shall take place only once per year on a calendar-year basis.',
            },
            [LANGUAGES.arabic]: {
                text: '8.5.	تدخل هذه شروط الخدمة حيز التنفيذ بالكامل اعتبارًا من تاريخ توقيع شروط الخدمة وستكون صالحة لمدة عام واحد (يشار إليها فيما يلي باسم "المدة الأولية") ويتم تجديدها تلقائيًا لمدة إضافية (يشار إليها فيما يلي بـ "مدة التجديد"). لتجنب أي شك، يُشار إلى المدة الأولية وأي مدة تجديد مجتمعين في هذه شروط الخدمة باسم "المدة") ما لم يتم الاتفاق على خلاف ذلك بين الطرفين، خلال مدة شروط الخدمة ، يتم تعديل الرسوم الشهرية بنسبة مئوية تساوي النسبة المئوية لزيادة معدل التضخم في دولة الإمارات العربية المتحدة، ويتم هذا التعديل مرة واحدة فقط في السنة على أساس السنة الميلادية.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer hereby undertakes to inform its Employees and always keep them informed of any change(s) or amendment(s) to this Terms of Service, as and when it occurs.',
            },
            [LANGUAGES.arabic]: {
                text: '9.5	يتعهد العميل بموجب هذا بإخطار موظفيه وجعلهم دائمًا على علم بأي تغيير (تغييرات) أو تعديل (تعديلات) على هذه شروط الخدمة ، كيفما وعند حدوثها.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Edenred shall not be liable, in any way for any files processed in the Edenred system once the file submitted and/or approved.',
            },
            [LANGUAGES.arabic]: {
                text: '10.5.	لن تكون شركة ايدنريد مسؤولة بأي شكلٍ من الأشكال عن أي ملفات تمت معالجتها على نظامها، بمجرد إرسال الملف و/أو الموافقة عليه.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: <><p>3.4.	The Customer undertakes that Salary will be Processed and transferred to the C3Pay Card only for Cardholders whose salary according to the labour contract is less than AED 5,000 (Five Thousand UAE Dirhams).&nbsp;<span className='text-underline'>Variable pay and other remuneration components related to their employment with the Customer can be loaded onto the C3Pay card in addition to the contracted salary, up to a value of 20,000 AED (Twenty Thousand UAE Dirhams).</span></p>
                    <p>End of Service (EOS) Payments are permitted upto a value of AED 50,000 (Fifty Thousand UAE Dirhams). The Customer acknowledges that such EOS Payments <span className='text-underline'>between AED 20,000 and 50,000</span> shall only be approved once during the lifetime of the active C3Pay Card issued to the respective beneficiary Cardholder and shall immediately initiate the Deletion Process of that C3Pay Card upon the receipt of the same.</p>
                </>,
            },
            [LANGUAGES.arabic]: {
                text: <>
                    <p> يتعهد العميل بأن الراتب سيتم معالجته وتحويله إلى بطاقة C3Pay فقط لحاملي البطاقات الذين يكون راتبهم وفقًا لعقد العمل أقل من 5000 درهم إماراتي. يمكن تحميل المكافأة المتغيرة ومكونات الأجر الأخرى المتعلقة بتوظيفهم لدى العميل على بطاقة بالإضافة إلى الراتب المتعاقد، بحد أقصى قيمة قدرها 20,000 درهم إماراتي.</p>
                    <p>يُسمح بدفعات نهاية الخدمة بقيمة تصل إلى 50,000 درهم إماراتي. يقر العميل بأن مثل هذه الدفعات بين 20,000 و 50,000 درهم إماراتي يجوز الموافقة عليها مرة واحدة فقط خلال فترة صلاحية بطاقة C3Pay النشطة المُصدرة لحامل البطاقة المستفيد، ويجب أن تبدأ فوراً عملية حذف تلك البطاقة C3Pay عند استلامها دفعة نهاية الخدمة</p>
                </>,
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Edenred Covenants',
            },
            [LANGUAGES.arabic]: {
                text: '4.	تعهدات شركة ايدنريد ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	On the Payment Dates and subject to this Terms of Service, Edenred shall load the C3Pay payroll cards for those Employees listed in the Payroll File with their respective Salaries',
            },
            [LANGUAGES.arabic]: {
                text: '1.5	في تواريخ السداد وبناءًا على هذه شروط الخدمة ، تقوم شركة ايدنريد بتحميل بطاقات مرتبات C3Pay للموظفين المدرجين في ملف كشوفات المرتبات بمرتباتهم الخاصة بهم.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Edenred shall carry out the Services in a diligent and professional manner and in compliance with all UAE applicable laws, regulations and appropriate banking practices.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4.	  يتعين على شركة ايدنريد تنفيذ الخدمات باجتهاد ومهنية ووفقًا لكافة القوانين واللوائح المعمول بها في دولة الإمارات العربية المتحدة والممارسات المصرفية المناسبة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Edenred shall not perform the Services unless notified to do so by the Customer. Unless the Payroll File is transmitted and received by Edenred in the manner as prescribed in this Terms of Service and any related service level Terms of Service, no Salary shall be loaded into any C3Pay Payroll Card.',
            },
            [LANGUAGES.arabic]: {
                text: '3.5	لا يجوز لشركة ايدنريد أداء الخدمات ما لم يتم إخطارها بذلك من طرف عميل. وما لم يتم إرسال ملف كشوف المرتبات واستلامه من قبل شركة ايدنريد بالطريقة المذكورة في هذه شروط الخدمة  وأي شروط الخدمة بمستوى الخدمة ذات صلة، فلن يتم تحميل أي راتب في أي بطاقة كشوف مرتبات C3Pay.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Any funds transferred or loaded into the C3Pay Payroll Cards shall continue to be owned by the Employee even after termination of their employment with the Customer or expiration of the C3Pay Payroll Card; in both cases Edenred shall provide the Customer with the necessary channels to ensure that any such funds remaining in the C3Pay Payroll Card can be made available to the Employee up to 90 day from the date of the termination of the employment of the Employee with the Customer.',
            },
            [LANGUAGES.arabic]: {
                text: '7.6.	تظل أي أموال يتم تحويلها أو تحميلها في بطاقات كشوفات المرتبات C3Pay مملوكة للموظف حتى بعد انتهاء عملهم مع العميل أو انتهاء صلاحية بطاقة كشوفات المرتبات C3Pay ؛ وفي كلتا الحالتين، يتعين على شركة ايدنريد توفير القنوات اللازمة للعميل لضمان إتاحة أي أموال متبقية في بطاقة كشوفات المرتبات C3Pay  للموظف إلى غاية 90 يومًا من تاريخ إنهاء توظيف الموظف لدى العميل.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Intellectual Property',
            },
            [LANGUAGES.arabic]: {
                text: '4.	الملكية الفكرية:',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer agrees that Intellectual Property Rights in any part of the world which are at any time created by or deriving from the business of or owned by Edenred, developed by Edenred or are the existing property of Edenred are exclusively owned by Edenred and the Customer will not use or infringe such Intellectual Property Rights.',
            },
            [LANGUAGES.arabic]: {
                text: '1.6	يوافق العميل على أن حقوق الملكية الفكرية في أي جزء من العالم، والتي تم إنشاؤها في أي وقت من طرف أو مشتقة من الأعمال التجارية لشركة ايدنريد أو المملوكة لها أو التي طورتها شركة ايدنريد أو أنها تحت الملكية الحالية لشركة ايدنريد هي حقوق مملوكة حصريًا لشركة ايدنريد ولن يقوم العميل باستعمال أو انتهاك حقوق الملكية الفكرية.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer agrees that all Intellectual Property Rights in all documents and materials specifically produced by or on behalf of Edenred in connection with or relating to this Terms of Service shall vest in and belong to Edenred.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4.	يوافق العميل على أن تكون كافة حقوق الملكية الفكرية في كافة المستندات والمواد الناتجة خصوصًا عن شركة ايدنريد أو بالنيابة عنها فيما يتعلق أو يتصل بهذه شروط الخدمة ملكًا لشركة ايدنريد وتعود لها.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	General',
            },
            [LANGUAGES.arabic]: {
                text: '4.	بند عام:',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Edenred will not be liable to the Customer for any delay or failure to fulfill its obligations under this Terms of Service to the extent that any such delay or failure arises from causes beyond its reasonable control including, without limitation, acts of terrorism, fire, floods, acts of God, acts or regulations of any governmental authority, war, riots, or strikes.',
            },
            [LANGUAGES.arabic]: {
                text: '1.7	 لن تكون شركة ايدنريد مسؤولة حيال العميل عن أي تأخير أو فشل في استيفاء التزاماتها بموجب هذه شروط  الخدمة إلى الحد الذي يكون فيه أي تأخير أو فشل ناشئ خارج نطاق سيطرتها المعقولة بما في ذلك، على سبيل المثال لا الحصر، أعمال الإرهاب أو الحرائق أو القضاء والقدر أو أعمال أو تنظيمات أي سلطة حكومية أو حرب أو أعمال شغب أو إضرابات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Both parties shall (and will ensure that its personnel shall) keep confidential all information of the other party unless otherwise agreed in writing or the disclosure of such confidential information is permitted by law or disclosed to professional advisors.',
            },
            [LANGUAGES.arabic]: {
                text: '2.9 يحافظ كلا الطرفين (وسيضمنان أن يحافظ موظفيهما) على سرية كافة المعلومات الخاصة بالطرف الآخر ما لم يتم الاتفاق على خلاف ذلك خطيًا أو يُسمح بالكشف عن هذه المعلومات السرية بموجب القانون أو يتم الكشف عنها للمستشارين المهنيين.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	All communication, C3Pay Payroll Cards, notices, invoices, demands or other documents under this Terms of Service may be delivered personally to either party, sent by courier or email to the other party to their respective addresses.',
            },
            [LANGUAGES.arabic]: {
                text: '3.9.	يجوز تسليم كافة الإرساليات أو بطاقات كشوف المرتبات C3Pay أو الإخطارات أو الفواتير أو الطلبات أو الوثائق الأخرى بموجب هذه شروط  الخدمة شخصيًا إلى أي من الطرفين أو إرسالها بالبريد السريع أو البريد الإلكتروني للطرف الآخر على عناوينهم الخاصة بهم.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer shall provide the necessary approvals to Edenred when requested for delivering & distribution of Cards at the premises of the Customer or the Customer’s staff accommodation.',
            },
            [LANGUAGES.arabic]: {
                text: '4.9.	يقوم العميل بتقديم الموافقات اللازمة لشركة ايدنريد عند الطلب لتسليم وتوزيع البطاقات في مقر العميل أو مكان إقامة موظفي العميل.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Any delay or omission by any party in exercising or enforcing (in whole or in part) any right or remedy arising under this Terms of Service shall not be construed as a waiver of such right or remedy.',
            },
            [LANGUAGES.arabic]: {
                text: '5.9.	لا يتم تفسير أي تأخير أو إغفال من قبل أي طرف في ممارسة أو إنفاذ (كليًا أو جزئيًا) لأي حق أو تعويض ناشئ بموجب هذه شروط الخدمة على أنه تنازل عن هذا الحق أو التعويض.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	If any of the provisions under this Terms of Service is or becomes illegal or unenforceable, the remaining provisions shall continue in full force and effect.',
            },
            [LANGUAGES.arabic]: {
                text: '6.9.	إذا كانت أي من الأحكام في هذه شروط الخدمة غير قانونية أو أصبحت غير قانونية أو غير قابل للتنفيذ، تظل الأحكام المتبقية سارية المفعول والتأثير بالكامل.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	No variation, extension or cancellations of this Terms of Service shall be binding upon either party unless agreed in writing by both parties.',
            },
            [LANGUAGES.arabic]: {
                text: '7.9.	لن يكون أي تغيير أو تمديد أو إلغاء لهذه شروط الخدمة ملزمًا لأي من الطرفين ما لم يتم الاتفاق خطيًا من قبل الطرفين.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	If at any time a dispute arises in connection with this Terms of Service, representatives of the Customer and Edenred shall in the first instance meet in good faith with a view to resolving the dispute within a period of fifteen (15) working days from the day the dispute first arises. Should the parties fail to resolve the dispute amicably, then either party shall have a right to refer the matter to their appropriate levels of senior management for resolution.',
            },
            [LANGUAGES.arabic]: {
                text: '8.9.	في حال نشوء نزاع في أي وقت فيما يتصل بهذه شروط  الخدمة ، يجتمع ممثلو العميل وشركة ايدنريد في الأول بحسن نية بهدف حل النزاع في غضون خمسة عشر (15) يوم عمل من اليوم الذي نشأ فيه النزاع لأول مرة. في حال فشل الطرفين لحل النزاع وديًا، يحق لأي من الطرفين إحالة الأمر إلى المستويات المناسبة لكل منهما من الإدارة العليا لحلها.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	This Terms of Service is governed by the laws of the Emirate of Dubai and the laws of the United Arab Emirates and the parties submit to the exclusive jurisdiction of the Dubai Courts.',
            },
            [LANGUAGES.arabic]: {
                text: '9.9.	تخضع هذه شروط الخدمة لقوانين إمارة دبي وقوانين الإمارات العربية المتحدة ويخضع الطرفان للاختصاص القضائي الحصري لمحاكم دبي.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	All disputes arising out of or in connection with the present Terms of Service shall be settled amicably by sincere efforts of both Parties hereto, but if and when such disputes cannot be so settled, within 60 (sixty) days after occurrence of such disputes, then such disputes shall be, by the request of either Party, finally settled in the Dubai Courts.',
            },
            [LANGUAGES.arabic]: {
                text: '10.9.	تتم تسوية كافة النزاعات الناشئة عن أو فيما يتعلق بهذه شروط الخدمة وديًا من خلال الجهود المخلصة لكلا الطرفين بموجب هذه شروط  الخدمة ، ولكن إذا وعندما يتعذر تسوية تلك النزاعات في غضون 60 (ستين) يومًا بعد حدوث تلك النزاعات، تتم تسويتها، بناءًا على طلب أي من الطرفين، نهائيًا في محاكم دبي.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Service Level',
            },
            [LANGUAGES.arabic]: {
                text: '4.	مستوى الخدمة',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Service Implementation & Customer Onboarding',
            },
            [LANGUAGES.arabic]: {
                text: '1.8	تنفيذ الخدمة وتسجيل العملاء',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..1.	Customer Meetings – Edenred shall undertake to provide representation at customer meetings to discuss implementation concerns.  The Customer will undertake to arrange such meetings by serving a written notice in advance to Edenred.',
            },
            [LANGUAGES.arabic]: {
                text: '1.1.10	اجتماعات العملاء - تتعهد شركة ايدنريد بتوفير التمثيل في اجتماعات العملاء لمناقشة المخاوف الخاصة بالتنفيذ. يتعهد العميل بترتيب تلك الاجتماعات من خلال تقديم إشعار خطي مسبق إلى شركة ايدنريد.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..2.	Commencement Date – Edenred shall agree the relevant commencement with the customer during the implementation meetings.',
            },
            [LANGUAGES.arabic]: {
                text: '2.1.10	تاريخ البدء – يتعين على شركة ايدنريد أن توافق على تاريخ البدء ذي الصلة مع العميل خلال اجتماعات التنفيذ.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	On-going Service',
            },
            [LANGUAGES.arabic]: {
                text: '2.10	  الخدمة المستمرة',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..1.	Customer Service – Edenred shall provide on-going customer service to the Customer and its Employees in the form of customer meetings, telephone conferences and helpdesks.',
            },
            [LANGUAGES.arabic]: {
                text: '1.2.10	خدمة العملاء - تقدم شركة ايدنريد خدمة عملاء مستمرة للعميل وموظفيه في شكل اجتماعات العملاء والمؤتمرات عبر الهاتف ومكاتب المساعدة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..2.	Issues – Edenred shall undertake to investigate and remedy all valid issues reported by the Customer within a reasonable time.  The Customer shall undertake to report any issues and concerns as soon as practically possible.',
            },
            [LANGUAGES.arabic]: {
                text: '2.2.10	الإشكاليات - تتعهد شركة ايدنريد بالتحقيق في ومعالجة كل الإشكاليات الحقيقية والمشروعة التي أبلغ عنها العميل في غضون فترة زمنية معقولة. يتعهد العميل بالإبلاغ عن أي إشكاليات ومخاوف في أقرب وقت ممكن عمليًا.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Reports and Invoicing',
            },
            [LANGUAGES.arabic]: {
                text: '3.10.	التقارير والفواتير',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..1.	Invoicing – Edenred shall undertake to provide relevant invoicing for each month’s activities.',
            },
            [LANGUAGES.arabic]: {
                text: '1.3.10	الفوترة - تتعهد شركة ايدنريد بتوفير الفواتير ذات الصلة الخاصة بأنشطة كل شهر.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..2.	Processing of Payroll File/Payment Date –Edenred shall ensure that the Salaries are loaded into the C3Pay Payroll Cards by the next working day after receiving funds before the WPS Cut-Off Time of 14:30 PM.',
            },
            [LANGUAGES.arabic]: {
                text: '2.3.10	معالجة ملف كشوف المرتبات/ تاريخ الدفع - تضمن شركة ايدنريد أن يتم تحميل المرتبات في بطاقات كشوف المرتبات C3Pay بحلول يوم العمل التالي بعد تلقي الأموال قبل آخر موعد لقيد ودائع في حساب عميل لبنك عبر نظام حماية الأجور والذي هو 14:30 مساءًا.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..3.	Confirmation of Successful Payment – Edenred shall provide confirmation to the Customer on the Payment Date that the payroll has been successfully processed and the relevant Salaries loaded into the C3Pay payroll cards.',
            },
            [LANGUAGES.arabic]: {
                text: '3.3.10	تأكيد الدفع الناجح - تقدم شركة ايدنريد تأكيدًا للعميل في تاريخ الدفع بأنه تمت معالجة كشوف المرتبات بنجاح وتحميل المرتبات ذات الصلة في بطاقات كشوفات المرتبات C3Pay.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '..4.	Issues with Payroll File – Edenred undertake to make all necessary validations and checks on all submitted Payroll Files and report any discrepancies on Edenred payroll portal.',
            },
            [LANGUAGES.arabic]: {
                text: '4.3.10	المشكلات الخاصة بملف المرتبات - تتعهد شركة ايدنريد بإجراء كافة عمليات المصادقة والتحقق اللازمة على كافة ملفات كشوف المرتبات المقدمة والإبلاغ عن أي تباينات عبر بوابة كشوف المرتبات الخاصة بشركة ايدنريد.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Compliance with AML/CFT Rules and Regulations',
            },
            [LANGUAGES.arabic]: {
                text: '4.	12.	الإلتزام بقوانين مكافحة غسل الأموال وتمويل الإرهاب',
            },
        },
        {
            [LANGUAGES.english]: {
                text: ' 3.4.	Customer will be liable to provide all the necessary KYC documents in regards to the company registration and authorized signatories at the time of signing of this Terms of Service',
            },
            [LANGUAGES.arabic]: {
                text: '1.9	يكون العميل مسؤولاً عن تقديم كافة مستندات "اعرف عميلك" الضرورية فيما يخص تسجيل الشركة والمفوضين بالتوقيع في وقت توقيع هذه شروط  الخدمة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Customer shall be liable, to either provide all the necessary KYC documents for their employees applying for a C3Pay Payroll Card, or alternatively support their Employees in providing the same directly to Edenred at time of distribution of the cards.',
            },
            [LANGUAGES.arabic]: {
                text: '2.11	 يكون العميل مسؤولاً، إما عن تقديم كل مستندات "اعرف عميلك" الضرورية الخاصة بموظفيه الذين يطلبون الحصول على بطاقة كشوف الروتب C3Pay ، أو بدلاً من ذلك دعم موظفيه في تقديم تلك المستندات مباشرةً إلى شركة ايدنريد في وقت توزيع البطاقات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Customer must make an effort in informing Edenred at the time of termination of the Employee',
            },
            [LANGUAGES.arabic]: {
                text: '3.11	يجب على العميل إبلاغ شركة ايدنريد في الوقت المناسب بإنهاء خدمات الموظف.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	11.4.	The Client warrant not to use C3Pay Cards or any Value-Added Services offered by Edenred to engage in Financial Crime, whether directly or indirectly benefiting any individual or legal entity listed on sanctions. Additionally, the Client assures that the funds will be employed exclusively for lawful purposes and in strict compliance with all relevant laws and regulations in the UAE, including, but not limited to, anti-money laundering and financial crime prevention laws.',
            },
            [LANGUAGES.arabic]: {
                text: '3.12	3.12	يضمن العميل عدم استخدام بطاقات C3Pay أو أي خدمات ذات قيمة مضافة تقدمها Edenred للانخراط في جرائم مالية، سواء بشكل مباشر أو غير مباشر لصالح أي فرد أو كيان قانوني مدرج في العقوبات. بالإضافة إلى ذلك، يؤكد العميل أن الأموال سيتم استخدامها حصريًا للأغراض القانونية وفي الامتثال الصارم لجميع القوانين واللوائح ذات الصلة في دولة الإمارات العربية المتحدة، بما في ذلك، على سبيل المثال لا الحصر، قوانين مكافحة غسل الأموال ومنع الجرائم المالية.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Indemnity',
            },
            [LANGUAGES.arabic]: {
                text: '4.	التعويض',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	The Customer agrees to indemnify, defend and hold harmless Edenred, its holding company, affiliates, subsidiaries and their representatives, officers, employees, contractors, assigns and agents indemnified from and against all losses arising in connection with this Terms of Service, including, without limitation, in connection with any breach of this Terms of Service by the  Customer, any Card Application, any C3Pay Payroll Cards, any payment to any C3Pay Payroll Cards, any Payroll Account, any Payroll File, the Intellectual Property Rights, any fraud, act or omission of the Customer, the Employees or any other third party.',
            },
            [LANGUAGES.arabic]: {
                text: '1.10	يوافق العميل على تعويض والدفاع عن وتعويض تضرر شركة ايدنريد وشركتها القابضة وشركاتها التابعة وشركاتها الفرعية وممثليها ومسؤوليها وموظفيها ومقاوليها والمتنازل لهم والوكلاء الذين يتم تعويضهم عن وضد كافة الخسائر الناشئة فيما يتعلق بهذه شروط  الخدمة ، بما في ذلك من دون حصر، فيما يتعلق بأي خرق لهذه شروط  الخدمة من قبل العميل، أو أي طلب للبطاقة، أو أي بطاقات كشوف المرتباتC3Pay ، وأي مدفوعات لأي بطاقات كشوف المرتبات C3Pay، وأي حساب كشوف مرتبات، أو أي ملف كشوف مرتبات، أو حقوق ملكية فكرية، أو أي احتيال أو فعل أو إغفال من طرف العميل أو الموظفين أو أي طرف ثالث آخر.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	In case Edenred cannot deliver a Card to the Cardholder, the Company takes the responsibility to deliver the Card to the Cardholder and keep the record of delivery of such Cards.',
            },
            [LANGUAGES.arabic]: {
                text: '2.12	 في حال لم تتمكن شركة ايدنريد من تسليم البطاقة إلى صاحبها، تتحمل الشركة مسؤولية تسليم البطاقة إلى حامل البطاقة والاحتفاظ بسجل تسليم هذه البطاقات.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '3.4.	Customer shall take full responsibility of the Cards upon taking delivery of the Cards from Edenred representative.',
            },
            [LANGUAGES.arabic]: {
                text: '3.13	 يتحمل العميل المسؤولية الكاملة عن البطاقات عند استلامها من ممثل شركة ايدنريد.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '4.	Data Protection',
            },
            [LANGUAGES.arabic]: {
                text: '11.0	 حماية البيانات ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '11.1	Edenred undertakes to be the Data Controller of all personal data procured as a consequence of the performance of activities outlined in this Terms of Service or any subsequent Amendments to this Terms of Service.',
            },
            [LANGUAGES.arabic]: {
                text: '11.1  تمثل إيدنريد مراقب البيانات الشخصية التي تم جمعها نتيجة لأداء الأنشطة الموضحة في هذه شروط  الخدمة أو أي تعديلات لاحقة على هذه شروط  الخدمة.',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '11.2	The Customer acknowledges and agrees that the storage of such data specified in Clause 11.1 is undertaken by the Third-Party Card Processing Company affiliated with Edenred.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4.	  يقر العميل ويوافق على أن البيانات المخزنة والمحددة فى البند 13.1 يتم معالجتها عن طريق طرف ثالث وهى شركة معالجة البيانات التابعة لشركة إيدنريد ',
            },
        },
        {
            [LANGUAGES.english]: {
                text: '11.3	Edenred agrees to execute its role as specified in Clause 11.1 in compliance with the provisions of the applicable Data Protection Laws of the UAE.',
            },
            [LANGUAGES.arabic]: {
                text: '3.4.	  توافق شركة إيدينريد على تنفيذ دورها على النحو المحدد فى البند 13.1 بما يتوافق مع أحكتم قوانين حماية البيانات المعمول به فى دولة الإمارات العربية المتحدة ',
            },
        },
    ],
});
