import React from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import { images } from '@assets/img/index';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { EmployeesExcelDownload } from '@sharedComponent/excel/employees-excel';
import ClevertapReact from 'src/utils/clever-tap';
import useQuickMenus from '../../../hooks/useQuickMenus/useQuickMenus';
import { SITEMAP } from 'src/routes/sitemap';

const ApprovePresentational = ({ goTodashboard, loading, employeeTableData = [] }) => {
	const { quickMenus } = useQuickMenus();
	return (
		<section className="approve_page_wrapper">
			<main className="main">
				<ImageComponent src={images.logo} className="approve_page_header_image" />
				<div className="title_section">
					<ETypo h2 medium color="#181919">
						What would you like to do next?
					</ETypo>
					<EdenredButtons
						btnType="secondary"
						onClick={() => {
							ClevertapReact.event('Onboarding_GoToDashboard');
							goTodashboard(SITEMAP.dashboard.index);
						}}>
						{' '}
						Skip & Go To Dashboard{' '}
					</EdenredButtons>
				</div>

				<section className="card_section">
					{quickMenus &&
						quickMenus.map((menu, index) => {
							return (
								<div className="card" key={`card-${index}`}>
									<img src={menu.icon} alt="" />
									<h1>{menu.title}</h1>
									<p>{menu.subTitle}</p>

									{menu.actionType === 'NAVIGATE' && typeof menu?.action?.navigateTo === 'string' && (
										<EdenredButtons
											btnType="secondary"
											onClick={async () => {
												ClevertapReact.event(menu.cleverTapValue, null);
												goTodashboard(menu.action.navigateTo);
											}}>
											{' '}
											Select{' '}
										</EdenredButtons>
									)}

									{menu.actionType === 'DOWNLOAD' && typeof menu?.action?.downloadEmployeeDetails === 'function' && (
										<EmployeesExcelDownload
											dataSet={employeeTableData}
											filename={`employeelist`}
											// filename={`${moment(new Date()).format('MM/DD/YYYY')}_Employee_list`}
											element={
												<EdenredButtons
													btnType="secondary"
													onClick={async () => {
														ClevertapReact.event(menu.cleverTapValue);
														const result = await menu.action.downloadEmployeeDetails((status) => {
															console.log('Loading status : ', status);
														});
														console.log('Result : ', result);
													}}>
													Select{' '}
												</EdenredButtons>
											}
										/>
									)}
								</div>
							);
						})}
				</section>
			</main>
		</section>
	);
};
export default ApprovePresentational;

// const temp = () => {
// 	return (
// 		<Row className="main_container">
// 			<Col xl={24} md={24} sm={24} xs={24} className="center_pannel">
// 				<Row className="sub_center_pannel">
// 					<Col xl={24} md={24} xs={24} sm={24} className="Logo_icon">
// 						<ImageComponent src={images.logo} />
// 					</Col>
// 					<Col xl={24} md={24} xs={24} sm={24} className="Logo_icon_mobile">
// 						<ImageComponent src={images.logo} />
// 					</Col>

// 					<Col xl={24} md={24} xs={24} sm={24} className="Logo_icon">
// 						<EdenredTypo> What would you like to do next?</EdenredTypo>
// 					</Col>
// 					<Col xl={24} md={24} xs={24} sm={24} className="Logo_icon_mobile">
// 						<EdenredTypo> What do you like to do first?</EdenredTypo>
// 					</Col>

// 					{content_list?.map((content, i) =>
// 						content?.route ? (
// 							<Col
// 								xl={4}
// 								md={4}
// 								lg={4}
// 								xs={24}
// 								sm={24}
// 								className="card_content"
// 								style={{ cursor: 'pointer' }}
// 								onClick={() => {
// 									console.log({ content });
// 									console.log(content.route);
// 									handleroute(content);
// 									//content.route && goTodashboard(content.route);
// 								}}>
// 								<EdenRedCard type="ApproveDashboard" content={content} className="card_row" />
// 							</Col>
// 						) : (
// 							<>
// 								{content?.name ? (
// 									<Col
// 										xl={4}
// 										md={4}
// 										lg={4}
// 										xs={24}
// 										sm={24}
// 										className="card_content"
// 										onClick={() => ClevertapReact.event('Onboarding_DownloadDetails')}
// 										style={{ cursor: 'pointer' }}>
// 										<EmployeesExcelDownload
// 											dataSet={employeeTableData}
// 											filename={`employeelist`}
// 											// filename={`${moment(new Date()).format('MM/DD/YYYY')}_Employee_list`}
// 											element={<EdenRedCard type="ApproveDashboard" content={content} className="card_row" />}
// 										/>
// 									</Col>
// 								) : null}
// 							</>
// 						)
// 					)}
// 					{content_list?.map((content, i) =>
// 						content?.route ? (
// 							<Col
// 								xl={4}
// 								md={4}
// 								lg={4}
// 								xs={24}
// 								sm={24}
// 								className="card_content_mobile_view"
// 								style={{ cursor: 'pointer' }}
// 								// onClick={() => {
// 								// 	if (content.name == '') {

// 								// 	}
// 								// }}
// 								onClick={() => {
// 									console.log({ content });
// 									console.log(content.route);
// 									handleroute(content.route);
// 									///content.route && goTodashboard(content.route);
// 								}}>
// 								<EdenRedCard type="ApproveDashboard" content={content} className="card_row" />
// 							</Col>
// 						) : (
// 							<>
// 								{content?.name ? (
// 									<Col
// 										xl={4}
// 										md={4}
// 										lg={4}
// 										xs={24}
// 										sm={24}
// 										onClick={() => ClevertapReact.event('Onboarding_DownloadDetails')}
// 										className="card_content_mobile_view"
// 										style={{ cursor: 'pointer' }}>
// 										<EmployeesExcelDownload
// 											dataSet={employeeTableData}
// 											filename={`employeelist`}
// 											// filename={`${moment(new Date()).format('MM/DD/YYYY')}_Employee_list`}
// 											element={<EdenRedCard type="ApproveDashboard" content={content} className="card_row" />}
// 										/>
// 									</Col>
// 								) : null}
// 							</>
// 						)
// 					)}

// 					<Col xl={24} md={24} sm={24} xs={24} className="go_to">
// 						<Col xl={24} className="go_btm">
// 							<EdenredButton
// 								loading={loading}
// 								onClick={() => goTodashboard(ClevertapReact.event('Onboarding_GoToDashboard'), null)}
// 								type="outline-g"
// 								style={{ width: '13%' }}>
// 								Go To Dashboard
// 							</EdenredButton>
// 						</Col>
// 						<Col xl={24} className="link_button">
// 							<EdenredButton onClick={() => goTodashboard(ClevertapReact.event('Onboarding_GoToDashboard'), null)} type="link">
// 								Go To Dashboard
// 							</EdenredButton>
// 						</Col>
// 					</Col>
// 					<Col xl={24} lg={24} md={24} xs={24} sm={24} className="card_dashboard">
// 						<Row style={{ width: '100%' }}>
// 							<Col xl={12} lg={12} md={12}>
// 								<ImageComponent src={images.leftbubble} />
// 							</Col>
// 							<Col xl={12} lg={12} md={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
// 								<ImageComponent src={images.rightbubble} />
// 							</Col>
// 						</Row>
// 					</Col>
// 				</Row>
// 			</Col>
// 		</Row>
// 	);
// }
