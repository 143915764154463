import React from 'react';
import { Col } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import AccordianPresentation from '@pages/DesignSystem/Accordian/accordian-presentaion';
import ItemHeader from '@pages/help/components/ItemHeader';
import './payroll-faqs.scss';

const PayrollFaqs = () => {
	const faq = [
		{
			question: ' I have added a few employees. When can I start running payroll for them?',
			answer: 'You can start running payroll for your employees once their accounts are active.You can run payroll for all employees under "Active" status in the "Manage Employees" section.',
			id: 0,
			isActive: false,
		},
		{
			question: 'I have run payroll. When will my employees receive their salaries?',
			answer: 'You can track your payroll status',
			id: 1,
			isActive: false,
		},

		{
			question: ' How do I process salaries through my bank?',
			answer: "You can process salaries on your bank's portal using our routing code 740810000.",
			id: 2,
			isActive: false,
		},
		{
			question: 'Where do I transfer salary funds?',
			answer: 'You can view our account details here',
			id: 3,
			isActive: false,
		},
		{
			question: 'My company has been blocked by the Ministry of Labour for non-payment of salary. How do I remove the block?',
			answer: 'If you have paid salaries for the previous months but made an error in the selection of the month or type of payroll, you can remove the block by requesting for a salary letter that you can show as proof of payment to the Ministry of Labour. Please send a mail with the details to support.ae@edenred.com.',
			id: 4,
			isActive: false,
		},
		{
			question: ' I require a Salary Letter to prove that I ran WPS payroll for a certain month. How do I get this?',
			answer: '	You can send an e-mail requesting the same to support.ae@edenred.com. Please state the month and the year you require the salary letter for.',
			id: 5,
			isActive: false,
		},
		{
			question: 'How do I know the status of my payroll fund transfer?',
			answer: 'You can know the status of your fund transfer. When the payroll funds have been received, the salaries will start being processed.',
			id: 6,
			isActive: false,
		},
		{
			question: ' Salary processing was rejected for a few of my employees. How do I process salaries for them?',
			answer: 'Salary processing is usually rejected by central bank because of incorrect employee details entered. Please check the WPS Person ID, WPS Establishment ID and Labour Card Number of the employee and correct these details by updating them in the employees section, after clicking on the employee.',
			id: 7,
			isActive: false,
		},
		{
			question: 'How do I get the statement of my payroll account and view past transfers and balance?',
			answer: 'You can view past transfers and balance in from the Transaction History section.',
			id: 8,
			isActive: false,
		},
	];

	return (
		<Col xs={24} sm={24} xl={24} md={24} lg={24} className="help-payroll-presentational-col">
			<ItemHeader title="Payroll" />
			<Col className="questions" xl={24} lg={24} md={24} sm={24} xs={24}>
				<AccordianPresentation questions={faq} />
			</Col>
		</Col>
	);
};
export default PayrollFaqs;
