import Action from './action';
import AnnouncementsSummary from './AnnouncementsSummary';
import React, { useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import moment from 'moment';
import SetupTitle from '../Component/SetupTitle/SetupTitle';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { useDispatch, useSelector } from 'react-redux';
import { announcementClaimCheck } from '../../../constants/app-constants';
import './announcement.scss';
import ClevertapReact from 'src/utils/clever-tap';

const Announcement = () => {
	const [announcementModal, setAnnouncementModal] = useState(false);
	const [announcementMessage, setAnnouncementMessage] = useState({
		title: '',
		body: '',
	});
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(Action.creators.getAnnouncement());
	}, []);
	const handleTitleMessage = (title) => {
		setAnnouncementMessage((prev) => ({ ...prev, title: title }));
	};
	const handleBodyMessage = (body) => {
		setAnnouncementMessage((prev) => ({ ...prev, body: body }));
	};
	const handleSendAnnouncement = () => {
		ClevertapReact.event('V1_1_Announ_sendConfirm', { ...announcementMessage });
		dispatch(Action.creators.putAnnouncementUpload({ title: announcementMessage?.title.trim(), body: announcementMessage?.body.trim() }));
		setAnnouncementModal(false);
		setAnnouncementMessage({ title: '', body: '' });
	};
	const notification = useSelector((state) => state?.hrSetup?.announcementList?.message);
	const notificationHistory = notification?.[0]?.announcementList.sort((a, b) => Date.parse(b.notificationDate) - Date.parse(a.notificationDate));
	let hrClaims = useSelector((state) => state?.sharedStates?.currentUser?.hrClaims);
	const nHrClaims = [];
	const claims = hrClaims?.map((data) => {
		if (announcementClaimCheck.includes(data?.value)) {
			nHrClaims.push(data?.value);
		}
	});
	return (
		<>
			<Edenredmodal
				title="You will be sending this message for FREE as an app notification to 40/50 of your employees:"
				desc={
					<div className="modal-description">
						<section className="announcement-modal-title-container">
							<span className="announcement-modal-title">Title:</span>
							{announcementMessage?.title.trim()}
						</section>
						<section className="announcement-modal-message-container">
							<span className="announcement-modal-message">Description:</span>"{announcementMessage?.body.trim()}"
						</section>
					</div>
				}
				onOkay={() => handleSendAnnouncement()}
				onCancelButton="Edit Message"
				onOkayButton="Send Announcement"
				onCancel={() => setAnnouncementModal(false)}
				isOpen={announcementModal}
				close={() => setAnnouncementModal(false)}
				btnleft={true}
				left={true}
				textcenter={false}
				textStart={true}
			/>
			<div className="announcement-container">
				<SetupTitle
					title={`Announcements`}
					rightBtnType={'primary'}
					rightBtnDisabled={!(announcementMessage?.body.trim() !== '' && announcementMessage?.title.trim() !== '')}
					rightBtnText={nHrClaims.length > 0 && 'Send Announcement'} // checking user has claims to sent announcements
					rightBtnClick={() => setAnnouncementModal(true)}
				/>

				{nHrClaims.length > 0 && ( // checking user has claims to sent announcement
					<AnnouncementsSummary
						infoShow={true}
						handleBodyMessage={handleBodyMessage}
						handleTitleMessage={handleTitleMessage}
						announcementMessage={announcementMessage}
					/>
				)}
				<div className="new_design_history">
					<div className="new_design_ann_his">
						<Row className="new_his_header">
							<Col xl={18} lg={18} md={18}>
								<p className="heading-history">History</p>
							</Col>
						</Row>
						{notificationHistory && notificationHistory?.length !== 0 ? (
							<div className="new_ann_his">
								{notificationHistory &&
									notificationHistory?.map((data) => (
										<Row>
											<Col xl={24} lg={24} md={24} className="announcement-history">
												<p className="for-date">{data.notificationDate && moment(data.notificationDate).format('LL')}</p>
												<p className="announcement-heading">{data.title}</p>
												<p className="announcement-text">"{data.text}"</p>
											</Col>
											<Divider />
										</Row>
									))}
							</div>
						) : (
							<div className="new_design_no_history">
								<div className="no_announcement_record">
									<Row className="history-frame">
										<ImageComponent src={not_focus_icons?.noAnnouncement} />
									</Row>
									<Row className="history-frame">
										<p className="no-announcement">No announcements yet</p>
									</Row>
									<Row className="history-frame">
										<p className="no-announcement-text">
											After your first announcement you will be <br />
											able to view it here
										</p>
									</Row>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default Announcement;
