import React, { useState } from 'react';
import { Select } from 'antd';
import './dropdown.scss';

const { Option } = Select;

const EdenredDropdown = ({ labelClassName, disabled = false, defaultValue, onChange, options = [], type, className = '', labels, placeholder }) => {
	const [marginTop, setMarginTop] = useState('0px');
	const [fontSize, setFontSize] = useState('14px');

	const handleChangeSelect = () => {
		setMarginTop('-20px');
		setFontSize('10px');
	};

	return (
		<div className="dropdown-label">
			<Select
				defaultValue={defaultValue}
				placeholder={placeholder}
				disabled={disabled}
				className={`${className} edenRedDropdown ${type}`}
				onDropdownVisibleChange={handleChangeSelect}
				onChange={onChange}>
				{options?.map((item, index) => (
					<Option key={index}>{item.label}</Option>
				))}
			</Select>
			{labels && (
				<label
					style={{ marginTop: marginTop, fontSize: fontSize }}
					className={`form-labels-dropdown${labelClassName ? ' ' + labelClassName : ''}`}>
					{labels}
				</label>
			)}
		</div>
	);
};

export default EdenredDropdown;
