import React from 'react';
import './props-tables.scss';

export const Propstable = ({ data }) => {
	console.log(data, 'abc');
	return (
		<div>
			<table className="prop-type-table">
				<thead>
					<tr>
						<th>Prop</th>
						<th>Type</th>
						<th>Default</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((propObj) => (
						<tr key={propObj.propName}>
							<td>{propObj.propName}</td>
							<td>{propObj.propType}</td>
							<td>{propObj.default}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
