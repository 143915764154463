import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import SetupActions from 'src/components/shared/app-action-reducer/action';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './documentSetup.scss';
import ClevertapReact from 'src/utils/clever-tap';

const list = [
	{
		iconSrc: focus_state_icons.deliveredSuccess,
		text: 'Store employee documents safely',
	},
	{
		iconSrc: focus_state_icons.deliveredSuccess,
		text: 'Access employee documents wherever you are',
	},
	{
		iconSrc: focus_state_icons.deliveredSuccess,
		text: 'Get monthly reminders in your email',
	},
];
const DocumentSetup = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const handleGetStarted = () => {
		ClevertapReact.event('V1_1_Docs_GetStarted');
		dispatch(SetupActions.creators.postHrSetupStatus({ documentCompleted: true }));
		history.push('/documents/documents-expiry');
	};
	return (
		<div className="wrappers">
			<span className="titles-container">
				<ETypo h2>Documents</ETypo>

				<span>
					<EdenredButtons
						children="Get started!"
						onClick={typeof handleGetStarted === 'function' && handleGetStarted}
						btnType="primary"
						rightIcon={<ImageComponent className="rightIcon" src={focus_state_icons.RightArrow} />}
					/>
				</span>
			</span>
			<div className="content-wrapper">
				<div className="hero-content">
					<div className="hero">
						<ImageComponent src={focus_state_icons?.StarIcon} className="icon-wrapper" />
						<ETypo h2>Avoid fines, we’ll remind you before documents expire</ETypo>
					</div>

					<section className="list-container">
						<ETypo h3>What is new?</ETypo>
						{list.map((item) => (
							<div className="list">
								<ImageComponent src={item?.iconSrc} />
								<ETypo h5>{item?.text}</ETypo>
							</div>
						))}
					</section>
				</div>
				<div className="video-wrapper">
					<video className="video-container-setup" autoPlay="true" controls>
						<source src="https://eae-c3pay-cdn-p.azureedge.net/hr-videos/HRVideo3_DocumentManagement_1080.mp4" type="video/mp4" />
					</video>
				</div>
			</div>
		</div>
	);
};

export default DocumentSetup;
