import Actions from './action';
export const initState = {
	users: [],
	usersLoading: false,
};

const appReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_USERS: {
			return {
				...state,
				usersLoading: true,
			};
		}
		case Actions.types.GET_USERS_SUCCESS: {
			return {
				...state,
				users: action.response,
				usersLoading: false,
			};
		}
		case Actions.types.GET_USERS_FAILED: {
			return {
				...state,
				usersLoading: false,
			};
		}

		default:
			return state;
	}
};

export default appReducer;
