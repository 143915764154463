import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { DOC_TYPES } from '@constants/app-constants';
import ImageComponent from '@sharedComponent/image-component';
import { LoadingOutlined } from '@ant-design/icons';
import { useComponentVisible } from 'src/helpers/hooks';
import ClevertapReact from 'src/utils/clever-tap';
import { focus_state_icons } from 'src/assets/icons/focus_state';

const DownloadDoc = ({
	downloadDocumentType,
	excelDownloadLoading,
	pdfDownloadLoading,
	detail,
	isComponentVisibleProp,
	setIsComponentVisibleProp,
	className = '',
	filterDocument: { documentTypeFilter = [], expiryDateFilter = [] },
}) => {
	const Item = ({ downloadDocumentType, image, name, value, loading }) => (
		<Col
			className="download-inner-container"
			onClick={() => {
				downloadDocumentType(value);
				if (name === 'Excel File') {
					ClevertapReact.event('Docs_Download_Excel');
				} else if (name === 'PDF File') {
					ClevertapReact.event('Docs_Download_PDF');
				}
			}}>
			<div className="text">{name}</div>
			<div className="image" style={{ height: '40px' }}>
				{!loading ? <ImageComponent className="download-image" src={image} /> : <LoadingOutlined />}
			</div>
		</Col>
	);

	const [downloadDocType, setDownloadDocType] = useState(DOC_TYPES);
	useEffect(() => {
		downloadDocType[0].loading = pdfDownloadLoading;
		downloadDocType[1].loading = excelDownloadLoading;
		setDownloadDocType([...downloadDocType]);
	}, [excelDownloadLoading, pdfDownloadLoading]);

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	return (
		<div className={`${detail?.length ? '' : 'download-disable'} download-div ${className}`} ref={ref}>
			<Col
				className="download-button-hr"
				onClick={() => {
					setIsComponentVisible(!isComponentVisible);
					isComponentVisibleProp && setIsComponentVisibleProp(false);
					ClevertapReact.event('Click_DownloadList');
				}}
				style={{
					background: 'white',
					...(isComponentVisible && { boxShadow: '0px 10px 20px rgba(174, 182, 183, 0.5)' }),
				}}>
				<img src={focus_state_icons.hrExport} className="newDownload" />
				Export
			</Col>
			{isComponentVisible ? (
				<Row align="middle" className="download-container">
					<div className="download-inner-div">
						{(downloadDocType || []).map(({ image, name, value, loading }, index) => (
							<Item
								key={index}
								{...{
									downloadDocumentType,
									loading,
									image,
									name,
									value,
								}}
							/>
						))}
					</div>
				</Row>
			) : null}
		</div>
	);
};

export default DownloadDoc;
