import { createSelector } from 'reselect';
import { initState } from './reducer';

const sendInvite = (state) => state.sendInvite || initState;
const sendingInvitation = createSelector(sendInvite, (cnter) => cnter.sendingInvitation);
const claims = createSelector(sendInvite, (cnter) => cnter.claims);
const claimsLoading = createSelector(sendInvite, (cnter) => cnter.claimsLoading);
const getEmployeesDetail = createSelector(sendInvite, (cnter) => cnter.getEmployeesDetail);
const isEmployeesLoading = createSelector(sendInvite, (cnter) => cnter.isEmployeesLoading);
const isEmployeeUserLoading = createSelector(sendInvite, (cnter) => cnter.isEmployeeUserLoading);
const employeeUserDetail = createSelector(sendInvite, (cnter) => cnter.employeeUserDetail);
const employeeUserError = createSelector(sendInvite, (cnter) => cnter.employeeUserError);

export default {
	sendInvite,
	sendingInvitation,
	claims,
	claimsLoading,
	getEmployeesDetail,
	isEmployeesLoading,
	isEmployeeUserLoading,
	employeeUserDetail,
	employeeUserError,
};
