import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import { createUserManager, loadUser } from 'redux-oidc';
import makeRootReducer from './rootReducer';
import injectReducers from './injectReducers';
import userManager from '@config/user-manager';

const initReducer = (state = {}, action) => {
	return state;
};

const initReducers = {
	init: initReducer,
};

const myStore = (initState = {}) => {
	const loggerMiddleware = (store) => (next) => (action) => {
		// console.log('Action type:', action.type);
		// console.log('Action payload:', action.payload);
		// console.log('State before:', store.getState());
		next(action);
		// console.log('State after:', store.getState());
	};
	const saga = createSagaMiddleware();
	let middleware = [saga, loggerMiddleware];
	const enhancers = [];
	let composeEnhancers = undefined;

	if (process.env.NODE_ENV !== 'production') {
		//remote debugging can be switched on/off by shaking the expo client.
		// if (Platform.OS === 'ios') NativeModules.DevSettings.setIsDebuggingRemotely(true);
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
		// middleware.push(logger);
	} else {
		composeEnhancers = compose;
	}

	// composeEnhancers = compose;
	const store = createStore(makeRootReducer(initReducers), initState, composeEnhancers(applyMiddleware(...middleware), ...enhancers));
	loadUser(store, userManager);

	store.asyncReducers = {};
	store.sagaMiddleware = saga;
	store.injectedEffects = {};

	store.attachSaga = (saga) => {
		store.sagaMiddleware.run(saga);
	};
	injectReducers(store);
	return store;
};

export default myStore;
