import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withReducer } from 'src/store/reducerLoader';
import Selector from './selector';
import action from './action';
import reducer from './reducer';
import saga from './saga/index';
import SetupLeaveUploadPresentational from './leave-upload-presentaional';

const SetupLeaveUploadFunctional = ({
	isLeaveUploadLoading,
	leaveUploadDetails,
	isLeaveDocUploaded,
	leaveApproveUploadSaga,
	leaveApproveTemplateSaga,
	isleaveApproveUpload = false,
	saveDocumentUploadReset,
	isSetup,
}) => {
	const leaveApproval = [
		{
			description: '1',
			list: <span className="spanA">Ensure that the leave balances are up-to-date to the date when you upload the spreadsheet.</span>,
		},
		{
			description: '2',
			list: (
				<span className="spanA">
					Download the leave balances spreadsheet
					<Link to={'#'} className="forHere" onClick={() => leaveApproveTemplateSaga()}>
						Here
					</Link>
				</span>
			),
		},
		{
			description: '3',
			list: <span className="spanA">Fill in your employees' leave balances and upload the spreadsheet below.</span>,
		},
	];
	return (
		<SetupLeaveUploadPresentational
			{...{
				leaveApproval,
				isLeaveUploadLoading,
				isLeaveDocUploaded,
				leaveUploadDetails,
				leaveApproveUploadSaga,
				isleaveApproveUpload,
				saveDocumentUploadReset,
				isSetup,
			}}
		/>
	);
};
const mapStateToProps = (state) => {
	return {
		isLeaveUploadLoading: Selector.isLeaveUploadLoading(state),
		leaveUploadDetails: Selector.leaveUploadDetails(state),
		isLeaveDocUploaded: Selector.isLeaveDocUploaded(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		leaveApproveUploadSaga: (file) => dispatch(action.creators.leaveApproveUploadStart(file)),
		leaveApproveTemplateSaga: () => dispatch(action.creators.leaveApproveTemplateStart()),
		saveDocumentUploadReset: () => dispatch(action.creators.saveDocumentUploadReset()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(SetupLeaveUploadFunctional);
const ReducedScreen = withReducer('leaveApprovalReducer', reducer, saga)(Connected);
export default ReducedScreen;
