import React from 'react';
import MainDashboardFunctional from './components/main-dashboard-functional';
import './main-dashboard.scss';

const MainDasboard = () => {
	return <MainDashboardFunctional />;
};

export default MainDasboard;

const d = {
	departments: [
		{
			id: '99c95b55-fe49-ed11-a27c-281878a772ba',
			name: 'BackEnd',
		},
		{
			id: '19378ff5-c959-ed11-ade6-281878a77855',
			name: '01Novdept',
		},
		{
			id: '3b177c0a-735b-ed11-ade6-281878a77855',
			name: 'IT',
		},
		{
			id: 'd7fc4f27-4b5f-ed11-ade6-281878a77855',
			name: 'sales department',
		},
		{
			id: '7c4cd745-4b5f-ed11-ade6-281878a77855',
			name: 'Recruitment department',
		},
		{
			id: '3bfaf852-4b5f-ed11-ade6-281878a77855',
			name: 'Marketing Deparment',
		},
		{
			id: '66af525c-4b5f-ed11-ade6-281878a77855',
			name: 'Business Development',
		},
		{
			id: '69af525c-4b5f-ed11-ade6-281878a77855',
			name: 'Product Team',
		},
		{
			id: '0cf6a166-4b5f-ed11-ade6-281878a77855',
			name: 'Design Team',
		},
		{
			id: '29a893c4-e276-ed11-ac20-281878a79fb4',
			name: 'house cleaning',
		},
		{
			id: '3a3136ca-954f-ed11-ade6-281878a7d74a',
			name: 'Dev',
		},
		{
			id: '4f41cbc2-1479-ec11-94f6-dc98405ae2f5',
			name: 'HR',
		},
		{
			id: '1aa298f7-1579-ec11-94f6-dc98405ae2f5',
			name: 'Development',
		},
		{
			id: 'a565c841-e179-ec11-94f6-dc98405ae2f5',
			name: 'Project Management',
		},
		{
			id: '8784833a-5082-ec11-94f6-dc98405ae2f5',
			name: 'QA',
		},
		{
			id: '648be4dd-5a82-ec11-94f6-dc98405ae2f5',
			name: 'Delivery',
		},
		{
			id: '45b2ece0-5b82-ec11-94f6-dc98405ae2f5',
			name: 'Sale',
		},
	],
	allowanceTypes: [
		{
			id: '9d1b2de4-b9a5-ec11-a99b-281878a74b5b',
			name: 'HR',
		},
		{
			id: 'f9601416-735b-ed11-ade6-281878a77855',
			name: 'Allowance',
		},
		{
			id: '03b6c870-4b5f-ed11-ade6-281878a77855',
			name: 'Travel allowance',
		},
		{
			id: 'd39a237e-4b5f-ed11-ade6-281878a77855',
			name: 'Medical Allowance',
		},
		{
			id: 'ae6d9886-4b5f-ed11-ade6-281878a77855',
			name: 'Hospital Allowance',
		},
		{
			id: '595af0a1-4b5f-ed11-ade6-281878a77855',
			name: 'DA allowance',
		},
		{
			id: '79065eaa-4b5f-ed11-ade6-281878a77855',
			name: 'Weekend Allowance',
		},
		{
			id: 'cddaffb5-4b5f-ed11-ade6-281878a77855',
			name: 'Festival Allowance',
		},
		{
			id: '0a4c45c3-4b5f-ed11-ade6-281878a77855',
			name: 'House Rent',
		},
		{
			id: '0b4c45c3-4b5f-ed11-ade6-281878a77855',
			name: 'Parking',
		},
		{
			id: '02e961db-4b5f-ed11-ade6-281878a77855',
			name: 'Testing allowanceee',
		},
		{
			id: '72e1fce3-4b5f-ed11-ade6-281878a77855',
			name: 'Reimbursement ',
		},
		{
			id: '506caced-4b5f-ed11-ade6-281878a77855',
			name: 'Special Allowance',
		},
		{
			id: '7b35faf6-4b5f-ed11-ade6-281878a77855',
			name: 'Emergency Allowance',
		},
		{
			id: 'f04d91fe-4b5f-ed11-ade6-281878a77855',
			name: 'Marriage Allowance',
		},
		{
			id: '84c5f50c-964f-ed11-ade6-281878a7d74a',
			name: 'DEV',
		},
		{
			id: '18a298f7-1579-ec11-94f6-dc98405ae2f5',
			name: 'HRA',
		},
		{
			id: 'a365c841-e179-ec11-94f6-dc98405ae2f5',
			name: 'Transport',
		},
		{
			id: 'c51869de-7182-ec11-94f6-dc98405ae2f5',
			name: 'PF',
		},
	],
	additionTypes: [
		{
			id: 'b26d3090-c6a5-ec11-a99b-281878a74b5b',
			name: 'Transport',
		},
		{
			id: 'cc23b11c-735b-ed11-ade6-281878a77855',
			name: 'Fee',
		},
		{
			id: '9888400b-4c5f-ed11-ade6-281878a77855',
			name: 'waive off',
		},
		{
			id: '0ebdf523-4c5f-ed11-ade6-281878a77855',
			name: 'Festive Bonus',
		},
		{
			id: '0fbdf523-4c5f-ed11-ade6-281878a77855',
			name: 'Monday Bonus',
		},
		{
			id: 'd72db42f-4c5f-ed11-ade6-281878a77855',
			name: 'Tuesday Bonus',
		},
		{
			id: 'abafd136-4c5f-ed11-ade6-281878a77855',
			name: 'WFO bonus',
		},
		{
			id: '8ec89549-4c5f-ed11-ade6-281878a77855',
			name: 'Wednesday Bonus',
		},
		{
			id: '2da5aa4f-4c5f-ed11-ade6-281878a77855',
			name: 'Education',
		},
		{
			id: '1aea3762-4c5f-ed11-ade6-281878a77855',
			name: 'unknown Addition',
		},
		{
			id: 'c08b7e6b-4c5f-ed11-ade6-281878a77855',
			name: 'Fresher Bonus',
		},
		{
			id: '5acfde72-4c5f-ed11-ade6-281878a77855',
			name: 'Experiencd Bonus',
		},
		{
			id: '00496a7f-4c5f-ed11-ade6-281878a77855',
			name: 'Dev Bonus',
		},
		{
			id: '01496a7f-4c5f-ed11-ade6-281878a77855',
			name: 'QA bonus',
		},
		{
			id: '2c97d28d-4c5f-ed11-ade6-281878a77855',
			name: 'miscellanious Bonus',
		},
		{
			id: '8038de98-4c5f-ed11-ade6-281878a77855',
			name: 'EB reimbursement',
		},
		{
			id: 'db1a1da0-4c5f-ed11-ade6-281878a77855',
			name: 'go LIVE Bonus',
		},
		{
			id: '8cbadcaf-4c5f-ed11-ade6-281878a77855',
			name: 'Greybox Bonus',
		},
		{
			id: 'ecb5bdb7-4c5f-ed11-ade6-281878a77855',
			name: 'Staging Bonus',
		},
		{
			id: '4732fabe-4c5f-ed11-ade6-281878a77855',
			name: 'UAT bonus',
		},
		{
			id: 'd5cbaec5-4c5f-ed11-ade6-281878a77855',
			name: 'post production Bonus',
		},
		{
			id: '56ff25ce-4c5f-ed11-ade6-281878a77855',
			name: 'Retest Bonus',
		},
		{
			id: '112c70e3-4c5f-ed11-ade6-281878a77855',
			name: 'feedback bonus',
		},
		{
			id: '85c5f50c-964f-ed11-ade6-281878a7d74a',
			name: 'Water',
		},
		{
			id: 'b1368ecf-388a-ec11-a507-a085fc6b06da',
			name: 'Bonus',
		},
		{
			id: '17a298f7-1579-ec11-94f6-dc98405ae2f5',
			name: 'Fuel',
		},
		{
			id: 'a265c841-e179-ec11-94f6-dc98405ae2f5',
			name: 'Tuition Fees',
		},
	],
	deductionTypes: [
		{
			id: 'c2e2a924-e158-ed11-ade6-281878a77855',
			name: 'hola',
		},
		{
			id: '58dd5327-735b-ed11-ade6-281878a77855',
			name: 'LOP',
		},
		{
			id: '1ae5ff6d-555f-ed11-ade6-281878a77855',
			name: '               ',
		},
		{
			id: 'f850e618-964f-ed11-ade6-281878a7d74a',
			name: 'Penson',
		},
		{
			id: 'b2368ecf-388a-ec11-a507-a085fc6b06da',
			name: 'Tax',
		},
		{
			id: '19a298f7-1579-ec11-94f6-dc98405ae2f5',
			name: 'Loans',
		},
		{
			id: 'a465c841-e179-ec11-94f6-dc98405ae2f5',
			name: 'LOPS',
		},
	],
	probationPeriod: {
		id: '817d56e6-1379-ec11-94f6-dc98405ae2f5',
		month: 1,
	},
	corporateId: '33304',
};
