import React, { useEffect, useState } from 'react';
import '../replacement-card.scss';
import { Row, Col, Form } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import Input from '@sharedComponent/text-field';
import EdenredButton from '@sharedComponent/button';
import { useLocation } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';

const IbmBankNumber = ({ history }) => {
	const { state } = useLocation();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		history.push({
			pathname: '/employees/ibm/name',
			state: { ...state, ...values },
		});
	};

	return (
		<>
			<EdenredHeader />
			<Row className="change-bank-account-number">
				<Col xl={24} md={24} lg={24} sm={24} xs={24} className="change-bank-account-header">
					<EdenredTypo>What Is {state.tableData.employeeName} ’s IBAN Number?</EdenredTypo>
				</Col>
				<Col xl={24} md={24} lg={24} sm={24} xs={24} className="change-bank-account-content">
					<EdenredTypo>23-digit IBAN for employees with bank accounts. </EdenredTypo>
				</Col>
				<Form form={form} onFinish={onFinish}>
					<Col xl={13} md={18} lg={15} sm={24} xs={24} className="change-bank-account-input">
						<Form.Item
							name="iBanNumber"
							rules={[
								{ required: true, message: 'Please provide the IBAN Number of the employee' },
								{
									max: 23,
									message: 'Max length should be 23',
								},
							]}>
							<Input type="number" labels="IBAN Number" placeholder="XX00 0000 0000 0000 0000 00" labelClassName="inputlabelrequired" />
						</Form.Item>
					</Col>
					<Row style={{ width: '100%' }}>
						<Col xl={9} md={12} lg={12} sm={24} xs={24} className="change-bank-account-content">
							<Form.Item shouldUpdate={true}>
								{() => (
									<EdenredButton
										disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}>
										Next
									</EdenredButton>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Row>
		</>
	);
};
export default IbmBankNumber;
