import React from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import Feedbox from '../feedbox';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from 'src/assets/icons/not_focus';

const Feedboxtitle = () => {
	return (
		<div>
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'}>
					Help Alert Box With Title
				</ETypo>
			</div>

			<Feedbox {...{ type: 'Help', Icon: <ImageComponent src={not_focus_icons.Help_icn} /> }} />
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Success Alert Box With Title
				</ETypo>
			</div>
			<Feedbox {...{ type: 'Success', Icon: <ImageComponent src={not_focus_icons.success_icn} /> }} />
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Warning Alert Box With Title
				</ETypo>
			</div>
			<Feedbox {...{ type: 'Warning', Icon: <ImageComponent src={not_focus_icons.warning_icn} /> }} />
			<div style={{ marginTop: '20px', marginBottom: '10px' }}>
				<ETypo headerStyle={'h3'} fontWeightStyle={'bold'} style={{ marginTop: '20px', marginBottom: '10px' }}>
					Error Alert Box With Title
				</ETypo>
			</div>
			<Feedbox {...{ type: 'Error', Icon: <ImageComponent src={not_focus_icons.Error_icn} /> }} />
		</div>
	);
};

export default Feedboxtitle;
