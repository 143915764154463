import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SliderPresentational from '@pages/auth/slider/component/slider-presentational';
import { images } from 'src/assets/img';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import './setupslider.scss';
import SharedServices, { GET_PROFILE, SET_LOGIN_STEP } from '@sharedComponent/app-action-reducer/services';

const SetupSlider = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const api = new SharedServices();

	const nextClicked = async () => {
		ClevertapReact.event('Setup_Carousel_Next');
		console.log('T5 nextClicked 2 ');
		setLoading(true);
		await api
			.services(SET_LOGIN_STEP, {
				step: 2,
			})
			.then((updateLoginStepResponse) => {
				console.log('T5 then 1 ');
				api.services(GET_PROFILE)
					.then((res) => {
						console.log('T5 then 2 ');
						setLoading(false);
						console.log('T5 push ');
						history.push('/approve_page');
					})
					.catch((err) => {});
			})
			.catch((getLoginStepErr) => {
				console.log('T5 catch ');
				setLoading(false);
			});
	};

	const contents = [
		{
			image: not_focus_icons.payslipForYourEmployees,
			title: 'Payslips for your employees',
			desc: 'Your employees can get payslips instantly that include allowances, additions, deductions and gratuity.',
		},
		{
			image: not_focus_icons.cardOrdersEasilyIcon,
			title: 'Order Cards Easily',
			desc: 'Employee records, account numbers and live card tracking all at your fingertips.',
		},
		{
			image: not_focus_icons.trackLeavesEasilyIcon,
			title: 'Track Leaves Easily',
			desc: "Leave balances will be calculated automatically and you'll get notified when employees apply for leave.",
		},
		{
			image: not_focus_icons.neverPayAFineIcon,
			title: 'Never Pay A Fine',
			desc: "Keep track of how many employees you've paid and get reminders before documents expire.",
		},
		{
			image: not_focus_icons.communicateInSeconds,
			title: 'Communicate In Seconds',
			desc: 'No need to post flyers in accommodations, send announcements to employees.',
		},
		{
			image: not_focus_icons.noMorePaperWorkIcon,
			title: 'No More Paperwork.',
			desc: 'Your employees can: request leaves, download payslips, receive announcements on C3Pay App and Edenred Portal.',
		},
	];

	return (
		<div className="setup-slider">
			<SliderPresentational {...{ loading: loading, contents, setup: true, nextClicked: nextClicked }} />
		</div>
	);
};

export default SetupSlider;
