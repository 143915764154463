import { Button, Col, Collapse, Divider, Row } from 'antd';
import { DATE_FORMAT, DATE_FORMAT_LEAVE, checkBalanceLeaveTypeArray } from '@constants/app-constants';
import React, { useState } from 'react';
import { checkCompensatory, checkLeaveTypeArray, checkOverlappingleaves } from '@constants/app-constants';

import { DateVaild } from '..';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import Expand from 'react-expand-animated';
import ImageComponent from '@sharedComponent/image-component';
import ViewSubmittedProof from '@pages/payroll/proof-of-transfer/components/view-submitted-proof';
import { images } from 'src/assets/img';
import moment from 'moment';
import { not_focus_icons } from 'src/assets/icons/not_focus';

function MobileLeaveToApproveCard({ cardData, showModal, handlePopupClick, approveMessage, rejectMessage }) {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [viewLeaveApprove, setViewLeaveApprove] = useState(false);
	const [mobileViewSubmittedProof, setMobileViewSubmittedProofModal] = useState(false);
	const [Image, setImage] = useState(null);
	const [attachmentname, setAttachmentname] = useState();
	const { Panel } = Collapse;

	const openSubmittedProofModal = (cardData) => {
		setViewLeaveApprove(true);
		setMobileViewSubmittedProofModal(false);
		setImage(cardData.attachment);
	};

	const closeSubmittedProofModal = () => {
		setViewLeaveApprove(false);
		setMobileViewSubmittedProofModal(false);
	};

	const viewSubmittedProofStylesWithoutHeight = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'relative',
			top: '0px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'auto',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			width: '415px',
			minWidth: '415px',
			// boxShadow: '0px 10px 20px rgba(174, 182, 183, 0.5)',
			boxShadow: 'none',
		},
	};
	return (
		<div className="main-inner-card">
			<Row className="sub-inner-card">
				<Col xs={19} className="mob-secound-text-con">
					<EdenredTypo className="name-leave-mob bold">{cardData.employeeName}</EdenredTypo>
					<EdenredTypo className="name-leave-mob-sub">
						{cardData.employeeId} {cardData.departmentName === null ? null : <span>| {cardData.departmentName}</span>}
					</EdenredTypo>
				</Col>
				<Col xs={5} className={`temp-class icon-container-leave-${cardData.leaveType.replace(/\s/g, '')}`}>
					<div>
						<ImageComponent src={`${not_focus_icons[cardData.leaveType.replace(/\s/g, '')]}`} />
					</div>
				</Col>
				<Col xs={17}>
					<Divider style={{ margin: 0, marginLeft: 10 }} />
				</Col>
			</Row>
			<Row className="sub-inner-card" key="1" onClick={() => setIsCollapsed(!isCollapsed)}>
				<Col xs={19} className="mob-secound-text-con">
					<EdenredTypo className="mob-sub-text-leave medium">
						{`${cardData.leaveType}: ${moment(cardData.startDate).format(DATE_FORMAT_LEAVE.MM_DD)}-${moment(cardData.endDate).format(
							DATE_FORMAT_LEAVE.MM_DD
						)} (${cardData.leaveDays} days)`}
					</EdenredTypo>
					{checkBalanceLeaveTypeArray.includes(cardData.leaveType) ? null : (
						<div>
							{checkCompensatory.includes(cardData?.leaveType) ? (
								<EdenredTypo className="mob-sub-sub-text-leave b2">
									New annual balance : {''} <span>{cardData.remainingBalance == null ? 0 : cardData.remainingBalance}</span> Days
								</EdenredTypo>
							) : (
								<EdenredTypo className="mob-sub-sub-text-leave b2">
									Balance Remaining: {''} <span>{cardData.remainingBalance == null ? 0 : cardData.remainingBalance}</span> Days
								</EdenredTypo>
							)}
						</div>
					)}
				</Col>
				{!isCollapsed && (
					<Col xs={5} className="down-arrow-mob">
						<div>
							<ImageComponent src={`${not_focus_icons.downmobarrow}`} />
						</div>
					</Col>
				)}
			</Row>
			<Expand open={isCollapsed}>
				<Row style={{ padding: 10 }}>
					{checkLeaveTypeArray.includes(cardData.leaveType) ? null : <DateVaild {...{ leaveToApproveDates: cardData }} />}
					<Col style={{ alignItems: 'unset' }} xs={24}>
						{!cardData.note ? (
							<Col className="mob-flex-leave" style={{ alignItems: 'unset' }} xs={24}>
								<div>
									<ImageComponent src={images.Leave_Comments} className="icons-mob-leave1" />
								</div>
								<EdenredTypo className="regular extra-small text-opacity">No Note</EdenredTypo>
							</Col>
						) : (
							<Col className="mob-flex-leave" style={{ alignItems: 'unset' }} xs={24}>
								<div>
									<ImageComponent src={images.Leave_Comments} className="icons-mob-leave" />
								</div>
								<EdenredTypo className="regular extra-small textval">{cardData.note}</EdenredTypo>
							</Col>
						)}
					</Col>
					<Col>
						{!cardData.attachment ? (
							<Col className="mob-flex-leave" xs={24}>
								<div>
									<ImageComponent src={not_focus_icons.No_attachment} className="icons-mob-leave2" />
								</div>
								<div>
									<a className="link-leave-tag1">No attachment</a>
								</div>
							</Col>
						) : (
							<Col className="mob-flex-leave" xs={24}>
								<div>
									<ImageComponent src={images.Leave_vector} className="icons-mob-leave2" />
								</div>
								<div>
									<p className="link-leave-tag textval" onClick={() => openSubmittedProofModal(cardData)}>
										{cardData.attachmentLinkName.substring(cardData.attachmentLinkName.lastIndexOf('/') + 1)}
									</p>
								</div>
							</Col>
						)}
					</Col>
					<Divider />
					<Col className="mob-flex-leave" xs={24}>
						{checkOverlappingleaves.includes(cardData?.leaveType) ? null : (
							<EdenredTypo className="mob-sub-text-leave medium" onClick={() => showModal(cardData)}>
								View overlapping leaves <a className="view-more-tag">View More Here</a>
							</EdenredTypo>
						)}
					</Col>
					<Col className="mob-flex-leave" xs={24}>
						<div>
							<EdenredTypo className="mob-sub-text-leave medium">Status: </EdenredTypo>
							<EdenredTypo className="mob-sub-text-leave regular">{cardData.approvalStatus}</EdenredTypo>
						</div>
					</Col>
					<Row className="bottom-container-leavemob">
						{cardData?.hasUserApproved ? null : (
							<Col xs={19} style={{ display: 'flex' }}>
								{/* <Button className="leave-btn-mob leave-btn-mob-reject">Reject</Button>
							<Button className="leave-btn-mob leave-btn-mob-approve">Approve</Button> */}
								<EdenredButton
									className="leave-btn-mob leave-btn-mob-reject noShadow"
									onClick={() => handlePopupClick(rejectMessage, cardData)}
									disabled={!cardData.canApproveReject}
									style={{ background: 'white', color: '#484B52' }}>
									Reject
								</EdenredButton>
								<EdenredButton
									className="leave-btn-mob leave-btn-mob-approve noShadow"
									onClick={() => handlePopupClick(approveMessage, cardData)}
									disabled={!cardData.canApproveReject}>
									Approve
								</EdenredButton>
							</Col>
						)}
						<Col className="down-arrow-mob" xs={5} onClick={() => setIsCollapsed(false)}>
							<ImageComponent src={not_focus_icons.uparrowmob} />
						</Col>
					</Row>
				</Row>
			</Expand>
			<ViewSubmittedProof
				viewSubmittedProof={viewLeaveApprove}
				setViewSubmittedProofModal={setViewLeaveApprove}
				openSubmittedProofModal={openSubmittedProofModal}
				closeSubmittedProofModal={closeSubmittedProofModal}
				customStyles={viewSubmittedProofStylesWithoutHeight}
				leaveToApproveImage={Image}
				attachmentname={attachmentname}
				leaveToApproveDownload={true}
				buttonText="Download Attachment"
				buttonModalStyle={{
					display: 'none',
				}}
			/>
		</div>
	);
}

export default MobileLeaveToApproveCard;
