import React, { useState, useEffect } from 'react';
import { Col, Divider, Row } from 'antd';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import EdenredPafStep from '@sharedComponent/paf-step-component';
import EdenredTypo from '@sharedComponent/typography';
import PayrollService from 'src/services/payroll-service';
import ContentLoader from '@sharedComponent/content-loader';

const directPAFContents = [
	{
		date: 'July 11',
		processingContent: 'Payroll information received from bank',
		content: 'Payroll information received from bank',
	},
	{
		date: 'July 20',
		processingContent: 'Your salaries are being processed',
		content: 'Your salaries processed',
	},
	{
		date: 'July 30',
		processingContent: 'Your employees have been paid!',
		content: 'Your employees have been paid!',
	},
];

const DirectPAFPayrollStatusContent = ({ status }) => {
	switch (status) {
		case 1:
			return (
				<Row className="direct_paf_payroll_status_content_container">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="direct_paf_payroll_status_header">
						<EdenredTypo>Payroll Submitted, Verifier Approval Pending</EdenredTypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="direct_paf_payroll_status_description">
						<EdenredTypo>You'll be notified by mail when your employees have received their salaries.</EdenredTypo>
					</Col>
				</Row>
			);
		case 2:
			return (
				<Row className="direct_paf_payroll_status_content_container">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="direct_paf_payroll_status_header">
						<EdenredTypo>Payroll submitted, processing salaries</EdenredTypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="direct_paf_payroll_status_description">
						<EdenredTypo>You'll be notified by mail when your employees have received their salaries.</EdenredTypo>
					</Col>
				</Row>
			);
		case 3:
			return (
				<Row className="direct_paf_payroll_status_content_container">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="direct_paf_payroll_status_header">
						<EdenredTypo>Salaries Have Been Credited To Employees!</EdenredTypo>
					</Col>
				</Row>
			);
		default:
			return null;
	}
};

const DirectPAFPayrollStatus = () => {
	const history = useHistory();
	const { uploadId } = useParams();
	console.log('uploadId....', uploadId);
	const navigateToviewPayroll = () => {
		history.push(`/payroll/failedDetail/${uploadId || ''}`, { state: uploadId });
	};

	const location = useLocation();
	const {
		state: { payrollRunHeader, cardDetail },
	} = location;
	const [stepModal, setStepModal] = useState(false);
	const [state, setstate] = useState({
		loading: true,
		statusResponse: null,
		stepCount: 0,
	});

	const showModal = () => {
		setStepModal(true);
	};

	const afterOpenModal = () => {
		setStepModal(false);
	};
	const closeModal = () => {
		setStepModal(false);
	};

	const api = new PayrollService();

	const { loading, statusResponse, stepCount } = state;

	const getPayrollStatus = () => {
		let type = null;
		if (cardDetail) {
			type = cardDetail.accountType === 'L' ? 'C3' : 'BANK';
		}
		api.getPayrollStatus(uploadId, type)
			.then((result) => {
				setstate({
					...state,
					loading: false,
					statusResponse: result.data,
				});
			})
			.catch((err) => {
				setstate({
					...state,
					loading: false,
				});
			});
	};

	useEffect(() => {
		if (statusResponse) {
			const {
				payrollSubmittedByUser,
				approvedByVerifier,
				salaryFundsAreReady,
				salaryProcessed,
				yourEmployeesPaid,
			} = statusResponse.payrollStatus;

			const temp = [
				{
					label: 'payrollSubmittedByUser',
					value: payrollSubmittedByUser,
				},
				{
					label: 'salaryProcessed',
					value: salaryProcessed,
				},
				{
					label: 'yourEmployeesPaid',
					value: yourEmployeesPaid,
				},
			];

			const index = temp.filter((data) => data.value).length;
			setstate({
				...state,
				stepCount: index,
			});
		}
	}, [statusResponse]);

	useEffect(() => {
		getPayrollStatus();
	}, []);

	const handleCount = (count) => {
		switch (count) {
			case 1:
				return '1.3';
			case 2:
				return '2.3';

			default:
				break;
		}
	};

	const tempCount = 1;

	const stepCountTemp = stepCount === 2 ? `2.0` : `${stepCount + 1}.0`;
	// const stepCountTemp = stepCount === 3 ? 4.0 : `${stepCount + 1}.0`;
	return (
		<>
			<EdenredHeader />
			{loading ? (
				<ContentLoader />
			) : (
					<Row className="direct_paf_payroll_status_container">
						<DirectPAFPayrollStatusContent status={stepCount} />
						<Divider />
						<Col xl={20} lg={20} md={20} sm={24} xs={24} className="direct_paf_payroll_status_payroll_amount_details">
							<EdenredTypo className="desktop_view_content">
								{<span>{statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.header}</span>}
							</EdenredTypo>
							<EdenredTypo className="mobile_view_content">
								<span>{statusResponse && statusResponse.payrollStatus && statusResponse.payrollStatus.header}</span>
							</EdenredTypo>
						</Col>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="direct_paf_payroll_status_payroll_steps_progress_bar">
							<EdenredPafStep activeStep={stepCountTemp} contents={directPAFContents} />
						</Col>
						<Col sm={24} xs={24} className="direct_paf_payroll_status_mobile_view_message">
							<EdenredTypo>You’ll be notified when employees receive their salaries.</EdenredTypo>
						</Col>
						<Row className="direct_paf_payroll_status_button_section">
							<Col xl={5} lg={5} md={12} sm={12} xs={12} className="direct_paf_payroll_status_view_button">
								{/* <Link to="/payroll/failedDetail"> */}
								<EdenredButton noShadow={true} onClick={navigateToviewPayroll}>
									View Payroll Details
							</EdenredButton>
								{/* </Link> */}
							</Col>
							<Col sm={24} xs={24} className="direct_paf_payroll_status_view_button_mobile">
								<Link to="/payroll/failedDetail">
									<EdenredButton noShadow={true}>Payroll Details</EdenredButton>
								</Link>
							</Col>
						</Row>
					</Row>
				)}
		</>
	);
};

export default DirectPAFPayrollStatus;
