import React from 'react';
import { CustomAlertNotification } from '@pages/Leave-Management/request-leave/alert-notification/alert-notification';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

export const PopUp = ({
	isSavePopUp,
	handlePopUp,
	saveMessage,
	subSaveMessage,
	loading,
	isCancelPopUp,
	cancelMessage,
	subCancelMessage,
	savedSuccessfully,
	successMessage,
	notification,
	notificationMessage,
	okPopUpText,
	cancelPopUpText,
}) => {
	return (
		<>
			<Edenredmodal
				{...{
					isOpen: isSavePopUp,
					onCancel: () => handlePopUp('closeSavePopUp'),
					onOkay: () => handlePopUp('handleSavePopUp'),
					title: saveMessage,
					desc: subSaveMessage,
					loading: loading,
					onOkayButton: 'Save',
					onCancelButton: 'Cancel',
				}}
			/>
			<Edenredmodal
				{...{
					isOpen: isCancelPopUp,
					onCancel: () => handlePopUp('closeCancelPopUp'),
					onOkay: () => handlePopUp('handleCancelPopUp'),
					title: cancelMessage,
					desc: subCancelMessage,
				}}
			/>
			{/* <Edenredmodal
				{...{
					isOpen: savedSuccessfully,
					title: successMessage,
					onOkay: () => handlePopUp('closeSuccessPopUp'),
					onOkayButton: 'Ok',
				}}
			/> */}
			<CustomAlertNotification
				{...{
					when: notification,
					message: notificationMessage,
					okText: okPopUpText,
					cancelText: cancelPopUpText,
					onOK: () => true,
					onCancel: () => true,
				}}
			/>
		</>
	);
};
