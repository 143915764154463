import Actions from './action';
export const initState = {
	setup: {},
	setupLoading: false,
	setupUpdateLoading: false,
	setupUpdateStatus: 'loading',
};

const setUpScreenReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_SETUP: {
			return {
				...state,
				setupLoading: true,
			};
		}
		case Actions.types.GET_SETUP_SUCCESS: {
			return {
				...state,
				setup: action.response,
				setupLoading: false,
			};
		}
		case Actions.types.GET_SETUP_FAILED: {
			return {
				...state,
				setupLoading: false,
			};
		}
		case Actions.types.SETUP_STATUS_UPDATE_START: {
			return {
				...state,
				setupUpdateLoading: true,
				setupUpdateStatus: 'loading',
			};
		}
		case Actions.types.SETUP_STATUS_UPDATE_SUCCESS: {
			return {
				...state,
				setupUpdateLoading: false,
				setupUpdateStatus: 'updated',
			};
		}
		case Actions.types.SETUP_STATUS_UPDATE_FAIL: {
			return {
				...state,
				setupUpdateLoading: false,
				setupUpdateStatus: 'failed',
			};
		}

		default:
			return state;
	}
};

export default setUpScreenReducer;
