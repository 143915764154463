import React from 'react';
import './invite-team-member.scss';
import { withReducer } from 'src/store/reducerLoader';
import reducer from './reducer';
import saga from './sagas/index';
import InviteTeamMemberFunctional from './components/invite-team-member-functional';

const InviteTeamMember = ({ issetup }) => {
	return <InviteTeamMemberFunctional {...{ issetup }} />;
};

const ReducedScreen = withReducer('sendInvite', reducer, saga)(InviteTeamMember);

export default ReducedScreen;
