import Invoices from '@pages/company/invoices/invoices-page';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import { Col, Row, Tabs } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import CompanyDetails from './company-details';
import CompanyDocumentCard from './company-document-card';

const { TabPane } = Tabs;

const CompanyMobilePresentational = (props) => {
	return (
		<Row className="company_mobile_container">
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				<Tabs defaultActiveKey="1" size="small">
					<TabPane tab="Your Company Information" key="1">
						<Row className="company_information_container">
							<CompanyDetails />
							<Row className="company_documents_details_row">
								<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company_documents_title">
									<EdenredTypo>Company Documents</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} sm={24} xs={24} className="company_documentation_card_section">
									{props.companyDocuments.map((companyDocument, index) => (
										<CompanyDocumentCard content={companyDocument} key={index} />
									))}
								</Col>
							</Row>
							<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_main_trade_license_section">
								<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_main_trade_license_description">
									<EdenredTypo>
										To add a new Main Trade License, please send a copy of the following to support.ae@edenred.com:
									</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_main_trade_license_step_button_section">
									{props.steps.map((step, index) => (
										<Row key={index} className="add_new_main_trade_license_steps_container">
											<Col xl={1} lg={1} md={1} sm={2} xs={2} className="add_new_main_trade_license_step_icon">
												<EdenredTypo>{step.step}</EdenredTypo>
											</Col>
											<Col xl={22} lg={22} md={22} sm={20} xs={20} className="add_new_main_trade_license_step_content">
												<EdenredTypo>{step.description}</EdenredTypo>
											</Col>
										</Row>
									))}
									{/* <Link to="/company/branch">
										<EdenredButton>Add New Trade License</EdenredButton>
									</Link> */}
								</Col>
							</Col>
						</Row>
					</TabPane>
					{/* <TabPane tab="Invoices" key="2">
						<Invoices />
					</TabPane> */}
				</Tabs>
			</Col>
		</Row>
	);
};

export default CompanyMobilePresentational;
