import React, { useMemo } from 'react';
import { Col } from 'antd';
import moment from 'moment';
import ImageComponent from '@sharedComponent/image-component';
import { checkForPassportDocuments, checkForVisaDocuments } from '@helpers';
import { DATE_FORMAT } from '@constants/app-constants';
import './Component/leave-to-approve.scss';

export const DateVaild = ({ leaveToApproveDates }) => {
	const passportDate = moment(leaveToApproveDates?.passportExpiryDate).format('DD/MM/YYYY');
	const visaDate = moment(leaveToApproveDates?.visaExpiryDate).format('DD/MM/YYYY');

	const documentPassportValid = useMemo(() => {
		return checkForPassportDocuments(
			leaveToApproveDates.passportExpiryDate,
			leaveToApproveDates?.endDate
				? moment(leaveToApproveDates?.endDate).format(DATE_FORMAT.YYYY_MM_DD)
				: moment().format(DATE_FORMAT.YYYY_MM_DD),
			moment(leaveToApproveDates?.startDate).format(DATE_FORMAT.YYYY_MM_DD)
		)?.filter((data) => data.rulePass);
	}, [leaveToApproveDates, leaveToApproveDates?.endDate]);

	const documentVisaValid = useMemo(() => {
		return checkForVisaDocuments(
			leaveToApproveDates.visaExpiryDate,
			leaveToApproveDates?.endDate
				? moment(leaveToApproveDates?.endDate).format(DATE_FORMAT.YYYY_MM_DD)
				: moment().format(DATE_FORMAT.YYYY_MM_DD),
			moment(leaveToApproveDates?.startDate).format(DATE_FORMAT.YYYY_MM_DD)
		)?.filter((data) => data.rulePass);
	}, [leaveToApproveDates, leaveToApproveDates?.endDate]);
	return (
		<>
			{documentPassportValid?.map((data) => (
				<Col className="passport mob-flex-leave">
					<ImageComponent className="image1 icons-mob-leave" src={data?.img} />
					<p className="text1 mob-sub-text-leave medium">
						Passport {data?.text == 'Valid' ? data?.text : ''}
						<span className="text1_pass leave-typo-date">
							{' '}
							({data?.name} {passportDate == 'Invalid date' ? 'Missing' : `on ${passportDate}`})
						</span>
					</p>
				</Col>
			))}
			{documentVisaValid?.map((data) => (
				<Col className="passport mob-flex-leave">
					<ImageComponent className="image1 icons-mob-leave" src={data?.img} />
					<p className="text1 mob-sub-text-leave medium">
						Visa {data?.text == 'Valid' ? data?.text : ''}
						<span className="text1_pass leave-typo-date">
							{' '}
							({data?.name} {visaDate == 'Invalid date' ? 'Missing' : `on ${visaDate}`})
						</span>
					</p>
				</Col>
			))}
		</>
	);
};
