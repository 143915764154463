import React, { useEffect, useState } from 'react';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { Card, Col, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import CompanyDetails from './company-details';
import { successToast } from 'src/utils/toast';
import Popup from 'src/utils/popup/popups';

const tradeLicenses = [
	{
		name: 'Edengreen Dubai',
		licenseType: 'MoL',
		establishmentId: '1234567890123456789',
	},
	{
		name: 'Edengreen Abu Dhabi',
		licenseType: 'MoL',
		establishmentId: '0192856291019887019',
	},
	{
		name: 'Edenred Free Zone',
		licenseType: 'JAFZA',
		establishmentId: '9910295578000091006',
	},
	{
		name: 'Edengreen Sharjah',
		licenseType: 'MoL',
		establishmentId: '0192856291019887019',
	},
];

const TradeLicenses = () => {
	let history = useHistory();
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});

	useEffect(() => {
		if (history.location.state && history.location.state.response) {
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: history.location.state.response,
				subMessage: null,
			});
			// successToast({
			// 	msg: history.location.state.response,
			// 	icon: 'Check',
			// });
		}
	}, []);

	const closePopup = () => {
		setpopupVisible(false);

		setTimeout(() => {
			if (history.location && history.location.state && history.location.state.response) {
				const state = { ...history.location.state };
				delete state.response;
				history.replace({ ...history.location, state });
			}
		}, 0);
	};

	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
			<EdenredHeader />
			<Row className="trade_licenses_container">
				<CompanyDetails />
				<Row className="trade_licenses_details_row">
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="trade_licenses_title">
						<EdenredTypo>Trade Licenses</EdenredTypo>
					</Col>
					<Col xl={24} lg={24} md={24} sm={24} xs={24} className="trade_licenses_card_section">
						{tradeLicenses.map((tradeLicense, index) => (
							<Col xl={12} lg={12} md={12} sm={12} xs={12} className="trade_license_card_column">
								<Card className="trade_license_card">
									<Col xl={22} lg={22} md={22} sm={22} xs={22} className="trade_license_content">
										<EdenredTypo className="trade_license_title">{tradeLicense.name}</EdenredTypo>
										<EdenredTypo className="trade_license_type_id">{tradeLicense.licenseType}</EdenredTypo>
										<EdenredTypo className="trade_license_type_id">{tradeLicense.establishmentId}</EdenredTypo>
									</Col>
									<Col xl={2} lg={2} md={2} sm={2} xs={2} className="trade_license_edit_icon">
										<ImageComponent src={icons.not_focus.Edit} />
									</Col>
								</Card>
							</Col>
						))}
					</Col>
				</Row>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_new_trade_license_button_section">
					<Link to="/company/branch">
						<EdenredButton>Add New Trade License</EdenredButton>
					</Link>
				</Col>
			</Row>
		</>
	);
};

export default TradeLicenses;
