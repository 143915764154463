import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { debounceHandler } from '@helpers';
import counterReducer from '@pages/payroll-plus-new/reducer';
import sagas from '@pages/payroll-plus-new/sagas/index';
import BankDetailsTransferCodeDesign from './bank-details-transfer-code-design';
import Actions from 'src/pages/payroll-plus-new/action';
import { withReducer } from 'src/store/reducerLoader';
import './invoice-bank-transfer.scss';

const BankDetailsTransferCode = ({ }) => {
	const [transferSalary, setTransferSalary] = useState(true);
	const [payInvoice, setPayInvoice] = useState(false);
	const [accNumber, setAccNumber] = useState('');
	const [transferCode, setTransferCode] = useState('');
	const [showTransferCode, setShowTransferCode] = useState(true);
	const [errorMessage, setErrorMessage] = useState(false);
	const [rakbankInfo, setRakbankInfo] = useState({ transferCode: {}, accountNumber: {} });
	const [proceedPopUp, setProceedPopUp] = useState(false);
	const [initialAccNumber, setInitialAccountNumber] = useState('');

	const dispatch = useDispatch();
	let rakbankDetails = useSelector((state) => state?.sharedStates?.rakbankdetails);
	let loader = useSelector((state) => state?.sharedStates?.isRakbankLoading);
	let postLoader = useSelector((state) => state?.payrollPlusState?.postRakBankDetailsLoading);

	let showParollBnkDetailPopup = useSelector((state) => state?.payrollPlusState?.isRakBankAccountHolder);

	const payInvoices = [
		{ title: 'Bank Name', value: 'Ras Al Khaimah Bank (RAK BANK)' },
		{ title: 'Bank Branch', value: 'Umm Hurair, Dubai' },
		{ title: 'Account Name', value: 'Edenred Prepaid Card Management Services LLC' },
		{ title: 'Swift Code', value: 'NRAKAEAK' },
		{ title: 'Account Number', value: '0332545791003' },
		{ title: 'IBAN Number', value: 'AE290400000332545791003' },
		{ title: 'Routing Code', value: 'N/A' },
	];
	useEffect(() => {
		if (rakbankDetails?.length > 0 && typeof rakbankDetails !== 'string') {
			rakbankDetails.map((res) => {
				if (res.nonRakBank === true) {
					rakbankInfo.transferCode = res;
					setTransferCode(res?.accountNumber);
					setAccNumber('');
					setInitialAccountNumber('');
				} else {
					rakbankInfo.accountNumber = res;
					setShowTransferCode(false);
					setAccNumber(res?.accountNumber);
					setInitialAccountNumber(res?.accountNumber);
				}
			});
			setRakbankInfo(rakbankInfo);
		}
	}, [rakbankDetails]);

	const createRakankAccDetails = (res, mode) => {
		setAccNumber(res);
		if (res?.toString()?.length === 13) {
			setErrorMessage(false);

			mode === 'inlinEditor' && debounceHandler(res, postBankDetails);
		} else {
			setErrorMessage(true);
		}
	};

	const postBankDetails = (res) => {
		payLaodStructuringAndPosting(res);
	};

	const handleProceeedPopUp = () => {
		setShowTransferCode(true);
		let bankDetailPayload = {};
		if (rakbankInfo?.transferCode?.accountNumber) {
			bankDetailPayload = rakbankInfo.transferCode;
		}
		bankDetailPayload.nonRakBank = true;
		dispatch(Actions.creators.postRakBankDetails(bankDetailPayload));
		setProceedPopUp(false);
	};

	const handleCreatePop = (res) => {
		payLaodStructuringAndPosting(res);
		// setShowCreatePopUp(false);
		setShowTransferCode(false);
	};

	const setPopUpCard = () => {
		dispatch(Actions.creators.isRakBankAccountHolder(true));
	};

	const payLaodStructuringAndPosting = (res) => {
		let bankDetailPayload = {};
		if (rakbankInfo?.accountNumber?.accountNumber) {
			bankDetailPayload = rakbankInfo?.accountNumber;
			bankDetailPayload.accountNumber = res;
		} else {
			bankDetailPayload = rakbankInfo?.transferCode;
			bankDetailPayload.accountNumber = res;
			delete bankDetailPayload?.id;
		}
		bankDetailPayload.nonRakBank = false;

		dispatch(Actions?.creators?.postRakBankDetails(bankDetailPayload));
	};

	const closePopUpCard = (res) => {
		dispatch(Actions.creators.isRakBankAccountHolder(false));
	};
	return (
		<BankDetailsTransferCodeDesign
			{...{
				transferSalary,
				payInvoice,
				rakbankInfo,
				accNumber,
				transferCode,
				setAccNumber,
				showTransferCode,
				setShowTransferCode,
				createRakankAccDetails,
				errorMessage,
				loader,
				proceedPopUp,
				setProceedPopUp,
				handleProceeedPopUp,
				handleCreatePop,
				tabInfo: payInvoices,
				postLoader,
				initialAccNumber,
				setPopUpCard,
				showParollBnkDetailPopup,
				closePopUpCard,
			}}
		/>
	);
};
const ReducedScreen = withReducer('payrollPlusState', counterReducer, sagas)(BankDetailsTransferCode);
export default ReducedScreen;
