import React, { useEffect, useState } from 'react';
import { Row, Col, Switch, Card, Divider } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import ImageComponent from '@sharedComponent/image-component';
import { icons } from '@assets/icons/index';
import EdenredTypo from '@sharedComponent/typography';
import EdenredButton from '@sharedComponent/button';
import { withReducer } from '@store/reducerLoader';
import reducer from '../reducer';
import sagas from '../sagas';
import Actions from '../action';
import Selectors from '../selectors';
import { appConstant } from '@constants/';
import { apiUrl } from '@constants/';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { getLocalvariable } from '@helpers';
import { APP_VARIABLES, CLEVER_TAP_EVENTS } from '@constants/app-constants';
import ClevertapReact from 'src/utils/clever-tap';
// import EdenRedCard from '@sharedComponent/card';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ChooseVerifierPermissions = ({ getClaims, claimsLoading, claims: { Verifier } }) => {
	const { state } = useLocation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const history = useHistory();
	let query = useQuery();

	function onChange({ value }, checked) {
		setData(
			data.map((role) => {
				if (role.value === value) {
					return { ...role, checked };
				} else return role;
			})
		);
	}

	const generateValues = (getClaims = []) => {
		setData(
			getClaims.map((claim, i) => {
				return {
					...claim,
					checked: true,
				};
			})
		);
	};

	useEffect(() => {
		if (Verifier.length) generateValues(Verifier);
	}, [Verifier]);

	const sendInvite = () => {
		ClevertapReact.event(CLEVER_TAP_EVENTS.SendInvite_InviteTeamMember);
		setLoading(true);
		const { username, email, roleId } = state;
		let filteredValue = data.filter((role) => role.checked);
		let claims = filteredValue.map((role) => role.value);
		Axios.post(`${appConstant.domain}${apiUrl.CREATE_USER}${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${username}`, {
			claims,
			email,
			roleId,
		})
			.then((res) => {
				setLoading(false);
				history.push({
					pathname: '/manage_team',
					state: {
						response: `${state.username} has been invited as a Verifer.`,
						text: `We’ve sent an email to ${state.username}.`,
						type: 'Invite',
					},
				});
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const ContentPannel = ({ list }) => {
		return (
			<Row className="choose_verifier_main_content">
				<Col className="choose_verifier_content_list">
					<Row className="choose_verifier_horizontal_line">
						<Divider style={{ margin: ' 0px 0px' }} />
					</Row>
					<Row className="choose_verifier_content_detail_toggle">
						<Col xl={3} md={3} sm={5} xs={5} className="choose_verifier_toggle_switch">
							<Switch onChange={(checked) => onChange(list, checked)} checked={list.checked} className="choose_verifier_switch" />
						</Col>
						<Col xl={21} md={21} sm={19} xs={19}>
							<Row className="choose_verifier_header">
								<EdenredTypo style={{ color: list.checked === false ? '#9A9EA6' : '#484B52' }}>{list.title}</EdenredTypo>
							</Row>
							<Row className="choose_verifier_detail">
								<EdenredTypo style={{ color: list.checked === false ? '#9A9EA6' : '#484B52' }}>{list.description}</EdenredTypo>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	return (
		<>
			<EdenredHeader />
			<Col xs={24} sm={24} md={24} xl={18} className="choose_verifier_permission">
				<EdenredTypo className="choose_verifier_title_mobile_view">What Activities of the User Account do you want to Approve? </EdenredTypo>
				<Card className="choose_verifier_card_action_pannel">
					<Row style={{ width: '100%' }}>
						<Col xl={2} sm={3} xs={3} md={4}>
							<ImageComponent src={icons.focus_state.Warning} />
						</Col>
						<Col xl={19} md={20} sm={21} xs={21} className="choose_verifier_action_approve">
							Disabling any of these actions would mean that the user account can directly perform them WITHOUT your approval. Disable
							this only if you want to make submissions directly from the user account WITHOUT approval.
						</Col>
					</Row>
				</Card>
				<Col xl={24} md={24} xs={24} sm={24} className="choose_verifier_scroll_view">
					{!claimsLoading && data.map((list, index) => <ContentPannel list={list} index={index} />)}
				</Col>
				<Row className="choose_verifier_horizontal_line" style={{ marginTop: '10px' }}>
					<Divider style={{ margin: ' 0px 0px' }} />
				</Row>
				<Col xl={12} md={12} className="choose_verifier_send_invites">
					<EdenredButton onClick={() => sendInvite()} loading={loading}>
						Send Invites
					</EdenredButton>
				</Col>
			</Col>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		sendingInvitation: Selectors.sendingInvitation(state),
		claimsLoading: Selectors.claimsLoading(state),
		claims: SelectorsApp.claims(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendInvite: () => dispatch(Actions.creators.sendInvite()),
		getClaims: (roleId) => dispatch(Actions.creators.getClaims(roleId)),
	};
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(ChooseVerifierPermissions);

const ReducedScreen = withReducer('sendInvite', reducer, sagas)(Connected);

export default ReducedScreen;
