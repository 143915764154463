import Axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import { templateDownload } from '@helpers';

export function* downloadpdfServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const payload = {
		salaryId: action?.payload,
	};
	try {
		const url = `${DOMAIN_URL}/SalaryReport/${corporateId}/payroll/salaryprocessed`;
		const res = yield call(Axios.post, url, payload ,{
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.getDownloadPdfSuccess(res.data));
        const downloadUrl = window.URL.createObjectURL(res.data);
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', 'PayrollSalary.pdf'); //any other extension
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (err) {
		yield put(Actions.creators.getDownloadPdfFail(err));
	}
}

export default function* payrollStatusPdfWatcher() {
	yield takeEvery(Actions.types.GET_DOWNLOAD_PDF_START, downloadpdfServices);
}
