import React from 'react';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import HealthPresentational from './health-insurance-presentational';

const HealthInsuranceFunctional = () => {
    const healthInsurenceDetail = [{
        name: 'Compared Quotes',
        param: 'compareqoutes',
        image: focus_state_icons?.compareqoutes,
        id: 0,
    }, {
        name: 'Best Price',
        param: 'bestprice',
        image: focus_state_icons?.bestprice,
        id: 1,
    }, {
        name: 'Customised Plans',
        param: 'customizedplan',
        image: focus_state_icons?.customizedplan,
        id: 2,
    }, {
        name: 'All In One',
        param: 'allinone',
        image: focus_state_icons?.allinone,
        id: 3,
    }]
    return <HealthPresentational {...{ healthInsurenceDetail }} />;
};

export default HealthInsuranceFunctional;