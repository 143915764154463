const GET_LEAVEBALANCE_START = 'GET_LEAVEBALANCE_START';
const GET_LEAVEBALANCE_SUCCESS = 'GET_LEAVEBALANCE_SUCESS';
const GET_LEAVEBALANCE_FAIL = 'GET_LEAVEBALANCE_FAIL';
const GET_LEAVETYPE_START = 'GET_LEAVETYPE_START';
const GET_LEAVETYPE_SUCCESS = 'GET_LEAVETYPE_SUCESS';
const GET_LEAVETYPE_FAIL = 'GET_LEAVETYPE_FAIL';
const SAVE_LEAVETYPE_START = 'SAVE_LEAVETYPE_START';
const SAVE_LEAVETYPE_SUCCESS = 'SAVE_LEAVETYPE_SUCESS';
const SAVE_LEAVETYPE_FAIL = 'SAVE_LEAVETYPE_FAIL';
const SAVE_LEAVE_SUCCEESSFUL_POPUP = 'SAVED_LEAVE_SUCCEESSFUL_POPUP';

const leaveBalanceStart = (employeeId) => {
	console.log('getLeaveBalanceStart');
	return {
		type: GET_LEAVEBALANCE_START,
		employeeId,
	};
};
const leaveBalanceSuccess = (response) => {
	console.log('getLeaveBalanceSuccess');
	console.log('response', response);
	return {
		type: GET_LEAVEBALANCE_SUCCESS,
		response,
	};
};
const leaveBalanceFail = (error) => {
	console.log('getLeaveBalanceFail');
	return {
		type: GET_LEAVEBALANCE_FAIL,
		error,
	};
};

const leaveTypeStart = (empId) => {
	console.log('getLeaveTypeStart');
	return {
		type: GET_LEAVETYPE_START,
		empId,
	};
};
const leaveTypeSuccess = (response) => {
	console.log('getLeaveTypeSuccess');
	console.log('response', response);
	return {
		type: GET_LEAVETYPE_SUCCESS,
		response,
	};
};
const leaveTypeFail = (error) => {
	console.log('getLeaveTypeFail');
	return {
		type: GET_LEAVETYPE_FAIL,
		error,
	};
};

const saveLeaveTypeStart = (payload) => {
	return {
		type: SAVE_LEAVETYPE_START,
		payload,
	};
};
const saveLeaveTypeSuccess = (response) => {
	return {
		type: SAVE_LEAVETYPE_SUCCESS,
		response,
	};
};
const saveLeaveTypeFail = (error) => {
	return {
		type: SAVE_LEAVETYPE_FAIL,
		error,
	};
};
const saveLeaveSucceessfulPopUp = (flag) => {
	return {
		type: SAVE_LEAVE_SUCCEESSFUL_POPUP,
		flag,
	};
};

export default {
	types: {
		GET_LEAVEBALANCE_START,
		GET_LEAVEBALANCE_SUCCESS,
		GET_LEAVEBALANCE_FAIL,
		GET_LEAVETYPE_START,
		GET_LEAVETYPE_SUCCESS,
		GET_LEAVETYPE_FAIL,
		SAVE_LEAVETYPE_START,
		SAVE_LEAVETYPE_SUCCESS,
		SAVE_LEAVETYPE_FAIL,
		SAVE_LEAVE_SUCCEESSFUL_POPUP,
	},
	creators: {
		leaveBalanceStart,
		leaveBalanceSuccess,
		leaveBalanceFail,
		leaveTypeStart,
		leaveTypeSuccess,
		leaveTypeFail,
		saveLeaveTypeStart,
		saveLeaveTypeSuccess,
		saveLeaveTypeFail,
		saveLeaveSucceessfulPopUp,
	},
};
