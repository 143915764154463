import React, { useState } from 'react';
import { Card, Col, Input, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import ETypo from '@pages/DesignSystem/Typo';
import { icons } from '@assets/icons/index';
import ViewSubmittedProof from '@pages/payroll/proof-of-transfer/components/view-submitted-proof';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import ImageComponent from '@sharedComponent/image-component';
import Switch from '@sharedComponent/switch';
import ClevertapReact from 'src/utils/clever-tap';
import './card.scss';
import '../../../DesktopFix.css';

const EdenRedCard = ({
	users,
	roles,
	getAllFundTransferHistory,
	link,
	background,
	type,
	className,
	title,
	description,
	onClick,
	content,
	hint,
	payroll_table_Data,
	handletoggleswitch,
	onClickApprove,
	isSelectClicked,
	checkedIndex,
	hanldePayTableData,
	userDetails = null,
	edit_permission,
	list,
	viewDetailsClicked,
	claims = [],
	refreshData,
	isSelectedCount,
	symbolicon,
	statusicon,
	setToastMessageShow = null,
	setToastMessage = null,
}) => {
	const [expand, setExpand] = useState(false);

	// future ref
	// const viewSubmittedProofStyles = {
	// 	overlay: {
	// 		position: 'fixed',
	// 		top: 0,
	// 		left: 0,
	// 		right: 0,
	// 		bottom: 0,
	// 		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	// 		display: 'flex',
	// 		alignItems: 'center',
	// 		justifyContent: 'center',
	// 	},
	// 	content: {
	// 		position: 'relative',
	// 		top: '0px',
	// 		left: '0px',
	// 		right: '0',
	// 		bottom: 'auto',
	// 		border: 'none',
	// 		background: 'none',
	// 		overflow: 'auto',
	// 		borderRadius: '0px',
	// 		outline: 'none',
	// 		padding: '0px',
	// 		height: '700px',
	// 		width: '415px',
	// 		minHeight: '700px',
	// 		minWidth: '415px',
	// 		boxShadow: 'none',
	// 	},
	// };
	const viewSubmittedProofStylesWithoutHeight = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'relative',
			top: '0px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'auto',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			width: '415px',
			minWidth: '415px',
			boxShadow: 'none',
		},
	};

	const MobileViewSubmittedProofStyles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			position: 'relative',
			top: '60px',
			left: '0px',
			right: '0',
			bottom: 'auto',
			border: 'none',
			background: 'none',
			overflow: 'inherit',
			borderRadius: '0px',
			outline: 'none',
			padding: '0px',
			height: '650px',
			width: '321px',
			minHeight: '300px',
			minWidth: '321px',
			boxShadow: 'none',
		},
	};

	const [viewSubmittedProof, setViewSubmittedProofModal] = useState(false);
	const [mobileViewSubmittedProof, setMobileViewSubmittedProofModal] = useState(false);

	const openSubmittedProofModal = () => {
		setViewSubmittedProofModal(true);
		setMobileViewSubmittedProofModal(false);
	};

	const closeSubmittedProofModal = () => {
		setViewSubmittedProofModal(false);
		setMobileViewSubmittedProofModal(false);
	};

	const openMobileSubmittedProofModal = () => {
		setMobileViewSubmittedProofModal(true);
		setViewSubmittedProofModal(false);
	};

	const closeMobileSubmittedProofModal = () => {
		setMobileViewSubmittedProofModal(false);
		setViewSubmittedProofModal(false);
	};
	const handleInvitemember = (type) => {
		if (type === 'Verifier') {
			ClevertapReact.event('Verifier_InviteTeamMember');
			history.push({ pathname: roles.link, state: { ...userDetails, roleId: roles.id } });
		} else if (type === 'User') {
			ClevertapReact.event('User_InviteTeamMember');
			history.push({ pathname: roles.link, state: { ...userDetails, roleId: roles.id } });
		}
	};
	const handleviewdetails = () => {
		ClevertapReact.event('ViewDetails_PayrollStatus');
	};

	const history = useHistory();
	const renderSwitch = (type) => {
		switch (type) {
			case 'ApproveDashboard':
				return (
					<Row className="edenredCard_desktop_view_approve_dashboard">
						<Col xl={24} lg={24} md={24} className="card_icon">
							<ImageComponent src={content?.image} />
						</Col>
						<Col xl={24} lg={24} md={24} className="card_content_section">
							<Col xl={24} lg={24} md={24} className="card_title">
								<EdenredTypo
									onClick={() => {
										ClevertapReact.event('Update Password');
									}}>
									{content?.header}
								</EdenredTypo>
							</Col>
							<Col xl={24} lg={24} md={24} className="card_description">
								<EdenredTypo>{content?.detail}</EdenredTypo>
							</Col>
						</Col>
					</Row>
				);
			case 'Edit Permissions':
				const nRoles = users?.roles?.map((data) => data?.name?.toLowerCase());
				const newRoles = users?.roles?.filter((data) => data?.name?.toLowerCase() !== 'employee');
				return (
					<Row
						className="edenredCard_web_view"
						onClick={() => {
							history.push({
								pathname: link,
								state: {
									claims,
									list,
								},
							});
						}}>
						<Col xl={5} lg={5} md={6} sm={6} className="manage_team_user_role">
							<EdenredTypo>{users?.roles === null ? 'none' : users?.userName}</EdenredTypo>
						</Col>
						<Col xl={5} lg={5} md={5} sm={5} className="manage_team_user_detail">
							<EdenredTypo className="manage_team_user_name">{users?.employeeName ? users?.employeeName : '-'}</EdenredTypo>
						</Col>
						<Col xl={8} lg={8} md={5} sm={5} className="manage_team_user_role">
							<EdenredTypo>{users?.userEmail}</EdenredTypo>
						</Col>
						<Col xl={3} lg={4} md={6} sm={5} className="manage_team_user_role1">
							<EdenredTypo>
								<p style={{ margin: 0, whiteSpace: 'normal' }}>
									{nRoles?.includes('superadmin') ? 'Super Admin' : newRoles?.map((data) => data?.name)?.join(', ')}
								</p>
							</EdenredTypo>
						</Col>
						<Col xl={3} lg={2} md={1} sm={2} className="manage_team_user_permission">
							{link ? (
								<Col
									onClick={() => {
										history.push({
											pathname: link,
											state: {
												claims,
												list,
											},
										});
									}}
									style={{ cursor: 'pointer' }}>
									<EdenredTypo style={{ fontSize: 12, color: '#00344E' }}>{edit_permission}</EdenredTypo>
								</Col>
							) : (
								<EdenredTypo>{edit_permission}</EdenredTypo>
							)}
							{edit_permission && (
								<Col
									onClick={() => {
										history.push({
											pathname: link,
											state: {
												claims,
												list,
											},
										});
									}}
									style={{ cursor: 'pointer' }}>
									<ImageComponent src={icons.not_focus.Right} className="arrow_img" />
								</Col>
							)}
						</Col>
					</Row>
				);
			case 'Roles':
				return (
					<Row className="edenredCard_web_view" onClick={() => handleInvitemember(roles.role)}>
						<Col md={22} lg={22} xl={22} className={`invite_team_type_role`}>
							<EdenredTypo className="role">{roles?.role}</EdenredTypo>
							<EdenredTypo className="description">{roles.description}</EdenredTypo>
						</Col>
						<Col md={2} lg={2} xl={2} className="arrow">
							<ImageComponent src={icons.not_focus.Right} style={{ width: '30px', height: '30px' }} className="arrow_img" />
						</Col>
					</Row>
				);
			case 'PayrollChannel':
				return (
					<Row className="edenredCard_web_view">
						<Col xl={6} lg={6} md={6} className={`icon_part`}>
							<ImageComponent src={content?.icon} />
						</Col>
						<Col xl={16} lg={16} md={18} className={`payrol_content_part`}>
							<Row>
								<Col xl={24} lg={24} md={24} className="payroll_title">
									<EdenredTypo>{content?.title}</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} className="payroll_description">
									<EdenredTypo b3>{content?.description}</EdenredTypo>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			case 'PayrollHistory':
				return (
					<Row className="edenredCard_payroll_history">
						<Col className="payroll_history_col_first" style={{ background: background }}></Col>
						<Col className="payroll_history_col_second">
							<div className="card_details_container">
								<div className="card_image">
									<ImageComponent src={statusicon} />
								</div>
								<div className="card_inner_details">
									<ETypo medium h5>
										AED {content?.payrollAmount}
									</ETypo>
									<ETypo light b1 color="#484B52">
										{content?.accountTypeDescription}
									</ETypo>
									<ETypo medium b2>
										{content?.expectedPayout}
									</ETypo>
								</div>
							</div>
						</Col>
						<Col style={{ cursor: 'pointer' }} className="payroll_history_col_third" onClick={() => handleviewdetails()}>
							<ETypo medium b1>
								View Details
							</ETypo>
						</Col>
					</Row>
				);
			case 'ApproveRequests':
				return (
					<Row className="edenredCard_desktop_view_approve_requests">
						<div className="payroll-request-upper-section">
							<div className="payrollrequest-checkbox-container">
								<Checkbox
									{...{
										borderWidth: 1,
										borderRadius: 4,
										checked: content.isSelect,
										size: 14,
										onChange: () => isSelectClicked(content),
										icon: (
											<div className="payroll_plus_table_check_box">
												<img src={icons.not_focus.tick} alt="check" />
											</div>
										),
									}}
								/>
							</div>
							<div className="date-and-button-container">
								<div className="payrollrequest-date-container">
									<div className="month-submited-section">
										<ETypo h5 bold>
											{content?.month_payroll}
										</ETypo>
										<ETypo b2 medium className="view-detail-style" color="#0E8AFF" onClick={() => viewDetailsClicked(content)}>
											View Details
										</ETypo>
									</div>
									<ETypo b1 light color="#484B52">
										{content?.submitted_date}
									</ETypo>
								</div>
								<div className="payrollrequest-cancel-approve-container">
									<EdenredButtons
										{...{
											onClick: () => onClickApprove(content, 'Reject'),
											btnType: 'secondary',
										}}>
										<ETypo b1 medium color="#484B52">
											Reject
										</ETypo>
									</EdenredButtons>
									<EdenredButtons
										{...{
											onClick: () => onClickApprove(content, 'Approve'),
											btnType: 'primary',
											style: { marginLeft: '6px' },
										}}>
										<ETypo b1 medium color="#FFFFFF">
											Approve
										</ETypo>
									</EdenredButtons>
								</div>
							</div>
						</div>
						<div className="payroll-request-down-section">
							<div className="employee-amount-container">
								<div className="total-employee">
									<ETypo b1 medium color="#484B52">
										Total Employes:{' '}
										<ETypo b1 light color="#484B52">
											{content?.employees}
										</ETypo>
									</ETypo>
								</div>
								<div className="total-amount">
									<ETypo b1 medium color="#484B52">
										Total Amount:{' '}
										<ETypo b1 light color="#484B52">
											AED {content?.total_amount}
										</ETypo>
									</ETypo>
								</div>
							</div>
						</div>
					</Row>
				);
			case 'PayrollTimings':
				return (
					<Row className="edenredCard_both_view_payroll_timings">
						{content &&
							content?.map((contents, index) => (
								<Col xl={24} lg={24} md={24} sm={24} xs={24} key={index} className="payroll_timings_row">
									<Col
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={
											index === 0 ? 'payroll_timings_salary_funds_recieved_title' : 'payroll_timings_salary_funds_recieved'
										}>
										<EdenredTypo>{contents?.salaryfundrecieved}</EdenredTypo>
									</Col>
									<Col
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className={index === 0 ? 'payroll_timings_expected_payout_title' : 'payroll_timings_expected_payout'}>
										<EdenredTypo>{contents?.expectedPayout}</EdenredTypo>
									</Col>
								</Col>
							))}
					</Row>
				);
			case 'BankDetails':
				return (
					<Row className="edenredCard_both_view_bank_details">
						{content &&
							content?.map((contents, index) => (
								<Col xl={24} lg={24} md={24} sm={24} xs={24} key={index} className="bank_details_row">
									<Col xl={9} lg={9} md={9} sm={9} xs={9} className="bank_details_title">
										<EdenredTypo medium>{contents?.title}</EdenredTypo>
									</Col>
									<Col xl={15} lg={15} md={15} sm={15} xs={15} className="bank_details_value">
										<EdenredTypo>{contents?.value}</EdenredTypo>
									</Col>
								</Col>
							))}
						<EdenredTypo b3 className="bank_details_hint">
							{hint}
						</EdenredTypo>
					</Row>
				);

			case 'Useful_files':
				return (
					<Row className="edencard_web_view_useful_files">
						<Col className="usefull_file_card">
							<Col className="payrollIcons">
								<ImageComponent src={content?.image} />
							</Col>
							<Col xl={20} lg={20} md={20} xs={20} className="content_useful_files">
								<Col>
									<ETypo medium b1>
										{content?.title}
									</ETypo>
								</Col>
								<Col>
									<ETypo light b1 color="#484B52">
										{content?.description}
									</ETypo>
								</Col>
							</Col>
						</Col>
						<Col className="download_icon">
							{symbolicon === 'salary_reports' ? (
								<ImageComponent className="salaryIcons" src={icons.not_focus.NavigateRight} style={{ height: '17px' }} />
							) : (
								<ImageComponent src={icons.not_focus.downloadNew} />
							)}
						</Col>
					</Row>
				);
			case 'proof_of_transfer':
				return (
					<Row className="edencard_web_view_proof_of_transfer">
						<Col className="proof_history_left_card">
							<Col className="proof_history_image">
								<ImageComponent src={content?.image} />
							</Col>
							<Col xl={13} lg={12} md={11} onClick={openSubmittedProofModal} data-testid="test-proof-history-card-content">
								<Col xl={24} md={24} lg={24}>
									<ETypo medium h5>
										{content?.header}
									</ETypo>
								</Col>
								<Col xl={24} md={24} lg={24}>
									<ETypo light b1 color="#484B52">
										{content?.title}
									</ETypo>
								</Col>
								<Col xl={24} md={24} lg={24}>
									<ETypo medium b2 color="#181919">
										{content?.detail}
									</ETypo>
								</Col>
								{/* Rejection reason */}
								{content?.reason && (
									<Col xl={24} md={24} lg={24}>
										<ETypo bold b2>
											{content?.reason}
										</ETypo>
									</Col>
								)}
							</Col>
						</Col>
						<Col xl={7} md={7} lg={7} className="view_submit">
							<ETypo
								medium
								b1
								color="#0E5274"
								style={{ cursor: 'pointer', textDecoration: 'underline' }}
								onClick={openSubmittedProofModal}>
								View Submitted Proof
							</ETypo>
						</Col>
						<ViewSubmittedProof
							{...{
								refreshData,
								content,
								getAllFundTransferHistory,
								setViewSubmittedProofModal,
								openSubmittedProofModal,
								closeSubmittedProofModal,
								viewSubmittedProof,
								customStyles: viewSubmittedProofStylesWithoutHeight,
								setToastMessageShow,
								setToastMessage,
							}}
						/>
					</Row>
				);
			case 'CompanyInvoice':
				return (
					<Row className="company-invoices-container">
						<Col xl={20} lg={20} md={20} xs={20} sm={20} className="company-invoices-col1">
							<Row className="company-invoices-row">
								<Col xl={24} lg={24} md={24} xs={24} sm={24}>
									<EdenredTypo className="header">{content.header}</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} xs={24} sm={24} style={{ marginTop: '5px' }}>
									<EdenredTypo className="sub-header">{content.subheader}</EdenredTypo>
								</Col>
							</Row>
						</Col>
						<Col xl={4} lg={4} md={4} xs={4} sm={4} className="company-view-details">
							<EdenredTypo>View Details</EdenredTypo>
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);
			default:
				return (
					<Row>
						<Col xl={24} lg={24} md={24}>
							<EdenredTypo medium h4>
								{title}
							</EdenredTypo>
						</Col>
						<Col xl={24} lg={24} md={24}>
							<EdenredTypo b3>{description}</EdenredTypo>
						</Col>
					</Row>
				);
		}
	};

	const renderSwitchMobile = (type) => {
		switch (type) {
			case 'ApproveDashboard':
				return (
					<Row className="edenredCard_mobile_view_approve_dashboard">
						<Col sm={4} xs={4} className="card_icon">
							<ImageComponent src={content?.image} />
						</Col>
						<Col sm={18} xs={18} className="card_content_section">
							<Row>
								<Col sm={24} xs={24} className="card_title">
									<EdenredTypo medium>{content?.header}</EdenredTypo>
								</Col>
								<Col sm={24} xs={24} className="card_description">
									<EdenredTypo>{content?.detail}</EdenredTypo>
								</Col>
							</Row>
						</Col>
						<Col sm={2} xs={2} className="right_icon">
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);

			case 'Edit Permissions':
				return (
					<Row className="edenredCard_mobile_view">
						<Col xs={22} sm={22} className="manage_team_user_details">
							<EdenredTypo medium className="manage_team_user_name">
								{users.userName}
							</EdenredTypo>
							<EdenredTypo medium className="manage_team_user_role">
								{users?.roles?.[0]?.name}
							</EdenredTypo>
							<EdenredTypo className="manage_team_user_mail">{users.userEmail}</EdenredTypo>
						</Col>
						<Col xs={2} sm={2} className="manage_team_edit_permissions">
							<Col
								onClick={() =>
									history.push({
										pathname: link,
										state: {
											claims,
										},
									})
								}>
								<ImageComponent src={icons.not_focus.Right} className="arrow_img" />
							</Col>
						</Col>
					</Row>
				);
			case 'Roles':
				return (
					<Link to={{ pathname: roles.link, state: { ...userDetails, roleId: roles.id } }}>
						<Row className="edenredCard_mobile_view">
							<Col sm={22} xs={22} className={`invite_team_type_role`}>
								<EdenredTypo className="role">{roles.role}</EdenredTypo>
								<EdenredTypo className="description">{roles.description}</EdenredTypo>
							</Col>
							<Col sm={2} xs={2} className="arrow">
								<ImageComponent src={icons.not_focus.Right} className="arrow_img" style={{ width: '25px', height: '25px' }} />
							</Col>
						</Row>
					</Link>
				);
			case 'PayrollChannel':
				return (
					<Row className="edenredCard_mobile_view_payroll_channel">
						<Col sm={4} xs={4} className="icon_part">
							<ImageComponent src={content.icon} className="icon" />
						</Col>
						<Col sm={20} xs={20} className={`payrol_content_part`}>
							<Row style={{ width: '100%' }}>
								<Col xl={24} lg={24} md={24} className="payroll_title">
									<EdenredTypo>{content.title}</EdenredTypo>
								</Col>
								<Col xl={24} lg={24} md={24} className="payroll_description">
									<EdenredTypo>{content.description}</EdenredTypo>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			case 'RunPayroll_Mobile':
				return (
					<Row className="runpayroll_mobile_card">
						<Col xs={4} sm={4}>
							<ImageComponent
								src={payroll_table_Data.isSelected === true ? icons.focus_state.Check : icons.not_focus.EmptyBox}
								onClick={(e) => checkedIndex(payroll_table_Data.emp_id)}
								style={{ width: '35px', height: '35px' }}
							/>
						</Col>
						<Col xs={16} sm={4}>
							<Col xs={24} sm={24} style={{ fontSize: '14px', fontWeight: '700', color: '#00344E' }}>
								{payroll_table_Data.firstName}
							</Col>
							<Col xs={24} style={{ fontSize: '12px' }}>
								{payroll_table_Data.employeeCode}
							</Col>
						</Col>
						<Col xs={4} sm={4}>
							<ImageComponent
								src={expand === false ? icons.not_focus.Down : icons.not_focus.Up}
								className="arrow_img_rotate"
								onClick={() => setExpand(!expand)}
							/>
						</Col>
						<Col xs={24} sm={24} className="total_salary">
							<Row>
								<Col xs={10} sm={10} style={{ paddingLeft: '2%' }}>
									<EdenredTypo>Total Salary</EdenredTypo>
								</Col>
								<Col xs={14} sm={14}>
									<Input
										className="runpayroll prehighlight-bold"
										prefix={'AED'}
										type="number"
										style={{
											borderColor:
												payroll_table_Data.isSelected === true
													? payroll_table_Data.totol_sal === '0' || payroll_table_Data.totol_sal === 0
														? '#E60A14'
														: null
													: null,
										}}
										value={payroll_table_Data.totol_sal}
										onChange={(e) => {
											hanldePayTableData('totol_sal', e.target.value, payroll_table_Data.emp_id);
										}}
									/>
									{payroll_table_Data.isSelected === true ? (
										payroll_table_Data.totol_sal === 0 || payroll_table_Data.totol_sal === '0' ? (
											<EdenredTypo style={{ color: '#E60A14' }}>Enter Amount</EdenredTypo>
										) : null
									) : (
										false
									)}
								</Col>
							</Row>
						</Col>
						{expand && (
							<Col xs={24} sm={24}>
								<Col xs={24} sm={24} className="total_salary">
									<Row style={{ paddingTop: '1%' }}>
										<Col xs={10} sm={10} style={{ paddingLeft: '2%' }}>
											<EdenredTypo>Variable Pay</EdenredTypo>
										</Col>
										<Col xs={14} sm={14}>
											<Input
												className="runpayroll prehighlight-bold"
												prefix={'AED'}
												type="number"
												value={payroll_table_Data.variable_pay}
												onChange={(e) => {
													hanldePayTableData('variable_pay', e.target.value, payroll_table_Data.emp_id);
												}}
											/>
											{parseFloat(payroll_table_Data.variable_pay) > parseFloat(payroll_table_Data.totol_sal) ? (
												<EdenredTypo
													style={{
														color: '#E60A14',
													}}>
													{'Must be < Total Salary'}
												</EdenredTypo>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col xs={24} sm={24} className="total_salary">
									<Row style={{ paddingTop: '1%' }}>
										<Col xs={10} sm={10} style={{ paddingLeft: '2%' }}>
											<EdenredTypo>Days on Leave</EdenredTypo>
										</Col>

										<Col xs={14} sm={14}>
											<Input
												className="runpayroll "
												value={payroll_table_Data.dayonLeave}
												type="number"
												onChange={(e) => {
													hanldePayTableData('dayonLeave', e.target.value, payroll_table_Data.emp_id);
												}}
											/>
											{payroll_table_Data.dayonLeave >= 30 ? (
												<EdenredTypo style={{ color: '#E60A14' }}>{`must be < 30`}</EdenredTypo>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col xs={24} sm={24} className="total_salary">
									<Row style={{ paddingTop: '1%' }}>
										<Col xs={14} sm={14} style={{ paddingLeft: '2%' }}>
											<EdenredTypo>End Of Service Benefit</EdenredTypo>
										</Col>
										<Col xs={10} sm={10} style={{ display: 'flex', flexDirection: 'row', paddingLeft: '4px' }}>
											<Col sm={6} xs={6} className={payroll_table_Data.endofservice === true ? 'checkNo' : 'checkNo-false'}>
												No
											</Col>
											<Col sm={10} xs={10} className="payroll">
												<Switch
													className="switch"
													value={payroll_table_Data.endofservice}
													onChange={() => handletoggleswitch(payroll_table_Data.emp_id)}
												/>
											</Col>
											<Col
												sm={6}
												xs={6}
												style={{
													paddingLeft: '2px',
													fontWeight: 'bold',
													color: payroll_table_Data.endofservice === true ? 'red' : ' #DFE1E6',
												}}>
												Yes
											</Col>
										</Col>
									</Row>
								</Col>
							</Col>
						)}
					</Row>
				);
			case 'Check_Run_Payroll':
				return (
					<Row className="edenredCard_mobile_view_check_run_payroll">
						<Col sm={4} xs={4} className="card_icon_payroll_check">
							<ImageComponent src={content.image} />
						</Col>
						<Col sm={18} xs={18} className="card_content_sectionpayroll_check">
							<Col sm={24} xs={24} className="card_titlepayroll_check" style={{ display: 'flex', alignItems: 'center' }}>
								<EdenredTypo medium bold style={{ fontSize: '14px', color: '#00344E' }}>
									{content.header}
								</EdenredTypo>
							</Col>
						</Col>
						<Col sm={2} xs={2} className="right_icon_payroll_check">
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);
			case 'PayrollHistory':
				return (
					<Row className="edenredCard_payroll_history_mobile_view">
						<Col sm={2} xs={2} className="payroll_history_col_first" style={{ background: background }}></Col>
						<Col sm={19} xs={19} className="payroll_history_col_second">
							<Col sm={24} xs={24} className="payroll_history_header">
								<EdenredTypo bold>AED {content.payrollAmount}</EdenredTypo>
							</Col>
							<Col sm={24} xs={24} className="payroll_history_detail">
								<EdenredTypo medium>{content.accountTypeDescription}</EdenredTypo>
							</Col>
							<Col sm={24} xs={24} className="payroll_history_content">
								<EdenredTypo>{content.expectedPayout}</EdenredTypo>
							</Col>
						</Col>
						<Col sm={3} xs={3} className="payroll_history_col_third">
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);
			case 'ApproveRequests':
				return (
					<Row className="edenredCard_mobile_view_approve_requests">
						<Col sm={4} xs={4} className="approve_requests_select_unselect_image">
							<ImageComponent
								src={content.isSelect === true ? icons.focus_state.Check : icons.not_focus.EmptyBox}
								onClick={() => isSelectClicked(content)}
							/>
						</Col>
						<Col sm={16} xs={16} className="approve_requests_payroll_details">
							<Col sm={24} xs={24} className="approve_requests_payroll_month_title">
								<EdenredTypo medium>{content.month_payroll}</EdenredTypo>
							</Col>
							<Col sm={24} xs={24} className="approve_requests_payroll_submitted_employees">
								<EdenredTypo>{content.employees}</EdenredTypo>
							</Col>
							<Col sm={24} xs={24} className="approve_requests_payroll_total_amount">
								<EdenredTypo medium>Total Amount :</EdenredTypo>
								<EdenredTypo> AED {content.total_amount}</EdenredTypo>
							</Col>
						</Col>
						<Col sm={4} xs={4} className="approve_requests_payroll_view_details" onClick={() => viewDetailsClicked(content)}>
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
						<Col sm={24} xs={24} className="approve_requests_payroll_buttons">
							<Col sm={12} xs={12} className="approve_requests_payroll_reject_button">
								<EdenredButton
									onClick={() => onClickApprove(content, 'Reject')}
									disabled={isSelectedCount > 1}
									type="outline-g"
									noShadow={false}>
									Reject
								</EdenredButton>
							</Col>
							<Col sm={12} xs={12} className="approve_requests_payroll_approve_button">
								<EdenredButton onClick={() => onClickApprove(content, 'Approve')} disabled={isSelectedCount > 1}>
									Approve
								</EdenredButton>
							</Col>
						</Col>
					</Row>
				);

			case 'Useful_files':
				return (
					<Row className="edencard_mobile_view_useful_files">
						<Col xs={4} sm={4} className="image_icon">
							<ImageComponent src={content.image} />
						</Col>
						<Col xs={18} sm={18} className="useful_files_mobile">
							<Row>
								<Col xs={24} sm={24} className="title_edencard">
									{content.title}
								</Col>
								<Col xs={24} sm={24} className="description_edenredcard">
									{content.description}
								</Col>
							</Row>
						</Col>
						<Col xs={2} sm={2} className="download_icon">
							<ImageComponent src={icons.not_focus.NavigateRight} />
						</Col>
					</Row>
				);
			case 'proof_of_transfer':
				return (
					<Row className="edencard_mobile_view_proof_of_transfer">
						<Col sm={20} xs={20} onClick={openMobileSubmittedProofModal}>
							<Col sm={24} xs={24} className="proof_header">
								<EdenredTypo>{content.header}</EdenredTypo>
							</Col>
							<Col sm={24} xs={24} className="proof_title">
								{content.title}
							</Col>
							<Col sm={24} xs={24} className="proof_detail">
								{content.detail}
							</Col>
						</Col>
						<Col xs={4} sm={4} className="view_submit" onClick={openMobileSubmittedProofModal}>
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
						<ViewSubmittedProof
							refreshData={refreshData}
							view="mobile"
							contentId={content.id}
							content={content}
							getAllFundTransferHistory={getAllFundTransferHistory}
							setViewSubmittedProofModal={setViewSubmittedProofModal}
							closeSubmittedProofModal={closeMobileSubmittedProofModal}
							viewSubmittedProof={mobileViewSubmittedProof}
							customStyles={MobileViewSubmittedProofStyles}
						/>
					</Row>
				);
			case 'EmployeeTable':
				return (
					<Row className="employee-table-mobile-view">
						<Col xs={17} sm={17}>
							<Col xs={24} sm={24} className="name-employe-table">
								<EdenredTypo>{content.name}</EdenredTypo>
							</Col>
							<Col xs={24} sm={24} className="employeid-employe-table">
								<EdenredTypo b3>{content.employeeId}</EdenredTypo>
							</Col>
						</Col>
						<Col xs={7} sm={7} className="salary-employe-table">
							<EdenredTypo b3>{content.amount}</EdenredTypo>
						</Col>
					</Row>
				);
			case 'EmployeeFailedDetails':
				return (
					<Row className="employee-failed-mobile-view">
						<Col xs={22} sm={22} className="employee_details_section">
							<EdenredTypo className="name-employe-failed">{content.name}</EdenredTypo>
							<EdenredTypo className="employeid-employe-failed">{content.employeeId}</EdenredTypo>
							<EdenredTypo className="salary-employe-failed">{content.amount}</EdenredTypo>
						</Col>
						<Col xs={2} sm={2} className="view_right">
							<ImageComponent src={icons.not_focus.Right} />
						</Col>
					</Row>
				);
			default:
				return (
					<Row>
						<Col xl={24} lg={24} md={24}>
							<EdenredTypo medium h4>
								{title}
							</EdenredTypo>
						</Col>
						<Col xl={24} lg={24} md={24}>
							<EdenredTypo b3>{description}</EdenredTypo>
						</Col>
					</Row>
				);
		}
	};

	return (
		<Card className={`edenredCard_main ${className}`} onClick={onClick}>
			{/* web */}
			{renderSwitch(type)}
			{/* mobile */}
			{renderSwitchMobile(type)}
		</Card>
	);
};

export default EdenRedCard;
