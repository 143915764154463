import React from 'react';
import EdenredTypo from '@sharedComponent/typography';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { APP_VARIABLES } from '@constants/app-constants';
import ETypo from './../../../DesignSystem/Typo/index';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import ImageComponent from '@sharedComponent/image-component';

const payrollStatuses = {
	pending_approval: {
		title: 'Pending Approval From The Verifier',
		steps: [
			{
				step: 1,
				description: ' Please ensure that the verifier approves your payroll submission.',
			},
			{
				step: 2,
				description: " If you haven't sent us your salary funds yet, send them to our",
				link: { name: ' Bank Account', path: '/payroll/bank_details' },
			},
			{
				step: 3,
				description: ' Once you send us your funds, please',
				link: { name: ' Upload Proof of Funds Transfer', path: '/payroll/proof_of_transfer' },
			},
			{
				step: 4,
				description: ' Status will be updated within 30 minutes of uploading your proof of payment.',
			},
		],
	},
	waiting_for_salary_funds: {
		title: 'Waiting For Salary Funds',
		steps: [
			{
				step: 1,
				description: " If you haven't sent us your salary funds yet, send them to our",
				link: { name: ' Bank Account', path: '/payroll/bank_details' },
			},
			{
				step: 2,
				description: ' Once you send us your funds, please',
				link: { name: ' Upload Proof of Funds Transfer', path: '/payroll/proof_of_transfer' },
			},
			{
				step: 3,
				description: ' Status will be updated within 30 minutes of uploading your proof of payment.',
			},
		],
	},
	expected_salary_payout: {
		title: 'Expected Salary Payout: 12 July, 10:30 AM',
		description: "You'll be notified by mail when your employees have received their salaries.",
	},
	salaries_credited: {
		title: 'Salaries Have Been Credited To Employees!',
	},
};
const PayrollStatusContent = ({ status, expectedPayoutTime = '', completedText = '' }) => {
	const renderSwitchContent = (status) => {
		switch (status) {
			case 1:
				return (
					<Row className="payroll_status_content_container">
						<Col xl={16} lg={16} md={16} sm={24} xs={24} className="payroll_status_sub_header">
							<ETypo h5 medium color="#00344E">
								Next Steps
							</ETypo>
						</Col>
						<Col xl={22} lg={22} md={22} sm={24} xs={24} className="payroll_status_steps">
							{payrollStatuses.pending_approval.steps.map((step, index) => (
								<Row key={index} className="payroll_status_steps_container">
									<Col xl={1} lg={1} md={1} sm={2} xs={2} className="payroll_status_step_icon">
										<div className="step-number-section">
											<ETypo
												b1
												medium
												color="#313338
">
												{step.step}
											</ETypo>
										</div>
									</Col>
									<Col xl={22} lg={22} md={22} sm={20} xs={20} className="payroll_status_step_content">
										<ETypo b1 light color="#484B52">
											{step.description}
											{step.link ? (
												<Link
													className="payroll-status-link"
													to={{ pathname: step.link.path }}
													target="_blank"
													onClick={() =>
														localStorage.setItem(
															APP_VARIABLES.REDIRECT_TO,
															JSON.stringify({
																url: step.link.path,
															})
														)
													}>
													{step.link.name}
												</Link>
											) : null}
										</ETypo>
									</Col>
								</Row>
							))}
						</Col>
					</Row>
				);
			case 2:
				return (
					<Row className="payroll_status_content_container">
						<Col xl={16} lg={16} md={16} sm={24} xs={24} className="payroll_status_sub_header">
							<ETypo h5 medium color="#00344E">
								Next Steps
							</ETypo>
						</Col>
						<Col xl={22} lg={22} md={22} sm={24} xs={24} className="payroll_status_steps">
							{payrollStatuses.waiting_for_salary_funds.steps.map((step, index) => (
								<Row key={index} className="payroll_status_steps_container">
									<Col xl={1} lg={1} md={1} sm={2} xs={2} className="payroll_status_step_icon">
										<div className="step-number-section">
											<ETypo
												b1
												medium
												color="#313338
">
												{step.step}
											</ETypo>
										</div>
									</Col>
									<Col xl={22} lg={22} md={22} sm={20} xs={20} className="payroll_status_step_content">
										<ETypo b1 light color="#484B52">
											{step.description}
											{step.link ? (
												<Link
													className="payroll-status-link"
													to={{ pathname: step.link.path }}
													target="_blank"
													onClick={() =>
														localStorage.setItem(
															APP_VARIABLES.REDIRECT_TO,
															JSON.stringify({
																url: step.link.path,
															})
														)
													}>
													{step.link.name}
												</Link>
											) : null}
										</ETypo>
									</Col>
								</Row>
							))}
						</Col>
					</Row>
				);
			case 3:
				return (
					<Row className="payroll_status_content_container">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_status_description">
							<ETypo b1 light color="#9A9EA6">
								{payrollStatuses.expected_salary_payout.description}
							</ETypo>
						</Col>
					</Row>
				);
			case 4:
				return (
					<Row className="payroll_status_content_container">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_status_description">
							<ETypo b1 light color="#9A9EA6">
								{payrollStatuses.expected_salary_payout.description}
							</ETypo>
						</Col>
					</Row>
				);
			case 5:
				return (
					<Row className="payroll_status_content_container">
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="payroll_status_header">
							<ETypo b1 light color="#9A9EA6">
								{completedText}
							</ETypo>
						</Col>
					</Row>
				);
			case 6:
				return (
					<div className="error-box-container">
						<ImageComponent src={focus_state_icons.hrDocumentExpired}></ImageComponent>
						<ETypo b1 light color="#00344E">
							{' '}
							Run payroll again{' '}
							<ETypo b1 medium color="#00344E">
								ONLY
							</ETypo>{' '}
							for the failed employees after rechecking their WPS Person ID and WPS Establishment ID.
						</ETypo>
					</div>
				);
			default:
				return null;
		}
	};
	return renderSwitchContent(status);
};

export default PayrollStatusContent;
