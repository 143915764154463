import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import userManager from '@config/user-manager';
import LoginPresentational from './login-presentational';
import { APP_VARIABLES } from '@constants/app-constants';
import { useHistory } from 'react-router-dom';

const LoginFunctional = ({ user = null, isLoadingUser }) => {
	const history = useHistory();
	useEffect(() => {
		userManager.signinRedirect();
	}, []);

	const onLoginButtonClick = (event) => {
		event.preventDefault();
		userManager.signinRedirect();
	};

	return <LoginPresentational onLoginButtonClick={onLoginButtonClick} />;
};

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		isLoadingUser: state.oidc.isLoadingUser,
	};
}

export default connect(mapStateToProps, null)(LoginFunctional);
