import Checkbox from '../../../DesignSystem/Checkbox/Checkbox';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import ImageComponent from '@sharedComponent/image-component';
import React from 'react';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import './heroContent.scss';

const HeroContent = ({ title, list, handleCheckboxState, onAddNewItem, addNewItemButtonTitle, loader }) => {
	return (
		<div className="content_wrapper">
			<div className="info_tag">
				<ImageComponent className="rightIcon" src={focus_state_icons.information} />
				{title && (
					<span>
						<ETypo H4>{title}</ETypo>
					</span>
				)}
			</div>
			<div className="list_frame">
				{loader ? (
					<ContentLoader />
				) : (
					<div className="list_container">
						{list &&
							list?.map((item, index) => (
								<span key={`${item?.name}${index}`} className={`list_option_box ${item?.isActive && 'active'}`}>
									<Checkbox
										borderColor="#00344E"
										borderRadius={5}
										label={<ETypo H4>{item?.name}</ETypo>}
										name="Checkbox"
										checked={item?.isActive}
										containerClassName="checkbox-class"
										size="16px"
										labelPadding="12px 16px"
										onChange={(event) => {
											typeof handleCheckboxState === 'function' &&
												handleCheckboxState(event, item?.name, item?.isActive, item?.isEmployeeExists);
										}}
										borderWidth={1}
									/>
								</span>
							))}
					</div>
				)}
				<span className="list_option_box">
					<EdenredButtons
						onClick={typeof onAddNewItem === 'function' && onAddNewItem}
						btnType="secondary"
						children={addNewItemButtonTitle || 'Add new'}
						leftIcon={<ImageComponent className="leftIcon" src={not_focus_icons?.VectorAdd} />}
					/>
				</span>
			</div>
		</div>
	);
};

export default HeroContent;
