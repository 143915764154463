const POST_UPLOAD = 'POST_UPLOAD';
const PUT_UPLOAD_LOADING = 'PUT_UPLOAD_LOADING';
const PUT_UPLOAD_ERROR = 'PUT_UPLOAD_ERROR';
const PUT_UPLOAD_VALIDATION_MESSAGE = 'PUT_UPLOAD_VALIDATION_MESSAGE';
const PUT_UPLOAD_RESET = 'PUT_UPLOAD_RESET';

const PUT_DOWNLOAD_TEMPLATE_LOADING = 'PUT_DOWNLOAD_TEMPLATE_LOADING';
const GET_DOWNLOAD_TEMPLATE = 'GET_DOWNLOAD_TEMPLATE';
const GET_DOWNLOAD_TEMPLATE_DATA = 'GET_DOWNLOAD_TEMPLATE_DATA';
const GET_DOWNLOAD_TEMPLATE_ERROR = 'GET_DOWNLOAD_TEMPLATE_ERROR';

const postUpload = (data) => ({
	type: POST_UPLOAD,
	payload: data,
});

const putUploadLoading = (loadingStatus) => ({
	type: PUT_UPLOAD_LOADING,
	payload: loadingStatus,
});

const putUploadValidationMessage = (validationMessage) => ({
	type: PUT_UPLOAD_VALIDATION_MESSAGE,
	payload: validationMessage,
});

const putUploadError = (error) => ({
	type: PUT_UPLOAD_ERROR,
	payload: error,
});
const putUploadReset = () => ({
	type: PUT_UPLOAD_RESET,
});

const putDownloadTemplateLoading = (loadingStatus) => ({
	type: PUT_DOWNLOAD_TEMPLATE_LOADING,
	payload: loadingStatus,
});

const getDownloadTemplate = () => ({
	type: GET_DOWNLOAD_TEMPLATE,
});

const getDownloadTemplateData = (data) => ({
	type: GET_DOWNLOAD_TEMPLATE_DATA,
	payload: data,
});

const getDownloadTemplateError = (error) => ({
	type: GET_DOWNLOAD_TEMPLATE_ERROR,
	payload: error,
});

export default {
	types: {
		GET_DOWNLOAD_TEMPLATE,
		GET_DOWNLOAD_TEMPLATE_DATA,
		GET_DOWNLOAD_TEMPLATE_ERROR,
		PUT_DOWNLOAD_TEMPLATE_LOADING,
		POST_UPLOAD,
		PUT_UPLOAD_LOADING,
		PUT_UPLOAD_VALIDATION_MESSAGE,
		PUT_UPLOAD_ERROR,
		PUT_UPLOAD_RESET,
	},
	creators: {
		getDownloadTemplate,
		getDownloadTemplateData,
		getDownloadTemplateError,
		putDownloadTemplateLoading,
		putUploadReset,
		postUpload,
		putUploadLoading,
		putUploadValidationMessage,
		putUploadError,
	},
};
