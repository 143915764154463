import { icons } from 'src/assets/icons';
import { SITEMAP } from 'src/routes/sitemap';
export const Atm_Pin_Qus = [
	{
		title: `Where to get the PIN`,
		quesAndAns: [
			{
				question: `1.1 Where can employees view their ATM PIN?`,
				answer: [
					{
						label: 'Employees can see their ATM Pin on the C3Pay app under "👤 Profile", then “🏧 Show ATM Pin” or “See your ATM Pin” on the dashboard.',
						link: '',
					},
				],
			},
			{
				question: `1.2 What if employees cannot access the C3Pay app?`,
				answer: [
					{
						label: 'Employees can reach out to us on Whatsapp +971 58 876 2762 or call +971 6005 67772 (this is not a toll free number). We will conduct security checks and send the ATM Pin via SMS to a UAE or international number.',
						link: '',
					},
				],
			},
			{
				question: `1.3 Can employees see their ATM Pin free of charge?`,
				answer: [
					{
						label: 'Yes, the C3Pay app is free to download, sign-up, and view the ATM Pin. If they wish to subscribe to additional services or send money abroad or use mobile recharge, there will be a separate cost.',
						link: '',
					},
				],
			},
			{
				question: `1.4 Why is the ATM Pin not sent with the C3Pay card?`,
				answer: [
					{
						label: 'We take the security of your employees seriously and ensure their money is protected. Employees can view their ATM Pins only after confirming their identity and going through security steps. This helps prevent fraud.',
						link: '',
					},
				],
			},
		],
	},
	{
		title: `PIN Change`,
		quesAndAns: [
			{
				question: `2.1 Can employees change their ATM Pin?`,
				answer: [
					{
						label: 'It is not possible to change the ATM Pin. If employees suspect someone knows their ATM Pin and wish to change it, they will need to request a new card.',
						link: '',
					},
				],
			},
			{
				question: `2.2 Will the ATM Pin change every time employees view it?`,
				answer: [
					{
						label: 'No, the ATM Pin will never change unless employees get a new card.',
						link: '',
					},
				],
			},
			{
				question: `2.3 Do employees have a new ATM Pin when they get a new card?`,
				answer: [
					{
						label: 'Yes, each card will have a unique ATM Pin. They will need to go to the C3Pay app to view it.',
						link: '',
					},
				],
			},
		],
	},
	{
		title: `Existing Cardholders`,
		quesAndAns: [
			{
				question: `3.1 Can employees who already have a C3Pay card view their ATM Pin on the app?`,
				answer: [
					{
						label: 'Yes, every employee can see their ATM Pin on the C3Pay app.',
						link: '',
					},
				],
			},
			{
				question: `3.2 Will employees who already have a C3Pay card have a new ATM Pin?`,
				answer: [
					{
						label: 'All employees will have the same ATM Pin. The ATM Pin only changes when a new card is issued.',
						link: '',
					},
				],
			},
		],
	},
	{
		title: `Emirates ID`,
		quesAndAns: [
			{
				question: `4.1 Why do employees need to upload their Emirates ID to see their ATM Pin?`,
				answer: [
					{
						label: 'In order to protect your employees, we need to verify their identity before showing them their ATM Pin.',
						link: '',
					},
				],
			},
			{
				question: `4.2 What happens if their Emirates ID is expired or expiring soon?`,
				answer: [
					{
						label: 'Employees can still view their ATM Pin if their Emirates ID is expired or expiring soon. However, they will not be able to transfer money abroad and mobile recharge will be limited.',
						link: '',
					},
				],
			},
			{
				question: `4.3 Employees added their Emirates ID but they can't view their ATM Pin, why?`,
				answer: [
					{
						label: `We need to verify the employee's Emirates ID. In most cases, this is done instantly but it can take up to 24 hours.If they need their ATM Pin urgently they can reach out to us on WhatsApp +971 58 876 2762 or call +971 6005 67772 (this is not a toll free number).`,
						link: '',
					},
				],
			},
		],
	},
	{
		title: `How can your employees keep their ATM Pin safe?`,
		quesAndAns: [
			{
				question: ``,
				answer: [
					{
						label: `The ATM Pin is secured on the app, in order to protect this, inform your employees to:`,
						list: [
							'Never share their C3Pay app password with anyone.',
							'Do not allow anyone access their card and do not share their card details.',
							'Hide their screen when viewing their ATM Pin on the app.',
						],
						link: '',
					},
				],
			},
		],
	},
];
export const Card_Orders_Qus = [
	{
		question: `I haven't received the card(s) I ordered. What do I do?`,
		answer: [
			{
				label: 'You can track your card order status ',
				link: '/employees/card/order/status',
				target: '_blank',
			},
		],
	},
	{
		question: 'How do I apply for a card for my new employee? ',
		answer: [
			{
				label: 'Visit the "Manage Employees" section. ',
				link: '',
			},
			{
				label: 'Select "Add Employees". ',
				link: '',
			},
			{
				label: 'Download the spreadsheet template for employee addition (Employee file) ',
				link: '',
			},
			{
				label: 'Select the salary processing channel as “WPS C3” or “Non WPS C3” as applicable. ',
				link: '',
			},
		],
	},
	{
		question: 'My employee has lost their card. How do I apply for a replacement cards? ',
		answer: [
			{
				label: 'Visit the "Manage Employees" section. ',
				link: '',
			},
			{
				label: 'Select the employee you wish to request a replacement card for.',
				link: '',
			},
			{
				label: 'Select" Request Replacement Card". ',
				link: '',
			},
		],
	},
];
export const employeeFaqs = [
	{
		url: SITEMAP.help.employee.cardOrder,
		image: icons.focus_state.moneycardorder,
		title: 'Card Orders',
	},
	{
		url: SITEMAP.help.employee.atmPin,
		image: icons.focus_state.moneyatm,
		title: 'ATM Pin',
	},
	{
		url: SITEMAP.help.employee.cardUsage,
		image: icons.focus_state.moneycardUsage,
		title: 'Card Usage',
	},
	{
		url: SITEMAP.help.employee.newEmployee,
		image: icons.focus_state.useremployee,
		title: 'New Employee',
	},
	{
		url: SITEMAP.help.employee.employeeRequests,
		image: icons.focus_state.mailemployee,
		title: 'Employee Requests',
	},
];

export const QUESTION_ANSWER = [
	{
		question: `I haven't received the card(s) I ordered. What do I do?`,
		answer: [
			{
				label: 'You can track your card order status ',
				link: '/payroll/bank_details',
			},
		],
	},
	{
		question: 'How do I apply for a card for my new employee? ',
		answer: [
			{
				label: 'Visit the "Manage Employees" section. ',
				link: '',
			},
			{
				label: 'Select "Add Employees". ',
				link: '',
			},
			{
				label: 'Download the spreadsheet template for employee addition (Employee file) ',
				link: '',
			},
			{
				label: 'Select the salary processing channel as “WPS C3” or “Non WPS C3” as applicable. ',
				link: '',
			},
		],
	},
	{
		question: 'My employee has lost their card. How do I apply for a replacement cards? ',
		answer: [
			{
				label: 'Visit the "Manage Employees" section. ',
				link: '',
			},
			{
				label: 'Select the employee you wish to request a replacement card for.',
				link: '',
			},
			{
				label: 'Select" Request Replacement Card". ',
				link: '',
			},
		],
	},
	{
		question: 'How do I add employees with bank accounts? ',
		answer: [
			{
				label: 'Visit the "Manage Employees" section. ',
				link: '',
			},
			{
				label: 'Select "Add New Employee". ',
				link: '',
			},
			{
				label: 'Download the spreadsheet template for employee addition (Employee file) ',
				link: '',
			},
			{
				label: 'Select the salary processing channel as “WPS Bank Account” or “Non WPS Bank Account” as applicable. ',
				link: '',
			},
		],
	},
	{
		question: 'How do I get the account numbers of my newly added employees? ',
		answer: [
			{
				label: 'Visit the “Manage Employees” section. ',
				link: '',
			},
			{
				label: 'Click on “Download Employee List” at the bottom left of the screen. ',
				link: '',
			},
			{
				label: 'The list of active employees will be downloaded along with their account numbers. ',
				link: '',
			},
		],
	},
	{
		question: 'How do my employees activate their cards? ',
		answer: [
			{
				label: 'Your employees can activate their cards by downloading the C3Pay Mobile App from the Play Store or App Store and scanning their Emirates ID on the app. ',
				link: '',
			},
		],
	},
	{
		question: 'How do employees retrieve the ATM PIN of their cards? ',
		answer: [
			{
				label: 'Your employees can retrieve the ATM PIN of their cards by calling 600567772 and requesting for the same. Their PIN will be sent to their registered mobile number. ',
				link: '',
			},
		],
	},
	{
		question: 'Where are C3 ATMs installed? ',
		answer: [
			{
				label: 'A full list of C3 ATMs can be found ',
				link: 'https://edenred.ae/atmlocation/',
				target: '_blank',
			},
		],
	},
	{
		question: 'How do my employees block or unblock their cards?',
		answer: [
			{
				label: 'Your employees can immediately block their cards by visiting the C3Pay Mobile App and selecting “Block/Unblock my Card” from the profile section. ',
				link: '',
			},
		],
	},
	{
		question: 'My employee had an issue with an ATM withdrawal. He received the wrong amount of money. What do I do? ',
		answer: [
			{
				label: 'Visit the “Manage Employees” section. ',
				link: '',
			},
			{
				label: 'Select the employee you wish to file an ATM dispute for. ',
				link: '',
			},
			{
				label: 'Select “ATM Dispute”.',
				link: '',
			},
			{
				label: 'Fill in the details of the transaction. This is important to retrieve the transaction’s details and initiate the refund process after verification.',
				link: '',
			},
		],
	},
	{
		question: 'How do I request for a statement for my employee? ',
		answer: [
			{
				label: 'Visit the “Manage Employees” section. ',
				link: '',
			},
			{
				label: 'Select the employee you wish to request a statement for. ',
				link: '',
			},
			{
				label: 'Select “Request Card Statement”. ',
				link: '',
			},
			{
				label: 'Enter the email address on which you wish to receive the statement. ',
				link: '',
			},
			{
				label: 'Click submit, you’ll soon receive the statement in your e-mail. ',
				link: '',
			},
		],
	},
	{
		question: 'My employee wants to close his C3 account and wishes to have the remaining balance transferred to him. How do I proceed? ',
		answer: [
			{
				label: 'Please visit the useful files section. If the employee wants the remaining balance to be transferred to his local or international bank account, you may download the Bank Transfer form. If the employee wants a cheque with the remaining balance on it, you may download the Cheque Issuance form. Filled copies of forms can be sent to support.ae@edenred.com ',
				link: '',
			},
		],
	},
	{
		question: 'My employee wants help with the C3Pay App. What should he do? ',
		answer: [
			{
				label: 'Your employees can get assistance with the C3Pay App at any time by calling 600567772 and speaking to an agent. ',
				link: '',
			},
		],
	},
];
