import Actions from './action';

export const initState = {
	employeeListsLoading: false,
	employeeLists: [],
	employeeListsError: '',
	leaveTypeLoading: false,
	leaveType: [],
	leaveTypeError: '',
	overLappingLoading: false,
	overLappingLists: [],
	overLappingError: '',
	leaveBalanceLoading: false,
	leaveBalanceLists: [],
	leaveBalanceError: '',
	documentValidLoading: false,
	documentValidLists: [],
	documentValidError: '',
	createLoading: false,
	createLists: [],
	isCreateSaved: false,
	leaveComfirmPopupFlag: false,
	createError: '',
	leaveValidLoading: false,
	leaveValid: '',
	leaveValidError: '',
	departmentLeaveLoading: false,
	departmentLeave: {},
	departmentLeaveError: '',
	leavePopupMsg: '',
};

const requestLeavesReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_EMPLOYEESLIST_START: {
			return {
				...state,
				employeeListsLoading: true,
			};
		}
		case Actions.types.GET_EMPLOYEESLIST_SUCCESS: {
			return {
				...state,
				employeeListsLoading: false,
				employeeLists: action.response,
			};
		}
		case Actions.types.GET_EMPLOYEESLIST_FAIL: {
			return {
				...state,
				employeeListsLoading: false,
				employeeLists: [],
				employeeListsError: action.error,
			};
		}
		case Actions.types.GET_LEAVETYPE_START: {
			return {
				...state,
				leaveTypeLoading: true,
			};
		}
		case Actions.types.GET_LEAVETYPE_SUCCESS: {
			return {
				...state,
				leaveTypeLoading: false,
				leaveType: action.response,
			};
		}
		case Actions.types.GET_LEAVETYPE_FAIL: {
			return {
				...state,
				leaveTypeLoading: false,
				leaveType: [],
				leaveTypeError: action.error,
			};
		}
		case Actions.types.GET_OVERLAPPING_START: {
			return {
				...state,
				overLappingLoading: true,
			};
		}
		case Actions.types.GET_OVERLAPPING_SUCCESS: {
			return {
				...state,
				overLappingLoading: false,
				overLappingLists: action.response,
			};
		}
		case Actions.types.GET_OVERLAPPING_FAIL: {
			return {
				...state,
				overLappingLoading: false,
				overLappingLists: [],
				overLappingError: action.error,
			};
		}
		case Actions.types.GET_LEAVEBALANCE_START: {
			return {
				...state,
				leaveBalanceLoading: true,
			};
		}
		case Actions.types.GET_LEAVEBALANCE_SUCCESS: {
			return {
				...state,
				leaveBalanceLoading: false,
				leaveBalanceLists: action.response,
			};
		}
		case Actions.types.GET_LEAVEBALANCE_FAIL: {
			return {
				...state,
				leaveBalanceLoading: false,
				leaveBalanceLists: [],
				leaveBalanceError: action.error,
			};
		}
		case Actions.types.GET_DOCUMENTVALID_START: {
			return {
				...state,
				documentValidLoading: true,
			};
		}
		case Actions.types.GET_DOCUMENTVALID_SUCCESS: {
			return {
				...state,
				documentValidLoading: false,
				documentValidLists: action.response,
			};
		}
		case Actions.types.GET_DOCUMENTVALID_FAIL: {
			return {
				...state,
				documentValidLoading: false,
				documentValidLists: [],
				documentValidError: action.error,
			};
		}
		case Actions.types.GET_CREATE_START: {
			return {
				...state,
				createLoading: true,
			};
		}
		case Actions.types.GET_CREATE_SUCCESS: {
			return {
				...state,
				createLoading: false,
				createLists: action.response,
			};
		}
		case Actions.types.TOGGLE_CREATE_SAVED: {
			return {
				...state,
				isCreateSaved: action.flag,
			};
		}
		case Actions.types.HANDLE_LEAVE_CONFIRM_POPUP: {
			return {
				...state,
				leaveComfirmPopupFlag: action.flag,
				leavePopupMsg: action?.msg === 'save' ? 'Do You Want To Submit Leave Request?' : 'Do You Want To Cancel Leave Request?',
			};
		}
		case Actions.types.GET_CREATE_FAIL: {
			return {
				...state,
				createLoading: false,
				createLists: [],
				createError: action.error,
			};
		}
		case Actions.types.GET_LEAVEVALID_START: {
			return {
				...state,
				leaveValidLoading: true,
			};
		}
		case Actions.types.GET_LEAVEVALID_SUCCUESS: {
			return {
				...state,
				leaveValidLoading: false,
				leaveValid: action.response,
			};
		}
		case Actions.types.GET_LEAVEVALID_FAIL: {
			return {
				...state,
				leaveValidLoading: false,
				leaveValidError: action.error,
				leaveValid: '',
			};
		}

		case Actions.types.GET_DEPARTMENTLEAVE_START: {
			return {
				...state,
				departmentLeaveLoading: true,
				departmentLeave: {},
			};
		}
		case Actions.types.GET_DEPARTMENTLEAVE_SUCCUESS: {
			return {
				...state,
				departmentLeaveLoading: false,
				departmentLeave: action.payload,
			};
		}
		case Actions.types.GET_DEPARTMENTLEAVE_FAIL: {
			return {
				...state,
				departmentLeaveLoading: false,
				departmentLeave: {},
				departmentLeaveError: action.error,
			};
		}
		default:
			return state;
	}
};

export default requestLeavesReducer;
