import React from 'react';
import { Col, Row } from 'antd';
import ETypo from '@pages/DesignSystem/Typo';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import './departmentMultiSelect.scss';

function DepartmentMultiSelect({ optionsFromParent, handleOptionChange }) {
	return (
		<Row className="department-multi-select-container">
			<Col xl={24} lg={24} md={24} sm={24} xs={24} className="title">
				<ETypo medium b1>
					Select Department
				</ETypo>
			</Col>
			<Col xl={8} lg={12} md={12} sm={12} xs={12}>
				<MultiSelect
					{...{
						placeholder: 'Select Department',
						Option: optionsFromParent,
						searchVisibility: false,
						multiSelect: true,
						dropDownValue: 'name',
						onChange: (e) => handleOptionChange(e),
						onClear: (e) => handleOptionChange([]),
					}}
				/>
			</Col>
		</Row>
	);
}

export default DepartmentMultiSelect;
