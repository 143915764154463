import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ImageComponent from '@sharedComponent/image-component';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';
import EdenredHeader from '@pages/main-layout/components/main-header';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { checkAnyClaims } from '@helpers';
import { APPROVE_EMPLOYEE_REQUEST } from '@constants/';
import './employees-submenu.scss';
// import { checkWithClaims } from '@helpers';
// import { userClaims } from '@constants/';

const EmployeesSubMenu = ({ currentUser }) => {
	// mobile claim check
	const payrollClaims = (currentUser && currentUser?.payrollClaims) || [];
	const isApproveExists = checkAnyClaims(payrollClaims, APPROVE_EMPLOYEE_REQUEST) || false;

	const employees_submenus = [
		{
			icon: icons.not_focus.User,
			content: 'Manage Employees',
			path: '/employees/manage_employee',
		},
		{
			icon: icons.not_focus.Approvals,
			content: 'Card Order Status',
			path: '/employees/card/order/status',
		},
		isApproveExists && {
			icon: icons.not_focus.Info,
			content: 'Approve Requests',
			path: '/employees/approve_employee_request',
		},
		{
			icon: icons.not_focus.Help,
			content: 'Useful Files',
			path: '/employees/useful/files',
		},
		{
			icon: icons.not_focus.Spreadsheet,
			content: 'HR Documents',
			path: '/hrdocuments',
		},
		{
			icon: icons.not_focus.Spreadsheet,
			content: 'Leaves To Approve',
			path: '/leaves/leaves_to_approve',
		},
		{
			icon: icons.not_focus.Spreadsheet,
			content: 'Leaves Statuses',
			path: '/leaves/leaves_statuses',
		},
		{
			icon: icons.not_focus.Spreadsheet,
			content: 'Request Leave',
			path: '/leaves/request_leave',
		},
	];
	return (
		<Row className="employees_SubMenuPage_Container">
			<EdenredHeader />
			<Col xs={24} sm={24} className="SubMenuPage">
				<Row className="SubMenuPage_card">
					<Col sm={24} xs={24} className="SubMenuPage_card_content">
						<Col sm={24} xs={24} style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
							<Col className="SubMenuPage_link_horizontal"></Col>
						</Col>
						{employees_submenus?.map(
							(employeesSubmenu, index) =>
								employeesSubmenu && (
									<>
										<Link to={employeesSubmenu?.path} key={index}>
											<Row className="submenu_item_card_row">
												<Col xs={4} sm={3} className="submenuitem_logo">
													<ImageComponent src={employeesSubmenu?.icon} />
												</Col>
												<Col xs={18} sm={18} className="submenuitem_title">
													<EdenredTypo>{employeesSubmenu?.content}</EdenredTypo>
												</Col>
												<Col xs={2} sm={2} className="right_arrow">
													<ImageComponent src={icons?.not_focus?.Right} />
												</Col>
											</Row>
										</Link>
										{index !== employees_submenus?.length - 1 ? <Col xs={24} sm={24} className="horizontal_line"></Col> : null}
									</>
								)
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: SelectorsApp.currentUser(state),
		user: state.oidc.user,
	};
};
export default connect(mapStateToProps, null)(EmployeesSubMenu);
