import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import Axios from 'axios';
import { domain, user } from '@constants/app-constants';
import { connect } from 'react-redux';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { useLocation, BrowserRouter as Router, useHistory, useParams } from 'react-router-dom';
import ActionApprovePresentational from './action-approve-presentational';
import TeamPermission, { UPDATE_CLAIMS } from '@pages/team-permission/services';
import UserService from 'src/services/user-service';
import ClevertapReact from 'src/utils/clever-tap';

export const claimsValue = {
	'Payrol.Request': 'Payroll Requests',
	'Payroll.Request': 'Payroll Requests',
	'Card.Cancellation': 'Card Cancellation',
	'Card.Order': 'Card Order',
	'Card.Replacement': 'Card Replacement',
	'Manage.Employees': 'Manage Employees',
	'Manage.Payroll': 'Manage Payroll',
};
export const claimsDetail = {
	'Manage.Employees': 'Manage Employees',
	'Manage.Payroll': 'Manage Payroll',
	'Payrol.Request': 'Approve salary uploads and additions from users.',
	'Payroll.Request': 'Approve salary uploads and additions from users.',
	'Card.Cancellation': 'Approve employee additions and card orders from users.',
	'Card.Order': 'Approve all card cancellation requests from users.',
	'Card.Replacement': 'Approve all card replacement from users.',
};

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ActionApproveFunctional = ({ claims: { Verifier } }) => {
	const [claimList, setclaimList] = useState([]);
	const history = useHistory();
	const [apiLoading, setapiLoading] = useState(true);
	const [hrclaims, setHrClaims] = useState();
	const { roleid, username } = useParams();
	const [user, setUser] = useState();
	const historyState = useLocation();
	const [verifiedData, setVerifiedData] = useState();
	const [allClaims, setAllClaims] = useState();

	const [loading, setLoading] = useState(false);
	const [isAllClaim, setIsAllClaims] = useState(false);
	const [visible, setVisible] = useState(false);

	const showModal = () => {
		setVisible(true);
	};
	const afterOpenModal = (e) => {
		setVisible(false);
	};

	const closeModal = () => {
		setVisible(false);
	};

	useEffect(() => {
		getUserClaims();
	}, [verifiedData]);
	useEffect(() => {
		getAllClaimsApi();
	}, []);

	const api = new TeamPermission();
	const userAPI = new UserService();

	const getUserClaims = () => {
		userAPI
			.getUserClaims(username)
			.then((result) => {
				let claimData = verifiedData.administratonClaims;
				let hrClaimData = verifiedData.hrClaims;
				let allClaimsData = verifiedData;
				setUser(result.data);
				let claimsArray = result?.data?.claims?.map((obj) => obj?.value);
				let hrClaimsArray = result.data?.hrClaims?.map((obj) => obj?.value);

				let defaultClaimIsSelected = claimData?.map((data) => {
					return {
						...data,
						isSelected: claimsArray?.includes(data.value),
					};
				});
				let defaultHrIsSelected = hrClaimData?.map((data) => {
					return {
						...data,
						isSelected: hrClaimsArray?.includes(data.value),
					};
				});

				let finalObj = { ...allClaimsData, administratonClaims: defaultClaimIsSelected, hrClaims: defaultHrIsSelected };
				setAllClaims(finalObj);

				setapiLoading(false);
				// generateClaims((result.data && result?.data?.claims.map((claim) => claim.value)) || []);
				// generateHrClaims((result.data && result?.data?.hrClaims.map((claim) => claim.value)) || []);
			})
			.catch((err) => {
				setapiLoading(false);
			});
	};
	const getAllClaimsApi = () => {
		userAPI
			.getAllClaimsApi()
			.then((result) => {
				setIsAllClaims(false);
				setVerifiedData(result.data);
			})
			.catch((err) => {
				setIsAllClaims(false);
			});
	};
	const generateHrClaims = (claims = []) => {
		setclaimList(
			verifiedData?.hrClaims?.map((verifier) => {
				return {
					...verifier,
					isSelected: claims?.indexOf(verifier.value) >= 0,
				};
			})
		);
	};

	const generateClaims = (claims = []) => {
		let data1 = verifiedData?.administratonClaims?.map((Verifier) => {
			return {
				...Verifier,
				// isSelected: claims.includes(user.value),
				isSelected: claims.indexOf(Verifier.value) >= 0,
			};
		});

		let data2 = verifiedData?.hrClaims?.map((verifier) => {
			return {
				...verifier,
				isSelected: claims?.indexOf(verifier.value) >= 0,
			};
		});

		let mergedArray = data1?.concat(data2);
		setclaimList(mergedArray);
	};

	useEffect(() => {
		generateClaims();
	}, [verifiedData]);

	const changeClaims = (isSelected, value) => {
		let newObj = {};
		if (isSelected) {
			newObj = {
				...allClaims,
				// administratonClaims: allClaims?.administratonClaims?.map((val) => (val.value === value ? { ...val, isSelected: true } : val)),
				administratonClaims: allClaims?.administratonClaims?.map((val) => (val.value === value ? { ...val, isSelected: true } : val)),
				hrClaims:
					value === 'Manage.Leaves'
						? allClaims?.hrClaims?.map((val) =>
								val.value === value
									? { ...val, isSelected: true }
									: val?.value === 'Approval.Workflow' || val?.value === 'Manage.Leaves'
									? { ...val, isSelected: true }
									: val
						  )
						: allClaims?.hrClaims?.map((val) => (val.value === value ? { ...val, isSelected: true } : val)),
			};
		} else {
			newObj = {
				...allClaims,
				administratonClaims: allClaims?.administratonClaims?.map((val) => (val.value === value ? { ...val, isSelected: false } : val)),
				hrClaims:
					value === 'Manage.Leaves'
						? allClaims?.hrClaims?.map((val) =>
								val.value === value
									? { ...val, isSelected: false }
									: val?.value === 'Approval.Workflow' || val?.value === 'Manage.Leaves'
									? { ...val, isSelected: false }
									: val
						  )
						: allClaims?.hrClaims?.map((val) => (val.value === value ? { ...val, isSelected: false } : val)),
			};
		}
		setAllClaims(newObj);
		// let tempClaimList = claimList.map((claim) => {
		// 	if (claim.value === value) {
		// 		return {
		// 			...claim,
		// 			isSelected,
		// 		};
		// 	} else if (claim?.value ==='Approval.Workflow' || claim?.value ==='Manage.Leaves'){
		//             return{
		// 				...claim,
		// 				isSelected,
		// 			}
		// 	}else{
		// 		return claim;
		// 	}

		// });
		//setclaimList([...tempClaimList]);
	};
	const addUserClaims = () => {
		setLoading(true);
		ClevertapReact.event('SaveChanges_Verifier');
		const addClaims = allClaims?.administratonClaims?.filter((claim) => claim.isSelected && claim.value).map((claim) => claim.value);
		const addClaims1 = allClaims?.hrClaims?.filter((claim) => claim.isSelected && claim.value).map((claim) => claim.value);
		const removeClaims = allClaims?.administratonClaims?.filter((claim) => !claim.isSelected && claim.value).map((claim) => claim.value);
		const removeClaims1 = allClaims?.hrClaims?.filter((claim) => !claim.isSelected && claim.value).map((claim) => claim.value);
		const add = addClaims?.concat(addClaims1);
		const remove = removeClaims?.concat(removeClaims1);
		api.services(
			UPDATE_CLAIMS,
			{ username },
			{
				removeClaims: remove || [],
				addClaims: add || [],
			}
		)
			.then((addClaimsResponse) => {
				setLoading(false);
				history.push({
					pathname: '/manage_team',
					state: {
						response: `${username} permissions have been updated`,
					},
				});
			})
			.catch((addClaimsErr) => {
				setLoading(false);
			});
	};
	return (
		<ActionApprovePresentational
			apiLoading={apiLoading}
			loading={loading}
			Verifier={claimList}
			modalSubmit={addUserClaims}
			changeClaims={changeClaims}
			username={username}
			hrclaims={hrclaims}
			allClaims={allClaims}
			verifiedData={user}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		claims: SelectorsApp.claims(state),
	};
};

const Connected = connect(mapStateToProps, null)(ActionApproveFunctional);

export default Connected;
