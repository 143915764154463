import React from 'react';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import Toast from '../index';
const ToastFour = () => {
	return (
		<>
			<Toast color="#FF9691" heart={true} size="240px" textcolor="#484B52" icon={<ImageComponent src={images.blackheart} />}>
				Lorem ipsum dolor sit
			</Toast>
			<Toast color="#FF9691" place="bottomright" textcolor="#484B52">
				Lorem ipsum dolor sit
			</Toast>
		</>
	);
};

export default ToastFour;
