import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';
import { Link } from 'react-router-dom';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { useHistory } from 'react-router-dom';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { images } from 'src/assets/img';
import ETypo from '@pages/DesignSystem/Typo';
const statuses = [
	{
		name: 'Check',
		icon: icons.focus_state.TimeTransper,
		status: 'The claim will be processed within 45-60 days.',
		status2: 'The claim will be processed within 20 days.',
	},
	{
		name: 'Pay Salary',
		icon: icons.focus_state.EmailTransper,
		status: 'You’ll be notified of your claim status over email. ',
		status2: 'You’ll be notified of your claim status over email. ',
	},
];

const NextStepTransaction = () => {
	const [isRank, setRank] = useState(false);
	const history = useHistory();
	useEffect(() => {
		let bankName = history?.location?.state?.state?.toLowerCase();
		let isRak = bankName?.includes('rak bank');
		setRank(isRak);
	}, [history?.location?.state]);
	return (
		<>
			<EdenredHeader />
			<Row className="next_page_employees_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="next_page_employees_header">
					<ETypo bold h2>
						Switch Claim Submitted
					</ETypo>
					<div>
						<EdenredButtons
							btnType="secondary"
							onClick={() => history.push('/employees/manage_employee')}
							leftIcon={<img src={images.leftsarrow} />}>
							Return to Employee Profile
						</EdenredButtons>
					</div>
				</Col>

				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="next_page_employees_status">
					<ETypo medium h4 style={{ marginBottom: '27px' }}>
						Important information:
					</ETypo>
					{statuses?.map((status, index) => (
						<Row key={index} className="next_page_employees_status_container">
							<div className="next_page_employees_status_icon">
								<ImageComponent src={status?.icon} />
							</div>
							<Col className="next_page_employees_status_content">
								<EdenredTypo>{isRank ? status?.status2 : status?.status}</EdenredTypo>
							</Col>
						</Row>
					))}
				</Col>
				{/* 
				<Row className="next_page_employees_button_row">
					<Col xl={5} lg={5} md={5} sm={24} xs={24} className="next_page_employees_button">
						<Link to="/employees/manage_employee">
							<EdenredButton type="outline-g">Return to Manage Employees</EdenredButton>
						</Link>
					</Col>
				</Row> */}
			</Row>
		</>
	);
};

export default NextStepTransaction;
