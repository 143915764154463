import React from 'react';
import { Route } from 'react-router-dom';
import { SITEMAP } from 'src/routes/sitemap';
import LeaveCalculation from './leave-calculation';
import WpsPenalty from './wps-penalty';
import WpsRules from './wps-rules';
import GratuityCalculate from './gratuity-calculate';
import OvertimePay from './overtime-pay';
import LabourLawFaqs from './LabourLawFaqs';
import './labour-law.scss';

const LabourLaw = () => {
	return (
		<>
			<Route path={SITEMAP.help.labourLaws.wpsPenalty} component={WpsPenalty} exact />
			<Route path={SITEMAP.help.labourLaws.wpsRules} component={WpsRules} exact />
			<Route path={SITEMAP.help.labourLaws.gratuityCalculate} component={GratuityCalculate} exact />
			<Route path={SITEMAP.help.labourLaws.overTimePay} component={OvertimePay} exact />
			<Route path={SITEMAP.help.labourLaws.leaveCalculation} component={LeaveCalculation} exact />
			<Route path={SITEMAP.help.labourLaws.index} component={LabourLawFaqs} exact />
		</>
	);
};
export default LabourLaw;
