import ETypo from '@pages/DesignSystem/Typo';
import React from 'react';

const timingcard = ({ content }) => {
	console.log(content);
	return (
		<div className="timing-card-container">
			<div className="timing-card-section">
				{/* {content.map((elements, index) => {
					<div className="field-section">
						<div className="received-column">{elements.salaryfundrecieved}</div>
						<div className="expected-column">{elements.expectedPayout}</div>
					</div>;
				})} */}
				{content?.map((res, index) => (
					<div className="field-section" style={{ backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#FBFCFD' }}>
						<div className="received-column">
							{index == 0 ? (
								<ETypo b2 medium className="heading-color ">
									{res?.salaryfundrecieved.slice(0, -1)}
								</ETypo>
							) : (
								<ETypo b2 light className="value-color">
									{' '}
									{res.salaryfundrecieved}{' '}
								</ETypo>
							)}
						</div>
						<div className="expected-column">
							{index == 0 ? (
								<ETypo b2 medium className="heading-color">
									{res.expectedPayout.slice(0, -1)}
								</ETypo>
							) : (
								<ETypo b2 light className="value-color">
									{res.expectedPayout}
								</ETypo>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default timingcard;
