import React, { useEffect, useState } from 'react';
import { Col, Row, Carousel } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import { roles, userClaims } from '@constants/';
import { APP_VARIABLES, DASH_BOARD_CONSTANT } from '@constants/app-constants';
import EdenredButton from '@sharedComponent/button';
import EdenredTypo from '@sharedComponent/typography';
import ImageComponent from '@sharedComponent/image-component';
import Selectors from '@sharedComponent/app-action-reducer/selectors';
import { checkWithClaims } from '@helpers';
import { icons } from 'src/assets/icons';
import GaugeChart from './gauge-chart';
import BarChart2 from './barchart2';
import '../main-dashboard-mobile.scss';

const MainDashboardMobilePresentational = (props) => {
	let month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	let currentMonth = month[new Date().getMonth()];
	let currentYear = new Date().getFullYear().toString();
	let availableBalance = localStorage.getItem('amount') || '0';
	let {
		employeeCount: { c3AppUsersCount, c3CardHoldersCount, totalEmployeesCount, bankAccountsCount },
	} = props;
	const role = (props?.currentUser && props?.currentUser?.roles && props?.currentUser?.roles?.[0]?.name) || null;
	const claims = (props?.currentUser && props?.currentUser?.claims) || [];
	const verifierUserName = props?.currentUser && props?.currentUser?.user?.userName;
	const verifierRolesId = props?.currentUser && props?.currentUser?.roles?.[0]?.id;
	const { approveReqCount } = props;
	const [corporateDetails, setCorporateDetails] = useState({
		address2: null,
		address3: null,
		amount: null,
		cbin: null,
		city: null,
		corporateId: null,
		corporateName: null,
		countryCode: null,
		emailId: null,
		embossingName: null,
		rmName: null,
		zipCode: null,
		phNumber: null,
		email: null,
		name: null,
	});
	useEffect(() => {
		getCoprateIds();
	}, []);
	const getCoprateIds = () => {
		let corporate = Object.assign(
			{},
			...(props?.corporateIds || []).filter((corporate) => corporate?.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
		);

		if (corporate) {
			if (corporate.rmName === 'CSU') {
				setCorporateDetails({ ...corporate, name: null, email: 'support.ae@edenred.com', phNumber: ' +971 4 521 4000 ' });
			} else if (corporate.rmName === 'Marcel') {
				setCorporateDetails({ ...corporate, name: ' Marcel Daniel ', email: ' marcel.daniel@edenred.com ', phNumber: ' +971 56 4335249 ' });
			} else if (corporate.rmName === 'Bahaa') {
				setCorporateDetails({ ...corporate, name: ' Bahaa Assouma ', email: ' bahaa.assouma@edenred.com ', phNumber: ' +971 54 4483470 ' });
			} else if (corporate.rmName === 'Melissa') {
				setCorporateDetails({ ...corporate, name: ' Melissa Lobo ', email: ' melissa.lobo@edenred.com ', phNumber: ' +971 52 7425148 ' });
			} else if (corporate.rmName === 'Priyanka Sengar') {
				setCorporateDetails({
					...corporate,
					name: ' Priyanka Sengar ',
					email: ' priyanka.sengar@edenred.com ',
					phNumber: ' +971 50 4275370 ',
				});
			}
		}
	};
	return (
		<Row className="main-dashboard-mobile">
			<Col xs={24} sm={24}>
				{/*Pending Alerts*/}
				{role !== roles.USER && (
					<Col className="mobile_view_pending_alerts">
						<Row className="mobile_view_pending_alerts_card">
							<Col xl={4} lg={4} md={4} sm={4} xs={4} className="mobile_view_pending_alert_img">
								<ImageComponent src={icons.focus_state.Warning} />
							</Col>
							<Col xl={20} lg={20} md={20} sm={20} xs={20} className="pending_alert_link_column">
								<Link to={{ pathname: '/dashboard/pending-alerts', state: { approveReqCount, role } }}>
									<Col xl={14} lg={14} md={14} sm={14} xs={14} className="mobile_view_pending_alert_msg">
										<EdenredTypo>{`${
											role !== roles.USER && approveReqCount && approveReqCount?.totalRequest ? approveReqCount.totalRequest : 0
										} Pending Alerts`}</EdenredTypo>
									</Col>
									<Col xl={10} lg={10} md={10} sm={10} xs={10} className="mobile_view_pending_alert_arrow">
										<EdenredTypo>View Details</EdenredTypo>
										<ImageComponent src={icons.not_focus.Right} />
									</Col>
								</Link>
							</Col>
						</Row>
					</Col>
				)}
				{/* first container */}
				<Row className="main-dashboard-logo">
					<Col className="main-dashboard-image">
						<ImageComponent src={icons.focus_state.Balance} />
					</Col>
					<Col xs={24} sm={24} className="main-dashboard-available-balance">
						<EdenredTypo>Available Balance</EdenredTypo>
					</Col>
					<Col xs={24} sm={24} className="main-dashboard-balance-amount">
						<EdenredTypo className="amount">{availableBalance}</EdenredTypo>
						<EdenredTypo className="point">.00</EdenredTypo>
						<EdenredTypo className="aed">AED</EdenredTypo>
					</Col>
					<Col xs={24} sm={24} className="main-dashboard-view-transaction-history">
						<Link to="/dashboard/transaction-history">
							<EdenredButton type="outline-g">
								<ImageComponent src={icons.not_focus.Show} />
								View Transaction History
							</EdenredButton>
						</Link>
					</Col>
				</Row>
				{/* payroll History */}
				<Row className="main-dashboard-history-payroll-card">
					<Col className="main-dashboard-payrol-icon">
						<ImageComponent src={icons.focus_state.BankTransfer} />
					</Col>
					<Col className="main-dashboard-amount">
						<EdenredTypo>Payroll History</EdenredTypo>
					</Col>
					<Row className="main-dashboard-carousel-container">
						{props?.isPayrollHistoryLoading === true ? (
							<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
								<DotLoader size={50} color={'#e91d24'} loading />
							</Col>
						) : (
							<Col xs={24} sm={24}>
								<Carousel style={{ height: '150px' }} slidesToShow={1} slidesToScroll={1} slidesPerRow={2} dots={false}>
									{props?.payrollTotalData === 0 ? (
										<Row className="main-dashboard-Carousel">
											<Col xs={1} sm={1} className="card_status_color_column">
												<Row className="background" style={{ backgroundColor: '#DFE1E6' }}></Row>
											</Col>
											<Col xs={23} sm={23} className="main-dashboard-carosel-content">
												<EdenredTypo className="wps-month">No payroll history</EdenredTypo>
												{role === roles.USER && (
													<EdenredTypo className="payroll-submit">
														Run payroll now for your employees
														<Link to="/run-payroll">
															<EdenredTypo
																style={{
																	textDecoration: 'underline',
																	textUnderlinePosition: 'under',
																	color: '#0E5274',
																	marginLeft: '4px',
																}}>
																Here
															</EdenredTypo>
														</Link>
													</EdenredTypo>
												)}
											</Col>
										</Row>
									) : (
										props?.totalPayrollHistory.map((content, i) => (
											<Row className="main-dashboard-Carousel" key={i}>
												<Col xs={1} sm={1} className="card_status_color_column">
													<Row
														className="background"
														style={{
															backgroundColor:
																content.payrollRunDetail &&
																content.payrollRunDetail.length > 0 &&
																content.payrollRunDetail[0].color
																	? content.payrollRunDetail[0].color
																	: content.payrollRunDetail.length >= 1 && content.payrollRunDetail[1].color,
														}}></Row>
												</Col>
												<Col xs={23} sm={23} className="main-dashboard-carosel-content">
													<EdenredTypo className="wps-month">
														{content.payrollRunHeader.headerAmount} - {content.payrollRunHeader.headerType}
													</EdenredTypo>
													<EdenredTypo className="payroll-submit">
														Payroll submitted: {content.payrollRunHeader.submittedAt}
													</EdenredTypo>
												</Col>
											</Row>
										))
									)}
								</Carousel>
							</Col>
						)}
					</Row>
					<Col xs={24} sm={24} className="main-dashboard-view-all">
						<Link to="/payroll/history">
							<EdenredButton type="outline-g">View All</EdenredButton>
						</Link>
					</Col>
				</Row>
				{/* cut off */}
				<Row className="main-dashboard-cut-off">
					{role === roles.USER ? (
						<>
							<Col xs={12} sm={12} className="cut-off-container">
								<Row className="wps-cut-off">
									<Col xs={24} sm={24} className="wps-label">
										<EdenredTypo>WPS Cut-Off:</EdenredTypo>
									</Col>
									<Col xs={24} sm={24} className="wps-timing">
										<EdenredTypo>
											Monday – Saturday <br />
											1:00 PM
										</EdenredTypo>
									</Col>
								</Row>
							</Col>
							<Col xs={12} sm={12} className="cut-off-container">
								<Row className="wps-cut-off">
									<Col xs={24} sm={24} className="wps-label">
										<EdenredTypo>Non WPS Cut-Off:</EdenredTypo>
									</Col>
									<Col xs={24} sm={24} className="wps-timing">
										<EdenredTypo>
											Monday – Saturday <br />
											3:00 PM
										</EdenredTypo>
									</Col>
								</Row>
							</Col>
							{checkWithClaims(claims, userClaims.MANAGE_PAYROLL) && (
								<Col xs={24} sm={24} className="view-details">
									<Link to="/payroll/timings">
										<EdenredButton type="outline-g">View Details</EdenredButton>
									</Link>
								</Col>
							)}
						</>
					) : (
						<>
							<Col xs={24} sm={24} className="change-your-approval">
								Change your approval settings
							</Col>
							<Col xs={24} sm={24} className="change-content">
								{DASH_BOARD_CONSTANT?.APPROVER_CONTENT}
							</Col>
							<Col xs={24} sm={24} className="view-details">
								<Link to={{ pathname: '/action_approve/' + verifierUserName + '/' + verifierRolesId, state: { claims } }}>
									<EdenredButton type="outline-g" className="modify_button">
										Click Here To Modify
									</EdenredButton>
								</Link>
							</Col>
						</>
					)}
				</Row>
				{/* payroll amount */}
				<Row className="main-dashboard-payroll-amount">
					<Col className="main-dashboard-payrol-icon">
						<ImageComponent src={icons.focus_state.BankTransfer} />
					</Col>
					<Col className="main-dashboard-amount">
						<EdenredTypo>Payroll Amount</EdenredTypo>
					</Col>
					{props?.isPayrollAmountLoading === true ? (
						<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
							<DotLoader size={50} color={'#e91d24'} loading />
						</Col>
					) : (
						<Col xs={24} sm={24} className="main-dashboard-barchart">
							<BarChart2 payroll={props?.employeePaidByPayroll} />
						</Col>
					)}
				</Row>
				{/* card order History */}
				<Row className="main-dashboard-history-card-order">
					<Col className="main-dashboard-payrol-icon">
						<ImageComponent src={icons.focus_state.BankTransfer} />
					</Col>
					<Col className="main-dashboard-amount">
						<EdenredTypo>Card Order History</EdenredTypo>
					</Col>
					<Row className="main-dashboard-carousel-container">
						{props?.isCardHistoryLoading === true ? (
							<Col style={{ minHeight: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
								<DotLoader size={50} color={'#e91d24'} loading />
							</Col>
						) : (
							<Col xs={24} sm={24}>
								<Carousel style={{ height: '150px' }} slidesToShow={1} slidesToScroll={1} slidesPerRow={2} dots={false}>
									{props?.cardOrderTotalData === 0 ? (
										<Row className="main-dashboard-Carousel">
											<Col xs={1} sm={1} className="card_status_color_column">
												<Row className="background" style={{ backgroundColor: '#DFE1E6' }}></Row>
											</Col>
											<Col xs={23} sm={23} className="main-dashboard-carosel-content">
												<EdenredTypo className="wps-month"> No cards ordered</EdenredTypo>
												{role === roles.USER && (
													<EdenredTypo className="payroll-submit">
														Order new cards
														<Link to="/employees/manage_employee">
															<EdenredTypo
																style={{
																	textDecoration: 'underline',
																	textUnderlinePosition: 'under',
																	color: '#0E5274',
																	marginLeft: '4px',
																}}>
																HERE.
															</EdenredTypo>
														</Link>
													</EdenredTypo>
												)}
											</Col>
										</Row>
									) : (
										props?.totalCardRequest?.map((content, i) => (
											<Row className="main-dashboard-Carousel" key={i}>
												<Col xs={1} sm={1} className="card_status_color_column">
													<Row className="background" style={{ backgroundColor: '#e4dd3d' }}></Row>
												</Col>
												<Col xs={23} sm={23} className="main-dashboard-carosel-content">
													<EdenredTypo className="wps-month"> {content?.noOfCards} Cards</EdenredTypo>
													<EdenredTypo className="payroll-submit">{content?.orderPlaced}</EdenredTypo>
												</Col>
											</Row>
										))
									)}
								</Carousel>
							</Col>
						)}
					</Row>
					<Col xs={24} sm={24} className="main-dashboard-view-all">
						<Link to="/employees/card/order/status">
							<EdenredButton type="outline-g">View All</EdenredButton>
						</Link>
					</Col>
				</Row>
				{/* employeee paid month */}
				<Row className="main-dashboard-employee-paid-month">
					<Col className="main-dashboard-payrol-icon">
						<ImageComponent src={icons.focus_state.CashPickUp} />
					</Col>
					<Col className="main-dashboard-amount">
						<EdenredTypo>% Of Employees Paid</EdenredTypo>
					</Col>
					<Row className="main_dashboard_carousel_container">
						{props?.isEmployeePaidLoading === true ? (
							<Col style={{ minHeight: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
								<DotLoader size={50} color={'#e91d24'} loading />
							</Col>
						) : (
							<Col xs={24} sm={24}>
								<Carousel style={{ height: '150px' }} slidesToShow={1} slidesToScroll={1} slidesPerRow={5} dots={false}>
									{props?.totalEmployeePaidOff?.map((content, index) => {
										// currentMonth === content.month && currentYear === content.year ? (
										// let perc = Math.round((content.totalEmployeePaid / content.totalEmployeeProcessed + Number.EPSILON) * 100);
										// if (isNaN(perc)) perc = 0;
										let perc1 = (content?.totalEmployeePaid / content?.totalEmployeeProcessed) * 100;
										let users = perc1.toFixed(2);
										let user = users.split('.');
										let con = user[0];
										let con1 = user[1] > '00' ? `.${user[1]}` : null;
										if (isNaN(con)) con = 0;
										return (
											<Row
												key={index}
												className={
													currentMonth === content?.month && currentYear === content?.year
														? 'employee_paid_current_month_detail_row'
														: 'employee_paid_detail_row'
												}>
												<Col xs={8} sm={8} className="employee_paid_month">
													<EdenredTypo>{content?.month}</EdenredTypo>
												</Col>
												<Col xs={6} sm={6} className={`employee_paid_percentage${con < 80 ? ' low_percentage' : ''}`}>
													<EdenredTypo>
														{con}
														{con1} %
													</EdenredTypo>
												</Col>
												<Col xs={10} sm={10} className="employee_paid_no_of_employee">
													<EdenredTypo>
														({content?.totalEmployeePaid} / {content?.totalEmployeeProcessed} Employees)
													</EdenredTypo>
												</Col>
											</Row>
										);
										// ) : null;
									})}
									{/* {props?.totalEmployeePaidOff.map((content, index) => {
											return currentMonth !== content.month && currentYear !== content.year ? (
												<Row
													key={index}
													className={
														currentMonth === content.month && currentYear === content.year
															? 'employee_paid_current_month_detail_row'
															: 'employee_paid_detail_row'
													}>
													<Col xs={8} sm={8} className="employee_paid_month">
														<EdenredTypo>{content.month}</EdenredTypo>
													</Col>
													<Col
														xs={6}
														sm={6}
														className={`employee_paid_percentage${(content.totalEmployeePaid / content.totalEmployeeProcessed) * 100 < 80
															? ' low_percentage'
															: ''
															}`}>
														<EdenredTypo>{(content.totalEmployeePaid / content.totalEmployeeProcessed) * 100} %</EdenredTypo>
													</Col>
													<Col xs={10} sm={10} className="employee_paid_no_of_employee">
														<EdenredTypo>
															({content.totalEmployeePaid} / {content.totalEmployeeProcessed} Employees)
													</EdenredTypo>
													</Col>
												</Row>
											) : null;
										})} */}
								</Carousel>
							</Col>
						)}
					</Row>
					<Row className="info">
						<Col sm={2} xs={2}>
							<ImageComponent src={icons.not_focus.Info} />
						</Col>
						<Col sm={20} xs={20}>
							{DASH_BOARD_CONSTANT?.WPS_PERCENTAGE}
						</Col>
					</Row>
					{/* <Col xs={24} sm={24} className="main-dashboard-view-all">
						<Link to="/employees/approve_employee_request">
							<EdenredButton type="outline-g">View All</EdenredButton>
						</Link>
					</Col> */}
				</Row>
				{/* thershold chart */}
				<Row className="main-dashboard-threshold-chart">
					{props?.isEmployeeCountLoading === true ? (
						<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
							<DotLoader size={50} color={'#e91d24'} loading />
						</Col>
					) : (
						<>
							<Col xs={12} sm={12}>
								<GaugeChart
									filledArcColor={'#dc4639'}
									// value={(totalEmployeesCount / totalEmployeesCount) * 100}
									// label={totalEmployeesCount}
									value={totalEmployeesCount ? (totalEmployeesCount / totalEmployeesCount) * 100 : 0}
									label={totalEmployeesCount ? totalEmployeesCount : 0}
									title={'Total Employees'}
								/>{' '}
							</Col>
							<Col xs={12} sm={12}>
								<GaugeChart
									filledArcColor={'#53565d'}
									value={c3CardHoldersCount ? (c3CardHoldersCount / totalEmployeesCount) * 100 : 0}
									label={c3CardHoldersCount ? c3CardHoldersCount : 0}
									title={'C3Pay Cardholders'}
								/>
							</Col>
							<Col xs={12} sm={12}>
								<GaugeChart
									filledArcColor={'#1a95d0'}
									value={totalEmployeesCount ? (c3AppUsersCount / totalEmployeesCount) * 100 : 0}
									label={c3AppUsersCount ? c3AppUsersCount : 0}
									title={'C3Pay App Users'}
								/>
							</Col>
							<Col xs={12} sm={12}>
								<GaugeChart
									filledArcColor={'#ded52f'}
									value={bankAccountsCount ? (bankAccountsCount / totalEmployeesCount) * 100 : 0}
									label={bankAccountsCount ? bankAccountsCount : 0}
									title={'Bank Accounts'}
								/>
							</Col>
						</>
					)}
				</Row>
				{/* email support */}
				{corporateDetails && corporateDetails?.rmName === 'CSU' ? (
					<Row className="main-dashboard-email-support">
						<Col xs={24} sm={24}>
							<ImageComponent src={icons.focus_state.Email} />
						</Col>
						<Col xs={24} sm={24} className="queries">
							<EdenredTypo>For any queries, please mail:</EdenredTypo>
						</Col>
						<Col xs={24} sm={24} className="email">
							<EdenredTypo>{corporateDetails?.email}</EdenredTypo>
						</Col>
					</Row>
				) : (
					<Row className="main-dashboard-email-support">
						<Col xs={24} sm={24}>
							<ImageComponent src={icons.focus_state.Help} />
						</Col>
						<Col xs={24} sm={24} className="queries">
							<EdenredTypo>For escalations, please contact:</EdenredTypo>
						</Col>
						<Col xs={15} sm={15} className="email">
							<EdenredTypo>
								{corporateDetails?.email} {corporateDetails?.phNumber}
							</EdenredTypo>
						</Col>
					</Row>
				)}
				{/* whats app */}
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		corporateIds: Selectors.corporateIds(state),
		user: state.oidc.user,
	};
};
const Connected = connect(mapStateToProps, null)(MainDashboardMobilePresentational);
export default Connected;
