import React, { useState } from 'react';
import Counter from '../index';

const Counter1 = () => {
	const [count, setCount] = useState(0);
	const array = ['100-200', '200-300', '300-400', '400-500'];

	const onIncrement = () => {
		setCount(count + 1);
	};
	const onDecrement = () => {
		setCount(count - 1);
	};

	return (
		<div>
			<div style={{ marginTop: '10px', marginBottom: '10px' }}>
				<Counter
					grey={true}
					width="202px"
					handleIncrement={array.length - 1 > count && onIncrement}
					handleDecrement={count !== 0 && onDecrement}
					content={array[count]}></Counter>
			</div>
		</div>
	);
};

export default Counter1;
