import React from 'react';
import { Row } from 'antd';
import { images } from 'src/assets/img';
import EdenredButton from '@sharedComponent/button';
import Edenredlog from '../../../../../assets/img/edenredlogo.png';
import './filter.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

function Filter({
	setFilterState,
	filterData,
	filterDocument,
	expiryDateOptions,
	documentTypeOptions,
	handleExpiryDateDocumentType,
	hasExpiryDateFilterData,
}) {
	const handleApplyFilter = () => {
		setFilterState(false);
		filterData(filterDocument);
	};
	return (
		<div>
			<div className="headContainer">
				<Row>
					<div className="ant-col-xl-5 ant-col-lg-5 ant-col-md-5 ant-col-sm-5">
						<img src={Edenredlog} className="forLogo" />
					</div>
				</Row>
				<Row style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }} onClick={() => setFilterState(false)}>
					<div className="ant-col-xl-5 ant-col-lg-5 ant-col-md-5 ant-col-sm-5">
						<div className="filters">
							<ArrowLeftOutlined style={{ fontSize: 15, paddingRight: 10 }} />
						</div>
					</div>
					<div className="ant-col-xl-5 ant-col-lg-5 ant-col-md-5 ant-col-sm-5">
						<div className="filters">Filters</div>
					</div>
				</Row>
				<Row>
					<p className="expDate no-margin">Expiry Date:</p>
				</Row>
				<Row>
					{expiryDateOptions.map(({ name, value }, i) => {
						const selected = filterDocument?.expiryDateFilter?.includes(value);
						return (
							<>
								<div key={i} onClick={() => handleExpiryDateDocumentType(value, selected)} className={'entireContent'}>
									<img src={selected ? images.hR_check : images.hR_uncheck} className="forCheck" />
									<p className="contents">{name}</p>
								</div>
							</>
						);
					})}
				</Row>
				<Row>
					<p className="docType no-margin">Document Type:</p>
				</Row>
				<Row>
					{documentTypeOptions?.map(({ name, value }) => {
						const selected = filterDocument?.documentTypeFilter?.includes(value);
						return (
							<>
								<div
									className={hasExpiryDateFilterData ? 'entireContent' : 'entireContenthidden'}
									onClick={() => handleExpiryDateDocumentType(value, selected, true)}>
									<img src={selected ? images.hR_check : images.hR_uncheck} className="forCheck" />
									<p className="contents">{name}</p>
								</div>
							</>
						);
					})}
				</Row>
				<Row style={{ paddingTop: 72 }}>
					<EdenredButton disabled={!hasExpiryDateFilterData} className="cancelSave-btn" onClick={handleApplyFilter}>
						Apply Filter
					</EdenredButton>
				</Row>
			</div>
		</div>
	);
}

export default Filter;
