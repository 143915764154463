import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action.js';
import Axios from 'axios';

export function* leaveApprovalTemplateService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/download-leave-balance`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.leaveApproveTemplateSuccess(res.data));
		const downloadUrl = window.URL.createObjectURL(res.data);
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', 'LeaveBalanceReport.xls');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (err) {
		yield put(Actions.creators.leaveApproveTemplateFail(err));
	}
}

export function* leaveApprovalUploadService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	let formData = new FormData();
	formData.append('file', action.payload);
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leave-balance-upload`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.leaveApproveUploaduccess(res.data));
	} catch (err) {
		yield put(Actions.creators.leaveApproveUploadFail(err));
	}
}
export function* leaveBalanceServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const userName = action?.payload;
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/bulk-update?userName=${userName}`;
		const res = yield call(Axios.put, uri);
		yield put(Actions.creators.leaveBalanceSuccess(res));
		yield put(Actions.creators.submitLeaveApprove(true));
	} catch (err) {
		yield put(Actions.creators.leaveBalanceFail(err));
	}
}
export function* getLeaveBalanceServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const userName = action?.payload?.userName;
	const pageNumber = action?.payload?.pageNumber;
	const pageSize = action?.payload?.pageSize;
	console.log('jjjj', action.payload);
	try {
		const uri = `${DOMAIN_URL}/Leave/${corporateId}/leave-balance-staging?userName=${userName}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getLeaveBalanceSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getLeaveBalanceFail(err));
	}
}

export default function* leaveApprovalWatcher() {
	yield takeEvery(Actions.types.LEAVE_APPROVAL_TEMPLATE_START, leaveApprovalTemplateService);
	yield takeEvery(Actions.types.LEAVE_APPROVAL_UPLOAD_START, leaveApprovalUploadService);
	yield takeEvery(Actions.types.LEAVE_BALANCE_SUBMIT_START, leaveBalanceServices);
	yield takeEvery(Actions.types.GET_LEAVE_BALANCE_SUBMIT_START, getLeaveBalanceServices);
}
