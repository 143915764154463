import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MultipleEmployeesSubmit from '@pages/employees/add-employees/components/multiple-employees-submit';
import selector from '../selector';
import action from '../action';
import { connect, useSelector } from 'react-redux';
import { withReducer } from 'src/store/reducerLoader';
import reducer from '../reducer';
import saga from '../saga/index';
import './setupUploadEmployeePayroll.scss';
import SetupHeader from '@pages/setup-Screen/components/setup-header';

function SetupUploadEmployeePayrollList({ isEmployeePayrollLoading, employeePayrollDetails, getEmployeePayrollSaga }) {
	const history = useHistory();
	const employeesList = history.location.state.data;
	const userName = useSelector((state) => state?.sharedStates?.currentUser?.user?.userName);
	const [pageNumber, setPageNumber] = useState({ pageNumber: 1, pageSize: 30, userName: userName });

	const handleInfiniteScroll = () => {
		setPageNumber({ ...pageNumber, pageSize: pageNumber?.pageSize + 30 });
	};
	useEffect(() => {
		// getEmployeePayrollSaga(pageNumber);
	}, [pageNumber]);
	return (
		<div className="setup-payroll">
			<SetupHeader />
			<div className="employeeListTable">
				<MultipleEmployeesSubmit {...{ isSetup: true, employeesList, status: 'update', nextRoute: '/setup', handleInfiniteScroll }} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		isEmployeePayrollLoading: selector.isEmployeePayrollLoading(state),
		employeePayrollDetails: selector.employeePayrollDetails(state),
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getEmployeePayrollSaga: (payload) => dispatch(action.creators.getEmployeePayrollStart(payload)),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(SetupUploadEmployeePayrollList);
const ReducedScreen = withReducer('employeePayrollReducer', reducer, saga)(Connected);
export default ReducedScreen;
