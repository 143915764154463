import { PERMISSIONS } from '@constants/app-constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { mergeAllClaims } from 'src/utils/claims';

function useClaimsBasedContents() {
	// const [result, setResult] = useState({})
	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	// const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);

	const contents = [
		{
			contentId: 'deleteInBulkButton',
			toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		},
		{
			contentId: 'updateInBulkButton',
			toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		},
		{
			contentId: 'addEmployeesButton',
			toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		},
		{
			contentId: 'downloadEmployeeButton',
			toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		},
		{
			contentId: 'accountTypeColumn',
			toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		},
		{
			contentId: 'deleteCardColumn',
			toShow: [PERMISSIONS.MANAGE_EMPLOYEES],
		},
	];

	let _result = {};
	for (const _content of contents) {
		const CONTENT_HAS_CLAIM = _content.toShow.some((values) => {
			return allClaims.indexOf(values) >= 0;
		});
		_result[`${_content.contentId}`] = CONTENT_HAS_CLAIM;
	}

	return {
		shouldRender: { ..._result },
	};
}

export default useClaimsBasedContents;
