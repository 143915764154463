import React, { useState } from 'react';
import OptionsContent from './OptionsContent';

const ContentManager = ({ data, handleDropdown, icon }) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => setIsOpen(!isOpen);
	return (
		<div>
			<OptionsContent {...{ data, handleDropdown, handleToggle, icon }} />
			{isOpen && (
				<>
					{data?.subMenu?.map((item) => (
						<OptionsContent {...{ data: item, handleDropdown, handleToggle, mainData: { name: data?.name, sub: data?.sub }, isOpen: isOpen }} />
					))}
				</>
			)}
		</div>
	);
};

export default ContentManager;
