import Action from './action';

export const initState = {
	employeeListLoading: false,
	employeeList: [],
	employeeListError: '',
};
const leaveStatusReducer = (state = initState, action) => {
	console.log('reducer');
	console.log('Action', Action.types);
	switch (action.type) {
		case Action.types.GET_CURRENTLYLEAVE_START: {
			console.log('GET_CURRENTLYLEAVE_START');
			return {
				...state,
				employeeListLoading: true,
			};
		}
		case Action.types.GET_CURRENTLYLEAVE_SUCCESS: {
			console.log('GET_CURRENTLYLEAVE_SUCCESS');
			console.log('action.response', Action.response);
			console.log('employeeList', state.employeeList);
			return {
				...state,
				employeeListLoading: false,
				employeeList: action?.response,
			};
		}
		case Action.types.GET_CURRENTLYLEAVE_FAIL: {
			console.log('GET_CURRENTLYLEAVE_FAIL');
			return {
				...state,
				employeeListLoading: false,
				employeeListError: action.error,
			};
		}
		default:
			return state;
	}
};

export default leaveStatusReducer;
