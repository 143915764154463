import { getDaysCountByTwoDates } from '@helpers';
import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';

export const RenderLargeBannerContent = () => {
	if (getDaysCountByTwoDates() > 0) {
		return (
			<div>
				<h3>Deadline Approaching Soon!</h3>
				<h4>Subscribe before Oct 1 & avoid unnecessary fines for Employees</h4>
				<div className="small-desc">
					<div className="item">
						<img src={images?.edTick} alt="Ed Tick" />
						<div>
							Bulk Subscribe in 3 easy steps{' '}
							<a
								style={{ color: '#181919', fontWeight: 700 }}
								href="https://edenred.ae/blog/unemployment-insurance-all-you-need-to-know/"
								target="_blank"
								className="ref_link"
								rel="noopener noreferrer">
								Know More
							</a>
						</div>
					</div>
					<div className="item">
						<img src={images?.edTick} alt="" />
						<div>Hassle Free Auto Debit Directly From Employees' Cards</div>
					</div>
					<div className="item">
						<img src={images?.edTick} alt="" />
						<div>Check Employee’s Policy Status Live</div>
					</div>
				</div>
				<div className="info-action">
					<Link
						style={{ color: '#181919' }}
						className="info-action-link"
						to={{
							pathname: `/un-employment-insurance/employee`,
						}}>
						Subscribe Now
						<img className="move-icon" src={icons?.focus_state?.RightArrowBanner} alt="icon" />
					</Link>
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<h3>Unemployment Insurance</h3>
				<h4>Subscribe for all your employees in one place & avoid fines</h4>
				<div className="small-desc">
					<div className="item">
						<img src={images?.edTickRed} alt="Ed Tick" />
						<div>
							Bulk Subscribe in 3 easy steps{' '}
							<a
								style={{ color: '#00344E', fontWeight: 700 }}
								href="https://edenred.ae/blog/unemployment-insurance-all-you-need-to-know/"
								target="_blank"
								className="ref_link"
								rel="noopener noreferrer">
								Know More
							</a>
						</div>
					</div>
					<div className="item">
						<img src={images?.edTickRed} alt="" />
						<div>Hassle Free Auto Debit Directly From Employees' Cards</div>
					</div>
					<div className="item">
						<img src={images?.edTickRed} alt="" />
						<div>Check Employee’s Policy Status Live</div>
					</div>
				</div>
				<div className="info-action">
					<Link
						style={{ color: '#181919' }}
						className="info-action-link"
						to={{
							pathname: `/un-employment-insurance/employee`,
						}}>
						Subscribe Now
						<img className="move-icon" src={icons?.focus_state?.RightArrowBanner} alt="icon" />
					</Link>
				</div>
			</div>
		);
	}
};
