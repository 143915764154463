import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredButton from '@sharedComponent/button';
import ImageComponent from '@sharedComponent/image-component';
import Input from '@sharedComponent/text-field';
import EdenredTypo from '@sharedComponent/typography';
import { Col, Row, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { images } from 'src/assets/img';

const AddLabourCardNumber = () => {
	const { state } = useLocation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();

	useEffect(() => {
		forceUpdate({});
	}, [forceUpdate]);

	const onFinish = (values) => {
		const { labourCardNumber } = values;
		history.push({ pathname: '/employees/add/individual/employee/emiratesidnumber', state: { ...state, labourCardNumber } });
	};

	return (
		<>
			<EdenredHeader />
			<Row className="add_labour_card_number_container">
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_labour_card_number_title">
					<EdenredTypo>What Is {state && state.firstName ? state.firstName : ''}’s Labour Card Number?</EdenredTypo>
				</Col>
				<Col xl={24} lg={24} md={24} sm={24} xs={24} className="add_labour_card_number_description">
					<EdenredTypo>Found 7-digit number on the front side of Employee's Work Permit/Labour Card.</EdenredTypo>
				</Col>
				<Row className="add_labour_card_number_form_image_row">
					<Col xl={10} lg={12} md={18} sm={24} xs={24} className="add_labour_card_number_form_section">
						<Form form={form} onFinish={onFinish}>
							<Form.Item
								name="labourCardNumber"
								rules={[
									{ required: true, message: 'Please provide the Labour Card Number of the employee' },
									{
										max: 7,
										message: 'Max length should be 7',
									},
								]}>
								<Input placeholder="7-Digit Number" labels="Labour Card Number" labelClassName="inputlabelrequired" />
							</Form.Item>
							<Col xl={18} lg={18} md={24} sm={24} xs={24}>
								<Form.Item shouldUpdate={true}>
									{() => (
										<EdenredButton
											disabled={
												!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length
											}>
											Next
										</EdenredButton>
									)}
								</Form.Item>
							</Col>
						</Form>
					</Col>
					<Col xl={13} lg={12} md={22} sm={24} xs={24} className="add_labour_card_number_card_image">
						<ImageComponent src={images.LabourCard2} />
					</Col>
				</Row>
			</Row>
		</>
	);
};

export default AddLabourCardNumber;
