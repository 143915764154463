const GET_USERS = 'GET_USERS';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAILED = 'GET_USERS_FAILED';

const getUsers = () => {
	return {
		type: GET_USERS,
	};
};
const getUsersSuccess = (response) => {
	return {
		type: GET_USERS_SUCCESS,
		response,
	};
};
const getUsersFailed = () => {
	return {
		type: GET_USERS_FAILED,
	};
};

export default {
	types: {
		GET_USERS,
		GET_USERS_SUCCESS,
		GET_USERS_FAILED,
	},
	creators: {
		getUsers,
		getUsersSuccess,
		getUsersFailed,
	},
};
