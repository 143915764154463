import React from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Modal } from 'antd';

class PromptComponent extends React.Component {
	/**
	 * Dialog state
	 */
	state = { open: false };

	constructor() {
		super();
		this.__trigger = Symbol.for(`Changes will be discarded while you leave !`);
	}

	/**
	 * Attach global dialog trigger for this component
	 * instance to our Symbol trigger
	 */
	componentDidMount() {
		window[this.__trigger] = this.show;
	}
	show = (allowTransitionCallback) => {
		this.setState({ open: true }, () => allowTransitionCallback(false));
	};
	/**
	 * Ensure we clean up and remove the reference
	 * from the global object
	 */
	componentWillUnmount() {
		delete window[this.__trigger];
	}

	render() {
		const { when, title, message } = this.props;
		const { open } = this.state;
		return (
			<React.Fragment>
				{/* React Router prompt, callback will return true to allow transition or dialog key to prevent */}
				<Prompt when={when} message={this.handleTransition} />

				{/* Example MUI dialog to show when open. You could make this
            totally customizable or a complete one-off. */}
				<Modal visible={open} onCancel={this.handleClose} onOk={this.handleClose} style={{ height: '200px' }}>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div>{message}</div>
					</div>
				</Modal>
			</React.Fragment>
		);
	}

	/**
	 * Show the dialog. Invoked primarily from React Router transition
	 * handler getUserConfirmation.
	 *
	 * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
	 */

	/**
	 * Closes the dialog
	 */
	handleClose = () => {
		this.setState({ open: false });
	};

	/**
	 * Handles the Router transition. Returns true if allowed
	 * or the dialog trigger key to enable the dialog.
	 *
	 * This would be a good candidate to allow optionally
	 * being passed as a callback prop to let
	 * caller decide if transition is allowed.
	 */
	handleTransition = (location) => {
		// example: allow transitions to /two
		if (location.pathname === '/hrdocuments') {
			return true;
		}

		return Symbol.keyFor(this.__trigger);
	};
}

export default PromptComponent;
