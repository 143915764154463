import ImageComponent from '@sharedComponent/image-component';
import { Col, Divider, Row, Table } from 'antd';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import React from 'react';
import './historySectionMobile.scss';

function EmployeeHistorySectionMobile({ sampleData, historyDetail, employeeDetails }) {
	console.log('employeeDetails', employeeDetails);
	return (
		<div>
			<Row className="topContainer">
				<col md={24} xs={24}></col>
				<Col md={16} xs={24}>
					<ImageComponent src={not_focus_icons.Edenred} />
					<Col xs={16}>
						<p className="leaveStatus">{employeeDetails?.name}</p>
						<div className="forCenter">
							<p className="empId">{employeeDetails?.employeeId}</p>
							<p className="department"> Product Team</p>
						</div>
					</Col>
				</Col>
			</Row>
			<Row className="fullContainer">
				<Row className="forBackground">
					<p className="leaveStatus">Leave Balances</p>
				</Row>
				<Row className="forMobileEmpLeave">
					{sampleData?.map((item) => (
						<Col md={10} xs={10}>
							<div className="forAlignments">
								<ImageComponent src={item.Image} className={`imgcolors-${item.leaveTypeName}`}></ImageComponent>

								<div className="forPadding">
									<p className="leaveType">{item.leaveTypeName}</p>
									<p className="Remaining">{item.Status || 'Remaining'}</p>
									<p className="forDays">{item.Days}</p>
								</div>
							</div>
						</Col>
					))}
					,
				</Row>

				<Row className="forBackground">
					<p className="leaveStatus">Leave History</p>
				</Row>

				<Row className="leaveHistoryContainer">
					{historyDetail?.map(
						(dats) => (
							console.log('dats', dats),
							(
								<>
									<Col xs={18}>
										<p className="leaveType">{dats.leaveTypeName}</p>

										{/* <p className="leaveDays">{item.FromTo}</p> */}

										<p className="attachments">No attachment </p>
									</Col>
									<Col xs={3}>
										<p className={dats.Status == 'Approved' ? 'forStatusReview' : 'forStatusReview1'}>{dats.Status} </p>
									</Col>
									<Divider />
								</>
							)
						)
					)}
				</Row>
			</Row>
		</div>
	);
}

export default EmployeeHistorySectionMobile;
