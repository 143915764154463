import { createSelector } from 'reselect';

import { initState } from './reducer';

const setUpScreenReducer = (state) => state.setUpScreenReducer || initState;
const setup = createSelector(setUpScreenReducer, (cnter) => cnter.setup);
const setupLoading = createSelector(setUpScreenReducer, (cnter) => cnter.setupLoading);
const setupUpdateLoading = createSelector(setUpScreenReducer, (cnter) => cnter.setupUpdateLoading);
const setupUpdateStatus = createSelector(setUpScreenReducer, (cnter) => cnter.setupUpdateStatus);

export default {
	setUpScreenReducer,
	setup,
	setupLoading,
	setupUpdateLoading,
	setupUpdateStatus,
};
