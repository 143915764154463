import React, { useState } from 'react';
import { Col } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DotLoader, PulseLoader } from 'react-spinners';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredEmployeeCard from '@sharedComponent/card/employees/employees-card';
import EdenredModal from '@sharedComponent/modal';
import { not_focus_icons } from 'src/assets/icons/not_focus';

const CardOrderStatusPresentational = (props) => {
	const [orderStatusModal, setorderStatusModal] = useState(false);
	const showModal = () => setorderStatusModal(true);
	const closeModal = () => setorderStatusModal(false);
	let height = window.innerHeight - 100;
	const getIcon = (status) => {
		if (status?.toLowerCase()?.includes('arrival')) {
			return not_focus_icons.statusVerified;
		} else if (status?.toLowerCase()?.includes('delivered')) {
			return not_focus_icons.statusDelivered;
		} else if (status?.toLowerCase()?.includes('canceled')) {
			return not_focus_icons.statusCanceled;
		} else {
			return not_focus_icons.statusVerified;
		}
	};
	return (
		<Col className="card-order-status-col-presentational">
			<EdenredHeader />
			{props?.loading && (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			)}
			{!props?.loading && (
				<>
					<div className="card_order_details_container_wrapper">
						<h1 className="title">View Card Order Details:</h1>

						<div className="container_wrapper">
							<p className="card_status_desc_text">
								Click on an order to view its status and check the list of employees you've ordered cards for.
							</p>
							<>
								<InfiniteScroll
									dataLength={props.pendingRequest?.length}
									next={props.handleOnScroll}
									hasMore={true}
									height={`${height}px`}
									style={{ height: '645px', scrollBehavior: 'smooth', overflowY: 'auto', whiteSpace: 'nowrap' }}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{props?.pendingRequest?.map((orderStatus) => {
										return orderStatus?.status === 'feedbackWarning' ? (
											<Col
												sm={24}
												xs={24}
												style={{ backgroundColor: `${orderStatus?.color}` }}
												className={`card_order_status_card_column ${orderStatus?.status}`}
												onClick={showModal}>
												<EdenredEmployeeCard className="order_details_card" type="OrderDetailsCard" content={orderStatus} />
											</Col>
										) : (
											<Link to={{ pathname: '/employees/card/order/track/status', state: { orderStatus } }}>
												<section className="card_status_wrapper" style={{ background: `${orderStatus?.color}` }}>
													<div className="status_body_wrapper">
														<div className="status_body">
															<img src={getIcon(orderStatus?.status)} alt="" />
															<div className="body_contents">
																{orderStatus?.noOfCards > 1 ? (
																	<h1>{orderStatus?.noOfCards} Cards</h1>
																) : (
																	<h1>{orderStatus?.noOfCards} Card</h1>
																)}
																<h3>
																	Order Placed: {moment(orderStatus.orderPlaced).format('DD MMMM YYYY HH:mm A')}
																</h3>
																<p>{orderStatus?.status}</p>
															</div>
														</div>

														<div className="navigation_indicator_wrapper">
															<p>View Order Details </p>
															<div>
																<img className="link-arrow" src={not_focus_icons.Right} alt="" />
															</div>
															<div> &nbsp; </div>
															<div> &nbsp; </div>
														</div>
													</div>
												</section>
											</Link>
										);
									})}
								</InfiniteScroll>
								{props?.contentLoading && (
									<Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<PulseLoader size={20} color={'#e91d24'} loading />
									</Col>
								)}
							</>
						</div>
					</div>
				</>
			)}

			<EdenredModal
				isOpen={orderStatusModal}
				getDeleterole={closeModal}
				closeModal={closeModal}
				go_Back={false}
				yes="OK"
				link="/employees/card/order/track/status"
				className="multiple_employees_submit_modal"
				user="Our team will deliver the cards to your
        preferred location soon"
				content_web="Please contact Marcel on +971 55 6759412 for
        more information."
				content_mobile="Please contact Marcel on +971 55 6759412 for
        more information."
			/>
		</Col>
	);
};

export default CardOrderStatusPresentational;

// <Row className="card_order_status_container">
// 						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="card_order_status_title">
// 							<EdenredTypo>View Card Order Details:</EdenredTypo>
// 						</Col>
// 						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="card_order_status_description"></Col>
// 						<Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ backgroundColor: 'white', padding: '32px', borderRadius: '8px' }}>
// 							<p className="card_status_desc_text">
// 								Click on an order to view its status and check the list of employees you've ordered cards for.
// 							</p>

// 							<Col
// 								xl={24}
// 								lg={24}
// 								md={24}
// 								sm={24}
// 								xs={24}
// 								id="card_order_status_card_section"
// 								className="card_order_status_card_section">
// 								{/* {console.log('props.contentLoading', props.contentLoading)} */}
// 								<InfiniteScroll
// 									dataLength={props.pendingRequest?.length}
// 									next={props.handleOnScroll}
// 									hasMore={true}
// 									height={`${height}px`}
// 									style={{ height: '525px', scrollBehavior: 'smooth', overflowY: 'auto', whiteSpace: 'nowrap' }}
// 									endMessage={
// 										<p style={{ textAlign: 'center' }}>
// 											<b>Yay! You have seen it all</b>
// 										</p>
// 									}>
// 									{props.pendingRequest.map((orderStatus, index) => {
// 										console.log('orderStatus :', orderStatus);
// 										return orderStatus.status === 'feedbackWarning' ? (
// 											<Col
// 												sm={24}
// 												xs={24}
// 												style={{ backgroundColor: `${orderStatus.color}` }}
// 												className={`card_order_status_card_column ${orderStatus.status}`}
// 												onClick={showModal}>
// 												<EdenredEmployeeCard className="order_details_card" type="OrderDetailsCard" content={orderStatus} />
// 											</Col>
// 										) : (
// 											<Link to={{ pathname: '/employees/card/order/track/status', state: { orderStatus } }}>
// 												<section className="card_status_wrapper">
// 													<div className="status_body_wrapper">
// 														<img src={not_focus_icons.statusVerified} alt="" />
// 														<div className="body_contents">
// 															<h1>22 Cards</h1>
// 															<h3>Order Placed: 30 August 2022, 7:15 pm</h3>
// 															<p>Delivered: 01 September 2022</p>
// 														</div>

// 														<div className="navigation_indicator_wrapper">
// 															<p>View Order Details &nbsp; &#62;</p>
// 															<div> &nbsp; </div>
// 															<div> &nbsp; </div>
// 														</div>
// 													</div>
// 												</section>
// 												{/* <Col
// 													sm={24}
// 													xs={24}
// 													style={{ backgroundColor: `${orderStatus.color}` }}
// 													className={`card_order_status_card_column ${orderStatus.status}`}>
// 													<EdenredEmployeeCard
// 														className="order_details_card"
// 														type="OrderDetailsCard"
// 														content={orderStatus}
// 													/>
// 												</Col> */}
// 											</Link>
// 										);
// 									})}
// 								</InfiniteScroll>
// 							</Col>
// 							{props.contentLoading && (
// 								<Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
// 									<PulseLoader size={20} color={'#e91d24'} loading />
// 								</Col>
// 							)}
// 						</Col>{' '}
// 					</Row>
