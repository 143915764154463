import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

export const CustomAlertNotification = ({ when, onOK, onCancel, message, okText, cancelText, className }) => {
	const history = useHistory();
	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');
	useEffect(() => {
		if (when) {
			history.block((prompt) => {
				setCurrentPath(prompt.pathname);
				setShowPrompt(true);
				return 'true';
			});
		} else {
			history.block(() => {});
		}

		return () => {
			history.block(() => {});
		};
	}, [history, when]);

	const handleOK = useCallback(async () => {
		if (onOK) {
			const canRoute = await Promise.resolve(onOK());
			if (canRoute) {
				history.block(() => {});
				history.push(currentPath);
			}
		}
	}, [currentPath, history, onOK]);

	const handleCancel = useCallback(async () => {
		setShowPrompt(false);
	}, [currentPath, history, onCancel]);

	return showPrompt ? (
		<Edenredmodal
			{...{
				className: className,
				isOpen: showPrompt,
				title: message,
				onOkayButton: okText,
				onCancelButton: cancelText,
				onOkay: handleOK,
				onCancel: handleCancel,
			}}
		/>
	) : null;
};
