import React from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import ItemHeader from '../../components/ItemHeader';
import './overtime-pay.scss';

const content = [
	{
		no: '1. ',
		timing: 'Monday – Saturday (Between 4am - 9pm)',
		employee: 'The employee is entitled to 125% of their original basic salary for each overtime hour worked.',
		example: 'If the employee is paid AED 100 per hour during normal work hours, he must receive AED 125 per hour.',
	},
	{
		no: '2. ',
		timing: 'Weekends/Between 9pm - 4am on Monday – Saturday',
		employee: 'The employee is entitled to a substitute rest day or 150% of their original basic salary for each overtime hour worked.',
		example: 'If the employee is paid AED 100 per hour during normal work hours, he must receive AED 150 per hour.',
	},
	{
		no: '3. ',
		timing: 'Public holidays',
		employee:
			'The employee is entitled to a substitute rest day or 150% of their original basic salary for each overtime hour worked. If the employee is not provided with any substitute leave, the employee is entitled to 250% of their original basic salary for each hour worked.',
		example: 'If the employee is paid AED 100 per hour during normal work hours, he must receive AED 250 per hour.',
	},
];
const OvertimePay = () => {
	return (
		<Col className="terminate-employee-presentational-col">
			<ItemHeader title="Overtime Pay Calculation" />
			<Row className="terminate-employee-presentational-row">
				<Col xl={24} md={24} lg={24}>
					<Row className="header">
						<EdenredTypo>How is gratuity / EOSB calculated under UAE Labour Law?</EdenredTypo>
					</Row>
					<Row className="sub-header">
						<EdenredTypo>When the employee is terminated by the employer in a limited or unlimited contract:</EdenredTypo>
					</Row>
					<Row className="employee-table">
						<Col xl={6} md={6} lg={6} className="table-border">
							<EdenredTypo>When did the employee work overtime?</EdenredTypo>
						</Col>
						<Col xl={9} md={9} lg={9} className="table-border">
							<EdenredTypo>What is the employee entitled to?</EdenredTypo>
						</Col>
						<Col xl={8} md={8} lg={8} className="table-border"></Col>
					</Row>
					{content.map((user) => (
						<Row className="employee-table1">
							<Col xl={6} md={6} lg={6} className="table-border">
								<EdenredTypo>{user.timing}</EdenredTypo>
							</Col>
							<Col xl={9} md={9} lg={9} className="table-border">
								<EdenredTypo>{user.employee}</EdenredTypo>
							</Col>
							<Col xl={8} md={8} lg={8} className="table-border">
								<EdenredTypo>{user.example}</EdenredTypo>
							</Col>
						</Row>
					))}
					{/* mobile */}
					<Row className="terminate-mobile">
						<Col xs={24} sm={24} className="sub-mobile-content">
							<EdenredTypo>
								An employee can work for a maximum of 48 hours per week. With approval, this can be increased to 54 hours per week for
								specific sectors with approval from the MoHRE.
								<br /> Any additional hours worked by the employee are counted as overtime. Overtime pay in the UAE is calculated
								based on the type of overtime work put in by the employee:
							</EdenredTypo>
						</Col>
						{content.map((users) => (
							<>
								<Col xs={24} sm={24} className="table-timing">
									<EdenredTypo>
										{users.no}
										{users.timing}
									</EdenredTypo>
								</Col>
								<Col xs={24} sm={24} className="table-content-col">
									<EdenredTypo>
										{users.employee}
										<br />
										{users.example}
									</EdenredTypo>
								</Col>
							</>
						))}
					</Row>
					<Row className="other-rules">
						<EdenredTypo>Other Rules:</EdenredTypo>
					</Row>
					<Row className="effective">
						<EdenredTypo>Effective overtime working hours on normal workdays must not exceed 2 hours per day.</EdenredTypo>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
export default OvertimePay;
