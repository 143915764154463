import React from 'react';
import PayrollPermission from '@pages/team-permission/permission-page/payroll-Permission';
import SetupHeader from '@pages/setup-Screen/components/setup-header';
import '../setupPermission.scss';
import { Permission } from '@pages/team-permission/permission-page/payroll-Permission-Persentational';
const SetupCreateClaims = () => {
	return (
		<>
			<SetupHeader />
			<div className="setup-claims-container">
				<PayrollPermission {...{ isSetup: true }} />
			</div>
		</>
	);
};

export default SetupCreateClaims;
