import Actions from './action';
export const initState = {
	isAnnouncementDataLoading: false,
	announcementData: [],
	isAnnouncementLoading: false,
	announcementDataSave: [],
	announcementSuccess: false,
	isAnnouncementPopUP: false,
	isAnnouncementToastVisible: false,
};

const counterReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_ANNOUNCEMENT_DATA: {
			return {
				...state,
				isAnnouncementDataLoading: true,
			};
		}
		case Actions.types.GET_ANNOUNCEMENT_DATA_SUCCESS: {
			return {
				...state,
				isAnnouncementDataLoading: false,
				announcementData: action.res,
			};
		}
		case Actions.types.GET_ANNOUNCEMENT_DATA_ERROR: {
			return {
				...state,
				isAnnouncementDataLoading: false,
				announcementData: action.res,
			};
		}
		case Actions.types.UPDATE_ANNOUNCEMENT_DATA: {
			return {
				...state,
				isAnnouncementLoading: true,
			};
		}
		case Actions.types.UPDATE_ANNOUNCEMENT_DATA_SUCCESS: {
			return {
				...state,
				announcementDataSave: action.res,
				isAnnouncementLoading: false,
			};
		}
		case Actions.types.UPDATE_ANNOUNCEMENT_DATA_ERROR: {
			return {
				...state,
				announcementDataSave: action.res,
				isAnnouncementLoading: false,
			};
		}
		case Actions.types.ANNOUNCEMENT_SUCCESS: {
			return {
				...state,
				announcementSuccess: action.flag,
			};
		}
		case Actions.types.ANNOUNCEMENT_POPUP: {
			return {
				...state,
				isAnnouncementPopUP: action.flag,
			};
		}
		case Actions.types.ANNOUNCEMENTS_TOAST_VISIBLE: {
			return {
				...state,
				isAnnouncementToastVisible: action.flag,
			};
		}
		default:
			return state;
	}
};
export default counterReducer;
