const SET_HR_SETUP_STATUS_SKIPPED = 'SET_HR_SETUP_STATUS_SKIPPED';
const GET_CORPORATE_SETTINGS = 'GET_CORPORATE_SETTINGS';
const GET_CORPORATE_SETTINGS_DATA = 'GET_CORPORATE_SETTINGS_DATA';
const PUT_CORPORATE_SETTINGS_LOADING = 'PUT_CORPORATE_SETTINGS_ERROR';
const PUT_CORPORATE_SETTINGS_ERROR = 'PUT_CORPORATE_SETTINGS_ERROR';

const setHrSetupStatusSkipped = (isSkipped) => ({
	type: SET_HR_SETUP_STATUS_SKIPPED,
	payload: isSkipped,
});

const getCorporateSettings = () => ({
	type: GET_CORPORATE_SETTINGS,
});

const getCorporateSettingsData = (response) => ({
	type: GET_CORPORATE_SETTINGS_DATA,
	response,
});

const putCorporateSettingsLoading = (loadingStatus) => ({
	type: PUT_CORPORATE_SETTINGS_LOADING,
	payload: loadingStatus,
});
const putCorporateSettingsError = (error) => ({
	type: PUT_CORPORATE_SETTINGS_ERROR,
	payload: error,
});

export default {
	types: {
		SET_HR_SETUP_STATUS_SKIPPED,
		GET_CORPORATE_SETTINGS: GET_CORPORATE_SETTINGS,
		GET_CORPORATE_SETTINGS_DATA: GET_CORPORATE_SETTINGS_DATA,
		PUT_CORPORATE_SETTINGS_LOADING: PUT_CORPORATE_SETTINGS_LOADING,
		PUT_CORPORATE_SETTINGS_ERROR: PUT_CORPORATE_SETTINGS_ERROR,
	},
	creators: {
		setHrSetupStatusSkipped,
		getCorporateSettings,
		getCorporateSettingsData,
		putCorporateSettingsError,
		putCorporateSettingsLoading,
	},
};
