import { createSelector } from 'reselect';

import { initState } from './reducer';

const setUpFinalReducer = (state) => state.setUpFinalReducer || initState;
const setupClientStatusLoading = createSelector(setUpFinalReducer, (cnter) => cnter.setupClientStatusLoading);
const setupClientStatusDetail = createSelector(setUpFinalReducer, (cnter) => cnter.setupClientStatusDetail);

export default {
	setUpFinalReducer,
	setupClientStatusLoading,
	setupClientStatusDetail,
};
