const GET_EMPLOYEESLIST_START = 'GET_EMPLOYEESLIST_START';
const GET_EMPLOYEESLIST_SUCCESS = 'GET_EMPLOYEESLIST_SUCCESS';
const GET_EMPLOYEESLIST_FAIL = 'GET_EMPLOYEESLIST_FAIL';
const GET_LEAVETYPE_START = 'GET_LEAVETYPE_START';
const GET_LEAVETYPE_SUCCESS = 'GET_LEAVETYPE_SUCCESS';
const GET_LEAVETYPE_FAIL = 'GET_LEAVETYPE_FAIL';
const GET_OVERLAPPING_START = 'GET_OVERLAPPING_START';
const GET_OVERLAPPING_SUCCESS = 'GET_OVERLAPPING_SUCCESS';
const GET_OVERLAPPING_FAIL = 'GET_OVERLAPPING_FAIL';
const GET_LEAVEBALANCE_START = 'GET_LEAVEBALANCE_START';
const GET_LEAVEBALANCE_SUCCESS = 'GET_LEAVEBALANCE_SUCCESS';
const GET_LEAVEBALANCE_FAIL = 'GET_LEAVEBALANCE_FAIL';
const GET_DOCUMENTVALID_START = 'GET_DOCUMENTVALID_START';
const GET_DOCUMENTVALID_SUCCESS = 'GET_DOCUMENTVALID_SUCCESS';
const GET_DOCUMENTVALID_FAIL = 'GET_DOCUMENTVALID_FAIL';
const GET_CREATE_START = 'GET_CREATE_START';
const GET_CREATE_SUCCESS = 'GET_CREATE_SUCCESS';
const TOGGLE_CREATE_SAVED = 'TOGGLE_CREATE_SAVED';
const GET_CREATE_FAIL = 'GET_CREATE_FAIL';
const HANDLE_LEAVE_CONFIRM_POPUP = 'HANDLE_LEAVE_CONFIRM_POPUP';
const GET_LEAVEVALID_START = 'GET_LEAVEVALID_START';
const GET_LEAVEVALID_SUCCUESS = 'GET_LEAVEVALID_SUCCUESS';
const GET_LEAVEVALID_FAIL = 'GET_LEAVEVALID_FAIL';
const GET_DEPARTMENTLEAVE_START = 'GET_DEPARTMENTLEAVE_START';
const GET_DEPARTMENTLEAVE_SUCCUESS = 'GET_DEPARTMENTLEAVE_SUCCUESS';
const GET_DEPARTMENTLEAVE_FAIL = 'GET_DEPARTMENTLEAVE_FAIL';

const getEmployeesListStart = () => {
	return {
		type: GET_EMPLOYEESLIST_START,
	};
};
const getEmployeesListSuccess = (response) => {
	return {
		type: GET_EMPLOYEESLIST_SUCCESS,
		response,
	};
};
const getEmployeesListFail = (error) => {
	return {
		type: GET_EMPLOYEESLIST_FAIL,
		error,
	};
};
const getLeaveTypeStart = () => {
	return {
		type: GET_LEAVETYPE_START,
	};
};
const getLeaveTypeSuccess = (response) => {
	return {
		type: GET_LEAVETYPE_SUCCESS,
		response,
	};
};
const getLeaveTypeFail = (error) => {
	return {
		type: GET_LEAVETYPE_FAIL,
		error,
	};
};
const getOverLappingStart = (employeeData) => {
	return {
		type: GET_OVERLAPPING_START,
		employeeData,
	};
};
const getOverLappingSuccess = (response) => {
	return {
		type: GET_OVERLAPPING_SUCCESS,
		response,
	};
};
const getOverLappingFail = (error) => {
	return {
		type: GET_OVERLAPPING_FAIL,
		error,
	};
};
const getLeaveBalanceStart = (employeeData) => {
	return {
		type: GET_LEAVEBALANCE_START,
		employeeData,
	};
};
const getLeaveBalanceSuccess = (response) => {
	return {
		type: GET_LEAVEBALANCE_SUCCESS,
		response,
	};
};
const getLeaveBalanceFail = (error) => {
	return {
		type: GET_LEAVEBALANCE_FAIL,
		error,
	};
};
const getDocumentValidStart = (employeeData) => {
	return {
		type: GET_DOCUMENTVALID_START,
		employeeData,
	};
};
const getDocumentValidSuccess = (response) => {
	return {
		type: GET_DOCUMENTVALID_SUCCESS,
		response,
	};
};
const getDocumentValidFail = (error) => {
	return {
		type: GET_DOCUMENTVALID_FAIL,
		error,
	};
};
const getCreateStart = (employeeData, fileUpload, toggle) => {
	return {
		type: GET_CREATE_START,
		employeeData,
		fileUpload,
		toggle,
	};
};
const getCreateSuccess = (response) => {
	return {
		type: GET_CREATE_SUCCESS,
		response,
	};
};
const getCreateSaved = (flag) => {
	return {
		type: TOGGLE_CREATE_SAVED,
		flag,
	};
};
const handleLeaveConfirmPopup = (flag, msg) => ({
	type: HANDLE_LEAVE_CONFIRM_POPUP,
	flag,
	msg,
});
const getCreateFail = (error) => {
	return {
		type: GET_CREATE_FAIL,
		error,
	};
};
const getLeaveValidStart = (payload) => {
	return {
		type: GET_LEAVEVALID_START,
		payload,
	};
};
const getLeaveValidSuccess = (response) => {
	return {
		type: GET_LEAVEVALID_SUCCUESS,
		response,
	};
};
const getLeaveValidFail = (error) => {
	return {
		type: GET_LEAVEVALID_FAIL,
		error,
	};
};
const getDepartmentLeaveStart = (action) => {
	return {
		type: GET_DEPARTMENTLEAVE_START,
		action,
	};
};
const getDepartmentLeaveSuccess = (payload) => {
	return {
		type: GET_DEPARTMENTLEAVE_SUCCUESS,
		payload,
	};
};
const getDepartmentLeaveFail = (error) => {
	return {
		type: GET_DEPARTMENTLEAVE_FAIL,
		error,
	};
};
export default {
	types: {
		GET_EMPLOYEESLIST_START,
		GET_EMPLOYEESLIST_SUCCESS,
		GET_EMPLOYEESLIST_FAIL,
		GET_LEAVETYPE_START,
		GET_LEAVETYPE_SUCCESS,
		GET_LEAVETYPE_FAIL,
		GET_OVERLAPPING_START,
		GET_OVERLAPPING_SUCCESS,
		GET_OVERLAPPING_FAIL,
		HANDLE_LEAVE_CONFIRM_POPUP,
		GET_LEAVEBALANCE_START,
		GET_LEAVEBALANCE_SUCCESS,
		GET_LEAVEBALANCE_FAIL,
		GET_DOCUMENTVALID_START,
		GET_DOCUMENTVALID_SUCCESS,
		GET_DOCUMENTVALID_FAIL,
		GET_CREATE_START,
		GET_CREATE_SUCCESS,
		TOGGLE_CREATE_SAVED,
		GET_CREATE_FAIL,
		GET_LEAVEVALID_START,
		GET_LEAVEVALID_SUCCUESS,
		GET_LEAVEVALID_FAIL,
		GET_DEPARTMENTLEAVE_START,
		GET_DEPARTMENTLEAVE_SUCCUESS,
		GET_DEPARTMENTLEAVE_FAIL,
	},
	creators: {
		getEmployeesListStart,
		getEmployeesListSuccess,
		getEmployeesListFail,
		getLeaveTypeStart,
		getLeaveTypeSuccess,
		handleLeaveConfirmPopup,
		getLeaveTypeFail,
		getOverLappingStart,
		getOverLappingSuccess,
		getOverLappingFail,
		getLeaveBalanceStart,
		getLeaveBalanceSuccess,
		getLeaveBalanceFail,
		getDocumentValidStart,
		getDocumentValidSuccess,
		getDocumentValidFail,
		getCreateStart,
		getCreateSuccess,
		getCreateSaved,
		getCreateFail,
		getLeaveValidStart,
		getLeaveValidSuccess,
		getLeaveValidFail,
		getDepartmentLeaveStart,
		getDepartmentLeaveSuccess,
		getDepartmentLeaveFail,
	},
};
