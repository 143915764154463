import { put, takeEvery, call } from 'redux-saga/effects';
import Axios from 'axios';
import Actions from '../action';
import { API_DOMAIN, APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';

export function* salaryPaidStatusService(employeeDetails) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const empolyeeId = employeeDetails.employeeDetails;
	try {
		const uri = `${DOMAIN_URL}/PayrollRun/${corporateId}/${empolyeeId}/salaryprocessstatus`;
		const res = yield call(Axios.get, uri);
		console.log(res);
		yield put(Actions.creators.getSalaryProcessStatusSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getSalaryProcessStatusFail(err));
	}
}
let payrunvalue = {
	payslip: {},
	employeeContract: {},
	limitedOrTerminated: {
		lessThen5Years: 21,
		moreThan5Years: 30,
	},
	unLimited: {
		between1And3Years: 7,
		between3And5Years: 14,
		moreThan5Years: 21,
	},
	employeeBasicSalary: {},
	employeeAllowances: [],
	overtimes: [],
	additions: [],
	deductions: [],
	gratuity: {},
};
export function* payRollRunService(action) {
	console.log('action', action);
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const empolyeeId = action.employeeDetails.employeeId;
	const months = action.month;
	const years = action.year;
	console.log('API CALL payroll', months, years);
	try {
		const uri = `${DOMAIN_URL}/PayrollRun/${corporateId}/${empolyeeId}/${months}/${years}/run`;
		console.log(uri);
		const res = yield call(Axios.get, uri);
		console.log(res.data);
		yield put(Actions.creators.getPayrollRunSuccess(res?.data || payrunvalue));
	} catch (err) {
		yield put(Actions.creators.getPayrollRunFail(err));
	}
}
export function* additionService() {
	console.log('API CALL addition');
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/additiontype`;
		const res = yield call(Axios.get, uri);
		console.log(res);
		yield put(Actions.creators.getAdditionValueSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getAdditionValueFail(err));
	}
}
export function* deductionService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/deductiontype`;
		const res = yield call(Axios.get, uri);
		console.log(res);
		yield put(Actions.creators.getDeductionValueSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getDeductionValueFail(err));
	}
}
export function* overTimePayCalService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/overtimepolicy`;
		const res = yield call(Axios.get, uri);
		console.log(res);
		yield put(Actions.creators.getOverTimePayCalSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getOverTimePayCalFail(err));
	}
}
export function* terminationTypeService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/terminationtype`;
		const res = yield call(Axios.get, uri);
		console.log(res);
		yield put(Actions.creators.getTerminationTypeSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getTerminationTypeFail(err));
	}
}
export function* savePayrollRunService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const empolyeeId = action.employeeDetails.employeeId;
	const save = action.saveValue;
	const months = action.month;
	const years = action.year;
	if (!action.isGratuity && action.saveValue?.gratuity?.id) {
		action.saveValue.gratuity.value = 0;
	}
	try {
		const uri = `${DOMAIN_URL}/PayrollRun/${corporateId}/${empolyeeId}/${months}/${years}/save`;
		const res = yield call(Axios.post, uri, save);
		yield put(Actions.creators.getSavePayrollRunSuccess(res?.data));
		yield put(Actions.creators.getSavePopup(false));
		yield put(Actions.creators.savedSucceessfulPopUp(true));
	} catch (err) {
		yield put(Actions.creators.getSavePayrollRunFail(err));
	}
}
export default function* payRollRunWatcher() {
	console.log('payroll run ');
	yield takeEvery(Actions.types.GET_SALARYPAIDSTATUS_START, salaryPaidStatusService);
	yield takeEvery(Actions.types.GET_PAYROLLRUN_START, payRollRunService);
	yield takeEvery(Actions.types.GET_ADDITION_START, additionService);
	yield takeEvery(Actions.types.GET_DEDUCTION_START, deductionService);
	yield takeEvery(Actions.types.GET_OVERTIMEPAYCAL_START, overTimePayCalService);
	yield takeEvery(Actions.types.GET_TERMINATIONTYPE_START, terminationTypeService);
	yield takeEvery(Actions.types.GET_SAVEPAYROLLRUN_START, savePayrollRunService);
}
