import Actions from './action';

export const initState = {
	salaryPaidStatusLoading: false,
	salaryPaidStatus: [],
	salaryPaidStatusError: '',
	payRollRunLoading: false,
	payRollRun: [],
	payRollRunLists: [],
	payRollRunError: '',
	additionLoading: false,
	additionValue: [],
	additionError: '',
	deductionLoading: false,
	deductionValue: [],
	deductionError: '',
	overTimePayCalLoading: false,
	overTimePayCal: [],
	overTimePayCalError: '',
	terminationTypeLoading: false,
	terminationType: [],
	terminationTypeError: '',
	savePayrollRunLoading: false,
	savePayrollRun: [],
	savePayrollRunError: '',
	savePopUp: false,
	savedSuccessfully: false,
	modifiedMonthYear: 0,
};

const PayRollRunReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_SALARYPAIDSTATUS_START: {
			return {
				...state,
				salaryPaidStatusLoading: true,
			};
		}
		case Actions.types.GET_SALARYPAIDSTATUS_SUCCESS: {
			return {
				...state,
				salaryPaidStatusLoading: false,
				salaryPaidStatus: action?.response,
			};
		}
		case Actions.types.GET_SALARYPAIDSTATUS_FAIL: {
			return {
				...state,
				salaryPaidStatusLoading: false,
				salaryPaidStatusError: action.error,
				salaryPaidStatus: [],
			};
		}
		case Actions.types.GET_PAYROLLRUN_START: {
			return {
				...state,
				payRollRunLoading: true,
			};
		}
		case Actions.types.GET_PAYROLLRUN_SUCCESS: {
			return {
				...state,
				payRollRunLoading: false,
				payRollRun: action?.response,
				payRollRunLists: JSON.parse(JSON.stringify(action?.response)),
			};
		}
		case Actions.types.GET_PAYROLLRUN_FAIL: {
			return {
				...state,
				payRollRunLoading: false,
				payRollRunError: action.error,
				payRollRun: [],
				payRollRunLists: [],
			};
		}
		case Actions.types.GET_ADDITION_START: {
			return {
				...state,
				additionLoading: true,
			};
		}
		case Actions.types.GET_ADDITION_SUCCESS: {
			return {
				...state,
				additionLoading: false,
				additionValue: action?.response,
			};
		}
		case Actions.types.GET_ADDITION_FAIL: {
			return {
				...state,
				additionLoading: false,
				additionError: action.error,
				additionValue: [],
			};
		}
		case Actions.types.GET_DEDUCTION_START: {
			return {
				...state,
				deductionLoading: true,
			};
		}
		case Actions.types.GET_DEDUCTION_SUCCESS: {
			return {
				...state,
				deductionLoading: false,
				deductionValue: action?.response,
			};
		}
		case Actions.types.GET_DEDUCTION_FAIL: {
			return {
				...state,
				deductionLoading: false,
				deductionError: action.error,
				deductionValue: [],
			};
		}
		case Actions.types.GET_OVERTIMEPAYCAL_START: {
			return {
				...state,
				overTimePayCalLoading: true,
			};
		}
		case Actions.types.GET_OVERTIMEPAYCAL_SUCCESS: {
			return {
				...state,
				overTimePayCalLoading: false,
				overTimePayCal: action?.response,
			};
		}
		case Actions.types.GET_OVERTIMEPAYCAL_FAIL: {
			return {
				...state,
				overTimePayCalLoading: false,
				overTimePayCalError: action.error,
				overTimePayCal: [],
			};
		}
		case Actions.types.GET_TERMINATIONTYPE_START: {
			return {
				...state,
				terminationTypeLoading: true,
			};
		}
		case Actions.types.GET_TERMINATIONTYPE_SUCCESS: {
			return {
				...state,
				terminationTypeLoading: false,
				terminationType: action?.response,
			};
		}
		case Actions.types.GET_TERMINATIONTYPE_FAIL: {
			return {
				...state,
				terminationTypeLoading: false,
				terminationTypeError: action.error,
				terminationType: [],
			};
		}
		case Actions.types.HANDLE_SAVE_POPUP: {
			console.log('HANDLE_SAVE_POPUP');
			return {
				...state,
				savePopUp: action.flag,
			};
		}
		case Actions.types.SAVED_SUCCEESSFUL_POPUP: {
			console.log('SAVED_SUCCEESSFUL_POPUP');
			return {
				...state,
				savedSuccessfully: action.flag,
			};
		}
		case Actions.types.GET_SAVEPAYROLLRUN_START: {
			return {
				...state,
				savePayrollRunLoading: true,
			};
		}
		case Actions.types.GET_SAVEPAYROLLRUN_SUCCESS: {
			return {
				...state,
				savePayrollRunLoading: false,
				savePayrollRun: action?.response,
			};
		}
		case Actions.types.GET_SAVEPAYROLLRUN_FAIL: {
			return {
				...state,
				savePayrollRunLoading: false,
				savePayrollRunError: action.error,
				savePayrollRun: [],
			};
		}
		case Actions.types.MODIFIED_MONTH_YEAR: {
			return {
				...state,
				modifiedMonthYear: action.value
			};
		}
		default:
			return state;
	}
};

export default PayRollRunReducer;
