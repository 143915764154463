import React from 'react';
import { Col } from 'antd';
import AccordianPresentation from '@pages/DesignSystem/Accordian/accordian-presentaion';
import ItemHeader from '@pages/help/components/ItemHeader';
import './invoice.scss';

const InvoiceFaqs = () => {
	const faq = [
		{
			question: 'Where should I pay my Edenred invoices?',
			answer: 'Your invoice payments must be made to a different account from the one you transfer your salary funds to. The invoice account details can be found ',
			link: '/payroll/bank_details',
			id: 0,
			isActive: false,
		},
		{
			question: 'I have not received my invoice for the month. What do I do?',
			answer: 'Please send an email to accounts-ae@edenred.com requesting for your monthly invoice.',
			id: 1,
			isActive: false,
		},

		{
			question: 'How do I get a statement of accounts for my company invoice payments?',
			answer: 'Please write to accounts-ae@edenred.com requesting for a copy of the same.',
			id: 2,
			isActive: false,
		},
		{
			question: 'I’m not allowed to run payroll. I am being shown a message about my pending invoices.',
			answer: 'You are requested to clear any pending invoices from this section as soon as possible and send a proof for the same to accounts-ae@edenred.com to immediately have your account unblocked.',
			id: 3,
			isActive: false,
		},
	];

	return (
		<Col xs={24} sm={24} xl={24} md={24} lg={24} className="invoice-presentational">
			<ItemHeader
				title="Invoices"
				description="Please remember to send a proof of invoice payment to accounts-ae@edenred.com to confirm that you have paid your invoices."
			/>
			<Col className="questions" xl={24} lg={24} md={24} sm={24} xs={24}>
				<AccordianPresentation questions={faq} />
			</Col>
		</Col>
	);
};
export default InvoiceFaqs;
