import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import EdenredButtons from './../../DesignSystem/button/EdenredButton';

import './index.scss';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { ALL_EMPLOYEE_CLAIMS, ALL_HR_CLAIMS, ALL_PAYROLL_CLAIMS } from '@constants/app-constants';
import { useSelector } from 'react-redux';
import { mergeAllClaims } from 'src/utils/claims';
import ClevertapReact from 'src/utils/clever-tap';
import { UnemploymentInsuranceBanner } from '@pages/dashboard/main-dashboard/components/unemployment-insurance-banner';
import { UnemploymentInsuranceAvoidFine } from '@pages/dashboard/main-dashboard/components/unemployment-insurance-avoid-fine';

function Index() {
	const history = useHistory();

	const currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);

	function onClickDoItLater() {
		ClevertapReact.event('Later_Unemp');
		const SHOULD_CONTAIN = allClaims.some((_claims) => [...ALL_EMPLOYEE_CLAIMS, ...ALL_HR_CLAIMS].indexOf(_claims) >= 0);
		const SHOULD_NOT_CONTAIN = !allClaims.some((_claims) => [...ALL_PAYROLL_CLAIMS].indexOf(_claims) >= 0);

		if (SHOULD_CONTAIN && SHOULD_NOT_CONTAIN) {
			history.push('/home');
		} else {
			history.push('/');
		}
	}

	return (
		<div style={{ padding: '40px', display: 'grid', rowGap: '20px' }}>
			<UnemploymentInsuranceBanner noButtons={true} />
			<UnemploymentInsuranceAvoidFine />
		</div>
	);
}

export default Index;
