import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import EdenredTypo from '@sharedComponent/typography';
import '../replacement-card.scss';
import EdenredButton from '@sharedComponent/button';
import EdenredHeader from '@pages/main-layout/components/main-header';
import { Link, useHistory, useLocation } from 'react-router-dom';

const ChangeBankAccount = ({ history }) => {
	const { state } = useLocation();

	const handleIbmNumber = () => {
		history.push({ pathname: '/employees/ibm/number', state: { ...state } });
	};

	const handleNoButton = () => {
		history.push({ pathname: '/employees/viewemployee', state: { ...state } });
	};
	return (
		<>
			<EdenredHeader />
			<Row className="change-bank-account-container">
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="change-bank-account-header">
					<EdenredTypo>{state.tableData.employeeName}</EdenredTypo>
				</Col>
				<Col xl={24} md={24} lg={24} xs={24} sm={24} className="change-bank-account-content">
					<EdenredTypo>
						{state.tableData.employeeName} ’s card will be deleted and they will be registered with a bank account. Do you want to
						continue?
					</EdenredTypo>
					<EdenredTypo>You may be charged a small fee for card deletion, depending upon your agreement.</EdenredTypo>
				</Col>
				<Col xl={8} lg={12} md={12} xs={12} sm={12} className="change-bank-account-button">
					<EdenredButton type="outline-g" onClick={handleNoButton}>
						No
					</EdenredButton>
				</Col>
				<Col xl={8} lg={12} md={12} xs={12} sm={12} className="change-bank-account-button">
					<EdenredButton onClick={handleIbmNumber}>Yes</EdenredButton>
				</Col>
			</Row>
		</>
	);
};
export default ChangeBankAccount;
