import Axios from 'axios';
import { getLocalvariable } from '@helpers';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';

class InvoiceService {
	submitInvoiceProof = (body, params) =>
		Axios.post(`${DOMAIN_URL}/InvoiceReceipt/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, body, { params });
	///api/v{version}/UnemploymentInsurance/{corporateId}/missing-details/bulk-upload
	uploadMissingEmpoloye = (body, params) =>
		Axios.post(`${DOMAIN_URL}/UnemploymentInsurance/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/missing-details/bulk-upload`, body, {
			params,
		});

	imageDownload = (encoded) =>
		Axios.get(`${DOMAIN_URL}/Download/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}?url=${encoded}`, { responseType: 'blob' });
}

export default InvoiceService;
