import ETypo from '@pages/DesignSystem/Typo';
import React from 'react';
import { icons } from 'src/assets/icons';
import { Link } from 'react-router-dom';
import { SITEMAP } from 'src/routes/sitemap';
import { FAQ_LINK } from '@constants/index';
import './insurance-banner.scss';

export const UnemploymentRenewalBanner = ({ action = () => {} }) => {
	return (
		<div className="un-employment-warning-banner">
			<div className="info-icon">
				<img src={icons?.focus_state?.infoTraingleWarning} alt="warning" />
			</div>
			<div className="info-content">
				<ETypo bold h5>
					Unemployment Insurance Policies Will Be Renewed Soon
				</ETypo>{' '}
				<br />
				<ETypo b2>
					Make sure your employees’ have sufficient balance for us to successfully renew their policies&nbsp;
					<a className="link" href={FAQ_LINK} target="_blank" rel="noopener noreferrer">
						<ETypo bold b1>
							{' '}
							Read More{' '}
						</ETypo>
					</a>{' '}
				</ETypo>
			</div>
			<div className="info-action" onClick={action}>
				<Link to={SITEMAP.unemploymentInsurance.employee}>
					<ETypo b1 light>
						Check now
					</ETypo>
				</Link>
			</div>
		</div>
	);
};
export const InsurancePolicyUpdateBanner = ({ action = () => {} }) => {
	return (
		<div className="un-employment-warning-banner">
			<div className="info-icon">
				<img src={icons?.focus_state?.infoTraingleWarning} alt="warning" />
			</div>
			<div className="info-content">
				<ETypo bold h5>
					Few Updates on Insurance Policy Subscriptions & Renewals
				</ETypo>
				<br />
				<ETypo b2>
					As per Dubai Insurance mandates, the new subscriptions are only allowed for two years, paid in full.
					<br />
					For subscribed employees, the Policy will be auto-renewed for one year to be paid on the same subscription plan as last year.
				</ETypo>
			</div>
			<div className="info-action" onClick={action}>
				<a href={FAQ_LINK} target="_blank" rel="noopener noreferrer">
					<ETypo b1 light>
						Know more
					</ETypo>
				</a>{' '}
			</div>
		</div>
	);
};
export const InsurancePolicyRenewalStartedBanner = ({ action = () => {} }) => {
	return (
		<div className="un-employment-warning-banner">
			<div className="info-icon">
				<img src={icons?.focus_state?.infoTraingleWarning} alt="warning" />
			</div>
			<div className="info-content">
				<ETypo bold h5>
					Policy Renewals Have Started For Your Employees
				</ETypo>
				<br />
				<ETypo b2>Please check the ‘pending’ tab & take appropriate action, if needed, to avoid any fines.</ETypo>
			</div>
			<div className="info-action" onClick={action}>
				<ETypo b1 light>
					Understood
				</ETypo>
			</div>
		</div>
	);
};
