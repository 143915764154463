import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { APP_VARIABLES } from '@constants/app-constants';
import AppLoader from '@sharedComponent/app-loader';
import ClevertapReact from 'src/utils/clever-tap';
import EmployeeSalariesService from 'src/services/employee-salaries-service';
import EmployeeService from 'src/services/employee-service';
import MainDashboardPresentational from './main-dashboard-presentational';
import PayoutTimingService from 'src/services/PayoutTiming-service';
import PayrollService from 'src/services/payroll-service';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import appActions from '@sharedComponent/app-action-reducer/action';
import { getLocalvariable } from '@helpers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

let TIMER_CALL = '';
const MainDashboardFunctional = ({ currentUser, corporateIds, corporateLoading }) => {
	const [pageLoading, setPageLoading] = useState(true);
	let [payrollHistory, setPayrollHistory] = useState([]);
	let [employeeCount, setEmployeeCount] = useState({});
	let [payoutTiming, setPayoutTiming] = useState([]);
	let [employeePaidByMonth, setEmployeePaidByMonth] = useState([]);
	let [employeePaidByPayrollChannel, setEmployeePaidByPayrollChannel] = useState([]);
	let [cardRequest, setCardRequest] = useState([]);
	let [currentTimeCutOff, setCurrentTimeCutOff] = useState({});
	let [isDataLoading, setIsDataLoading] = useState(false);
	let [isPayrollHistoryLoading, setIsPayrollHistoryLoading] = useState(true);
	let [isCardHistoryLoading, setIsCardHistoryLoading] = useState(false);
	let [isEmployeePaidLoading, setIsEmployeePaidLoading] = useState(false);
	let [isPayrollAmountLoading, setIsPayrollAmountLoading] = useState(false);
	let [isEmployeeCountLoading, setIsEmployeeCountLoading] = useState(false);
	const [approveReqCount, setApproveReqCount] = useState();
	const [nextCardLenght, setNextCardLenght] = useState(2);
	const [size, setSize] = useState([0, 0]);
	let totalPayrollHistory = useRef([]);
	let totalCardRequest = useRef([]);
	let totalEmployeePaidOff = useRef([]);
	let totalPayrollAmount = useRef([]);

	const dispatch = useDispatch();

	let payrollSlideIndex = useRef({
		startIndex: 0,
		endIndex: 2,
	});

	let cardReqSlideIndex = useRef({
		startIndex: 0,
		endIndex: 2,
	});

	let employeePaidOffSlideIndex = useRef({
		startIndex: 0,
		endIndex: 4,
	});

	//Bar Chart Pagination -- Payroll Amount
	let payrollAmountSlideIndex = useRef({
		startIndex: 0,
		endIndex: 6,
	});
	const history = useHistory();
	const _payrollService = new PayrollService();
	const employeesSalaryApi = new EmployeeSalariesService();
	const payoutTimingApi = new PayoutTimingService();
	const employeeApi = new EmployeeService();
	const { user } = currentUser;

	const { invoiceCorporateList } = useSelector((state) => state?.sharedStates) || {};

	const callRemainingAPIs = () => {
		fetchPayrollHistory();
		getDashboardDetails();
		getApproveRequestCount();
		fetchEmployeePaidMonthWise();
	};
	useEffect(() => {
		if (history.location.state && history.location.state.response) {
			window.location.reload();
		}
	}, [history?.location?.state?.response]);

	// useEffect(() => {
	// 	if (invoiceCorporateList.includes(localStorage.getItem('cid'))) {
	// 		fetchInvoiceDetails();
	// 	}
	// }, [invoiceCorporateList]);

	useEffect(() => {
		fetchInvoiceDetails();
	}, []);

	useEffect(() => {
		let accessToken = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
		const corprateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID) ? localStorage.getItem(APP_VARIABLES.CORPORATE_ID) : '';
		const corprateName = localStorage.getItem(APP_VARIABLES.CORPORATE_NAME) ? localStorage.getItem(APP_VARIABLES.CORPORATE_NAME) : '';
		if (user && accessToken) {
			fetchPendingRequests();
			// fetchPendingRequests();
			// fetchPayrollHistory();
			// getDashboardDetails();
			// //fetchEmployeeCount();
			// getApproveRequestCount();
			// fetchEmployeePaidMonthWise();
			// ClevertapReact.profile({
			// 	Site: {
			// 		Name: currentUser.user.userName,
			// 		Email: currentUser.user.userEmail,
			// 	},
			// });
			// ClevertapReact.event('LoginSuccess');
			// ClevertapReact.onUserLogin({
			// 	Site: {
			// 		Name: currentUser.user.userName,
			// 		Email: currentUser.user.userEmail,
			// 		Identity: currentUser.user.userId,
			// 		CorprateId: corprateId,
			// 		CompanyName: corprateName,
			// 		Role: currentUser.user.roles && currentUser.user.roles.length > 0 ? currentUser.user.roles[0].name : '',
			// 	},
			// });
			// ClevertapReact.onUserLogin({
			// 	Site: {
			// 		Name: currentUser.user.userName,
			// 		Email: currentUser.user.userEmail,
			// 		Identity: currentUser.user.userId,
			// 		Role: currentUser.user.roles && currentUser.user.roles.length > 0 ? currentUser.user.roles[0].name : '',
			// 	},
			// });
			// getApproveReqCountByTime();
			return function cleanup() {
				clearTimeout(TIMER_CALL);
			};
		}
	}, [user]);
	let final;
	function useWindowSize() {
		useLayoutEffect(() => {
			function updateSize() {
				final = window.innerWidth < 1680 ? 2 : 3;
				setNextCardLenght(final);
				/**
				 * Old design cards need to show records based on screen size.
				 * In New Design change as table. So no need to show based on screen size.
				 * In New Design we show all records getting from API.
				 * No need to call apis while resizing the window
				 */
				// fetchPendingRequests();
				// fetchPayrollHistory();
			}
			window.addEventListener('resize', updateSize);
			updateSize();
			return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	}

	const getApproveRequestCount = () => {
		_payrollService
			.getApproveRequestCountAPI()
			.then((res) => {
				setApproveReqCount(res && res.data && res.data.pendingRequest ? res.data.pendingRequest : null);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getApproveReqCountByTime = () => {
		TIMER_CALL = setInterval(() => getApproveRequestCount(), 3000);
	};

	async function getDashboardDetails() {
		// setIsDataLoading(true);

		let [
			// employeeCountResponse,
			// payrollResponse,
			payoutTimingResponse,
			// pendingRequestResp,
			// employeePaidByMonthResp,
			employeePaidByPayrollResp,
		] = await Promise.all([
			//fetchEmployeeCount(),
			// fetchPayrollHistory(),
			fetchPayoutTiming(),
			// fetchPendingRequests(),
			// fetchEmployeePaidMonthWise(),
			fetchEmployeePaidByPayrollChannel(),
			fetchEmployeeCount(),
		]);

		// if (employeeCountResponse) {
		// 	setEmployeeCount(employeeCountResponse.employeeCount);
		// }
		// card order history
		// if (pendingRequestResp) {
		// 	let response =
		// 		pendingRequestResp.requests &&
		// 		pendingRequestResp.requests.map((request) => {
		// 			request.noOfCards = request.numberOfRequest;
		// 			let orderPlacedDate = moment(request.orderPlaced);
		// 			request.orderPlaced = `${orderPlacedDate.format('DD MMMM YYYY')} at ${orderPlacedDate.format('h:mm a')}`;
		// 			return request;
		// 		});
		// 	totalCardRequest.current = [...response];
		// 	setCardRequest(response.slice(0, 2));
		// }

		if (payoutTimingResponse) {
			let time = new Date().toLocaleTimeString();
			let currentTimeInfo = payoutTimingResponse.payoutTimings.find((timing) => {
				let startTime = moment(timing.startTime, 'h:mm a');
				let endTime = moment(timing.endTime, 'h:mm a');
				let currentTime = moment(time, 'h:mm a');
				return currentTime.isBetween(startTime, endTime, 'time');
			});
			if (currentTimeInfo) {
				setCurrentTimeCutOff(currentTimeInfo);
				setPayoutTiming([
					{
						title: 'WPS Cut Off',
						time: currentTimeInfo.bankWPS,
					},
					{
						title: 'Non WPS Cut Off',
						time: currentTimeInfo.bankNonWPS,
					},
				]);
			}
		}

		if (employeePaidByPayrollResp) {
			let updated = employeePaidByPayrollResp.salaryPayrollChannel.map((salaryPayrollChannel) => {
				salaryPayrollChannel.month = moment(salaryPayrollChannel.month, 'MMM YYYY').format('MMM YY');
				return salaryPayrollChannel;
			});
			setEmployeePaidByPayrollChannel(updated.slice(-6));
			if (updated.length <= 6) {
				setCurrentPayrollAmountActiveSlide({
					prev: false,
					next: false,
				});
			} else {
				payrollAmountSlideIndex.current.endIndex = updated.length;
				payrollAmountSlideIndex.current.startIndex = updated.length - 6;
				setCurrentPayrollAmountActiveSlide({
					prev: true,
					next: false,
				});
			}
			totalPayrollAmount.current = [...updated];
		}
		setIsDataLoading(false);
	}

	async function fetchPendingRequests() {
		setIsCardHistoryLoading(true);
		try {
			let fetchPendingRequestsResponse = await employeeApi.getCardStatus();
			if (fetchPendingRequestsResponse && fetchPendingRequestsResponse.data) {
				setIsCardHistoryLoading(false);
				// return fetchPendingRequestsResponse.data;
				let response =
					fetchPendingRequestsResponse.data.requests &&
					fetchPendingRequestsResponse.data.requests.map((request) => {
						request.noOfCards = request.numberOfRequest;
						let orderPlacedDate = moment(request.orderPlaced);
						request.orderPlaced = `${orderPlacedDate.format('DD MMMM YYYY')} at ${orderPlacedDate.format('h:mm a')}`;
						return request;
					});
				totalCardRequest.current = [...response];
				setCardRequest(response.slice(0, 10));
			} else {
				setCardRequest([]);
			}
			callRemainingAPIs();
			setPageLoading(false);
		} catch (err) {
			setIsCardHistoryLoading(false);
			setPageLoading(false);
			console.log(err);
		}
	}

	async function fetchEmployeePaidMonthWise() {
		setIsEmployeePaidLoading(true);
		try {
			let employeePaidByMonthRespresponse = await employeesSalaryApi.getEmployeePaidMonthWise();
			if (employeePaidByMonthRespresponse && employeePaidByMonthRespresponse.data) {
				setIsEmployeePaidLoading(false);
				// return response.data;
				let updated = employeePaidByMonthRespresponse.data.salaryPaidCount.map((salaryPaid) => {
					let month_year = salaryPaid.month.split(' ');
					salaryPaid.month = moment(month_year[0], 'MMM').format('MMMM');
					salaryPaid.year = month_year[1];
					return salaryPaid;
				});
				setEmployeePaidByMonth(updated.slice(0, 4));
				totalEmployeePaidOff.current = [...updated];
			}
		} catch (err) {
			console.log(err);
			setIsEmployeePaidLoading(false);
		}
	}

	async function fetchInvoiceDetails() {
		dispatch(appActions.creators.getInvoiceInfoStart());
		try {
			let response = await employeeApi.getInvoiceDetails();
			if (response && response.data) {
				dispatch(appActions.creators.getInvoiceInfoSuccess(response.data));
				return response.data;
			}
		} catch (err) {
			dispatch(appActions.creators.getInvoiceInfoFail());
		}
	}

	async function fetchEmployeePaidByPayrollChannel() {
		setIsPayrollAmountLoading(true);
		try {
			let response = await employeesSalaryApi.getEmployeePaidByPayrollChannel();
			if (response && response.data) {
				setIsPayrollAmountLoading(false);
				return response.data;
			}
		} catch (err) {
			console.log(err);
			setIsPayrollAmountLoading(false);
		}
	}

	async function fetchPayrollHistory() {
		setIsPayrollHistoryLoading(true);
		try {
			let payrollResponseresponse = await employeesSalaryApi.getPayrollHistory(1, 10);
			if (payrollResponseresponse && payrollResponseresponse.data) {
				setIsPayrollHistoryLoading(false);
				if (payrollResponseresponse.data.payrollHistory.length <= 2) {
					setCurrentActiveSlide({
						prev: false,
						next: false,
					});
				}
				let response = payrollResponseresponse.data.payrollHistory;
				totalPayrollHistory.current = [...response];
				setPayrollHistory(response.slice(0, 10));
			}
		} catch (err) {
			console.log(err);
			setIsPayrollHistoryLoading(false);
		}
	}

	async function fetchEmployeeCount() {
		setIsEmployeeCountLoading(true);
		try {
			let response = await employeeApi.getDashboardEmployeeCount();
			if (response && response.data) {
				setIsEmployeeCountLoading(false);
				if (response.data) {
					setEmployeeCount(response.data.employeeCount);
				}
				return response.data;
			}
		} catch (err) {
			setIsEmployeeCountLoading(false);
		}
	}

	async function fetchPayoutTiming() {
		try {
			let response = await payoutTimingApi.getPayoutTiming();
			if (response && response.data) {
				return response.data;
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [currentActiveSlide, setCurrentActiveSlide] = useState({
		prev: false,
		next: true,
	});
	const [currentCardOrderActiveSlide, setCurrentCardOrderActiveSlide] = useState({
		prev: false,
		next: true,
	});

	const [currentEmployeePaid, setCurrentEmployeePaid] = useState({
		prev: false,
		next: true,
	});
	const [currentPayrollAmountActiveSlide, setCurrentPayrollAmountActiveSlide] = useState({
		prev: false,
		next: true,
	});
	function handlePayrollSlideChange(navigation) {
		let { startIndex, endIndex } = payrollSlideIndex.current;
		let totalData = totalPayrollHistory.current.length;
		if (!totalData) {
			return;
		}
		if (navigation === 'prev') {
			if (startIndex < 1) {
				payrollSlideIndex.current.endIndex = totalData;
				payrollSlideIndex.current.startIndex = totalData - 2;
			} else if (startIndex == 1) {
				payrollSlideIndex.current.endIndex = 2;
				payrollSlideIndex.current.startIndex = 0;
			} else {
				payrollSlideIndex.current.startIndex = endIndex - 4;
				payrollSlideIndex.current.endIndex = endIndex - 2;
			}
		} else {
			if (totalData > 2 && totalData > payrollSlideIndex.current.endIndex) {
				payrollSlideIndex.current.startIndex = endIndex;
				payrollSlideIndex.current.endIndex = endIndex + 2 <= totalData ? endIndex + nextCardLenght : totalData;
				// payrollSlideIndex.current.endIndex = totalData % 2 == 0 ? endIndex + 2 : totalData;
			} else {
				payrollSlideIndex.current.startIndex = 0;
				payrollSlideIndex.current.endIndex = 2;
			}

			// if (endIndex < totalData - 1) {
			// 	payrollSlideIndex.current.startIndex = endIndex;
			// 	payrollSlideIndex.current.endIndex = endIndex + 2;
			// } else {
			// 	payrollSlideIndex.current.startIndex = 0;
			// 	payrollSlideIndex.current.endIndex = 2;
			// }
		}
		console.log(payrollSlideIndex.current.startIndex);
		console.log(payrollSlideIndex.current.endIndex);
		if (totalData <= payrollSlideIndex.current.endIndex) {
			setCurrentActiveSlide({
				prev: true,
				next: false,
			});
		} else if (totalData > 0 && payrollSlideIndex.current.endIndex <= 2) {
			setCurrentActiveSlide({
				prev: false,
				next: true,
			});
		} else {
			setCurrentActiveSlide({
				prev: true,
				next: true,
			});
		}
		setPayrollSliderData();
	}

	function setPayrollSliderData() {
		let { startIndex, endIndex } = payrollSlideIndex.current;
		setPayrollHistory(totalPayrollHistory.current.slice(startIndex, endIndex));
	}

	function setCardHistorySliderData() {
		let { startIndex, endIndex } = cardReqSlideIndex.current;
		setCardRequest(totalCardRequest.current.slice(startIndex, endIndex));
	}

	function handleCardHistorySlideChange(navigation) {
		let { startIndex, endIndex } = cardReqSlideIndex.current;
		let totalData = totalCardRequest.current.length;
		if (!totalData) {
			return;
		}
		console.log({ startIndex });
		console.log({ endIndex });
		console.log({ totalData });
		if (navigation === 'prev') {
			if (startIndex < 1) {
				cardReqSlideIndex.current.endIndex = totalData || 2;
				cardReqSlideIndex.current.startIndex = totalData - 2 || 0;
			} else if (startIndex == 1) {
				cardReqSlideIndex.current.endIndex = 2;
				cardReqSlideIndex.current.startIndex = 0;
			} else {
				cardReqSlideIndex.current.startIndex = endIndex - 4 ? endIndex - 4 : 0 || 0;
				cardReqSlideIndex.current.endIndex = endIndex - 2 || 2;
				console.log('startIndex', cardReqSlideIndex.current.startIndex);
				console.log('endIndex', cardReqSlideIndex.current.endIndex);
			}
		} else {
			if (totalData > 2 && totalData > cardReqSlideIndex.current.endIndex) {
				cardReqSlideIndex.current.startIndex = endIndex;
				cardReqSlideIndex.current.endIndex = endIndex + 2 <= totalData ? endIndex + nextCardLenght : totalData;
				// cardReqSlideIndex.current.endIndex = totalData % 2 == 0 ? endIndex + 2 : totalData;
			} else {
				cardReqSlideIndex.current.startIndex = 0;
				cardReqSlideIndex.current.endIndex = 2;
			}

			// if (endIndex < totalData - 1) {
			// 	cardReqSlideIndex.current.startIndex = endIndex;
			// 	cardReqSlideIndex.current.endIndex = endIndex + 2;
			// } else {
			// 	cardReqSlideIndex.current.startIndex = 0;
			// 	cardReqSlideIndex.current.endIndex = 2;
			// }
		}
		console.log(cardReqSlideIndex.current.startIndex);
		console.log(cardReqSlideIndex.current.endIndex);
		if (totalData <= cardReqSlideIndex.current.endIndex) {
			setCurrentCardOrderActiveSlide({
				prev: true,
				next: false,
			});
		} else if (totalData > 0 && cardReqSlideIndex.current.endIndex <= 2) {
			setCurrentCardOrderActiveSlide({
				prev: false,
				next: true,
			});
		} else {
			setCurrentCardOrderActiveSlide({
				prev: true,
				next: true,
			});
		}

		setCardHistorySliderData();
	}

	function handleEmployeePaidOffSlideChange(navigation) {
		let { startIndex, endIndex } = employeePaidOffSlideIndex.current;
		let totalData = totalEmployeePaidOff.current.length;
		console.log(totalData);
		if (!totalData) {
			return;
		}
		if (navigation === 'prev') {
			if (startIndex <= 1) {
				employeePaidOffSlideIndex.current.endIndex = totalData;
				employeePaidOffSlideIndex.current.startIndex = totalData - 4;
			} else {
				employeePaidOffSlideIndex.current.startIndex = endIndex - 8;
				employeePaidOffSlideIndex.current.endIndex = endIndex - 4;
			}
		} else {
			if (endIndex < totalData - 1) {
				employeePaidOffSlideIndex.current.startIndex = endIndex;
				employeePaidOffSlideIndex.current.endIndex = endIndex + 4;
			} else {
				employeePaidOffSlideIndex.current.startIndex = 0;
				employeePaidOffSlideIndex.current.endIndex = 4;
			}
		}
		setEmployeePaidOffSliderData();
	}
	function setEmployeePaidOffSliderData() {
		let { startIndex, endIndex } = employeePaidOffSlideIndex.current;
		setEmployeePaidByMonth(totalEmployeePaidOff.current.slice(startIndex, endIndex));
	}

	function handlePayrollAmountSlideChange(navigation) {
		let { startIndex, endIndex } = payrollAmountSlideIndex.current;
		let totalData = totalPayrollAmount.current.length;
		if (!totalData) {
			return;
		}
		if (navigation === 'prev') {
			if (startIndex <= 1) {
				payrollAmountSlideIndex.current.endIndex = totalData;
				payrollAmountSlideIndex.current.startIndex = totalData - 6;
			} else {
				payrollAmountSlideIndex.current.startIndex = endIndex - 12;
				payrollAmountSlideIndex.current.endIndex = endIndex - 6;
			}
		} else {
			if (totalData > 6 && totalData > payrollAmountSlideIndex.current.endIndex) {
				payrollAmountSlideIndex.current.startIndex = endIndex;
				payrollAmountSlideIndex.current.endIndex = endIndex + 6 <= totalData ? endIndex + 6 : totalData;
				// payrollAmountSlideIndex.current.endIndex = totalData % 2 == 0 ? endIndex + 2 : totalData;
			} else {
				payrollAmountSlideIndex.current.startIndex = 0;
				payrollAmountSlideIndex.current.endIndex = 6;
			}
		}
		if (totalData <= payrollAmountSlideIndex.current.endIndex) {
			setCurrentPayrollAmountActiveSlide({
				prev: true,
				next: false,
			});
		} else if (totalData > 0 && payrollAmountSlideIndex.current.endIndex <= 6) {
			setCurrentPayrollAmountActiveSlide({
				prev: false,
				next: true,
			});
		} else {
			setCurrentPayrollAmountActiveSlide({
				prev: true,
				next: true,
			});
		}
		setPayrollAmountSliderData();
	}

	function handleEmployeePaid(navigation) {
		//TODO -need to return for employee paid functionality
	}
	function setPayrollAmountSliderData() {
		let { startIndex, endIndex } = payrollAmountSlideIndex.current;
		setEmployeePaidByPayrollChannel(totalPayrollAmount.current.slice(startIndex, endIndex));
	}
	const [width, height] = useWindowSize();

	// if (pageLoading) return <AppLoader />;

	return (
		<>
			<MainDashboardPresentational
				{...{ pageLoading }}
				isDataLoading={isDataLoading}
				payrollHistory={payrollHistory}
				employeeCount={employeeCount}
				employeePaidByPayroll={employeePaidByPayrollChannel}
				employeePaidByMonth={employeePaidByMonth}
				cardRequest={cardRequest}
				payoutTiming={payoutTiming}
				currentTimeCutOff={currentTimeCutOff}
				onPayrollSlideChange={handlePayrollSlideChange}
				onCardHistorySlideChange={handleCardHistorySlideChange}
				onEmployeePaidOffSlideChange={handleEmployeePaidOffSlideChange}
				onPayrollAmountSlideChange={handlePayrollAmountSlideChange}
				onEmployeePaidSlideChange={handleEmployeePaid}
				totalPayrollHistory={totalPayrollHistory.current}
				totalCardRequest={totalCardRequest.current}
				totalEmployeePaidOff={totalEmployeePaidOff.current}
				currentActiveSlide={currentActiveSlide}
				currentCardOrderActiveSlide={currentCardOrderActiveSlide}
				currentEmployeePaid={currentEmployeePaid}
				currentPayrollAmountActiveSlide={currentPayrollAmountActiveSlide}
				payrollTotalData={totalPayrollHistory.current.length}
				cardOrderTotalData={totalCardRequest.current.length}
				currentUser={currentUser}
				approveReqCount={approveReqCount}
				isPayrollHistoryLoading={isPayrollHistoryLoading}
				isCardHistoryLoading={isCardHistoryLoading}
				isEmployeePaidLoading={isEmployeePaidLoading}
				isPayrollAmountLoading={isPayrollAmountLoading}
				isEmployeeCountLoading={isEmployeeCountLoading}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		currentUser: SelectorsApp.currentUser(state),
		corporateIds: SelectorsApp.corporateIds(state),
		corporateLoading: SelectorsApp.corporateLoading(state),
		user: state.oidc.user,
	};
};

export default connect(mapStateToProps, null)(MainDashboardFunctional);
