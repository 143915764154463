import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import SharedServices, { GET_PROFILE } from '@sharedComponent/app-action-reducer/services';
import { APP_VARIABLES } from '@constants/app-constants';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import PayrollService from 'src/services/payroll-service';
import { images } from 'src/assets/img';
import Actions from '../action';
import Popup from 'src/utils/popup/popups';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import UploadAttachment from '@pages/Leave-Management/request-leave/component/upload-attachment';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ProfileAction from '../action';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '@pages/DesignSystem/Toast';
import ClevertapReact from 'src/utils/clever-tap';
import ETypo from '@pages/DesignSystem/Typo';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ImageComponent from '@sharedComponent/image-component';
import mfaActions from '@pages/auth/mfa/mfaActions';
import { MFA_CONSTANTS } from '@pages/auth/mfa/constants';
import FlagImage from '@sharedComponent/FlagImage';
import { getLocalvariable, setLocalvariable } from '@helpers';
import { UserService } from 'src/services';
import CountryDropdownInput from '@sharedComponent/CountryDropdownInput';
import '../../../../DesktopFix.css';

const ProfilePresentational = ({ currentUser, userName, role, setUser }) => {
	const [loading, setloading] = useState(false);
	const { user } = currentUser;
	const [Email, setEmail] = useState('');
	const inputFieldEdited = useRef(false);
	const api = new SharedServices();
	let history = useHistory();
	const _payrollService = new PayrollService();
	const accesstoken = JSON.parse(localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN));
	const [popupVisible, setpopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const dispatch = useDispatch();
	const isImageUpload = useSelector((state) => state.profileReducer.isProfileImgSaved);
	const deleteProfileImgMsg = useSelector((state) => state.profileReducer.deleteProfileImgMsg);
	const profileImg = useSelector((state) => state?.sharedStates?.profileImg);
	const [mobileNumberModal, setmobileNumberModal] = useState(false);
	const [numberValidation, setnumberValidation] = useState(false);
	const [otpValidation, setotpValidation] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [otpStatusSent, setOtpStatusSent] = useState(false);
	const [verifyOtpStatus, setverifyOtpStatus] = useState(false);
	const [otp, setOtp] = useState(Array(6).fill(''));
	const inputRefs = useRef(Array(6).fill(null));
	const [seconds, setSeconds] = useState(30);
	const auth = useSelector((state) => state.auth);
	const [stdCode, setStdCode] = useState('971');
	const [phoneNumberFormat, setPhoneNumberFormat] = useState(`+${stdCode}${phoneNumber}`);
	// default country as UAE
	const [country, setCountry] = useState({
		code: 'ae',
		stdCode: '971',
	});
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		if (user && user.userEmail) {
			setEmail(user.userEmail);
		}
	}, [user]);

	useEffect(() => {
		if (history.location.state && history.location.state.response) {
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: history.location.state.response,
				subMessage: null,
			});
		}
		setTimeout(() => {
			if (history.location && history.location.state && history.location.state.response) {
				const state = { ...history.location.state };
				delete state.response;
				history.replace({ ...history.location, state });
			}
		}, 0);
	}, []);

	useEffect(() => {
		const { status, sent } = auth.otp || {};
		if (sent) {
			setOtpStatusSent(true);
			setSeconds(30);
			// if the phone number and its format not same then only will update the phone number
			if (phoneNumber !== phoneNumberFormat) {
				setPhoneNumber(phoneNumberFormat);
			}
		}
		if (status) {
			if (status === MFA_CONSTANTS.otpStatusVerify.WRONG_OTP && !otpValidation) {
				setotpValidation(true);
			} else if (status === MFA_CONSTANTS.otpStatusVerify.UPDATED) {
				setOtpStatusSent(false);
				setverifyOtpStatus(true);
			}
		}
	}, [auth.otp]);

	useEffect(() => {
		if (verifyOtpStatus) {
			api.services(GET_PROFILE).then((res) => {
				setUser(res.data);
			});
		}
	}, [verifyOtpStatus]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	useEffect(() => {
		inputRefs.current = inputRefs.current.map((ref, index) => ref || createRef(index));
	}, []);

	useEffect(() => {
		if (mobileNumberModal) {
			const countriesDataFromLocal = getLocalvariable(MFA_CONSTANTS.localStorage.countriesKey);
			if (countriesDataFromLocal) {
				const parsedCountriesData = typeof countriesDataFromLocal === 'string' ? JSON.parse(countriesDataFromLocal) : [];
				setCountries(parsedCountriesData);
			} else {
				getCountries();
			}
		}
	}, [mobileNumberModal]);

	const getCountries = async () => {
		const userService = new UserService();
		try {
			const res = await userService.getAllCountries();
			setCountries(res.data);
			setLocalvariable(MFA_CONSTANTS.localStorage.countriesKey, res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const handleEmailvalidation = (val) => {
		setEmail(val);
		const regexEmailCheck =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!regexEmailCheck.test(val)) {
			inputFieldEdited.current = false;
		} else {
			inputFieldEdited.current = true;
		}
	};
	const handleChange = () => {
		ClevertapReact.event('Profile_DocsMgt_Save', null);
		if (Email) {
			setloading(true);
			let data = {
				username: accesstoken.username,
				email: Email,
			};
			_payrollService
				.updateEmail(data)
				.then((res) => {
					setEmail(false);
					api.services(GET_PROFILE)
						.then((res) => {
							setUser(res.data);
						})
						.catch((err) => {
							setloading(false);
						});
					setloading(false);

					setpopupVisible(true);
					setPopupMessage({
						titleMessage: 'Email successfully updated',
						subMessage: null,
					});
				})
				.catch((err) => {
					setloading(false);
				});
		}
	};

	const createRef = (index) => {
		inputRefs.current[index] = React.createRef();
		return inputRefs.current[index];
	};

	const closePopup = () => {
		setpopupVisible(false);
	};
	const updateMobileNumberModal = () => {
		setmobileNumberModal(true);
	};

	const handleInput = (e, index) => {
		const value = e.target.value;
		if (!isNaN(value)) {
			setOtp([...otp.slice(0, index), value, ...otp.slice(index + 1)]);
			if (value && inputRefs.current[index + 1]) {
				inputRefs.current[index + 1].current.focus();
			}
			// disabling the focus for the last input
			if (value && !inputRefs.current[index + 1]) {
				inputRefs.current[index].current.blur();
			}
			// Move focus to the previous input if the current input is empty and backspace is pressed
			if (value === '') {
				// Using backsapce key to remove input
				if (e.nativeEvent.inputType === 'deleteContentBackward' && inputRefs.current[index - 1]) {
					inputRefs.current[index - 1].current.focus();
					// Using deletekey to remove input
				} else if (e.nativeEvent.inputType === 'deleteContentForward' && inputRefs.current[index + 1]) {
					inputRefs.current[index + 1].current.focus();
				}
				setotpValidation(false);
			}
		}
	};
	const handlePaste = (e) => {
		if (!isNaN(e.clipboardData.getData('text'))) {
			setOtp(e.clipboardData.getData('text').substring(0, 6).split(''));
		}
	};

	const isValidOTP = () => {
		if (otp.join('').length === 6 && !otpValidation) {
			return true;
		}
		return false;
	};

	const sendingOTP = () => {
		if (!phoneNumber.length) {
			setnumberValidation(true);
			return;
		}
		const phoneNumberWithStdcode = `+${stdCode}${phoneNumber}`;
		const body = {
			phoneNumber: phoneNumberWithStdcode,
			reason: MFA_CONSTANTS.otpReason.update,
		};
		dispatch(mfaActions.creators.sendOtp(body));
		setPhoneNumberFormat(phoneNumberWithStdcode);
		setnumberValidation(false);
	};

	const handleVerifyOtp = () => {
		const body = {
			otp: otp.join(''),
			phoneNumber,
		};
		dispatch(mfaActions.creators.updatePhoneNumber(body));
	};

	const handleResendOtp = () => {
		setotpValidation(false);
		const body = {
			phoneNumber,
			reason: MFA_CONSTANTS.otpReason.update,
		};
		dispatch(mfaActions.creators.sendOtp(body));
		setOtp(new Array(6).fill(''));
		setSeconds(30);
	};
	// Modal components
	const OTPValidationComponent = (
		<div className="otp-container">
			<div className="otp-input-container">
				<div className="otp-input-wrapper">
					<div className="otp-input">
						{otp.map((digit, index) => (
							<input
								className={`input-field ${digit !== '' ? 'input-field-value' : 'border'} ${otpValidation ? 'error-input' : ''}`}
								key={index}
								type="text"
								autoComplete="off"
								maxLength="1"
								name={`otp${index}`}
								value={digit}
								autoFocus={index === 0}
								onChange={(e) => handleInput(e, index)}
								onPaste={handlePaste}
								ref={inputRefs.current[index]}
							/>
						))}
					</div>
					{otpValidation && (
						<ETypo b3 className="error-msg">
							Incorrect OTP!
						</ETypo>
					)}
				</div>
			</div>
			<div className="otp-validation">
				<ETypo b1 onClick={seconds === 0 ? handleResendOtp : undefined}>
					<span className={seconds === 0 ? 'resend-otp-text' : ''}>Resend OTP</span>&nbsp;
					{seconds > 0 && <span>{`in ${seconds}s`}</span>}
				</ETypo>
				<span className="verify-btn">
					<EdenredButtons
						loading={auth?.otp?.inProgress}
						children={'Verify OTP'}
						btnType={isValidOTP() ? 'primary' : 'secondary'}
						className={isValidOTP() ? '' : 'verify-button'}
						onClick={isValidOTP() ? handleVerifyOtp : undefined}
					/>
				</span>
			</div>
		</div>
	);
	const handleNumberChange = (e) => {
		const { shortCode, stdCode } = e || {};
		if (stdCode) {
			setStdCode(stdCode);
		}
		setCountry({ code: shortCode, stdCode });
	};
	const inputChange = (value) => {
		setPhoneNumber(value);
		setnumberValidation(false);
	};
	const MobileNumberComponent = (
		<div className="update-number-field-modal">
			<CountryDropdownInput
				options={countries}
				value={stdCode}
				inputValue={phoneNumber}
				country={country}
				onChange={handleNumberChange}
				onInputChange={inputChange}
				placeholder="Enter your mobile number"
				label="Mobile Number"
				searchLabel="countryName"
				searchHolder="Search by country name"
				prefix={<FlagImage flagCode={country.code} />}
				optionValue="stdCode"
				isError={numberValidation}
			/>
			{numberValidation && (
				<ETypo b3 className="error-msg">
					Invalid Number
				</ETypo>
			)}
		</div>
	);

	const displayModalContent = () => {
		if (otpStatusSent) {
			return {
				title: 'Verify Mobile Number',
				desc: <span className="phone-number-desc">Enter the OTP sent to {phoneNumber} to verify your Mobile Number</span>,
				onOkay: null,
				onCancel: null,
				children: OTPValidationComponent,
			};
		} else if (verifyOtpStatus) {
			return {
				title: 'Verification Successful!',
				desc: 'Your Mobile Number has been successfully updated',
				onOkayButton: 'Done',
				onOkay: onCloseModal,
				onCancel: null,
				close: null,
				topImage: <ImageComponent src={images.verifySuccessTick} />,
			};
		}
		return {
			title: 'Update Mobile Number',
			desc: 'Enter the new Mobile Number and we will send you an OTP for verification',
			onOkay: sendingOTP,
			onCancelButton: 'Cancel',
			onOkayButton: 'Send OTP',
			loading: auth?.otp?.loading,
			onOkayDisable: !phoneNumber.length,
			children: MobileNumberComponent,
		};
	};

	const onCloseModal = () => {
		setmobileNumberModal(false);
		resetModal();
	};

	function resetModal() {
		setOtpStatusSent(false);
		setotpValidation(false);
		setnumberValidation(false);
		setverifyOtpStatus(false);
		setPhoneNumber('');
		setOtp(new Array(6).fill(''));
		setSeconds(30);
	}

	return (
		<>
			<Popup visible={popupVisible} message={popupMessage} closePopup={closePopup}></Popup>
			<Toast
				width={'30px'}
				color="#8EF9B3"
				textcolor="#484B52"
				visible={isImageUpload}
				clearPopUp={() => dispatch(Actions.creators.showSuccessSaveMsg(false))}>
				Profile image uploaded successfuly
			</Toast>
			<Toast
				width={'30px'}
				color="#8EF9B3"
				textcolor="#484B52"
				visible={!!deleteProfileImgMsg}
				clearPopUp={() => dispatch(Actions.creators.deleteProfileImageSuccess(null))}>
				{deleteProfileImgMsg}
			</Toast>
			<div className="mobile-number-modal">
				<Edenredmodal onCancel={onCloseModal} isOpen={mobileNumberModal} close={onCloseModal} {...displayModalContent()} />
			</div>
			<EdenredHeader profile={{ link: { pathname: '/dashboard/profile', state: {} } }} />
			<Row className="profile-dashboard-presentational">
				<Col xl={24} lg={24} md={24} xs={24} sm={24} className="profile-dashboard-col">
					{/* header */}
					<Row className="header">
						<Col xl={24} lg={24} md={24} xs={24} sm={24} className="profile-header">
							<EdenredTypo>Profile</EdenredTypo>
							<Col className="profile-header-buttons">
								<EdenredButtons
									onClick={() => history.goBack()}
									btnType="secondary"
									leftIcon={<img alt="left-arrow" src={images.leftsarrow} />}
									className="go-back-button">
									Back
								</EdenredButtons>
								<EdenredButtons
									onClick={() =>
										history.push({
											pathname: '/dashboard/change-password',
										})
									}
									btnType="secondary"
									leftIcon={<img alt="locknew" src={images.locknew} />}
									className="change-password-button">
									Change Password
								</EdenredButtons>
								<EdenredButtons
									btnType="primary"
									className="save-button"
									disabled={!(Email && inputFieldEdited.current === true)}
									loading={loading}
									onClick={() => handleChange()}>
									Save
								</EdenredButtons>
							</Col>
						</Col>
					</Row>
					{/* icon edit */}
					<Row className="profile-body">
						<Col className="profile-dashboard-image">
							<EdenredTypo>Profile Picture</EdenredTypo>
							<div className="attachment preview-profile-image">
								<UploadAttachment
									size={400000}
									setFileUpload={(file) => dispatch(ProfileAction.creators.saveProfileImageStart(file))}
									draggerStatus={profileImg ? 'done' : 'new'}
									saveGetUploadLogoStatus={profileImg || []}
									deleteProfileImg={() => dispatch(ProfileAction.creators.deleteProfileImageStart())}
								/>
							</div>
						</Col>
						<Col className="profile-dashboard-input">
							<EdenredTypo>Personal Info</EdenredTypo>
							<section>
								<div className="username-input1 font-bold">
									<EdenredTypo>
										Username<span style={{ color: '#FF9691' }}> *</span>
									</EdenredTypo>
									<Inputbox className="input-note" type="text" placeholder="Username" disabled={true} value={user.userName} />
								</div>
								<div className="username-input font-bold">
									<EdenredTypo>
										Role<span style={{ color: '#FF9691' }}> *</span>
									</EdenredTypo>
									<Inputbox
										className="input-note"
										type="text"
										placeholder="Email"
										disabled={true}
										value={role ? role.toString() : undefined}
									/>
								</div>
								<div className="username-input">
									<EdenredTypo>
										Email Address <span style={{ color: '#FF9691' }}> *</span>
									</EdenredTypo>
									<Inputbox
										className="input-note editable"
										type="text"
										placeholder="Email"
										onChange={(e) => handleEmailvalidation(e.target.value)}
										value={Email}
									/>
								</div>
								{user?.phoneNumber && (
									<div className="phone-number-input font-bold">
										<EdenredTypo>Mobile Number</EdenredTypo>
										<Inputbox
											rightIcon={
												<ETypo className="phone-number-update-text" b1 onClick={updateMobileNumberModal}>
													Update
												</ETypo>
											}
											className="input-note"
											type="number"
											disabled
											placeholder="Phone number"
											value={user.phoneNumber}
										/>
									</div>
								)}
							</section>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default ProfilePresentational;
