import React from 'react';
import { connect } from 'react-redux';
import ProfilePresentational from './profile-presentational';
import Actions from '@sharedComponent/app-action-reducer/action';

const ProfileFunctional = ({ setUser, currentUser }) => {
	const adimRoles = ['SuperAdmin', 'Employee'];
	const role =
		currentUser && currentUser.roles.length > 0 && currentUser.roles?.map((data) => data?.name)?.filter((item) => !adimRoles.includes(item));
	return <ProfilePresentational role={role} currentUser={currentUser} setUser={setUser} />;
};

function mapStateToProps({ sharedStates: { currentUser } }) {
	return { currentUser };
}
const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (details) => dispatch(Actions.creators.setUser(details)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFunctional);
