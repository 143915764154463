import EdenredTypo from '@sharedComponent/typography';
import { Carousel, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';

function MobileCarousel({ data, title, slidesPerRow, slidesToShow, slidesToScroll, dots, content, mockData }) {
	console.log('MobileCarousel', data);

	return (
		<div>
			<div style={{ paddingBottom: 15 }}>
				<EdenredTypo className="medium title-leave-status">{title}</EdenredTypo>
			</div>
			<div className="Parent-leave-status-container">
				<Carousel slidesToShow={slidesToShow} slidesToScroll={slidesToScroll} slidesPerRow={slidesPerRow} dots="true">
					{data?.map((data, i) => {
						console.log('dataconsole1', data);
						let temp = i + 1;
						return (
							<>
								<Row key={i} className="inner-row-leave-status">
									<Col xs={24}>
										<EdenredTypo className="medium leave-status-name">{data?.employeeName || ''}</EdenredTypo>
									</Col>
									<Col xs={24}>
										<EdenredTypo className="regular subtext-leave-status">{`${data?.employeeId} | ${
											data?.departmentName || ''
										}`}</EdenredTypo>
									</Col>
									<Col xs={24}>
										<EdenredTypo className="mob-sub-text-leave regular">
											<span className="span-leavereason">{data?.leaveTypeName}: </span>
											{`${moment(data?.startDate).format('MMM DD')}-${moment(data?.endDate).format('MMM DD')} (${
												data?.totalDays
											} days)`}
										</EdenredTypo>
									</Col>
									{temp % 4 == 0 ? <Col xs={24} style={{ paddingBottom: 40 }}></Col> : <Divider style={{ margin: '10px 0px' }} />}
								</Row>
							</>
						);
					})}
				</Carousel>
			</div>
		</div>
	);
}

export default MobileCarousel;
