import React from 'react';
import DocumentExpiry from '@pages/NewHrModuleSetup/DocumentsExpiry/DocumentExpiry';

const UploadDocumentsData = () => {
	return (
		<>
			<DocumentExpiry isShowAddOptions={true} isShowSecondaryBtn={false} navigate="/documents/preview-upload-document" />
		</>
	);
};

export default UploadDocumentsData;
