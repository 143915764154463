const GET_SETUP = 'GET_SETUP';
const GET_SETUP_SUCCESS = 'GET_SETUP_SUCCESS';
const GET_SETUP_FAILED = 'GET_SETUP_FAILED';
const SETUP_STATUS_UPDATE_START = 'SETUP_STATUS_UPDATE_START';
const SETUP_STATUS_UPDATE_SUCCESS = 'SETUP_STATUS_UPDATE_SUCCESS';
const SETUP_STATUS_UPDATE_FAIL = 'SETUP_STATUS_UPDATE_FAIL';

const getSetup = () => {
	return {
		type: GET_SETUP,
	};
};
const getSetupSuccess = (response) => {
	return {
		type: GET_SETUP_SUCCESS,
		response,
	};
};
const getSetupFailed = () => {
	return {
		type: GET_SETUP_FAILED,
	};
};
const setupStatusUpdateStart = () => {
	return {
		type: SETUP_STATUS_UPDATE_START,
	};
};
const setupStatusUpdateSuccess = (res) => {
	return {
		type: SETUP_STATUS_UPDATE_SUCCESS,
		res,
	};
};
const setupStatusUpdateFail = () => {
	return {
		type: SETUP_STATUS_UPDATE_FAIL,
	};
};

export default {
	types: {
		GET_SETUP,
		GET_SETUP_SUCCESS,
		GET_SETUP_FAILED,
		SETUP_STATUS_UPDATE_START,
		SETUP_STATUS_UPDATE_SUCCESS,
		SETUP_STATUS_UPDATE_FAIL,
	},
	creators: {
		getSetup,
		getSetupSuccess,
		getSetupFailed,
		setupStatusUpdateStart,
		setupStatusUpdateSuccess,
		setupStatusUpdateFail,
	},
};
