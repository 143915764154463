import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { useHistory, useLocation } from 'react-router-dom';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import ETypo from '@pages/DesignSystem/Typo';
import { CorporateService } from 'src/services';
import { API_STATUS_CODES } from '@constants/app-constants';
import { SITEMAP } from 'src/routes/sitemap';
import ImageComponent from '@sharedComponent/image-component';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { COOKIE_CONSTANTS, deletingCookie, gettingCookie } from 'src/helpers/cookies';
import TableView from './TableView';
import { useCallEmployeeVerify } from 'src/hooks';
import './index.scss';

const EdenredTC = () => {
	const [openPopUp, setOpenPopUp] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const history = useHistory();
	const location = useLocation();
	const { callEmployeeVerifyCheck } = useCallEmployeeVerify();

	useEffect(() => {
		const cbdCookieExists = gettingCookie(COOKIE_CONSTANTS.cbdClient.tc);
		if (!cbdCookieExists) {
			redirectToDashboard();
		}
	}, []);

	function redirectToDashboard() {
		history.replace({ pathname: SITEMAP.dashboard.index, state: { user: location?.state?.user } });
	}

	async function acceptingTermsAndConditions() {
		setAcceptedTerms(true);
		try {
			const corporateService = new CorporateService();
			const res = await corporateService.acceptTermsAndConditions(true);
			if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
				throw new Error('Something went wrong');
			}
			if (res.status === API_STATUS_CODES.SUCCESS) {
				if (gettingCookie(COOKIE_CONSTANTS.cbdClient.tc)) {
					deletingCookie(COOKIE_CONSTANTS.cbdClient.tc);
				}
				callEmployeeVerifyCheck();
			}
		} catch (error) {
			setAcceptedTerms(false);
			if (error?.message) {
				message.error(error?.message);
			}
			console.error(error, 'cbd client api');
		}
	}

	const handleChange =
		(id = '') =>
		() => {
			if (id === 'next') {
				setPageIndex((prev) => prev + 1);
			} else if (id === 'prev') {
				setPageIndex((prev) => prev - 1);
			}
		};

	function showContents() {
		return <TableView pageNo={pageIndex} />;
	}

	return (
		<div className="edenred-terms-and-condition-container">
			<div className="terms-and-conditions">
				<section className="header-container">
					<div className="header-contents">
						<ETypo h2 bold>
							Terms & Conditions
						</ETypo>
						<br />
						<ETypo h3 regular>
							We're happy to offer you our services. Read and agree to the Terms & Conditions
						</ETypo>
					</div>
					<div className="header-buttons">
						{pageIndex > 0 && (
							<ImageComponent
								className="select-btn"
								src={focus_state_icons.selectLeftArrow}
								onClick={handleChange('prev')}
								alt="left-chevron-icon"
							/>
						)}
						{pageIndex > 1 ? (
							<EdenredButtons onClick={() => setOpenPopUp(true)}>Accept & Confirm</EdenredButtons>
						) : (
							<EdenredButtons onClick={handleChange('next')}>Next</EdenredButtons>
						)}
					</div>
				</section>

				<section className="main">{showContents()}</section>
			</div>
			<Edenredmodal
				{...{
					isOpen: openPopUp,
					close: () => setOpenPopUp(false),
					onOkayButton: 'I Accept & Confirm ',
					onCancelButton: 'Cancel',
					onOkay: acceptingTermsAndConditions,
					onCancel: () => setOpenPopUp(false),
					loading: acceptedTerms,
					title: 'Please Confirm',
					desc: 'By clicking, you confirm that you have read and agree to our Terms & Conditions',
				}}
			/>
		</div>
	);
};

export default EdenredTC;
