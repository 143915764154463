import Axios from 'axios';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL, UN_EMPLOYMENT_INSURANCE_CONSTANTS } from '@constants/app-constants';
import Actions from '../action';
import { apiUrl, appConstant } from '@constants/index';
import sharedActions from '@sharedComponent/app-action-reducer/action';

export function* getUnEmploymentServices() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const baseURL = `${DOMAIN_URL}/Employees/${corporateId}?accountType=C3Pay%20Cardholders&status=ACTIVE&pageNumber=1&pageSize=1&payrollBlockCheck=false`;

	try {
		const url = baseURL;
		const res = yield call(Axios.get, url);
		const url1 = `${baseURL}&validEmiratesIdCheck=valid`;
		const res1 = yield call(Axios.get, url1);

		const unEmployementCountUrl = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/count?paymentPlan=${UN_EMPLOYMENT_INSURANCE_CONSTANTS.insurancePlan}`;
		const unEmployementCount = yield call(Axios.get, unEmployementCountUrl);
		yield put(Actions.creators.getUnEmploymentCardHolderSuccess(unEmployementCount.data));

		yield put(Actions.creators.getUnEmploymentEidDataSuccess(res1.data));
	} catch (err) {
		yield put(Actions.creators.getUnEmploymentDataFail(err));
	}
}

export function* getMissingDetailsEmpService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const { pageSize, pageNumber, searchedText } = action;
	const baseURL = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/missing-details/data?paymentPlan=${UN_EMPLOYMENT_INSURANCE_CONSTANTS.insurancePlan}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${searchedText}`;

	try {
		const url = baseURL;
		const res = yield call(Axios.get, url);

		yield put(Actions.creators.getMissingEmployeeListSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getMissingEmployeeListFail());
	}
}

export function* getUnEmploymentWithStatusService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const { pageSize, pageNumber, searchedText, status } = action;
	const baseURL = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/details/data?status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${searchedText}`;

	try {
		const url = baseURL;
		const res = yield call(Axios.get, url);

		yield put(Actions.creators.getUnEmploymentListWithStatusSuccess(res.data, status));
	} catch (err) {
		yield put(Actions.creators.getUnEmploymentListWithStatusFail());
	}
}

export function* getUnemploymentAllCountService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const baseURL = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/details/count`;

	try {
		const url = baseURL;
		const res = yield call(Axios.get, url);

		yield put(Actions.creators.getUnemploymentAllCountSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.getUnemploymentAllCountFail());
	}
}

export function* updateMissingEmployeeDetailService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const response = action?.payload;
	try {
		const uri = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/missing-details/save`;
		const res = yield call(Axios.post, uri, response);
		if (res.data === '') {
			yield put(Actions.creators.getMissingEmployeeList(10, 1, ''));
			yield put(Actions.creators.updateMissingEmployeeListSuccess(null));
			// Calling Pending alerts count to update latest count		
			yield put(sharedActions.creators.getPendingStatusStart());
			yield put(Actions.creators.setSuccessToastFlag(true));
		} else {
			yield put(Actions.creators.updateMissingEmployeeListSuccess(res.data));
		}
		yield put(Actions.creators.getUnemploymentAllCount());
	} catch (err) {
		yield put(Actions.creators.updateMissingEmployeeListFail());
	}
}

export function* sendUnEmploymentEmployeeData(action) {
	try {
		yield put({
			type: Actions.types.PUT_UNEMPLOYMENT_EMPLOYEE_LOADING,
			payload: {
				loading: true,
			},
		});

		const { sharedStates } = yield select();

		const currentCorporateId = sharedStates.corporateId;

		const url = `${DOMAIN_URL}/UnemploymentInsurance/${currentCorporateId}?paymentPlan=${action?.extras?.plan || UN_EMPLOYMENT_INSURANCE_CONSTANTS.insurancePlan}`;

		const response = yield call(Axios.post, url);
		// if the response went success
		if (response.data === '') {
			// Calling Pending alerts count to update latest count		
			yield put(sharedActions.creators.getPendingStatusStart());
		}

		const uri = `${appConstant.domain}${apiUrl.CORPORATE_ID}`;
		const res = yield call(Axios.get, uri);
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID)
			? localStorage.getItem(APP_VARIABLES.CORPORATE_ID)
			: res.data.corporates[0].corporateId;
		const corporateIds = res.data.corporates;

		corporateIds.forEach((corporate) => {
			if (corporate.corporateId === corporateId) {
				if (corporate.isUnemploymentInsuranceSubmit === false) {
					throw new Error('Something went wrong');
				}
			}
		});
		yield put(sharedActions.creators.getCorporateIdSuccess(corporateId, corporateIds));

		if (typeof action?.extras?.history?.push === 'function') {
			yield put(sharedActions.creators.getUnEmploymentInsuranceDetailsBanner());
			action.extras.history.push('/un-employment-insurance/success');
		}
		yield put({
			type: Actions.types.PUT_UNEMPLOYMENT_EMPLOYEE_LOADING,
			payload: {
				loading: false,
			},
		});
	} catch (err) {
		yield put({
			type: Actions.types.PUT_UNEMPLOYMENT_EMPLOYEE_LOADING,
			payload: {
				loading: false,
			},
		});
		yield put(Actions.creators.putUnEmploymentEmployeeDataFail(err));
	}
}

export function* missingEmpTemplateDownloadService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const payload = {};
	try {
		const uri = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/details/download`;
		const res = yield call(Axios.post, uri, payload, {
			responseType: 'blob',
			headers: {
				Accept: 'application/xlsx',
			},
		});
		const BACKEND_SUCCESS_MSG_FOR_EMPTY = 'text/plain';
		if (res.data.type !== BACKEND_SUCCESS_MSG_FOR_EMPTY) {
			const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {}));
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute('download', `UnemployeementMissingList.xlsx`); //any other extension
			document.body.appendChild(link);
			link.click();
			link.remove();
			yield put(Actions.creators.missingEmpTemplateDownloadSuccess(null));
		} else {
			yield put(Actions.creators.missingEmpTemplateDownloadSuccess('There is no data for this Report'));
		}
	} catch (err) {
		yield put(Actions.creators.missingEmpTemplateDownloadFail());
	}
}

export function* downloadDetailedReportService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const payload = {};
	try {
		const uri = `${DOMAIN_URL}/UnemploymentInsurance/${corporateId}/details/download?status=${action.status ? action.status : null}`;
		const res = yield call(Axios.post, uri, payload, {
			responseType: 'blob',
			headers: {
				Accept: 'application/xlsx',
			},
		});

		const BACKEND_SUCCESS_MSG_FOR_EMPTY = 'text/plain';
		if (res.data.type !== BACKEND_SUCCESS_MSG_FOR_EMPTY) {
			const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {}));
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute('download', `UnemployeementList.xlsx`); //any other extension
			document.body.appendChild(link);
			link.click();
			link.remove();

			yield put(Actions.creators.downloadDetailedReportSuccess(null));
		} else {
			yield put(Actions.creators.downloadDetailedReportSuccess('There is no data for this Report'));
		}
	} catch (err) {
		yield put(Actions.creators.downloadDetailedReportFail());
	}
}

export default function* unEmploymentWatcher() {
	yield takeEvery(Actions.types.GET_UNEMPLOYMENT_START, getUnEmploymentServices);
	yield takeEvery(Actions.types.PUT_UNEMPLOYMENT_EMPLOYEE_DATA, sendUnEmploymentEmployeeData);

	yield takeEvery(Actions.types.GET_MISSING_EMPLOYEE_LIST, getMissingDetailsEmpService);

	yield takeEvery(Actions.types.UPDATE_MISSING_EMPLOYEE_LIST, updateMissingEmployeeDetailService);

	yield takeEvery(Actions.types.MISSING_EMP_TEMPLATE_DOWNLOAD, missingEmpTemplateDownloadService);

	yield takeEvery(Actions.types.GET_UNEMPLOYMENT_ALL_COUNT, getUnemploymentAllCountService);

	yield takeEvery(Actions.types.GET_UN_EMPLOYMENT_LIST_WITH_STATUS, getUnEmploymentWithStatusService);

	yield takeEvery(Actions.types.DOWNLOAD_DETAILED_REPORT_START, downloadDetailedReportService);
}
