import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { popUpNotification } from '@constants/app-constants';
import { CustomAlertNotification } from '@pages/Leave-Management/request-leave/alert-notification/alert-notification';
import ETypo from '@pages/DesignSystem/Typo';
import DatePickers from '@pages/DesignSystem/date-flat-picker';
import UploadPayrollPopup from 'src/utils/uploadPayrollPopup';
import Popup from 'src/utils/popup/popups';
import './payroll-date-picker.scss';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';

export const PayrollDatePicker = ({ payrollDetails, defaultDate, placeholder, calenderVissible = false, onChange, dateLoader = false, disable }) => {
	const [focused, setFocused] = useState(false);
	const options = {
		allowInput: true,
		dateFormat: 'd/m/Y',
		disableMobile: true,
		typeof: 'number',
	};

	const datevalid = 'End Date must be greater than Joining Date';
	const datepick =
		payrollDetails?.endDate != '' &&
		payrollDetails?.joiningDate.split('/').reverse().join('-') > payrollDetails?.endDate.split('/').reverse().join('-');
	return (
		<div className="payroll-flatpickrs">
			<ETypo medium b3 color="#181919">
				{placeholder}
			</ETypo>
			<DatePickers
				showDatesIcon={false}
				value={defaultDate}
				onChange={onChange}
				className={datepick ? 'validate' : ''}
				onKeyDown={(e) => e.keyCode >= 65 && e.keyCode <= 90 && e.preventDefault()}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				{...{
					dateOption: options,
					disabled: disable,
					placeholder: focused ? 'DD/MM/YYYY' : placeholder,
				}}
			/>
			{datepick && <p className="payroll-date-validate-picker">{datepick ? datevalid : null}</p>}
			{dateLoader ? <ClipLoader className="cliploader-loading" loading /> : null}
		</div>
	);
};

export const PopUp = ({ isEnabled, isSavePopUp, notification, handlePayrollPopup, savePopUp, savedSuccessfully, isPayrollSubmitLoading }) => {
	return (
		<>
			<UploadPayrollPopup
				visible={isSavePopUp}
				closePopup={() => handlePayrollPopup('closeSavePopUp')}
				autoFixEmployeeFileError={() => handlePayrollPopup('handleSavePopUp')}
				message={popUpNotification.saveMessage}
				subMessage={popUpNotification?.subSaveMessage}
				className="payroll-save-popup"
				loading={isPayrollSubmitLoading}
				okText="Save"
				cancelText="Cancel"
			/>
			<UploadPayrollPopup
				visible={isEnabled.cancelPopUp}
				closePopup={() => handlePayrollPopup('closeCancelPopUp')}
				autoFixEmployeeFileError={() => handlePayrollPopup('handleCancelPopUp')}
				message={popUpNotification.cancelMessage}
				subMessage={popUpNotification?.subCancelMessage}
				className="payroll-cancel-popup"
			/>
			<Edenredmodal
				isOpen={savedSuccessfully}
				title={popUpNotification?.successMessage}
				onOkayButton={'Ok'}
				onOkay={() => handlePayrollPopup('closeSuccessPopUp')}
				textcenter={true}
			/>
			<CustomAlertNotification
				{...{
					when: notification,
					message: 'Do you want to leave this page? Your changes will not be saved.',
					okText: 'Yes, I want to leave',
					cancelText: 'Stay on Page',
					onOK: () => true,
					onCancel: () => true,
				}}
			/>
		</>
	);
};
