import React from 'react'
import LandingFunctional from './LandingFunctional'

const LandingPage = () => {
    return (
        <div>
            <LandingFunctional />
        </div>
    )
}

export default LandingPage