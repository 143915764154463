const GET_FILTER_DROPDOWN_DATA = 'GET_FILTER_DROPDOWN_DATA';
const GET_FILTER_DROPDOWN_DATA_SUCCESS = 'GET_FILTER_DROPDOWN_DATA_SUCCESS';
const GET_FILTER_DROPDOWN_DATA_ERROR = 'GET_FILTER_DROPDOWN_DATA_ERROR';
const GET_EMPLOYEE_TABLE_DATA = 'GET_EMPLOYEE_TABLE_DATA';
const GET_EMPLOYEE_TABLE_DATA_SUCCESS = 'GET_EMPLOYEE_TABLE_DATA_SUCCESS';
const GET_EMPLOYEE_TABLE_DATA_ERROR = 'GET_EMPLOYEE_TABLE_DATA_ERROR';
const GET_PREVIOUS_PAYROLL_DATA = 'GET_PREVIOUS_PAYROLL_DATA';
const GET_PREVIOUS_PAYROLL_DATA_SUCCESS = 'GET_PREVIOUS_PAYROLL_DATA_SUCCESS';
const GET_PREVIOUS_PAYROLL_DATA_ERROR = 'GET_PREVIOUS_PAYROLL_DATA_ERROR';
const GET_TOTAL_COUNT_SUCCESS = 'GET_TOTAL_COUNT_SUCCESS';
const GET_TOTAL_COUNT_ERROR = 'GET_TOTAL_COUNT_ERROR';

const getFilterDropdownData = (response) => {
	return {
		type: GET_FILTER_DROPDOWN_DATA,
		payload: response,
	};
};

const getFilterDropdownDataSuccess = (res) => {
	return {
		type: GET_FILTER_DROPDOWN_DATA_SUCCESS,
		res,
	};
};

const getFilterDropdownDataError = () => {
	return {
		type: GET_FILTER_DROPDOWN_DATA_ERROR,
		payload: {},
	};
};

const getEmployeeTabledata = (response) => {
	console.log('getEmployeeTabledata', response);
	return {
		type: GET_EMPLOYEE_TABLE_DATA,
		response,
	};
};

const getEmployeeTabledataSuccess = (res) => {
	return {
		type: GET_EMPLOYEE_TABLE_DATA_SUCCESS,
		res,
	};
};

const getEmployeeTabledataError = () => {
	return {
		type: GET_EMPLOYEE_TABLE_DATA_ERROR,
		payload: {},
	};
};

const getPreviousPayrollData = (response) => {
	console.log('responseeee', response);
	return {
		type: GET_PREVIOUS_PAYROLL_DATA,
		response,
	};
};
const getPreviousPayrollDataSuccess = (response) => {
	console.log('vvvvvv', response);
	return {
		type: GET_PREVIOUS_PAYROLL_DATA_SUCCESS,
		response,
	};
};
const getPreviousPayrollDataError = () => {
	return {
		type: GET_PREVIOUS_PAYROLL_DATA_ERROR,
	};
};

const getTotalCountSuccess = (res) => {
	return {
		type: GET_TOTAL_COUNT_SUCCESS,
		res,
	};
};
const getTotalCountError = (res) => {
	return {
		type: GET_TOTAL_COUNT_ERROR,
		res,
	};
};

export default {
	types: {
		GET_FILTER_DROPDOWN_DATA,
		GET_FILTER_DROPDOWN_DATA_SUCCESS,
		GET_FILTER_DROPDOWN_DATA_ERROR,
		GET_EMPLOYEE_TABLE_DATA,
		GET_EMPLOYEE_TABLE_DATA_SUCCESS,
		GET_EMPLOYEE_TABLE_DATA_ERROR,
		GET_PREVIOUS_PAYROLL_DATA,
		GET_PREVIOUS_PAYROLL_DATA_SUCCESS,
		GET_PREVIOUS_PAYROLL_DATA_ERROR,
		GET_TOTAL_COUNT_SUCCESS,
		GET_TOTAL_COUNT_ERROR,
	},
	creators: {
		getFilterDropdownData,
		getFilterDropdownDataSuccess,
		getFilterDropdownDataError,
		getEmployeeTabledata,
		getEmployeeTabledataSuccess,
		getEmployeeTabledataError,
		getPreviousPayrollData,
		getPreviousPayrollDataSuccess,
		getPreviousPayrollDataError,
		getTotalCountSuccess,
		getTotalCountError,
	},
};
