import Actions from './action';
export const initState = {
	setupClientStatusLoading: false,
	setupClientStatusDetail: [],
	setupClientStatusFail: '',
};

const setUpFinalReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.GET_CLIENT_STATUS_START: {
			return {
				...state,
				setupClientStatusLoading: true,
			};
		}
		case Actions.types.GET_CLIENT_STATUS_SUCCESS: {
			return {
				...state,
				setupClientStatusLoading: false,
				setupClientStatusDetail: action.res,
			};
		}
		case Actions.types.GET_CLIENT_STATUS_FAIL: {
			return {
				...state,
				setupClientStatusLoading: false,
				setupClientStatusDetail: [],
			};
		}

		default:
			return state;
	}
};

export default setUpFinalReducer;
