import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { DotLoader, PulseLoader } from 'react-spinners';
import InfiniteScroll from 'react-infinite-scroll-component';
import EdenRedCard from '@sharedComponent/card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ETypo from '@pages/DesignSystem/Typo';
import PayrollService from 'src/services/payroll-service';
import { not_focus_icons } from 'src/assets/icons/not_focus';

const PayrollHistoryPresentational = () => {
	const [state, setstate] = useState({
		loading: true,
		payrollHistory: [],
	});
	const _payrollService = new PayrollService();
	const [pageNumber, setPageNumber] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [contentLoading, setcontentLoading] = useState(false);

	useEffect(() => {
		getPayrollHistory(pageNumber, true);
	}, []);
	useEffect(() => {
		getPayrollHistory(pageNumber);
	}, [pageNumber]);

	const getPayrollHistory = (page, initial) => {
		//TODO-Here we want to remove dummy data and do api integration
		let params = {};
		params['pageNumber'] = page;
		params['pageSize'] = 10;
		if (initial === true) {
			setcontentLoading(true);
		}
		_payrollService
			.getPayrollHistory(params)
			.then((response) => {
				let a = [...state?.payrollHistory, ...response?.data?.payrollHistory];
				if (response?.data?.payrollHistory && response?.data?.payrollHistory.length > 0) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setstate({
					loading: false,
					payrollHistory: a || [],
				});
				if (initial === true) {
					setcontentLoading(false);
				}
			})
			.catch((err) => {
				setstate({
					...state,
					loading: false,
					payrollHistory: [],
				});
				if (initial === true) {
					setcontentLoading(false);
				}
			});
	};

	const fetchMoreData = () => setPageNumber((pageNumber) => pageNumber + 1);

	const { loading, payrollHistory } = state;
	let height = window.innerHeight - 64;
	const getStatusIcon = (status) => {
		if (status?.toLowerCase()?.includes('payout')) {
			return { color: '#FFDC91', icon: not_focus_icons.statusVerified };
		} else if (status?.toLowerCase()?.includes('email')) {
			return { color: '#FFDC91', icon: not_focus_icons.statusCanceled };
		} else if (status?.toLowerCase()?.includes('credited')) {
			return { color: '#8EF9B3', icon: not_focus_icons.statusDelivered };
		} else {
			return { color: '#FFDC91', icon: not_focus_icons.statusVerified };
		}
	};
	return (
		<>
			<EdenredHeader />
			{contentLoading === true ? (
				<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					<DotLoader size={50} color={'#e91d24'} loading />
				</Col>
			) : (
				<Row className="Payroll_history_container">
					<Col style={{ width: '100%' }}>
						<Col className="Payroll_status_card">
							<InfiniteScroll
								dataLength={payrollHistory?.length}
								next={fetchMoreData}
								hasMore={hasMore}
								loader={
									<Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<DotLoader size={50} color={'#e91d24'} loading />
									</Col>
								}
								height={`${height}px`}
								endMessage={
									<p style={{ textAlign: 'center' }}>
										<b>Yay! You have seen it all</b>
									</p>
								}>
								<div className="payroll_status_header">
									<Col xl={24} md={24} lg={24} sm={24} xs={24} className="payroll_history">
										<ETypo bold h2>
											Payroll Status
										</ETypo>
									</Col>
									<Col xl={24} md={24} lg={24} sm={24} xs={24} className="click_payroll">
										<ETypo light b1 color="#484b52">
											Click on a payroll run to view its status and the details of the employees getting paid.
										</ETypo>
									</Col>
									<Col xl={24} md={24} lg={24} sm={24} xs={24} className="click_payroll_mobile_view">
										<ETypo light b1 color="#484b52">
											Tap on a payroll to view its status.
										</ETypo>
									</Col>
								</div>
								{payrollHistory && payrollHistory?.length > 0 ? (
									payrollHistory?.map((val, ind) => {
										const uploadId = val?.payrollRunHeader?.uploadId;
										return (
											<div className="payroll_card_container">
												<div>
													<Col key={ind} xl={24} md={24} lg={24} sm={24} xs={24} className="payroll_history_month">
														<ETypo
															medium
															h5>{`${val?.payrollRunHeader?.headerAmount} - ${val?.payrollRunHeader?.headerType}`}</ETypo>
													</Col>
												</div>
												<div>
													<Col xl={24} md={24} lg={24} sm={24} xs={24} className="payroll_history_month_mobile_view">
														<ETypo medium h5>
															{val?.payrollRunHeader && val?.payrollRunHeader?.headerAmount}
														</ETypo>
														<ETypo medium h5>
															{val?.payrollRunHeader?.headerType} payroll.
														</ETypo>
													</Col>
													<Col xl={24} md={24} lg={24} sm={24} xs={24} className="payroll_history_submit">
														<Col xl={12} md={12} lg={12} sm={12} xs={12}>
															<ETypo light b1>
																Submitted at {val?.payrollRunHeader?.submittedAt}
															</ETypo>
														</Col>
														<Col xl={12} md={12} lg={12} sm={12} xs={12} className="payroll-status-filename-con">
															<ETypo light b1 className="ellipsis">
																Filename: {val?.payrollRunHeader?.fileName}
															</ETypo>
														</Col>
													</Col>
												</div>
												<Row>
													{val?.payrollRunDetail?.map((item) => {
														const bothHasValues =
															val?.payrollRunDetail?.filter((item) => item?.payrollAmount)?.length === 2;
														const width = bothHasValues ? 12 : 24;
														return (
															<>
																{item?.payrollAmount ? (
																	<Col xl={width} lg={width} md={24} sm={24} xs={24} className="card_details">
																		{item?.isPAF ? (
																			<Link
																				to={{
																					pathname: `/payroll/status/direct-paf/status/${uploadId}`,
																					state: {
																						payrollRunHeader:
																							val?.payrollRunHeader &&
																							val?.payrollRunHeader?.payrollAmountTypeMonthYear,
																						cardDetail: item,
																					},
																				}}>
																				<div>
																					<EdenRedCard
																						type="PayrollHistory"
																						className="payroll_history_status_card"
																						content={item}
																						background={getStatusIcon(item?.expectedPayout)?.color}
																						statusicon={getStatusIcon(item?.expectedPayout)?.icon}
																					/>
																				</div>
																			</Link>
																		) : (
																			<Link
																				to={{
																					pathname: `/payroll/status/${uploadId}`,
																					state: {
																						payrollRunHeader:
																							val?.payrollRunHeader &&
																							val?.payrollRunHeader?.payrollAmountTypeMonthYear,
																						cardDetail: item,
																					},
																				}}>
																				<div>
																					<EdenRedCard
																						type="PayrollHistory"
																						className="payroll_history_status_card"
																						content={item}
																						background={getStatusIcon(item?.expectedPayout)?.color}
																						statusicon={getStatusIcon(item?.expectedPayout)?.icon}
																					/>
																				</div>
																			</Link>
																		)}
																	</Col>
																) : null}
															</>
														);
													})}
												</Row>
											</div>
										);
									})
								) : (
									<div>No Records Found</div>
								)}
							</InfiniteScroll>
						</Col>
					</Col>
				</Row>
			)}
		</>
	);
};
export default PayrollHistoryPresentational;
