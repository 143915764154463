import { put, takeEvery, call } from 'redux-saga/effects';
import Axios, { post } from 'axios';
import { message } from 'antd';
import Actions from '../action';
import { appConstant, apiUrl } from '@constants';
import { API_DOMAIN, APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';

export function* uploadDocumentService(file) {
	let formData = new FormData();
	formData.append('file', file.file);
	var request = file;
	try {
		// yield put(Actions.creators.saveDocumentUploadSuccess(''));
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/bulk-update-upload`;
		const res = yield call(Axios.post, uri, formData);
		yield put(Actions.creators.saveDocumentUploadSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.saveDocumentUploadFail(err));
	}
}
export function* templateService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const isSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const forName = isSubscription ? 'EmployeeDownloadSubscription_Template' : 'EmployeeDownloadNoSubscription_Template';
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/data/download`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf',
			},
		});
		yield put(Actions.creators.saveEmpTemplateSuccess(res.data));
		const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {}));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', `employee.xlsx`); //any other extension
		// link.setAttribute('download', 'file.xls'); //any other extension
		document.body.appendChild(link);
		link.click();
		link.remove();
	} catch (err) {
		yield put(Actions.creators.saveEmpTemplateFail(err));
	}
}

export default function* uploadWatcher() {
	yield takeEvery(Actions.types.SAVE_DOCUMENT_UPLOAD_LOADING, uploadDocumentService);
	yield takeEvery(Actions.types.SAVE_EMP_TEMPLATE_LOADING, templateService);
}
