import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SharedServices, { GET_PROFILE, SET_LOGIN_STEP } from '@sharedComponent/app-action-reducer/services';
import { connect } from 'react-redux';
import { Row } from 'antd';
import ApprovePresentational from './approve-presentational';
import Actions from '@sharedComponent/app-action-reducer/action';
import { icons } from 'src/assets/icons';
import { USER_ROLE } from '@constants/app-constants';
import ContentLoader from '@sharedComponent/content-loader';
import EmployeeService from 'src/services/employee-service';
import { SITEMAP } from 'src/routes/sitemap';

const routes = {
	'Manage.Employees': {
		detail: 'Add new employee and order cards for them.',
		route: '/employees/manage_employee',
		image: icons.focus_state.AddUsers,
		name: 'manage_employee',
		header: (
			<Row>
				Manage Employees
				<br />
			</Row>
		),
	},
	'Manage.Payroll': {
		detail: 'Pay your employees!',
		route: '/run-payroll',
		image: icons.focus_state.Payroll,
		name: 'run-payroll',
		header: (
			<Row>
				Run Payroll
				<br />
			</Row>
		),
	},
};

const ApproveFunctional = ({ currentUser, setUser }) => {
	const isUser = currentUser.roles && currentUser.roles[0].name === USER_ROLE.USER;
	const claims = currentUser.claims;
	const [loading, setLoading] = useState(false);
	const [employeeTableData, setEmployeeTableData] = useState([]);
	const api = new SharedServices();
	let employeeApi = new EmployeeService();

	const history = useHistory();
	const { state } = useLocation() || {};

	const goTodashboard = (url) => {
		// debugger;
		setLoading(true);
		api.services(SET_LOGIN_STEP, {
			step: 0,
		})
			.then(() => {
				api.services(GET_PROFILE)
					.then((res) => {
						setLoading(false);
						setUser(res.data);
						if (url) {
							history.push({
								pathname: url,
								state: {
									user: state?.user,
								},
							});
						} else {
							history.push({
								pathname: SITEMAP.dashboard.index,
								state: {
									user: state?.user,
								},
							});
						}
					})
					.catch((err) => {
						setLoading(false);
					});
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const verifier_content_list = [
		{
			image: icons.focus_state.Check,
			header: (
				<Row>
					Approve Payroll
					<br />
					Requests
				</Row>
			),
			detail: 'Approve any pending payroll request from users.',
			route: '/payroll/approve_requests',
			name: 'approve_requests',
		},
		{
			image: icons.focus_state.Cards,
			header: (
				<Row>
					Check Card Order
					<br />
					Status
				</Row>
			),
			detail: 'Find out where the cards you’ve ordered are.',
			route: '/employees/card/order/status',
			name: 'cardorder_status',
		},
		{
			image: icons.focus_state.Download,
			header: (
				<Row>
					Download
					<br />
					Employee Details
				</Row>
			),
			detail: 'Get spreadsheet of employee details and account numbers',
			name: 'download_employee',
		},
		{
			image: icons.focus_state.ApproveCards,
			header: (
				<Row>
					Approve Employee
					<br />
					Requests
				</Row>
			),
			detail: 'Approve any pending employee requests from users.',
			route: '/employees/approve_employee_request',
			name: 'approve_employee_request',
		},
		{
			image: icons.focus_state.AddUser,
			header: (
				<Row>
					Invite
					<br />
					Team Members
				</Row>
			),
			detail: 'Add and edit team members and their permissions.',
			route: '/invite_team_member',
			name: 'invite_team_member',
		},
	];
	const user_content_list = [
		{
			image: icons.focus_state.Cards,
			header: (
				<Row>
					Check Card Order
					<br />
					Status
				</Row>
			),
			detail: 'Find out where the cards you’ve ordered are.',
			route: '/employees/card/order/status',
			name: 'cardorder_status',
		},
		{
			image: icons.focus_state.Download,
			header: (
				<Row>
					Download
					<br />
					Employee Details
				</Row>
			),
			detail: 'Get spreadsheet of employee details and account numbers',
			name: 'download_employee',
		},
	];

	useEffect(() => {
		fetchEmployees();
	}, []);

	async function fetchEmployees() {
		let queryParams = {
			status: 'ACTIVE',
			includeEmployeeCounts: true,
		};
		employeeApi
			.getEmployees(queryParams)
			.then((response) => {
				if (response && response.data) {
					setEmployeeTableData([...response.data.employees]);
				}
			})
			.catch((err) => {});
	}

	const tempClaims = (claims || []).map((claim) => {
		return routes[claim.value];
	});

	const content_list = isUser ? user_content_list.concat(tempClaims) : verifier_content_list;
	return currentUser.user ? (
		<ApprovePresentational employeeTableData={employeeTableData} content_list={content_list} loading={loading} goTodashboard={goTodashboard} />
	) : (
		<ContentLoader />
	);
};

const mapStateToProps = ({ sharedStates: { currentUser } }) => {
	return { currentUser };
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (roles) => dispatch(Actions.creators.setUser(roles)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveFunctional);
