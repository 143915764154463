import React from 'react';
import ImageComponent from '@sharedComponent/image-component';
import { images } from 'src/assets/img';
import Toast from '../index';

const ToastTwo = () => {
	return (
		<>
			<Toast color="#8EF9B3" timeout={3000} heart={true} size="240px" textcolor="#484B52" icon={<ImageComponent src={images.blackheart} />}>
				Lorem ipsum dolor sit
			</Toast>
			<Toast color="#8EF9B3" textcolor="#484B52">
				Lorem ipsum dolor sit
			</Toast>
		</>
	);
};

export default ToastTwo;
