import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const SCREEN_TYPES = {
	MOBILE: 'MOBILE',
	TAB: 'TAB',
	LAPTOP: 'LAPTOP',
	MONITOR: 'MONITOR',
	MONITOR_BIG: 'MONITOR_BIG',
};

const MOBILE = {
	minHeight: 0,
	minWidth: 0,
	maxHeight: 720,
	maxWidth: 450,
};

const TAB = {
	minHeight: MOBILE.maxHeight,
	minWidth: MOBILE.maxWidth,
	maxHeight: 800,
	maxWidth: 1180,
};

const LAPTOP = {
	minHeight: TAB.maxHeight,
	minWidth: TAB.maxWidth,
	maxHeight: 850,
	maxWidth: 1500,
};

const MONITOR = {
	minHeight: LAPTOP.maxHeight,
	minWidth: LAPTOP.maxWidth,
};

function useResponsiveScreen() {
	const responsiveScreen = useSelector((state) => state.responsiveScreen);
	const dispatch = useDispatch();
	const currentScreen = useRef(responsiveScreen.screen);
	currentScreen.current = responsiveScreen.screen;

	const putResponsiveScreen = (screen) => {
		const height = window.innerHeight;
		const width = window.innerWidth;
		dispatch({
			type: 'SET_RESPONSIVE_SCREEN',
			payload: {
				screen: screen,
				width: width,
				height: height,
			},
		});
	};

	function setScreen(currentScreen) {
		const width = window.innerWidth;
		const IS_MOBILE = width > MOBILE.minWidth && width < MOBILE.maxWidth;
		const IS_TAB = width > TAB.minWidth && width < TAB.maxWidth;
		const IS_LAPTOP = width > LAPTOP.minWidth && width < LAPTOP.maxWidth;
		const IS_MONITOR = width > MONITOR.minWidth;
		if (IS_MOBILE) {
			if (currentScreen !== SCREEN_TYPES.MOBILE) {
				putResponsiveScreen(SCREEN_TYPES.MOBILE);
			}
		} else if (IS_TAB) {
			if (currentScreen !== SCREEN_TYPES.TAB) {
				putResponsiveScreen(SCREEN_TYPES.TAB);
			}
		} else if (IS_LAPTOP) {
			if (currentScreen !== SCREEN_TYPES.LAPTOP) {
				putResponsiveScreen(SCREEN_TYPES.LAPTOP);
			}
		} else if (IS_MONITOR) {
			if (currentScreen !== SCREEN_TYPES.MONITOR) {
				putResponsiveScreen(SCREEN_TYPES.MONITOR);
			}
		}
	}

	// Create a ref that stores handler
	useEffect(() => {
		window.addEventListener('resize', (_) => {
			setScreen(currentScreen.current);
		});
		return () => {
			window.removeEventListener('resize');
		};
	}, []);
}

export default useResponsiveScreen;
